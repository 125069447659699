



































import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  provide,
  onMounted,
  useAsync,
  useContext,
  useMeta,
} from '@nuxtjs/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import {
  PRESERVE_PREVIEW,
  addPreservePreviewParam,
} from '@/components/cms/cmsUtils';
import {
  useInitSetup,
  useHistory,
  useMonetate,
  useI18n,
  useSignInToStore,
} from '@vf/composables';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useCyberSourceStore } from '@vf/composables/src/store/cyberSourceStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

import ModalManager from '@/components/modals/ModalManager.vue';
import Spinner from '@/components/loaders/Spinner.vue';
import LocationConfirmationModal from '@/components/modals/LocationConfirmationModal.vue';
import { isClient } from '@vf/shared/src/utils/helpers';
import useUIContextProvider from '@/shared/useUIContextProvider';
import useGlobalState from '@/shared/useGlobalState';
import useRootInstance from '@/shared/useRootInstance';
import SkipToContent from '@/components/accessibility/SkipToContent.vue';
import ProgressiveProfileManager from '@/components/static/simplifiedEnrollment/ProgressiveProfileManager.vue';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import { load as loadScript } from '@vf/shared/src/utils/helpers/load-script';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';

import type { ScriptPropertySrc } from 'vue-meta/types/vue-meta';

export default defineComponent({
  components: {
    SkipToContent,
    ModalManager,
    Spinner,
    ProgressiveProfileManager,
    LocationConfirmationModal,
  },
  setup() {
    const { root } = useRootInstance();
    const { monetateScriptUrl } = useMonetate(root);
    const { initSetup, apiClientRef } = useInitSetup(root);
    const { app } = useContext();
    const {
      prepareDeviceFingerprinting,
      deviceFingerPrintURL,
    } = useCyberSourceStore();
    const { updateHistoryWithAkamaiRedirection } = useHistory();
    const { localePath } = useI18n(root);
    const { clearStoreSession, hasStoreCookie } = useSignInToStore(root);
    const { currentStep } = storeToRefs(useCheckoutStore());
    // TODO: GLOBAL15-61059 remove after redesign core
    const {
      enableLocationConfirmation,
      checkoutOrderSummaryUpdated,
      checkoutStickyCtas,
      isCheckoutSuccessRedesignEnabled,
      isCoreRedesignEnabled,
      isFiltersUiUpdateEnabled,
      isMobileAppMetatagEnabled,
      isSimplifiedEnrollmentEnabled,
    } = storeToRefs(useFeatureFlagsStore());
    const { activeComponent } = storeToRefs(useSimplifiedEnrollmentStore(root));
    const cmsRefStore = useCmsRefStore();
    const { pageTypeName } = storeToRefs(cmsRefStore);

    useMeta(() => {
      const mappMetatag = root.$getConfigValueByCurrentLocale<any>(
        'MAPP_METATAG'
      );
      const meta =
        isMobileAppMetatagEnabled.value && mappMetatag ? [mappMetatag] : [];

      return {
        meta,
      };
    });

    const theme = root.$themeConfig.staticCheckout;
    const loading = isClient ? useGlobalState().loading : false;

    // TODO: GLOBAL15-61059 remove after core redesign
    provide('isCoreRedesignEnabled', isCoreRedesignEnabled);
    // TODO: GLOBAL15-62475 remove after filters redesign
    provide('isFiltersUiUpdateEnabled', isFiltersUiUpdateEnabled);
    provide(
      'isCheckoutSuccessRedesignEnabled',
      isCheckoutSuccessRedesignEnabled
    );
    provide(
      'cmsApiClient',
      computed(() => apiClientRef)
    );
    provide('initSetup', initSetup);

    useUIContextProvider();

    async function init() {
      try {
        await initSetup({ forcePreview: app.$isPreview });
      } catch (error) {
        root.$log.error(
          `@theme/layouts/checkoutStatic.vue::init Error: ${error.message}`
        );
        app.$nuxt.error({ statusCode: 404, message: 'Not found' });
      }
    }

    useMeta(() => {
      const scripts: ScriptPropertySrc[] = [];

      scripts.push({
        src: deviceFingerPrintURL,
      });

      return {
        script: scripts,
      };
    });

    useAsync(async () => {
      await init();
    });

    onMounted(() => {
      window.monetateQ ??= [];
      window.monetateQ.push([
        'setPageType',
        pageTypeName.value?.replace(/\s/g, ''),
      ]);
      loadScript(monetateScriptUrl);
      prepareDeviceFingerprinting();
      updateHistoryWithAkamaiRedirection();
      if (root.$isPreview) {
        const monetateParam = root.$route.query?.p13n_testcontext;
        window.sessionStorage.setItem(PRESERVE_PREVIEW, 'true');
        addPreservePreviewParam(monetateParam);
      }
    });

    const clearStoreSessionHandler = () => {
      clearStoreSession(localePath(theme.header.clearSession.link));
    };

    return {
      theme,
      loading,
      clearStoreSessionHandler,
      hasStoreCookie,
      currentStep,
      activeComponent,
      CheckoutContext,
      isCoreRedesignEnabled,
      isSimplifiedEnrollmentEnabled,
      checkoutOrderSummaryUpdated,
      enableLocationConfirmation,
      checkoutStickyCtas,
    };
  },
  // TODO: GLOBAL15-61059 remove after redesign core
  head() {
    return {
      bodyAttrs: {
        class: this.isCoreRedesignEnabled ? 'redesign-core' : undefined,
      },
    };
  },
});
