import { CMTeaser, CmsSite } from '@vf/api-contract';
import { getTranslation, generateLinkFromTeaserTargets } from './utils';
import { ComposableContext } from '../types';

export const maTitleHeader = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'account/MyAccountHeading',
    props: {
      translations: {
        headingLabel: getTranslation(data, 'title', '$My Account$'),
        signOutLabel: getTranslation(data, 'signOutLabel', '$Sign Out$'),
        notUserLabel: getTranslation(data, 'notUserLabel', '$Not {0} $'),
        signOutLink: generateLinkFromTeaserTargets(
          data.teaserTargets,
          context,
          siteConfiguration,
          data.urlParams,
          cmsBaseUri
        ),
      },
    },
  };
};
