import { useRequestTracker, useUrl } from '@vf/composables';
import useModalHandlers from '@vf/ui/composables/useModalHandlers';
import useAsyncScrollTo from '@vf/ui/composables/useAsyncScrollTo';
import usePrefetch from '@vf/ui/composables/usePrefetch';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

export default function useUIContextProvider(): void {
  const { root } = useRootInstance();

  const { openModal } = useModal();

  const { onAllDone } = useRequestTracker(root);

  const { provideModalHandlers } = useModalHandlers();
  const { provideCallbacks } = useAsyncScrollTo();
  const { providePrefetchConfig } = usePrefetch();

  provideModalHandlers({
    openModal,
  });

  provideCallbacks({
    onAllDone,
  });

  const { prefetchUrl } = useUrl(root);

  providePrefetchConfig({
    getPrefetchConfig: () => root.context?.$config?.prefetch,
    prefetchUrl,
  });
}
