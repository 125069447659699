import { Plugin } from '@nuxt/types';
import { getEnvValueByLocale } from '@vf/env';

const CurrentLocaleBasedConfig: Plugin = (context, inject) => {
  function getEnvValueByCurrentLocale<T>(
    envVar: string
  ): T | string | undefined {
    // Get current locale from the context
    const locale = context.app.i18n.locale;
    return getEnvValueByLocale<T>(envVar, locale, context.$env);
  }

  function getConfigValueByCurrentLocale<T>(configKey: string) {
    // Get current locale from the context
    const locale = context.app.i18n.locale;
    const configVar = context.$config[configKey];
    if (!configVar) return;
    if (locale && configVar[locale] !== undefined)
      return configVar[locale] as T;
    if (locale && configVar.default !== undefined)
      return configVar.default as T;
    return configVar as T;
  }

  inject('getEnvValueByCurrentLocale', getEnvValueByCurrentLocale);
  inject('getConfigValueByCurrentLocale', getConfigValueByCurrentLocale);
};

export default CurrentLocaleBasedConfig;
