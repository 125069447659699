import { ScriptPropertyJson } from 'vue-meta/types/vue-meta';
import { toDate } from 'date-fns';
import { ComponentInstance } from '../types';
import useProduct from '../useProduct';
import useUrl from '../useUrl';
import { useI18n } from '../useI18n';
import useCms from '../useCms';
import { PageTypeName } from '../useCms/types';
import { apiClientFactory, FaqItem, Product } from '@vf/api-client';
import useReviews from '../useReviews';
import { computed, ComputedRef } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import { useCmsRefStore } from '../store/cmsRef';

const useJsonLinkedData = (
  instance: ComponentInstance,
  contextKey?: string
) => {
  const cmsRefStore = useCmsRefStore();
  const { product, variantsData } = useProduct(instance, contextKey);
  const { getProductUrl, getProductCanonicalUrl } = useUrl(instance);
  const { meta } = useCms(instance, contextKey);
  const { defaultCurrency } = useI18n(instance);
  const { reviews } = useReviews(instance);
  const { generateProductIdScene7 } = apiClientFactory(instance);

  const { pageTypeName, breadcrumbs, cmsSiteConfiguration } = storeToRefs(
    cmsRefStore
  );

  const getProductImagesSchema = (
    product: Product,
    settings: { baseHeight: number; aspectRatios: string[] }
  ) => {
    if (!settings.baseHeight || !settings.aspectRatios.length) {
      return [];
    }

    return settings.aspectRatios
      .map((aspectRatio) => {
        const [widthRatio, heightRatio] = aspectRatio
          .split(':')
          .map((val) => parseInt(val));
        if (!widthRatio || !heightRatio) {
          return null;
        }

        const ratio = widthRatio / heightRatio;
        const newWidth = Math.floor(settings.baseHeight * ratio);

        return {
          '@type': 'ImageObject',
          url: instance.$root.$mediaUrlGenerator({
            colorName: product.color?.label,
            pid: product.isCustoms
              ? product.id
              : generateProductIdScene7(product, product.color?.value),
            productName: product.name,
            shotType: instance.$env.IMAGES_SHOTTYPE,
            preset: `hei=${settings.baseHeight}&wid=${newWidth}&qlt=95`,
          }),
          caption: product.name,
          width: {
            '@type': 'QuantitativeValue',
            value: newWidth,
            unitText: 'pixel',
            unitCode: 'E37',
          },
          height: {
            '@type': 'QuantitativeValue',
            value: settings.baseHeight,
            unitText: 'pixel',
            unitCode: 'E37',
          },
        };
      })
      .filter(Boolean);
  };

  const mapReviewToLdJson = (review) => ({
    '@type': 'Review',
    reviewRating: {
      '@type': 'Rating',
      ratingValue: review.metrics.rating,
    },
    author: {
      '@type': 'Person',
      name: review.details.nickname,
    },
    reviewBody: review.details.comments,
    dateCreated: toDate(review.details.createdDate).toString(),
    dateModified: toDate(review.details.updatedDate).toString(),
    headline: review.details.headline,
    text: review.details.comments,
    image: review.media?.[0],
  });

  const pdpSchema = computed(() => {
    if (!product.value) return null;

    return {
      type: 'application/ld+json',
      'data-json-ld': 'product',
      json: {
        '@context': 'https://schema.org',
        '@type': 'Product',
        ...(product.value.rating.numReviews > 0
          ? {
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: product.value.rating.score,
                reviewCount: product.value.rating.numReviews,
              },
            }
          : {}),
        id: getProductCanonicalUrl(product.value),
        name: product.value.name,
        image: getProductImagesSchema(product.value, {
          baseHeight: parseInt(instance.$env.SEO_JSONLD_PDP_IMAGES_BASE_HEIGHT),
          aspectRatios: (
            instance.$env.SEO_JSONLD_PDP_IMAGES_ASPECT_RATIOS || ''
          ).split(','),
        }),
        description: product.value.description,
        sku: product.value.sku,
        brand: {
          '@type': 'Brand',
          name: cmsSiteConfiguration.value?.name,
        },
        audience: {
          '@type': 'PeopleAudience',
          suggestedGender: product.value.gender,
        },
        category: product.value.productTypeDepartment,
        mpn: product.value.id,
        review: reviews.value.map(mapReviewToLdJson),
        offers: {
          '@type': 'Offer',
          url: getProductUrl(product.value),
          priceCurrency: defaultCurrency.value,
          price: product.value.variant?.price?.current?.toFixed(2) ?? '',
          availability: `https://schema.org/${
            variantsData.value.areAllVariantsOutOfStock
              ? 'OutOfStock'
              : 'InStock'
          }`,
        },
      },
    };
  });

  const getPageJsonLinkedData = (): ComputedRef<ScriptPropertyJson> => {
    switch (pageTypeName.value) {
      case PageTypeName.PDP:
        return pdpSchema;
      case PageTypeName.NEWS_ARTICLE:
      case PageTypeName.EVENT:
      case PageTypeName.FAQ:
        return computed(() => meta.value[pageTypeName.value]);
      default:
        return null;
    }
  };

  const getPageBreadcrumbJsonLinkedData = (path) => {
    const jsonld = transformBreadcrumbsDataToJsonld(breadcrumbs.value);

    if (jsonld) {
      const lastEntryType =
        breadcrumbs.value[breadcrumbs.value.length - 1]?.type;
      const isPdp = pageTypeName.value === PageTypeName.PDP;
      const baseUrl = 'https://' + instance.$getDomainName();

      const isLastElementProduct =
        lastEntryType === 'product' || lastEntryType === 'sku';

      if (isPdp && !isLastElementProduct) {
        const elements = jsonld.itemListElement;
        jsonld.itemListElement = [
          ...elements,
          {
            '@type': 'ListItem',
            position: elements.length + 1,
            name: product.value?.name,
            item: `${baseUrl}${path}`,
          },
        ];
      }

      return {
        type: 'application/ld+json',
        'data-json-ld': 'breadcrumbs',
        json: jsonld,
      };
    }
  };

  const transformBreadcrumbsDataToJsonld = (breadcrumbsData) => {
    if (!breadcrumbsData.length) return null;

    const baseUrl = 'https://' + instance.$getDomainName();

    const listElements = breadcrumbsData.map((breadcrumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb.text,
        item: `${baseUrl}${breadcrumb.link}`,
      };
    });

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: listElements,
    };
  };

  const transformFaqDataToJsonld = (data: FaqItem[]) => {
    const mappedQuestions = data.map((item) => {
      return {
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item.answer,
        },
      };
    });
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: mappedQuestions,
    };
  };

  const getPageWebsiteJsonLinkedData = () => {
    const domainName = instance.$getDomainName();
    return {
      type: 'application/ld+json',
      'data-json-ld': 'website',
      json: {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: instance.$env.WEBSITE_NAME,
        url: `https://${domainName}`,
        potentialAction: {
          '@type': 'SearchAction',
          target: `https://${domainName}/${instance.$i18n.locale}/search/product?q={search_term_string}`,
          'query-input': 'required name=search_term_string',
        },
      },
    };
  };

  return {
    getPageBreadcrumbJsonLinkedData,
    getPageJsonLinkedData,
    getPageWebsiteJsonLinkedData,
    transformBreadcrumbsDataToJsonld,
    transformFaqDataToJsonld,
  };
};

export default useJsonLinkedData;
