import { CMCollection, CmsSite } from '@vf/api-contract';
import { camelCase } from '../dataTransformators/utils/camelCase';
import { getStateManagementData } from './utils';
import {
  ComposableContext,
  PageTypeName,
  CmsSanitizationRequirements,
} from '../types';
import * as mappings from './index';

export const plpFiltersAndGrid = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => {
  const childComponents = data.teasableItems.map((item) => {
    if (!item.viewtype) {
      return;
    }
    const mappingMethod = mappings[camelCase(item.viewtype)];
    const mapping = mappingMethod(
      item,
      context,
      siteConfiguration,
      cmsBaseUri,
      null,
      pageTypeName
    );

    return {
      ...mapping,
      props: {
        ...mapping.props,
        _states: getStateManagementData(item),
      },
      slot:
        item.viewtype === 'plp-grid' ||
        item.viewtype === 'search-results-products'
          ? 'right'
          : null,
    };
  });

  return {
    component: 'plp/CategoryFiltersAndGridWrapper',
    children: childComponents,
  };
};
