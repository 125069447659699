import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
} from '../types';
import { CmsSite, CMTeaser } from '@vf/api-contract';
import { extractProperty } from './utils';

export const pinnedArticle = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  let articlePageId = '';
  let component = '';
  if (data.teaserTargets?.length) {
    // If an article is attached as teaser target, extract Article Page ID
    articlePageId = extractProperty(data, `teaserTargets[0].target.id`, '');
    component = 'pinnedArticle/PinnedArticleById';
  } else {
    // If no teaser target is set, fetch latest article based on tags
    requirements.push({
      name: CmsSanitizationRequirementName.SORTED_ARTICLES,
      filters: extractProperty(data, 'subjectTaxonomy', []),
      searchTerm: '',
      order: '',
    });
    component = 'pinnedArticle/PinnedArticleByTags';
  }

  return {
    component,

    props: {
      articlePageId,
    },
  };
};
