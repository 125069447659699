import { CmsSite, CMCollection } from '@vf/api-contract';
import { extractProperty } from './utils';
import { ComposableContext } from '../types';
import { heroItem } from './heroItem';

export const hero = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { noCrop } = getConfigValues(data);

  return {
    component: 'shared/Hero',
    children: data.teasableItems.map((item) => {
      return heroItem(item, context, siteConfiguration, cmsBaseUri, { noCrop });
    }),
    props: {
      width: extractProperty(data.teasableItems[0].pictures[0], 'width'),
      height: extractProperty(data.teasableItems[0].pictures[0], 'height'),
    },
  };
};

const getConfigValues = (data: CMCollection) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'hero-component-properties'
    );

    return config.values;
  } catch (e) {
    console.error('Hero component error: no config found.');
  }

  return {
    noCrop: false,
  };
};
