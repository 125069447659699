import { CategoryProduct, Product } from '@vf/api-client';
import type { Ref } from '@vue/composition-api';
import { EventPropsHandler } from '.';
import { Filter } from '../../types';
import {
  GtmImpressionProductObject,
  ProductImpressionsEvent,
} from '../../types/gtm';
import useCategory, { SortOption } from '../../useCategory';
import useSearch from '../../useSearch';
import useGtm from '../../useGtm';
import { useGridConfig } from '../../useGridConfig';

import {
  getCustomsCategory,
  getColorInfoOrDefault,
  recipeID,
  updateItemInProductImpressionsObject,
  getDiscountTypesByPrice,
  getStyleCode,
  getVirtualStyleCode,
  createProductGroups,
} from './helpers';
import { useI18n } from '../../useI18n';
interface ProductImpressionsParams {
  productIds: string[];
}

export const productImpressionsHandler: EventPropsHandler<
  ProductImpressionsEvent[],
  ProductImpressionsParams
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);
  const categoryContext = eventProps.composablesContexts?.useCategory;
  const {
    fetchedProducts,
    products,
    selectedFilters,
    sortingOptions,
    selectedSortingOptionId,
  }: {
    fetchedProducts: Ref<(Product | CategoryProduct)[]>;
    products: Ref<(Product | CategoryProduct)[]>;
    selectedFilters: Ref<Filter[]>;
    sortingOptions: Ref<SortOption[]>;
    selectedSortingOptionId: Ref<string>;
  } = categoryContext
    ? useCategory(vmInstance, categoryContext)
    : useSearch(vmInstance);
  const { updatePersistentVariables, gtmPersistentVariables } = useGtm(
    vmInstance
  );
  const { currentConfig } = useGridConfig(vmInstance);

  let list;
  if (categoryContext) {
    list = `Grid: PLP | ${gtmPersistentVariables.value.category}`;
  } else {
    list = `Search: PLP | ${gtmPersistentVariables.value.searchTerm}`;
  }

  const persistentVariables = (product) => {
    return updatePersistentVariables(product.id, {
      category: getCustomsCategory(
        product,
        gtmPersistentVariables.value.category
      ),
    });
  };

  const getBadge = (product: CategoryProduct, $themeConfig: any): string => {
    if (
      $themeConfig.productCard?.showBadgesByColorSwatch &&
      'color_swatches' in product
    ) {
      const defaultColor =
        product.color_swatches.find((color) => color.defaultColor) ||
        product.color_swatches[0];
      return defaultColor?.labels;
    }
    return product.labels?.[0];
  };

  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();

  const mapProductToProductObject = (
    product: CategoryProduct,
    position: number
  ): GtmImpressionProductObject => {
    const { colorCode, colorDescription } = getColorInfoOrDefault(product);
    const styleCode = getStyleCode(product, vmInstance.$themeConfig);

    return {
      ...persistentVariables(product),
      availSizes: [], // TODO: not available on PLP,
      avgRating: product.rating.score,
      badge: getBadge(product, vmInstance.$themeConfig),
      brand,
      bundleId: undefined,
      catalogCategory: product.primary_category_name,
      colorCode,
      colorDescription,
      customized: !!recipeID(product),
      discountType: getDiscountTypesByPrice(product) || undefined,
      id: vmInstance.$themeConfig.gtm?.useColorOnId
        ? product.id + colorCode
        : product.id,
      list,
      masterId: product.pid,
      merchantProductId: product.merchantProductId,
      name: product.name?.trim(),
      numReviews: product.rating.numReviews,
      onSale: product.price.original !== product.price.current,
      originalPrice: product.price.original,
      position,
      preCreatedCustomCode: product.dummyCustoms ? styleCode : undefined,
      price: product.price.current,
      recipeID: recipeID(product) || undefined,
      saleDiscountAmount: product.price.discount || 0,
      season: product.season || undefined,
      styleCode,
      virtualStyleCode: getVirtualStyleCode(product.colorBundleCode),
    };
  };

  const getProductImpressionsObject = (
    productIds?: string[]
  ): GtmImpressionProductObject[] => {
    let productImpressionsData: GtmImpressionProductObject[] = [];

    const filteredProducts = fetchedProducts.value.filter(
      (product) => !productIds?.length || productIds?.includes(product.id)
    );
    const positionOffset = products.value.length - fetchedProducts.value.length;
    filteredProducts.forEach((product, index) => {
      const productPosition = positionOffset + index + 1;
      const storedProduct = productImpressionsData.find(
        (prod) => prod.id === product.id
      );
      if (storedProduct) {
        productImpressionsData = updateItemInProductImpressionsObject(
          product.id,
          storedProduct,
          productPosition,
          productImpressionsData
        );
      } else {
        const mappedProduct = mapProductToProductObject(
          product as CategoryProduct,
          productPosition
        );
        productImpressionsData.push(mappedProduct);
      }
    });
    return productImpressionsData;
  };

  const getGtmProductLayout = () => {
    const numColumn = 12 / currentConfig.value.grid[vmInstance.$viewport.size];
    return `${numColumn} column`;
  };

  // group By filter code
  const getGtmFilters = () => {
    const groupByCode = {};
    selectedFilters.value.forEach((filter) => {
      groupByCode[filter.code] = groupByCode[filter.code]
        ? groupByCode[filter.code].concat(filter.value)
        : [filter.value];
    });
    return Object.keys(groupByCode).map((code) => ({
      category: code,
      values: groupByCode[code].sort((a: string, b: string) =>
        a.toLowerCase() <= b.toLowerCase() ? -1 : 1
      ),
    }));
  };

  const getGtmPayload = (productIds = []): ProductImpressionsEvent => ({
    event: 'productImpressions',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Impressions',
    eventLabel: undefined,
    nonInteractive: true,
    ecommerce: {
      currencyCode:
        (fetchedProducts.value[0] as CategoryProduct)?.price?.currency ||
        defaultCurrency.value,
      impressions: getProductImpressionsObject(productIds),
    },
    customMetrics: {},
    customVariables: {
      productLayout: getGtmProductLayout(),
      sortOption: sortingOptions.value.find(
        (opt) => opt.value === selectedSortingOptionId.value
      )?.label,
      filters: getGtmFilters(),
    },
    _clear: true,
  });

  const productIds = eventProps.overrideAttributes?.productIds;
  return productIds?.length
    ? createProductGroups(productIds).map(getGtmPayload)
    : [getGtmPayload()];
};
