






















































import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { PropType, defineComponent, inject } from '@vue/composition-api';
import { RatingTranslations } from '@vf/api-contract';

const TOTAL_STARS = 5;

export default defineComponent({
  name: 'VfRating',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    score: {
      type: Number,
      default: 1,
    },
    reviews: {
      type: Number,
      default: 0,
    },
    hasIndicator: {
      type: Boolean,
      default: false,
    },
    starSize: {
      type: Number,
      default: 14,
    },
    translations: {
      type: Object as PropType<RatingTranslations>,
      default: () => ({
        reviewSingular: '({{count}})',
        reviewPlural: '({{count}})',
        ariaLabel: '{{score}} out of {{total}} stars with {{reviews}} reviews',
      }),
    },
    showAriaLabel: {
      type: Boolean,
      default: true,
    },
    showZeroReviews: {
      type: Boolean,
      default: false,
    },
    ratingLink: {
      type: String,
      default: '',
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  computed: {
    showNoReviewsText() {
      return (
        (this.$themeConfig.rating?.showNoReviewsText || false) && !this.reviews
      );
    },
    formattedNumber() {
      const { reviewSingular, reviewPlural } = this.$props.translations,
        translation = this.$props.reviews === 1 ? reviewSingular : reviewPlural;

      return translation.replace('{{count}}', this.$props.reviews + '');
    },
    ariaLabel() {
      if (!this.showAriaLabel) return;
      return this.score
        ? this.translations.ariaLabel
            .replace('{{score}}', this.score)
            .replace('{{total}}', TOTAL_STARS)
            .replace('{{reviews}}', this.reviews)
        : this.translations.noReviews;
    },
    activeStarsWidth() {
      return 'width: ' + this.$props.score * this.$props.starSize + 'px;';
    },
    starPoints() {
      let centerX = this.$props.starSize / 2;
      let centerY = this.$props.starSize / 2;

      let innerCircleArms = 5;

      let innerRadius = this.$props.starSize / innerCircleArms;
      let innerOuterRadiusRatio = 2.5;
      let outerRadius = innerRadius * innerOuterRadiusRatio;

      let angle = Math.PI / innerCircleArms;
      let angleOffsetToCenterStar = 60;

      let totalArms = innerCircleArms * 2;
      let points = '';
      for (let i = 0; i < totalArms; i++) {
        let isEvenIndex = i % 2 == 0;
        let r = isEvenIndex ? outerRadius : innerRadius;
        let currX = centerX + Math.cos(i * angle + angleOffsetToCenterStar) * r;
        let currY = centerY + Math.sin(i * angle + angleOffsetToCenterStar) * r;
        points += currX + ',' + currY + ' ';
      }
      return points;
    },
    totalStars() {
      return TOTAL_STARS;
    },
  },
});
