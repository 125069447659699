import { defineStore } from 'pinia';
import { getCurrentInstance } from '@vue/composition-api';

import { Address } from '@vf/api-client/src/types';

import ss from '../utils/sessionStorage';
import ls from '../utils/localStorage';
import { AddressValidationResponse } from '@vf/api-client/src/api-types';
import { ComponentInstance, useUtilities } from '@vf/composables';
import { useAddressesStore } from './addresses';

export const setInitState = (storeState) => {
  storeState.AVSdone = !!ss.getItem('AVSdone');
  storeState.AVSskip = !!JSON.parse(ls.getItem('AVSskip'));
  storeState.AVSoldAddress = JSON.parse(ss.getItem('AVSoldAddress'));
};

export const useAddressValidationServiceStore = defineStore(
  'addressValidationService',
  {
    state: () => {
      return {
        AVSchanged: false,
        AVSisAddressInvalid: false,
        AVSdone: false,
        AVSskip: false,
        AVSoldAddress: {} as Address,
      };
    },
    actions: {
      isAVSRejected(avs: AddressValidationResponse): boolean {
        /**
         * Exception workaround to prevent taking back to shipping step for specific error case
         * GLOBAL15-18300, GLOBAL15-18303, GLOBAL15-29792
         */
        const isException =
          avs.serviceResponse.decision === 'ERROR' &&
          [
            avs.serviceResponse.reasonCode,
            avs.serviceResponse.davRasonCode,
          ].includes('150');

        return (
          avs.serviceResponse.decision === 'REJECT' || isException || avs.error
        );
      },
      setAVSSkip(value: boolean) {
        this.AVSskip = value;
        ls.setItem('AVSskip', value);
      },
      confirmAddressAndClearAVSStorage() {
        ss.setItem('AVSdone', 'true');
        ss.removeItem('AVSoldAddress');
      },

      async AVSrestoreAddress() {
        const currentInstance = getCurrentInstance();
        const localInstance = currentInstance?.proxy;
        const addressesStore = useAddressesStore(localInstance);
        const { getStateCode } = useUtilities(
          localInstance as ComponentInstance
        );
        try {
          const {
            addressLine1,
            addressLine2,
            city,
            postalCode,
            province,
            country,
          } = this.AVSoldAddress;

          const stateCode = await getStateCode(province, country);

          addressesStore.shippingAddress = {
            ...addressesStore.shippingAddress,
            ...{
              addressLine1,
              addressLine2: addressLine2 || '',
              city,
              postalCode,
              province: stateCode,
            },
          };
        } catch (err) {
          console.error(err);
        }
      },
    },
    hydrate(storeState) {
      setInitState(storeState);
    },
  }
);
