import { InitializedApiClient } from '../types';

export const getHeaderAndFooterData = async (
  id: string,
  apiClient: InitializedApiClient
) => {
  try {
    const response = await apiClient.getHeaderAndFooter(id);

    return {
      content: response,
      errors: [],
    };
  } catch (e) {
    return {
      content: null,
      errors: [`HTTP Error while trying to get Menu data: ${e.message}`],
    };
  }
};
