import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const deviceFingerPrint = (configuration: ApiClientConfiguration) => (
  sessionID: string
): Promise<AxiosResponse<{ data: Record<string, never> }>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).cart.deviceFingerPrint, {
      config: configuration,
    }),
    { sessionID },
    {
      headers: headerBuilder(configuration),
    }
  );
};
