import Vue, { PluginObject } from 'vue';

const IFRAME_SIGNALER_TYPE = 'the_parent';

export default function ({
  $eventBus,
}: {
  $eventBus: Vue;
}): PluginObject<never> {
  window.addEventListener(
    'message',
    function (event) {
      const {
        origin,
        source,
        data: { type, command, body },
      } = event;

      if (!command || !type || type === IFRAME_SIGNALER_TYPE) {
        return;
      }
      $eventBus.$emit(command, { origin, source, body });
    },
    false
  );

  return {
    install(Vue) {
      Vue.prototype.$vfIframeCommunicator = {
        postMessage(data = null) {
          const element = document.querySelector(
            'iframe[isCustomsIframe="true"]'
          ) as HTMLIFrameElement;

          if (!element) {
            console.error(
              `iframeCommunicator: Element to send message was not found by target provided.`
            );
            return;
          }
          if (!data) {
            console.error(
              `iframeCommunicator: data to send message was not defined.`
            );
            return;
          }

          element.contentWindow.postMessage(
            { type: IFRAME_SIGNALER_TYPE, ...data },
            '*'
          );
        },
      };
    },
  };
}
