import { ValidationSteps } from '@vf/shared/src/utils/helpers';

export const mapPasswordValidationSteps = (
  steps: Record<string, string>
): ValidationSteps => ({
  length: steps.characters,
  uppercase: steps.uppercaseLetter,
  number: steps.number,
  lowercase: steps.lowercaseLetter,
});
