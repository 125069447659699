import { EventPropsHandler } from './index';
import { PromotionClickEvent } from '../../types/gtm';

export const internalPromotionClickHandler: EventPropsHandler<
  PromotionClickEvent,
  {
    id: string;
    name?: string;
    creative?: string;
    position?: string;
  }
> = (eventProps) => {
  const internalPromotion = {
    id: eventProps.overrideAttributes.id,
    name: eventProps.overrideAttributes.name,
    creative: eventProps.overrideAttributes.creative,
    position: eventProps.overrideAttributes.position,
  };
  return {
    event: 'promotionClick',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Promotion Click',
    eventLabel: undefined,
    nonInteractive: false,
    ecommerce: {
      promoClick: {
        promotions: [internalPromotion],
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
