import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

type DeletePaymentRequestObject = {
  cartId: string;
  payment_instrument_id: string;
};

export const deleteCartPaymentInstruments = (
  configuration: ApiClientConfiguration
) => async (data: DeletePaymentRequestObject): Promise<any> => {
  const response = await axios.delete(
    urlBuilder(endpoints.get(configuration).cart.deletePaymentInstruments, {
      config: configuration,
      mapping: { id: data.cartId },
    }),
    {
      data: {
        payment_instrument_id: data.payment_instrument_id,
      },
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
