import { scrollTo as scrollToTop } from './scrollTo';

export function scrollToFirstError(): void {
  const ERROR_SELECTORS =
    '.vf-input--invalid, .vf-select--is-invalid, .vf-notification--invalid, .credit-card-form-error';
  let topMargin;

  const errors = document.querySelectorAll<HTMLElement>(ERROR_SELECTORS);
  // no error elements found, nothing to do here
  if (!errors.length) return;

  const firstError = errors[0];
  // is in a modal?
  const modal = firstError.closest('.vf-modal__content');
  if (modal) {
    // we are in a modal
    scrollToTop({ target: modal, top: firstError.offsetTop - 30 });
  } else {
    // not in a modal
    const headerElement = document.querySelector<HTMLElement>(
      '.vf-header__container'
    );
    const checkoutHeaderElement = document.querySelector<HTMLElement>(
      '.vf-header'
    );
    const offsetHt = headerElement ? headerElement.offsetHeight : 0;
    const checkoutOffsetHt = checkoutHeaderElement
      ? checkoutHeaderElement.offsetHeight
      : 0;
    topMargin = headerElement ? offsetHt : checkoutOffsetHt;
    scrollToTop({ top: errors[0].offsetTop - (topMargin + 30) });
  }

  // A11Y: set focus on the first error's input
  errors[0].querySelector<HTMLElement>('input, textarea, select')?.focus();
}
