import { extractProperty, getTranslation } from './utils';
import { CMTeaser } from '@vf/api-contract';

export const pdpSizeAndFit = (data: CMTeaser) => {
  return {
    component: 'pdp/PdpSizeAndFit',
    props: {
      translations: {
        sizeFitSubBeginning: getTranslation(
          data,
          'sizeFitSubBeginning',
          '$Fit based on-$'
        ),
        sizeFitSubEnding: getTranslation(
          data,
          'sizeFitSubEnding',
          '$end label$'
        ),
        title: extractProperty(data, 'teaserTitle', '$Size and Fit$'),
        tooSmall: getTranslation(data, 'tooSmall', '$tooSmall$'),
        tooBig: getTranslation(data, 'tooBig', '$tooBig$'),
      },
    },
  };
};
