import {
  extractProperty,
  getImageObject,
  getMediaSeoAttributes,
  decorateHtmlValue,
  generateLinkFromTeaserTargets,
  getTranslation,
  extractProductSkus,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
} from './utils';
import { CMTeaser, CmsSite, CMPictureCropType } from '@vf/api-contract';
import {
  ComposableContext,
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
} from '../types';
export const customsHpSlide = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const productSkus = extractProductSkus(data, '');
  requirements.push({
    name: CmsSanitizationRequirementName.PRODUCT_CONTEXT,
    productSkus,
  });
  const targetCta = extractProperty(data, 'teaserTargets[0].target');
  const useRichText = extractProperty(data, 'useRichText', false);
  const pictures = getPictures(data, siteConfiguration, cmsBaseUri);

  return {
    component: 'customs/CustomsHpSlide',
    props: {
      banner: extractProperty(pictures, 'imagesObject[0]', {}),
      productImages: pictures?.imagesObject?.slice(1) || [],
      text: useRichText
        ? decorateHtmlValue(
            data.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : data.teaserText,
      buttonText:
        extractProperty(data, 'teaserTargets[0].callToActionText') ||
        extractProperty(targetCta, 'teaserTitle'),
      isCustomsCta: extractProperty(targetCta, 'viewtype') === 'customs-cta',
      buttonLink: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
      productSkus,
      translations: {
        customizeCTA: getTranslation(data, 'customizeCTA', '$Customize$'),
        buyNowCTA: getTranslation(data, 'buyNowCTA', '$Buy now$'),
      },
      contextKey: context.contextKey,
      imageTarget: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'imageTarget')?.[0] || {},
        context,
        siteConfiguration,
        cmsBaseUri
      ),
    },
  };
};

const getPictures = (data, siteConfiguration, cmsBaseUri) => {
  if (data.pictures?.length > 1) {
    const imagesObject = data.pictures.map((picture, index) => {
      return {
        ...getImageObject(
          picture,
          siteConfiguration,
          CMPictureCropType.LANDSCAPE_RATIO_8x3,
          cmsBaseUri,
          data.responsiveMedia,
          index
        ),
        seo: getMediaSeoAttributes(picture),
        alt: '',
        width: null,
        height: null,
      };
    });
    return {
      imagesObject,
    };
  }
};
