import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { RemoveSaveForLaterResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const removeSaveForLater = (
  configuration: ApiClientConfiguration
) => (params: {
  consumerId: string;
  saveForLaterId: string;
  itemId: string;
}): Promise<AxiosResponse<RemoveSaveForLaterResponse>> => {
  const url = urlBuilder(endpoints.get(configuration).cart.removeSaveForLater, {
    config: configuration,
    mapping: {
      saveForLaterId: params.saveForLaterId,
      itemId: params.itemId,
      consumerId: params.consumerId,
    },
  });
  return axios.delete(url, {
    data: null,
    headers: headerBuilder(configuration),
  });
};
