import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
} from '../types';
import {
  generateLinkFromTeaserTargets,
  getDynamicField,
  getStateManagementData,
  getSegmentsData,
} from './utils';

export const stateRedirect = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(data)
  );
  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(data)
  );

  const redirectUrl = generateLinkFromTeaserTargets(
    data.teaserTargets,
    context,
    siteConfiguration,
    data.urlParams,
    cmsBaseUri
  );

  const redirectBack = getDynamicField(data, 'redirectBack', false);
  const notification = getDynamicField(data, 'notification');

  if (stateFulfilled && segmentsFulfilled) {
    requirements.push({
      name: CmsSanitizationRequirementName.REDIRECT,
      to: redirectUrl,
      options: {
        redirectBack,
        notification,
      },
    });
  }

  return {
    component: 'shared/Redirect',
    uniqueKey: 'redirect-component',
  };
};
