import { enCA, frCA, enUS } from 'date-fns/locale';
import { mapLocale } from '@vf/env';

const localesMap = {
  enCA,
  frCA,
  enUS,
};

export const getDateFnsLocale = (
  locale: string,
  mapping: string
): Locale | undefined => {
  const normalized = mapLocale(locale, mapping).replace('_', '');

  if (localesMap[normalized]) {
    return localesMap[normalized];
  }

  console.error(`Couldn't find a locale with the name: ${locale}`);
};
