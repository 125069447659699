import { EventPropsHandler } from '.';
import { VirtualPageViewEvent } from '../../types/gtm';
import { santizeEmailAddresses } from './helpers';

export const virtualPageViewHandler: EventPropsHandler<
  VirtualPageViewEvent,
  {
    pageUrl?: string;
    routeName?: string;
  }
> = (eventProps, vmInstance) => {
  return {
    event: 'virtualPageView',
    pageType: 'PageView',
    pageUrl:
      santizeEmailAddresses(eventProps.overrideAttributes.pageUrl) ||
      santizeEmailAddresses(vmInstance.$route.fullPath),
    routeName:
      eventProps.overrideAttributes.routeName || vmInstance.$route.name,
  };
};
