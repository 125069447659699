import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { ReturnDetailsObject } from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const getReturnDetails = (configuration: ApiClientConfiguration) => (
  orderHeaderKey: string,
  data: any,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<ReturnDetailsObject>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.getReturnDetails,
    {
      config: configuration,
      mapping: { orderHeaderKey },
    }
  );

  return axios.post(
    url,
    {
      pageSize: data.pageSize,
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
