import { Ref, ref } from '@vue/composition-api';
import ss from '../../../utils/sessionStorage';
import ls from '../../../utils/localStorage';
import initStorage from '../../../utils/storage';
import { errorMessages } from '../../../utils/errorMessages';
import { UseCartStorage } from '../../types';
import { ComposablesStorage } from '../../../types';
import { apiClientFactory } from '@vf/api-client';
import { isBasketNotFoundError } from '../../helpers';
import { NotAddedItem } from '@vf/api-client/src/types';

const LS_CART_ID_KEY = 'cartId';

export const useSharedCart = (
  instance,
  { createNewCart, resetCart, updateCartItems, cartItems },
  contextKey
) => {
  const { displayErrorMessages } = errorMessages(instance);
  const {
    applyShared: applySharedAPI,
    shareCart: shareCartAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const cartId: Ref<string> = storage.getOrSave(
    'cartId',
    ref(ls.getItem(LS_CART_ID_KEY) || '')
  );

  const notAddedItems: Ref<NotAddedItem[]> = storage.getOrSave(
    'notAddedItems',
    ref([])
  );

  const getShareLink = async (consumerId: string) => {
    const products = cartItems.value.map((element) => {
      const productObject: { [k: string]: any } = {
        pdpUrl: element.pdpUrl,
        productId: element.productId,
        productImageUrl: element.productImageURL,
        qty: element.qty,
        sku: element.productId,
      };
      if (element.recipeId) productObject.recipeId = element.recipeId;
      return productObject;
    });

    return shareCartAPI({
      products,
      consumerId,
    });
  };

  const showExpiredSharedCartError = () => {
    const shareCartErrorMessage = ss.getItem('shareCartExpiredError');
    if (shareCartErrorMessage) {
      displayErrorMessages(JSON.parse(shareCartErrorMessage));
      ss.removeItem('shareCartExpiredError');
    }
  };

  const clearNotAddedItems = () => {
    notAddedItems.value = [];
  };

  const applyShared = async (scid: string, customerId: string) => {
    try {
      if (!cartId.value)
        await createNewCart({ isBackgroundRequest: false, isTemporary: false });
      const applySharedResponse = await applySharedAPI({
        sharedCartId: scid,
        cartId: cartId.value,
        customerId,
      });

      if ([200, 201].includes(applySharedResponse.status)) {
        await updateCartItems(applySharedResponse.data);
        notAddedItems.value = applySharedResponse.data.notAddedItems || [];
      }
    } catch (err) {
      if (isBasketNotFoundError(err.response?.data)) {
        resetCart();
        return applyShared(scid, customerId);
      } else {
        ss.setItem('shareCartExpiredError', JSON.stringify(err));
      }
    }
  };

  return {
    getShareLink,
    showExpiredSharedCartError,
    applyShared,
    notAddedItems,
    clearNotAddedItems,
  };
};
