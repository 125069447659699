import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { SignInRequest, SignInResponse } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const signIn = (configuration: ApiClientConfiguration) => (
  data: SignInRequest
): Promise<AxiosResponse<SignInResponse>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).authentication.signIn, {
      config: configuration,
    }),
    {
      recaptcha_response: data.recaptchaToken,
      codeChallenge: data.codeChallenge,
      username: data.username,
      password: data.password,
      type: 'Registered',
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': data.usid,
      }),
    }
  );
};
