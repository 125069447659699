import { computed, ComputedRef, ssrRef } from '@nuxtjs/composition-api';

type UseLoaderReturn = {
  showSpinner: () => void;
  hideSpinner: () => void;
  isSpinnerVisible: ComputedRef<boolean>;
};

const isSpinnerVisible = ssrRef(false, 'useLoader-isSpinnerVisible');

const useLoader = (): UseLoaderReturn => {
  const showSpinner = () => {
    isSpinnerVisible.value = true;
  };
  const hideSpinner = () => {
    isSpinnerVisible.value = false;
  };

  return {
    showSpinner,
    hideSpinner,
    isSpinnerVisible: computed(() => isSpinnerVisible.value),
  };
};

export default useLoader;
