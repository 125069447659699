import { CMTeaser, CMChannel, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  mergeProperty,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
  getTranslation,
} from './utils';

export const loyaltyLogin = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const [forgotPasswordLink]: [CMChannel] = extractLocalSettingsExpandedLinks(
    data,
    'forgotPasswordLink'
  );

  return {
    component: 'loyalty/loyaltyLogin',
    props: {
      forgotPasswordLink: generateLinkFromTarget(
        forgotPasswordLink,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      translations: {
        heading: getTranslation(
          data,
          'greeting',
          '$Hello! Welcome to the family.$'
        ),
        subheading: getTranslation(
          data,
          'subtext',
          '$Help us get to know you better. You know, because family stays close.$'
        ),
        emailLabel: getTranslation(data, 'emailLabel', '$Email Address$'),
        passwordLabel: getTranslation(data, 'passwordLabel', '$Password$'),
        validationMessages: mergeProperty(
          {
            email: '$Please enter a valid email address.$',
            required:
              '$This field is required, please add your {{fieldName}}.$',
          },
          getTranslation(data, 'validationMessages', {})
        ),
        forgotPasswordButtonText: getTranslation(
          data,
          'forgotPasswordButtonText',
          '$Forgot password?$'
        ),
        signInButtonText: getTranslation(data, 'signInButtonText', '$Sign in$'),
        createAccountButtonLabel: getTranslation(
          data,
          'dontHaveAnAccountText',
          "$Don't have an account?"
        ),
        createAccountButtonText: getTranslation(
          data,
          'enrollNowButtonText',
          '$Enroll now$'
        ),
        loginError: getTranslation(
          data,
          'loginError',
          '$Either the login id or password entered is incorrect. Enter the information again.$'
        ),
      },
    },
  };
};
