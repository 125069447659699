import { ComposableContext } from '../types';
import { isClient } from '@vf/shared/src/utils/helpers';

export const getUrlQueryParam = (
  context: ComposableContext,
  param: string
): string | null => {
  try {
    const query = isClient
      ? context.instance.$router.currentRoute?.query
      : context.instance.context.route?.query || {};

    return (query[param] as string) || null;
  } catch (e) {
    console.error(
      `Error occurred query URL params getter. Param: ${param}, error:`,
      e
    );
    return null;
  }
};
