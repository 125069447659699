import { extractProperty, getTranslation } from './utils';
import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
} from '../types';
import { CmsSite, CMTeaser } from '@vf/api-contract';
import { getTeaserStyles } from './utils';
import { getGridConfigValues } from './utils/articleGridUtils';

export const articleGrid = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const configuration = getGridConfigValues(data, 'article-grid-settings');
  const styles = getTeaserStyles(data);

  requirements.push({
    name: CmsSanitizationRequirementName.SORTED_ARTICLES,
    filters: extractProperty(data, 'subjectTaxonomy', []),
    searchTerm: extractProperty(configuration, 'search-term', ''),
    order: extractProperty(configuration, 'search-order', ''),
  });

  return {
    component: 'shared/ArticleGrid',
    props: {
      title: data.teaserTitle,
      subtitle: data.teaserSubtitle,
      styles,
      showMoreButtonText: extractProperty(
        data,
        'teaserTargets[0].callToActionText',
        'Show more'
      ),
      defaultShowMoreButtonText: getTranslation(
        data,
        'viewMore',
        '$Show more$'
      ),
      defaultReadMoreButtonText: getTranslation(
        data,
        'readMore',
        '$Read More$'
      ),
      configuration,
      openInNewModal: extractProperty(data, 'openInNewModal'),
      openInNewTab: extractProperty(data, 'openInNewTab'),
    },
  };
};
