import { EventPropsHandler } from '.';
import { LoadEventDataEvent } from '../../types/gtm';

export const loadEventDataHandler: EventPropsHandler<
  LoadEventDataEvent,
  {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    customMetrics: object;
    customVariables: object;
    nonInteractive: boolean;
  }
> = (eventProps) => {
  return {
    event: 'loadEventData',
    eventCategory: eventProps.overrideAttributes.eventCategory || 'Customs',
    eventAction: eventProps.overrideAttributes.eventAction,
    eventLabel: eventProps.overrideAttributes.eventLabel,
    nonInteractive: eventProps.overrideAttributes.nonInteractive || false,
    customMetrics: eventProps.overrideAttributes.customMetrics || {},
    customVariables: eventProps.overrideAttributes.customVariables || {},
    _clear: true,
  };
};
