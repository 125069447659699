import { Ref } from '@vue/composition-api';
import { Product } from '@vf/api-client';
import { PageMetaData } from '@vf/api-client/src/types';
import { Filter } from '../types';
import {
  SearchProduct,
  GetSearchSuggestionsResponse,
} from 'packages/api-client/src/api-types';

export type GetSearchResultsSettings = {
  per?: number;
  page?: number;
  start?: number;
  sort?: string;
  filters?: Filter[];
  requestType?: string;
  loadAllPreviousPages?: boolean;
  appendFetchedProducts?: boolean;
  prependFetchedProducts?: boolean;
  fromSearchForm?: boolean;
};

export type SortOption = {
  value: string;
  label: string;
};

export type KeywordRedirect = {
  originalQuery: string;
  redirectedQuery: string;
  redirectedUrl: string;
};

export type SearchResultsStorage = {
  loading: boolean;
  queryString: string;
  queryParams: string;
  filteringOptions: Filter[];
  selectedFilters: Filter[];
  appliedFilters: Filter[];
  selectedSortingOptionId: string;
  selectedSortingChanged: boolean;
  sortingOptions: SortOption[];
  autoCorrectQuery: string;
  didYouMean: string[];
  isRedirected: boolean;
  isFilterSidebarOpen: boolean;
  isInfiniteScrolling: boolean;

  products: Product[];
  fetchedProducts: Product[];

  initialLoadProductsAmount: number;
  searchType: string;

  meta: PageMetaData;
  pagination: {
    total: number;
    per: number;
    page: number;
    previousPage: number;
    start: number;
    maxQuery: number;
  };
  keywordRedirect?: KeywordRedirect;
  lastQuery: any;
  requestType: string | null;
  pageHeaderData: {
    canonicalURL?: string;
    belowGrid?: string;
    aboveh1?: string;
    belowh1?: string;
  };
};

export type SearchSuggestions = {
  products: SearchProduct[];
  terms: {
    label: string;
  }[];
};

export type UseSearchResultsStorage = {
  searchRef: Ref<SearchResultsStorage>;
  searchSuggestions: Ref<GetSearchSuggestionsResponse>;
  pdoSearchRef: Ref<PdoSearch>;
};

export type PdoSearch = {
  searchTerm?: string;
  searchTermAdj?: string;
  searchType: string;
  searchResults: number;
};

export enum SearchType {
  STANDARD = 'Standard',
  AUTO_CORRECTION = 'Auto-correction',
  SUGGESTED_SEARCH = 'Suggested search results',
  NO_RESULTS = 'No results',
  REDIRECTED = 'Redirection',
  CLICK = 'click',
}
