import { Product } from '@vf/api-client';

export const generateProductIdScene7 = (
  product: Product,
  configuredColor = ''
): string => {
  if (product.id.length === 8) {
    if (configuredColor) {
      return `${product.id}_${configuredColor}`;
    }

    const firstColor = product.colors[0]?.value || 'missingColorValue;';
    return `${product.id}_${firstColor}`;
  } else {
    const masterIdLength: number = product.id.length - 3;
    const productIdSection: string = product.id.slice(0, masterIdLength);
    const productColorValue: string = product.id.slice(
      productIdSection.length,
      product.id.length
    );

    return `${productIdSection}_${productColorValue}`;
  }
};
