import type { ComponentInstance } from '../types';
import { Ref, ref, computed, ComputedRef } from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import ls from '../utils/localStorage';
import { useAuthentication } from '../useAuthentication';

export type EmployeeDetails = {
  storeId: string;
  employeeId: string;
  login: string;
};

type UseSignInToStoreStorage = {
  employeeInfoData: Ref<EmployeeCookieType>;
  storeInfoData: Ref<StoreCookieType>;
  employeeSignInPageUrl: Ref<string>;
  inStoreSalesInfo: Ref<InStoreSalesInfo>;
};

type EmployeeCookieType = {
  employeeToken: string;
  employeeExpiryTime: string;
  employeeData: EmployeeDetails;
};

type StoreCookieType = {
  storeId: string;
  storeName: string;
};
export interface InStoreSalesInfo {
  storeId: string;
  storeEmployeeId: string;
}

const useSignInToStore = (instance: ComponentInstance) => {
  const { signOut } = useAuthentication(instance);
  const {
    getStores: getStoresAPI,
    getStoreEmployees: getStoreEmployeesAPI,
    signInToStore: signInToStoreAPI,
  } = apiClientFactory(instance);
  const storage: ComposablesStorage<UseSignInToStoreStorage> = initStorage<UseSignInToStoreStorage>(
    instance,
    'useSignInToStore'
  );
  const employeeCookieName = `vfa_${instance.$getEnvValueByCurrentLocale(
    'API_SITE_ID'
  )}_emp`;

  const storeCookieName = `vfa_${instance.$getEnvValueByCurrentLocale(
    'API_SITE_ID'
  )}_str`;

  const employeeInfoData: Ref<EmployeeCookieType> =
    storage.get('employeeInfoData') ??
    storage.save(
      'employeeInfoData',
      ref(instance.$cookies.get(employeeCookieName))
    );

  const storeInfoData: Ref<StoreCookieType> =
    storage.get('storeInfoData') ??
    storage.save('storeInfoData', ref(instance.$cookies.get(storeCookieName)));

  const employeeSignInPageUrl: Ref<string> =
    storage.get('employeeSignInPageUrl') ??
    storage.save('employeeSignInPageUrl', ref(''));

  const inStoreSalesInfo: Ref<InStoreSalesInfo> =
    storage.get('inStoreSalesInfo') ??
    storage.save('inStoreSalesInfo', ref({ storeId: '', storeEmployeeId: '' }));

  const stores = ref([]);
  const storeEmployees = ref([]);
  const fetchingStores = ref(false);
  const fetchingStoreEmployees = ref(false);
  const isSigningIn = ref(false);
  const errorData = ref({});
  const emptyEmployeeInfo = {
    employeeToken: '',
    employeeExpiryTime: '',
    employeeData: {
      storeId: '',
      employeeId: '',
      login: '',
    },
  };

  const fetchStores = async () => {
    fetchingStores.value = true;
    try {
      const response = await getStoresAPI();
      stores.value = response.data.length ? response.data : [];
    } catch (e) {
      console.error(e.message);
    } finally {
      fetchingStores.value = false;
    }
  };

  const fetchStoreEmployees = async (storeId: string) => {
    fetchingStoreEmployees.value = true;
    storeEmployees.value = [];
    try {
      const response = await getStoreEmployeesAPI(storeId);
      storeEmployees.value = response.data.length ? response.data : [];
    } catch (e) {
      console.error(e.message);
    } finally {
      fetchingStoreEmployees.value = false;
    }
  };

  const signIn = async (data: Record<string, string>) => {
    isSigningIn.value = true;
    try {
      const signInResult = await signInToStoreAPI({
        userId: data.userId.toLowerCase(),
        password: data.password,
      });
      const result = {
        employeeToken: signInResult.data.bmUserToken,
        employeeExpiryTime: signInResult.data.expiryTime,
        employeeData: {
          storeId: data.storeId,
          employeeId: data.employeeId,
          login: data.userId.toLowerCase(),
        },
      };
      ls.setItem('usid', result.employeeToken);
      employeeInfoData.value = result;
      // Set employee data in cookies.
      instance.$cookies.set(employeeCookieName, JSON.stringify(result));
      return signInResult;
    } catch (e) {
      employeeInfoData.value = emptyEmployeeInfo;
      errorData.value = e;
    } finally {
      isSigningIn.value = false;
    }
  };

  const employeeInfo = computed(
    () => employeeInfoData.value ?? emptyEmployeeInfo
  );
  const employeeExpiryTime = computed(
    () => employeeInfo.value.employeeExpiryTime ?? ''
  );
  const employeeToken = computed(() => employeeInfo.value.employeeToken ?? '');

  const employeeConnected = computed(() => {
    if (employeeToken.value && employeeExpiryTime.value) {
      const date = new Date(employeeExpiryTime.value);
      return date.getTime() >= new Date(new Date().toISOString()).getTime();
    }
    return false;
  });

  const employeeDetails: ComputedRef<EmployeeDetails> = computed(() => {
    if (employeeInfo.value) {
      return employeeInfo.value.employeeData;
    }
    return null;
  });

  const employeeSignOut = (): void => {
    ls.clearStorage();
    employeeInfoData.value = emptyEmployeeInfo;
    instance.$cookies.remove(employeeCookieName);
  };

  const hasStoreCookie = computed(
    () => instance.$cookies.get(storeCookieName) !== undefined
  );

  const setStoreCookie = (store: StoreCookieType) => {
    instance.$cookies.set(storeCookieName, JSON.stringify(store));
    storeInfoData.value = store;
  };

  const clearStoreSession = async (
    redirectPath?: string,
    skipRedirect = false
  ) => {
    await signOut(redirectPath, skipRedirect);
    inStoreSalesInfo.value.storeEmployeeId = '';
  };

  return {
    employeeCookieName,
    isSigningIn,
    stores,
    storeEmployees,
    signIn,
    employeeToken,
    fetchingStores: computed(() => fetchingStores.value),
    fetchingStoreEmployees: computed(() => fetchingStoreEmployees.value),
    fetchStores,
    fetchStoreEmployees,
    employeeConnected,
    employeeDetails,
    errorData,
    employeeSignOut,
    employeeExpiryTime,
    employeeSignInPageUrl,
    getEmployeeSignInPageUrl: computed(() => employeeSignInPageUrl.value),
    setStoreCookie,
    clearStoreSession,
    hasStoreCookie,
    storeInfoData,
    inStoreSalesInfo,
  };
};

export default useSignInToStore;
