
import { useFetch } from '@nuxtjs/composition-api';
import { SearchType } from '@vf/composables/src/useSearch/types';
import { getFinalPdoSearch } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { Context } from '@vf/api-contract';
import {
  useArticles,
  useCategory,
  useCms,
  useMonetate,
  useNuanceChat,
  useProduct,
  useSearch,
  useSignInToStore,
  useAuthentication,
  useHeaderAndFooter,
  useFavorites,
  useProductInventory,
  useTrackPage,
  ROUTES,
} from '@vf/composables';
import { getCacheKeyFromProps, isClient } from '@vf/shared/src/utils/helpers';
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  PropType,
  watch,
  ComputedRef,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import {
  importPageComponents,
  finalPageRenderer,
  transformSeoMetadata,
  useUserData,
  handlePreviewModeClientSide,
} from './cmsUtils';
import useModal from '@/shared/useModal';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import { setPageTypeName } from '@/helpers';
import { isProductSegment } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CmsPage',
  props: {
    /** Specific page type */
    path: {
      type: String,
      default: null,
    },
    apiDataFetchMethod: {
      type: Function as PropType<() => Promise<void>>,
      default: () => async (): Promise<void> => {
        return;
      },
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hidePageContent: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      default: Context.PageContent,
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    let isLoadPageGTMEventsStarted = false;
    let isLoadCSR = false;

    const { dispatchPageGTMEvents } = useTrackPage(root);
    const { openModal } = useModal();

    const {
      setup,
      verifySetup,
      apiClientRef,
      fetchPage,
      pageLayoutName,
      page,
      header,
      footer,
      errors,
      stateRedirectUrlExecuted,
      canonicalRedirectUrlExecuted,
      meta,
      headerVisible,
      footerVisible,
      headerConfig,
      employeeSignInPage,
      resetPage,
      flexibleContentByPathResult,
    } = useCms(root, props.contextKey);

    const { updateHeaderAndFooterData } = useHeaderAndFooter(
      root,
      verifySetup,
      apiClientRef.value,
      Context.PageContent
    );
    const { getFavorites } = useFavorites(root);

    // Initialize useCategory and useProduct to have the right ssr reference in ssrRef.
    // It can't be done inside useCms alone because after executing first awaiter in useFetch,
    // as Vue doesn't correctly pass SSR instance to the subsequent Promises
    // bottom line:
    // Any ssrRef after first await will not work until migrated from useAsync/onServerPrefetch to fetch is made.
    const { product } = useProduct(root, Context.PageContent);
    useArticles(root, Context.PageContent);
    const { categoryId } = useCategory(root, Context.PageContent);
    const {
      pagination,
      autoCorrectQuery,
      isRedirected,
      queryString,
      pdoSearch,
      clearPdoSearch,
      products,
      meta: searchMeta,
      isTagPage,
      isExplorePage,
    } = useSearch(root);
    const {
      employeeSignInPageUrl,
      employeeConnected,
      hasStoreCookie,
    } = useSignInToStore(root);

    const { pageTypeName, cmsSiteConfiguration } = storeToRefs(cmsRefStore);

    const skipEvents = computed(
      () => stateRedirectUrlExecuted.value || canonicalRedirectUrlExecuted.value
    );
    const { monetateCartCustomVars } = useMonetate(root, props.contextKey);
    const isFavoritesPage: ComputedRef<boolean> = computed(() =>
      [PageTypeName.PDP].includes(pageTypeName.value)
    );

    onMounted(() => {
      if (
        !isLoadCSR &&
        isProductSegment(root.$route.path) &&
        product.value?.id
      ) {
        // call inventory Client side for PDP when page is loaded server side
        useProductInventory(root, Context.PageContent).getProductInventory(
          product.value.id
        );
      }

      if (root.$isPreview) {
        handlePreviewModeClientSide(root, cmsRefStore.cmsSiteConfiguration);
      }

      employeeSignInPageUrl.value = employeeSignInPage.value;
    });

    onBeforeUnmount(() => {
      if (props.contextKey === Context.PageContent)
        cmsRefStore.$patch({
          isNotFound: false,
        });
    });

    const pathFromProps = computed(() => props.path);

    // app state relevant computed
    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
    const { consumerType, isEmployee, getSegments } = useAuthentication(root);
    const isDummyCustom = computed(() => product.value?.dummyCustoms);
    const isSearchResults = computed(
      () => products.value && products.value.length > 0
    );

    // app state change watcher
    watch(
      [
        loggedIn,
        loyaltyEnrolled,
        isDummyCustom,
        isSearchResults,
        employeeConnected,
        hasStoreCookie,
      ],
      () => {
        updateHeaderAndFooterData();
      }
    );

    watch(pathFromProps, async () => {
      await loadPage();
      loadPageGTMEvents();
      useNuanceChat(root);
    });

    watch(pageTypeName, async (pageTypeName) => {
      if (pageTypeName !== 'PDP' && monetateCartCustomVars.value) {
        /* GTM requires to persist customVariables while entering PDP from Recomendation Carousel
         * persisted data needs to be deleted when user leaves PDP */
        monetateCartCustomVars.value = null;
      }
      setPageTypeName(pageTypeName);
    });

    const shouldLoadFavourites = computed(
      () => isFavoritesPage.value && isClient && !employeeConnected.value
    );
    const loadPage = async () => {
      isLoadCSR = isClient;
      const { path, apiDataFetchMethod } = props;
      try {
        await setup({ forcePreview: root.$isPreview });
        const {
          fetchHeaderAndFooter,
          getHeaderAndFooterData,
          sanitizeHeaderAndFooterData,
          errors,
        } = useHeaderAndFooter(
          root,
          verifySetup,
          apiClientRef.value,
          Context.PageContent
        );
        await fetchHeaderAndFooter();
        if (errors.value.length) {
          root.$log.error(`USEHEADERANDFOOTER ERRORS: ${errors.value}`);
        }

        // get CMS page data and save it in composable
        await fetchPage(
          path,
          root.$route.query as Record<string, string>,
          getHeaderAndFooterData,
          sanitizeHeaderAndFooterData
        );
        if (stateRedirectUrlExecuted.value) {
          return;
        }

        // run API fetcher method if specified
        if (apiDataFetchMethod) {
          await apiDataFetchMethod();
        }

        await importPageComponents(page.value);

        root.$nuxt.$emit('customTriggerScroll');

        if (shouldLoadFavourites.value) {
          getFavorites();
        }
      } catch (error) {
        root.$log.error(`Could not load the page: ${error.message}`, {
          path,
          error,
        });
      }
    };

    useFetch(async () => {
      if (props.contextKey === Context.PageContent) resetPage();

      await loadPage();
    });

    const loadPageGTMEvents = async () => {
      if (
        [!isClient, skipEvents.value, isLoadPageGTMEventsStarted].some(Boolean)
      ) {
        return;
      }
      isLoadPageGTMEventsStarted = true;

      await dispatchPageGTMEvents({
        contextKey: props.contextKey as Context,
        openModal,
        pageTypeName,
        trackAfterSSR: { value: true },
        useUserData,
        hookAfterLoadPageData: clearPdoSearch,
        hookLoadPageDataExtendOverrideAttibutes: (overrideAttibutes) => {
          overrideAttibutes.categoryID =
            pageTypeName.value === PageTypeName.PLP
              ? categoryId.value
              : undefined;

          const {
            searchTerm,
            searchTermAdj,
            searchType,
            searchResults,
          } = getFinalPdoSearch(
            pdoSearch.value,
            pageTypeName.value,
            queryString.value,
            autoCorrectQuery.value,
            isRedirected.value,
            pagination.value.total
          );
          const appendPdoSearch =
            pageTypeName.value === PageTypeName.SEARCH ||
            (pageTypeName.value && searchType === SearchType.CLICK);

          return appendPdoSearch
            ? {
                ...overrideAttibutes,
                searchTerm,
                searchTermAdj,
                searchType,
                searchResults,
              }
            : overrideAttibutes;
        },
        hookAfterBasicInformation: async (basicInformation) => {
          if (
            basicInformation.value.prosumer &&
            root.$config.areIpaWranxEnabled
          ) {
            await getSegments();
          }
        },
      });

      isEmployee.value = consumerType.value === 'EMPLOYEE';
      isLoadPageGTMEventsStarted = false;
    };
    loadPageGTMEvents();

    const forceHideAllErrors = () =>
      root.$route.query.hide_debug_messages === 'true';

    const getPageMeta = (useSearchMeta, searchQuery) => {
      if (isTagPage.value || isExplorePage.value) {
        return {
          htmlDescription: useSearchMeta.description?.content,
          htmlTitle: useSearchMeta.title?.content,
          openGraph: {
            ogDescription: useSearchMeta['og:description']?.content,
            ogTitle: useSearchMeta['og:title']?.content,
          },
          analyticsTags: {
            searchQuery,
          },
        };
      }

      return meta.value;
    };

    if (useFeatureFlagsStore().enable3ds) {
      // We need to reset order when we leave the page
      // to cover edge case when user bought something
      // and want to create other order with different card

      const path = root.$route.path;
      onBeforeUnmount(() => {
        if (path.includes(ROUTES.CHECKOUT_ORDER_STATUS())) {
          useCheckoutStore().order = null;
        }
      });
    }

    return {
      meta: computed(() => getPageMeta(searchMeta.value, queryString.value)),
      page: computed(() => page.value),
      pageTypeName: computed(() => pageTypeName.value),
      pageLayoutName: computed(() => pageLayoutName.value),
      header: computed(() => header.value),
      footer: computed(() => footer.value),
      errors: computed(() => errors.value),
      headerVisible: computed(() => headerVisible.value),
      footerVisible: computed(() => footerVisible.value),
      headerConfig: computed(() => headerConfig.value),
      cmsSiteConfiguration: computed(() => cmsSiteConfiguration.value),
      shouldLoadFavourites,
      cmsDebugEnabled:
        (root.$env.DEBUG_SSR === 'true' || root.$isPreview) &&
        !forceHideAllErrors(),
      flexibleContentByPathResult: computed(
        () => flexibleContentByPathResult.value
      ),
    };
  },
  head() {
    return transformSeoMetadata(this);
  },

  render(createElement) {
    // Make sure component caching is enabled when rendering CMS pages
    (getCacheKeyFromProps as any)._disabled = false;

    const elementsToRender = [];

    !this.hideHeader &&
      this.headerVisible &&
      elementsToRender.push(this.header);

    elementsToRender.push(this.page);

    !this.hideFooter &&
      this.footerVisible &&
      elementsToRender.push(this.footer);

    return finalPageRenderer(
      createElement,
      this.contextKey,
      elementsToRender,
      this.cmsDebugEnabled ? this.errors : null,
      this.$root,
      this.pageTypeName,
      this.pageLayoutName,
      this.headerConfig
    );
  },
});
