import { CMPlaceholder } from '@vf/api-contract';
import {
  extractCommonConfigLink,
  getDynamicField,
  getTranslation,
} from './utils';
import { MappingMethod } from '../types';
import { extractInterests } from './utils/extractInterests';

export const loyaltyQuestions: MappingMethod = (
  data: CMPlaceholder,
  context,
  siteConfiguration,
  cmsBaseUri
) => {
  const [shoeSizeSelector]: [CMPlaceholder] = extractCommonConfigLink(
    siteConfiguration,
    'loyalty-forms.shoe-size-selector'
  );
  const variant: string = getDynamicField(
    shoeSizeSelector,
    'variant',
    '$nora$'
  );

  return {
    component: 'loyalty/LoyaltyQuestions',
    props: {
      interests: extractInterests(siteConfiguration, cmsBaseUri),
      translations: {
        heading: getTranslation(
          data,
          'greeting',
          '$This is great... tell us more!$'
        ),
        subheading: getTranslation(data, 'subtext', ''),
        shoeSizeLabel: getTranslation(
          data,
          'questions.shoeSize.label',
          '$Select Size$'
        ),
        shoeSizePlaceholder: getTranslation(
          data,
          'questions.shoeSize.options.selectSize',
          '$Select Size$'
        ),
        shoeSizeMensText: getTranslation(shoeSizeSelector, 'mens', '$Mens$'),
        shoeSizeWomensText: getTranslation(
          shoeSizeSelector,
          'womens',
          '$WoMens$'
        ),
        shoeSizeDisplayFormat: getTranslation(
          shoeSizeSelector,
          `displayFormat[${variant}]`,
          '${{gender}} - {{size}}$'
        ),
        interestsSubheading: getTranslation(
          data,
          'interestsSubheading',
          '$Tell us 3 things you love to do or know about.$'
        ),
        whichLocation: {
          differentStore: getTranslation(
            data,
            'questions.whichLocation.options.differentStore',
            '$I heard about it in a different store$'
          ),
          didNotHearInStore: getTranslation(
            data,
            'questions.whichLocation.options.didNotHearInStore',
            '$I did not hear about it in store$'
          ),
          selectStore: getTranslation(
            data,
            'questions.whichLocation.options.selectStore',
            '$Select Store$'
          ),
          label: getTranslation(
            data,
            'questions.whichLocation.label',
            '$At which location did you hear about Vans Family?$'
          ),
        },
        age: {
          label: getTranslation(
            data,
            'questions.age.label',
            '$How old were you when you got your first pair of Vans?$'
          ),
          selectAge: getTranslation(
            data,
            'questions.age.options.selectAge',
            '$Select Age$'
          ),
        },
        buttonText: getTranslation(data, 'buttonText', '$Finish$'),
      },
    },
  };
};
