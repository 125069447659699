import { getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const plpSortBy = (data: CMPlaceholder) => {
  const defaultSortOption = getDefaultSortingOption(data);

  return {
    component: 'plp/CategorySort',
    props: {
      translations: {
        sortLabel: getTranslation(data, 'sortLabel', '$Sort by$'),
        sortOptions: {
          whatsNew: getTranslation(data, 'whatsNew'),
          topSellers: getTranslation(data, 'topSellers'),
          rating: getTranslation(data, 'rating'),
          bestMatches: getTranslation(data, 'bestMatches'),
          priceHighToLow: getTranslation(data, 'priceHighToLow'),
          priceLowToHigh: getTranslation(data, 'priceLowToHigh'),
        },
      },
      selectedSort: defaultSortOption,
    },
  };
};

const getDefaultSortingOption = (data: CMPlaceholder) => {
  try {
    const sortConfig = data.componentConfig.find(
      (conf) => conf.name === 'plp-sort-by-properties'
    );

    return sortConfig.values['sort-by'];
  } catch (e) {
    console.error(
      'PLP Sort by component error: no default sorting option found, falling back to default.'
    );
  }

  return 'bestMatches';
};
