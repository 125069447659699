import { Ref, computed, ComputedRef, ssrRef } from '@nuxtjs/composition-api';
import { AdditionalData, Context, ModalSettings } from '@vf/api-contract';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import { storeToRefs } from 'pinia';
import useRootInstance from '@/shared/useRootInstance';

type UseModal = {
  modalType: ComputedRef<ModalSettings['type']>;
  resourceId: ComputedRef<string>;
  path: ComputedRef<string>;
  additionalData: ComputedRef<AdditionalData>;
  isModalOpen: ComputedRef<boolean>;
  isNotificationInModalVisible: ComputedRef<boolean>;
  contextKey: ComputedRef<string>;
  modalProps: ComputedRef<ModalProps>;
  scrollModalTop: ComputedRef<boolean>;
  openModal(settings: ModalSettings): void;
  closeModal(): void;
  setModalProps(options: ModalProps): void;
  setScrollModalTop(scrollTop: boolean): void;
  setNotificationVisibility(visibility: boolean): void;
  getModalSettingsFromLink(link: string): ModalSettings;
  setAdditionalData(data: AdditionalData): void;
};

type UseModalStorage = {
  modalType: Ref<ModalSettings['type']>;
  resourceId: Ref<string>;
  path: Ref<string>;
  additionalData: Ref<AdditionalData>;
  isModalOpen: Ref<boolean>;
  isNotificationInModalVisible: Ref<boolean>;
  contextKey: Ref<string>;
  modalProps: Ref<ModalProps>;
  scrollModalTop: Ref<boolean>;
};

type ModalSettingsSize = {
  small?: string;
  medium?: string;
  large?: string;
};

type ModalProps = {
  title?: string;
  visible?: boolean;
  cross?: boolean;
  overlay?: boolean;
  persistent?: boolean;
  transitionOverlay?: string;
  transitionModal?: string;
  width?: ModalSettingsSize;
  height?: ModalSettingsSize;
  flexible?: boolean;
  isNestedModal?: boolean;
  slotChangeIndicator?: string;
  disableScrollTop?: boolean;
  class?: string;
  ariaLabel?: string;
};

const modalProps: UseModalStorage['modalProps'] = ssrRef(
  {},
  'useModal-modalProps'
);

const modalType: UseModalStorage['modalType'] = ssrRef(
  null,
  'useModal-modalType'
);

const resourceId: UseModalStorage['resourceId'] = ssrRef(
  '',
  'useModal-resourceId'
);

const path: UseModalStorage['path'] = ssrRef('', 'useModal-path');

const additionalData: UseModalStorage['additionalData'] = ssrRef(
  {},
  'useModal-additionalData'
);

const isModalOpen: UseModalStorage['isModalOpen'] = ssrRef(
  false,
  'useModal-isModalOpen'
);

const isNotificationInModalVisible: UseModalStorage['isNotificationInModalVisible'] = ssrRef(
  false,
  'useModal-isNotificationInModalVisible'
);

const contextKey: UseModalStorage['contextKey'] = ssrRef(
  Context.Modal,
  'useModal-contextKey'
);

const scrollModalTop: UseModalStorage['scrollModalTop'] = ssrRef(
  false,
  'useModal-scrollModalTop'
);

const defaultQuickshopModal = {
  height: {
    large: 'fit-content',
    medium: 'fit-content',
  },
  width: {
    large: '70vw',
    medium: '90vw',
  },
  flexible: true,
  class: 'modal-quickshop',
};

const redesignQuickshopModal = {
  height: {
    large: '608px',
    medium: '608px',
  },
  width: {
    large: '80vw',
    medium: '80vw',
  },
  flexible: true,
  class: 'modal-quickshop',
};

const useModal = (): UseModal => {
  const { root } = useRootInstance();
  const { showGoBackButton } = storeToRefs(useSimplifiedEnrollmentStore(root));
  const { isSimplifiedEnrollmentEnabled } = useFeatureFlagsStore();

  const openModal = (settings: ModalSettings): void => {
    modalType.value = settings.type;
    resourceId.value = settings.resourceId;
    path.value = settings.path;
    contextKey.value = settings.contextKey || Context.Modal;
    additionalData.value = {
      ...settings.additionalData,
      ...(isSimplifiedEnrollmentEnabled && {
        showGoBackButton: showGoBackButton,
      }),
    };

    isModalOpen.value = true;
    isNotificationInModalVisible.value = true;
    setModalSize(settings);
  };

  const setModalSize = (settings: ModalSettings): void => {
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();
    if (settings.type === 'page') {
      if (settings.contextKey === 'quickshop') {
        setModalProps(
          isCoreRedesignEnabled ? redesignQuickshopModal : defaultQuickshopModal
        );
      }
    }
  };

  const setModalProps = (options: ModalProps): void => {
    modalProps.value = options;
  };

  const closeModal = (): void => {
    isNotificationInModalVisible.value = false;
    isModalOpen.value = false;

    setTimeout(() => {
      setModalProps({});
    }, additionalData.value?.cssAnimationDuration);
  };

  const setScrollModalTop = (scrollTop: boolean): void => {
    scrollModalTop.value = scrollTop;
  };

  const setNotificationVisibility = (visibilty: boolean): void => {
    isNotificationInModalVisible.value = visibilty;
  };

  const getModalSettingsFromLink = (
    link: string
  ): Omit<ModalSettings, 'contextKey' | 'additionalData'> => {
    return link.includes('data-id')
      ? { type: 'lazyFragment', resourceId: link.replace(/\D/g, '') }
      : {
          type: 'page',
          path: link,
        };
  };

  const setAdditionalData = (data: AdditionalData) => {
    additionalData.value = data;
  };

  return {
    resourceId: computed(() => resourceId.value),
    path: computed(() => path.value),
    additionalData: computed(() => additionalData.value),
    modalType: computed(() => modalType.value),
    modalProps: computed(() => modalProps.value),
    contextKey: computed(() => contextKey.value),
    isModalOpen: computed(() => isModalOpen.value),
    isNotificationInModalVisible: computed(
      () => isNotificationInModalVisible.value
    ),
    scrollModalTop: computed(() => scrollModalTop.value),
    openModal,
    closeModal,
    setModalProps,
    setScrollModalTop,
    setNotificationVisibility,
    getModalSettingsFromLink,
    setAdditionalData,
  };
};

export default useModal;
