




import { defineComponent } from '@vue/composition-api';
import { ROUTES } from '@vf/composables';

export default defineComponent({
  key() {
    return 'cms-page';
  },
  layout: 'cms/cmsDefault',
  props: { error: { type: Object } },
  setup() {
    return { pathNotFound: ROUTES.NOT_FOUND() };
  },
});
