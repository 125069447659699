import { CMPlaceholder } from '@vf/api-contract';
import { MappingMethod } from '../types';
import {
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
  getTranslation,
  mapPasswordValidationSteps,
} from './utils';

export const orderRegisterXplr: MappingMethod = (
  data: CMPlaceholder,
  context,
  siteConfiguration,
  cmsBaseUri
) => {
  const xplrPassDashboardPage = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'xplrPassDashboardPage')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const xplrPassTermsPage = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'xplrPassTermsPage')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const xplrPassPage = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'xplrPassPage')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const shippingTermsPage = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'shippingTermsPage')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  return {
    component: 'loyalty/OrderRegisterXPLR',
    props: {
      xplrPassDashboardPage,
      xplrPassTermsPage,
      xplrPassPage,
      shippingTermsPage,
      translations: {
        plusText: getTranslation(data, 'plusText'),
        emailAddress: getTranslation(data, 'emailAddress'),
        learnMore: getTranslation(data, 'learnMore'),
        shippingTerms: getTranslation(data, 'shippingTerms'),
        heading: getTranslation(data, 'heading'),
        joinNow: getTranslation(data, 'joinNow'),
        enrolledHeading: getTranslation(data, 'enrolledHeading'),
        earnMorePoints: getTranslation(data, 'earnMorePoints'),
        passwordPlaceholder: getTranslation(data, 'passwordPlaceholder'),
        show: getTranslation(data, 'show', 'Show'),
        ptsExplanation: getTranslation(data, 'ptsExplanation'),
        agree: getTranslation(data, 'agree'),
        loyaltyStateEnrolled: getTranslation(data, 'loyaltyStateEnrolled'),
        validationStepsHeading: getTranslation(data, 'validationSteps.heading'),
        validationSteps: mapPasswordValidationSteps(
          getTranslation(data, 'validationSteps', {})
        ),
      },
    },
  };
};
