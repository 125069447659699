import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _111517ce = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _6c6022d5 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _2cf1bf0a = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _2368a0e3 = () => interopDefault(import('../pages/customs/index.vue' /* webpackChunkName: "pages/customs/index" */))
const _9a208462 = () => interopDefault(import('../pages/customs-customizer/index.vue' /* webpackChunkName: "pages/customs-customizer/index" */))
const _01db1b26 = () => interopDefault(import('../pages/healthcheck/index.vue' /* webpackChunkName: "pages/healthcheck/index" */))
const _001e6e44 = () => interopDefault(import('../pages/poslogon/index.vue' /* webpackChunkName: "pages/poslogon/index" */))
const _03f5620a = () => interopDefault(import('../pages/robots.vue' /* webpackChunkName: "pages/robots" */))
const _3119fe67 = () => interopDefault(import('../pages/stores/index.vue' /* webpackChunkName: "pages/stores/index" */))
const _6590e492 = () => interopDefault(import('../pages/account/sign-out.vue' /* webpackChunkName: "pages/account/sign-out" */))
const _ca966c06 = () => interopDefault(import('../pages/cart/gift.vue' /* webpackChunkName: "pages/cart/gift" */))
const _3e35fb62 = () => interopDefault(import('../pages/cart/share.vue' /* webpackChunkName: "pages/cart/share" */))
const _4788f6ec = () => interopDefault(import('../pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _0931a87a = () => interopDefault(import('../pages/cms/preview/index.vue' /* webpackChunkName: "pages/cms/preview/index" */))
const _2bc362f4 = () => interopDefault(import('../pages/errors/test.vue' /* webpackChunkName: "pages/errors/test" */))
const _279f1b6a = () => interopDefault(import('../pages/healthcheck/probe.vue' /* webpackChunkName: "pages/healthcheck/probe" */))
const _d41d1aac = () => interopDefault(import('../pages/product/review.vue' /* webpackChunkName: "pages/product/review" */))
const _7aba65e1 = () => interopDefault(import('../pages/reviews/writeReview.vue' /* webpackChunkName: "pages/reviews/writeReview" */))
const _557d7c8c = () => interopDefault(import('../pages/search/product.vue' /* webpackChunkName: "pages/search/product" */))
const _08021f06 = () => interopDefault(import('../pages/account/returns/details/_id.vue' /* webpackChunkName: "pages/account/returns/details/_id" */))
const _5c9576bc = () => interopDefault(import('../pages/account/returns/return-order/_id.vue' /* webpackChunkName: "pages/account/returns/return-order/_id" */))
const _2a9f846c = () => interopDefault(import('../pages/account/order-details/_id.vue' /* webpackChunkName: "pages/account/order-details/_id" */))
const _30a830a4 = () => interopDefault(import('../pages/add-your-touch/_id.vue' /* webpackChunkName: "pages/add-your-touch/_id" */))
const _4531a39a = () => interopDefault(import('../pages/articles/_id.vue' /* webpackChunkName: "pages/articles/_id" */))
const _15813d5b = () => interopDefault(import('../pages/category/_id.vue' /* webpackChunkName: "pages/category/_id" */))
const _716ad308 = () => interopDefault(import('../pages/explore/_id.vue' /* webpackChunkName: "pages/explore/_id" */))
const _0ec58536 = () => interopDefault(import('../pages/find-in-store/_id.vue' /* webpackChunkName: "pages/find-in-store/_id" */))
const _09864cd8 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _24348804 = () => interopDefault(import('../pages/reviews/_id.vue' /* webpackChunkName: "pages/reviews/_id" */))
const _6a0d44e0 = () => interopDefault(import('../pages/stores/_state/index.vue' /* webpackChunkName: "pages/stores/_state/index" */))
const _1859a783 = () => interopDefault(import('../pages/tag/_id.vue' /* webpackChunkName: "pages/tag/_id" */))
const _425fa8ea = () => interopDefault(import('../pages/stores/_state/_city/index.vue' /* webpackChunkName: "pages/stores/_state/_city/index" */))
const _de501f88 = () => interopDefault(import('../pages/stores/_state/_city/_store/index.vue' /* webpackChunkName: "pages/stores/_state/_city/_store/index" */))
const _0559fac2 = () => interopDefault(import('../pages/plp/_.vue' /* webpackChunkName: "pages/plp/_" */))
const _735ed86c = () => interopDefault(import('../pages/pdp/_.vue' /* webpackChunkName: "pages/pdp/_" */))
const _ac0efce0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _111517ce,
    name: "affiliate"
  }, {
    path: "/cart",
    component: _6c6022d5,
    name: "cart"
  }, {
    path: "/checkout",
    component: _2cf1bf0a,
    name: "checkout"
  }, {
    path: "/customs",
    component: _2368a0e3,
    name: "customs"
  }, {
    path: "/customs-customizer",
    component: _9a208462,
    name: "customs-customizer"
  }, {
    path: "/healthcheck",
    component: _01db1b26,
    name: "healthcheck"
  }, {
    path: "/poslogon",
    component: _001e6e44,
    name: "poslogon"
  }, {
    path: "/robots",
    component: _03f5620a,
    name: "robots"
  }, {
    path: "/stores",
    component: _3119fe67,
    name: "stores"
  }, {
    path: "/account/sign-out",
    component: _6590e492,
    name: "account-sign-out"
  }, {
    path: "/cart/gift",
    component: _ca966c06,
    name: "cart-gift"
  }, {
    path: "/cart/share",
    component: _3e35fb62,
    name: "cart-share"
  }, {
    path: "/checkout/success",
    component: _4788f6ec,
    name: "checkout-success"
  }, {
    path: "/cms/preview",
    component: _0931a87a,
    name: "cms-preview"
  }, {
    path: "/errors/test",
    component: _2bc362f4,
    name: "errors-test"
  }, {
    path: "/healthcheck/probe",
    component: _279f1b6a,
    name: "healthcheck-probe"
  }, {
    path: "/product/review",
    component: _d41d1aac,
    name: "product-review"
  }, {
    path: "/reviews/writeReview",
    component: _7aba65e1,
    name: "reviews-writeReview"
  }, {
    path: "/search/product",
    component: _557d7c8c,
    name: "search-product"
  }, {
    path: "/account/returns/details/:id?",
    component: _08021f06,
    name: "account-returns-details-id"
  }, {
    path: "/account/returns/return-order/:id?",
    component: _5c9576bc,
    name: "account-returns-return-order-id"
  }, {
    path: "/account/order-details/:id?",
    component: _2a9f846c,
    name: "account-order-details-id"
  }, {
    path: "/add-your-touch/:id?",
    component: _30a830a4,
    name: "add-your-touch-id"
  }, {
    path: "/articles/:id?",
    component: _4531a39a,
    name: "articles-id"
  }, {
    path: "/category/:id?",
    component: _15813d5b,
    name: "category-id"
  }, {
    path: "/explore/:id?",
    component: _716ad308,
    name: "explore-id"
  }, {
    path: "/find-in-store/:id?",
    component: _0ec58536,
    name: "find-in-store-id"
  }, {
    path: "/product/:id?",
    component: _09864cd8,
    name: "product-id"
  }, {
    path: "/reviews/:id?",
    component: _24348804,
    name: "reviews-id"
  }, {
    path: "/stores/:state",
    component: _6a0d44e0,
    name: "stores-state"
  }, {
    path: "/tag/:id?",
    component: _1859a783,
    name: "tag-id"
  }, {
    path: "/stores/:state/:city",
    component: _425fa8ea,
    name: "stores-state-city"
  }, {
    path: "/stores/:state/:city/:store",
    component: _de501f88,
    name: "stores-state-city-store"
  }, {
    path: "/plp/*",
    component: _0559fac2,
    name: "plp-all"
  }, {
    path: "/pdp/*",
    component: _735ed86c,
    name: "pdp-all"
  }, {
    path: "/",
    component: _ac0efce0,
    name: "index"
  }, {
    path: "/account/sign-out",
    component: _6590e492,
    name: "sign_out"
  }, {
    path: "/customizer.:model.html",
    component: _9a208462,
    name: "customizer"
  }, {
    path: "/custom-shoes",
    component: _2368a0e3,
    name: "customs_shoes"
  }, {
    path: "/:errorCode(\\d\\d\\d)test/*",
    component: _2bc362f4,
    name: "error_page_test"
  }, {
    path: "(/.*)?/cart-new",
    redirect: "/cart"
  }, {
    path: "/cart/gift?*",
    component: _ca966c06,
    name: "cart_gift"
  }, {
    path: "/product/review?*",
    component: _d41d1aac,
    name: "product_review"
  }, {
    path: "/reviews/:id",
    component: _24348804,
    name: "reviews"
  }, {
    path: "/reviews/writeReview",
    component: _7aba65e1,
    name: "write_review"
  }, {
    path: "/find-in-store/:id",
    component: _0ec58536,
    name: "find_in_store"
  }, {
    path: "/*",
    component: _ac0efce0,
    name: "common_page"
  }, {
    path: "/dynamic_import_plp",
    component: _0559fac2,
    name: "dynamic_import_plp"
  }, {
    path: "/*/*-p(sw|vn|nf)([a-z0-9]{6,10})",
    component: _735ed86c,
    name: "templated_pdp_with_category_segment"
  }, {
    path: "/*-p(sw|vn|nf)([a-z0-9]{6,10})",
    component: _735ed86c,
    name: "templated_pdp"
  }, {
    path: "/product/(sw|vn|nf)([a-z0-9]{6,10})",
    component: _735ed86c,
    name: "templated_pdp_automated_tests"
  }, {
    path: "/robots.txt",
    component: _03f5620a,
    meta: {"skipLocalization":true},
    name: "robots.txt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
