import { ComponentInstance } from '@vf/composables';
import { ComposablesStorage } from '../types';
import { Ref } from '@vue/composition-api';
import { ref } from '@nuxtjs/composition-api';
import initStorage from '../utils/storage';

type SectionID = string;

type UseNumerationStorage = {
  sections: Ref<SectionID[]>;
  removed: Ref<SectionID[]>;
};

const useSectionNumeration = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UseNumerationStorage> = initStorage<UseNumerationStorage>(
    instance,
    'useSectionNumeration'
  );

  const sections: Ref<SectionID[]> =
    storage.get('sections') ?? storage.save('sections', ref([] as SectionID[]));

  const removed: Ref<SectionID[]> =
    storage.get('removed') ?? storage.save('removed', ref([] as SectionID[]));

  const getSectionNumber = (sectionId?: SectionID) => {
    return sections.value.findIndex((section) => section === sectionId) + 1;
  };

  const addNumberedSection = (sectionId: SectionID) => {
    if (sections.value.includes(sectionId)) {
      return;
    }

    removed.value = removed.value.filter((section) => section !== sectionId);
    sections.value.unshift(sectionId);
  };

  const setNumberedSectionList = (sectionsIds: SectionID[]) => {
    if (JSON.stringify(sectionsIds) === JSON.stringify(sections.value)) {
      return;
    }

    sections.value = sectionsIds;
  };

  const removeNumberedSection = (sectionId: SectionID) => {
    sections.value = sections.value.filter((section) => section !== sectionId);

    if (!removed.value.includes(sectionId)) {
      removed.value.push(sectionId);
    }
  };

  const isManuallyRemoved = (sectionId?: string) => {
    return removed.value.includes(sectionId);
  };

  return {
    getSectionNumber,
    addNumberedSection,
    setNumberedSectionList: setNumberedSectionList,
    removeNumberedSection,
    isManuallyRemoved,
    sections,
  };
};

export default useSectionNumeration;
