import {
  extractComponentName,
  getTranslation,
  getDynamicField,
  getComponentConfigByName,
} from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const pageTitle = (data: CMPlaceholder) => {
  const thematic = getDynamicField(data, 'thematic', false);
  const componentToRender = extractComponentName(data, componentsMapping);

  const component = thematic
    ? 'thematic/ThematicCategoryTitle'
    : componentToRender || 'shared/PageTitle';

  const props = thematic
    ? {
        level: getHeadingLevelOption(data),
      }
    : {
        level: getHeadingLevelOption(data),
        showSelectedFilters: getDynamicField(data, 'showSelectedFilters', true),
        showItemsNumber: getDynamicField(data, 'showItemsNumber', true),
        showBackButton: getDynamicField(data, 'showBackButton', true),
        showSubtitle: getDynamicField(data, 'show-subtitle', true),
        numberOfTextSuggestions: getNumberOfSuggestions(data),
        showAutoCorrectQueryInfo: getDynamicField(
          data,
          'showAutoCorrectQueryInfo',
          false
        ),
        title: getDynamicField(data, 'title', '$Title$'),
        subtitle: getDynamicField(data, 'subtitle', ''),
        translations: {
          itemsCountPlural: getTranslation(data, 'itemsCountPlural', '$Items$'),
          itemsCountSingular: getTranslation(
            data,
            'itemsCountSingular',
            '$Item$'
          ),
          page: getTranslation(data, 'page', '$Page$'),
          weFound: getTranslation(data, 'weFound', '$We found$'),
          items: getTranslation(data, 'items', '$items!$'),
          favoritesLoggedIn: getTranslation(
            data,
            'favoritesLoggedIn',
            "${{name}}'s Favorites$"
          ),
          favoritesLoggedOut: getTranslation(
            data,
            'favoritesLoggedOut',
            '$Favorites$'
          ),
          didYouMean: getTranslation(data, 'didYouMean', '$Did you mean$'),
          misspellQueryResults: getTranslation(
            data,
            'misspellQueryResults',
            '$0 results for "{{query}}"$'
          ),
          autoCorrectQueryInfo: getTranslation(
            data,
            'autoCorrectQueryInfo',
            '$Results for "{{correction}}" are shown instead$'
          ),
        },
      };

  return {
    component,
    props,
  };
};

export const PAGE_TITLE_CMS_CONFIGURATION_ERROR =
  'PageTitle cms configuration error: no heading level specified\n' +
  ' or contains incorrect value, falling back to default.';

const validateHeadingLevelValue = (value) => {
  if (![1, 2, 3, 4, 5, 6].includes(value)) {
    throw new Error('Not a valid heading level!');
  }

  return value;
};

export const getHeadingLevelOption = (data: CMPlaceholder) => {
  try {
    const levelConfig = data.componentConfig.find(
      (conf) => conf.name === 'title-properties'
    );

    const value = parseInt(levelConfig.values['heading-level'].toString());

    return validateHeadingLevelValue(value);
  } catch (e) {
    console.warn(PAGE_TITLE_CMS_CONFIGURATION_ERROR);

    return 2;
  }
};

export const getNumberOfSuggestions = (data) => {
  try {
    const config = getComponentConfigByName(
      data,
      'search-page-title-config',
      {}
    );
    return parseInt(config['number-of-suggestions']) || 0;
  } catch (e) {
    return 0;
  }
};

const componentsMapping = {
  'data-source-plp': 'plp/CategoryTitle',
  'data-source-favorites-layout': 'favorites/FavoritesTitle',
  'data-source-search': 'search/SearchTitle',
};
