import { CMTeaser, CmsGridOverImage, CMContentHero } from '@vf/api-contract';
import { extractProperty } from './extractProperty';
import { getVideoObject } from './getVideoObject';

export const getVideoResponsiveMedia = (
  component: CMTeaser | CmsGridOverImage | CMContentHero,
  cmsBaseUri: string,
  size: 'medium' | 'large'
) => {
  const videoObjects = extractProperty(component, `responsiveMedia.${size}`);

  try {
    return getVideoObject(videoObjects, cmsBaseUri);
  } catch (e) {
    return null;
  }
};
