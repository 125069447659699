




























































































import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { computed, ref } from '@vue/composition-api';
import { lazyImage } from '@vf/shared/src/utils/directives';
export default {
  name: 'VfImage',
  directives: { lazyImage },
  inheritAttrs: false,
  serverCacheKey: getCacheKeyFromProps,
  props: {
    src: {
      type: [String, Object],
      default: '',
    },
    alt: {
      type: String,
      default: null,
    },
    seo: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        date: '',
      }),
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    /** Class that wil be added to img tag */
    classImg: {
      type: String,
      default: null,
    },
    /** Determines if image scr should be passed to image tag or lazy load directive */
    fillImgSrc: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    /** Determines if image should fill all provided space */
    fillSpace: {
      type: Boolean,
      default: false,
    },
    /** Determines if overlay is visible for current viewport */
    showOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Determines if overlay is transparent */
    transparentOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Determines if overlay should be always visible */
    alwaysShowOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Determines content position */
    contentPosition: {
      type: Object,
      default: () => ({
        small: 'middle_center',
        medium: 'middle_center',
        large: 'middle_center',
      }),
    },
    /** Overlay colors **/
    overlayColorSettings: {
      type: Object,
      default: () => ({
        small: '',
        medium: '',
        large: '',
      }),
    },
    /** Overlay colors **/
    overlayOpacitySettings: {
      type: Object,
      default: () => ({
        small: 0,
        medium: 0,
        large: 0,
      }),
    },
    showContentOnHoverSettings: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
  },
  setup(props, { root, slots }) {
    const image = ref(null);
    const isPicture = computed(() => {
      return !!props.src && typeof props.src === 'object';
    });

    const size = computed(() => {
      return (
        props.width &&
        props.height && {
          '--image-width': `${props.width}px`,
          '--image-height': `${props.height}px`,
          '--_image-width': `${props.width}px`,
          '--_image-height': `${props.height}px`,
        }
      );
    });

    const hasOverlay = computed(() => {
      return !!slots.default;
    });
    const hasOverlayVisible = computed(() => {
      return props.showOverlay[root.$viewport?.size || 'small'];
    });

    const errorCounter = ref(0);
    const onError = (e) => {
      if (errorCounter.value > 0) return;
      e.target.src = root.$mediaUrlFallback();
      errorCounter.value++;
    };
    const showContentOnHover = computed(
      () => props.showContentOnHoverSettings[root.$viewport?.size || 'small']
    );
    const imageOverlayOpacity = computed(() => {
      const DEFAULT_OPACITY_PERCENTAGE = 40;

      const overlayOpacityInt =
        props.overlayOpacitySettings[root.$viewport?.size || 'small'];
      return (overlayOpacityInt ?? DEFAULT_OPACITY_PERCENTAGE) * 0.01;
    });

    const dispatchHtmlEvent = () => {
      image.value?.dispatchEvent(new Event('imgLoaded', { bubbles: true }));
    };

    return {
      dispatchHtmlEvent,
      image,
      isPicture,
      size,
      hasOverlay,
      hasOverlayVisible,
      onError,
      imageOverlayOpacity,
      showContentOnHover,
    };
  },
};
