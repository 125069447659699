export enum OrderStatus {
  processing = 'Processing',
  shipped = 'Shipped',
  returnCreated = 'Return Created',
  returnReceived = 'Return Received',
  returnInvoiced = 'Return Invoiced',
  cancelled = 'Cancelled',
  dyoCancelled = 'DYO Cancelled',
  readyPick = 'Ready for Customer Pickup',
  picked = 'Picked Up',
  shippedToStore = 'Shipped to Store',
  pickExpired = 'Pick Up Expired',
  created = 'Created',
}

export const feOrderStatusMapping = {
  new: OrderStatus.processing,
  open: OrderStatus.processing,
  'draft order created': OrderStatus.processing,
  created: OrderStatus.processing,
  'backordered from node': OrderStatus.processing,
  scheduled: OrderStatus.processing,
  released: OrderStatus.processing,
  'release acknowledged': OrderStatus.processing,
  'ready for customer pick': OrderStatus.readyPick,
  'order received by factory': OrderStatus.processing,
  'order in production': OrderStatus.processing,
  'ready for packing': OrderStatus.processing,
  'pack in progress': OrderStatus.processing,
  'ready to ship': OrderStatus.processing,
  'sent to store': OrderStatus.processing,
  'partially shipped': OrderStatus.processing,
  'partially sent to store': OrderStatus.processing,
  'partially release acknowledged': OrderStatus.processing,
  shipped: OrderStatus.shipped,
  'return created': OrderStatus.returnCreated,
  'receipt closed': OrderStatus.returnCreated,
  'return received': OrderStatus.returnReceived,
  'return invoiced': OrderStatus.returnReceived,
  picked: OrderStatus.picked,
  'shipped to store': OrderStatus.shippedToStore,
  'received, ready for customer pickup': OrderStatus.readyPick,
  'shipped back to dc': OrderStatus.pickExpired,
  'customer pickedup': OrderStatus.picked,
  cancelled: OrderStatus.cancelled,
  'dyo cancelled': OrderStatus.cancelled,
};
