import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import {
  Enrollment,
  IConsumerProfile,
  IConsumerDetails,
  RequestHeader,
  Subscription,
} from '../../types';
import { format } from 'date-fns';
import { ApiClientConfiguration } from '../../configuration';

export class ConsumerDto implements IConsumerProfile {
  public consumerDetails: IConsumerDetails;
  public subscriptions: Array<Subscription>;
  public enrollments: Array<Enrollment>;
  public preferences: Array<never>;

  constructor(data: { gender?: string; [key: string]: any }) {
    this.consumerDetails = {
      lastName: data.lastName,
      birthDate: data.birthDate,
      consumerId: data.consumerId,
      firstName: data.firstName,
      email: data.email,
      postalCode: data.postalCode,
      phone: data.phone,
    };
    if (data?.campaingName) {
      this.consumerDetails.source = {
        campaignName: data.campaignName,
      };
    }
    if (data?.gender?.length) {
      this.consumerDetails.gender = data.gender;
    }
    this.enrollments = ConsumerDto.getEnrollmentsList(data.enrollments);
    this.subscriptions = ConsumerDto.getSubscriptionsList(data.subscriptions);
    if (data.preferences) {
      Object.assign(this, {
        preferences: data.preferences,
      });
    }
    if (data.userData) {
      Object.assign(this, {
        userData: data.userData,
      });
    }
  }

  static getEnrollmentsList(enrollments: Enrollment[]): Enrollment[] {
    if (!enrollments?.length) return [];

    return enrollments.map((e) => ({
      type: e.type,
      optin: true,
      doubleOptin: true,
      country: e.country,
      region: process.env.API_REGION,
    }));
  }

  static getSubscriptionsList(
    subscriptions: Array<Subscription> = []
  ): Array<Subscription> {
    if (!subscriptions.length) return [];
    const newsLatterSubscription = subscriptions.find(
      (item) => item.type === 'Newsletter'
    );
    if (!newsLatterSubscription) return subscriptions;

    const isSubscribeStatus: boolean = newsLatterSubscription.optin;

    const subscriptionData: Subscription = {
      category: 'subscription',
      type: 'Newsletter',
      optin: isSubscribeStatus,
      doubleOptin: isSubscribeStatus,
      channel: 'email',
      region: newsLatterSubscription.region,
      country: newsLatterSubscription.country,
      optinSource: newsLatterSubscription.optinSource,
    };

    if (isSubscribeStatus) {
      Object.assign(subscriptionData, {
        optIndate: format(new Date(), 'yyyy-MM-dd'),
        doubleOptIndate: format(new Date(), 'yyyy-MM-dd'),
      });
    } else {
      Object.assign(subscriptionData, {
        optoutDate: format(new Date(), 'yyyy-MM-dd'),
      });
    }

    return [subscriptionData];
  }
}

export const setBasicInformation = (configuration: ApiClientConfiguration) => (
  data,
  { usid = null }: RequestHeader = {}
): Promise<any> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.setBasicInformation,
    {
      config: configuration,
    }
  );

  const body = { consumerProfile: new ConsumerDto(data) };

  return axios.put(url, body, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
