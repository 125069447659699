import { Ref, nextTick, watch } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import {
  BasicInformationObject,
  Context,
  ModalSettings,
} from '@vf/api-contract';
import { isClient } from '@vf/shared/src/utils/helpers';
import { afterSSR } from '../../lifecycleMethods';
import {
  ComponentInstance,
  useBasicInformation,
  useMonetate,
  useGtm,
  useRequestTracker,
} from '../../../index';
import { EventProps } from '../../../useGtm/eventPropsHandlers';
import { useUserStore } from '../../../store/user';
import { PageTypeName } from '../../types';
import { cmsCommonConfigRoutes } from '../../../utils/cmsCommonConfigRoutes';
import { getRedirectUrlIfSafe } from '../../../utils/getRedirectUrlIfSafe';

let routeUnwatch = null;

export const useTrackPage = (instance: ComponentInstance) => {
  const { startTracking, onAllDone, onBaseDone } = useRequestTracker(instance);
  const { dispatchEvent, dispatchPageEvent } = useGtm(instance);
  const userStore = useUserStore(instance);
  const { loggedIn } = storeToRefs(userStore);

  const dispatchPageGTMEvents = ({
    contextKey,
    openModal,
    pageTypeName,
    trackAfterSSR,
    useUserData,
    categoryID,
    hookAfterBasicInformation,
    hookAfterLoadPageData,
    hookLoadPageDataExtendOverrideAttibutes,
  }: {
    contextKey: Context;
    openModal: (settings: ModalSettings) => void;
    pageTypeName: { value: PageTypeName };
    trackAfterSSR: { value: any };
    useUserData: (instance: any) => any;
    categoryID?: string;
    hookAfterBasicInformation?: (
      basicInformation: Ref<BasicInformationObject>
    ) => Promise<unknown>;
    hookAfterLoadPageData?: () => unknown;
    hookLoadPageDataExtendOverrideAttibutes?: (
      overrideAttibutes: Record<string, any>
    ) => Record<string, any>;
  }): Promise<boolean> => {
    if (!isClient || !instance) return Promise.resolve(false);

    return new Promise((resolve) => {
      startTracking();

      onBaseDone(() =>
        onBaseDoneImpl({
          dispatchEvent,
          instance,
          loggedIn,
          openModal,
          pageTypeName,
          categoryID,
          hookAfterLoadPageData,
          hookLoadPageDataExtendOverrideAttibutes,
        })
      );

      onAllDone(() => {
        nextTick(() =>
          onAllDoneImp({
            contextKey,
            dispatchEvent,
            dispatchPageEvent,
            instance,
            loggedIn,
            pageTypeName,
            resolve,
            trackAfterSSR,
            useUserData,
            hookAfterBasicInformation,
          })
        );
      });
    });
  };

  return {
    dispatchPageGTMEvents,
  };
};

const onBaseDoneImpl = ({
  dispatchEvent,
  instance,
  loggedIn,
  openModal,
  pageTypeName,
  categoryID,
  hookAfterLoadPageData,
  hookLoadPageDataExtendOverrideAttibutes,
}: {
  dispatchEvent: (payload: EventProps, options?: any) => void;
  instance: ComponentInstance;
  loggedIn: Ref<boolean>;
  openModal: (settings: ModalSettings) => void;
  pageTypeName: { value: PageTypeName };
  categoryID?: string;
  hookAfterLoadPageData?: () => unknown;
  hookLoadPageDataExtendOverrideAttibutes?: (
    overrideAttibutes: Record<string, any>
  ) => Record<string, any>;
}) => {
  const { signInLink } = cmsCommonConfigRoutes(instance);

  async function handleSigninQueryParams() {
    if (shouldSignInModalOpen()) {
      // without waiting for the next tick, the watcher for `visible` in modal molecule
      // does not kick in and so the modal doesn't open ¯\_(ツ)_/¯
      await instance.$nextTick();
      openModal({
        type: 'lazyFragment',
        resourceId: signInLink.id,
      });
    } else if (shouldRedirectLoggedInUser()) {
      checkUrlAndRedirect();
    }
  }

  const shouldSignInModalOpen = () =>
    !loggedIn.value &&
    signInLink &&
    (instance.$route.query.sign_in === '1' ||
      instance.$route.query.sign_up === '1');

  const shouldRedirectLoggedInUser = () =>
    loggedIn.value &&
    (instance.$route.query.sign_in === '1' ||
      instance.$route.query.sign_up === '1') &&
    instance.$route.query.redirectTo;

  let overrideAttibutes: Record<string, any> = {
    pageTypeName: pageTypeName.value,
    categoryID,
  };

  if (typeof hookLoadPageDataExtendOverrideAttibutes === 'function') {
    overrideAttibutes = hookLoadPageDataExtendOverrideAttibutes(
      overrideAttibutes
    );
  }

  dispatchEvent({
    eventName: 'loadPageData',
    overrideAttributes: overrideAttibutes,
  });

  // useful if you want to do something on this point
  typeof hookAfterLoadPageData === 'function' && hookAfterLoadPageData();

  dispatchEvent({ eventName: 'loadUserData' });

  const checkUrlAndRedirect = () => {
    const redirect = getRedirectUrlIfSafe(
      instance.$route.query.redirectTo as string
    );

    if (redirect) {
      instance.$router.push(instance.localePath(redirect));
    }
  };

  handleSigninQueryParams();

  if (!routeUnwatch) {
    routeUnwatch = watch(() => instance.$route.query, handleSigninQueryParams);
  }
};

const onAllDoneImp = async ({
  contextKey,
  dispatchEvent,
  dispatchPageEvent,
  instance,
  loggedIn,
  pageTypeName,
  resolve,
  trackAfterSSR,
  useUserData,
  hookAfterBasicInformation,
}: {
  contextKey: Context;
  dispatchEvent: (payload: EventProps, options?: any) => void;
  dispatchPageEvent: () => void;
  instance: ComponentInstance;
  loggedIn: Ref<boolean>;
  pageTypeName: { value: PageTypeName };
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  trackAfterSSR: { value: any };
  useUserData: (instance: any) => any;
  hookAfterBasicInformation?: (
    basicInformation: Ref<BasicInformationObject>
  ) => Promise<unknown>;
}) => {
  const { getExperienceDecision, addCustomVars } = useMonetate(
    instance,
    contextKey
  );

  if (loggedIn.value) {
    const { getBasicInformation, basicInformation } = useBasicInformation(
      instance
    );
    if (!basicInformation.value.firstName) {
      await getBasicInformation();
    }
    if (typeof hookAfterBasicInformation === 'function') {
      await hookAfterBasicInformation(basicInformation);
    }
  }

  const userData = useUserData(instance);
  addCustomVars(userData);
  if (trackAfterSSR.value) {
    await getExperienceDecision();
    await afterSSR(
      {
        instance: instance,
        contextKey,
      },
      pageTypeName.value
    );
  }
  const pageUrl = instance.$route.fullPath;
  const routeName = instance.$route.name;

  dispatchPageEvent();
  dispatchEvent({
    eventName: 'virtualPageView',
    overrideAttributes: {
      pageUrl,
      routeName,
    },
  });
  resolve(true);
};
