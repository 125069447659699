import { ApiClientConfiguration } from '../configuration';

const checkParamMapping = (
  endpoint: string,
  mapping: Record<string, string>
) => {
  if (process.env.NODE_ENV !== 'development') return;
  const pathParams = endpoint.match(/[^{\}]+(?=})/g) || [];
  const notExistingParams = pathParams.filter(
    (param: string) => !mapping[param]
  );
  if (notExistingParams.length) {
    throw new Error(
      `You're invoking endpoint '${endpoint}' without mapping for: ${notExistingParams.join(
        ', '
      )}`
    );
  }
};

export const urlBuilder = (
  endpoint: string,
  {
    config,
    mapping = {},
  }: {
    config: ApiClientConfiguration;
    mapping?: Record<string, string>;
  }
): string => {
  checkParamMapping(endpoint, mapping);
  const apiUrl: string = config.isPreview
    ? config.previewApiUrl
    : config.apiUrl;
  let url = `${apiUrl}${endpoint}`;
  for (const placeholder in mapping) {
    url = url.replace(`{${placeholder}}`, mapping[placeholder]);
  }
  return url;
};
