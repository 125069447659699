import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { ref, Ref } from '@vue/composition-api';
import { ReturnHistoryObject } from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { apiClientFactory } from '@vf/api-client';
import { useAuthentication, useRequestTracker, useUrl } from '@vf/composables';
import { UseAccountStorage } from '../../index';

export const useReturnHistory = (instance) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { getRelativeUrl } = useUrl(instance);

  const { getUsid } = useAuthentication(instance);
  const { getReturnHistory: getReturnHistoryAPI } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const returnHistory: Ref<ReturnHistoryObject> =
    storage.get('returnHistory') ??
    storage.save(
      'returnHistory',
      ref({
        isLastPage: false,
        returns: [],
        overallTotals: {},
        pageNumber: 1,
        pageSize: 10,
      })
    );

  const getReturnHistory = async (
    data = { pageSize: 10, pageNumber: 1 },
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useAccount-getReturnHistory',
      isBackgroundRequest
    );
    try {
      const returnHistoryData: AxiosResponse<ReturnHistoryObject> = await getReturnHistoryAPI(
        data,
        {
          usid: getUsid.value,
        }
      );
      (returnHistoryData.data.returns || []).forEach((ret) =>
        (ret.order_lines as any[]).forEach((orderLine) => {
          orderLine.item = {
            ...orderLine.item,
            pdpUrl: getRelativeUrl(orderLine.item.pdpUrl),
          };
        })
      );
      const updatedReturns = returnHistoryData.data?.returns || []; // orderHistoryData.data.returns is null in case of a customer without returns

      if (data.pageNumber > 1) {
        updatedReturns.unshift(...returnHistory.value.returns);
      }
      returnHistory.value = {
        ...returnHistoryData.data,
        returns: updatedReturns,
      };
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const resetReturnHistory = () => {
    // TODO after migration to pinia we can use reset state.$reset()
    // https://pinia.vuejs.org/core-concepts/state.html#resetting-the-state
    returnHistory.value = {
      isLastPage: false,
      pageNumber: 1,
      pageSize: 10,
      returns: [],
      overallTotals: {},
    };
  };

  return {
    returnHistory,
    getReturnHistory,
    resetReturnHistory,
  };
};
