import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import {
  UpdateSaveForLaterItemRequest,
  SaveForLaterResponse,
} from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const updateSaveForLater = (configuration: ApiClientConfiguration) => (
  data: UpdateSaveForLaterItemRequest
): Promise<AxiosResponse<SaveForLaterResponse>> => {
  return axios.put(
    urlBuilder(endpoints.get(configuration).cart.updateSaveForLater, {
      config: configuration,
    }),
    {
      productId: data.productId,
      recipeId: data.recipeId,
      qty: data.qty,
      type: data.type,
      defaultProductView: data.defaultProductView,
      productImageURL: data.productImageURL,
      pdpUrl: data.pdpUrl,
      available: data.available,
      saveForLater: {
        itemId: data.saveForLater.itemId,
        consumerID: data.saveForLater.consumerID,
        saveForLaterId: data.saveForLater.saveForLaterId,
      },
    },
    {
      headers: headerBuilder(configuration),
    }
  );
};
