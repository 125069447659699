


















































































































import { defineComponent, PropType, inject } from '@vue/composition-api';
import VfThumbnail from './Atom.Thumbnail.vue';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { Colors } from '@vf/api-contract/src/ui/colorSwatch';
import VfPopover from './Atom.Popover.vue';

export default defineComponent({
  name: 'VfColorSwatch',
  components: { VfPopover, VfThumbnail },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    value: {
      type: String,
      default: '',
    },
    /** Heading used for screen readers **/
    ariaHeading: {
      type: String,
      default: '',
    },
    colors: {
      type: Object as PropType<Colors>,
      default: () => ({}),
    },
    selectedValueLoading: {
      type: Boolean,
      default: false,
    },
    showAllColors: {
      type: Boolean,
      default: false,
    },
    showAlwaysAllColors: {
      type: Boolean,
      default: false,
    },
    itemsCountSmall: {
      type: Number,
      default: null,
    },
    itemsCountMedium: {
      type: Number,
      default: null,
    },
    itemsCountLarge: {
      type: Number,
      default: null,
    },
    showSolidColors: {
      type: Boolean,
      default: false,
    },
    isPlp: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    thumbnailHeight: {
      type: [String, Number],
      default: 50,
    },
    thumbnailWidth: {
      type: [String, Number],
      default: 50,
    },
  },
  setup() {
    return { isVansPdpRedesignEnabled: inject('isVansPdpRedesignEnabled') };
  },
  data() {
    return {
      activeColorValue: '',
      viewAllColors: this.$props.isPlp
        ? false
        : this.$props.showAllColors || this.$props.showAlwaysAllColors,
      scrollHeight: 0,
      clientHeight: 0,
      showInTwoRows: false,
    };
  },
  computed: {
    colorsChildren() {
      const children = this.colors?.children || [];
      // TODO: GLOBAL15-63801 clean this and change on configs/ProductGallery.js
      return this.isVansPdpRedesignEnabled
        ? children.map((child) => {
            child.src = child.src.replace('wid=50&hei=63', 'wid=80&hei=101');
            return child;
          })
        : children;
    },
    selectedIndex() {
      return this.colorsChildren.findIndex((child) => {
        return child.value === this.$props.value;
      });
    },
    isViewAllVisible() {
      return this.isDefinedColorsOnViewport
        ? this.getColorsOnViewport.length !== this.colorsChildren.length ||
            this.getColorsOnViewport.length < this.colorsChildren.length
        : this.scrollHeight > this.clientHeight;
    },
    buttonText() {
      if (this.$props.isPlp) {
        const itemsNumber =
          (this.$props.colors?.children?.length || 0) -
          this.$props.itemsCountLarge;
        return (this.viewAllColors ? ' - ' : ' + ') + itemsNumber;
      }
      if (this.viewAllColors) {
        return this.colors.showLess;
      }

      return this.colors.viewAll
        ? `${this.colors.viewAll} (${this.colorsChildren.length})`
        : '';
    },
    isSelectedColorHidden() {
      if (!this.$isServer && this.$viewport) {
        switch (this.$viewport.size) {
          case 'large':
            return (
              this.selectedIndex >=
              (this.itemsCountLarge ||
                this.itemsCountMedium ||
                this.itemsCountSmall)
            );
          case 'medium':
            return (
              this.selectedIndex >=
              (this.itemsCountMedium ||
                this.itemsCountSmall ||
                this.itemsCountLarge)
            );
          default:
            return (
              this.selectedIndex >=
              (this.itemsCountSmall ||
                this.itemsCountMedium ||
                this.itemsCountLarge)
            );
        }
      }
      return false;
    },
    isDefinedColorsOnViewport() {
      return (
        this.itemsCountLarge && this.itemsCountMedium && this.itemsCountSmall
      );
    },
    getColorsOnViewport() {
      if (!this.$isServer && this.$viewport) {
        switch (this.$viewport.size) {
          case 'large':
            return this.colorsChildren.slice(
              0,
              this.itemsCountLarge ||
                this.itemsCountMedium ||
                this.itemsCountSmall
            );
          case 'medium':
            return this.colorsChildren.slice(
              0,
              this.itemsCountMedium ||
                this.itemsCountSmall ||
                this.itemsCountLarge
            );
          default:
            return this.colorsChildren.slice(
              0,
              this.itemsCountSmall ||
                this.itemsCountMedium ||
                this.itemsCountLarge
            );
        }
      }
      return false;
    },
    visibleColors() {
      if (this.viewAllColors || !this.isDefinedColorsOnViewport) {
        return this.colorsChildren;
      }
      return this.getColorsOnViewport;
    },
  },
  mounted() {
    if (!this.isDefinedColorsOnViewport) {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }
    this.$nextTick(() => {
      if (this.isSelectedColorHidden) {
        this.viewAllColors = true;
      }
    });
    if (this.isVansPdpRedesignEnabled) {
      this.viewAllColors = true;
      this.calculateShowInTwoRows();
      this.$nextTick(() => this.showInTwoRows && this.scrollOnColorSelected());
    }
  },
  beforeDestroy() {
    if (!this.isDefinedColorsOnViewport) {
      window.removeEventListener('resize', this.handleResize);
    }
  },
  methods: {
    calculateShowInTwoRows() {
      // enable show in two row  under 768px and if our items not fit in two rows
      if (this.$refs.colorSwatch && this.$viewport.breakpoint.smDown) {
        const itemsInOneRow = Math.floor(
          this.$refs.colorSwatch.clientWidth / 88
        );
        this.showInTwoRows = this.colorsChildren.length > itemsInOneRow * 2;
      } else this.showInTwoRows = false;
    },
    handleResize() {
      this.$nextTick(() => {
        this.scrollHeight = this.$refs.colorSwatch?.scrollHeight || 0;
        this.clientHeight = this.$refs.colorSwatch?.clientHeight || 0;
      });
    },
    getLabel(value: string) {
      if (!value) return value;
      const VARIANT_COLOR_SEPARATOR = '-';
      const [variant, colorName] = value.split(VARIANT_COLOR_SEPARATOR);
      return colorName?.trim() || variant;
    },
    getColorsBackgroundValue(colorString) {
      if (!colorString) return false;
      const colorsArr = colorString.split('|');

      if (colorsArr.length == 2) {
        return `linear-gradient(225deg, #${colorsArr[0]} 0%, #${colorsArr[0]} 50%, #${colorsArr[1]} 50%, #${colorsArr[1]} 100%)`;
      } else if (colorsArr.length == 3) {
        return `linear-gradient(225deg, #${colorsArr[0]} 0%, #${colorsArr[0]} 33%, #${colorsArr[1]} 33%, #${colorsArr[1]} 66%, #${colorsArr[2]} 66%, #${colorsArr[2]} 100%)`;
      }

      return `#${colorString}`;
    },
    scrollOnColorSelected() {
      if (!this.$refs.colorSwatch) return;
      const elColorSelected = this.$refs.colorSwatch.children[
        this.selectedIndex
      ];
      if (!elColorSelected) return;

      // position on center
      const newScrollLeft =
        elColorSelected.offsetLeft +
        elColorSelected.clientWidth / 2 - // half of element
        this.$refs.colorSwatch.clientWidth / 2; // half of visible area
      if (newScrollLeft > 0) this.$refs.colorSwatch.scrollLeft = newScrollLeft;
    },
    showPopover(colorValue: string) {
      if (!this.$props.isPlp && this.$viewport.isSmall) return false;
      this.$refs.colorSwatchContainer.style.setProperty(
        '--vf-color-swatch-container-width',
        `${this.$refs.colorSwatchContainer.clientWidth}px`
      );
      this.activeColorValue = colorValue;
    },
  },
});
