import { EventPropsHandler } from '.';
import { ProductRecClickEvent } from '../../types/gtm';
import { useI18n } from '../../useI18n';

export const productRecClickHandler: EventPropsHandler<
  ProductRecClickEvent,
  {
    product: any;
    list: string;
    experience: any;
    action:
      | 'Open Quick View'
      | 'Open Quick Shop'
      | 'Navigate To PDP'
      | 'Click View Details';
    list_type: string;
  }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);

  const product = {
    brand: vmInstance
      .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
      .toUpperCase(),
    id: eventProps.overrideAttributes.product.id,
    name: eventProps.overrideAttributes.product.title,
    colorCode: eventProps.overrideAttributes.product.colorCode,
    onSale:
      eventProps.overrideAttributes.product.price.original !=
      eventProps.overrideAttributes.product.price.current,
    originalPrice: eventProps.overrideAttributes.product.price.original,
    price: eventProps.overrideAttributes.product.price.current,
    saleDiscountAmount:
      eventProps.overrideAttributes.product.price.original -
      eventProps.overrideAttributes.product.price.current,
    position: eventProps.overrideAttributes.product.index,
  };

  return {
    event: 'productRecClick',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Clicks',
    eventLabel: eventProps.overrideAttributes.product.id,
    nonInteractive: false,
    ecommerce: {
      currencyCode: defaultCurrency.value,
      click: {
        actionField: {
          action: eventProps.overrideAttributes.action,
          list: eventProps.overrideAttributes.list,
        },
        products: [product],
      },
    },
    customMetrics: {},
    customVariables: {
      list_type: eventProps.overrideAttributes.list_type,
      recs_set: eventProps.overrideAttributes.experience.component,
      experience_id:
        eventProps.overrideAttributes.experience.impressionReporting[0]
          .experience_id,
      experience_label:
        eventProps.overrideAttributes.experience.impressionReporting[0]
          .experience_label,
      experience_name:
        eventProps.overrideAttributes.experience.impressionReporting[0]
          .experience_name,
      recs_set_id: eventProps.overrideAttributes.experience.actionId,
    },
    _clear: true,
  };
};
