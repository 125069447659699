import {
  CartProductsTranslations,
  CMPlaceholder,
  CmsSite,
} from '@vf/api-contract';
import {
  extractProperty,
  extractCommonConfigLink,
  generateLinkFromTarget,
  getDynamicField,
  getTranslation,
  extractLocalSettingsExpandedLinks,
  getFlashMessage,
  getComponentConfigByName,
} from './utils';
import { getGiftOptionTranslations } from './utils/getGiftOptionTranslations';
import { ComposableContext } from '../types';

const parseStringToArrayOfNumbers = (string: string): number[] => {
  return string.split(',').map((e) => parseInt(e.trim()));
};

export const cartProductList = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const config = getComponentConfigByName(
    data,
    'pdp-find-in-store-settings',
    {}
  );
  const quickShopLink = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'cart-quick-shop'),
    '[0]'
  );
  const favoritesLoggedInData = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'favoritesLoggedInLink'),
    '[0]'
  );
  const favoritesLoggedOutData = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'favoritesLoggedOutLink'),
    '[0]'
  );

  const translations: CartProductsTranslations = {
    edit: getDynamicField(data, 'edit-cta-name', '$Edit$'),
    remove: getDynamicField(data, 'remove-cta-name', '$Remove$'),
    accordionLabel: getTranslation(data, 'errorAccordionLabel', '$Errors$'),
    moveToFavoritesNotification: getTranslation(
      data,
      'moveToFavoritesNotification',
      "$YOUR ITEMS HAVE BEEN MOVED TO YOUR <a href='{0}'>FAVORITES</a>. PLEASE SIGN IN TO VIEW YOUR FAVORITES.$"
    ),
    moveToFavoritesNotificationAuthorised: getTranslation(
      data,
      'moveToFavoritesNotificationAuthorised',
      "$Item has been moved to your <a href='{0}'>Favorites</a>$"
    ),
    availability: getDynamicField(
      data,
      'shipping-message-regular-product',
      '$Available to Ship$'
    ),
    availabilityCustom: getDynamicField(
      data,
      'shipping-message-custom-product',
      '$Available to Ship Custom$'
    ),
    outOfStock: getTranslation(data, 'outOfStock', '$Out of stock$'),
    color: getTranslation(data, 'color', '$Color$'),
    size: getTranslation(data, 'size', '$Size$'),
    itemDescription: getTranslation(
      data,
      'itemDescription',
      '$Item Description$'
    ),
    item: getTranslation(data, 'item', '$Item$'),
    items: getTranslation(data, 'items', '$Items$'),
    itemsCount: getTranslation(data, 'itemsCount', '$Items$'),
    price: getTranslation(data, 'price', '$Price$'),
    quantity: getTranslation(data, 'quantity', '$Quantity$'),
    total: getTranslation(data, 'total', '$Total$'),
    ariaLabelQty: getTranslation(data, 'ariaLabelQty', '$Quantity$'),
    saveForLater: getDynamicField(
      data,
      'save-for-later-cta-name',
      '$Save for later$'
    ),
    savedForLater: getTranslation(data, 'savedForLater', '$Saved For Later$'),
    savedForLaterNotification: getTranslation(
      data,
      'savedForLaterNotification',
      '$Product saved for later$'
    ),
    moveToCart: getTranslation(data, 'moveToCart', '$Move to cart$'),
    movedToCartNotification: getTranslation(
      data,
      'movedToCartNotification',
      '$Product moved to cart$'
    ),
    moveToWishList: getTranslation(
      data,
      'moveToWishList',
      '$Move to wish list$'
    ),
    saveToFavorites: getDynamicField(
      data,
      'add-to-favorites-cta-name',
      '$Save to favorites$'
    ),
    addedToFavoritesNotification: getTranslation(
      data,
      'addedToFavoritesNotification',
      '$Product added to favorites$'
    ),
    viewFavorites: getTranslation(data, 'viewFavorites', '$View favorites$'),
    customs: getTranslation(data, 'customs', '$Customs$'),
    flashMessages: {
      DefaultFlashMessage: getFlashMessage(
        data,
        'DefaultFlashMessage',
        '$Something went wrong.$'
      ),
      InvalidProductItem: getFlashMessage(
        data,
        'InvalidProductItem',
        '$We’re sorry we ran out. Please remove this item from your cart.$'
      ),
      ProductItemNotAvailable: getFlashMessage(
        data,
        'ProductItemNotAvailable',
        '$We’re sorry we ran out. Please remove this item from your cart.$'
      ),
      ProductLineItemInventoryMissing: getFlashMessage(
        data,
        'ProductLineItemInventoryMissing',
        '$The full quantity of this item is no longer available. <br/>Order totals have been updated to reflect the changes.$'
      ),
      ProductLineItemFullInventoryMissing: getFlashMessage(
        data,
        'ProductLineItemFullInventoryMissing',
        '$Order totals have been updated to reflect the changes.$'
      ),
      ProductLineItemIsNotEligibleForGiftOption: getFlashMessage(
        data,
        'ProductLineItemIsNotEligibleForGiftOption',
        '$Gift Options are not available for selected shipping option$'
      ),
      ProductItemMaxQuantity: getFlashMessage(
        data,
        'ProductItemMaxQuantity',
        '$The maximum quantity for {{productName}} {{variationAttributes}} in the shopping cart is {{maxQuantity}} and you have specified {{currentQuantity}}. Specify a lower quantity.$'
      ),
      ProductLineItemNoLongerAvailable: getFlashMessage(
        data,
        'ProductLineItemNoLongerAvailable',
        '$The item is no longer available$'
      ),
      PickupToStsTransition: getFlashMessage(
        data,
        'PickupToStsTransition',
        '$The delivery method for this product has changed$'
      ),
      PickupToSthTransition: getFlashMessage(
        data,
        'PickupToSthTransition',
        '$The delivery method for this product has changed$'
      ),
      StsToSthTransition: getFlashMessage(
        data,
        'StsToSthTransition',
        '$The delivery method for this product has changed$'
      ),
      StsToPickupTransition: getFlashMessage(
        data,
        'StsToPickupTransition',
        '$The delivery method for this product has changed$'
      ),
      ProductStyleMaxQuantity: getFlashMessage(
        data,
        'ProductStyleMaxQuantity',
        '$The maximum quantity for {{productName}} {{variationAttributes}} in the shopping cart is {{maxQuantity}} and you have specified {{currentQuantity}}. Specify a lower quantity.$'
      ),
    },
    noAddedItemsError: getTranslation(
      data,
      'noAddedItemsError',
      '$Sorry, but we updated your order totals based on the following reason(s)$'
    ),
    removedFromCartMessage: getTranslation(
      data,
      'removedFromCartMessage',
      '$Removed from cart message$'
    ),
    removedFromSavedForLaterMessage: getTranslation(
      data,
      'removedFromSavedForLaterMessage',
      '$Removed from saved for later message$'
    ),
    maxQuantityErrorMessage: getTranslation(
      data,
      'maxQuantity',
      '$Max quantity error message$'
    ),
    useSuccessNotification: getDynamicField(
      data,
      'use-success-notification ',
      false
    ),
    removingReasons: {
      WILL_EXCEED_MAX_ALLOWED_QTY: getTranslation(
        data,
        'willExceedMaxAllowedQtyMessage',
        '$You have exceeded the maximum quantity allowed for this product. The total in your cart has been updated.$'
      ),
      NO_LONGER_AVAILABLE: getTranslation(
        data,
        'noLongerAvailableMessage',
        '$This item is no longer available$'
      ),
      NOT_ENOUGH_INVENTORY: getTranslation(
        data,
        'notEnoughInventoryMessage',
        '$The full quantity of items is no longer available.$'
      ),
    },
    productItemHasErrorMessage: getTranslation(
      data,
      'productItemHasErrorMessage',
      '$Sorry, but we updated your order totals based on the following reason(s).$'
    ),
    selectStoreTooltip: getTranslation(
      data,
      'selectStoreTooltip',
      '$This item can be picked up from the selected store depending on the item availability at the store.$'
    ),
    freeShipping: getTranslation(data, 'freeShipping', '$Free shipping$'),
    inStorePickup: getTranslation(
      data,
      'inStorePickup',
      '$In Store Pickup {{storeName}}$'
    ),
    curbsidePickup: getTranslation(
      data,
      'curbsidePickup',
      '$Curbside Pickup {{storeName}}$'
    ),
    sts: getTranslation(data, 'sts', '$Ship To Store$'),
    close: getTranslation(data, 'close', '$Close$'),
    change: getTranslation(data, 'change', '$Change$'),
    itemNotAvailable: getTranslation(
      data,
      'itemNotAvailable',
      '$Item not available {{storeName}}$'
    ),
    freePickup: getTranslation(data, 'freePickup', '$Free Pickup$'),
    pickUpNotAvailable: getTranslation(
      data,
      'pickUpNotAvailable',
      '$Pickup not available$'
    ),
    selectStore: getTranslation(data, 'selectStore', '$Select Store$'),
    selectStoreRemoveGiftOption: getTranslation(
      data,
      'selectStoreRemoveGiftOption',
      '$Pickup is not available with gifting in this store. If you want to pick up here, please remove the gift option.$'
    ),
    shipIt: getTranslation(data, 'shipIt', '$Ship It$'),
    storeAvailabilityModal: {
      cancelCTA: getTranslation(data, 'cancelCTA', '$Cancel$'),
      withinLabel: getTranslation(data, 'withinLabel', '$Within$'),
      continueCTA: getTranslation(data, 'continueCTA', '$Continue$'),
      noStoresWithinRangeError: getTranslation(
        data,
        'noStoresWithinRangeError',
        '$No retail stores available within range.$'
      ),
      returnToShoppingCTA: getTranslation(
        data,
        'returnToShoppingCTA',
        '$Return to Shopping$'
      ),
      title: getTranslation(data, 'title', '$Select Store$'),
      storesNearLabel: getTranslation(data, 'storesNearLabel', '$Stores Near$'),
      km: getTranslation(data, 'km', '$km$'),
      miles: getTranslation(data, 'miles', '$miles$'),
      stsAvailableLabel: getTranslation(
        data,
        'stsAvailableLabel',
        '${{address}}. Ship to store is available$'
      ),
      validationMessages: getTranslation(data, 'validationMessages', {
        required: '$This field is required, please add your {{fieldName}}.$',
        maxLength: '$This field must have a length no longer than {{max}}.$',
      }),
      distanceSuffix: getTranslation(data, 'distanceSuffix', ''),
      giftOptionWarning: getTranslation(
        data,
        'giftOptionWarning',
        '$Selecting this store will result in removal of gift options for this item.$'
      ),
    },
    priceOverride: {
      priceLabel: getTranslation(data, 'priceOverride.priceLabel', '$Price$'),
      commentLabel: getTranslation(
        data,
        'priceOverride.commentLabel',
        '$Comment$'
      ),
      overrideButtonText: getTranslation(
        data,
        'priceOverride.overrideButtonText',
        '$Override$'
      ),
      resetButtonText: getTranslation(
        data,
        'priceOverride.resetButtonText',
        '$Reset$'
      ),
      headingTitle: getTranslation(
        data,
        'priceOverride.headingTitle',
        '$Override$'
      ),
      overrideSuccessfulMessage: getTranslation(
        data,
        'priceOverride.overrideSuccessfulMessage',
        '$Override successful$'
      ),
      resetSuccessfulMessage: getTranslation(
        data,
        'priceOverride.resetSuccessfulMessage',
        '$Price reset successful$'
      ),
    },
    itemPromoAppliedLabel: getTranslation(
      data,
      'itemPromoAppliedLabel',
      '$PROMOTIONS APPLIED:$'
    ),
    addGiftOption: getGiftOptionTranslations(data),
    free: getTranslation(data, 'free', '$Free$'),
    giftItemPickupNotAvailable: getTranslation(
      data,
      'giftItemPickupNotAvailable',
      '$Gift item is not eligible for pickup$'
    ),
    excludedFromAthleteMessage: getTranslation(
      data,
      'excludedFromAthleteProgramMessage',
      '$This product is excluded from the Athlete Program credit or discount.$'
    ),
    excludedFromAthleteDueSale: getTranslation(
      data,
      'excludedFromAthleteDueSale',
      `$Additional discounts cannot be applied to sale items.$`
    ),
  };

  return {
    component: 'cart/CartProductList',
    props: {
      showOnlyErrorInfo: getDynamicField(data, 'displayOnlyErrorInfo', false),
      maxQuantity: getDynamicField(data, 'maxQuantity', 10),
      allowRedirectToPdp: getDynamicField(data, 'redirect-to-pdp', true),
      showEditButton: getDynamicField(data, 'show-edit-cta', true),
      showRemoveButton: getDynamicField(data, 'show-remove-cta', true),
      showSaveForLaterButton: getDynamicField(
        data,
        'show-save-for-later-cta',
        true
      ),
      showSaveToFavoritesButton: getDynamicField(
        data,
        'show-add-to-favorites-cta',
        true
      ),
      openEditProductModal: getDynamicField(
        data,
        'open-edit-product-modal',
        false
      ),
      variationAddToFavorites: getDynamicField(
        data,
        'variationAddToFavorites',
        false
      ),
      showFavoritesTooltip: getDynamicField(
        data,
        'showFavoritesTooltip',
        false
      ),
      differentFavoritesMessages: getDynamicField(
        data,
        'differentFavoritesMessages',
        false
      ),
      showNotificationIcons: getDynamicField(
        data,
        'showNotificationIcons',
        true
      ),
      quickShopLink: generateLinkFromTarget(
        quickShopLink,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      favoritesLoggedInLink: generateLinkFromTarget(
        favoritesLoggedInData,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      favoritesLoggedOutLink: generateLinkFromTarget(
        favoritesLoggedOutData,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      /* 'dropdown' or'controller' */
      quantityPicker: getDynamicField(data, 'quantity-picker', 'dropdown'),
      showAddGiftOptionCta: getDynamicField(
        data,
        'show-add-gift-option-cta',
        false
      ),
      storeDistanceUnit: getDynamicField(data, 'store-distance-unit', 'km'),
      storeDistanceOptions: parseStringToArrayOfNumbers(
        getDynamicField(
          data,
          'store-distance-options',
          '5, 10, 15, 25, 50, 100'
        )
      ),
      unitOfMeasure: extractProperty(config, 'unit-of-measure', 'km'),
      translations,
    },
  };
};
