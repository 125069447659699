import { ProductAdjustment } from '@vf/api-contract';
import { ref, Ref } from '@vue/composition-api';
import { ComposablesStorage } from '../../../types';
import { UseCartStorage } from '../../types';
import initStorage from '../../../utils/storage';
import { apiClientFactory } from '@vf/api-client';
import { errorMessages } from '../../../utils/errorMessages';

export const usePrice = (instance, { updateCartItems }, contextKey) => {
  const {
    deletePriceAdjustment: deletePriceAdjustmentAPI,
    setPriceAdjustment: setPriceAdjustmentAPI,
    updatePriceAdjustment: updatePriceAdjustmentAPI,
  } = apiClientFactory(instance);
  const { displayErrorMessages } = errorMessages(instance);

  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const isPriceAdjusted: Ref<boolean> = storage.getOrSave(
    'isPriceAdjusted',
    ref(false)
  );
  /**
   * Override product price.
   *
   * @param id
   * @param body
   */
  const overridePrice = async (id: string, body: ProductAdjustment) => {
    isPriceAdjusted.value = false;
    try {
      const response = await setPriceAdjustmentAPI(id, body);
      if (response.status === 201) {
        await updateCartItems(response.data);
        isPriceAdjusted.value = true;
      }
    } catch (err) {
      displayErrorMessages(err);
    }
  };

  /**
   * Update product price.
   *
   * @param id
   * @param priceAdjustmentId
   * @param body
   */
  const updatePrice = async (
    id: string,
    priceAdjustmentId: string,
    body: ProductAdjustment
  ) => {
    isPriceAdjusted.value = false;
    try {
      const response = await updatePriceAdjustmentAPI(
        id,
        priceAdjustmentId,
        body
      );
      if (response.status === 200) {
        await updateCartItems(response.data);
        isPriceAdjusted.value = true;
      }
    } catch (err) {
      displayErrorMessages(err);
    }
  };

  /**
   * Remove price overriding from cart.
   *
   * @param id
   * @param priceAdjustmentId
   */
  const resetPrice = async (id: string, priceAdjustmentId: string) => {
    isPriceAdjusted.value = false;
    try {
      const response = await deletePriceAdjustmentAPI(id, priceAdjustmentId);
      if (response.status === 200) {
        await updateCartItems(response.data);
        isPriceAdjusted.value = true;
      }
    } catch (err) {
      displayErrorMessages(err);
    }
  };

  return {
    isPriceAdjusted,
    overridePrice,
    resetPrice,
    updatePrice,
  };
};
