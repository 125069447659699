import { computed, ref, Ref } from '@nuxtjs/composition-api';
import { useRequestTracker } from '../../../useRequestTracker';
import initStorage from '../../../utils/storage';
import { ProductConfiguration, UseProductStorage } from '../../types';
import { ComponentInstance, ComposablesStorage } from '../../../types';

export const useQuickShop = (
  instance: ComponentInstance,
  contextKey: string,
  { getProductDetails, configure }
) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const storage: ComposablesStorage<UseProductStorage> = initStorage<UseProductStorage>(
    instance,
    `useProduct-${contextKey}`
  );
  const findInStoreQuickShopVisible: Ref<boolean> =
    storage.get('findInStoreQuickShopVisible') ??
    storage.save('findInStoreQuickShopVisible', ref(false));

  const quickShopOpenProductId: Ref<string> =
    storage.get('quickShopOpenProductId') ??
    storage.save('quickShopOpenProductId', ref(''));

  const toggleQuickShop = async (
    id: string,
    defaultVariants: ProductConfiguration = null,
    saveOnlyColor = false
  ) => {
    quickShopOpenProductId.value = id;
    const { tag } = trackRequest('useProduct-toggleQuickShop', false);
    try {
      await getProductDetails(id, {
        isBackgroundRequest: false,
        loadImages: true,
        saveVariation: false,
        saveOnlyColor,
        configuredColor: defaultVariants?.color || '',
      });
      if (defaultVariants) {
        configure(defaultVariants);
      }
    } finally {
      clearRequest(tag, false);
    }
  };

  const closeQuickShop = () => {
    quickShopOpenProductId.value = '';
  };

  return {
    findInStoreQuickShopVisible,
    quickShopOpenProductId,
    isQuickShopOpen: computed(() => !!quickShopOpenProductId.value),
    toggleQuickShop,
    closeQuickShop,
  };
};
