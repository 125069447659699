import { ComponentInstance } from '@vf/composables';
import { ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { computed, ref, Ref } from '@vue/composition-api';

type GridConfigName = 'normal' | 'compact' | 'expanded';

type ProductConfigDetails = {
  badges: boolean;
  scrollableImages: boolean;
  showSwiperIcons: boolean;
  colors: number;
};

type ProductConfig = {
  small: ProductConfigDetails;
  medium: ProductConfigDetails;
  large: ProductConfigDetails;
};

type GridSizeConfig = {
  small: number;
  medium: number;
  large: number;
};

type GridSize = {
  name: GridConfigName;
  icon: string;
  selectedIcon: string;
  showOnMobile: boolean;
  grid: GridSizeConfig;
  product: ProductConfig;
};

type UseGridConfigStorage = {
  selectedConfig: Ref<GridSize>;
};

interface OptionsValues {
  colors: number;
  badges: boolean;
}

interface OptionsMapOption {
  normal: OptionsValues;
  compact: OptionsValues;
  expanded: OptionsValues;
}

interface OptionsMap {
  small: OptionsMapOption;
  medium: OptionsMapOption;
  large: OptionsMapOption;
}

const optionsMap: OptionsMap = {
  small: {
    compact: {
      colors: 3,
      badges: true,
    },
    normal: {
      colors: 3,
      badges: true,
    },
    expanded: {
      colors: 3,
      badges: true,
    },
  },
  medium: {
    compact: {
      colors: 4,
      badges: false,
    },
    normal: {
      colors: 6,
      badges: true,
    },
    expanded: {
      colors: 13,
      badges: true,
    },
  },
  large: {
    compact: {
      colors: 4,
      badges: false,
    },
    normal: {
      colors: 7,
      badges: true,
    },
    expanded: {
      colors: 13,
      badges: true,
    },
  },
};

export const useGridConfig = (instance: ComponentInstance) => {
  const gridConfig = instance.$themeConfig?.productsGrid ?? {};
  const defaultGridConfig: GridSize =
    gridConfig?.gridSizes.find(
      (config) => config.name === gridConfig?.defaultGridSize
    ) ??
    gridConfig?.gridSizes[0] ??
    {};
  const storage: ComposablesStorage<UseGridConfigStorage> = initStorage<UseGridConfigStorage>(
    instance,
    'useGridConfig'
  );

  const currentConfig: Ref<GridSize> =
    storage.get('selectedConfig') ??
    storage.save('selectedConfig', ref(defaultGridConfig));

  const setGridConfig = (config: GridSize) => {
    currentConfig.value = config;
  };

  const colorsCount = computed(() => {
    return currentConfig.value.name === 'compact' ? 3 : 4;
  });

  const colorsCountForPlp = computed(() => {
    const gridValue: string = currentConfig.value.name;
    const displaySize: string = instance.$viewport.size;
    return optionsMap[displaySize][gridValue]?.colors;
  });

  const isShowBadgesForGrid = computed(() => {
    const gridValue: string = currentConfig.value.name;
    const displaySize: string = instance.$viewport.size;
    return optionsMap[displaySize][gridValue]?.badges;
  });

  const isOneColumn = computed(() => {
    return (
      currentConfig.value.name === 'expanded' &&
      instance.$viewport.size === 'small'
    );
  });

  return {
    currentConfig,
    setGridConfig,
    colorsCount,
    colorsCountForPlp,
    isShowBadgesForGrid,
    isOneColumn,
  };
};
