import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation, getDynamicField } from './utils';

export const backToTopButton = (data: CMPlaceholder) => ({
  component: 'common/BackToTopButton',
  props: {
    translations: {
      title: getTranslation(data, 'title', '$Back To Top$'),
      ariaLabel: getTranslation(data, 'ariaLabel', ''),
    },
    variant: getDynamicField(data, 'buttonType', 'floating'),
  },
});
