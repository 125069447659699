import { CmsSite, CMPlaceholder } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
  getTranslation,
  getDynamicField,
} from './utils';
import { getLocales } from '../utils/getLocales';

export const siteSelector = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const title = getDynamicField(data, 'selectLocationTitle');

  return {
    component: 'SiteSelector',
    props: {
      title,
      logo: getLogoUrl(data, cmsBaseUri),
      locales: getLocales(siteConfiguration),
      translations: {
        heading: title,
        deliveryLocationLabel: getTranslation(
          data,
          'selectLocationLabel',
          '$Delivery Location$'
        ),
        deliveryLocationPlaceHolder: getTranslation(
          data,
          'selectLocationPlaceholder',
          '$Select Location$'
        ),
        languageSelectorLabel: getTranslation(
          data,
          'selectALanguage',
          '$Select Language$'
        ),
        buttonText: getTranslation(data, 'buttonText', 'Go'),
        helperText: getTranslation(
          data,
          'helperText',
          'Please note that by changing location all items in your shopping cart will be removed'
        ),
        currencyText: getTranslation(data, 'currencyText', '$Currency$'),
        languageSelectorPlaceHolder: getTranslation(
          data,
          'languageSelectorPlaceHolder',
          '$Please select a language$'
        ),
        languageSelectedLabel: getTranslation(
          data,
          'languageSelectedLabel',
          '$Language$'
        ),
        locationSelectionRequired: getTranslation(
          data,
          'locationSelectionRequired',
          '$Location is required$'
        ),
      },
    },
  };
};

const getLogoUrl = (data, cmsBaseUri: string) => {
  const settingsExpanded = extractLocalSettingsExpandedLinks(data, 'logo');
  const logoPath = extractProperty(
    settingsExpanded,
    '[0].pictures[0].data.uri',
    ''
  );
  const height = extractProperty(
    settingsExpanded,
    '[0].pictures[0].height',
    ''
  );
  const width = extractProperty(settingsExpanded, '[0].pictures[0].width', '');
  const seoTitle = extractProperty(
    settingsExpanded,
    '[0].pictures[0].seoTitle',
    ''
  );

  const logo = `${cmsBaseUri}${logoPath}`;

  return {
    url: logo,
    width,
    height,
    ariaLabel: seoTitle,
  };
};
