










































import { defineComponent, nextTick, ref, watch } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import useRootInstance from '@/shared/useRootInstance';
import BaseDialog from '@/components/dialog/BaseDialog.vue';
import { useLocationConfirmationStore } from '@vf/composables/src/store/locationConfirmation';
import { replaceAll } from '@/helpers/replaceAll';
import ls from '@vf/composables/src/utils/localStorage';

export default defineComponent({
  name: 'LocationConfirmationModal',
  components: { BaseDialog },
  setup() {
    const { root } = useRootInstance();
    const locationConfirmationStore = useLocationConfirmationStore();
    const { proposedSiteUrl, shouldShowDialog } = storeToRefs(
      locationConfirmationStore
    );
    const dialog = ref(null);
    const openModal = () => dialog.value?.open();
    const closeModal = () => dialog.value?.close();

    const brand = root.$env.WEBSITE_NAME;

    const heading = replaceAll(
      root.$t('locationConfirmationModal.heading') as string,
      {
        brand,
      }
    );

    const body = replaceAll(
      root.$t('locationConfirmationModal.body') as string,
      {
        brand,
      }
    );

    const goToButtonText = root.$t(
      'locationConfirmationModal.goToButton'
    ) as string;
    const remainButtonText = root.$t(
      'locationConfirmationModal.remainButton'
    ) as string;

    const goToAction = () => {
      window.location.href = proposedSiteUrl.value;
    };

    const remainAction = () => {
      ls.setItem(
        'confirmedCountry',
        root.$getEnvValueByCurrentLocale<string>('COUNTRY').toUpperCase()
      );
      locationConfirmationStore.closeDialog();
    };

    watch(
      shouldShowDialog,
      async (val: boolean) => {
        await nextTick();
        if (val) {
          openModal();
        } else {
          closeModal();
        }
      },
      { immediate: true }
    );

    return {
      body,
      closeModal,
      dialog,
      goToAction,
      goToButtonText,
      heading,
      openModal,
      remainAction,
      remainButtonText,
    };
  },
});
