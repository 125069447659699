import { EventPropsHandler } from '.';
import { RefundEvent } from '../../types/gtm';
import { useI18n } from '../../useI18n';

export const refundHandler: EventPropsHandler<
  RefundEvent,
  { products: any[]; orderData: any }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);

  return {
    event: 'refund',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Refund',
    eventLabel: undefined,
    nonInteractive: true,
    ecommerce: {
      /** MISSING_CURRENCY: We should get currency code from product(s) object */
      currencyCode: defaultCurrency.value,
      refund: {
        // TODO: implement once refund flow gets implemented
        actionField: {},
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
