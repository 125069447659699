//
//
//
//
//

export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return { threshold: [0, 1], rootMargin: '0px' };
      },
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    this.initializeObserver();
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    initializeObserver() {
      const options = this.options || {};
      this.observer = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) {
          // Not visible at all
          this.$emit('hidden', entry);
          return;
        }

        if (entry.intersectionRatio === 1) {
          // All parts visible on scren as a whole
          this.$emit('visible', entry);
        } else if (entry.boundingClientRect.y <= 0) {
          // Visible from top, but not whole
          this.$emit('partialy-visible-on-top', entry);
        } else {
          // Visible from bottom, but not whole
          this.$emit('partialy-visible-on-bottom', entry);
        }
      }, options);
      this.observer.observe(this.$el);
    },
  },
};
