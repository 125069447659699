import {
  CmsCategoryPageContent,
  CmsProductPageContent,
  PageType,
} from '@vf/api-contract';
import { getRestoredSearchSettings } from '../../utils/searchState';
import useCategory from '../../useCategory';
import useUrl from '../../useUrl';
import { Product } from '@vf/api-client';

import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
} from '../types';
import { requirementsResolver, isValidProductData } from '../utils';

const logError = (e: Error) =>
  console.error('After CMS Response Sanitization lifecycle Error:', e);

type CanonicalResult = {
  notFound?: boolean;
  permanent?: boolean;
  canonicalUrl?: string;
  canonicalRedirectUrl?: string;
};

export const afterCmsResponseSanitization = async (
  type: PageType,
  context: ComposableContext,
  pageId: string,
  pageContent: CmsCategoryPageContent | CmsProductPageContent,
  requirements: CmsSanitizationRequirements,
  qs?: { [key: string]: string },
  productData?: Product
): Promise<{
  notFound?: boolean;
  stateRedirectUrlExecuted?: boolean;
  canonicalRedirectUrlExecuted?: boolean;
  canonicalUrl?: string;
}> => {
  const stateRedirectUrlExecuted = await requirementsResolver(
    context,
    requirements,
    [
      CmsSanitizationRequirementName.REDIRECT,
      CmsSanitizationRequirementName.ARTICLES,
      CmsSanitizationRequirementName.SORTED_ARTICLES,
      CmsSanitizationRequirementName.PRODUCT_CONTEXT,
      CmsSanitizationRequirementName.FACET_CONFIGURATION,
    ]
  );

  if (stateRedirectUrlExecuted) {
    return { stateRedirectUrlExecuted };
  }

  // Check to verify if product response misses any required data
  if (productData && !isValidProductData(context.instance, productData)) {
    return {
      notFound: true,
    };
  }

  let notFound = false;
  let permanent = false;
  let canonicalUrl = null;
  let canonicalRedirectUrl = null;
  let canonicalRedirectUrlExecuted = false;

  switch (type) {
    case 'category':
      ({
        notFound,
        permanent,
        canonicalUrl,
        canonicalRedirectUrl,
      } = await getCategoryPageData(pageId, pageContent, context, qs));
      break;

    case 'product':
      ({
        notFound,
        permanent,
        canonicalUrl,
        canonicalRedirectUrl,
      } = await getProductPageUrls(productData, context, qs));
      break;
  }

  if (notFound) {
    return {
      notFound: true,
    };
  }

  if (canonicalUrl || canonicalRedirectUrl) {
    if (!context.instance.$isPreview) {
      canonicalRedirectUrlExecuted = await requirementsResolver(
        context,
        [
          {
            name: CmsSanitizationRequirementName.REDIRECT,
            to: canonicalRedirectUrl || canonicalUrl,
            options: {
              permanent,
            },
          },
        ],
        [CmsSanitizationRequirementName.REDIRECT]
      );
    }
  }

  return {
    stateRedirectUrlExecuted,
    canonicalRedirectUrlExecuted,
    canonicalUrl: canonicalUrl || canonicalRedirectUrl,
  };
};

const getCategoryPageData = async (
  commercePath: string,
  pageContent: CmsCategoryPageContent,
  context: ComposableContext,
  qs?: { [key: string]: string }
): Promise<CanonicalResult> => {
  try {
    const { getCategoryUrl } = useUrl(context.instance);
    const categoryName = (commercePath || '').split('/').pop();
    const categoryId = pageContent?.commerceRef?.id;
    const {
      getCatalog,
      getSearchSetting,
      setActiveCategorySlug,
      setActiveCategoryId,
      setActivePage,
      resetFiltersView,
      readFiltersDataFromUrl,
    } = useCategory(context.instance, context.contextKey);

    resetFiltersView();
    readFiltersDataFromUrl();
    const searchSettingsCatalog = await getSearchSetting(
      getRestoredSearchSettings()
    );
    setActivePage(searchSettingsCatalog.page);
    setActiveCategorySlug(categoryName);
    setActiveCategoryId(categoryId);

    await getCatalog(searchSettingsCatalog, {
      isBackgroundRequest: false,
      useCache: !context.instance.$root?.$themeConfig?.productsGrid
        ?.loadPrevious.isLoadPreviousEnabled,
    });

    return {
      canonicalUrl: getCategoryUrl(pageContent, qs),
      canonicalRedirectUrl: getCategoryUrl(pageContent, qs),
    };
  } catch (e) {
    logError(e);
  }

  return {
    notFound: true,
  };
};

const getProductPageUrls = async (
  productData: Product,
  context: ComposableContext,
  qs?: { [key: string]: string }
): Promise<CanonicalResult> => {
  try {
    const { getProductRedirectUrl, getProductCanonicalUrl } = useUrl(
      context.instance
    );

    const { outOfStock, permanent, url } = getProductRedirectUrl(
      productData,
      qs
    );

    if (outOfStock && !context.instance.$isServer) {
      return {};
    }

    return {
      permanent,
      canonicalUrl: getProductCanonicalUrl(productData),
      canonicalRedirectUrl: url,
    };
  } catch (e) {
    logError(e);
  }

  return {
    notFound: true,
  };
};
