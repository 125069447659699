import { ApiClientConfiguration } from '@vf/api-client';
import { ComponentInstance } from '@vf/composables';
import { PhotorankApi } from '../../../api-types';
import { endpoints } from '../../../config';
import { axios } from '../../../helpers';

export const trackCheckoutEvent = (
  configuration: ApiClientConfiguration
) => async (
  options: {
    transactionId: string;
    format?: string;
  },
  params: {
    products: string[];
    analytics_id: string;
    auth_token: string;
  },
  root: ComponentInstance
): Promise<any> => {
  const { transactionId, format = 'json' } = options;

  const baseUrl =
    root.$env.SOCIAL_NATIVE_ANALYTICS_BASE_URL ||
    'https://data.photorank.me/track';

  const url = endpoints
    .get(configuration)
    .socialNative.analytics.trackCheckoutEvent.replace(
      '{transactionId}',
      transactionId
    )
    .replace('{format}', format);

  return axios.get<PhotorankApi>(baseUrl + url, {
    params,
  });
};
