/**
 * Sorts and removes empty values from array of filters.
 *
 * @param Array of filters
 * @param Desired filter order.
 * @param Object key containing filter code.
 */
export const mapOrderFilters = (arrayOfFilters, order, key) => {
  const reducedOrder = order.reduce(
    (result, value, index) => ((result[value] = index), result),
    {}
  );
  const arrayWithoutEmptyValues = arrayOfFilters.filter((el) => {
    return el.options?.length > 0;
  });
  return arrayWithoutEmptyValues.sort(
    (a, b) => reducedOrder[a[key]] - reducedOrder[b[key]]
  );
};
