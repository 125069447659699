import { CMContentHero, CmsSite } from '@vf/api-contract';
import {
  getImageObject,
  extractProperty,
  getVideoObject,
  getVideoResponsiveMedia,
  getTeaserTargets,
  extractComponentStyleDefinitions,
} from './utils';
import { ComposableContext } from '../types';
import { getResponsiveMediaPictureObject } from './utils/getImageObject';

export const easyHero = (
  data: CMContentHero,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const image = getImageObject(
    extractProperty(data, 'pictures[0]'),
    siteConfiguration,
    null,
    cmsBaseUri,
    data.responsiveMedia
  );
  const mediumImage = getResponsiveMediaPictureObject(
    extractProperty(data, 'pictures[0]'),
    'medium',
    data.responsiveMedia,
    0
  );
  const largeImage = getResponsiveMediaPictureObject(
    extractProperty(data, 'pictures[0]'),
    'large',
    data.responsiveMedia,
    0
  );

  const smallVideo = getVideoObject(data.videos, cmsBaseUri);
  const mediumVideo =
    getVideoResponsiveMedia(data, cmsBaseUri, 'medium') || smallVideo;
  const largeVideo =
    getVideoResponsiveMedia(data, cmsBaseUri, 'large') || mediumVideo;

  const hasImage = !!(image.small || image.medium || image.large);
  const hasVideo = !!(smallVideo || mediumVideo || largeVideo);

  const { ctas, mediaTarget: defaultMediaTarget } = getTeaserTargets(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );

  /*
   * When CMS provides CTAs image should link to the first one.
   * Only when no CTAs provided image should link to mediaTarget.
   * */
  const mediaTarget = ctas.length ? ctas[0] : defaultMediaTarget;

  return {
    component: 'shared/ContentHero',
    props: {
      variant: '',
      media: {
        ...(hasImage && {
          image: {
            large: image.large,
            medium: image.medium,
            small: image.small,
            alt: image.alt,
            title: image.title,
            sizes: {
              small: {
                width: image.width,
                height: image.height,
              },
              medium: {
                width: mediumImage.width,
                height: mediumImage.height,
              },
              large: {
                width: largeImage.width,
                height: largeImage.height,
              },
            },
          },
        }),
        ...(hasVideo && {
          video: {
            large: largeVideo,
            medium: mediumVideo,
            small: smallVideo,
          },
        }),
      },
      title: extractComponentStyleDefinitions(data, 'teaserTitle'),
      subtitle: extractComponentStyleDefinitions(
        data,
        'teaserSubTitle',
        'teaserSubtitle'
      ),
      overlay: {
        position: extractProperty(data, 'componentStyles.overlayPosition'),
        width: extractProperty(data, 'componentStyles.overlayWidth'),
      },
      textBlockPosition: extractProperty(
        data,
        'componentStyles.textBlockPosition'
      ),
      bottomMargin: extractProperty(data, 'componentStyles.bottomMargin'),
      rowBackgroundColor: extractProperty(
        data,
        'componentStylesDefinitions.rowBackgroundColor.data.color'
      ),
      ctas,
      mediaTarget,
    },
  };
};

export type EasyHeroData = ReturnType<typeof easyHero>;
