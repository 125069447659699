import { CMTeaser } from '@vf/api-contract';
import { getDynamicField, getTranslation } from './utils';

export const quickAddToCart = (data: CMTeaser) => {
  return {
    component: 'carousel/QuickAddToCart',
    props: {
      translations: {
        addToCartButtonText: getTranslation(
          data,
          'addToCartButtonText',
          '$Add to Cart$'
        ),
        sizeLabel: getTranslation(data, 'sizeLabel', '$Size$'),
        sizePlaceholder: getTranslation(data, 'sizePlaceholder', '$Select$'),
        sizeError: getTranslation(data, 'sizeError', '$Error$'),
        qtyLabel: getTranslation(data, 'qtyLabel', '$Qty$'),
      },
      maxQuantity: parseInt(getDynamicField(data, 'maxQuantity', 10)),
    },
  };
};
