import ls from '../../../utils/localStorage';
import { TokensObject } from '@vf/api-client/src/api-types';
import { apiClientFactory } from '@vf/api-client';
import { useCart, useFavorites } from '@vf/composables';
import { v4 as uuidv4 } from 'uuid';
import { errorMessages } from '../../../utils/errorMessages';
import { useSaveForLater } from '../useSaveForLater';
import { useUserSessionData } from '../useUserSessionData';

export const useGuestUser = (instance) => {
  const { signInGuest: signInGuestAPI } = apiClientFactory(instance);
  const { displayErrorMessages } = errorMessages(instance);

  const { setSaveForLaterId } = useSaveForLater(instance);

  const { setUserSessionDatainLS } = useUserSessionData(instance);

  const signInGuest = async () => {
    try {
      // use currently stored usid in order to retrieve the cart that's been
      // assigned to the lost guest token
      const storedUsid = ls.getItem('usid');
      const userId = storedUsid || uuidv4();
      let guestSessionData: TokensObject;
      try {
        guestSessionData = (await signInGuestAPI(userId)).data;
      } catch (innerErr) {
        // in case the customer was logged in but the token cookies are gone
        // we still have the registered customer's usid stored in localstorage
        // and it results in the error below - in such case we need to retry with freshly generated usid
        if (
          innerErr.response?.data?.errorDetails?.[0]?.defaultError ===
          'USID has already been assigned to another user.'
        ) {
          guestSessionData = (await signInGuestAPI(uuidv4())).data;
        } else {
          throw innerErr;
        }
      }

      // only reset data when confirmed the customer is guest and usid differs from the stored one
      // as it might happen the preceding refresh token fails and then FE goes with a new guest session
      // but Mule will retry to refresh registered token when registered refresh cookie is present
      if (
        !guestSessionData?.consumerType &&
        guestSessionData?.usid !== storedUsid
      ) {
        ls.clearStorage();
        // Clear any remainings of old cart
        useCart(instance).resetCart();
        // set session data in local storage: usid, consumerId, consumerNo
        setUserSessionDatainLS(guestSessionData);
        setSaveForLaterId('');
        const { setFavoriteId, setFavoritesCount } = useFavorites(instance);
        setFavoriteId('');
        setFavoritesCount(0);
      }
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  return {
    signInGuest,
  };
};
