import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { SignUpResponse, SignUpRequest } from '../../api-types';

export const signUp = (configuration: ApiClientConfiguration) => async (
  data: SignUpRequest,
  usid: string
): Promise<AxiosResponse<SignUpResponse>> => {
  const { siteId, locale } = configuration;

  return axios.post<SignUpResponse>(
    urlBuilder(endpoints.get(configuration).authentication.signUp, {
      config: configuration,
    }),
    {
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      email: data.email,
      postalCode: data.postalCode,
      birthDate: data.birthDate,
      phone: data.phone,
      country: data.country,
      locale: locale,
      source: {
        store: data.store || siteId,
        campaignName: data.campaignName,
        acquisitionType: 'Registered',
      },
      subscriptions: {
        newsletterConsent: data.isSubscribed,
        loyaltyConsent: data.enrollNewUsersToLoyalty && data.isTermsConfirmed,
        loyaltylNewsletterConsent:
          data.enrollNewUsersToLoyalty && data.isSubscribed,
        smsNotificationConsent: data.smsNotificationConsent,
      },
      isTermsConfirmed: data.isTermsConfirmed,
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
        'x-consumer': usid,
        ...(data.source && { source: data.source }),
        ...(data.channel && { channel: data.channel }),
      }),
      params: {
        captchaResponse: data.recaptchaToken,
      },
    }
  );
};
