import {
  ProductReviewRatingDistribution,
  ProductReviewRollup,
} from 'packages/api-contract/src/entities';

export const getReviewRatingDistribution = (
  rollup: ProductReviewRollup
): ProductReviewRatingDistribution[] =>
  rollup.ratingHistogram
    .map((value, index) => ({ stars: index + 1, value: value }))
    .reverse();
