const fixEmptyFields = (address) =>
  // TODO this is helper could be moved to sharded code
  Object.fromEntries(
    Object.entries(address).map(([key, value]) => [key, value ?? ''])
  );

export const mapAddress = (address) =>
  fixEmptyFields({
    ...address,
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.city,
    postalCode: address.postalCode,
    countryCode: address.country || address.countryCode,
    stateCityProvDept: address.province,
    recipientFirstName: address.firstName,
    recipientLastName: address.lastName,
    recipientContactPhone: address.phone,
    recipientContactEmail: address.email,
    subscriptions: {
      newsletterConsent: false,
    },
  });
