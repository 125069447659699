import { CMPlaceholder } from '@vf/api-contract';
import { extractProperty } from './utils';

export const curalateSocialMedia = (data: CMPlaceholder) => {
  return {
    component: 'CuralateSocialMedia',
    props: {
      type: extractProperty(data, 'localSettings.dynamicFields.type'),
      filter: extractProperty(data, 'localSettings.dynamicFields.filter'),
      customContainerID: extractProperty(
        data,
        'localSettings.dynamicFields.customContainerID'
      ),
    },
  };
};
