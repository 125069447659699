import { ROUTES, useAccount, useGtm, useI18n } from '../../index';
import useFitFinder from '../../useFitFinder';
import { ComposableContext } from '../types';
import { useUserStore } from '../../store/user';
import { storeToRefs } from 'pinia';

export const afterSSR = async (
  context: ComposableContext,
  type: string
): Promise<void> => {
  switch (type) {
    case 'PDP':
      return pdpAfterSSR(context);
    case 'product':
    case 'PLP':
    case 'category':
      return null;

    default:
      return defaultPageAfterSSR(context);
  }
};

const defaultPageAfterSSR = (context: ComposableContext) => {
  const userStore = useUserStore(context.instance);
  const { loggedIn } = storeToRefs(userStore);
  const { localePath } = useI18n(context.instance);
  const { basicInformation, getBasicInformation } = useAccount(
    context.instance
  );

  if (
    context.instance.$route.path.startsWith(localePath(ROUTES.ACCOUNT())) &&
    loggedIn.value &&
    !basicInformation.value.email // When a component has already called getBasicInformation
  ) {
    getBasicInformation();
  }
};

const pdpAfterSSR = (context: ComposableContext) => {
  const { dispatchEvent } = useGtm(context.instance);
  const { exposeFitFinderData } = useFitFinder(context.instance);
  exposeFitFinderData(window);
  dispatchEvent({
    eventName: 'productDetailView',
    composablesContexts: { useProduct: 'page-content' },
    overrideAttributes: {},
  });
};
