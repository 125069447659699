import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { ForgotPasswordRequest, ForgotPasswordResponse } from '../../api-types';
import type { ApiClientConfiguration } from '../../configuration';

export const forgotPassword = (configuration: ApiClientConfiguration) => (
  data: ForgotPasswordRequest
): Promise<AxiosResponse<ForgotPasswordResponse>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).customer.forgotPassword, {
      config: configuration,
    }),
    {
      username: data.login,
      recaptcha_response: data.recaptchaToken,
    },
    {
      headers: headerBuilder(configuration),
    }
  );
};
