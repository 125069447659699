import type { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { ssrRef, Ref } from '@nuxtjs/composition-api';
import { useMonetate } from '../useMonetate';
import useProduct from '../useProduct';
import { Product } from '@vf/api-client';
import { MonetateRecommendedProduct, UpsellsItem } from '@vf/api-contract';
import { ProductUpsellItem } from '@vf/api-client/src/types';

export type UseUpsell = {
  isUpsellSelected: Ref<boolean>;
  upsellItem: Ref<UpsellsItem>;
  getRecommendedItem: (
    product: Product,
    useMonetateFallback: boolean,
    experienceId?: string
  ) => Promise<void>;
  setIsUpsellSelected: (isSelected: boolean) => void;
};

export type UseUpsellStorage = {
  isUpsellSelected: Ref<boolean>;
  upsellItem: Ref<UpsellsItem>;
};

export interface UpsellItem {
  name: string;
  quantity: number;
  price: {
    original: number;
    current: number;
    currency: string;
  };
  custom: boolean;
  upsell: boolean;
  image: {
    url: string;
    alt: string;
  };
  savedForLater: boolean;
}

/**
 * Composable for pdp upsell
 * @param {ComponentInstance} instance root vue instance
 */
const useUpsell = (
  instance: ComponentInstance,
  contextKey: string
): UseUpsell => {
  const { extractRecommendedItems } = useMonetate(instance, contextKey);
  const { product: recommendedItem, getProductDetails } = useProduct(
    instance,
    'useProduct-upsell'
  );

  const storage: ComposablesStorage<UseUpsellStorage> = initStorage<UseUpsellStorage>(
    instance,
    'useUpsell'
  );

  const upsellItem: Ref<UpsellsItem> =
    storage.get('upsellItem') ??
    storage.save('upsellItem', ssrRef(null, `useUpsell-upsellItem`));

  const isUpsellSelected: Ref<boolean> =
    storage.get('isUpsellSelected') ??
    storage.save(
      'isUpsellSelected',
      ssrRef(false, `useUpsell-isUpsellSelected`)
    );

  const isMonetateRecommendedProduct = (
    item: ProductUpsellItem | MonetateRecommendedProduct
  ): item is MonetateRecommendedProduct =>
    !!(item as MonetateRecommendedProduct).itemGroupId;

  const getRecommendedItem = async (
    pdpProduct: Product,
    useMonetateFallback = false,
    experienceId?: string
  ) => {
    if (pdpProduct.upsellProducts) {
      const items = pdpProduct.upsellProducts;
      if (items.length) {
        setUpsellItem(getUpsellItem(items[0]));
        return;
      }
    }

    if (!useMonetateFallback || !experienceId) return emptyUpsellItem();

    const monetateItems = extractRecommendedItems(experienceId);

    if (!monetateItems || !monetateItems.length) return emptyUpsellItem();

    const product = await getProductDetails(monetateItems[0].itemGroupId, {
      isBackgroundRequest: true,
      loadImages: false,
      saveVariation: false,
    });
    setUpsellItem(
      getUpsellItem(monetateItems[0], product.variant?.price.currency)
    );
  };

  const getUpsellItem = (
    product: ProductUpsellItem | MonetateRecommendedProduct,
    currency?: string
  ): UpsellsItem =>
    isMonetateRecommendedProduct(product)
      ? {
          id: product.id,
          name: product.title,
          itemGroupId: product.itemGroupId,
          price: {
            currency,
            original: product.price,
            current: product.salePrice,
          },
          custom: false,
          upsell: true,
          image: {
            src: product.imageLink,
            alt: product.title,
          },
          savedForLater: false,
          description: product.description,
          quantity: 1,
          pageUrl: recommendedItem.value.pageUrl,
          sku: recommendedItem.value.sku,
          variant: {},
        }
      : {
          id: product.sku,
          name: product.name,
          itemGroupId: product.id,
          price: {
            currency: product.price.currency,
            original: product.price.original,
            current: product.price.current,
          },
          custom: false,
          upsell: true,
          image: {
            src: instance.$mediaUrlGenerator({
              pid: product.id,
              productName: product.name,
            }) as string,
            alt: product.name,
          },
          savedForLater: false,
          description: product.description,
          quantity: 1,
          pageUrl: product.pageUrl,
          sku: product.sku,
          variant: {},
        };

  const emptyUpsellItem = (): void => {
    upsellItem.value = null;
    isUpsellSelected.value = false;
  };

  const setUpsellItem = (item: UpsellsItem) => (upsellItem.value = item);
  const setIsUpsellSelected = (isSelected: boolean) =>
    (isUpsellSelected.value = isSelected);

  return {
    isUpsellSelected,
    upsellItem,
    getRecommendedItem,
    setIsUpsellSelected,
  };
};

export default useUpsell;
