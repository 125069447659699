import { CMCollection, CmsSite } from '@vf/api-contract';
import { camelCase } from '../dataTransformators/utils/camelCase';
import { getStateManagementData } from './utils';
import { ComposableContext } from '../types';
import * as mappings from './index';

export const pdpButtonsWrapper = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const childComponents = data.teasableItems.map((item) => {
    if (!item.viewtype) {
      return;
    }
    const mappingMethod = mappings[camelCase(item.viewtype)];
    const mapping = mappingMethod(item, context, siteConfiguration, cmsBaseUri);

    return {
      ...mapping,
      props: {
        ...mapping.props,
        _states: getStateManagementData(item),
      },
      slot: item.viewtype === 'pdp-action-buttons' ? 'right' : null,
    };
  });

  return {
    component: 'pdp/ProductButtonsWrapper',
    children: childComponents,
  };
};
