import { useAuthentication } from '../useAuthentication';
import { ComponentInstance } from '../types';
import {
  State,
  States,
  StateConfigNames,
  StateConfigOperator,
  StateGroup,
} from '@vf/api-contract';
import useProduct from '../useProduct';
import useSearch from '../useSearch';
import { useCart } from '../useCart';
import { useSaveForLater } from '../useSaveForLater';
import useSignInToStore from '../useSignInToStore';
import { storeToRefs } from 'pinia';
import { useUserStore } from '../store/user';

export const manageState = (
  states: States[] | State[] | null,
  instance: ComponentInstance,
  contextKey?: string | null
): boolean => {
  if (!states || !states.length) return true;

  const userStore = useUserStore(instance);
  const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);

  const { isEmployee } = useAuthentication(instance);
  const { product } = useProduct(instance, contextKey);
  const { products } = useSearch(instance);
  const { totalItems, outOfStockList, outOfStockFlashErrors } = useCart(
    instance
  );
  const {
    saveForLater,
    deletedFromSavedForLaterProductsList,
  } = useSaveForLater(instance);
  const { employeeConnected, hasStoreCookie } = useSignInToStore(instance);

  const isStateFulfilled = (state: States): boolean => {
    switch (state) {
      case States.LoggedIn:
        return loggedIn.value;
      case States.NotLoggedIn:
        return !loggedIn.value;

      case States.LoyaltyEnrolled:
        return loyaltyEnrolled.value;
      case States.NotLoyaltyEnrolled:
        return !loyaltyEnrolled.value;

      case States.CustomsPDP:
        return product.value?.dummyCustoms;
      case States.NotCustomsPDP:
        return !product.value?.dummyCustoms;

      case States.SearchResults:
        return products.value && products.value.length > 0;
      case States.NoSearchResults:
        return !products.value || products.value.length === 0;

      case States.CartEmpty:
        return (
          !totalItems.value &&
          !saveForLater.value.count &&
          outOfStockList.value.length === 0 &&
          deletedFromSavedForLaterProductsList.value.length === 0 &&
          outOfStockFlashErrors.value.length === 0
        );
      case States.NotCartEmpty:
        return (
          !!totalItems.value ||
          !!saveForLater.value.count ||
          outOfStockList.value.length > 0 ||
          deletedFromSavedForLaterProductsList.value.length > 0 ||
          outOfStockFlashErrors.value.length > 0
        );

      case States.EmployeeInstoreLoggedIn:
        return employeeConnected.value;
      case States.EmployeeInstoreNotLoggedIn:
        return !employeeConnected.value;

      case States.EmployeeLoggedIn:
        return isEmployee.value;
      case States.EmployeeNotLoggedIn:
        return !isEmployee.value;

      case States.InStoreDevice:
        return hasStoreCookie.value;

      default:
        return true;
    }
  };

  const stateConfigOperatorHandlers = {
    /* merge results of boolean array with assigned operator (only for group states),
     [key as enum value]: value as Higher order function, with THIS bound as array
     (reminder: "this" in arrow functions is not working the same way as in "function") */
    [StateConfigOperator.AND]: Array.prototype.every,
    [StateConfigOperator.OR]: Array.prototype.some,
    [StateConfigOperator.NOT]: function (predicate) {
      return !Array.prototype.some.call(this, predicate);
    },
  };

  const handleStateGroup = (stateGroup: StateGroup): boolean => {
    const operatorHandler =
      stateConfigOperatorHandlers[stateGroup.values.operator];
    if (!operatorHandler) {
      instance.$log.error(
        '[@utils/stateManager.ts::handleStateGroup] Unhandled state operator.'
      );
      return false;
    }

    const iterableStates = stateGroup.values.states || [];
    const iterabreSegments = stateGroup.values.segment || [];

    return operatorHandler.call(
      [...iterableStates, ...iterabreSegments],
      (stateOrSegment) => {
        // If it is state:
        if (typeof stateOrSegment === 'string') {
          return isStateFulfilled(stateOrSegment as States);
        }
        // If it is segment:
        return instance.$root.$segmentsChecker([stateOrSegment]);
      }
    );
  };

  const resolveState = (state: State | States): boolean => {
    if (typeof state === 'string') return isStateFulfilled(state);
    switch (state.name) {
      case StateConfigNames.STATES:
        return isStateFulfilled(state.values.state);
      case StateConfigNames.STATES_GROUP:
        return handleStateGroup(state);
      default:
        instance.$log.error(
          '[@utils/stateManager.ts::handleStateGroup] Unknown state name.'
        );
        return false;
    }
  };

  return Array.prototype.every.call(states, (state) => resolveState(state));
};
