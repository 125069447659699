import { CmsSite } from '@vf/api-contract';
import { extractCommonConfigLink } from '../utils';

const dataAttribute = `data-commonconfigkey`;
const getRegex = (pattern = '[a-z-]*') => {
  return new RegExp(`${dataAttribute}\=('\|"\)(${pattern})('\|"\)`, 'g');
};

export const getTextWithAttrHrefFromCommonConfig = (
  str: string,
  siteConfiguration: CmsSite
) => {
  let htmlText = str ?? '';
  if (!htmlText) return htmlText;

  const configKeys =
    htmlText
      .match(getRegex())
      /** slice extracted keys from string (data-commonconfigkey="key-name") */
      ?.map((key) => key.slice(dataAttribute.length + 2, -1)) || [];

  configKeys.forEach((key) => {
    const [link] = extractCommonConfigLink(siteConfiguration, key);
    if (!link?.id) {
      console.warn(
        'Cannot replace link with common config link. configKey does not exist',
        { key }
      );
      return;
    }

    htmlText = htmlText.replace(getRegex(key), `data-href='${link.id}'`);
  });

  return htmlText;
};
