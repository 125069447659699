import { CMTeaser } from '@vf/api-contract';
import { getTranslation, getDynamicField } from './utils';

export const billingAddressesSelect = (data: CMTeaser) => {
  return {
    component: 'addressBook/BillingAddressPicker',
    props: {
      showEditButton: true,
      showDeleteButton: true,
      showDefaultAddressOnTopRow: getDynamicField(
        data,
        'showDefaultAddressOnTopRow',
        false
      ),
      translations: {
        heading: getTranslation(data, 'heading', '$Billing Addresses$'),
        subtitle: getTranslation(
          data,
          'subheading',
          '$Select your default billing address$'
        ),
        buttonText: getTranslation(data, 'buttonText', '$Add Billing Address$'),
        editButton: getTranslation(data, 'editButton', '$Edit$'),
        deleteButton: getTranslation(data, 'deleteButton', '$Delete$'),
        deleteSuccess: getTranslation(
          data,
          'deleteSuccess',
          '$Address deleted$'
        ),
        deleteError: getTranslation(
          data,
          'deleteError',
          '$Address delete error$'
        ),
        updateSuccess: getTranslation(data, 'updateSuccess', '$Address saved$'),
      },
    },
  };
};
