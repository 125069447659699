import type { AxiosResponse } from 'axios';
import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const submitContactForm = (configuration: ApiClientConfiguration) => (
  body: unknown
): Promise<AxiosResponse> => {
  return axios.post(
    endpoints.get(configuration).contactForm.submitContactForm,
    body
  );
};
