var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-price"},[(_vm.isDiscount)?_c('span',{staticClass:"sr-only"},[_vm._v("\n    Minus "+_vm._s(_vm.regularValue.replace('-', ''))+"\n  ")]):_vm._e(),_vm._v(" "),(!_vm.special)?_c('VfText',{class:['vf-price--regular', { 'vf-price--small': _vm.small }],attrs:{"tag":"span","aria-hidden":_vm.isDiscount || null,"content":_vm.regularValue}}):_c('span',{staticClass:"vf-price"},[(!_vm.html && _vm.regularValue)?_c('VfText',{class:[
        'vf-price--strike',
        {
          'vf-price--no-strikethrough': !_vm.strikeOriginalPrice,
          'vf-price--small': _vm.small,
          'vf-price--show-after': _vm.showRegularPriceAfter,
          'vf-price--regular-price-colored': _vm.specialPriceColored,
        } ],attrs:{"tag":_vm.strikeOriginalPrice ? 'del' : 'p',"content":_vm.regularValue}}):_c('VfText',{class:[
        {
          'vf-price--small': _vm.small,
          'vf-price--show-after': _vm.showRegularPriceAfter,
        } ],attrs:{"html":_vm.html,"content":_vm.regularValue}}),_vm._v(" "),_c('VfText',{class:[
        'vf-price--sale',
        {
          'vf-price--small': _vm.small,
          'vf-price--special-price-colored':
            _vm.specialPriceColored && _vm.regularValue,
          'vf-price--html': _vm.html,
        } ],attrs:{"tag":"ins","content":_vm.specialValue}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }