import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import {
  AddressValidationRequest,
  AddressValidationResponse,
} from '../../api-types';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const addressValidation = (configuration: ApiClientConfiguration) => (
  address: AddressValidationRequest,
  cartId: string
): Promise<AxiosResponse<AddressValidationResponse>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).checkout.addressValidation,
    {
      config: configuration,
      mapping: {
        cartId,
      },
    }
  );
  return axios.post(url, address, {
    headers: headerBuilder(configuration),
  });
};
