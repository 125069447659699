import type { CmsMenuResponse } from '@vf/api-contract';
import type { ComponentInstance } from '../types';
import type { ComposableContext, InitializedApiClient } from '../useCms/types';

import { sanitizeCmsHeaderAndFooterResponse } from '../useCms/dataTransformators';
import { computed } from '@nuxtjs/composition-api';
import * as dataFetcher from '../useCms/dataFetcher';
import { getHeaderConfig } from '../useCms/utils';
import { useHeaderAndFooterStore } from '../store/headerAndFooter';
import { useCmsRefStore } from '../store/cmsRef';

interface IHeaderAndFooterContent {
  content: CmsMenuResponse;
  errors: string[];
}

const useHeaderAndFooter = (
  instance: ComponentInstance,
  verifySetup: any,
  apiClientRef: InitializedApiClient | null,
  contextKey?: string
) => {
  const context: ComposableContext = { instance, contextKey };
  const headerAndFooterStore = useHeaderAndFooterStore(instance.$pinia);
  const cmsRefStore = useCmsRefStore(instance.$pinia);

  const sanitizeHeaderAndFooterData = (headerAndFooterContent) => {
    // sanitize header and footer data
    const headerAndFooterSanitized = sanitizeCmsHeaderAndFooterResponse(
      headerAndFooterContent.content,
      cmsRefStore.cmsSiteConfiguration,
      context,
      cmsRefStore.baseMediaUri,
      headerAndFooterStore.headerConfig
    );

    const errors = headerAndFooterContent.errors.concat(
      headerAndFooterSanitized.errors
    );

    return {
      content: headerAndFooterSanitized.data,
      errors,
    };
  };

  const getHeaderAndFooterData = async () => {
    if (headerAndFooterStore.headerAndFooterData) {
      // If data is fetched already return with no errors and empty content,
      // header and footer data never updates into empty - check updatePageContentRefs
      return { errors: [], content: {} };
    }

    // Fetch header and footer data
    const headerAndFooterContent: null | IHeaderAndFooterContent = await dataFetcher.menu.getHeaderAndFooterData(
      cmsRefStore.cmsSiteConfiguration.root.id,
      apiClientRef
    );
    // Store header and footer data
    headerAndFooterStore.$patch({
      headerAndFooterData: headerAndFooterContent as any,
    });

    return sanitizeHeaderAndFooterData(headerAndFooterContent);
  };

  const updateHeaderAndFooterData = () => {
    const sanitizedHeaderAndFooterData: any = sanitizeHeaderAndFooterData(
      headerAndFooterStore.headerAndFooterData
    );
    const errors = [...headerAndFooterStore.errors].concat(
      sanitizedHeaderAndFooterData.errors
    );
    headerAndFooterStore.$patch({
      header: sanitizedHeaderAndFooterData.content.header,
      footer: sanitizedHeaderAndFooterData.content.footer,
      errors,
    });
  };

  const fetchHeaderAndFooter = async () => {
    if (verifySetup()) {
      const headerAndFooterData: {
        content: any;
        errors: string[];
      } = await getHeaderAndFooterData();
      headerAndFooterStore.$patch({
        header: headerAndFooterData.content.header
          ? headerAndFooterData.content.header
          : headerAndFooterStore.header,
        footer: headerAndFooterData.content.footer
          ? headerAndFooterData.content.footer
          : headerAndFooterStore.footer,
      });

      // TODO Consider enhance getHeaderConfig fn to configure headerConfig based on specific static page config
      if ((instance as any).layoutName.includes('cms')) {
        return;
      }
      headerAndFooterStore.$patch({
        headerConfig: getHeaderConfig(null),
      });
    }
  };

  return {
    updateHeaderAndFooterData,
    fetchHeaderAndFooter,
    getHeaderAndFooterData,
    sanitizeHeaderAndFooterData,
    header: computed(() => headerAndFooterStore.header),
    footer: computed(() => headerAndFooterStore.footer),
    errors: computed(() => headerAndFooterStore.errors),
    headerConfig: computed(() => headerAndFooterStore.headerConfig),
  };
};

export default useHeaderAndFooter;
