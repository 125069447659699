import { EventPropsHandler } from '.';
import {
  GtmProductObject,
  ProductDetailViewEvent,
  ProductDetailViewViewType,
} from '../../types/gtm';
import useProduct from '../../useProduct';
import useGtm from '../../useGtm';
import {
  getCustomsCategory,
  getDiscountTypesByPrice,
  getStyleCodePDP,
  getVirtualStyleCode,
  recipeID,
} from './helpers';
import { Product } from '@vf/api-client';
import { useI18n } from '../../useI18n';

export const mapProductDetail = (
  prod: Product,
  { brand, persistentVariables, $themeConfig }
): GtmProductObject => {
  const styleCode = getStyleCodePDP(prod, $themeConfig);
  const color = prod.color || prod.colors?.[0];
  const colorCode = color.value;
  const colorDescription = prod.colorDescription || color.label;
  const productId = $themeConfig.gtm?.useColorOnId
    ? prod.id + colorCode
    : prod.id;

  const getSizeAvailabilityState = (size) => {
    return prod.variants.some(
      (variant) =>
        variant.attributes.color == prod.color.value &&
        variant.attributes.size == size.value
    );
  };

  const availableSizes = prod.sizes.filter((size) => {
    return size.available && prod.color?.value
      ? getSizeAvailabilityState(size)
      : true;
  });

  return {
    ...persistentVariables,
    availSizes: availableSizes.map((size) => size.value),
    avgRating: prod.rating.score.toString(),
    badge: prod.labels[0],
    brand,
    bundleId: undefined,
    catalogCategory: prod.primaryCategoryName,
    colorCode,
    colorDescription,
    customized: !!recipeID(prod),
    customModel: prod.customModel || undefined,
    customsTool: prod.customsTool || undefined,
    discountType: getDiscountTypesByPrice(prod.variant) || undefined,
    id: productId,
    masterId: prod.masterId,
    merchantProductId: prod.merchantProductId,
    name: prod.name?.trim(),
    numReviews: prod.rating.numReviews,
    onSale: prod.variant.price.original !== prod.variant.price.current,
    originalPrice: prod.variant.price.original,
    preCreatedCustomCode: prod.dummyCustoms ? prod.id : undefined,
    price: prod.variant.price.current,
    recipeID: recipeID(prod) || undefined,
    saleDiscountAmount: prod.variant.price.discount || 0,
    season: prod.season || undefined,
    styleCode,
    variant: colorDescription,
    lowOnStock:
      prod.variant.stock.quantity > 0 && prod.variant.stock.quantity < 10,
    virtualStyleCode: getVirtualStyleCode(prod.colorBundleCode),
  };
};

export const productDetailViewHandler: EventPropsHandler<
  ProductDetailViewEvent,
  { viewType?: ProductDetailViewViewType }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);
  const { product } = useProduct(
    vmInstance,
    eventProps.composablesContexts.useProduct
  );
  const {
    updatePersistentVariables,
    gtmPersistentVariables,
    gtmProductTraceMap,
  } = useGtm(vmInstance);

  const prod = product?.value;

  const viewType =
    eventProps.overrideAttributes.viewType ||
    gtmProductTraceMap.value[prod.id]?.viewType ||
    ProductDetailViewViewType.PDP;

  const persistentVariables = updatePersistentVariables(prod.id, {
    category: getCustomsCategory(
      prod,
      gtmPersistentVariables.value.category || 'Direct to PDP'
    ),
  });

  const productObject = mapProductDetail(prod, {
    brand: vmInstance
      .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
      .toUpperCase(),
    persistentVariables,
    $themeConfig: vmInstance.$themeConfig,
  });
  updatePersistentVariables(productObject.id, persistentVariables);

  return {
    event: 'productDetailView',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Detail View',
    eventLabel: productObject.id,
    nonInteractive: true,
    ecommerce: {
      currencyCode: prod?.variant?.price?.currency || defaultCurrency.value,
      detail: {
        actionField: { viewType },
        products: [productObject],
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
