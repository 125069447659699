import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const shareCart = (configuration: ApiClientConfiguration) => (
  body: unknown
): Promise<any> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).cart.share, {
      config: configuration,
    }),
    body,
    {
      headers: headerBuilder(configuration),
    }
  );
};
