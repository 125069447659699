import { computed, ComputedRef, Ref, ssrRef } from '@nuxtjs/composition-api';
import { useValidation } from '../../../useValidation';
import {
  Product,
  ProductAttribute,
  ProductAttributeCodes,
  ProductAttributeValues,
  ProductInventoryState,
} from '@vf/api-client';
import { ProductVariant } from '@vf/api-client/src/types';
import initStorage from '../../../utils/storage';
import { ComposablesStorage, ComponentInstance } from '../../../types';
import {
  COMING_SOON_BADGE,
  AttributesData,
  UseProductStorage,
  VariantsData,
  ProductConfiguration,
} from '../../types';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export const useProductAttributes = (
  instance: ComponentInstance,
  {
    product,
    configure,
  }: {
    product: Ref<Product>;
    configure: (configuration: ProductConfiguration) => void;
  },
  contextKey: string
) => {
  const storage: ComposablesStorage<UseProductStorage> = initStorage<UseProductStorage>(
    instance,
    `useProduct-${contextKey}`
  );
  const $themeConfig = instance.$themeConfig;
  const { areComingSoonProductsEnabled } = useFeatureFlagsStore();

  const defaultAttributes = [
    {
      code: ProductAttributeCodes.Color,
      outputKey: 'colors',
      logError: true,
      fallback: [
        {
          available: false,
          badges: [],
          description: '',
          fullPageUrl: '',
          label: '',
          modelMeasurements: [],
          pageURL: '',
          presale: false,
          slug: '',
          value: '',
        },
      ],
    },
    {
      code: ProductAttributeCodes.Size,
      outputKey: 'sizes',
      logError: true,
      fallback: [{ label: '', value: '', available: false }],
    },
    {
      code: ProductAttributeCodes.Length,
      outputKey: 'lengths',
      logError: false,
      fallback: [],
    },
    {
      code: ProductAttributeCodes.Width,
      outputKey: 'widths',
      logError: false,
      fallback: [],
    },
    {
      code: ProductAttributeCodes.Zip,
      outputKey: 'zips',
      logError: false,
      fallback: [],
    },
  ];

  const attributesDataEmptyObject: AttributesData = {
    attributeCodes: [],
    attributeGroupingPrices: null,
    attributeOptionsAttName: {},
    isAttributePriceVariationClicked: false,
    isFutureProduct: false,
    isInStock: true,
    isSoldOut: false,
    isOutOfStock: false,
    showSizeSelector: true,
  };

  const VariantsDataEmptyObject: VariantsData = {
    areAllVariantsInStock: false,
    areAllVariantsOutOfStock: false,
    remappedVariantsByPriceAndAttributes: [],
    sortedVariantsByCurrentPrice: [],
    sortedVariantsByOriginalPrice: [],
  };

  const attributesData: Ref<AttributesData> =
    storage.get('attributesData') ??
    storage.save(
      'attributesData',
      ssrRef(
        attributesDataEmptyObject,
        `useProduct-attributesData-${contextKey}`
      )
    );

  const variantsData: Ref<VariantsData> =
    storage.get('variantsData') ??
    storage.save(
      'variantsData',
      ssrRef(VariantsDataEmptyObject, `useProduct-variantsData-${contextKey}`)
    );

  const emptyAttributesData = (): void => {
    attributesData.value = {
      attributeCodes: [],
      attributeGroupingPrices: null,
      attributeOptionsAttName: {},
      isAttributePriceVariationClicked: false,
      isFutureProduct: false,
      isInStock: true,
      isSoldOut: false,
      isOutOfStock: false,
      showSizeSelector: true,
    };
  };

  const emptyVariantsData = (): void => {
    variantsData.value = {
      areAllVariantsInStock: false,
      areAllVariantsOutOfStock: false,
      remappedVariantsByPriceAndAttributes: [],
      sortedVariantsByCurrentPrice: [],
      sortedVariantsByOriginalPrice: [],
    };
  };

  const mapAttributesAndVariantsData = (): void => {
    mapVariantsData();
    mapAttributesData();
  };

  const mapAttributesData = (): void => {
    try {
      attributesData.value.attributeGroupingPrices = getAttributeGroupingPrices();
      attributesData.value.isFutureProduct = getIsFutureProduct();
      attributesData.value.isInStock =
        product.value.productInventoryState === ProductInventoryState.InStock;
      attributesData.value.isSoldOut =
        product.value.productInventoryState === ProductInventoryState.SoldOut;
      attributesData.value.isOutOfStock =
        product.value.productInventoryState ===
        ProductInventoryState.OutOfStock;
      attributesData.value.showSizeSelector = getShowSizeSelector();
    } catch (error) {
      instance.$log.error(
        `[@useProductAttributes::mapAttributesData] Error while mapping attributes for product <${product.value.id}>. Error: ${error.message}`
      );
    }
  };

  const mapVariantsData = (): void => {
    try {
      variantsData.value.remappedVariantsByPriceAndAttributes = mapVariantsByPriceAndAttributes();
      variantsData.value.sortedVariantsByCurrentPrice = sortVariantsByPriceType(
        'current'
      );
      variantsData.value.sortedVariantsByOriginalPrice = sortVariantsByPriceType(
        'original'
      );

      variantsData.value.areAllVariantsOutOfStock = product.value.variants.every(
        (variant: ProductVariant) => !variant.stock.inStock
      );
      variantsData.value.areAllVariantsInStock = product.value.variants.every(
        (variant: ProductVariant) => variant.stock.inStock
      );
    } catch (error) {
      instance.$log.error(
        `[@useProductAttributes::mapVariantsData] Error while mapping variants for product <${product.value.id}>. Error: ${error.message}`
      );
    }
  };

  const mapProductAttributeOptions = (
    slug: string,
    responseData: any,
    instance: ComponentInstance
  ): any => {
    const result = {
      ...responseData,
      colors: [],
      sizes: [],
      lengths: [],
      zips: [],
      widths: [],
    };

    try {
      defaultAttributes.forEach((parsingAttribute) => {
        const attData = responseData.attributes.find(
          (attribute: ProductAttribute) =>
            attribute.code === parsingAttribute.code
        );
        if (attData) {
          // for reactivity purposes, we need to explicitly set `available` property
          // as it's sometimes not present in the API response
          const options = (attData.options || []).map((option) => ({
            ...option,
            available: Boolean(option.available),
          }));
          attData.options = options;
          result[parsingAttribute.outputKey] = options;
          attributesData.value.attributeCodes.push(parsingAttribute.code);
          attributesData.value.attributeOptionsAttName = {
            ...attributesData.value.attributeOptionsAttName,
            [parsingAttribute.code]: parsingAttribute.outputKey,
          };
        } else {
          if (parsingAttribute.logError) {
            instance.$log.error(
              `[@useProductAttributes::mapProductAttributeOptions] Missing <options> property of <${parsingAttribute.code}> attribute for product <${slug}>, using fake list as a fallback. Please check catalog configuration for that product.`
            );
          }
          result[parsingAttribute.outputKey] = parsingAttribute.fallback;
        }
      });
    } catch (error) {
      instance.$log.error(
        `[@useProductAttributes::mapProductAttributeOptions] Error while mapping attributes for product <${slug}>. Error: ${error.message}`
      );
    }
    return result;
  };

  const getSingleOptionAttributeValue = (
    variantsByColor: ProductVariant[],
    attributeName: string
  ): string | null => {
    const availableAttribute = new Set();
    for (const i in variantsByColor) {
      if (availableAttribute.size < 2) {
        availableAttribute.add(variantsByColor[i].attributes[attributeName]);
      } else {
        break;
      }
    }
    if (availableAttribute.size === 1) {
      const [firstValue] = availableAttribute;
      return firstValue as string;
    }
    return null;
  };

  const getDefaultAttributesOptionValues = () => {
    const defaultAttributesOptionValues = {
      lengthValue: '',
      widthValue: '',
      zipValue: '',
      sizeValue: '',
    };

    if (!product.value) return defaultAttributesOptionValues;

    attributesData.value.attributeCodes.forEach((code) => {
      if (code !== ProductAttributeCodes.Color) {
        // preselect if only one is available
        const singleOptionAttributeValue = getSingleOptionAttributeValue(
          availableAttributes.value.color,
          code
        );
        if (singleOptionAttributeValue) {
          defaultAttributesOptionValues[
            `${code}Value`
          ] = singleOptionAttributeValue;
        }
      }
    });

    return defaultAttributesOptionValues;
  };

  const setSingleOptionAttributes = (): void => {
    if (!product.value) return;

    attributesData.value.attributeCodes.forEach((code) => {
      if (code !== ProductAttributeCodes.Color) {
        // preselect if only one is available
        const firstValue = getSingleOptionAttributeValue(
          availableAttributes.value.color,
          code
        );
        if (firstValue) {
          configure({
            [code]: firstValue,
          });
        }
      }
    });
  };

  const getPartialId = (id: string, attr: string): string => {
    return id.replace(`:${attr}:`, ':');
  };

  const getAttributeGroupingPrices = (): string =>
    product.value.attributes.find((attribute: ProductAttribute) =>
      isAttributePriceVariation(attribute.code)
    )?.code ?? ProductAttributeCodes.Color;

  const mapVariantsByPriceAndAttributes = () => {
    if (!product.value?.displayPriceRange) {
      return [];
    }

    const respAtt = attributesData.value.attributeCodes.map((attr) => {
      const attrSet = new Set();
      let partialId;
      return product.value.variants.reduce((acc, variant) => {
        const priceKey = `${variant.price.current || 'no'}:${
          variant.price.original
        }`;
        !partialId &&
          (partialId = getPartialId(variant.id, variant.attributes[attr]));
        if (
          acc[attr] &&
          !attrSet.has(variant.attributes[attr]) &&
          partialId === getPartialId(variant.id, variant.attributes[attr])
        ) {
          if (!acc[attr][priceKey]) {
            acc[attr][priceKey] = {
              options: [],
              price: variant.price,
              firstVariantAttribute: variant.attributes[attr],
              priceKey,
            };
          }
          acc[attr][priceKey].options.push(variant);
          attrSet.add(variant.attributes[attr]);
          partialId = getPartialId(variant.id, variant.attributes[attr]);
        } else if (
          !attrSet.has(variant.attributes[attr]) &&
          partialId === getPartialId(variant.id, variant.attributes[attr])
        ) {
          acc[attr] = {
            [priceKey]: {
              options: [variant],
              price: variant.price,
              firstVariantAttribute: variant.attributes[attr],
              priceKey,
            },
          };
          attrSet.add(variant.attributes[attr]);
          partialId = getPartialId(variant.id, variant.attributes[attr]);
        }
        return acc;
      }, {});
    });
    return respAtt;
  };

  const isAttributePriceVariation = (attribute: string): boolean => {
    return variantsData.value.remappedVariantsByPriceAndAttributes.some(
      (el: ProductVariant) =>
        (el[attribute] ? Object.keys(el[attribute]).length : 0) > 1
    );
  };

  const setAttributePriceVariationClicked = (value: boolean): void => {
    attributesData.value.isAttributePriceVariationClicked = value;
  };

  const areAllAttributesSelected: ComputedRef<boolean> = computed(() => {
    return (
      product.value?.id &&
      !!attributesData.value.attributeCodes.length &&
      attributesData.value.attributeCodes.every(
        (attribute) => product.value[attribute]
      )
    );
  });

  const availableAttributes = computed(() => {
    const availableVariants = {
      size: [],
      length: [],
      width: [],
      zip: [],
      color: [],
    };

    if (!product.value) {
      return availableVariants;
    }

    const productAttributeCodes = attributesData.value.attributeCodes;

    productAttributeCodes.forEach((attribute) => {
      const otherAttributes = productAttributeCodes.filter(
        (attCode) => attCode !== attribute
      );

      availableVariants[attribute] = product.value.variants.filter(
        (variant: ProductVariant) => {
          if (otherAttributes.every((otherAtt) => !product.value[otherAtt])) {
            return variant;
          }

          return (
            variant.stock?.inStock &&
            otherAttributes.every(
              (otherAtt) =>
                !product.value[otherAtt] ||
                variant.attributes[otherAtt] === product.value[otherAtt].value
            )
          );
        }
      );
    });
    return availableVariants;
  });

  const checkAttributes = ({ lazy } = { lazy: false }): boolean => {
    let isError = false;

    if (product.value) {
      product.value.attributes.forEach((attr: ProductAttribute) => {
        if (attr.code !== ProductAttributeCodes.Color) {
          const validator = useValidation(
            instance,
            attr.code.toUpperCase() + '_SELECT'
          );
          if (!validator.$v.value) return;
          if (!lazy) {
            validator.$v.value[attr.code]?.$touch();
            validator.$v.value[attr.code].$dirty = true;
          }
          if (validator.$v.value && validator.$v.value[attr.code]?.$invalid) {
            isError = true;
          }
        }
      });
    }

    return isError;
  };

  const availableColorCodes = computed(
    () =>
      product.value.attributes
        ?.find(({ code }) => code === 'color')
        ?.options.map(({ value }) => value) ?? []
  );

  const sortVariantsByPriceType = (
    type: 'original' | 'current'
  ): ProductVariant[] => {
    return [...product.value.variants]
      .filter(
        (variant) =>
          availableColorCodes.value.includes(variant.attributes.color) &&
          variant.stock.inStock &&
          !!variant.price[type]
      )
      .sort((a, b) => {
        return a.price[type] - b.price[type];
      });
  };

  const getIsFutureProduct = (): boolean =>
    areComingSoonProductsEnabled &&
    product.value?.notifyMe &&
    (product.value?.labels || []).find(
      (badge: string) => badge.toLowerCase() === COMING_SOON_BADGE
    ) &&
    !!product.value?.targetPublishDate;

  const getShowSizeSelector = (): boolean => {
    // hide sizes selector if we have only OneSize value and hideComponentForOneSize is true
    if ($themeConfig.productSizeSelect?.hideComponentForOneSize) {
      const sizes = product.value?.sizes || [];
      return sizes.length === 1 &&
        sizes[0].value === ProductAttributeValues.OneSize
        ? false
        : true;
    }
    return true;
  };

  return {
    attributesData: computed(() => attributesData.value),
    areAllAttributesSelected,
    availableAttributes,
    checkAttributes,
    emptyAttributesData,
    emptyVariantsData,
    getDefaultAttributesOptionValues,
    isAttributePriceVariation,
    mapAttributesAndVariantsData,
    mapVariantsData,
    mapAttributesData,
    mapProductAttributeOptions,
    setAttributePriceVariationClicked,
    setSingleOptionAttributes,
    variantsData,
    defaultAttributes,
  };
};
