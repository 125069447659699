import { CMPlaceholder } from '@vf/api-contract';
import { getDynamicField } from './utils';

export const thirdPartyComponent = (data: CMPlaceholder) => {
  return {
    component: 'shared/CMVendorPlaceholder',
    props: {
      cssClass: getDynamicField(data, 'css-class'),
    },
  };
};
