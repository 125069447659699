import { ApiClientConfiguration } from '../../configuration';
import { axios } from '../../helpers';

export type GetCustomsImageParams = {
  baseUrl: string;
  caid: string;
  recipeId: string;
  view: string;
  size: string;
  locale: string;
};

export const getCustomsImage = (configuration: ApiClientConfiguration) => (
  params: GetCustomsImageParams
): Promise<any> => {
  const url = `${params.baseUrl}/${params.recipeId}?size=${params.size}&view=${params.view}&caid=${params.caid}`;
  configuration.logger.debug('Get Custom Image', { url });
  return axios.get(url);
};
