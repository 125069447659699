import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getProvinceList = (
  configuration: ApiClientConfiguration
) => async (countryCode: string, locale: string): Promise<any> => {
  let url: string = urlBuilder(
    endpoints.get(configuration).utilities.provinceList,
    {
      config: configuration,
      mapping: {
        countryCode,
      },
    }
  );
  url = `${url}?locale=${locale}`;
  const response = await axios.get(url, {
    headers: headerBuilder(configuration),
  });
  return response;
};
