import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  extractProperty,
  getImageObject,
  getMediaSeoAttributes,
  generateLinkFromTeaserTargets,
} from './utils';
import { ComposableContext } from '../types';
import { getDataNavigationValue } from './utils/extractProperty';

export const image = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const picture = data.pictures[0];
  const imageObject = getImageObject(
    picture,
    siteConfiguration,
    null,
    cmsBaseUri,
    data.responsiveMedia
  );
  const { alt, width, height } = imageObject;

  return {
    component: 'common/ImageComponent',
    props: {
      src: imageObject,
      alt,
      width,
      height,
      seo: getMediaSeoAttributes(extractProperty(data, 'pictures[0]')),
      openInNewTab: extractProperty(data, 'openInNewTab'),
      openInNewModal: extractProperty(data, 'openInNewModal'),
      scrollToComponent: extractProperty(data, 'scrollToComponent'),
      dataNavigation: getDataNavigationValue(data),
      link: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
    },
  };
};
