import {
  CMPlaceholder,
  CmsSite,
  SocialNativeLayout,
  SocialNativeTranslations,
} from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractLocalSettingsExpandedLinks,
  extractLocalSettingsExpandedRichText,
  generateLinkFromTarget,
  getDynamicField,
  getTranslation,
} from './utils';

export const socialNative = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const goToLink = extractLocalSettingsExpandedLinks(data, 'goToLink')?.[0];

  const translations: SocialNativeTranslations = {
    title: getTranslation(data, 'title', '$Title$'),
    summary:
      extractLocalSettingsExpandedRichText(data, 'summary', undefined)?.html ||
      '',
    addYourPhoto: getTranslation(data, 'addPhotoLabel', '$Add Your Photo$'),
    shopTheLook: getTranslation(data, 'shopTheLook', '$Shop the Look$'),
    loadMore: getTranslation(data, 'loadMore', '$Load More$'),
    // shop the look translations
    prevControlLabel: getTranslation(data, 'prevControlLabel', '$prev Items$'),
    nextControlLabel: getTranslation(data, 'nextControlLabel', '$next Items$'),
    items: getTranslation(data, 'items', '${count} items$'),
    featuredProducts: getTranslation(
      data,
      'featuredProducts',
      '$Featured Products$'
    ),
    productNameFallback: getTranslation(
      data,
      'productNameFallback',
      '$Product Name Fallback$'
    ),
  };

  const streamId = getDynamicField(data, 'streamId');
  return {
    component: 'shared/SocialNative',
    props: {
      backgroundHex: getDynamicField(data, 'backgroundColor'),
      contextKey: context.contextKey,
      goToLink: goToLink
        ? {
            title: goToLink.title,
            to: generateLinkFromTarget(
              goToLink,
              context,
              siteConfiguration,
              cmsBaseUri
            ),
          }
        : undefined,
      layout: getDynamicField(data, 'layout', SocialNativeLayout.HOMEPAGE),
      streamId: streamId && streamId != '0' ? streamId : undefined,
      categoryId: getDynamicField(data, 'categoryId'),
      textColor: getDynamicField(data, 'textColor'),
      translations,
    },
  };
};
