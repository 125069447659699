import { defineStore } from 'pinia';
import { getCurrentInstance, ref } from '@vue/composition-api';
import { Address } from '@vf/api-client/src/types';
import { getEmptyAddressObject } from '../useCheckout/utils';
import cloneDeep from '@vf/shared/src/utils/cloneDeep';
import { useCartStore } from './cartStore';
import { useFeatureFlagsStore } from './featureFlags';

export const useAddressesStore = (instance?) => {
  // TODO we should use useContext here and this should be used only in components setup
  const currentInstance = getCurrentInstance();
  const localInstance = currentInstance?.proxy || instance;

  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  const cartStore = useCartStore();

  return defineStore('addresses', () => {
    const country = localInstance
      .$getEnvValueByCurrentLocale('COUNTRY')
      .toUpperCase();

    const isPoAddress = ref(false);
    const billingSameAsShipping = ref(true);
    const shippingAddress = ref<Address>(getEmptyAddressObject(country));
    const billingAddress = ref<Address>(getEmptyAddressObject(country));
    const pickupAddress = ref<Address>(getEmptyAddressObject(country));

    const saveShippingAddress = (address?: Address): void => {
      shippingAddress.value = cloneDeep({
        ...getEmptyAddressObject(country),
        ...address,
      }) as Address;
    };

    const clearShippingAddress = (): void => {
      shippingAddress.value = getEmptyAddressObject(country);
    };

    const saveBillingAddress = (address: Address): void => {
      billingAddress.value = address;
    };

    // TODO: GLOBAL15-56318 Move this to cart store and delete addresses store
    const isShippingAddressIncomplete = () => {
      const address = isCheckoutRedesignEnabled
        ? cartStore.fullShippingAddress
        : shippingAddress.value;
      const fieldsToSkip = [
        'id',
        'addressId',
        'addressLine2',
        'addressLine3',
        'stateCode',
        'subscriptions',
      ];
      return Object.keys(address).some((field) => {
        if (fieldsToSkip.includes(field)) return false;
        return !address[field];
      });
    };

    return {
      isPoAddress,
      shippingAddress,
      billingAddress,
      pickupAddress,
      billingSameAsShipping,
      saveShippingAddress,
      saveBillingAddress,
      isShippingAddressIncomplete,
      clearShippingAddress,
    };
  })();
};
