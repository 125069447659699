import { apiClientFactory } from '@vf/api-client';
import { ComponentInstance } from '../types';
import { parseCustomsCodeBySku } from './utils';
import axios from 'axios';

export const useCustoms = (instance: ComponentInstance) => {
  const {
    getCustomsImage,
    getUGCModerationStatus,
    getBundleByRecipe: getBundleByRecipeAPI,
    getPriceByBundlePartNumber: getPriceByBundlePartNumberAPI,
    getSizeByBundlePartNumber: getSizeByBundlePartNumberAPI,
    getCustomsSku: getCustomsSkuAPI,
  } = apiClientFactory(instance);
  const customsImage = async ({
    recipe,
    view = 'pilot_front',
    size = 'square',
  }: {
    recipe: string;
    view?: string;
    size?: string;
  }) => {
    const imageParams = {
      baseUrl: instance.$env.CUSTOMS_GENERATE_IMAGE,
      caid: instance.$env[getCustomsCaid()],
      recipeId: recipe,
      view,
      size,
      locale: instance.$i18n.locale.replace('-', '_'),
    };
    const customsImage = await getCustomsImage(imageParams);
    return customsImage.data?.[`${view}_view`]?.[size];
  };

  const customsPrice = async (recipeId: string) => {
    const product = await getBundleByRecipeAPI(
      recipeId,
      instance.$env.CUSTOMS_PRODUCT
    );
    const price = await getPriceByBundlePartNumberAPI(
      product?.data,
      instance.$env.CUSTOMS_PRICE
    );
    return price.data;
  };

  const customsPdm = async ({ model }: { model: string }) => {
    const url = `${instance.$env.CUSTOMS_PDM}/${model}.json`;
    const pdm = await axios.get(url, {
      params: {
        caid: instance.$env[getCustomsCaid()],
        v: instance.$env.CUSTOMS_VERSION,
      },
    });

    return pdm.data;
  };

  const customsDataByRecipe = async (recipeId: string) => {
    const product = await getBundleByRecipeAPI(
      recipeId,
      instance.$env.CUSTOMS_PRODUCT
    );
    const price = await getPriceByBundlePartNumberAPI(
      product?.data,
      instance.$env.CUSTOMS_PRICE
    );
    const sizesObject = {};
    const sizes = await getSizeByBundlePartNumberAPI(
      product?.data?.bundle_partnumber,
      instance.$env.CUSTOMS_SIZE,
      instance.$i18n.locale.split('-')?.[1] === '001'
        ? 'en_ca'
        : instance.$i18n.locale.replace('-', '_'),
      instance.$env[getCustomsCaid()]
    );
    sizes?.data?.short_display_values?.forEach((k, i) => {
      sizesObject[k] = sizes?.data?.values[i];
    });
    return { ...product?.data, ...price?.data, sizesObject };
  };

  const generateCustomSku = async (recipeId: string, size: string) => {
    const sku = await getCustomsSkuAPI({
      caid: instance.$env[getCustomsCaid()],
      size,
      baseUrl: instance.$env.CUSTOMS_SKU,
      locale:
        instance.$i18n.locale.split('-')?.[1] === '001'
          ? 'en_ca'
          : instance.$i18n.locale.replace('-', '_'),
      recipeId,
    });
    return sku.data;
  };

  const getCustomsCaid = () => {
    const locale =
      instance.$i18n.locale.split('-')?.[1] === '001'
        ? 'EN_CA'
        : instance.$i18n.locale.replace('-', '_').toUpperCase();
    return `CUSTOMS_CAID_${locale}`;
  };

  const getImageModerationStatus = async (recipeId: string) => {
    try {
      const response = await getUGCModerationStatus({
        baseUrl: instance.$env.CUSTOMS_UGC_MODERATION,
        recipeId,
      });

      return response.data.status;
    } catch (e) {
      console.error(
        `Moderation status for ${recipeId} could not be determined: ${e.message}`
      );
    }

    return 'pending';
  };

  const setImagesDeclined = async (products: any[]) =>
    Promise.all(
      products.map(async (product) => {
        let status = null;
        const recipeId =
          product.customsRecipeID || product.recipeId || product.recipe;

        if (recipeId) {
          status = await getImageModerationStatus(recipeId);
        }

        return {
          ...product,
          imageDeclined: status === 'declined',
        };
      })
    );

  const getCustomsCodeForRecipe = async ({
    recipe,
    size = '',
  }: {
    recipe: string;
    size?: string;
  }): Promise<string> => {
    const { sku } = await generateCustomSku(recipe, size);

    return parseCustomsCodeBySku(sku);
  };

  const getDefaultRecipe = async ({
    model,
  }: {
    model: string;
  }): Promise<string> => {
    const pdm = await customsPdm({ model });

    if (pdm) {
      return pdm.default_recipe;
    }

    return null;
  };

  const getStyleCodeForModel = async ({
    model,
    recipe,
  }: {
    model: string;
    recipe?: string;
  }) => {
    let styleCode: string;

    if (recipe) {
      styleCode = await getCustomsCodeForRecipe({ recipe });
    }

    if (!styleCode) {
      const defaultRecipe = await getDefaultRecipe({ model });

      styleCode = await getCustomsCodeForRecipe({
        recipe: defaultRecipe,
      });
    }

    return styleCode;
  };

  return {
    customsPdm,
    customsImage,
    customsPrice,
    customsDataByRecipe,
    generateCustomSku,
    getCustomsCaid,
    setImagesDeclined,
    getStyleCodeForModel,
    getCustomsCodeForRecipe,
  };
};
