
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfConditionalRenderer',
  functional: true,
  props: {
    // Root wrapper tag
    tag: {
      type: String,
      default: 'div',
    },
    // Toggles visibility of slot content
    show: {
      type: Boolean,
      default: true,
    },
    // Controls SSR behavior of slot content
    ssr: {
      type: Boolean,
      default: true,
    },
    // Allows defining custom hiding logic from the outside
    custom: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { data, props, scopedSlots }) {
    // If component is not needed to be shown in any context - don't return anything
    if (!props.ssr && !props.show) return null;
    // If no custom behavior is provided - hide contents visually on show toggle
    if (!props.show && !props.custom) {
      data.style ??= {};
      data.style['display'] = 'none';
    }
    data.class ??= {};
    data.class['vf-conditional-renderer'] = true;
    return h(
      props.tag,
      data,
      scopedSlots.default({ show: props.show, ssr: props.ssr })
    );
  },
});
