import type { ComponentInstance } from '../../../types';
import type { CMDataSource, PdpGalleryImage, PdpGalleryVideo } from '../types';
import type { Product } from '@vf/api-client';
import { useFeatureFlagsStore } from '../../../store/featureFlags';
import { scene7Media, scene7MediaPlp } from './scene7Media';
import { cloudinaryMedia } from './cloudinary';

const getMediaPlp = ({
  colorCode,
  colorName,
  instance,
  productId,
  productName,
}: {
  colorCode: string;
  colorName: string;
  instance: ComponentInstance;
  productId: string;
  productName: string;
}) => {
  const { enableCloudinaryCdn } = useFeatureFlagsStore(instance.$pinia);

  return enableCloudinaryCdn
    ? cloudinaryMedia({
        colorCode,
        colorName,
        imageSizes: instance.$themeConfig?.productsGrid.imageSizes,
        instance,
        productId,
        productName,
      })
    : scene7MediaPlp(productId, colorCode, instance);
};

const getPdpPreset = (instance: ComponentInstance, isPdpImage: boolean) => {
  const productGallery = instance.$themeConfig.productGallery;
  const usePresets = productGallery?.usePresets;
  let presets: Record<string, string> = {};
  if (usePresets && isPdpImage && productGallery?.presets) {
    presets = productGallery.presets;
  } else {
    presets = {
      src: instance.$env.IMAGES_PDP_MAIN_PRESET,
      zoom: instance.$env.IMAGES_PDP_ZOOM_PRESET,
      thumbnail: instance.$env.IMAGES_PDP_GALLERY_THUMBNAIL_PRESET,
    };
  }
  return presets;
};

const getMediaPdp = async ({
  colorCode,
  instance,
  isPdpImage,
  pdpGalleryDataSources,
  product,
}: {
  product: Product;
  instance: ComponentInstance;
  isPdpImage: boolean;
  colorCode: string;
  pdpGalleryDataSources: CMDataSource[];
}) => {
  const { enableCloudinaryCdn } = useFeatureFlagsStore(instance.$pinia);
  if (enableCloudinaryCdn) {
    const availableColor = product.colors.find(
      ({ value }) => value === colorCode
    ) ||
      product.colors[0] || { value: '', label: '' };
    const imagesData = await cloudinaryMedia({
      colorCode: availableColor.value,
      colorName: availableColor.label,
      imageSizes: getPdpPreset(instance, isPdpImage),
      instance,
      productDescription: product.description,
      productId: product.id,
      productName: product.name,
      supportVideo: true,
    });
    return imagesData.map((image) => ({ ...image, alt: product.name }));
  } else {
    const createScene7Media = async (
      product: Product
    ): Promise<(PdpGalleryImage | PdpGalleryVideo)[]> => {
      return await scene7Media(product, instance, isPdpImage, colorCode);
    };

    const imagesData = [];

    if (pdpGalleryDataSources.length > 0) {
      for (const dataSource of pdpGalleryDataSources) {
        if (dataSource.viewtype === 'scene7') {
          imagesData.push(...(await createScene7Media(product)));
        }
      }
    } else {
      imagesData.push(...(await createScene7Media(product)));
    }

    return imagesData;
  }
};

export { getMediaPdp, getMediaPlp };
