import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTranslation, generateLinkFromTeaserTargets } from './utils';

export const maHomeOrderBlock = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'account/OrderSummary',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Order History$'),
        textNoOrders: getTranslation(
          data,
          'textNoOrders',
          '$You have no orders in your Order History$'
        ),
        ctaLink: generateLinkFromTeaserTargets(
          data.teaserTargets,
          context,
          siteConfiguration,
          data.urlParams,
          cmsBaseUri
        ),
        ctaText: getTranslation(data, 'ctaText', '$View All Orders$'),
        ctaTextNoOrders: getTranslation(
          data,
          'ctaTextNoOrders',
          '$Check Order Status$'
        ),
        orderLabel: getTranslation(data, 'orderLabel', '$Order #$'),
        placedLabel: getTranslation(data, 'placedLabel', '$Placed$'),
        statusLabel: getTranslation(data, 'statusLabel', '$Status$'),
        serverErrorMessage: getTranslation(
          data,
          'serverErrorMessage',
          '$Unable to get orders data$'
        ),
      },
    },
  };
};
