import { GetCatalogSettings } from '../useCategory';
import { GetSearchResultsSettings } from '../useSearch/types';

export const getRestoredSearchSettings = ():
  | GetCatalogSettings
  | GetSearchResultsSettings => {
  const searchSettings: GetCatalogSettings = {};
  const state = typeof window !== 'undefined' && window.history.state;

  if (state) {
    searchSettings.loadAllPreviousPages = true;

    if (state.selectedFilters) {
      searchSettings.filters = state.selectedFilters;
    }
    if (state.selectedSortingOptionId) {
      searchSettings.sort = state.selectedSortingOptionId;
    }
    if (state.page) {
      searchSettings.page = state.page;
    }
  }

  return searchSettings;
};
