import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { GetSearchSuggestionsResponse } from '../../api-types';
import type { ApiClientConfiguration } from '../../configuration';

export const getSearchSuggestions = (configuration: ApiClientConfiguration) => (
  query: string
): Promise<AxiosResponse<GetSearchSuggestionsResponse>> => {
  return axios.get(
    urlBuilder(
      `${endpoints.get(configuration).search.getSearchSuggestions}?${query}`,
      {
        config: configuration,
      }
    ),
    {
      headers: headerBuilder(configuration),
    }
  );
};
