import { OrderItem, OrderTotals } from './order';
import { Product } from './product';
import { ShippingMethod } from './checkout';
import { Price } from './price';
import { CartLineItem as CartLineItemAPI } from '@vf/api-client';
import { ApproachingDiscountTranslations } from '../cmsPropsEntities';

export interface Coupon {
  code: string;
  discount: number;
  id: string;
  percentage: number;
  type: string;
  valid: boolean;
}

/** Common interface for all types of applied promotions */
export interface AppliedPromotion {
  id: string;
  description: string;
  price: string;
  calloutMsg?: string;
  itemText?: string;
  type: 'product-promotion' | 'auto-promotion' | 'cart-promotion';
  couponCode?: string;
  isBonus?: boolean;
  isGWP?: boolean;
  isOrderAuto?: boolean;
}

export interface GiftOption {
  from: string;
  to: string;
  message?: string;
}

export interface OptionItem {
  optionId: string;
  optionValueId: string;
  quantity: number;
  price: number;
  priceAfterItemDiscount: number;
  priceAfterOrderDiscount: number;
}

export interface CartLineItem extends CartLineItemAPI {
  imageDeclined?: boolean;
}

export interface UpdateItemRequestObject {
  cartId: string;
  productId: string;
  recipeId: string;
  itemId?: string;
  qty: number;
  maxQty: number;
  pdpUrl: string;
  productImageURL: string;
  storeId?: string;
  gift?: boolean;
  giftOption?: GiftOption;
  isGiftBoxSelected?: boolean;
}

export interface UpdateItemRequest extends UpdateItemRequestObject {
  items?: UpdateItemRequestObject[];
}

export interface Cart {
  coupons: Coupon[];
  id: string;
  items: OrderItem[];
  shippingMethods: ShippingMethod[];
  upsells: Product[];
  totals: OrderTotals;
  totalItems: number;
}

export interface SaveForLaterItemRequest {
  itemId: string;
  consumerID: string;
  saveForLaterId: string;
}

export interface CartLineItemAttribute {
  code: string;
  id: string;
  label: string;
  value: string;
}

export interface AddToSaveForLaterItemRequest {
  cartId: string;
  productId: string;
  recipeId: string;
  qty: number;
  type: string;
  pdpUrl: string;
  productImageURL: string;
  masterId: string;
  defaultProductView: string;
  available: string;
  saveForLater: SaveForLaterItemRequest;
  variants: CartLineItemAttribute[];
}

export interface SaveForLaterItem {
  id: string;
  isCustoms: boolean;
  itemId: string;
  itemType: string;
  masterId: string;
  maxQty: number;
  quantityAvailable: number;
  name: string;
  pageUrl: string;
  pdpUrl: string;
  price: Price;
  priority: number;
  productId: string;
  productImageURL: string;
  productType: {
    type: string;
    variationGroup: string;
  };
  public: boolean;
  purchasedQty: number;
  qty: number;
  rating: {
    score: number;
    numReviews: number;
  };
  variants: CartLineItemAttribute[];
}

export interface StoreInfo {
  id: string;
  name: string;
  pickupOptions: string[];
}

export interface ProductShippingOption {
  selected: boolean;
  available: boolean;
  shippingMethod: ShippingMethod;
  storeInfo?: StoreInfo;
}

export enum AthletePromoType {
  PROMO_ID = '50%_ATHLETES',
}

export enum PromotionMessageContext {
  Cart = 'cart',
  MiniCart = 'miniCart',
  OrderConfirmation = 'orderConfirmation',
}

export interface PromotionMessage {
  name: 'bounceBackVoucher' | string;
  translations: ApproachingDiscountTranslations;
}

export interface ApproachingDiscountDetails {
  earned: boolean;
  conditionThreshold: number;
  currentAmount: number;
  title?: string;
  message: string;
}
