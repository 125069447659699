import { useValidation } from '@vf/composables';
import { Address } from '@vf/api-client/src/types';
import { useAddressesStore } from '../../../store/addresses';
import { storeToRefs } from 'pinia';
import { scrollToFirstError } from '@vf/shared/src/utils/helpers';

export const usePickup = (instance, { cart, storeAddresses }) => {
  const store = useAddressesStore(instance);
  const { pickupAddress } = storeToRefs(store);

  const setupInitialPickupAddress = (): Address => {
    const cartPickupAddress: Address = cart.value.shippingMethods.find(
      (method) => method?.storeId
    )?.address;
    if (cartPickupAddress) {
      pickupAddress.value = {
        ...pickupAddress.value,
        ...cartPickupAddress,
      };
    }
    return cartPickupAddress;
  };

  const { $v: $vPICKUP } = useValidation(instance, 'PICKUP_FORM');

  const setPickupAddressField = async (field: string, value: string) => {
    pickupAddress.value = {
      ...pickupAddress.value,
      ...{ [field]: value },
    };
    await storeAddresses({
      updateCartData: true,
      updatePickupAddress: true,
    });
  };

  // TODO Type shouldn't be Partial<Address>?
  const setPickupAddressData = async (data: any) => {
    pickupAddress.value = {
      ...pickupAddress.value,
      ...data,
    };
    if (!validatePickupForm()) return;
    await storeAddresses({
      updateCartData: true,
      updatePickupAddress: true,
    });
  };

  const validatePickupForm = () => {
    $vPICKUP.value && $vPICKUP.value.$touch();
    if (!$vPICKUP.value || $vPICKUP.value.$invalid) {
      return false;
    }
    return true;
  };

  const isPickupDataValid = (): boolean => {
    const { firstName, lastName, email } = pickupAddress.value;
    return !!(firstName && lastName && email);
  };

  const openPickupValidationError = () => {
    setTimeout(() => {
      $vPICKUP.value && $vPICKUP.value.$touch();
      scrollToFirstError();
    }, 100);
  };

  return {
    validatePickupForm,
    pickupAddress,
    setupInitialPickupAddress,
    setPickupAddressField,
    setPickupAddressData,
    isPickupDataValid,
    openPickupValidationError,
  };
};
