import {
  CheckoutRewardCardTranslations,
  CMPlaceholder,
} from '@vf/api-contract';
import {
  extractProperty,
  getComponentConfigByName,
  getTranslation,
} from './utils';
import { useFeatureFlagsStore } from '../../store/featureFlags';

export const checkoutReward = (data: CMPlaceholder) => {
  const config = getComponentConfigByName(
    data,
    'checkout-gift-card-settings',
    {},
    true
  );

  const translations: CheckoutRewardCardTranslations = {
    title: getTranslation(data, 'title', '$Reward Card$'), // TODO: GLOBAL15-56318 remove title translation
    titleReward: getTranslation(
      data,
      'titleReward',
      '$Membership Reward Balance$'
    ),
    titleRewardWithAvailableReward: getTranslation(
      data,
      'titleRewardWithAvailableReward',
      '$<b>{{availableReward}}</b> available reward to use towards this purchase.$'
    ),
    // TODO: GLOBAL15-56318 remove description translation
    description: getTranslation(
      data,
      'description',
      '$Congratulations! You’ve earned a Reward. Tap “Apply” to use it on this purchase.$'
    ),
    // TODO: GLOBAL15-56318 remove rewardCardApplied translation
    rewardCardApplied: getTranslation(
      data,
      'rewardCardApplied',
      getTranslation(data, 'rewardCardApplied', '$Reward Card Applied$')
    ),
    applySuccessMessage: getTranslation(
      data,
      'applySuccessMessage',
      '$Your reward has been successfully applied$'
    ),
    removeSuccessMessage: getTranslation(
      data,
      'removeSuccessMessage',
      '$Your reward has been successfully removed$'
    ),
    apply: getTranslation(data, 'apply', '$Apply$'),
    removeRewardCard: getTranslation(data, 'removeRewardCard', '$Remove Card$'),
    // TODO: GLOBAL15-56318 remove cardNumberHeading translation
    cardNumberHeading: getTranslation(
      data,
      'cardNumberHeading',
      '$Rewards Card Number$'
    ),
    // TODO: GLOBAL15-56318 remove amount translation
    amount: getTranslation(data, 'amount', '$Amount$'),
    maxCardsApplied: getTranslation(
      data,
      'maxCardsApplied',
      '$Maximum of 2 gift/reward cards per order. To add more, please contact customer service.$'
    ),
    legacyIssuedText: getTranslation(
      data,
      'legacyIssuedText',
      '$Legacy: Issued before Winter 2023$'
    ),
    expires: getTranslation(data, 'expires', '$Expires$'),
    issuedSummer: getTranslation(data, 'issuedSummer', '$Issued Summer$'),
    issuedWinter: getTranslation(data, 'issuedWinter', '$Issued Winter$'),
    issuedSpring: getTranslation(data, 'issuedSpring', '$Issued Spring$'),
    instructionsDefault: getTranslation(
      data,
      'instructionsDefault',
      '$Apply any reward below to use on this purchase.$'
    ),
    instructionsOneApplied: getTranslation(
      data,
      'instructionsOneApplied',
      '$Congrats! Your reward has been applied.$'
    ),
    instructionsMaxApplied: getTranslation(
      data,
      'instructionsMaxApplied',
      '$Congrats! Your rewards have been applied.$'
    ),
    rewardDetails: getTranslation(data, 'rewardDetails', '$Reward Details$'),
    selectionMax: getTranslation(
      data,
      'selectionMax',
      '$Select up to 2 rewards max.$'
    ),
    detailsExpanded: getTranslation(
      data,
      'detailsExpanded',
      '$May be used with other payment types and promo codes<br/>Remaining amount of rewards will be available until their expiration date (US only)$'
    ),
    lockedRewardInfoText: getTranslation(
      data,
      'lockedRewardInfoText',
      '$This reward is temporarily processing.$'
    ),
    lockedRewardToolipButtonAriaLabel: getTranslation(
      data,
      'lockedRewardToolipButtonAriaLabel',
      '$Locked reward tooltip$'
    ),
    seeMoreRewards: getTranslation(
      data,
      'seeMoreRewards',
      '$See More Rewards$'
    ),
  };

  // TODO: GLOBAL15-56318 cleanup after checkout redesign
  const componentVersion = useFeatureFlagsStore().areRewardCodesEnabled
    ? 'New'
    : 'Old';

  return {
    component: `checkout/CheckoutRewardCard/CheckoutRewardCard${componentVersion}`,
    props: {
      translations,
      // TODO: GLOBAL15-56318 change prop name to maxRewardUsage
      maxGiftCards: extractProperty(config, 'values.max-gift-cards', 2),
    },
  };
};
