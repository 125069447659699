import { Plugin } from '@nuxt/types';
import { format } from 'date-fns';
import { getDateFnsLocale } from '@vf/shared/src/utils/helpers';
import { ComponentInstance } from '@vf/composables';

const EstimatedDeliveryDate: Plugin = (context, inject) => {
  /**
   * Returns a formatted delivery date label.
   *
   * @param instance Vue component instance
   * @param dateString input string containing timestamp or date
   * @returns formatted date string
   */
  function getEstimatedDeliveryDateLabel(
    instance: ComponentInstance,
    dateString: string = ''
  ): string {
    if (!dateString) return '';

    const dateTemplate = instance.$root.$t(
      'narvar.dynamicEstimatedDelivery'
    ) as string;
    const dateTemplateForInvalidDate = instance.$root.$t(
      'narvar.staticEstimatedDelivery'
    ) as string;

    if (!dateTemplate) return dateString;

    const [startMarker, endMarker] = ['{{', '}}'];
    const startIndex = dateTemplate.lastIndexOf(startMarker);
    const endIndex = dateTemplate.indexOf(
      endMarker,
      startIndex + startMarker.length
    );
    if (startIndex === -1 || endIndex === -1) return dateTemplate;
    const dateFormat = dateTemplate.substring(
      startIndex + startMarker.length,
      endIndex
    );
    const placeholder = `${startMarker + dateFormat + endMarker}`;

    try {
      // GLOBAL15-3740 When two dates provided, maximum date should be used
      const [minDateString, maxDateString] = dateString.split(',');
      const localTimeZoneDate = new Date(maxDateString ?? minDateString);

      const date = new Date(
        localTimeZoneDate.valueOf() +
          localTimeZoneDate.getTimezoneOffset() * 60 * 1000
      );

      const dateFnsLocale = getDateFnsLocale(
        instance.$root.$i18n.defaultLocale,
        instance.$root.$env.LOCALE_MAPPING
      );

      const formattedDate = format(date, dateFormat, {
        ...(dateFnsLocale && { locale: dateFnsLocale }),
      });

      return dateTemplate.replace(
        placeholder,
        formattedDate.replace(/( )(\d+)/, ` $2`)
      );
    } catch (e) {
      return (dateTemplateForInvalidDate || dateTemplate).replace(
        placeholder,
        dateString
      );
    }
  }

  inject('getEDDLabel', getEstimatedDeliveryDateLabel);
};

export default EstimatedDeliveryDate;
