import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const probeApiMiddleware = (
  configuration: ApiClientConfiguration
) => (): Promise<any> => {
  const url: string = endpoints.get(configuration).healthCheck;
  return axios.get(url);
};
