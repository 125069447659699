var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-sticky",class:{
    'vf-sticky--sticky': _vm.isSticky,
    'vf-sticky--bound': _vm.isBound,
    'vf-sticky--hidden': _vm.isStickyVisibleOnTrigger,
    'vf-sticky--slide-down':
      (_vm.isSticky && _vm.triggerElementId) || (_vm.isSticky && _vm.triggerDefinedNumber),
    'vf-sticky--top': _vm.isStickyTop,
    'vf-sticky--top-bound': _vm.isStickyTopBound,
    'vf-sticky--bottom': _vm.isStickyBottom,
    'vf-sticky--bottom-bound': _vm.isStickyBottomBound,
  },style:(("" + (_vm.isFixed ? 'position: fixed' : '')))},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }