import { isClient } from './check-environment';
export const onStopScroll = (callback: () => void): void => {
  let timer = null;
  if (!isClient) return;
  const listener = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      callback();
      window.removeEventListener('scroll', listener);
    }, 60);
  };
  window.addEventListener('scroll', listener, false);
};
