import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getI18n = (configuration: ApiClientConfiguration) => (
  countryCode: string,
  locale: string
): Promise<AxiosResponse<any>> => {
  const url = urlBuilder(endpoints.get(configuration).utilities.i18n, {
    config: configuration,
    mapping: {
      countryCode,
    },
  });
  return axios.get(url, {
    params: {
      locale,
    },
    headers: headerBuilder(configuration),
  });
};
