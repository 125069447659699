import { CMPlaceholder, KlarnaModalAndThreshold } from '@vf/api-contract';
import { getTranslation } from './extractProperty';

export const getKlarnaModalTranslations = (
  data: CMPlaceholder
): KlarnaModalAndThreshold => {
  return {
    modal: getTranslation(data, 'modal', {
      title: {
        inThresholdLabel: '$Pay {{payments}} Installments of {{amount}}$',
        belowThresholdLabel: '$Pay in {{payments}} interest-free installments$',
      },
      subtitle:
        '$Pay in {{payments}} interest-free installments so you can spread the cost$',
      listItem: [
        '$Add item(s) to your cart$',
        '$Go to checkout and choose {{klarnaIcon}}$',
        '$Enter your debit or credit card information$',
        '$Pay later in {{payments}} installments. The first payment is taken when the order is processed and the remaining {{remainging}} are automatically taken every two weeks.$',
      ],
      disclaimerText:
        '$Pay later in {{payments}} installments complete <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/sliceitinx" target="_blank">Terms</a>. For CA residents loans made or arranged pursuant to California Finance Lenders Law license.$',
      closeLabel: '$Close$',
    }),
  };
};
