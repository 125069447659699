import { CMPlaceholder } from '@vf/api-contract';

export const getConfigValues = (data: CMPlaceholder) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'plp-grid-properties'
    );

    return config.values;
  } catch (e) {
    console.error('SRP Grid component error: no config found.');
  }

  return {
    productsPerPage: 25,
    'next-products': 25,
    showAddToFavourites: true,
  };
};

export const getSrpSettingsValues = (data: CMPlaceholder) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'plpSettings'
    );

    return config.values;
  } catch (e) {
    console.error('SRP Grid component error: no plp settings config found.');
  }

  return {
    'show-see-more': true,
    'show-color-swatches': true,
    'show-regular-price': true,
    'show-wishlist-icon': true,
    'show-product-badge': true,
    'show-product-name': true,
    'show-eyebrow': true,
    'show-product-images': true,
    'show-quick-shop': true,
    'show-ratings-icon': true,
    'show-product-desc': true,
  };
};
