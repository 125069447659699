import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const applyShared = (configuration: ApiClientConfiguration) => async (
  body: unknown
): Promise<any> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).cart.applyShared, {
      config: configuration,
    }),
    body,
    {
      headers: headerBuilder(configuration),
    }
  );
};
