import { defineStore } from 'pinia';
import { computed, getCurrentInstance, ref } from '@vue/composition-api';
import type {
  GetCustomerRewardCardsResponse,
  RewardCode,
} from '@vf/api-contract';
import { PaymentMethodCode } from '@vf/api-client';
import { usePaymentInstrument } from '../useAccount/composables/usePaymentInstrument';
import { useFeatureFlagsStore } from './featureFlags';
import {
  mapRewardCodeToReward,
  mapRewardCardToReward,
} from '@vf/shared/src/utils/helpers';
export interface Reward {
  id: string;
  paymentMethodId: 'REWARD_CARD' | 'REWARD_CODE';
  cardType: string;
  paymentInstrumentId: string;
  balance: string;
  rawBalance: number;
  maskedNumber?: string | null;
  expireDateTime?: string;
  issueDateTime?: string;
  rewardCategory?: 'SUMMER' | 'WINTER' | 'SPRING';
  authorized?: boolean;
}

export const useRewardStore = (instance?) => {
  const currentInstance = getCurrentInstance();
  const localInstance = currentInstance?.proxy || instance;

  return defineStore('rewards', () => {
    const cards = ref<Reward[]>([]);
    const codes = ref<Reward[]>([]);

    const getCards = async () => {
      const { getPaymentInstruments } = usePaymentInstrument(localInstance);

      const rewardCardsResponse = await getPaymentInstruments<GetCustomerRewardCardsResponse>(
        PaymentMethodCode.REWARD_CARD
      );

      cards.value = rewardCardsResponse.data
        .filter(({ card }) => card.gcBalance > 0)
        .map(({ card }) =>
          mapRewardCardToReward(card, localInstance.$formatPrice)
        );
    };

    const getCodes = async () => {
      const { getPaymentInstruments } = usePaymentInstrument(localInstance);

      const response = await getPaymentInstruments<RewardCode[]>(
        PaymentMethodCode.REWARD_CODE
      );

      codes.value = response.data.map((code) =>
        mapRewardCodeToReward(code, localInstance.$formatPrice)
      );
    };

    // TODO: GLOBAL15-56318 just return first part of ternary after redesign
    const getRewards = () =>
      useFeatureFlagsStore().areRewardCodesEnabled
        ? Promise.all([getCards(), getCodes()])
        : getCards();

    const rewards = computed(() => [...cards.value, ...codes.value]);

    return {
      rewards,
      cards,
      codes,
      getCodes,
      getCards,
      getRewards,
    };
  })();
};
