import { PageMetaData, PageMetaTag } from '@vf/api-client/src/types';

export const createMetaTagObject = (
  tagName: string,
  content: string,
  isProperty: boolean = false
) => {
  return { id: tagName, content, isProperty };
};

/**
 * Transformer for meta data returned from the catalog API,
 * to ensure backwards compatibility and unify formats across
 * various commerce API responses.
 * @param pageMetaData meta tags as nested object or an array of tag objects
 * @returns PageMetaData object
 */
export const transformPageMetaData = (
  pageMetaData: PageMetaData | PageMetaTag[] | undefined
): PageMetaData => {
  if (!pageMetaData) return {};
  if (Array.isArray(pageMetaData)) {
    return Object.fromEntries(
      pageMetaData.map((tag: PageMetaTag) => [tag.id, tag])
    );
  }
  return pageMetaData;
};
