import { extractProperty, generateLinkFromTarget } from '../utils';
import { ComposableContext } from '../../types';
import { CmsSite } from '@vf/api-contract';

export const geminiSectionCarousel = (
  data: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const ctaUrl1 = extractProperty(data, 'ctaUrl1[0]');

  return {
    component: 'gemini/SectionCarousel',
    attrs: {
      'data-id': data.id,
    },
    props: {
      copy: extractProperty(data, 'copy1', ''),
      ctaCopy: extractProperty(data, 'ctaCopy1', ''),
      ctaHasIcon: extractProperty(data, 'ctaHasIcon1', false),
      ctaUrl: ctaUrl1
        ? generateLinkFromTarget(
            ctaUrl1,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      ctaTarget: extractProperty(data, 'ctaUrl1[0].type', ''),
      heading: extractProperty(data, 'heading1', ''),
      pattern: extractProperty(data, 'cssPattern', ''),
      wrapper: extractProperty(data, 'wrapper', ''),
    },
  };
};
