import { ReturnPreviewObject } from '@vf/api-client';
import { ReturnDetailsObject } from '@vf/api-contract';
import useUrl from '../../useUrl';
import { ComponentInstance } from '../../types';

const mapReturnItems = (data, instance) => {
  const { getRelativeUrl } = useUrl(instance);

  return data.order_lines.map((lineItem) => {
    return {
      ...lineItem.item,
      pdpUrl: getRelativeUrl(lineItem.item.pdpUrl),
      sku: lineItem.item.itemID,
      returnQty: lineItem.orderedQty,
      name: lineItem.name,
      reasonCode: lineItem.returnReason,
      price: {
        original:
          lineItem.lineOverallTotals.lineTotalWithoutTax / lineItem.orderedQty,
        priceAfterItemDiscount: lineItem.lineOverallTotals.lineTotalWithoutTax,
        currency: data.currencyIsoCode,
      },
      totals: {
        unitPrice: lineItem.linePriceInfo.unitPrice,
        currency: data.currencyIsoCode,
      },
      image: lineItem.item.img,
      styleCode: lineItem.item.styleCode,
      attributes: lineItem.attributes.map((attr) => {
        return { ...attr, id: attr.value };
      }),
      quantity: {
        ordered: lineItem.orderedQty,
        returned: lineItem.orderedQty,
      },
    };
  });
};

export const mapReturnOrder = (
  data: ReturnDetailsObject,
  instance: ComponentInstance
): ReturnPreviewObject => {
  const returnOrderLine = data.order_lines[0];

  return {
    returnNumber: data.order_number,
    orderNumber: returnOrderLine.derivedFromOrder.orderNo,
    derivedFromOrder: {
      orderNo: returnOrderLine.derivedFromOrder.orderNo,
      orderHeaderKey: '',
    },
    personInfoShipTo: returnOrderLine.personInfoShipTo,
    status: data.status,
    orderLines: data.order_lines as any,
    items: mapReturnItems(data, instance),
    returnedItems: data.order_lines.reduce((a, b) => +a + +b.orderedQty, 0),
    extnIsRefundOnGiftCard: data.extn?.extnIsRefundOnGiftCard === 'Y',
    extnReturnLabel: data.extn?.extnReturnLabel,
    extnReturnTrackingNo: data.extn?.extnReturnTrackingNo,
    extnReturnTrackingURL: data.extn?.extnReturnTrackingURL,
    overallTotals: data.overallTotals,
    orderHeaderKey: data.orderHeaderKey,
    headerCharges: data.headerCharges,
  };
};
