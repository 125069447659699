import { getDynamicField } from './utils';
import { CmsSite, CMCollection } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { accordionItem } from './accordionItem';

export const accordion = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const iconType = getDynamicField(data, 'iconsType', 'arrows');
  return {
    component: 'accordion/Accordion',
    props: {
      items: data.teasableItems.map((item) =>
        accordionItem(item, context, siteConfiguration, cmsBaseUri, {
          iconType: iconType === 'caret-chevron' ? 'arrow' : '',
        })
      ),
    },
  };
};
