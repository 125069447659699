import { ComposableContext } from '../types';
import {
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
} from './utils';
import {
  extractProperty,
  getDynamicField,
  getTranslation,
} from './utils/extractProperty';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
export const favoritesShareButton = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const signInData = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'signInLink'),
    '[0]'
  );
  const backToShoppingData = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'backToShoppingLink'),
    '[0]'
  );
  return {
    component: 'favorites/FavoritesTopButton',
    props: {
      signInLink: generateLinkFromTarget(
        signInData,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      backToShoppingLink: generateLinkFromTarget(
        backToShoppingData,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      shareFavoritesLink: '',
      translations: {
        backToShoppingLabel: getDynamicField(
          data,
          'backToShoppingLabel',
          '$back to shopping$'
        ),
        signInText: getDynamicField(data, 'signInText', '$Sign in to save$'),
        shareFavorites: getTranslation(
          data,
          'shareFavorites',
          '$Share Your Favorites$'
        ),
      },
    },
  };
};
