import { AxiosError } from 'axios';

export interface ApiError {
  errorDetails: {
    message: string;
    errorId: string;
    modifiers?: string;
    correlationId?: string;
  }[];
}

export const isAxiosApiError = (error): error is AxiosError<ApiError> => {
  const details = error.response?.data?.errorDetails?.[0];
  return !!(details && 'message' in details && 'errorId' in details);
};
