import { Plugin } from '@nuxt/types';

const PageUrl: Plugin = (context, inject) => {
  function getPageUrl(): string {
    if (!process.server) {
      return window.location.href.split('?')[0];
    }
    return `https://${context.req.headers.host}${context.route.fullPath}`;
  }

  inject('getPageUrl', getPageUrl);
};

export default PageUrl;
