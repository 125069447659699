import { CMPlaceholder, CmsSite } from 'packages/api-contract/src/cms';
import {
  getTranslation,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
} from './utils';
import { ComposableContext } from '../types';
export const creditCardsSelect = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const links = extractLocalSettingsExpandedLinks(
    data,
    'add-credit-card-button-link'
  );
  const addCreditCardButtonLink = links?.length
    ? generateLinkFromTarget(links[0], context, siteConfiguration, cmsBaseUri)
    : '';
  return {
    component: 'account/CreditCardsSelect',
    props: {
      contextName: 'new-credit-card',
      translations: {
        heading: getTranslation(data, 'heading', '$Saved credit cards$'),
        subtitle: getTranslation(data, 'subtitle', '$Subtitle$'),
        buttonText: getTranslation(data, 'buttonText', '$Add credit card$'),
        editButton: getTranslation(data, 'editButton', '$Edit$'),
        deleteButton: getTranslation(data, 'deleteButton', '$Delete$'),
        updateSuccess: getTranslation(
          data,
          'updateSuccess',
          '$The default credit card has been changed$'
        ),
        deleteSuccess: getTranslation(
          data,
          'deleteSuccess',
          '$The credit card has been deleted$'
        ),
        addSuccess: getTranslation(
          data,
          'addSuccess',
          '$You card has been saved$'
        ),
        cardEndingIn: getTranslation(data, 'cardEndingIn', '$Ending in$'),
        cardExpiration: getTranslation(data, 'cardExpiration', '$Exp.$'),
      },
      showEditButton: false,
      showDeleteButton: true,
      addCreditCardButtonLink,
    },
  };
};
