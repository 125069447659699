const pathToMockFileName = {
  '/': 'root',
  '/customs': 'customs',
  '/search/product': 'search',
  '/cart': 'cart',
  '/favorites': 'favorites',

  '/checkout/payment': 'checkout_payment',
  '/checkout/shipping': 'checkout_shipping',

  '/reset-password': 'reset_password',
  '/account/forgot-password': 'forgot_password',
  '/account/sign-in': 'sign_in',
  '/account/profile': 'account_profile',
  '/account/credit-cards': 'credit_cards',
  '/account/credit-cards/new': 'new_credit_card',
  '/account/order-details': 'order_details_logged',
  '/account/order-history': 'order_history',
  '/account/address-book': 'address_book',
  '/account/address-book/shipping-address': 'shipping_address',
  '/account/address-book/billing-address': 'billing_address',

  '/order/success': 'order_success',
  '/order-details': 'order_details',
  '/order-status': 'order_status',

  '/not-found': 'not_found',

  '/customs-shoes': 'customs_shoes',
  '/contact-us': 'contact_us',
  '/loyalty-member-dashboard': 'loyalty_member_dashboard',
};

export const pagePathToMockMapping = (path: string): string => {
  return pathToMockFileName[path] || path;
};
