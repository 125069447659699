







































































// TODO: GLOBAL15-63799 remove the whole file when redesign work is done
import { focus } from '@vf/shared/src/utils/directives';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfAccordion',
  directives: { focus },
  props: {
    header: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    strongHeader: {
      type: Boolean,
      default: true,
    },
    titleModifier: {
      type: String,
      default: '',
    },
    dividerType: {
      type: String,
      default: '',
    },
    showTopDivider: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: 'plus',
    },
    iconSize: {
      type: String,
      default: '24px',
    },
    indentHeader: {
      type: Boolean,
      default: false,
    },
    ssr: {
      type: Boolean,
      default: true,
    },
    managedInParent: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      required: false,
    },
    styles: Object,
  },
  setup() {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    headingModifier() {
      if (this.titleModifier) {
        return this.titleModifier;
      }
      if (this.$themeConfig?.accordion?.headingModifier) {
        return this.$themeConfig?.accordion?.headingModifier;
      }
      return 'link';
    },
    icons() {
      return {
        closed: this.iconType === 'arrow' ? 'chevron_down' : 'plus',
        opened: this.iconType === 'arrow' ? 'chevron_up' : 'minus',
      };
    },
    ariaLabelledByKey() {
      return (
        'tab-panel-name-' +
        (this.header?.replace(/\s+/g, '-').toLowerCase() ?? 'no-header')
      );
    },
    idContent() {
      return `${this.ariaLabelledByKey}-content`;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(value) {
        this.open = value;
      },
    },
  },
  mounted() {
    this.setAsOpen();
  },
  methods: {
    setAsOpen() {
      if (this.isOpen) {
        this.open = true;
      }
    },
    toggleHandler() {
      if (!this.managedInParent) {
        this.open = !this.open;
      }

      this.iconType != 'none'
        ? this.$emit('toggle', {
            header: this.header,
            opened: this.open,
            identifier: this.identifier,
          })
        : this.$emit('click');
    },
  },
});
