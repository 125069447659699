import { EventPropsHandler } from '.';
import { LaunchDarklyExperienceEvent } from '../../types/gtm';

export const launchDarklyExperiencesHandler: EventPropsHandler<
  LaunchDarklyExperienceEvent,
  {
    actions: any[];
  }
> = (eventProps, vmInstance) => {
  return {
    event: 'launchDarklyActiveExperiences',
    eventCategory: 'LaunchDarkly',
    eventAction: 'Active Experiences',
    eventLabel: undefined,
    nonInteractive: true,
    customMetrics: {},
    customVariables: {},
    launchDarklyExperience: vmInstance.$launchDarkly.getActiveExperiments(),
    source: 'FE',
    _clear: true,
  };
};
