import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  generateLinkFromTeaserTargets,
  getDynamicField,
  getTranslation,
  getTitle,
  getCreateAccountTranslations,
  getValidationConfigValues,
  generateLinkFromTarget,
  extractLocalSettingsExpandedLinks,
} from './utils';
import { ComposableContext } from '../types';

// maCreateAccount component for TNF
export const maCreateAccountBirthdate = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'account/CreateAccountFormBirthdate',
    props: {
      link: getDynamicField(data, 'link', ''),
      interestsLink: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
      shippingTermsLink: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'shippingTermsLink')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      newsletterSignUpAutoCheck: getDynamicField(
        data,
        'newsletterSignUpAutoCheck',
        false
      ),
      showSmsSubscription: getDynamicField(
        data,
        'smsSubscriptionEnabled',
        false
      ),
      showPasswordToggler: getDynamicField(data, 'showPasswordToggler', false),
      showHeading: getDynamicField(data, 'showHeading', true),
      showHeadingIcon: getDynamicField(data, 'showHeadingIcon', false),
      showXplrPass: getDynamicField(data, 'showXplrPass', true),
      headingIcon: getDynamicField(data, 'headingIcon', ''),
      headingIconSize: getDynamicField(data, 'headingIconSize', ''),
      validations: getValidationConfigValues(data),
      redirectTarget: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'targetPage')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      redirectEnrolledTarget: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'enrolledTargetPage')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      translations: {
        heading: getTranslation(data, 'greeting', getTitle(data)),
        subheading: getTranslation(data, 'subtext', getTitle(data)),
        ...getCreateAccountTranslations(
          data,
          context,
          siteConfiguration,
          cmsBaseUri
        ),
      },
    },
  };
};
