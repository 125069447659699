//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'Eyebrow',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
});
