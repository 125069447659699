//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'Price',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    regular: {
      type: [Number, String],
      default: null,
    },
    regularPrefix: {
      type: String,
      default: '',
    },
    special: {
      type: [Number, String],
      default: null,
    },
    specialPrefix: {
      type: String,
      default: '',
    },
    showPrefixes: {
      type: Boolean,
      default: false,
    },
    strikeOriginalPrice: {
      type: Boolean,
      default: true,
    },
    showRegularPriceAfter: {
      type: Boolean,
      default: false,
    },
    specialPriceColored: {
      type: Boolean,
      default: false,
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    regularValue() {
      return this.special && this.showPrefixes
        ? `${this.regularPrefix} ${this.regular}`
        : this.regular;
    },
    specialValue() {
      return this.special && this.showPrefixes
        ? `${this.specialPrefix} ${this.special}`
        : this.special;
    },
    isDiscount() {
      return String(this.regularValue).includes('-');
    },
  },
});
