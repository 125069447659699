




















































import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'VfText',
  inheritAttrs: false,
  props: {
    tag: {
      type: String as PropType<string>,
      default: 'p',
    },
    hasParentTag: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    html: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    content: {
      type: [String, Number] as PropType<string | number>,
      default: '',
    },
    modifier: {
      type: String as PropType<string>,
      default: '',
    },
    textCustomClasses: {
      type: String as PropType<string>,
      default: '',
    },
    fontSizes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    fontWeight: {
      type: String as PropType<string>,
      default: '',
    },
    fontFamily: {
      type: String as PropType<string>,
      default: '',
    },
    color: {
      type: String as PropType<string>,
      default: '',
    },
    textColor: {
      type: String as PropType<string>,
      default: '',
    },
    backgroundColor: {
      type: String as PropType<string>,
      default: '',
    },
    styles: {
      type: Object,
      default: () => ({
        fontSize: '',
        fontWeight: '',
      }),
    },
    textAlign: {
      type: Object,
      default: () => ({
        small: '',
        medium: '',
        large: '',
      }),
    },
    hyperlinkStyle: {
      type: String as PropType<string>,
      default: '',
    },
    hyperlinkMargin: {
      type: String as PropType<string>,
      default: '',
    },
    vfTextAriaRole: String,
    vfTextAriaLive: String,
  },
  setup(props, context) {
    const getClassName = (modifier, suffix = '') => {
      if (modifier) {
        return suffix
          ? `vf-text--${modifier}-${suffix}`
          : `vf-text--${modifier}`;
      }
    };

    const getClassNames = (modifiers, suffix) =>
      modifiers?.length > 0
        ? modifiers.map((modifier) => getClassName(modifier, suffix))
        : [];

    const computedClasses = computed(() =>
      [
        getClassName(props.modifier),
        ...getClassNames(props.fontSizes, 'font-size'),
        getClassName(props.fontWeight, 'font-weight'),
        getClassName(props.color, 'color'),
        props.textCustomClasses,
      ]
        .filter((el) => el)
        .join(' ')
    );
    const textAlignClasses = computed(() => [
      `vf-text--sm-${props.textAlign.small}-text-align`,
      `vf-text--md-${props.textAlign.medium}-text-align`,
      `vf-text--lg-${props.textAlign.large}-text-align`,
    ]);
    const hyperlinkClass = computed(() => {
      if (
        context.root.$viewport?.isMobileDevice ||
        props.hyperlinkStyle === 'underlineAlways'
      ) {
        return undefined;
      }
      switch (props.hyperlinkStyle) {
        case 'noUnderline':
          return 'vf-hyperlink--no-underline';
        case 'underlineOnHover':
          return 'vf-hyperlink--hover-underline';
        case 'underlineAnimate':
          return 'vf-hyperlink--animate-underline';
        case 'default':
          return 'vf-hyperlink--default';
        default:
          return undefined;
      }
    });
    const hyperhyperlinkUnderlineOffset = computed(() => {
      switch (props.hyperlinkMargin.toLowerCase()) {
        case 'none':
          return 'auto';
        case 'xs':
          return '1px';
        case 's':
          return '2px';
        default:
          return undefined;
      }
    });

    const textStyles = computed(() => ({
      color: props.textColor,
      'font-size': props.styles.fontSize || undefined,
      'font-weight': props.styles.fontWeight || undefined,
      '--vf-text-highlighted-background': props.backgroundColor,
      '--vf-hyperlink-underline-offset': hyperhyperlinkUnderlineOffset.value,
    }));

    return {
      computedClasses,
      textAlignClasses,
      hyperlinkClass,
      textStyles,
    };
  },
});
