import { Address } from '@vf/api-client/src/types';

export const getEmptyAddressObject = (country): Address => ({
  id: null,
  stateCode: null,
  firstName: null,
  lastName: null,
  email: null,
  addressLine1: null,
  addressLine2: null,
  postalCode: null,
  city: null,
  province: null,
  phone: null,
  country,
  subscriptions: {
    newsletterConsent: false,
  },
  addressId: null,
});
