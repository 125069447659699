import { CMTeaser } from '@vf/api-contract';
import { getDynamicField, getTeaserStyle, getTranslation } from './utils';
export const returnHeading = (data: CMTeaser) => {
  return {
    component: 'return/ReturnHeading',
    props: {
      styles: {
        classModifiers: getTeaserStyle(data, 'teaserClassModifiers'),
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        subtitleColor: getTeaserStyle(data, 'teaserSubTitleColor'),
        subtitleClassModifiers: getTeaserStyle(
          data,
          'teaserSubTitleClassModifiers'
        ),
        titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        subtitleFontSize: getTeaserStyle(data, 'teaserSubTitleFontSize'),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        subtitleFontFamily: getTeaserStyle(data, 'teaserSubTitleFontFace'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        titleTextAlign: getDynamicField(data, 'titleTextAlign', 'center'),
        subtitleTextAlign: getDynamicField(data, 'subtitleTextAlign', 'center'),
      },
      translations: {
        returnSubtitle: getTranslation(
          data,
          'returnDetailsSubtitle',
          '$Returns$'
        ),
        titles: {
          historyDetails: getTranslation(
            data,
            'titles.returnDetailsTitle',
            '$Return Details$'
          ),
          details: getTranslation(
            data,
            'titles.returnOrderDetailsTitle',
            '$Order Details$'
          ),
          summary: getTranslation(
            data,
            'titles.returnSummaryTitle',
            '$Shipping & Summary$'
          ),
          confirmation: getTranslation(
            data,
            'titles.returnConfirmationTitle',
            '$Confirmation$'
          ),
        },
      },
    },
  };
};
