import { ComponentInstance } from '@vf/composables';
import { useCyberSourcePaymentProvider } from './cybersource';
import { useAdyenPaymentProvider } from './adyen';
import { usePaymentStore } from '../store/payment';
import type { ComputedRef, Ref } from '@vue/composition-api';
import { ValidatorField } from '../useValidators';
import { TokenizedCreditCard } from '@vf/api-contract';

export interface PaymentProvider {
  form: {
    expirationDate?: Ref<string>;
    validate?: (field: string) => boolean;
    validationFields: Ref<Record<string, ValidatorField>>;
  };
  getAdditionalData: () => any;
  getCardData: () => Promise<TokenizedCreditCard>;
  initService: () => Promise<void>;
  isExpiryExternal?: boolean;
  load: () => Promise<void>;
  preparePaymentData: () => Promise<unknown>;
  render: () => Promise<void>;
  showCreditCardNumberSuccess: ComputedRef<boolean>;
  showExpirationDateSuccess?: ComputedRef<boolean>;
  showSecurityNumberSuccess: ComputedRef<boolean>;
  unload: () => Promise<void>;
  unmount: () => void;
  reset: () => void;
}

let provider: PaymentProvider;
let providerType = '';

export const usePaymentProvider = (
  instance: ComponentInstance
): PaymentProvider => {
  const paymentStore = usePaymentStore(instance);

  if (
    paymentStore.provider &&
    (!provider || providerType !== paymentStore.provider)
  ) {
    providerType = paymentStore.provider;
    switch (paymentStore.provider) {
      case 'ADYEN':
        provider = useAdyenPaymentProvider(instance);
        break;
      case 'CYBERSOURCE':
        provider = useCyberSourcePaymentProvider(instance);
        break;
    }
  }

  return provider;
};
