import {
  CMContentHero,
  CMContentBodyText,
  CMContentTextBlock,
  CMContentRow,
  CMContentHorizontalSimple,
  CmsSite,
  CMImageBlockWithOverlay,
} from '@vf/api-contract';
import { generateLinkFromTarget, appendParamsToUrl } from '.';
import { ComposableContext } from '../../types';

export function getTeaserTargets(
  data:
    | CMContentHero
    | CMContentBodyText
    | CMContentTextBlock
    | CMContentRow
    | CMContentHorizontalSimple
    | CMImageBlockWithOverlay,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) {
  const targets = (data.teaserTargets || []).map((teaserTarget) => {
    const basicLink = generateLinkFromTarget(
      teaserTarget.target,
      _context,
      siteConfiguration,
      cmsBaseUri,
      teaserTarget.linkBehavior === 'scrollToComponent'
    );

    return {
      label: teaserTarget.callToActionText,
      styleClass: teaserTarget.callToActionStyle,
      link: appendParamsToUrl(basicLink, teaserTarget.urlParams),
      linkBehavior: teaserTarget.linkBehavior,
      callToActionEnabled: teaserTarget.callToActionEnabled,
      icon: teaserTarget.callToActionIcon,
      buttonTargetComponent: teaserTarget.target.type,
    };
  });

  // Either mediaTarget or ctas are allowed, never both
  const mediaTarget = targets.find(
    (teaserTarget) => teaserTarget.callToActionEnabled === false
  );

  const ctas = mediaTarget
    ? []
    : targets.filter((teaserTarget) => teaserTarget.callToActionEnabled);

  return { ctas, mediaTarget };
}
