import {
  CmsMonetateComponent,
  CmsMonetateExperienceComponentData,
  CmsSite,
  ExperienceVariant,
} from '@vf/api-contract';
import { standardComponentDataParser } from './standard';
import { parseDataBasedOnType } from '../parseDataBasedOnType';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../../../types';
import {
  getStateManagementData,
  getSegmentsData,
} from '../../../mappings/utils/extractProperty';
import { getUrlQueryParam } from '../../../utils';
import useMonetate from '../../../../useMonetate';

export const monetateDecision = (
  component: CmsMonetateComponent,
  componentParser: ReturnType<typeof standardComponentDataParser>,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  context: ComposableContext,
  pageTypeName: PageTypeName
): CmsMonetateExperienceComponentData => {
  const { setLoading, addCustomVars } = useMonetate(
    context.instance,
    context.contextKey
  );

  const errors = [];
  const experienceId = component.experienceId.split('_')[3] || '';

  const parseData = (target: any) => {
    const { data, errors } = parseDataBasedOnType(
      { ...target, _injectedProps: { experienceId } },
      componentParser,
      siteConfiguration,
      cmsBaseUri,
      requirements,
      context,
      pageTypeName
    );

    return {
      data,
      errors,
    };
  };

  let baseline = null;

  try {
    const baselineParsedData = parseData(component.baseline);

    errors.push(...baselineParsedData.errors);
    baseline = baselineParsedData.data;
  } catch (e) {
    errors.push(`Monetate Baseline mapping error: ${e.message}`);
  }

  const forcedVariantLabel = getForcedVariantLabel(context, component.variants);

  if (!forcedVariantLabel) {
    /** Set loading flag to true to prevent flashing of old content in monetate experience Vue components */
    if (!context.instance.$isPreview) setLoading('loading');
  }
  addCustomVars(component.baseline?.localSettings?.customVariables);
  component.variants?.forEach((variant) =>
    addCustomVars(variant.target?.localSettings?.customVariables)
  );

  let defaultVariant = null;
  try {
    const parsedVariant = parseData(component.variants[0].target);

    errors.push(...parsedVariant.errors);
    defaultVariant = parsedVariant.data;
  } catch (e) {
    errors.push(`Monetate Default Variant mapping error: ${e.message}`);
  }

  return {
    data: {
      component: 'monetate/MonetateExperience',
      _cms_id: `monetate-decision-${component.experienceId}`,
      props: {
        experienceId,
        forcedVariantLabel,
        baseline: {
          children: baseline ? [baseline] : [],
        },
        _states: getStateManagementData(component),
        _segments: getSegmentsData(component),
        defaultVariant: {
          children: defaultVariant ? [defaultVariant] : [],
        },
        variants: component.variants
          .map((variant) => {
            let content = null;

            if (!variant.target) {
              return null;
            }

            try {
              const parsedVariant = parseData(variant.target);

              errors.push(...parsedVariant.errors);
              content = parsedVariant.data;
            } catch (e) {
              errors.push(`Monetate Variant mapping error: ${e.message}`);
            }

            if (!content) {
              return null;
            }

            return {
              id: variant.variantId,
              label: variant.name,
              content: {
                children: [content],
              },
            };
          })
          .filter(Boolean),
      },
    },
    errors,
  };
};

const getForcedVariantLabel = (
  context: ComposableContext,
  variants: ExperienceVariant[]
): string | null => {
  try {
    if (!context.instance.$isPreview) return null;

    const cmsPayload: { variants: string[] } = JSON.parse(
      getUrlQueryParam(context, 'p13n_testcontext') || '{ "variants": [] }'
    );

    if (cmsPayload.variants && cmsPayload.variants.length) {
      const variantIds = cmsPayload.variants.map((cmsVariantPayload) =>
        (cmsVariantPayload || '').split('_').pop()
      );

      const forcedVariant = variants.find((variant) =>
        variantIds.includes(variant.variantId)
      );

      if (forcedVariant) {
        return forcedVariant.name;
      }
    }

    return null;
  } catch (e) {
    console.error('Error occurred on forced Monetate variant getter:', e);
    return null;
  }
};
