import { axios, headerBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const searchSite = (configuration: ApiClientConfiguration) => async (
  countryCode: string
): Promise<any> => {
  let url: string = endpoints.get(configuration).utilities.searchSite;
  url = `${url}?countryCode=${countryCode}`;
  const response = await axios.get(url, {
    headers: headerBuilder(configuration),
  });
  return response;
};
