import { Plugin } from '@nuxt/types';
import { ComponentInstance, useI18n } from '@vf/composables';
import { getVueInstanceFromContext } from '../helpers';

type PriceFormatEnv = {
  format: string;
  delimiter: string;
  currency: { [key: string]: string };
};

/**
 * Price Formatter plugin
 * Uses Intl.NumberFormat browser API
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
const FormatPrice: Plugin = (context, inject) => {
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  const { defaultCurrency, localeCode } = useI18n(vueInstance);
  let definedPriceFormat: PriceFormatEnv | null = null;

  /**
   * If ENV variable contains price format data - use it.
   * Otherwise use Intl.NumberFormat
   * */
  if (vueInstance.$env.PRICE_FORMAT) {
    try {
      const envPriceFormat = (vueInstance.$getEnvValueByCurrentLocale(
        'PRICE_FORMAT'
      ) as unknown) as PriceFormatEnv;

      if (envPriceFormat?.format) {
        definedPriceFormat = envPriceFormat;
      }
    } catch (e) {
      /** Price format is not defined in env variables, Intl.NumberFormat will be used */
    }
  }

  function formatPrice(
    price: number | string,
    currency?: string,
    rounded?: boolean
  ): string {
    try {
      const currencyToUse =
        /** Currency mapping from ENV variables are most important */
        definedPriceFormat?.currency?.[currency || defaultCurrency.value] ||
        /** If above is not defined - use currency from passed attribute */
        currency ||
        /** CMS defined currency will be used as a fallback */
        defaultCurrency.value;

      if (definedPriceFormat) {
        return definedPriceFormat.format
          .replace('{currency}', currencyToUse)
          .replace(
            '{price}',
            (Number(price) || 0)
              .toFixed(rounded ? 0 : 2)
              .replace('.', definedPriceFormat.delimiter || '.')
          );
      }

      return price.toLocaleString(formatLocaleStringForCurrency(localeCode()), {
        style: 'currency',
        currencyDisplay: getCurrencyDisplayMethod(currencyToUse),
        currency: currencyToUse,
      });
    } catch (e) {
      return `{{${currency || ''} ${price}}}`;
    }
  }

  inject('formatPrice', formatPrice);
};

/**
 * How to display the currency in currency formatting. Possible values are:
 * - "symbol" to use a localized currency symbol such as €, this is the default value,
 * - "narrowSymbol" to use a narrow format symbol ("$100" rather than "US$100"),
 * - "code" to use the ISO currency code,
 * - "name" to use a localized currency name such as "dollar",
 * @param currency
 */
const getCurrencyDisplayMethod = (
  currency: string
): 'code' | 'symbol' | 'narrowSymbol' | 'name' => {
  switch (currency) {
    case 'USD':
      return 'symbol';

    case 'CAD':
      return 'code';

    default:
      return 'code';
  }
};

const formatLocaleStringForCurrency = (locale: string) => {
  if (!locale) return '';

  /** Format locale just for Intl.NumberFormat, for example en-US */
  return locale
    .toLowerCase()
    .replace('_', '-')
    .split('-')
    .map((part, i) => {
      if (i > 0) {
        return part.toUpperCase();
      }
      return part;
    })
    .join('-');
};

export default FormatPrice;
