const LSVariables = [
  'cartId',
  'consumerId',
  'billingAddressId',
  'consumerNo',
  'employeeToken',
  'employeeExpiryTime',
  'employeeData',
];
export default {
  getItem: (item: string) => {
    if (typeof window === 'undefined') return null;
    return localStorage.getItem(item);
  },
  setItem: (item: string, value) => {
    if (typeof window === 'undefined') return null;
    return localStorage.setItem(item, value);
  },
  removeItem: (item: string) => {
    if (typeof window === 'undefined') return null;
    return localStorage.removeItem(item);
  },
  clearStorage: () => {
    if (typeof window === 'undefined') return null;
    LSVariables.forEach((element) => {
      localStorage.removeItem(element);
    });
    return true;
  },
};
