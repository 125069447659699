import { Element } from './../types';

export const focus = {
  bind(el: Element): void {
    el._mouseHandler = function () {
      el.style.outline = 'none';
    };
    el._keyHandler = function () {
      el.style.outline = '';
    };
    window.addEventListener('mousedown', el._mouseHandler);
    el.addEventListener('keyup', el._keyHandler);
  },
  unbind(el: Element): void {
    window.removeEventListener('mousedown', el._mouseHandler);
    el.removeEventListener('keyup', el._keyHandler);
  },
};
