import { isClient } from '@vf/shared/src/utils/helpers';

/**
 * Function to detect Safari browser
 * @returns {boolean}
 */
export const isSafari = (): boolean => {
  return isClient
    ? /^((?!chrome|android).)*safari/i.test(navigator?.userAgent)
    : false;
};
