import ls from '../utils/localStorage';
import { UseAuthenticationStorage } from './types';

export const syncStorageWithLocalStorage = (storage) => (
  ref: keyof UseAuthenticationStorage,
  data
) => {
  storage.get(ref).value = data;
  ls.setItem(ref, data);
};
