import type { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const submitContactFormSfcc = (
  configuration: ApiClientConfiguration
) => (body: unknown): Promise<AxiosResponse> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).contactForm.submitContactFormSfcc, {
      config: configuration,
    }),
    body,
    { headers: headerBuilder(configuration) }
  );
};
