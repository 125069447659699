import { CMPlaceholder } from '@vf/api-contract';
import { MappingMethod } from '../types';
import { extractLocalSettingsExpandedLinks, getTranslation } from './utils';

export const signOutCta: MappingMethod = (data: CMPlaceholder) => {
  const redirectTo =
    extractLocalSettingsExpandedLinks(data, 'redirectPage')?.[0]?.urlSegment ||
    null;

  return {
    component: 'shared/SignOutCTA',
    props: {
      redirectTo,
      translations: {
        buttonText: getTranslation(data, 'signOutLabel', '$Sign Out$'),
      },
    },
  };
};
