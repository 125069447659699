import { extractProperty } from './utils';

export const customsCustomizer = (data: any) => {
  return {
    component: 'customs/CustomsCustomizer',
    sfComponent: false,
    props: {
      preloadedComponent: 'CustomsCustomizer',
    },
    attrs: {
      experienceType:
        extractProperty(data, 'localSettings.dynamicFields.experienceType') ||
        undefined,
    },
  };
};
