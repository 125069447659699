/**
 * @deprecated remove when GLOBAL15-746 epic will be done
 * TODO: GLOBAL15-8194
 */
export const pdpReviewsForm = () => {
  return {
    component: 'pdp/ProductReviewForm',
    attrs: {
      'data-id': 'dataId',
    },
    props: {},
  };
};
