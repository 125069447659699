import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import {
  AddToFavoritesPayload,
  AddToFavoritesResponse,
} from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const addToFavorites = (configuration: ApiClientConfiguration) => (
  data: AddToFavoritesPayload
): Promise<AxiosResponse<AddToFavoritesResponse>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).wishList.addToFavorites, {
      config: configuration,
    }),
    data,
    {
      headers: headerBuilder(configuration),
    }
  );
};
