import { EventPropsHandler } from '.';
import {
  EnhancedSaleViewEvent,
  EnhancedSaleList,
  EnhancedSaleType,
  EventName,
  GtmProductObject,
  ProductDetailViewViewType,
} from '../../types/gtm';
import { Product } from '@vf/api-client';
import { mapProductDetail } from './productDetailViewHandler';
import useGtm from '..';

const mapProductDetailEnhancedSale = (
  product: GtmProductObject,
  {
    brand,
    list,
  }: {
    brand: string;
    list: string;
  }
) => {
  return {
    brand: brand,
    id: product.id,
    masterId: product.masterId,
    name: product.name,
    numReviews: product.numReviews,
    price: product.price,
    list: list,
    availSizes: product.availSizes,
    colorCode: product.colorCode,
    colorDescription: product.colorDescription,
    originalPrice: product.originalPrice,
    saleDiscountAmount: product.saleDiscountAmount,
    avgRating: product.avgRating,
    onSale: product.onSale,
    styleCode: product.styleCode,
    customized: product.customized,
  };
};

export const enhancedSaleViewHandler: EventPropsHandler<
  EnhancedSaleViewEvent,
  {
    type: EnhancedSaleType;
    product: Product;
  }
> = (eventProps, vmInstance) => {
  const { updatePersistentVariables } = useGtm(vmInstance);

  const { eventName, list } =
    eventProps.overrideAttributes.type === EnhancedSaleType.UP_SELL
      ? {
          eventName: 'UpSellCompleteTheLookView' as EventName,
          list: EnhancedSaleList.UP_SELL,
        }
      : {
          eventName: 'CrossSellCompleteTheLookView' as EventName,
          list: EnhancedSaleList.CROSS_SELL,
        };
  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();
  const currencyCode =
    eventProps.overrideAttributes.product.variant.price.currency;

  const product = mapProductDetailEnhancedSale(
    mapProductDetail(eventProps.overrideAttributes.product, {
      brand,
      persistentVariables: {},
      $themeConfig: vmInstance.$themeConfig,
    }),
    {
      brand,
      list,
    }
  );

  [eventProps.overrideAttributes.product.id, product.id].forEach((pid) => {
    updatePersistentVariables(pid, { list });
  });

  const ecommerce = {
    currencyCode,
    detail: {
      actionField: {
        viewType: ProductDetailViewViewType.QUICK_SHOP,
        list,
      },
      products: [product],
    },
  };

  return {
    event: eventName,
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Detail View',
    eventLabel: product.id,
    nonInteractive: true,
    ecommerce,
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
