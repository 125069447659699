import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';

type Bin = {
  binValue: string;
  type: string;
};

export const useOrderStore = defineStore('order', () => {
  const isPlaceOrderButtonDisabled = ref(false);
  const isPlaceOrderAttemptInProgress = ref(false);
  const loading = ref(false);
  const applePayOrderToken = ref('');
  const savedCreditCard = ref('');
  const paymentMethod = ref('');
  const paymentMethodsData = ref([]);
  const allAvailablePaymentMethods = ref([]);
  const forterToken = ref('');
  const challenge3ds = ref();
  const readyToPlaceOrder = ref(false);
  const bin = ref<Bin>({ binValue: '', type: '' });

  const resetOrderStore = () => {
    isPlaceOrderButtonDisabled.value = false;
    isPlaceOrderAttemptInProgress.value = false;
    loading.value = false;
    applePayOrderToken.value = '';
    savedCreditCard.value = '';
    paymentMethod.value = '';
    paymentMethodsData.value = [];
    allAvailablePaymentMethods.value = [];
    challenge3ds.value = undefined;
    readyToPlaceOrder.value = false;
    bin.value = { binValue: '', type: '' };
  };

  return {
    forterToken,
    challenge3ds,
    allAvailablePaymentMethods,
    bin,
    isPlaceOrderButtonDisabled,
    isPlaceOrderAttemptInProgress,
    loading,
    applePayOrderToken,
    savedCreditCard,
    paymentMethod,
    paymentMethodsData,
    readyToPlaceOrder,
    resetOrderStore,
  };
});
