
import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ThemeLink',
  functional: true,
  // TODO: GLOBAL15-61059 remove after redesign core
  inject: ['isCoreRedesignEnabled'],
  props: {
    color: String as PropType<'primary | reverse'>,
    size: String as PropType<'sm' | 'md' | 'lg' | 'base'>,
    context: String as PropType<'dark'>,
    to: [String, Object],
    tag: String,
    target: String,
    /** @deprecated Doesn't work, kept for compability? */
    exact: Boolean,
    /** @deprecated Doesn't work, kept for compability? */
    nofollow: Boolean,
    legacy: {
      /**
       * The only purpose of this prop is backward compatibility.
       * To use brand-sensitive ThemeLink set legacy to false and use new props related to styles.
       * When all existing ThemeLink will be refactored with legacy="false" this prop should removed
       */
      type: Boolean,
      default: true,
    },
  },
  render(h, { props, data, listeners, children, parent, injections }) {
    if (props.target) {
      data.attrs.target = props.target;
    }
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = injections.isCoreRedesignEnabled.value;

    return h(
      props.legacy ? 'UiLink' : `${parent.$config.theme}-theme-link`,
      {
        ...data,
        class: [data.class].concat(
          props.legacy
            ? ['vf-link', isCoreRedesignEnabled && 'vf-link--redesign-core']
            : [
                'vf-theme-link',
                props.color && `vf-color-${props.color}`,
                props.size && `vf-size-${props.size}`,
                props.context && `vf-context-${props.context}`,
                isCoreRedesignEnabled && 'vf-theme-link--redesign-core',
              ]
        ),
        props,
        on: listeners,
        nativeOn: data.nativeOn,
      },
      children
    );
  },
});
