

































































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
} from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';
import { useCms, useGtm, useI18n } from '@vf/composables';
import useModalHandlers from '@vf/ui/composables/useModalHandlers';
import useRootInstance from '@/shared/useRootInstance';
import {
  getCacheKeyFromProps,
  scrollTo as scrollToTop,
} from '@vf/shared/src/utils/helpers';
import { AnalyticsDataDomLocation } from '../../../types';
import VfFooterColumn from '@vf/ui/components/Organism.FooterColumn.vue';
import VfFooterSubscribe from '@/components/footer/FooterSubscribe.vue';
import VfFooterSocialMedia from '@/components/footer/FooterSocialMedia.vue';
import VfFooterStoreLocator from '@/components/footer/FooterStoreLocator.vue';
import type {
  FooterBackToTop,
  FooterBottomBar,
  FooterColumnItem,
  FooterLogo,
  FooterSocialLink,
  FooterStoreLocator,
  FooterSubscribe,
} from '@vf/api-contract';

export default defineComponent({
  name: 'SmartFooter',
  components: {
    VfFooterColumn,
    VfFooterSubscribe,
    VfFooterSocialMedia,
    VfFooterStoreLocator,
  },
  directives: { focus },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Array with bottom footer section data */
    bottomBar: {
      type: [String, Object] as PropType<FooterBottomBar | FooterBottomBar[]>,
      default: '',
    },
    /** Number of footer columns */
    column: {
      type: Number,
      default: 5,
    },
    /** Array of columns data */
    columns: {
      type: Array as PropType<FooterColumnItem[]>,
      default: () => [],
    },
    /** _Deprecated property_ */
    multiple: {
      type: Boolean,
      default: true,
    },
    /** Logo image data */
    logo: {
      type: [String, Object] as PropType<FooterLogo | FooterLogo[]>,
      default: '',
    },
    /** Array of social media icons */
    socialMedia: {
      type: [Object, Array] as PropType<FooterSocialLink | FooterSocialLink[]>,
      default: () => ({}),
    },
    /** Array of available localizations for country selector */
    locales: {
      type: Array,
      default: () => [],
    },
    /** Array of store locator section data */
    storeLocator: {
      type: [Object, Array] as PropType<
        FooterStoreLocator | FooterStoreLocator[]
      >,
      default: () => ({}),
    },
    subscribe: {
      type: Object as PropType<FooterSubscribe>,
      default: () => ({
        translations: {
          privacyLink: '',
          legalTermText: '',
        },
      }),
    },
    /** Back To Top button text */
    backToTop: {
      type: [String, Object] as PropType<FooterBackToTop>,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { changeLocale: changeCurrentLocale, setup } = useCms(
      root,
      props.contextKey
    );

    const { dispatchEvent } = useGtm(root);
    const { configureApiInternalization } = useI18n(root);
    const { openModal } = useModalHandlers();

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled: Ref<boolean> = inject('isCoreRedesignEnabled');

    const stateFulfilledColumns = computed(() =>
      props.columns.filter((i) => {
        if (i.stateFulfilled) {
          i.items = i.items.filter(
            (item) =>
              root.$stateManagementChecker(item.states) &&
              root.$segmentsChecker(item.segments)
          );
          return true;
        }
        return false;
      })
    );

    const enableCSRHydration = ref(false);

    onMounted(() => {
      enableCSRHydration.value = true;
    });

    const selectedLocale = computed(() => root.$i18n.locale);

    const style = computed(() => {
      return {
        '--_footer-column-width': `calc(${
          100 / props.column
        }% - calc(var(--spacer-default) * 2))`,
      };
    });

    const classes = root.$themeConfig?.footer
      ?.bottomItemsFooterContainerNoTopPadding
      ? ' vf-footer__container--no-top-padding'
      : '';

    const changeLocale = async (value) => {
      await setup({ forcePreview: root.$isPreview });
      changeCurrentLocale(value);
      configureApiInternalization();
    };

    const handleFooterLinkClick = (data, e: Event) => {
      const eventLabel = [data.l1, data.l2].filter(Boolean).join(' - ');
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Navigation',
          eventAction: 'Global Footer Nav',
          eventLabel,
        },
      });

      if (!data.openInNewModal) return;
      e.preventDefault();
      openModal({
        type: 'page',
        path: data.link,
      });
    };

    const isChat = (item) => item.role === 'chat';

    const footerBottomSectionClass = [
      root.$themeConfig.footer.bottomSectionOrder[
        root.$viewport.size || 'small'
      ],
      // TODO: GLOBAL15-61059 remove after redesign core
      isCoreRedesignEnabled.value ? 'footer-bottom-section--redesign-core' : '',
    ];

    return {
      isChat,
      scrollToTop,
      changeLocale,
      handleFooterLinkClick,
      enableCSRHydration,
      style,
      classes,
      stateFulfilledColumns,
      AnalyticsDataDomLocation,
      selectedLocale,
      isCoreRedesignEnabled,
      footerBottomSectionClass,
    };
  },
});
