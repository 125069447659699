import { Plugin } from '@nuxt/types';
import { ComponentInstance } from '@vf/composables/src/types';
import { getVueInstanceFromContext } from '../helpers';
import { useRouting } from '@vf/composables';

const TrackRoutingPlugin: Plugin = (context) => {
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  const { setPreviousRoute, setCurrentRoute } = useRouting(vueInstance);

  context.app.router.afterEach((to, from) => {
    setPreviousRoute(from);
    setCurrentRoute(to);
  });
};

export default TrackRoutingPlugin;
