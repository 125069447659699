
export default {
  name: 'ThemeTitle',
  functional: true,
  props: {
    variant: String,
    color: String,
    colorHex: String,
    backgroundColor: String,
    backgroundColorHex: String,
    level: {
      type: [Number, String],
      required: true,
    },
  },
  render(h, { props, data, listeners, children, parent }) {
    return h(
      `${parent.$config.theme}-theme-title`,
      {
        ...data,
        class: [
          'vf-theme-title',
          ...(data.staticClass || data.class
            ? [data.staticClass, data.class]
            : []),
          props.color ? `vf-color-${props.color}` : 'vf-color-unset',
          props.backgroundColor
            ? `vf-bg-color-${props.backgroundColor}`
            : 'vf-bg-color-unset',
          props.variant ? `vf-variant-${props.variant}` : 'vf-variant-unset',
        ],
        style: {
          color: props.colorHex || null,
          'background-color': props.backgroundColorHex || null,
        },
        props,
        on: listeners,
      },
      children
    );
  },
};
