import { apiClientFactory } from '@vf/api-client';
import { prepareLocale } from '../../../utils/query';
import { ref, Ref } from '@vue/composition-api';
import { OrderDetailsObject } from '@vf/api-contract';
import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { useAuthentication, useI18n } from '@vf/composables';
import { UseAccountStorage } from '../../index';

export const useOrderDetails = (instance) => {
  const { localeCode } = useI18n(instance);
  const { getUsid } = useAuthentication(instance);
  const { getOrderDetails: getOrderDetailsAPI } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const orderDetails: Ref<OrderDetailsObject> =
    storage.get('orderDetails') ??
    storage.save(
      'orderDetails',
      ref({
        orderId: '',
        shipments: [],
        billingAddress: {},
        brand: '',
        channel: '',
        customized: false,
        items: [],
        orderDate: '',
        orderNumber: '',
        orderPromotions: [],
        payments: [],
        source: '',
        status: '',
        totals: {},
      })
    );

  /**
   * Get order details by consumer identifiant and order id.
   *
   * @param orderId
   * @param persist
   */
  const getOrderDetails = async (orderId: string, persist = true) => {
    try {
      const result = await getOrderDetailsAPI(
        orderId,
        prepareLocale(localeCode()),
        {
          usid: getUsid.value,
        }
      );
      const response =
        result.data.length > 0 && result.status === 200
          ? result.data[0]
          : {
              orderId: '',
              shipments: [],
              billingAddress: {},
              brand: '',
              channel: '',
              customized: false,
              items: [],
              orderDate: '',
              orderNumber: '',
              orderPromotions: [],
              payments: [],
              source: '',
              status: '',
              totals: {},
            };
      if (persist) {
        orderDetails.value = { ...response };
      }
      return response;
    } catch (e) {
      console.error('Error fetching OrderDetails!');
    }
  };

  return {
    getOrderDetails,
    orderDetails,
  };
};
