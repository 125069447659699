module.exports.storeLocatorLinkBuilder = function (
  startLink = '',
  state = '',
  city = '',
  clientKey = ''
) {
  let endpoint = startLink;
  if (state) {
    endpoint = `${endpoint}/${state}`;
  }
  if (city) {
    endpoint = `${endpoint}/${city.replace(/[^a-zA-Z0-9]/g, '-')}`;
  }
  if (clientKey) {
    endpoint = `${endpoint}/${clientKey}`;
  }
  return endpoint.toLowerCase();
};
