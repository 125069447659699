import { differenceInYears } from 'date-fns';
import { getParsedDate } from '../form-helpers';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/min';

const DATE = new Date();
// only letters and special characters
const DEFAULT_NAME_REGEXP: RegExp = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
// eslint-disable-next-line no-control-regex
const EMAIL_REGEX = /^(?!(.*\.(test|example|invalid|localhost))$|(.*\.example\.(com|net|org))$)(?:[A-z0-9!#$%\/&'*+=?^_`{|}~-]+(?:\.[A-z0-9!\/#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9](?:[A-z0-9-]*[A-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-z0-9-]*[A-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
// there is no use case for a year older than 130 years ago so this keeps validation of dates "feeling" real
const MAX_AGE = 130;
export const NAME_MAX_LENGTH = 64;

export const checkDate = (locale = 'en-US') => (value: string): boolean => {
  try {
    if (!value) return true;
    const parsedDate = getParsedDate(value, locale);
    const ageDiff = differenceInYears(DATE, parsedDate);

    if (ageDiff >= MAX_AGE) return false;

    return Boolean(new Intl.DateTimeFormat(locale).format(parsedDate));
  } catch (e) {
    return false;
  }
};

export const checkAge = (minAge: number, locale: string = 'en-US') => (
  value: string
): boolean => {
  if (!value) return true;
  const parsedDate = getParsedDate(value, locale);
  const diff = differenceInYears(DATE, parsedDate);
  return diff >= minAge && diff < MAX_AGE;
};

export const email = (email: string): boolean => {
  if (!email) return true;
  return EMAIL_REGEX.test(email);
};

/**
 * Name should include only letters and special characters
 * @param regexp
 */
export const checkName = (regexp: RegExp = DEFAULT_NAME_REGEXP) => (
  name: string
): boolean => regexp.test(name);

export type PasswordValidationRule =
  | 'length'
  | 'number'
  | 'uppercase'
  | 'lowercase';

export type ValidationSteps = Record<PasswordValidationRule, string>;

/**
 * At least 8 characters includes optional allowed special characters ($%/()[]{}=?!.,-_*|+~#@)
 * and required one digit, one letter, one lowercase, one uppercase.
 */
export const checkPassword = (password: string): boolean => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w$%/()[\]{}=?!.,\-*|+~#@]{8,}$/;
  return reg.test(password);
};

export const matchPasswordRule = (
  password: string,
  ruleName: PasswordValidationRule
): boolean => {
  switch (ruleName) {
    case 'number':
      return password && new RegExp(/\d/).test(password);
    case 'length':
      return password && password.length > 7;
    case 'uppercase':
      return password && new RegExp(/[A-Z]/).test(password);
    case 'lowercase':
      return password && new RegExp(/[a-z]/).test(password);
    default:
      return false;
  }
};

export const checkPhone = (
  phoneNumber: string,
  country: CountryCode = 'US'
): boolean => {
  if (!phoneNumber) return false;
  return isValidPhoneNumber(phoneNumber, country);
};
