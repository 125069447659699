import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { OrderDetailsObject } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const getGuestOrderDetails = (configuration: ApiClientConfiguration) => (
  body: {
    orderNo: string;
    zipCode: string;
    [searchBy: string]: string;
  },
  params: {
    captchaResponse: string;
  }
): Promise<AxiosResponse<OrderDetailsObject[]>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.trackOrder,
    {
      config: configuration,
    }
  );

  return axios.post(url, body, {
    headers: headerBuilder(configuration),
    params,
  });
};
