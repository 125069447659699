import { ssrRef, Ref } from '@nuxtjs/composition-api';
import {
  CMDataSource,
  PdpGalleryImage,
} from '../../../utils/dataSources/types';
import { useRequestTracker } from '../../../useRequestTracker';
import { dataSourceFetcher } from '../../../utils/dataSources';
import initStorage from '../../../utils/storage';
import { ComponentInstance, ComposablesStorage } from '../../../types';
import { UseProductStorage } from '../../types';

export const useProductGallery = (
  instance: ComponentInstance,
  { product },
  contextKey: string
) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const storage: ComposablesStorage<UseProductStorage> = initStorage<UseProductStorage>(
    instance,
    `useProduct-${contextKey}`
  );
  const pdpGalleryDataSources: Ref<CMDataSource[]> =
    storage.get('pdpGalleryDataSources') ??
    storage.save(
      'pdpGalleryDataSources',
      ssrRef([], `useProduct-pdpGalleryDataSources-${contextKey}`)
    );

  const pdpImages: Ref<PdpGalleryImage[]> =
    storage.get('pdpImages') ??
    storage.save('pdpImages', ssrRef([], `useProduct-pdpImages-${contextKey}`));

  const fallbackImage = instance.$mediaUrlFallback();
  const notFoundGalleryImage: PdpGalleryImage = {
    src: fallbackImage,
    thumbnail: fallbackImage,
    alt: 'Image not found',
  };

  const setPdpGalleryDataSource = async (sources: CMDataSource[]) => {
    pdpGalleryDataSources.value = sources;
  };

  const loadPdpGalleryImages = async (
    { isBackgroundRequest, configuredColor, allowColorOutsideOfVariants } = {
      isBackgroundRequest: false,
      configuredColor: '',
      allowColorOutsideOfVariants: false,
    }
    // TODO: add return type
  ) => {
    if (!product.value) return;
    // check if selected color is available on pdp
    const isColorAvailable = product.value.colors.find(
      ({ value }) => value === configuredColor
    )?.value;
    const availableColor = isColorAvailable
      ? configuredColor
      : allowColorOutsideOfVariants
      ? configuredColor
      : undefined;

    const { tag, isAlreadyTracked } = trackRequest(
      'useProduct-loadPdpImages',
      isBackgroundRequest
    );

    if (isAlreadyTracked) return clearRequest(tag);

    try {
      const imagesData = await dataSourceFetcher.getMediaPdp({
        colorCode: availableColor,
        instance,
        isPdpImage: true,
        pdpGalleryDataSources: pdpGalleryDataSources.value,
        product: product.value,
      });
      pdpImages.value = imagesData.length ? imagesData : [notFoundGalleryImage];
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const loadPlpGalleryImages = async (
    {
      colorCode,
      colorName,
      productId,
      productName,
    }: {
      colorCode: string;
      colorName: string;
      productId: string;
      productName: string;
    },
    { isBackgroundRequest } = { isBackgroundRequest: false }
    // TODO: add return type
  ) => {
    if (!productId || typeof window === 'undefined') return;
    const { tag, isAlreadyTracked } = trackRequest(
      `useProduct-loadPlpImages${productId}${colorCode}`,
      isBackgroundRequest
    );

    const imagesData = [];
    try {
      if (isAlreadyTracked) return clearRequest(tag);
      imagesData.push(
        ...(await dataSourceFetcher.getMediaPlp({
          colorCode,
          colorName,
          instance,
          productId,
          productName,
        }))
      );
      return imagesData;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const resetGallery = () => {
    pdpImages.value = [];
  };

  return {
    pdpGalleryDataSources,
    pdpImages,
    loadPdpGalleryImages,
    loadPlpGalleryImages,
    setPdpGalleryDataSource,
    resetGallery,
  };
};
