import { Plugin } from '@nuxt/types';
import { Segment } from '@vf/api-contract';
import { getVueInstanceFromContext } from '../helpers';
import { manageSegments } from '@vf/composables';

const customerSegments: Plugin = (context, inject) => {
  function segmentsChecker(segments: Segment[] | null): boolean {
    return manageSegments(segments, getVueInstanceFromContext(context));
  }

  inject('segmentsChecker', segmentsChecker);
};

export default customerSegments;
