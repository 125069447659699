import { computed, ref, Ref } from '@vue/composition-api';
import ls from '../../../utils/localStorage';
import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { UseAccountStorage } from '../../index';

export const useFavoriteStore = (instance) => {
  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const favoriteStoreId: Ref<string> =
    storage.get('favoriteStoreId') ??
    storage.save('favoriteStoreId', ref(ls.getItem('favoriteStoreId')));

  const setFavoriteStoreId = (storeId: string): void => {
    if (favoriteStoreId.value && favoriteStoreId.value === storeId) {
      favoriteStoreId.value = null;
      ls.removeItem('favoriteStoreId');
    } else {
      favoriteStoreId.value = storeId;
      ls.setItem('favoriteStoreId', storeId);
    }
  };

  return {
    setFavoriteStoreId,
    favoriteStoreId: computed(() => favoriteStoreId.value),
  };
};
