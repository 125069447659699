import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { validateResponse } from './helpers';
import {
  PhotorankApi,
  PhotorankApiStream,
  PhotorankApiSignInRequest,
} from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const createSNUser = (configuration: ApiClientConfiguration) => async (
  apiKey: string,
  data: PhotorankApiSignInRequest
): Promise<string> => {
  const url = endpoints
    .get(configuration)
    .socialNative.createUser.replace('{api_key}', apiKey);
  const response = await axios.post<PhotorankApi<PhotorankApiStream>>(url, {
    email: data.email,
    screen_name: data.screen_name,
  });
  validateResponse(response.data);
  return response.data.data.id;
};
