import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';
import { getDynamicField } from './utils';
import { getGiftOptionTranslations } from './utils/getGiftOptionTranslations';

export const orderDetails = (data: CMPlaceholder) => {
  return {
    component: 'order/OrderDetails',
    props: {
      translations: {
        ...getGiftOptionTranslations(data),
        shippingInfoLabel: getTranslation(
          data,
          'shippingInfoLabel',
          '$Shipping Info$'
        ),
        shippingToLabel: getTranslation(
          data,
          'shippingToLabel',
          '$Shipping To$'
        ),
        shippingMethodLabel: getTranslation(
          data,
          'shippingMethodLabel',
          '$Shipping Method$'
        ),
        addressLabel: getTranslation(data, 'addressLabel', '$Address$'),
        billingInfoLabel: getTranslation(
          data,
          'billingInfoLabel',
          '$Billing Info$'
        ),
        billingToLabel: getTranslation(data, 'billingToLabel', '$Billing To$'),
        pickupInfoLabel: getTranslation(
          data,
          'pickupInfoLabel',
          '$Pickup info$'
        ),
        pickupLocationLabel: getTranslation(
          data,
          'pickupLocationLabel',
          '$Pickup Location$'
        ),
        pickupPersonLabel: getTranslation(
          data,
          'pickupPersonLabel',
          '$Pickup Person$'
        ),
        altPickupPersonLabel: getTranslation(
          data,
          'altPickupPersonLabel',
          '$Alt Pickup Person$'
        ),
        direction: getTranslation(data, 'direction', '$Directions$'),
        paymentLabel: getTranslation(data, 'paymentLabel', '$Payment$'),
        itemSummaryLabel: getTranslation(
          data,
          'itemSummaryLabel',
          '$Item Summary$'
        ),
        trackPackageLabel2: getTranslation(
          data,
          'trackPackageLabel2',
          '$Shipment: Tracking$'
        ),
        itemDescription: getTranslation(
          data,
          'itemDescription',
          '$Item Description$'
        ),
        quantity: getTranslation(data, 'quantity', '$Quantity$'),
        price: getTranslation(data, 'price', '$Price$'),
        total: getTranslation(data, 'total', '$Total$'),
        processingLabel: getTranslation(
          data,
          'processingLabel',
          '$Processing$'
        ),
        shippedLabel: getTranslation(data, 'shippedLabel', '$Shipped$'),
        returnReceivedLabel: getTranslation(
          data,
          'returnReceivedLabel',
          '$Return received$'
        ),
        returnCreatedLabel: getTranslation(
          data,
          'returnCreatedLabel',
          '$Return created$'
        ),
        returnItemsButtonText: getTranslation(
          data,
          'returnItemButtonText',
          '$Return Selected Items$'
        ),
        confirmReturnsButtonText: getTranslation(
          data,
          'confirmReturnsButtonText',
          'Confirm Returns'
        ),
        selectItemsToReturnSummaryHeading: getTranslation(
          data,
          'selectItemsToReturnSummaryHeading',
          '$2. Select Item(s) to Return$'
        ),
        itemsToReturnSummaryHeading: getTranslation(
          data,
          'itemsToReturnSummaryHeading',
          '$Item(s) to return$'
        ),
        itemsInReturnSummaryHeading: getTranslation(
          data,
          'itemsInReturnSummaryHeading',
          '$Item(s) in return$'
        ),
        cancelledLabel: getTranslation(data, 'cancelledLabel', '$Cancelled$'),
        pickedLabel: getTranslation(data, 'pickedLabel', '$Picked up$'),
        readyPickLabel: getTranslation(
          data,
          'readyPickLabel',
          '$Ready for Customer Pickup$'
        ),
        shippedToStoreLabel: getTranslation(
          data,
          'shippedToStoreLabel',
          '$Shipped to Store$'
        ),
        pickExpiredLabel: getTranslation(
          data,
          'pickExpiredLabel',
          '$Pick Up Expired$'
        ),
        color: getTranslation(data, 'color', '$Color$'),
        size: getTranslation(data, 'size', '$Size$'),
        qty: getTranslation(data, 'qtyLabel', '$Quantity$'),
        shippingDurationInfo: getTranslation(data, 'shippingDurationInfo', {
          express: {
            line1: '$Shipping Express$',
            line2: '$1-2 days$',
          },
          standard: {
            line1: '$Standard Shipping$',
            line2: '$2-3 business days$',
          },
          pobox: {
            line1: '$Pobox Shipping$',
            line2: '$3-4 business days$',
          },
          customs: {
            line1: '$Customs Shipping$',
            line2: '$Available 3-4 weeks$',
          },
        }),
        paymentMethodLabel: {
          CREDIT_CARD: '$Credit Card$',
          GIFT_CARD: '$Gift Card$',
          REWARD_CARD: '$Reward Card$',
          PAYPAL: '$PayPal$',
          KLARNA: '${{klarnaIconRed}} 4 interest-free payments$',
          DW_APPLE_PAY: '$Apple Pay$',
          ATHLETE_CREDIT: '$Athlete Credit$',
          LOYALTY_POINTS: '$Loyalty Points$',
          ...getTranslation(data, 'paymentMethodLabel', {}),
        },
        paymentTextWithCardType: getTranslation(
          data,
          'paymentTextWithCardType',
          '${{type}}: **** **** **** {{number}}$'
        ),
        paymentTextWithoutCardType: getTranslation(
          data,
          'paymentTextWithoutCardType',
          '$**** **** **** {{number}}$'
        ),
        shipmentDataProcessedMessage: getTranslation(
          data,
          'shipmentDataProcessedMessage',
          "$A shipment for your order is being processed. We'll update the details soon.$"
        ),
        shipmentSummaryArrival: getTranslation(
          data,
          'shipmentSummaryArrival',
          '$Shipment {{number}} below:$'
        ),
        returnedItemsText: getTranslation(
          data,
          'returnedItemsText',
          '${{quantity}} item(s) returned$'
        ),
        returnedItemsLinkText: getTranslation(
          data,
          'returnedItemsLinkText',
          '$DETAILS$'
        ),
        reasonDropdownLabel: getTranslation(
          data,
          'reasonDropdownLabel',
          '$SELECT REASON$'
        ),
        cancelledItemsLabel: getTranslation(
          data,
          'cancelledItemsLabel',
          '$Following items are cancelled$'
        ),
        free: getTranslation(data, 'free', '$Free$'),
        emailPlaceholder: getTranslation(
          data,
          'emailPlaceholder',
          '$Email Address$'
        ),
        reason: getTranslation(data, 'reason', '$REASON$'),
        ariaLabel: getTranslation(data, 'ariaLabel', '$Shopping Cart$'),
        pickupFromLabel: getTranslation(data, 'pickupFromLabel', '$Pickup$'),
        notificationText: getTranslation(
          data,
          'notificationText',
          '$We will notify you$'
        ),
        tracking: getTranslation(data, 'tracking', '$Tracking #:$'),
        selectReturnToTrackLabel: getTranslation(
          data,
          'narvar.selectReturnToTrackLabel',
          '$Select a return to Track$'
        ),
        returnDateLabel: getTranslation(
          data,
          'narvar.returnDateLabel',
          '$#{{number}}: {{MMMM dd, yyyy}}$'
        ),
        startReturnLinkButton: getTranslation(
          data,
          'narvar.startReturnLinkButton',
          '$Start a Return$'
        ),
        trackReturnLinkButton: getTranslation(
          data,
          'narvar.trackReturnLinkButton',
          '$Track My Return$'
        ),
        legacyReward: getTranslation(data, 'legacyReward', '$Legacy Reward$'),
        summer: getTranslation(data, 'summer', '$Summer$'),
        winter: getTranslation(data, 'winter', '$Winter$'),
        spring: getTranslation(data, 'spring', '$Spring$'),
      },
      orderPageType: getDynamicField(data, 'orderPageType', 'order'),
      refundMethodOptions: {
        title: getTranslation(
          data,
          'refundMethodTitle',
          '$1. Select Refund Method$'
        ),
        options: [
          {
            label: getTranslation(
              data,
              'originalPaymentMethod',
              '$Refund return amount to my original form of payment.$'
            ),
            method: 'original',
            tooltip: getTranslation(
              data,
              'originalPaymentMethodHint',
              "$Mail item(s) back to The North Face for a refund (minus any applicable shipping changes if you use our own prepaid label), and we'll credit your original form of payment once we receive your returned item(s).$"
            ),
          },
          {
            label: getTranslation(
              data,
              'giftCard',
              '$Receive an E-Gift Card with return amount via email at this address.$'
            ),
            method: 'giftCard',
            tooltip: getTranslation(
              data,
              'giftCardHint',
              "$Returning item(s)for E-Gift Card at thenotrhface.com is the fastest way to receive a new order. Mail us back the item(s) you don't want, and we'll issue an E-Gift Card at thenorthface.com for the value of the item(s) you returned once we receive your package(s).$"
            ),
          },
        ],
      },
      displayReturnedItemsDetailsCTA: getDynamicField(
        data,
        'displayReturnedItemsDetailsCTA',
        true
      ),
    },
  };
};
