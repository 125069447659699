import { ApiClientConfiguration } from '@vf/api-client';
import { ComponentInstance } from '@vf/composables';
import { PhotorankApi, SocialNativeAnalyticsParams } from '../../../api-types';
import { endpoints } from '../../../config';
import { axios } from '../../../helpers';
import { buildTrackUrlWithOptions } from './helpers';

export const trackWidgetEvent = (
  configuration: ApiClientConfiguration
) => async (
  widgetType: string,
  options: {
    instanceId: string;
    action: string;
    categoryId?: string;
    streamId?: string;
    format?: string;
  },
  params: SocialNativeAnalyticsParams,
  root: ComponentInstance
): Promise<any> => {
  const url = buildTrackUrlWithOptions(
    widgetType,
    endpoints.get(configuration).socialNative.analytics.trackWidgetEvent[
      widgetType
    ],
    options,
    root
  );

  return axios.get<PhotorankApi>(url, {
    params,
  });
};
