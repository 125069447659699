import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTranslation, generateLinkFromTarget } from './utils';

export const maHomeCreditCardsBlock = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'account/CreditCardsSummary',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Credit Card$'),
        textNoCreditCards: getTranslation(
          data,
          'textNoCreditCards',
          '$You have no saved credit cards$'
        ),
        ctaLink: generateLinkFromTarget(
          data.teaserTargets[0].target,
          context,
          siteConfiguration,
          cmsBaseUri
        ),
        ctaLinkNew: generateLinkFromTarget(
          data.teaserTargets[1].target,
          context,
          siteConfiguration,
          cmsBaseUri
        ),
        ctaText: getTranslation(data, 'ctaText', '$View All Credit Cards$'),
        ctaTextNoCreditCards: getTranslation(
          data,
          'ctaTextNoCreditCards',
          '$Add a Credit Card$'
        ),
        defaultCreditCardLabel: getTranslation(
          data,
          'defaultCreditCardLabel',
          '$Default Credit Card$'
        ),
        cardLabel: getTranslation(data, 'cardLabel', '$Card$'),
        addressLabel: getTranslation(data, 'addressLabel', '$Address$'),
        phoneLabel: getTranslation(data, 'phoneLabel', '$Phone$'),
        cardNumberReplacementText: getTranslation(
          data,
          'cardNumberReplacementText',
          '$ending in$'
        ),
        cardExpReplacementText: getTranslation(
          data,
          'cardExpReplacementText',
          '$exp$'
        ),
        serverErrorMessage: getTranslation(
          data,
          'serverErrorMessage',
          '$Unable to get credit cards data$'
        ),
      },
    },
  };
};
