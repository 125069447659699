import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { validateResponse } from './helpers';
import {
  PhotorankApi,
  PhotorankApiMedia,
  SocialNativePagination,
} from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

/**
 * all medias by stream id
 * @returns medias and next url link
 */
export const getSNMediaByStream = (
  configuration: ApiClientConfiguration
) => async ({
  apiKey,
  streamId,
  count = 20,
  pageNumber = 1,
}: {
  apiKey: string;
  streamId: string;
  count?: number;
  pageNumber?: number;
}): Promise<SocialNativePagination> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getMediaByStream.replace('{api_key}', apiKey)
    .replace('{stream_id}', streamId)
    .replace('{count}', `${count}`)
    .replace('{page_number}', `${pageNumber}`);
  const response = await axios.get<PhotorankApi<PhotorankApiMedia>>(url);
  validateResponse(response.data);
  return {
    nextLink: response.data.data._links.next.href,
    media: response.data.data._embedded.media,
  };
};

/**
 * all recent medias by custeomr
 * @returns medias and next url link
 */
export const getSNMediaByCustomer = (
  configuration: ApiClientConfiguration
) => async ({
  apiKey,
  customerId,
  count = 20,
  pageNumber = 1,
}: {
  apiKey: string;
  customerId: string;
  count?: number;
  pageNumber?: number;
}): Promise<SocialNativePagination> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getMediaByCustomer.replace('{api_key}', apiKey)
    .replace('{customer_id}', customerId)
    .replace('{count}', `${count}`)
    .replace('{page_number}', `${pageNumber}`);
  const response = await axios.get<PhotorankApi<PhotorankApiMedia>>(url);
  validateResponse(response.data);
  return {
    nextLink: response.data.data._links.next.href,
    media: response.data.data._embedded.media,
  };
};

/**
 * all medias by category id
 */
export const getSNMediaByCategoryId = (
  configuration: ApiClientConfiguration
) => async ({
  apiKey,
  categoryId,
  count = 20,
  pageNumber = 1,
}: {
  apiKey: string;
  categoryId: string;
  count?: number;
  pageNumber?: number;
}): Promise<SocialNativePagination> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getMediaByCategoryId.replace('{api_key}', apiKey)
    .replace('{tag_key}', categoryId)
    .replace('{count}', `${count}`)
    .replace('{page_number}', `${pageNumber}`);
  const response = await axios.get<PhotorankApi<PhotorankApiMedia>>(url);
  validateResponse(response.data);
  return {
    nextLink: response.data.data._links.next.href,
    media: response.data.data._embedded.media,
  };
};

const extractStreamIdFromSNMediaResponse = (data) => {
  const [streamId] = data._links.self.href.match(/\/streams\/\d+/) || [];

  if (streamId) {
    return streamId.slice('/streams/'.length);
  }
};

/**
 * all medias by tagKey
 */
export const getSNMediaByTagKey = (
  configuration: ApiClientConfiguration
) => async ({
  apiKey,
  tagKey,
  count = 20,
  pageNumber = 1,
}: {
  apiKey: string;
  tagKey: string;
  count?: number;
  pageNumber?: number;
}): Promise<SocialNativePagination> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getMediaByTagKey.replace('{api_key}', apiKey)
    .replace('{tag_key}', tagKey)
    .replace('{count}', `${count}`)
    .replace('{page_number}', `${pageNumber}`);
  const response = await axios.get<PhotorankApi<PhotorankApiMedia>>(url);
  validateResponse(response.data);

  const streamId = extractStreamIdFromSNMediaResponse(response.data.data);

  return {
    nextLink: response.data.data._links.next.href,
    streamId,
    media: response.data.data._embedded.media,
  };
};
