






















































import { computed, defineComponent } from '@vue/composition-api';
import MobileMenuAccordion from '@/components/smart/layout/MobileMenuAccordion.vue';
import MobileMenuColumnList from '@/components/smart/layout/MobileMenuColumnList.vue';
import MobileMenuMoreSection from '@/components/smart/layout/MobileMenuMoreSection.vue';
import useRootInstance from '@/shared/useRootInstance';
import { memoize } from '../../../helpers/memoize';

export default defineComponent({
  name: 'HeaderMegaMenuMobile',
  components: {
    MobileMenuMoreSection,
    MobileMenuColumnList,
    MobileMenuAccordion,
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
    isMobileNavigationItemHidden: {
      type: Function,
      default: (i) => i,
    },
    mobileMenuSettings: {
      type: Object,
      default: () => ({}),
    },
    shopAll: {
      type: String,
      default: '',
    },
    showShopAll: {
      type: Function,
      default: () => undefined,
    },
    handleUtilityNavLinkClick: {
      type: Function,
      default: () => undefined,
    },
    displayLabel: {
      type: Function,
      default: () => undefined,
    },
    maxItems: {
      type: Object,
      default: () => ({}),
    },
    moreTitle: {
      type: String,
      default: '',
    },
    utilityNavigation: {
      type: Object,
      default: () => ({ desktop: [], mobile: [] }),
    },
    loyaltyRewardsProgressTranslations: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig;
    const isMobileMenuWithAccordion = theme.header.isMobileMenuWithAccordion;

    const fulfilledNavigation = computed(() => {
      return props.navigation.filter(
        (el, i) => el.stateFulfilled && !props.isMobileNavigationItemHidden(i)
      );
    });

    const handleMobileNavLinkClick = (e, data) => {
      if (data.isModal) {
        e.preventDefault();
      }
      emit('nav-menu-link-clicked', data);
    };

    const handleActiveMegaMenuColumn = (data) => {
      emit('nav-active-megamenu-column', data);
    };

    const getShopAllLabel = (mobileTitle, title, settings = null) => {
      if (settings) {
        return settings['shop-all-button-mobile'];
      }
      return (props.shopAll || '').replace('{0}', mobileTitle || title);
    };

    const filteredNavigation = computed(() => {
      return isMobileMenuWithAccordion
        ? getFilteredNavigation(true)
        : getFilteredNavigation(false);
    });

    const getFilteredNavigation = (isAccordion: boolean) => {
      const columnsFilterCallback = (i) => i.stateFulfilled;
      return fulfilledNavigation.value.map((navItem) => ({
        ...navItem,
        columns: getFilteredColumns(
          navItem,
          columnsFilterCallback,
          isAccordion
        ),
      }));
    };

    const getFilteredColumns = memoize(
      (navItem, callback = (columnItem) => columnItem, isAccordion) => {
        const filteredColumns = navItem.columns.filter(callback);
        const isL2MenuItemWithImageInside = (i) =>
          i.children.length === 1 && i.children[0].isImage;
        const level2MenuItemsCallback = isAccordion
          ? (i) =>
              i.stateFulfilled && !i.isImage && !isL2MenuItemWithImageInside(i)
          : (i) => i.stateFulfilled;
        return filteredColumns.map((column) => ({
          ...column,
          level2MenuItems: getFilteredLevel2MenuItems(
            column,
            level2MenuItemsCallback,
            isAccordion
          ),
        }));
      }
    );

    const getFilteredLevel2MenuItems = memoize(
      (column, callback = (l2Item) => l2Item, isAccordion) => {
        const filteredLevel2MenuItems = column.level2MenuItems.filter(callback);
        const childrenFilterCallback = isAccordion
          ? (i) => i.stateFulfilled && !i.isImage
          : (i) => i.stateFulfilled;
        return filteredLevel2MenuItems.map((l2MenuItem) => ({
          ...l2MenuItem,
          children: getFilteredChildren(l2MenuItem, childrenFilterCallback),
        }));
      }
    );

    const getFilteredChildren = memoize(
      (l2MenuItem, callback = (child) => child) => {
        return l2MenuItem.children.filter(callback);
      }
    );

    return {
      isMobileMenuWithAccordion,
      handleMobileNavLinkClick,
      handleActiveMegaMenuColumn,
      getShopAllLabel,
      filteredNavigation,
      getFilteredNavigation,
    };
  },
});
