import { axios } from '../../helpers';

export const getSizeByBundlePartNumber = (
  bundlePartNumber: string,
  baseUrl: string,
  locale: string,
  caid: string
): Promise<any> => {
  const url = `${baseUrl}/${bundlePartNumber}?locale=${locale}&caid=${caid}`;
  return axios.get(url);
};
