import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../../types';
import { decorateHtmlValue } from './decorateHtmlValue';
import { extractLocalSettingsExpandedRichText } from './extractLocalSettingsExpandedRichText';

export const extractRichText = (
  data: CMPlaceholder,
  settingName: string,
  defaultText: string,
  cmsBaseUri: string,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const richText = extractLocalSettingsExpandedRichText(
    data,
    settingName,
    defaultText
  );
  return richText?.html
    ? decorateHtmlValue(richText, cmsBaseUri, context, siteConfiguration)
    : defaultText;
};
