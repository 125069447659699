import {
  extractCommonConfigLink,
  extractProperty,
  extractRichText,
  getDynamicField,
  getTranslation,
} from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getApplePayComponentData } from './utils/getApplePayComponentData';

export const checkoutSidebarTop = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const applePayData = getApplePayComponentData(
    data,
    cmsBaseUri,
    context,
    siteConfiguration
  );

  return {
    component: 'cart/CheckoutSidebarTop',
    props: {
      context: getDynamicField(data, 'context', 'cart'),
      translations: {
        orderTotal: getTranslation(data, 'orderTotal', '$Order Total$'),
        beforeTax: getTranslation(data, 'beforeTax', '$Before Tax$'),
        proceedToCheckoutButton: getDynamicField(
          data,
          'proceed-to-checkout-cta-name',
          '$Proceed to checkout$'
        ),
        proceedToBillingButton: getTranslation(
          data,
          'proceedToBillingButton',
          '$Proceed to billing$'
        ),
        placeOrderButton: getTranslation(
          data,
          'placeOrderButton',
          '$Place Order$'
        ),
        or: getTranslation(data, 'or', '$Or$'),
        ageConfirmation: getTranslation(
          data,
          'ageConfirmation',
          '$By clicking Continue to Billing, you acknowledge that you are at least 13 years or older.$'
        ),
        privacyPolicy: extractRichText(
          data,
          'privacyPolicy',
          getTranslation(
            data,
            'privacyPolicy',
            `$By placing this order, you agree to <a href='#'>Terms & Conditions</a> and <a href='#'>Privacy Policy</a>$`
          ),
          cmsBaseUri,
          context,
          siteConfiguration
        ),
      },
      showBeforeTaxMessage: getDynamicField(
        data,
        'show-before-tax-message',
        true
      ),
      currencySymbol: extractProperty(
        extractCommonConfigLink(siteConfiguration, 'currencySymbol'),
        '[0]'
      ),
      showApplePayComponent: !!applePayData.component,
      applePayTranslations: applePayData.translations,
    },
  };
};
