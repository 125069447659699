import { CMTeaser, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getComponentConfigByName,
  getTranslation,
  getTitle,
  generateLinkFromTeaserTargets,
  getCreateAccountTranslations,
  decorateHtmlValue,
  getValidationConfigValues,
  getDynamicField,
} from './utils';
import { ComposableContext } from '../types';

export const checkoutCreateAccount = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const config = getComponentConfigByName(data, 'states', {}, true);

  const createAccountData = extractProperty(
    data,
    'teaserTargets[0].target',
    {}
  );

  return {
    component: 'checkout/CheckoutCreateAccount',
    props: {
      isLoggedFromCms: config.values.state,
      showSmsSubscription: getDynamicField(
        createAccountData,
        'smsSubscriptionEnabled',
        false
      ),
      interestsLink: createAccountData
        ? generateLinkFromTeaserTargets(
            createAccountData.teaserTargets,
            context,
            siteConfiguration
          )
        : '',
      createAccountButtonText: extractProperty(
        data,
        'teaserTargets[0].callToActionText'
      ),
      validations: getValidationConfigValues(createAccountData),
      translations: {
        xplrTeaser: decorateHtmlValue(
          data.teaserRichTextObject,
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        createAccountButtonLabel: extractProperty(
          data,
          'teaserTargets[0].callToLabelText',

          '$Not a member$'
        ),
        createAccountButtonText: extractProperty(
          data,
          'teaserTargets[0].callToActionText',
          '$Join Now$'
        ),
        createAccountTranslations: {
          heading: getTranslation(
            createAccountData,
            'greeting',
            getTitle(createAccountData)
          ),
          subheading: getTranslation(
            createAccountData,
            'subtext',
            getTitle(createAccountData)
          ),
          ...getCreateAccountTranslations(
            createAccountData,
            context,
            siteConfiguration,
            cmsBaseUri
          ),
        },
      },
    },
  };
};
