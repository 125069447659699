





































import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import SimplifiedEnrollmentConfirmation from './SimplifiedEnrollmentConfirmation.vue';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProgressiveProfileConfirmation',
  components: {
    SimplifiedEnrollmentConfirmation,
  },
  setup() {
    const { root } = useRootInstance();
    const simplifiedEnrollmentStore = useSimplifiedEnrollmentStore(root);
    const { isPartiallyEnrolled } = storeToRefs(simplifiedEnrollmentStore);
    const {
      deactivate,
      activateProgressiveProfile,
    } = simplifiedEnrollmentStore;

    const status = computed(() =>
      isPartiallyEnrolled.value ? 'notCompleted' : 'completed'
    );

    const t = (key: string) =>
      root.$t(`simplifiedEnrollment.${status.value}.${key}`) as string;

    return {
      t,
      deactivate,
      isPartiallyEnrolled,
      activateProgressiveProfile,
    };
  },
});
