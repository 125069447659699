import { CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  decorateHtmlValue,
  extractProperty,
  getImageObject,
  getVideoObject,
  getButtonStyles,
  getTeaserLink,
  appendParamsToUrl,
} from './utils';

const DEFAULT_BUTTON_STYLE = 'tertiary';

export const teaserTile = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const [button, secondButton] = data.teaserTargets as any;
  const useRichText = extractProperty(data, 'useRichText');

  let buttonLink =
    button && getTeaserLink(button, context, siteConfiguration, cmsBaseUri);

  // Link for the first button supports additional configuration for urlParams
  if (buttonLink && data?.urlParams) {
    buttonLink = appendParamsToUrl(buttonLink, data.urlParams);
  }

  const secondButtonLink =
    secondButton &&
    getTeaserLink(secondButton, context, siteConfiguration, cmsBaseUri);

  return {
    component: 'shared/TeaserTile',
    props: {
      buttonStyle: getButtonStyles(data, '0', 'style', DEFAULT_BUTTON_STYLE),
      buttonSize: getButtonStyles(data, '0', 'size', 'small'),
      buttonIcon: getButtonStyles(data, '0', 'icon', ''),
      buttonIconPosition: getButtonStyles(data, '0', 'iconPosition', ''),
      buttonTargetComponent: extractProperty(
        data,
        `teaserTargets[0].target.type`,
        ''
      ),
      secondButtonStyles: {
        buttonStyle: getButtonStyles(data, '1', 'style', DEFAULT_BUTTON_STYLE),
        buttonSize: getButtonStyles(data, '1', 'size', 'small'),
        buttonIcon: getButtonStyles(data, '1', 'icon', ''),
        buttonIconPosition: getButtonStyles(data, '1', 'iconPosition', ''),
      },
      link: buttonLink,
      secondButtonLink,
      linkCta:
        button?.callToActionText ||
        button?.target?.title ||
        button?.target?.teaserTitle,
      secondButtonText:
        secondButton?.callToActionText ||
        secondButton?.target?.title ||
        secondButton?.target?.teaserTitle,
      showSecondButton: !!secondButton,
      openInNewModal: extractProperty(data, 'openInNewModal'),
      openInNewTab: extractProperty(data, 'openInNewTab'),
      image: getImageObject(
        extractProperty(data, 'pictures[0]'),
        siteConfiguration,
        null,
        cmsBaseUri,
        data.responsiveMedia
      ),
      video: {
        small: getVideoObject(data.videos || [], cmsBaseUri),
      },
      title: extractProperty(data, 'teaserTitle'),
      useRichText,
      text: useRichText
        ? decorateHtmlValue(
            data.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : extractProperty(data, 'teaserText'),
    },
  };
};
