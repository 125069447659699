import { ref, Ref } from '@vue/composition-api';
import { Product } from '@vf/api-client';

import { ComposablesStorage } from '../../../types';
import { UseCartStorage } from '../../types';
import initStorage from '../../../utils/storage';

export const useRecentlyAddedProduct = (instance, contextKey?: string) => {
  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const recentlyAddedProduct: Ref<Product> = storage.getOrSave(
    'recentlyAddedProduct',
    ref(null)
  );

  const setRecentlyAddedProduct = (product: Product | null) => {
    recentlyAddedProduct.value = product;
  };

  return {
    setRecentlyAddedProduct,
    recentlyAddedProduct,
  };
};
