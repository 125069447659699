import { EasyHeroData } from '../easyHero';

export const mapDefaultGifVideoOptions = (data: EasyHeroData) => {
  const defaultOptions = {
    preload: 'metadata',
    autoplay: true,
    hideControls: true,
    muted: true,
    showPlayButton: false,
    loop: true,
    showSubtitles: false,
  };
  if (data.props.media.video) {
    ['small', 'medium', 'large'].forEach((size) => {
      if (data.props.media.video[size])
        data.props.media.video[size].options = Object.assign(
          {},
          defaultOptions
        );
    });
  }

  // Remove image if CMS sent one
  if (data.props.media.image) {
    delete data.props.media.image;
  }

  return data;
};
