import { CMPictureCropType, CmsArticle, CmsSite } from '@vf/api-contract';
import { Article } from '@vf/api-client';
import {
  decorateHtmlValue,
  extractProperty,
  getImageObject,
} from '../../useCms/mappings/utils';
import {
  getDataNavigationValue,
  generateLinkFromTeaserTargets,
} from '../../useCms/mappings/utils';

export const articleMapper = (
  data: CmsArticle[],
  cmsSiteConfiguration: CmsSite,
  cmsBaseUri: string,
  context
) => {
  if (!data) {
    return [];
  }

  return data
    .map(
      (article): Article => {
        if (!article) {
          return null;
        }

        try {
          const readMoreText = extractProperty(
            article,
            'teaserTargets[0].callToActionText'
          );
          const showReadMore = extractProperty(
            article,
            'teaserTargets[0].callToActionEnabled'
          );

          const image =
            Array.isArray(article.pictures) && article.pictures[0]
              ? getImageObject(
                  article.pictures[0],
                  cmsSiteConfiguration,
                  CMPictureCropType.RATIO_1x1,
                  cmsBaseUri,
                  null
                )
              : {
                  small: {},
                  medium: {},
                  large: {},
                  width: null,
                  height: null,
                };

          const teaserTargets = extractProperty(article, 'teaserTargets', []);

          return {
            title: article.teaserTitle ?? article.title,
            text: article.teaserText,
            richText: article.teaserRichTextObject?.html
              ? decorateHtmlValue(
                  article.teaserRichTextObject,
                  cmsBaseUri,
                  context,
                  cmsSiteConfiguration
                )
              : '',
            link: generateLinkFromTeaserTargets(
              teaserTargets,
              context,
              cmsSiteConfiguration,
              null,
              cmsBaseUri
            ),
            dataNavigation: getDataNavigationValue(article),
            readMoreText,
            showReadMore,
            image,
            subjectTaxonomy: article.subjectTaxonomy,
            publicationDate: article.publicationDate,
          };
        } catch (e) {
          console.warn('Article mapping error', e);
          return null;
        }
      }
    )
    .filter((article) => article !== null);
};
