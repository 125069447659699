import { EventPropsHandler } from '.';
import {
  CheckoutEvent,
  CheckoutStepNumber,
  CheckoutStepName,
} from '../../types/gtm';
import { useCart } from '../../useCart';
import useGtm from '../../useGtm';
import { useI18n } from '../../useI18n';
import {
  cartLineItemToProductObject,
  getOrderPromoActionFields,
  getShippingActionFields,
} from './helpers';

const checkoutEventActions: Record<CheckoutStepNumber, CheckoutStepName> = {
  [CheckoutStepNumber.CART_EMPTY]: 'Empty Cart',
  [CheckoutStepNumber.CART]: 'Checkout - Cart',
  [CheckoutStepNumber.SHIPPING]: 'Checkout - Shipping',
  [CheckoutStepNumber.BILLING]: 'Checkout - Payment',
  [CheckoutStepNumber.REVIEW]: 'Checkout - Summary',
};

export const checkoutHandler: EventPropsHandler<
  CheckoutEvent,
  { step: CheckoutStepNumber; option?: string }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);
  const { cart } = useCart(vmInstance);
  const { getCartProductTrace } = useGtm(vmInstance);

  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();

  const ecommerce =
    eventProps.overrideAttributes.step === CheckoutStepNumber.CART_EMPTY
      ? {}
      : {
          /** MISSING_CURRENCY: Currency is specified for all products,
           * we may take it from first products from the list
           * or take it from CMS configuration */
          currencyCode: defaultCurrency.value,
          checkout: {
            actionField: {
              step: eventProps.overrideAttributes.step,
              ...getOrderPromoActionFields(cart.value.orderPromotions),
              ...getShippingActionFields(cart.value.shippingMethods),
              option:
                eventProps.overrideAttributes.option ||
                /* In case of cart and shipping page we've got already selected shipping option */
                ([
                  CheckoutStepNumber.CART,
                  CheckoutStepNumber.SHIPPING,
                ].includes(eventProps.overrideAttributes.step)
                  ? `Shipping Option: ${cart.value.shippingMethods[0].label}`
                  : undefined),
            },
            products: cart.value.items?.map((prod) => ({
              ...cartLineItemToProductObject(
                prod,
                getCartProductTrace(prod.productId)
              ),
              brand,
              quantity: prod.qty,
            })),
          },
        };

  return {
    event: 'checkout',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: checkoutEventActions[eventProps.overrideAttributes.step],
    eventLabel: undefined,
    nonInteractive: true,
    ecommerce,
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
