import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { extractProperty, getTranslation, decorateHtmlValue } from './utils';

export const employeeDiscountModal = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'shared/EmployeeDiscountModal',
    props: {
      teaserTitle: extractProperty(data, 'teaserTitle', ''),
      richText: decorateHtmlValue(
        data.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      ),
      accept: getTranslation(data, 'accept', ''),
      doNotAccept: getTranslation(data, 'do_not_accept', ''),
      successMessage: getTranslation(data, 'successMessage', ''),
    },
  };
};
