import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const buyInStoreInfo = (data: CMPlaceholder) => {
  return {
    component: 'buyInStore/BuyInStoreInfo',
    props: {
      translations: {
        associateIdLabel: getTranslation(
          data,
          'associateIdLabel',
          '$Associate ID$'
        ),
        associateIdRequiredError: getTranslation(
          data,
          'associateIdRequiredError',
          '$The Associate ID field is required$'
        ),
        storeIdLabel: getTranslation(data, 'storeIdLabel', '$Store ID$'),
      },
    },
  };
};
