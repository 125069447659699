import { getTranslation, getDynamicField } from './utils/extractProperty';
import { CMPlaceholder } from '@vf/api-contract';

export const pdpStickyHeader = (data: CMPlaceholder) => {
  return {
    component: 'pdp/PdpStickyHeader',
    props: {
      translations: {
        chooseSize: getTranslation(data, 'ctaChooseSize', '?Choose Your Size?'),
        addToCart: getTranslation(data, 'ctaAddToCart', '?Add to Cart?'),
        addedToFavoritesNotification: getDynamicField(
          data,
          'added-to-favorites-notification',
          '$You have successfully added {{product}} to your Favorites.$'
        ),
      },
    },
  };
};
