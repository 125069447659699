import { Plugin } from '@nuxt/types';
import { ComponentInstance } from '@vf/composables/src/types';
import { getVueInstanceFromContext } from '../helpers';
import { useI18n } from '@vf/composables';

const ApiClientLocalePlugin: Plugin = (context) => {
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  const { configureApiInternalization } = useI18n(vueInstance);
  configureApiInternalization();
};

export default ApiClientLocalePlugin;
