/* eslint-disable no-case-declarations */
import { PaymentMethodCode } from '@vf/api-client';
import { PaymentMethodsIcon, PaymentMethodsLabel } from '@vf/api-contract';

type PaymentMethod = {
  code: PaymentMethodCode;
  label: string;
  additionalData: {
    amount?: number;
    card_type?: string;
    credit_card_expired?: boolean;
    credit_card_token?: string;
    expiration_month?: number;
    expiration_year?: number;
    masked_number?: string;
    number_last_digits?: string;
    payment_method_id?: string;
    payment_instrument_id?: string;
    gcCardNumber?: string;
  };
};

export type CreditCardIcon = typeof creditCardsIcons[number];

export const creditCardsIcons = [
  {
    id: 'visa',
    alt: 'Visa',
    src: require('../../ui/assets/visa.svg'),
  },
  {
    id: 'mastercard',
    alt: 'Mastercard',
    src: require('../../ui/assets/mastercard.svg'),
  },
  {
    id: 'amex',
    alt: 'American express',
    src: require('../../ui/assets/amex.svg'),
  },
  {
    id: 'discover',
    alt: 'Discover Network',
    src: require('../../ui/assets/discover.svg'),
  },
  {
    id: 'diners-club',
    alt: 'Diners-club international',
    src: require('../../ui/assets/diners-club.svg'),
  },
];

export const getPaymentIcon = (
  paymentMethodIcons: PaymentMethodsIcon[],
  code: string
): PaymentMethodsIcon => {
  const methodIcon = paymentMethodIcons.find((method) => method.code === code);
  return methodIcon || { icon: '', image: '', code: '' };
};

export const getPaymentLabel = (
  paymentMethodLabels: PaymentMethodsLabel[],
  method: PaymentMethod
): string => {
  const methodLabel =
    paymentMethodLabels.find((label) => label.code === method.code)?.label ||
    method.label;
  return methodLabel || '';
};

export const getCardLogo = (type: string = ''): string => {
  if (type === '') return '';
  return (
    creditCardsIcons.find((icon) =>
      icon.id.toLowerCase().includes(type.toLowerCase())
    )?.src || ''
  );
};

export const isPayPalFlow = (
  cart: Record<string, any>,
  isFlowInProgress: boolean
): boolean => {
  const cartInstruments = cart?.payment_instruments || [];
  const paypalInstrument = !!cartInstruments.find(
    (instrument) => instrument.payment_method_id === PaymentMethodCode.PAYPAL
  );
  return paypalInstrument && isFlowInProgress;
};
