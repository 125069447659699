import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { apiClientFactory } from '@vf/api-client';
import {
  BasicInformationObject,
  OrderDetailsObject,
  OrderHistoryObject,
  PaymentInstrument,
  ReturnHistoryObject,
} from '@vf/api-contract';
import { useAuthentication } from '../useAuthentication';
import { useI18n } from '../useI18n';

import { computed, Ref, ref } from '@vue/composition-api';
import { errorMessages } from '../utils/errorMessages';
import { getEnvValueByLocale } from '@vf/env';
import { Notification } from '../useNotification/types';
import { useBasicInformation } from './composables/useBasicInformation';
import { useAddress } from './composables/useAddress';
import { usePaymentInstrument } from './composables/usePaymentInstrument';
import { useOrderHistory } from './composables/useOrderHistory';
import { useReturnHistory } from './composables/useReturnHistory';
import { useEmployeeDiscount } from './composables/useEmployeeDiscount';
import { useOrderDetails } from './composables/useOrderDetails';
import { useFavoriteStore } from './composables/useFavoriteStore';
import { useNotification } from './composables/useNotification';
import { useSmsSubscription } from './composables/useSmsSubscription';
import { isAxiosApiError } from '../utils/isAxiosApiError';

export type UseAccountStorage = {
  basicInformation: Ref<BasicInformationObject>;
  orderHistory: Ref<OrderHistoryObject>;
  returnHistory: Ref<ReturnHistoryObject>;
  addressOperationMessage: Ref<Notification>;
  orderDetails: Ref<OrderDetailsObject>;
  paymentInstruments: Ref<{ cards: PaymentInstrument[] }>;
  newPaymentInstrumentAdded: Ref<boolean>;
  isAddAddressRequestPending: Ref<boolean>;
  isAccountSaveCreditButtonDisabled: Ref<boolean>;
  favoriteStoreId: Ref<string>;
  pageTypesWithDisplayedNotification: Ref<string[]>;
  areCreditCardsFetched: Ref<boolean>;
};

type UpdateProfileObject = {
  email: string;
  currency: string;
  language: string;
};

export const useAccount = (instance: ComponentInstance) => {
  const { getUsid } = useAuthentication(instance);

  const {
    basicInformation,
    getBasicInformation,
    setBasicInformation,
    resetBasicInformation,
    customerEmail,
    interestsList,
    setCountryPreferences,
  } = useBasicInformation(instance);

  const { addAddress, updateAddress, deleteAddress, getAddresses } = useAddress(
    instance
  );

  const {
    paymentInstruments,
    getConsumerCreditCards,
    addPaymentInstrument,
    savePaymentInstrument,
    deletePaymentInstrument,
    setDefaultPaymentInstrument,
    newPaymentInstrumentAdded,
    setNewPaymentInstrumentAdded,
    defaultPaymentInstrument,
    selectFirstPaymentInstrument,
    areCreditCardsFetched,
  } = usePaymentInstrument(instance);

  const { resetOrderHistory, getOrderHistory, orderHistory } = useOrderHistory(
    instance
  );

  const { updateAcceptance } = useEmployeeDiscount(instance);

  const { getOrderDetails, orderDetails } = useOrderDetails(instance);

  const {
    getReturnHistory,
    resetReturnHistory,
    returnHistory,
  } = useReturnHistory(instance);

  const { favoriteStoreId, setFavoriteStoreId } = useFavoriteStore(instance);

  const {
    pageTypesWithDisplayedNotification,
    displayEmployeeDiscountNotification,
  } = useNotification();

  const {
    setSubscription: setSmsSubscription,
    setSubscriptionGuest: setGuestSmsSubscription,
  } = useSmsSubscription(instance);

  const smsSubscription = computed(() => {
    return basicInformation.value?.subscriptions?.find(
      (sub) => sub.channel === 'sms'
    );
  });

  const { displayErrorMessages } = errorMessages(instance);
  const { localeCode } = useI18n(instance);
  const {
    changePassword: changePasswordAPI,
    forgotPassword: forgotPasswordAPI,
    updateProfile: updateProfileAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const addressOperationMessage: Ref<Notification> =
    storage.get('addressOperationMessage') ??
    storage.save('addressOperationMessage', ref(null));

  const isAccountSaveCreditButtonDisabled: Ref<boolean> =
    storage.get('isAccountSaveCreditButtonDisabled') ??
    storage.save('isAccountSaveCreditButtonDisabled', ref(false));

  const isAddAddressRequestPending: Ref<boolean> =
    storage.get('isAddAddressRequestPending') ??
    storage.save('isAddAddressRequestPending', ref(false));

  const changePassword = async (data) => {
    try {
      return await changePasswordAPI(data, {
        usid: getUsid.value,
      });
    } catch (e) {
      if (isAxiosApiError(e)) {
        for (const errorDetail in e.response.data.errorDetails) {
          e.response.data.errorDetails[errorDetail].modifiers =
            'scrollToNotification';
        }
      }
      displayErrorMessages(e);
      return false;
    }
  };

  const forgotPassword = async (data) => {
    try {
      return await forgotPasswordAPI(data);
    } catch (err) {
      displayErrorMessages(err);
    }
  };

  const updateProfile = async (data: UpdateProfileObject) => {
    const country = getEnvValueByLocale<string>(
      'COUNTRY',
      localeCode(),
      instance.$env
    )?.toUpperCase();
    return updateProfileAPI({ ...data, country }, getUsid.value);
  };

  const clearAccountData = () => {
    resetBasicInformation();
    resetOrderHistory();
    resetReturnHistory();
  };

  return {
    getBasicInformation,
    setBasicInformation,
    basicInformation,
    interestsList,
    changePassword,
    forgotPassword,
    getOrderHistory,
    getReturnHistory,
    clearAccountData,
    getOrderDetails,
    orderHistory,
    returnHistory,
    orderDetails,
    addAddress,
    updateAddress,
    deleteAddress,
    getAddresses,
    updateProfile,
    addressOperationMessage,
    updateAcceptance,
    paymentInstruments,
    defaultPaymentInstrument,
    getConsumerCreditCards,
    addPaymentInstrument,
    savePaymentInstrument,
    deletePaymentInstrument,
    setDefaultPaymentInstrument,
    selectFirstPaymentInstrument,
    newPaymentInstrumentAdded,
    setNewPaymentInstrumentAdded,
    customerEmail,
    favoriteStoreId,
    setFavoriteStoreId,
    isAddAddressRequestPending,
    pageTypesWithDisplayedNotification,
    displayEmployeeDiscountNotification,
    isAccountSaveCreditButtonDisabled,
    setSmsSubscription,
    setGuestSmsSubscription,
    smsSubscription,
    areCreditCardsFetched,
    setCountryPreferences,
  };
};
