import { extractLocalSettingsExpandedLinks, getDynamicField } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const customContent = (data: CMPlaceholder) => {
  const modalLinks = extractLocalSettingsExpandedLinks(data, 'loginModalList');
  return {
    component: 'customContent/CustomContent',
    props: {
      customContentFile: getDynamicField(data, 'customContentFile', ''),
      customContentPath: getDynamicField(data, 'customContentPath', ''),
      modals: modalLinks.reduce((acc, item) => {
        return item.customId ? { ...acc, [item.customId]: item.id } : acc;
      }, {}),
    },
  };
};
