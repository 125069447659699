import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { PutOrderObject } from '@vf/api/src/types/Checkout';
import { getChannel } from '../utilities/getChannel';
import { ApiClientConfiguration } from '../../configuration';
import { CheckoutOrder } from '../../types';
import { AxiosResponse } from 'axios';

export const placeOrder = (configuration: ApiClientConfiguration) => async (
  formData: PutOrderObject,
  { query = '' } = {}
): Promise<AxiosResponse<CheckoutOrder>> => {
  let baseUrl = endpoints.get(configuration).checkout.placeOrder;
  if (query) {
    baseUrl += `?${query}`;
  }
  const url: string = urlBuilder(baseUrl, { config: configuration });

  return axios.put(
    url,
    {
      recaptcha_response: formData.recaptcha_response,
      cartId: formData.cartId,
      billingAddress: formData.billingAddress,
      paymentMethod: {
        code: formData.paymentMethod.code,
        ...(formData.paymentMethod.id && { id: formData.paymentMethod.id }),
        paymentCard: formData.paymentMethod.paymentCard,
        additionalData: [formData.paymentMethod.additionalData],
        ...(formData.paymentMethod.bin && {
          bin: formData.paymentMethod.bin,
          type: formData.paymentMethod.type,
        }),
      },
      inStoreSalesInfo: formData.inStoreSalesInfo,
    },
    {
      headers: headerBuilder(configuration, {
        channel: getChannel(),
      }),
    }
  );
};
