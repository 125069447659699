import ls from './localStorage';

export type Pkce = {
  codeVerifier: string;
  codeChallenge: string;
};

export default {
  generate: async (): Promise<Pkce> => {
    const Base64 = (
      await import(
        /* webpackChunkName: "crypto" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'crypto-js/enc-base64'
      )
    ).default;
    const SHA256 = (
      await import(
        /* webpackChunkName: "crypto" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'crypto-js/sha256'
      )
    ).default;
    const WordArray = await import(
      /* webpackChunkName: "crypto" */
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      'crypto-js/lib-typedarrays'
    );

    function base64URLEncode(wordArr) {
      return wordArr
        .toString(Base64)
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }
    const codeVerifier: string = base64URLEncode(WordArray.random(32));

    const codeChallenge: string = base64URLEncode(SHA256(codeVerifier));

    ls.setItem('codeVerifier', codeVerifier);
    ls.setItem('codeChallenge', codeChallenge);

    return { codeVerifier, codeChallenge };
  },
};
