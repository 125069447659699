











import ModalManager from '@/components/modals/ModalManager.vue';
import Notifications from '@/components/smart/layout/Notifications.vue';
import Spinner from '@/components/loaders/Spinner.vue';
import { useInitSetup, useHistory } from '@vf/composables';
import {
  defineComponent,
  useAsync,
  useContext,
  onMounted,
  provide,
} from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';

import useUIContextProvider from '@/shared/useUIContextProvider';
import useGlobalState from '@/shared/useGlobalState';
import { isClient } from '@vf/shared/src/utils/helpers';

import {
  PRESERVE_PREVIEW,
  addPreservePreviewParam,
} from '@/components/cms/cmsUtils';

import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';

export default defineComponent({
  components: {
    ModalManager,
    Spinner,
    Notifications,
  },

  setup() {
    const { root } = useRootInstance();
    const { app } = useContext();

    const { initSetup } = useInitSetup(root);
    const { updateHistoryWithAkamaiRedirection } = useHistory();

    const loading = isClient ? useGlobalState().loading : false;

    useUIContextProvider();

    const { isCoreRedesignEnabled, isFiltersUiUpdateEnabled } = storeToRefs(
      useFeatureFlagsStore()
    );

    provide('isCoreRedesignEnabled', isCoreRedesignEnabled);
    // TODO: GLOBAL15-62475 remove after filters redesign
    provide('isFiltersUiUpdateEnabled', isFiltersUiUpdateEnabled);

    async function init() {
      try {
        await initSetup({ forcePreview: app.$isPreview });
      } catch (error) {
        root.$log.error(
          `@theme/layouts/emptyNative.vue::init Error: ${error.message}`
        );
        app.$nuxt.error({ statusCode: 404, message: 'Not found' });
      }
    }

    useAsync(async () => {
      await init();
    });

    onMounted(() => {
      updateHistoryWithAkamaiRedirection();
      if (root.$isPreview) {
        const monetateParam = root.$route.query?.p13n_testcontext;
        window.sessionStorage.setItem(PRESERVE_PREVIEW, 'true');
        addPreservePreviewParam(monetateParam);
      }
    });

    return {
      loading,
      isCoreRedesignEnabled,
    };
  },
  head() {
    return {
      bodyAttrs: {
        class: this.isCoreRedesignEnabled ? 'redesign-core' : undefined,
      },
    };
  },
});
