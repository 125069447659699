import { Ref, computed } from '@vue/composition-api';
import { ssrRef } from '@nuxtjs/composition-api';
import { SearchQueryParams } from '@vf/api-contract';
import { apiClientFactory } from '@vf/api-client';
import {
  GetSearchResultsSettings,
  SearchResultsStorage,
  UseSearchResultsStorage,
  SearchSuggestions,
  PdoSearch,
  KeywordRedirect,
} from './types';
import { mapOrderFilters } from '../utils/mapOrderFilters';
import { useI18n } from '../useI18n';
import useGtm from '../useGtm';
import { ComposablesStorage, ComponentInstance, Filter } from '../types';
import initStorage from '../utils/storage';
import {
  clampCount,
  prepareServiceFromCookie,
  prepareCount,
  prepareFiltersJson,
  prepareSort,
  prepareStart,
  prepareSearch,
  prepareSearchSuggestions,
  prepareStoreFilter,
  prepareLocale,
  prepareRequestType,
  prepareQuery,
  preparePageMetaTags,
} from '../utils/query';
import { transformPageMetaData } from '../utils/metaTags';
import { useRequestTracker } from '../useRequestTracker';
import useShippingFilter from '../useShippingFilter';
import { isEmpty } from '../utils/isEmpty';
import { useRouting } from '../useRouting';
import * as urlDecoder from '../utils/urlDecoder';
import {
  isClient,
  scrollTo as scrollToTop,
} from '@vf/shared/src/utils/helpers';
import { getEventFromTemplate } from '../useGtm/helpers';
import { PageTypeName } from '../useCms/types';
import productsGridCache from '@vf/shared/src/theme/productsGridCache';
import { ROUTES } from '@vf/composables';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { transformListingFilters } from '../useCategory/utils';
import { getCookieByName } from '../utils/cookie';
import { errorMessages } from '../utils/errorMessages';

const MONETATE_SERVICE_COOKIE = 'mt.productSearchService';

const MULE_QUERY_LIMIT = 200;

const resetSearch = (instance) => {
  return {
    loading: false,
    queryString: '',
    filteringOptions: [],
    selectedFilters: urlDecoder.decodeFilterParam(instance),
    appliedFilters: urlDecoder.decodeFilterParam(instance),
    selectedSortingOptionId: 'bestMatches',
    selectedSortingChanged: false,
    sortingOptions: [],
    totalProductsNumber: 0,
    isFilterSidebarOpen: false,
    isInfiniteScrolling: false,
    products: [],
    fetchedProducts: [],
    productsPerPage: 10,
    initialLoadProductsAmount: 24,
    searchType: 'product',
    queryParams: '',
    autoCorrectQuery: '',
    didYouMean: [],
    isRedirected: false,
    pagination: {
      total: 0,
      per: 0,
      page: 1,
      start: 0,
      previousPage: 0,
      maxQuery: MULE_QUERY_LIMIT, // Total number queryable by Mule - should be confirurable with - https://digital.vfc.com/jira/browse/ECOM15-11018
    },
    lastQuery: null,
    meta: {},
    requestType: null,
    pageHeaderData: {},
  };
};

const useSearch = (instance: ComponentInstance) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const {
    selectedStoreId,
    syncWithUrl: shippingSyncWithUrl,
  } = useShippingFilter(instance);
  const { localeCode, localePath } = useI18n(instance);
  const { setQueryFilterParams } = useRouting(instance);
  const { dispatchEvent } = useGtm(instance);
  const {
    getSearchResults: getSearchResultsAPI,
    getSearchSuggestions: getSearchSuggestionsAPI,
  } = apiClientFactory(instance);
  const featureFlags = useFeatureFlagsStore();
  const { displayErrorMessages } = errorMessages(instance);

  const storage: ComposablesStorage<UseSearchResultsStorage> = initStorage<UseSearchResultsStorage>(
    instance,
    'useSearch'
  );
  const searchRef: Ref<SearchResultsStorage> =
    storage.get('searchRef') ??
    storage.save('searchRef', ssrRef(resetSearch(instance), 'searchRef'));

  const searchSuggestions: Ref<SearchSuggestions> =
    storage.get('searchSuggestions') ??
    storage.save(
      'searchSuggestions',
      ssrRef(
        {
          products: [],
          service: '',
          terms: [],
        },
        'searchSuggestions'
      )
    );

  const pdoSearchRef: Ref<PdoSearch> =
    storage.get('pdoSearchRef') ??
    storage.save(
      'pdoSearchRef',
      ssrRef(
        {
          searchTerm: undefined,
          searchTermAdj: undefined,
          searchType: undefined,
          searchResults: -1,
        },
        'pdoSearchRef'
      )
    );

  const isLoadPreviousEnabled =
    instance.$root.$themeConfig.productsGrid?.loadPrevious
      .isLoadPreviousEnabled;

  const setPdoSearch = ({
    searchTerm,
    searchTermAdj,
    searchType,
    searchResults,
  }) => {
    pdoSearchRef.value.searchTerm = searchTerm ?? pdoSearchRef.value.searchTerm;
    pdoSearchRef.value.searchTermAdj =
      searchTermAdj ?? pdoSearchRef.value.searchTermAdj;
    pdoSearchRef.value.searchType = searchType ?? pdoSearchRef.value.searchType;
    pdoSearchRef.value.searchResults =
      searchResults ?? pdoSearchRef.value.searchResults;
  };

  const clearPdoSearch = () => {
    pdoSearchRef.value = {
      searchTerm: undefined,
      searchTermAdj: undefined,
      searchType: undefined,
      searchResults: -1,
    };
  };

  const setSettings = (data: GetSearchResultsSettings) => {
    const defaults = {
      sort: searchRef.value.selectedSortingOptionId,
      filters: searchRef.value.selectedFilters || [],
      page: searchRef.value.pagination.page || 1,
      start: searchRef.value.pagination.start,
      appendFetchedProducts: false,
      prependFetchedProducts: false,
      fromSearchForm: data?.fromSearchForm,
      per: searchRef.value.pagination.per,
      requestType: data?.fromSearchForm ? null : searchRef.value.requestType,
    };

    const settings = Object.assign({}, defaults, data);

    if (settings.page >= 2 && settings.loadAllPreviousPages) {
      settings.start = 0;
      settings.per = clampCount(
        searchRef.value.initialLoadProductsAmount +
          (settings.page - 1) * searchRef.value.pagination.per
      );
    } else if (!settings.per) {
      settings.per = clampCount(
        settings.page === 1
          ? searchRef.value.initialLoadProductsAmount
          : searchRef.value.pagination.per
      );
    } else {
      settings.per = clampCount(settings.per);
    }
    return settings;
  };

  const isBatchLoad = (per: number) => {
    return (
      per > searchRef.value.pagination.maxQuery &&
      !searchRef.value.selectedSortingChanged &&
      !searchRef.value.selectedFilters.length
    );
  };

  const getSearchResults = async (
    data?: GetSearchResultsSettings,
    { isBackgroundRequest, useCache } = {
      isBackgroundRequest: false,
      useCache: false,
    }
  ) => {
    const { tag } = trackRequest(
      'useSearch-getSearchResults',
      isBackgroundRequest
    );
    searchRef.value.selectedFilters = [...searchRef.value.appliedFilters];
    searchRef.value.loading = true;

    const settings = setSettings(data);
    updatePreviousPage(settings);

    try {
      if (isBatchLoad(settings.per)) {
        return await batchLoadProducts(settings.per);
      }

      const query: string[] = [
        prepareStart(settings.start),
        prepareCount(settings.per),
        prepareSort(settings.sort),
        prepareLocale(localeCode()),
      ];

      if (selectedStoreId.value)
        query.push(prepareStoreFilter(selectedStoreId.value));

      if (settings.filters.length) {
        query.push(prepareFiltersJson(settings.filters));
      }

      if (settings.requestType) {
        query.push(prepareRequestType(settings.requestType));
      } else {
        query.push(
          preparePageMetaTags(
            instance.context.route.path,
            settings.fromSearchForm
          )
        );
      }

      if (searchRef.value.queryString)
        query.push(
          prepareSearch(encodeURIComponent(searchRef.value.queryString))
        );

      query.push(searchRef.value.queryParams);

      if (featureFlags.isProductSearchServiceCookieDriven) {
        const serviceQuery = prepareServiceFromCookie(
          getCookieByName(MONETATE_SERVICE_COOKIE)
        );
        if (serviceQuery) {
          query.push(serviceQuery);
        }
      }

      if (JSON.stringify(searchRef.value.lastQuery) === JSON.stringify(query)) {
        return;
      }

      searchRef.value.lastQuery = query;

      let searchResultsData = null;
      const currentPath = instance.context.route.fullPath;
      const cache =
        useCache && (await productsGridCache.getCachedItem(currentPath));
      if (cache) {
        searchResultsData = {
          data: cache.lastRequest,
        };

        searchResultsData.data.products = cache.products;
        settings.page = Math.ceil(
          searchResultsData.data.products.length / settings.per
        );
      } else {
        searchResultsData = await getSearchResultsAPI(
          query.filter((i) => Boolean(i)).join('&')
        );
      }

      searchRef.value.isRedirected = !isEmpty(
        searchResultsData.data.keywordRedirect
      );
      searchRef.value.keywordRedirect = searchResultsData.data
        .keywordRedirect as KeywordRedirect;

      if (searchRef.value.keywordRedirect?.redirectedUrl && isClient) {
        instance.$router.push(
          localePath(searchRef.value.keywordRedirect.redirectedUrl)
        );
        return;
      }

      searchRef.value.fetchedProducts = searchResultsData.data.products || [];

      searchRef.value.pagination.total = searchResultsData.data.total || 0;
      searchRef.value.pagination.page = settings.page;

      if (settings.appendFetchedProducts) {
        searchRef.value.products = searchRef.value.products.concat(
          searchRef.value.fetchedProducts
        );
      } else if (settings.prependFetchedProducts) {
        if (!cache) {
          searchRef.value.products = searchRef.value.fetchedProducts.concat(
            searchRef.value.products
          );
        }
      } else searchRef.value.products = searchRef.value.fetchedProducts;

      searchRef.value.sortingOptions = searchResultsData.data.sort || [];

      if (searchResultsData.data.filters) {
        searchRef.value.filteringOptions = transformListingFilters(
          searchResultsData.data.filters,
          featureFlags.isNewColorDividerEnabled
        );
      } else {
        searchRef.value.filteringOptions = [];
      }
      searchRef.value.autoCorrectQuery =
        searchResultsData.data.autoCorrectQuery;

      searchRef.value.didYouMean = searchResultsData.data.did_you_mean;
      searchRef.value.meta = transformPageMetaData(
        searchResultsData.data.pageMetaData
      );
      searchRef.value.pageHeaderData = searchResultsData.data.pageHeaderData;

      productsGridCache.storeCachedItem(currentPath, {
        products: searchRef.value.products,
        lastRequest: searchResultsData.data,
      });
    } catch (err) {
      instance.$log.error(
        `[@useSearch/index::getSearchResults] Error <${err.message}> while fetching products`,
        { err }
      );
      searchRef.value = resetSearch(instance);
      displayErrorMessages(err);
    } finally {
      searchRef.value.loading = false;
      searchRef.value.selectedSortingChanged = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  // first search frontend call
  const updatePreviousPage = ({
    appendFetchedProducts,
    page,
    prependFetchedProducts,
  }) => {
    if (
      isLoadPreviousEnabled &&
      isClient &&
      !appendFetchedProducts &&
      !prependFetchedProducts
    ) {
      searchRef.value.pagination.previousPage = page - 1;
    }
  };

  const writeFiltersDataToUrl = (writeSort = false) => {
    const queryParams = urlDecoder.encodeFiltersQueryParams(
      searchRef.value.appliedFilters
    );
    if (queryParams !== null) {
      if (writeSort) queryParams.sort = searchRef.value.selectedSortingOptionId;
      setQueryFilterParams(queryParams);
    }
  };

  const setRequestType = (requestType: string | null) => {
    searchRef.value.requestType = requestType;
  };

  const setQueryString = (q: string) => {
    searchRef.value.queryString = q;
    searchRef.value.pagination.maxQuery = MULE_QUERY_LIMIT;
  };

  const setQueryParams = (params: SearchQueryParams) => {
    const queryParams = {
      _br_uid_2: params.brId,
      url: params.url,
      ref_url: params.refUrl,
    };

    searchRef.value.queryParams = prepareQuery(queryParams);
  };

  const selectFilter = (
    filter: Filter,
    update = true,
    isOnlyGtmChanges = false
  ) => {
    if (!isOnlyGtmChanges) {
      searchRef.value.appliedFilters.push(filter);
    }

    if (update) writeFiltersDataToUrl();
    dispatchEvent(
      getEventFromTemplate('search-filter:applied', {
        filterCategory: filter.code,
        filterValue: filter.value,
      })
    );
  };

  const removeFilter = (
    filter: Filter,
    update = true,
    isOnlyGtmChanges = false
  ) => {
    if (!isOnlyGtmChanges) {
      searchRef.value.appliedFilters = searchRef.value.appliedFilters.filter(
        (it) => it.code !== filter.code || it.value !== filter.value
      );
    }
    if (update) writeFiltersDataToUrl();
    dispatchEvent(
      getEventFromTemplate('search-filter:removed', {
        filterCategory: filter.code,
        filterValue: filter.value,
      })
    );
  };

  const resetFiltersView = () => {
    searchRef.value.selectedFilters = [];
    searchRef.value.appliedFilters = [];
  };

  const resetPagination = () => {
    searchRef.value.pagination.total = 0;
    searchRef.value.pagination.page = 1;
    searchRef.value.pagination.start = 0;
    searchRef.value.pagination.previousPage = 0;
  };

  const resetFilters = () => {
    setQueryFilterParams({ f: [] });
    dispatchEvent(
      getEventFromTemplate('filter:clear', {
        eventCategory: PageTypeName.SEARCH,
        eventLabel: 'Clear All',
        filterCategory: 'All',
      })
    );
  };

  const resetFilter = (code, update = true) => {
    searchRef.value.appliedFilters = searchRef.value.appliedFilters.filter(
      (item) => item.code !== code
    );
    if (update) writeFiltersDataToUrl();
    dispatchEvent(
      getEventFromTemplate('filter:clear', {
        eventCategory: PageTypeName.SEARCH,
        eventLabel: 'Clear All',
        filterCategory: code,
      })
    );
  };

  const readFiltersDataFromUrl = () => {
    searchRef.value.appliedFilters = urlDecoder.decodeFilterParam(instance);
    shippingSyncWithUrl();
  };

  const updateSearch = async () => {
    if (!instance.$root.$viewport.isSmall) {
      scrollToTop();
    }

    readFiltersDataFromUrl();
    setActivePage(1);
    await getSearchResults();
  };

  const setProductsPerPage = (per: number) => {
    searchRef.value.pagination.per = clampCount(per);
  };

  const setInitialLoadProductsAmount = (productsAmount: number) => {
    searchRef.value.initialLoadProductsAmount = productsAmount;
  };

  const setInfiniteScrolling = (infiniteScrolling: boolean) => {
    searchRef.value.isInfiniteScrolling = infiniteScrolling;
  };

  const loadNextPage = () => {
    window.history.replaceState(
      {
        ...window.history.state,
        productsGridIndex: '',
        productsGridKey: '',
        queryString: searchRef.value.queryString,
      },
      ''
    );
    setActivePage(searchRef.value.pagination.page + 1);
    getSearchResults({
      appendFetchedProducts: true,
      prependFetchedProducts: false,
    });
  };

  const loadPreviousPage = async () => {
    const lastPage = searchRef.value.pagination.page;
    setActivePage(searchRef.value.pagination.previousPage);
    await getSearchResults(
      {
        appendFetchedProducts: false,
        prependFetchedProducts: true,
      },
      {
        isBackgroundRequest: false,
        useCache: !isLoadPreviousEnabled,
      }
    );
    searchRef.value.pagination.previousPage =
      searchRef.value.pagination.previousPage - 1;
    setActivePage(lastPage);
  };

  const batchLoadProducts = async (count: number) => {
    if (count > searchRef.value.pagination.maxQuery) {
      const finalRemainder =
        (count - searchRef.value.products.length) %
        searchRef.value.pagination.maxQuery;
      const queryCount = Math.ceil(
        (count - searchRef.value.products.length) /
          searchRef.value.pagination.maxQuery
      );
      await daisyChainSearch(finalRemainder, queryCount);
    } else {
      await getSearchResults({
        page: 1,
        per: searchRef.value.pagination.total,
        start: 0,
      });
    }
  };

  const loadAllProducts = async (maxQuery: number) => {
    if (maxQuery && maxQuery <= MULE_QUERY_LIMIT)
      searchRef.value.pagination.maxQuery = maxQuery;

    await batchLoadProducts(searchRef.value.pagination.total);

    const { initialLoadProductsAmount: initial, pagination } = searchRef.value;
    const { per, total } = pagination;

    searchRef.value.pagination.page = Math.ceil((total - initial) / per) + 1;
  };

  const daisyChainSearch = async (
    finalRemainder: number,
    queryCount: number
  ) => {
    searchRef.value.pagination.start = searchRef.value.products.length;
    await getSearchResults({
      per:
        queryCount === 1 ? finalRemainder : searchRef.value.pagination.maxQuery,
      appendFetchedProducts: true,
    });
    queryCount--;
    if (queryCount === 0 || isInfiniteScrolling.value) return;
    await daisyChainSearch(finalRemainder, queryCount);
  };

  const setActivePage = (page: number) => {
    searchRef.value.pagination.page = page;

    if (page > 1) {
      const initialCount = searchRef.value.initialLoadProductsAmount;
      const pagesCount = (page - 2) * searchRef.value.pagination.per;

      searchRef.value.pagination.start = initialCount + pagesCount;
    } else {
      searchRef.value.pagination.start = 0;
    }
  };

  const changeSort = (selectedSortingOptionId, shouldDispatchEvent = true) => {
    searchRef.value.selectedSortingOptionId = selectedSortingOptionId;
    searchRef.value.selectedSortingChanged = true;
    searchRef.value.pagination.maxQuery = MULE_QUERY_LIMIT;
    setInfiniteScrolling(false);
    if (shouldDispatchEvent) {
      const selectedOption = searchRef.value.sortingOptions.find(
        (sort) => sort.value === selectedSortingOptionId
      );
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: PageTypeName.SEARCH,
          eventAction: 'Sort by',
          eventLabel: selectedOption?.label || selectedOption,
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    }
  };

  const getSearchSuggestions = async (params) => {
    try {
      const query = prepareSearchSuggestions(params);
      const suggestions = await getSearchSuggestionsAPI(query);
      searchSuggestions.value = {
        ...suggestions.data,
        products: suggestions.data.products.map((product) => ({
          ...product,
          url: localePath(product.url),
        })),
      };
    } catch (err) {
      instance.$log.error(
        `[@useSearch/index::getSearchSuggestions] Error <${err.message}> while fetching search suggestions`,
        { err }
      );
      resetSearchSuggestions();
      displayErrorMessages(err);
    }
  };

  const resetSearchSuggestions = () => {
    searchSuggestions.value = {
      products: [],
      terms: [],
    };
  };

  const resetSearchResults = () => {
    searchRef.value.products = [];
    searchRef.value.lastQuery = null;
  };

  const setSearchType = (type: string) => {
    searchRef.value.searchType = type;
  };

  const isInfiniteScrolling = computed(
    () => searchRef.value.isInfiniteScrolling
  );

  const getFirstPageNumber = async (defaultPage = 1) => {
    if (isLoadPreviousEnabled && isClient) {
      const currentPath = instance.context.route.fullPath;
      const cache = await productsGridCache.getCachedItem(currentPath);
      if (cache) {
        return cache.currentPage;
      }
    }
    return defaultPage;
  };

  const getSearchSetting = async (
    searchSettings: GetSearchResultsSettings
  ) => ({
    ...searchSettings,
    page: await getFirstPageNumber(searchSettings.page),
    loadAllPreviousPages: isLoadPreviousEnabled
      ? false
      : searchSettings.loadAllPreviousPages,
  });

  const isTagPage = computed(() => instance.$route.fullPath.includes('/tag/'));

  const isExplorePage = computed(
    () =>
      instance.$route.fullPath ===
      localePath(ROUTES.EXPLORE(instance.$route.params.id))
  );

  const setFilterSidebarOpen = (isOpen: boolean) => {
    searchRef.value.isFilterSidebarOpen = isOpen;
  };

  return {
    sortingOptions: computed(() => searchRef.value.sortingOptions),
    selectedSortingOptionId: computed(
      () => searchRef.value.selectedSortingOptionId
    ),
    changeSort,
    setSearchType,
    getSearchResults,
    getSearchSuggestions,
    resetSearchSuggestions,
    searchSuggestions: computed(() => searchSuggestions.value),
    setQueryString,
    setRequestType,
    selectFilter,
    removeFilter,
    resetFilters,
    resetFilter,
    resetFiltersView,
    setProductsPerPage,
    setInfiniteScrolling,
    loadNextPage,
    loadAllProducts,
    setActivePage,
    setQueryParams,
    setInitialLoadProductsAmount,
    selectedSortingChanged: computed(
      () => searchRef.value.selectedSortingChanged
    ),
    products: computed(() => searchRef.value.products),
    fetchedProducts: computed(() => searchRef.value.fetchedProducts),
    initialLoadProductsAmount: computed(
      () => searchRef.value.initialLoadProductsAmount
    ),
    pagination: computed(() => ({
      total: searchRef.value.pagination.total,
      per: searchRef.value.pagination.per,
      page: searchRef.value.pagination.page,
      previousPage: searchRef.value.pagination.previousPage,
    })),
    searchType: computed(() => searchRef.value.searchType),
    isFilterSidebarOpen: computed(() => searchRef.value.isFilterSidebarOpen),
    setFilterSidebarOpen,
    isInfiniteScrolling,
    selectedFilters: computed(() => searchRef.value.selectedFilters),
    appliedFilters: computed(() => searchRef.value.appliedFilters),
    filteringOptions: computed(() => searchRef.value.filteringOptions),
    loading: computed(() => searchRef.value.loading),
    lastQuery: computed(() => searchRef.value.lastQuery),
    meta: computed(() => searchRef.value.meta),
    queryString: computed(() => searchRef.value.queryString),
    autoCorrectQuery: computed(() => searchRef.value.autoCorrectQuery),
    didYouMean: computed(() => searchRef.value.didYouMean),
    isRedirected: computed(() => searchRef.value.isRedirected),
    redirectedUrl: computed(() =>
      searchRef.value.keywordRedirect
        ? searchRef.value.keywordRedirect.redirectedUrl
        : ''
    ),
    pdoSearch: computed(() => pdoSearchRef.value),
    setPdoSearch,
    clearPdoSearch,
    resetPagination,
    mapOrderFilters,
    writeFiltersDataToUrl,
    updateSearch,
    resetSearchResults,
    loadPreviousPage,
    getSearchSetting,
    headerData: computed(() => searchRef.value.pageHeaderData),
    isTagPage,
    isExplorePage,
  };
};

export default useSearch;
