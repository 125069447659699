import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation, getDynamicField } from './utils/extractProperty';

export const orderHistory = (data: CMPlaceholder) => {
  const historyPageType = getDynamicField(data, 'orderPageType', 'order');
  const isOrderHistory = historyPageType === 'order';

  return {
    component: 'account/OrderHistory',
    props: {
      translations: {
        pageTitle: getTranslation(data, 'pageTitle', '$Order History$'),
        pageReturnTitle: getTranslation(
          data,
          'pageReturnTitle',
          '$Return History$'
        ),
        itemSummaryLabel: getTranslation(
          data,
          'itemSummaryLabel',
          '$Item Summary$'
        ),
        orderLabel: getTranslation(data, 'orderLabel', '$Order$'),
        authorizationLabel: getTranslation(
          data,
          'authorizationLabel',
          '$Authorization$'
        ),
        shippingAddressLabel: getTranslation(
          data,
          'shippingAddressLabel',
          '$Shipping Address$'
        ),
        orderedItemsLabel: getTranslation(
          data,
          isOrderHistory ? 'orderedItemsLabel' : 'itemsInReturnLabel',
          '$Items Ordered$'
        ),
        estimatedShippingLabel: getTranslation(
          data,
          'estimatedShippingLabel',
          '$Estimated Shipping$'
        ),
        estimatedTaxLabel: getTranslation(
          data,
          'estimatedTaxLabel',
          '$Estimated Tax$'
        ),
        orderTotalLabel: getTranslation(
          data,
          isOrderHistory ? 'orderTotalLabel' : 'totalRefundLabel',
          '$Order Total$'
        ),
        trackPackageLabel: getTranslation(
          data,
          'trackPackageLabel',
          '$Track Package$'
        ),
        orderDetailsButton: getTranslation(
          data,
          isOrderHistory ? 'orderDetailsButton' : 'returnDetailsButton',
          '$Order Details$'
        ),
        returnDisclaimer: getTranslation(
          data,
          'returnDisclaimer',
          '$Return Disclaimer$'
        ),
        printPackingSlipButtonText: getTranslation(
          data,
          'printPackingSlipButtonText',
          '$Print Packing Slip$'
        ),
        printReturnLabelButtonText: getTranslation(
          data,
          'printReturnLabelButtonText',
          '$Print Return Label$'
        ),
        returnLinkButton: getTranslation(
          data,
          'returnLinkButton',
          '$Create a Return$'
        ),
        showAllOrders: getTranslation(
          data,
          'showAllOrders',
          '$Show All Orders$'
        ),
        continueShopping: getTranslation(
          data,
          'continueShopping',
          '$Continue Shopping$'
        ),
        noOrders: getTranslation(
          data,
          isOrderHistory ? 'noOrders' : 'noReturns',
          '$No orders were found$'
        ),
        reviewLabel: getTranslation(data, 'reviewLabel', '$Write a review$'),
        trackYourOrder: getTranslation(
          data,
          'trackYourOrder',
          '$Track your order$'
        ),
        qty: getTranslation(data, 'qty', '$QTY$'),
        orderedQuantity: getTranslation(
          data,
          isOrderHistory ? 'orderedQuantity' : 'returnedQuantity',
          '$Ordered Quantity$'
        ),
        returnableQuantity: getTranslation(
          data,
          'returnableQuantity',
          '$Returnable Quantity$'
        ),
        serverErrorMessage: getTranslation(data, 'serverErrorMessage', ''),
      },
      showPageTitle: getDynamicField(data, 'showPageTitle', false),
      historyPageType,
    },
  };
};
