import {
  CmsSiteConfigurationResponse,
  CmsFragmentFolderResponse,
} from '@vf/api-contract';
import { ComponentInstance } from '../../types';
import { InitializedApiClient } from '../types';

export const getSiteConfiguration = async (
  siteId: string,
  apiClient: InitializedApiClient
) => {
  const siteConfigResponse: CmsSiteConfigurationResponse = await apiClient.getSiteConfiguration(
    siteId
  );

  try {
    if (!siteConfigResponse || !siteConfigResponse.site) {
      throw new Error(
        `[@useCms/dataFetcher/config::getSiteConfiguration] CMS Error: Site configuration with ID <${siteId}> has not been found.`
      );
    }

    return siteConfigResponse.site;
  } catch (err) {
    throw new Error(
      `${err.message} CMS response body: ${JSON.stringify(
        siteConfigResponse,
        null,
        2
      )}`
    );
  }
};

export const getFragmentFolderData = async (
  instance: ComponentInstance,
  apiClient: InitializedApiClient,
  folder: string,
  domain: string,
  fragmentName: string
) => {
  try {
    const response: CmsFragmentFolderResponse = await apiClient.getFragmentFolder(
      folder,
      domain,
      fragmentName
    );
    return response.data;
  } catch (err) {
    instance.$log.error(
      `[@useCms/dataFetcher/config::getFragmentFolderData] Error while trying to get Fragment with name <${fragmentName}> in folder <${folder}>: ${err.message}`,
      err
    );
    return [];
  }
};
