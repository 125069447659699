import { Segment } from '@vf/api-contract';
import { ComponentInstance } from '../types';
import { useAuthentication } from '@vf/composables';

export const manageSegments = (
  segments: Segment[] | null,
  instance: ComponentInstance
): boolean => {
  if (!segments || !segments.length) return true;

  const { customerGroups } = useAuthentication(instance);

  return segments.every((segment) => {
    if (!segment.negation) {
      return customerGroups.value.includes(segment.segmentID);
    }
    if (segment.negation) {
      return !customerGroups.value.includes(segment.segmentID);
    }
  });
};
