

































import { ref, defineComponent, computed, inject } from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';
import { onStopScroll, scrollTo } from '@vf/shared/src/utils/helpers';
import useModalHandlers from '../composables/useModalHandlers';

export default defineComponent({
  name: 'VfButton',
  directives: { focus },
  props: {
    iconSize: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    rel: {
      type: String,
      default: undefined,
    },
    textColor: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '',
    },
    fontWeight: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    scrollToLink: {
      type: Boolean,
      default: false,
    },
    isEmitter: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    download: {
      type: String,
      default: '',
    },
  },
  setup: (props, { attrs, emit }) => {
    const lastScrollPosition = ref(0);
    const { openModal } = useModalHandlers();
    const isOpenInModal = computed(() => {
      /**
       * If openInNewModal is not explicitely set to true
       * but if the link has a CMS component (data-id) and this ain't a scrollToLink button
       * try to open it in a modal
       */
      return (
        props.openInNewModal ||
        (!props.scrollToLink &&
          typeof props.link === 'string' &&
          props.link?.includes('data-id'))
      );
    });

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const openInNewModal = (link) => {
      if (link.includes('data-id')) {
        const resourceId = link.replace(/\D/g, '');
        openModal({ type: 'lazyFragment', resourceId });
      } else {
        openModal({
          type: 'page',
          path: link,
        });
      }
    };

    const scrollToLink = (e) => {
      const elem = document.querySelector(props.link);
      const sticky = document.querySelector(
        '.vf-header.sticky-header'
      ) as HTMLElement;

      if (elem) {
        const top =
          elem.getBoundingClientRect().top +
          window.scrollY -
          (sticky ? sticky.offsetHeight : 0);

        scrollTo({ top });
        const tolerance = 0.01;
        if (Math.abs(lastScrollPosition.value - top) > tolerance) {
          lastScrollPosition.value = top;
          onStopScroll(() => {
            handleCustomAction(e);
          });
        }
      }
    };

    const handleCustomAction = (e) => {
      const chooseCustomHandler = () => {
        switch (true) {
          case props.link && props.scrollToLink:
            return () => scrollToLink(e);
          case isOpenInModal.value:
            return () => openInNewModal(props.link);
          case props.isEmitter:
            return () => {
              emit('click');
            };
          default:
        }
      };
      const handler = chooseCustomHandler();

      if (handler) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        handler();
      }
    };

    const isExternal = computed(() => {
      return (
        typeof props.link === 'string' &&
        (props.link.startsWith('http://') ||
          props.link.startsWith('https://') ||
          props.download)
      );
    });

    /**
     * Known issue: when button renders as <button /> (not <a />), element does not take
     * the full width of the parent.
     * https://stackoverflow.com/questions/67375431/html-css-anchor-takes-up-full-width-when-display-flex-but-button-does-not
     * You can fix it in scope of your component by adding `width: 100%`.
     */
    const tag = computed(() => {
      if (props.scrollToLink || isOpenInModal.value) {
        return 'button';
      }
      if (isExternal.value) {
        return 'a';
      }
      return props.link ? 'nuxt-link' : 'button';
    });

    const linkAttributes = computed(() => {
      return typeof props.link === 'string'
        ? { ...attrs, href: props.link }
        : attrs;
    });

    const target = computed(() => {
      return props.link && props.openInNewTab ? { target: '_blank' } : null;
    });

    const buttonStyles = computed(() => {
      if (
        !props.textColor &&
        !props.fontSize &&
        !props.fontWeight &&
        !props.backgroundColor
      ) {
        return null;
      }

      return {
        ...(props.textColor && {
          '--button-color': props.textColor,
          '--vf-button-text-icon-color': props.textColor,
          '--vf-button-icon-color': props.textColor,
          '--icon-color': props.textColor,
        }),
        ...(props.fontSize && { '--button-font-size': props.fontSize }),
        ...(props.fontWeight && { '--button-font-weight': props.fontWeight }),
        ...(props.backgroundColor && {
          '--button-background': props.backgroundColor,
        }),
      };
    });

    return {
      handleCustomAction,
      isExternal,
      tag,
      linkAttributes,
      target,
      buttonStyles,
      isCoreRedesignEnabled,
    };
  },
  methods: {
    handleCustomActionNative() {
      this.$parent.$emit('click-native');
    },
  },
});
