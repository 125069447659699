import { CmsSite, CMTeaser } from '@vf/api-contract';
import { Interest } from '../../types';
import { extractCommonConfigLink } from './extractCommonConfigLink';
import { getDynamicField } from './extractProperty';
import { getImageObject } from './getImageObject';

export const extractInterests = (
  siteConfiguration: CmsSite,
  cmsBaseUri: string
): Interest[] => {
  const [interests] = extractCommonConfigLink(
    siteConfiguration,
    'loyalty-forms.interests'
  );
  if (!interests) {
    return [];
  }

  return interests.teasableItems.map((teaser: CMTeaser) => ({
    title: teaser.teaserTitle?.toLowerCase(),
    code: getDynamicField(
      teaser,
      'interestId',
      teaser.teaserTitle?.toLowerCase()
    ),
    image: getImageObject(
      teaser.pictures[0],
      siteConfiguration,
      null,
      cmsBaseUri
    ),
  }));
};
