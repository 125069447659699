import { DirectiveBinding } from 'vue/types/options';
import { Element } from './types';

export const swipe = {
  bind(el: Element, binding: DirectiveBinding): void {
    if (typeof binding.value !== 'function') {
      return;
    }

    const horizontalTouchAngle = 45;
    const swipeThreshold = 20;
    let touchstartX = 0;
    let touchendX = 0;
    let touchstartY = 0;
    let touchendY = 0;

    el._handleTouchStart = ({ changedTouches }) => {
      touchstartX = changedTouches[0].screenX;
      touchstartY = changedTouches[0].screenY;
    };

    el._handleTouchEnd = ({ changedTouches }) => {
      touchendX = changedTouches[0].screenX;
      touchendY = changedTouches[0].screenY;

      const diffX = touchstartX - touchendX;
      const diffY = touchstartY - touchendY;

      const touchAngle =
        (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) / Math.PI;

      if (touchAngle > horizontalTouchAngle) {
        return;
      }

      if (touchendX + swipeThreshold < touchstartX) {
        binding.value({ direction: 'right' });
      }

      if (touchendX > touchstartX + swipeThreshold) {
        binding.value({ direction: 'left' });
      }
    };

    el.addEventListener('touchstart', el._handleTouchStart);
    el.addEventListener('touchend', el._handleTouchEnd);
  },
  unbind(el: Element): void {
    el.removeEventListener('touchstart', el._handleTouchStart);
    el.removeEventListener('touchend', el._handleTouchEnd);
  },
};
