import { CMTeaser, SmartGiftCtaTranslations } from '@vf/api-contract';
import { getTranslation } from './utils';

export const smartGiftCtaButton = (data: CMTeaser) => ({
  component: 'shared/SmartGiftCTAButton',
  props: {
    translations: {
      sendWithSmartGift: getTranslation(
        data,
        'sendWithSmartGift',
        '$Send with smart gift$'
      ),
    } as SmartGiftCtaTranslations,
  },
});
