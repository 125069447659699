import { extractDataSources, getDynamicField, getTranslation } from './utils';
import { ComposableContext } from '../types';
import useProduct from '../../useProduct';
import { CMPlaceholder } from 'packages/api-contract/src/cms';

export const pdpThumbnail = (
  data: CMPlaceholder,
  context: ComposableContext
) => {
  const config = getConfigValues(data);
  const gridLayout =
    getDynamicField(data, 'imageLayout') === 'pdp-thumbnail-grid';
  const { setPdpGalleryDataSource } = useProduct(
    context.instance,
    context.contextKey
  );
  setPdpGalleryDataSource(extractDataSources(data));
  return {
    component: 'pdp/ProductGallery',
    props: {
      gridLayout: gridLayout,
      showBadges: config['show-sfcc-badges'],
      sequenceNumber: config['sequence-number'],
      showWishListIcon: config['show-wish-list-icon'],
      dotsNavigationEnabled: config['dots-navigation-enabled'],
      arrowsNavigationEnabled: config['arrows-navigation-enabled'],
      hideControls: getDynamicField(data, 'hide-controls', false),
      translations: {
        zoomIn: {
          ariaLabel: getTranslation(data, 'zoomIn', '$Zoom in$'),
        },
        zoomOut: {
          ariaLabel: getTranslation(data, 'zoomOut', '$Zoom out$'),
        },
        return: {
          ariaLabel: getTranslation(data, 'return', '$Return$'),
        },
        fullScreen: {
          ariaLabel: getTranslation(data, 'fullScreen', '$Open Full Screen$'),
        },
        swipePrevious: {
          ariaLabel: getTranslation(data, 'swipePrevious', '$Swipe Previous$'),
        },
        swipeNext: {
          ariaLabel: getTranslation(data, 'swipeNext', '$Swipe Next$'),
        },
        arrowsNavigationCounterConnector: getTranslation(
          data,
          'arrowsNavigationCounterConnector',
          '$of$'
        ),
        closeFullScreen: {
          ariaLabel: getTranslation(
            data,
            'closeFullScreen',
            '$Close Full Screen$'
          ),
        },
        visualSearchButtonLabel: getTranslation(
          data,
          'visualSearchButtonLabel',
          '$ShopSimilar$'
        ),
        addedToFavoritesNotification: getDynamicField(
          data,
          'added-to-favorites-notification',
          '$You have successfully added {{product}} to your Favorites.$'
        ),
      },
    },
  };
};

const getConfigValues = (data: CMPlaceholder) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'pdp-thumbnail-properties'
    );

    return config.values;
  } catch (e) {
    console.error('PDP Product Gallery component error: no config found.');
  }

  return {
    'sequence-number': 0,
    'show-sfcc-badges': true,
    'show-wish-list-icon': true,
  };
};
