import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const shareMyFavoritesModal = (data: CMPlaceholder) => {
  return {
    component: 'favorites/ShareMyFavorites',
    props: {
      translations: {
        title: getTranslation(
          data,
          'title',
          '$Share your favorites with others$'
        ),
        closeWindowButton: getTranslation(
          data,
          'closeWindowButton',
          '$Close Window$'
        ),
        copyLinkButton: getTranslation(data, 'copyLinkButton', '$Copy Link$'),
        copyLinkMark: getTranslation(data, 'copyLinkMark', '$Copy Link'),
        copySuccessNotificationText: getTranslation(
          data,
          'copySuccessNotificationText',
          '$Link copied successfully.$'
        ),
        copyFailedNotificationText: getTranslation(
          data,
          'copyFailedNotificationText',
          '$Something went wrong. Please try again.$'
        ),
        shareFavoritesError: getTranslation(
          data,
          'shareFavoritesError',
          '$Share my favorites error$'
        ),
      },
    },
  };
};
