import type { ComponentInstance } from '../types';
import type { BrandifyStoreInfo } from '@vf/api-client';
import { computed, ref, Ref, shallowRef } from '@vue/composition-api';
import { ssrRef } from '@nuxtjs/composition-api';
import { useAccount } from '../useAccount';
import { useAuthentication } from '../useAuthentication';
import { useRouting } from '../useRouting';
import { BRAND_ID_FIELD } from '../useFindInStore';
import useGtm from '../useGtm';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import initStorage from '../utils/storage';
import ls from '../utils/localStorage';
import { PdpShippingMethod } from '../store/cartStore';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { COOKIE_NAME, FILTER_URL } from './utils';

type UseShippingFilter = {
  lastSearchInput: Ref<string>;
  selected: Ref<PdpShippingMethod>;
  selectedStore: Ref<BrandifyStoreInfo | null>;
  selectedStoreId: Ref<string>;
  isPickupStoreModalOpen: Ref<boolean>;
};

const useShippingFilter = (instance: ComponentInstance) => {
  const storage = initStorage<UseShippingFilter>(instance, 'useShippingFilter');
  const { favoriteStoreId, setFavoriteStoreId } = useAccount(instance);
  const { isIpaProsumer, isWranxProsumer } = useAuthentication(instance);
  const { dispatchEvent } = useGtm(instance);
  const { setQueryFilterParams } = useRouting(instance);
  const { isBopis20PlpEnabled } = useFeatureFlagsStore();

  const lastSearchInput = storage.getOrSave('lastSearchInput', shallowRef(''));
  const selectedStore = storage.getOrSave(
    'selectedStore',
    ref(JSON.parse(ls.getItem('defaultStore')))
  );
  const selectedStoreId = storage.getOrSave(
    'selectedStoreId',
    ssrRef(
      () => (instance.context.route.query?.[FILTER_URL] || '').toString(),
      'shippingFilter-selectedStoreId'
    )
  );
  const selected = storage.getOrSave(
    'selected',
    ssrRef(
      () =>
        selectedStoreId.value
          ? PdpShippingMethod.Pickup
          : PdpShippingMethod.ShipToHome,
      'shippingFilter-selected'
    )
  );
  const isPickupStoreModalOpen = storage.getOrSave(
    'isPickupStoreModalOpen',
    ref(false)
  );

  const updateCookie = () => {
    instance.$cookies.set(COOKIE_NAME, selectedStoreId.value);
  };

  const selectionChanged = (select: PdpShippingMethod) => {
    if (select === PdpShippingMethod.ShipToHome) {
      selectedStoreId.value = '';
      dispatchEvent(
        getEventFromTemplate('bopis:method-selection', {
          shippingStoreId: '',
          eventLabel: 'STH',
        })
      );
    } else {
      selectedStoreId.value = selectedStore.value[BRAND_ID_FIELD];
      dispatchEvent(
        getEventFromTemplate('bopis:method-selection', {
          shippingStoreId: selectedStore.value.enterprise_store_identifier,
          eventLabel: 'BOPIS',
        })
      );
    }
    updateCookie();
    setQueryFilterParams({ [FILTER_URL]: selectedStoreId.value || undefined });
  };

  const onChange = (select: PdpShippingMethod) => {
    if (select === PdpShippingMethod.Pickup) {
      if (selected.value === select || !selectedStore.value)
        return openPickupSelector();
    }
    selected.value = select;
    selectionChanged(select);
  };

  const openPickupSelector = () => {
    isPickupStoreModalOpen.value = true;
  };

  const closeModal = () => {
    isPickupStoreModalOpen.value = false;
  };

  const saveStore = (store: BrandifyStoreInfo) => {
    selectedStore.value = store;
    selected.value = PdpShippingMethod.Pickup;
    ls.setItem('defaultStore', JSON.stringify(store));
    if (favoriteStoreId.value !== store[BRAND_ID_FIELD]) {
      setFavoriteStoreId(store[BRAND_ID_FIELD]);
    }

    selectionChanged(selected.value);
    closeModal();
  };

  const syncWithUrl = () => {
    if (instance.context.route.query?.[FILTER_URL]) {
      selected.value = PdpShippingMethod.Pickup;
      selectedStoreId.value = instance.context.route.query[
        FILTER_URL
      ].toString();
      const lsDefaultStore = JSON.parse(ls.getItem('defaultStore'));
      if (lsDefaultStore?.[BRAND_ID_FIELD] === selectedStoreId.value) {
        selectedStore.value = lsDefaultStore;
      }
    } else {
      selected.value = PdpShippingMethod.ShipToHome;
      selectedStoreId.value = '';
    }
    updateCookie();
  };

  const reset = () => {
    selected.value = PdpShippingMethod.ShipToHome;
    selectedStoreId.value = '';
  };

  const isEnableShippingFilter = computed(
    () => isBopis20PlpEnabled && !isIpaProsumer.value && !isWranxProsumer.value
  );

  return {
    closeModal,
    isEnableShippingFilter,
    isPickupStoreModalOpen,
    lastSearchInput,
    onChange,
    openPickupSelector,
    reset,
    saveStore,
    selected,
    selectedStore,
    selectedStoreId: computed(() =>
      isEnableShippingFilter.value ? selectedStoreId.value : ''
    ),
    syncWithUrl,
  };
};

export default useShippingFilter;
