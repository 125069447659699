import { easyHero } from './easyHero';
import { CMContentHero, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const easyHeroVideoPlayer = (
  data: CMContentHero,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const defaultOptions = {
    autoplay: false,
    hideControls: false,
    muted: false,
    showPlayButton: true,
    loop: false,
    showSubtitles: true,
  };

  // Apply standard video player options to all video sizes
  const easyHeroData = easyHero(data, _context, siteConfiguration, cmsBaseUri);
  if (easyHeroData.props.media.video) {
    ['small', 'medium', 'large'].forEach((size) => {
      if (easyHeroData.props.media.video) {
        easyHeroData.props.media.video[size].options = Object.assign(
          {},
          defaultOptions
        );
      }
    });
  }

  // Remove image if CMS sent one
  if (easyHeroData.props.media.image) {
    delete easyHeroData.props.media.image;
  }

  // In case overlay data was sent, don't pass it to the component because
  // an external video player cannot have an overlay
  easyHeroData.props.ctas = [];
  if (easyHeroData.props.title) delete easyHeroData.props.title;
  if (easyHeroData.props.subtitle) delete easyHeroData.props.subtitle;
  if (easyHeroData.props.mediaTarget) delete easyHeroData.props.mediaTarget;

  return easyHeroData;
};
