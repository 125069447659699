import { CartAddressRequest } from '@vf/api-contract';
import {
  axios,
  headerBuilder,
  urlBuilder,
  sanitizeFormData,
} from '../../helpers';
import { AxiosResponse } from 'axios';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { Cart } from '../../types';

export const setShippingAddress = (configuration: ApiClientConfiguration) => (
  cartId: string,
  formData: CartAddressRequest[],
  { query = '' }: { query?: string } = {}
): Promise<AxiosResponse<Cart>> => {
  let baseUrl = endpoints.get(configuration).checkout.setShippingAddress;
  if (query) {
    baseUrl += `?${query}`;
  }

  const url: string = urlBuilder(baseUrl, {
    config: configuration,
    mapping: { basketId: cartId },
  });
  const sanitizedFormData = formData.map(sanitizeFormData);

  return axios.post(
    url,
    sanitizedFormData.length === 1 ? sanitizedFormData[0] : sanitizedFormData,
    {
      headers: headerBuilder(configuration),
    }
  );
};
