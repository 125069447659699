import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { CreateCartResponse } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const getCart = (configuration: ApiClientConfiguration) => (
  query: string
): Promise<AxiosResponse<CreateCartResponse>> => {
  const url = `${endpoints.get(configuration).cart.getCart}?${query}`;
  return axios.post(
    urlBuilder(url, { config: configuration }),
    {},
    {
      headers: headerBuilder(configuration),
    }
  );
};
