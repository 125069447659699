import { ComponentInstance, ComposableContext } from '../types';
import useCms from '../useCms';
import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
} from '../useCms/mappings/utils';
import { CmsSite } from '@vf/api-contract/src';
import { useCmsRefStore } from '../store/cmsRef';
import { storeToRefs } from 'pinia';

type CmsCommonConfigRoutes = {
  contactUsURL: string;
  forgotPasswordURL: string;
  signInLink: any;
};

const getFormattedLinkFromTarget = (
  linkTarget: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  baseUri: string
) => {
  return linkTarget
    ? generateLinkFromTarget(
        { urlSegment: linkTarget.urlSegment, type: linkTarget.type },
        context,
        siteConfiguration,
        baseUri
      )
    : '';
};

export const cmsCommonConfigRoutes = (
  instance: ComponentInstance,
  contextKey: string = 'page-content'
): CmsCommonConfigRoutes => {
  const cmsRefStore = useCmsRefStore();
  const { baseUri } = useCms(instance, contextKey);

  const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);

  const context: ComposableContext = { instance, contextKey };

  const contactUsURLTarget = extractProperty(
    extractCommonConfigLink(cmsSiteConfiguration.value, 'contactUsURL'),
    '[0]'
  );

  const forgotPasswordURLTarget = extractProperty(
    extractCommonConfigLink(cmsSiteConfiguration.value, 'forgotPasswordURL'),
    '[0]'
  );

  return {
    contactUsURL: getFormattedLinkFromTarget(
      contactUsURLTarget,
      context,
      cmsSiteConfiguration.value,
      baseUri.value
    ),
    forgotPasswordURL: getFormattedLinkFromTarget(
      forgotPasswordURLTarget,
      context,
      cmsSiteConfiguration.value,
      baseUri.value
    ),
    signInLink: extractProperty(
      extractCommonConfigLink(
        cmsSiteConfiguration.value,
        'loyalty-register-login'
      ),
      '[0]'
    ),
  };
};
