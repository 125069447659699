import { ComponentInstance } from '../../types';
import {
  EventName,
  GtmCartProductObject,
  IGtmEvent,
  ProductDetailViewViewType,
} from '../../types/gtm';
import { addToCartHandler } from './addToCartHandler';
import { checkoutCompletionHandler } from './checkoutCompletionHandler';
import { checkoutHandler } from './checkoutHandler';
import { checkoutOptionHandler } from './checkoutOptionHandler';
import { enhancedSaleHandler } from './enhancedSaleHandler';
import { enhancedSaleClickHandler } from './enhancedSaleClickHandler';
import { enhancedSaleViewHandler } from './enhancedSaleViewHandler';
import { loadEventDataHandler } from './loadEventDataHandler';
import { loadPageDataHandler } from './loadPageDataHandler';
import { loadUserDataHandler } from './loadUserDataHandler';
import { productClickHandler } from './productClickHandler';
import { productDetailViewHandler } from './productDetailViewHandler';
import { productImpressionsHandler } from './productImpressionsHandler';
import { refundHandler } from './refundHandler';
import { removeFromCartHandler } from './removeFromCartHandler';
import { updateCartHandler } from './updateCartHandler';
import { virtualPageViewHandler } from './virtualPageViewHandler';
import { monetateActiveExperiencesHandler } from './monetateActiveExperiencesHandler';
import { productRecClickHandler } from './productRecClickHandler';
import { productRecImpressionsHandler } from './productRecImpressionsHandler';
import { internalPromotionImpressionsHandler } from './internalPromotionImpressionsHandler';
import { internalPromotionClickHandler } from './internalPromotionClickHandler';
import { launchDarklyExperiencesHandler } from './launchDarklyActiveExperiencesHandler';

export interface EventPropsHandler<
  EVENT_OBJECT_TYPE = IGtmEvent | IGtmEvent[],
  OVERRIDES = unknown
> {
  (
    eventProps: EventProps<OVERRIDES>,
    vmInstance: ComponentInstance
  ): EVENT_OBJECT_TYPE;
}

export type EventProps<OVERRIDES = unknown> = {
  eventName: EventName;
  composablesContexts?: { [key: string]: string };
  overrideAttributes?: Partial<OVERRIDES>;
  persistentVariables?: EventPersistentVariables;
  monetateExperiences?: any;
};

export type VideoEventProps = {
  eventAction: 'Start' | 'Pause' | 'Play' | 'Complete';
  videoDuration: number;
  videoCurrentTime: number;
  name: string;
};

export type EventPersistentVariables = {
  category?: string;
  searchTerm?: string;
  id?: string;
  availSizes?: string[];
  avgRating?: string;
  badge?: string;
  numReviews?: number | string;
  viewType?: ProductDetailViewViewType;
  styleCode?: string;
  catalogCategory?: string;
  list?: string;
  shippingMethod?: GtmCartProductObject['shippingMethod'];
};

export type EventPersistentVariablesMap = {
  [productId: string]: EventPersistentVariables;
};

export const eventPropsHandlers = new Map<EventName, EventPropsHandler>([
  ['loadPageData', loadPageDataHandler],
  ['loadUserData', loadUserDataHandler],
  ['virtualPageView', virtualPageViewHandler],
  ['productImpressions', productImpressionsHandler],
  ['productClick', productClickHandler],
  ['productDetailView', productDetailViewHandler],
  ['addToCart', addToCartHandler],
  ['removeFromCart', removeFromCartHandler],
  ['cartUpdate', updateCartHandler],
  ['checkout', checkoutHandler],
  ['checkoutOption', checkoutOptionHandler],
  ['checkoutCompletion', checkoutCompletionHandler],
  ['enhancedSale', enhancedSaleHandler],
  ['enhancedSaleClick', enhancedSaleClickHandler],
  ['enhancedSaleView', enhancedSaleViewHandler],
  ['refund', refundHandler],
  ['loadEventData', loadEventDataHandler],
  ['monetateActiveExperiences', monetateActiveExperiencesHandler],
  ['launchDarklyActiveExperiences', launchDarklyExperiencesHandler],
  ['productRecClick', productRecClickHandler],
  ['productRecImpressions', productRecImpressionsHandler],
  ['promotionImpressions', internalPromotionImpressionsHandler],
  ['promotionClick', internalPromotionClickHandler],
]);
