import { CartLineItem } from '@vf/api-client/src/types';
import { EventPropsHandler, EventPersistentVariables } from '.';
import { ComponentInstance } from '../../types';
import { AddToCartEvent, GtmCartProductObject } from '../../types/gtm';
import { useCart } from '../../useCart';
import { isCustomsProduct } from '../../useCustoms/utils';
import useGtm from '../../useGtm';
import { useI18n } from '../../useI18n';

import { cartLineItemToProductObject } from './helpers';

export const addToCartHandler: EventPropsHandler<
  AddToCartEvent,
  {
    productId: string;
    quantity: number;
    availSizes?: string[];
    avgRating?: string;
    badge?: string;
    colorCode?: string;
    colorDescription?: string;
    customModel?: string;
    customsTool?: string;
    numReviews?: number;
    isDummyCustom?: boolean;
    customsRecipeID?: string;
    sku: string;
    list_type: string;
    list?: string;
    experience: any;
    shippingMethod?: GtmCartProductObject['shippingMethod'];
  }
> = (eventProps, vmInstance: ComponentInstance) => {
  const { cart } = useCart(vmInstance);
  const { defaultCurrency } = useI18n(vmInstance);
  const {
    addGtmProductTrace,
    getCartProductTrace,
    gtmProductTraceMap,
    updatePersistentVariables,
  } = useGtm(vmInstance);
  const itemOnCart: CartLineItem = cart.value.items.find(
    (item) =>
      item.productId === eventProps.overrideAttributes.sku &&
      (!isCustomsProduct(item) ||
        // in case we have different customs with same base and size, they share same SKU
        item.recipeId === eventProps.overrideAttributes.customsRecipeID)
  );

  let persistentVariables: EventPersistentVariables = {
    ...getCartProductTrace(itemOnCart.masterId),
    ...getCartProductTrace(itemOnCart.productId),
    list: undefined,
    ...gtmProductTraceMap.value[itemOnCart.masterId],
  };

  [
    'availSizes',
    'avgRating',
    'numReviews',
    'badge',
    'shippingMethod',
    'styleCode',
    'catalogCategory',
    'list',
  ].forEach((key) => {
    if (eventProps.overrideAttributes[key]) {
      persistentVariables[key] = eventProps.overrideAttributes[key];
    }
  });

  persistentVariables = updatePersistentVariables(
    itemOnCart.masterId,
    persistentVariables
  );

  const masterPersistentVariables = { ...persistentVariables };
  // remove Persistent variable in Master
  ['shippingMethod'].forEach((excludeProps) => {
    if (masterPersistentVariables[excludeProps]) {
      delete masterPersistentVariables[excludeProps];
    }
  });
  updatePersistentVariables(itemOnCart.masterId, masterPersistentVariables);
  updatePersistentVariables(itemOnCart.productId, persistentVariables);
  [itemOnCart.masterId, itemOnCart.productId].forEach((pid) =>
    addGtmProductTrace(pid)
  );

  const prodObject: GtmCartProductObject = {
    ...cartLineItemToProductObject(itemOnCart, persistentVariables),
    brand: vmInstance
      .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
      .toUpperCase(),
    quantity: eventProps.overrideAttributes.quantity ?? 1,
    customModel: eventProps.overrideAttributes?.customModel || undefined,
    customsTool: eventProps.overrideAttributes?.customsTool || undefined,
  };
  prodObject.colorCode =
    prodObject.colorCode || eventProps.overrideAttributes?.colorCode;
  prodObject.colorDescription =
    prodObject.colorDescription ||
    eventProps.overrideAttributes?.colorDescription;

  let customVariables = {};
  const { experience, list_type } = eventProps.overrideAttributes;

  if (experience) {
    customVariables = Object.assign({}, customVariables, {
      list_type,
      recs_set: eventProps.overrideAttributes.experience.component,
      experience_id:
        eventProps.overrideAttributes.experience.impressionReporting[0]
          .experience_id,
      experience_label:
        eventProps.overrideAttributes.experience.impressionReporting[0]
          .experience_label,
      experience_name:
        eventProps.overrideAttributes.experience.impressionReporting[0]
          .experience_name,
      recs_set_id: eventProps.overrideAttributes.experience.actionId,
    });
  }

  return {
    event: 'addToCart',
    eventLabel: itemOnCart.masterId,
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Add to Cart',
    nonInteractive: false,
    ecommerce: {
      currencyCode: cart.value?.currency || defaultCurrency.value,
      add: {
        products: [prodObject],
      },
    },
    customMetrics: {},
    customVariables,
    _clear: true,
  };
};
