









































import { focus } from '@vf/shared/src/utils/directives';
import { computed, defineComponent, inject } from '@vue/composition-api';
import useModalHandlers from '../composables/useModalHandlers';
import useAsyncScrollTo from '../composables/useAsyncScrollTo';
import usePrefetch from '../composables/usePrefetch';

export default defineComponent({
  name: 'VfLink',
  directives: { focus },
  props: {
    /**
     * Page route or element selector for scrollable links
     */
    link: {
      type: [String, Object],
      default: '',
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Object,
      default: null,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    scrollToComponent: {
      type: Boolean,
      default: false,
    },
    isEmitter: {
      type: Boolean,
      default: false,
    },
    isDataHref: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit, root }) {
    const { openModal } = useModalHandlers();
    const { scrollTo } = useAsyncScrollTo();
    const { handleIntent, clearIntent } = usePrefetch();
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    // Support anchor links and any valid selectors
    // e.g http://domain#scroll-to-id
    const getElementSelector = (link: string): string => {
      return link.includes('#') ? `#${link.split('#').pop()}` : link;
    };

    const handleCustomClick = (e) => {
      const chooseCustomHandler = () => {
        switch (true) {
          case props.openInNewModal:
            return () => {
              if (props.link.includes('data-id')) {
                const resourceId = props.link.replace(/\D/g, '');
                openModal({ type: 'lazyFragment', resourceId });
              } else {
                openModal({
                  type: 'page',
                  path: props.link,
                });
              }
              emit('clicked-open-modal', props.link);
            };

          case props.scrollToComponent:
            return () => {
              const targetSelector = getElementSelector(props.link);
              scrollTo(targetSelector);
            };

          case props.isEmitter:
            return () => {
              emit('clicked', props.link);
            };

          default:
        }
      };

      const handler = chooseCustomHandler();

      if (handler) {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        handler();
      }
    };

    const handleMouseDown = () => {
      // Having this in a separate function breaks the navigation - somehow
      if (props.state) {
        window.history.replaceState(
          {
            ...(window.history.state || {}),
            ...props.state,
          },
          ''
        );
      }

      clearIntent();
    };

    const isExternal = computed(
      () =>
        typeof props.link === 'string' && props.link.search(/(^\/|^#)/g) === -1
    );

    const currentRoute = computed(() => root.$route?.fullPath);
    const isNativeLinkTag = computed(
      () =>
        isExternal.value ||
        !currentRoute.value ||
        props.openInNewModal ||
        currentRoute.value === props.link
    );

    const urlTag = computed(() =>
      isNativeLinkTag.value ? { href: props.link } : { to: props.link }
    );
    const target = computed(() =>
      props.openInNewTab ? { target: '_blank' } : null
    );
    const linkComponentTag = computed(() =>
      root.$nuxt ? 'nuxt-link' : 'router-link'
    );

    return {
      urlTag,
      target,
      linkComponentTag,
      isNativeLinkTag,
      isExternal,
      currentRoute,
      handleMouseDown,
      handleCustomClick,
      handleIntent,
      clearIntent,
      isCoreRedesignEnabled,
    };
  },
});
