import { CategoryProduct } from '@vf/api-client';
import { unref } from '@vue/composition-api';
import { EventPropsHandler } from '.';
import { GtmCategoryProductObject, ProductClickEvent } from '../../types/gtm';
import useGtm from '../../useGtm';
import { useI18n } from '../../useI18n';
import {
  getCustomsCategory,
  getColorInfoOrDefault,
  getDiscountTypesByPrice,
  getStyleCode,
  getVirtualStyleCode,
  recipeID,
} from './helpers';

type ProductClickAction =
  | 'Open Quick View'
  | 'Open Quick Shop'
  | 'Navigate To PDP'
  | 'Click View Details';

export const productClickHandler: EventPropsHandler<
  ProductClickEvent,
  {
    product: CategoryProduct;
    contextName: string;
    action: ProductClickAction;
    position?: number;
    badge?: string;
  }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);
  const product: CategoryProduct = unref(
    eventProps.overrideAttributes?.product
  );
  const { updatePersistentVariables, gtmPersistentVariables } = useGtm(
    vmInstance
  );

  const persistentVariables = (product) => {
    return updatePersistentVariables(product.id, {
      category: getCustomsCategory(
        product,
        gtmPersistentVariables.value.category
      ),
    });
  };

  let list;
  if (eventProps.overrideAttributes.contextName === 'category') {
    list = `Grid: PLP | ${gtmPersistentVariables.value.category}`;
  } else {
    list = `Search: PLP | ${gtmPersistentVariables.value.searchTerm}`;
  }

  const { colorCode, colorDescription } = getColorInfoOrDefault(product);
  const styleCode = getStyleCode(product, vmInstance.$themeConfig);
  const productId = vmInstance.$themeConfig.gtm?.useColorOnId
    ? product.id + colorCode
    : product.id;

  const productObject: GtmCategoryProductObject = {
    ...persistentVariables(product),
    avgRating: product.rating.score,
    badge: eventProps.overrideAttributes.badge,
    brand: vmInstance
      .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
      .toUpperCase(),
    bundleId: undefined,
    catalogCategory: product.primary_category_name,
    colorCode,
    colorDescription,
    customized: !!recipeID(product),
    discountType: getDiscountTypesByPrice(product) || undefined,
    id: productId,
    masterId: product.pid,
    merchantProductId: product.merchantProductId,
    name: product.name?.trim(),
    numReviews: product.rating.numReviews,
    onSale: product.price.original !== product.price.current,
    originalPrice: product.price.original,
    position: eventProps.overrideAttributes.position,
    preCreatedCustomCode: product.dummyCustoms ? styleCode : undefined,
    price: product.price.current,
    recipeID: recipeID(product) || undefined,
    saleDiscountAmount: product.price.discount || 0,
    season: product.season || undefined,
    styleCode,
    virtualStyleCode: getVirtualStyleCode(product.colorBundleCode),
  };

  return {
    event: eventProps.eventName,
    eventCategory: 'Enhanced Ecommerce',
    eventLabel: productId,
    eventAction: 'Product Clicks',
    nonInteractive: false,
    ecommerce: {
      currencyCode: product?.price?.currency || defaultCurrency.value,
      click: {
        actionField: { action: eventProps.overrideAttributes.action, list },
        products: [productObject],
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
