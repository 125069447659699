import axios, { AxiosInstance } from 'axios';

export const axiosclient: AxiosInstance = axios.create();

/**
 * On ssr mode we should use localhost url, it's for the requests to /fapi
 */
if (typeof window === 'undefined') {
  axiosclient.defaults.baseURL = 'http://localhost:3000';
}

export default axiosclient;

declare const process: {
  env: {
    NODE_ENV: string;
    AXIOS_WRAPPER_OPTIONS: any;
  };
};

/**
 * It helps to get cache wrapper config used in axios.
 * @param type
 * @returns
 */
export const getCacheWrapperConfig = (type: string) => {
  try {
    const axiosWrapperOptions = JSON.parse(process.env.AXIOS_WRAPPER_OPTIONS);
    if (!Object.values(axiosWrapperOptions).length) return {};
    return axiosWrapperOptions[type];
  } catch (e) {
    return {};
  }
};

export const getClientInstance = ({
  instanceWithCache,
  preview,
}: {
  instanceWithCache: any;
  preview: boolean;
}): AxiosInstance => {
  return preview ? axiosclient : instanceWithCache;
};
