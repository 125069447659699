import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  extractProperty,
  getDynamicField,
  getTranslation,
  generateLinkFromTeaserTargets,
  getCreateAccountTranslations,
} from './utils';
import { ComposableContext } from '../types';
export const maCreateAccount = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'account/CreateAccountForm',
    props: {
      link: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
      newsletterSignUpAutoCheck: getDynamicField(
        data,
        'newsletterSignUpAutoCheck',
        false
      ),
      translations: {
        heading: extractProperty(data, 'teaserTitle', '$Title$'),
        firstNameLabel: getTranslation(data, 'firstNameLabel', '$First name$'),
        lastNameLabel: getTranslation(
          data,
          'lastNameLabel',
          '$Last name label$'
        ),
        emailLabel: getTranslation(data, 'emailLabel', '$Email$'),
        requiredFieldsInfo: getTranslation(
          data,
          'requiredFieldsInfo',
          '$Required$'
        ),
        ...getCreateAccountTranslations(
          data,
          context,
          siteConfiguration,
          cmsBaseUri
        ),
        confirmPasswordLabel: getTranslation(
          data,
          'confirmPasswordLabel',
          '$Confirm password$'
        ),
        subscribeTextCheckbox: getDynamicField(
          data,
          'marketingConsentText',
          '$Please send me emails with offers and updates. I understand that I can unsubscribe at any time.$'
        ),
      },
    },
  };
};
