import { extractComponentName, addressFormTranslations } from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const maAddAddress = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const componentsMapping = {
    'data-source-shipping-address': 'ShippingAddressForm',
    'data-source-billing-address': 'BillingAddressForm',
  };
  const componentToRender = extractComponentName(data, componentsMapping);
  return {
    component: componentToRender
      ? `addressBook/${componentToRender}`
      : 'shared/AddressForm',
    props: {
      translations: addressFormTranslations(data, context, siteConfiguration),
      showHeading: true,
      showBackButton: true,
      showActionButtons: true,
      showEmailHelperText: true,
      showPhoneHelperText: true,
    },
  };
};
