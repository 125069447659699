import { RequestHeader } from '../../types';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { LoyaltyWelcomePromoStatusResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const getLoyaltyWelcomePromoStatus = (
  configuration: ApiClientConfiguration
) => async ({
  usid = null,
}: RequestHeader = {}): Promise<LoyaltyWelcomePromoStatusResponse> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.loyaltyWelcomePromoStatus,
    {
      config: configuration,
    }
  );

  const response = await axios.get<LoyaltyWelcomePromoStatusResponse>(url, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });

  return response.data;
};
