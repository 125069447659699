import { AxiosResponse } from 'axios';
import {
  SeoRecommendationData,
  SeoRecommendationType,
} from '@vf/composables/src/useSeo/types';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export interface SeoRecommendationsQuery {
  ptype: SeoRecommendationType;
  url: string;
}

export const getWidgetRecommendations = (
  configuration: ApiClientConfiguration
) => (
  params: SeoRecommendationsQuery
): Promise<AxiosResponse<SeoRecommendationData>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).product.widgetRecommendations,
    {
      config: configuration,
    }
  );
  const headers = headerBuilder(configuration);

  return axios.get<SeoRecommendationData>(url, { headers, params });
};
