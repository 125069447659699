var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-accordion",class:{
    'vf-accordion--open': _vm.isOpen,
    'vf-accordion--redesign-core': _vm.isCoreRedesignEnabled,
  }},[_c('div',{staticClass:"vf-accordion-item"},[(_vm.showTopDivider)?_c('VfDivider',{staticClass:"vf-divider__first",class:_vm.dividerType}):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"vf-accordion__tab",attrs:{"type":"button","aria-expanded":String(_vm.open),"aria-controls":_vm.idContent},on:{"click":function($event){$event.stopPropagation();return _vm.toggleHandler.apply(null, arguments)}}},[_c('span',{staticClass:"vf-accordion__tab-container",class:{ 'vf-accordion__tab-container--indent': _vm.indentHeader }},[_vm._t("header",function(){return [_c('VfHeading',{attrs:{"id":_vm.ariaLabelledByKey,"title":_vm.header,"level":2,"title-modifier":_vm.strongHeader ? 'title-5' : _vm.headingModifier,"styles":_vm.styles
                ? {
                    titleColor: _vm.styles.color,
                    titleFontWeight: _vm.styles.fontWeight,
                    titleFontStyle: _vm.styles.fontStyle,
                  }
                : {}}})]}),_vm._v(" "),(_vm.iconType != 'none')?_c('VfIcon',{staticClass:"vf-accordion__icon",attrs:{"size":_vm.iconSize,"color":"#2a2a2a","aria-hidden":true,"icon":_vm.open ? _vm.icons.opened : _vm.icons.closed}}):_vm._e()],2)]),_vm._v(" "),(!_vm.showTopDivider && !_vm.open)?_c('VfDivider',{staticClass:"vf-divider__first",class:_vm.dividerType}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[_c('VfConditionalRenderer',{attrs:{"id":_vm.idContent,"show":_vm.open,"ssr":_vm.ssr}},[_c('div',{staticClass:"vf-accordion-item__content"},[_c('div',{staticClass:"vf-accordion__content-inner"},[_vm._t("default")],2)])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }