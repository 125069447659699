import { gridColumn } from './components/gridColumn';
import { gridOverImage } from './components/gridOverImage';
import { monetateDecision } from './components/monetateDecision';
import { standardComponentDataParser } from './components/standard';
import { CmsSite } from '@vf/api-contract';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../../types';

export const parseDataBasedOnType = (
  pageItem: any,
  componentParser: ReturnType<typeof standardComponentDataParser>,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  context: ComposableContext,
  pageTypeName: PageTypeName,
  nested = false
) => {
  const errors = [];

  try {
    if (isGridRowComponent(pageItem) && !isGridOverImageComponent(pageItem)) {
      const parsedGrid = gridColumn(
        pageItem,
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        pageTypeName,
        nested
      );
      errors.push(...parsedGrid.errors);
      return {
        data: parsedGrid.data,
        errors,
      };
    } else if (isDecisionComponent(pageItem)) {
      const parsedDecisionComponent = monetateDecision(
        pageItem,
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        pageTypeName
      );
      errors.push(...parsedDecisionComponent.errors);

      return {
        data: parsedDecisionComponent.data,
        errors,
      };
    } else if (
      isGridRowComponent(pageItem) &&
      isGridOverImageComponent(pageItem)
    ) {
      const parsedGrid = gridOverImage(
        pageItem,
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        pageTypeName,
        nested
      );
      errors.push(...parsedGrid.errors);
      return {
        data: parsedGrid.data,
        errors,
      };
    } else {
      return {
        data: componentParser(pageItem),
        errors,
      };
    }
  } catch (e) {
    errors.push(e.message);
  }

  return {
    data: null,
    errors,
  };
};

const isGridOverImageComponent = (item: any) => {
  return item.pictures?.length || item.videos?.length;
};

const isGridRowComponent = (item: any) => {
  return (
    item &&
    item.gridColumn &&
    item.gridColumn.columns &&
    Array.isArray(item.gridColumn.columns)
  );
};

const isDecisionComponent = (item: any) => {
  return item && typeof item.experienceId === 'string';
};
