import { ApiClientConfiguration } from '../configuration';
import { v4 as uuidv4 } from 'uuid';
import { AxiosRequestHeaders } from 'axios';

export const headerBuilder = (
  config: ApiClientConfiguration,
  additionalHeaders: Record<string, string> = {}
): AxiosRequestHeaders => {
  const correlationId = uuidv4();
  return {
    source: config.source,
    brand: config.brand.toUpperCase(),
    region: config.region,
    'x-transaction-id': correlationId,
    'X-Correlation-Id': correlationId,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    siteid: config.siteId,
    locale: config.locale,
    channel: config.channel,
    ...(config.ssrAccessToken && { 'x-auth-access': config.ssrAccessToken }),
    ...(config.ssrTrustId && { 'vf-server-trust-id': config.ssrTrustId }),
    ...(config.ssrBypassMaintenanceHeader && {
      'x-bypass-maintenance': config.ssrBypassMaintenanceHeader,
    }),
    //for preview debug purposes
    ...(config.isPreview && { 'x-preview-mode': 'y' }),
    ...additionalHeaders,
  };
};
