import { EventPropsHandler } from '.';
import { MonetateExperienceEvent } from '../../types/gtm';

export const monetateActiveExperiencesHandler: EventPropsHandler<
  MonetateExperienceEvent,
  {
    actions: any[];
  }
> = (eventProps, vmInstance) => {
  const prepareProductsForGtm = (experienceAction: any) => {
    return (
      experienceAction.items?.map((item) => {
        return {
          brand: vmInstance
            .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
            .toUpperCase(),
          id: item.itemGroupId,
          colorCode: item.id.split(':')[2],
          merchantProductId: item.merchantProductId,
          name: item.title,
          onSale: item.price != item.salePrice,
          originalPrice: item.price,
          price: item.salePrice,
          saleDiscountAmount: item.price - item.salePrice,
          recs_set: experienceAction?.component,
          experience_id: experienceAction.impressionReporting[0].experience_id,
          experience_label:
            experienceAction.impressionReporting[0].experience_label,
          experience_name:
            experienceAction.impressionReporting[0].experience_name,
          recs_set_id: experienceAction.actionId,
        };
      }) || []
    );
  };

  const monetateExperiences = eventProps.overrideAttributes.actions.map(
    (action) => ({
      split: action.impressionReporting[0].variant_label,
      id: action.impressionReporting[0].experience_id,
      key: action.impressionReporting[0].experience_label,
    })
  );

  const products = eventProps.overrideAttributes.actions.reduce(
    (accumulator, action) => [...accumulator, ...prepareProductsForGtm(action)],
    []
  );

  return {
    event: 'monetateActiveExperiences',
    eventCategory: 'Monetate',
    eventAction: 'Active Experiences',
    eventLabel: undefined,
    nonInteractive: true,
    customMetrics: {},
    customVariables: {},
    monetateExperiences,
    recommendations: {
      products,
    },
    source: 'FE',
    _clear: true,
  };
};
