import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { CartAddressRequest } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const setBillingAddress = (configuration: ApiClientConfiguration) => (
  cartId: string,
  formData: CartAddressRequest
): Promise<any> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).checkout.setBillingAddress,
    {
      config: configuration,
      mapping: { basketId: cartId },
    }
  );

  return axios.post(
    url,
    {
      ...(formData.addressId && { addressId: formData.addressId }),
      shippingId: formData.shippingId,
      firstName: formData.firstName || '',
      lastName: formData.lastName || '',
      email: formData.email || '',
      country: formData.country || '',
      addressLine1: formData.addressLine1 || '',
      addressLine2: formData.addressLine2 || '',
      postalCode: formData.postalCode || '',
      city: formData.city || '',
      province: formData.province || '',
      phone: formData.phone || '',
      subscriptions: {
        newsletterConsent: !!formData.subscriptions?.newsletterConsent,
      },
    },
    {
      headers: headerBuilder(configuration),
    }
  );
};
