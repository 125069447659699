import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
  getDynamicField,
  getTranslation,
} from './utils';
import { CmsSite, CMTeaser } from '@vf/api-contract';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export const cartUpsell = (
  data: CMTeaser,
  context,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  if (isCheckoutRedesignEnabled) {
    return {
      component: 'upsell/MonetateCartUpsell2',
      props: {
        upsellsType: getDynamicField(data, 'type', 'singleSized'),
        quickShopLink: generateLinkFromTarget(
          extractProperty(
            extractCommonConfigLink(siteConfiguration, 'upsell-quick-shop'),
            '[0]'
          ),
          context,
          siteConfiguration,
          cmsBaseUri
        ),
        ...(data._injectedProps ? data._injectedProps : {}),
      },
    };
  } else {
    return {
      component: 'monetate/MonetateCartUpsell',
      props: {
        upsellsType: getDynamicField(data, 'type', 'singleSized'),
        headingTemplate: extractProperty(data, 'teaserTitle'),
        buttonStyle: getDynamicField(data, 'style'),
        translations: {
          ariaLabel: getTranslation(data, 'ariaLabel', '$add to cart$'),
          addToCartButtonText: getTranslation(
            data,
            'addToCartButtonText',
            '$Add to cart$'
          ),
          defaultOption: getTranslation(
            data,
            'defaultOption',
            '$Please Select Your Size$'
          ),
          outOfStock: getTranslation(data, 'outOfStock', '$Out of Stock$'),
        },
        ...(data._injectedProps ? data._injectedProps : {}),
      },
    };
  }
};
