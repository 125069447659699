






























import {
  defineComponent,
  ref,
  Ref,
  watch,
  onMounted,
} from '@vue/composition-api';
import { useRouting, useAuthentication } from '@vf/composables';
import CmsModal from '@/components/cms/CmsModal.vue';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import useLoader from '@/shared/useLoader';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'ModalManager',
  components: {
    CmsModal,
  },
  setup() {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const isContentLoaded: Ref<boolean> = ref(false);

    const {
      isModalOpen,
      openModal,
      closeModal,
      modalProps,
      modalType,
      path,
      contextKey,
      resourceId,
      scrollModalTop,
      setScrollModalTop,
      additionalData,
    } = useModal();

    const { previousRoute } = useRouting(root);
    const { isSpinnerVisible } = useLoader();
    const { consumerType } = useAuthentication(root);

    watch(previousRoute, () => {
      if (isModalOpen.value) {
        onCloseModal();
      }
    });

    watch(
      consumerType,
      () => {
        if (
          consumerType.value === 'EMPLOYEE' &&
          !userStore.isEmployeeDiscountTCAnswered
        ) {
          /**
           * This hack is an edge case, because it will be invoked only for EMPLOYEE customer type
           * This is added because openModal() must be invoked only when the previous modal will be fully closed
           * https://digital.vfc.com/jira/browse/GLOBAL15-44701
           */
          const defineInterval = setInterval(() => {
            if (!isModalOpen.value) {
              clearInterval(defineInterval);

              openModal({
                type: 'fragment',
                resourceId: 'employeeDiscountModal',
                contextKey: 'employee-modal',
              });
            }
          }, 200);
        }
      },
      {
        immediate: true,
      }
    );

    watch(scrollModalTop, () => {
      if (scrollModalTop.value) {
        scrollToTop();
        setScrollModalTop(false);
      }
    });

    const isExternalUrl = (url = '') => {
      return url.startsWith('http://') || url.startsWith('https://');
    };

    const onCloseModal = () => {
      closeModal();

      setTimeout(() => {
        isContentLoaded.value = false;
      }, additionalData.value?.cssAnimationDuration);
    };

    const onContentLoad = () => {
      isContentLoaded.value = true;
    };

    const scrollToTop = () => {
      const modalContent = document.querySelector('.vf-modal__content');
      if (modalContent) {
        modalContent.scrollTop = 0;
      }
    };

    onMounted(() => {
      if (isModalOpen.value) onCloseModal();
    });

    return {
      isModalOpen,
      isContentLoaded,
      isExternalUrl,
      openModal,
      onCloseModal,
      onContentLoad,
      scrollToTop,
      contextKey,
      modalProps,
      modalType,
      path,
      resourceId,
      isSpinnerVisible,
      additionalData,
    };
  },
});
