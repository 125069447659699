export default function uniqBy<T>(target: T[], key: string): T[] {
  if (!Array.isArray(target)) {
    return target;
  }

  const tempMap = new Map();
  for (const obj of target) {
    if (!tempMap.has(obj[key])) tempMap.set(obj[key], obj);
  }
  return [...tempMap.values()];
}
