import { getTranslation } from './utils';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../types';
import { CmsSite } from '@vf/api-contract';
import useCategory from '../../useCategory';

export const plpFilters = (
  data: CmsSearchFilters,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => {
  const { changeSort } = useCategory(context.instance, context.contextKey);
  const generalConfig = getConfig(data, 'plp-filters-properties');
  const smallViewportConfig = getConfig(data, 'plp-filters-properties-small', {
    'filter-number-of-facets-to-show': 3,
    'filters-expansion': 'closed',
  });
  const mediumViewportConfig = getConfig(
    data,
    'plp-filters-properties-medium',
    {
      'filter-number-of-facets-to-show': null,
      'filters-expansion': 'closed',
    }
  );
  const largeViewportConfig = getConfig(data, 'plp-filters-properties-large', {
    'filter-number-of-facets-to-show': null,
    'filters-expansion': 'open',
  });

  const defaultSort = generalConfig['sort-by'];
  const sort = context.instance.context.route.query?.sort || defaultSort;
  if (sort) changeSort(sort, false);

  const showDropdown = generalConfig['show-dropdown'];
  let expandedAccordions = generalConfig['expanded-facets'];
  if (typeof expandedAccordions === 'string')
    expandedAccordions = expandedAccordions.split(',');

  const isCategory = pageTypeName === PageTypeName.PLP;

  return {
    component: showDropdown
      ? 'plp/CategoryFilterPanel'
      : isCategory
      ? 'plp/CategoryFilters'
      : 'search/SearchFilters',
    props: {
      translations: {
        applyButton: getTranslation(data, 'apply', '$Apply$'),
        applyButtonAria: getTranslation(
          data,
          'applyButtonResetAria',
          '$Apply filters$'
        ),
        applyButtonDisabled: getTranslation(
          data,
          'applyDisabled',
          '$Select Filter$'
        ),
        topResetButton: getTranslation(
          data,
          'resetButtonText',
          '$Reset filters$'
        ),
        resetAria: getTranslation(data, 'resetAria', '$reset-button$'),
        resetFilter: getTranslation(data, 'resetFilter', '$Clear All$'),
        resetFilterAria: getTranslation(
          data,
          'resetFilterAria',
          '$Clear Selection$'
        ),
        topResetButtonAria: getTranslation(data, 'resetAria', '$reset-button$'),
        bottomResetButton: getTranslation(data, 'reset', '$Reset$'),
        bottomResetButtonAria: getTranslation(
          data,
          'resetAria',
          '$Reset filters$'
        ),
        selectAria: getTranslation(data, 'selectAria', '$select$'),
        sortLabel: getTranslation(data, 'sortLabel', '$Sort by$'),
        filtersLabel: getTranslation(data, 'filtersLabel', '$Filters$'),
        showMore: getTranslation(data, 'showMore', '$Show More$'),
        showLess: getTranslation(data, 'showLess', '$Show less$'),
        shopAt: getTranslation(
          data,
          'shopAt',
          '$Shop {{category}} {{filters}} at$'
        ),
        productsQuantitySingle: getTranslation(
          data,
          'productsQuantitySingle',
          '${{quantity}} Product$'
        ),
        productsQuantityPlural: getTranslation(
          data,
          'productsQuantityPlural',
          '${{quantity}} Products$'
        ),
        expandAll: getTranslation(data, 'expandAll', '$Expand all$'),
        expandAllAria: getTranslation(
          data,
          'expandAllAria',
          '$Expand all filters$'
        ),
        collapseAll: getTranslation(data, 'collapseAll', '$Collapse all$'),
        collapseAllAria: getTranslation(
          data,
          'collapseAllAria',
          '$Collapse all filters$'
        ),
        seeMoreFilters: getTranslation(data, 'seeMoreFilters', '$See More$'),
        seeMoreFiltersAria: getTranslation(
          data,
          'seeMoreFiltersAria',
          '$See More Applied Filters$'
        ),
        seeLessFilters: getTranslation(data, 'seeLessFilters', '$See Less$'),
        seeLessFiltersAria: getTranslation(
          data,
          'seeLessFiltersAria',
          '$See Less Applied Filters$'
        ),
        sortOptions: {
          whatsNew: getTranslation(data, 'whatsNew', '$Whats new$'),
          topSellers: getTranslation(data, 'topSellers', '$Top Sellers$'),
          rating: getTranslation(data, 'rating', '$Rating$'),
          bestMatches: getTranslation(data, 'bestMatches', '$Best matches$'),
          priceHighToLow: getTranslation(
            data,
            'priceHighToLow',
            '$Price high to low$'
          ),
          priceLowToHigh: getTranslation(
            data,
            'priceLowToHigh',
            '$Price low to high$'
          ),
        },
        selectedGridSizeSelectorLabel: getTranslation(
          data,
          'selectedGridSizeSelectorLabel',
          '$View$'
        ),
        priceRangeInputLabels: {
          minPriceInputLabel: getTranslation(
            data,
            'minPriceInputLabel',
            '$Minimum Price Input Field$'
          ),
          maxPriceInputLabel: getTranslation(
            data,
            'maxPriceInputLabel',
            '$Maximum Price Input Field$'
          ),
          minPriceRangeLabel: getTranslation(
            data,
            'minPriceRangeLabel',
            '$Minimum Price Range Slider$'
          ),
          maxPriceRangeLabel: getTranslation(
            data,
            'maxPriceRangeLabel',
            '$Maximum Price Range Slider$'
          ),
          maximumDisplayPrice: getTranslation(data, 'maximumDisplayPrice', 300),
        },
        allFiltersRemoved: getTranslation(
          data,
          'allFiltersRemoved',
          '$All filters removed. {{x}} products available.$'
        ),
        filterRemoved: getTranslation(
          data,
          'filterRemoved',
          '$Removed {{filterName}} successfully. {{x}} products available.$'
        ),
        abovePriceFilterLabel: getTranslation(
          data,
          'abovePriceFilterLabel',
          '$Above {{value}}$'
        ),
        belowPriceFilterLabel: getTranslation(
          data,
          'belowPriceFilterLabel',
          '$Under {{value}}$'
        ),
      },
      accordionsOpen: {
        small: areFiltersExpanded(data, 'small'),
        medium: areFiltersExpanded(data, 'medium'),
        large: areFiltersExpanded(data, 'large'),
      },
      expandedAccordions,
      defaultSort,
      sticky: generalConfig['filters-sticky'],
      displayCount: generalConfig['filters-count'],
      showResetButton: true, // TODO Remove this hardcoded value if property wont come from CMS
      filterNumberOfFacetsToShowSmall:
        smallViewportConfig['filter-number-of-facets-to-show'],
      filterNumberOfFacetsToShowMedium:
        mediumViewportConfig['filter-number-of-facets-to-show'],
      filterNumberOfFacetsToShowLarge:
        largeViewportConfig['filter-number-of-facets-to-show'],
      filterNumberOfFacetsToShow:
        generalConfig['filter-number-of-facets-to-show'],
      sizeFiltersVariant: generalConfig['sizes-as-text-or-chips'],
      isCategory,
    },
  };
};

const getConfig = (
  data: CmsSearchFilters,
  configName: string,
  defaultConfig?: CmsSearchFiltersViewportConfig
) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === configName
    );

    return config.values;
  } catch (e) {
    console.error('Search Filters component error: no config found.');
    if (defaultConfig) return defaultConfig;
  }

  return {
    'filters-count': true,
    'filters-display': 'vertically',
    'filters-nest': true,
    'filters-show-sizes': true,
    'filters-sticky': true,
    'filter-number-of-facets-to-show': 5,
    'sizes-as-text-or-chips': 'text',
  };
};

const areFiltersExpanded = (
  data: CmsSearchFilters,
  size: 'small' | 'medium' | 'large'
) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === `plp-filters-properties-${size}`
    );

    return config.values['filters-expansion'] === 'open';
  } catch (e) {
    console.error('Search Filters component error: no config found.');
  }

  return true;
};

export type CmsSearchFilters = {
  id: string;
  type: 'CMPlaceholder';
  name: string;
  title: string;
  componentConfig: CmsSearchFiltersConfig[];
  viewtype: 'search-filters';
};

export type CmsSearchFiltersViewportConfig = {
  'filter-number-of-facets-to-show': number;
  'filters-expansion': string;
};

export type CmsSearchFiltersConfig = {
  name: string;
  values: {
    'show-add-to-favorites': boolean;
    'show-no-inventory-products': boolean;
    'next-products': number;
    'initial-load-products': number;
    'sizes-as-text-or-chips': 'text' | 'chips';
  };
  valuesExpanded: ValueExpanded[];
};

type ValueExpanded = {
  path: string;
  links: Array<object>;
};
