//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'VfFloatingButton',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    icon: {
      type: String,
      required: true,
    },
    counter: {
      type: String,
      required: false,
      default: null,
    },
    randomizer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
});
