import { cmsApiClient, CmsApiClientSettings, cmsSiteId } from '@vf/api-client';
import { CMCollection, CMPicture, CmsSite, CMTeaser } from '@vf/api-contract';
import {
  decorateHtmlValue,
  extractCommonConfigLink,
  extractProperty,
  getDynamicField,
  getImageObject,
} from '../useCms/mappings/utils';
import { ComponentInstance } from '../types';
import { isClient } from '@vf/shared/src/utils/helpers';
import * as dataFetcher from '../useCms/dataFetcher';
import { CmsSetupSettings, InitializedApiClient } from '../useCms/types';
import { useI18n } from '../index';
import { getUrlQueryParam } from '../useCms/utils';
import { Ref, ref } from '@nuxtjs/composition-api';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
export default function useInitSetup(instance: ComponentInstance) {
  const apiClientRef: Ref<InitializedApiClient | null> = ref(null);
  const { localePath } = useI18n(instance);
  const cmsRefStore = useCmsRefStore(instance.$pinia);

  const getHeaders = (
    renderer: string,
    envProvider: any,
    $root: any
  ): CmsApiClientSettings['headers'] => {
    const headers = {};

    /**
     * Access token needs to be hidden in client requests.
     * Keep access token headers on:
     * - SSR requests
     * - Client side request only on development mode
     *
     * HUMAN header only for developers working on localhost
     */
    if ($root.$env.NODE_ENV === 'development') {
      headers['x-disable-human'] = 'true';
      headers[envProvider[`COREMEDIA_ACCESS_HEADER_${renderer}`]] =
        envProvider[`COREMEDIA_ACCESS_TOKEN_${renderer}`];
    }

    if (
      renderer === 'SSR' &&
      !headers[envProvider[`COREMEDIA_ACCESS_HEADER_${renderer}`]]
    ) {
      headers[envProvider[`COREMEDIA_ACCESS_HEADER_${renderer}`]] =
        envProvider[`COREMEDIA_ACCESS_TOKEN_${renderer}`];
    }

    return headers;
  };

  function buildCmsClientSettings(
    renderer: string,
    uriPrefix: string,
    forcePreview: boolean,
    envProvider: any,
    $root: any
  ): CmsApiClientSettings {
    const cmsClientSettings: CmsApiClientSettings = {
      contentEnv: '',
      baseUri: $root.$env[`${uriPrefix}_${renderer}`],
      menuEndpoint: $root.$env['COREMEDIA_MENU_ENDPOINT'],
      baseMediaUri: $root.$env[`${uriPrefix}_CSR`],
      headers: getHeaders(renderer, envProvider, $root),
      disableCache: forcePreview,
    };

    /** Add Header to bypass Akamai Maintenance page on SRR */
    if (
      renderer === 'SSR' &&
      process.env.HEADER_BYPASS_AKAMAI_MAINTENANCE_PAGE
    ) {
      cmsClientSettings.headers['x-bypass-maintenance'] =
        process.env.HEADER_BYPASS_AKAMAI_MAINTENANCE_PAGE;
    }

    /** Add custom preview date to API call headers if it's there in URL Query params */
    if (forcePreview) {
      const previewDate = getUrlQueryParam(
        { instance, contextKey: '' },
        'previewDateRFC1123'
      );
      if (!isNaN(Date.parse(previewDate))) {
        cmsClientSettings.headers['X-Preview-Date'] = previewDate;
      }
    }

    /* append cms query param to non production cms requests */
    if (!$root.$env.DOMAIN.includes('www') && instance.$route.query.cms) {
      cmsClientSettings.contentEnv = `?cms=${instance.$route.query.cms}`;
    }

    return cmsClientSettings;
  }

  const getBadgesObject = (siteConfiguration: CmsSite) => {
    try {
      const badgesCollection: CMCollection = extractProperty(
        extractCommonConfigLink(siteConfiguration, 'badges'),
        '[0]'
      );

      return (
        badgesCollection?.teasableItems?.reduce(
          (allBadges, cmsObject: CMTeaser) => {
            const type: string = cmsObject.type;

            if (type === 'CMPicture') {
              const cmPicture: CMPicture = (cmsObject as unknown) as CMPicture;

              allBadges[cmPicture.name] = {
                src: getImageObject(
                  cmPicture,
                  siteConfiguration,
                  null,
                  cmsRefStore.baseMediaUri
                ),
                alt: cmPicture.alt,
                width: cmPicture.width,
                height: cmPicture.height,
              };
            }
            return allBadges;
          },
          {}
        ) || {}
      );
    } catch (e) {
      return {};
    }
  };
  const getSizeChartObject = (siteConfiguration: CmsSite) => {
    try {
      const sizeChartCollection: CMCollection = extractProperty(
        extractCommonConfigLink(siteConfiguration, 'sizecharts'),
        '[0]'
      );

      return (
        sizeChartCollection?.teasableItems?.reduce(
          (allSizeCharts, cmsObject: CMTeaser) => {
            const type: string = cmsObject.type;

            if (cmsObject.viewtype === 'custom-content') {
              allSizeCharts[cmsObject.name] = {
                type: 'custom',
                src: {
                  customContentFile: getDynamicField(
                    cmsObject,
                    'customContentFile',
                    ''
                  ),
                  customContentPath: getDynamicField(
                    cmsObject,
                    'customContentPath',
                    ''
                  ),
                },
                alt: cmsObject.alt,
              };
            } else if (type === 'CMPicture') {
              const cmPicture: CMPicture = (cmsObject as unknown) as CMPicture;

              allSizeCharts[cmPicture.name] = {
                type: 'image',
                src: getImageObject(
                  cmPicture,
                  siteConfiguration,
                  null,
                  cmsRefStore.baseMediaUri
                ),
                alt: cmPicture.alt,
              };
            } else if (type === 'CMTeaser') {
              allSizeCharts[cmsObject.name] = {
                type: 'html',
                src: decorateHtmlValue(
                  cmsObject.teaserRichTextObject,
                  cmsRefStore.baseMediaUri,
                  null,
                  siteConfiguration
                ),
                alt: cmsObject.alt,
              };
            }

            return allSizeCharts;
          },
          {}
        ) || {}
      );
    } catch (e) {
      return {};
    }
  };
  const getEmployeeSignInPage = (siteConfiguration: CmsSite) => {
    const employeeSignInPage = extractProperty(
      extractCommonConfigLink(siteConfiguration, 'employeeSignInPage'),
      '[0]'
    );
    if (!employeeSignInPage) return '';

    const replacePageSegment = new RegExp(`${employeeSignInPage.segment}\/?`);
    const cmsLocale =
      employeeSignInPage?.urlSegment?.replace(replacePageSegment, '') ?? '';
    return localePath(
      employeeSignInPage?.urlSegment?.replace(cmsLocale, '/') ?? '/'
    );
  };

  async function initSetup({ forcePreview }: CmsSetupSettings) {
    const renderer = isClient ? 'CSR' : 'SSR';
    const uriPrefix = forcePreview ? 'COREMEDIA_URI_PREVIEW' : 'COREMEDIA_URI';
    const { $root } = instance;
    const envProvider =
      $root.$env.NODE_ENV === 'development' ? $root.$env : process.env;

    const cmsClientSettings: CmsApiClientSettings = buildCmsClientSettings(
      renderer,
      uriPrefix,
      forcePreview,
      envProvider,
      $root
    );
    // initialize CMS Api Client
    if (!apiClientRef.value) {
      // Use Force Preview here to configure the client to disable caching
      apiClientRef.value = await cmsApiClient(cmsClientSettings);
    }

    if (cmsRefStore.cmsInitialized) {
      if (forcePreview) {
        // The backend cannot always know the preview state as it's stored in
        // session storage, so the early exit on the client here can lead to
        // incorrect URLs being used after CSR
        cmsRefStore.$patch({
          baseUri: cmsClientSettings.baseUri,
          baseMediaUri: cmsClientSettings.baseMediaUri,
        });
      }

      cmsSiteId.set(cmsRefStore.site);
      return;
    }

    try {
      cmsRefStore.$patch({
        errors: [],
        baseUri: cmsClientSettings.baseUri,
        baseMediaUri: cmsClientSettings.baseMediaUri,
        // Select proper Site ID basing on current locale or default locale
        site: instance.$getEnvValueByCurrentLocale<string>('CMS_SITE'),
      });

      if (!cmsRefStore.site) {
        throw new Error(
          `Locale <${instance.$root.$i18n.locale.toLowerCase()}> is not set up in CoreMedia.`
        );
      }

      cmsSiteId.set(cmsRefStore.site);

      // Fetch and save CMS Site configuration data
      const cmsSiteConfiguration = await dataFetcher.config.getSiteConfiguration(
        cmsRefStore.site,
        apiClientRef.value
      );
      cmsRefStore.$patch({ cmsSiteConfiguration });

      const badges = getBadgesObject(cmsRefStore.cmsSiteConfiguration);
      const sizeCharts = getSizeChartObject(cmsRefStore.cmsSiteConfiguration);
      const employeeSignInPage = getEmployeeSignInPage(
        cmsRefStore.cmsSiteConfiguration
      );

      cmsRefStore.$patch({
        badges,
        sizeCharts,
        employeeSignInPage,
      });

      cmsRefStore.$patch({
        cmsInitialized: true,
      });
    } catch (err) {
      cmsRefStore.$patch({
        errors: [
          ...cmsRefStore.errors,
          'Cannot setup CMS properly! Please make sure that settings below are correct: ',
          `Base URL: ${cmsClientSettings.baseUri}`,
          `Base media URL: ${cmsClientSettings.baseMediaUri}`,
          `Site Id: ${cmsRefStore.site}`,
          `Supported languages: ${instance.$root.$env.SUPPORTED_LANGUAGES}`,
          `Current locale: ${instance.$root.$i18n.locale}`,
          `Headers: ${JSON.stringify(cmsClientSettings.headers)}`,
          `Error message: ${err.message}`,
        ],
      });
      instance.$log.error(
        `[@useInitSetup::initSetup] Error while setting up CMS Site Configuration.`,
        { error: err }
      );
    }
  }

  function verifySetup() {
    let errorMessage = '';
    if (!apiClientRef.value) {
      errorMessage = `[@useInitSetup::verifySetup] CMS Api Client is not initialized.`;
    } else if (!cmsRefStore.cmsSiteConfiguration) {
      errorMessage = `[@useInitSetup::verifySetup] No CMS Site config is stored in Pinia.`;
    } else if (!cmsRefStore.baseUri) {
      errorMessage = `[@useInitSetup::verifySetup] No base URI stored in Pinia.`;
    }

    if (errorMessage) {
      instance.$log.error(errorMessage);
      return false;
    }

    return true;
  }

  return {
    initSetup,
    verifySetup,
    apiClientRef,
  };
}
