import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, headerBuilder, urlBuilder } from '../../helpers';

export const getStoresCountries = (
  configuration: ApiClientConfiguration
) => async (): Promise<any> => {
  return axios.get(
    urlBuilder(endpoints.get(configuration).stores.getCountries, {
      config: configuration,
    }),
    {
      headers: headerBuilder(configuration),
    }
  );
};
