import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getAffiliateCookies = (configuration: ApiClientConfiguration) => (
  query: string
): Promise<any> => {
  const url: string = endpoints.get(configuration).customer.getAffiliateCookies;
  return axios.get(urlBuilder(`${url}?${query}`, { config: configuration }), {
    headers: headerBuilder(configuration),
  });
};
