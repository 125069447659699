import getCartImage from '../utils/dataSources/handlers/getCartImage';
import { ComponentInstance, ROUTES } from '../../src/index';
import useUrl from '../useUrl/';
import { useCustoms } from '../useCustoms';
import { isCustomsProduct, getRecipeId } from '../useCustoms/utils';
import { Product } from '@vf/api-client';
import { prepareOrderShipments } from '@vf/composables/src/utils/orders';

export type OrderProductData = {
  orders: Order[];
};

export type Order = {
  order_lines: OrderLine[];
};

type OrderLine = Product & {
  attributes: Product['attributes'];
  customsCustomizer?: boolean;
  customsExternalUrl?: string;
  image: string;
  imageDeclined: string;
  img: string;
  masterId: string;
  name: string;
  pdpUrl: string;
  quantity_ordered: number;
  quantity_shipped: number;
  customsRecipeID?: string;
  recipeId?: string;
  recipe?: string;
  sku: string;
  style_code: string;
  skuColorCode?: string;
};

const buildAdditionalImageInfo = async (
  instance: ComponentInstance,
  product: OrderLine
) => {
  if (isCustomsProduct(product)) {
    const { customsPdm } = useCustoms(instance);

    const customizerUrl = [product.customsExternalUrl, product.pdpUrl].find(
      (url) => url && url.includes(ROUTES.CUSTOMIZER())
    );

    if (customizerUrl) {
      const model = customizerUrl
        .split(`${ROUTES.CUSTOMIZER()}.`)[1]
        .split('.html')[0];

      if (model) {
        const pdm = await customsPdm({ model });
        const imageInfo = pdm?.product_images?.featured_views?.mini_cart;

        if (imageInfo) {
          return {
            imageSize: imageInfo.size,
            imageView: imageInfo.view,
          };
        }
      }
    }
  }

  return {};
};

export const mapAndAssignOrdersProducts = async (
  instance: ComponentInstance,
  data: OrderProductData
): Promise<OrderProductData> => {
  if (!data.orders) return;

  const { getProductUrl, getRelativeUrl } = useUrl(instance);
  const { setImagesDeclined } = useCustoms(instance);

  for (const order of data.orders) {
    for (const product of order.order_lines) {
      const isCustoms = isCustomsProduct(product);
      const recipeId = getRecipeId(product);
      const imageInfo = await buildAdditionalImageInfo(instance, product);

      product.pdpUrl = instance.$themeConfig.productAddToCart
        .pdpUrlWithAttributes
        ? getRelativeUrl(product.pdpUrl)
        : getProductUrl(product as any, {
            recipe: recipeId,
            variant: !recipeId ? product.masterId : undefined,
          });
      product.image = await getCartImage(instance, {
        image_uri: instance.$mediaUrlGenerator({
          pid: product.masterId,
          colorCode:
            instance.$themeConfig.orderDetails.appendColorCode &&
            product.skuColorCode,
        }) as string,
        fallbackImage: instance.$mediaUrlFallback(),
        productId: product.masterId,
        recipeId,
        isCustoms,
        ...imageInfo,
      });
    }

    order.order_lines = await setImagesDeclined(order.order_lines);
  }
  return data;
};

export const mapUserOrder = async (orderData, instance: ComponentInstance) => {
  const shipments = prepareOrderShipments(orderData.shipments);
  const tempOrder = { ...orderData, shipments };
  await mapProductProps(tempOrder, instance);
  return tempOrder;
};

const mapProductProps = async (data, instance: ComponentInstance) => {
  const orderProductData: OrderProductData = {
    orders: [
      {
        order_lines: data.items,
      },
    ],
  };

  await mapAndAssignOrdersProducts(instance, orderProductData);

  // Pass-through images declined status
  data.items = orderProductData.orders[0].order_lines;
};
