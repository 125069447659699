import { ComponentInstance, ComposablesStorage } from '../types';
import { ssrRef } from '@nuxtjs/composition-api';
import {
  EventProps,
  eventPropsHandlers,
  EventPersistentVariables,
  EventPersistentVariablesMap,
  VideoEventProps,
} from './eventPropsHandlers';
import { Ref, computed } from '@vue/composition-api';
import initStorage from '../utils/storage';
import ls from '../utils/localStorage';
import { useCart } from '../useCart';
import { PageTypeName } from '../useCms/types';
import { CheckoutStepNumber, EventName } from '../types/gtm';
import { santizeEmailAddresses } from './eventPropsHandlers/helpers';
import { Context } from '@vf/api-contract';
import { useCmsRefStore } from '../store/cmsRef';

type UseGtm = {
  initGtm(gtmId: string): void;
  dispatchEvent: (payload: EventProps, options?: DispachEventOptions) => void;
  dispatchErrorEvent: (eventName: string, error: Error) => void;
  dispatchEventAfter: (eventName: EventName, cb: () => void) => void;
  dispatchVideoEvent: (payload: VideoEventProps) => void;
  dispatchPageEvent: () => void;
  addGtmProductTrace(newArray): void;
  clearGtmAttributesStorage(productId?): void;
  getCartProductTrace(productId): EventPersistentVariables;
  deleteCartProductTrace(
    productId: string,
    persistentVariablesKeys: Array<keyof EventPersistentVariables>
  ): void;
  gtmPersistentVariables: Ref<EventPersistentVariables>;
  gtmProductTraceMap: Ref<EventPersistentVariablesMap>;
  getFormLocation: (contextKey: string) => string;
  updatePersistentVariables(
    productId: string,
    persistentVariables: EventPersistentVariables
  ): EventPersistentVariables;
};

type DispachEventOptions = {
  clearDataLayer: boolean;
};

type SubscribedEvents = Record<EventName, Array<() => void>>;

type UseGtmStorage = {
  gtmPersistentVariables: Ref<EventPersistentVariables>;
  gtmCartProductsTraceMap: Ref<EventPersistentVariablesMap>;
  gtmProductTraceMap: Ref<EventPersistentVariablesMap>;
  gtmSubscribedEvents: SubscribedEvents;
};

/**
 * Composable for interactions with Google Tag Manager.
 * @param {ComponentInstance} instance root vue instance
 */
const useGtm = (instance: ComponentInstance): UseGtm => {
  const cmsRefStore = useCmsRefStore();
  /**
   * Initialize gtm script.
   * To be called on client side only when user gives consent for tracking.
   * @param {string} gtmId
   */
  function initGtm(gtmId: string) {
    if (instance.$isServer) {
      throw new Error('Trying to initialize GTM in server side.');
    } else {
      instance.$gtm.init(gtmId);
    }
  }
  /**
   * Composable storage for Google Tag Persistent Variables.
   */
  const storage: ComposablesStorage<UseGtmStorage> = initStorage<UseGtmStorage>(
    instance,
    'useGtm'
  );

  const gtmProductTraceMap: Ref<EventPersistentVariablesMap> =
    storage.get('gtmProductTraceMap') ??
    storage.save('gtmProductTraceMap', ssrRef({}, 'gtmProductTraceMap'));

  const gtmCartProductsTraceMap: Ref<EventPersistentVariablesMap> =
    storage.get('gtmCartProductsTraceMap') ??
    storage.save(
      'gtmCartProductsTraceMap',
      ssrRef(
        JSON.parse(ls.getItem('gtmCartProductsTraceMap') || '{}'),
        'gtmCartProductsTraceMap'
      )
    );

  const gtmPersistentVariables: Ref<EventPersistentVariables> =
    storage.get('gtmPersistentVariables') ??
    storage.save(
      'gtmPersistentVariables',
      ssrRef(
        JSON.parse(ls.getItem('gtmPersistentVariables') || '{}'),
        'gtmPersistentVariables'
      )
    );

  const subscribedEvent: SubscribedEvents = storage.getOrSave(
    'gtmSubscribedEvents',
    {} as SubscribedEvents
  );

  const getCartProductTrace = (productId: string) => {
    return gtmCartProductsTraceMap.value[productId] || {};
  };

  const deleteCartProductTrace = (
    productId: string,
    persistentVariablesKeys: Array<keyof EventPersistentVariables>
  ) => {
    if (!gtmCartProductsTraceMap.value[productId]) return;

    persistentVariablesKeys.forEach((key) => {
      delete gtmCartProductsTraceMap.value[productId][key];
    });

    ls.setItem(
      'gtmCartProductsTraceMap',
      JSON.stringify(gtmCartProductsTraceMap.value)
    );
  };

  const addGtmProductTrace = (productId: string) => {
    if (!gtmProductTraceMap.value[productId]) return;
    gtmCartProductsTraceMap.value[productId] =
      gtmProductTraceMap.value[productId];
    ls.setItem(
      'gtmCartProductsTraceMap',
      JSON.stringify(gtmCartProductsTraceMap.value)
    );
  };

  const updatePersistentVariables = (productId: string, properties) => {
    const result = {
      ...gtmPersistentVariables.value,
      ...properties,
    };
    gtmProductTraceMap.value[productId] = result;
    ls.setItem(
      'gtmPersistentVariables',
      JSON.stringify(gtmPersistentVariables.value)
    );
    return result;
  };

  const clearGtmAttributesStorage = (productId?: string) => {
    if (!gtmCartProductsTraceMap.value[productId]) return;
    if (!productId) ls.removeItem('gtmCartProductsTraceMap');
    delete gtmCartProductsTraceMap.value[productId];
    ls.setItem(
      'gtmCartProductsTraceMap',
      JSON.stringify(gtmCartProductsTraceMap.value)
    );
    !productId && ls.setItem('gtmPersistentVariables', JSON.stringify('{}'));
  };

  const getFormLocation = (contextKey: Context): string => {
    const formLocationsDictionary = {
      [Context.Modal]: 'modal:single:none',
      [Context.PageContent]: 'inline:body:none',
    };
    const formLocation = formLocationsDictionary[contextKey];
    if (!formLocation) {
      return '';
    }
    return formLocation;
  };

  const dispatchEvent = (props: EventProps) => {
    /**
     * Saving persistent variables passed from EventProps invoking dispatch in component.
     */
    if (props.persistentVariables) {
      gtmPersistentVariables.value = {
        category: props.persistentVariables.category,
        ...(props.persistentVariables.searchTerm && {
          searchTerm: santizeEmailAddresses(
            props.persistentVariables.searchTerm
          ),
        }),
      };
    }
    try {
      const handler = eventPropsHandlers.get(props.eventName);
      const events = handler?.(props, instance) ?? {
        event: props.eventName,
        customMetrics: {},
        customVariables: props.overrideAttributes || {},
      };
      [events].flat().forEach((event) => instance.$gtm.push(event));

      if (props.eventName === 'loadUserData') {
        dispatchEvent({
          eventName: 'launchDarklyActiveExperiences',
        });
      }
    } catch (err) {
      dispatchErrorEvent(props.eventName, err);
      instance.$log.error(
        `[@useGtm/index::dispatchEvent] [GTM] Failed to handle '${props.eventName}' event. Check handlers and event payload.`,
        err
      );
    }

    checkAndCallSubscribeEvent(props.eventName);
  };

  const checkAndCallSubscribeEvent = (eventName: EventName) => {
    if (subscribedEvent[eventName]?.length) {
      subscribedEvent[eventName].forEach((cb) => cb());
    }
    subscribedEvent[eventName] = [];
  };

  const dispatchEventAfter = (eventName: EventName, cb: () => void) => {
    if (subscribedEvent[eventName] === undefined) {
      subscribedEvent[eventName] = [];
    }
    subscribedEvent[eventName].push(cb);
  };

  const dispatchErrorEvent = (eventName: EventName, error: Error) => {
    instance.$gtm.push({
      event: 'loadEventData',
      eventCategory: 'ERRORS',
      eventAction: 'UI',
      eventLabel: error.message,
      nonInteractive: true,
      customMetrics: {
        error: 1,
      },
      customVariables: {
        errorMessage: error.message,
        errorOrigin: 'UI',
        errorDetail: `Failed to handle '${eventName}' event.`,
      },
      _clear: true,
    });
  };

  const dispatchVideoEvent = (props) => {
    dispatchEvent({
      eventName: 'loadEventData',
      overrideAttributes: {
        eventCategory: props.eventCategory || 'Video',
        eventAction: props.eventAction,
        eventLabel: props.name,
        nonInteractive: props.nonInteractive,
        customMetrics: {
          videoDuration: props.videoDuration,
          videoCurrentTime: props.videoCurrentTime,
        },
      },
    });
  };

  /**
   * Aims to detect the page type based on pageTypeName and then dispatch appropriate
   * event that will hold attributes related to that page being just loaded.
   */
  const dispatchPageEvent = (): void => {
    const { totalItems } = useCart(instance);

    switch (cmsRefStore.pageTypeName) {
      case PageTypeName.CART:
        dispatchEventAfter('virtualPageView', () => {
          dispatchEvent({
            eventName: 'checkout',
            overrideAttributes: {
              step:
                totalItems.value > 0
                  ? CheckoutStepNumber.CART
                  : CheckoutStepNumber.CART_EMPTY,
            },
          });
        });
        break;
      // TODO: handle other page-load-related events here - GLOBAL15-1943
      default:
        break;
    }
  };

  return {
    initGtm,
    dispatchEvent,
    dispatchEventAfter,
    dispatchPageEvent,
    updatePersistentVariables,
    addGtmProductTrace,
    clearGtmAttributesStorage,
    getCartProductTrace,
    deleteCartProductTrace,
    dispatchVideoEvent,
    dispatchErrorEvent,
    getFormLocation,
    gtmPersistentVariables: computed(() => gtmPersistentVariables.value),
    gtmProductTraceMap: computed(() => gtmProductTraceMap.value),
  };
};

export default useGtm;
