export enum AnalyticsDataDomLocation {
  TopNavigation = 'top-nav',
  UtilityNavigation = 'utility-nav',
  FooterNavigation = 'footer-nav',
  BreadcrumbNavigationLink = 'breadcrumb-nav',
  FeaturedImage = 'featured-image',
  FeaturedImageWithOverlay = 'featured-image-with-overlay',
  FeaturedVideo = 'featured-video',
  FeaturedVideoWithOverlay = 'featured-video-with-overlay',
  Article = 'article',
  ShopBySectionLink = 'shop-by-section-link',
  StickyHeader = 'category-header-link',
}

export type FilterOption = {
  id: string;
  label: string;
  items?: number;
};
