import { CMComponent, CmsSite, VFConfigComponent } from '@vf/api-contract';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../../../types';
import * as mappings from '../../../mappings';
import * as plainMappings from '../../../mappings/plain';
import { camelCase } from '../camelCase';
import { applyDecorators } from '../decorators';
import { getGeminiTag } from './flexibleContentByPath';

/**
 * Factory method to handle component data parsing basing on "viewtype" parameter in CMS components.
 * viewtype item parameters is unique on CMS level.
 * @param siteConfiguration
 * @param context
 * @param cmsBaseUri
 * @param requirements
 */
export const standardComponentDataParser = (
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => (itemData: Exclude<CMComponent, VFConfigComponent>) => {
  let mappingMethod;

  // Some components from CoreMedia don't have a viewtype, but we still want them to previewable
  if (!itemData?.viewtype && itemData?.type && plainMappings[itemData.type]) {
    mappingMethod = plainMappings[itemData.type];
  } else {
    if (!itemData?.viewtype) {
      return null;
    }

    //Flexible means gemini components https://digital.vfc.com/jira/browse/GLOBAL15-54743
    const isFlexibleComponent = itemData.viewtype.startsWith('flexible');

    const viewType = camelCase(
      isFlexibleComponent ? getGeminiTag(itemData.viewtype) : itemData.viewtype
    );

    if (!mappings[viewType]) {
      throw new Error(
        `There is no mapping for this component-type: ${itemData.viewtype} | id: ${itemData.id} | name: ${itemData.name}`
      );
    }

    mappingMethod = mappings[viewType];
  }

  if (mappingMethod) {
    const mappedData = mappingMethod(
      itemData,
      context,
      siteConfiguration,
      cmsBaseUri,
      requirements,
      pageTypeName
    );

    return applyDecorators(
      [
        'StateManagement',
        'ComponentCmsIdDecorator',
        'SegmentsDecorator',
        'DataIdDecorator',
        'DataTrackingDecorator',
      ],
      itemData,
      mappedData
    );
  }

  return null;
};
