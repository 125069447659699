import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { ApiClientConfiguration } from '../../configuration';

export const changePassword = (configuration: ApiClientConfiguration) => (
  data,
  { usid = null }: RequestHeader = {}
): Promise<any> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).customer.changePassword, {
      config: configuration,
    }),
    {
      password: data.password,
      previousPassword: data.previousPassword,
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
