//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'VfFooterColumn',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isCollapsed = ref(true);
    const transformedTitle = props.title.toLowerCase().replace(' ', '-') + '_';

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const toggle = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    return {
      controlTitle: 'control_' + transformedTitle,
      isCollapsed,
      transformedTitle,
      toggle,
      isCoreRedesignEnabled,
    };
  },
});
