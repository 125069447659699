import { computed, ComputedRef, Ref } from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { useI18n } from '../../../useI18n';
import ls from '../../../utils/localStorage';
import { prepareLocale } from '../../../utils/query';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { isBasketNotFoundError } from '../../helpers';
import { useCartStore } from '../../../store/cartStore';

export const usePickup = (
  instance,
  {
    cartId,
    cartItems,
    refreshCart,
    updateCart,
    isPatchCallRequired,
  }: {
    cartId: Ref<string>;
    cartItems: ComputedRef<any>;
    updateCart: (data: any) => void;
    refreshCart: () => void;
    isPatchCallRequired: (path: string) => boolean;
  }
) => {
  const { getCartObject: getCartObjectAPI } = apiClientFactory(instance);
  const { localeCode } = useI18n(instance);
  const { isBopisEnabled } = useFeatureFlagsStore();

  const itemsToShip = computed(() => {
    return isBopisEnabled
      ? cartItems.value.filter(
          (item) =>
            !item.shippingOptions ||
            item.shippingOptions.find(
              (option) => option.selected && !option.storeInfo
            )
        ) ?? []
      : cartItems.value;
  });

  const itemsForPickup = computed(() => {
    return isBopisEnabled
      ? cartItems.value.filter((item) => {
          return item.shippingOptions?.find((option) => {
            return option.selected && option.storeInfo;
          });
        })
      : [];
  });

  const hasPickupItems = computed(() => {
    return isBopisEnabled ? itemsForPickup.value.length > 0 : false;
  });

  const isPickupItem = (cartitemId: string): boolean => {
    return itemsForPickup.value.some((item) => item.id === cartitemId);
  };

  const isShipmentPickup = (shipmentId: string): boolean => {
    return itemsForPickup.value.some((item) => item.shippingId === shipmentId);
  };

  const updateCartForBopis = async () => {
    if (!cartId.value) return refreshCart();
    const favoriteStoreId = ls.getItem('favoriteStoreId');
    const query: string[] = [prepareLocale(localeCode())];
    if (favoriteStoreId) query.push(`favStoreId=${favoriteStoreId}`);
    if (isPatchCallRequired(window.location.pathname))
      query.push('isPatchRequired=true');
    try {
      const response = await getCartObjectAPI(cartId.value, query.join('&'));
      await updateCart(response.data);
    } catch (error) {
      if (isBasketNotFoundError(error.response?.data)) {
        useCartStore().appendCartNotFoundFlash(
          error.response.data.errorDetails
        );
        refreshCart();
      }
    }
  };

  return {
    itemsToShip,
    itemsForPickup,
    hasPickupItems,
    isPickupItem,
    isShipmentPickup,
    updateCartForBopis,
  };
};
