import { ref, Ref } from '@vue/composition-api';
import ls from '../../../utils/localStorage';
import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { UseAuthenticationStorage } from '../../types';
import { syncStorageWithLocalStorage } from '../../utils';

export const useSaveForLater = (instance) => {
  const storage: ComposablesStorage<UseAuthenticationStorage> = initStorage<UseAuthenticationStorage>(
    instance,
    'useAuthentication'
  );

  const setLSProperty = syncStorageWithLocalStorage(storage);

  const saveForLaterId: Ref<string> =
    storage.get('saveForLaterId') ??
    storage.save('saveForLaterId', ref(ls.getItem('saveForLaterId') || ''));

  const setSaveForLaterId = (saveForLaterIdentity: string = '') => {
    setLSProperty('saveForLaterId', saveForLaterIdentity);
  };

  return {
    setSaveForLaterId,
    saveForLaterId,
  };
};
