import { CMImageBlockWithOverlay, CmsSite } from '@vf/api-contract';
import {
  getImageObject,
  extractProperty,
  getTeaserTargets,
  extractComponentStyleDefinitions,
} from './utils';
import { ComposableContext } from '../types';
import { getResponsiveMediaPictureObject } from './utils/getImageObject';

export const easyImageBlockWithOverlay = (
  data: CMImageBlockWithOverlay,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const baseImage = getImageObject(
    extractProperty(data, 'pictures[0]'),
    siteConfiguration,
    null,
    cmsBaseUri,
    data.responsiveMedia
  );
  const mediumImage = getResponsiveMediaPictureObject(
    extractProperty(data, 'pictures[0]'),
    'medium',
    data.responsiveMedia,
    0
  );
  const largeImage = getResponsiveMediaPictureObject(
    extractProperty(data, 'pictures[0]'),
    'large',
    data.responsiveMedia,
    0
  );

  const hasImage = !!(baseImage.small || baseImage.medium || baseImage.large);

  const { ctas, mediaTarget } = getTeaserTargets(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );

  return {
    component: 'shared/ContentImageWithHoverOverlay',
    props: {
      media: {
        ...(hasImage && {
          image: {
            large: baseImage.large,
            medium: baseImage.medium,
            small: baseImage.small,
            alt: baseImage.alt,
            title: baseImage.title,
            sizes: {
              small: {
                width: baseImage.width,
                height: baseImage.height,
              },
              medium: {
                width: mediumImage.width,
                height: mediumImage.height,
              },
              large: {
                width: largeImage.width,
                height: largeImage.height,
              },
            },
          },
        }),
      },
      title: extractComponentStyleDefinitions(data, 'teaserTitle'),
      bodyText: extractComponentStyleDefinitions(data, 'teaserText'),
      ctas,
      mediaTarget,
    },
  };
};
