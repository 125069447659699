import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTranslation, generateLinkFromTeaserTargets } from './utils';

interface HomeAddressBlock {
  component: string;
  attrs?: Record<string, unknown>;
  props: Record<string, unknown>;
}

export const maHomeAddressBlock = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
): HomeAddressBlock => {
  return {
    component: 'account/AddressSummary',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Address Book$'),
        text: getTranslation(data, 'text', '$Default Address:$'),
        textNoAddress: getTranslation(
          data,
          'textNoAddress',
          '$You have no address$'
        ),
        ctaText: getTranslation(data, 'ctaText', '$View Addresses$'),
        ctaTextNoAddress: getTranslation(
          data,
          'ctaNoAddressText',
          '$Add Address$'
        ),
        ctaLink: generateLinkFromTeaserTargets(
          data.teaserTargets,
          context,
          siteConfiguration,
          data.urlParams,
          cmsBaseUri
        ),
        nameLabel: getTranslation(data, 'nameLabel', '$Name:$'),
        phoneLabel: getTranslation(data, 'phoneLabel', '$Phone:$'),
        addressLabel: getTranslation(data, 'addressLabel', '$Address:$'),
      },
    },
  };
};
