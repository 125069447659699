import { CMTeaser, CmsSite, CMPictureCropType } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  getDynamicField,
  getTranslation,
  getImageObject,
  getMediaSeoAttributes,
} from './utils';

export const customsProductSlide = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  carouselParams?: { controlsPosition: string; isFullHeight: boolean }
) => {
  const pictures = getPictures(data, siteConfiguration, cmsBaseUri);
  return {
    component: 'carousel/CustomsProductSlide',
    props: {
      title: extractProperty(data, 'teaserTitle', ''),
      subtitle: extractProperty(data, 'teaserSubtitle', ''),
      location: getDynamicField(data, 'location', ''),
      authorName: getDynamicField(data, 'authorName', ''),
      recipeId: getDynamicField(data, 'recipeId', ''),
      defaultPicture: extractProperty(pictures, 'imagesObject[0]', {}),
      translations: {
        buyCta: getTranslation(data, 'buyCTA', '${{price}} Buy$'),
        inspiredBy: getTranslation(data, 'inspiredBy', '$Inspired By$'),
        editDesignCTA: getTranslation(data, 'editDesignCTA', '$Edit Design$'),
        noProductFound: getTranslation(
          data,
          'noProductFound',
          '$Sorry, there was a problem loading this product.$'
        ),
        accessibility: getTranslation(data, 'accessibility', {
          carouselSlideDescription: '$Product Slide.$',
        }),
      },
      controlsPosition: carouselParams?.controlsPosition ?? 'bottom',
      isFullHeight: carouselParams?.isFullHeight ?? true,
      hideBuyCTA: getDynamicField(data, 'hideBuyCTA', false),
    },
  };
};

const getPictures = (data, siteConfiguration, cmsBaseUri) => {
  if (data.pictures?.length) {
    const imagesObject = data.pictures.map((picture, index) => {
      return {
        ...getImageObject(
          picture,
          siteConfiguration,
          CMPictureCropType.LANDSCAPE_RATIO_8x3,
          cmsBaseUri,
          data.responsiveMedia,
          index
        ),
        seo: getMediaSeoAttributes(picture),
        alt: '',
        width: null,
        height: null,
      };
    });
    return {
      imagesObject,
    };
  }
};
