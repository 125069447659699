
























import { defineComponent, PropType } from '@vue/composition-api';

interface BadgeImgMap {
  large: string;
  medium: string;
  small: string;
}

interface Badge {
  src?: BadgeImgMap;
  alt: string;
}

export default defineComponent({
  name: 'VfProductBadge',
  props: {
    badges: {
      type: Array as PropType<Array<Badge>>,
      default: () => [],
    },
    /** Optional value for the fetch priority attribute for images */
    imageFetchPriority: {
      type: String,
      default: null,
    },
  },
});
