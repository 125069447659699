/**
 * Function to get value based on two conditions.
 * @returns {string} Modified string.
 * @param conditionA {boolean} First condition used for resolving.
 * @param conditionB {boolean} Second condition used for resolving.
 * @param aAndBValueAndBValue {T} Value returned if `conditionA` and `conditionB` are true
 * @param onlyAValue {T} Value returned if `conditionA` is true
 * @param onlyBValue {T} Value returned if `conditionB` is true
 * @param notAOrBValue {T} Value returned if `conditionA` and `conditionB` are false
 */
export const resolveTwoConditions = <T>(
  conditionA: boolean,
  conditionB: boolean,
  aAndBValueAndBValue: T,
  onlyAValue: T,
  onlyBValue: T,
  notAOrBValue: T
): T => {
  if (conditionA && conditionB) return aAndBValueAndBValue;
  else if (conditionA) return onlyAValue;
  else if (conditionB) return onlyBValue;
  return notAOrBValue;
};
