export function maskGiftCardNumber(
  cardNumber: string,
  unmaskedLength = 4,
  maskCharacter = '*'
): string {
  /** Wrapped in try catch block to deal with empty cardNumber strings or undefined values coming from API */
  try {
    const lastFiveSigns = cardNumber.slice(cardNumber.length - unmaskedLength);
    return (
      maskCharacter.repeat(cardNumber.length - unmaskedLength) + lastFiveSigns
    );
  } catch (e) {
    return cardNumber ?? '';
  }
}

export function formatGiftCardNumber(cardNumber: string): string {
  const chunks = [];
  const len = cardNumber.length;

  for (let i = 0; i < len; i += 4) {
    chunks.push(cardNumber.substr(i, 4));
  }

  return chunks.join('-');
}

export function getGiftCardFormValidationPatterns(data: {
  cardNumberLengths: number[];
  pinLengths: number[];
}): { cardNumber: string; pin: string } {
  return {
    cardNumber: getNumericPattern(data.cardNumberLengths),
    pin: getNumericPattern(data.pinLengths),
  };
}

/** Returns a numeric regex pattern to match number values with given characters length, examples:
 * input: [3, 8]
 * result: ^(\d{3}|\d{8})$
 */
const getNumericPattern = (val: number[]) => {
  return `^(${val.map((val) => `\\d{${val}}`).join('|')})$`;
};
