import { ref, Ref } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';

type RequestProps = {
  name: string;
  tag: symbol;
  ongoingRequest: Promise<void>;
  resolver: () => void;
};

type UseRequestTrackerStorage = {
  baseRequests: Ref<RequestProps[]>;
  backgroundRequests: Ref<RequestProps[]>;
  onBaseDoneCallbacks: Ref<(() => void)[]>;
  onAllDoneCallbacks: Ref<(() => void)[]>;
  timeoutId: Ref<NodeJS.Timeout>;
};

export const useRequestTracker = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UseRequestTrackerStorage> = initStorage<UseRequestTrackerStorage>(
    instance,
    'useRequestTracker'
  );

  const baseRequests =
    storage.get('baseRequests') ?? storage.save('baseRequests', ref([]));

  const backgroundRequests =
    storage.get('backgroundRequests') ??
    storage.save('backgroundRequests', ref([]));

  const onBaseDoneCallbacks =
    storage.get('onBaseDoneCallbacks') ??
    storage.save('onBaseDoneCallbacks', ref([]));

  const onAllDoneCallbacks =
    storage.get('onAllDoneCallbacks') ??
    storage.save('onAllDoneCallbacks', ref([]));

  const timeoutId =
    storage.get('timeoutId') ?? storage.save('timeoutId', ref(null));

  const onBaseDone = (cb: () => void) => onBaseDoneCallbacks.value.push(cb);

  const onAllDone = (cb: () => void) => onAllDoneCallbacks.value.push(cb);

  const trackRequest = <T>(
    name: string,
    isBackgroundRequest = false
  ): {
    tag: symbol;
    isAlreadyTracked: boolean;
    ongoingRequest: Promise<T>;
  } => {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }

    const arrayStorage = isBackgroundRequest
      ? backgroundRequests
      : baseRequests;

    const requestTag = Symbol(name);
    const firstTrackedRequest = arrayStorage.value.find(
      (req) => req.name === name
    );

    let resolver;
    const ongoingRequest = new Promise<T>((resolve) => {
      resolver = resolve;
    });

    arrayStorage.value.push({
      name,
      tag: requestTag,
      ongoingRequest: (firstTrackedRequest?.ongoingRequest ||
        ongoingRequest) as Promise<void>,
      resolver: firstTrackedRequest?.resolver || resolver,
    });
    return {
      tag: requestTag,
      isAlreadyTracked: !!firstTrackedRequest,
      ongoingRequest: (firstTrackedRequest?.ongoingRequest ||
        ongoingRequest) as Promise<T>,
    };
  };

  const callEveryCallbackAndClear = (list: (() => void)[]): void => {
    list.forEach((cb) => cb());
    list.length = 0;
  };

  const isRequestArrayEmpty = (array: Ref<RequestProps[]>): boolean => {
    return !array.value.length;
  };
  const checkAvailableRequests = () => {
    if (isRequestArrayEmpty(baseRequests)) {
      callEveryCallbackAndClear(onBaseDoneCallbacks.value);

      if (isRequestArrayEmpty(backgroundRequests)) {
        callEveryCallbackAndClear(onAllDoneCallbacks.value);
      }
    }
  };

  const setTimedActions = () => {
    timeoutId.value = setTimeout(() => {
      checkAvailableRequests();
    }, 500);
  };

  const clearRequest = (
    requestTag: symbol,
    isBackgroundRequest = false
  ): void => {
    const targetRef = isBackgroundRequest ? backgroundRequests : baseRequests;
    const [request] = targetRef.value.splice(
      targetRef.value.findIndex((req) => req.tag === requestTag),
      1
    );
    request?.resolver();
    setTimedActions();
  };

  const startTracking = () => setTimedActions();

  const getOngoingRequest = (requestName: string) => {
    const ongoingRequest = [
      ...baseRequests.value,
      ...backgroundRequests.value,
    ].find(({ name }) => name === requestName)?.ongoingRequest;
    return ongoingRequest || Promise.resolve();
  };

  const isRequestOngoing = (requestName: string) => {
    return [...baseRequests.value, ...backgroundRequests.value].some(
      ({ name }) => name === requestName
    );
  };

  return {
    clearRequest,
    getOngoingRequest,
    onBaseDone,
    onAllDone,
    startTracking,
    trackRequest,
    isRequestOngoing,
  };
};
