import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const flagReview = (configuration: ApiClientConfiguration) => async (
  productId: string,
  reviewId: number,
  body: unknown
): Promise<any> => {
  const url = urlBuilder(endpoints.get(configuration).product.flagReview, {
    config: configuration,
    mapping: {
      productId,
      reviewId: String(reviewId),
    },
  });

  const response = await axios.post(url, body, {
    headers: headerBuilder(configuration),
  });
  return response;
};
