import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import {
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
} from './utils';
import { ComposableContext } from '../types';

export const storesList = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const fileUrl =
    generateLinkFromTarget(
      extractLocalSettingsExpandedLinks(data, 'storesList')[0],
      context,
      siteConfiguration,
      cmsBaseUri
    ) || '';

  const redirectPath =
    generateLinkFromTarget(
      extractLocalSettingsExpandedLinks(data, 'redirectPath')[0],
      context,
      siteConfiguration,
      cmsBaseUri
    ) || '';

  return {
    component: 'buyInStore/StoresList',
    props: {
      storesListCsvPath: fileUrl,
      redirectPath: redirectPath,
    },
  };
};
