import { CMCollection, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  generateLinkFromTeaserTargets,
  getStateManagementData,
  getSegmentsData,
} from './utils';

export const maNavigation = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const navigationItems = getNavigationData(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );
  return {
    component: 'account/AccountSubNavigation',
    props: {
      items: navigationItems,
    },
  };
};

const getNavigationData = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const items = data.teasableItems.map((item) => {
    return {
      id: item.id,
      name: item.title,
      url: generateLinkFromTeaserTargets(
        item.teaserTargets,
        context,
        siteConfiguration,
        item.urlParams,
        cmsBaseUri
      ),
      _states: getStateManagementData(item),
      _segments: getSegmentsData(item),
    };
  });
  return items;
};
