import { extractProperty, generateLinkFromTarget } from '../utils';
import * as mappings from '../index';
import { getGeminiTag } from '../../dataTransformators/utils/components/flexibleContentByPath';
import { ComposableContext } from '../../types';
import { CmsSite } from '@vf/api-contract';

export const geminiSectionGrid = (
  data: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const childComponents = data.sectionGridContent.map((item) => {
    if (!item.viewType) {
      return;
    }
    const mappingMethod = mappings[getGeminiTag(item.viewType)];
    const mapping = mappingMethod(item, context, siteConfiguration, cmsBaseUri);

    return {
      ...mapping,
      props: {
        ...mapping.props,
      },
    };
  });

  const ctaUrl = extractProperty(data, 'ctaUrl1[0]');

  return {
    component: 'gemini/GeminiSectionGrid',
    attrs: {
      'data-id': data.id,
    },
    props: {
      pattern: extractProperty(data, 'cssPattern', ''),
      wrapper: extractProperty(data, 'wrapper', ''),
      heading: extractProperty(data, 'heading1', ''),
      copy: extractProperty(data, 'copy1', ''),
      ctaCopy: extractProperty(data, 'ctaCopy1', ''),
      ctaUrl: ctaUrl
        ? generateLinkFromTarget(ctaUrl, context, siteConfiguration, cmsBaseUri)
        : '',
      ctaHasIcon: extractProperty(data, 'ctaHasIcon1', false),
      gridProps: {
        gap: extractProperty(data, 'gridGap', null),
        desktop: extractProperty(data, 'gridColumnsDesktop', null),
        tablet: extractProperty(data, 'gridColumnsTablet', null),
        mobile: extractProperty(data, 'gridColumnsMobile', null),
      },
    },
    children: childComponents,
  };
};
