import { ref, computed, Ref } from '@vue/composition-api';
import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import {
  UseNotificationStorage,
  PasswordChangeNotification,
} from '../../types';

export const usePasswordChangeNotification = (instance) => {
  const storage: ComposablesStorage<UseNotificationStorage> = initStorage<UseNotificationStorage>(
    instance,
    'useNotification'
  );

  const passwordChangeNotification: Ref<PasswordChangeNotification> =
    storage.get('passwordChangeNotification') ??
    storage.save(
      'passwordChangeNotification',
      ref({ isShown: false, message: '' })
    );

  const setPasswordChangeNotification = (
    value: Partial<PasswordChangeNotification>
  ) => {
    passwordChangeNotification.value = {
      isShown: false,
      message: '',
      ...value,
    };
  };

  const showChangePassLinkExpiredNotification: Ref<boolean> =
    storage.get('showChangePassLinkExpiredNotification') ??
    storage.save('showChangePassLinkExpiredNotification', ref(false));

  const setChangePassLinkExpiredNotification = (value: boolean) => {
    showChangePassLinkExpiredNotification.value = value;
  };

  const hasChangePasswordNotification: Ref<boolean> =
    storage.get('hasChangePasswordNotification') ??
    storage.save('hasChangePasswordNotification', ref(false));

  const setChangePasswordNotification = (value: boolean) => {
    hasChangePasswordNotification.value = value;
  };

  return {
    passwordChangeNotification: computed(
      () => passwordChangeNotification.value
    ),
    setPasswordChangeNotification,
    showChangePassLinkExpiredNotification: computed(
      () => showChangePassLinkExpiredNotification.value
    ),
    setChangePassLinkExpiredNotification,
    hasChangePasswordNotification: computed(
      () => hasChangePasswordNotification.value
    ),
    setChangePasswordNotification,
  };
};
