import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const checkoutPickupInfo = (data: CMPlaceholder) => {
  return {
    component: 'checkout/CheckoutPickupInfo',
    props: {
      translations: {
        pickupHeader: getTranslation(
          data,
          'pickupHeader',
          '$Pickup information$'
        ),
        altPickupHeader: getTranslation(
          data,
          'altPickupHeader',
          '$Add alternate pickup person$'
        ),
        infoLabel: getTranslation(
          data,
          'infoLabel',
          '$You can name a friend or a family member.$'
        ),
        firstNameLabel: getTranslation(data, 'firstNameLabel', '$First Name$'),
        lastNameLabel: getTranslation(data, 'lastNameLabel', '$Last Name$'),
        emailLabel: getTranslation(data, 'emailLabel', '$Email$'),
        emailHelperText: getTranslation(
          data,
          'emailHelperText',
          '$To send you updates about your order.$'
        ),
        newsletterConsentLabel: getTranslation(
          data,
          'newsletterConsentLabel',
          '$Sign up for the latest Vans news, events and offers$'
        ),
        validationMessages: {
          default: getTranslation(data, 'validationMessages', {
            required:
              '$This field is required, please add your {{fieldName}}.$',
            minLength: '$This field must have a length no less than {{min}}.$',
            maxLength:
              '$This field must have a length no longer than {{max}}.$',
            pattern: '$Please match the requested format.$',
            email: '$Please enter a valid email address.$',
            fallback: '$Invalid value.$',
          }),
        },
        requiredFieldsInfo: getTranslation(
          data,
          'requiredFieldsInfo',
          '$*Required fields$'
        ),
        youArePickingUpTheseItemsHeader: getTranslation(
          data,
          'youArePickingUpTheseItemsHeader',
          '$You are picking up these items in store$'
        ),
      },
    },
  };
};
