import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { GetFavoritesResponse } from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const getFavorites = (
  configuration: ApiClientConfiguration
) => (): Promise<AxiosResponse<GetFavoritesResponse>> => {
  const url = urlBuilder(endpoints.get(configuration).wishList.getFavorites, {
    config: configuration,
  });
  return axios.get(url, {
    headers: headerBuilder(configuration),
  });
};
