import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { OrderDetails, RequestHeader } from '../../types';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const getOrderDetails = (configuration: ApiClientConfiguration) => (
  orderId: string,
  query: string,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<OrderDetails>> => {
  let url: string = urlBuilder(
    endpoints.get(configuration).customer.getOrderDetails,
    {
      config: configuration,
      mapping: { orderId },
    }
  );
  url = `${url}?${query}`;

  return axios.get(url, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
