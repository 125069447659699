import { useAccount, useValidation } from '@vf/composables';
import { nextTick } from '@nuxtjs/composition-api';
import { scrollToFirstError } from '@vf/shared/src/utils/helpers';
import { useUserInterfaceStore } from '../../../store/userInterface';
import { storeToRefs } from 'pinia';
import { useUserStore } from '../../../store/user';

export const useAddressFormValidations = (instance) => {
  const uiStore = useUserInterfaceStore();
  const userStore = useUserStore(instance);

  const { getAddresses } = useAccount(instance);
  const { $v } = useValidation(instance, 'ADDRESS_FORM');

  const { loggedIn } = storeToRefs(userStore);

  const validateBillingAddressForm = () => {
    $v.value?.$touch();
    if ($v.value?.$invalid) {
      nextTick(() => {
        scrollToFirstError();
      });
      return false;
    }
    return true;
  };

  const validateShippingAddressForm = () => {
    if (!loggedIn.value || getAddresses('S').value.length === 0) {
      $v.value?.$touch();
      if (!$v.value || $v.value.$invalid) {
        nextTick(() => {
          scrollToFirstError();
        });
        return false;
      }
    }
    return true;
  };

  const loadErrorMessage = () => {
    // TODO can we simplify this function?
    // TODO is this name correct?
    if (!loggedIn.value) {
      $v.value?.$touch();
      nextTick(() => {
        scrollToFirstError();
      });
    } else {
      scrollToFirstError();
    }
  };

  const openAddressValidationError = (formError) => {
    if (formError) {
      uiStore.shippingFormAccordion = true;
    }
    loadErrorMessage();
  };

  return {
    $v,
    validateBillingAddressForm,
    validateShippingAddressForm,
    loadErrorMessage,
    openAddressValidationError,
  };
};
