import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { SetGuestSmsSubscriptionPayload } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const setSubscriptionGuest = (configuration: ApiClientConfiguration) => (
  payload: SetGuestSmsSubscriptionPayload,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<any>> => {
  const url = urlBuilder(
    endpoints.get(configuration).customer.setSubscriptionGuest,
    {
      config: configuration,
    }
  );

  return axios.post(url, payload, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
