import { extractProperty } from './extractProperty';

export const extractLocalSettingsExpandedRichText = (
  item: any,
  target: string,
  defaultValue: string
) => {
  const localSettingsExpanded = extractProperty(
    item,
    'localSettingsExpanded',
    []
  );

  if (!Array.isArray(localSettingsExpanded)) {
    return defaultValue;
  }

  const dataSourcesObjects = localSettingsExpanded.find(
    (setting) => setting.path === `dynamicFields.${target}`
  );

  if (!dataSourcesObjects || !Array.isArray(dataSourcesObjects.richTexts)) {
    return defaultValue;
  }

  return extractProperty(dataSourcesObjects, 'richTexts[0]', defaultValue);
};
