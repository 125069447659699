import { axios, urlBuilder, headerBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ProductAdjustment } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const updatePriceAdjustment = (
  configuration: ApiClientConfiguration
) => async (
  id: string,
  priceAdjustmentId: string,
  body: ProductAdjustment
): Promise<any> => {
  const response = await axios.patch(
    urlBuilder(endpoints.get(configuration).cart.updatePriceAdjustments, {
      config: configuration,
      mapping: {
        id,
        priceAdjustmentId,
      },
    }),
    body,
    {
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
