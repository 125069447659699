import { storeToRefs } from 'pinia';
import { apiClientFactory } from '@vf/api-client';
import { ComponentInstance, ComposablesStorage } from '../types';
import { useRequestTracker } from '../useRequestTracker';
import {
  MonetateDecisionResponseBody,
  MonetateRecEvent,
  MonetateEventType,
} from '@vf/api-contract';
import { getCookieByName } from '../utils/cookie';
import initStorage from '../utils/storage';
import { computed, Ref } from '@vue/composition-api';
import { ssrRef } from '@nuxtjs/composition-api';
import { useAuthentication } from '../useAuthentication';
import useGtm from '../useGtm';
import { getMonetateEventsForDecisionRequest } from './helpers';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import { useUserStore } from '../store/user';

type MonetateLoadingStates =
  | 'initialized'
  | 'loading'
  | 'animateIn'
  | 'completed';

type UseMonetateStorage = {
  decision: Ref<MonetateDecisionResponseBody | null>;
  loading: Ref<MonetateLoadingStates>;
  hasLoaded: Ref<boolean>;
  previousRequestPath: Ref<string>;
  errored: Ref<boolean>;
  customVars: Ref<Record<string, string>>;
  monetateExperiences: Ref<Array<Record<string, number>>>;
  monetateCartCustomVars: Ref<Record<
    string,
    string | Record<string, string | number>
  > | null>;
};

type MonetateChannelConfig = {
  brand_src: string;
  environment: 'p' | 'd';
  id: string;
};

type MonetateDecisionRequestBody = {
  monetateId: string;
  channel: string;
  domain: string;
  customerId?: string;
  events: MonetateRecEvent[];
};

type CustomVars = Record<string, string | boolean>;

export const useMonetate = (
  instance: ComponentInstance,
  contextKey?: string
) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { getConsumerId } = useAuthentication(instance);
  const userStore = useUserStore(instance);
  const { loggedIn } = storeToRefs(userStore);
  const { dispatchEvent } = useGtm(instance);
  const { getExperienceVariantDecision } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseMonetateStorage> = initStorage<UseMonetateStorage>(
    instance,
    `useMonetate`
  );

  const decision: Ref<MonetateDecisionResponseBody> =
    storage.get('decision') ??
    storage.save('decision', ssrRef(null, `useMonetate-decision`));

  const loading: Ref<MonetateLoadingStates> =
    storage.get('loading') ??
    storage.save(
      'loading',
      ssrRef('initialized' as MonetateLoadingStates, `useMonetate-loading`)
    );

  const errored: Ref<boolean> =
    storage.get('errored') ??
    storage.save('errored', ssrRef(false, `useMonetate-errored`));

  const customVars: Ref<CustomVars> =
    storage.get('customVars') ??
    storage.save('customVars', ssrRef({}, `useMonetate-customVars`));

  const hasLoaded: Ref<boolean> =
    storage.get('hasLoaded') ??
    storage.save('hasLoaded', ssrRef(false, `useMonetate-hasLoaded`));

  const previousRequestPath: Ref<string> =
    storage.get('previousRequestPath') ??
    storage.save(
      'previousRequestPath',
      ssrRef('', `useMonetate-previousRequestPath`)
    );

  const monetateExperiences: Ref<Array<Record<string, number>>> =
    storage.get('monetateExperiences') ??
    storage.save(
      'monetateExperiences',
      ssrRef([], `useMonetate-monetateExperiences`)
    );

  const monetateCartCustomVars: Ref<Record<
    string,
    string | Record<string, string | number>
  > | null> =
    storage.get('monetateCartCustomVars') ??
    storage.save(
      'monetateCartCustomVars',
      ssrRef(null, `useMonetate-monetateCartCustomVars`)
    );

  const addCustomVars = (newVars: CustomVars): void => {
    if (!newVars) return;
    customVars.value = {
      ...customVars.value,
      ...newVars,
    };
  };

  const getMonetateConfig = (): MonetateChannelConfig =>
    instance.$getEnvValueByCurrentLocale<any>('MONETATE_CHANNEL_CONFIG');

  const getMonetateEnvironment = (): 'd' | 'p' => {
    const { environment } = getMonetateConfig();
    return environment;
  };

  const getMonetateChannel = () => {
    const { id, brand_src, environment } = getMonetateConfig();
    return `${id}/${environment}/${brand_src}`;
  };

  const getMonetateScriptUrl = () => {
    const scriptUrl = instance.$env.MONETATE_SCRIPT_URL;
    const channel = getMonetateChannel();
    return scriptUrl.replace('{channel}', channel);
  };

  const getMonetateDecisionEndpointUrl = () => {
    const decisionEndpoint = instance.$env.MONETATE_DECISION_ENDPOINT;
    const { brand_src } = getMonetateConfig();
    return decisionEndpoint.replace('{retailerName}', brand_src);
  };

  const setHasLoaded = (hasLoadedBool: boolean) => {
    hasLoaded.value = hasLoadedBool;
  };

  // Monetate Storage will not persist on refresh
  // using localstorage for previous path and stored Decision response
  const getPreviousDecisionData = () => {
    if (!previousRequestPath.value) {
      previousRequestPath.value =
        localStorage.getItem('useMonetatePreviousRequestPath') || '';
    }
    if (!decision.value) {
      decision.value =
        JSON.parse(
          localStorage.getItem('useMonetatePreviousDecisionResponse')
        ) || null;
    }
  };

  const setPreviousDecisionValue = (val = null) => {
    if (!val) {
      localStorage.removeItem('useMonetatePreviousDecisionResponse');
    } else {
      localStorage.setItem(
        'useMonetatePreviousDecisionResponse',
        JSON.stringify(val)
      );
    }
  };

  const setPreviousRequestPath = (val = '') => {
    if (!val) {
      localStorage.removeItem('useMonetatePreviousRequestPath');
    } else {
      localStorage.setItem('useMonetatePreviousRequestPath', val);
    }
    previousRequestPath.value = val;
  };
  /** Send tracking events both to monetate and gtm */
  const sendRecImpressionsEvent = (
    products,
    startsFrom: number,
    endsWith: number,
    experienceId: string | number,
    title: string,
    list_type: string
  ) => {
    const recImpressionsProducts = products.slice(startsFrom, endsWith);
    const recImpressions = recImpressionsProducts.reduce((acc, current) => {
      acc.push(current.recToken);
      return acc;
    }, []);
    if (!recImpressionsProducts.length) return;
    sendMonetateEvents([
      {
        eventType: MonetateEventType.RecImpressions,
        recImpressions,
      },
    ]);

    dispatchEvent({
      eventName: 'productRecImpressions',
      overrideAttributes: {
        products: recImpressionsProducts,
        list: `${list_type}: ${title}`,
        experience: extractRecommendedAction(experienceId),
        list_type,
      },
    });
  };

  const getMonetateDecisionRequestBody = (
    monetateEvents: MonetateRecEvent[]
  ) => {
    const monetateDecisionRequestBody: MonetateDecisionRequestBody = {
      monetateId: getCookieByName('mt.v') || getCookieByName('monId'),
      channel: getMonetateChannel(),
      domain: window?.location?.hostname,
      events: monetateEvents,
    };

    if (loggedIn.value) {
      monetateDecisionRequestBody.customerId = getConsumerId.value;
    }

    return monetateDecisionRequestBody;
  };

  const sendMonetateEvents = (monetateEvents: MonetateRecEvent[]) => {
    getExperienceVariantDecision(
      getMonetateDecisionEndpointUrl(),
      getMonetateDecisionRequestBody(monetateEvents)
    );
  };

  const getExperienceDecision = async (
    forceDecision: boolean = false,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ): Promise<void> => {
    if (!process.client) return;

    getPreviousDecisionData();
    // If we request an experience on the same page twice, identify this request as already completed
    if (
      forceDecision ||
      previousRequestPath.value !== instance.$router.currentRoute.fullPath
    ) {
      setPreviousRequestPath(instance.$router.currentRoute.fullPath);
      hasLoaded.value = false;
    } else {
      if (decision.value) hasLoaded.value = true;
    }

    if (!hasLoaded.value) {
      decision.value = null;
      setPreviousDecisionValue();
      loading.value = 'animateIn';
      errored.value = false;

      const { tag, isAlreadyTracked } = trackRequest(
        'useMonetate-getExperienceDecision',
        isBackgroundRequest
      );
      if (isAlreadyTracked) {
        clearRequest(tag, isBackgroundRequest);
        return;
      }
      const monetateEvents = getMonetateEventsForDecisionRequest(
        instance,
        getMonetateEnvironment(),
        contextKey,
        customVars.value
      );

      try {
        const response = await getExperienceVariantDecision(
          getMonetateDecisionEndpointUrl(),
          getMonetateDecisionRequestBody(monetateEvents)
        );
        decision.value = response.data;
        if (response.data.meta?.monetateId) {
          instance.$cookies.set('monId', response.data.meta?.monetateId, {
            maxAge: 365 * 24 * 60 * 60,
          });
        }
        setPreviousDecisionValue(decision.value);
      } catch (err) {
        console.error('Could not execute getExperienceVariantDecision', err);
        errored.value = true;
      } finally {
        clearRequest(tag, isBackgroundRequest);
        loading.value = 'completed';
        hasLoaded.value = true;
        customVars.value = {};
      }
    } else {
      // there is nothing new being decisioned. the experince is already loaded
      loading.value = 'completed';
      hasLoaded.value = true;
    }
  };

  const debounceSendToGtm = debounce(() => {
    sendToGtm();
  }, 2000);

  const sendToGtm = () => {
    monetateExperiences.value.length &&
      dispatchEvent({
        eventName: 'monetateActiveExperiences',
        overrideAttributes: {
          actions: monetateExperiences.value,
        },
      });

    monetateExperiences.value = [];
  };

  const extractImpressionReportingDataForGtm = (experienceAction) => {
    const impressionReporting = experienceAction.impressionReporting[0];
    !monetateExperiences.value.find(
      (el) =>
        el.impressionReporting[0].experience_id ===
        impressionReporting.experience_id
    ) && monetateExperiences.value.push(experienceAction);
    debounceSendToGtm();
  };

  const extractRecommendedItems = (experienceId: string | number) => {
    if (!decision.value && process.client) {
      getPreviousDecisionData();
    }
    if (!decision.value || !experienceId) {
      return [];
    }

    try {
      const actions = decision.value.data.responses[0].actions;

      const experienceAction = actions.find(
        (act) =>
          act.impressionReporting[0].experience_id.toString() ===
          experienceId.toString()
      );

      if (experienceAction) {
        return experienceAction.items || [];
      }
    } catch (e) {
      console.warn('Could not extract recommended items', e);
      return [];
    }

    return [];
  };

  const extractRecommendedAction = (experienceId: string | number) => {
    if (!decision.value && process.client) {
      getPreviousDecisionData();
    }
    if (!decision.value || !experienceId) {
      return {};
    }

    try {
      const actions = decision.value.data.responses[0].actions;

      const experienceAction = actions.find(
        (act) =>
          act.impressionReporting[0].experience_id.toString() ===
          experienceId.toString()
      );
      return experienceAction || {};
    } catch (e) {
      console.warn('Could not extract recommended action', e);
      return {};
    }
  };

  return {
    extractRecommendedItems,
    getExperienceDecision,
    setLoading: (value: MonetateLoadingStates) => {
      loading.value = value;
    },
    monetateScriptUrl: getMonetateScriptUrl(),
    decision,
    loading: computed(() => loading.value),
    hasLoaded,
    errored: computed(() => errored.value),
    addCustomVars,
    getMonetateEnvironment,
    previousRequestPath,
    setHasLoaded,
    sendMonetateEvents,
    sendRecImpressionsEvent,
    extractImpressionReportingDataForGtm,
    monetateExperiences,
    monetateCartCustomVars,
    extractRecommendedAction,
  };
};

export default useMonetate;
