import { getDynamicField, getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const orderSuccess = (data: CMPlaceholder) => {
  return {
    component: 'confirmation/OrderSuccess',
    props: {
      showNotification: getDynamicField(data, 'show-notification', false),
      translations: {
        header: getTranslation(data, 'header', '$Thank you!$'),
        message: getTranslation(data, 'message', '$Order number:$'),
        addOrderToAccount: getTranslation(
          data,
          'addOrderToAccount',
          '$Yes, add this order$'
        ),
        emailHeader: getTranslation(
          data,
          'emailHeader',
          '$We found a customer account with the email address you entered$'
        ),
        orderAccountAssign: getTranslation(
          data,
          'orderAccountAssign',
          '$Would you like to add this order to that account?$'
        ),
        successMessage: getTranslation(
          data,
          'successMessage',
          '$Thank you! Your order has been added to your account.$'
        ),
        viewOrderHistory: getTranslation(
          data,
          'viewOrderHistory',
          '$View Order History$'
        ),
        notification: getTranslation(
          data,
          'notification',
          '$Your order has been placed.$'
        ),
        pickupDetails: {
          detailsInfoLabel: getTranslation(
            data,
            'pickupDetails',
            '$Pickup Details$'
          ),
          detailsIntroText: getTranslation(
            data,
            'pickupIntroText',
            '$We will send you a notification when your order is ready for pickup.$'
          ),
          detailsStepsTexts: getTranslation(data, 'pickupMessage', {
            1: '$Bring a government-issued ID and a copy of the receipt (email or paper are fine)$',
            2: '$Let a sales associate know you’re there to pick up your order.$',
            3: '$Feel free to try out the product before making the final purchase.$',
          }),
        },
      },
    },
  };
};
