

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'replaceAll' in String.prototype;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "string.prototype.replaceall"
        ], function (require) {
            const polyfill = require("string.prototype.replaceall");

            const install = (p) => p.shim();

            install(polyfill);

            resolve();
        });
    });
}