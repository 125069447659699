import { DOMParser } from 'xmldom';
import { ComposableContext } from '../../types';
import { CmsSite, CMSRichTextObject } from '@vf/api-contract';
import { generateLinkFromTeaserTargets } from './getLink';

export const decorateHtmlValue = (
  richTextData: CMSRichTextObject | null,
  cmsBaseUri: string,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  if (!richTextData) {
    return '';
  }

  try {
    const doc = new DOMParser().parseFromString(richTextData.html, 'text/html');
    const images = doc.getElementsByTagName('img');
    const links = doc.getElementsByTagName('a');

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const uriTemplate = image.getAttribute('data-uritemplate');
      if (uriTemplate.startsWith('/')) {
        const relativeUrl = uriTemplate.replace('/{cropName}/{width}', '');
        image.setAttribute('src', `${cmsBaseUri}${relativeUrl}`);
      } else image.setAttribute('src', uriTemplate);
    }

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const linkReference = link.getAttribute('data-href');
      if (linkReference) {
        const linkObject = richTextData.links.find(
          (l) => l.id === linkReference
        );

        if (linkObject) {
          link.setAttribute(
            'href',
            generateLinkFromTeaserTargets(
              [
                {
                  callToActionEnabled: false,
                  callToActionText: '',
                  target: linkObject.link,
                },
              ],
              context,
              siteConfiguration,
              linkObject.link.urlParams,
              cmsBaseUri
            )
          );
        }
      }
    }

    return doc.toString();
  } catch (e) {
    console.error(`Rich Text component mapping error: ${e.message}`);
  }

  return richTextData.html;
};
