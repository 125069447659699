import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { GetSaveForLaterResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const getSaveForLater = (
  configuration: ApiClientConfiguration
) => (): Promise<AxiosResponse<GetSaveForLaterResponse>> => {
  const url = urlBuilder(
    endpoints.get(configuration).wishList.getSaveForLater,
    {
      config: configuration,
    }
  );

  return axios.get(url, {
    headers: headerBuilder(configuration),
  });
};
