import { ref, Ref } from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { ComposablesStorage } from '../../../types';
import { ConsumerType, UseAuthenticationStorage } from '../../types';
import { syncStorageWithLocalStorage } from '../../utils';
import initStorage from '../../../utils/storage';
import ls from '../../../utils/localStorage';

export type customerGroups = 'ProsumersWranx' | 'ProsumersIPA' | string;

export const useConsumer = (instance) => {
  const storage: ComposablesStorage<UseAuthenticationStorage> = initStorage<UseAuthenticationStorage>(
    instance,
    'useAuthentication'
  );

  const setLSProperty = syncStorageWithLocalStorage(storage);

  // TODO this should have better typing should be union with allowed types
  const consumerType: Ref<ConsumerType> =
    storage.get('consumerType') ??
    storage.save(
      'consumerType',
      ref(ls.getItem('consumerType') as ConsumerType)
    );

  const isEmployee: Ref<boolean> =
    storage.get('isEmployee') ?? storage.save('isEmployee', ref(false));

  const consumerId: Ref<string> =
    storage.get('consumerId') ??
    storage.save('consumerId', ref(ls.getItem('consumerId')));

  const consumerNo: Ref<string> =
    storage.get('consumerNo') ??
    storage.save('consumerNo', ref(ls.getItem('consumerNo')));

  const customerGroups: Ref<customerGroups[]> =
    storage.get('customerGroups') ?? storage.save('customerGroups', ref([]));

  const setConsumerNo = (consumerIdentity: string) => {
    setLSProperty('consumerNo', consumerIdentity);
  };

  const setConsumerType = (value) => {
    setLSProperty('consumerType', value);
  };
  const setConsumerId = (value) => {
    setLSProperty('consumerId', value);
  };

  const getSegments = async () => {
    const { getSegments: getSegmentsAPI } = apiClientFactory(instance);

    try {
      const segments = await getSegmentsAPI();
      customerGroups.value = segments.data?.customerGroups || [];
    } catch (e) {
      instance.$log.error(e);
    }
  };

  return {
    setConsumerId,
    setConsumerType,
    consumerType,
    consumerId,
    consumerNo,
    customerGroups,
    setConsumerNo,
    getSegments,
    isEmployee,
  };
};
