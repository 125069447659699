import { defineStore, skipHydrate } from 'pinia';
import { getCurrentInstance, ref } from '@vue/composition-api';
import ls from '../utils/localStorage';

export const useUserStore = (instance?) => {
  // TODO we should use useContext here and this should be used only in components setup
  const currentInstance = getCurrentInstance();
  const localInstance = currentInstance?.proxy || instance;
  const siteId: string = localInstance.$getEnvValueByCurrentLocale(
    'API_SITE_ID'
  );

  return defineStore('user', () => {
    const isTokenCallRequired = (): boolean => {
      const accessExpCookie: string = instance.$cookies.get(
        `vfa_${siteId}_access_exp`
      );
      // TODO: We need the list of accepted sites from the brand. To add them to the env variables and update referrer check after that.
      return !usid.value || !accessExpCookie || !!document.referrer;
    };

    const hasValidRefreshToken = () => {
      const refreshExpCookie: string = instance.$cookies.get(
        `vfa_${siteId}_refresh_exp`
      );
      return !!refreshExpCookie;
    };

    const isCustomerLoggedIn = (
      { preventSideEffect } = { preventSideEffect: false }
    ): boolean => {
      // !! IMPORTANT USE isCustomerLoggedIn ONLY WHEN YOU REALLY NEED IT
      // in most cases you should use just loggedIn
      const cookie: string = instance.$cookies.get(`vfa_${siteId}_li`);
      const fallbackCookie = instance.$cookies.get(`vfa_${siteId}_rr_exp`);

      const logged = !!(cookie || fallbackCookie);

      if (!preventSideEffect) {
        loggedIn.value = logged;
      }

      return logged;
    };

    const loggedIn = skipHydrate(
      ref(isCustomerLoggedIn({ preventSideEffect: true }))
    );

    const usid = skipHydrate(ref(ls.getItem('usid')));

    const setUsid = (value) => {
      usid.value = value;
      ls.setItem('usid', value);
    };

    const isEmployeeDiscountTCAnswered = skipHydrate(
      ref(!!JSON.parse(ls.getItem('isEmployeeDiscountTCAnswered')))
    );

    const isEmployeeDiscountTCAccepted = skipHydrate(
      ref(!!JSON.parse(ls.getItem('isEmployeeDiscountTCAccepted')))
    );

    const athlete = ref<{ currentSpend: number; annualCap: number }>(null);

    const setEmployeeDiscountTCAcceptanceCriteria = ({
      accepted,
      answered,
    }) => {
      isEmployeeDiscountTCAccepted.value = accepted;
      isEmployeeDiscountTCAnswered.value = answered;
      ls.setItem('isEmployeeDiscountTCAccepted', accepted);
      ls.setItem('isEmployeeDiscountTCAnswered', answered);
    };

    const loyaltyEnrolledCookieName = `vfa_${localInstance.$getEnvValueByCurrentLocale(
      'API_SITE_ID'
    )}_lty`;

    const loyaltyEnrolled = skipHydrate(
      ref(localInstance.$cookies.get(loyaltyEnrolledCookieName) || false)
    );

    const setLoyaltyEnrolled = (value) => {
      loyaltyEnrolled.value = value;
      ls.setItem('loyaltyEnrolled', value);
    };

    return {
      hasValidRefreshToken,
      setLoyaltyEnrolled,
      setEmployeeDiscountTCAcceptanceCriteria,
      setUsid,
      isTokenCallRequired,
      isCustomerLoggedIn: skipHydrate(isCustomerLoggedIn),
      usid,
      loggedIn,
      isEmployeeDiscountTCAnswered,
      isEmployeeDiscountTCAccepted,
      loyaltyEnrolled,
      loyaltyEnrolledCookieName,
      athlete,
    };
  })();
};
