import { reactive, ref, Ref, UnwrapRef } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { apiClientFactory } from '@vf/api-client';
import { Field, Form } from '../types/utilities';
import { CountryCode, CountryCallingCode } from 'libphonenumber-js';
import { mapCountriesToPhoneInputCountries } from '../utils/mapCountriesToPhoneInputCountries';
import { useRequestTracker } from '@vf/composables';

export type Country = {
  name: string;
  code: string;
  fields: Field[];
};

export type PhoneInputCountry = {
  name: string;
  countryCode: CountryCode;
  countryCallingCode: CountryCallingCode;
};

type PhoneInputCountryStore = {
  [locale: string]: PhoneInputCountry[];
};

type Province = {
  name: string;
  code: string;
};

type ProvinceList = {
  postCodeRegEx?: string;
  provinces?: Province[];
};

type CountryStore = {
  [locale: string]: Country[];
};

type Provinces = {
  [country: string]: ProvinceList;
};

type ProvinceStore = {
  [locale: string]: Provinces;
};
type UseUtilities = {
  getCountryList(locale: string): Promise<Country[]>;
  getProvinceList(countryCode: string, locale: string): Promise<ProvinceList>;
  getPhoneInputCountryList(locale: string): Promise<PhoneInputCountry[]>;
  getI18n(countryCode: string, locale: string, formName: string): Promise<Form>;
  getI18nFallback(formName: string): Form;
  getCountry(): string;
  getStateCode(name: string, country: string): Promise<string>;
  getStateName(code: string, country: string): Promise<string>;
};

type UseUtilitiesStorage = {
  countries: Ref<CountryStore>;
  provinces: UnwrapRef<ProvinceStore>;
  phoneInputCountries: Ref<PhoneInputCountryStore>;
  i18n: Ref;
};

/**
 * Composable for utilities as coutry and province list
 * @param {ComponentInstance} instance root vue instance
 */
const useUtilities = (instance: ComponentInstance): UseUtilities => {
  const {
    getCountryList: getCountryListApi,
    getProvinceList: getProvinceListApi,
    getI18n: getI18nApi,
  } = apiClientFactory(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const storage: ComposablesStorage<UseUtilitiesStorage> = initStorage<UseUtilitiesStorage>(
    instance,
    'useUtilities'
  );

  const countries: Ref<CountryStore> =
    storage.get('countries') ?? storage.save('countries', ref({}));

  const provinces: UnwrapRef<ProvinceStore> =
    storage.get('provinces') ?? storage.save('provinces', reactive({}));

  const phoneInputCountries: Ref<PhoneInputCountryStore> =
    storage.get('phoneInputCountries') ??
    storage.save('phoneInputCountries', ref({}));

  const i18n: Ref = storage.get('i18n') ?? storage.save('i18n', ref({}));

  const getCountryList = async (userLocale: string): Promise<Country[]> => {
    const { tag, isAlreadyTracked, ongoingRequest } = trackRequest<Country[]>(
      'useUtilities-getCountryList'
    );
    const locale = fixLocaleCase(userLocale);
    if (countries.value[locale]) {
      clearRequest(tag);
      return countries.value[locale];
    }

    if (isAlreadyTracked) {
      await ongoingRequest;
      clearRequest(tag);
      return countries.value[locale];
    }

    try {
      countries.value[locale] = (await getCountryListApi(locale)).data;
    } catch (error) {
      instance.$log.error(
        `[@useUtilities/index::getCountryList] Unable to fetch.`,
        { error }
      );
    } finally {
      clearRequest(tag);
    }
    return countries.value[locale];
  };

  const getProvinceList = async (
    countryCode: string,
    userLocale: string
  ): Promise<ProvinceList> => {
    const {
      tag,
      isAlreadyTracked,
      ongoingRequest,
    } = trackRequest<ProvinceList>('useUtilities-getProvinceList');
    const locale = fixLocaleCase(userLocale);
    let provinceList = provinces[locale]?.[countryCode];

    if (!provinceList) {
      if (isAlreadyTracked) {
        await ongoingRequest;
        clearRequest(tag);
        return provinces[locale]?.[countryCode];
      }

      provinceList = (await getProvinceListApi(countryCode, locale)).data;

      if (!provinces[locale]) {
        provinces[locale] = {};
      }

      provinces[locale][countryCode] = provinceList;
    }

    clearRequest(tag);
    return provinceList;
  };

  const getPhoneInputCountryList = async (
    userLocale: string
  ): Promise<PhoneInputCountry[]> => {
    const locale = fixLocaleCase(userLocale);
    if (!phoneInputCountries.value[locale]) {
      const countryList = await getCountryList(userLocale);
      phoneInputCountries.value[locale] = mapCountriesToPhoneInputCountries(
        countryList
      );
    }
    return phoneInputCountries.value[locale];
  };

  const basicValidation = {
    required: true,
    minLength: 2,
    maxLength: 30,
  };

  const getI18nFallback = (formName: string): Form => ({
    formId: formName,
    fields: [
      {
        fieldId: 'firstName',
        type: 'text',
        validation: { ...basicValidation },
      },
      {
        fieldId: 'lastName',
        validation: { ...basicValidation },
      },
      {
        fieldId: 'addressLine1',
        type: 'text',
        validation: { ...basicValidation },
      },
      {
        fieldId: 'addressLine2',
        type: 'text',
        validation: { ...basicValidation, required: false },
      },
      {
        fieldId: 'city',
        type: 'text',
        validation: { ...basicValidation },
      },
      {
        fieldId: 'province',
        validation: { ...basicValidation },
      },
      {
        fieldId: 'postalCode',
        type: 'text',
        validation: { ...basicValidation },
      },
      {
        fieldId: 'email',
        type: 'email',
        validation: { required: true },
      },
      {
        fieldId: 'phone',
        type: 'text',
        validation: {
          required: true,
          pattern: '^[0-9*#+]+$',
          minLength: 4,
          maxLength: 15,
        },
        mask: '',
      },
    ],
  });

  const getI18n = async (
    countryCode: string,
    userLocale: string,
    formName: string
  ): Promise<Form> => {
    const locale = fixLocaleCase(userLocale);
    let i18nValue = i18n.value?.[locale]?.[countryCode];
    if (!i18nValue) {
      i18nValue = (await getI18nApi(countryCode, locale)).data;
      if (!i18n.value[locale]) {
        i18n.value[locale] = {};
      }
      i18n.value[locale][countryCode] = i18nValue;
    }
    return (
      i18nValue.find((form) => form.formId === formName) ||
      getI18nFallback(formName)
    );
  };

  const getCountry = (): string =>
    instance.$getEnvValueByCurrentLocale<string>('COUNTRY');

  const fixLocaleCase = (locale: string) => {
    /**
     * IMPORTANT:
     * This is a workaround to map locales defined in CMS to locales in SFCC.
     * Instead, `mapLocale` util function from `@vf/env` package should be used
     * but currently it fails to read `process.env` properties on client side,
     * resulting in AddressForm component not being able to load proper values
     * (see: https://digital.vfc.com/jira/browse/GLOBAL15-4589)
     *
     * FIXME: https://digital.vfc.com/jira/browse/ECOM15-11408
     */
    const [lang, country] = locale.split(/[-_]/);
    const countryCode = /\d/.test(country) ? 'CA' : country;
    return `${lang}-${countryCode.toUpperCase()}`;
  };

  const getStateCode = async (
    name: string,
    country: string
  ): Promise<string> => {
    try {
      const locale = fixLocaleCase(instance.$i18n.locale);
      const countryCode = country?.toLowerCase();
      const { provinces } = await getProvinceList(countryCode, locale);

      return name
        ? provinces.find(
            (item) => item.name.toLowerCase() === name.toLowerCase()
          )?.code || name
        : null;
    } catch (err) {
      console.error(err);
      return name;
    }
  };

  const getStateName = async (
    code: string,
    country: string
  ): Promise<string> => {
    try {
      const locale = fixLocaleCase(instance.$i18n.locale);
      const countryCode = country?.toLowerCase();
      const { provinces } = await getProvinceList(countryCode, locale);

      const stateData = provinces.find((item) => item.code === code);
      if (typeof stateData !== 'undefined') {
        return stateData.name;
      } else {
        console.error(`Missing state with code: ${code}`);
        return code;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    getCountryList,
    getProvinceList,
    getPhoneInputCountryList,
    getI18n,
    getI18nFallback,
    getCountry,
    getStateCode,
    getStateName,
  };
};
export { useUtilities };
