import { computed } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import { AutoAppliedPromotion } from '@vf/api-client/src/types';
import { AthletePromoType } from '@vf/api-contract';
import { useFeatureFlagsStore } from '../../../store/featureFlags';

export const usePromotions = ({ cartRef, cartItems }) => {
  // TODO: GLOBAL15-56318 this file is kind of duplicated with
  // packages/composables/src/store/discountStore.ts
  const appliedGiftCards = computed(
    () =>
      cartRef.value.payment_instruments?.filter(
        (item) => item.payment_method_id === PaymentMethodCode.GIFT_CARD
      ) || []
  );
  const appliedRewards = computed(
    () =>
      cartRef.value.payment_instruments?.filter((item) =>
        // TODO: GLOBAL15-56318 remove feature flag usage after checkout redesign
        // the one with REWARD_CODE should stay
        useFeatureFlagsStore().areRewardCodesEnabled
          ? [
              PaymentMethodCode.REWARD_CARD,
              PaymentMethodCode.REWARD_CODE,
            ].includes(item.payment_method_id)
          : item.payment_method_id === PaymentMethodCode.REWARD_CARD
      ) || []
  );
  const autoAppliedPromotions = computed<AutoAppliedPromotion[]>(
    () => cartRef.value.discounts?.autoAppliedPromotions || []
  );
  const payFullWithGiftCard = computed(
    () => parseFloat(cartRef.value.totals.remainingToPay) <= 0
  );

  const productsExcludedFromAthletes = computed(() => {
    return cartItems.value.filter((item) => {
      return !item?.productPromotions?.some(
        (promo) => promo.promotionId === AthletePromoType.PROMO_ID
      );
    });
  });

  return {
    appliedGiftCards,
    appliedRewards,
    autoAppliedPromotions,
    payFullWithGiftCard,
    productsExcludedFromAthletes,
  };
};
