import { computed, Ref } from '@vue/composition-api';
import { ssrRef } from '@nuxtjs/composition-api';
import { apiClientFactory, CategoryProduct } from '@vf/api-client';
import { PageMetaData } from '@vf/api-client/src/types';
import { ComponentInstance, ComposablesStorage, Filter } from '../types';
import { useI18n } from '../useI18n';
import initStorage from '../utils/storage';
import {
  isClient,
  scrollTo as scrollToTop,
} from '@vf/shared/src/utils/helpers';

import {
  clampCount,
  prepareCategoryFilters,
  prepareCount,
  prepareFiltersJson,
  prepareLocale,
  prepareSort,
  prepareStart,
  prepareStoreFilter,
} from '../utils/query';
import { transformPageMetaData } from '../utils/metaTags';
import { mapOrderFilters } from '../utils/mapOrderFilters';
import { useRequestTracker } from '../useRequestTracker';
import useShippingFilter from '../useShippingFilter';
import { CmsBreadcrumb, PageTypeName } from '../useCms/types';
import useGtm from '../useGtm';
import { useRouting } from '../useRouting';
import * as urlDecoder from '../utils/urlDecoder';
import {
  getMetaDescription,
  getPageTitle,
} from '@vf/shared/src/utils/helpers/seo';
import { getEventFromTemplate } from '../useGtm/helpers';
import { useCmsRefStore } from '../store/cmsRef';
import productsGridCache from '@vf/shared/src/theme/productsGridCache';
import { Context } from '@vf/api-contract';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { transformListingFilters } from './utils';

export type GetCatalogSettings = {
  per?: number;
  page?: number;
  start?: number;
  sort?: string;
  filters?: Filter[];
  loadAllPreviousPages?: boolean;
  appendFetchedProducts?: boolean;
  prependFetchedProducts?: boolean;
  customID?: string;
};

export type SortOption = {
  value: string;
  label: string;
};

enum ApiService {
  PRIMARY = 'bloomreach',
  FALLBACK = 'commercecloud',
}

type CategoryStorage = {
  loading: boolean;
  title: string;
  cmsCategoryTitle: string;
  activeCategoryId: string;
  activeCategorySlug: string;
  filteringOptions: Filter[];
  selectedFilters: Filter[];
  appliedFilters: Filter[];
  selectedSortingOptionId: string;
  selectedSortingChanged: boolean;
  sortingOptions: SortOption[];

  isFilterSidebarOpen: boolean;
  isInfiniteScrolling: boolean;

  products: CategoryProduct[];
  fetchedProducts: CategoryProduct[];
  service: ApiService.PRIMARY | ApiService.FALLBACK | null;
  category: string;
  breadcrumbs: CmsBreadcrumb[];
  initialLoadProductsAmount: number;
  meta: PageMetaData;
  pagination: {
    total: number;
    per: number;
    page: number;
    previousPage: number;
    start: number;
    maxQuery: number;
  };
};

type UseCategoryStorage = {
  categoryRef: Ref<CategoryStorage>;
};

const muleQueryLimit = 200;

const defaultCategoryStore = () => ({
  loading: false,
  title: '',
  cmsCategoryTitle: '',
  activeCategoryId: '',
  activeCategorySlug: '',
  filteringOptions: [],
  selectedFilters: [],
  selectedSortingChanged: false,
  appliedFilters: [],
  selectedSortingOptionId: '',
  sortingOptions: [],
  totalProductsNumber: 0,
  isFilterSidebarOpen: false,
  isInfiniteScrolling: false,
  products: [],
  fetchedProducts: [],
  category: '',
  productsPerPage: 10,
  initialLoadProductsAmount: 25,
  pagination: {
    total: 0,
    per: 0,
    page: 1,
    start: 0,
    maxQuery: 200,
    previousPage: 0,
  },
  breadcrumbs: [],
  meta: {},
  service: null,
});

const useCategory = (instance: ComponentInstance, contextKey?: string) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const {
    syncWithUrl: shippingSyncWithUrl,
    selectedStoreId,
  } = useShippingFilter(instance);
  const { localeCode } = useI18n(instance);
  const { setQueryFilterParams } = useRouting(instance);
  const { dispatchEvent } = useGtm(instance);
  const { getCatalog: getCatalogAPI } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseCategoryStorage> = initStorage<UseCategoryStorage>(
    instance,
    `useCategory-${contextKey}`
  );

  const categoryRef: Ref<CategoryStorage> =
    storage.get('categoryRef') ??
    storage.save(
      'categoryRef',
      ssrRef(
        {
          ...defaultCategoryStore(),
          selectedFilters: urlDecoder.decodeFilterParam(instance),
          appliedFilters: urlDecoder.decodeFilterParam(instance),
        },
        `categoryRef-${contextKey}`
      )
    );

  const resetState = () => {
    categoryRef.value = defaultCategoryStore();
  };

  const cmsRefStore = useCmsRefStore(instance.$pinia);
  const featureFlags = useFeatureFlagsStore();

  const headerLinks = ssrRef([], `headerLinks-${contextKey}`);
  const nextUrl = ssrRef(null, `nextUrl-${contextKey}`);
  const isLoadPreviousEnabled =
    instance.$root.$themeConfig.productsGrid?.loadPrevious
      .isLoadPreviousEnabled;

  const setProductsPerPage = (per: number) => {
    categoryRef.value.pagination.per = clampCount(per);
  };

  const getCatalog = async (
    data?: GetCatalogSettings,
    options?: {
      isBackgroundRequest?: boolean;
      useCache?: boolean;
    }
  ) => {
    const { isBackgroundRequest = false, useCache = false } = options || {};
    const { tag } = trackRequest('useCategory-getCatalog', isBackgroundRequest);
    categoryRef.value.loading = true;
    categoryRef.value.selectedFilters = [...categoryRef.value.appliedFilters];

    const defaults = {
      sort: categoryRef.value.selectedSortingOptionId,
      filters: categoryRef.value.selectedFilters,
      page: categoryRef.value.pagination.page,
      start: categoryRef.value.pagination.start,
      loadAllPreviousPages: false,
      appendFetchedProducts: false,
      prependFetchedProducts: false,
      per: categoryRef.value.pagination.per,
    };

    const settings = Object.assign({}, defaults, data);

    updatePreviousPage(settings);
    preparePagination(settings);

    try {
      if (settings.per > categoryRef.value.pagination.maxQuery) {
        return await batchLoadProducts(settings.per);
      }

      const currentPath = instance.context.route.fullPath;
      const cache =
        useCache && (await productsGridCache.getCachedItem(currentPath));
      const catalogData = await getCatalogData(cache, settings);

      // getting breadcrumbs from catalog api response
      const localeCodeStr = localeCode();

      categoryRef.value.breadcrumbs = catalogData.data.breadcrumbs.map(
        (crumb) => ({
          link: `/${localeCodeStr}${crumb.url}`,
          text: crumb.label,
          dataNavigation: `category-${crumb.url.split('/').slice(-1)[0]}`,
          type: 'category',
        })
      );

      if (contextKey !== Context.ShopByActivity) {
        cmsRefStore.$patch({
          breadcrumbs: categoryRef.value.breadcrumbs,
        });
      }

      const fetchedProducts = catalogData.data.products || [];
      categoryRef.value.service = catalogData.data.service || null;
      categoryRef.value.fetchedProducts = fetchedProducts;
      categoryRef.value.pagination.total = catalogData.data.total || 0;
      categoryRef.value.pagination.page = settings.page || 1;

      if (settings.appendFetchedProducts) {
        categoryRef.value.products = categoryRef.value.products.concat(
          fetchedProducts
        );
      } else if (settings.prependFetchedProducts) {
        if (!cache) {
          categoryRef.value.products = fetchedProducts.concat(
            categoryRef.value.products
          );
        }
      } else categoryRef.value.products = fetchedProducts;

      categoryRef.value.sortingOptions = catalogData.data.sort || [];

      if (catalogData.data.filters) {
        categoryRef.value.filteringOptions = transformListingFilters(
          catalogData.data.filters,
          featureFlags.isNewColorDividerEnabled
        );
      } else {
        categoryRef.value.filteringOptions = [];
      }

      categoryRef.value.meta = transformPageMetaData(
        catalogData.data.pageMetaData
      );
      categoryRef.value.loading = false;
      const breadcrumb = categoryRef.value.breadcrumbs?.slice(-1)[0];
      const seoCategoryTitle = catalogData.data.seoTags?.h1;
      const activeCatTitle =
        seoCategoryTitle ||
        breadcrumb?.text ||
        categoryRef.value.meta?.title?.content ||
        categoryRef.value.cmsCategoryTitle;
      setActiveCategoryTitle(activeCatTitle);

      productsGridCache.storeCachedItem(currentPath, {
        products: categoryRef.value.products,
        lastRequest: catalogData.data,
      });
    } finally {
      categoryRef.value.loading = false;
      categoryRef.value.selectedSortingChanged = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  // first plp frontend call
  const updatePreviousPage = ({
    appendFetchedProducts,
    page,
    prependFetchedProducts,
  }) => {
    if (
      isLoadPreviousEnabled &&
      isClient &&
      !appendFetchedProducts &&
      !prependFetchedProducts
    ) {
      categoryRef.value.pagination.previousPage = page - 1;
    }
  };

  const preparePagination = (settings: GetCatalogSettings) => {
    const countedPagination = clampCount(
      settings.page === 1
        ? categoryRef.value.initialLoadProductsAmount
        : categoryRef.value.pagination.per
    );

    if (settings.page >= 2 && settings.loadAllPreviousPages) {
      settings.start = 0;
      settings.per = clampCount(
        categoryRef.value.initialLoadProductsAmount +
          (settings.page - 1) * categoryRef.value.pagination.per
      );
    } else if (!settings.per) {
      settings.per = countedPagination;
    } else {
      settings.per = clampCount(settings.per);
    }
  };

  const getCatalogData = async (cache, settings: GetCatalogSettings) => {
    let catalogData = null;

    const query: string[] = [
      prepareStart(settings.start),
      prepareCount(settings.per),
      prepareSort(settings.sort),
      prepareLocale(localeCode()),
    ];

    if (selectedStoreId.value)
      query.push(prepareStoreFilter(selectedStoreId.value));

    const categoryId =
      settings.customID || categoryRef.value.activeCategorySlug || null;

    query.push(
      prepareCategoryFilters(
        settings.filters,
        categoryId,
        !!settings.customID || null
      )
    );

    query.push(prepareFiltersJson(settings.filters, categoryId));

    if (cache) {
      catalogData = {
        data: cache.lastRequest,
      };

      catalogData.data.products = cache.products;

      settings.page = Math.ceil(
        catalogData.data.products.length / settings.per
      );
    } else {
      catalogData = await getCatalogAPI(query.join('&'));
    }

    return catalogData;
  };

  const setInfiniteScrolling = (infiniteScrolling: boolean) => {
    categoryRef.value.isInfiniteScrolling = infiniteScrolling;
  };

  const setInitialLoadProductsAmount = (productsAmount: number) => {
    categoryRef.value.initialLoadProductsAmount = clampCount(productsAmount);
  };

  const selectFilter = (
    filter: Filter,
    update = true,
    isOnlyGtmChanges = false
  ) => {
    if (!isOnlyGtmChanges) {
      categoryRef.value.appliedFilters.push(filter);
    }

    if (update) writeFiltersDataToUrl();

    dispatchEvent({
      eventName: 'loadEventData',
      overrideAttributes: {
        eventCategory: PageTypeName.PLP,
        eventAction: 'Filter',
        eventLabel: 'Apply',
        nonInteractive: false,
        customMetrics: {},
        customVariables: {
          filterCategory: filter.code,
          filterValue: filter.value,
        },
        _clear: true,
      },
    });
  };

  const removeFilter = (
    filter: Filter,
    update = true,
    isOnlyGtmChanges = false
  ) => {
    if (!isOnlyGtmChanges) {
      categoryRef.value.appliedFilters = categoryRef.value.appliedFilters.filter(
        (it) => {
          return it.code !== filter.code || it.value !== filter.value;
        }
      );
    }
    if (update) writeFiltersDataToUrl();

    dispatchEvent({
      eventName: 'loadEventData',
      overrideAttributes: {
        eventCategory: PageTypeName.PLP,
        eventAction: 'Filter',
        eventLabel: 'Remove',
        nonInteractive: false,
        customMetrics: {},
        customVariables: {
          filterCategory: filter.code,
          filterValue: filter.value,
        },
        _clear: true,
      },
    });
  };

  const setFilterSidebarOpen = (open: boolean) => {
    categoryRef.value.isFilterSidebarOpen = open;
  };
  const setActiveCategorySlug = (slug: string) => {
    categoryRef.value.activeCategorySlug = slug;
  };
  const setActiveCategoryId = (id: string) => {
    categoryRef.value.activeCategoryId = id;
  };
  const setActiveCategoryTitle = (categoryTitle: string) => {
    categoryRef.value.title = categoryTitle;
  };
  const resetFiltersView = () => {
    categoryRef.value.selectedFilters = [];
  };
  const resetAppliedFilters = () => {
    categoryRef.value.appliedFilters = [];
  };

  const resetFilters = () => {
    setQueryFilterParams({ f: [] });
    dispatchEvent(
      getEventFromTemplate('filter:clear', {
        eventCategory: PageTypeName.PLP,
        eventLabel: 'Clear All',
        filterCategory: 'All',
      })
    );
  };

  const resetFilter = (code, update = true) => {
    categoryRef.value.appliedFilters = categoryRef.value.appliedFilters.filter(
      (item) => item.code !== code
    );
    if (update) writeFiltersDataToUrl();
    dispatchEvent(
      getEventFromTemplate('filter:clear', {
        eventCategory: PageTypeName.PLP,
        eventLabel: 'Clear All',
        filterCategory: code,
      })
    );
  };

  const updateCatalog = () => {
    readFiltersDataFromUrl();
    applyFilters();
  };

  // TODO: remove this - unnecessary function
  const applyFilters = () => {
    if (!instance.$root.$viewport.isSmall) {
      scrollToTop();
    }

    setActivePage(1);
    getCatalog();
  };

  const changeSort = (selectedSortingOptionId, shouldDispatchEvent = true) => {
    categoryRef.value.selectedSortingOptionId = selectedSortingOptionId;
    categoryRef.value.selectedSortingChanged = true;
    if (shouldDispatchEvent) {
      const selectedOption = categoryRef.value.sortingOptions.find(
        (sort) => sort.value === selectedSortingOptionId
      );
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: PageTypeName.PLP,
          eventAction: 'Sort by',
          eventLabel: selectedOption?.label || selectedOption,
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    }
  };

  const loadNextPage = () => {
    setActivePage(categoryRef.value.pagination.page + 1);
    return getCatalog({
      appendFetchedProducts: true,
      prependFetchedProducts: false,
    });
  };

  const loadPreviousPage = async () => {
    const previousPage = categoryRef.value.pagination.previousPage;
    categoryRef.value.pagination.start = calculateStartPosition(previousPage);
    await getCatalog(
      {
        appendFetchedProducts: false,
        prependFetchedProducts: true,
      },
      {
        isBackgroundRequest: false,
        useCache: !isLoadPreviousEnabled,
      }
    );
    categoryRef.value.pagination.previousPage = previousPage - 1;
  };

  const batchLoadProducts = async (count: number) => {
    if (count > categoryRef.value.pagination.maxQuery) {
      const finalRemainder =
        (count - categoryRef.value.products.length) %
        categoryRef.value.pagination.maxQuery;
      const queryCount = Math.ceil(
        (count - categoryRef.value.products.length) /
          categoryRef.value.pagination.maxQuery
      );

      await daisyChainCatalog(finalRemainder, queryCount);
    } else {
      await getCatalog({
        page: 1,
        per: categoryRef.value.pagination.total,
        start: 0,
      });
    }
  };

  const loadAllProducts = async (
    maxQuery?: number,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useCategory-loadAllProducts',
      isBackgroundRequest
    );

    if (maxQuery && maxQuery <= muleQueryLimit)
      categoryRef.value.pagination.maxQuery = maxQuery;

    await batchLoadProducts(categoryRef.value.pagination.total);

    const {
      initialLoadProductsAmount: initial,
      pagination,
    } = categoryRef.value;
    const { per, total } = pagination;

    categoryRef.value.pagination.page = Math.ceil((total - initial) / per) + 1;

    clearRequest(tag, isBackgroundRequest);
  };

  const daisyChainCatalog = async (
    finalRemainder: number,
    queryCount: number
  ) => {
    categoryRef.value.pagination.start = categoryRef.value.products.length;
    await getCatalog({
      per:
        queryCount === 1
          ? finalRemainder
          : categoryRef.value.pagination.maxQuery,
      appendFetchedProducts: true,
    });
    queryCount--;
    if (queryCount === 0 || isInfiniteScrolling.value) return;
    await daisyChainCatalog(finalRemainder, queryCount);
  };

  const calculateStartPosition = (page) => {
    if (page > 1) {
      const initialCount = categoryRef.value.initialLoadProductsAmount;
      const pagesCount = (page - 2) * categoryRef.value.pagination.per;

      return initialCount + pagesCount;
    } else {
      return 0;
    }
  };

  const setActivePage = (page: number) => {
    categoryRef.value.pagination.page = page;

    categoryRef.value.pagination.start = calculateStartPosition(page);
  };

  const resetPagination = () => {
    categoryRef.value.pagination.total = 0;
    categoryRef.value.pagination.page = 1;
    categoryRef.value.pagination.start = 0;
    categoryRef.value.pagination.previousPage = 0;
  };

  const getCurrentCategory = () => {
    const { breadcrumbs } = categoryRef.value;
    if (breadcrumbs?.length) {
      categoryRef.value.category = breadcrumbs
        .map((crumb) => crumb.text)
        .join(' - ');
      const { link: url } = breadcrumbs[breadcrumbs.length - 1];
      return url.split('/').slice(-1)[0];
    }
  };

  const readFiltersDataFromUrl = () => {
    categoryRef.value.appliedFilters = urlDecoder.decodeFilterParam(instance);
    shippingSyncWithUrl();
  };

  const title = computed(() => categoryRef.value.title);
  const selectedFilters = computed(() => categoryRef.value.selectedFilters);
  const sortedFilters = computed(() =>
    urlDecoder.sortFilters(selectedFilters.value)
  );
  const isInfiniteScrolling = computed(
    () => categoryRef.value.isInfiniteScrolling
  );

  const pageTitle = computed(() => {
    return getPageTitle(title.value, sortedFilters.value);
  });

  const pageMetaTitle = computed(() => {
    const customCommerceTitle = categoryRef.value.meta?.title?.content;
    return customCommerceTitle || pageTitle.value;
  });

  const customPageDescription = computed(() => {
    return categoryRef.value.meta?.description?.content;
  });

  const writeFiltersDataToUrl = (writeSort = false) => {
    const queryParams = urlDecoder.encodeFiltersQueryParams(
      categoryRef.value.appliedFilters
    );
    if (queryParams !== null) {
      if (writeSort)
        queryParams.sort = categoryRef.value.selectedSortingOptionId;
      setQueryFilterParams(queryParams);
    }
  };

  const setEmptyHeaderLinks = () => {
    nextUrl.value = null;
    headerLinks.value = [];
  };

  const setHeaderLinks = (isClient: boolean, initialProductsLoad: number) => {
    if (isClient) setEmptyHeaderLinks();
    if (!categoryRef.value.products || !categoryRef.value.products.length)
      return;

    const position =
      initialProductsLoad +
      (categoryRef.value.pagination.page - 1) *
        categoryRef.value.pagination.per;

    setEmptyHeaderLinks();
    const catalogUrl = `https://${instance.$root.$getDomainName()}${
      instance.context.route.path
    }`;

    if (categoryRef.value.pagination.page > 1) {
      const prevUrlQuery = ({
        ...instance.context.route.query,
      } as unknown) as Record<string, string>;

      if (categoryRef.value.pagination.page <= 2) {
        delete prevUrlQuery.page;
      } else {
        prevUrlQuery.page = (categoryRef.value.pagination.page - 1).toString();
      }

      const prevUrl = Object.keys(prevUrlQuery).length
        ? `${catalogUrl}?${new URLSearchParams(prevUrlQuery).toString()}`
        : catalogUrl;

      headerLinks.value.push({
        hid: 'linkPrev',
        rel: 'prev',
        href: prevUrl,
      });
    }

    if (position <= categoryRef.value.pagination.total) {
      const nextUrlQuery = ({
        ...instance.context.route.query,
      } as unknown) as Record<string, string>;

      nextUrlQuery.page = (categoryRef.value.pagination.page + 1).toString();

      nextUrl.value = Object.keys(nextUrlQuery).length
        ? `${catalogUrl}?${new URLSearchParams(nextUrlQuery).toString()}`
        : catalogUrl;

      headerLinks.value.push({
        hid: 'linkNext',
        rel: 'next',
        href: nextUrl.value,
      });
    }
    return headerLinks.value.length > 0;
  };

  const getFirstPageNumber = async (defaultPage = 1) => {
    if (isLoadPreviousEnabled && isClient) {
      const currentPath = instance.context.route.fullPath;
      const cache = await productsGridCache.getCachedItem(currentPath);
      if (cache) {
        return cache.currentPage;
      }
    }
    return instance.$route.query?.page
      ? parseInt(<string>instance.$route.query.page)
      : defaultPage;
  };

  const getSearchSetting = async (searchSettings: GetCatalogSettings) => ({
    ...searchSettings,
    page: await getFirstPageNumber(searchSettings.page),
    loadAllPreviousPages: isLoadPreviousEnabled
      ? false
      : searchSettings.loadAllPreviousPages,
  });

  const setCmsCategoryTitle = (catTitle: string): void => {
    categoryRef.value.cmsCategoryTitle = catTitle;
  };

  const isCatalogResponseFromFallbackService = computed(
    () => categoryRef.value.service === ApiService.FALLBACK
  );

  const isBlankCategoryResponse = computed(
    () =>
      isCatalogResponseFromFallbackService.value &&
      !categoryRef.value.products.length &&
      !categoryRef.value.filteringOptions?.length
  );

  return {
    getCurrentCategory,
    readFiltersDataFromUrl,
    writeFiltersDataToUrl,
    title,
    pageTitle,
    pageMetaTitle,
    customPageDescription,
    selectedFilters,
    sortedFilters,
    isFilterSidebarOpen: computed(() => categoryRef.value.isFilterSidebarOpen),
    setFilterSidebarOpen,
    isInfiniteScrolling,
    pagination: computed(() => ({
      total: categoryRef.value.pagination.total,
      per: categoryRef.value.pagination.per,
      page: categoryRef.value.pagination.page,
      previousPage: categoryRef.value.pagination.previousPage,
    })),
    products: computed(() => categoryRef.value.products),
    fetchedProducts: computed(() => categoryRef.value.fetchedProducts),
    initialLoadProductsAmount: computed(
      () => categoryRef.value.initialLoadProductsAmount
    ),
    loading: computed(() => categoryRef.value.loading),
    meta: computed(() => categoryRef.value.meta),
    sortingOptions: computed(() => categoryRef.value.sortingOptions),
    filteringOptions: computed(() => categoryRef.value.filteringOptions),
    appliedFilters: computed(() => categoryRef.value.appliedFilters),
    selectedSortingOptionId: computed(
      () => categoryRef.value.selectedSortingOptionId
    ),
    selectedSortingChanged: computed(
      () => categoryRef.value.selectedSortingChanged
    ),
    category: computed(() => categoryRef.value.category),
    categoryId: computed(() => categoryRef.value.activeCategoryId),
    applyFilters,
    changeSort,
    resetFilters,
    resetFilter,
    resetFiltersView,
    resetAppliedFilters,
    removeFilter,
    setActiveCategorySlug,
    setActiveCategoryTitle,
    setActiveCategoryId,
    selectFilter,
    getSearchSetting,
    getCatalog,
    setProductsPerPage,
    setInfiniteScrolling,
    setInitialLoadProductsAmount,
    loadNextPage,
    loadPreviousPage,
    loadAllProducts,
    setActivePage,
    resetPagination,
    mapOrderFilters,
    updateCatalog,
    getPageTitle,
    getMetaDescription,
    breadcrumbs: computed(() => categoryRef.value.breadcrumbs),
    // for tests only
    batchLoadProducts,
    setHeaderLinks,
    headerLinks: computed(() => headerLinks.value),
    resetState,
    setCmsCategoryTitle,
    isBlankCategoryResponse,
  };
};

export default useCategory;
