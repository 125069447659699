import { CountryCode, getCountryCallingCode } from 'libphonenumber-js/min';

const isUSCAAddress = (address: Record<string, any>): boolean =>
  ['US', 'CA'].includes(address?.country || address?.countryCode);

export const stripPhone = (phone: string): string =>
  phone?.replace(/(\D*)/g, '')?.match(/(?:\d*)(?<phone>\d{10})/)?.groups?.phone;

const US_CA_AREA_CODE = '+1';

export const stripPhoneAndPrependUSCAAreaCode = (phone: string): string =>
  stripPhoneAndPrependCountryCallingCode(phone, 'US');

export const prependUSCAAreaCode = (phone: string): string =>
  prependAreaCode(phone, US_CA_AREA_CODE);

const prependAreaCode = (phone: string, areaCode: string): string => {
  if (!phone || !areaCode) return phone;
  return `${areaCode}${phone}`;
};

export const maskUSCAPhone = (phone: string): string => {
  const destructedPhone = phone.match(/(\d{3})(\d{3})(\d{4})/);
  return `${destructedPhone[1]}-${destructedPhone[2]}-${destructedPhone[3]}`;
};

/*
 *  `AddressObject` type was replaced by `Record<string, any>`. Refactor
 *  of address types should be conducted as suggested in this comment
 *  https://digital.vfc.com/jira/browse/GLOBAL15-2852?focusedCommentId=930201&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-930201
 *  When done `mapPhoneOnUSCAAddress` should be typed with common address type.
 * */

export const mapPhoneOnUSCAAddress = (
  address: Record<string, any>,
  prependCode: boolean = false,
  applyMask: boolean = false
): Record<string, any> => {
  if (!address.phone || !isUSCAAddress(address)) return address;
  let phone = stripPhone(address.phone);
  if (prependCode) phone = prependUSCAAreaCode(phone);
  else if (applyMask) phone = maskUSCAPhone(phone);
  return {
    ...address,
    ...{ phone },
  };
};

export const getOnlyDigits = (value: string): string =>
  value?.replace(/\D/g, '') ?? value;

export const stripPhoneAndPrependCountryCallingCode = (
  value: string,
  country: string
): string => {
  if (!value || value.includes('+')) return value;
  try {
    const countryCallingCode = getCountryCallingCode(country as CountryCode);
    return prependAreaCode(getOnlyDigits(value), `+${countryCallingCode}`);
  } catch {
    return value;
  }
};

export function isPOBoxAddress(address: string, address2 = ''): boolean {
  const pattern = /((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|(#|num)?\d+))|(post? *(o(ff(ice)?)?)? *(box|bin)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|(box|bin) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;
  return !!pattern.exec(`${address} ${address2}`);
}
