import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
  generateLinkFromTarget,
  generateLinkFromTeaserTargets,
  getDynamicField,
  getImageObject,
} from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { isEmpty } from '../../utils/isEmpty';
import { customContent } from './customContent';

export const stickyHeader = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const logo = extractLocalSettingsExpandedLinks(data, 'logo')[0];
  const links = extractLocalSettingsExpandedLinks(data, 'navigation');
  const customsCta = getCustomsCta(data);
  const customContentInfo = getCustomContentInfo(data);

  return {
    component: 'layout/StickyHeader',
    props: {
      logo: {
        ...getImageObject(logo, siteConfiguration, null, cmsBaseUri),
        link: '',
      },
      title: extractProperty(data, 'title'),
      dataObject: getDynamicField(customsCta, 'dataObject'),
      experience: getDynamicField(customsCta, 'experience'),
      experienceType:
        getDynamicField(customsCta, 'experienceType') || undefined,
      ctaButtons: getButtons(data, context, siteConfiguration, cmsBaseUri),
      customContentInfo,
      triggerStickyOnElementId: getButtonId(data),
      triggerStickyOnDefinedNumber: getDynamicField(
        data,
        'triggerStickyOnDefinedNumber',
        null
      ),
      showLogo: !isEmpty(logo),
      showTitle: !isEmpty(extractProperty(data, 'title')),
      showNavigation: !isEmpty(links),
      showCustomButton: !!customsCta,
      transparentBackground: getDynamicField(
        data,
        'transparentBackground',
        false
      ),
      showAccordionOnSmall: getDynamicField(data, 'showAccordionOnSmall', true),
      separatorForLink: getDynamicField(data, 'separatorForLink', true),
      contentPosition: getPosition(data),
      navigation: getNavigation(links, context, siteConfiguration, cmsBaseUri),
      seo: {},
      showOnSmall: getDynamicField(data, 'showOnSmall', true),
      showOnMedium: getDynamicField(data, 'showOnMedium', true),
      showOnLarge: getDynamicField(data, 'showOnLarge', true),
    },
  };
};

const getNavigation = (data, context, siteConfiguration, cmsBaseUri) => {
  return data.map((item) => {
    const { scrollToComponent } = item;

    switch (item.type) {
      case 'CMTeaser':
        return {
          id: extractProperty(item, 'id'),
          label: item.teaserTitle,
          link: generateLinkFromTeaserTargets(
            item.teaserTargets,
            context,
            siteConfiguration,
            null,
            cmsBaseUri,
            scrollToComponent
          ),
          scrollToComponent,
        };
      case 'CMExternalChannel':
      default:
        return {
          id: extractProperty(item, 'commerceRef.id'),
          label: extractProperty(item, 'commerceRef.title'),
          link: generateLinkFromTarget(
            item,
            context,
            siteConfiguration,
            cmsBaseUri,
            scrollToComponent
          ),
          scrollToComponent,
        };
    }
  });
};

const getButtonId = (data) => {
  const btn = extractLocalSettingsExpandedLinks(data, 'triggerStickyOnElement');

  return (
    extractProperty(btn, '[0].customId', '') ||
    extractProperty(btn, '[0].id', '')
  );
};

const getButtons = (data, context, siteConfiguration, cmsBaseUri) => {
  const buttons = extractLocalSettingsExpandedLinks(data, 'ctaButton');
  return buttons.map((button) => {
    return {
      buttonStyle: extractProperty(button, 'localSettings.dynamicFields.style'),
      buttonSize: extractProperty(button, 'localSettings.dynamicFields.size'),
      iconPosition: extractProperty(
        button,
        'localSettings.dynamicFields.iconPosition'
      ),
      disabled: !extractProperty(button, 'localSettings.dynamicFields.enabled'),
      underline: extractProperty(
        button,
        'localSettings.dynamicFields.underline'
      ),
      link: generateLinkFromTeaserTargets(
        button.teaserTargets,
        context,
        siteConfiguration,
        button.urlParams,
        cmsBaseUri
      ),
      icon: extractProperty(button, 'localSettings.dynamicFields.icon', ''),
      buttonText: extractProperty(button, 'teaserTitle', '$Button text$'),
    };
  });
};
const getPosition = (data) => {
  return {
    small: extractProperty(
      data,
      'localSettings.dynamicFields.contentPositionSmall'
    ),
    medium: extractProperty(
      data,
      'localSettings.dynamicFields.contentPositionMedium'
    ),
    large: extractProperty(
      data,
      'localSettings.dynamicFields.contentPositionLarge'
    ),
  };
};
const getCustomsCta = (data) => {
  return extractLocalSettingsExpandedLinks(data, 'ctaButton').find((button) =>
    button.viewtype.includes('customs-cta')
  );
};
const getCustomContentInfo = (data) => {
  return extractLocalSettingsExpandedLinks(data, 'ctaButton')
    .filter((button) => button.viewtype.includes('custom-content'))
    .map((content) => customContent(content).props);
};
