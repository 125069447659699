import { CMTeaser, CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../../types';
import {
  decorateHtmlValue,
  extractProperty,
  extractLocalSettingsExpandedLinks,
  extractLocalSettingsExpandedRichText,
  generateLinkFromTeaserTargets,
  getDynamicField,
  getTranslation,
  extractRichText,
} from './index';

export const getCreateAccountTranslations = (
  data: CMTeaser | CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const isRichTextObject = extractProperty(data, 'useRichText', false);
  const termsLabel = extractLocalSettingsExpandedRichText(
    data,
    'termsLabel',
    ''
  );
  const privacyLabel = extractLocalSettingsExpandedRichText(
    data,
    'privacyLabel',
    ''
  );
  const xplrLearnMoreData = extractLocalSettingsExpandedLinks(
    data,
    'xplrLearnMoreLink'
  );

  const fieldsTranslations = {
    firstNameLabel: getTranslation(data, 'firstNameLabel', '$First name$'),
    lastNameLabel: getTranslation(data, 'lastNameLabel', '$Last name label$'),
    emailLabel: getTranslation(data, 'emailLabel', '$Email$'),
    zipCodeLabel: getDynamicField(data, 'zipCodeLabel', '$Zip Code$'),
    zipCodeRegex: getTranslation(data, 'zipCodeRegex', '$zipCode$'),
    zipCodeHelpText: getTranslation(data, 'zipCodeHelpText', '$Zip Code$'),
    birthdayLabel: getTranslation(
      data,
      'birthdayLabel',
      '$Bitrthday MM/DD/YYYY$'
    ),
    birthdayHelpText: getTranslation(data, 'birthdayHelpText', '$Birthday$'),
    phoneNumberLabel: getTranslation(
      data,
      'phoneNumberLabel',
      '$Phone Number$'
    ),
    countryCodeLabel: getTranslation(
      data,
      'countryCodeLabel',
      '$Country Code$'
    ),
    phoneNumberHelpText: getTranslation(
      data,
      'phoneNumberHelpText',
      '$Phone Number$'
    ),
    signInButtonText: getTranslation(data, 'signInButtonText', 'Sign In'),
  };

  const passwordTranslations = {
    passwordLabel: getTranslation(data, 'passwordLabel', '$Password$'),
    passwordHelperText: getTranslation(
      data,
      'passwordHelperText',
      '$Password helper text$'
    ),
    passwordMinCharsLabel: getTranslation(
      data,
      'passwordMinCharsLabel',
      '$8 characters$'
    ),
    passwordNumberLabel: getTranslation(
      data,
      'passwordNumberLabel',
      '$1 number$'
    ),
    passwordLowerLetterLabel: getTranslation(
      data,
      'passwordLowerLetterLabel',
      '$1 lowercase letter$'
    ),
    passwordUpperLetterLabel: getTranslation(
      data,
      'passwordUpperLetterLabel',
      '$1 uppercase letter$'
    ),
  };

  const xplrPassTranslations = {
    xplrAccessLabel: getTranslation(
      data,
      'xplrAccessLabel',
      '$xplrAccessLabel$'
    ),
    xplrAccessIcon: getTranslation(data, 'xplrAccessIcon', 'loyalty_gear'),
    xplrTitleLabel: getTranslation(
      data,
      'xplrTitleLabel',
      '$ENROLL IN XPLR PASS$'
    ),
    xplrPointsLabel: getTranslation(
      data,
      'xplrPointsLabel',
      '$xplrPointsLabel$'
    ),
    xplrPointsIcon: getTranslation(data, 'xplrPointsIcon', 'loyalty_earn'),
    xplrJoinLabel: getTranslation(data, 'xplrJoinLabel', '$xplrJoinLabel$'),
    xplrJoinIcon: getTranslation(data, 'xplrJoinIcon', 'loyalty_join'),
    xplrLearnMoreLabel: getTranslation(
      data,
      'xplrLearnMoreLabel',
      '$Learn More$'
    ),
    xplrLearnMoreLink: generateLinkFromTeaserTargets(
      [
        {
          target: extractProperty(xplrLearnMoreData, '[0]'),
          callToActionEnabled: false,
          callToActionText: '',
        },
      ],
      context,
      siteConfiguration,
      '',
      cmsBaseUri
    ),
  };

  const smsTranslations = {
    sms: {
      checkboxLabel:
        '$I agree to receive texts about my orders and offerings from The NorthFace.$',
      ...getTranslation(data, 'sms', {}),
      termsAndConditions: {
        title: '$Terms & Conditions$',
        ctaLabel: '$I Accept$',
        ...getTranslation(data, 'sms.termsAndConditions', {}),
        body: extractRichText(
          data as CMPlaceholder,
          'tcRichText',
          '$I expressly consent to receive recurring text messages from The NorthFace.$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
      },
    },
  };

  const AgreementAndSubscriptionTranslations = {
    subscribeTextCheckbox: getDynamicField(
      data,
      'sendMeEmailsText',
      '$Please send me emails with offers and updates from Vans.$'
    ),
    termsLabel:
      (termsLabel.html
        ? decorateHtmlValue(termsLabel, cmsBaseUri, context, siteConfiguration)
        : getDynamicField(data, 'termsLabel')) ||
      '$I agree to the XPLR Pass Terms and website Terms of Use.$',
    privacyLabel:
      (privacyLabel?.html
        ? decorateHtmlValue(
            privacyLabel,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : getDynamicField(data, 'privacyLabel')) ||
      '$I agree to the use of my information in accordance with the Privacy Policy.$',
    termsTextCheckbox: isRichTextObject // other than create-account-birthdate
      ? decorateHtmlValue(
          (data as CMTeaser).teaserRichTextObject,
          cmsBaseUri,
          context,
          siteConfiguration
        )
      : extractProperty(data, 'teaserRichTextObject.html'),
    privacyPolicyText: decorateHtmlValue(
      extractLocalSettingsExpandedRichText(data, 'privacyPolicyText', ''),
      cmsBaseUri,
      context,
      siteConfiguration
    ),
  };

  const validationMessages = {
    requiredError: getTranslation(
      data,
      'requiredError',
      '$This field is required$'
    ),
    nameError: getTranslation(data, 'nameError', '$Please enter a valid name$'),
    emailError: getTranslation(
      data,
      'emailError',
      '$Please enter a valid email address$'
    ),
    zipError: getTranslation(
      data,
      'zipCodeError',
      '$Please match the requested format: (55555 or 55555-5555$'
    ),
    birthdayError: getTranslation(
      data,
      'birthdayError',
      '$Please match the requested format: Birthday (MM/DD/YYYY).$'
    ),
    phoneError: getTranslation(
      data,
      'phoneNumberError',
      '$A valid phone number is required$'
    ),
    passwordError: getTranslation(
      data,
      'passwordError',
      '$Password entry does not meet criteria$'
    ),
    termsError: getTranslation(
      data,
      'termsError',
      '$Please confirm the terms.$'
    ),
    privacyPolicyError: getTranslation(
      data,
      'privacyPolicyError',
      '$Please accept the Privacy Policy$'
    ),
    privacyError: getTranslation(
      data,
      'privacyError',
      '$Please accept the Privacy Policy.$'
    ),
    xplrTermsError: getTranslation(
      data,
      'xplrTermsError',
      '$Please confirm the loyalty terms$'
    ),
    userTooYoungError: getTranslation(
      data,
      'userTooYoungError',
      '$You must be 16 or older to join$'
    ),
    uniqueEmailError: getTranslation(
      data,
      'uniqueEmailError',
      '$Please use a different email address$'
    ),
  };

  return {
    ...fieldsTranslations,
    ...passwordTranslations,
    ...AgreementAndSubscriptionTranslations,
    ...xplrPassTranslations,
    ...smsTranslations,
    validationMessages,
    requiredFieldsInfo: getTranslation(
      data,
      'requiredFieldsInfo',
      '$All Fields Required$'
    ),
    buttonText: getTranslation(data, 'buttonText', '$Submit$'),
    joinNowButtonText: getTranslation(data, 'joinNowButtonText', '$JOIN NOW$'),
  };
};
