//TODO: Remove in GLOBAL15-56318
import { getDynamicField, getTranslation, extractRichText } from './utils';
import { ComposableContext } from '../types';
import {
  CMPlaceholder,
  CmsSite,
  CheckoutSidebarTranslations,
} from '@vf/api-contract';
import { getApplePayComponentData } from './utils/getApplePayComponentData';

export const cartOrderSummary = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const applePayData = getApplePayComponentData(
    data,
    cmsBaseUri,
    context,
    siteConfiguration
  );

  const productListTranslations: CheckoutSidebarTranslations['productList'] = {
    qty: getTranslation(data, 'quantity', '$Quantity$'),
    remove: getTranslation(data, 'remove', '$Remove$'),
    customItems: getTranslation(data, 'customItems', '$Customs$'),
    giftBox: getTranslation(data, 'giftBox', '$Gift Box$'),
    giftBoxPrice: getTranslation(
      data,
      'giftBoxPrice',
      '$Qty {{quantity}} @ {{price}}$'
    ),
    inStorePickup: getTranslation(
      data,
      'inStorePickup',
      `$Pick up @ {{storeName}}$`
    ),
    commonNarvarAndPickupLabel: getTranslation(
      data,
      'commonNarvarAndPickupLabel',
      `$Pick it up in {{EEE, MMM dd}} at {{storeName}}$`
    ),
    excludedFromAthleteMessage: getTranslation(
      data,
      'excludedFromAthleteMessage',
      `$Excluded from Athlete Program.$`
    ),
    excludedFromAthleteDueSale: getTranslation(
      data,
      'excludedFromAthleteDueSale',
      `$Additional discount cannot be applied.$`
    ),
    giftWithPurchaseMessage: getTranslation(
      data,
      'giftWithPurchaseMessage',
      `$Free gifts with purchases are not eligible for returns or exchanges$`
    ),
    free: getTranslation(data, 'free', `$Free$`),
    itemsCountSingular: getTranslation(data, 'itemsCountSingular', '$Item$'),
    itemsCountPlural: getTranslation(data, 'itemsCountPlural', '$Items$'),
  };
  return {
    component: 'cart/CartOrderSummary',
    props: {
      context: getDynamicField(data, 'context', 'cart'),
      currencySymbol: siteConfiguration?.commonConfig?.currencySymbol,
      showContinueShoppingButton: getDynamicField(
        data,
        'show-continue-shopping-cta',
        true
      ),
      showPayPalButton: getDynamicField(data, 'show-pay-with-paypal-cta', true),
      showBeforeTaxMessage: getDynamicField(
        data,
        'show-before-tax-message',
        true
      ),
      showShareMyCartButton: getDynamicField(
        data,
        'show-share-my-cart-cta',
        true
      ),
      showPayWithAppleButton: getDynamicField(
        data,
        'show-pay-with-apple-pay-cta',
        true
      ),
      showPayWithGoogleButton: getDynamicField(
        data,
        'show-pay-with-google-pay-cta',
        true
      ),
      showProducts: getDynamicField(data, 'showProducts', true),
      showDetailingShipment: getDynamicField(
        data,
        'show-detailing-shipment',
        true
      ),
      showAddPromoCode: getDynamicField(data, 'show-add-promo-code', false),
      showAddPromoCodeWithSingleValue: getDynamicField(
        data,
        'show-add-promo-code-with-single-value',
        false
      ),
      showLifeTimeGuarantee: getDynamicField(
        data,
        'show-life-time-guarantee',
        false
      ),
      showFreeReturns: getDynamicField(data, 'show-free-returns', false),
      showBottomActionButton: getDynamicField(
        data,
        'show-bottom-action-button',
        true
      ),
      showSidebarTopMobile: getDynamicField(
        data,
        'show-sidebar-top-mobile',
        true
      ),
      showAgeConfirmation: getDynamicField(data, 'show-age-confirmation', true),
      showEstimatedDate: getDynamicField(data, 'showEstimatedDate', false),
      translations: {
        close: getTranslation(data, 'close', '$Close$'),
        privacyPolicy: extractRichText(
          data,
          'privacyPolicy',
          getTranslation(
            data,
            'privacyPolicy',
            `$By placing this order, you agree to <a href='#'>Terms & Conditions</a> and <a href='#'>Privacy Policy</a>$`
          ),
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        ageConfirmation: getTranslation(
          data,
          'ageConfirmation',
          '$By clicking Continue to Billing, you acknowledge that you are at least 13 years or older.$'
        ),
        poAddressErrorMessage: getTranslation(
          data,
          'poAddressErrorMessage',
          '$Custom shoes orders cannot be shipped to PO Box or FPO/APO addresses. Please change your address or remove the item from the cart.$'
        ),
        orderTotal: getTranslation(data, 'orderTotal', '$Order Total$'),
        beforeTax: getTranslation(data, 'beforeTax', '$Before Tax$'),
        edit: getTranslation(data, 'edit', '$Edit$'),
        editCart: getTranslation(data, 'editCart', '$Edit cart$'),
        shippingTo: getTranslation(data, 'shippingTo', '$Shipping To$'),
        orderSummary: getTranslation(data, 'orderSummary', '$Order Summary$'),
        itemSubtotal: getTranslation(data, 'itemSubtotal', '$Item Subtotal$'),
        estimatedShipping: getDynamicField(
          data,
          'estimated-shipping-cta-name',
          '$Estimated Shipping$'
        ),
        taxLabel: getTranslation(data, 'taxLabel', '$Tax$'),
        proceedToCheckoutButton: getDynamicField(
          data,
          'proceed-to-checkout-cta-name',
          '$Proceed to checkout$'
        ),
        proceedToBillingButton: getTranslation(
          data,
          'proceedToBillingButton',
          '$Proceed to billing$'
        ),
        placeOrderButton: getTranslation(
          data,
          'placeOrderButton',
          '$Place Order$'
        ),
        or: getTranslation(data, 'or', '$Or$'),
        shareButton: getTranslation(data, 'shareButton', '$Share my cart$'),
        continueShoppingButton: getTranslation(
          data,
          'continueShoppingButton',
          '$Continue Shopping$'
        ),
        estimatedTax: getDynamicField(
          data,
          'estimated-tax-cta-name',
          '$Estimated tax$'
        ),
        free: getTranslation(data, 'free', '$FREE$'),
        shipping: getTranslation(data, 'shipping', '$shipping$'),
        shippingDescription: getTranslation(
          data,
          'shippingDescription',
          '$An estimation how long processing and shipping might take$'
        ),
        rewardCardLabel: getTranslation(
          data,
          'rewardCardLabel',
          '$Reward Card$'
        ),
        giftCardLabel: getTranslation(data, 'giftCardLabel', '$Gift Card$'),
        removeSuccessMessage: getTranslation(
          data,
          'removeSuccessMessage',
          '$Your gift card has been successfully removed$'
        ),
        removeRewardCardSuccessMessage: getTranslation(
          data,
          'removeRewardCardSuccessMessage',
          '$Your reward card has been successfully removed$'
        ),
        remove: getTranslation(data, 'remove', '$Remove$'),
        removeCode: getTranslation(data, 'removeCode', '$Remove code'),
        removeCodeMessage: getTranslation(
          data,
          'removeCodeMessage',
          '$THE PROMOTION CODE HAS BEEN REMOVED$'
        ),
        productList: productListTranslations,
        shippingTooltip: extractRichText(
          data,
          'shipping-tooltip-richtext',
          '$Shipping$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        taxTooltip: extractRichText(
          data,
          'tax-tooltip-richtext',
          '$Tax$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        lifeTimeGuaranteeTitle: getDynamicField(
          data,
          'life-time-guarantee-title',
          '$Lifetime Guarantee$'
        ),
        lifeTimeGuarantee: extractRichText(
          data,
          'life-time-guarantee',
          '$Our products are fully warranted against defects in materials and workmanship with a lifetime guarantee. Exclusions apply* <a href="#">See Details</a>$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        freeReturnsTitle: getDynamicField(
          data,
          'free-returns-title',
          '$Free Returns$'
        ),
        freeReturns: extractRichText(
          data,
          'free-return',
          '$Returns are easy! Everyone gets free online and in-store returns$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        pickup: getTranslation(data, 'pickup', `$Pickup$`),
        inStorePickup: getTranslation(
          data,
          'inStorePickup',
          `$Pick up @ {{storeName}}$`
        ),
        pickupTooltip: extractRichText(
          data,
          'pickupTooltip',
          getTranslation(
            data,
            'pickupTooltip',
            `$Express orders placed before 12 pm (EST) Monday - Friday to be processed and shipped the same day. Please allow an additional 24-48 hours of processing time for orders placed after 12pm EST.$`
          ),
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        stsTooltip: extractRichText(
          data,
          'stsTooltip',
          getTranslation(
            data,
            'stsTooltip',
            `$The actual tax will be calculated based on the applicable state and local sales taxes where your order is shipped.$`
          ),
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        promotionLabel: getTranslation(
          data,
          'promotionLabel',
          '$Promo Applied$'
        ),
        promotionNotAppliedLabel: getTranslation(
          data,
          'promotionNotAppliedLabel',
          '$Promo Not Applied$'
        ),
        promotionNotActiveLabel: getTranslation(
          data,
          'promotionNotActiveLabel',
          '$Promo Not Active$'
        ),
        promotionNotActiveErrorMessage: getTranslation(
          data,
          'promotionNotActiveErrorMessage',
          '$The promotion code in your cart is no longer valid, do not worry we will remove the invalid coupon during the checkout process.$'
        ),
        athleteDiscount: getTranslation(
          data,
          'athleteDiscount',
          '$Athlete Discount$'
        ),
        appliedVouchersLabel: getTranslation(
          data,
          'appliedVouchersLabel',
          '$VANS FAMILY REWARDS$'
        ),
        springReward: getTranslation(
          data,
          'springReward',
          '$Spring {{year}} Reward$'
        ),
        summerReward: getTranslation(
          data,
          'summerReward',
          '$Summer {{year}} Reward$'
        ),
        winterReward: getTranslation(
          data,
          'winterReward',
          '$Winter {{year}} Reward$'
        ),
        legacyReward: getTranslation(data, 'legacyReward', '$Legacy Reward$'),
      },
      promoCodeTranslations: {
        close: getTranslation(data, 'close', '$Close$'),
        promoCodeTitle: getTranslation(data, 'promoCodeTitle', '$Promo code$'),
        ...getTranslation(data, 'promoCode', {
          title: '$Promo code$',
          label: '$Enter Promo Code$',
          btnApply: '$Apply$',
          removePromo: '$Remove Promo$',
          info: '$Where do I get promo codes?$',
          tooltip: '',
          addAnother: '$Add another promo$',
          promoApplied: '$Promo Applied$',
          notifications: {
            applied: '$The promotion code has been applied$',
            removed: '$The promotion code has been removed$',
            error:
              '$The promotion code you have entered is invalid. Please verify the code and try again.$',
          },
        }),
        couponApplied: getTranslation(
          data,
          'couponApplied',
          '$Coupon Applied$'
        ),
      },
      showApplePayComponent: !!applePayData.component,
      applePayTranslations: applePayData.translations,
    },
  };
};
