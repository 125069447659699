import { CMComponent, VFConfigComponent } from '@vf/api-contract';

/**
 * Method to extract data for tracking in the components
 * @param itemData
 */

type TrackingFieldKey =
  | 'data-tracking-enabled'
  | 'data-tracking-name'
  | 'data-tracking-position'
  | 'data-tracking-creative';

type TrackingData = {
  [key in TrackingFieldKey]: string | boolean;
};

export const dataTrackingDecorator = (
  cmsData: Exclude<CMComponent, VFConfigComponent>,
  mappedData
) => {
  if (!mappedData || (cmsData && !cmsData.enableTracking)) return mappedData;
  if (!mappedData.attrs) mappedData.attrs = {};
  Object.assign(mappedData.attrs, extractTrackingData(cmsData));
  return mappedData;
};

const extractTrackingData = (
  itemData: Exclude<CMComponent, VFConfigComponent>
): TrackingData => {
  const {
    enableTracking,
    tracking_position,
    tracking_creative,
    name,
  } = itemData;
  return {
    'data-tracking-enabled': enableTracking,
    'data-tracking-name': name,
    'data-tracking-position': tracking_position,
    'data-tracking-creative': tracking_creative,
  };
};
