import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { MappingMethod } from '../types';
import { extractProperty } from './utils';
import { ComposableContext, CmsSanitizationRequirements } from '../types';
import * as mappings from '../mappings';
import * as plainMappings from '../mappings/plain';
import { camelCase } from '../dataTransformators/utils/camelCase';

export const eSpot: MappingMethod = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const position = extractProperty(
    data,
    'localSettings.dynamicFields.position',
    0
  );
  const mobileWidth = parseInt(
    extractProperty(data, 'localSettings.dynamicFields.mobileWidth', '1'),
    10
  );
  const tabletWidth = parseInt(
    extractProperty(data, 'localSettings.dynamicFields.tabletWidth', '1'),
    10
  );
  const desktopWidth = parseInt(
    extractProperty(data, 'localSettings.dynamicFields.desktopWidth', '1'),
    10
  );
  const espotItem = data.localSettingsExpanded[0].links[0];
  let mappingMethod;
  const viewType = camelCase(espotItem.viewtype);

  if (
    !espotItem?.viewtype &&
    espotItem?.type &&
    plainMappings[espotItem.type]
  ) {
    mappingMethod = plainMappings[espotItem.type];
  } else if (espotItem.viewtype) {
    mappingMethod = mappings[viewType];
  }

  const component =
    mappingMethod?.(
      espotItem,
      context,
      siteConfiguration,
      cmsBaseUri,
      requirements
    ) || null;

  return {
    component: 'shared/ESpotPreviewGrid',
    props: {
      mobileWidth,
      tabletWidth,
      desktopWidth,
      position,
      component,
      contextKey: context.contextKey,
    },
  };
};
