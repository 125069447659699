import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { IConsumerProfile, RequestHeader } from '../../types';
import { ApiClientConfiguration } from '../../configuration';

export type BasicInformationResponse = {
  consumerProfile: IConsumerProfile;
};

export const getBasicInformation = (configuration: ApiClientConfiguration) => (
  query: string,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<BasicInformationResponse>> => {
  let url = urlBuilder(
    endpoints.get(configuration).customer.getBasicInformation,
    {
      config: configuration,
    }
  );
  url = `${url}?${query}`;

  return axios.get(url, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
