import {
  CMPlaceholder,
  CMCollection,
  CmsMenuResponse,
  CmsPageContent,
  CmsSite,
} from '@vf/api-contract';
import { headerAndFooter } from './utils/components/headerAndFooter';
import { transformPageContentData } from './utils/transformPageContentData';
import { parseDataBasedOnType } from './utils/parseDataBasedOnType';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
  CmsHeaderConfig,
  CmsEspot,
} from '../types';
import { standardComponentDataParser } from './utils/components/standard';
import { flexibleContentByPath } from './utils/components/flexibleContentByPath';

export const sanitizeCmsPageContentResponse = (
  content: CmsPageContent,
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => {
  const initialData = {
    children: [],
  };

  if (!content) {
    return {
      data: initialData,
      errors: [
        'No content provided for Page data transformer (page response is empty or invalid?).',
      ],
    };
  }

  try {
    const componentParser = standardComponentDataParser(
      siteConfiguration,
      context,
      cmsBaseUri,
      requirements,
      pageTypeName
    );

    const decoratedContent = transformPageContentData(
      siteConfiguration,
      cmsBaseUri,
      content,
      componentParser,
      requirements,
      context,
      pageTypeName
    );

    return {
      data: decoratedContent.data,
      errors: decoratedContent.errors,
    };
  } catch (e) {
    return {
      data: initialData,
      errors: [`Error while trying to parse CMS response: ${e.message}`],
    };
  }
};

export const sanitizeCmsHeaderAndFooterResponse = (
  response: CmsMenuResponse,
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string,
  headerConfig?: CmsHeaderConfig
) => {
  const initialData = {
    header: { children: [] },
    footer: { children: [] },
  };

  if (!response) {
    return {
      data: initialData,
      errors: [
        'No content provided for Header and Footer transformer (menu response is empty or invalid?).',
      ],
    };
  }
  try {
    const content = response.menu;
    const parser = headerAndFooter(
      siteConfiguration,
      context,
      cmsBaseUri,
      headerConfig
    );

    const errors = [];

    const headerChildren = [];
    const footerChildren = [];

    try {
      headerChildren.push(parser(content.header, 'header', content.menu));
    } catch (e) {
      errors.push(`Header parsing error: ${e.message}`);
    }
    try {
      footerChildren.push(parser(content.footer, 'footer', content.menu));
    } catch (e) {
      errors.push(`Footer parsing error: ${e.message}`);
    }

    return {
      data: {
        header: { children: headerChildren },
        footer: { children: footerChildren },
      },
      errors,
    };
  } catch (e) {
    console.error(`Header/Footer parsing error: ${e.message}`);
    return {
      data: initialData,
      errors: [`Header and Footer transformer error: ${e.message}`],
    };
  }
};

export const sanitizeCmsEspotsResponse = (
  espots: CMPlaceholder[],
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => {
  const initialData = [] as CmsEspot[];

  if (!espots || espots.length === 0) {
    return {
      data: initialData,
      errors: [],
    };
  }

  try {
    const componentParser = standardComponentDataParser(
      siteConfiguration,
      context,
      cmsBaseUri,
      requirements,
      pageTypeName
    );

    const errors = [];
    const data = espots.map((espot) => {
      const component = espot.localSettingsExpanded.find(
        (setting) => setting.path === 'dynamicFields.component'
      );
      const position = espot.localSettings.dynamicFields?.position;
      const mobileWidth = espot.localSettings.dynamicFields?.mobileWidth;
      const desktopWidth = espot.localSettings.dynamicFields?.desktopWidth;
      const tabletWidth = espot.localSettings.dynamicFields?.tabletWidth;

      const componentParsed = parseDataBasedOnType(
        component.links[0],
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        pageTypeName
      );
      if (componentParsed.errors.length) {
        errors.push(...componentParsed.errors);
      }

      return {
        position,
        mobileWidth,
        tabletWidth,
        desktopWidth,
        component: componentParsed.data,
      } as CmsEspot;
    });

    return {
      data,
      errors,
    };
  } catch (e) {
    return {
      data: initialData,
      errors: [`Error while trying to parse CMS response: ${e.message}`],
    };
  }
};

export const sanitizeCmsVisualFiltersResponse = (
  visualFilter: CMCollection,
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => {
  const initialData = {};

  if (!visualFilter) {
    return {
      data: initialData,
      errors: [],
    };
  }

  try {
    const componentParser = standardComponentDataParser(
      siteConfiguration,
      context,
      cmsBaseUri,
      requirements,
      pageTypeName
    );

    const errors = [];
    const data = componentParser(visualFilter);

    return {
      data,
      errors,
    };
  } catch (e) {
    return {
      data: initialData,
      errors: [`Error while trying to parse CMS response: ${e.message}`],
    };
  }
};

export const sanitizeCmsFlexibleContentByPathResponse = (
  flexibleContentByPathResult: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const initialData = {
    flexContentByPathResult: null,
  };

  if (!flexibleContentByPathResult.length) {
    return {
      data: initialData,
      errors: [
        'No content provided for Flex Content By Path transformer (flexContent response is empty or invalid?).',
      ],
    };
  }

  const parser = flexibleContentByPath(siteConfiguration, context, cmsBaseUri);

  const errors = [];

  let flexContentChildren = [];

  try {
    flexContentChildren = parser(flexibleContentByPathResult);

    return {
      data: {
        flexContentByPathResult: { children: flexContentChildren },
      },
      errors,
    };
  } catch (e) {
    return {
      data: initialData,
      errors: [`Flexible Content transformer error: ${e.message}`],
    };
  }
};
