import {
  extractProperty,
  getTitle,
  getVideoObject,
  getVideoResponsiveMedia,
  generateLinkFromTarget,
} from './utils';
import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const customShoesTopLeft = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'customs/CustomShoesTopLeft',
    props: {
      video: {
        small: getVideoObject(data.videos, cmsBaseUri),
        medium:
          extractProperty(data, 'responsiveMedia.medium[0].type', '') ===
          'CMVideo'
            ? getVideoResponsiveMedia(data, cmsBaseUri, 'medium')
            : null,
        large:
          extractProperty(data, 'responsiveMedia.large[0].type', '') ===
          'CMVideo'
            ? getVideoResponsiveMedia(data, cmsBaseUri, 'large')
            : null,
      },
      subheading: getTitle(data),
      links: data.teaserTargets.map((item) => {
        return {
          title: extractProperty(item, 'callToActionText'),
          target: generateLinkFromTarget(
            item.target,
            context,
            siteConfiguration,
            cmsBaseUri,
            data.scrollToComponent
          ),
        };
      }),
    },
  };
};
