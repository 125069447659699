import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import {
  SetDefaultCustomerCreditCardRequest,
  SetDefaultCustomerCreditCardResponse,
} from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const setDefaultPaymentInstrument = (
  configuration: ApiClientConfiguration
) => (
  instrumentId: string,
  data: SetDefaultCustomerCreditCardRequest
): Promise<AxiosResponse<SetDefaultCustomerCreditCardResponse>> => {
  return axios.patch(
    urlBuilder(
      endpoints.get(configuration).customer.setDefaultPaymentInstrument,
      {
        config: configuration,
        mapping: {
          instrumentId,
        },
      }
    ),
    data,
    {
      headers: headerBuilder(configuration),
    }
  );
};
