import { CmsPlacement } from '@vf/api-contract';
import { FacetCmsConfiguration } from '../../../types';
import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
} from '../../../mappings/utils';

/**
 * Normalize array of facet configuration into object with proper keys.
 * It allows front end to perform quick and easy check if certain key exists:
 * !!normalizedData[key]
 * Instead of doing .find or .filter which is looping through all items in array.
 * @param placement
 */
export const normalizeFacetConfiguration = (
  placement: CmsPlacement,
  cmsBaseUri: string
): Record<string, FacetCmsConfiguration> => {
  return placement.pageItems.reduce((normalized, pageItem) => {
    const config: FacetCmsConfiguration =
      pageItem?.localSettings?.config?.['facet-configuration'];

    if (
      pageItem.type === 'VFConfigComponent' &&
      validateFacetConfiguration(config)
    ) {
      /**
       * Assign the same reference to both keys (code and fallback)
       * It won't consume much memory and will allow us to quickly check
       * for facet key existence
       */
      normalized[config.code] = config;
      if (config.fallbackCode) {
        normalized[config.fallbackCode] = config;
      }
      if (config.icons) {
        const links = extractLocalSettingsExpandedLinks(
          pageItem,
          'icons',
          'config.facet-configuration'
        );
        normalized[config.code].icons = links.reduce((acc, item) => {
          const url = extractProperty(item, 'data.uri');
          acc[item.name] = `${cmsBaseUri}${url}`;
          return acc;
        }, {});
      }
    }

    return normalized;
  }, {});
};

const validateFacetConfiguration = (
  config?: Partial<FacetCmsConfiguration>
) => {
  return (
    !!config?.code &&
    !!config?.displayType &&
    typeof config?.crawlable === 'boolean'
  );
};
