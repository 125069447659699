














import { defineComponent } from '@vue/composition-api';
import SimplifiedEnrollmentHeader from './SimplifiedEnrollmentHeader.vue';

export default defineComponent({
  name: 'SimplifiedEnrollmentConfirmation',
  components: {
    SimplifiedEnrollmentHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    html: {
      type: Boolean,
      default: false,
    },
    hasParentTag: {
      type: Boolean,
      default: false,
    },
  },
});
