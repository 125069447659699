import { apiClientFactory } from '@vf/api-client';
import { errorMessages } from '../../../utils/errorMessages';

export const useGiftCards = (instance) => {
  const { checkGiftCardBalance: checkGiftCardBalanceAPI } = apiClientFactory(
    instance
  );
  const { displayErrorMessages } = errorMessages(instance);

  const checkGiftCardBalance = async (data) => {
    try {
      return await checkGiftCardBalanceAPI({
        gc_number: data.cardNumber,
        gc_pin: data.pin,
        g_recaptcha_response: data.recaptcha,
      });
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  return {
    checkGiftCardBalance,
  };
};
