export const dataListingTable = () => {
  return {
    //   dynamic rendering component according to context name
    component: 'addressBook/ShippingAddressPicker',
    attrs: {
      'data-id': 'dataId',
    },
    props: {
      translations: {
        heading: '',
        subtitle: '',
        buttonText: '',
        editButton: '',
        deleteButton: '',
      },
      showEditButton: true,
      showDeletButton: true,
      showDefaultAddressOnTopRow: false,
    },
  };
};
