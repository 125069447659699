import jsonp from 'jsonp';
import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { Scene7GalleryResponse } from '@vf/api-contract';
import { ComponentInstance } from '../../../../composables/src/types';
import { ApiClientConfiguration } from '../../configuration';

export const getGalleryScene7 = (
  configuration: ApiClientConfiguration
) => async (
  productId: string,
  instance: ComponentInstance
): Promise<Scene7GalleryResponse> => {
  const hostname = instance.$env.S7_IMAGES_HOSTNAME;
  const S7ImageCompany = instance.$env.S7_IMAGES_COMPANY;
  const S7MediaType = instance.$env.S7_PDP_MEDIA_TYPE;
  const timeout = parseInt(instance.$env.S7_TIMEOUT);

  /** Generated callback function name to be used in Scene7 JSONP response */
  const jsonpCallbackFunctionName = `scene7cb_${productId}`;

  const url: string = `${endpoints.get(configuration).images.gallery.scene7}`
    .replace('{hostname}', hostname)
    .replace('{S7ImageCompany}', S7ImageCompany)
    .replace('{S7MediaType}', S7MediaType)
    .replace('{productId}', productId)
    .replace('{callbackFnName}', jsonpCallbackFunctionName);

  if (typeof window !== 'undefined') {
    /** JSONP client side solution for getting scene7 images gallery */
    return new Promise((resolve, reject) => {
      jsonp(url, { name: jsonpCallbackFunctionName }, (err, data) => {
        if (err) {
          return reject(err);
        }

        resolve(data);
      });
    });
  } else {
    /** Server side solution for getting scene7 images gallery */
    const response = await axios.get<string>(url, { timeout });

    /** Instantiate variable to hold gallery data */
    let galleryResponse = null;

    /** catch possible error triggered from scene7 */
    global.s7jsonError = (scene7ErrorObj) => {
      throw new Error(
        `[@vf/api-client/api/images/getGalleryScene7::getGalleryScene7] scene7 error for productId: ${productId} obj scene7 returned: ${JSON.stringify(
          scene7ErrorObj
        )}`
      );
    };

    /* Declare global method to be called from JSONP response */
    global[jsonpCallbackFunctionName] = (data) => {
      data.lastModified = response.headers['last-modified']
        ? new Date(response.headers['last-modified']).toISOString()
        : '';
      galleryResponse = data;
    };

    /** Call JSONP scene7 function */
    new Function(response.data)();

    /** Remove declared global function */
    delete global[jsonpCallbackFunctionName];

    /** Return stored gallery data */
    return galleryResponse;
  }
};
