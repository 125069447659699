import { Product } from '@vf/api-client';
import { ROUTES } from '../../utils/routes';
import { ComponentInstance } from '../../types';
import { GetProductRedirectUrl, GetProductUrlFactory } from '../types';
import { formatUrlName, normalizeUrl } from './urlHelpers';
import { PRODUCT_ID_REGEX, OLD_PRODUCT_ID_REGEX } from './patterns';

const generateProductUrl = (
  id: string,
  name: string,
  qs?: { [key: string]: string }
): string => {
  if (
    id &&
    (PRODUCT_ID_REGEX.test(id) ||
      (qs && qs.recipe && OLD_PRODUCT_ID_REGEX.test(id)))
  ) {
    return `/${formatUrlName(name)}-p${id}`;
  }

  return `/product/${id}`;
};

export const getProductUrlFactory = (
  instance: ComponentInstance
): GetProductUrlFactory => {
  const getProductUrl = (
    product: Product,
    qs?: { [key: string]: string },
    isCanonical: boolean = false
  ): string => {
    const {
      pageUrl,
      fullPageUrl,
      pdpUrl,
      primaryCategorySlug,
      productSlug,
      customsCustomizer,
      model,
    } = product;
    const productId = product.id || product.masterId;
    const productName = product.name;
    const customsUrl = product.customsExternalurl ?? product.customsExternalUrl;
    const queryString = !isCanonical ? qs : {};

    // pdpUrl present no need to generate url
    if (pdpUrl) {
      return normalizeUrl(instance, pdpUrl, queryString);
    }

    // Customs fallback
    if (customsCustomizer && customsUrl) {
      return normalizeUrl(instance, customsUrl, queryString);
    }

    // If canonical URL is requested, return just fullPageUrl with hostname and without query string
    if (isCanonical && fullPageUrl) {
      return fullPageUrl;
    }

    if (pageUrl) {
      return normalizeUrl(instance, pageUrl, queryString);
    }

    if (fullPageUrl) {
      return normalizeUrl(instance, fullPageUrl, queryString);
    }

    if (primaryCategorySlug && productSlug) {
      return normalizeUrl(
        instance,
        `${primaryCategorySlug}/${productSlug}`,
        queryString
      );
    }

    // Standard products missing url properties
    const slug = generateProductUrl(productId, productName, queryString);
    if (primaryCategorySlug) {
      return normalizeUrl(
        instance,
        `${primaryCategorySlug}/${slug}`,
        queryString
      );
    }

    if (model) {
      return normalizeUrl(
        instance,
        `${ROUTES.CUSTOMIZER()}.${model}.html`,
        queryString
      );
    }

    return normalizeUrl(instance, `${slug}`, queryString);
  };

  const getProductRedirectUrl = (
    product: Product,
    qs?: { [key: string]: string }
  ): GetProductRedirectUrl => {
    const url = getProductUrl(product, qs);
    const customsUrl = product.customsExternalurl ?? product.customsExternalUrl;
    const outOfStock = url && url.includes(product.primaryCategorySlug);
    const customizerRedirect = url && url === customsUrl;
    const permanent = !customizerRedirect;

    return {
      url,
      permanent,
      outOfStock,
    };
  };

  const getProductCanonicalUrl = (product: Product): string => {
    return getProductUrl(product, {}, true);
  };

  return {
    getProductUrl,
    getProductRedirectUrl,
    getProductCanonicalUrl,
  };
};
