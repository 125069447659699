import type { RewardCard, RewardCode } from '@vf/api-contract';
import type { Reward } from '@vf/composables/src/store/rewards';
import { maskGiftCardNumber } from '@vf/composables/src/utils/giftCard';

export const mapRewardCardToReward = (
  card: RewardCard,
  formatPrice: (number) => string
): Reward => ({
  id: card.gcNumber,
  maskedNumber: maskGiftCardNumber(card.gcNumber, 4, 'X'),
  paymentMethodId: card.paymentMethodId,
  cardType: card.cardType,
  paymentInstrumentId: card.paymentInstrumentId,
  balance: formatPrice(card.gcBalance),
  rawBalance: card.gcBalance,
  expireDateTime: card.expirationDate,
  issueDateTime: card.issueDate,
});

export const mapRewardCodeToReward = (
  code: RewardCode,
  formatPrice: (number) => string
): Reward => ({
  id: code.paymentInstrumentId,
  paymentMethodId: code.paymentMethodId,
  cardType: code.cardType,
  paymentInstrumentId: code.paymentInstrumentId,
  balance: formatPrice(code.amount),
  rawBalance: code.amount,
  expireDateTime: code.expireDateTime,
  issueDateTime: code.issueDateTime,
  rewardCategory: code.rewardCategory,
  authorized: Boolean(code.authorized),
});
