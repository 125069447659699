import { UrlParseResult } from '../types';
import { ComponentInstance } from '../../types';
import { useRouting } from '../../useRouting';
import { ROUTES } from '../../utils/routes';
import {
  CATEGORY_URL_SEGMENT_REGEX,
  PRODUCT_URL_SEGMENT_REGEX,
  OLD_PRODUCT_URL_SEGMENT_REGEX,
} from './patterns';

const getLastUrlSegment = (url: string): string => {
  const urlSegments = url
    .split('/')
    .filter((segment) => !!segment)
    .map((segment) => segment.replace(/\//g, ''));
  return urlSegments[urlSegments.length - 1];
};

const isProductUrl = (url: string) => url.startsWith('/product');
const isCategoryUrl = (url: string) => url.startsWith('/category');
const isTagExploreUrl = (url: string) =>
  (url + '/').startsWith('/tag/') || (url + '/').startsWith('/explore/');
const isCustomizerUrl = (url: string) => url.startsWith('/customizer');
const isSearchUrl = (url: string) => url.startsWith('/search/');

export const isProductSegment = (
  segment: string,
  qs?: { [key: string]: string }
) => {
  /**
   * early check for edge case. (sw|vn|nf)([a-z0-9]{6,10}) regex treats 'confirmation' string as product id
   */
  if (!segment || segment.includes('confirmation')) return false;
  return (
    PRODUCT_URL_SEGMENT_REGEX.test(segment) ||
    (qs && qs.recipe && OLD_PRODUCT_URL_SEGMENT_REGEX.test(segment))
  );
};

export const extractProductIdFromUrl = (url: string) => {
  if (!url) {
    return;
  }

  const [path] = url.split('?');

  const match =
    path.match(PRODUCT_URL_SEGMENT_REGEX) ||
    path.match(OLD_PRODUCT_URL_SEGMENT_REGEX);

  if (match) {
    const [id] = match;

    return id.split('-p').pop().toUpperCase();
  }
};

export const isCategorySegment = (segment: string) =>
  CATEGORY_URL_SEGMENT_REGEX.test(segment);

export const getId = (url: string): string | null => {
  const idSegment = getLastUrlSegment(url);

  if (isProductUrl(url) || isCategoryUrl(url) || isTagExploreUrl(url)) {
    return idSegment;
  }

  const idPart = idSegment.split(/-(c|p)/).pop();

  if (idPart) {
    return idPart;
  }

  return null;
};

type RouteDefinition = {
  path: string;
  contextKey?: string;
};

const normalizeUrl = (url: string) => url.replace(/\/$/, '');

const shouldHideComponent = (
  routes: RouteDefinition[],
  url: string,
  contextKey?: string
) =>
  routes
    .filter(
      (route) =>
        !contextKey || !route.contextKey || route.contextKey === contextKey
    )
    .some((route) => url.startsWith(normalizeUrl(route.path)));

const shouldHideFooter = (url: string, contextKey?: string) =>
  shouldHideComponent(
    [
      { path: ROUTES.CUSTOMIZER() },
      { path: ROUTES.PRODUCT_REVIEW(), contextKey: 'pr-header' },
      { path: ROUTES.STORE_LOCATOR_BASE(), contextKey: 'pr-header' },
      { path: ROUTES.CHECKOUT_SHIPPING() },
      { path: ROUTES.CHECKOUT_PAYMENT() },
    ],
    url,
    contextKey
  );

const shouldHideHeader = (url: string, contextKey?: string) =>
  shouldHideComponent(
    [
      { path: ROUTES.PRODUCT_REVIEW(), contextKey: 'pr-footer' },
      { path: ROUTES.STORE_LOCATOR_BASE(), contextKey: 'pr-footer' },
      { path: ROUTES.CHECKOUT_SHIPPING() },
      { path: ROUTES.CHECKOUT_PAYMENT() },
    ],
    url,
    contextKey
  );

const shouldHidePageContent = (url: string, contextKey?: string) =>
  shouldHideComponent(
    [
      // { path: ROUTES.CUSTOMIZER() }, We should have content by this path to make Mini Cart configurable by CMS on customs page https://digital.vfc.com/jira/browse/GLOBAL15-3322
      { path: ROUTES.PRODUCT_REVIEW() },
      { path: ROUTES.STORE_LOCATOR_BASE() },
    ],
    url,
    contextKey
  );

export const parseUrlFactory = (
  instance: ComponentInstance,
  contextKey?: string
) => {
  const { getPathWithoutLocalization } = useRouting(instance);
  return (
    localizedUrl: string,
    qs?: { [key: string]: string }
  ): UrlParseResult => {
    const url = getPathWithoutLocalization(localizedUrl);
    const lastSegment = getLastUrlSegment(url);
    const result: UrlParseResult = {
      hideFooter: shouldHideFooter(url, contextKey),
      hideHeader: shouldHideHeader(url, contextKey),
      hidePageContent: shouldHidePageContent(url, contextKey),
    };

    if (isProductUrl(url) || isProductSegment(lastSegment, qs)) {
      result.resourceType = 'product';
      result.resourceId = getId(url).toUpperCase();
    } else if (isCategoryUrl(url) || isCategorySegment(lastSegment)) {
      result.resourceType = 'category';
      result.resourceId = getId(url);
    } else if (isTagExploreUrl(url)) {
      result.resourceType = 'tag';
    } else if (isCustomizerUrl(url)) {
      result.resourceType = 'customizer';
    } else if (isSearchUrl(url)) {
      result.resourceType = 'search';
    } else if (url === '/') {
      result.resourceType = 'homepage';
    }
    return result;
  };
};
