import { CMPlaceholder } from '@vf/api-contract';
import { getDynamicField } from './utils';

export const lottieLoader = (data: CMPlaceholder) => {
  return {
    component: 'shared/LottieLoader',
    props: {
      animation: getDynamicField(data, 'JSON'),
      minumumLoading: getDynamicField(data, 'minumumLoading'),
      optionalTimeout: getDynamicField(data, 'optionalTimeout'),
      endLoadingEvent: getDynamicField(data, 'endLoadingEvent', '')
        .split(',')
        .map((event: string) => event.trim())
        .filter((el) => el),
      source: getDynamicField(data, 'source', ''),
      width: getDynamicField(data, 'width'),
      height: getDynamicField(data, 'height'),
    },
  };
};
