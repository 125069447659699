import { easyHero } from './easyHero';
import { CMContentHero, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const easyBannerImage = (
  data: CMContentHero,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  // Banner is basically a hero with some slight UI changes
  const easyBannerData = easyHero(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );
  easyBannerData.props.variant = 'banner';

  return easyBannerData;
};
