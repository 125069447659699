import { apiClientFactory } from '@vf/api-client';
import { useUrl, useI18n } from '@vf/composables';
import {
  SocialNativePagination,
  SocialNativeOptions,
  PhotorankApiSignInRequest,
  PhotorankApiAddMediaRequest,
} from '@vf/api-client/src/api-types';
import { ComponentInstance } from '../types';
import type { WidgetName, WidgetType } from './types';
import {
  extractCommonConfigLink,
  extractProperty,
} from '../useCms/mappings/utils';
import { useCmsRefStore } from '../store/cmsRef';

const useSocialNative = (instance: ComponentInstance) => {
  const {
    getSNAuth,
    getSNMediaByCategoryId,
    getSNMediaByCustomer,
    getSNMediaByStream,
    getSNMediaByTagKey,
    getSNStreamByMedia,
    createSNUser,
    addSNMedia,
    trackSocialNativeWidgetEvent,
    trackSocialNativeMediaEvent,
    trackSocialNativeProductShopEvent,
    trackSocialNativeCheckoutEvent,
  } = apiClientFactory(instance);
  let lastNextCallForGallery: SocialNativePagination = {
    media: [],
    nextLink: null,
  };
  const { getRelativeUrl } = useUrl(instance);
  const { localeCode } = useI18n(instance);
  const cmsRefStore = useCmsRefStore();

  const uploadMediaModalId = extractProperty(
    extractCommonConfigLink(
      cmsRefStore.cmsSiteConfiguration,
      'socialnative-media-upload-modal'
    ),
    '[0]'
  )?.id;

  let customerId: string | null = null;

  // call and cache next call to check if last items cover all count request
  const getMediaForGallery = async ({
    apiKey,
    count = 20,
    pageNumber = 1,
    nextCount,
    nextPageNumber,
  }: {
    apiKey: string;
    count?: number;
    pageNumber?: number;
    nextCount?: number;
    nextPageNumber?: number;
  }): Promise<SocialNativePagination> => {
    const getRequestMedia = () =>
      lastNextCallForGallery.media.length
        ? Promise.resolve(lastNextCallForGallery)
        : getSNMediaByCustomer({
            apiKey,
            customerId,
            count,
            pageNumber,
          });

    if (customerId === null) {
      customerId = await getSNAuth(apiKey);
    }

    const getNextMedia = () =>
      getSNMediaByCustomer({
        apiKey,
        customerId,
        count: nextCount || count,
        pageNumber: nextPageNumber || pageNumber + 1,
      }).catch(() => ({
        media: [],
        nextLink: null,
      }));

    return Promise.all([getRequestMedia(), getNextMedia()]).then(
      ([currentMedia, nextGalleryCall]) => {
        // Next call is usefull to understand if next item fill all count request
        lastNextCallForGallery = nextGalleryCall;
        return {
          ...currentMedia,
          nextLink:
            nextGalleryCall.media.length >= (nextCount || count)
              ? currentMedia.nextLink
              : null,
        };
      }
    );
  };

  const getMediaByStream = ({
    apiKey,
    streamId,
    count = 20,
    pageNumber = 1,
  }: {
    apiKey: string;
    streamId: string;
    count?: number;
    pageNumber?: number;
  }) => {
    return getSNMediaByStream({
      apiKey,
      streamId,
      count,
      pageNumber,
    });
  };

  const getMediaByCategoryId = ({
    apiKey,
    categoryId,
    count = 20,
    pageNumber = 1,
  }: {
    apiKey: string;
    categoryId: string;
    count?: number;
    pageNumber?: number;
  }) =>
    getSNMediaByCategoryId({
      apiKey,
      categoryId,
      count,
      pageNumber,
    });

  const getMediaByTagKey = ({
    apiKey,
    tagKey,
    count = 20,
    pageNumber = 1,
  }: {
    apiKey: string;
    tagKey: string;
    count?: number;
    pageNumber?: number;
  }) =>
    getSNMediaByTagKey({
      apiKey,
      tagKey,
      count,
      pageNumber,
    });

  /**
   * (workaround) this extra effort is necessary because API product not return localized link but only english,
   * for this reason we put relative path with right locale for english link (en-ca/en-us)
   * end for FR create full refresh page, using absolute path (ssrProductLink)
   */
  const getRelativePathLocalized = (productUrl: string) => {
    const relativeUrl = getRelativeUrl(productUrl);
    // remove locale and update with correct one
    const indexSlashAfterLocale = relativeUrl.indexOf('/', 1); // fromIndex=1 skip first /
    const urlNoLocale = relativeUrl.substring(indexSlashAfterLocale);
    return `/${localeCode()}${urlNoLocale}`;
  };

  const getStreamByMedia = ({
    apiKey,
    mediaId,
    productNameFallback,
  }: {
    apiKey: string;
    mediaId: string;
    productNameFallback: string;
  }) =>
    getSNStreamByMedia(apiKey, mediaId).then((products) => {
      const {
        productNameFallback: useProductNameFallback,
        ssrProductLink,
      } = instance.$getEnvValueByCurrentLocale<SocialNativeOptions>(
        'SOCIAL_NATIVE_OPTIONS'
      );

      const pathDomain = ssrProductLink ? location.origin : '';
      return products.map((product) => ({
        ...product,
        displayName: useProductNameFallback
          ? productNameFallback
          : product.name,
        url: pathDomain + getRelativePathLocalized(product.product_url),
      }));
    });

  const createUser = (apiKey: string, data: PhotorankApiSignInRequest) =>
    createSNUser(apiKey, data);

  const postMedia = (
    apiKey: string,
    userId: string,
    params: PhotorankApiAddMediaRequest
  ) => addSNMedia(apiKey, userId, params);

  const getAnalyticsId = () => {
    const cookieName = '__olapicU';
    let analyticsId = instance.$cookies.get(cookieName);

    if (!analyticsId) {
      analyticsId = randomString(32);

      const daysToLive = 30;
      instance.$cookies.set(cookieName, analyticsId, {
        maxAge: daysToLive * 24 * 60 * 60,
      });
    }

    return analyticsId;
  };

  const getInstanceId = (widgetName: WidgetName) => {
    return instance.$getEnvValueByCurrentLocale(
      'SOCIAL_NATIVE_ANALYTICS_WIDGET_ID'
    )[widgetName];
  };

  const createTrackSocialNativeEvent = (
    callAnalyticsApi:
      | typeof trackSocialNativeWidgetEvent
      | typeof trackSocialNativeMediaEvent
      | typeof trackSocialNativeProductShopEvent
  ) => (
    widgetType: WidgetType,
    widgetName: WidgetName,
    options: {
      apiKey: string;
      action: string;
      pics: number;
      categoryId?: string;
      streamId?: string;
      mediaId?: string;
    }
  ) => {
    const { apiKey, categoryId, streamId, action, pics, mediaId } = options;
    const instanceId = getInstanceId(widgetName);
    const analyticsId = getAnalyticsId();

    return callAnalyticsApi(
      widgetType,
      {
        instanceId,
        categoryId,
        streamId,
        action,
        mediaId,
      },
      {
        auth_token: apiKey,
        pics,
        analytics_id: analyticsId,
      },
      instance
    );
  };

  return {
    createUser,
    getMediaByCategoryId,
    getMediaByStream,
    getMediaForGallery,
    getMediaByTagKey,
    getStreamByMedia,
    postMedia,
    updatePlacementLayoutAndMedia,
    uploadMediaModalId,
    trackWidgetEvent: createTrackSocialNativeEvent(
      trackSocialNativeWidgetEvent
    ),
    trackMediaEvent: createTrackSocialNativeEvent(trackSocialNativeMediaEvent),
    trackProductShopEvent: createTrackSocialNativeEvent(
      trackSocialNativeProductShopEvent
    ),
    trackCheckoutEvent: (options, params) =>
      trackSocialNativeCheckoutEvent(options, params, instance),
    getAnalyticsId,
  };
};
/**
 * return item in column by use placementLayout
 *
 * @param {Array} items
 * @param {Array} placementLayout of max element in columns ex: [2,3,1]
 * @returns {Array} [[item1,item2],[item3,item4,item5],[item1],....]
 */
export const updatePlacementLayoutAndMedia = (
  items: any[],
  placementLayout: number[]
): any[][] => {
  let col = 0;
  let row = 0;
  const layoutAndMedia = [];
  let index = 0;
  items.forEach((item) => {
    if (row < placementLayout[col]) {
      row++;
    } else {
      row = 1;
      col = col + 1 < placementLayout.length ? col + 1 : 0;
      index++;
    }
    if (layoutAndMedia[index] === undefined) {
      layoutAndMedia[index] = [];
    }
    layoutAndMedia[index].push(item);
  });
  return layoutAndMedia;
};

function randomString(
  length,
  chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
) {
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
}

export default useSocialNative;
