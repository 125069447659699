import Vue from 'vue';

import LazyHydrate from 'vue-lazy-hydration';
import UiLink from '@vf/ui/components/Ui.Link.vue';
import ThemeLink from '@vf/ui/components/Theme.Link.vue';
import ThemeButton from '@vf/ui/components/Theme.Button.vue';
import ThemeProductLine from '@vf/ui/components/Theme.ProductLine.vue';
import UiScroll from '@vf/ui/components/Ui.Scroll.vue';
import UiSwiper from '@vf/ui/components/Ui.Swiper.vue';
import UiDropFile from '@vf/ui/components/Ui.DropFile.vue';
import ThemeTitle from '@vf/ui/components/Theme.Title.vue';
import VfRating from '@vf/ui/components/Molecule.Rating.vue';
import VfPrice from '@vf/ui/components/Atom.Price.vue';
import VfPriceRange from '@vf/ui/components/Atom.PriceRange.vue';
import VfProductCard from '@vf/ui/components/Organism.ProductCard.vue';
import VfProductBadge from '@vf/ui/components/Molecule.ProductBadge.vue';
import VfButton from '@vf/ui/components/Atom.Button.vue';
import VfIcon from '@vf/ui/components/Atom.Icon.vue';
import VfChevron from '@vf/ui/components/Atom.Chevron.vue';
import VfImage from '@vf/ui/components/Atom.Image.vue';
import VfPicture from '@vf/ui/components/Atom.Picture.vue';
import VfSpinner from '@vf/ui/components/Atom.Spinner.vue';
import VfLink from '@vf/ui/components/Atom.Link.vue';
import VfPageLoader from '@vf/ui/components/Atom.PageLoader.vue';
import VfText from '@vf/ui/components/Atom.VfText.vue';
import VfOverlay from '@vf/ui/components/Atom.Overlay.vue';
import VfFloatingButton from '@vf/ui/components/Molecule.FloatingButton.vue';
import VfListItem from '@vf/ui/components/Organism.ListItem.vue';
import VfList from '@vf/ui/components/Organism.List.vue';
import VfProperty from '@vf/ui/components/Molecule.Property.vue';
import VfSeparator from '@vf/ui/components/Molecule.Separator.vue';
import VfConditionalRenderer from '@vf/ui/components/Atom.ConditionalRenderer.vue';
import VfColumn from '@vf/ui/components/Atom.Column.vue';
import VfFrame from '@vf/ui/components/Atom.Frame.vue';
import VfNotification from '@vf/ui/components/Molecule.Notification.vue';
import VfBreadcrumbs from '@vf/ui/components/Molecule.Breadcrumbs.vue';
import VfFacet from '@vf/ui/components/Molecule.Facet.vue';
import VfFilterItem from '@vf/ui/components/Molecule.FilterItem.vue';
import VfColorSwatch from '@vf/ui/components/Molecule.ColorSwatch.vue';
import VfOptionsSwatch from '@vf/ui/components/Molecule.OptionsSwatch.vue';
import VfAccordion from '@vf/ui/components/Molecule.Accordion.vue';
import VfSticky from '@vf/ui/components/Molecule.Sticky.vue';
import VfObserver from '@vf/ui/utilities/intersection-observer/Observer.vue';
import ThenorthfaceThemeButton from '@vf/ui/components/thenorthface/Theme.Button.vue';
import VansThemeButton from '@vf/ui/components/vans/Theme.Button.vue';
import VfPhoneInput from '@vf/ui/components/Molecule.PhoneInput.vue';
import VfFlyoutContainer from '@vf/ui/components/Molecule.FlyoutContainer.vue';
import VfPopover from '@vf/ui/components/Atom.Popover.vue';

Vue.component('UiLink', UiLink);
Vue.component('ThemeLink', ThemeLink);
Vue.component('ThemeButton', ThemeButton);
Vue.component('ThemeProductLine', ThemeProductLine);
Vue.component('UiScroll', UiScroll);
Vue.component('UiSwiper', UiSwiper);
Vue.component('UiDropFile', UiDropFile);
Vue.component('ThemeTitle', ThemeTitle);
Vue.component('LazyHydrate', LazyHydrate);
Vue.component('VfRating', VfRating);
Vue.component('VfPrice', VfPrice);
Vue.component('VfPriceRange', VfPriceRange);
Vue.component('VfProductCard', VfProductCard);
Vue.component('VfButton', VfButton);
Vue.component('VfIcon', VfIcon);
Vue.component('VfChevron', VfChevron);
Vue.component('VfImage', VfImage);
Vue.component('VfPicture', VfPicture);
Vue.component('VfSpinner', VfSpinner);
Vue.component('VfLink', VfLink);
Vue.component('VfPageLoader', VfPageLoader);
Vue.component('VfText', VfText);
Vue.component('VfOverlay', VfOverlay);
Vue.component('VfFloatingButton', VfFloatingButton);
Vue.component('VfListItem', VfListItem);
Vue.component('VfList', VfList);
Vue.component('VfProperty', VfProperty);
Vue.component('VfSeparator', VfSeparator);
Vue.component('VfConditionalRenderer', VfConditionalRenderer);
Vue.component('VfColumn', VfColumn);
Vue.component('VfFrame', VfFrame);
Vue.component('VfNotification', VfNotification);
Vue.component('VfBreadcrumbs', VfBreadcrumbs);
Vue.component('VfFacet', VfFacet);
Vue.component('VfFilterItem', VfFilterItem);
Vue.component('VfColorSwatch', VfColorSwatch);
Vue.component('VfOptionsSwatch', VfOptionsSwatch);
Vue.component('VfAccordion', VfAccordion);
Vue.component('VfSticky', VfSticky);
Vue.component('VfObserver', VfObserver);
Vue.component('ThenorthfaceThemeButton', ThenorthfaceThemeButton);
Vue.component('VansThemeButton', VansThemeButton);
Vue.component('VfProductBadge', VfProductBadge);
Vue.component('VfPhoneInput', VfPhoneInput);
Vue.component('VfFlyoutContainer', VfFlyoutContainer);
Vue.component('VfPopover', VfPopover);

Vue.component(
  'ThenorthfaceThemeLink',
  () =>
    import(
      '@vf/ui/components/thenorthface/Theme.Link.vue' /* webpackChunkName: "uiTnf" */
    )
);

Vue.component(
  'VansThemeLink',
  () =>
    import(
      '@vf/ui/components/vans/Theme.Link.vue' /* webpackChunkName: "uiVans" */
    )
);

Vue.component(
  'ThenorthfaceThemeTitle',
  () =>
    import(
      '@vf/ui/components/thenorthface/Theme.Title.vue' /* webpackChunkName: "uiTnf" */
    )
);

Vue.component(
  'VansThemeTitle',
  () =>
    import(
      '@vf/ui/components/vans/Theme.Title.vue' /* webpackChunkName: "uiVans" */
    )
);

Vue.component(
  'VfMostLikedReview',
  () =>
    import(
      '@vf/ui/components/Molecule.MostLikedReview.vue' /* webpackChunkName: "uiReviews" */
    )
);

Vue.component(
  'VfTagGroup',
  () =>
    import(
      '@vf/ui/components/Organism.TagGroup.vue' /* webpackChunkName: "uiReviews" */
    )
);

Vue.component(
  'VfProgressBar',
  () =>
    import(
      '@vf/ui/components/Atom.ProgressBar.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfDivider',
  () =>
    import(
      '@vf/ui/components/Atom.Divider.vue' /* webpackChunkName: "uiGrid" */
    )
);

Vue.component(
  'VfProfileSummaryRow',
  () =>
    import(
      '@vf/ui/components/Atom.ProfileSummaryRow.vue' /* webpackChunkName: "uiProfile" */
    )
);

Vue.component(
  'VfVideo',
  () =>
    import('@vf/ui/components/Atom.Video.vue' /* webpackChunkName: "uiMedia" */)
);

Vue.component(
  'VfChip',
  () =>
    import(
      '@vf/ui/components/Atom.Chip.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfDate',
  () =>
    import(
      '@vf/ui/components/Atom.Date.vue' /* webpackChunkName: "uiSidebar" */
    )
);

Vue.component(
  'VfRow',
  () =>
    import('@vf/ui/components/Atom.Row.vue' /* webpackChunkName: "uiGrid" */)
);

Vue.component(
  'VfContainer',
  () =>
    import(
      '@vf/ui/components/Atom.Container.vue' /* webpackChunkName: "uiGrid" */
    )
);

// TODO: We should remove this component since it doesn't have any real benefits
Vue.component(
  'VfFrameLoader',
  () =>
    import(
      '@vf/ui/components/Atom.FrameLoader.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfLoyaltyInterestsItem',
  () =>
    import(
      '@vf/ui/components/Atom.LoyaltyInterestsItem.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfLottie',
  () =>
    import(
      '@vf/ui/components/Atom.Lottie.vue' /* webpackChunkName: "uiLottie" */
    )
);

Vue.component(
  'VfSelectOption',
  () =>
    import(
      '@vf/ui/components/Atom.VfSelectOption.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfRadio',
  () =>
    import(
      '@vf/ui/components/Molecule.Radio.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfHeading',
  () =>
    import(
      '@vf/ui/components/Atom.VfHeading.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  // eslint-disable-next-line vue/component-definition-name-casing
  'VfBasicInformation_VARIANT_A',
  () =>
    import(
      '@vf/ui/components/Organism.BasicInformation.VARIANT_A.vue' /* webpackChunkName: "uiInfo" */
    )
);

Vue.component(
  // eslint-disable-next-line vue/component-definition-name-casing
  'VfBasicInformation_VARIANT_B',
  () =>
    import(
      '@vf/ui/components/Organism.BasicInformation.VARIANT_B.vue' /* webpackChunkName: "uiInfo" */
    )
);

Vue.component(
  'VfTooltip',
  () =>
    import(
      '@vf/ui/components/Molecule.Tooltip.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfAddressSummary',
  () =>
    import(
      '@vf/ui/components/Organism.AddressSummary.vue' /* webpackChunkName: "uiAddressSummary" */
    )
);

Vue.component(
  'VfCreditCardsSummary',
  () =>
    import(
      '@vf/ui/components/Organism.CreditCardsSummary.vue' /* webpackChunkName: "uiCreditCardsSummary" */
    )
);

Vue.component(
  'VfNotifyMe',
  () =>
    import(
      '@vf/ui/components/Organism.NotifyMe.vue' /* webpackChunkName: "uiCart" */
    )
);

Vue.component(
  'VfChangePasswordA',
  () =>
    import(
      '@vf/ui/components/Organism.ChangePassword.VARIANT_A.vue' /* webpackChunkName: "uiPassword" */
    )
);

// TODO: Remove this component since it's not in use
Vue.component(
  'VfChangePasswordB',
  () =>
    import(
      '@vf/ui/components/Organism.ChangePassword.VARIANT_B.vue' /* webpackChunkName: "uiPassword" */
    )
);

Vue.component(
  'VfInput',
  () =>
    import(
      '@vf/ui/components/Molecule.Input.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfGiftOption',
  () =>
    import(
      '@vf/ui/components/Molecule.GiftOption.vue' /* webpackChunkName: "uiCart" */
    )
);

Vue.component(
  'VfGiftOptionActions',
  () =>
    import(
      '@vf/ui/components/Molecule.GiftOptionActions.vue' /* webpackChunkName: "uiCart" */
    )
);

Vue.component(
  'VfCheckbox',
  () =>
    import(
      '@vf/ui/components/Molecule.Checkbox.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfPasswordHelper',
  () =>
    import(
      '@vf/ui/components/Molecule.PasswordHelper.vue' /* webpackChunkName: "uiPassword" */
    )
);

Vue.component(
  'VfModal',
  () =>
    import(
      '@vf/ui/components/Molecule.Modal.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfPanel',
  () =>
    import(
      '@vf/ui/components/Molecule.Panel.vue' /* webpackChunkName: "uiRedesignLayout" */
    )
);

Vue.component(
  'VfVideoTile',
  () =>
    import(
      '@vf/ui/components/Molecule.VideoTile.vue' /* webpackChunkName: "uiMedia" */
    )
);

Vue.component(
  'VfImageTile',
  () =>
    import(
      '@vf/ui/components/Molecule.ImageTile.vue' /* webpackChunkName: "uiMedia" */
    )
);

Vue.component(
  'VfBar',
  () =>
    import(
      '@vf/ui/components/Molecule.Bar.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfMenuItem',
  () =>
    import(
      '@vf/ui/components/Molecule.MenuItem.vue' /* webpackChunkName: "uiMenus" */
    )
);

Vue.component(
  'VfMobileMenuColumn',
  () =>
    import(
      '@vf/ui/components/Organism.MobileMenuColumn.vue' /* webpackChunkName: "uiMenus" */
    )
);

Vue.component(
  'VfTextArea',
  () =>
    import(
      '@vf/ui/components/Molecule.TextArea.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfCreateAccount',
  () =>
    import(
      '@vf/ui/components/Organism.CreateAccount.vue' /* webpackChunkName: "uiAccount" */
    )
);

Vue.component(
  'VfCreateAccountBirthdate',
  () =>
    import(
      '@vf/ui/components/Organism.CreateAccountBirthdate.vue' /* webpackChunkName: "uiAccount" */
    )
);

Vue.component(
  'VfSocialMediaLink',
  () =>
    import(
      '@vf/ui/components/Molecule.SocialMediaLink.vue' /* webpackChunkName: "uiAccount" */
    )
);

Vue.component(
  'VfEmailPreferences',
  () =>
    import(
      '@vf/ui/components/Organism.EmailPreferences.vue' /* webpackChunkName: "uiEmailPreferences" */
    )
);

Vue.component(
  'VfFavoritesTooltip',
  () =>
    import(
      '@vf/ui/components/Molecule.FavoritesTooltip.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfMobileMenu',
  () =>
    import(
      '@vf/ui/components/Organism.MobileMenu.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfUtilityNavigation',
  () =>
    import(
      '@vf/ui/components/Molecule.UtilityNavigation.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfContentPage',
  () =>
    import(
      '@vf/ui/components/Organism.ContentPage.vue' /* webpackChunkName: "uiMenu" */
    )
);

Vue.component(
  'VfGallery',
  () =>
    import(
      '@vf/ui/components/Organism.Gallery.vue' /* webpackChunkName: "uiGallery" */
    )
);

Vue.component(
  'VfSearchSuggestions',
  () =>
    import(
      '@vf/ui/components/Organism.SearchSuggestions.vue' /* webpackChunkName: "uiSearch" */
    )
);

Vue.component(
  'VfInterests',
  () =>
    import(
      '@vf/ui/components/Organism.Interests.vue' /* webpackChunkName: "uiInterests" */
    )
);

Vue.component(
  'VfYoutubeVideo',
  () =>
    import(
      '@vf/ui/components/Atom.YoutubeVideo.vue' /* webpackChunkName: "uiMedia" */
    )
);

Vue.component(
  'VfCheckoutHeader',
  () =>
    import(
      '@vf/ui/components/Organism.CheckoutHeader.vue' /* webpackChunkName: "uiRedesignCheckout" */
    )
);

Vue.component(
  'VfCollapsible',
  () =>
    import(
      '@vf/ui/components/Molecule.Collapsible.vue' /* webpackChunkName: "uiRedesignCheckout" */
    )
);

Vue.component(
  'VfSimplifiedHeader',
  () =>
    import(
      '@vf/ui/components/Organism.SimplifiedHeader.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfChatLink',
  () =>
    import(
      '@vf/ui/components/Atom.ChatLink.vue' /* webpackChunkName: "uiHelpers" */
    )
);

Vue.component(
  'VfSearchBar',
  () =>
    import(
      '@vf/ui/components/Molecule.SearchBar.vue' /* webpackChunkName: "uiSearch" */
    )
);

Vue.component(
  'VfVisualSearchBar',
  () =>
    import(
      '@vf/ui/components/Molecule.VisualSearchBar.vue' /* webpackChunkName: "uiSearch" */
    )
);

Vue.component(
  'VfShipmentSelector',
  () =>
    import(
      '@vf/ui/components/Organism.ShipmentSelector.vue' /* webpackChunkName: "uiCart" */
    )
);

Vue.component(
  'VfMegaMenu',
  () =>
    import(
      '@vf/ui/components/Organism.MegaMenu.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfSidebar',
  () =>
    import(
      '@vf/ui/components/Organism.Sidebar.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfQuantityPicker',
  () =>
    import(
      '@vf/ui/components/Molecule.QuantityPicker.vue' /* webpackChunkName: "uiCart" */
    )
);

Vue.component(
  'VfQuantitySelector',
  () =>
    import(
      '@vf/ui/components/Molecule.QuantitySelector.vue' /* webpackChunkName: "uiRedesignCart" */
    )
);

Vue.component(
  'VfOrderRegister',
  () =>
    import(
      '@vf/ui/components/Organism.OrderRegister.vue' /* webpackChunkName: "uiCheckout" */
    )
);

Vue.component(
  'VfCheckoutFooter',
  () =>
    import(
      '@vf/ui/components/Organism.CheckoutFooter.vue' /* webpackChunkName: "uiRedesignCheckout" */
    )
);

Vue.component(
  'VfSignIn',
  () =>
    import(
      '@vf/ui/components/Organism.SignIn.vue' /* webpackChunkName: "uiAccount" */
    )
);

Vue.component(
  'VfQuickShopTileOptions',
  () =>
    import(
      '@vf/ui/components/QuickShopTile/Molecule.QuickShopTileOptions.vue' /* webpackChunkName: "uiQuickshop" */
    )
);

Vue.component(
  'VfQuickShopTileButtons',
  () =>
    import(
      '@vf/ui/components/QuickShopTile/Molecule.QuickShopTileButtons.vue' /* webpackChunkName: "uiQuickshop" */
    )
);

Vue.component(
  'VfQuickShopTileToggle',
  () =>
    import(
      '@vf/ui/components/QuickShopTile/Molecule.QuickShopTileToggle.vue' /* webpackChunkName: "uiQuickshop" */
    )
);

Vue.component(
  'VfInterestsCollection',
  () =>
    import(
      '@vf/ui/components/Organism.InterestsCollection.vue' /* webpackChunkName: "uiIntresets" */
    )
);
Vue.component(
  'VfTeaserTile',
  () =>
    import(
      '@vf/ui/components/Organism.TeaserTile.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfPromoBar',
  () =>
    import(
      '@vf/ui/components/Molecule.PromoBar.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfUpsells',
  () =>
    import(
      '@vf/ui/components/Organism.Upsells.vue' /* webpackChunkName: "uiUpsells" */
    )
);

Vue.component(
  'VfFilterDropdown',
  () =>
    import(
      '@vf/ui/components/Molecule.FilterDropdown.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfFilterColor',
  () =>
    import(
      '@vf/ui/components/Molecule.FilterColor.vue' /* webpackChunkName: "uiPLP" */
    )
);

Vue.component(
  'VfSelectList',
  () =>
    import(
      '@vf/ui/components/Organism.SelectList.vue' /* webpackChunkName: "uiPLP" */
    )
);

Vue.component(
  'VfFilterCheckbox',
  () =>
    import(
      '@vf/ui/components/Molecule.FilterCheckbox.vue' /* webpackChunkName: "uiPLP" */
    )
);

Vue.component(
  'VfAppliedFilters',
  () =>
    import(
      '@vf/ui/components/Molecule.AppliedFilters.vue' /* webpackChunkName: "uiPLP" */
    )
);

Vue.component(
  'VfGridSizeSelector',
  () =>
    import(
      '@vf/ui/components/Molecule.GridSizeSelector.vue' /* webpackChunkName: "uiPLP" */
    )
);

Vue.component(
  'VfSelect',
  () =>
    import(
      '@vf/ui/components/Molecule.VfSelect.vue' /* webpackChunkName: "uiForms" */
    )
);

Vue.component(
  'VfPinnedArticle',
  () =>
    import(
      '@vf/ui/components/Organism.PinnedArticle.vue' /* webpackChunkName: "uiNews" */
    )
);

Vue.component(
  'VfOrderProduct',
  () =>
    import(
      '@vf/ui/components/Organism.OrderProduct.vue' /* webpackChunkName: "uiOrder" */
    )
);

Vue.component(
  'VfUpsellsCheckbox',
  () =>
    import(
      '@vf/ui/components/Organism.UpsellsCheckbox.vue' /* webpackChunkName: "uiUpsells" */
    )
);

Vue.component(
  'VfUpsellsItem',
  () =>
    import(
      '@vf/ui/components/Organism.UpsellsItem.vue' /* webpackChunkName: "uiUpsellsItem" */
    )
);

Vue.component(
  'VfCrossSellItem',
  () =>
    import(
      '@vf/ui/components/Molecule.CrossSellItem.vue' /* webpackChunkName: "uiCrossSellItem" */
    )
);

Vue.component(
  'VfControlledVideo',
  () =>
    import(
      '@vf/ui/components/Molecule.ControlledVideo.vue' /* webpackChunkName: "uiMedia" */
    )
);

Vue.component(
  'VfOrderShipmentLabel',
  () =>
    import(
      '@vf/ui/components/Molecule.OrderShipmentLabel.vue' /* webpackChunkName: "uiOrder" */
    )
);

Vue.component(
  'VfImageGrid',
  () =>
    import(
      '@vf/ui/components/Organism.ImageGrid.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfOrderProductSummary',
  () =>
    import(
      '@vf/ui/components/Organism.OrderProductSummary.vue' /* webpackChunkName: "uiOrder" */
    )
);
Vue.component(
  'VfOrderSummary',
  () =>
    import(
      '@vf/ui/components/Organism.OrderSummary.vue' /* webpackChunkName: "uiOrder" */
    )
);

Vue.component(
  'VfHelpful',
  () =>
    import(
      '@vf/ui/components/Molecule.Helpful.vue' /* webpackChunkName: "uiReviews" */
    )
);

Vue.component(
  'VfTag',
  () =>
    import(
      '@vf/ui/components/Molecule.Tag.vue' /* webpackChunkName: "uiReviews" */
    )
);

Vue.component(
  'VfReviewSubmittedBy',
  () =>
    import(
      '@vf/ui/components/Molecule.ReviewSubmittedBy.vue' /* webpackChunkName: "uiReviews" */
    )
);
Vue.component(
  'VfReviewCategories',
  () =>
    import(
      '@vf/ui/components/Molecule.ReviewCategories.vue' /* webpackChunkName: "uiReviews" */
    )
);

Vue.component(
  'VfProCon',
  () =>
    import(
      '@vf/ui/components/Molecule.ProCon.vue' /* webpackChunkName: "uiReviews" */
    )
);

Vue.component(
  'VfSubscriptionForm',
  () =>
    import(
      '@vf/ui/components/Molecule.SubscriptionForm.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfSubNavigation',
  () =>
    import(
      '@vf/ui/components/Molecule.SubNavigation.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfShoesSizeSelector',
  () =>
    import(
      '@vf/ui/components/Molecule.ShoesSizeSelector.vue' /* webpackChunkName: "uiShoesSize" */
    )
);

Vue.component(
  'VfOrderCardSidebar',
  () =>
    import(
      '@vf/ui/components/Molecule.OrderCardSidebar.vue' /* webpackChunkName: "uiOrder" */
    )
);

Vue.component(
  'VfFavoritesActions',
  () =>
    import(
      '@vf/ui/components/Organism.FavoritesActions.vue' /* webpackChunkName: "uiMyAccount" */
    )
);

Vue.component(
  'VfFavoritesProducts',
  () =>
    import(
      '@vf/ui/components/Organism.FavoritesProducts.vue' /* webpackChunkName: "uiMyAccount" */
    )
);

Vue.component(
  'VfTab',
  () =>
    import(
      '@vf/ui/components/Molecule.Tab.vue' /* webpackChunkName: "uiSearch" */
    )
);

Vue.component(
  'VfFilterImage',
  () =>
    import(
      '@vf/ui/components/Molecule.FilterImage.vue' /* webpackChunkName: "uiFilterImage" */
    )
);

Vue.component(
  'VfSocialNativeGallery',
  () =>
    import(
      '@vf/ui/components/SocialNative/Organism.SocialNativeGallery.vue' /* webpackChunkName: "uiSocialNative" */
    )
);

Vue.component(
  'VfSocialNativeMedia',
  () =>
    import(
      '@vf/ui/components/SocialNative/Molecule.SocialNativeMedia.vue' /* webpackChunkName: "uiSocialNative" */
    )
);

Vue.component(
  'VfSocialNativeModalProducts',
  () =>
    import(
      '@vf/ui/components/SocialNative/Molecule.SocialNativeModalProducts.vue' /* webpackChunkName: "uiSocialNative" */
    )
);

Vue.component(
  'VfSocialNativeSignInForm',
  () =>
    import(
      '@vf/ui/components/SocialNative/Molecule.SocialNativeSignInForm.vue' /* webpackChunkName: "uiSocialNative" */
    )
);

Vue.component(
  'VfTabs',
  () =>
    import(
      '@vf/ui/components/Molecule.Tabs.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfTabItem',
  () =>
    import(
      '@vf/ui/components/Molecule.TabItem.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfToast',
  () =>
    import(
      '@vf/ui/components/Molecule.Toast.vue' /* webpackChunkName: "uiLayout" */
    )
);

Vue.component(
  'VfThemeProductCard',
  () =>
    import(
      '@vf/ui/components/Theme.ProductCard.vue' /* webpackChunkName: "uiRedesignCart" */
    )
);
