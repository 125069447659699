import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { DeleteCustomerCreditCardsResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const deletePaymentInstrument = (
  configuration: ApiClientConfiguration
) => (
  instrumentId: string
): Promise<AxiosResponse<DeleteCustomerCreditCardsResponse>> => {
  return axios.delete(
    urlBuilder(endpoints.get(configuration).customer.deletePaymentInstrument, {
      config: configuration,
      mapping: {
        instrumentId,
      },
    }),
    {
      headers: headerBuilder(configuration),
    }
  );
};
