import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { validateResponse } from './helpers';
import { PhotorankApi, PhotorankApiAddMediaRequest } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const addSNMedia = (configuration: ApiClientConfiguration) => async (
  apiKey: string,
  userId: string,
  params: PhotorankApiAddMediaRequest
): Promise<any> => {
  const url = endpoints
    .get(configuration)
    .socialNative.addMedia.replace('{api_key}', apiKey)
    .replace('{user_id}', userId);

  const formData = new FormData();
  formData.append('file', params.file);
  params.caption && formData.append('caption', params.caption);

  const response = await axios.post<PhotorankApi<any>>(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  validateResponse(response.data);
  return response.data;
};
