import { getMediaPdp, getMediaPlp } from './handlers/media';
import getCartImage from './handlers/getCartImage';

const dataSourceFetcher = {
  getMediaPdp,
  getMediaPlp,
  getCartImage,
};

export { dataSourceFetcher };
