import { CMCollection, CmsSite } from '@vf/api-contract';
import {
  getTitle,
  extractProperty,
  getImageObject,
  generateLinkFromTeaserTargets,
} from './utils';
import { ComposableContext } from '../types';

export const checkoutFooter = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const checkoutFooterCta = getCtaData(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const logo = getLogoUrl(data, siteConfiguration, cmsBaseUri);
  const secureLogoItems = getSecureLogoItems(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const getSecureItemLogoByName = (itemName) => {
    return secureLogoItems.find((item) => item.name.toLowerCase() == itemName);
  };
  return {
    component: 'layout/CheckoutFooter',
    sfComponent: false,
    props: {
      logo: logo,
      ...checkoutFooterCta,
      befItem: getSecureItemLogoByName('checkout footer bef'),
      mcAfeeItem: getSecureItemLogoByName('checkout footer mcafee'),
    },
  };
};

const getCtaData = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const cta = data.teasableItems.find((item) => item.viewtype === 'contact-us');
  return cta
    ? {
        cta: getTitle(cta),
        phoneNumber: extractProperty(cta, 'teaserSubtitle', ''),
        link: generateLinkFromTeaserTargets(
          cta.teaserTargets,
          context,
          siteConfiguration,
          cmsBaseUri
        ),
      }
    : {};
};

const getLogoUrl = (
  data: CMCollection,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const logo = data.teasableItems.find((item) => item.viewtype === 'site-logo');
  const pictureObject = extractProperty(logo, 'pictures[0]', null);
  if (!pictureObject) {
    console.error('Checkout footer logo error: no site logo component found');
    return {};
  }
  return {
    id: logo.id,
    url: getImageObject(pictureObject, siteConfiguration, null, cmsBaseUri),
    ariaLabel: logo.name,
  };
};

const getSecureLogoItems = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const items = data.teasableItems
    .filter((item) => item.viewtype == 'pdp-link')
    .map((item) => {
      return {
        name: item.name,
        url: generateLinkFromTeaserTargets(
          item.teaserTargets,
          context,
          siteConfiguration,
          item.urlParams,
          cmsBaseUri
        ),
        icon: extractProperty(
          item,
          'localSettings.dynamicFields.icon',
          extractProperty(item, 'localSettings.icon')
        ),
        imgUrl: getImageObject(
          extractProperty(item, 'pictures[0]', null),
          siteConfiguration,
          null,
          cmsBaseUri
        ),
        height: extractProperty(item, 'pictures[0].height', null),
        width: extractProperty(item, 'pictures[0].width', null),
      };
    });
  return items;
};
