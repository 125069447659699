export default <T extends []>(
  callback: (..._: T) => void,
  wait: number
): ((..._: T) => void) => {
  let timer: any;
  let time: number;
  return function fn(...args: T): void {
    const now: number = Date.now();
    clearTimeout(timer);
    if (!time || now - time >= wait) {
      callback(...args);
      time = now;
    } else timer = setTimeout(() => fn(...args), wait - (now - time));
  };
};
