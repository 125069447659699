import type { ApiClientConfiguration } from '@vf/api-client';
import type { RecursivePartial } from '@vf/api-contract';
import type { PutOrderObject } from '@vf/api/src/types/Checkout';
import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { getChannel } from '../utilities/getChannel';

export const patchOrder = (configuration: ApiClientConfiguration) => async (
  orderNumber: string,
  payload: RecursivePartial<PutOrderObject>
) => {
  const baseUrl = endpoints.get(configuration).checkout.patchOrder;

  const url: string = urlBuilder(baseUrl, {
    config: configuration,
    mapping: { orderNumber },
  });

  return axios.patch(url, payload, {
    headers: headerBuilder(configuration, {
      channel: getChannel(),
    }),
  });
};
