import { parseUrlFactory } from '../../useUrl/handlers';
import { ComponentInstance } from '../../types';
import { PageType } from '@vf/api-contract';

const isAllowedParameter = (pageType?: PageType, param?: string) => {
  if (!pageType || !param) return false;
  switch (pageType) {
    case 'customizer':
      return ['recipe'].includes(param);
    // matches category / plp.
    case 'category':
      return ['page'].includes(param);
    case 'product':
      return ['recipe'].includes(param);
    default:
      return false;
  }
};

const deserialize = (queryString: string, resourceType?: PageType) => {
  const vars = queryString.split('&');

  return vars.reduce((acc, cur) => {
    const decodedURI = decodeURIComponent(cur);
    const [key, value] = decodedURI.split('=');
    if (!isAllowedParameter(resourceType, key)) {
      return acc;
    }

    return [...acc, [key, value]];
  }, []);
};

const serialize = (params: [string, string][]): string => {
  return params
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
};

export const getPageCanonicalUrl = (
  url: string,
  instance: ComponentInstance
) => {
  if (!url) {
    return '';
  }

  const [base, queryString] = url.split('?');

  if (!queryString) {
    return !url.startsWith('http')
      ? `https://${instance.$getDomainName()}${url}`
      : url;
  }

  const parseUrl = parseUrlFactory(instance);
  const meta = parseUrl(base);
  const filteredQuery = deserialize(queryString, meta.resourceType);

  const result = `${base}${
    filteredQuery.length ? '?' + serialize(filteredQuery) : ''
  }`;

  return !result.startsWith('http')
    ? `https://${instance.$getDomainName()}${result}`
    : result;
};
