import { axios, urlBuilder, headerBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const deletePriceAdjustment = (
  configuration: ApiClientConfiguration
) => async (id: string, priceAdjustmentId: string): Promise<any> => {
  return axios.delete(
    urlBuilder(endpoints.get(configuration).cart.deletePriceAdjustments, {
      config: configuration,
      mapping: {
        id,
        priceAdjustmentId,
      },
    }),
    {
      headers: headerBuilder(configuration),
      data: {},
    }
  );
};
