import { EventPropsHandler } from '.';
import {
  EnhancedSaleClickEvent,
  EnhancedSaleList,
  EnhancedSaleType,
  EventName,
} from '../../types/gtm';
import { ProductCrossSellItem, ProductUpsellItem } from '@vf/api-client';
import { mapProductImpression } from './enhancedSaleHandler';

export const enhancedSaleClickHandler: EventPropsHandler<
  EnhancedSaleClickEvent,
  {
    type: EnhancedSaleType;
    product: ProductCrossSellItem | ProductUpsellItem;
    position: number;
  }
> = (eventProps, vmInstance) => {
  const { action, eventName, list } =
    eventProps.overrideAttributes.type === EnhancedSaleType.UP_SELL
      ? {
          action: 'openUpSell',
          eventName: 'UpSellCompleteTheLookClick' as EventName,
          list: EnhancedSaleList.UP_SELL,
        }
      : {
          action: 'openCrossSell',
          eventName: 'CrossSellCompleteTheLookClick' as EventName,
          list: EnhancedSaleList.CROSS_SELL,
        };
  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();
  const useColorOnId = vmInstance.$themeConfig.gtm?.useColorOnId;
  const currencyCode = eventProps.overrideAttributes.product.price.currency;

  const product = mapProductImpression(eventProps.overrideAttributes.product, {
    brand,
    list,
    position: eventProps.overrideAttributes.position,
    useColorOnId,
  });

  const ecommerce = {
    currencyCode,
    click: {
      actionField: {
        action,
        list,
      },
      products: [product],
    },
  };

  return {
    event: eventName,
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Clicks',
    eventLabel: product.id,
    nonInteractive: false,
    ecommerce,
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
