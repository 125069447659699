import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { RequestHeader } from '../../types';
import { ApiClientConfiguration } from '../../configuration';

export const deleteAddress = (configuration: ApiClientConfiguration) => (
  addressId: string,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<{ consumerId: string }>> => {
  const url = urlBuilder(endpoints.get(configuration).customer.deleteAddress, {
    config: configuration,
    mapping: {
      addressId,
    },
  });

  return axios.delete(url, {
    data: null,
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
