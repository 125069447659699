import ls from '../../../utils/localStorage';

export const LS_GUEST_CART_KEY = 'guestCart';

export const useGuestCart = ({ cartItems, totalItems }) => {
  const saveGuestCart = () => {
    const guestCartItems =
      cartItems.value?.reduce(
        (acc, item) => ({
          ...acc,
          [item.slug]: item.qty,
        }),
        {}
      ) || {};

    ls.setItem(LS_GUEST_CART_KEY, JSON.stringify(guestCartItems));
  };
  const getGuestCartItems = () => {
    return JSON.parse(ls.getItem(LS_GUEST_CART_KEY)) as Record<string, number>;
  };
  const getGuestItemQuantity = () => {
    const guestCartItems = getGuestCartItems();
    return guestCartItems
      ? Object.values(guestCartItems).reduce((a: number, b: number) => a + b, 0)
      : 0;
  };
  const isGuestCartMerged = () => {
    const guestCartItems = getGuestCartItems();
    return cartItems.value.some(
      (item) => guestCartItems[item.slug] <= item.qty
    );
  };
  const clearGuestCart = () => {
    return ls.removeItem(LS_GUEST_CART_KEY);
  };

  const isCartMergeNotificationVisible = () => {
    return isGuestCartMerged() && getGuestItemQuantity() !== totalItems.value;
  };

  return {
    saveGuestCart,
    clearGuestCart,
    isCartMergeNotificationVisible,
  };
};
