import {
  CMContentHorizontalSimple,
  CmsSite,
  CMPictureCropType,
} from '@vf/api-contract';
import {
  extractProperty,
  getTeaserTargets,
  decorateHtmlValue,
  getImageObject,
  getVideoObject,
  getMediaSeoAttributes,
  extractComponentStyleDefinitions,
} from './utils';
import { ComposableContext } from '../types';

export const easyHorizontalSimple = (
  data: CMContentHorizontalSimple,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const pictures = getPictures(data, siteConfiguration, cmsBaseUri);
  const videos = getVideos(data, siteConfiguration, cmsBaseUri);
  videos?.videosObject.forEach((video) => setVideoOptions(video));
  const { ctas } = getTeaserTargets(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );

  return {
    component: 'shared/ContentHorizontalSimple',
    props: {
      title: extractComponentStyleDefinitions(data, 'teaserTitle'),
      pictures: pictures?.imagesObject || [],
      videos: videos?.videosObject || [],
      backgroundColorStyle: extractProperty(
        data,
        'componentStylesDefinitions.rowBackgroundColor.data.color'
      ),
      bodyText: {
        content:
          decorateHtmlValue(
            data?.teaserRichTextObject,
            cmsBaseUri,
            _context,
            siteConfiguration
          ) || extractProperty(data, 'teaserText'),
        color: extractProperty(
          data,
          'componentStylesDefinitions.teaserTextColor.data.color'
        ),
        variant: extractProperty(data, 'componentStyles.teaserTextStyle'),
      },
      ctas,
      bottomMarginStyle: extractProperty(data, 'componentStyles.bottomMargin'),
      contentAlignment: extractProperty(
        data,
        'componentStyles.contentAlignment'
      ),
    },
  };
};

const getPictures = (data, siteConfiguration, cmsBaseUri) => {
  if (data.pictures?.length) {
    const imagesObject = data.pictures.map((picture) => {
      return {
        ...getImageObject(
          picture,
          siteConfiguration,
          CMPictureCropType.LANDSCAPE_RATIO_8x3,
          cmsBaseUri,
          data.responsiveMedia,
          checkMediaOrder(data, picture.id)
        ),
        order: checkMediaOrder(data, picture.id),
        id: picture.id,
        seo: getMediaSeoAttributes(picture),
        alt: picture.alt,
        width: null,
        height: null,
      };
    });
    return {
      imagesObject,
    };
  }
};

const getVideos = (data, siteConfiguration, cmsBaseUri) => {
  if (data.videos?.length) {
    const videosObject = data.videos.map((video, index) => {
      return {
        id: video.id,
        order: checkMediaOrder(data, video.id),
        small: getVideoResponsive(data, cmsBaseUri, 'small', index),
        medium: getVideoResponsive(data, cmsBaseUri, 'medium', index),
        large: getVideoResponsive(data, cmsBaseUri, 'large', index),
      };
    });
    return {
      videosObject,
    };
  }
};

const getVideoResponsive = (data, cmsBaseUri, type, index) => {
  if (data.videos.length > 1) {
    const responsiveVideo = extractProperty(
      data,
      `responsiveMedia[${type}][${index}]`,
      ''
    );
    return getVideoObject([responsiveVideo], cmsBaseUri);
  } else {
    const videoIndex = data.responsiveMedia[type].findIndex(
      (el) => el.type === 'CMVideo'
    );
    const responsiveVideo = extractProperty(
      data,
      `responsiveMedia[${type}][${videoIndex}]`,
      ''
    );
    return getVideoObject([responsiveVideo], cmsBaseUri);
  }
};

const checkMediaOrder = (data, id) => {
  const findMedia = data.responsiveMedia.small.findIndex(
    (media) => media.id === id
  );
  return findMedia;
};

const setVideoOptions = (video) => {
  const defaultOptions = {
    preload: 'metadata',
    autoplay: true,
    hideControls: true,
    muted: true,
    showPlayButton: false,
    loop: true,
    showSubtitles: false,
  };
  ['small', 'medium', 'large'].forEach((size) => {
    if (video[size]) video[size].options = Object.assign({}, defaultOptions);
  });
};
