import { apiClientFactory, CartLineItem, Product } from '@vf/api-client';
import { UpsellProduct, UpsellsItem } from '@vf/api-contract';
import getCartImage from '../../../utils/dataSources/handlers/getCartImage';
import useUrl from '../../../useUrl';
import { isCustomsProduct } from '@vf/composables/src/useCustoms/utils';

type CartItemPDPDetails = {
  pdpUrl: string;
  productImageURL: string;
};

export const usePDPDetails = (instance) => {
  const { getProductUrl } = useUrl(instance);
  const { generateProductIdScene7 } = apiClientFactory(instance);

  const getProductUrlInPDPDetails = (
    isSaveForLaterProduct: boolean,
    product: CartLineItem | Product,
    recipeId?: string
  ): string => {
    if (
      !recipeId &&
      instance.$themeConfig.productAddToCart.pdpUrlWithAttributes
    ) {
      // use attributes in pdpUrl
      const qsObj = isSaveForLaterProduct
        ? (product as CartLineItem).variants.reduce((finalObj, variant) => {
            finalObj[variant.code] = variant.id;
            return finalObj;
          }, {})
        : (product as Product).variant?.attributes;
      const queryStringEncoded = Object.keys(qsObj)
        .map((key) => `${key}=${encodeURIComponent(qsObj[key])}`)
        .join('&');
      const pdpUrl = getProductUrl(product as Product);
      return `${pdpUrl.split('?')[0]}?${queryStringEncoded}`;
    }

    const idToBuildVariant = () =>
      isSaveForLaterProduct
        ? (product as CartLineItem).productId
        : (product as Product).variant.id;

    const queryParams = {
      recipe: recipeId,
      variant: !recipeId ? idToBuildVariant() : undefined,
    };
    return getProductUrl(product as Product, queryParams);
  };

  // TODO Types here are a mess
  const buildItemPDPDetails = async (
    product: (Product | CartLineItem | UpsellsItem | UpsellProduct) & {
      productImageURL?: string;
      imageSize?: string;
      imageView?: string;
      isCustoms?: boolean;
    },
    recipeId?: string
  ): Promise<CartItemPDPDetails> => {
    // TODO why we convert to boolean here?
    const isSaveForLaterProduct = !!(product as any).saveForLater;
    const isUpsellProduct = !!(product as UpsellsItem).upsell;
    const isFavoriteItem = !!(product as any).isFavoriteItem;

    if (isFavoriteItem) {
      return {
        pdpUrl: `${window.location.origin}${(product as any).pdpUrl}`,
        productImageURL: (product as any).productImageURL,
      };
    }
    let productId: string;
    let additionalData: any = {};
    if (isSaveForLaterProduct) {
      productId = (product as CartLineItem).masterId;
    } else if (isUpsellProduct) {
      productId = (product as UpsellsItem).itemGroupId;
      const colorCode = (product as UpsellProduct).attributes?.color;
      if (
        colorCode &&
        instance.$themeConfig.productAddToCart.pdpUrlWithAttributes
      ) {
        additionalData = { colorCode };
      }
    } else if (!product.isCustoms) {
      // don't go there if it's an item from customizer
      productId = generateProductIdScene7(
        product as Product,
        (product as Product).color?.value
      );
    }

    const pdpUrl = getProductUrlInPDPDetails(
      isSaveForLaterProduct,
      product as CartLineItem | Product,
      recipeId
    );
    const isCustoms = isCustomsProduct(product as Product);
    const productImageURL =
      product.productImageURL ||
      (await getCartImage(instance, {
        image_uri: instance.$mediaUrlGenerator({
          pid: productId,
          productName: product.name,
          colorName: (product as Product).color?.label,
          ...additionalData,
        }),
        fallbackImage: instance.$mediaUrlFallback(),
        productId: product.id,
        recipeId,
        isCustoms,
        imageSize: product.imageSize,
        imageView: product.imageView,
      }));

    return {
      // SFCC needs full path for pdpUrl (GLOBAL15-5742)
      pdpUrl: `https://${instance.$env.DOMAIN}${pdpUrl}`,
      productImageURL,
    };
  };

  return {
    buildItemPDPDetails,
  };
};
