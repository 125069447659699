import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { AxiosResponse } from 'axios';
import {
  PrintReturnLabelPayload,
  PrintReturnLabelResponse,
} from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const printReturnLabel = (configuration: ApiClientConfiguration) => (
  orderNo: string,
  body: PrintReturnLabelPayload,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<PrintReturnLabelResponse>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.printReturnLabel,
    {
      config: configuration,
      mapping: { orderNo },
    }
  );
  const headers = headerBuilder(configuration, {
    'x-usid': usid,
  });

  return axios.post(url, body, {
    headers,
  });
};
