import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, headerBuilder, urlBuilder } from '../../helpers';

export const getStoresStates = (
  configuration: ApiClientConfiguration
) => async (params: { country?: string; state?: string }): Promise<any> => {
  return axios.get(
    urlBuilder(endpoints.get(configuration).stores.getStates, {
      config: configuration,
    }),
    {
      headers: headerBuilder(configuration),
      params,
    }
  );
};
