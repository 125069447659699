import { computed } from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { mapAddress } from './helpers';
import { useBasicInformation } from '../useBasicInformation';
import { errorMessages } from '../../../utils/errorMessages';

export const useAddress = (instance) => {
  const { displayErrorMessages } = errorMessages(instance);

  const { basicInformation } = useBasicInformation(instance);

  const {
    addAddress: addAddressAPI,
    deleteAddress: deleteAddressAPI,
    updateAddress: updateAddressAPI,
  } = apiClientFactory(instance);

  const getAddresses = (type: string) => {
    return computed(() =>
      basicInformation.value
        ? basicInformation.value.address.filter(
            (item) => item.approachType === type
          )
        : []
    );
  };

  /**
   * Validates address.
   * Removes addressLine2 if it is the same as addressLine1.
   * @param {object} address - The address object.
   * @returns {object} Updated address object.
   */
  const validationAddress = (address) => {
    if (address.addressLine2 && address.addressLine1 === address.addressLine2)
      return { ...address, addressLine2: null };
    return address;
  };

  const addAddress = async (address) => {
    try {
      address = validationAddress(address);
      const response = await addAddressAPI(mapAddress(address));

      if (response.data.addressId) {
        address.id = response.data.addressId;
        basicInformation.value.address.push(address);
      }
      return response;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const updateAddress = async (addressId, address) => {
    try {
      address = validationAddress(address);
      const response = await updateAddressAPI(addressId, mapAddress(address));
      if (response.data.addressId) {
        for (let i = 0; i < basicInformation.value.address.length; i++) {
          if (basicInformation.value.address[i].id === addressId) {
            basicInformation.value.address[i] = address;
          }
        }
      }
      return response;
    } catch (e) {
      displayErrorMessages(e);

      throw e;
    }
  };

  const deleteAddress = async (addressId) => {
    try {
      await deleteAddressAPI(addressId);
      const deletedAddress = basicInformation.value.address.find(
        (item) => item.id === addressId
      );
      basicInformation.value.address = basicInformation.value.address.filter(
        (item) => item.id !== addressId
      );
      if (!deletedAddress?.main) return;
      const newDefaultAddress = basicInformation.value.address.filter(
        (item) => item.approachType === deletedAddress.approachType
      )[0];
      if (!newDefaultAddress) return;
      await updateAddress(newDefaultAddress.id, {
        ...newDefaultAddress,
        main: true,
      });
    } catch (err) {
      displayErrorMessages(err);
      throw err;
    }
  };

  return {
    deleteAddress,
    updateAddress,
    addAddress,
    getAddresses,
  };
};
