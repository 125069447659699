
















































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';
import { AnalyticsDataDomLocation } from '@/types';
import type { FooterSocialLink } from '@vf/api-contract';

export default defineComponent({
  name: 'FooterSocialMedia',
  directives: { focus },
  props: {
    /** Array of social media icons data */
    socialMedia: {
      type: [Object, Array] as PropType<FooterSocialLink | FooterSocialLink[]>,
      required: true,
    },
  },
  setup() {
    const socialMediaIconsHover = (socialMediaName) => {
      return 'icon-' + socialMediaName;
    };

    return {
      AnalyticsDataDomLocation,
      socialMediaIconsHover,
    };
  },
});
