import { EventPropsHandler } from '.';
import {
  CheckoutOptionEvent,
  CheckoutOptionEventLabel,
  CheckoutStepNumber,
} from '../../types/gtm';
import { useCart } from '../../useCart';
import { getOrderPromoActionFields, getShippingActionFields } from './helpers';

export const checkoutOptionHandler: EventPropsHandler<
  CheckoutOptionEvent,
  { step: CheckoutStepNumber; option: CheckoutOptionEventLabel }
> = (eventProps, vmInstance) => {
  const { cart } = useCart(vmInstance);

  return {
    event: 'checkoutOption',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Checkout Option',
    eventLabel: eventProps.overrideAttributes.option,
    nonInteractive: true,
    ecommerce: {
      checkout_option: {
        actionField: {
          step: eventProps.overrideAttributes.step,
          option: eventProps.overrideAttributes.option,
          ...getOrderPromoActionFields(cart.value.orderPromotions),
          ...getShippingActionFields(cart.value.shippingMethods),
        },
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
