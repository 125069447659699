import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { EmailLookupRequest } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const emailLookup = (configuration: ApiClientConfiguration) => (
  data: EmailLookupRequest
): Promise<AxiosResponse> => {
  return axios.get(
    urlBuilder(endpoints.get(configuration).authentication.emailLookup, {
      config: configuration,
    }),
    {
      headers: headerBuilder(configuration, {
        'x-usid': data.usid,
      }),
      params: {
        email: `et~${data.email}`,
        captchaResponse: data.recaptchaToken,
      },
    }
  );
};
