import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const socialMediaLink = (data: CMPlaceholder) => {
  return {
    component: 'account/SocialMediaLink',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Link your social accounts$'),
        subheading: getTranslation(
          data,
          'subheading',
          '$Connect your account$'
        ),
        ariaLabel: getTranslation(data, 'ariaLabel', '$Log in with Facebook$'),
      },
    },
  };
};
