export enum ProductReviewPropertyType {
  TAG = 'tag',
}

export type ProductReviewResponse = {
  paging: ProductReviewPaging;
  results: ProductReviewResult[];
};

export type ProductReviewConfResponse = {
  features: {
    search_supported_locales: string[];
  };
  localizations: Record<string, string>;
  properties: Record<string, string | boolean>;
};

export type ReviewsTranslations = {
  /** Review snapshot heading */
  heading: string;
  /** Write a review button text */
  writeReview: string;
  /** Write a review button text when no reviews */
  writeFirstReview: string;
  /** Reviews count label */
  reviewsCount: {
    /** Reviews count label for singular */
    singular: string;
    /** Reviews count label for plural */
    plural: string;
  };
  /** Recommendations label */
  recommendationsLabel: string;
  /** Rating distribution translations */
  ratingDistribution: {
    /** Rating distribution heading */
    heading: string;
    /** Stars count singular label */
    starCount?: string;
    /** Stars count plural label */
    starsCount: string;
    /** Rating distribution tooltip message */
    tooltip: string;
  };
  /** Pros and Cons tooltip label */
  proConTagTooltip: string;
  /** Pros section */
  pros: {
    /** Pros heading label */
    heading: string;
    /** Pros empty text */
    emptyText: string;
  };
  /** Cons section */
  cons: {
    /** Cons heading label */
    heading: string;
    /** Cons empty text */
    emptyText: string;
  };
  /** Most liked positive and negative
   * review read complete label */
  mostLikedReadComplete: string;
  /** Most liked positive and negative
   * review read less label */
  mostLikedReadLess: string;
  /** Most liked positive review heading */
  mostLikedPositiveHeading: string;
  /** Most liked negative review heading */
  mostLikedNegativeHeading: string;
  /** Flag Review component translations */
  flagReviewForm: {
    /** Flag this review link if not flagged */
    flagReviewNotFlagged: string;
    /** You flagged this review text if already flagged */
    flagReviewFlagged: string;
    /** Flag UGC link if not flagged */
    flagUGCNotFlagged: string;
    /** Flag UGC link if it is flagged */
    flagUGCFlagged: string;
    /** Flag form heading what is the issue */
    heading: string;
    /** Email field placeholder text */
    emailPlaceholder: string;
    /** Email field required error message */
    emailRequiredText: string;
    /** Invalid Email error message */
    emailInvalidText: string;
    /** Comments field placeholder text */
    commentsPlaceholder: string;
    /** Flag button label */
    flagButtonLabel: string;
    /** Cancel button label */
    cancelButtonLabel: string;
    /** Types of the issue radio selectons */
    flagTypes: {
      /** Flag type translation radio label for Profanity */
      profane: string;
      /** Flag type translation radio label for Wrong Product */
      wrong_product: string;
      /** Flag type translation radio label for Spam */
      spam: string;
      /** Flag type translation radio label for Duplicate */
      duplicate: string;
      /** Flag type translation radio label for Copyright Violation */
      copyright: string;
      /** Flag type translation radio label for Not a Product Review */
      not_review: string;
      /** Flag type translation radio label for Customer Image */
      customer_image: string;
      /** Flag type translation radio label for Other */
      other: string;
    };
    /** Flag button thumb helpful */
    helpfulVoteLabel: string;
    /** Flag button thumb unhelpful */
    unhelpfulVoteLabel: string;
  };
  /** Tags translations */
  tags: {
    /** More button label */
    more: string;
    /** Less button label */
    less: string;
  };
  /** Reviews list translations */
  list: {
    /** Reviews list heading label */
    heading: string;
    /** Filters applied label */
    filtersApplied: string;
    /** Reviews list sorting ariaLabel */
    sortingAriaLabel: string;
    /** Merchant response label */
    merchantResponse: string;
    /** Bottom line label */
    bottomLine: string;
    /** Was it helpful line before Helpful component */
    helpfulLine: string;
    /** Information about currently displaying reviews count */
    displayingReviews: string;
    /** Back to top label */
    backToTop: string;
    /** Previous page label */
    prevPage: string;
    /** Next page label */
    nextPage: string;
    /** No Results label */
    noResults: string;
    /** Disclosure label for Sweepstakes */
    disclosureLabelSweepstakes: string;
  };
  sortingOptions?: ProductReviewSorting['options'];
  /** Submitted By section */
  submittedBy: {
    /** Submitted label */
    submittedLabel: string;
    /** By label */
    submittedByLabel: string;
    /** From label */
    fromLabel: string;
    /** Verified buyer label */
    verifiedBuyerLabel: string;
  };
  /** Review Categories section */
  reviewCategories: {
    /** More details label */
    moreDetails: string;
    /** Less details label */
    lessDetails: string;
  };
  /** Bottom Line Yes/No section */
  bottomLine: {
    /** Bottom Line Yes */
    recommended: string;
    /** Bottom Line No */
    notRecommended: string;
  };
  /** Reviews based fit section heading */
  reviewBasedFitScale: {
    /** heading */
    heading: string;
  };
};

export type ProductReviewResult = {
  productId: string;
  rollup: ProductReviewRollup;
  reviews: ProductReview[];
};

export type ProductReviewPropertyValue = {
  label: string;
  count: number;
};

export type ProductReviewRollupProperty = {
  key: string;
  name: string;
  type: string;
  display_type?: string;
  display_values?: string[];
  values: ProductReviewPropertyValue[];
};

export type ProductReviewRollup = {
  properties: ProductReviewRollupProperty[];
  ratingHistogram: number[];
  recommendedRatio: number;
  averageRating: number;
  reviewCount: number;
  faceoff: {
    positive: ProductReviewFaceoff;
    negative: ProductReviewFaceoff;
  };
  nativeReviewCount: number;
  nativeSamplingReviewCount: number;
  nativeSweepstakesReviewCount: number;
  nativeCommunityContentReviewCount: number;
  syndicatedReviewCount: number;
};

export type ProductReviewFaceoff = {
  comments: string;
  headline: string;
  rating: number;
};

export type ProductReview = {
  ugcId: number;
  reviewId: number;
  details: ProductReviewDetails;
  media?: ProductReviewUgcItem[];
  badges: ProductReviewBadges;
  metrics: ProductReviewMetrics;
};

export type ProductReviewDetails = {
  comments: string;
  headline: string;
  nickname: string;
  locale: string;
  location: string;
  createdDate: number;
  updatedDate: number;
  bottomLine?: string;
  properties: ProductReviewProperty[];
  merchantResponse?: string;
  disclosureCode: string;
};

export type ProductReviewBadges = {
  isStaffReviewer: boolean;
  isVerifiedBuyer: boolean;
  isVerifiedReviewer: boolean;
};

export type ProductReviewMetrics = {
  helpfulVotes: number;
  notHelpfulVotes: number;
  rating: number;
  helpfulScore: number;
};

export type ParsedProductReviewItem = {
  score: ProductReview['metrics']['rating'];
  title: ProductReview['details']['headline'];
  description: ProductReview['details']['comments'];
  merchantResponse: ProductReview['details']['merchantResponse'];
  details: {
    nickname: ProductReview['details']['nickname'];
    createdDate: string;
    location: ProductReview['details']['location'];
    disclosureCode: ProductReview['details']['disclosureCode'];
  };
  bottomLine?: string | null;
  media: ParsedProductReviewUgcItem[];
  badges: {
    isVerifiedBuyer: ProductReview['badges']['isVerifiedBuyer'];
  };
  thumbUpCount: ProductReview['metrics']['helpfulVotes'];
  thumbDownCount: ProductReview['metrics']['notHelpfulVotes'];
  isUpSelected: boolean;
  isDownSelected: boolean;
  reviewId: ProductReview['reviewId'];
  metrics: ProductReview['metrics'];
  properties: ProductReviewDetails['properties'];
  flagged: boolean;
};

export type ProductReviewUgcItem = {
  id: string;
  type: string;
  uri: string;
  caption?: string;
  helpful_votes: number;
  not_helpful_votes: number;
  thumbUp?: boolean;
  thumbDown?: boolean;
};

export type ParsedProductReviewUgcItem = Omit<
  ProductReviewUgcItem,
  'id' | 'helpful_votes' | 'not_helpful_votes'
> & {
  id: number;
  helpfulVotes: number;
  notHelpfulVotes: number;
  flagged: boolean;
};

export type ProductReviewPaging = {
  totalResults: number;
  pagesTotal: number;
  pageSize: number;
  currentPageNumber: number;
  nextPageUrl: string;
};

export enum ProductReviewSortingParameter {
  Newest = 'Newest',
  MostHelpful = 'MostHelpful',
  LowestRating = 'LowestRating',
  HighestRating = 'HighestRating',
  Oldest = 'Oldest',
  MediaSort = 'MediaSort',
}

export type ProductReviewSorting = {
  options: Record<ProductReviewSortingParameter, string> | null;
  sortBy: ProductReviewSortingParameter;
};

export type ProductReviewFilter = {
  key: string;
  value: string[];
};

export type ProductReviewProperty = {
  key: string;
  label: string;
  type: string;
  value: string[];
};

export type ProductReviewRatingDistribution = {
  stars: number;
  value: number;
};

export type MappedProductReviewProperty = {
  label: ProductReviewPropertyValue['label'];
  count: ProductReviewPropertyValue['count'];
  isSelected: boolean;
};

export type ProductReviewTag = {
  heading: ProductReviewRollupProperty['name'];
  key: ProductReviewRollupProperty['key'];
  tags: MappedProductReviewProperty[];
  value?: ParsedProductReview['sizing'] | ParsedProductReview['width'];
  minLabel?: string;
  maxLabel?: string;
};

export type ParsedProductReview = {
  productId: string;
  score: ProductReviewRollup['averageRating'];
  reviewsCount: ProductReviewRollup['reviewCount'];
  recommendationsCount: ProductReviewRollup['recommendedRatio'];
  ratingDistribution: ProductReviewRatingDistribution[];
  pros: MappedProductReviewProperty[];
  cons: MappedProductReviewProperty[];
  sizing: {
    value: number;
    maxValue: number;
  };
  width: number;
  sleevelength: number;
  chestsize: number;
  tags: ProductReviewTag[];
  mostLikedPositive: ProductReviewFaceoff;
  mostLikedNegative: ProductReviewFaceoff;
  reviews: ParsedProductReviewItem[];
  sorting: ProductReviewSorting;
  pagination: ProductReviewPaging;
  filters: ProductReviewFilter[];
  rollup: ProductReviewRollup;
};

export enum ProductReviewVoteType {
  Helpful = 'helpful',
  NotHelpful = 'unhelpful',
}
