import { CMCollection, CmsSite } from '@vf/api-contract';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../types';
import { extractProperty, getDynamicField, getTranslation } from './utils';
import { ImageWithOverlayData } from './imageWithOverlay';
import { standardComponentDataParser } from '../dataTransformators/utils/components/standard';
import { parseDataBasedOnType } from '../dataTransformators/utils/parseDataBasedOnType';

interface CMTabsCollection extends Omit<CMCollection, 'teasableItems'> {
  teasableItems: CMCollection[];
}

export const shopByActivityPageTabs = (
  data: CMTabsCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  /* Activity Pages have slider build out from ImageWithOverlay components */
  const slides: ImageWithOverlayData[] = [];

  /* Mapping through activities: Hike, Snowboard */
  const tabs = data.teasableItems.map((item) => {
    /* tabs are always returned as collections since each tab build one page */
    if (item.type !== 'CMCollection') return;
    if (!item.teasableItemsCount) return;

    const pageContent: Record<string, unknown>[] = [];

    const componentParser = standardComponentDataParser(
      siteConfiguration,
      context,
      cmsBaseUri,
      requirements,
      PageTypeName.SHOP_BY_ACTIVITY
    );

    item.teasableItems.forEach((pageItem, index) => {
      /*
       * This parser is used to ensure that anything can be put here by CMS authors,
       * with emphasis on using Monetate Experiences and Grids.
       * */
      const parsedComponent = parseDataBasedOnType(
        pageItem,
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        PageTypeName.SHOP_BY_ACTIVITY
      );
      /*
       * New use case has been discovered here - ImageWithOverlay is expected to be wrapped
       * in GridColumn to provide different styling/copy for mobile/desktop.
       *  */
      if (index === 0) {
        slides.push(parsedComponent.data);
        return;
      }

      pageContent.push(parsedComponent.data);
    });

    return {
      id: item.id,
      label: getDynamicField(item, 'Label'),
      pageContent,
    };
  });

  const labels: string[] = tabs.map((tab) => tab.label);

  return {
    component: 'shopByActivity/PageTabs',
    props: {
      title: extractProperty(data, 'teaserTitle', '$Shop By Activity$'),
      labels,
      slides,
      tabs,
      translations: {
        accessibility: getTranslation(data, 'accessibility', {
          carouselTitleLabel: '$Carousel.$',
          carouselNavPreviousSlideLabel: '$Go to previous slide.$',
          carouselNavNextSlideLabel: '$Go to next slide.$',
        }),
      },
    },
  };
};
