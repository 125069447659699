import { FlashErrorDetails } from '@vf/api-client';

export function replaceMessagePlaceholders(
  message: string,
  errorDetails: FlashErrorDetails
): string {
  if (!errorDetails) return message;

  let variationAttrsString = '';
  if (typeof errorDetails.variationAttributes !== 'undefined') {
    const variationAttrsObject = errorDetails.variationAttributes;
    Object.keys(variationAttrsObject).forEach((key, index) => {
      variationAttrsString += `${index ? ', ' : ''}${key.toUpperCase()}: ${
        variationAttrsObject[key]
      }`;
    });
  }

  const mapping = {
    '{{productName}}': `<span class="cart-product__error-product-name">${errorDetails.productName}</span>`,
    '{{color}}': errorDetails.color || errorDetails.variationAttributes?.color,
    '{{size}}': errorDetails.size || errorDetails.variationAttributes?.size,
    '{{maxQuantity}}': errorDetails.maxAllowedQty,
    '{{currentQuantity}}': errorDetails.requiredQty,
    '{{variationAttributes}}': variationAttrsString,
  };

  return message?.replace(
    /{{productName}}|{{color}}|{{size}}|{{maxQuantity}}|{{currentQuantity}}|{{variationAttributes}}/gi,
    (matched) => {
      return mapping[matched];
    }
  );
}
