import { ComponentInstance } from '../../types';
import { Product } from '@vf/api-client';

enum error {
  ATT_NO_OPTIONS = 'ATTRIBUTE_NO_OPTIONS',
  PRODUCT_NO_ATTRIBUTES = 'PRODUCT_NO_ATTRIBUTES',
  PRODUCT_NO_VARIANTS = 'PRODUCT_NO_VARIANTS',
  PRODUCT_NOT_RETRIEVED = 'PRODUCT_NOT_RETRIEVED',
}

export const isValidProductData = (
  instance: ComponentInstance,
  product: Product
): boolean => {
  try {
    // this scenario handles when API response is erroed.
    if (Object.keys(product).length === 1) {
      throw new Error(
        `[@useCms/utils/productDataValidator::isValidProductData] Error <${error.PRODUCT_NOT_RETRIEVED}> | Product <${product.id}> was not retrieved from MuleSoft.`
      );
    }

    if (product.attributes?.length) {
      product.attributes.forEach((att) => {
        if (!att.options?.length) {
          throw new Error(
            `[@useCms/utils/productDataValidator::isValidProductData] Error <${error.ATT_NO_OPTIONS}> | Attribute <${att.code}> in product <${product.id}> has no options`
          );
        }
      });
    } else {
      throw new Error(
        `[@useCms/utils/productDataValidator::isValidProductData] Error <${error.PRODUCT_NO_ATTRIBUTES}> | Product <${product.id}> has no attributes configured`
      );
    }

    if (!product.variants?.length) {
      throw new Error(
        `[@useCms/utils/productDataValidator::isValidProductData] Error <${error.PRODUCT_NO_VARIANTS}> | Product <${product.id}> has no variants configured`
      );
    }

    return true;
  } catch (err) {
    instance.$log.error(err.message, { product });
    return false;
  }
};
