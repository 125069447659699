import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  extractProperty,
  getTeaserStyle,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
  getButtonStyles,
  appendParamsToUrl,
  getTeaserLink,
} from '.';
import { getDataNavigationValue } from './extractProperty';
import { decorateHtmlValue } from './decorateHtmlValue';
import { ComposableContext } from '../../types';

export const getMediaWithOverlay = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  let imageLink = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'imageTarget')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  if (imageLink && data?.urlParams) {
    imageLink = appendParamsToUrl(imageLink, data.urlParams);
  }

  /** Category link with url's params from CMS to the imageLink */
  let link =
    extractProperty(data, 'teaserTargets.length', 0) > 0
      ? getTeaserLink(
          data.teaserTargets[0],
          context,
          siteConfiguration,
          cmsBaseUri
        )
      : '';

  if (link && data?.urlParams) {
    link = appendParamsToUrl(link, data.urlParams);
  }

  return {
    props: {
      category: {
        imageLink,
        title: data.teaserTitle,
        subtitle: data.teaserSubtitle,
        text: data.useRichText
          ? decorateHtmlValue(
              data.teaserRichTextObject,
              cmsBaseUri,
              context,
              siteConfiguration
            )
          : data.teaserText,
        useRichText: extractProperty(data, 'useRichText', false),

        link,
        buttonText: buttonText(data, '0'),
        styles: styles(data),
      },
      firstButtonStyles: {
        buttonStyleSmall: getButtonStyles(data, '0', 'style', 'text'),
        buttonStyleLarge: getButtonStyles(data, '0', 'style'),
        buttonSize: getButtonStyles(data, '0', 'size'),
        buttonIcon: getButtonStyles(data, '0', 'icon', ''),
        buttonIconPosition: getButtonStyles(data, '0', 'iconPosition', ''),
        buttonTargetComponent: getButtonTarget(data, '0'),
      },
      dataNavigation: getDataNavigationValue(data),
      ...overlaySettings(data),
      ctaButtons: getButtons(data, context, siteConfiguration, cmsBaseUri),
    },
  };
};

const overlaySettings = (data: CMTeaser) => {
  return {
    showOverlay: {
      small: getTeaserOverlaySetting(data, 'showOverlay', 'small', true),
      medium: getTeaserOverlaySetting(data, 'showOverlay', 'medium', true),
      large: getTeaserOverlaySetting(data, 'showOverlay', 'large', true),
    },
    transparentOverlay: {
      small: getTeaserOverlaySetting(data, 'transparentBackground', 'small'),
      medium: getTeaserOverlaySetting(data, 'transparentBackground', 'medium'),
      large: getTeaserOverlaySetting(data, 'transparentBackground', 'large'),
    },
    alwaysShowOverlay: {
      small: getTeaserOverlaySetting(data, 'alwaysShowContent', 'small'),
      medium: getTeaserOverlaySetting(data, 'alwaysShowContent', 'medium'),
      large: getTeaserOverlaySetting(data, 'alwaysShowContent', 'large'),
    },
    showBelowArticle: {
      small: getTeaserOverlaySetting(data, 'showBelowImage', 'small', false),
      medium: getTeaserOverlaySetting(data, 'showBelowImage', 'medium', false),
      large: getTeaserOverlaySetting(data, 'showBelowImage', 'large', false),
    },
    showButtonsBelowArticle: {
      small: getTeaserOverlaySetting(data, 'buttonsBelowImage', 'small', false),
      medium: getTeaserOverlaySetting(
        data,
        'buttonsBelowImage',
        'medium',
        false
      ),
      large: getTeaserOverlaySetting(data, 'buttonsBelowImage', 'large', false),
    },
    overlaySettingsSmall: {
      hOffset: getTeaserOverlaySetting(data, 'hOffset', 'small'),
      vOffset: getTeaserOverlaySetting(data, 'vOffset', 'small'),
      width: getTeaserOverlaySetting(data, 'width', 'small', ''),
      color: getTeaserOverlaySetting(data, 'overlayColor', 'small', ''),
      opacity: getTeaserOverlaySetting(data, 'overlayOpacity', 'small', 0),
      showOnHover: false,
    },
    overlaySettingsMedium: {
      hOffset: getTeaserOverlaySetting(data, 'hOffset', 'medium'),
      vOffset: getTeaserOverlaySetting(data, 'vOffset', 'medium'),
      width: getTeaserOverlaySetting(data, 'width', 'medium'),
      color: getTeaserOverlaySetting(data, 'overlayColor', 'medium'),
      opacity: getTeaserOverlaySetting(data, 'overlayOpacity', 'medium'),
      showOnHover: getTeaserOverlaySetting(
        data,
        'showContentOnHover',
        'medium',
        false
      ),
    },
    overlaySettingsLarge: {
      hOffset: getTeaserOverlaySetting(data, 'hOffset', 'large'),
      vOffset: getTeaserOverlaySetting(data, 'vOffset', 'large'),
      width: getTeaserOverlaySetting(data, 'width', 'large'),
      color: getTeaserOverlaySetting(data, 'overlayColor', 'large'),
      opacity: getTeaserOverlaySetting(data, 'overlayOpacity', 'large'),
      showOnHover: getTeaserOverlaySetting(
        data,
        'showContentOnHover',
        'large',
        false
      ),
    },
    contentPosition: {
      small: getTeaserOverlaySetting(
        data,
        'position',
        'small',
        'middle_center'
      ),
      medium: getTeaserOverlaySetting(
        data,
        'position',
        'medium',
        'middle_center'
      ),
      large: getTeaserOverlaySetting(
        data,
        'position',
        'large',
        'middle_center'
      ),
    },
    textAlign: {
      small: getTeaserOverlaySetting(data, 'textAlign', 'small', 'center'),
      medium: getTeaserOverlaySetting(data, 'textAlign', 'medium', 'center'),
      large: getTeaserOverlaySetting(data, 'textAlign', 'large', 'center'),
    },
  };
};

const getTeaserOverlaySetting = (
  data,
  setting:
    | 'showOverlay'
    | 'showContentOnHover'
    | 'alwaysShowContent'
    | 'showBelowImage'
    | 'transparentBackground'
    | 'position'
    | 'hOffset'
    | 'vOffset'
    | 'width'
    | 'textAlign'
    | 'overlayColor'
    | 'overlayOpacity'
    | 'buttonsBelowImage',
  viewport: string,
  defaultValue?: unknown
) => {
  const positionValue = extractProperty(
    data,
    `teaserOverlayPosition.${viewport}.${setting}`,
    defaultValue || null
  );
  return positionValue !== '' ? positionValue : defaultValue;
};

const styles = (data) => {
  return {
    titleColor: getTeaserStyle(data, 'teaserTitleColor'),
    titleBackgroundColor: getTeaserStyle(data, 'teaserTitleBackgroundColor'),
    titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
    titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
    titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
    headingLevel: getTeaserStyle(data, 'teaserTitleHeadingLevel'),
    titleHeadingStyle: getTeaserStyle(data, 'teaserTitleHeadingStyle'),
    titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
    textColor: getTeaserStyle(data, 'teaserTextColor'),
    textBackgroundColor: getTeaserStyle(data, 'teaserTextBackgroundColor'),
    textClassModifiers: getTeaserStyle(data, 'teaserTextClassModifiers'),
    subtitleColor: getTeaserStyle(data, 'teaserSubTitleColor'),
    subtitleFontSize: getTeaserStyle(data, 'teaserSubTitleFontSize'),
    subtitleFontWeight: getTeaserStyle(data, 'teaserSubTitleFontWeight'),
    subtitleFontFamily: getTeaserStyle(data, 'teaserSubTitleFontFace'),
    subtitleBackgroundColor: getTeaserStyle(
      data,
      'teaserSubTitleBackgroundColor'
    ),
    subtitleClassModifiers: getTeaserStyle(
      data,
      'teaserSubTitleClassModifiers'
    ),
    subtitleHeadingStyle: getTeaserStyle(data, 'teaserSubTitleHeadingStyle'),
  };
};

const buttonText = (data, index: string) => {
  return (
    extractProperty(data, `teaserTargets[${index}].callToActionText`, '') ||
    extractProperty(data, `teaserTargets[${index}].target.teaserTitle`, '')
  );
};

const getButtonTarget = (data, index: string) => {
  const targetType = extractProperty(data, `teaserTargets[0].target.type`, '');
  if (targetType === 'CMTeaser') {
    const nestedTarget = extractProperty(
      data,
      `teaserTargets[${index}].target.teaserTargets[0].target.type`,
      ''
    );
    return nestedTarget;
  }
  return targetType;
};

export const getButtons = (data, context, siteConfiguration, cmsBaseUri) => {
  let buttons = extractProperty(data, 'teaserTargets');

  /** Slicing first button, because it is already declared and allowing to add max 3 buttons */
  buttons = buttons.slice(
    1,
    context.instance.$themeConfig?.easyComponents.numberOfCtas || 2
  );
  const ctas = buttons.map((button, index) => {
    return {
      buttonText: buttonText(data, index + 1),
      buttonStyle: getButtonStyles(data, index + 1, 'style'),
      buttonSize: getButtonStyles(data, index + 1, 'size'),
      buttonIcon: getButtonStyles(data, index + 1, 'icon', ''),
      buttonIconPosition: getButtonStyles(data, index + 1, 'iconPosition', ''),
      buttonLink: getTeaserLink(
        data.teaserTargets[index + 1],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      buttonUnderline: getButtonStyles(data, index + 1, 'underline', ''),
      buttonTargetComponent: getButtonTarget(data, index + 1),
    };
  });
  return ctas;
};
