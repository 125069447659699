import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, headerBuilder, urlBuilder } from '../../helpers';

export const getStoresStores = (
  configuration: ApiClientConfiguration
) => async (params: {
  city?: string;
  country?: string;
  state?: string;
  store?: string;
}): Promise<any> => {
  return axios.get(
    urlBuilder(endpoints.get(configuration).stores.getStores, {
      config: configuration,
    }),
    {
      headers: headerBuilder(configuration),
      params,
    }
  );
};
