import { Filter } from '@vf/composables/src/types';
import { FacetCmsConfiguration } from '@vf/composables/src/useCms/types';

const MAX_SIMULTANEOUS_FACETS = 2;
const MAX_SIMULTANEOUS_FILTERS = 1;

/**
 * Returns grouped filters by facet
 * @param filters
 */
const getFiltersByFacet = (filters: Filter[]): Record<string, Filter[]> =>
  filters.reduce((acc, filter) => {
    acc[filter.code] = acc[filter.code] || [];
    acc[filter.code].push(filter);
    return acc;
  }, {});

/**
 * Check is SEO filter limits exceeded
 * @param filters - active filters
 */
const isSEOLimitsExceeded = (filters: Filter[]): boolean => {
  const groupedFilters = getFiltersByFacet(filters);

  if (Object.keys(groupedFilters).length <= MAX_SIMULTANEOUS_FACETS) {
    return Object.values(groupedFilters).some(
      (f) => f.length > MAX_SIMULTANEOUS_FILTERS
    );
  } else {
    return true;
  }
};

export const isFilterFollowable = (
  filterCode: string,
  filters: Filter[]
): boolean => {
  const groupedFilters = getFiltersByFacet(filters);
  /**
   * All filter links are not followable if we've matched or exceeded the maxFacets limit.
   */
  const overMaxFacets =
    Object.keys(groupedFilters).length >= MAX_SIMULTANEOUS_FACETS;
  if (overMaxFacets) return false;

  /**
   * For the currently selected facet group, return if we've exceeded the maxFilter limit.
   */
  if (filterCode in groupedFilters) {
    return groupedFilters[filterCode].length < MAX_SIMULTANEOUS_FILTERS;
  }

  return true;
};

export const isPageIndexable = (
  filters: Filter[],
  facetConfiguration: Record<string, FacetCmsConfiguration>
): boolean => {
  /**
   * Every selected filter needs to be marked as 'crawlable' in facetConfigs placement
   * in order to make PLP page indexable (robots='index, follow')
   * Otherwise we mark PLP page as not indexable (robots='noindex, follow')
   */
  const cmsConfigurationSatisfied = filters.every(
    (selectedFilter) => !!facetConfiguration[selectedFilter.code]?.crawlable
  );

  if (!cmsConfigurationSatisfied) {
    return false;
  }
  /**
   * If there are no selected filters it's obvious, let PLP page be indexed.
   */
  if (!filters.length) {
    return true;
  }

  /**
   * Calculate if selected filters exceeded the limits
   */
  return !isSEOLimitsExceeded(filters);
};

export const getPageTitle = (
  category: string,
  filters: Filter[],
  sfccMetaTitle?: string
): string => {
  if (sfccMetaTitle) return sfccMetaTitle;
  if (!filters.length || isSEOLimitsExceeded(filters)) {
    return category;
  }

  const filtersByFacet = getFiltersByFacet(filters);
  const filtersStr = Object.keys(filtersByFacet).reduce((acc, facet) => {
    const f = filtersByFacet[facet].map(({ value }) => value).join(' & ');
    acc.push(`${facet}: ${f}`);
    return acc;
  }, [] as string[]);

  return `${category} | ${filtersStr.join(' | ')}`;
};

/**
 * Generate page meta description
 * @param category
 * @param filters - alphabetically sorted active filters
 * @param brandName
 * @param translation
 */
export const getMetaDescription = (
  category: string,
  filters: Filter[],
  brandName: string,
  translation: string
): string => {
  const hideDescription = !filters.length || isSEOLimitsExceeded(filters);
  const filtersStr = filters.map(({ value }) => value).join(', ');

  return `${translation
    .replace('{{category}}', category)
    .replace('{{filters}}', hideDescription ? '' : filtersStr)} ${brandName}`;
};
