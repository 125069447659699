export const iconColors = {
  greenPrimary: 'green-primary',
  greenSecondary: 'green-secondary',
  black: 'black',
  darkSecondary: 'dark-secondary',
  grayPrimary: 'gray-primary',
  graySecondary: 'gray-secondary',
  lightPrimary: 'light-primary',
  white: 'white',
  redPrimary: 'red-primary',
  redSecondary: 'red-secondary',
  yellowPrimary: 'yellow-primary',
  yellowSecondary: 'yellow-secondary',
  bluePrimary: 'blue-primary',
  blueSecondary: 'blue-secondary',
};
export const colors = {
  primary: 'color-primary',
  secondary: 'color-secondary',
  light: 'color-light',
  dark: 'color-dark',
  info: 'color-info',
  success: 'color-success',
  warning: 'color-warning',
  danger: 'color-danger',
};
export const colorsValues = Object.values(colors);
export const iconColorsValues = Object.values(iconColors);
export default colors;
