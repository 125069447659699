import type { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';
import { axios } from '../../helpers';

export const getCloudinaryAnyList = (configuration: ApiClientConfiguration) => (
  productId: string,
  cloudinaryDomain: string
): Promise<
  AxiosResponse<{
    resources: {
      created_at: string;
      public_id: string;
      format: string;
      width: number;
      height: number;
      metadata: {
        external_id: string;
        value: string | number;
      }[];
      resource_type: 'image' | 'video';
    }[];
  }>
> => {
  const url = `${cloudinaryDomain}/any/list/${productId}.json`;
  configuration.logger.debug('Get Cloudinary Any List', { url });
  return axios.get(url);
};
