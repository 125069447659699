import { extractProperty, getDynamicField } from './utils';
import { CMCollection } from '@vf/api-contract';

export const promoBar = (data: CMCollection) => {
  return {
    component: 'cart/PromoBar',
    props: {
      enableOverlayArrows: getDynamicField(data, 'enableOverlayArrows', false),
      backgroundColor: getDynamicField(data, 'backgroundColor', '#000000'),
      enablePauseOnHover: getDynamicField(data, 'enablePauseOnHover', true),
      promosPerSlide: getDynamicField(data, 'promosPerSlide', '1'),
      enableSwiping: getDynamicField(data, 'enableSwiping', true),
      interval: getDynamicField(data, 'interval', 2000),
      textColor: getDynamicField(data, 'textColor', '#FFFFFF'),
      actionButton: extractProperty(
        data,
        'localSettings.dynamicFields.actionButton',
        'Details'
      ),
      actionButtonType: extractProperty(
        data,
        'localSettings.dynamicFields.actionButtonType',
        'Modal'
      ),
      teasableItems: data.teasableItems,
      teasableItemsCount: data.teasableItemsCount,
    },
  };
};
