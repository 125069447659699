import {
  CmsBreadcrumb,
  InitializedApiClient,
  PreviewContentType,
  PreviewType,
} from '../types';

export const getPreviewData = async (
  type: PreviewType,
  id: string,
  contentType: PreviewContentType,
  commerceId: string,
  siteId: string,
  apiClient: InitializedApiClient
): Promise<{
  content: any;
  errors: any[];
  headerVisibility?: string;
  footerVisibility?: string;
  breadcrumbs?: CmsBreadcrumb[];
}> => {
  try {
    switch (type) {
      case 'page':
        return await getPagePreviewData(
          id,
          contentType,
          commerceId,
          siteId,
          apiClient
        );

      case 'fragment':
        return await getFragmentPreviewData(id, apiClient);
    }
  } catch (e) {
    return {
      content: null,
      errors: [`HTTP Error while trying to get preview data: ${e.message}`],
    };
  }
};

const getPagePreviewData = async (
  id: string,
  contentType: PreviewContentType,
  commerceId: string,
  siteId: string,
  apiClient: InitializedApiClient
) => {
  switch (contentType) {
    case 'CMExternalChannel': {
      return {
        content: (await apiClient.getCategoryPageContent(siteId, commerceId))
          .data.content.commercepage,
        errors: [],
      };
    }
    case 'CMExternalProduct': {
      return {
        content: (await apiClient.getProductPageContent(siteId, commerceId))
          .data.content.commercepage,
        errors: [],
      };
    }

    default: {
      return {
        content: (await apiClient.getPageContent(id)).pagecontent,
        errors: [],
      };
    }
  }
};

const getFragmentPreviewData = async (
  id: string,
  apiClient: InitializedApiClient
) => {
  const fragmentData = await apiClient.getFragment(id);

  return {
    content: {
      grid: {
        cssClassName: '',
        rows: [
          {
            placements: [
              {
                pageItems: [fragmentData.data.content.content],
              },
            ],
          },
        ],
      },
      locales: [],
    },
    errors: [],
  };
};
