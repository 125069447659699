import { CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  getTeaserStyle,
  getDynamicField,
  getTranslation,
  extractCommonConfigLink,
  generateLinkFromTarget,
} from './utils';
import { getQuickShopTranslations } from './utils/getQuickShopTranslations';

export const recommendationCarousel = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const quickShopLink = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'plp-quick-shop'),
    '[0]'
  );
  const signInToBuy = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

  const loyaltyEnrollment = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'loyalty-enrollment-modal'),
    '[0]'
  )?.id;
  return {
    component: 'monetate/MonetateProductRecommendations',
    props: {
      translations: {
        heading: extractProperty(data, 'teaserTitle', '$We may also suggest$'),
        quickShop: getTranslation(data, 'quickShop', '$Quick Shop$'),
        ...getQuickShopTranslations(data),
      },
      showPrice: getDynamicField(data, 'showPrice', true),
      headingLevel: parseInt(getTeaserStyle(data, 'teaserTitleHeadingLevel')),
      headerFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
      headerTextAlign: getDynamicField(data, 'headerTextAlign', 'left'),
      showAddToFavorites: getDynamicField(data, 'showAddToFavorites', false),
      showQuickShopCta: getDynamicField(data, 'showQuickShopCta', true),
      showMobileOnTwoRows: getDynamicField(data, 'showMobileOnTwoRows', false),
      isCarousel: getDynamicField(data, 'isCarousel', true),
      isRandomized: getDynamicField(data, 'isRandomized', false),
      slideFullRow: getDynamicField(data, 'slideFullRow', false),
      autoplay: getDynamicField(data, 'autoplay', false),
      loop: getDynamicField(data, 'loop', false),
      showAsPeek: {
        small: getDynamicField(data, 'showAsPeekSmall', false),
        medium: getDynamicField(data, 'showAsPeekMedium', false),
        large: getDynamicField(data, 'showAsPeekLarge', false),
      },
      slideDuration: getDynamicField(data, 'slideDuration', 2000),
      quickShopLink: generateLinkFromTarget(
        quickShopLink,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      ...(data._injectedProps ? data._injectedProps : {}),
      displayedProductsSmall: getDynamicField(
        data,
        'displayedProductsSmall',
        2
      ),
      displayedProductsMedium: getDynamicField(
        data,
        'displayedProductsMedium',
        4
      ),
      displayedProductsLarge: getDynamicField(
        data,
        'displayedProductsLarge',
        6
      ),
      modals: {
        signInToBuy,
        loyaltyEnrollment,
      },
    },
  };
};
