import { FlashError } from '@vf/api-client/src/types';
import { computed, ref, Ref } from '@vue/composition-api';
import { FlashErrorType } from '@vf/api-contract';
import { ComposablesStorage } from '../../../../src/types';
import { UseCartStorage } from '../../../../src/useCart/types';
import initStorage from '../../../../src/utils/storage';

const ProductRelatedFlashErrorCodes = [
  FlashErrorType.Invalid,
  FlashErrorType.NotAvailable,
  FlashErrorType.InventoryMissing,
  FlashErrorType.ProductItemMaxQuantity,
  FlashErrorType.FullInventoryMissing,
  FlashErrorType.NoLongerAvailable,
  FlashErrorType.ProductStyleMaxQuantity,
];

const FlashErrorCodesToDisplay = [
  FlashErrorType.InventoryMissing,
  FlashErrorType.FullInventoryMissing,
  FlashErrorType.ProductItemMaxQuantity,
  FlashErrorType.NoLongerAvailable,
];

export const useFlashErrors = (
  instance,
  { cartRef, isPageHasBeenChanged },
  contextKey
) => {
  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );
  const cachedFlashErrors: Ref<FlashError[]> = storage.getOrSave(
    'cachedFlashErrors',
    ref([])
  );

  // TODO errorDetails types
  const updateCartFlashesFromError = (errorDetails) => {
    // TODO why error?.message can be just error.message?
    const messages = errorDetails.map((error) => error?.message).flat();
    const flashes: FlashError[] = messages
      // TODO why message?._type can be just message._type?
      .filter((message) => message?._type === 'flash')
      .map((rawError) => {
        return {
          code: rawError.type,
          path: rawError.path,
          details: rawError.details,
        };
      });
    updateCartFlashes(flashes);
  };

  const updateCartFlashes = (
    flashes: FlashError[] = [],
    forceUpdate = false
  ) => {
    if (!cachedFlashErrors.value) {
      // TODO is this if needed if we init this with array?
      cachedFlashErrors.value = [];
    }
    // TODO why we reset flash here if we assign it lower?
    cartRef.value.flash = [];
    if (isPageHasBeenChanged.value || forceUpdate) {
      cachedFlashErrors.value = flashes;

      /** Set page changed flag after we update the flashes/notifications array */
      isPageHasBeenChanged.value = false;
    } else {
      flashes.forEach((error) => {
        // TODO CLEANUP!
        const existingFlashIndex = cachedFlashErrors.value.findIndex(
          (existingError) => {
            if (error.details) {
              return (
                existingError?.details?.productId === error?.details?.productId
              );
            }
            return (
              existingError.code === error.code &&
              existingError.path === error.path
            );
          }
        );
        if (existingFlashIndex === -1) {
          cachedFlashErrors.value.push(error);
        } else {
          cachedFlashErrors.value[existingFlashIndex] = error;
        }
      });
    }
    cartRef.value = {
      ...cartRef.value,
      flash: cachedFlashErrors.value || [],
    };
  };

  const outOfStockFlashErrors = computed(() => {
    return cartRef.value.flash.filter((error) => {
      return (
        error.code === FlashErrorType.NoLongerAvailable ||
        (error.code === FlashErrorType.FullInventoryMissing &&
          error.details.availableQty === 0 &&
          error.details.missingQty >= 1)
      );
    });
  });

  const getProductRelatedFlashMessages = () => {
    return (
      cartRef.value.flash?.filter((error) =>
        ProductRelatedFlashErrorCodes.includes(error.code)
      ) ?? []
    );
  };

  const hasFlashErrorsToDisplay = computed(() => {
    return cartRef.value.flash.some((error) =>
      FlashErrorCodesToDisplay.includes(error.code)
    );
  });

  return {
    getProductRelatedFlashMessages,
    hasFlashErrorsToDisplay,
    updateCartFlashesFromError,
    outOfStockFlashErrors,
    updateCartFlashes,
  };
};
