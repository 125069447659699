import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useI18n } from '../useI18n';

import type { ComponentInstance } from '../types';

enum DynatraceActionType {
  API = 'API Error Received',
  NON_API = 'Unexpected non-API error',
  MONETATE_EXPERIENCE_RENDERED = 'Monetate experience rendered',
}

type DynatraceActionProperties = {
  experienceId: string;
  variantId: string;
  variantName: string;
};

export const useDynatrace = (instance: ComponentInstance) => {
  const {
    isDynatraceRumEnabled,
    isPushMonetateExperienceDataToDynatraceEnabled,
  } = useFeatureFlagsStore();
  const { getStaticTranslation } = useI18n(instance);

  const pushActionToDynatrace = (
    actionType: DynatraceActionType,
    message: string,
    messageId?: string,
    correlationId?: string
  ) => {
    if (!isDynatraceRumEnabled || !isClient || !window.dtrum) return;

    const muleSoftErrorsTranslations = getStaticTranslation('muleSoftErrors');
    const userFacingWarningMessage =
      messageId && muleSoftErrorsTranslations[messageId]
        ? muleSoftErrorsTranslations[messageId]
        : message;

    const actionId = window.dtrum.enterAction(actionType);
    window.dtrum.addActionProperties(actionId, null, null, {
      warningmessage: userFacingWarningMessage, // get error message displaye to the user
      warningmessagemule: message, // get error message returned from MuleSoft
      warningmessageid: messageId, // get the error ID for the warning message. Example "AUT401"
      warningtransactionid: correlationId,
    });

    window.dtrum.addActionProperties(actionId, null, null, {
      monetateExperience: message, // get error message returned from MuleSoft
      monetateExperienceVariant: messageId, // get the error ID for the warning message. Example "AUT401"
    });

    window.dtrum.leaveAction(actionId);
    instance.$log.debug(
      `[@useDynatrace::pushActionToDynatrace] Dynatrace action pushed.`,
      {
        actionId: actionId,
        warningmessage: userFacingWarningMessage,
        warningmessagemule: message,
        warningmessageid: messageId,
        warningtransactionid: correlationId,
      }
    );
  };

  const pushMonetateExperienceRenderedToDynatrace = (
    properties: DynatraceActionProperties
  ) => {
    if (
      !isDynatraceRumEnabled ||
      !isPushMonetateExperienceDataToDynatraceEnabled ||
      !isClient ||
      !window.dtrum
    )
      return;
    try {
      const actionId = window.dtrum.enterAction(
        DynatraceActionType.MONETATE_EXPERIENCE_RENDERED
      );
      window.dtrum.addActionProperties(actionId, null, null, {
        monetateexperienceid: properties.experienceId,
        monetateexperiencevariantid: properties.variantId,
        monetateexperiencevariantname: properties.variantName,
      });
      window.dtrum.leaveAction(actionId);
      instance.$log.debug(
        `[@useDynatrace::pushMonetateExperienceRenderedToDynatrace] Monetate experience rendered data pushed to Dynatrace. Action ID: ${actionId}`,
        properties
      );
    } catch (err) {
      instance.$log.error(
        `[@useDynatrace::pushMonetateExperienceRenderedToDynatrace] Error pushing Monetate experience rendered data to Dynatrace`,
        err
      );
    }
  };

  return {
    DynatraceActionType,
    pushActionToDynatrace,
    pushMonetateExperienceRenderedToDynatrace,
  };
};
