import { CmsQuery } from '../types';
import { pagePathToMockMapping } from '../utils/pagePathToMockMapping';

export const getSiteConfigurationQuery = (id: string): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/site/:id',
    params: { id },
    mockedFileName: 'site',
  };
};
export const getPageContentQuery = (id: string): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/pagecontent/:id',
    params: { id },
    mockedFileName: id,
  };
};
export const getPageContentByPathQuery = (
  path: string,
  segment: string
): CmsQuery => {
  return {
    method: 'get' as const,
    uri: `/caas/v1/pagecontentbypath/${segment}:path`,
    params: { path },
    mockedFileName: pagePathToMockMapping(path),
  };
};
export const getCategoryPageContentQuery = (
  siteId: string,
  commercePagePath: string
): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/commercepage/:siteId/category/:commercePagePath',
    params: { siteId, commercePagePath },
    mockedFileName: 'category',
  };
};
export const getProductPageContentQuery = (
  siteId: string,
  productId: string
): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/commercepage/:siteId/product/:productId',
    params: { siteId, productId },
    mockedFileName: 'product',
  };
};
export const getMenuQuery = (id: string, menuEndpoint: string): CmsQuery => {
  return {
    method: 'get' as const,
    uri: ':menuEndpoint/:id',
    params: { id, menuEndpoint },
    mockedFileName: 'menu',
  };
};
export const getFragmentQuery = (id: string): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/fragment/:id',
    params: { id },
  };
};
export const getCmsArticlesQuery = (siteId: string): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/articles/:siteId',
    params: { siteId },
  };
};

export const getFragmentFolderQuery = (
  folder: string,
  domain: string,
  fragmentName: string
): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/fragmentbyfolder/:folder/:domain/:fragmentName',
    params: { folder, domain, fragmentName },
    mockedFileName: fragmentName,
  };
};

export const getFlexibleContentByPathQuery = (siteId: string): CmsQuery => {
  return {
    method: 'get' as const,
    uri: '/caas/v1/flexiblecontentbypath/:siteId',
    params: { siteId },
  };
};
