import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import type { ApiClientConfiguration } from '../../configuration';

export const confirmForgotPassword = (
  configuration: ApiClientConfiguration
) => (data): Promise<any> => {
  return axios.post(
    urlBuilder(
      endpoints.get(configuration).authentication.confirmForgotPassword,
      {
        config: configuration,
      }
    ),
    {
      password: data.password,
      confirmationCode: data.confirmationCode,
    },
    {
      headers: headerBuilder(configuration),
      params: {
        captchaResponse: data.captchaResponse,
      },
    }
  );
};
