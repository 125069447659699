
































































import type { PropType } from 'vue';
import type { SpinnerTranslations } from '@vf/api-contract';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object as PropType<SpinnerTranslations>,
      default: () => ({}),
    },
  },
});
