import { CmsPlacement, CmsSite, VFFlexibleComponent } from '@vf/api-contract';
import { ComposableContext } from '../../../types';
import * as mappings from '../../../mappings';
import { camelCase } from '../camelCase';

export const flexibleContentByPath = (
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string
) => {
  return (content: any[]) => {
    const arr = content
      .flatMap((item) => item.placements)
      .flatMap((placement: Partial<CmsPlacement>) =>
        placement.pageItems.filter(
          (pageItem) => pageItem.type === 'VfFlexibleComponent'
        )
      )
      .map((pageItem: VFFlexibleComponent) => {
        const geminiTag = getGeminiTag(pageItem.viewType);
        const mappingFunction = mappings[geminiTag];
        return mappingFunction
          ? mappingFunction(pageItem, context, siteConfiguration, cmsBaseUri)
          : null;
      })
      .filter(Boolean);
    return arr;
  };
};

export const getGeminiTag = (viewType: string) => {
  const camelCased = camelCase(viewType.replace(/^flexible-/, ''));
  const componentName =
    camelCased.charAt(0).toUpperCase() + camelCased.slice(1);

  const prefix = 'gemini';

  return `${prefix}${componentName}`;
};
