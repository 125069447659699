import { PageTypeName } from '../types';

/**
 * Traverse page content and get all faq-questions inside
 * @param data
 */
const getQuestions = (data: any) => {
  const questions = [];
  let visiting = [data];

  while (visiting.length > 0) {
    const toVisit = [];

    for (const e of visiting) {
      if (Array.isArray(e) && e.length > 0) {
        toVisit.push(...e);
      } else {
        if (e.viewtype === 'faq-question') {
          questions.push({
            '@type': 'Question',
            name: e.teaserTitle,
            acceptedAnswer: {
              '@type': 'Answer',
              text: e.teaserRichText,
            },
          });
        } else {
          toVisit.push(
            ...Object.values(e).filter((k) => typeof k === 'object' && k)
          );
        }
      }
    }
    visiting = toVisit;
  }

  return questions;
};

const getNewsArticleData = (content: any, jsonLdOrganization) => {
  return {
    type: 'application/ld+json',
    json: {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://google.com/article',
      },
      headline: content.title,
      image: content.pictures?.map((pic) => pic.dataUrl) || [],
      datePublished: content.publicationDate,
      dateModified: content.modificationDate,
      author: {
        '@type': 'Person',
        name: content.authorName || '',
      },
      publisher: {
        ...jsonLdOrganization,
        '@id': `#${jsonLdOrganization['@id']}`,
      },
    },
  };
};

const getEventData = (content: any) => {
  if (!content) {
    return {};
  }

  return {
    type: 'application/ld+json',
    json: {
      '@context': 'https://schema.org',
      '@type': 'Event',
      name: content.title,
      // Remove timezone name
      // e.g. 2022-07-14T00:00-04:00[America/New_York] -> 2022-07-14T00:00-04:00
      startDate: content.event.startDate?.split('[')[0],
      endDate: content.event.endDate?.split('[')[0],
      eventAttendanceMode: content.event.eventAttendanceMode,
      eventStatus: content.event.eventStatus,
      location: content.event.location,
      description: content.teaserText,
      image: content.pictures?.map((pic) => pic.dataUrl) || [],
      offers: content.event.offers,
      performer: content.event.performer,
      organizer: content.event.organizer,
    },
  };
};

const getFAQData = (content: any) => ({
  type: 'application/ld+json',
  json: {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: getQuestions(content),
  },
});

export const getStructuredData = (pageContent: any, jsonLdOrganization) => {
  const { content } = pageContent;

  switch (pageContent.pageTypeName) {
    case PageTypeName.NEWS_ARTICLE:
      return {
        [pageContent.pageTypeName]: getNewsArticleData(
          content,
          jsonLdOrganization
        ),
      };
    case PageTypeName.EVENT:
      return {
        [pageContent.pageTypeName]: getEventData(content),
      };
    case PageTypeName.FAQ:
      return {
        [pageContent.pageTypeName]: getFAQData(content),
      };
    default:
      return {};
  }
};
