import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import merge from '@vf/composables/src/utils/merge';

const prepareCustoms = async () => {
  try {
    await import(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Cannot find module or its corresponding type declarations.
      '@vfdp-customs/customs-umd-lib/dist/customs-umd-lib.css' /* webpackChunkName: "customs" */
    );

    const CustomsVueComponents = await import(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Cannot find module or its corresponding type declarations.
      '@vfdp-customs/customs-umd-lib/dist/customs-umd-lib.umd.min.js' /* webpackChunkName: "customs" */
    );

    if (CustomsVueComponents) {
      const currentEvents = Vue.prototype.$eventBus._events;

      Vue.use(CustomsVueComponents.default);

      // The customs UMD lib can change the eventBus on the Vue prototype with a new one,
      // and since this happens at runtim, we need to preserve any events listeners
      // that have already been attached at the customs initialization point
      Vue.prototype.$eventBus._events = merge(
        Vue.prototype.$eventBus._events,
        currentEvents
      );
    }
  } catch (e) {
    console.log(
      'Optional dependency not available, iframe will be used instead'
    );
  }
};

const CustomizerPlugin: Plugin = async (context, inject: Inject) => {
  if (context.app.$env.CUSTOMS_ENABLED === 'true') {
    inject('prepareCustoms', prepareCustoms);
  }
};

export default CustomizerPlugin;
