import { ComponentInstance } from '@vf/composables/src/types';
import { Product } from '@vf/api-client';

export const addVariantOptionToUrlParams = (
  root: ComponentInstance,
  variantKey: string,
  optionValue: string,
  path?: string
): string => {
  const fullPath = path ?? root.$route.fullPath;
  let newPath = '';
  if (fullPath.includes('?')) {
    const urlSegments = fullPath.split('?');
    const urlParams = new URLSearchParams(`?${urlSegments[1]}`);
    urlParams.set(variantKey, optionValue);
    newPath = `${root.$route.path}?${urlParams.toString()}`;
  } else {
    newPath = `${root.$route.path}?${variantKey}=${optionValue}`;
  }
  return newPath;
};

// Used to add variants selected to url apart from color
// which already comes in urls provided in API response
export const addOtherVariantOptionsToColorUrl = (
  root: ComponentInstance,
  colorUrl: string,
  product: Product
): string => {
  let newPath = colorUrl;
  if (product.size) {
    newPath = addVariantOptionToUrlParams(
      root,
      'size',
      product.size.value,
      newPath
    );
  }

  if (product.length) {
    newPath = addVariantOptionToUrlParams(
      root,
      'length',
      product.length.value,
      newPath
    );
  }

  return newPath;
};
