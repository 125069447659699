import { inject, provide } from '@vue/composition-api';
import createThrowContextErrorFunction from './utilities/createThrowContextErrorFunction';

type ModalHandlers = {
  openModal?: (modal: Modal) => void;
  closeModal?: () => void;
};

type UseModalHandlersReturn = {
  openModal: ModalHandlers['openModal'];
  closeModal: ModalHandlers['closeModal'];
  provideModalHandlers: (handlers: ModalHandlers) => void;
};

type Modal = {
  type: string;
  path?: string;
  resourceId?: string;
};

const ModalHandlersKey = Symbol('ModalHandlers');

const provideModalHandlers = (handlers: ModalHandlers) => {
  provide(ModalHandlersKey, handlers);
};

function useModalHandlers(): UseModalHandlersReturn {
  const { openModal, closeModal } = inject(ModalHandlersKey, {
    openModal: createThrowContextErrorFunction(ModalHandlersKey, 'openModal'),
    closeModal: createThrowContextErrorFunction(ModalHandlersKey, 'closeModal'),
  });

  return {
    openModal,
    closeModal,
    provideModalHandlers,
  };
}

export default useModalHandlers;
