const MAX_STYLECODE_LENGTH = 8;

export const generateProductIdThumbnailsScene7 = (
  productId: string
): string => {
  if (
    // in PLPs, what this util gets is just the stylecode, and for that case we don't want to process it
    productId &&
    productId.length > MAX_STYLECODE_LENGTH &&
    !productId.includes('_')
  ) {
    const masterIdLength: number = productId.length - 3;
    const masterId: string = productId.slice(0, masterIdLength);
    const productColorValue: string = productId.slice(
      masterId.length,
      productId.length
    );

    return `${masterId}_${productColorValue}`;
  } else {
    return productId;
  }
};
