import { endpoints } from '../../config';
import {
  headerBuilder,
  urlBuilder,
  axios,
  getCacheWrapperConfig,
  getClientInstance,
} from '../../helpers';
import wrapper from 'axios-cache-plugin';
import type { ApiClientConfiguration } from '../../configuration';

const productsWrapperConfig = getCacheWrapperConfig('products');

const http = wrapper(axios, productsWrapperConfig);

http.__addFilter(/products/);

export default http;
export const getProductDetails = (
  configuration: ApiClientConfiguration
) => async (slug: string, query: string): Promise<any> => {
  const url = urlBuilder(
    endpoints.get(configuration).product.getProductDetails,
    {
      config: configuration,
      mapping: {
        slug,
      },
    }
  );

  const { logger } = configuration;
  logger.debug('Get Product Details', { url });

  const client = getClientInstance({
    instanceWithCache: http,
    preview: configuration.isPreview,
  });

  return client.get(`${url}?${query}`, {
    headers: headerBuilder(configuration),
  });
};
