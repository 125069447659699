import { UpdateItemRequestObject } from '@vf/api-contract';
import { CartLineItem } from '@vf/api-client';
import { UpdateItemObject } from '../useCart/types';

interface ExtendedCartLineItem extends CartLineItem {
  storeId: string;
}

/**
 * Maps provided data to UpdateItemRequestObject
 */
export const mapUpdateItemRequestObject = (
  product: UpdateItemObject | ExtendedCartLineItem,
  cartId: string
): UpdateItemRequestObject => {
  return {
    cartId: cartId,
    productId: product.productId,
    ...(product.recipeId !== '' && {
      recipeId: product.recipeId,
    }),
    itemId: product.itemId,
    qty: product.qty,
    maxQty: product.maxQty,
    pdpUrl: product.pdpUrl,
    productImageURL: product.productImageURL,
    ...(product.storeId !== undefined && {
      storeId: product.storeId,
    }),
    ...(product.gift !== undefined
      ? {
          gift: product.gift,
          giftOption: {
            to: product.giftOption.to,
            from: product.giftOption.from,
            message: product.giftOption.message,
          },
          isGiftBoxSelected: product.isGiftBoxSelected,
        }
      : {}),
  };
};
