import { ROUTES } from '../utils/routes';
import ss from '../utils/sessionStorage';

import { useCardStore } from '../store/card';
import { useCartStore } from '../store/cartStore';
import { useOrderStore } from '../store/order';
import { usePaymentStore } from '../store/payment';
import { useUserStore } from '../store/user';

import { usePaymentProvider } from '../usePaymentProvider';
import { useI18n } from '../useI18n';
import { ApplePayContext } from '../useApplePay/types';
import { useCart, useApplePayPdpCart } from '../useCart';

export const useSuccessfulOrder = (
  instance,
  contextKey?,
  isTemporary = false
) => {
  const cartStore = useCartStore();
  const orderStore = useOrderStore();
  const cardStore = useCardStore();
  const userStore = useUserStore(instance);
  const paymentStore = usePaymentStore(instance);

  const { localePath } = useI18n(instance);
  const paymentProvider = usePaymentProvider(instance);

  const { resetCart, createNewCart } =
    contextKey === ApplePayContext.PDP
      ? useApplePayPdpCart(instance)
      : useCart(instance);

  instance.$router.push(localePath(ROUTES.CHECKOUT_ORDER_STATUS()), () => {
    ss.removeItem('AVSoldAddress');
    ss.removeItem('AVSdone');

    paymentProvider.reset();
    cardStore.reset();
    orderStore.resetOrderStore();
    cartStore.resetCartStore();
    paymentStore.reset();
    // Clear cart after order
    resetCart({ suppressLocalStorageOverride: isTemporary });

    if (userStore.loggedIn && !isTemporary) {
      createNewCart({
        isBackgroundRequest: false,
        isTemporary: false,
      });
    }
  });

  return { success: true };
};
