import {
  CMPicture,
  CMPictureCropType,
  CmsCropSize,
  CmsSite,
  ImageSizeName,
  ResponsiveMedia,
} from '@vf/api-contract';
import { extractProperty } from './extractProperty';

export const getImageObject = (
  pictureObject: CMPicture,
  siteConfiguration: CmsSite,
  cropName: CMPictureCropType | null,
  cmsBaseUri: string,
  responsiveMedia?: ResponsiveMedia | null,
  index?: number
) => {
  return {
    // TODO: this method returns direct URL to the resource. For relative URL just remove ${cmsBaseUri} part
    small: getImageUrl(
      pictureObject,
      siteConfiguration,
      cropName,
      'small',
      cmsBaseUri,
      responsiveMedia,
      index
    ),
    medium: getImageUrl(
      pictureObject,
      siteConfiguration,
      cropName,
      'medium',
      cmsBaseUri,
      responsiveMedia,
      index
    ),
    large: getImageUrl(
      pictureObject,
      siteConfiguration,
      cropName,
      'large',
      cmsBaseUri,
      responsiveMedia,
      index
    ),
    alt: extractProperty(pictureObject, 'alt', null),
    title: extractProperty(pictureObject, 'title', null),
    detailRichText: extractProperty(pictureObject, 'detailRichText', null),
    width: extractProperty(pictureObject, 'width', null),
    height: extractProperty(pictureObject, 'height', null),
  };
};

const getImageUrl = (
  mainPictureData: CMPicture,
  siteConfiguration: CmsSite,
  fallbackCropName: CMPictureCropType | null,
  sizeName: ImageSizeName,
  cmsBaseUri: string,
  responsiveMedia?: ResponsiveMedia | null,
  index?: number
) => {
  if (!mainPictureData && !responsiveMedia) {
    return '';
  }

  /** Extract data for responsive media as most important */
  const imageData = getResponsiveMediaPictureObject(
    mainPictureData,
    sizeName,
    responsiveMedia,
    index
  );

  if (!imageData) {
    return '';
  }

  if (imageData.useOriginalImage) {
    return `${cmsBaseUri}${imageData.data?.uri}` || '';
  }

  // dataUrl is external url and does not need cmsBaseUri
  if (imageData.dataUrl) {
    return imageData.dataUrl;
  }

  if (imageData.forcedAbsoluteUrl) {
    return imageData.data.uri;
  }

  const cropNameForSize = extractProperty(
    responsiveMedia,
    `imageSizing.crop.${sizeName}`,
    fallbackCropName
  );

  // TODO: Replace once Image component refactored
  const cropData = cropNameForSize
    ? siteConfiguration.crops.find((crop) => crop.name === cropNameForSize)
    : null;

  const sizes = extractProperty(cropData, 'sizes', []);

  if (cropData) {
    return `${cmsBaseUri}${imageData.uriTemplate
      .replace('{cropName}', cropData.name)
      .replace('{width}', getImageWidth(sizes, sizeName).toString())}`;
  } else {
    return `${cmsBaseUri}${imageData.data?.uri}` || '';
  }
};

export const getResponsiveMediaPictureObject = (
  mainPicture: CMPicture,
  size: ImageSizeName,
  responsiveMedia?: ResponsiveMedia | null,
  index?: number
) => {
  const indexOf = index || 0;
  const responsiveImageObject = extractProperty(
    responsiveMedia,
    `${size}[${indexOf}]`,
    null
  );
  if (responsiveImageObject && responsiveImageObject.type === 'CMPicture') {
    return responsiveImageObject;
  }

  return mainPicture;
};

const getImageWidth = (sizes: CmsCropSize[], sizeName: ImageSizeName) => {
  const widths = sizes.map((size) => size.width);

  // TODO: to be verified. For now it works like this: lowest width value is returned for small size, highest for large, and for medium - first one from array that is not lowest/highest.
  switch (sizeName) {
    case 'small':
      return Math.min(...widths);

    case 'medium':
      if (widths.length < 3) {
        return Math.max(...widths);
      }
      return widths.filter(
        (width) =>
          width !== Math.min(...widths) && width !== Math.max(...widths)
      )[0];
    case 'large':
      return Math.max(...widths);
  }
};
