import { QuickShopTileTranslations } from '@vf/api-contract';
import { getTranslation } from './extractProperty';

export const getQuickShopTranslations = (
  data: any
): QuickShopTileTranslations => {
  return {
    addToCartButtonText: getTranslation(
      data,
      'addToCartButtonText',
      '$Add to cart$'
    ),
    viewDetailsText: getTranslation(data, 'viewDetailsText', '$View details$'),
    placeholders: {
      sizePlaceholder: getTranslation(data, 'sizePlaceholder', '$Size$'),
      lengthPlaceholder: getTranslation(data, 'lengthPlaceholder', '$Length$'),
      widthPlaceholder: getTranslation(data, 'widthPlaceholder', '$Width$'),
      zipPlaceholder: getTranslation(data, 'zipPlaceholder', '$Zip$'),
    },
    errors: {
      selectSizeError: getTranslation(
        data,
        'selectSizeError',
        '$Please select Size$'
      ),
      selectLengthError: getTranslation(
        data,
        'selectLengthError',
        '$Please select Length$'
      ),
      selectWidthError: getTranslation(
        data,
        'selectWidthError',
        '$Please select width$'
      ),
      selectZipError: getTranslation(
        data,
        'selectZipError',
        '$Please select zip side$'
      ),
    },
    signInToBuyButtonText: getTranslation(
      data,
      'signInToBuy',
      '$Sign in to buy$'
    ),
    outOfStockText: getTranslation(data, 'outOfStockText', '$Out of stock$'),
    ariaLabelChooseColor: getTranslation(
      data,
      'ariaLabelChooseColor',
      '$Choose color$'
    ),
  };
};
