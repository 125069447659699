import { axios, urlBuilder, headerBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ProductAdjustment } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const setPriceAdjustment = (
  configuration: ApiClientConfiguration
) => async (id: string, body: ProductAdjustment): Promise<any> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).cart.priceAdjustments, {
      config: configuration,
      mapping: {
        id,
      },
    }),
    body,
    {
      headers: headerBuilder(configuration),
    }
  );
};
