import { CmsSite, CMTeaser } from '@vf/api-contract';
import { getTeaserStyle } from './utils/getTeaserStyle';
import { ComposableContext } from '../types';
import { extractProperty } from './utils';
import { decorateHtmlValue } from './utils/decorateHtmlValue';

export const checkoutTermsConditions = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'checkout/CheckoutTermsConditions',
    props: {
      target: extractProperty(
        data,
        'teaserTargets[0].target.segment',
        'payment'
      ),
      content: data.useRichText
        ? decorateHtmlValue(
            data.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : data.teaserText,
      html: data.useRichText,
      translations: {
        buttonText: extractProperty(
          data,
          'teaserTargets[0].callToActionText',
          '$Continue To Billing$'
        ),
      },
      textColor: getTeaserStyle(data, 'teaserTextColor'),
      textCustomClasses: getTeaserStyle(data, 'teaserTextClassModifiers'),
      backgroundColor: getTeaserStyle(data, 'teaserTextBackgroundColor'),
    },
  };
};
