import { CMCollection, CmsSite } from '@vf/api-contract';
import { extractLocalSettingsExpandedLinks } from './utils';
import { ComposableContext, CmsSanitizationRequirements } from '../types';
import * as mappings from '../mappings';
import { camelCase } from '../dataTransformators/utils/camelCase';

export const plpRestrict = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const components = extractLocalSettingsExpandedLinks(data, 'component').map(
    (item) => {
      if (!item.viewtype) {
        console.warn(
          `There is no mapping view-type for this component id: ${item.id} | name: ${item.name}`
        );
      }

      const viewType = camelCase(item.viewtype);
      const mappingMethod = mappings[viewType];

      return mappingMethod
        ? mappingMethod(
            item,
            context,
            siteConfiguration,
            cmsBaseUri,
            requirements
          )
        : undefined;
    }
  );

  return {
    component: 'plp/PlpRestrict',
    props: {
      components,
      categoriesId: extractLocalSettingsExpandedLinks(data, 'categories').map(
        (category) => category.commerceRef.id
      ),
      contextKey: context.contextKey,
    },
  };
};
