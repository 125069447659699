import { ApiClientConfiguration } from '@vf/api-client';
import { PaginationObject } from '@vf/api-contract';
import { headerBuilder } from './headerBuilder';
import axios from './axios';

export const getHistory = (
  url: string,
  data: PaginationObject,
  configuration: ApiClientConfiguration,
  usid: string
) => {
  const requestData: PaginationObject = {
    pageSize: data.pageSize,
    pageNumber: data.pageNumber,
    paginationStrategy: 'NEXTPAGE',
    ...(data.numberOfAdditionalOrders && {
      numberOfAdditionalOrders: data.numberOfAdditionalOrders,
    }),
  };

  const isSubsequentPage = Boolean(
    data.previousPageNumber && data.lastOrderHeaderKey
  );

  if (isSubsequentPage) {
    requestData.previousPageNumber = data.previousPageNumber;
    requestData.lastOrderHeaderKey = data.lastOrderHeaderKey;
  }

  return axios.post(url, requestData, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
