interface ScrollToOptionsExt extends ScrollToOptions {
  target?: typeof window | Element;
}

export function scrollTo(options?: ScrollToOptionsExt): void {
  const { target = window, top = 0, left = 0, behavior = 'smooth' } =
    options || {};

  if (typeof target === 'undefined') return;
  target.scroll({
    top,
    left,
    behavior,
  });
}
