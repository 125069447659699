import { CMPlaceholder } from '@vf/api-contract';
import { getDynamicField } from './utils';

export const iframe = (data: CMPlaceholder) => {
  const iframeType = getDynamicField(data, 'iframeType');
  const customsIframe = iframeType === 'customs';

  const frameProps: Record<string, string | boolean> = {
    src: getDynamicField(data, 'src'),
    width: getDynamicField(data, 'width'),
    height: getDynamicField(data, 'height'),
    autoHeight: getDynamicField(data, 'autoHeight'),
    iframeType,
    allow: '',
    allowFullScreen: true,
  };

  return {
    component: customsIframe ? 'customs/CustomsPreloader' : 'shared/Frame',
    attrs: {
      ...(customsIframe ? frameProps : {}),
    },
    props: customsIframe
      ? {
          preloadedComponent: 'Frame',
        }
      : frameProps,
  };
};
