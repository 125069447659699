import {
  CMPlaceholder,
  CMTeaser,
  CmsSite,
  LoyaltyFinalizationType,
} from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  extractLocalSettingsExpandedLinks,
  extractCommonConfigLink,
  getDynamicField,
  getImageObject,
  getMediaSeoAttributes,
  generateLinkFromTarget,
  generateLinkFromTeaserTargets,
  getTranslation,
} from './utils';

import { loyaltyLogin } from './loyaltyLogin';
import { loyaltyRegister } from './loyaltyRegister';
import { loyaltyQuestions } from './loyaltyQuestions';
import { loyaltySuccessStep } from './loyaltySuccessStep';

type SuccessButton = {
  link: string;
  text: string;
};

export const getSuccessButtons = (
  prop,
  data,
  context,
  siteConfiguration,
  cmsBaseUri
): SuccessButton[] => {
  return (
    extractLocalSettingsExpandedLinks(data, prop)?.[0]?.teaserTargets?.map(
      (button) => {
        return {
          link: generateLinkFromTeaserTargets(
            extractProperty(button, `target.teaserTargets`, ''),
            context,
            siteConfiguration,
            data.urlParams,
            cmsBaseUri
          ),
          text:
            extractProperty(button, `target.callToActionText`, '') ||
            extractProperty(button, `target.teaserTitle`, '') ||
            extractProperty(button, `teaserTitle`, ''),
        };
      }
    ) || []
  );
};

export const loyaltyRegisterLogin = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const campaignHeading = getDynamicField(data, 'campaignGreeting', null);
  const campaignSubheading = getDynamicField(data, 'campaignSubtext', null);
  const isSurveyStepEnabled = getDynamicField(data, 'enableSurveyStep', true);
  const showEnrollmentAfterLoginToLegacyUsers = getDynamicField(
    data,
    'showEnrollmentAfterLoginToNonEnrolledUsers',
    false
  );
  const enableLoginForm = getDynamicField(data, 'enableLoginForm', true);
  const registerFinalizationType: LoyaltyFinalizationType = getDynamicField(
    data,
    'finalizationType',
    'displayConfirmationStep'
  );
  const loginFinalizationType: LoyaltyFinalizationType = getDynamicField(
    data,
    'loginFinalizationType',
    'closeModal'
  );
  const signInButtonText = getTranslation(
    data,
    'signInButtonText',
    '$SIGN IN$'
  );

  const getComponentBindings = (componentData: CMTeaser | CMPlaceholder) => {
    if (!componentData) {
      return;
    }

    const viewTypeMapping = {
      'loyalty-login': loyaltyLogin,
      'loyalty-register': loyaltyRegister,
      'loyalty-questions': loyaltyQuestions,
      'loyalty-success-step': loyaltySuccessStep,
    };

    const componentMapper = viewTypeMapping[componentData.viewtype];

    if (componentMapper) {
      const component = componentMapper(
        componentData,
        context,
        siteConfiguration,
        cmsBaseUri
      );
      return {
        ...component.attrs,
        ...component.props,
      };
    }
  };

  const getLoginStep = () => {
    const component = getComponentBindings(
      extractCommonConfigLink(siteConfiguration, 'loyalty-forms.login')[0]
    );

    if (!component) {
      return;
    }

    const showHeading = getDynamicField(data, 'loginStepGreetingEnabled', true);
    const showSubheading = getDynamicField(
      data,
      'loginStepSubtextEnabled',
      true
    );
    const headingOverride = getDynamicField(data, 'loginStepGreeting', '');
    const subheadingOverride = getDynamicField(data, 'loginStepSubtext', '');
    const heading =
      headingOverride || campaignHeading || component.translations.heading;
    const subheading =
      subheadingOverride ||
      campaignSubheading ||
      component.translations.subheading;

    return {
      ...component,
      showHeading,
      showSubheading,
      translations: {
        ...component.translations,
        heading,
        subheading,
      },
    };
  };

  const getRegisterStep = () => {
    const component = getComponentBindings(
      extractCommonConfigLink(siteConfiguration, 'loyalty-forms.register')[0]
    );

    if (!component) {
      return;
    }

    const showHeading = getDynamicField(
      data,
      'registerStepGreetingEnabled',
      true
    );
    const showSubheading = getDynamicField(
      data,
      'registerStepSubtextEnabled',
      true
    );
    const headingOverride = getDynamicField(data, 'registerStepGreeting', '');
    const subheadingOverride = getDynamicField(data, 'registerStepSubtext', '');
    const headingLegacy = getDynamicField(
      data,
      'registerStepGreetingForNotEnrolled',
      ''
    );
    const subheadingLegacy = getDynamicField(
      data,
      'registerStepSubtextForNotEnrolled',
      ''
    );
    const heading =
      headingOverride || campaignHeading || component.translations.heading;
    const subheading =
      subheadingOverride ||
      campaignSubheading ||
      component.translations.subheading;

    return {
      ...component,
      showHeading,
      showSubheading,
      translations: {
        ...component.translations,
        heading,
        subheading,
        headingLegacy,
        subheadingLegacy,
        signInButtonText,
      },
    };
  };

  const getSurveyStep = () => {
    const component = getComponentBindings(
      extractCommonConfigLink(siteConfiguration, 'loyalty-forms.questions')[0]
    );

    if (!component) {
      return;
    }

    const showHeading = getDynamicField(
      data,
      'surveyStepGreetingEnabled',
      true
    );
    const showSubheading = getDynamicField(
      data,
      'surveyStepSubtextEnabled',
      true
    );
    const headingOverride = getDynamicField(data, 'surveyStepGreeting', '');
    const subheadingOverride = getDynamicField(data, 'surveyStepSubtext', '');
    const heading = headingOverride || component.translations.heading;
    const subheading = subheadingOverride || component.translations.subheading;

    return {
      ...component,
      showHeading,
      showSubheading,
      translations: {
        ...component.translations,
        heading,
        subheading,
      },
    };
  };

  const getConfirmationStep = () => {
    const component = getComponentBindings(
      extractCommonConfigLink(siteConfiguration, 'loyalty-forms.success')[0]
    );

    if (!component) {
      return false;
    }

    const showHeading = getDynamicField(
      data,
      'successStepGreetingEnabled',
      true
    );
    const showSubheading = getDynamicField(
      data,
      'successStepSubtextEnabled',
      true
    );
    const headingOverride = getDynamicField(data, 'successStepGreeting', '');
    const headingLegacy = getDynamicField(
      data,
      'successStepGreetingForNotEnrolled',
      ''
    );
    const headingSignIn = getDynamicField(
      data,
      'successStepGreetingForSignIn',
      ''
    );
    const subheadingOverride = getDynamicField(data, 'successStepSubtext', '');
    const subheadingLegacy = getDynamicField(
      data,
      'successStepSubtextForNotEnrolled',
      ''
    );
    const subheadingSignIn = getDynamicField(
      data,
      'successStepSubtextForSignIn',
      ''
    );
    const heading = headingOverride || component.translations.heading;
    const subheading = subheadingOverride || component.translations.subheading;

    const [button1, button2] = getSuccessButtons(
      'confirmationStep',
      data,
      context,
      siteConfiguration,
      cmsBaseUri
    );

    if (button1 || button2) {
      component.translations.buttonText = button1?.text;
      component.translations.secondButtonText = button2?.text;
      component.buttonLink = button1?.link;
      component.secondButtonLink = button2?.link;
    }

    const [buttonLegacy, secondButtonLegacy] = getSuccessButtons(
      'successStepButtonsForNotEnrolled',
      data,
      context,
      siteConfiguration,
      cmsBaseUri
    );

    const [buttonSignIn, secondButtonSignIn] = getSuccessButtons(
      'successStepButtonsForSignIn',
      data,
      context,
      siteConfiguration,
      cmsBaseUri
    );

    const image = extractLocalSettingsExpandedLinks(
      data,
      'successStepImage'
    )[0];

    // override original image object
    if (image) {
      component.imageObject = {
        src: getImageObject(image, siteConfiguration, null, cmsBaseUri),
        seo: getMediaSeoAttributes(image),
      };
    }

    const imageLegacy = extractLocalSettingsExpandedLinks(
      data,
      'successStepImageForNotEnrolled'
    )[0];

    const imageSignIn = extractLocalSettingsExpandedLinks(
      data,
      'successStepImageForSignIn'
    )[0];

    return {
      ...component,
      showHeading,
      showSubheading,
      buttonLegacy,
      secondButtonLegacy,
      buttonSignIn,
      secondButtonSignIn,
      imageLegacy: imageLegacy
        ? {
            src: getImageObject(
              imageLegacy,
              siteConfiguration,
              null,
              cmsBaseUri
            ),
            seo: getMediaSeoAttributes(image),
          }
        : null,
      imageSignIn: imageSignIn
        ? {
            src: getImageObject(
              imageSignIn,
              siteConfiguration,
              null,
              cmsBaseUri
            ),
            seo: getMediaSeoAttributes(image),
          }
        : null,
      translations: {
        ...component.translations,
        heading,
        headingLegacy,
        headingSignIn,
        subheading,
        subheadingLegacy,
        subheadingSignIn,
      },
    };
  };

  return {
    component: 'loyalty/LoyaltyRegisterLogin',
    props: {
      displayAsModal: getDynamicField(data, 'displayAsModal', true),
      enrollNewUsersToLoyalty: getDynamicField(
        data,
        'enrolNewUsersToLoyalty',
        true
      ),
      loginStep: getLoginStep(),
      registerStep: getRegisterStep(),
      surveyStep: isSurveyStepEnabled ? getSurveyStep() : false,
      confirmationStep: getConfirmationStep(),
      pageToRedirectToAfterRegister: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'pageToRedirectTo')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      pageToRedirectToAfterLogin: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(
          data,
          'pageToRedirectToAfterLogin'
        )[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      registerFinalizationType,
      loginFinalizationType,
      showEnrollmentAfterLoginToLegacyUsers,
      enableLoginForm,
      showTabsAboveForm: getDynamicField(data, 'showTabsAboveForm', false),
      loginStepShowRegistrationLink: getDynamicField(
        data,
        'loginStepShowRegistrationLink',
        true
      ),
      signInTabText: getTranslation(
        data,
        'signInTabText',
        '$Sign In To Vans Family$'
      ),
      signInButtonText,
      signUpTabText: getTranslation(data, 'signUpTabText', '$Sign Up Online$'),
      joinTheFamilyButtonText: getTranslation(
        data,
        'joinTheFamilyButtonText',
        '$JOIN THE FAMILY$'
      ),
    },
  };
};
