export async function withRetryAfterTime<T = unknown>(
  fn: () => Promise<T>,
  time: number,
  errorCode?: number
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    // if no specific error code given or error code matches given one, retry after given time
    if (!errorCode || (errorCode && error.response?.status === errorCode)) {
      await new Promise((resolve) => setTimeout(resolve, time));
      return await fn();
    }

    throw error;
  }
}
