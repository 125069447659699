import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComponentInstance } from '../../types';

import {
  extractProperty,
  getImageObject,
  getMediaSeoAttributes,
} from './utils';

export const cartTopPromo = (
  data: CMTeaser,
  context: ComponentInstance,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const headingText = extractProperty(data, 'teaserTitle');

  const getImage = () => {
    const pictures = extractProperty(data, 'pictures[0]');

    if (!pictures) {
      return null;
    }

    const seo = pictures && getMediaSeoAttributes(pictures);
    const images =
      pictures &&
      getImageObject(
        pictures,
        siteConfiguration,
        null,
        cmsBaseUri,
        data.responsiveMedia
      );

    return {
      images,
      seo,
    };
  };

  return {
    component: 'cart/CartTopPromo',
    props: {
      headingText,
      imageObject: getImage(),
    },
  };
};
