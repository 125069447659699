import { CartLineItem } from '@vf/api-client/src/types';
import { EventPropsHandler } from '.';
import { GtmCartProductObject, RemoveFromCartEvent } from '../../types/gtm';
import { cartLineItemToProductObject } from './helpers';
import useGtm from '../../useGtm';
import { useI18n } from '../../useI18n';
import { useCart } from '../../useCart';

export const removeFromCartHandler: EventPropsHandler<
  RemoveFromCartEvent,
  { product: CartLineItem; quantity: number }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);
  const { clearGtmAttributesStorage, getCartProductTrace } = useGtm(vmInstance);
  const { cartItems } = useCart(vmInstance);

  const prod = eventProps.overrideAttributes.product;
  const prodObject: GtmCartProductObject = {
    ...cartLineItemToProductObject(prod, getCartProductTrace(prod.productId)),
    brand: vmInstance
      .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
      .toUpperCase(),
    quantity: eventProps.overrideAttributes.quantity ?? 1,
  };

  if (!cartItems.value.some((item) => item.masterId === prod.masterId)) {
    // clear from localstorage if not exist other prod with same masterId
    [prod.masterId, prod.productId].forEach((pid) =>
      clearGtmAttributesStorage(pid)
    );
  }
  return {
    event: 'removeFromCart',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Remove from Cart',
    eventLabel: prod.masterId,
    nonInteractive: false,
    ecommerce: {
      /** MISSING_CURRENCY: Currency is specified for all products,
       * we may take it from first products from the list
       * or take it from CMS configuration */
      currencyCode: defaultCurrency.value,
      remove: {
        products: [prodObject],
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
