var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loyaltyEnrolled && _vm.isPointsToCurrencyEnabled)?_c('LoyaltyRewardsProgress',{attrs:{"translations":_vm.loyaltyRewardsProgressTranslations,"accordion":""}}):_vm._e(),_vm._v(" "),_vm._l((_vm.navigation),function(l1,l1Key){return _c('VfAccordion',{key:l1Key,staticClass:"vf-accordion--l1",class:[
      l1.cssClass,
      {
        'vf-accordion--redesign-core': _vm.isCoreRedesignEnabled,
      } ],attrs:{"id":l1['data-id'],"header":l1.mobileTitle || l1.title,"icon-type":l1.columns.length ? _vm.accordionIcon.type : 'none',"icon-size":_vm.accordionIcon.size,"styles":l1.componentStyles,"show-top-divider":_vm.showTopDivider,"is-open":_vm.openL1Items.includes(l1),"identifier":l1['data-id'],"managed-in-parent":_vm.onlyAllowOneOpenL1Item},on:{"toggle":_vm.handleAccordionToggle,"click":function($event){return _vm.handleMobileNavLinkClickAndRedirect($event, {
        link: l1.link,
        l1: l1.mobileTitle || l1.title,
        l2: '',
        l3: '',
        isModal: l1.openInNewModal,
        openInNewTab: l1.openInNewTab,
      })}}},[(l1.columns.length)?_c('VfMobileMenu',[(l1.navItemSettings['show-shop-all-button-mobile'])?_c('VfList',[(l1.link && (l1.mobileTitle || l1.title) && _vm.hideShopAllText)?_c('VfListItem',[_c('ThemeLink',{class:l1.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l1.dataNavigation,"to":l1.link,"target":l1.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                link: l1.link,
                l1: l1.mobileTitle || l1.title,
                l2: '',
                l3: '',
                isModal: l1.openInNewModal,
              })}}},[_c('VfMenuItem',{attrs:{"label":_vm.getShopAllLabel(l1.mobileTitle, l1.title, l1.navItemSettings),"icon":""}})],1)],1):(_vm.showShopAll(l1, 'show-shop-all-button-mobile'))?_c('VfListItem',[_c('ThemeLink',{staticClass:"vf-mega-menu__shop-all-l1",class:l1.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l1.dataNavigation,"to":l1.link,"target":l1.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                link: l1.link,
                l1: l1.mobileTitle || l1.title,
                l2: '',
                l3: '',
                isModal: l1.openInNewModal,
              })}}},[_c('VfMenuItem',{attrs:{"label":_vm.displayLabel(l1, 'shop-all-button-mobile'),"icon":_vm.shopAllIcon}})],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),_vm._l((l1.columns),function(column,l2Key){return _c('div',{key:l1Key + '_' + l2Key,class:{
          'vf-mega-menu--single-list': !column.children,
        }},_vm._l((column.level2MenuItems),function(l2,l2ItemsKey){return _c('div',{key:l1Key + '_' + l2ItemsKey},[(!l2.children || !l2.children.length)?_c('VfList',[_c('VfListItem',[_c('ThemeLink',{staticClass:"vf-header-mobile-menu__indented-link",class:l2.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l2.dataNavigation,"to":l2.link,"target":l2.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                    link: l2.link,
                    l1: l1.mobileTitle || l1.title,
                    l2: l2.mobileTitle || l2.title,
                    l3: '',
                    isModal: l2.openInNewModal,
                  })}}},[_c('VfMenuItem',{attrs:{"label":l2.mobileTitle || l2.title,"label-styles":Object.assign({}, l2.componentStyles)},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("​")]},proxy:true}],null,true)})],1)],1)],1):(
              Array.isArray(l2.children) &&
              l2.children.length &&
              l2.expandMobile
            )?[(_vm.showShopAll(l1, 'show-shop-all-button-mobile') && !l2Key)?_c('VfDivider',{staticClass:"vf-mega-menu__utility-divider"}):_vm._e(),_vm._v(" "),_c('VfList',{staticClass:"vf-list--expanded"},_vm._l((l2.children),function(expandedItem,expandedKey){return _c('VfListItem',{key:l2ItemsKey + '_' + expandedKey},[_c('ThemeLink',{class:expandedItem.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":expandedItem.dataNavigation,"to":expandedItem.link,"target":expandedItem.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                      link: expandedItem.link,
                      l1: l1.mobileTitle || l1.title,
                      l2: expandedItem.title,
                      l3: '',
                      isModal: expandedItem.openInNewModal,
                    })}}},[_c('VfMenuItem',{attrs:{"label":expandedItem.mobileTitle || expandedItem.title,"icon":""}})],1)],1)}),1)]:(!(l2.children.length === 1 && l2.children[0].isImage))?_c('VfAccordion',{staticClass:"vf-accordion--l2",attrs:{"header":l2.mobileTitle || l2.title,"icon-type":_vm.alwaysShowIcon ? _vm.l2Icon : _vm.getIcon(l2),"icon-size":_vm.accordionIcon.size,"show-top-divider":_vm.showTopDivider,"indent-header":true,"styles":l2.componentStyles,"is-open":l2.accordionOpenByDefault['accordion-open-by-default-mobile']},on:{"click":function($event){return _vm.handleMobileNavLinkClickAndRedirect($event, {
                link: l2.link,
                l1: l1.mobileTitle || l1.title,
                l2: l2.mobileTitle || l2.title,
                l3: '',
                isModal: l2.openInNewModal,
              })},"toggle":function($event){return _vm.handleAccordionToggle($event, l1.mobileTitle || l1.title)}}},[_c('VfList',[(
                  _vm.shouldUseAutoGeneratedL2LinksOnMobile(l2) &&
                  l2.link &&
                  _vm.shouldUseAutoGeneratedL2Click(l2)
                )?_c('VfListItem',[_c('ThemeLink',{staticClass:"vf-header-mobile-menu__indented-header",class:l2.cssClass,attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l2.dataNavigation,"to":l2.link,"target":l2.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                      link: l2.link,
                      l1: l1.mobileTitle || l1.title,
                      l2: l2.mobileTitle || l2.title,
                      l3: '',
                      isModal: l2.openInNewModal,
                    })}}},[_c('VfMenuItem',{attrs:{"label":_vm.formatMobileMenuLink(l2),"icon":''}})],1)],1):_vm._e(),_vm._v(" "),_vm._l((l2.children),function(l3,l3Key){return _c('VfListItem',{key:l1Key + '_' + l2ItemsKey + '_' + l3Key},[_c('ThemeLink',{staticClass:"vf-header-mobile-menu__indented-header",class:l3.cssClass,style:(Object.assign({}, l3.componentStyles)),attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"data-navigation":l3.dataNavigation,"to":l3.link,"target":l3.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                      link: l3.link,
                      l1: l1.mobileTitle || l1.title,
                      l2: l2.mobileTitle || l2.title,
                      l3: l3.mobileTitle || l3.title,
                      isModal: l3.openInNewModal,
                    })}}},[_c('VfMenuItem',{attrs:{"label":l3.mobileTitle || l3.title,"label-styles":Object.assign({}, l3.componentStyles)},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("​")]},proxy:true}],null,true)})],1)],1)})],2)],1):_vm._e()],2)}),0)})],2):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }