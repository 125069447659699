
import { isEmptyClass } from '../utilities/helpers';

export default {
  name: 'UiLink',
  functional: true,
  props: {
    to: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
  },
  render(h, { props, data, listeners, children }) {
    if (!props.to && props.tag === 'a') {
      console.warn(
        'Usage of <a> tag is not allowed without "to" prop and will be ignored!'
      );
    }
    const isTag = props.tag && props.tag !== 'a';
    const attrs = data.attrs || {};
    const tag =
      props.to && !attrs.disabled ? 'a' : isTag ? props.tag : 'button';
    const isLink = tag === 'a';
    const to = isLink ? props.to : null;
    const isExternal = to?.startsWith?.('http');
    const isProto = to?.match?.(/^mailto:|^tel:|^sms:|^geo:/i);
    const isSelf = attrs.target === '_self';

    const settings = {
      ...data,
      attrs,
      class: isEmptyClass(data.class) ? null : data.class,
      on: {
        ...listeners,
        ...data.nativeOn,
      },
      domProps: {},
    };

    const component = h(tag, settings, children);

    if (!isLink) {
      // Removing all properties that should only be on links
      delete attrs.href;
      delete attrs.rel;
      delete attrs.target;
      if (tag === 'button') {
        attrs.type ??= 'button';
      } else {
        delete attrs.type;
      }
      // Returning root component if it's not an internal link
      return component;
    }

    delete attrs.type;
    attrs.rel ??= isExternal && !isSelf ? 'noopener' : attrs.rel || null;

    // Add target blank to external links if it wasn't specified deliberately
    if (isExternal) attrs.target ??= '_blank';

    // _self is default value so we can delete it
    if (isSelf) delete attrs.target;

    // Returning regular <a> for non-internal links
    if (isExternal || isProto) {
      attrs.href = to;
      return component;
    }

    const existingClick = settings.on.click;

    return h('router-link', {
      props: {
        to,
        custom: true,
      },
      scopedSlots: {
        default: ({ isActive, isExactActive, navigate, href }) => {
          if (isActive || isExactActive) {
            settings.class ??= [];
            const newClass = {
              active: isActive,
              exact: isExactActive,
            };
            if (Array.isArray(settings.class)) {
              settings.class.push(newClass);
            } else if (
              typeof settings.class === 'object' &&
              settings.class !== null
            ) {
              settings.class = [settings.class, newClass];
            } else {
              settings.class = [newClass];
            }
          }

          settings.attrs.href = href;
          settings.on.click = (e) => {
            existingClick?.(e);
            navigate(e);
            e.preventDefault();
          };
          return component;
        },
      },
    });
  },
};
