// Icon sizes
export const sizes = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xl3: 'xl3',
  xl4: 'xl4',
};
// Typography sizes
export const typographySizes = {
  'extra-small': 'extra-small',
  small: 'small',
  regular: 'regular',
  big: 'big',
};
export const sizesValues = Object.values(sizes);
export default sizes;
