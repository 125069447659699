import axios from '../../helpers/axios';
import { endpoints } from '../../config';
import { headerBuilder, urlBuilder } from '../../helpers';
import { ApiClientConfiguration } from '../../configuration';

export const getStoreEmployees = (configuration: ApiClientConfiguration) => (
  storeId: string
): Promise<any> => {
  let url = endpoints.get(configuration).customer.getBuyInStoreEmployees;
  url = url.replace('{storeId}', storeId);
  return axios.get(urlBuilder(url, { config: configuration }), {
    headers: headerBuilder(configuration),
    data: {},
  });
};
