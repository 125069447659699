import { parseISO, isValid } from 'date-fns';

export const getDeliveryDate = (value: string = ''): Date => {
  if (!value) return null;
  const [minDateString, maxDateString] = value.split(',');
  try {
    let date = parseISO(maxDateString);
    if (isValid(date)) return date;
    date = parseISO(minDateString);
    return isValid(date) ? date : null;
  } catch (e) {
    return null;
  }
};
