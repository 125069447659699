import {
  CMCollection,
  CmsSite,
  PlpVisualCarouselFilter,
} from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  getDynamicField,
  decorateHtmlValue,
  extractProperty,
  getTitle,
  getImageObject,
  getTranslation,
} from './utils';

export const visualFilterCarousel = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const headlineAriaTemplate = getTranslation(
    data,
    'headlineAriaTemplate',
    '$Filter product list to {{headline}} products$'
  );
  const filters: PlpVisualCarouselFilter[] = data.teasableItems.map((item) => {
    const headline = getTitle(item);
    return {
      headline,
      headlineAria: headlineAriaTemplate.replace('{{headline}}', headline),
      code: getDynamicField(item, 'filterCode'),
      value: getDynamicField(item, 'filterValue'),
      backgroundStyle: getDynamicField(item, 'backgroundStyle'),
      imageUrl: getImageObject(
        extractProperty(item, 'pictures[0]', null),
        siteConfiguration,
        null,
        cmsBaseUri
      ).small,
      subcopy: decorateHtmlValue(
        item.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      ),
    };
  });

  return {
    component: 'plp/PlpVisualFilter',
    props: {
      filters,
      enablePagination: getDynamicField(data, 'controlsType') === 'dots',
    },
  };
};
