import {
  extractProperty,
  getDynamicField,
  getTranslation,
  extractCommonConfigLink,
} from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getProductCtaButtonTranslations } from './utils/getProductCtaButtonTranslations';

export const quickshopProductCtas = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const signInToBuy = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

  const loyaltyEnrollment = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'loyalty-enrollment-modal'),
    '[0]'
  )?.id;

  return {
    component: 'quickshop/QuickshopProductCtas',
    props: {
      translations: {
        ...getProductCtaButtonTranslations(data),
        buttonCTASuccess: getTranslation(data, 'buttonCTASuccess', '$Added$'),
        addedToFavoritesNotification: getDynamicField(
          data,
          'added-to-favorites-notification',
          '$You have successfully added {{product}} to your Favorites.$'
        ),
        showMoreText: getTranslation(data, 'showMoreText', '$Show More$'),
        moreDetailsCTA: getTranslation(
          data,
          'moreDetailsCTA',
          '$More Details$'
        ),
      },
      modals: {
        signInToBuy,
        loyaltyEnrollment,
      },
    },
  };
};
