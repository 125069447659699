import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { getTranslation } from './utils';
import { getLocales } from '../utils/getLocales';
import { ComposableContext } from '../types';

export const languagePreferencesForm = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
): any => {
  return {
    component: 'account/LanguagePreference',
    props: {
      locales: getLocales(siteConfiguration),
      translations: {
        header: getTranslation(
          data,
          'headerLanguagePreference',
          '$Language Preferences$'
        ),
        saveButtonText: getTranslation(data, 'saveCTA', '$Save Preferences$'),
        languagePreferencesSaved: getTranslation(
          data,
          'languagePreferencesSaved',
          '$You have saved your language preferences.$'
        ),
        setBasicInformationError: getTranslation(
          data,
          'setBasicInformationError',
          '$CANNOT UPDATE PROFILE INFORMATION.$'
        ),
        label: getTranslation(data, 'label', '$Language Preferences$'),
      },
    },
  };
};
