import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const orderBrandAddress = (data: CMPlaceholder) => ({
  component: 'confirmation/OrderBrandAddress',
  props: {
    translations: {
      address: getTranslation(
        data,
        'address',
        '$Our address. 105 Corporate Center Blvd. Greensboro, North Carolina 27408.$'
      ),
    },
  },
});
