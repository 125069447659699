import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import {
  AddCustomerCreditCardsRequest,
  AddCustomerCreditCardsResponse,
} from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const addPaymentInstrument = (configuration: ApiClientConfiguration) => (
  data: AddCustomerCreditCardsRequest
): Promise<AxiosResponse<AddCustomerCreditCardsResponse>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).customer.addPaymentInstrument, {
      config: configuration,
    }),
    data,
    {
      headers: headerBuilder(configuration),
    }
  );
};
