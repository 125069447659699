import {
  extractProperty,
  getDynamicField,
  getTranslation,
} from './utils/extractProperty';
import { CMPlaceholder } from '@vf/api-contract';
import { extractCommonConfigLink } from './utils';
import { MappingMethod } from '../types';

export const interestsForm: MappingMethod = (
  data: CMPlaceholder,
  context,
  siteConfiguration
) => {
  const welcomeModalData = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'postEnrolmentModal'),
    '[0]'
  );

  return {
    component: 'account/InterestsForm',
    props: {
      welcomeModal: welcomeModalData?.id,
      afterSignUp: getDynamicField(data, 'show-personalize-modal', false),
      interestItems: getTranslation(data, 'interests', []),
      translations: {
        thanksHeader: getDynamicField(data, 'thanks-header', '$Thank you$'),
        promptText: getDynamicField(
          data,
          'prompting-text',
          '$Tell us about yourself so we can personalize your experience with The North Face.$'
        ),
        skipForNowButton: getDynamicField(
          data,
          'skip-for-now-button',
          '$Skip For Now$'
        ),
        saveButtonText: getDynamicField(
          data,
          'update-button',
          '$UPDATE PROFILE$'
        ),
        male: getTranslation(data, 'male', '$Male$'),
        female: getTranslation(data, 'female', '$Female$'),
        genderTitle: getTranslation(data, 'gender', '$Gender$'),
        heading: getTranslation(
          data,
          'heading',
          '$See more of the gear you want.$'
        ),
        subheading: getTranslation(
          data,
          'subheading',
          '$Tell us about your interests.$'
        ),
        interests: getTranslation(data, 'interests', {
          0: '$Skate$',
          1: '$Surf$',
          2: '$Snow$',
          3: '$BMX$',
          4: '$Music$',
          5: '$Art$',
          6: '$Fashion$',
        }),
        setBasicInformationSuccess: getTranslation(
          data,
          'setBasicInformationSuccess',
          '$Interests have been saved$'
        ),
        setBasicInformationError: getTranslation(
          data,
          'setBasicInformationError',
          '$Interests have not been saved$'
        ),
      },
    },
  };
};
