import { ROUTES } from '../../utils/routes';
import { useI18n } from '../../useI18n';
import { ComposableContext } from '../types';

export const accountSubNavigation = (
  componentData: unknown,
  context: ComposableContext
) => {
  const { localePath } = useI18n(context.instance);

  return {
    component: 'account/AccountSubNavigation',
    attrs: {
      'data-id': 'dataId',
    },
    props: {
      items: [
        { name: 'My Account', url: localePath(ROUTES.ACCOUNT('profile')) },
        {
          name: 'Order History',
          url: localePath(ROUTES.ACCOUNT('order-history')),
        },
        {
          name: 'Address Book',
          url: localePath(ROUTES.ACCOUNT('address-book')),
        },
        {
          name: 'Credit Cards',
          url: localePath(ROUTES.ACCOUNT('credit-cards')),
        },
      ],
    },
  };
};
