import { ComponentInstance } from '../types';
import useNotification from '../useNotification';
import { useDynatrace } from '../useDynatrace';
import { replaceMessagePlaceholders } from '@vf/shared/src/utils/helpers';
import { isAxiosApiError } from './isAxiosApiError';

const PERSISTENT_ERROR_CODES = ['INV409'];

type MsgFormatter = (formatterConfig: {
  messageFromCMS: string;
  errorDetails?: any;
  otherParams?: any;
}) => string;

export const errorMessages = (instance: ComponentInstance) => {
  const { addNotification } = useNotification(instance);
  const { DynatraceActionType, pushActionToDynatrace } = useDynatrace(instance);

  const errorDetailParams = {
    productName: '',
    maxAllowedQty: '',
    variationAttributes: {
      color: '',
      size: '',
    },
  };

  const formatters = {
    PRM175({ messageFromCMS, errorDetails, otherParams }) {
      return messageFromCMS
        .replace(
          '{{thresholdValue}}',
          instance.$formatPrice(
            errorDetails.message?.conditionThreshold,
            otherParams?.currency
          )
        )
        .replace('{{discountAmount}}', errorDetails.message?.discount || '')
        .replace(
          '{{remainingAmount}}',
          instance.$formatPrice(
            errorDetails.message?.distanceFromConditionThreshold,
            otherParams?.currency
          )
        );
    },
    INV409_INV407({ messageFromCMS, otherParams }) {
      if (
        !messageFromCMS ||
        !otherParams?.productRelatedFlashMessages?.length ||
        !otherParams?.cartItems?.length
      ) {
        return messageFromCMS;
      }

      const { cartItems, productRelatedFlashMessages } = otherParams;

      messageFromCMS += '<ul>';

      cartItems.forEach((product, index) => {
        const productHasError = productRelatedFlashMessages.find((error) => {
          return (
            error.path.endsWith('[' + index + ']') ||
            error.path.endsWith('[' + index + '].quantity')
          );
        });
        if (productHasError) {
          messageFromCMS += '<li>' + product.name + '</li>';
        }
      });

      messageFromCMS += '</ul>';

      return messageFromCMS;
    },
    INV408({ messageFromCMS, errorDetails }) {
      return getINV408ErrorMessageById(errorDetails, messageFromCMS);
    },
    AUT437({ messageFromCMS, otherParams }) {
      if (!otherParams || !otherParams.forgotPasswordURL) {
        return messageFromCMS;
      }

      return messageFromCMS.replace(
        '{{forgotPasswordURL}}',
        otherParams.forgotPasswordURL
      );
    },
    AUT441({ messageFromCMS, otherParams }) {
      if (!otherParams || !otherParams.contactUsURL) {
        return messageFromCMS;
      }

      return messageFromCMS.replace(
        '{{contactUsURL}}',
        otherParams.contactUsURL
      );
    },
    default({ messageFromCMS }) {
      return messageFromCMS;
    },
  };

  const getMessageFormatter = (errorMessageId: string): MsgFormatter => {
    switch (errorMessageId) {
      case 'INV409':
      case 'INV407':
        return formatters.INV409_INV407;
      default:
        return formatters[errorMessageId] ?? formatters.default;
    }
  };

  const displayErrorMessages = (error) => {
    if (isAxiosApiError(error)) {
      const errorsObjects = getErrorDetails(error.response.data.errorDetails);
      if (errorsObjects.length > 0) {
        errorsObjects.forEach((errorObject) => {
          addNotification(errorObject);
          pushActionToDynatrace(
            DynatraceActionType.API,
            errorObject.message,
            errorObject.errorMessageId,
            error.response.data.errorDetails?.[0].correlationId
          );
        });
      }
    } else {
      instance.$log.error('Unexpected non-API error', error);
      pushActionToDynatrace(DynatraceActionType.NON_API, error);
    }
  };

  const getErrorDetails = (errorDetails: any[] = [], productId = undefined) => {
    const errorsObjects = [];
    const info = errorDetails.find(
      (error) => typeof error.additionalInfo !== 'undefined'
    );
    errorDetails.forEach((error, index) => {
      if (
        typeof error.errorId !== 'undefined' &&
        // When error comes with errorId === PRM175 error message will be an object
        (typeof error.message === 'string' ||
          typeof error.defaultError === 'string' ||
          error.errorId === 'PRM175')
      ) {
        errorsObjects.push({
          message: error.message,
          type: 'danger',
          persistent: PERSISTENT_ERROR_CODES.includes(error.errorId),
          errorMessageId: error.errorId,
          productId,
          modifiers: error.modifiers || '',
          // Temporary solution until ECOM15-13193 will be finished
          ...((error.errorId === 'SHP500' ||
            error.errorId === 'INV408' ||
            error.errorId === 'SHP410') && {
            additionalInfo: info.additionalInfo[index],
          }),
        });
      }
    });
    return errorsObjects;
  };

  const getINV408ErrorMessageById = (errorDetail, messageFromCms) => {
    return replaceMessagePlaceholders(
      messageFromCms,
      errorDetail?.additionalInfo?.argument?.statusDetails?.additionalDetails ??
        errorDetailParams
    );
  };

  return {
    displayErrorMessages,
    getErrorDetails,
    getMessageFormatter,
    getINV408ErrorMessageById,
  };
};
