import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
} from '../types';
import { extractProperty } from './utils/extractProperty';

export const searchArticlesTaxonomy = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  requirements.push({
    name: CmsSanitizationRequirementName.ARTICLES,
    filters: extractProperty(data, 'subjectTaxonomy', []),
  });

  return {
    component: 'articleSearch/SearchArticlesTaxonomy',
  };
};
