import { apiClientFactory } from '@vf/api-client';
import { prepareLocale, prepareRetailStoreId } from '../utils/query';
import { Ref, ssrRef } from '@nuxtjs/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { UseProductInventoryStorage } from './types';
import { useProductAttributes } from '../useProduct/composables/useProductAttributes';
import { useFeatureFlagsStore } from '../store/featureFlags';
import {
  ProductInventoryVariant,
  ProductVariant,
  StoredProductInventoryVariant,
} from '@vf/api-client/src/types';
import useProduct from '../useProduct';
import { useI18n } from '../useI18n';
import { useRequestTracker } from '../useRequestTracker';

const ECOMM_STOCK = 'DC';

export const useProductInventory = (
  instance: ComponentInstance,
  contextKey?: string
) => {
  const storage: ComposablesStorage<UseProductInventoryStorage> = initStorage<UseProductInventoryStorage>(
    instance,
    `useProductInventory-${contextKey}`
  );
  const inventoryLoading: Ref<boolean> =
    storage.get('inventoryLoading') ??
    storage.save(
      'inventoryLoading',
      ssrRef(false, `useProductInventory-inventoryLoading-${contextKey}`)
    );

  const storedInventory: Ref<StoredProductInventoryVariant> =
    storage.get('storedInventory') ??
    storage.save(
      'storedInventory',
      ssrRef(
        {
          productId: '',
          stock: {},
        },
        `useProductInventory-storedInventory-${contextKey}`
      )
    );

  const { getProductInventory: getProductInventoryAPI } = apiClientFactory(
    instance
  );
  const { product, configure, updatePriceData } = useProduct(
    instance,
    contextKey
  );

  const { mapVariantsData, defaultAttributes } = useProductAttributes(
    instance,
    { product, configure },
    contextKey
  );

  const { localeCode } = useI18n(instance);

  const { trackRequest, getOngoingRequest, clearRequest } = useRequestTracker(
    instance
  );
  const { isBopis20Enabled } = useFeatureFlagsStore();

  const applyStockToProductVariants = (variants: ProductInventoryVariant) => {
    product.value.variants.forEach((productVariant: ProductVariant) => {
      productVariant.stock =
        variants[productVariant.id] || productVariant.stock;
    });
  };

  const getAvailableAttributesValues = () =>
    product.value.variants.reduce<Map<string, Set<string>>>(
      (avAttrs: Map<string, Set<string>>, variant) => {
        if (!variant.stock?.inStock) return avAttrs;

        Object.entries(variant.attributes).forEach(
          ([attributeName, attributeValue]) => {
            let attributeAvailableValues = avAttrs.get(attributeName);
            if (attributeAvailableValues) {
              attributeAvailableValues.add(attributeValue);
            } else {
              attributeAvailableValues = new Set<string>([attributeValue]);
              avAttrs.set(attributeName, attributeAvailableValues);
            }
          }
        );
        return avAttrs;
      },
      new Map<string, Set<string>>()
    );

  const remapProductAttributeOptions = () => {
    const availableAttributes = getAvailableAttributesValues();

    defaultAttributes.forEach(({ code, outputKey: attributePropertyName }) => {
      const availableAttributeValues = availableAttributes.get(code);
      (product.value[attributePropertyName] as any).forEach((option) => {
        option.available = availableAttributeValues?.has(option.value);
      });
      product.value.attributes
        .find((attribute) => attribute.code === code)
        ?.options.forEach((option) => {
          option.available = availableAttributeValues?.has(option.value);
        });
    });
  };

  const getProductInventory = async (
    productId: string,
    storeId?: string,
    showLoadingState: boolean = true
  ) => {
    inventoryLoading.value = showLoadingState;
    const query: string[] =
      isBopis20Enabled && storeId
        ? [prepareLocale(localeCode()), prepareRetailStoreId(storeId)]
        : [prepareLocale(localeCode())];
    const { tag } = trackRequest(
      `useProductInventory-getProductInventory-${contextKey}`
    );
    const stockSource = isBopis20Enabled && storeId ? storeId : ECOMM_STOCK;

    try {
      if (
        storedInventory.value.productId !== productId ||
        !storedInventory.value.stock[stockSource]
      ) {
        const response = await getProductInventoryAPI(
          productId,
          query.join('&')
        );

        storedInventory.value.productId = productId;
        storedInventory.value.stock[stockSource] = response.data.variants;
      }
      await getOngoingRequest('useProduct-getProductDetails');

      // update semi-static data based on the inventory response
      applyStockToProductVariants(storedInventory.value.stock[stockSource]);
      mapVariantsData();
      remapProductAttributeOptions();
      updatePriceData();
    } catch (err) {
      instance.$log.error(
        `[@useProductInventory::getProductInventory] Failed to fetch product <${productId}> inventory with following error: <${err}>`
      );
    } finally {
      clearRequest(tag);
      inventoryLoading.value = false;
    }
  };

  const invalidateStoredInventory = () =>
    (storedInventory.value = { productId: '', stock: {} });

  return {
    applyStockToProductVariants,
    getProductInventory,
    invalidateStoredInventory,
    inventoryLoading,
    storedInventory,
  };
};
