import productsGridCache from '@vf/shared/src/theme/productsGridCache';
import {
  isCategorySegment,
  isProductSegment,
} from '@vf/composables/src/useUrl/handlers/parseUrl';
import { ROUTES } from '@vf/composables/src/utils/routes';

const isListingPage = (path) =>
  isCategorySegment(path) || path?.includes(ROUTES.SEARCH());

export const PREVIOUS_PLP = 'PREVIOUS_PLP';

const saveListingPage = (path) => {
  window.sessionStorage.setItem(PREVIOUS_PLP, path);
};

const getListingPage = () => {
  return window.sessionStorage.getItem(PREVIOUS_PLP);
};

if (typeof window !== 'undefined') {
  window.addEventListener('popstate', () => {
    productsGridCache.checkForCache = true;
  });
}

const handleRouteChange = async (to, from) => {
  productsGridCache.currentPage = 1;
  let destination = null;

  if (from && to.path !== from.path) {
    let shouldKeepCache = false;
    if (productsGridCache.checkForCache) {
      const listingPage = getListingPage();
      if (to.path === listingPage) {
        if (isProductSegment(from.path) && isListingPage(to.path)) {
          destination = await productsGridCache.getDestination();
          productsGridCache.currentPage = destination?.productPage || 1;
          shouldKeepCache = true;
        }
      }

      productsGridCache.checkForCache = false;
    }

    if (!isProductSegment(to.path) && !shouldKeepCache) {
      await productsGridCache.reset();
    }

    if (isListingPage(from.path)) {
      saveListingPage(from.path);
    }
  }

  return destination;
};

export default async function (to, from, savedPosition) {
  if (to.query?.keepPosition) {
    return {};
  }

  let position = savedPosition || { x: 0, y: 0 };

  const destination = await handleRouteChange(to, from);

  const nuxt = window.$nuxt;

  if (to.path !== from.path && !destination) {
    nuxt.$nextTick(() => nuxt.$emit('customTriggerScroll'));
  }

  return new Promise((resolve) => {
    nuxt.$once('customTriggerScroll', () => {
      if (to.hash) {
        position = { selector: '#' + window.CSS.escape(to.hash.substr(1)) };
      }

      if (isProductSegment(to.path) || isListingPage(to.path)) {
        position = { x: 0, y: 0 };
      }

      resolve(position);
    });

    nuxt.$off('customTriggerGridScroll');
    destination &&
      nuxt.$once('customTriggerGridScroll', () => {
        const destinationKey = destination.rowId;
        const selector = `[data-lazy-row-id="${destinationKey}"]`;

        const element = document.querySelector(selector);

        if (element) {
          element.scrollIntoView({ behavior: 'auto', block: 'end' });
        }
      });
  });
}
