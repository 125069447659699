import { ProductReviewFilter } from '@vf/api-contract';
import {
  ProductReviewRollup,
  ProductReviewTag,
} from 'packages/api-contract/src/entities';

export const getProductReviewTags = (
  rollup: ProductReviewRollup,
  filters: ProductReviewFilter[]
): ProductReviewTag[] =>
  rollup.properties
    .filter(
      (prop) => prop.type === 'tag' && !['pros', 'cons'].includes(prop.key)
    )
    .map((prop) => ({
      heading: prop.name,
      key: prop.key,
      tags: prop.values.map(({ label, count }) => ({
        label,
        count,
        isSelected: !!filters.find(
          (f) => f.key === prop.key && f.value.includes(label)
        ),
      })),
      minLabel:
        prop.display_type === 'histogram' ? prop.display_values[0] : undefined,
      maxLabel:
        prop.display_type === 'histogram'
          ? prop.display_values[prop.display_values.length - 1]
          : undefined,
    }));
