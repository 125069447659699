import { extractProperty, getTeaserStyle } from './utils';
import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { decorateHtmlValue } from './utils/decorateHtmlValue';

export const productsListNoResults = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const isRichTextObject = extractProperty(data, 'useRichText', false);
  return {
    component: 'shared/ProductsListNoResults',
    props: {
      title: isRichTextObject
        ? decorateHtmlValue(
            data.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : extractProperty(data, 'teaserTitle'),
      subtitle: extractProperty(data, 'teaserSubtitle'),
      html: isRichTextObject,
      titleStyles: {
        color: getTeaserStyle(data, 'teaserTitleColor'),
        fontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        fontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
      },
      subtitleStyles: {
        color: getTeaserStyle(data, 'teaserSubTitleColor'),
        fontFamily: getTeaserStyle(data, 'teaserSubTitleFontFace'),
        fontWeight: getTeaserStyle(data, 'teaserSubTitleFontWeight'),
      },
    },
  };
};
