import { Plugin } from '@nuxt/types';
import { onGlobalSetup, provide, ssrRef } from '@nuxtjs/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getVueInstanceFromContext } from '../helpers';
import { GetAllFlagsResponse } from '@vf/api-contract';
import { getCookieByName } from '@vf/composables/src/utils/cookie';
import { apiClientFactory } from '@vf/api-client';
import { LDClient } from 'launchdarkly-js-client-sdk';

const launchDarkly: Plugin = async (context, inject) => {
  const vueInstance = getVueInstanceFromContext(context);
  let ldClientSdk: LDClient;

  const { getFeatureFlags } = apiClientFactory(vueInstance);
  const featureFlagsStore = useFeatureFlagsStore();
  const ldPassthroughHeader = ssrRef(undefined, 'ldPassthoughHeader');

  const fetchAllFlags = async (instance): Promise<GetAllFlagsResponse> => {
    try {
      if (!instance.$env.LAUNCHDARKLY_CLIENT_KEY) {
        instance.$log.debug(
          `[@useLaunchDarkly/index::fetchAllFlags] Launch Darkly not loaded; Client Key is missing`
        );
        return;
      }

      if (process.server) {
        ldPassthroughHeader.value = context.req.headers[
          'x-ld-passthrough'
        ]?.toString();

        const resp = await getFeatureFlags();
        Object.keys(resp.data.$flagsState).forEach((key: string) => {
          featureFlagsStore.$patch({ [key]: resp.data[key] });
        });
        return resp.data;
      } else {
        if (!ldClientSdk) {
          const { initialize } = await import('launchdarkly-js-client-sdk');

          const xUsIdHeader = getCookieByName('x-usid');
          const currentUrl = window.location.href;
          const url = new URL(currentUrl);
          const params = new URLSearchParams(url.search);
          const ldPassthroughValue = params.get('ldPassthrough');

          const ldContext = {
            kind: 'user',
            key: xUsIdHeader || instance.$env.LAUNCHDARKLY_CLIENT_KEY,
            brand: instance.$env.BRAND,
            locale: instance.$i18n.locale,
            domain: document.location.host,
            ldPassthrough: ldPassthroughHeader.value || ldPassthroughValue,
          };

          ldClientSdk = initialize(
            instance.$env.LAUNCHDARKLY_CLIENT_KEY,
            ldContext,
            { streaming: true }
          );

          await ldClientSdk.waitForInitialization();
        }

        const allFlags = ldClientSdk.allFlags();

        Object.keys(allFlags).forEach((key) => {
          featureFlagsStore.$patch({ [key]: allFlags[key] });
        });

        return {
          $flagsState: {
            ...allFlags,
          },
          $valid: true,
        } as GetAllFlagsResponse;
      }
    } catch (error) {
      instance.$log.error(
        `[@useLaunchDarkly/index::fetchAllFlags] Error: ${error.message}`
      );
    }
  };

  const getActiveExperiments = () => {
    if (!ldClientSdk) {
      return;
    }

    const variationDetails = Object.entries(ldClientSdk.allFlags()).map(
      ([key]) => ({
        key,
        ...ldClientSdk.variationDetail(key),
      })
    );

    return variationDetails
      .filter((flag) => flag.reason?.inExperiment)
      .map((flag) => {
        return {
          key: flag.key,
          value: String(flag.value),
          variationIndex: flag.variationIndex,
        };
      });
  };

  await fetchAllFlags(vueInstance);

  inject('launchDarkly', {
    getFeatureFlags: () => featureFlagsStore,
    fetchAllFlags: () => fetchAllFlags(vueInstance),
    getActiveExperiments,
  });

  onGlobalSetup(() => {
    provide('getFeatureFlags', () => featureFlagsStore);
  });
};

export default launchDarkly;
