











import {
  defineComponent,
  watch,
  onUnmounted,
  ref,
  nextTick,
} from '@vue/composition-api';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'VfPopover',
  props: {
    visible: {
      type: Boolean,
    },
    position: {
      type: String as PropType<'top' | 'bottom' | 'left' | 'right'>,
      default: 'bottom',
    },
    /**
     * if is defined,the tooltip must remain within the boundaries of the container
     */
    containerSelector: {
      type: String,
    },
  },
  setup(props) {
    let unwatchVisible = () => null;
    const refPopover = ref<HTMLDivElement>(null);
    if (props.containerSelector && ['top', 'bottom'].includes(props.position)) {
      const updatePosition = () => {
        refPopover.value.style.removeProperty('--vf-popover-translate-x');
        refPopover.value.style.removeProperty('--vf-popover-left');
        const containerBounding = refPopover.value
          .closest(props.containerSelector)
          ?.getBoundingClientRect();
        if (!containerBounding) return;
        const popoverBounding = refPopover.value.getBoundingClientRect();

        const moveFromLeftBorder = (offsetLeft: number) => {
          refPopover.value.style.setProperty('--vf-popover-translate-x', '0');
          refPopover.value.style.setProperty('--vf-popover-left', '0');
          refPopover.value.style.setProperty(
            '--vf-popover-left',
            `${
              containerBounding.left -
              refPopover.value.getBoundingClientRect().left +
              offsetLeft
            }px`
          );
        };
        if (popoverBounding.left < containerBounding.left) {
          // move the popover to the left side
          moveFromLeftBorder(0);
        } else if (popoverBounding.right > containerBounding.right) {
          // move the popover to the right side
          moveFromLeftBorder(containerBounding.width - popoverBounding.width);
        }
      };

      unwatchVisible = watch(
        () => props.visible,
        async (isVisible) => {
          await nextTick();
          if (!isVisible || !refPopover.value) return;
          updatePosition();
        }
      );
    }

    onUnmounted(unwatchVisible);
    return {
      refPopover,
    };
  },
});
