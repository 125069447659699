import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils';

export const giftCardBalanceForm = (data: CMPlaceholder) => {
  return {
    component: 'giftCards/CheckGiftCardBalanceForm',
    props: {
      translations: {
        cardNumberLabel: getTranslation(
          data,
          'cardNumberLabel',
          '$Please enter the card number$'
        ),
        cardNumberPlaceholder: getTranslation(
          data,
          'cardNumberPlaceholder',
          '$Please enter the card number$'
        ),
        cardPinLabel: getTranslation(
          data,
          'cardPinLabel',
          '$Please enter the card pin$'
        ),
        cardPinPlaceholder: getTranslation(
          data,
          'cardPinPlaceholder',
          '$Please enter the card pin$'
        ),
        captchaLabel: getTranslation(data, 'captchaLabel', '$Captcha$'),
        submitButton: getTranslation(data, 'submitButton', '$Submit$'),
        securityCheckText: getTranslation(
          data,
          'securityCheckText',
          '$Security check provided by$'
        ),
        securityLinkText: getTranslation(
          data,
          'securityLinkText',
          '$mCaptcha$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$Required field$',
          digits16: '$Please match the requested format: XXXX-XXXX-XXXX-XXXX$',
          digits4or8: '$Please match the requested format: XXXX or XXXXXXXX$',
        }),
      },
    },
  };
};
