//
//
//
//
//
//
//
//
//

import HeaderPlaceholder from './Organism.HeaderPlaceholder';
import HeroPlaceholder from './Organism.HeroPlaceholder';
import JumbotronPlaceholder from './Organism.JumbotronPlaceholder';
import ArticleGridPlaceholder from './Organism.ArticleGridPlaceholder';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'HomepagePlaceholder',
  components: {
    HeaderPlaceholder,
    HeroPlaceholder,
    JumbotronPlaceholder,
    ArticleGridPlaceholder,
  },
});
