import { UpdateItemRequest } from '@vf/api-contract';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const updateItem = (configuration: ApiClientConfiguration) => (
  data: UpdateItemRequest,
  query?: string
): Promise<any> => {
  return axios.put(
    urlBuilder(
      `${endpoints.get(configuration).cart.updateItem}${
        query ? '?' + query : ''
      }`,
      {
        config: configuration,
        mapping: { id: data.cartId },
      }
    ),
    data,
    {
      headers: headerBuilder(configuration),
    }
  );
};
