import { CMPlaceholder } from '@vf/api-contract';
import { CmsSite } from '@vf/api-contract';
import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
  getTranslation,
} from './utils';
import { pdpProductUpsell } from './pdpProductUpsell';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export const cartProductUpsell = (
  data: CMPlaceholder,
  context,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  if (isCheckoutRedesignEnabled) {
    return {
      component: 'upsell/SFCCCartUpsell',
      props: {
        quickShopLink: generateLinkFromTarget(
          extractProperty(
            extractCommonConfigLink(siteConfiguration, 'upsell-quick-shop'),
            '[0]'
          ),
          context,
          siteConfiguration,
          cmsBaseUri
        ),
      },
    };
  } else {
    const pdpProductUpsellObj = pdpProductUpsell(
      data,
      context,
      siteConfiguration,
      cmsBaseUri
    );
    return {
      ...pdpProductUpsellObj,
      props: {
        ...pdpProductUpsellObj.props,
        isCartLayout: true,
        translations: {
          ...pdpProductUpsellObj.props.translations,
          details: getTranslation(data, 'addToCartCta', '$add To Cart Cta$'),
        },
      },
    };
  }
};
