import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  generateLinkFromTeaserTargets,
  getImageObject,
  getVideoObject,
} from './utils';

export const carouselSlideActivity = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'carousel/CarouselSlideActivity',
    props: {
      link: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
      image: getImageObject(
        extractProperty(data, 'pictures[0]'),
        siteConfiguration,
        null,
        cmsBaseUri,
        data.responsiveMedia
      ),
      video: {
        small: getVideoObject(data.videos || [], cmsBaseUri),
      },
      text: extractProperty(data, 'teaserTitle'),
      teaserStyleCustomizations: extractProperty(
        data,
        'teaserStyleCustomizations'
      ),
    },
  };
};
