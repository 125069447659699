import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

const date = new Date();
const month = (date.getMonth() + 1).toString();
const formattedDate =
  date.getFullYear() +
  '-' +
  (month.length > 1 ? month : '0' + month) +
  '-' +
  date.getDate();

export const updateProfile = (configuration: ApiClientConfiguration) => (
  data,
  usid: string
): Promise<any> => {
  const { siteId } = configuration;
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.updateProfile,
    {
      config: configuration,
    }
  );

  return axios.post(
    url,
    {
      consumerProfile: {
        context: {
          caller: 'SFCC_CALLER',
        },
        consumerDetails: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          source: {
            store: siteId,
            acquisitionType: 'Signup',
          },
        },
        subscriptions: [
          {
            type: 'Newsletter',
            optin: true,
            doubleOptin: true,
            date: formattedDate,
            channel: 'email',
          },
        ],
        preferences: {
          languageCode: data.language,
          countryCode: data.country,
          currencyCode: data.currency,
        },
      },
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
