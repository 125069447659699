import { apiClientFactory } from '@vf/api-client';
import { useCart, useLoyalty, useRequestTracker } from '../../../';
import ls from '../../../utils/localStorage';
import { useUserSessionData } from '../useUserSessionData';
import { useLoyaltyEnrolled } from '../useLoyaltyEnrolled';
import { useConsumer } from '../useConsumer';
import { useSaveForLater } from '../useSaveForLater';
import { ConsumerType } from '../../types';
import { withRetryAfterTime } from '../../../utils/withRetryAfterTime';

export const useUserToken = (instance) => {
  const getToken = async (data, guestObjects) => {
    const { trackRequest, clearRequest } = useRequestTracker(instance);

    const { getUserToken } = apiClientFactory(instance);

    const { tag } = trackRequest('useAuthentication-getToken');
    const codeVerifier = ls.getItem('codeVerifier');

    try {
      // interim solution for GLOBAL15-62050
      const result = await withRetryAfterTime(
        () =>
          getUserToken('Registered', {
            authorizationCode: data.authorizationCode,
            codeVerifier: codeVerifier,
            st: data.st,
            orderNo: data.orderNo,
            action: data.action,
            guestObjects,
          }),
        2000,
        409
      );

      clearRequest(tag);
      return result;
    } catch (err) {
      instance.$log.error(
        '[@/useAuthentication/composables/useToken::getToken]',
        err
      );
      throw err;
    }
  };

  const setNewStateWithToken = (token) => {
    const { setUserSessionDatainLS } = useUserSessionData(instance);
    const {
      isLoyaltyEnabled,
      setLoyaltyEnrolled,
      loyaltyEnrolledCookieName,
    } = useLoyaltyEnrolled(instance);
    const { setSaveForLaterId } = useSaveForLater(instance);
    const { consumerType } = useConsumer(instance);

    // set session data in local storage: usid, consumerId, loyaltyEnrolled, consumerType, consumerNo
    setUserSessionDatainLS(token);
    setLoyaltyEnrolled(token.isLoyaltyMember ?? false);

    if (token.isLoyaltyMember && isLoyaltyEnabled) {
      const { referralCode, getLoyaltyToken } = useLoyalty(instance);
      instance.$cookies.set(loyaltyEnrolledCookieName, 'true', {
        path: '/',
      });
      if (referralCode.value) {
        // call loyalty token API asynchronously only when there is a referral_code
        getLoyaltyToken();
      }
    }

    // update current registered customer's cart ID if given
    if (token.basketId) {
      useCart(instance).setCartId(token.basketId);
    }

    if (token.saveForLaterId) {
      setSaveForLaterId(token.saveForLaterId);
    }

    consumerType.value = token.consumerType as ConsumerType;
  };

  return {
    getToken,
    setNewStateWithToken,
  };
};
