import { Country, PhoneInputCountry } from '../useUtilities';
import { getFieldById } from './getFieldById';
import { CountryCode } from 'libphonenumber-js';

export const mapCountriesToPhoneInputCountries = (
  countries: Country[]
): PhoneInputCountry[] =>
  countries
    .map((item) => {
      const phoneData = getFieldById(item.fields, 'phone');
      return item.name && item.code && phoneData?.areaCodes
        ? phoneData.areaCodes.map((value) => ({
            name: item.name,
            countryCode: item.code as CountryCode,
            countryCallingCode: value,
          }))
        : [];
    })
    .flat();
