import {
  ParsedProductReviewUgcItem,
  ProductReviewUgcItem,
} from '@vf/api-contract';

export const getMappedReviewMedia = (
  media: ProductReviewUgcItem[]
): ParsedProductReviewUgcItem[] =>
  media && media.length
    ? media.map(({ id, helpful_votes, not_helpful_votes, ...item }) => ({
        ...item,
        id: parseInt(id, 10),
        helpfulVotes: helpful_votes,
        notHelpfulVotes: not_helpful_votes,
        flagged: false,
      }))
    : [];
