import { ref, Ref } from '@vue/composition-api';
import ss from '@vf/composables/src/utils/sessionStorage';

export const useNotification = () => {
  const displayEmployeeDiscountNotification = (data: string[]): void => {
    const uniqueData = [...new Set(data)];
    ss.setItem(
      'pageTypesWithDisplayedNotification',
      JSON.stringify(uniqueData)
    );
    pageTypesWithDisplayedNotification.value = uniqueData;
  };

  const pageTypesWithDisplayedNotification: Ref<string[]> = ref(
    JSON.parse(ss.getItem('pageTypesWithDisplayedNotification')) || []
  );

  return {
    displayEmployeeDiscountNotification,
    pageTypesWithDisplayedNotification,
  };
};
