import { ApplePayTranslations, CMPlaceholder, CmsSite } from '@vf/api-contract';
import { extractProperty, getTranslation } from './extractProperty';
import { extractLocalSettingsExpandedLinks } from './extractLocalSettingsExpandedLinks';
import { extractRichText } from './extractRichText';
import { ComposableContext } from '../../types';

export const getApplePayComponentData = (
  data: CMPlaceholder,
  cmsBaseUri: string,
  context: ComposableContext,
  siteConfiguration: CmsSite
): { component: CMPlaceholder | null; translations: ApplePayTranslations } => {
  const applePayComponent = extractApplePayObject(data);

  return {
    component: applePayComponent,
    translations: {
      total: getTranslation(applePayComponent, 'total', '$Total$'),
      subtotal: getTranslation(applePayComponent, 'subtotal', '$Subtotal$'),
      tax: getTranslation(applePayComponent, 'tax', '$Tax$'),
      shipping: getTranslation(applePayComponent, 'shipping', '$Shipping$'),
      discount: getTranslation(applePayComponent, 'discount', '$Discount$'),
      placeOrder: getTranslation(
        applePayComponent,
        'placeOrder',
        '$Place your order with ApplePay$'
      ),
      giftCard: getTranslation(applePayComponent, 'giftCard', '$Gift Card$'),
      applePay: getTranslation(applePayComponent, 'applePay', '$ApplePay$'),
      tooltip: extractRichText(
        data,
        'applepay-tooltip-richtext',
        '$Pay With Apple Pay$',
        cmsBaseUri,
        context,
        siteConfiguration
      ),
      athleteCreditLabel: getTranslation(
        applePayComponent,
        'athleteCreditLabel',
        '$Athlete Credit$'
      ),
      nameFormatError: getTranslation(
        applePayComponent,
        'nameFormatError',
        '$Please enter a valid name.$'
      ),
      nameLengthError: getTranslation(
        applePayComponent,
        'nameLengthError',
        '$This field has a {{nameLengthLimit}} character limit$'
      ),
      addressFormatError: getTranslation(
        applePayComponent,
        'addressFormatError',
        '$The address is not valid, please verify and try again$'
      ),
    },
  };
};

const extractApplePayObject = (data: CMPlaceholder): CMPlaceholder => {
  /** When Apple Pay CTA is directly placed on the page */
  if (data.viewtype === 'apple-pay-cta') {
    return data;
  }

  /** When Apple Pay CTA is one of linked properties */
  return extractProperty(
    extractLocalSettingsExpandedLinks(data, 'applePayComponent'),
    '[0]'
  );
};
