export type Validator = (
  value: any,
  data?: Record<string, any>,
  key?: string
) => string | boolean | undefined;

/**
 * Validates that a value follows the pattern.
 * If invalid, throw an error message.
 * @category Utils
 */
export const validateRegex = (_regex: RegExp, message?: string): Validator => (
  value
) => {
  return !value?.match(_regex) && (message || `Value should match ${_regex}`);
};

/**
 * Validates that a value follows the pattern of a valid date.
 * If invalid, throw an error message.
 * @category Utils
 */
// export const validateDate = (
//   format: 'MM/DD/YYYY' | 'DD/MM/YYYY' | 'YYYY-MM-DD' = 'YYYY-MM-DD',
//   message?: string
// ): Validator => (value) => {
//   const invalidResponse =
//     message || `Date should match ${format} and be a valid date.`;
//   if (!value) return invalidResponse;

//   const [year, month, day] = mapDateParts(value, format);

//   // no matter month > 12 or day > 28-31, testDate will be a valid date in the future,
//   // with the correspondent days/months addition:
//   // ie: 14/31/1990 is converted to 02/13/1991.
//   const testDate = new Date(
//     Date.UTC(Number(year), Number(month) - 1, Number(day), 0, 0, 0)
//   );
//   // so we ensure the introduced date is valid and won't change comparing each date portion
//   // if keeps stable, is valid
//   const valid =
//     Number(day) === testDate.getUTCDate() &&
//     Number(month) - 1 === testDate.getUTCMonth() &&
//     Number(year) === testDate.getUTCFullYear();
//   return !valid && invalidResponse;
// };

/**
 * Validates that a value follows the pattern of a valid password.
 * If invalid, throw an error message.
 * @category Utils
 */
export const validatePassword = (
  message = 'Password does not meet requirements'
): Validator => (value) =>
  !value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?.).{8,}$/) && message;

export const passwordExpand = {
  length: (v): string =>
    !v.match(/.{8,}/) && 'Password must contain at least 8 characters',
  number: (v): string =>
    !v.match(/\d/) && 'Password must contain at least one number',
  upper: (v): string =>
    !v.match(/[A-Z]/) &&
    'Password must contain at least one character in upper case',
  lower: (v): string =>
    !v.match(/[a-z]/) &&
    'Password must contain at least one character in lower case',
};

/**
 * Validates that a value follows the pattern of a valid email address.
 * If invalid, throw an error message.
 * The regex pattern below aligns with RFC 3696 specifications
 * https://help.okta.com/en-us/Content/Topics/Directory/Reference_Directories.htm
 * https://regex101.com/library/PEtM4r
 * @category Utils
 */
export const validateEmail = (
  message: string = 'Email is not valid'
): Validator => (value) => {
  if (
    !value.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
    return message;

  // check TLD domain is present
  if (value.split('@')[1].split('.').length < 2) return message;
};

/**
 * Validates that a value is required. If invalid, throw an error message.
 * @category Utils
 */
export const validateRequired = (
  message: string = 'This field is required'
): Validator => (value) => !value && message;

/**
 * Validates that value contains at least `_min` characters.
 * If value is less than `_min`, throw an error message.
 * @category Utils
 */
export const validateMinLength = (
  _min: number,
  message?: string
): Validator => (value) =>
  (value?.length || 0) < _min &&
  (message || `Value must contain at least ${_min} characters`);

/**
 * Validates that value contains at exactly `_size` characters.
 * If value is different than `_size`, throw an error message.
 * @category Utils
 */
export const validateExactLength = (
  _size: number,
  message?: string
): Validator => (value) =>
  value?.length !== _size &&
  (message || `Value must contain at exactly ${_size} characters`);

/**
 * Validates that value is not greater than `_max` characters.
 * If value is greater than `_max`, throw an error message.
 * @category Utils
 */
export const validateMaxLength = (
  _max: number,
  message?: string
): Validator => (value) =>
  (value?.length || 0) > _max &&
  (message || `Value must not be longer than ${_max} characters`);

/**
 * Validates that value is less than `_min`.
 * If value is less than `_min`, throw an error message.
 * @category Utils
 */
export const validateMin = (_min: number, message?: string): Validator => (
  value
) => value < _min && (message || `Value must not be less than ${_min}`);

/**
 * Validates that value is not greater than `_max`.
 * If value is greater than `_max`, throw an error message.
 * @category Utils
 */
export const validateMax = (_max: number, message?: string): Validator => (
  value
) => value > _max && (message || `Value must not be more than ${_max}`);

/**
 * Validates that value is the same as other `field` in the form.
 * If value is not the same as `field` , returns an error message.
 * @category Utils
 */
export const validateSameAs = (field: string, message?: string): Validator => (
  value,
  form
) => {
  // if the comparison value is not yet filled, skip the validation
  if (!form[field]) return;
  return (
    value !== form[field] && (message || `Value must be the same as ${field}`)
  );
};

/**
 * Validates that a value is same or after `_min`.
 * If invalid, throw an error message.
 * @category Utils
 */
// export const validateMinDate = (
//   _minDate: Date,
//   message?: string
// ): Validator => (value) => {
//   const _date = new Date(value);
//   if (_date.getTime() < _minDate.getTime())
//     return message || `Date must be same or after ${_minDate.toDateString()}`;
// };

/**
 * Validates that a value is same or after `_max`.
 * If invalid, throw an error message.
 * @category Utils
 */
// export const validateMaxDate = (
//   _maxDate: Date,
//   message?: string
// ): Validator => (value) => {
//   const _date = new Date(value);
//   if (_date.getTime() > _maxDate.getTime())
//     return message || `Date must be same or before ${_maxDate.toDateString()}`;
// };

/**
 * Validates that a value follows the pattern of a valid phone number.
 * If invalid, throw an error message.
 * @category Utils
 */
export const validatePhone = (
  message = 'A valid phone number is required'
): Validator => (value) => !value.match(/^\d{10}$/) && message;

export const validateName = (message?: string): Validator => (value) =>
  validateRegex(
    /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
    message
  )(value);

/**
 * Validates that a value is future date.
 * If invalid, throw an error message.
 * @category Utils
 */
export const validateCreditCardExpiration = (
  message = 'Expiration date must follow MM/YY format'
): Validator => (value = '') => {
  const [month = '', year = ''] = value.split('/');

  const isCorrectDateFormat =
    value && /^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(value); // mm/yy format
  const isFutureDate = new Date(Number(`20${year}`), month, 0) > new Date(); // make sure that cc may be still valid for FE validation

  if (!isCorrectDateFormat || !isFutureDate) {
    return message;
  }
};
