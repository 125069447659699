import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useLocationConfirmationStore = defineStore(
  'locationConfirmation',
  () => {
    const shouldShowDialog = ref<boolean>(false);
    const proposedSiteUrl = ref<string>('');

    const openDialog = () => (shouldShowDialog.value = true);
    const closeDialog = () => (shouldShowDialog.value = false);

    const setProposedSiteUrl = (siteUrl: string) =>
      (proposedSiteUrl.value = siteUrl);

    return {
      closeDialog,
      openDialog,
      proposedSiteUrl,
      setProposedSiteUrl,
      shouldShowDialog,
    };
  }
);
