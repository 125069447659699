import type { VuelidateObject } from '../useValidation';
import type { CardType, CyberSourceTokenError } from '@vf/api-contract';

import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref, getCurrentInstance } from '@vue/composition-api';
import ls from '../utils/localStorage';
import { apiClientFactory } from '@vf/api-client';

export const useCyberSourceStore = (instance?) => {
  const currentInstance = getCurrentInstance();
  const localInstance = currentInstance?.proxy || instance;

  return defineStore('cyberSource', () => {
    const { deviceFingerPrint: deviceFingerPrintAPI } = apiClientFactory(
      localInstance
    );

    const formInstance = ref();
    const deviceFingerPrintURL = ref(ls.getItem('deviceFingerPrintURL'));

    const sessionID = ref(ls.getItem('sessionID'));
    const captureContext = ref('');
    const microformToken = ref('');

    const cardErrors = ref<CyberSourceTokenError['details']>([]);
    const possibleCardTypes = ref<CardType[]>([]);
    const expirationDate = ref<string>('');

    const getCyberSourcePayload = () => ({
      captureContextKey: captureContext.value,
      microformToken: microformToken.value,
    });

    const $v = ref<VuelidateObject>({} as VuelidateObject);

    const isCyberSourceLoaded = ref(false);
    /**
     * Generating sessionID to track device for cybersource fingerprinting
     */

    const prepareDeviceFingerprinting = async () => {
      if (sessionID.value && deviceFingerPrintURL.value) return;

      const uuid = uuidv4();
      sessionID.value = uuid;
      ls.setItem('sessionID', uuid);

      try {
        const result = await deviceFingerPrintAPI(sessionID.value);
        const url = result.data.data?.url;

        deviceFingerPrintURL.value = url;
        ls.setItem('deviceFingerPrintURL', url);
      } catch (err) {
        instance.$log.error(
          `[@cyberSourceStore::prepareDeviceFingerprinting] unexpected deviceFingerPrintAPI error`,
          err
        );
      }
    };

    const setMicroformToken = (token: string) => {
      microformToken.value = token;
    };

    return {
      formInstance,
      deviceFingerPrintURL: computed(() => deviceFingerPrintURL.value || ''),
      sessionID: computed(() => sessionID.value || ''),
      microformToken: computed(() => microformToken.value),
      captureContext,
      getCyberSourcePayload,
      prepareDeviceFingerprinting,
      setMicroformToken,
      $v,
      expirationDate,
      possibleCardTypes,
      cardErrors,
      isCyberSourceLoaded,
    };
  })();
};
