import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const addCoupon = (configuration: ApiClientConfiguration) => (
  data
): Promise<any> => {
  return axios.put(
    urlBuilder(endpoints.get(configuration).cart.addCoupon, {
      config: configuration,
    }),
    {
      cartId: data.cartId,
      code: data.code,
    },
    {
      headers: headerBuilder(configuration),
    }
  );
};
