import type { Cart, CheckoutOrder } from '@vf/api-client';

import { defineStore } from 'pinia';
import { compareAsc } from 'date-fns';
import { CheckoutContext } from '@vf/api-contract';
import { computed, ref } from '@vue/composition-api';
import { getDeliveryDate } from '@vf/shared/src/utils/helpers';
import { groupShipmentWithItems } from '../utils/orders';
import { PaymentMethodCode } from '@vf/api-client/src';

export const useCheckoutStore = defineStore('checkout', () => {
  const currentStep = ref<CheckoutContext>(CheckoutContext.Shipping);

  const previousCart = ref<Cart>(null);
  const order = ref<CheckoutOrder>(null);

  const orderShipmentsSortedByDelivery = computed(() => {
    if (!order.value?.shipments) return [];
    return (
      groupShipmentWithItems(
        order.value.shipments as any,
        order.value.items as any
      )
        // sort shipments by deliveryTime if provided
        .sort((shipmentA, shipmentB) => {
          const dateA = getDeliveryDate(shipmentA.items[0].deliveryTime);
          if (dateA === null) return 1;
          const dateB = getDeliveryDate(shipmentB.items[0].deliveryTime);
          if (dateB === null) return -1;
          return compareAsc(dateA, dateB);
        })
    );
  });

  const orderShipmentsSortedByDeliveryAndShipmentId = computed(() => {
    if (!orderShipmentsSortedByDelivery.value.length) return [];

    const regularShipments = orderShipmentsSortedByDelivery.value.filter(
      (shipment) => shipment.shipmentId.includes('me')
    );
    const customShipments = orderShipmentsSortedByDelivery.value.filter(
      (shipment) => shipment.shipmentId.includes('customs')
    );
    const pickupShipments = orderShipmentsSortedByDelivery.value.filter(
      (shipment) => shipment.shipmentId.includes('pickup')
    );
    return [...regularShipments, ...customShipments, ...pickupShipments];
  });

  const appliedPromotions = computed(() => {
    const promotions = [];
    (order.value?.orderPromotions ?? []).forEach((promotion) => {
      promotions.push({
        id: promotion.promotionId,
        price: promotion.price,
        description: promotion.couponCode || promotion.itemText,
      });
    });
    (order.value?.promoSummary ?? [])
      .filter(({ level }) => level === 'PRODUCT')
      .forEach((promotion) => {
        promotions.push({
          id: promotion.promotionId,
          price: promotion.discount,
          description: promotion.promotionName,
        });
      });
    return promotions;
  });

  const filterPaymentMethod = (method) =>
    (order.value?.paymentMethod ?? [])
      .filter(({ code }) => code === method)
      .map(({ additionalData }) => additionalData);

  const setCurrentStep = (value: CheckoutContext) => {
    currentStep.value = value;
  };

  const giftCards = computed(() =>
    filterPaymentMethod(PaymentMethodCode.GIFT_CARD)
  );
  const rewardCards = computed(() =>
    filterPaymentMethod(PaymentMethodCode.REWARD_CARD)
  );
  const rewardCodes = computed(() =>
    filterPaymentMethod(PaymentMethodCode.REWARD_CODE)
  );
  const rewards = computed(() => [...rewardCards.value, ...rewardCodes.value]);
  const appliedFamilyVoucher = computed(
    () => filterPaymentMethod(PaymentMethodCode.LOYALTY_POINTS)[0]
  );
  const athleteDiscounts = computed(() =>
    filterPaymentMethod(PaymentMethodCode.ATHLETES)
  );

  const isZeroOrder = computed(() => order.value?.totals?.remainingToPay === 0);

  return {
    currentStep,
    setCurrentStep,
    previousCart,
    order,
    orderShipmentsSortedByDelivery,
    orderShipmentsSortedByDeliveryAndShipmentId,
    orderShipments: computed(() =>
      (orderShipmentsSortedByDelivery.value || []).filter(
        (method) => !method.storeName
      )
    ),
    orderPickups: computed(() =>
      (orderShipmentsSortedByDelivery.value || []).filter(
        (method) => method.storeName
      )
    ),
    appliedPromotions,
    giftCards,
    rewardCards,
    rewardCodes,
    rewards,
    appliedFamilyVoucher,
    athleteDiscounts,
    isZeroOrder,
  };
});
