import { CMPlaceholder } from '@vf/api-contract';
import { getDynamicField, getTranslation } from './utils';
// import { getComponentConfigByName } from './utils';

export const checkoutRewards = (data: CMPlaceholder) => {
  return {
    component: 'checkout/CheckoutFamilyRewards',
    props: {
      translations: {
        rewardDetails: getTranslation(
          data,
          'rewardDetails',
          '$Reward Details$'
        ),
        rewardDetailsDescription: getDynamicField(
          data,
          'rewardDetails',
          '$<div><p>A maximum amount of $20 in rewards can be used per transaction.</p><p>Points may expire 1 year from issuance. This may impact your available reward amount.</p><p>This reward can be combined with some promo codes, gift card, and other payment methods offered below.</p><p>If the reward amount is greater than the order total, any remaining amount of the reward will be forfeited.</p></div>$'
        ),
        availableRewards: getTranslation(
          data,
          'availableRewards',
          '$You have <b>up to {{maximum_available_reward}} available rewards</b> to use towards this purchase$'
        ),
        doNotHaveAnyAvailableRewards: getTranslation(
          data,
          'doNotHaveAnyAvailableRewards',
          "$Sorry, you don't have any available rewards. You are {{amount_of_points}} away from a {{minimal_value_reward}}."
        ),
        thisPurchaseWillEarn: getTranslation(
          data,
          'thisPurchaseWillEarn',
          '$This purchase will earn you {{earn_amount_of_point}} points!$'
        ),
        saveRewardForLater: getTranslation(
          data,
          'saveRewardForLater',
          '$Save reward for later$'
        ),
        rewardAppliedStillHaveRewards: getTranslation(
          data,
          'rewardAppliedStillHaveRewards',
          '$Your reward has been applied! You still have available rewards after this purchase.$'
        ),
        rewardAmount: getTranslation(data, 'rewardAmount', '$Reward Amount$'),
        vansFamilyRewards: getTranslation(
          data,
          'vansFamilyRewards',
          '$Vans Family Rewards$'
        ),
        selectAmount: getTranslation(data, 'selectAmount', '$Select Amount$'),
        orderUpdated: getTranslation(
          data,
          'orderUpdated',
          '$Order total has been updated. Please select a reward that is less than or equal to your new order total.$'
        ),
        rewardAppliedNoRemainingRewards: getTranslation(
          data,
          'rewardAppliedNoRemainingRewards',
          '$Your reward has been applied! You will have no remaining rewards after this purchase.$'
        ), // There is a typo in CMS 'rewardAppliedNoRemainigRewards'
      },
    },
  };
};
