import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import {
  AddToSaveForLaterItemRequest,
  SaveForLaterResponse,
} from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const saveForLater = (configuration: ApiClientConfiguration) => (
  data: AddToSaveForLaterItemRequest
): Promise<AxiosResponse<SaveForLaterResponse>> => {
  const body = {
    cartId: data.cartId,
    productId: data.productId,
    recipeId: data.recipeId,
    qty: data.qty,
    productImageURL: data.productImageURL,
    masterId: data.masterId,
    type: 'product',
    pdpUrl: data.pdpUrl,
    defaultProductView: '',
    available: '',
    saveForLater: {
      itemId: data.saveForLater.itemId,
      consumerID: data.saveForLater.consumerID,
      saveForLaterId: data.saveForLater.saveForLaterId,
    },
  };
  return axios.post(
    urlBuilder(endpoints.get(configuration).cart.saveForLater, {
      config: configuration,
      mapping: { id: data.cartId },
    }),
    body,
    {
      headers: headerBuilder(configuration),
    }
  );
};
