import { ClearSession, CMCollection, CmsSite } from '@vf/api-contract';
import { ROUTES } from '../../utils/routes';
import { useI18n } from '../../useI18n';
import { ComposableContext } from '../types';
import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
  getDynamicField,
  getImageObject,
  getTeaserStyle,
  getTranslation,
  removeRootSegmentFromUrlSegment,
  getTitle,
  generateLinkFromTeaserTargets,
  getStateManagementData,
} from './utils';
// TODO: Remove it after GLOBAL15-56318
export const checkoutHeaderDynamic = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const logo = getLogoUrl(data, siteConfiguration, cmsBaseUri, context);
  const clearSession = getClearSession(
    data,
    siteConfiguration,
    cmsBaseUri,
    context
  );

  return {
    component: 'layout/CheckoutHeaderDynamic',
    props: {
      title: '',
      // TODO: Adapt the linkTag prop to the response and possible type approach
      linkTag: 'a',
      logo: logo,
      translations: {
        shipStepLabel: getTranslation(data, 'shipStepLabel', '$Shipping$'),
        pickupStepLabel: getTranslation(data, 'pickupStepLabel', '$Pickup$'),
        shipAndPickupStepLabel: getTranslation(
          data,
          'shipAndPickupStepLabel',
          '$Ship and pickup$'
        ),
        payAndReviewLabel: getTranslation(
          data,
          'payAndReviewLabel',
          '$Review and pay$'
        ),
      },
      shippingPage: removeRootSegmentFromUrlSegment(
        extractProperty(
          extractLocalSettingsExpandedLinks(data, 'shippingPage'),
          '[0].urlSegment'
        ),
        siteConfiguration,
        context
      ),
      billingPage: removeRootSegmentFromUrlSegment(
        extractProperty(
          extractLocalSettingsExpandedLinks(data, 'billingPage'),
          '[0].urlSegment'
        ),
        siteConfiguration,
        context
      ),
      clearSession,
    },
  };
};

const getClearSession = (
  data: CMCollection,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  context
): ClearSession | undefined => {
  const clearSession = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'clearSession'),
    '[0]'
  );
  const isClearSessionEligible =
    clearSession &&
    context.instance.$root.$stateManagementChecker(
      getStateManagementData(clearSession)
    );

  return isClearSessionEligible
    ? {
        title: getTitle(clearSession),
        role: getDynamicField(clearSession, 'role'),
        icon: getDynamicField(clearSession, 'icon'),
        titleColor: getTeaserStyle(clearSession, 'teaserTitleColor'),
        titleBackgroundColor: getTeaserStyle(
          clearSession,
          'teaserTitleBackgroundColor'
        ),
        titleClassModifiers: getTeaserStyle(
          clearSession,
          'teaserTitleClassModifiers'
        ),
        link: generateLinkFromTeaserTargets(
          clearSession.teaserTargets,
          context,
          siteConfiguration,
          clearSession.urlParams,
          cmsBaseUri
        ),
      }
    : undefined;
};

const getLogoUrl = (
  data: CMCollection,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  context
) => {
  const { localePath } = useI18n(context.instance);

  const logo = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'siteLogo'),
    '[0]'
  );

  const pictureObject = extractProperty(logo, 'pictures[0]', null);
  if (!pictureObject) {
    console.error('Checkout header logo error: no site logo component found');
    return {};
  }

  return {
    id: logo.id,
    link: localePath(ROUTES.HOME()),
    url: getImageObject(pictureObject, siteConfiguration, null, cmsBaseUri),
    ariaLabel: logo.name,
  };
};
