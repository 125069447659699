export const getConfigValues = (data: CmsPlpGrid) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'plp-grid-properties'
    );

    return config.values;
  } catch (e) {
    console.error('PLP Grid component error: no config found.');
  }

  return {
    productsPerPage: 25,
    'next-products': 6,
    showAddToFavourites: true,
    showNoInventoryProducts: false,
    'show-trailing-zeros': false,
    'ratings-by-count-or-score': 'count',
  };
};

export const getTitle = (data: CmsPlpGrid) => {
  try {
    const title = data?.localSettings?.dynamicFields?.showProductGridTitle
      ? data?.localSettingsExpanded[0]?.links[0]?.commerceRef?.title
      : data?.localSettings?.dynamicFields?.customProductGridTitle;
    return title ?? '';
  } catch (e) {
    console.error('Component data title error: title not found');
  }
};

export const getPlpSettingsValues = (data: CmsPlpGrid) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'plpSettings'
    );

    return config.values;
  } catch (e) {
    console.error('PLP Grid component error: no plp settings config found.');
  }

  return {
    'show-see-more': true,
    'show-color-swatches': true,
    'show-regular-price': true,
    'show-wishlist-icon': true,
    'show-product-badge': true,
    'show-product-name': true,
    'show-eyebrow': true,
    'show-product-images': true,
    'show-sale-price': true,
    'show-quick-shop': true,
    'show-ratings-icon': true,
    'show-product-desc': true,
  };
};

type localSettingsExp = [
  {
    links: [
      {
        commerceRef: {
          id: string;
          title: string;
        };
      }
    ];
  }
];

type localSettingCustom = {
  dynamicFields: {
    showProductGridTitle: boolean;
    customProductGridTitle: string;
  };
};

export type CmsPlpGrid = {
  id: string;
  type: 'CMPlaceholder';
  name: string;
  title: string;
  componentConfig: (CmsPlpSettings | CmsPlpGridConfig)[];
  viewtype: 'page-title';
  localSettingsExpanded: localSettingsExp;
  localSettings: localSettingCustom;
};

export type CmsPlpGridConfig = {
  name: 'plp-grid-properties';
  values: {
    'initial-load-products': number;
    productsPerPage: number;
    'next-products': number;
    showAddToFavourites: boolean;
    showNoInventoryProducts: boolean;
    'show-trailing-zeros': boolean;
    'ratings-by-count-or-score': string;
  };
};

export type CmsPlpSettings = {
  name: 'plpSettings';
  values: {
    'show-see-more': boolean;
    'show-color-swatches': boolean;
    'show-regular-price': boolean;
    'show-wishlist-icon': boolean;
    'show-product-badge': boolean;
    'show-product-name': boolean;
    'show-eyebrow': boolean;
    'show-product-images': boolean;
    'show-sale-price': boolean;
    'show-quick-shop': boolean;
    'show-ratings-icon': boolean;
    'show-product-desc': boolean;
  };
};
