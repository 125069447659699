








































































import { CountryCallingCode, PhoneNumber } from 'libphonenumber-js/types';

import {
  computed,
  defineComponent,
  nextTick,
  PropType,
  ref,
  watch,
  inject,
} from '@vue/composition-api';

import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

import { masker, tokens } from 'ke-the-mask';
import { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { getOnlyDigits } from '@vf/shared/src/utils/helpers';

type CountryKey = `${CountryCode}-${CountryCallingCode}`;

export default defineComponent({
  name: 'VfPhoneInput',
  props: {
    value: String,
    mask: String,
    /**
     * Native input required attribute
     */
    required: Boolean,
    /**
     * Native input disabled attribute
     */
    disabled: Boolean,
    /**
     * Validate value of form input
     */
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: String,
    label: String,
    countryCodeLabel: String,
    defaultCountryCode: {
      type: String as PropType<CountryCode>,
      default: 'US',
    },
    countries: {
      type: Array as PropType<PhoneInputCountry[]>,
    },
    showCountrySelector: Boolean,
    notification: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'country-changed', 'countryPrefix-changed'],
  setup(props, { emit }) {
    const countryKey = ref<CountryKey>();

    const countrySelectorRef = ref(null);

    const nationalNumber = ref<string>(null);
    const nationalNumberInputRef = ref(null);

    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const getCountryKey = (country: PhoneInputCountry): CountryKey =>
      country?.countryCode && country?.countryCallingCode
        ? `${country.countryCode}-${country.countryCallingCode}`
        : null;

    const splitCountryKey = (
      value: CountryKey
    ): [CountryCode, CountryCallingCode] => {
      if (!value) return null;
      const [countryCode, countryCallingCode] = value.split('-') as [
        CountryCode,
        CountryCallingCode
      ];
      return [countryCode, countryCallingCode];
    };

    const selectedHandler = (value) => {
      setCountryKey(value);
      calculateValueAndEmit(
        countryKey.value,
        nationalNumber.value,
        props.value
      );
      const inputs = nationalNumberInputRef.value?.$el?.getElementsByTagName(
        'input'
      );
      inputs?.[0]?.focus();
    };

    const inputHandler = (value) => {
      const input = nationalNumberInputRef.value.$el?.querySelector('input');
      const position = input?.selectionStart;
      nationalNumber.value = maskValue(value, props.mask);
      if (input && position && value.length !== position) {
        nextTick(() => {
          input.setSelectionRange(position, position);
        });
      }
      calculateValueAndEmit(
        countryKey.value,
        nationalNumber.value,
        props.value
      );
    };

    const calculateValueAndEmit = (
      country: CountryKey,
      phone: string,
      oldValue: string
    ) => {
      const value = getValue(country, phone);
      if (value !== oldValue) emit('input', value);
    };

    const setCountryKey = async (value: CountryKey) => {
      if (countryKey.value === value) return;
      countryKey.value = value;
      const [countryCode] = splitCountryKey(value);
      emit('country-changed', countryCode);
      await nextTick();
    };

    const getValue = (country: CountryKey, phone: string) => {
      if (!country || !phone) return null;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, countryCallingCode] = splitCountryKey(country);
      const countryPrefix = getCountryPrefix(countryCallingCode) ?? '';
      const sanitizedNationalNumber = getOnlyDigits(phone) ?? '';
      emit('countryPrefix-changed', countryPrefix);
      return `${countryPrefix}${sanitizedNationalNumber}`;
    };

    const getCountryPrefix = (code: CountryCallingCode): string =>
      code ? `+${code}` : null;

    const selectedCountryCallingCode = computed(() => {
      if (!countryKey.value) return null;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, countryCallingCode] = splitCountryKey(countryKey.value);
      return getCountryPrefix(countryCallingCode);
    });

    const getCountryLabel = (name: string, code: CountryCallingCode): string =>
      name && code ? `${name} ${getCountryPrefix(code)}` : null;

    const maskValue = (value: string, mask: string): string => {
      if (!value || !mask) return value;
      return masker(value, mask, true, tokens);
    };

    const getValueWithoutCountryCallingCode = (
      value: string,
      countryCallingCode: CountryCallingCode
    ): string => {
      if (!value) return null;
      return countryCallingCode
        ? value.replace(`+${countryCallingCode}`, '')
        : value;
    };

    const countrySelectorDisabled = computed(
      () => props.disabled || !props.countries || props.countries?.length < 2
    );

    const findCountry = (
      countries: PhoneInputCountry[],
      countryCode: CountryCode = null,
      countryCallingCode: CountryCallingCode = null
    ): PhoneInputCountry => {
      if (!(countries && countries.length)) return null;
      let foundCountry = countries.find(
        (value) =>
          value.countryCode === countryCode &&
          value.countryCallingCode === countryCallingCode
      );
      if (foundCountry) return foundCountry; // exact match
      foundCountry = countries.find(
        (value) =>
          value.countryCode === countryCode ||
          value.countryCallingCode === countryCallingCode
      );
      return foundCountry ? foundCountry : null; // inexact or no match
    };

    const parseProps = async () => {
      let parsedPhoneNumber: PhoneNumber;
      try {
        parsedPhoneNumber = parsePhoneNumberFromString(props.value);
      } catch {
        //  props.value cannot be parsed, swallow error and continue
      }
      let searchedCountry = findCountry(
        props.countries,
        parsedPhoneNumber?.country,
        parsedPhoneNumber?.countryCallingCode
      );
      if (!searchedCountry) {
        if (countryKey.value) {
          const [countryCode, countryCallingCode] = splitCountryKey(
            countryKey.value
          );
          searchedCountry = findCountry(
            props.countries,
            countryCode,
            countryCallingCode
          );
        } else {
          searchedCountry = findCountry(
            props.countries,
            props.defaultCountryCode
          );
        }
      }
      const parsedCountryKey = getCountryKey(searchedCountry);
      if (parsedCountryKey) await setCountryKey(parsedCountryKey);
      const finalNationalNumber =
        parsedPhoneNumber?.nationalNumber ??
        getValueWithoutCountryCallingCode(
          props.value,
          searchedCountry?.countryCallingCode
        );
      nationalNumber.value = maskValue(finalNationalNumber, props.mask);
      calculateValueAndEmit(
        countryKey.value,
        nationalNumber.value,
        props.value
      );
    };

    watch(
      () => [props.countries, props.mask, props.value],
      ([newCountries, newMask]) => {
        if ((newCountries && newCountries.length) || props.mask !== newMask)
          parseProps();
      },
      { immediate: true }
    );

    return {
      countrySelectorDisabled,
      countrySelectorRef,
      countryKey,
      nationalNumberInputRef,
      nationalNumber,
      selectedCountryCallingCode,
      selectedHandler,
      inputHandler,
      getCountryKey,
      getCountryPrefix,
      getCountryLabel,
      isCoreRedesignEnabled,
    };
  },
});
