import { CMPlaceholder } from '@vf/api-contract';
import { getCustomerNotification } from './utils';

export const notifications = (data: CMPlaceholder) => {
  return {
    component: 'layout/Notifications',
    props: {
      translations: {
        customerNotifications: {
          OrderShippingMethodDowngraded: getCustomerNotification(
            data,
            'OrderShippingMethodDowngraded',
            '$Sorry, but we updated your order based on the following reason(s): <br/>Expedited shipping is no longer available. Your order will ship via standard shipping. <br/>Order totals have been updated to reflect the change.$'
          ),
        },
      },
    },
  };
};
