export const getEmptyAddress = () => ({
  addressId: '',
  shippingId: '',
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  addressLine1: '',
  addressLine2: '',
  postalCode: '',
  city: '',
  province: '',
  phone: '',
  stateCode: '',
  subscriptions: {
    newsletterConsent: false,
  },
  altFirstName: '',
  altLastName: '',
  altEmail: '',
});

export const sanitizeFormData = (item) => {
  const data = getEmptyAddress();
  Object.keys(item).forEach((key) => {
    if (item[key]) data[key] = item[key];
  });
  const fieldsToExcludeIfEmpty = ['altFirstName', 'altLastName'];
  for (const field of fieldsToExcludeIfEmpty) {
    if (data[field] === '') {
      delete data[field];
    }
  }
  return data;
};
