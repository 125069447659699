import { stateManagementDecorator } from './stateManagementDecorator';
import { componentCmsIdDecorator } from './componentCmsIdDecorator';
import { segmentsDecorator } from './segmentsDecorator';
import { dataIdDecorator } from './dataIdDecorator';
import { dataTrackingDecorator } from './dataTrackingDecorator';
import { DecoratorMappingName } from '../../../../types';

const handlers: Record<
  DecoratorMappingName,
  (mappedData: any, data: any) => any
> = {
  StateManagement: stateManagementDecorator,
  SegmentsDecorator: segmentsDecorator,
  ComponentCmsIdDecorator: componentCmsIdDecorator,
  DataIdDecorator: dataIdDecorator,
  DataTrackingDecorator: dataTrackingDecorator,
};

export default handlers;
