import {
  extractProperty,
  getImageObject,
  generateLinkFromTarget,
  getVideoObject,
} from '../utils';
import { ComposableContext } from '../../types';
import { CmsSite } from '@vf/api-contract';

export const geminiHero = (
  data: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const img1 = extractProperty(data, 'mainImage[0]');
  const lockupImg = extractProperty(data, 'svgImage[0]');
  const lockupImgMobile = extractProperty(data, 'svgImageMobile[0]');
  const addImageOne = extractProperty(data, 'addImage[0]');
  const mobileImage = extractProperty(data, 'mobileImage[0]');

  const ctaUrl1 = extractProperty(data, 'ctaUrl1[0]');
  const ctaUrl2 = extractProperty(data, 'ctaUrl2[0]');
  const ctaUrl3 = extractProperty(data, 'ctaUrl3[0]');
  const legalUrl = extractProperty(data, 'legalTarget[0]');
  const termsTarget = extractProperty(data, 'termsTarget[0]');

  const getMobileImage = () => {
    if (mobileImage) {
      return getImageObject(mobileImage, siteConfiguration, null, cmsBaseUri)
        ?.small;
    }

    return img1
      ? getImageObject(img1, siteConfiguration, null, cmsBaseUri)?.small
      : '';
  };

  const addImageOneObject = () =>
    addImageOne
      ? getImageObject(addImageOne, siteConfiguration, null, cmsBaseUri)?.small
      : '';

  const getMainImage = () =>
    img1
      ? getImageObject(img1, siteConfiguration, null, cmsBaseUri)?.small
      : '';

  const captionType = extractProperty(data, 'captionType')
    ? `caption-${extractProperty(data, 'captionType')}`
    : '';

  const getMainVideo = () => {
    const mainVideoObject = getVideoObject(data.mainVideo || [], cmsBaseUri);

    if (mainVideoObject) {
      return mainVideoObject.sources[0].src;
    }

    return '';
  };
  const mainVideoHeight = extractProperty(data, 'mainVideo[0].height', '');
  const mainVideoWidth = extractProperty(data, 'mainVideo[0].width', '');

  const getMobileVideo = () => {
    const mobileVideoObject = getVideoObject(
      data.mobileVideo || [],
      cmsBaseUri
    );

    if (mobileVideoObject) {
      return mobileVideoObject.sources[0].src;
    }

    return '';
  };

  const mobileVideoHeight = extractProperty(data, 'mobileVideo[0].height', '');
  const mobileVideoWidth = extractProperty(data, 'mobileVideo[0].width', '');

  return {
    component: 'gemini/Hero',
    props: {
      pattern: `${extractProperty(data, 'cssPattern')} ${captionType}`,
      img1Desktop: getMainImage(),
      img1DesktopH: extractProperty(
        data,
        'mainImage[0].height',
        ''
      )?.toString(),
      img1DesktopW: extractProperty(data, 'mainImage[0].width', '')?.toString(),
      img1Tablet: getMainImage(),
      img1TabletH: extractProperty(data, 'mainImage[0].height', '')?.toString(),
      img1TabletW: extractProperty(data, 'mainImage[0].width', '')?.toString(),
      img1Mobile: getMobileImage(),
      img1MobileH: extractProperty(data, 'mainImage[0].height', '')?.toString(),
      img1MobileW: extractProperty(data, 'mainImage[0].height', '')?.toString(),
      img1Alt: extractProperty(data, 'mainImage[0].alt', ''),
      caption: extractProperty(data, 'caption'),
      grid: extractProperty(data, 'gridPosition'),
      eyebrow: extractProperty(data, 'eyebrow'),
      heading1: extractProperty(data, 'heading1', ''),
      heading2: extractProperty(data, 'heading2', ''),
      copy1: extractProperty(data, 'copy1'),
      copy2: extractProperty(data, 'copy2'),
      copy3: extractProperty(data, 'copy3'),
      legalCopy: extractProperty(data, 'legalCopy'),
      legalUrl: legalUrl
        ? generateLinkFromTarget(
            legalUrl,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      legalTarget: extractProperty(data, 'legalTarget[0].type', ''),
      cta1Url: ctaUrl1
        ? generateLinkFromTarget(
            ctaUrl1,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      cta1Target: extractProperty(data, 'ctaUrl1[0].type', ''),
      cta2Url: ctaUrl2
        ? generateLinkFromTarget(
            ctaUrl2,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      cta2Target: extractProperty(data, 'ctaUrl2[0].type', ''),
      cta3Url: ctaUrl3
        ? generateLinkFromTarget(
            ctaUrl3,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      cta3Target: extractProperty(data, 'ctaUrl3[0].type', ''),
      cta1Copy: extractProperty(data, 'ctaCopy1', ''),
      cta2Copy: extractProperty(data, 'ctaCopy2'),
      cta3Copy: extractProperty(data, 'ctaCopy3'),
      cta1Type: extractProperty(data, 'ctaType1', ''),
      cta2Type: extractProperty(data, 'ctaType2'),
      cta3Type: extractProperty(data, 'ctaType3'),
      terms: extractProperty(data, 'terms'),
      termsCopy: extractProperty(data, 'termsCopy'),
      termsUrl: termsTarget
        ? generateLinkFromTarget(
            termsTarget,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      termsTarget: extractProperty(data, 'termsTarget[0].type', ''),
      addDesktop: addImageOneObject(),
      addDesktopH: extractProperty(data, 'addImage[0].height', '')?.toString(),
      addDesktopW: extractProperty(data, 'addImage[0].width', '')?.toString(),
      addTablet: addImageOneObject(),
      addTabletH: extractProperty(data, 'addImage[0].height', '')?.toString(),
      addTabletW: extractProperty(data, 'addImage[0].width', '')?.toString(),
      addMobile: addImageOneObject(),
      addMobileH: extractProperty(data, 'addImage[0].height', '')?.toString(),
      addMobileW: extractProperty(data, 'addImage[0].height', '')?.toString(),
      addAlt: extractProperty(data, 'addImage[0].alt', ''),
      lockupDesktop: lockupImg
        ? getImageObject(lockupImg, siteConfiguration, null, cmsBaseUri)?.small
        : '',
      lockupAlt: extractProperty(data, 'svgImage[0].alt', ''),
      lockupDesktopH: extractProperty(
        data,
        'svgImage[0].height',
        ''
      )?.toString(),
      lockupDesktopW: extractProperty(
        data,
        'svgImage[0].width',
        ''
      )?.toString(),
      lockupMobile: lockupImgMobile
        ? getImageObject(lockupImgMobile, siteConfiguration, null, cmsBaseUri)
            ?.small
        : '',
      lockupMobileH: extractProperty(
        data,
        'svgImageMobile[0].height',
        ''
      )?.toString(),
      lockupMobileW: extractProperty(
        data,
        'svgImageMobile[0].width',
        ''
      )?.toString(),
      vidDesktop: getMainVideo(),
      vidDesktopH: mainVideoHeight?.toString(),
      vidDesktopW: mainVideoWidth?.toString(),
      vidMobile: getMobileVideo(),
      vidMobileH: mobileVideoHeight?.toString(),
      vidMobileW: mobileVideoWidth?.toString(),
    },
  };
};
