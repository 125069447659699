/**
 * Keep deleted item in the same index in the list by doing an insertion using splice().
 *
 * @param list cart items.
 * @param listDeleted list of deleted products with flag isDeleted = true.
 */
export const getProductListWithDeletedItems = (list, listDeleted) => {
  const cartItems = [...list];
  listDeleted.forEach((item) => {
    const index = item.index;
    cartItems.splice(index, 0, item.product);
  });
  return cartItems;
};
