import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
  getTranslation,
} from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getConfigValues, getSrpSettingsValues } from './utils/srpConfig';
import { getQuickShopTranslations } from './utils/getQuickShopTranslations';
import useSearch from '../../useSearch';

export const searchResultsProducts = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { setProductsPerPage, setInitialLoadProductsAmount } = useSearch(
    context.instance
  );
  const config = getConfigValues(data);
  const srpConfig = getSrpSettingsValues(data);
  const quickShopLink = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'plp-quick-shop'),
    '[0]'
  );

  const signInToBuy = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

  const loyaltyEnrollment = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'loyalty-enrollment-modal'),
    '[0]'
  )?.id;

  setInitialLoadProductsAmount(config['initial-load-products'] as number);
  setProductsPerPage(config['next-products'] as number);
  return {
    component: 'search/SearchGrid',
    props: {
      initialProductsLoad: config['initial-load-products'],
      productsPerPage: config['next-products'],
      showAddToFavourites: config['showAddToFavourites'],
      showSeeMore: srpConfig['show-see-more'],
      showColorSwatches: srpConfig['show-color-swatches'],
      showRegularPrice: srpConfig['show-regular-price'],
      showSalePrice: srpConfig['show-sale-price'],
      showWishListIcon: srpConfig['show-wishlist-icon'],
      showProductBadge: srpConfig['show-product-badge'],
      showProductName: srpConfig['show-product-name'],
      showEyebrow: srpConfig['show-eyebrow'],
      showQuickShop: srpConfig['show-quick-shop'],
      showRatingsIcon: srpConfig['show-ratings-icon'],
      showProductDescription: srpConfig['show-product-desc'],
      showProductImages: srpConfig['show-product-images'],
      showBadges: srpConfig['show-badges'],
      showAddToCart: srpConfig['showAddToCart'],
      modals: {
        signInToBuy,
        loyaltyEnrollment,
      },
      quickShopLink: generateLinkFromTarget(
        quickShopLink,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      translations: {
        addedToWishlist: getTranslation(
          data,
          'addedToWishlist',
          '$Product {{product}} has been added to wishlist$'
        ),
        removedFromWishlist: getTranslation(
          data,
          'removedFromWishlist',
          '$Product {{product}} has been removed from wishlist$'
        ),
        itemsCountPlural: getTranslation(data, 'itemsCountPlural', '$Items$'),
        itemsCountSingular: getTranslation(data, '$Item$'),
        specialPriceLabel: getTranslation(data, 'specialPriceLabel', '$NOW$'),
        offerPriceLabel: getTranslation(data, 'offerPriceLabel', '$WAS$'),
        seeMoreColorsLink: getTranslation(
          data,
          'seeMoreColorsLink',
          '$See more colors$'
        ),
        quickShopButtonText: getTranslation(
          data,
          'quickShopButtonText',
          '$Quick Shop$'
        ),
        addToCartLabel: getTranslation(
          data,
          'addToCartButtonText',
          '$Add to Cart$'
        ),
        addWishListAriaLabel: getTranslation(
          data,
          'addWishListAriaLabel',
          '$Add to wishlist$'
        ),
        removeWishListAriaLabel: getTranslation(
          data,
          'removeWishListAriaLabel',
          '$Remove from wishlist$'
        ),
        notifyMeLabel: getTranslation(data, 'notifyMeLabel', '$Notify me$'),
        noLongerAvailableLabel: getTranslation(
          data,
          'noLongerAvailableLabel',
          '$No longer available$'
        ),
        viewPrevious: getTranslation(data, 'viewPrevious', '$VIEW PREVIOUS$'),
        ...getQuickShopTranslations(data),
      },
    },
  };
};
