import { parse, format } from 'date-fns';

const API_DATE_FORMAT = 'yyyy-MM-dd';

export const getDatePlaceholderFor = (locale: string): string => {
  const dateFormat = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
  const parts = new Intl.DateTimeFormat(locale).formatToParts(dateFormat);
  let placeholder = '';
  parts.forEach(({ type, value }) => {
    if (type === 'day') placeholder += 'd'.repeat(value.length);
    else if (type === 'month') placeholder += 'M'.repeat(value.length);
    else if (type === 'year') placeholder += 'y'.repeat(value.length);
    else placeholder += value;
  });
  return placeholder;
};

export const getDateMaskFor = (locale: string): string => {
  const dateFormat = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
  const parts = new Intl.DateTimeFormat(locale).formatToParts(dateFormat);
  let mask = '';
  parts.forEach(({ type, value }) => {
    if (['day', 'month', 'year'].includes(type)) {
      mask += '#'.repeat(value.length);
    } else {
      mask += value;
    }
  });
  return mask;
};

export const getParsedDate = (value: string, locale: string = 'en-US'): Date =>
  parse(value, getDatePlaceholderFor(locale), new Date());

export const getParsedApiDate = (value: string): Date =>
  parse(value, API_DATE_FORMAT, new Date());

export const translateFromApiDateFormat = (
  value: string,
  locale: string = 'en-US'
): string =>
  value && format(getParsedApiDate(value), getDatePlaceholderFor(locale));

export const translateToApiDateFormat = (
  value: string,
  locale: string = 'en-US'
): string =>
  value ? format(getParsedDate(value, locale), API_DATE_FORMAT) : undefined;
