export default {
  getItem: (item: string): string => {
    if (typeof window === 'undefined') return null;
    return sessionStorage.getItem(item);
  },
  setItem: (item: string, value: string): void => {
    if (typeof window === 'undefined') return null;
    return sessionStorage.setItem(item, value);
  },
  removeItem: (item: string): void => {
    if (typeof window === 'undefined') return null;
    return sessionStorage.removeItem(item);
  },
};
