import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { validateResponse } from './helpers';
import {
  PhotorankApi,
  PhotorankApiStream,
  PhotorankApiStreamOfMedia,
  PhotorankApiMediaItemProduct,
} from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

/**
 * looking for stream id with tagKey for that customerId
 */
export const getSNStreamId = (configuration: ApiClientConfiguration) => async (
  apiKey: string,
  customerId: string,
  tagKey: string
): Promise<string> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getStreamByKey.replace('{api_key}', apiKey)
    .replace('{customer_id}', customerId)
    .replace('{tag_key}', tagKey);
  const response = await axios.get<PhotorankApi<PhotorankApiStream>>(url);
  validateResponse(response.data);
  return response.data.data.id;
};

/**
 * get stream retrieve by media
 */
export const getSNStreamByMedia = (
  configuration: ApiClientConfiguration
) => async (
  apiKey: string,
  mediaId: string
): Promise<PhotorankApiMediaItemProduct[]> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getStreamByMedia.replace('{api_key}', apiKey)
    .replace('{media_id}', mediaId);
  const response = await axios.get<PhotorankApi<PhotorankApiStreamOfMedia>>(
    url
  );
  validateResponse(response.data);
  return response.data.data._embedded.stream;
};
