import { CMTeaser, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getButtonStyles,
  getMediaWithOverlay,
  getVideoObject,
  getVideoResponsiveMedia,
} from './utils';

import { ComposableContext } from '../types';
import merge from '../../utils/merge';
import type { ViewportSize } from '@vf/composables';

const videoExists = (data, viewport) => {
  return (
    extractProperty(data, `responsiveMedia.${viewport}[0].type`, '') ===
    'CMVideo'
  );
};

export const videoWithOverlay = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
): any => {
  const mediaWithOverlay = getMediaWithOverlay(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );

  // By default, the button should be `primary`, not `text` as for other components
  const firstButtonStyleOverride = getButtonStyles(
    data,
    '0',
    'style',
    'primary'
  );

  const imageLink = mediaWithOverlay?.props?.category?.imageLink;

  // If overlay is enabled for a given breakpoint and overlay is not in the bottom,
  // display video as GIF (autoplay, loop, muted, no controls)
  const getOverlaySettings = (viewport: ViewportSize) => {
    const showOverlay = mediaWithOverlay?.props?.showOverlay[viewport];
    const showBelowArticle =
      mediaWithOverlay?.props?.showBelowArticle[viewport];

    const gifVideoOptions = {
      preload: 'metadata',
      autoplay: true,
      hideControls: true,
      muted: true,
      showPlayButton: false,
      loop: true,
      showSubtitles: false,
    };

    if (imageLink) {
      return { options: gifVideoOptions };
    }

    return showOverlay && !showBelowArticle ? { options: gifVideoOptions } : {};
  };

  return merge(mediaWithOverlay, {
    component: 'shared/VideoWithOverlay',
    props: {
      firstButtonStyles: {
        buttonStyleSmall: firstButtonStyleOverride,
      },
      category: {
        imageLink,
        video: {
          small: {
            ...getVideoObject(data.videos, cmsBaseUri),
            ...getOverlaySettings('small'),
          },
          medium: videoExists(data, 'medium')
            ? {
                ...getVideoResponsiveMedia(data, cmsBaseUri, 'medium'),
                ...getOverlaySettings('medium'),
              }
            : null,
          large: videoExists(data, 'large')
            ? {
                ...getVideoResponsiveMedia(data, cmsBaseUri, 'large'),
                ...getOverlaySettings('large'),
              }
            : null,
        },
      },
      openInNewTab: data.openInNewTab,
      openInNewModal: data.openInNewModal,
    },
  });
};
