































import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from '@vue/composition-api';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'VfFlyoutContainer',
  directives: {
    ClickOutside,
  },
  setup(props, { root }) {
    const isVisible = ref(false);

    const dropdownCoordinates = reactive({
      top: 0,
      right: 0,
    });

    const recalculateDropdownPosition = (event: Event) => {
      const element = event.target as HTMLElement;
      if (!element) {
        return;
      }
      const elementToCalculatePosition =
        element.closest('A, BUTTON') || element;
      const { top, right } =
        elementToCalculatePosition?.getBoundingClientRect() ||
        element.getBoundingClientRect();
      dropdownCoordinates.top =
        top + window.scrollY + elementToCalculatePosition.clientHeight;
      dropdownCoordinates.right = right + window.scrollX;
    };

    const show = (event?: Event) => {
      if (event) {
        recalculateDropdownPosition(event);
      }
      isVisible.value = true;
    };
    const hide = () => {
      isVisible.value = false;
    };

    const computedStyles = computed(() => {
      return {
        top: dropdownCoordinates.top ? `${dropdownCoordinates.top}px` : 0,
        left: dropdownCoordinates.right ? `${dropdownCoordinates.right}px` : 0,
        position: mode.value === 'dropdown' ? 'absolute' : 'static',
      };
    });

    const mode = computed(() => {
      return root.$viewport.breakpoint.smUp ? 'dropdown' : 'modal';
    });

    onMounted(() => {
      window.addEventListener('resize', hide);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', hide);
    });
    return {
      mode,
      show,
      hide,
      isVisible,
      computedStyles,
    };
  },
};
