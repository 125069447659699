import { getTranslation } from './utils/extractProperty';
import { CMPlaceholder } from '@vf/api-contract';
export const favoritesListGridShare = (data: CMPlaceholder) => {
  return {
    component: 'favorites/Favorites',
    props: {
      translations: {
        title: getTranslation(data, 'favoritesTitle', '$Your Favorites$'),
        editSettingsLabel: getTranslation(
          data,
          'editSettingsLabel',
          '$Edit Favorites Settings$'
        ),
        cancelLabel: getTranslation(data, 'cancelLabel', '$Cancel$'),
        gridViewLabel: getTranslation(data, 'gridViewLabel', '$Grid View$'),
        listViewLabel: getTranslation(data, 'listViewLabel', '$List View$'),
        addedToCartText: getTranslation(
          data,
          'addedToCartText',
          '$Item has been moved to your Shopping Cart$'
        ),
        backLabel: getTranslation(data, 'backLabel', '$Back to Favorites$'),
        itemsCount: getTranslation(data, 'itemsCount', '$item(s)$'),
        printButtonText: getTranslation(data, 'printButtonText', '$Print$'),
        emptyFavoritesListText: getTranslation(
          data,
          'emptyFavoritesListText',
          '$Your Favorites List is Empty.$'
        ),
        privacyDescription: getTranslation(
          data,
          'privacyDescription',
          '$Public favorites list URLs can be shared online and directly with friends and family, and may be featured by The North Face.$'
        ),
        removedItemText: getTranslation(
          data,
          'removedItemText',
          '$Item has been removed from your Favorites List$'
        ),
        radioButtonLabels: getTranslation(data, 'radio', {
          privateLabel: '$PRIVATE, only I can see$',
          sharedLabel: '$SHAREABLE, anyone with a link can see it$',
        }),
        privacyTitle: getTranslation(
          data,
          'privacyTitle',
          '$Privacy Settings$'
        ),
        addToCartLabel: getTranslation(data, 'addToCartLabel', '$Add To Cart$'),
        favoritesListText: getTranslation(data, 'favoritesListText', {
          shared: '$Your favorites list is currently shared.$',
          private: '$Your favorites list is currently private.$',
        }),
        removeLabel: getTranslation(data, 'removeLabel', '$Remove$'),
        notifications: getTranslation(data, 'notifications', {
          shared: '$Your Favorites List has been shared.$',
          private: '$Your Favorites List has been made private.$',
          copied: '$Your link has been copied to the clipboard$',
        }),
        shareUrl: getTranslation(data, 'shareUrl', {
          title: '$Favorites URL$',
          copyLabel: '$Copy URL$',
        }),
        viewDetails: getTranslation(data, 'viewDetails', '$View details$'),
      },
    },
  };
};
