import {
  getDynamicField,
  getComponentConfigByName,
  extractProperty,
} from './utils';
import { CMPlaceholder } from '@vf/api-contract';

const getCredantials = (data: CMPlaceholder) => {
  const componentConfig = getComponentConfigByName(data, 'olapic', null);
  if (!componentConfig) return null;
  return {
    id: extractProperty(data, 'customId', ''),
    newWidgetId: {
      pdp: extractProperty(componentConfig, 'pdp', ''),
      customs: extractProperty(componentConfig, 'customs', ''),
      homepage: extractProperty(componentConfig, 'homepage', ''),
    },
    newWidgetTestingId: extractProperty(
      componentConfig,
      'newWidgetTestingId',
      ''
    ),
    locale: extractProperty(componentConfig, 'locale', ''),
    buildPath: extractProperty(componentConfig, 'buildPath', ''),
    apiKey: extractProperty(componentConfig, 'apiKey', ''),
    instance: null,
  };
};

export const olapicImageGrid = (data: CMPlaceholder) => {
  const credentials = getCredantials(data);

  return {
    component: 'shared/Olapic',
    props: {
      instance: getDynamicField(data, 'instance', ''),
      credentials,
    },
  };
};
