import { EventPropsHandler } from '.';
import { ProductRecImpressionsEvent } from '../../types/gtm';
import { useI18n } from '../../useI18n';

export const productRecImpressionsHandler: EventPropsHandler<
  ProductRecImpressionsEvent,
  {
    products: any[];
    list: string;
    experience: any;
    list_type: string;
  }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);

  const products = eventProps.overrideAttributes.products.map((product) => ({
    brand: vmInstance
      .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
      .toUpperCase(),
    id: product.id,
    colorCode: product.colorCode,
    list: eventProps.overrideAttributes.list,
    name: product.title,
    onSale: product.price.original != product.price.current,
    originalPrice: product.price.original,
    price: product.price.current,
    saleDiscountAmount: product.price.original - product.price.current,
    position: product.index + 1,
  }));

  const impressionReporting =
    eventProps.overrideAttributes.experience.impressionReporting?.[0] || {};

  return {
    event: 'productRecImpressions',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Impressions',
    eventLabel: undefined,
    nonInteractive: true,
    ecommerce: {
      currencyCode: defaultCurrency.value,
      impressions: products,
    },
    customMetrics: {},
    customVariables: {
      list_type: eventProps.overrideAttributes.list_type,
      recs_set: eventProps.overrideAttributes.experience.component,
      experience_id: impressionReporting.experience_id,
      experience_label: impressionReporting.experience_label,
      experience_name: impressionReporting.experience_name,
      recs_set_id: eventProps.overrideAttributes.experience.actionId,
    },
    _clear: true,
  };
};
