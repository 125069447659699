









































































import type { PropType } from 'vue';
import { defineComponent, inject } from '@vue/composition-api';
import useGtm from '@vf/composables/src/useGtm';
import useRootInstance from '@/shared/useRootInstance';
import type { FooterStoreLocator } from '@vf/api-contract';

export default defineComponent({
  name: 'FooterStoreLocator',
  props: {
    /** Array of store locator section data */
    storeLocator: {
      type: [Object, Array] as PropType<
        FooterStoreLocator | FooterStoreLocator[]
      >,
      required: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const handleClick = () => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Navigation',
          eventAction: 'Global Footer Nav',
          eventLabel: 'Store Locator',
        },
      });
    };

    return {
      handleClick,
      isCoreRedesignEnabled,
    };
  },
});
