import { extractProperty } from './extractProperty';

export const extractLocalSettingsExpandedLinks = (
  item: any,
  target: string,
  path: string = 'dynamicFields'
) => {
  const localSettingsExpanded = extractProperty(
    item,
    'localSettingsExpanded',
    []
  );

  if (!Array.isArray(localSettingsExpanded)) {
    return [];
  }

  const dataSourcesObjects = localSettingsExpanded.find(
    (setting) => setting.path === `${path}.${target}`
  );

  if (!dataSourcesObjects || !Array.isArray(dataSourcesObjects.links)) {
    return [];
  }

  return dataSourcesObjects.links;
};
