export const extractComponentConfigLink = (
  item: any,
  valueExpanded: string
) => {
  const name = item?.name || '';
  const valuesExpanded = item?.valuesExpanded?.find(
    (value) => value?.path === `config.${name}.${valueExpanded}`
  );
  return valuesExpanded?.links[0] || {};
};
