import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';
import { SetShippingMethodObject } from '../../api-types';
import { Cart } from '../../types';

export const setShippingMethod = (
  configuration: ApiClientConfiguration
) => async (
  data: SetShippingMethodObject,
  { query = '' } = {}
): Promise<AxiosResponse<Cart>> => {
  let baseUrl = endpoints.get(configuration).cart.setShippingMethod;
  if (query) {
    baseUrl += `?${query}`;
  }

  const response = await axios.post<Cart>(
    urlBuilder(baseUrl, { config: configuration }),
    {
      cartId: data.cartId,
      code: data.code,
      shippingId: data.shippingId,
      shopperAppliedSM: Boolean(data.shopperAppliedSM),
    },
    {
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
