import {
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
  getDynamicField,
  getImageObject,
  getMediaSeoAttributes,
} from './utils';
import { CMPicture, CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

const getImage = (
  image: CMPicture,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  if (image) {
    return {
      src: getImageObject(image, siteConfiguration, null, cmsBaseUri),
      seo: getMediaSeoAttributes(image),
    };
  }

  return null;
};

export const loyaltyOrderRegister = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const guestImage = extractLocalSettingsExpandedLinks(data, 'bannerGuest')[0];
  const legacyImage = extractLocalSettingsExpandedLinks(
    data,
    'bannerNotEnrolled'
  )[0];
  const bannerMember = extractLocalSettingsExpandedLinks(
    data,
    'bannerMember'
  )[0];

  return {
    component: 'loyalty/LoyaltyOrderRegister',
    props: {
      guest: {
        title: getDynamicField(data, 'titleGuest', ''),
        subtitle: getDynamicField(data, 'subtitleGuest', ''),
        banner: getImage(guestImage, siteConfiguration, cmsBaseUri),
        ctaText: getDynamicField(data, 'ctaTextGuest', ''),
        ctaTarget: generateLinkFromTarget(
          extractLocalSettingsExpandedLinks(data, 'ctaTargetGuest')[0],
          context,
          siteConfiguration,
          cmsBaseUri
        ),
      },
      legacy: {
        title: getDynamicField(data, 'titleNotEnrolled', ''),
        subtitle: getDynamicField(data, 'subtitleNotEnrolled', ''),
        banner: getImage(legacyImage, siteConfiguration, cmsBaseUri),
        ctaText: getDynamicField(data, 'ctaTextNotEnrolled', ''),
        ctaTarget: generateLinkFromTarget(
          extractLocalSettingsExpandedLinks(data, 'ctaTargetNotEnrolled')[0],
          context,
          siteConfiguration,
          cmsBaseUri
        ),
      },
      member: {
        title: getDynamicField(data, 'titleMember', ''),
        subtitle: getDynamicField(data, 'subtitleMember', ''),
        banner: getImage(bannerMember, siteConfiguration, cmsBaseUri),
        ctaText: getDynamicField(data, 'ctaTextMember', ''),
        ptsExplanation: getDynamicField(data, 'ptsExplainedMember', ''),
        ctaTarget: generateLinkFromTarget(
          extractLocalSettingsExpandedLinks(data, 'ctaTargetMember')[0],
          context,
          siteConfiguration,
          cmsBaseUri
        ),
      },
    },
  };
};
