import axios from '../../../src/helpers/axios';
import { endpoints } from '../../../src/config';
import { headerBuilder, urlBuilder } from '../../helpers';
import { ApiClientConfiguration } from '../../configuration';

export const signInToStore = (configuration: ApiClientConfiguration) => async (
  data
): Promise<any> => {
  const response = await axios.post(
    urlBuilder(endpoints.get(configuration).agent.employeeSignIn, {
      config: configuration,
    }),
    data,
    {
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
