import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { SetSmsSubscriptionPayload } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const setSubscription = (configuration: ApiClientConfiguration) => (
  payload: SetSmsSubscriptionPayload,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<any>> => {
  const url = urlBuilder(
    endpoints.get(configuration).customer.setSubscription,
    {
      config: configuration,
    }
  );

  return axios.put(url, payload, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
