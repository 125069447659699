import { CMPlaceholder } from '@vf/api-contract';
import {
  getDynamicField,
  getTranslation,
  getComponentConfigByName,
} from './utils';

export const pdpColorChoser = (data: CMPlaceholder) => {
  const config = getConfigValues(data);
  const otherConfigs = getComponentConfigByName(data, 'other', {
    'show-divider-line': true,
  });
  return {
    component: 'pdp/PdpProductColors',
    props: {
      showNumeration: getDynamicField(data, 'showNumeration', true),
      showFullLabel: getDynamicField(data, 'showFullLabel', false),
      showSolidColors: getDynamicField(data, 'showSolidColors', false),
      hideColorName: getDynamicField(data, 'hideColorName', false),
      saveVariationOnColorChange: getDynamicField(
        data,
        'saveVariationOnColorChange',
        false
      ),
      splitColorsWatchForDiscount: getDynamicField(
        data,
        'splitColorsWatchForDiscount',
        false
      ),
      historyReplaceOnColorChange: getDynamicField(
        data,
        'historyReplaceOnColorChange',
        false
      ),
      showAlwaysAllColors: getDynamicField(
        data,
        'showColorsAlwaysExpanded',
        false
      ),
      showDivider: otherConfigs['show-divider-line']
        ? otherConfigs['show-divider-line']
        : true,
      translations: {
        chooseColor: getTranslation(data, 'chooseColor', '$chooseColor$'),
        colorLabel: getTranslation(data, 'colorLabel', '$colorLabel$'),
        viewAll: getTranslation(data, 'viewAll', '$viewAll$'),
        showLess: getTranslation(data, 'showLess', '$showLess$'),
        color: getTranslation(data, 'color', '$color$'),
      },
      itemsCountSmall: config['num-colors-sm'],
      itemsCountMedium: config['num-colors-md'],
      itemsCountLarge: config['num-colors-lg'],
      showAllColors: config['show-all-colors'] === 'all-colors',
      displayAs: config['display-as'],
    },
  };
};

const getConfigValues = (data: CMPlaceholder) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'pdp-color-choser-properties'
    );

    return config.values;
  } catch (e) {
    console.error('PDP Color Chooser component error: no config found.');
  }

  return {
    'display-as': 'pictures',
    'show-all-colors': 'view-more-show-less-button',
    'num-colors-sm': 5,
    'num-colors-md': 8,
    'num-colors-lg': 11,
  };
};
