import {
  getColumnSize,
  getColumnVisibility,
  getColumnOrder,
  getBottomMarginFlags,
  getColumnMargins,
  getColumnPaddings,
} from './gridColumn';
import {
  CmsGridOverImage,
  ResponsiveMedia,
  CmsSite,
  CMPicture,
  CMPictureCropType,
} from '@vf/api-contract';
import {
  getImageObject,
  getMediaSeoAttributes,
  getVideoObject,
} from '../../../mappings/utils';

import { standardComponentDataParser } from './standard';
import { parseDataBasedOnType } from '../parseDataBasedOnType';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../../../types';
import {
  getStateManagementData,
  getSegmentsData,
} from '../../../mappings/utils/extractProperty';
import { getVideoResponsiveMedia } from '../../../mappings/utils/getVideoResponsiveMedia';

// TODO: check if component is still used anywhere
export const gridOverImage = (
  component: CmsGridOverImage,
  componentParser: ReturnType<typeof standardComponentDataParser>,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  context: ComposableContext,
  pageTypeName: PageTypeName,
  isNested = false
) => {
  const errors = [];
  const data = {
    component: 'grid/GridOverImage',
    _cms_id: `grid-over-image-container-${component.id}`,
    attrs: {
      'data-id': component.id,
    },
    props: {
      ...getMediaLayout(component, siteConfiguration, cmsBaseUri),
      backgroundPosition: component.gridColumn.background?.position,
      backgroundSize: component.gridColumn.background?.size,
      backgroundRepeat: component.gridColumn.background?.repeat,
      mediaLayout: component.gridColumn.background?.mediaLayout,
      _states: getStateManagementData(component),
      _segments: getSegmentsData(component),
    },
    children: [
      {
        component: 'grid/Row',
        _cms_id: `grid-over-image-row-${component.id}`,
        props: {
          fullHeight: true,
          nested: isNested,
          noMargin: component.gridColumn.fullWidth,
          contentPosition: component.gridColumn.contentPosition,
        },

        children: component.gridColumn.columns
          .map((column) => {
            return {
              component: 'grid/Column',
              _cms_id: `grid-over-image-column-${component.id}`,
              props: {
                ...getColumnSize(column),
                ...getColumnVisibility(column),
                ...getColumnOrder(column),
                ...getBottomMarginFlags(column),
                ...getColumnMargins(column),
                ...getColumnPaddings(column),
                verticalAlign: column.verticalAlign,
                contentPosition: column.contentPosition,
                sticky: column.sticky,
                nested: isNested,
                _states: getStateManagementData(column),
                _segments: getSegmentsData(column),
              },
              children: column.components.map((component) => {
                const parsedData = parseDataBasedOnType(
                  component,
                  componentParser,
                  siteConfiguration,
                  cmsBaseUri,
                  requirements,
                  context,
                  pageTypeName,
                  true
                );

                errors.push(...parsedData.errors);

                return parsedData.data;
              }),
            };
          })
          .filter((item) => item !== null),
      },
    ],
  };

  return {
    data,
    errors,
  };
};

export const getMediaLayout = (
  component: CmsGridOverImage,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const getImageData = (
    imageData: CMPicture,
    responsiveMedia?: ResponsiveMedia | null,
    index?: number
  ) => ({
    ...getImageObject(
      imageData,
      siteConfiguration,
      CMPictureCropType.LANDSCAPE_RATIO_8x3,
      cmsBaseUri,
      responsiveMedia,
      index
    ),
    seo: getMediaSeoAttributes(imageData),
  });

  switch (component.gridColumn.background?.mediaLayout) {
    case 'background':
      if (component.pictures?.length || component.videos?.length) {
        return {
          images: [
            getImageData(component.pictures[0], component.responsiveMedia),
          ],
          video: {
            small: getVideoObject(component.videos, cmsBaseUri),
            medium:
              component.responsiveMedia?.medium[0]?.type === 'CMVideo'
                ? getVideoResponsiveMedia(component, cmsBaseUri, 'medium')
                : null,
            large:
              component.responsiveMedia?.large[0]?.type === 'CMVideo'
                ? getVideoResponsiveMedia(component, cmsBaseUri, 'large')
                : null,
          },
        };
      }
      break;
    case 'background_and_image':
      if (component.pictures?.length > 1) {
        const imagesObject = component.pictures.map((picture, index) => {
          return getImageData(picture, component.responsiveMedia, index);
        });
        return {
          images: imagesObject,
        };
      }
      if (component.pictures?.length && component.videos?.length) {
        return {
          images: [
            getImageData(component.pictures[0], component.responsiveMedia),
          ],
          video: {
            small: getVideoObject(component.videos, cmsBaseUri),
            medium: null,
            large: null,
          },
        };
      }
      break;
    default:
      break;
  }
  return {};
};
