var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"vf-price-range"},[(_vm.showBrackets)?_c('VfText',{class:[
      _vm.showTextInColor
        ? 'vf-price-range--special-price-colored'
        : 'vf-price-range--regular-price' ],attrs:{"tag":"span","content":'('}}):_vm._e(),_vm._v(" "),_c('VfText',{class:[
      _vm.priceRange.isLowestPriceDiscounted
        ? 'vf-price-range--special-price-colored'
        : 'vf-price-range--regular-price' ],attrs:{"tag":"span","content":_vm.lowestPrice}}),_vm._v(" "),_c('span',{staticClass:"vf-price-range__separator",class:[
      _vm.showTextInColor
        ? 'vf-price-range--special-price-colored'
        : 'vf-price-range--regular-price' ]},[_vm._v(_vm._s('-'))]),_vm._v(" "),_c('VfText',{class:[
      _vm.priceRange.isHighestPriceDiscounted
        ? 'vf-price-range--special-price-colored'
        : 'vf-price-range--regular-price' ],attrs:{"tag":"span","content":_vm.highestPrice}}),_vm._v(" "),(_vm.showBrackets)?_c('VfText',{class:[
      _vm.showTextInColor
        ? 'vf-price-range--special-price-colored'
        : 'vf-price-range--regular-price' ],attrs:{"tag":"span","content":')'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }