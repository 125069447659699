import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { Cart } from '../../types';
import type { CartPickupAddressRequest } from '@vf/api-contract';

export const setPickupAddress = (configuration: ApiClientConfiguration) => (
  cartId: string,
  formData: CartPickupAddressRequest[]
): Promise<AxiosResponse<Cart>> => {
  const baseUrl = endpoints.get(configuration).checkout.setShippingAddress;

  const url: string = urlBuilder(baseUrl, {
    config: configuration,
    mapping: { basketId: cartId },
  });

  return axios.post(url, formData.length === 1 ? formData[0] : formData, {
    headers: headerBuilder(configuration),
  });
};
