import { RequestHeader } from '../../types';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { LoyaltyTokenResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

type LoyaltyTokenRequest = {
  referralCode?: string;
};

export const getLoyaltyToken = (
  configuration: ApiClientConfiguration
) => async (
  data: LoyaltyTokenRequest | null,
  { usid = null }: RequestHeader = {}
): Promise<LoyaltyTokenResponse> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.loyaltyToken,
    {
      config: configuration,
    }
  );

  const response = await axios.post<LoyaltyTokenResponse>(url, data, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });

  return response.data;
};
