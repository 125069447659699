import { States, Segment, StateConfigNames } from '@vf/api-contract';
import get from '../../../utils/get';
import merge from '../../../utils/merge';

export const extractProperty = (
  item: any,
  path: string,
  defaultValue?: any
): any => {
  return get(item, path, defaultValue !== undefined ? defaultValue : null);
};

export const mergeProperty = (source: any, overrides: any) => {
  return merge(source, overrides);
};

export const getTranslation = (item: any, name: string, defaultValue?: any) =>
  extractProperty(item, `resourceBundle.common[${name}]`, defaultValue);

export const getDynamicField = (item: any, name: string, defaultValue?: any) =>
  extractProperty(item, `localSettings.dynamicFields[${name}]`, defaultValue);

export const getFlashMessage = (item, name: string, defaultValue?: string) =>
  extractProperty(
    item,
    `resourceBundle.common.flashMessages[${name}]`,
    defaultValue
  );

export const getDataNavigationValue = (item: any): string | null => {
  /** Try to extract 'commerceRef.id' property */
  const commerceRefId = extractProperty(item, 'target[0].commerceRef.id');
  if (commerceRefId) return commerceRefId;

  /** Try to extract 'urlSegment' property */
  const urlSegment = extractProperty(item, 'target[0].urlSegment');
  if (urlSegment) return urlSegment;

  const teaserTargetUrlSegment = extractProperty(
    item,
    'teaserTargets[0].target.urlSegment'
  );
  if (teaserTargetUrlSegment) return teaserTargetUrlSegment;

  /** Try to extract 'segment' property */
  const segment = extractProperty(item, 'target[0].segment');
  if (segment) return segment;

  const teaserTargetSegment = extractProperty(
    item,
    'teaserTargets[0].target.segment'
  );
  if (teaserTargetSegment) return teaserTargetSegment;

  /** Try to extract 'title' property */
  const title = extractProperty(item, 'target[0].title');
  if (title) return title;

  const teaserTitle = extractProperty(
    item,
    'teaserTargets[0].target.teaserTitle'
  );
  if (teaserTitle) return teaserTitle;

  /** Return regular title or null if nothing has been found */
  return item.title || null;
};

export const getStateManagementData = (item: any): States[] | null => {
  try {
    const config = extractProperty(item, 'componentConfig');
    const states = config.reduce((acc, conf) => {
      if (
        conf.name === StateConfigNames.STATES &&
        typeof conf.values.state !== 'undefined'
      ) {
        return acc.concat(conf.values.state);
      }
      if (conf.name === StateConfigNames.STATES_GROUP) {
        return acc.concat(conf);
      }
      return acc;
    }, []);

    if (!states.length) {
      return null;
    }

    return states;
  } catch (e) {
    return null;
  }
};

export const getSegmentsData = (item: any): Segment[] | null => {
  try {
    const config = extractProperty(item, 'componentConfig');
    const segments = config
      .filter((conf) => conf.name === 'segment')
      .map((item) => item.values);

    if (!segments.length) {
      return null;
    }

    return segments;
  } catch (e) {
    return null;
  }
};

export const getComponentConfigByName = (
  item: any,
  name: string,
  defaultValue: any,
  expanded?: boolean
): any => {
  const configs = extractProperty(item, 'componentConfig');
  return expanded
    ? configs?.find((conf) => conf.name === name) || defaultValue
    : configs?.find((conf) => conf.name === name)?.values || defaultValue;
};

export const getCustomerNotification = (
  item,
  name: string,
  defaultValue?: string
) =>
  extractProperty(
    item,
    `resourceBundle.common.customerNotifications[${name}]`,
    defaultValue
  );
