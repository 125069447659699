import { ComponentInstance } from '../../types';
import { parseUrlFactory } from './';
import useCms from '../../useCms';
import { CmsQueries, CmsTypes } from '@vf/api-client';
import { useCmsRefStore } from '../../store/cmsRef';
import { storeToRefs } from 'pinia';

export const prefetchUrlFactory = (
  instance: ComponentInstance,
  contextKey?: string
): ((url: string) => Promise<void>) => {
  if (!instance.$config.prefetch?.enabled) {
    return async (url: string): Promise<void> => {
      if (instance.$config.prefetch?.logging) {
        instance.$log.warn(`Skipping prefetch of ${url}`);
      }
    };
  }
  const cmsRefStore = useCmsRefStore();

  const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);

  const { apiClientRef, setup: cmsSetup } = useCms(instance, contextKey);
  const parseUrl = parseUrlFactory(instance);

  const getCmsQueryUrl = (query: CmsTypes.CmsQuery) => {
    const relUrl = Object.entries(query.params).reduce(
      (url, [param, value]) => url.replace(`:${param}`, value as string),
      query.uri
    );
    const absUrl = `${apiClientRef.value.settings.baseUri}${relUrl}`;

    return absUrl;
  };

  return async (url: string): Promise<void> => {
    const urls = [];
    const meta = parseUrl(url);

    if (typeof document === 'undefined') {
      return;
    }

    if (!apiClientRef.value || !cmsSiteConfiguration.value) {
      await cmsSetup({ forcePreview: false });
    }

    if (meta.resourceType) {
      switch (meta.resourceType) {
        case 'category':
          urls.push(
            getCmsQueryUrl(
              CmsQueries.getCategoryPageContentQuery(
                cmsSiteConfiguration.value.id,
                meta.resourceId
              )
            )
          );
          urls.push(
            `${instance.$getApiClientConfiguration.endpoints.catalog.getCatalog}/${meta.resourceId}`
          );
          break;

        case 'product':
          urls.push(
            getCmsQueryUrl(
              CmsQueries.getProductPageContentQuery(
                cmsSiteConfiguration.value.id,
                meta.resourceId
              )
            )
          );
          urls.push(
            `${instance.$getApiClientConfiguration.endpoints.product.getProductDetails}/${meta.resourceId}`
          );
          break;
      }
    } else {
      urls.push(
        getCmsQueryUrl(
          CmsQueries.getPageContentByPathQuery(
            url,
            cmsSiteConfiguration.value.root.segment
          )
        )
      );
    }

    urls.forEach((urlToPrefetch) => {
      const existingLink = document.querySelector(
        `link[href="${urlToPrefetch}"]`
      );

      if (!existingLink) {
        const link = document.createElement('link');

        link.rel = 'prefetch';
        link.href = urlToPrefetch;
        link.as = 'fetch';

        document.head.appendChild(link);
      }
    });
  };
};
