import { ref, Ref, computed } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { useCart } from '../useCart';
import { ROUTES } from '../utils/routes';

// add other keys here
type ValidationKey =
  | 'ADDRESS_FORM'
  | 'CYBER_SOURCE_FORM'
  | 'GIFT_CARD_FORM'
  | 'PICKUP_FORM'
  | 'ADD_GIFT_OPTION'
  | string;

export type VuelidateObject = {
  $touch: () => void;
  $invalid: boolean;
  $error: boolean;
  [others: string]: any; // TODO add other Vuelidate types
};

type ValidationStorage = {
  [key in ValidationKey]?: VuelidateObject;
};

type UseValidationStorage = {
  validationRef: Ref<ValidationStorage>;
};

export const useValidation = (
  instance: ComponentInstance,
  validationKey: ValidationKey
) => {
  const storage: ComposablesStorage<UseValidationStorage> = initStorage<UseValidationStorage>(
    instance,
    'useValidation'
  );

  const validationRef =
    storage.get('validationRef') ?? storage.save('validationRef', ref({}));

  const setValidation = (vuelidateObject: VuelidateObject) => {
    validationRef.value = {
      ...validationRef.value,
      [validationKey]: vuelidateObject,
    };
  };

  const $v = computed(() => validationRef.value[validationKey]);

  const getCustomsInBasketValidationHandler = (validatorFn) => {
    const skipValidationFn = () => true;
    if (!instance.$route.path.endsWith(ROUTES.CHECKOUT_SHIPPING()))
      return skipValidationFn;
    const { isSomeItemCustom } = useCart(instance);
    return isSomeItemCustom.value ? validatorFn : skipValidationFn;
  };

  // collection of conditional macros' handler mappings
  const validationPredicateMap = {
    basketHasCustoms: getCustomsInBasketValidationHandler,
  };

  const getValidationHandler = (ruleConfig) => {
    if (ruleConfig.predicateName) {
      return validationPredicateMap[ruleConfig.predicateName](ruleConfig.fn);
    }
    return ruleConfig.fn;
  };

  const validateFields = (fields: string[]) =>
    !fields
      .map((fieldName) => {
        const field = $v.value[fieldName];
        field.$touch();
        return field.$invalid;
      })
      .some(Boolean);

  return {
    $v,
    setValidation,
    getValidationHandler,
    validateFields,
  };
};
