import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
  getTranslation,
} from './utils';
import { CmsSite, CMPlaceholder } from '@vf/api-contract';

export const pdpCompleteTheLook = (
  data: CMPlaceholder,
  context,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const quickShopLink = generateLinkFromTarget(
    extractProperty(
      extractCommonConfigLink(
        siteConfiguration,
        'pdp-complete-the-look-quick-shop'
      ),
      '[0]'
    ),
    context,
    siteConfiguration,
    cmsBaseUri
  );
  return {
    component: 'pdp/ProductCrossSell',
    props: {
      quickShopLink,
      translations: {
        nextCta: getTranslation(data, 'nextCta', '$next$'),
        prevCta: getTranslation(data, 'prevCta', '$prev$'),
        title: getTranslation(data, 'title', '$Complete the Look$'),
      },
    },
  };
};
