import { Article, apiClientFactory } from '@vf/api-client';
import { computed, Ref } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage, Taxonomy } from '../types';
import initStorage from '../utils/storage';
import { ssrRef } from '@nuxtjs/composition-api';
import { articleMapper } from './utils/articleMapper';
import useCms from '../useCms';
import { useRequestTracker } from '../useRequestTracker';
import {
  prepareFilters,
  prepareLimit,
  prepareOffset,
  preparePrimaryFilters,
  prepareSearch,
  prepareSort,
} from '../utils/articlesQuery';
import { ArticlesSearchStorage, GetArticlesResultsSettings } from './types';
import { ComposableContext } from '../useCms/types';
import {
  decorateHtmlValue,
  generateLinkFromTarget,
  getImageObject,
} from '../useCms/mappings/utils';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useCmsRefStore } from '../store/cmsRef';
import { storeToRefs } from 'pinia';

type UseArticlesStorage = {
  articlesRef: Ref<ArticlesSearchStorage>;
};

const useArticle = (instance: ComponentInstance, contextKey?: string) => {
  const cmsRefStore = useCmsRefStore();
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { getArticles: getArticlesAPI } = apiClientFactory(instance);

  const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);

  const storage: ComposablesStorage<UseArticlesStorage> = initStorage<UseArticlesStorage>(
    instance,
    `useArticle-${contextKey}`
  );
  const articlesRef: Ref<ArticlesSearchStorage> =
    storage.get('articlesRef') ??
    storage.save(
      'articlesRef',
      ssrRef(
        {
          loading: true,
          transitioningFromProducts: true,
          articles: [],
          filtersOptions: [],
          settings: null,
          baseMediaUri: '',
          sortingOptions: [],
          selectedFilters: [],
          primaryFilters: [],
          selectedSortingOptionId: '',
          queryString: '',
          noResultQueryString: '',
          pagination: {
            total: 0,
            offset: 0,
            limit: 10, // default value for returned records in the response
          },
          noArticlesFound: false,
          publicationDate: '',
        },
        `articlesRef-${contextKey}`
      )
    );

  const context: ComposableContext = { instance, contextKey };

  const setQueryString = (q: string) => {
    articlesRef.value.queryString = q;
  };
  const setNoResultQueryString = (q: string) => {
    articlesRef.value.noResultQueryString = q;
  };

  const changeSort = (selectedSortingOptionId) => {
    articlesRef.value.selectedSortingOptionId = selectedSortingOptionId;
  };

  const selectFilter = async (filter: Taxonomy) => {
    if (!articlesRef.value.selectedFilters.find((f) => f.id === filter.id)) {
      articlesRef.value.selectedFilters.push(filter);
      await getArticlesResults();
    }
  };

  const selectPrimaryFilters = (filters: Taxonomy[]) => {
    if (!Array.isArray(filters)) {
      return;
    }
    articlesRef.value.primaryFilters = filters;
  };

  const removeFilter = async (filter: Taxonomy) => {
    articlesRef.value.selectedFilters = articlesRef.value.selectedFilters.filter(
      (it) => it.value !== filter.value
    );
    await getArticlesResults();
  };

  const isSelected = (item: Taxonomy) => {
    return articlesRef.value.selectedFilters.some((el) => el.id === item.id);
  };

  const toggleSelection = (item) => {
    isSelected(item) ? removeFilter(item) : selectFilter(item);
  };

  const setLoading = (value = true) => (articlesRef.value.loading = value);

  const setTransitionFromProducts = (value = true) =>
    (articlesRef.value.transitioningFromProducts = value);

  const resetFilters = () => {
    articlesRef.value.selectedFilters = [];
  };

  const getArticlesResults = async (
    data?: GetArticlesResultsSettings,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    setLoading();
    articlesRef.value.articles = await fetchArticles(data, {
      isBackgroundRequest,
    });
    articlesRef.value.noArticlesFound = !articlesRef.value.articles.length;
    setLoading(false);
  };

  const fetchArticles = async (
    data?: GetArticlesResultsSettings,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    if (!cmsSiteConfiguration.value) {
      instance.$log.warn(
        `[@useArticles::fetchArticles] missing cmsSiteConfiguration with contextKey: ${contextKey}`
      );
      return [];
    }

    const { tag } = trackRequest('useArticle-getArticles', isBackgroundRequest);
    articlesRef.value.pagination.offset = data?.offset || 0;

    const defaults = {
      sortBy: articlesRef.value.selectedSortingOptionId,
      filters: articlesRef.value.selectedFilters,
      primaryFilters: articlesRef.value.primaryFilters,
      offset: articlesRef.value.pagination.offset,
      limit: articlesRef.value.pagination.limit,
    };
    const settings = { ...defaults, ...data };
    try {
      const renderer = isClient ? 'CSR' : 'SSR';

      const envProvider =
        instance.$root.$env.NODE_ENV === 'development'
          ? instance.$root.$env
          : process.env;

      const { baseMediaUri, baseUri } = useCms(instance, contextKey);

      const query: string[] = [
        prepareSort(settings.sortBy),
        prepareLimit(settings.limit),
        prepareOffset(settings.offset),
      ];

      if (settings.filters?.length) {
        query.push(prepareFilters(settings.filters));
      }
      if (settings.primaryFilters?.length) {
        query.push(preparePrimaryFilters(settings.primaryFilters));
      }
      if (articlesRef.value.noResultQueryString) {
        query.push(prepareSearch(articlesRef.value.noResultQueryString));
      } else if (articlesRef.value.queryString) {
        query.push(prepareSearch(articlesRef.value.queryString));
      } else {
        const queryString = (instance.$root?.$route?.query.q as string) || '';
        query.push(prepareSearch(queryString));
      }

      const articlesResponse = await getArticlesAPI({
        baseUri: baseUri.value,
        headers:
          instance.$root.$env.NODE_ENV === 'development' || renderer === 'SSR'
            ? {
                [envProvider[`COREMEDIA_ACCESS_HEADER_SSR`]]:
                  envProvider[`COREMEDIA_ACCESS_TOKEN_SSR`],
                ['vf-server-trust-id']:
                  envProvider['SECRET_SERVER_CDN_TRUST_HEADER'],
              }
            : {},
        siteId: cmsSiteConfiguration.value.id,
        query: query.join('&'),
      });
      const fetchedArticles = articleMapper(
        articlesResponse.searchpages.pages,
        cmsSiteConfiguration.value,
        baseMediaUri.value,
        context
      );
      articlesRef.value.filtersOptions =
        articlesResponse.searchpages.taxonomies;

      articlesRef.value.pagination.total =
        articlesResponse.searchpages.numFound;
      return fetchedArticles || [];
    } catch (e) {
      resetNoArticlesFound();
      instance.$log.error(
        `[@useArticles::fetchArticles] Failed to fetch articles with following error: <${e}> at page <${instance.$root.$route.path}>`
      );
      return [];
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const loadMoreArticles = async (count: number) => {
    if (
      articlesRef.value.articles.length < articlesRef.value.pagination.total
    ) {
      articlesRef.value.pagination.offset = articlesRef.value.articles.length;
      const moreArticles = await fetchArticles({
        limit: count,
        offset: articlesRef.value.pagination.offset,
      });
      articlesRef.value.articles.push(...moreArticles);
    }
  };

  const setNoArticlesFound = (value: boolean) => {
    articlesRef.value.noArticlesFound = value;
  };

  const resetNoArticlesFound = () => {
    articlesRef.value.noArticlesFound = true;
    articlesRef.value.pagination.total = 0;
    articlesRef.value.articles = [];
  };

  const fetchArticleById = async (id: string): Promise<Article> => {
    const { apiClientRef, setup: cmsSetup, baseUri } = useCms(
      instance,
      contextKey
    );
    if (!apiClientRef.value || !cmsSiteConfiguration.value) {
      await cmsSetup({ forcePreview: false });
    }
    // get page content
    const pageDataResponse = await apiClientRef.value.getPageContent(id);

    if (pageDataResponse?.pagecontent?.title) {
      const {
        title,
        teaserText,
        teaserRichTextObject,
        pictures,
        urlSegment,
        subjectTaxonomy,
      } = pageDataResponse.pagecontent;

      const image = pictures?.[0]
        ? getImageObject(
            pictures[0],
            cmsSiteConfiguration.value,
            null,
            baseUri.value,
            null
          )
        : {
            small: {},
            medium: {},
            large: {},
            width: null,
            height: null,
          };
      return {
        title,
        text: teaserText,
        richText: decorateHtmlValue(
          teaserRichTextObject,
          baseUri.value,
          context,
          cmsSiteConfiguration.value
        ),
        link: generateLinkFromTarget(
          {
            urlSegment: urlSegment,
            type: 'CMChannel',
          },
          context,
          cmsSiteConfiguration.value,
          baseUri.value
        ),
        dataNavigation: null,
        readMoreText: '',
        showReadMore: false,
        image,
        subjectTaxonomy,
        publicationDate: '',
      };
    }
    return null;
  };

  const setSortingOptions = (options) =>
    (articlesRef.value.sortingOptions = options);

  return {
    getArticlesResults,
    removeFilter,
    resetFilters,
    fetchArticleById,
    filtersOptions: computed(() => articlesRef.value.filtersOptions),
    selectedFilters: computed(() => articlesRef.value.selectedFilters),
    primaryFilters: computed(() => articlesRef.value.primaryFilters),
    loading: computed(() => articlesRef.value.loading),
    transitioningFromProducts: computed(
      () => articlesRef.value.transitioningFromProducts
    ),
    articles: computed(() => articlesRef.value.articles),
    sortingOptions: computed(() => articlesRef.value.sortingOptions),
    setQueryString,
    setNoResultQueryString,
    selectFilter,
    selectPrimaryFilters,
    isSelected,
    toggleSelection,
    setLoading,
    setTransitionFromProducts,
    changeSort,
    loadMoreArticles,
    setNoArticlesFound,
    resetNoArticlesFound,
    noArticlesFound: computed(() => articlesRef.value.noArticlesFound),
    noResultQueryString: computed(() => articlesRef.value.noResultQueryString),
    pagination: computed(() => ({
      total: articlesRef.value.pagination.total,
      offset: articlesRef.value.pagination.offset,
      limit: articlesRef.value.pagination.limit,
    })),
    selectedSortingOptionId: computed(
      () => articlesRef.value.selectedSortingOptionId
    ),
    setSortingOptions,
  };
};

export default useArticle;
