import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { getApplePayComponentData } from './utils/getApplePayComponentData';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../types';

export const applePayCta = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  pageTypeName: PageTypeName
) => {
  const applePayComponentData = getApplePayComponentData(
    data,
    cmsBaseUri,
    context,
    siteConfiguration
  );

  /** Directly placed Apple Pay CTA may live only on PDP page */
  if (pageTypeName !== PageTypeName.PDP) {
    return null;
  }

  return {
    component: 'pdp/ApplePayPdp',
    props: {
      translations: applePayComponentData.translations,
    },
  };
};
