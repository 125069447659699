import {
  extractProperty,
  getComponentConfigByName,
  getDynamicField,
} from './utils';
import { CMTeaser } from '@vf/api-contract';

export const pdpLink = (data: CMTeaser) => {
  const config = getComponentConfigByName(data, 'pdp-attribute-expression', {});
  return {
    component: 'pdp/ProductLink',
    props: {
      config: config,
      linkLabel: extractProperty(data, 'teaserTitle', '$link label$'),
      icon: getDynamicField(data, 'icon', ''),
      text: extractProperty(data, 'teaserRichText', '$text$'),
    },
  };
};
