import {
  CmsGridColumn,
  CmsGridColumnColumn,
  CmsMenuGridColumn,
  CmsSite,
} from '@vf/api-contract';
import { standardComponentDataParser } from './standard';
import { parseDataBasedOnType } from '../parseDataBasedOnType';
import {
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../../../types';
import {
  getStateManagementData,
  getComponentConfigByName,
  getSegmentsData,
} from '../../../mappings/utils/extractProperty';

export const gridColumn = (
  component: CmsGridColumn,
  componentParser: ReturnType<typeof standardComponentDataParser>,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements,
  context: ComposableContext,
  pageTypeName: PageTypeName,
  isNested = false
) => {
  const errors = [];
  const config = getComponentConfigByName(component, 'states', {});
  const backgroundColor = component.gridColumn.backgroundColor;

  const data = {
    component: 'grid/Container',
    _cms_id: `grid-container-${component.id}`,
    attrs: {
      'data-id': component.id,
    },
    props: {
      fullHeight: !isNested,
      nested: isNested,
      fullWidth: component.gridColumn.fullWidth,
      backgroundColor:
        backgroundColor && backgroundColor !== 'transparent'
          ? backgroundColor
          : undefined,
      hasPlaceholder: !!config.hideShowEffect,
      _states: getStateManagementData(component),
      _segments: getSegmentsData(component),
    },
    children: [
      {
        component: 'grid/Row',
        _cms_id: `grid-row-${component.id}`,
        props: {
          fullHeight: !isNested,
          nested: isNested,
          contentPosition: component.gridColumn.contentPosition,
        },
        children: component.gridColumn.columns
          .map((column, columnIndex) => {
            return {
              component: 'grid/Column',
              _cms_id: `grid-column-${component.id}-${columnIndex}`,
              props: {
                ...getColumnSize(column),
                ...getColumnVisibility(column),
                ...getColumnOrder(column),
                ...getBottomMarginFlags(column),
                ...getColumnMargins(column),
                ...getColumnPaddings(column),
                verticalAlign: column.verticalAlign,
                contentPosition: column.contentPosition,
                sticky: column.sticky,
                nested: isNested,
                fullWidth: component.gridColumn.fullWidth,
                _states: getStateManagementData(column),
                _segments: getSegmentsData(column),
              },
              children: column.components.map((children) => {
                const parsedData = parseDataBasedOnType(
                  {
                    ...children,
                    _injectedProps: component._injectedProps || {},
                  },
                  componentParser,
                  siteConfiguration,
                  cmsBaseUri,
                  requirements,
                  context,
                  pageTypeName,
                  true
                );

                errors.push(...parsedData.errors);

                return parsedData.data;
              }),
            };
          })
          .filter((item) => item !== null),
      },
    ],
  };

  return {
    data,
    errors,
  };
};

export const getColumnSize = (
  column: CmsGridColumnColumn | CmsMenuGridColumn,
  isInNavigation = false
) => {
  const maxColSize = 12;
  const navigationOrColumnDefaultSize = isInNavigation ? 2 : maxColSize;
  const getValidSize = (size: number) =>
    size > 0 && size <= maxColSize ? size : navigationOrColumnDefaultSize;
  return {
    small: getValidSize(column.smallSize),
    medium: getValidSize(column.mediumSize),
    large: getValidSize(column.largeSize),
  };
};

export const getColumnVisibility = (
  column: CmsGridColumnColumn | CmsMenuGridColumn
) => {
  return {
    hiddenOnSmall: column.smallHidden,
    hiddenOnMedium: column.mediumHidden,
    hiddenOnLarge: column.largeHidden,
  };
};

export const getColumnOrder = (
  column: CmsGridColumnColumn | CmsMenuGridColumn
) => {
  return {
    orderSmall: column.smallPosition,
    orderMedium: column.mediumPosition,
    orderLarge: column.largePosition,
  };
};

export const getBottomMarginFlags = (
  column: CmsGridColumnColumn | CmsMenuGridColumn
) => {
  return {
    smallNoBottomMargin: column.smallNoBottomMargin,
    mediumNoBottomMargin: column.mediumNoBottomMargin,
    largeNoBottomMargin: column.largeNoBottomMargin,
  };
};

export const getColumnMargins = (column: CmsGridColumnColumn) => {
  return {
    smallMargins: column.margins?.small || {},
    mediumMargins: column.margins?.medium || {},
    largeMargins: column.margins?.large || {},
  };
};

export const getColumnPaddings = (column: CmsGridColumnColumn) => {
  return {
    smallPaddings: column.paddings?.small || {},
    mediumPaddings: column.paddings?.medium || {},
    largePaddings: column.paddings?.large || {},
  };
};
