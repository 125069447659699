var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMobileMoreSectionVisible)?_c('VfMobileMenuColumn',{staticClass:"vf-mobile-menu-more-section",attrs:{"title":_vm.moreTitle},on:{"change-active":function($event){return _vm.handleActiveMegaMenuColumn({ l1: $event })}},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var title = ref.title;
var changeActive = ref.changeActive;
var active = ref.active;
return [_c('button',{tag:"component",staticClass:"vf-header-mobile-menu__menu-item",attrs:{"tabindex":active ? '-1' : '0'},on:{"click":function($event){return changeActive(title)}}},[_c('VfMenuItem',{staticClass:"vf-mega-menu-column__header",attrs:{"label":_vm.moreTitle,"icon":'chevron_right'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vf-menu-item__label"},[_vm._v("\n              "+_vm._s(_vm.moreTitle)+"\n            ")])]},proxy:true}],null,true)})],1)]}}],null,false,1871578285)},[_vm._v(" "),_c('VfMobileMenu',{attrs:{"visible":true}},_vm._l((_vm.navigation.slice(_vm.maxItems.smallMaxItems)),function(l2,l2Key){return _c('div',{key:l2Key},[(!l2.columns)?_c('VfList',[_c('VfListItem',[_c('ThemeLink',{class:l2.cssClass,attrs:{"to":l2.link,"data-navigation":l2.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"target":l2.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                  link: l2.link,
                  l1: 'More',
                  l2: l2.name,
                  l3: '',
                  isModal: l2.openInNewModal,
                })}}},[_c('VfMenuItem',{attrs:{"label":l2.mobileTitle || l2.title},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("​")]},proxy:true}],null,true)})],1)],1)],1):_c('VfMobileMenuColumn',{attrs:{"title":l2.mobileTitle || l2.title},on:{"change-active":function($event){return _vm.handleActiveMegaMenuColumn({ l1: _vm.moreTitle, l2: $event })}}},[_c('VfList',[(l2.link)?_c('VfListItem',[_c('ThemeLink',{class:l2.cssClass,attrs:{"to":l2.link,"data-navigation":l2.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"target":l2.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                    link: l2.link,
                    l1: 'More',
                    l2: l2.name,
                    l3: '',
                    isModal: l2.openInNewModal,
                  })}}},[_c('VfMenuItem',{attrs:{"label":_vm.getShopAllLabel(l2.mobileTitle, l2.title),"icon":""}})],1)],1):_vm._e(),_vm._v(" "),_vm._l((l2.columns),function(l2MenuItems,l2MenuItemsKey){return _c('div',{key:l2Key + '_' + l2MenuItemsKey},_vm._l((l2MenuItems.level2MenuItems),function(l3,l3Key){return _c('VfListItem',{key:l2MenuItemsKey + '_' + l3Key},[_c('ThemeLink',{class:l3.cssClass,attrs:{"to":l3.link,"data-navigation":l3.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"target":l3.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleMobileNavLinkClick($event, {
                      link: l3.link,
                      l1: 'More',
                      l2: l2.name,
                      l3: l3.name,
                      isModal: l3.openInNewModal,
                    })}}},[_c('VfMenuItem',{attrs:{"label":l3.mobileTitle || l3.title},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_vm._v("​")]},proxy:true}],null,true)})],1)],1)}),1)})],2)],1)],1)}),0)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }