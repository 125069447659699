import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils';

export const signInToStore = (data: CMPlaceholder) => {
  return {
    component: 'buyInStore/SignInToStore',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Employee Sign In$'),
        userId: getTranslation(data, 'userId', '$User ID$'),
        password: getTranslation(data, 'password', '$Password$'),
        store: getTranslation(data, 'store', '$Store ID$'),
        employee: getTranslation(data, 'employee', '$Employee Number$'),
        signInButtonText: getTranslation(data, 'signInButtonText', '$Sign In$'),
        requiredFieldInfo: getTranslation(
          data,
          'requiredFieldInfo',
          '$This field is required, please add your {{fieldName}}.$'
        ),
        requiredFieldsInfo: getTranslation(
          data,
          'requiredFieldsInfo',
          '$All fields required$'
        ),
        wrongCredentialsInfo: getTranslation(
          data,
          'wrongCredentialsInfo',
          '$User ID or Password is not valid$'
        ),
        userIdErrorMessage: getTranslation(
          data,
          'userIdErrorMessage',
          '$User ID should be a valid email.$'
        ),
        networkErrorMessage: getTranslation(
          data,
          'networkErrorMessage',
          '$No Internet Connection has been established.$'
        ),
        generalErrorMessage: getTranslation(
          data,
          'generalErrorMessage',
          '$Internal error, Please try again later.$'
        ),
      },
    },
  };
};
