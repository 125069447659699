import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  getTranslation,
  getDynamicField,
  decorateHtmlValue,
  extractLocalSettingsExpandedRichText,
} from './utils';

export const returnSteps = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const extractMethodRichText = (data, settingName, defaultText) => {
    const richText = extractLocalSettingsExpandedRichText(
      data,
      settingName,
      defaultText
    );
    return richText?.html
      ? decorateHtmlValue(richText, cmsBaseUri, context, siteConfiguration)
      : defaultText;
  };
  return {
    component: 'return/ReturnSteps',
    props: {
      showPrintSlip: getDynamicField(data, 'showPrintSlip', false),
      topPart: extractMethodRichText(data, 'topPart', ''),
      translations: {
        printPackingSlipButtonText: getTranslation(
          data,
          'printPackingSlipButtonText',
          '$Print Packing Slip$'
        ),
        printReturnLabelButtonText: getTranslation(
          data,
          'printReturnLabelButtonText',
          '$Print Return Label$'
        ),
        stepLabelOne: getTranslation(data, 'stepLabelOne', '$Step 1$'),
        stepLabelTwo: getTranslation(data, 'stepLabelTwo', '$Step 2$'),
        summaryConfirmText: getTranslation(
          data,
          'summaryConfirmText',
          '$Confirm Return:$'
        ),
        summaryConfirmSubText: getTranslation(
          data,
          'summaryConfirmSubText',
          '$Please confirm the item(s) for return back to the North Face$'
        ),
        radioOptions: {
          free: getTranslation(
            data,
            'radioOptions.free',
            '$Free - Print a prepaid Label$'
          ),
          paid: getTranslation(
            data,
            'radioOptions.paid',
            '${{price}} - Print a retail label$'
          ),
        },
        radioOptionsTooltips: {
          free: getTranslation(
            data,
            'radioOptionsTooltips.free',
            '$Return shipping is free. You will print a prepaid shipping label for your package.$'
          ),
          paid: getTranslation(
            data,
            'radioOptionsTooltips.paid',
            '$Return shipping is {{price}}. The {{price}} will be deducted from your refund amount.$'
          ),
        },
      },
    },
  };
};
