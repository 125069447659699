export default <T extends unknown[]>(
  callback: (..._: T) => void,
  wait: number
): ((..._: T) => void) => {
  let timer: any;
  return function (...args: T): void {
    clearTimeout(timer);
    timer = setTimeout((): void => {
      callback(...args);
    }, wait);
  };
};
