export const appendColorsPrice = (colorAttributes, variants) => {
  let colors = (colorAttributes || [])?.find(
    (attribute) => attribute.code === 'color'
  );
  colors = colors?.options || [];

  let newColorsList = [...colors];
  const colorsList: string[] = colors.map((c) => c.value);
  colorsList.forEach((colorCode) => {
    const variantsWithColors = variants.filter(
      (v) => v.attributes.color === colorCode && v.price
    );
    if (variantsWithColors) {
      const [price] = variantsWithColors.map((v) => v.price);

      newColorsList = newColorsList.map((color) => {
        if (color.value === colorCode) {
          return { ...color, price };
        }
        return color;
      });
    }
  });

  const newColorAttributesList = colorAttributes.map((attribute) => {
    if (attribute.code === 'color') {
      attribute.options = newColorsList;
    }
    return attribute;
  });

  return newColorAttributesList;
};
