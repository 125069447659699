import { CmsSite, CMTeaser } from '@vf/api-contract';
import { getTeaserStyle } from './utils/getTeaserStyle';
import { ComposableContext } from '../types';
import { getDynamicField } from './utils';
import { decorateHtmlValue } from './utils/decorateHtmlValue';
import usePlaceholders from '../../usePlaceholders';
import {
  getPlaceholdersInString,
  replacePlaceholdersInString,
} from '../../utils/placeholders';

export const richText = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  let content = data.useRichText
    ? decorateHtmlValue(
        data.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      )
    : data.teaserText;

  const {
    placeholders,
    currentPlaceholders,
    currentPlaceholderValues,
  } = usePlaceholders(context.instance);

  const placeholdersFound = getPlaceholdersInString(content, placeholders);

  const includedInCurrentPlaceholders = (placeholder) =>
    currentPlaceholders.value.includes(placeholder);

  const hasPlaceholders = !!placeholdersFound.length;
  const hasAnyCurrentPlaceholder =
    hasPlaceholders && placeholdersFound.some(includedInCurrentPlaceholders);
  const hasAllCurrentPlaceholders =
    hasPlaceholders && placeholdersFound.every(includedInCurrentPlaceholders);

  if (hasAnyCurrentPlaceholder)
    content = replacePlaceholdersInString(
      content,
      currentPlaceholderValues.value
    );

  return {
    component: 'common/RichText',
    props: {
      content: content,
      html: data.useRichText,
      textColor: getTeaserStyle(data, 'teaserTextColor'),
      textCustomClasses: getTeaserStyle(data, 'teaserTextClassModifiers'),
      wrapperClassModifier: getTeaserStyle(data, 'teaserClassModifiers'),
      backgroundColor: getTeaserStyle(data, 'teaserTextBackgroundColor'),
      hyperlinkStyle: getDynamicField(data, 'hyperlinkStyle', 'default'),
      hyperlinkMargin: getDynamicField(data, 'hyperlinkMargin', 'none'),
      showLineSeparator: getDynamicField(data, 'isLineSeparator', false),
    },
    replacePlaceholders: hasPlaceholders && !hasAllCurrentPlaceholders,
  };
};
