import { useI18n } from '../../useI18n';
import { useRouting } from '../../useRouting';
import { ComponentInstance } from '../../types';
import { removeDomain } from '@vf/shared/src/utils/helpers';

const isExternalUrl = (url = ''): boolean => {
  return url.startsWith('http://') || url.startsWith('https://');
};

export const formatUrlName = (name?: string): string =>
  encodeURIComponent(
    (name || 'notitle').trim().toLowerCase().replace(/\s+/g, '-')
  );

export const normalizeUrl = (
  instance: ComponentInstance,
  url: string,
  qs?: Record<string, string>
): string => {
  if (!url) {
    return null;
  }

  const { localePath } = useI18n(instance);
  const { getPathWithoutLocalization } = useRouting(instance);
  const path = isExternalUrl(url) ? removeDomain(url) : url;
  const processedUrl = getPathWithoutLocalization(path);

  const [pathWithoutQuery, existingQueryString] = processedUrl.split('?');
  let appendQueryString = !!existingQueryString;
  const queryParams = new URLSearchParams(existingQueryString);
  Object.keys(qs || {})
    .filter((key) => !!qs[key] && !queryParams.has(key))
    .forEach((key) => {
      queryParams.set(key, qs[key]);
      appendQueryString = true;
    });

  if (instance.$config.queryParametersCaseSensitive) {
    return localePath(
      `/${pathWithoutQuery.toLowerCase()}${
        appendQueryString ? `?${queryParams.toString()}` : ''
      }`
        .replace(/\/{2,}/g, '/')
        .replace(/recipe/i, 'recipe')
    );
  }
  return localePath(
    `/${pathWithoutQuery}${
      appendQueryString ? `?${queryParams.toString()}` : ''
    }`.replace(/\/{2,}/g, '/')
  ).toLowerCase();
};
