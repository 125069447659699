import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const updateAddress = (configuration: ApiClientConfiguration) => (
  addressId: string,
  data,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<{ addressId: string; consumerId: string }>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.updateAddress,
    {
      config: configuration,
      mapping: { addressId },
    }
  );

  return axios.put(
    url,
    { address: data },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
