import { MappingMethod } from '../types';
import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
  getDynamicField,
  getTranslation,
} from './utils';

const DEFAULT_SLIDES_LG = 4;
const DEFAULT_SLIDES_SM = 2;

export const seoRelatedWidget: MappingMethod = (
  data,
  context,
  siteConfiguration,
  cmsBaseUri
) => {
  const quickShop = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'plp-quick-shop'),
    '[0]'
  );

  return {
    component: 'shared/SeoRelatedWidget',
    props: {
      quickShopLink: generateLinkFromTarget(
        quickShop,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      slidesToShow: {
        lg: getDynamicField(
          data,
          'relatedProductsCountLarge',
          DEFAULT_SLIDES_LG
        ),
        md: getDynamicField(
          data,
          'relatedProductsCountMedium',
          DEFAULT_SLIDES_LG
        ),
        sm: getDynamicField(
          data,
          'relatedProductsCountSmall',
          DEFAULT_SLIDES_SM
        ),
      },
      relatedProductsDisplayMode: getDynamicField(
        data,
        'relatedProductsDisplayMode',
        'titleImgDescriptionCta'
      ),
      relatedSearchesDisplayMode: getDynamicField(
        data,
        'relatedSearchesDisplayMode',
        'split'
      ),
      showRelatedProducts: getDynamicField(data, 'showRelatedProducts', true),
      showRelatedSearches: getDynamicField(data, 'showRelatedSearches', true),
      relatedSearchesCount: getDynamicField(data, 'relatedSearchesCount', 6),
      translations: {
        relatedSearches: getTranslation(
          data,
          'relatedSearches',
          '$Related Searches$'
        ),
        relatedProducts: getTranslation(
          data,
          'relatedProducts',
          '$Related Products$'
        ),
        relatedItems: getTranslation(data, 'relatedItems', '$Related Items$'),
        relatedCategories: getTranslation(
          data,
          'relatedCategories',
          '$Related Categories$'
        ),
        showMore: getTranslation(data, 'showMore', '$Show More$'),
        showLess: getTranslation(data, 'showLess', '$Show Less$'),
        moreColors: getTranslation(data, 'moreColors', '$More Colors$'),
        viewProduct: getTranslation(data, 'viewProduct', '$View Product$'),
      },
    },
  };
};
