import {
  CMCollection,
  CMPictureCropType,
  CmsSite,
  CMTeaser,
  CMTeaserTarget,
} from '@vf/api-contract';
import {
  extractProperty,
  getImageObject,
  getTeaserStyle,
  generateLinkFromTeaserTargets,
  generateLinkFromTarget,
  appendParamsToUrl,
} from './utils';
import { ROUTES } from '../../utils/routes';
import { useI18n } from '../../useI18n';
import { ComposableContext } from '../types';

export const shopBySectionList = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'shared/ShopByGrid',
    props: {
      title: data.teaserTitle,
      titleStyles: {
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        titleBackgroundColor: getTeaserStyle(
          data,
          'teaserTitleBackgroundColor'
        ),
        headingLevel: getTeaserStyle(data, 'teaserTitleHeadingLevel'),
        titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
      },
      blocks: data.teasableItems
        .map((teaser: CMTeaser) =>
          getBlock(siteConfiguration, cmsBaseUri, teaser, context)
        )
        .filter((item) => item !== null),
    },
  };
};

const getBlock = (
  siteConfiguration,
  cmsBaseUri,
  blockTeaser: CMTeaser,
  context: ComposableContext
) => {
  const { localePath } = useI18n(context.instance);
  const medium = extractProperty(
    blockTeaser,
    'localSettings.dynamicFields.itemsPerRowMedium',
    2
  );
  const large = extractProperty(
    blockTeaser,
    'localSettings.dynamicFields.itemsPerRowMedium',
    3
  );

  const getLinksToDisplay = () => {
    const teaserTarget = extractProperty(
      blockTeaser,
      'teaserTargets[0].target'
    );

    const useInheritedCategoryLinks =
      extractProperty(
        blockTeaser,
        `localSettings.dynamicFields['inherit-children']`
      ) === true;

    if (!teaserTarget.category) {
      const generatedLink = generateLinkFromTarget(
        teaserTarget,
        context,
        siteConfiguration
      );
      return [
        {
          label: extractProperty(teaserTarget, 'name', ''),
          link: blockTeaser.urlParams
            ? appendParamsToUrl(generatedLink, blockTeaser.urlParams)
            : generatedLink,
          dataNavigation: '/',
        },
      ];
    }

    if (useInheritedCategoryLinks) {
      const links = teaserTarget.category?.children ?? [];
      return links
        .map((linkItem) => {
          const commerceLink = extractProperty(linkItem, 'link', '');
          const externalTechId = extractProperty(
            linkItem,
            'externalTechId',
            '/'
          );
          let link = localePath(
            commerceLink ? commerceLink : ROUTES.CATEGORY(externalTechId)
          );
          if (blockTeaser.urlParams) {
            link = appendParamsToUrl(link, blockTeaser.urlParams);
          }
          return {
            label: extractProperty(linkItem, 'name', ''),
            link: link,
            dataNavigation: externalTechId,
          };
        })
        .slice(0, 4);
    }

    return getCustomLinks();
  };

  const getCustomLinks = () => {
    const links = [];

    (blockTeaser.localSettingsExpanded || []).forEach((expandedLink) => {
      const linksItems = extractProperty(expandedLink, 'links', []);
      linksItems.forEach((linkItem) => {
        const label = extractProperty(
          linkItem,
          'teaserTitle',
          extractProperty(linkItem, 'title')
        );
        const link = generateLinkFromTeaserTargets(
          [{ target: linkItem } as CMTeaserTarget],
          context,
          siteConfiguration,
          blockTeaser.urlParams
        );

        if (label && link) {
          links.push({ label, link });
        }
      });
    });

    return links.slice(0, 4);
  };

  try {
    return {
      id: blockTeaser.id,
      title: blockTeaser.teaserTitle,
      styles: {
        titleColor:
          getTeaserStyle(blockTeaser, 'teaserTitleColor') ||
          extractProperty(
            blockTeaser,
            'localSettings.dynamicFields.title-color'
          ),
        titleFontSize: getTeaserStyle(blockTeaser, 'teaserTitleFontSize'),
        titleFontWeight: getTeaserStyle(blockTeaser, 'teaserTitleFontWeight'),
        titleFontFamily: getTeaserStyle(blockTeaser, 'teaserTitleFontFace'),
        titleBackgroundColor: getTeaserStyle(
          blockTeaser,
          'teaserTitleBackgroundColor'
        ),
        titleClassModifiers: getTeaserStyle(
          blockTeaser,
          'teaserTitleClassModifiers'
        ),
      },
      headingLevel: getTeaserStyle(blockTeaser, 'teaserTitleHeadingLevel'),
      linkColor:
        getTeaserStyle(blockTeaser, 'teaserTextColor') ||
        extractProperty(
          blockTeaser,
          'localSettings.dynamicFields.children-color'
        ),
      linkBackgroundColor: getTeaserStyle(
        blockTeaser,
        'teaserTextBackgroundColor'
      ),
      linkClassModifiers: getTeaserStyle(
        blockTeaser,
        'teaserTextClassModifiers'
      ),
      gridRow: {
        medium,
        large,
      },
      backgroundUrl: getImageObject(
        blockTeaser.pictures[0],
        siteConfiguration,
        CMPictureCropType.RATIO_1x1,
        cmsBaseUri,
        blockTeaser.responsiveMedia
      ),
      openInNewTab: extractProperty(blockTeaser, 'openInNewTab', false),
      openInNewModal: extractProperty(blockTeaser, 'openInNewModal', false),
      links: getLinksToDisplay(),
    };
  } catch (e) {
    return null;
  }
};
