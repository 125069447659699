import { Plugin } from '@nuxt/types';

const CustomConfiguration: Plugin = (context, inject) => {
  function getCustomConfiguration<T = boolean>(key: string): T {
    const config = JSON.parse(context.$env.CUSTOM_CONFIGURATION);

    return config[key];
  }

  inject('getCustomConfiguration', getCustomConfiguration);
};

export default CustomConfiguration;
