export const getProductImage = (
  product: Record<string, any>,
  overrideImage: string
): string => {
  if (overrideImage) return overrideImage;
  if (!product.colorCode || product.masterId === product.colorCode) {
    return product.productImageUrl || product.productImageURL;
  }
  return product.image;
};
