import { CMTeaser } from '@vf/api-contract';
import { extractProperty, getTeaserStyle } from './utils';

export const customsCta = (data: CMTeaser) => {
  return {
    component: 'customs/CustomsPreloader',
    props: {
      preloadedComponent: 'CustomsCta',
    },
    attrs: {
      id: extractProperty(data, 'id'),
      dataObject: extractProperty(
        data,
        'localSettings.dynamicFields.dataObject'
      ),
      buttonSize: extractProperty(data, 'localSettings.dynamicFields.size'),
      buttonText: extractProperty(data, 'title'),
      customId: extractProperty(data, 'customId'),
      underline: extractProperty(
        data,
        'localSettings.dynamicFields.underline',
        false
      ),
      experience: extractProperty(
        data,
        'localSettings.dynamicFields.experience'
      ),
      icon: extractProperty(data, 'localSettings.dynamicFields.icon', ''),
      experienceType:
        extractProperty(data, 'localSettings.dynamicFields.experienceType') ||
        undefined,
      iconPosition: extractProperty(
        data,
        'localSettings.dynamicFields.iconPosition'
      ),
      buttonStyle: extractProperty(data, 'localSettings.dynamicFields.style'),
      styles: {
        fontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        fontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        fontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        textColor: getTeaserStyle(data, 'teaserTitleColor'),
        backgroundColor: getTeaserStyle(data, 'teaserTitleBackgroundColor'),
        customClasses: getTeaserStyle(data, 'teaserClassModifiers'),
      },
    },
  };
};
