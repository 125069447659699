//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { iconColorsValues as SF_COLORS } from './../assets/variables/colors';
import { sizesValues as SF_SIZES } from './../assets/variables/sizes';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfIcon',
  props: {
    /**
     * Icon SVG path(s)
     * It can be single SVG path (string) or array of SVG paths or icon name
     * from our icons list (such as 'added_to_cart`)
     */
    icon: {
      type: String,
      default: '',
    },
    /**
     * Custom size of the icon
     * It can be our standard sizes, or `12px` or `1.2rem` or nothing.
     * Standard sizes: `xxs`, `xs`, `sm`, `md`, `lg`, `xl`, `xxl`, `xl3`, `xl4`.
     */
    size: {
      type: String,
      default: '',
    },
    /**
     * Custom color of the icon
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `white`, `black`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`, `accent`.
     */
    color: {
      type: String,
      default: '',
    },
    /**
     * Icon height
     * Default height for SVG initial load size
     */
    height: {
      type: Number,
      default: 24,
    },
    /**
     * Icon width
     * Default width for SVG initial load size
     */
    width: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.color.trim());
    },
    isSFSizes() {
      const size = this.size.trim();
      return SF_SIZES.includes(size);
    },
    iconColorClass() {
      return this.isSFColors ? `color-${this.color.trim()}` : '';
    },
    iconSizeClass() {
      return this.isSFSizes ? `size-${this.size.trim()}` : '';
    },
    iconCustomStyle() {
      if (!this.color && !this.size) {
        return null;
      }
      return {
        '--icon-color': !this.isSFColors ? this.color : '',
        '--icon-size': !this.isSFSizes ? this.size : '',
      };
    },
    iconName() {
      return `${this.$root.$env?.THEME || 'wl'}/${this.icon}`;
    },
    spriteName() {
      let [sprite, icon] = this.iconName.split('/');

      if (!icon) {
        icon = sprite;
        sprite = 'icons';
      }

      return this.getSpritePath(sprite) + `#i-${this.generateName(icon)}`;
    },
  },
  methods: {
    generateName(name) {
      return name
        .toLowerCase()
        .replace(/\.svg$/, '')
        .replace(/[^a-z0-9-]/g, '-');
    },
    /**
     * Find sprite file name after nuxt build
     * @param {string} sprite Name of a sprite
     */
    getSpritePath(sprite) {
      /* eslint-disable @typescript-eslint/no-var-requires */
      const module = require('./../assets/sprite/' + sprite + '.svg');
      if (typeof module === 'string') {
        return module;
      }
      // nuxt-edge v3.0.0-26398097.20b0379b returns object instead of string
      if (typeof module === 'object' && typeof module.default === 'string') {
        return module.default;
      }
      return '';
    },
  },
});
