const createThrowContextErrorFunction = (
  context: symbol,
  functionName: string
) => (): void => {
  throw new Error(
    `You are trying to use "${functionName}" function without providing "${context.description}"`
  );
};

export default createThrowContextErrorFunction;
