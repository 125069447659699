import { CMCommerceRef, CmsCategoryPageContent } from '@vf/api-contract';
import { ComponentInstance } from '../../types';
import { formatUrlName, normalizeUrl } from './urlHelpers';
import { CATEGORY_ID_REGEX } from './patterns';

const generateCategorySegments = (
  commercePath: CMCommerceRef[],
  addPrefixOnLast?: boolean
) => {
  const categorySegments = [...commercePath]
    .reverse()
    .filter((category) => category.id !== 'root')
    .map((category, i, arr) => {
      const titlePart = formatUrlName(category.title);

      if (addPrefixOnLast && i === arr.length - 1) {
        return `${titlePart}-c${category.id}`;
      }

      return titlePart;
    });

  return categorySegments.join('/');
};

const generateCategoryUrl = (
  instance: ComponentInstance,
  categoryPage?: CmsCategoryPageContent
) => {
  if (!categoryPage) {
    return null;
  }

  if (categoryPage.commerceRef.link) {
    return categoryPage.commerceRef.link;
  }

  if (instance.$env.SEO_CANONICAL_GENERATE_CATEGORY_URL !== 'true') {
    return null;
  }

  const id = categoryPage.commerceRef.id;

  if (CATEGORY_ID_REGEX.test(id)) {
    const title = formatUrlName(categoryPage.commerceRef.title);

    const categorySegment = `${title}-c${id}`;

    if (categoryPage.commercePath) {
      const parentCategorySegments = generateCategorySegments(
        categoryPage.commercePath
      );

      if (parentCategorySegments) {
        return `/${parentCategorySegments}/${categorySegment}`;
      }
    }

    return `/${categorySegment}`;
  }

  return `/category/${id}`;
};

export const getCategoryUrlFactory = (instance: ComponentInstance) => {
  const getCategoryUrl = (
    page: CmsCategoryPageContent,
    qs?: { [key: string]: string }
  ) => normalizeUrl(instance, generateCategoryUrl(instance, page), qs);

  return getCategoryUrl;
};
