




import { defineComponent } from '@vue/composition-api';
import SimplifiedEnrollmentToast from './SimplifiedEnrollmentToast.vue';
import ProgressiveProfileModal from './ProgressiveProfileModal.vue';
import ProgressiveProfileConfirmation from './ProgressiveProfileConfirmation.vue';

export default defineComponent({
  name: 'ProgressiveProfileManager',
  components: {
    SimplifiedEnrollmentToast,
    ProgressiveProfileModal,
    ProgressiveProfileConfirmation,
  },
  props: {
    activeComponent: {
      type: String,
      default: null,
    },
  },
});
