import { CmsSite } from '@vf/api-contract';
import { extractCommonConfigLink, extractProperty } from '../mappings/utils';

export const getLocales = (siteConfiguration: CmsSite) => {
  const languageSelectorSites = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'language-selector-sites'),
    '[0].settings.sites',
    {}
  );

  if (!languageSelectorSites || !languageSelectorSites.length) {
    console.warn(`Configuration for Site Language/Country Selector not found.`);
    return [];
  }

  return languageSelectorSites;
};
