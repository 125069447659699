export const buildTrackUrlWithOptions = (
  widgetType: string,
  endpoint: string,
  options: {
    instanceId: string;
    action?: string;
    categoryId?: string;
    streamId?: string;
    mediaId?: string;
    format?: string;
  },
  root
) => {
  const baseUrl =
    root.$env.SOCIAL_NATIVE_ANALYTICS_BASE_URL ||
    'https://data.photorank.me/track';

  const {
    instanceId,
    action,
    categoryId,
    streamId,
    mediaId,
    format = 'json',
  } = options;

  let url = endpoint
    .replace('{instanceId}', instanceId)
    .replace('{action}', action)
    .replace('{format}', format);

  if (mediaId) {
    url = url.replace('{mediaId}', mediaId.toString());
  }

  if (widgetType === 'category') {
    url = url.replace('{categoryId}', categoryId.toString());
  }

  if (widgetType === 'stream') {
    url = url.replace('{streamId}', streamId.toString());
  }

  return `${baseUrl}${url}`;
};
