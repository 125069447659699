import { defineStore, storeToRefs } from 'pinia';
import { isClient } from '@vf/shared/src/utils/helpers';
import { watch } from '@vue/composition-api';
import { useUserStore } from './user';
import { ComponentInstance, useFavorites } from '@vf/composables';

export const useFavoritesStore = (instance: ComponentInstance) => {
  return defineStore('favorites', () => {
    if (isClient) {
      const userStore = useUserStore(instance);
      const { loggedIn } = storeToRefs(userStore);

      const { getFavorites } = useFavorites(instance);

      watch(loggedIn, (val) => {
        if (val) {
          getFavorites();
        }
      });
    }

    return {};
  })();
};
