import { extractProperty, getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';
export const pdpUpsell = (data: CMPlaceholder) => {
  return {
    component: 'pdp/ProductUpsell',
    props: {
      useMonetateFallback: extractProperty(data, 'useMonetateFallback', false),
      translations: {
        details: getTranslation(data, 'details', '$Details$'),
        extras: getTranslation(data, 'extras', '$Extras$'),
        title: getTranslation(
          data,
          'title',
          '$Would you like to add {{productName}} for only {{price}}?$'
        ),
      },
      ...(data._injectedProps ? data._injectedProps : {}),
    },
  };
};
