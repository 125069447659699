import {
  AddToFavoritesPayload,
  CartProductsTranslations,
} from '@vf/api-contract';
import { EventProps } from '@vf/composables/src/useGtm/eventPropsHandlers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { replaceMessagePlaceholders } from '@vf/shared/src/utils/helpers';

export interface CartProductListConfig {
  imageWidth: number;
  imageHeight: number;
  tableColspan: number;
  sortProductsByFlashErrors: boolean;
  showErrorNotification: boolean;
  showSuccessMessagePerRow: boolean;
  notificationClass: string;
  storeNearFieldMaxLength: number;
  showStaticTextInsteadOfShippingLabel: boolean;
  productAvailabilityModalCancelButtonClass: string;
  showStsInfoInProductAvailabilityModal: boolean;
  showGeneralFlashNotificationOnCart: boolean;
  showFavoritesNotificationOnCart: boolean;
  productIdPropertyName: 'masterId' | 'masterId2';
}

export const getCartProductListThemeConfig = (
  themeConfig = {} as any
): CartProductListConfig => {
  const config = themeConfig.cartProductList || {};
  return {
    imageWidth: config.imageWidth || 140,
    imageHeight: config.imageHeight || 140,
    tableColspan: config.tableColspan || 4,
    sortProductsByFlashErrors: config.sortProductsByFlashErrors || true,
    showErrorNotification: config.showErrorNotification || false,
    showSuccessMessagePerRow: config.showSuccessMessagePerRow || false,
    notificationClass: config.notificationClass || '',
    storeNearFieldMaxLength: config.storeNearFieldMaxLength || 256,
    showStaticTextInsteadOfShippingLabel:
      config.showStaticTextInsteadOfShippingLabel ?? false,
    productAvailabilityModalCancelButtonClass:
      config.productAvailabilityModalCancelButtonClass ||
      'vf-button--secondary vf-shipment-store-modal__btn',
    showStsInfoInProductAvailabilityModal:
      config.showStsInfoInProductAvailabilityModal ?? true,
    showGeneralFlashNotificationOnCart:
      config.showGeneralFlashNotificationOnCart ?? true,
    showFavoritesNotificationOnCart: !!config.showFavoritesNotificationOnCart,
    productIdPropertyName: config.productIdPropertyName,
  };
};

export const mapMessage = (
  error: Record<string, any>,
  translations: Record<string, any>
): { code: string; message: string } => {
  const code = error.code || error.type;
  const mappedError = code
    ? translations[code]
    : translations.DefaultFlashMessage;
  const message =
    replaceMessagePlaceholders(mappedError, error.details) ||
    translations.DefaultFlashMessage ||
    '';
  return { code, message };
};

export const getMaxQuantity = (
  item: Record<string, any>,
  maxQty: number
): boolean => {
  return item.maxQty > maxQty ? maxQty : item.maxQty;
};

export const getOverrideImage = (
  item: Record<string, any>,
  instance: Record<string, any>
): string => {
  if (item.imageDeclined) {
    return instance.$mediaUrlFallback();
  }
  return null;
};

export const getAddToFavoritesProduct = (
  item: Record<string, any>,
  options: Record<string, any>
): AddToFavoritesPayload => {
  return {
    favoriteId: options.favoriteId || '',
    recipeId: item.recipeId || '',
    id: options.useVariation ? item.productId : item.masterId,
    itemType: 'product',
    pdpUrl: item.pdpUrl && !options.useVariation ? item.pdpUrl : options.pdpUrl,
    pageUrl: item.pageUrl || options.productUrl,
    productImageUrl:
      !item.colorCode || item.masterId === item.colorCode
        ? item.productImageUrl || item.productImageURL
        : item.image,
    available: item.available || '',
    qty: 1,
    defaultProductView: '',
    public: true,
  };
};

export const getGtmPayloadforCartUpdate = (data: {
  quantity: number;
  product: { qty: number; masterId: string; productId: string };
}): EventProps => {
  const difference = data.quantity - data.product.qty;
  let payload: EventProps = {
    eventName: undefined,
    composablesContexts: { useProduct: 'quickShop' },
    overrideAttributes: {
      quantity: Math.abs(difference),
    },
  };
  if (difference > 0) {
    payload = {
      ...payload,
      ...getEventFromTemplate('cart:add', {}),
      overrideAttributes: {
        ...payload.overrideAttributes,
        productId: data.product.masterId,
        sku: data.product.productId,
      },
    };
  } else {
    payload = {
      ...payload,
      ...getEventFromTemplate('cart:remove', {}),
      overrideAttributes: {
        ...payload.overrideAttributes,
        product: data.product,
        sku: data.product.productId,
      },
    };
  }
  return payload;
};

export const getPickupLabels = (
  translations: CartProductsTranslations
): Record<string, string> => {
  return {
    BOPIS: translations.inStorePickup,
    CURBSIDE: translations.curbsidePickup,
    STS: translations.sts,
  };
};

type DistanceOption = { label: string; value: string };
export const getStoreDistanceOptionList = (
  storeDistanceOptions: number[],
  storeDistanceUnit: string,
  translations: CartProductsTranslations['storeAvailabilityModal']
): DistanceOption[] => {
  const storeDistanceUnitLabel =
    storeDistanceUnit?.toLowerCase() === 'km'
      ? translations.km
      : translations.miles;
  return storeDistanceOptions.map((distance) => ({
    label: `${distance} ${storeDistanceUnitLabel}`,
    value: `${distance}`,
  }));
};
