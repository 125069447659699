var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfButton',{staticClass:"vf-facet vf-button--text",class:{
    'vf-facet--active': _vm.active,
    'vf-facet--tile': _vm.tiled,
    'vf-facet--redesign': _vm.isFiltersUiUpdateEnabled,
    'vf-ripple-disabled': _vm.isCoreRedesignEnabled,
  },attrs:{"aria-label":_vm.ariaLabel ? (_vm.ariaLabel + ": " + _vm.value) : null,"link":_vm.link,"rel":_vm.rel},nativeOn:{"click":function($event){!_vm.active ? _vm.select() : _vm.remove()}}},[(_vm.tiled && _vm.color.length)?_c('div',{staticClass:"vf-facet__color",style:({
      background: _vm.background,
    })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vf-facet__item"},[_c('div',{staticClass:"vf-facet__name"},[_vm._v(_vm._s(_vm.value))]),_vm._v(" "),(_vm.displayCount && !_vm.tiled)?_c('div',{staticClass:"vf-facet__count"},[_vm._v("\n      ("+_vm._s(_vm.count)+")\n    ")]):_vm._e()]),_vm._v(" "),((_vm.active || _vm.tiled) && _vm.showIcon)?_c('div',{staticClass:"vf-facet__reset",class:{ 'vf-facet__reset--redesign-catalog': _vm.isVansPlpRedesignEnabled }},[_c('VfIcon',{attrs:{"icon":_vm.icon}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }