import Vue from 'vue';
import { Context, Inject } from '@nuxt/types/app';
import { getGoogleReCaptchaLocale } from '@vf/shared/src/utils/locale';
import { getEnvValueByLocale } from '@vf/env';

export default ({ app, $env }: Context, inject: Inject): void => {
  const recaptchaEvent = new Vue();

  inject('recaptchaEvent', recaptchaEvent);

  recaptchaEvent.$once('load-recaptcha', () => {
    const { VueReCaptcha } = require('vue-recaptcha-v3');

    Vue.use(VueReCaptcha, {
      siteKey: getEnvValueByLocale('RECAPTCHA_SITE_KEY', app.i18n.locale, $env),
      loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
        useEnterprise: true,
        renderParameters: {
          hl: getGoogleReCaptchaLocale(app.i18n.locale),
        },
      },
    });
  });
};
