import { CMTeaser } from '@vf/api-contract';
import { getTranslation, getDynamicField } from './utils/extractProperty';

export const shippingAddressesSelect = (data: CMTeaser) => {
  return {
    component: 'addressBook/ShippingAddressPicker',
    props: {
      showEditButton: true,
      showDeleteButton: true,
      showDefaultAddressOnTopRow: getDynamicField(
        data,
        'showDefaultAddressOnTopRow',
        false
      ),
      translations: {
        heading: getTranslation(data, 'heading', '$Shipping Addresses$'),
        subtitle: getTranslation(
          data,
          'subheading',
          '$Select your default shipping address$'
        ),
        buttonText: getTranslation(
          data,
          'buttonText',
          '$Add Shipping Address$'
        ),
        editButton: getTranslation(data, 'editButton', '$Edit$'),
        deleteButton: getTranslation(data, 'deleteButton', '$Delete$'),
        deleteSuccess: getTranslation(
          data,
          'deleteSuccess',
          '$Address deleted$'
        ),
        deleteError: getTranslation(
          data,
          'deleteError',
          '$Address delete error$'
        ),
        updateSuccess: getTranslation(data, 'updateSuccess', '$Address saved$'),
      },
    },
  };
};
