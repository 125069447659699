import { extractProperty } from './extractProperty';
export const extractComponentStyleDefinitions = (
  data: any,
  type: string,
  typeAlternative?: string
) => {
  return {
    text: extractProperty(data, type) || extractProperty(data, typeAlternative),
    variant: extractProperty(data, `componentStyles.${type}Style`),
    color: extractProperty(
      data,
      `componentStylesDefinitions.${type}Color.data.color`
    ),
    backgroundColor: extractProperty(
      data,
      `componentStylesDefinitions.${type}BackgroundColor.data.color`
    ),
  };
};
