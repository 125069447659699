import { getDynamicField } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const aemContentImport = (data: CMPlaceholder) => {
  return {
    component: 'static/StaticContent',
    props: {
      customUrl: getDynamicField(data, 'customUrl', ''),
    },
  };
};
