import { getTranslation } from './utils';
import { CMTeaser } from '@vf/api-contract';

export const loyaltyMemberDashboard = (data: CMTeaser) => {
  return {
    component: 'loyalty/LoyaltyMemberDashboard',
    props: {
      translations: {
        loadingIndicator: getTranslation(data, 'loadingIndicator', '$Loading$'),
        retryAgain: getTranslation(data, 'retryAgain', '$Retry Again$'),
        appError: getTranslation(
          data,
          'appError',
          '$Sorry, we cannot load loyalty content at this moment.  Please try again later.$'
        ),
      },
    },
  };
};
