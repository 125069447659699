import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { Ref, ref } from '@vue/composition-api';
import { useCart } from '../useCart';
import { useRequestTracker } from '../useRequestTracker';
import { apiClientFactory, PaymentMethodCode } from '@vf/api-client';
import { Address } from '@vf/api-client/src/types';
import { load as loadScript } from '@vf/shared/src/utils/helpers/load-script';
import { errorMessages } from '../utils/errorMessages';
import { useOrderStore } from '../store/order';
import { useFeatureFlagsStore } from '../store/featureFlags';

type UseKlarnaStorage = {
  isKlarnaInitialized: Ref<boolean>;
  isKlarnaUpdateInProgress: Ref<boolean>;
  isKlarnaSdkLoaded: Ref<boolean>;
  klarnaAuthorizationToken: Ref<KlarnaAuthorizationToken>;
  klarnaSessionData: Ref<KlarnaSessionData>;
};

type KlarnaAuthorizationToken = {
  approved: boolean;
  token: string;
};

type KlarnaSessionData = {
  paymentMethodCategory: string;
  session_id: string;
  token: string;
  payment_instrument_id: string;
  attachment: {
    content_type: string;
    body: string;
  };
};

type KlarnaAuthorizationPayload = {
  at: string;
  payment_instrument_id: string;
  sessionID: string;
  clientToken: string;
};

export const useKlarna = (instance: ComponentInstance) => {
  const orderStore = useOrderStore();
  const {
    getCartPaymentInstruments: getCartPaymentInstrumentsAPI,
  } = apiClientFactory(instance);
  const storage: ComposablesStorage<UseKlarnaStorage> = initStorage<UseKlarnaStorage>(
    instance,
    'useKlarna'
  );
  const { cartId } = useCart(instance);
  const { trackRequest, clearRequest, getOngoingRequest } = useRequestTracker(
    instance
  );
  const { displayErrorMessages } = errorMessages(instance);
  const klarnaSdkSrc = 'https://x.klarnacdn.net/kp/lib/v1/api.js';

  const isKlarnaInitialized: Ref<boolean> =
    storage.get('isKlarnaInitialized') ??
    storage.save('isKlarnaInitialized', ref(false));

  const isKlarnaUpdateInProgress: Ref<boolean> =
    storage.get('isKlarnaUpdateInProgress') ??
    storage.save('isKlarnaUpdateInProgress', ref(false));

  const isKlarnaSdkLoaded: Ref<boolean> =
    storage.get('isKlarnaSdkLoaded') ??
    storage.save('isKlarnaSdkLoaded', ref(false));

  const klarnaSessionData: Ref<KlarnaSessionData> =
    storage.get('klarnaSessionData') ??
    storage.save(
      'klarnaSessionData',
      ref({
        formattedOrderTotal: 0,
        paymentMethodCategory: '',
        token: '',
        session_id: '',
        payment_instrument_id: '',
        attachment: {
          content_type: '',
          body: '',
        },
      })
    );

  const klarnaAuthorizationToken: Ref<KlarnaAuthorizationToken> =
    storage.get('klarnaAuthorizationToken') ??
    storage.save(
      'klarnaAuthorizationToken',
      ref({ approved: false, token: '' })
    );

  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

  const loadKlarnaSdk = async () =>
    loadScript(klarnaSdkSrc, setKlarnaSdkLoaded);

  const setKlarnaSdkLoaded = () => (isKlarnaSdkLoaded.value = true);

  const updateKlarnaSession = async () => {
    await getKlarnaToken();
    await initKlarna();
    await loadKlarnaPayment();
    isKlarnaUpdateInProgress.value = false;
  };

  const isKlarnaInPaymentMethods = (paymentMethods) => {
    return paymentMethods.value.some(
      (paymentMethod) => paymentMethod.code === PaymentMethodCode.KLARNA
    );
  };

  const getKlarnaToken = async () => {
    const { tag } = trackRequest('useKlarna-getKlarnaToken');
    try {
      const response = await getCartPaymentInstrumentsAPI({
        cartId: cartId.value,
        payment_method_id: PaymentMethodCode.KLARNA,
      });
      const paymentSessionResponse = response.data.payment_instruments?.find(
        (method) => method.payment_method_id === PaymentMethodCode.KLARNA
      );
      if (paymentSessionResponse) {
        klarnaSessionData.value = {
          token: paymentSessionResponse.client_token,
          paymentMethodCategory:
            paymentSessionResponse.payment_method_categories[0].identifier,
          session_id: paymentSessionResponse.session_id,
          payment_instrument_id: paymentSessionResponse.payment_instrument_id,
          attachment: paymentSessionResponse.attachment,
        };
        if (isKlarnaSdkLoaded.value) await initKlarna();
      } else {
        throw 'Klarna token not received';
      }
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      clearRequest(tag);
    }
  };

  const initKlarna = async () => {
    try {
      window.Klarna.Payments.init({
        client_token: klarnaSessionData.value.token,
      });
      isKlarnaInitialized.value = true;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const loadKlarnaPayment = async () => {
    try {
      window.Klarna.Payments.load({
        container: '#klarna-payment-container',
        payment_method_category: klarnaSessionData.value.paymentMethodCategory,
      });
    } catch (e) {
      console.error('Error loading Klarna');
      // Display notification only if Klarna method is selected
      // Don't show notification when request is delayed and Klarna method is not selected
      if (orderStore.paymentMethod === PaymentMethodCode.KLARNA) {
        displayErrorMessages(e);
      }
    }
  };

  const getAddressField = (address: Address) => {
    const streetAddress = address.addressLine2
      ? address.addressLine1 + ' ' + address.addressLine2
      : address.addressLine1;

    return {
      given_name: address.firstName,
      family_name: address.lastName,
      email: address.email,
      title: '',
      street_address: streetAddress,
      postal_code: address.postalCode,
      city: address.city,
      region: address.province,
      phone: address.phone,
      country: address.country,
    };
  };

  const authorizeKlarnaPayment = async (
    billingAddress: Address,
    shippingAddress: Address,
    callbackFn?
  ) => {
    if (isCheckoutRedesignEnabled) {
      type KlarnaResponse =
        | KlarnaAuthorizationPayload
        | { success: boolean; error: unknown };
      return new Promise<KlarnaResponse>((resolve) => {
        try {
          window.Klarna.Payments.authorize(
            {
              payment_method_category:
                klarnaSessionData.value.paymentMethodCategory,
            },
            {
              billing_address: getAddressField(billingAddress),
              shipping_address: getAddressField(shippingAddress),
              attachment: klarnaSessionData.value.attachment,
            },
            (klarnaResponse) => {
              if (klarnaResponse.approved) {
                klarnaAuthorizationToken.value = {
                  approved: klarnaResponse.approved,
                  token: klarnaResponse.authorization_token,
                };
                resolve({
                  at: klarnaAuthorizationToken.value.token,
                  payment_instrument_id:
                    klarnaSessionData.value.payment_instrument_id,
                  sessionID: klarnaSessionData.value.session_id,
                  clientToken: klarnaSessionData.value.token,
                });
                return;
              }
              resolve(null);
            }
          );
        } catch (error) {
          displayErrorMessages(error);
        }
      });
    }

    try {
      window.Klarna.Payments.authorize(
        {
          payment_method_category:
            klarnaSessionData.value.paymentMethodCategory,
        },
        {
          billing_address: getAddressField(billingAddress),
          shipping_address: getAddressField(shippingAddress),
          attachment: klarnaSessionData.value.attachment,
        },
        (klarnaResponse) => {
          if (klarnaResponse.approved) {
            klarnaAuthorizationToken.value = {
              approved: klarnaResponse.approved,
              token: klarnaResponse.authorization_token,
            };
            callbackFn(
              {
                payment_instrument_id:
                  klarnaSessionData.value.payment_instrument_id,
                sessionID: klarnaSessionData.value.session_id,
                clientToken: klarnaSessionData.value.token,
                at: klarnaAuthorizationToken.value.token,
              },
              {
                paymentMethod: PaymentMethodCode.KLARNA,
              }
            );
          }
        }
      );
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const isBillingAddressValidForKlarna = (address: Address): boolean => {
    const klarnaDisabledBillingCountries: string[] = JSON.parse(
      instance.$env.KLARNA_DISABLED_BILLING_COUNTRIES
    );
    return !klarnaDisabledBillingCountries?.includes(address.countryCode);
  };

  const handleAddressUpdateForKlarna = async () => {
    try {
      if (
        orderStore.paymentMethod === PaymentMethodCode.KLARNA &&
        !isKlarnaUpdateInProgress.value
      ) {
        isKlarnaUpdateInProgress.value = true;
        await getOngoingRequest('useKlarna-getKlarnaToken');
        await updateKlarnaSession();
      }
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  return {
    handleAddressUpdateForKlarna,
    getKlarnaToken,
    isKlarnaInitialized,
    isKlarnaInPaymentMethods,
    isKlarnaSdkLoaded,
    klarnaAuthorizationToken,
    klarnaSessionData,
    loadKlarnaSdk,
    loadKlarnaPayment,
    updateKlarnaSession,
    isBillingAddressValidForKlarna,
    authorizeKlarnaPayment,
  };
};

export default useKlarna;
