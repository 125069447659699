import { extractProperty, getDynamicField, getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const pdpActionButtons = (data: CMPlaceholder) => {
  return {
    component: 'pdp/ProductActionButtons',
    props: {
      largeButton:
        extractProperty(data, 'title') === 'PDP Action Buttons Big Button',
      turnOnAddToFavourites: getDynamicField(
        data,
        'turn-on-add-to-favorites',
        true
      ),
      turnOnFindInStore: getDynamicField(data, 'turn-on-find-in-store', true),
      turnOnAddToFavouritesHeart: getDynamicField(
        data,
        'turn-on-add-to-favorites-heart',
        false
      ),
      translations: {
        findInAStore: getTranslation(
          data,
          'findInStore',
          '$Find in a Vans Store$'
        ),
        removeFromFavorites: getTranslation(
          data,
          'removeFromFavorites',
          '$Remove from Favorites$'
        ),
        addedToFavorites: getTranslation(
          data,
          'addedToFavorites',
          '$Added to favorites$'
        ),
        addToFavorites: getTranslation(
          data,
          'addToFavorites',
          '$Add To Favorites$'
        ),
        addedToFavoritesNotification: getTranslation(
          data,
          'addedToFavoritesNotification',
          '$You have successfully added {{product}} to your Favorites.$'
        ),
        buttonsVariant: getDynamicField(data, 'cta-text-button', 'text-links'),
      },
    },
  };
};
