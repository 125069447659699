import axios from '../../helpers/axios';
import { endpoints } from '../../config';
import { headerBuilder, urlBuilder } from '../../helpers';
import { ApiClientConfiguration } from '../../configuration';

export const getStores = (
  configuration: ApiClientConfiguration
) => (): Promise<any> => {
  const url = endpoints.get(configuration).customer.getBuyInStoreLocations;
  return axios.get(urlBuilder(url, { config: configuration }), {
    headers: headerBuilder(configuration),
  });
};
