import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractRichText,
  getTranslation,
  getValidationConfigValues,
} from './utils';

export const smsSubscriptionCheckout = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'order/OrderSmsSubscription',
    props: {
      translations: {
        title: getTranslation(data, 'title', '$Get Text Updates$'),
        subtitle: {
          unsubscribed: extractRichText(
            data,
            'unsubscribed',
            '$Add your mobile number to receive informations$',
            cmsBaseUri,
            context,
            siteConfiguration
          ),
          optedIn: getTranslation(
            data,
            'subtitle.optedIn',
            '$Please reply "Yes" to 84243 to complete SMS Registration.$'
          ),
          confirmed: getTranslation(
            data,
            'subtitle.confirmed',
            '$You signed up to receive texts about your orders and offerings$'
          ),
        },
        phoneLabel: getTranslation(data, 'phoneLabel', '$Phone Number$'),
        checkboxLabel: extractRichText(
          data,
          'checkboxLabel',
          '$I expressly consent to receive recurring text messages.$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        updatePreferencesText: extractRichText(
          data,
          'updateLabel',
          '$To update preferences, go to <a href="#">My Account</a>.$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        subscribeCTA: getTranslation(data, 'subscribeCTA', '$Subscribe$'),
        validationMessages: {
          phoneError: getTranslation(
            data,
            'validationMessages.phoneError',
            '$A valid phone number is required$'
          ),
          checkboxError: getTranslation(
            data,
            'validationMessages.checkboxError',
            '$*Required$'
          ),
        },
      },
      validations: getValidationConfigValues(data),
    },
  };
};
