import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const shareMyCartModal = (data: CMPlaceholder) => {
  return {
    component: 'cart/ShareMyCart',
    props: {
      translations: {
        title: getTranslation(data, 'title', '$Share your cart with others$'),
        content: getTranslation(
          data,
          'content',
          '$Custom item(s) with user generated content will not be shared.$'
        ),
        closeButtonText: getTranslation(
          data,
          'closeButtonText',
          '$Close Window$'
        ),
        copyButtonText: getTranslation(data, 'copyButtonText', '$Copy Link$'),
        copySuccessNotificationText: getTranslation(
          data,
          'copySuccessNotificationText',
          '$Link copied successfully.$'
        ),
        copyFailedNotificationText: getTranslation(
          data,
          'copyFailedNotificationText',
          '$Something went wrong. Please try again.$'
        ),
        shareCartError: getTranslation(
          data,
          'shareCartError',
          '$Share my cart error$'
        ),
      },
    },
  };
};
