import { RequestHeader } from '../../types';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import type { LoyaltyVouchersResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const getLoyaltyVouchers = (
  configuration: ApiClientConfiguration
) => async ({
  usid = null,
}: RequestHeader = {}): Promise<LoyaltyVouchersResponse> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.loyaltyVouchers,
    {
      config: configuration,
    }
  );

  const response = await axios.get<LoyaltyVouchersResponse>(url, {
    data: {},
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });

  return response.data;
};
