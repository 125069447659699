import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getApplePayComponentData } from './utils/getApplePayComponentData';
import {
  extractRichText,
  getComponentConfigByName,
  getTranslation,
  extractComponentConfigLink,
  extractProperty,
  getImageObject,
} from './utils';

export const checkoutPaymentInfo = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const config = getComponentConfigByName(
    data,
    'checkout-payment-info-properties',
    {},
    true
  );

  const paymentMethodsIcons = getPaymentMethodsIcons(
    config,
    siteConfiguration,
    cmsBaseUri
  );

  const paymentLabels = getPaymentMethodsLabels(config);

  const tooltipImage =
    getImageObject(
      extractComponentConfigLink(config, 'securityCodeTooltip'),
      siteConfiguration,
      null,
      cmsBaseUri
    )?.small || '';

  return {
    component: 'checkout/CheckoutPaymentInfo',
    props: {
      translations: {
        paymentInformation: getTranslation(
          data,
          'paymentInformation',
          '$Payment information$'
        ),
        payPalCheckOutInformation: getTranslation(
          data,
          'payPalCheckOutInformation',
          '$Continuing below will take you to the PayPal Site to complete and place your order.$'
        ),
        addNewCreditCard: getTranslation(
          data,
          'addNewCreditCard',
          '$+ Add New Credit Card$'
        ),
        cardNumber: getTranslation(data, 'cardNumber', '$Card Number$'),
        month: getTranslation(data, 'month', '$Month$'),
        year: getTranslation(data, 'year', '$Year$'),
        altText: getTranslation(
          data,
          'altText',
          '$This 3 or 4-digit number can be found on the back of your card, near your signature or on the front if you have an American Express card.$'
        ),
        securityCode: getTranslation(data, 'securityCode', '$Security Code$'),
        saveCreditCard: getTranslation(
          data,
          'saveCreditCard',
          '$Save This Credit Card$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          required: '$This field is required, please add your {{fieldName}}.$',
          securityCode: '$Please match the requested format: (XXX) or (XXXX)$',
          cardNumber: '$This field is not correct.$',
        }),
        cardEndingIn: getTranslation(data, 'cardEndingIn', '$Ending in$'),
        cardExpiration: getTranslation(data, 'cardExpiration', '$Exp.$'),
        paypalTooltipAriaLabel: getTranslation(
          data,
          'payPalMoreInfo',
          '$More info about PayPal$'
        ),
        paypalTooltip: extractRichText(
          data,
          'paypal-tooltip-richtext',
          '$Checkout faster and easier with PayPal$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        backToSavedCards: getTranslation(
          data,
          'backToSavedCards',
          '$Back to Saved Cards$'
        ),
      },
      paymentMethodsIcons,
      paymentLabels,
      maxExpirationYear: extractProperty(
        config,
        'values.maxExpirationYear',
        2040
      ),
      securityCodeTooltipImage: tooltipImage,
      applePayTranslations: getApplePayComponentData(
        data,
        cmsBaseUri,
        context,
        siteConfiguration
      ).translations,
    },
  };
};

const getPaymentMethodsIcons = (config, siteConfiguration, cmsBaseUri) => {
  const paymentMethodsIconsConfig = extractComponentConfigLink(
    config,
    'checkoutPaymentMethods'
  );
  return extractProperty(paymentMethodsIconsConfig, 'teasableItems', []).map(
    (method) => {
      return {
        code: method.teaserTitle || '',
        icon: method.teaserStyleCustomizations?.teaserClassModifiers || '',
        image: method.pictures?.[0]
          ? getImageObject(
              method.pictures?.[0],
              siteConfiguration,
              null,
              cmsBaseUri
            ).small
          : '',
      };
    }
  );
};

const getPaymentMethodsLabels = (config) => {
  const paymentMethodsIconsConfig = extractComponentConfigLink(
    config,
    'checkoutPaymentMethods'
  );
  return extractProperty(paymentMethodsIconsConfig, 'teasableItems', []).map(
    (method) => {
      return {
        code: method.teaserTitle || '',
        label: method.localSettings.teaserSubTitle,
      };
    }
  );
};
