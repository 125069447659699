import { useConsumer } from '../useConsumer';
import { useUserStore } from '../../../store/user';

export const useUserSessionData = (instance) => {
  const userStore = useUserStore(instance);
  const {
    setUsid,
    setEmployeeDiscountTCAcceptanceCriteria,
    setLoyaltyEnrolled,
  } = userStore;

  const { setConsumerNo, setConsumerType, setConsumerId } = useConsumer(
    instance
  );

  const setUserSessionDatainLS = (data) => {
    if (data.usid !== null) {
      setUsid(data.usid);
    }
    if (data.consumerId) {
      setConsumerId(data.consumerId);
    }
    if (data.consumerNo) {
      setConsumerNo(data.consumerNo);
    }
    if (data.consumerType !== null) {
      setConsumerType(data.consumerType);
    }
    if (data.isLoyaltyMember !== undefined) {
      setLoyaltyEnrolled(data.isLoyaltyMember);
    }

    const accepted = data.isEmployeeDiscountTCAccepted ?? false;
    setEmployeeDiscountTCAcceptanceCriteria({
      accepted,
      answered: accepted,
    });
  };

  return {
    setUserSessionDatainLS,
  };
};
