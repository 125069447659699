import { DirectiveBinding } from 'vue/types/options';
import { Element } from '../types';
import { setImageSrc, observeImage, unObserveImage } from './lazy-image';

const setLazySrc = (el: Element) => {
  if (
    'loading' in HTMLImageElement.prototype ||
    el.getAttribute('loading') !== 'lazy'
  ) {
    setImageSrc(el);
    return;
  }
  observeImage(el);
};

export const lazyImage = {
  inserted(el: Element, binding: DirectiveBinding): void {
    if (binding.value) {
      el._lazySrc = binding.value;
      setLazySrc(el);
    }
  },
  componentUpdated(el: Element, binding: DirectiveBinding): void {
    if (binding.value !== binding.oldValue) {
      el._lazySrc = binding.value;
      setLazySrc(el);
    }
  },
  unbind(el: Element): void {
    unObserveImage(el);
  },
};
