import { extractProperty, decorateHtmlValue, getTitle } from './utils';
import { CmsSite, CMCollection } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const faqCollection = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'faq/FaqCollection',
    props: {
      title: getTitle(data),
      questions: data.teasableItems.map((item) => {
        return {
          id: extractProperty(item, 'id'),
          title: getTitle(item),
          text: decorateHtmlValue(
            item.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          ),
        };
      }),
    },
  };
};
