import { CMCollection } from '@vf/api-contract';
import { getTranslation } from './utils';
import { MappingMethod } from '../types';
import { extractInterests } from './utils/extractInterests';

export const interestsFormCollection: MappingMethod = (
  data: CMCollection,
  context,
  siteConfiguration,
  cmsBaseUri
) => {
  return {
    component: 'loyalty/InterestsFormCollection',
    props: {
      interests: extractInterests(siteConfiguration, cmsBaseUri),
      translations: {
        heading: getTranslation(data, 'heading', '$Family$'),
        subheading: getTranslation(
          data,
          'subheading',
          '$Tell us 3 things you love to do or know about.$'
        ),
      },
    },
  };
};
