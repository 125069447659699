import { Product } from '@vf/api-client';
import { CmsCategoryPageContent } from '@vf/api-contract/src/cms';
import { ComponentInstance } from '../types';
import { UrlParseResult, GetProductRedirectUrl } from './types';
import {
  parseUrlFactory,
  getCategoryUrlFactory,
  getProductUrlFactory,
  prefetchUrlFactory,
  getRelativeUrlFactory,
} from './handlers';

type UseUrl = {
  parseUrl: (
    localizedUrl: string,
    qs?: { [key: string]: string }
  ) => UrlParseResult;
  getCategoryUrl: (
    page: CmsCategoryPageContent,
    qs?: { [key: string]: string }
  ) => string;
  getProductUrl: (
    product: Product,
    qs?: { [key: string]: string },
    isCanonical?: boolean
  ) => string;
  getProductRedirectUrl: (
    product: Product,
    qs?: { [key: string]: string }
  ) => GetProductRedirectUrl;
  getProductCanonicalUrl: (product: Product) => string;
  prefetchUrl: (url: string) => Promise<void>;
  getRelativeUrl: (url: string) => string;
};

const useUrl = (instance: ComponentInstance, contextKey?: string): UseUrl => {
  const parseUrl = parseUrlFactory(instance, contextKey);
  const getCategoryUrl = getCategoryUrlFactory(instance);
  const prefetchUrl = prefetchUrlFactory(instance, contextKey);
  const getRelativeUrl = getRelativeUrlFactory();

  const {
    getProductUrl,
    getProductRedirectUrl,
    getProductCanonicalUrl,
  } = getProductUrlFactory(instance);

  return {
    parseUrl,
    getCategoryUrl,
    getProductUrl,
    getProductRedirectUrl,
    getProductCanonicalUrl,
    prefetchUrl,
    getRelativeUrl,
  };
};

export default useUrl;
