import { CouponItem } from '@vf/api-client';
import { CheckoutPromoCodeTranslations } from '@vf/api-contract';

export enum CouponStatusCode {
  Applied = 'applied',
  NoApplicablePromotion = 'no_applicable_promotion',
  NoActivePromotion = 'no_active_promotion',
  NotActiveCampaign = 'no_active_campaign',
  CouponDisabled = 'coupon_disabled',
  CouponUnknown = 'coupon_code_unknown',
}

export const isCouponPromotionNotApplied = (coupon: CouponItem): boolean =>
  [CouponStatusCode.NoApplicablePromotion as string].includes(
    coupon.statusCode
  );

export const isCouponPromotionNotActive = (coupon: CouponItem): boolean =>
  [
    CouponStatusCode.NoActivePromotion as string,
    CouponStatusCode.NotActiveCampaign as string,
    CouponStatusCode.CouponDisabled as string,
  ].includes(coupon?.statusCode);

export const getCouponClass = (coupon: CouponItem): string => {
  return isCouponPromotionNotApplied(coupon) ||
    isCouponPromotionNotActive(coupon)
    ? 'vf-property--promo-not-applied'
    : '';
};

export const getCouponLabel = (
  coupon: CouponItem,
  translations: CheckoutPromoCodeTranslations
): string => {
  if (isCouponPromotionNotApplied(coupon)) return translations.promoNotApplied;
  if (isCouponPromotionNotActive(coupon)) return translations.promoNotActive;
  return translations.promoApplied;
};

export const getCouponDiscountAmount = (
  couponCode: string,
  cart: Record<string, any>
): number | null => {
  const couponDiscounts = cart?.discounts?.couponDiscounts ?? [];
  return couponDiscounts.find(({ code }) => code === couponCode)?.discount;
};

export const isCouponNotApplicableToCart = (
  couponCode: string,
  cartCoupons: CouponItem[]
): boolean => {
  return cartCoupons.some(
    (coupon) =>
      coupon.code === couponCode && isCouponPromotionNotApplied(coupon)
  );
};
