import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';

// TODO temporary solution please do not overuse it
export const useUserInterfaceStore = defineStore('ui', () => {
  const pickupFormAccordion = ref(true);
  const altPickupFormAccordion = ref(false);
  const shippingFormAccordion = ref(true);
  const billingFormAccordion = ref(true);
  const paymentFormAccordion = ref(true);
  const wasShippingStepSkippedByUser = ref(false);

  return {
    pickupFormAccordion,
    altPickupFormAccordion,
    shippingFormAccordion,
    billingFormAccordion,
    paymentFormAccordion,
    wasShippingStepSkippedByUser,
  };
});
