import {
  extractProperty,
  getDynamicField,
  getTranslation,
  getComponentConfigByName,
} from './utils';

export const pdpAdditionalSizesSelector = (data: CmsSizeSelector) => {
  const role = getDynamicField(data, 'role', 'size');
  let sizeLabelName = 'sizeLabel';
  switch (role) {
    case 'length':
      sizeLabelName = 'lengthLabel';
      break;
    case 'width':
      sizeLabelName = 'widthLabel';
      break;
    case 'zip':
      sizeLabelName = 'zipLabel';
      break;
  }
  const otherConfigs = getComponentConfigByName(
    data,
    'other',
    {
      'show-divider-line': false,
      'show-top-divider-line': false,
    },
    true
  );

  return {
    component: 'pdp/ProductAdditionalSizes',
    props: {
      showNumeration: getDynamicField(data, 'showNumeration', true),
      preselectDefaultSize: getDynamicField(data, 'preselected-size', false),
      hideComponent: getDynamicField(data, 'hide-component', false),
      showSelectedVariationTitle: getDynamicField(
        data,
        'showSelectedVariationTitle',
        false
      ),
      role: role,
      showBottomDivider: extractProperty(
        otherConfigs,
        'show-divider-line',
        false
      ),
      showTopDivider: extractProperty(
        otherConfigs,
        'show-top-divider-line',
        false
      ),
      translations: {
        sizeLabel: getTranslation(data, sizeLabelName, '$Size$'),
        validationMessages: {
          unselectedMessage: getDynamicField(data, 'unselected-message'),
        },
        showMoreText: getTranslation(data, 'showMoreText', '$Show more$'),
      },
    },
  };
};

export type CmsSizeSelector = {
  id: string;
  type: string;
  name: string;
  viewtype: 'pdp-size-selector';
  title: string;
  componentConfig: CmsSizeSelectorProperties[];
};

export type CmsSizeSelectorProperties = {
  name: 'pdp-size-selector-properties';
  values: {
    'display-as': 'dropdown' | 'titles-chips';
    'unselected-message': string;
    'preselected-size': boolean;
    'hide-component': boolean;
  };
};
