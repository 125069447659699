import { easyHero } from './easyHero';
import { mapDefaultGifVideoOptions } from './utils/mapDefaultVideoOptions';
import { CMContentHero, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const easyHeroGifVideo = (
  data: CMContentHero,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const easyHeroData = easyHero(data, _context, siteConfiguration, cmsBaseUri);

  return mapDefaultGifVideoOptions(easyHeroData);
};
