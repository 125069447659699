import { getTranslation, extractLocalSettingsExpandedRichText } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const orderRegister = (data: CMPlaceholder) => {
  const mainContent = extractLocalSettingsExpandedRichText(
    data,
    'mainContent',
    ''
  );

  return {
    component: 'confirmation/OrderRegister',
    props: {
      mainContent: mainContent.html || '',
      translations: {
        heading: getTranslation(data, 'heading', '$Register and save time!$'),
        firstLine: getTranslation(
          data,
          'firstLine',
          '$- Save time when ordering$'
        ),
        secondLine: getTranslation(
          data,
          'secondLine',
          '$- Get exclusive deals$'
        ),
        thirdLine: getTranslation(
          data,
          'thirdLine',
          '$- Get personalized recommendations$'
        ),
        passwordPlaceholder: getTranslation(
          data,
          'passwordPlaceholder',
          '$Enter desired password$'
        ),
        fieldRequired: getTranslation(
          data,
          'fieldRequired',
          '$Field is required$'
        ),
        registerButtonText: getTranslation(
          data,
          'registerButtonText',
          '$Register$'
        ),
        wrongPasswordErrorMessage: getTranslation(
          data,
          'wrongPasswordErrorMessage',
          '$Password does not meet the security criteria$'
        ),
      },
    },
  };
};
