import type { CartLineItem } from '@vf/api-client';
import type { UseCartStorage } from '../../types';
import type { ComposablesStorage } from '../../../types';
import type { CartLineItemCustomerNotification } from '@vf/api-client/src/types';

import { ref, Ref } from '@vue/composition-api';
import { CustomerNotificationType } from '@vf/api-contract';
import initStorage from '../../../utils/storage';
import { ROUTES } from '../../../utils/routes';
import useCheckout from '../../../useCheckout';
import { useI18n } from '../../../useI18n';

export const useCustomerNotifications = (
  instance,
  isPageHasBeenChanged,
  cartRef,
  contextKey
) => {
  const { localePath } = useI18n(instance);

  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const cartLineItemCustomerNotifications: Ref<
    CartLineItemCustomerNotification[]
  > = storage.getOrSave('cartLineItemCustomerNotifications', ref([]));

  const updateCustomerNotifications = (
    notifications: CartLineItemCustomerNotification[] = []
  ) => {
    // TODO do we need this if cartLineItemCustomerNotifications is init with [] ...
    // TODO ... and notifications is default []?
    if (!cartLineItemCustomerNotifications.value) {
      cartLineItemCustomerNotifications.value = [];
    }

    // TODO this is confusing why we here use variable if page changed...
    // TODO ... I think this should function should be called somewhere on route change
    if (isPageHasBeenChanged.value) {
      cartLineItemCustomerNotifications.value = notifications;

      /** Set page changed flag after we update the flashes/notifications array */
      isPageHasBeenChanged.value = false;
    } else {
      notifications.forEach((notification) => {
        const notificationAlreadyExists = cartLineItemCustomerNotifications.value.find(
          // TODO Improve readability of this chunk of code
          (existingNotification) => {
            return (
              existingNotification?.details?.productId ===
                notification?.details?.productId &&
              existingNotification.type === notification.type &&
              existingNotification.path === notification.path
            );
          }
        );
        if (!notificationAlreadyExists) {
          // TODO shouldn't Array.filter be used here instead of forEach with if?
          cartLineItemCustomerNotifications.value.push(notification);
        }
      });
    }
  };

  // TODO name of this function is not meaningful
  const cartLevelCustomerNotificationActions = (
    notifications: CartLineItemCustomerNotification[]
  ) => {
    if (!notifications.length) {
      return;
    }
    updateCustomerNotifications(notifications);
    // When store inventory changes after switching between shipping and billing page,
    // resulting in changing delivery from pickup to shipping, the shipping address will
    // be incomplete (reset to minimal, empty state). In that case we need to lead customer
    // back to shipping page to provide it for validation.

    // TODO composables can be called on the top of composable
    const {
      setShippingAddressFromCart,
      isShippingAddressIncomplete,
    } = useCheckout(instance);

    if (
      notifications.some(
        (n) =>
          n.type === CustomerNotificationType.PickupToSthTransition ||
          n.type === CustomerNotificationType.StsToSthTransition
      )
    ) {
      // apply changes made to shipping address object to local state
      setShippingAddressFromCart();
      if (
        isShippingAddressIncomplete() &&
        instance.$root?.$route?.path.endsWith(ROUTES.CHECKOUT_PAYMENT())
      ) {
        instance.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
      }
    }
  };

  const clearCartLevelCustomerNotifications = () => {
    cartRef.value = {
      ...cartRef.value,
      customerNotifications: [],
    };
  };

  const clearCartLineItemCustomerNotifications = (force = false) => {
    // TODO this function looks too complicated maybe can be simplified ?
    const shouldClearNotifications = !cartRef.value.items.filter(
      (item: CartLineItem) => item?.customerNotifications?.length
    ).length;
    if (shouldClearNotifications || force) {
      cartLineItemCustomerNotifications.value = [];
    }
  };

  return {
    cartLineItemCustomerNotifications,
    updateCustomerNotifications,
    cartLevelCustomerNotificationActions,
    clearCartLevelCustomerNotifications,
    clearCartLineItemCustomerNotifications,
  };
};
