import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '@vf/api-client';
import { LocatorRequest, LocatorResponse } from '../../api-types';
import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';

export const getFindInStoreLocations = (
  configuration: ApiClientConfiguration
) => async (
  body: LocatorRequest,
  params?: { enhancedStoreSearch?: boolean; filterBOPISStores?: boolean }
): Promise<AxiosResponse<LocatorResponse>> => {
  const baseUrl = endpoints.get(configuration).stores.search;
  configuration.logger.debug('Stores Locator', {
    url: baseUrl,
  });

  return axios.post(
    urlBuilder(baseUrl, {
      config: configuration,
    }),
    body,
    {
      headers: headerBuilder(configuration),
      params,
    }
  );
};
