export enum MonetateEventType {
  DecisionRequest = 'monetate:decision:DecisionRequest',
  PageView = 'monetate:context:PageView',
  ProductDetailView = 'monetate:context:ProductDetailView',
  Referrer = 'monetate:context:Referrer',
  ScreenSize = 'monetate:context:ScreenSize',
  ProductView = 'monetate:context:ProductView',
  SearchView = 'monetate:context:SearchView',
  Language = 'monetate:context:Language',
  Purchase = 'monetate:context:Purchase',
  UserAgent = 'monetate:context:UserAgent',
  CustomVariables = 'monetate:context:CustomVariables',
  Coordinates = 'monetate:context:Coordinates',
  Cart = 'monetate:context:Cart',
  ThumbnailView = 'monetate:context:ProductThumbnailView',
  ThumbnailSkuView = 'monetate:context:ProductThumbnailSkuView',
  RecClicks = 'monetate:record:RecClicks',
  RecImpressions = 'monetate:record:RecImpressions',
}

export enum MonetateActionType {
  OmnichannelJson = 'monetate:action:OmnichannelJson',
}

export interface MonetateDecisionRequestBody {
  channel: string;
  domain: string;
  monetateId?: string;
  customerId?: string;
  events: MonetateEvent[];
}

export type MonetateEvent =
  | MonetateDecisionEvent
  | MonetateContextPageViewEvent
  | MonetateProductDetailEvent
  | MonetateReferrerEvent
  | MonetateScreenSizeEvent
  | MonetateProductViewEvent
  | MonetatePurchaseEvent
  | MonetateUserAgentEvent
  | MonetateSearchViewEvent
  | MonetateLanguageEvent
  | MonetateCustomVariablesEvent
  | MonetateCoordinatesEvent
  | MonetateCartEvent
  | MonetateRecEvent
  | MonetateThumbnailView
  | MonetateThumbnailSkuView;

export interface MonetateDecisionEvent {
  includeReporting?: boolean;
  requestId: string;
  domain: string;
  eventType: MonetateEventType;
}
export interface MonetateContextPageViewEvent {
  eventType: MonetateEventType;
  url?: string;
  pageType?: string;
  categories?: string[];
  breadcrumbs?: string[];
}

export interface MonetateCustomVariablesEvent {
  eventType: MonetateEventType;
  customVariables: MonetateCustomVariable[];
}

export interface MonetateCustomVariable {
  variable: string;
  value: string;
}

export interface MonetateProductDetailEvent {
  eventType: MonetateEventType;
  products: MonetateProduct[];
}

export interface MonetatePurchaseEvent {
  eventType: MonetateEventType;
  account: string;
  domain: string;
  instance: 'p' | 'd';
  purchaseId: string;
  purchaseLines: MonetatePurchaseLine[];
}

export interface MonetatePurchaseLine {
  sku: string;
  pid: string;
  quantity: number;
  currency: string;
  value: string;
}

export interface MonetateReferrerEvent {
  eventType: MonetateEventType;
  referrer: string;
}
export interface MonetateScreenSizeEvent {
  eventType: MonetateEventType;
  height: number;
  width: number;
}

export interface MonetateUserAgentEvent {
  eventType: MonetateEventType;
  userAgent: string;
}

export interface MonetateProductViewEvent {
  eventType: MonetateEventType;
  products: string[];
}

export interface MonetateThumbnailView {
  eventType: MonetateEventType;
  products: string[];
}

export interface MonetateThumbnailSkuView {
  eventType: MonetateEventType;
  productSkus: string[];
}

export interface MonetateSearchViewEvent {
  eventType: MonetateEventType;
  searchTerm: string;
  searchType: 'site' | 'category';
  searchTermType: 'text' | 'product_id';
  // searchActionId should only be provided when search is provided by Monetate
  searchActionId?: string;
}
export interface MonetateLanguageEvent {
  eventType: MonetateEventType;
  language: string;
}
export interface MonetateCoordinatesEvent {
  eventType: MonetateEventType;
  latitude: string;
  longitude: string;
}

export interface MonetateDecisionResponseBody {
  meta: {
    code: number;
    monetateId: string;
  };
  data: {
    responses: MonetateResponse[];
  };
}

export interface MonetateResponse {
  requestId: string;
  actions: MonetateAction[];
}

export interface MonetateProduct {
  productId: string;
  sku: string;
}
export interface MonetateRecommendedProduct {
  description: string;
  id: string;
  imageLink: string;
  itemGroupId: string;
  link: string;
  price: number;
  productType: string;
  recSetId: number;
  salePrice: number;
  slotIndex: number;
  title: string;
  recToken?: string;
}

export interface MonetateAction {
  impressionReporting: MonetateImpressionReport[];
  json?: Record<string, unknown>;
  actionType?: MonetateActionType;
  actionId: number;
  isControl: boolean;
  items: MonetateRecommendedProduct[];
}

export interface MonetateImpressionReport {
  is_control: boolean;
  has_targets: boolean;
  tags: [];
  experience_type: string;
  experience_name: string;
  experience_id: number;
  experience_label: string;
  variant_label: string;
  control_allocation?: number;
}

export interface MonetateCartEvent {
  eventType: MonetateEventType;
  cartLines: MonetateCartLine[];
}

export interface MonetateCartLine {
  sku: string;
  pid: string;
  quantity: number;
  currency: string;
  value: string;
}

export interface MonetateRecEvent {
  eventType: MonetateEventType;
  recClicks?: string[];
  recImpressions?: string[];
}
