import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { Cart } from '../../types';
import { ApiClientConfiguration } from '../../configuration';

export type ApplyPaymentInstrumentRequestObject = {
  cartId: string;
  payment_method_id: string;
  c_gcCardNumber?: string | null;
  c_gcEAN?: string | null;
  paymentInstrumentId?: string;
  recaptcha_response?: string;
  additionalDetails?: { paymentData: string };
  isTemporary?: boolean;
  context?: string;
};

export const getCartPaymentInstruments = (
  configuration: ApiClientConfiguration
) => (data: ApplyPaymentInstrumentRequestObject) => {
  const body = {
    payment_method_id: data.payment_method_id,
    customer_payment_instrument_id: data.paymentInstrumentId,
    recaptcha_response: data.recaptcha_response,
    ...(data.recaptcha_response && {
      recaptcha_response: data.recaptcha_response,
    }),
    ...(data.c_gcCardNumber !== null && {
      c_gcCardNumber: data.c_gcCardNumber,
    }),
    ...(data.c_gcEAN !== null && { c_gcEAN: data.c_gcEAN }),
    ...(data.additionalDetails !== null && {
      additionalDetails: data.additionalDetails,
    }),
  };
  return axios.post<Cart>(
    urlBuilder(endpoints.get(configuration).cart.paymentInstruments, {
      config: configuration,
      mapping: {
        id: data.cartId,
      },
    }),
    body,
    {
      headers: headerBuilder(configuration),
      params: {
        ...(data.isTemporary && {
          bid: 'cookie',
        }),
        ...(data.context && {
          context: data.context,
        }),
      },
    }
  );
};
