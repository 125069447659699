import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const createCartAddItem = (configuration: ApiClientConfiguration) => (
  body: unknown,
  { query = '' } = {}
): Promise<any> => {
  let url = endpoints.get(configuration).cart.createCartAddItem;
  if (query) {
    url += `?${query}`;
  }

  return axios.post(urlBuilder(url, { config: configuration }), body, {
    headers: headerBuilder(configuration),
  });
};
