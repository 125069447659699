import { ComponentInstance } from '../types';

const isNuanceUsed = (instance: ComponentInstance) =>
  instance.$getEnvValueByCurrentLocale('LIVE_CHAT_VENDOR') === 'Nuance';

export const useNuanceChat = (instance: ComponentInstance) => {
  if (!isNuanceUsed(instance)) {
    return;
  }

  const { path } = instance.$route;
  const pageIdsMapped: Record<string, number> = JSON.parse(
    instance.$env.NUANCE_PAGE_IDS
  );

  if (pageIdsMapped[path]) {
    instance.$log.debug('Reinitialising Nuance Proactive Chat, found path', {
      path,
      id: pageIdsMapped[path],
    });
    window.Inq.reinitChat(pageIdsMapped[path]);
  }
};
