import compare from './compare';
import get from './get';
import flatten from './flatten';

export default <T>(
  arr: T[],
  ...paths: (string | string[] | ((item: T) => any) | ((item: T) => any)[])[]
): T[] =>
  flatten(paths).reduce(
    (source: T[], path: string | ((item: T) => any)): T[] =>
      [...source].sort((a: T, b: T): number =>
        typeof path === 'function'
          ? compare(path(a), path(b))
          : compare(get(a, path), get(b, path))
      ),
    arr
  );
