import { AxiosResponse } from 'axios';
import { axios } from '../../helpers';

type GetUGCModerartionStatusParams = {
  baseUrl: string;
  recipeId: string;
};

type UGCModerartionStatus = {
  status: 'pending' | 'declined' | 'approved';
};

export const getUGCModerationStatus = (
  params: GetUGCModerartionStatusParams
): Promise<AxiosResponse<UGCModerartionStatus>> =>
  axios.get(`${params.baseUrl}?recipe_id=${params.recipeId}`);
