import { extractProperty, getDynamicField } from './utils';
import { CMCollection } from '@vf/api-contract';

export const pdpBenefitRating = (data: CMCollection) => {
  const benefitItems = [];
  if (data.teasableItems?.length) {
    data.teasableItems.forEach((item) => {
      benefitItems.push({
        title: item.title,
        benefitAttributeName: getDynamicField(item, 'benefitAttributeName', ''),
      });
    });
  }

  return {
    component: 'pdp/ProductBenefitRating',
    sfComponent: false,

    props: {
      title: extractProperty(data, 'teaserTitle', '$Benefit Rating$'),
      benefitItems: benefitItems,
    },
  };
};
