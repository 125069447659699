import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils';

export const cartNotification = (data: CMPlaceholder) => {
  return {
    component: 'cart/CartNotification',
    props: {
      translations: {
        updatedOrderReasons: getTranslation(
          data,
          'updatedOrderReasons',
          '$Updated order reasons$'
        ),
        itemNotAvailable: getTranslation(
          data,
          'itemNotAvailable',
          '$Item not available$'
        ),
        updatedOrderTotal: getTranslation(
          data,
          'updatedOrderTotal',
          '$Updated orde total$'
        ),
      },
    },
  };
};
