import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { ShareFavoritesResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const shareFavorites = (configuration: ApiClientConfiguration) => (
  favoriteId: string
): Promise<AxiosResponse<ShareFavoritesResponse>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).wishList.shareFavorites,
    {
      config: configuration,
      mapping: {
        favoriteId,
      },
    }
  );
  return axios.post(
    url,
    {},
    {
      headers: headerBuilder(configuration),
    }
  );
};
