import { CMCollection, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  generateLinkFromTeaserTargets,
  getDynamicField,
} from './utils';
import { ComposableContext, SearchResultToggle } from '../types';

export const searchResultsToggle = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const teasableItems = extractProperty(data, 'teasableItems', []);

  const getToggles = (): SearchResultToggle[] =>
    teasableItems.map((t) => ({
      label: t.teaserTitle,
      value: generateLinkFromTeaserTargets(
        t.teaserTargets,
        context,
        siteConfiguration,
        t.urlParams
      ),
    }));

  return {
    component: 'search/SearchResultsToggle',
    props: {
      displayProductsCount: getDynamicField(
        data,
        'displayProductsCount',
        false
      ),
      toggles: getToggles(),
    },
  };
};
