import {
  CMPlaceholder,
  OrderSuccessDetailsTranslation,
} from '@vf/api-contract';
import { getTranslation } from './utils';

export const orderSuccessDetails = (data: CMPlaceholder) => {
  const translations: OrderSuccessDetailsTranslation = {
    shippingInfoLabel: getTranslation(
      data,
      'shippingInfoLabel',
      '$Shipping Info$'
    ),
    shippingToLabel: getTranslation(data, 'shippingToLabel', '$Shipping To$'),
    shippingMethodLabel: getTranslation(
      data,
      'shippingMethodLabel',
      '$Shipping Method$'
    ),
    addressLabel: getTranslation(data, 'addressLabel', '$Address$'),
    billingInfoLabel: getTranslation(
      data,
      'billingInfoLabel',
      '$Billing Info$'
    ),
    pickupInfoLabel: getTranslation(data, 'pickupInfoLabel', '$Pickup Info$'),
    pickupLocationLabel: getTranslation(
      data,
      'pickupLocationLabel',
      '$Pickup Location$'
    ),
    pickupPersonLabel: getTranslation(
      data,
      'pickupPersonLabel',
      '$Pickup Person$'
    ),
    altPickupPersonLabel: getTranslation(
      data,
      'altPickupPersonLabel',
      '$Alt Pickup Person$'
    ),
    direction: getTranslation(data, 'direction', '$Directions$'),
    detailsInfoLabel: getTranslation(data, 'detailsInfoLabel', '$Details$'),
    detailsIntroText: getTranslation(
      data,
      'detailsIntroText',
      '$We will send you a notification when your order is ready for pickup$'
    ),
    detailsQuestionText: getTranslation(
      data,
      'detailsQuestionText',
      '$What do I do when I get to the store?$'
    ),
    detailsInStoreStepsTexts: getTranslation(data, 'detailsInStoreStepsTexts', [
      '$Please stop by within the next 2 days.$',
      '$Bring a valid ID and a copy of the receipt (email or paper).$',
      '$IN-STORE: let a sales associate know you’re there to pick up your order.$',
    ]),
    detailsCurbsideStepsTexts: getTranslation(
      data,
      'detailsCurbsideStepsTexts',
      [
        '$Please stop by within the next 2 days.$',
        '$Bring a valid ID and a copy of the receipt (email or paper).$',
      ]
    ),
    detailsInStoreAndCurbsideStepsTexts: getTranslation(
      data,
      'detailsInStoreAndCurbsideStepsTexts',
      [
        '$Please stop by within the next 2 days.$',
        '$Bring a valid ID and a copy of the receipt (email or paper).$',
        '$IN-STORE: let a sales associate know you’re there to pick up your order.$',
      ]
    ),
    paymentMethodLabel: {
      REWARD_CODE: '$Reward Code$',
      CREDIT_CARD: '$Credit Card$',
      GIFT_CARD: '$Gift Card$',
      REWARD_CARD: '$Reward Card$',
      PAYPAL: '$PayPal$',
      KLARNA: '${{klarnaIconRed}} 4 interest-free payments$',
      DW_APPLE_PAY: '$Apple Pay$',
      ATHLETE_CREDIT: '$Athlete Credit$',
      LOYALTY_POINTS: '$Loyalty Points$',
      ...getTranslation(data, 'paymentMethodLabel', {}),
    },
    loyaltyPointsDisplayText: getTranslation(
      data,
      'loyaltyPointsDisplayText',
      '${{dollarAmount}} off ({{pointAmount}} points)$'
    ),
    billingToLabel: getTranslation(data, 'billingToLabel', '$Billing To$'),
    paymentLabel: getTranslation(data, 'paymentLabel', '$Payment$'),
    quantity: getTranslation(data, 'quantity', '$Quantity$'),
    price: getTranslation(data, 'price', '$Price$'),
    total: getTranslation(data, 'total', '$Total$'),
    color: getTranslation(data, 'color', '$Color$'),
    size: getTranslation(data, 'size', '$Size$'),
    cardNumberReplacement: getCardNumberReplacment(data),
    summerReward: getTranslation(data, 'summerReward', '$Summer {{year}}$'),
    winterReward: getTranslation(data, 'winterReward', 'Winter {{year}}$'),
    springReward: getTranslation(data, 'springReward', 'Spring {{year}}$'),
  };

  return {
    component: 'confirmation/SuccessDetails',
    props: {
      translations,
    },
  };
};

const getCardNumberReplacment = (data: CMPlaceholder): string => {
  const replacement = getTranslation(data, 'cartNumberReplacementText', '');
  // Hack for card mask as we can't set white space as first character in CMS
  if (!replacement) return '';
  return replacement.startsWith(':') ? replacement : ' ' + replacement;
};
