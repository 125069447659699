























































































































































import Notifications from '@/components/smart/layout/Notifications.vue';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';
import { useAccount, useNotification, useValidation } from '@vf/composables';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import {
  translateFromApiDateFormat,
  translateToApiDateFormat,
} from '@vf/shared/src/utils/form-helpers';
import {
  checkAge,
  checkDate,
  checkName,
  checkPhone,
  stripPhoneAndPrependUSCAAreaCode,
} from '@vf/shared/src/utils/helpers';
import { defineComponent, ref } from '@vue/composition-api';
import { mask } from 'ke-the-mask';
import { validationMixin } from 'vuelidate';
import { helpers } from 'vuelidate/lib/validators';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';

export default defineComponent({
  name: 'ProgressiveProfile',
  components: { Notifications },
  directives: { mask },
  mixins: [validationMixin],
  setup() {
    const { root } = useRootInstance();
    const { clearNotifications } = useNotification(root);
    const { setNotificationVisibility } = useModal();
    const {
      activateProgressiveProfileConfirmation,
      deactivate,
    } = useSimplifiedEnrollmentStore(root);
    const { setValidation } = useValidation(root, 'PROGRESSIVE_PROFILE_MODAL');
    const {
      basicInformation,
      setBasicInformation,
      setSmsSubscription,
    } = useAccount(root);
    const { displayErrorMessages } = errorMessages(root);

    setNotificationVisibility(true);

    const t = (key: string) =>
      root.$t(`progressiveProfileModal.${key}`) as string;

    const currentStep = ref<1 | 2 | 3>(
      !basicInformation.value.phone
        ? 1
        : !(
            basicInformation.value.firstName &&
            basicInformation.value.lastName &&
            basicInformation.value.postalCode &&
            basicInformation.value.birthDate
          )
        ? 2
        : 3
    );
    const isSaving = ref(false);

    const phone = ref(basicInformation.value.phone);
    const smsNotificationConsent = ref(false);

    const firstName = ref(basicInformation.value.firstName);
    const lastName = ref(basicInformation.value.lastName);
    const zipCode = ref(basicInformation.value.postalCode);
    const birthDate = ref(
      translateFromApiDateFormat(basicInformation.value.birthDate)
    );

    const INTERESTS = [
      'skateboarding',
      'surfing',
      'snowboarding',
      'bmx',
      'music',
      'drawing_and_painting',
      'design',
      'fashion',
      'photography',
      'videography',
    ].map((code) => ({
      code,
      title: t(`${code}Title`),
      image: require(`@vf/ui/assets/interests/${code}.webp`),
    }));

    const interests = ref(
      INTERESTS.map((i) => ({
        ...i,
        isActive:
          basicInformation.value.preferences.interests?.includes(i.code) ??
          false,
      }))
    );

    const handleInterestsChange = ({ code, isActive }) => {
      const interest = interests.value.find(
        (interest) => interest.code === code
      );
      interest.isActive = isActive;
    };

    const validateStep = async () => {
      if (currentStep.value === 1) {
        if (smsNotificationConsent.value) {
          try {
            isSaving.value = true;
            const phoneNumber = stripPhoneAndPrependUSCAAreaCode(phone.value);
            setSmsSubscription(phoneNumber, true);
          } catch (error) {
            displayErrorMessages(error);
          } finally {
            isSaving.value = false;
          }
        }

        if (phone.value) {
          await updateProfile({ phone: phone.value });
        }
        // if step 2 already filled before, skip it
        if (
          basicInformation.value.firstName &&
          basicInformation.value.lastName &&
          basicInformation.value.postalCode &&
          basicInformation.value.birthDate
        ) {
          currentStep.value = 3;
        } else {
          currentStep.value = 2;
        }
      } else if (currentStep.value === 2) {
        const payload = {
          ...(firstName.value && { firstName: firstName.value }),
          ...(lastName.value && { lastName: lastName.value }),
          ...(zipCode.value && { postalCode: zipCode.value }),
          ...(birthDate.value && {
            birthDate: translateToApiDateFormat(birthDate.value),
          }),
        };

        if (Object.values(payload).length) {
          await updateProfile(payload);
        }

        const selectedInterests = interests.value
          .filter((i) => i.isActive)
          .map((i) => i.code);

        // if step 3 already filled before, skip it
        if (selectedInterests.length === 0) {
          currentStep.value = 3;
        } else {
          setNotificationVisibility(false);
          activateProgressiveProfileConfirmation();
        }
      } else if (currentStep.value === 3) {
        const selectedInterests = interests.value
          .filter((i) => i.isActive)
          .map((i) => i.code);

        if (selectedInterests.length) {
          await updateProfile({
            preferences: {
              interests: selectedInterests.join('|'),
            },
          });
        }
        setNotificationVisibility(false);
        activateProgressiveProfileConfirmation();
      }
    };

    const updateProfile = async (
      data: Record<any, any>,
      onSuccess?: () => void
    ) => {
      isSaving.value = true;
      const response = await setBasicInformation(data);
      isSaving.value = false;

      if (response) {
        clearNotifications();
        onSuccess?.();
      }
    };

    const getBirthdateMessage = (validationObj) => {
      if (!validationObj.$error) return;
      if (!validationObj.valid) {
        return t('incorrectDateFormat');
      }
      if (!validationObj.minAge) {
        return t('minAgeError');
      }
    };

    const skipStep = () => {
      clearNotifications();
      if (currentStep.value === 3) {
        setNotificationVisibility(false);
        activateProgressiveProfileConfirmation();
      } else {
        currentStep.value++;
      }
    };

    const close = () => {
      setNotificationVisibility(false);
      deactivate();
    };

    return {
      isSaving,
      currentStep,
      phone,
      smsNotificationConsent,
      firstName,
      lastName,
      zipCode,
      birthDate,
      interests,
      basicInformation,
      t,
      handleInterestsChange,
      validateStep,
      setValidation,
      getBirthdateMessage,
      close,
      activateProgressiveProfileConfirmation,
      skipStep,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations() {
    return {
      phone: {
        valid: () => checkPhone(this.phone),
      },
      firstName: {
        valid: checkName(),
      },
      lastName: {
        valid: checkName(),
      },
      zipCode: {
        valid: helpers.regex('valid', /^(?!^00000$)\d{5}(?:-\d{4})?$/),
      },
      birthDate: {
        valid: checkDate(),
        minAge: checkAge(16),
      },
    };
  },
});
