import { getDynamicField, getTranslation } from './utils/extractProperty';

export const plpCtaViewAll = (data: CmsPlpCtaViewAll) => {
  const config = getConfigValues(data);

  return {
    component: 'shared/ViewMoreProductsButtons',
    props: {
      icon: getIconName(data),
      openTarget: config['open-window'],
      size: config.size,
      disabled: config.disable,
      modalId: config['modal-id'],
      style: config.style,
      class: config.class,
      url: config.url,
      alternateStyle: config['alternate-style'],
      showViewAll: getDynamicField(data, 'showViewAll', true),
      maxQuery: getDynamicField(data, 'maxQuery', 200),
      infiniteScroll: getDynamicField(data, 'infiniteScroll', false),
      infiniteScrollOffset: getDynamicField(data, 'infiniteScrollOffset', {
        large: '435px',
        medium: '460px',
        small: '280px',
      }),
      showProgressBar: getDynamicField(data, 'showProgressBar', false),
      stackButtonsMobile: getDynamicField(data, 'stackButtonsMobile', true),
      translations: {
        viewAllButtonText: getTranslation(
          data,
          'viewAllButtonText',
          '$View All ({{quantity}})$'
        ),
        viewMoreButtonText: getTranslation(
          data,
          'viewMoreButtonText',
          '$View {{quantity}} More$'
        ),
        itemsCountPlural: getTranslation(data, 'itemsCountPlural', '$Items$'),
        itemsCountSingular: getTranslation(
          data,
          'itemsCountSingular',
          '$Item$'
        ),
        progressBarLabel: getTranslation(
          data,
          'progressBarLabel',
          `You've viewed {{loaded}} of {{total}} products`
        ),
      },
    },
  };
};

const getConfigValues = (data: CmsPlpCtaViewAll) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'plp-view-all-properties'
    ) as CmsPlpViewAllProperty;

    return config.values;
  } catch (e) {
    console.error('PLP View All CTA component error: no config found.');
  }

  return {
    openTarget: '',
    size: '',
    disable: false,
    modalId: '',
    style: '',
    class: '',
    url: '',
    alternateStyle: '',
  };
};

const getIconName = (data: CmsPlpCtaViewAll) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'icon-properties'
    ) as CmsPlpViewAllIconProperty;

    return config.values;
  } catch (e) {
    console.error('PLP View All CTA component error: no icon found.');
  }

  return 'list';
};

export type CmsPlpCtaViewAll = {
  id: string;
  type: 'CMTeaser';
  name: string;
  title: string;
  componentConfig: (CmsPlpViewAllProperty | CmsPlpViewAllIconProperty)[];
  viewtype: 'plp-cta-view-all';
};

export type CmsPlpViewAllProperty = {
  name: 'plp-view-all-properties';
  values: {
    'open-window': 'new';
    size: 'full';
    disable: boolean;
    'modal-id': string;
    style: 'primary' | 'secondary';
    class: string;
    url: string;
    'alternate-style': string;
  };
};

export type CmsPlpViewAllIconProperty = {
  name: 'icon-properties';
  values: {
    'icon-name': string;
  };
};
