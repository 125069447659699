import { IncomingMessage } from 'http';
import { getClientIp } from '@supercharge/request-ip';
import { getCookieByName } from '@vf/composables/src/utils/cookie';
import axiosclient from './helpers/axios';
import { Logger } from '@vf/api-contract';

export interface ApiClientConfiguration {
  apiUrl: string;
  previewApiUrl: string;
  isPreview: boolean;
  apiHost: string;
  brand: string;
  source: string;
  channel: string;
  siteId: string;
  locale: string;
  region: string;
  req: IncomingMessage;
  endpoints: Record<string, Record<string, string>>;
  ssrAccessToken: string | null;
  ssrTrustId: string | null;
  ssrBypassMaintenanceHeader?: string;
  timeout: number;
  logger: Logger;
}

const baseConfig: ApiClientConfiguration = {
  apiUrl: '',
  previewApiUrl: '',
  isPreview: false,
  apiHost: '',
  brand: '',
  source: '',
  channel: '',
  siteId: '',
  locale: '',
  region: '',
  req: null,
  endpoints: {},
  ssrAccessToken: null,
  ssrTrustId: null,
  ssrBypassMaintenanceHeader: null,
  timeout: 0,
  logger: {
    error: console.log,
    warn: console.log,
    info: console.log,
    http: console.log,
    verbose: console.log,
    debug: console.log,
    silly: console.log,
  },
};

const getUserAgent = (req: IncomingMessage) => {
  if (req) {
    return req.headers['user-agent'];
  } else if (typeof window !== 'undefined') {
    return window.navigator.userAgent;
  }

  return null;
};

const configureAxios = (request: IncomingMessage, timeout: number): void => {
  const ip = request
    ? request.headers['true-client-ip']?.toString() || getClientIp(request)
    : getCookieByName('user-ip');

  const headers = {
    'xx-user-agent': getUserAgent(request),
    'xx-forwarded-for': ip,
  };

  axiosclient.defaults.headers.common = {
    ...(axiosclient.defaults.headers.common || {}),
    ...headers,
    'x-forwarded-headers': Object.keys(headers).join(','),
  };

  axiosclient.defaults.timeout = timeout;
};

export const setup = (
  params: ApiClientConfiguration
): ApiClientConfiguration => {
  const config = { ...baseConfig };
  for (const key in config) {
    config[key] = params[key] || config[key];
  }

  configureAxios(params.req, config.timeout);
  return config;
};

export const set401Handler = (handler: (url: string) => Promise<void>) => {
  axiosclient.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        await handler(error.config.url);
      }
      return Promise.reject(error);
    }
  );
};
