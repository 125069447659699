




















































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  inject,
} from '@vue/composition-api';
import { CmsBreadcrumbProp } from '../types';

import { AnalyticsDataDomLocation } from '../../theme/types';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import productsGridCache from '@vf/shared/src/theme/productsGridCache';

export default defineComponent({
  name: 'Breadcrumbs',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    breadcrumbs: {
      type: Array as PropType<CmsBreadcrumbProp[]>,
      default: () => [],
    },
    clickableLastItem: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // To be cleaned up with GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');
    const last: ComputedRef<number> = computed(
      () => props.breadcrumbs.length - 1
    );
    const backIndex: ComputedRef<number> = computed(
      () => props.breadcrumbs.length - 2
    );
    const backRoute = computed(() => {
      return props.breadcrumbs[backIndex.value]
        ? props.breadcrumbs[backIndex.value].link
        : '/';
    });
    const clickHandler = () => {
      productsGridCache.checkForCache = true;
    };
    return {
      backIndex,
      backRoute,
      last,
      clickHandler,
      AnalyticsDataDomLocation,
      isVansPdpRedesignEnabled,
    };
  },
});
