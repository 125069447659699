import { FlashError } from '@vf/api-client/src/types';

const getProductId = (flash: FlashError): string => {
  return flash.details?.productId;
};
export const findFlashIndex = (
  flashes: FlashError[],
  flash: FlashError
): number =>
  flashes.findIndex((existingFlash) => {
    const productId = getProductId(flash);
    const existingProductId = getProductId(existingFlash);
    if (productId && existingProductId) {
      return productId === existingProductId;
    }
    return [
      existingFlash.code,
      flash.code,
      existingFlash.path,
      flash.path,
      existingFlash.code === flash.code,
      existingFlash.path === flash.path,
    ].every(Boolean);
  });
