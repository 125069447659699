import { CMPlaceholder } from '@vf/api-contract';
import {
  getTranslation,
  getDynamicField,
  getValidationConfigValues,
} from './utils';
import { MappingMethod } from '../types';
import { extractInterests } from './utils/extractInterests';

export const getBasicInfoTranslation = (data) => ({
  heading: getTranslation(data, 'heading', '$Basic Information$'),
  subheading: getTranslation(data, 'subHeading', '$First, the basics.$'),
  firstSubtitle: getTranslation(data, 'firstSubtitle', '$Account Email$'),
  secondSubtitle: getTranslation(data, 'secondSubtitle', '$Personal Info$'),
  buttonText: getTranslation(data, 'buttonText', '$Save Info$'),
  firstNameLabel: getTranslation(data, 'firstNameLabel', '$First Name$'),
  emailLabel: getTranslation(data, 'emailLabel', '$Email Address$'),
  phoneNumberLabel: getTranslation(data, 'phoneNumberLabel', '$Phone$'),
  phoneText: getTranslation(
    data,
    'phoneText',
    '$To reach you about your order.$'
  ),
  birthdayHeading: getTranslation(
    data,
    'birthdayHeading',
    '$Get a birthday gift$'
  ),
  birthdaySubheading: getTranslation(
    data,
    'birthdaySubheading',
    `$From birthday shoutouts to gift with purchase, we're big on celebrating you.$`
  ),
  shoesSizeDescription: getTranslation(
    data,
    'shoesSizeLabel',
    `$How big are those feet?</br>Share your shoe size. We can add others later.$`
  ),
  shoesSizeLabel: getTranslation(data, 'shoesSizeLabel', '$Select Size$'),
  streetLabel: getTranslation(data, 'streetLabel', '$Street Address$'),
  apartmentLabel: getTranslation(
    data,
    'apartmentLabel',
    '$Apartment/Unit/Suite$'
  ),
  cityLabel: getTranslation(data, 'cityLabel', '$City$'),
  phoneLabel: getTranslation(data, 'phoneLabel', '$Cell Phone$'),
  countryCodeLabel: getTranslation(data, 'countryCodeLabel', '$Country Code$'),
  provinceLabel: getTranslation(data, 'provinceLabel', '$Choose State$'),
  zipCodeLabel: getTranslation(data, 'zipCodeLabel', '$Zip Code$'),
  requiredFields: getTranslation(data, 'requiredFields', '$*Required fields$'),
  helpText: getTranslation(data, 'helpText', {
    birthday: '$So we can recognize you on your (special) day.$',
    phoneNumber: '$So you can use this as your member ID in store or outlet.$',
    zipCode: '$So we can send rewards in the correct currency.$',
    close: '$Close$',
  }),
  genderLabel: getTranslation(data, 'genderLabel', '$Gender$'),
  lastNameLabel: getTranslation(data, 'lastNameLabel', '$Last Name$'),
  birthdayLabel: getTranslation(data, 'birthdayLabel', '$Birthday$'),
  genders: getTranslation(data, 'genders', [
    { id: 'M', label: '$Male$' },
    { id: 'F', label: '$Female$' },
  ]),
  memberId: getTranslation(data, 'memberId'),
  loyaltyStateNotEnrolled: getTranslation(
    data,
    'loyaltyStateNotEnrolled',
    '$You are not currently enrolled in XPLR Pass.$'
  ),
  loyaltyStateEnrolled: getTranslation(
    data,
    'loyaltyStateEnrolled',
    '$loyaltyStateEnrolled$'
  ),
  viewPointsLabel: getTranslation(data, 'viewPointsLabel', '$View points$'),
  setBasicInformationSuccess: getTranslation(
    data,
    'setBasicInformationSuccess',
    '$PROFILE INFORMATION HAS BEEN SAVED.$'
  ),
  setBasicInformationError: getTranslation(
    data,
    'setBasicInformationError',
    '$CANNOT UPDATE PROFILE INFORMATION.$'
  ),
  shareYourShoeSizeLabel: getTranslation(
    data,
    'shareYourShoeSizeLabel',
    '$Share your shoe size. We can add others later.$'
  ),
  howBigFeetLabel: getTranslation(
    data,
    'howBigFeetLabel',
    '$How big are those feet?$'
  ),
  interestsHeading: getTranslation(data, 'interestsHeading', '$Family$'),
  interestsSubheading: getTranslation(
    data,
    'interestsSubheading',
    '$Tell us 3 things you love to do or know about.$'
  ),
  validationMessages: {
    requiredError:
      getTranslation(data, 'validationMessages.requiredError') ??
      '$Required field$',
    nameError:
      getTranslation(data, 'validationMessages.nameError') ??
      '$Please provide valid name.$',
    emailError:
      getTranslation(data, 'validationMessages.emailError') ??
      '$Please provide valid e-mail address.$',
    birthdayError:
      getTranslation(data, 'validationMessages.birthdayError') ??
      '$Incorrect date format, please use: {{dateFormat}}$',
    minRegisterAgeError:
      getTranslation(data, 'validationMessages.minRegisterAgeError') ??
      '$You must have at least {{minRegisterAge}} years old$',
    phoneError:
      getTranslation(data, 'validationMessages.phoneError') ??
      '$A valid phone number is required$',
    postalCodeError:
      getTranslation(data, 'validationMessages.postalCodeError') ??
      '$Please match the requested format: {{zipCodeLabel}} {{zipCodeFormat}}.$',
  },
});

export const basicAccountForm: MappingMethod = (
  data: CMPlaceholder,
  _,
  siteConfiguration,
  cmsBaseUri
) => {
  return {
    component: 'account/BasicInformationForm',
    props: {
      componentVariant: getDynamicField(data, 'componentVariant', 'VARIANT_B'),
      interests: extractInterests(siteConfiguration, cmsBaseUri),
      translations: getBasicInfoTranslation(data),
      validationConfig: getValidationConfigValues(data),
    },
  };
};
