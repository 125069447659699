import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  extractComponentConfigLink,
  getImageObject,
  getTextWithAttrHrefFromCommonConfig,
  getComponentConfigByName,
  getTranslation,
} from './utils';
import { isEmpty } from '../../utils/isEmpty';

export const checkoutGiftCard = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const config = getComponentConfigByName(
    data,
    'checkout-gift-card-settings',
    {},
    true
  );

  const tooltipHelpImageObject = extractComponentConfigLink(
    config,
    'tooltipHelpImage'
  );

  const tooltipImage = isEmpty(tooltipHelpImageObject)
    ? null
    : getImageObject(
        tooltipHelpImageObject,
        siteConfiguration,
        null,
        cmsBaseUri
      );

  return {
    component: 'checkout/CheckoutGiftCard',
    props: {
      translations: {
        close: getTranslation(data, 'close', '$Close$'),
        title: getTranslation(data, 'title', '$Our Gift Card$'),
        titleForNotLoggedIn: getTranslation(
          data,
          'titleForNotLoggedIn',
          '$Reward or Gift Card$'
        ),
        card_number: getTranslation(data, 'card_number', '$Card Number$'),
        applySuccessMessage: getTranslation(
          data,
          'applySuccessMessage',
          '$Your gift card has been successfully applied$'
        ),
        removeSuccessMessage: getTranslation(
          data,
          'removeSuccessMessage',
          '$Your gift card has been successfully removed$'
        ),
        applyRewardCardSuccessMessage: getTranslation(
          data,
          'applyRewardCardSuccessMessage',
          '$Your reward card has been successfully applied$'
        ),
        removeRewardCardSuccessMessage: getTranslation(
          data,
          'removeRewardCardSuccessMessage',
          '$Your reward card has been successfully removed$'
        ),
        invalidRewardCardErrorMessage: getTranslation(
          data,
          'invalidRewardCardErrorMessage',
          '$Given reward card is not allowed$'
        ),
        notLoggedinRewardCardErrorMessage: getTranslation(
          data,
          'notLoggedinRewardCardErrorMessage',
          '$Please Sign In to your account to apply your rewards card successfully$'
        ),
        notLoggedinRewardCardErrorMessageModal: getTextWithAttrHrefFromCommonConfig(
          getTranslation(data, 'notLoggedinRewardCardErrorMessageModal', ''),
          siteConfiguration
        ),
        pin: getTranslation(data, 'pin', '$PIN$'),
        info: getTranslation(data, 'info', "$Where's the PIN?$"),
        tooltip: getTranslation(
          data,
          'tooltip',
          "$Help text for Where's the PIN button.$"
        ),
        btnCTA: getTranslation(data, 'btnCTA', '$Apply$'),
        captcha: getTranslation(data, 'captcha', '$Captcha$'),
        giftCardApplied: getTranslation(
          data,
          'giftCardApplied',
          '$Gift Card Applied$'
        ),
        rewardCardApplied: getTranslation(
          data,
          'rewardCardApplied',
          getTranslation(data, 'giftCardApplied', '$Reward Card Applied$')
        ),
        removeGiftCard: getTranslation(data, 'removeGiftCard', '$Remove Card$'),
        addAnotherGiftCard: getTranslation(
          data,
          'addAnotherGiftCard',
          '$Add Another Gift Card$'
        ),
        validation_messages: getTranslation(data, 'validation_messages', {
          required: '$This field is required, please add your {{fieldName}}.$',
          digitsLength: '$Must be 16 digits long.$',
          pinLength: '$4 or 8 digits.$',
        }),
      },
      accordionOpened: extractProperty(config, 'values.accordionOpened', false),
      tooltipHelpImage: tooltipImage?.small || '',
      maxGiftCards: extractProperty(config, 'values.max-gift-cards', 2),
    },
  };
};
