/**
 * Method to convert hyphens to camel case (camelCase)
 * For example: utility-navigation -> utilityNavigation
 * @param str
 */
export const camelCase = (str: string): string => {
  if (!str) return '';
  return str.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};
