import {
  extractProperty,
  decorateHtmlValue,
  getTitle,
  getDynamicField,
  generateLinkFromTeaserTargets,
  getStateManagementData,
  getSegmentsData,
} from './utils';
import { CmsSite, CMCollection } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const dashboardLayout = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'account/Dashboard',
    props: {
      dashboardItems: data.teasableItems
        .map((item) => {
          const stateFulfilled = context.instance.$root.$stateManagementChecker(
            getStateManagementData(item)
          );
          const segmentsFulfilled = context.instance.$root.$segmentsChecker(
            getSegmentsData(item)
          );
          if (!stateFulfilled || !segmentsFulfilled) {
            return null;
          }
          return {
            id: extractProperty(item, 'id'),
            title: getTitle(item),
            text: decorateHtmlValue(
              item.teaserRichTextObject,
              cmsBaseUri,
              context,
              siteConfiguration
            ),
            icon: getDynamicField(item, 'icon', ''),
            target: generateLinkFromTeaserTargets(
              item.teaserTargets,
              context,
              siteConfiguration
            ),
          };
        })
        .filter(Boolean),
    },
  };
};
