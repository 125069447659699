import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getPaymentMethods = (configuration: ApiClientConfiguration) => (
  cartId: string,
  query: string
): Promise<any> => {
  let url: string = urlBuilder(
    endpoints.get(configuration).checkout.getPaymentMethods,
    {
      config: configuration,
      mapping: { basketId: cartId },
    }
  );
  url = `${url}?${query}`;

  return axios.get(url, {
    headers: headerBuilder(configuration),
  });
};
