import {
  CMPicture,
  CMPictureCropType,
  CmsSite,
  CMTeaser,
  CMVideo,
  ResponsiveMedia,
} from '@vf/api-contract';
import {
  extractProperty,
  getImageObject,
  getMediaSeoAttributes,
  getTeaserStyle,
  getTitle,
  getVideoResponsiveMedia,
  getVideoObject,
} from './utils';
import { ComposableContext } from '../types';

export const heroItem = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  componentConfiguration: Record<string, any> = {}
) => {
  return {
    component: 'shared/HeroItem',
    props: {
      hero: {
        ...getImageOrVideoData(data, siteConfiguration, cmsBaseUri),
        id: data.id,
        headingText: getTitle(data),
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        titleClassModifier: getTeaserStyle(data, 'teaserTitleClassModifiers'),
        headingLevel: getTeaserStyle(data, 'teaserTitleHeadingLevel'),
        subtitleColor: getTeaserStyle(data, 'teaserSubTitleColor'),
        subtitleClassModifier: getTeaserStyle(
          data,
          'teaserSubTitleClassModifiers'
        ),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        titleBackgroundColor: getTeaserStyle(
          data,
          'teaserTitleBackgroundColor'
        ),
        subtitleFontSize: getTeaserStyle(data, 'teaserSubTitleFontSize'),
        subtitleFontWeight: getTeaserStyle(data, 'teaserSubTitleFontWeight'),
        subtitleBackgroundColor: getTeaserStyle(
          data,
          'teaserSubTitleBackgroundColor'
        ),

        subtitleFontFamily: getTeaserStyle(data, 'teaserSubTitleFontFace'),
        subheadingText: data.useRichText
          ? data.teaserRichTextObject?.html || ''
          : data.teaserText,
        useRawHtml: data.useRichText,
        buttonText: extractProperty(data, 'teaserTargets[0].callToActionText'),
        buttonVisible: extractProperty(
          data,
          'teaserTargets[0].callToActionEnabled',
          false
        ),
      },
      noCrop: extractProperty(componentConfiguration, 'noCrop', false),
      bottomHeroImage: null,
      heroText: null,
    },
  };
};

const getImageOrVideoData = (
  item: CMTeaser | CMVideo | CMPicture,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const getImageData = (
    imageData: CMPicture,
    responsiveMedia?: ResponsiveMedia | null
  ) => ({
    ...getImageObject(
      imageData,
      siteConfiguration,
      CMPictureCropType.LANDSCAPE_RATIO_8x3,
      cmsBaseUri,
      responsiveMedia
    ),
    seo: getMediaSeoAttributes(imageData),
    width: '100%',
    height: 640,
  });

  switch (item.type) {
    case 'CMPicture':
      return {
        image: getImageData(item),
      };
    case 'CMTeaser':
      if (item.pictures && item.pictures.length) {
        return {
          image: getImageData(item.pictures[0], item.responsiveMedia),
        };
      }
      if (item.videos && item.videos.length) {
        return {
          video: {
            small: getVideoObject(item.videos, cmsBaseUri),
            medium: getVideoResponsiveMedia(item, cmsBaseUri, 'medium'),
            large: getVideoResponsiveMedia(item, cmsBaseUri, 'large'),
          },
        };
      }
      return {};

    case 'CMVideo':
      return {
        video: {
          small: getVideoObject([item], cmsBaseUri),
          medium: null,
          large: null,
        },
      };
  }
};
