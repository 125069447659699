var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lazy-hydrate',{attrs:{"never":"","trigger-hydration":_vm.enableCSRHydration}},[_c('footer',{staticClass:"vf-footer",class:{ 'vf-footer--redesign-core': _vm.isCoreRedesignEnabled },style:(_vm.style)},[_c('div',{staticClass:"vf-footer__top-items"},[_c('div',{staticClass:"vf-footer__container extended"},_vm._l((_vm.stateFulfilledColumns),function(l1,l1Key){return _c('VfFooterColumn',{key:l1Key,class:[l1.classModifiers],style:({ '--vf-footer-column-title-color': l1.titleColor }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(l1.id, 'items'),"title":l1.title,"multiple":_vm.multiple}},[(l1.items && l1.items.length)?_c('VfList',_vm._l((l1.items),function(l2,l2Key){return _c('VfListItem',{key:l1Key + '_' + l2Key},[_c(_vm.isChat(l2) ? 'vf-chat-link' : 'ui-link',{tag:"component",staticClass:"ui-link",class:[l2.titleClassModifiers],style:({
                  '--vf-footer-link-color': l2.titleColor,
                  '--link-font-size': l2.fontSize,
                  '--vf-link-font-weight': l2.fontWeight,
                  '--link-font-family': l2.fontFamily,
                }),attrs:{"link-type":"ui-link","data-cm-metadata":_vm.$previewMetaDataLocation(l2.id),"to":l2.link,"title":l2.title,"data-navigation":l2.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.FooterNavigation,"target":l2.openInNewTab ? '_blank' : ''},on:{"click":function($event){return _vm.handleFooterLinkClick(
                    {
                      l1: l1.name,
                      l2: l2.name,
                      link: l2.link,
                      openInNewModal: l2.openInNewModal,
                    },
                    $event
                  )}}},[(l2.image)?_c('VfPicture',{attrs:{"src":l2.image,"width":l2.image.width,"height":l2.image.height,"alt":l2.image.alt}}):[_vm._v("\n                  "+_vm._s(l2.title)+"\n                ")],_vm._v(" "),(l2.icon)?_c('div',{staticClass:"ui-link-icon-wrapper"},[_c('VfIcon',{staticClass:"ui-link-icon",attrs:{"icon":l2.icon}})],1):_vm._e()],2)],1)}),1):_c('div',{staticClass:"vf-footer-column--with-html"},_vm._l((l1.sections.filter(
                function (i) { return i.stateFulfilled; }
              )),function(section,key){return _c('div',{key:key},[_c('h4',{staticClass:"vf-footer-column-row__title",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(section.id, 'teaserTitle')}},[_vm._v("\n                "+_vm._s(section.title)+"\n              ")]),_vm._v(" "),(section.link)?_c('p',{staticClass:"vf-footer-column__paragraph html-content",class:{ 'small-font': section.smallFont },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(section.id, 'teaserTargets')}},[_c('UiLink',{staticClass:"ui-link",class:[section.titleClassModifiers],style:({
                    '--vf-footer-link-color': section.titleColor,
                    '--link-font-size': section.fontSize,
                    '--vf-link-font-weight': section.fontWeight,
                    '--link-font-family': section.fontFamily,
                  }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(section.id, 'teaserTargets'),"to":section.link,"title":section.html,"target":section.openInNewTab ? '_blank' : '',"data-navigation":section.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.FooterNavigation},on:{"click":function($event){return _vm.handleFooterLinkClick(
                      {
                        l1: l1.name,
                        l2: section.name,
                        link: section.link,
                        openInNewModal: section.openInNewModal,
                      },
                      $event
                    )}}},[_vm._v("\n                  "+_vm._s(section.html)+"\n                ")])],1):_c('p',{staticClass:"vf-footer-column__paragraph html-content",class:{ 'small-font': section.smallFont },domProps:{"innerHTML":_vm._s(section.html)}})])}),0)],1)}),1)]),_vm._v(" "),_c('div',{staticClass:"vf-footer__bottom-items"},[_c('div',{staticClass:"vf-footer__container extended",class:_vm.classes},[_c('div',{staticClass:"footer-bottom-section",class:_vm.footerBottomSectionClass},[(_vm.storeLocator)?_c('VfFooterStoreLocator',{staticClass:"vf-footer-column footer-bottom-section__element locator",class:{
              'vf-footer-column--redesign-core': _vm.isCoreRedesignEnabled,
            },attrs:{"store-locator":_vm.storeLocator}}):_vm._e(),_vm._v(" "),_c('VfFooterSocialMedia',{staticClass:"vf-footer-column footer-bottom-section__element social",class:{
              'vf-footer-column--redesign-core': _vm.isCoreRedesignEnabled,
            },attrs:{"social-media":_vm.socialMedia}}),_vm._v(" "),(_vm.subscribe)?_c('VfFooterSubscribe',{staticClass:"vf-footer-column footer-bottom-section__element subscribe",class:{
              'vf-footer-column--redesign-core': _vm.isCoreRedesignEnabled,
            },attrs:{"subscribe":_vm.subscribe,"context-key":_vm.contextKey}}):_vm._e()],1),_vm._v(" "),(_vm.backToTop)?_c('div',{staticClass:"footer-back-to-top small-only",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.backToTop.id)}},[_c('button',{directives:[{name:"focus",rawName:"v-focus"}],class:[_vm.backToTop.titleClassModifiers],style:({
              '--vf-footer-back-to-top-color': _vm.backToTop.titleColor,
              '--vf-footer-back-to-top-font-family': _vm.backToTop.fontFamily,
              '--vf-footer-back-to-top-font-size': _vm.backToTop.fontSize,
              '--vf-footer-back-to-top-font-weight': _vm.backToTop.fontWeight,
            }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.backToTop.id, 'teaserTitle')},on:{"click":function($event){return _vm.scrollToTop()}}},[_c('VfIcon',{style:({
                '--vf-footer-back-to-top-color': _vm.backToTop.titleColor,
              }),attrs:{"icon":"chevron_up"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.backToTop.title))])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"footer-bottom-bar",class:{
            'footer-bottom-bar--redesign-core': _vm.isCoreRedesignEnabled,
          },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.bottomBar.id)}},[_c('div',{staticClass:"footer-bottom-bar__info",class:[_vm.bottomBar.classModifiers],style:({
              '--vf-footer-bottom-bar-info-color': _vm.bottomBar.textColor,
              '--vf-footer-bottom-bar-info-font-size': _vm.bottomBar.fontSize,
              '--vf-footer-bottom-bar-info-font-family': _vm.bottomBar.fontFamily,
              '--vf-footer-bottom-bar-info-font-weight': _vm.bottomBar.fontWeight,
            }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.bottomBar.id)}},[_c('div',{staticClass:"footer-bottom-bar__logo"},[_c('UiLink',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.logo.id),"to":_vm.logo.link,"aria-label":_vm.logo.ariaLabel,"data-navigation":_vm.logo.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.FooterNavigation}},[(_vm.isCoreRedesignEnabled)?_c('VfIcon',{attrs:{"icon":"logo_otw"}}):_c('VfPicture',{attrs:{"alt":_vm.logo.ariaLabel,"src":_vm.logo.url,"height":_vm.logo.height,"width":_vm.logo.width}})],1)],1),_vm._v(" "),_c('div',{staticClass:"footer-text-wrapper"},[_c('div',[_c('span',{staticClass:"footer-bottom-bar__copyrights"},[_vm._v(_vm._s(_vm.bottomBar.title))]),_vm._v(" "),(
                    _vm.bottomBar.bottomLinks &&
                    _vm.bottomBar.bottomLinks.length === 1
                  )?_c('span',[_c('UiLink',{staticClass:"footer-bottom-bar__policy",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.logo.id, 'teaserTargets'),"to":_vm.bottomBar.link,"data-navigation":_vm.bottomBar.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.FooterNavigation,"target":_vm.bottomBar.openInNewTab ? '_blank' : ''},on:{"click":function($event){return _vm.handleFooterLinkClick(_vm.bottomBar, $event)}}},[_vm._v("\n                    "+_vm._s(_vm.bottomBar.linkText)+"\n                  ")])],1):_vm._e()]),_vm._v(" "),(
                  _vm.bottomBar.bottomLinks && _vm.bottomBar.bottomLinks.length > 1
                )?_c('ul',{staticClass:"footer-bottom-bar__bottom-links-wrp"},_vm._l((_vm.bottomBar.bottomLinks),function(l1,l1Key){return _c('li',{key:l1Key,staticClass:"bottom-link-wrapper"},[_c('UiLink',{staticClass:"bottom-link",attrs:{"to":l1.link}},[(l1.icon)?_c('VfIcon',{staticClass:"bottom-link__icon",attrs:{"size":l1.iconSize,"icon":l1.icon}}):_vm._e(),_vm._v("\n                    "+_vm._s(l1.text)+"\n                  ")],1)],1)}),0):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"footer-bottom-bar__site-selector"},[(!_vm.isCoreRedesignEnabled)?_c('label',{staticClass:"footer-bottom-bar__site-selector--label",attrs:{"for":"country-select"}},[_vm._v(_vm._s(_vm.bottomBar.translations.countrySelectLabel))]):_vm._e(),_vm._v(" "),_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.locales && _vm.locales.length)?_c('VfSelect',{attrs:{"id":"country-select","value":_vm.selectedLocale,"label":_vm.isCoreRedesignEnabled
                    ? _vm.bottomBar.translations.countrySelectLabel
                    : undefined},on:{"selected":_vm.changeLocale}},_vm._l((_vm.locales),function(site){return _c('VfSelectOption',{key:site.locale,attrs:{"value":site.locale,"selected":site.locale === _vm.selectedLocale}},[_vm._v("\n                  "+_vm._s(site.displayName)+"\n                ")])}),1):_vm._e()],1)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }