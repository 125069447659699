import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';
import { CustomerSegments } from '../../api-types';

export const getSegments = (configuration: ApiClientConfiguration) => ({
  usid = null,
}: RequestHeader = {}): Promise<AxiosResponse<CustomerSegments>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.getSegments,
    {
      config: configuration,
    }
  );

  return axios.get(url, {
    headers: headerBuilder(configuration, {
      'x-usid': usid,
    }),
  });
};
