import { render, staticRenderFns } from "./FooterSocialMedia.vue?vue&type=template&id=c96832fe&"
import script from "./FooterSocialMedia.vue?vue&type=script&lang=ts&"
export * from "./FooterSocialMedia.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports