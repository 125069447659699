import {
  prepareLocale,
  prepareTemporaryBasket,
} from '../../../../src/utils/query';
import { GetShippingResponse } from '@vf/api-client/src/api-types';
import { apiClientFactory, PaymentMethodCode } from '@vf/api-client';
import { computed, ref, Ref } from '@vue/composition-api';
import {
  BgRequestWithTempBasket,
  ShippingMethodGroups,
  UseCartStorage,
} from '../../../../src/useCart/types';
import { useI18n, useRequestTracker } from '@vf/composables';
import { errorMessages } from '../../../../src/utils/errorMessages';
import { ComposablesStorage } from '../../../../src/types';
import initStorage from '../../../../src/utils/storage';
import { isBasketNotFoundError } from '../../helpers';
import { useCartStore } from '../../../store/cartStore';
import { useCartNotificationsStore } from '../../../store/cartNotificationsStore';
import { CustomFlashType } from '@vf/api-contract';

export const useShipping = (
  instance,
  {
    cartRef,
    isProceedBillingButtonDisabled,
    cartId,
    loadCart,
    isShipmentPickup,
    updateCartItems,
  },
  contextKey
) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { displayErrorMessages } = errorMessages(instance);
  const { localeCode } = useI18n(instance);
  const { setCartLoading } = useCartStore();

  const {
    setShippingMethod: setShippingMethodAPI,
    getShippingMethods: getShippingMethodsAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const shippingMethodsCartState: Ref<
    Record<string, string>
  > = storage.getOrSave('shippingMethodsCartState', ref({}));

  const shippingMethodGroups: Ref<ShippingMethodGroups> = storage.getOrSave(
    'shippingMethodGroups',
    ref({})
  );

  const getShippingMethods = async (
    shippingId: string,
    { isBackgroundRequest, isTemporary }: BgRequestWithTempBasket = {
      isBackgroundRequest: false,
      isTemporary: false,
    }
  ) => {
    setCartLoading(true);
    isProceedBillingButtonDisabled.value = true;
    if (cartId.value && !cartRef.value?.id) {
      await loadCart({
        isBackgroundRequest,
        isTemporary,
        inventorySupplyCheck: true,
      });
    }

    const cartState = JSON.stringify(cartRef.value);
    const storedState = shippingMethodsCartState.value[shippingId];

    if (cartId.value && cartState !== storedState) {
      shippingMethodsCartState.value[shippingId] = cartState;

      const { tag } = trackRequest(
        'useCart-getShippingMethods',
        isBackgroundRequest
      );

      try {
        const query: string[] = [prepareLocale(localeCode())];
        if (isTemporary) {
          query.push(prepareTemporaryBasket());
        }
        const res = await getShippingMethodsAPI(
          cartId.value,
          shippingId,
          query.join('&')
        );

        validateAndSetShippingMethod(shippingId, res.data);

        shippingMethodGroups.value = {
          ...shippingMethodGroups.value,
          [shippingId]: res.data,
        };

        const { setShippingGroupMethods } = useCartStore();
        setShippingGroupMethods(shippingId, res.data.shippingMethods);

        return res;
      } catch (e) {
        if (!isBasketNotFoundError(e.response?.data)) {
          delete shippingMethodsCartState.value[shippingId];
          displayErrorMessages(e);
        }

        return {
          data: shippingMethodGroups.value[shippingId] || [],
        };
      } finally {
        clearRequest(tag, isBackgroundRequest);
        isProceedBillingButtonDisabled.value = false;
        setCartLoading(false);
      }
    } else if (cartId.value) {
      isProceedBillingButtonDisabled.value = false;
      setCartLoading(false);
      return {
        data: shippingMethodGroups.value[shippingId],
      };
    } else {
      isProceedBillingButtonDisabled.value = false;
      setCartLoading(false);
      console.error('getShippingMethods: No cart id');
      return null;
    }
  };

  const validateAndSetShippingMethod = (
    shippingId: string,
    availableShippingMethods: GetShippingResponse
  ): void => {
    if (isShipmentPickup(shippingId)) {
      return;
    }
    const defaultMethod = cartRef.value.shippingMethods.find(
      (method) => method.shippingId === shippingId
    );

    const defaultMethodCode = cartRef.value?.featureFlags
      ?.applyBestShippingMethod
      ? availableShippingMethods.defaultShippingMethodId
      : defaultMethod?.code;

    const newDefaultMethodCode =
      availableShippingMethods.shippingMethods?.find(
        (method) => method.code === defaultMethodCode
      )?.code ||
      availableShippingMethods.shippingMethods?.find(
        (method) =>
          method.code === availableShippingMethods.defaultShippingMethodId
      )?.code ||
      availableShippingMethods.shippingMethods?.[0].code;
    if (newDefaultMethodCode && defaultMethod?.code !== newDefaultMethodCode) {
      setShippingMethod({ code: newDefaultMethodCode, shippingId });
      useCartNotificationsStore().appendCustomFlash(
        CustomFlashType.ShippingMethodChanged
      );
    }
  };

  const setShippingMethod = async ({
    code,
    shippingId,
    isTemporary,
    shopperAppliedSM,
    isApplePayExpress,
  }: {
    code: string;
    shippingId: string;
    isTemporary?: boolean;
    shopperAppliedSM?: boolean;
    isApplePayExpress?: boolean;
  }) => {
    const query: string[] = [];
    if (isTemporary) {
      query.push(prepareTemporaryBasket());
    }

    if (isApplePayExpress) {
      query.push(`context=${PaymentMethodCode.APPLE_PAY_EXPRESS}`);
    }

    try {
      const response = await setShippingMethodAPI(
        {
          cartId: cartId.value,
          code,
          shippingId,
          shopperAppliedSM,
        },
        {
          query: query.join('&'),
        }
      );
      if (response.status === 200) {
        await updateCartItems(response.data);
      }
    } catch (err) {
      displayErrorMessages(err);
    }
  };

  const shippingGroups = computed(() => {
    return cartRef.value.shippingMethods.map((method) => ({
      ...method,
      methods:
        shippingMethodGroups.value[method.shippingId]?.shippingMethods || [],
      edit: {
        isEditable: true, // From where?
        showEditLink: true, // From where?
      },
    }));
  });

  /**
   * Get address for default shipping
   */
  const defaultShippingAddress = computed(() => {
    const addressObject = cartRef.value.shippingMethods?.find(
      (element) => element.shippingId === 'me'
    );
    return addressObject ? addressObject.address : null;
  });

  const getShippingGroupFormattedPrice = (method, freeShippingTranslation) => {
    if (typeof method.price.finalAmount === 'undefined') return '';
    if (Math.abs(method.price.finalAmount) === 0) {
      return freeShippingTranslation;
    } else {
      return instance.$formatPrice(
        method.price.finalAmount,
        method.price.currency
      );
    }
  };

  return {
    setShippingMethod,
    defaultShippingAddress,
    shippingGroups,
    getShippingMethods,
    getShippingGroupFormattedPrice,
  };
};
