import { CmsSite, CMTeaser } from '@vf/api-contract';
import merge from '../../utils/merge';
import { getMediaWithOverlay, getImageObject, extractProperty } from './utils';
import { ComposableContext } from '../types';
import { getResponsiveMediaPictureObject } from './utils/getImageObject';
import usePlaceholders from '../../usePlaceholders';
import {
  getPlaceholdersInObject,
  replacePlaceholdersInObject,
} from '../../utils/placeholders';

export const imageWithOverlay = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const getImage = getImageObject(
    extractProperty(data, 'pictures[0]'),
    siteConfiguration,
    null,
    cmsBaseUri,
    data.responsiveMedia
  );
  const mediumImage = getResponsiveMediaPictureObject(
    extractProperty(data, 'pictures[0]'),
    'medium',
    data.responsiveMedia,
    0
  );
  const largeImage = getResponsiveMediaPictureObject(
    extractProperty(data, 'pictures[0]'),
    'large',
    data.responsiveMedia,
    0
  );

  let urlParams = '';
  // Check urlParams at data, if no urlParams,
  // then try ro take it from localSettings
  if (!data.urlParams) {
    urlParams = extractProperty(data, 'localSettings.urlParams', '');
    if (urlParams) urlParams = '?' + new URLSearchParams(urlParams).toString();
  }

  const componentData = merge(
    getMediaWithOverlay(data, context, siteConfiguration, cmsBaseUri),
    {
      component: 'shared/ImageWithOverlay',
      props: {
        category: {
          image: {
            large: getImage.large,
            medium: getImage.medium,
            small: getImage.small,
            alt: getImage.alt,
            title: getImage.title,
            detailRichText: getImage.detailRichText,
            sizes: {
              small: {
                width: getImage.width,
                height: getImage.height,
              },
              medium: {
                width: mediumImage.width,
                height: mediumImage.height,
              },
              large: {
                width: largeImage.width,
                height: largeImage.height,
              },
            },
          },
        },
        openInNewTab: data.openInNewTab,
        openInNewModal: data.openInNewModal,
        urlParams,
      },
    }
  );

  const {
    fields,
    placeholders,
    currentPlaceholders,
    currentPlaceholderValues,
  } = usePlaceholders(context.instance);

  const placeholdersFound = getPlaceholdersInObject(
    componentData.props,
    fields,
    placeholders
  );

  const hasPlaceholders = !!placeholdersFound.length;
  const hasAllCurrentPlaceholders =
    hasPlaceholders &&
    placeholdersFound.every((placeholder) =>
      currentPlaceholders.value.includes(placeholder)
    );

  if (!hasPlaceholders) return componentData;

  componentData.replacePlaceholders =
    hasPlaceholders && !hasAllCurrentPlaceholders;

  return replacePlaceholdersInObject(
    componentData,
    fields,
    currentPlaceholderValues.value
  );
};

export type ImageWithOverlayData = ReturnType<typeof imageWithOverlay>;
