import { extractProperty } from './extractProperty';

export const extractCommonConfigLink = (item: any, target: string) => {
  const commonConfigExpanded = extractProperty(
    item,
    'commonConfigExpanded',
    []
  );

  if (!Array.isArray(commonConfigExpanded)) {
    return [];
  }

  const dataSourcesObjects = commonConfigExpanded.find(
    (setting) => setting.path === target
  );

  if (!dataSourcesObjects || !Array.isArray(dataSourcesObjects.links)) {
    return [];
  }

  return dataSourcesObjects.links;
};
