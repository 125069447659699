import { ComponentInstance, ComposablesStorage } from '../types';

export default function initStorage<T>(
  componentInstance: ComponentInstance,
  composableName: string
): ComposablesStorage<T> {
  if (!componentInstance)
    throw new Error(
      `You're trying to initialize storage for ${composableName} without passing root instance.`
    );
  if (componentInstance.$composables[composableName] === undefined) {
    componentInstance.$composables[composableName] = {};
  }
  const storage: T = componentInstance.$composables[composableName];

  return {
    get<K extends keyof T>(key: K): T[K] | null {
      return storage[key] ?? null;
    },
    save<K extends keyof T>(key: K, value: T[K]): T[K] {
      storage[key] = value;
      return value;
    },
    getOrSave<K extends keyof T>(key: K, initialValue: T[K]): T[K] {
      const value = storage[key] ?? null;
      if (value === null) {
        storage[key] = initialValue;
        return initialValue;
      }
      return value;
    },
  };
}
