


















import { defineComponent, computed } from '@vue/composition-api';
import { ROUTES, useCart, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { pluralize } from '@/helpers/pluralize';

export default defineComponent({
  name: 'CartIcon',
  setup() {
    const { root } = useRootInstance();
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const theme = root.$themeConfig.staticCart;

    const { totalItems } = useCart(root);
    const { localePath } = useI18n(root);

    const cartIconAriaLabel = computed(() => {
      return `${pluralize(
        root.$t('miniCartNew.ariaLabelCartIcons', {
          amount: totalItems.value,
        }) as string,
        totalItems.value
      )}`;
    });

    return {
      cartIconAriaLabel,
      theme,
      totalItems,
      isCoreRedesignEnabled,
      cartPath: localePath(ROUTES.CART()),
    };
  },
});
