var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.html && !_vm.hasParentTag)?_c('div',_vm._b({staticClass:"vf-text",class:[
    _vm.textCustomClasses,

    _vm.fontFamily,
    _vm.hyperlinkClass ].concat( _vm.textAlignClasses,
    [{ 'vf-text--highlighted': _vm.backgroundColor }] ),style:(_vm.textStyles),domProps:{"innerHTML":_vm._s(_vm.content)}},'div',_vm.$attrs,false)):(_vm.html && _vm.hasParentTag)?_c(_vm.tag,_vm._b({tag:"component",staticClass:"vf-text",class:[
    _vm.computedClasses,
    _vm.fontFamily,
    _vm.hyperlinkClass ].concat( _vm.textAlignClasses,
    [{ 'vf-text--highlighted': _vm.backgroundColor }] ),style:(_vm.textStyles),domProps:{"innerHTML":_vm._s(_vm.content)}},'component',_vm.$attrs,false)):_c(_vm.tag,_vm._b({tag:"component",staticClass:"vf-text",class:[
    _vm.computedClasses,
    _vm.fontFamily,
    _vm.hyperlinkClass ].concat( _vm.textAlignClasses,
    [{ 'vf-text--highlighted': _vm.backgroundColor }] ),style:(_vm.textStyles),attrs:{"role":_vm.vfTextAriaRole,"aria-live":_vm.vfTextAriaLive}},'component',_vm.$attrs,false),[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.content))]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }