import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const prepareOrder = (configuration: ApiClientConfiguration) => (body: {
  cartId: string;
}): Promise<any> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).cart.prepareOrder,
    {
      config: configuration,
    }
  );
  return axios.post(
    url,
    {
      basketId: body.cartId,
    },
    {
      headers: headerBuilder(configuration),
    }
  );
};
