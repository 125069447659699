import { Ref, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import type { CardType } from '@vf/api-contract';

export const useCardStore = defineStore('card', () => {
  const saveCreditCard = ref(false);
  const card: Ref<{
    cardType: CardType;
    paymentInstrumentId?: string;
    address?: any;
    maskedNumber: string;
    expirationMonth?: number | string;
    expirationYear?: number | string;
  } | null> = ref(null);
  const cardNumber = ref('');
  const cardType = ref<CardType>(null);

  const setCardType = (type: CardType = null) => {
    // both `diners-club` and `diners` go with `diners` in the store
    if (type?.startsWith('diners')) cardType.value = 'diners';
    else cardType.value = type;
  };

  const reset = () => {
    cardType.value = null;
    card.value = null;
    cardNumber.value = '';
  };

  return {
    card,
    cardNumber,
    cardType,
    saveCreditCard,
    setCardType,
    reset,
  };
});
