import { ref } from '@vue/composition-api';
import { ComponentInstance } from '../types';
import { axios } from '@vf/api-client';
import { mapLocale } from '@vf/env';
import type { UseI18n, StaticTranslations } from './types';

/**
 * Composable for reliable internalisation
 * @param {ComponentInstance} instance root vue instance
 */
const useI18n = (instance: ComponentInstance): UseI18n => {
  const envPriceFormat: {
    currency: Record<string, string>;
  } = instance.$getEnvValueByCurrentLocale?.('PRICE_FORMAT');
  // ex: currency = {'USD': '$'}
  // fallback is only for unit testing purposes, in runtime there must be always a value set in Helm Charts
  const currency = envPriceFormat?.currency || { '': 'no_env' };
  /**
   * Get localized path based on nuxt-i18n settings
   * @param {string} path
   * @param {string} locale
   */
  function localePath(path: string, locale: string = null): string {
    if (path === '#') {
      return '#';
    }
    const locales = instance.$i18n.locales as string[];

    const localeInHomepage = (locales as string[]).find(
      (loc: string) => path === `/${loc}`
    );

    if (localeInHomepage) {
      if (!locale) {
        return instance.localePath(path.replace(`/${localeInHomepage}`, ''));
      }
      return instance.localePath(
        path.replace(`/${localeInHomepage}`, ''),
        locale
      );
    }
    const localeInPath = locales.find((loc) => path.startsWith(`/${loc}/`));
    if (localeInPath) {
      if (!locale) {
        return instance.localePath(path.replace(`/${localeInPath}/`, '/'));
      }
      return instance.localePath(
        path.replace(`/${localeInPath}/`, '/'),
        locale
      );
    }
    if (!locale) {
      return instance.localePath(path);
    }
    return instance.localePath(path, locale);
  }

  function configureApiInternalization(): void {
    axios.defaults.headers.common.locale = mapLocale(
      instance.$i18n.locale,
      instance.$env.LOCALE_MAPPING
    );
  }

  function localeCode(): string {
    return instance.$i18n.locale;
  }

  const getIetfLocale = (): string => {
    const [lang, region] = instance.$i18n.locale.split('-');
    return `${lang.toLowerCase()}-${region.toUpperCase()}`;
  };

  const getPosixLocale = (): string => {
    const [lang, region] = instance.$i18n.locale.split('-');
    return `${lang.toLowerCase()}_${region.toUpperCase()}`;
  };

  // TODO: GLOBAL15-56318 Get rid of this function after checkout redesing (it was used only for Narvar EDD and it will be not usefull anymore)
  function localeMapping(): string {
    return instance.$env.LOCALE_MAPPING;
  }

  const defaultCurrency = ref(Object.keys(currency)[0]);

  const currencySymbol = ref(Object.values(currency)[0]);

  const staticTranslations = instance.$i18n.messages[
    localeCode()
  ] as StaticTranslations;

  const getStaticTranslation: UseI18n['getStaticTranslation'] = (
    propertyName
  ) => {
    return staticTranslations && staticTranslations[propertyName]
      ? staticTranslations[propertyName]
      : {};
  };

  return {
    configureApiInternalization,
    localePath,
    localeCode,
    getIetfLocale,
    getPosixLocale,
    defaultCurrency,
    currencySymbol,
    localeMapping,
    staticTranslations,
    getStaticTranslation,
  };
};

export { useI18n };
