import axios from 'axios';
import { CmsArticlesResponse } from '@vf/api-contract';
import { getCacheWrapperConfig } from '../helpers';
import wrapper from 'axios-cache-plugin';
import { ApiClientConfiguration } from '../configuration';

const articleWrapperConfig = getCacheWrapperConfig('articles');
const http = wrapper(axios, articleWrapperConfig);

http.__addFilter(/pages/);

export default http;

export const getArticles = (
  configuration: ApiClientConfiguration
) => async (settings: {
  siteId: string;
  baseUri: string;
  headers: { [key: string]: string };
  query?: string;
}): Promise<CmsArticlesResponse> => {
  const url = `${settings.baseUri}/caas/v1/pages/${settings.siteId}?${settings.query}`;
  configuration.logger.debug('Get Articles', { url });

  const articlesResponse: { data: CmsArticlesResponse } = await http.get(url, {
    headers: settings.headers,
    validateStatus: () => true,
  });

  return articlesResponse.data;
};
