import { computed, Ref, ComputedRef } from '@vue/composition-api';
import { ssrRef } from '@nuxtjs/composition-api';
import { apiClientFactory, OrderDetails } from '@vf/api-client';

import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { useRequestTracker } from '../useRequestTracker';
import { useAuthentication } from '../index';
import { prepareLocale } from '../utils/query';
import { useI18n } from '../useI18n';
import { mapUserOrder } from '../useAccount/helpers';
import { useReCaptcha } from '../useReCaptcha';

type UseOrders = {
  order: ComputedRef<OrderDetails>;
  loadOrder: (orderId: string) => Promise<void>;
  findGuestOrder: (
    orderNumber: string,
    emailOrLastName: string,
    searchByLastName: boolean,
    zipCode: string
  ) => Promise<void>;
  getOrderStatusLabel: (orderStatus: string, itemStatuses: string[]) => string;
};

type UseOrdersStorage = {
  order: Ref<OrderDetails>;
};

export const useOrders = (instance: ComponentInstance): UseOrders => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { getUsid } = useAuthentication(instance);
  const { getStaticTranslation, localeCode } = useI18n(instance);

  const {
    getOrderDetails: getOrderDetailsAPI,
    getGuestOrderDetails: getGuestOrderDetailsAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseOrdersStorage> = initStorage<UseOrdersStorage>(
    instance,
    'useOrders'
  );

  const order: Ref<OrderDetails> =
    storage.get('order') ??
    storage.save('order', ssrRef<OrderDetails>(null, 'orderRef'));

  const loadOrder = async (
    orderId: string,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ): Promise<void> => {
    const { tag } = trackRequest('useOrders-loadOrder', isBackgroundRequest);
    try {
      const query: string[] = [prepareLocale(localeCode())];
      const response = await getOrderDetailsAPI(orderId, query.join('&'), {
        usid: getUsid.value,
      });
      order.value = await mapUserOrder(response.data[0], instance);
    } catch (err) {
      instance.$log.error(err);
      throw err;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const findGuestOrder = async (
    orderNo: string,
    emailOrLastName: string,
    searchByLastName: boolean,
    zipCode: string,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ): Promise<void> => {
    const { tag } = trackRequest(
      'useOrders-findGuestOrder',
      isBackgroundRequest
    );

    const searchBy = searchByLastName ? 'lastName' : 'customerEmail';
    const captchaResponse = await useReCaptcha(instance).executeRecaptcha(
      'trackOrder'
    );

    try {
      const response = await getGuestOrderDetailsAPI(
        {
          orderNo,
          zipCode,
          [searchBy]: emailOrLastName,
        },
        {
          captchaResponse,
        }
      );

      order.value = await mapUserOrder(response.data[0], instance);
    } catch (err) {
      instance.$log.error(err);
      throw err;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  enum Status {
    'notAvailable' = 'NA',
    'shippedToStore' = 'shippedToStore',
    'orderInProduction' = 'orderInProduction',
    'orderDetailsBelow' = 'orderDetailsBelow',
    'returnReceived' = 'returnReceived',
    'readyForCustomerPickup' = 'readyForCustomerPickup',
    'pickedUp' = 'pickedUp',
    'cancelled' = 'cancelled',
    'transactionComplete' = 'transactionComplete',
    'shipped' = 'shipped',
    'orderPending' = 'orderPending',
    'transactionCompleteReturnRequested' = 'transactionCompleteReturnRequested',
    'orderReceivedByFactory' = 'orderReceivedByFactory',
    'pickUpExpired' = 'pickUpExpired',
    'partiallyShipped' = 'partiallyShipped',
    'processing' = 'processing',
    'returnCreated' = 'returnCreated',
  }

  const feOrderStatusMapping = {
    new: Status.processing,
    open: Status.processing,
    'draft order created': Status.processing,
    created: Status.processing,
    'backordered from node': Status.processing,
    backordered: Status.processing,
    scheduled: Status.processing,
    released: Status.processing,
    'release acknowledged': Status.processing,
    'ready for customer pick': Status.readyForCustomerPickup,
    'order received by factory': Status.processing,
    'order in production': Status.processing,
    'ready for packing': Status.processing,
    'pack in progress': Status.processing,
    'ready to ship': Status.processing,
    'sent to store': Status.processing,
    'partially shipped': Status.partiallyShipped,
    'partially released': Status.processing,
    'partially release acknowledged': Status.processing,
    'partially sent to store': Status.orderDetailsBelow,
    'partially scheduled': Status.processing,
    'partially return created': Status.orderDetailsBelow,
    'return created': Status.returnCreated,
    'receipt closed': Status.returnCreated,
    shipped: Status.shipped,
    'return received': Status.returnReceived,
    'return invoiced': Status.returnReceived,
    'shipped to store': Status.shippedToStore,
    'received, ready for customer pickup': Status.readyForCustomerPickup,
    'shipped back to dc': Status.pickUpExpired,
    'customer pickedup': Status.pickedUp,
    cancelled: Status.cancelled,
    'dyo cancelled': Status.cancelled,
    'partially ready for packing': Status.orderDetailsBelow,
    'partially customer pickedup': Status.orderDetailsBelow,
    'partially picked': Status.orderDetailsBelow,
    'partially received, ready for customer pickup': Status.orderDetailsBelow,
    'partially pack in progress': Status.orderDetailsBelow,
    'partially ready to ship': Status.orderDetailsBelow,
    'partially shipped to store': Status.orderDetailsBelow,
    'partially ready for customer pick': Status.orderDetailsBelow,
    'partially ready for customer pick up': Status.orderDetailsBelow,
    'partially return received': Status.orderDetailsBelow,
    'partially return invoiced': Status.orderDetailsBelow,
    'partially shipped back to dc': Status.orderDetailsBelow,
    picked: Status.pickedUp,
    /** Support for legacy migration for WCS in flight orders GLOBAL15-30486 */
    p: Status.notAvailable,
    m: Status.notAvailable,
    c: Status.notAvailable,
    r: Status.processing,
    g: Status.processing,
    x: Status.cancelled,
    d: Status.shipped,
  };

  const getOrderStatusLabel = (
    orderStatus: string = '',
    itemStatuses: string[] = []
  ) => {
    /*
    For 'Customer PickedUp' order level status two messages are possible
    depending on item level statuses. If more than one item level status
    is passed then 'Order Details Below' message should display.
    https://digital.vfc.com/wiki/pages/viewpage.action?spaceKey=ECOM15&title=06+Order+Status+Mapping
    */
    const mappedOrderStatus =
      orderStatus.toLowerCase() === 'customer pickedup' &&
      itemStatuses.length > 1
        ? Status.orderDetailsBelow
        : feOrderStatusMapping[orderStatus.toLowerCase()];

    if (mappedOrderStatus === Status.notAvailable) {
      return mappedOrderStatus;
    }
    const orderStatusTranslations = getStaticTranslation('orderStatuses');
    const feStatus = orderStatusTranslations[mappedOrderStatus];
    if (!feStatus) {
      instance.$log.error(
        `[@useOrders/index::getOrderStatusLabel] Cannot find order status translation for <${orderStatus}>`,
        orderStatusTranslations
      );
    }
    return feStatus || `$${orderStatus}$`;
  };

  return {
    order: computed(() => order.value),
    loadOrder,
    findGuestOrder,
    getOrderStatusLabel,
  };
};
