import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { AxiosResponse } from 'axios';
import { AddNewAddressResponse } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const addAddress = (configuration: ApiClientConfiguration) => async (
  data,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<AddNewAddressResponse>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.addAddress,
    {
      config: configuration,
    }
  );
  return axios.post(
    url,
    { address: data },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
