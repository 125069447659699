function standardizeLocale(locale: string): string {
  locale = locale ? locale.replace('_', '-').toLowerCase() : '';
  return locale;
}

function getEnvValueByLocale<T = string>(
  envVar: string,
  locale: string,
  envList: Record<string, string> = process.env
): T | undefined {
  if (typeof envList !== 'object') return;
  const envValue = envList[envVar];
  if (!envValue) return;
  const jsonValue = JSON.parse(envValue);
  locale = standardizeLocale(locale);
  if (locale && jsonValue[locale] !== undefined) return jsonValue[locale];
  if (jsonValue[`default`] !== undefined) return jsonValue[`default`];
  return (envValue as unknown) as T;
}

function mapLocale(
  locale: string = process.env.DEFAULT_LANGUAGE,
  mapping: string = process.env.LOCALE_MAPPING
): string {
  const LOCALE_MAPPING = JSON.parse(mapping);
  const returnValue = LOCALE_MAPPING[locale.toLowerCase()];
  if (returnValue) return returnValue;
  return locale;
}

function getSiteIdByLocale(locale: string): string {
  const API_SITE_ID = JSON.parse(process.env.API_SITE_ID);
  const returnValue = API_SITE_ID[standardizeLocale(locale)];
  if (returnValue) return returnValue;
  return API_SITE_ID['default'];
}

export { getEnvValueByLocale, mapLocale, getSiteIdByLocale, standardizeLocale };
