import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../../types';
import {
  extractProperty,
  extractLocalSettingsExpandedLinks,
  getDynamicField,
} from './utils';

export const fullWidthTape = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'shared/ImageTape',
    props: {
      background: getBackgroundImage(data, cmsBaseUri),
      height: getDynamicField(data, 'tape-height'),
    },
  };
};

const getBackgroundImage = (data: CMPlaceholder, cmsBaseUri: string) => {
  return `${cmsBaseUri}${extractProperty(
    extractLocalSettingsExpandedLinks(data, 'tape-image'),
    '[0].data.uri',
    ''
  )}`;
};
