import { isClient } from '@vf/shared/src/utils/helpers';
import { PRESERVE_PREVIEW } from '../components/cms/cmsUtils';

export default function (context, inject) {
  const isPreview =
    context.query[PRESERVE_PREVIEW] === 'true' ||
    (isClient && sessionStorage.getItem(PRESERVE_PREVIEW) === 'true');

  const previewMetadataCache = {};

  const previewMetaDataLocation = (id, propertyName = '') => {
    if (!isPreview || !id) return;
    const cacheKey = id + propertyName;
    if (previewMetadataCache[cacheKey]) return previewMetadataCache[cacheKey];

    const metaDataId = new Array({ _: { $Ref: `content/${id}` } });
    const metaDataProperty = new Array({ _: `properties.${propertyName}` });
    const metaData = propertyName
      ? [...metaDataId, ...metaDataProperty]
      : metaDataId;
    const result = JSON.stringify(metaData);
    previewMetadataCache[cacheKey] = result;
    return result;
  };

  inject('isPreview', isPreview || false);
  inject('previewMetaDataLocation', previewMetaDataLocation);
}
