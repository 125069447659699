import { CMPlaceholder } from '@vf/api-contract';
import { getDynamicField } from './utils';

export const articleAbout = (data: CMPlaceholder) => {
  return {
    component: 'article/ArticleAbout',
    props: {
      showTitle: getDynamicField(data, 'enableTitle', true),
      showSubtitle: getDynamicField(data, 'enableTeaserText', false),
      showDate: getDynamicField(data, 'showDate', true),
    },
  };
};
