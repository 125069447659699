import benefitsMapFr from './static/benefitsFr.json';
import benefitsMapDefault from './static/benefitsEn.json';

const allowedBenefits = {
  'en-us': benefitsMapDefault,
  'en-ca': benefitsMapDefault,
  'fr-ca': benefitsMapFr,
};

export const extractBenefitsFromProductData = (
  benefitsAttribute: string[] = [],
  activityAttribute: string[] = []
): string[] => {
  const maxSnapshotItems = 3;
  if (benefitsAttribute.length === 0 && activityAttribute.length === 0)
    return [];
  if (benefitsAttribute.length >= 3)
    return benefitsAttribute.slice(0, maxSnapshotItems);
  if (benefitsAttribute.length === 2 && activityAttribute.length > 0)
    return [activityAttribute[0], ...benefitsAttribute].slice(
      0,
      maxSnapshotItems
    );
  if (benefitsAttribute.length === 2 && activityAttribute.length === 0)
    return [];
  if (benefitsAttribute.length === 1 && activityAttribute.length >= 2)
    return [...activityAttribute, ...benefitsAttribute].slice(
      0,
      maxSnapshotItems
    );
  if (benefitsAttribute.length === 1 && activityAttribute.length === 1)
    return [];
  if (benefitsAttribute.length === 0 && activityAttribute.length >= 3)
    return [...activityAttribute].slice(0, maxSnapshotItems);
  return [];
};

/**
 * Return Product's snapshot attributes
 */
export const productSnapshots = (
  benefitsAttribute: string[] = [],
  activityAttribute: string[] = [],
  locale: string
): string[] => {
  const extractedBenefits = extractBenefitsFromProductData(
    benefitsAttribute,
    activityAttribute
  );

  return (allowedBenefits[locale] || benefitsMapDefault).filter((benefit) =>
    extractedBenefits.includes(benefit.alt)
  );
};
