import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { extractCommonConfigLink, extractProperty } from './utils';
import {
  getTranslation,
  getComponentConfigByName,
} from './utils/extractProperty';
import { ComposableContext } from '../types';

export const checkoutPromoCode = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const config = getComponentConfigByName(
    data,
    'checkout-promocode-settings',
    {}
  );

  return {
    component: 'checkout/CheckoutPromoCode',
    props: {
      translations: {
        close: getTranslation(data, 'close', '$Close$'),
        promoCodeTitle: getTranslation(data, 'promoCodeTitle', '$Promo code$'),
        promoNotApplied: getTranslation(
          data,
          'promoNotApplied',
          '$Promo Not Applied$'
        ),
        promoNotActive: getTranslation(
          data,
          'promoNotActive',
          '$Promo Not Active$'
        ),
        ...getTranslation(data, 'promo_code', {
          title: '$Promo code$',
          label: '$Enter Promo Code$',
          btnApply: '$Apply$',
          removePromo: '$Remove Promo$',
          info: '$Where do I get promo codes?$',
          tooltip: '',
          addAnother: '$Add another promo$',
          promoApplied: '$Promo Applied$',
          notifications: {
            applied: '$The promotion code has been applied$',
            appliedNotApplicable:
              '$The promotion code is not applied to the cart, as your cart is not eligible for the promotion.$',
            removed: '$The promotion code has been removed$',
            error:
              '$The promotion code you have entered is invalid. Please verify the code and try again.$',
          },
        }),
        currencySymbol: extractProperty(
          extractCommonConfigLink(siteConfiguration, 'currencySymbol'),
          '[0]'
        ),
      },
      accordionOpened: extractProperty(config, 'accordionOpened', false),
    },
  };
};
