import { ComponentInstance } from '../types';
import { PaymentMethodCode, apiClientFactory } from '@vf/api-client';
import { groupShipmentWithItems } from '../utils/orders';
import { useAccount } from '../useAccount';
import { useCart, useApplePayPdpCart } from '../useCart';
import { useI18n } from '../useI18n';
import useKlarna from '../useKlarna';
import useNotification from '../useNotification';
import { useUtilities } from '../useUtilities';

import { computed } from '@nuxtjs/composition-api';
import { ROUTES } from '../utils/routes';
import { useRequestTracker } from '../useRequestTracker';
import { errorMessages } from '../utils/errorMessages';
import { prepareLocale } from '../utils/query';
import { ShippingMethod } from '@vf/api-contract';
import {
  scrollToFirstError,
  getDeliveryDate,
} from '@vf/shared/src/utils/helpers';
import { compareAsc } from 'date-fns';
import { ApplePayContext } from '../useApplePay/types';

import { useAddress } from './composables/useAddress';
import { usePickup } from './composables/usePickup';
import { storeToRefs } from 'pinia';
import { useAddressFormValidations } from './composables/useAddressFormValidations';
import { useCheckoutStore } from '../store/checkoutStore';
import { useAddressValidationServiceStore } from '../store/addressValidationService';
import { useOrderStore } from '../store/order';
import { useUserInterfaceStore } from '../store/userInterface';
import { useAddressesStore } from '../store/addresses';
import { useOrders } from './composables/useOrders';
import { usePromotions } from './composables/usePromotions';
import { useUserStore } from '../store/user';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { useRewardStore } from '../store/rewards';

const useCheckout = (instance: ComponentInstance, contextKey?: string) => {
  const addressesStore = useAddressesStore(instance);
  const addressValidationServiceStore = useAddressValidationServiceStore();
  const uiStore = useUserInterfaceStore();
  const orderStore = useOrderStore();
  const checkoutStore = useCheckoutStore();

  const {
    saveBillingAddress,
    saveShippingAddress,
    isShippingAddressIncomplete,
  } = addressesStore;

  const { billingSameAsShipping, isPoAddress } = storeToRefs(addressesStore);
  const {
    pickupFormAccordion,
    altPickupFormAccordion,
    shippingFormAccordion,
    billingFormAccordion,
    paymentFormAccordion,
    wasShippingStepSkippedByUser,
  } = storeToRefs(uiStore);

  const {
    confirmAddressAndClearAVSStorage,
    setAVSSkip,
    AVSrestoreAddress,
  } = addressValidationServiceStore;
  const {
    AVSchanged,
    AVSisAddressInvalid,
    AVSdone,
    AVSskip,
    AVSoldAddress,
  } = storeToRefs(addressValidationServiceStore);
  const {
    isPlaceOrderButtonDisabled,
    loading,
    applePayOrderToken,
    savedCreditCard,
    paymentMethod,
    paymentMethodsData,
    allAvailablePaymentMethods,
  } = storeToRefs(orderStore);

  const { getStateCode } = useUtilities(instance);

  const { getPaymentMethods: getPaymentMethodsAPI } = apiClientFactory(
    instance
  );

  const {
    cart,
    payFullWithGiftCard,
    cartId,
    shippingGroups,
    hasPickupItems,
    hasShippingItems,
    isProceedBillingButtonDisabled,
    clearCartLineItemCustomerNotifications,
  } =
    contextKey === ApplePayContext.PDP
      ? useApplePayPdpCart(instance)
      : useCart(instance);

  const {
    loadErrorMessage,
    openAddressValidationError,
    validateShippingAddressForm,
  } = useAddressFormValidations(instance);

  const {
    basicInformation,
    addAddress,
    getConsumerCreditCards,
    getAddresses,
    paymentInstruments,
  } = useAccount(instance);

  const {
    billingAddress,
    shippingAddress,
    setShippingAddress,
    storeAddresses,
    validateAddress,
    checkIfAddressFieldValueChanged,
    shouldStoreBillingAddress,
    storeBillingAddress,
    moveDefaultAddressToShipping,
    checkAddressChanges,
    setShippingAddressFromCart,
    setBillingAddress,
    setPickupAddress,
  } = useAddress(
    instance,
    {
      addAddress,
      getAddresses,
      getStateCode,
      basicInformation,
    },
    contextKey
  );

  const {
    isZeroOrder,
    placeOrder,
    sendOrder,
    resetPlaceOrderButton,
  } = useOrders(
    instance,
    {
      paymentInstruments,
      shouldStoreBillingAddress,
      storeBillingAddress,
    },
    contextKey
  );

  const {
    validatePickupForm,
    pickupAddress,
    openPickupValidationError,
    isPickupDataValid,
    setPickupAddressData,
    setPickupAddressField,
    setupInitialPickupAddress,
  } = usePickup(instance, { cart, storeAddresses });

  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const userStore = useUserStore(instance);
  const { loggedIn } = storeToRefs(userStore);
  const { localePath, localeCode } = useI18n(instance);
  const { displayErrorMessages } = errorMessages(instance);
  const { notifications } = useNotification(instance);
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  const {
    updateKlarnaSession,
    loadKlarnaSdk,
    isKlarnaSdkLoaded,
    isKlarnaInPaymentMethods,
    handleAddressUpdateForKlarna,
  } = useKlarna(instance);

  const getPaymentMethods = async (
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    if (cartId.value) {
      const { tag } = trackRequest(
        'useCheckout-getPaymentMethods',
        isBackgroundRequest
      );
      try {
        const query: string[] = [prepareLocale(localeCode())];
        if (isCheckoutRedesignEnabled) {
          query.push('v2CheckoutEnabled=true'); // TODO: GLOBAL15-55324 clean up
        }
        const [methods] = await Promise.all([
          getPaymentMethodsAPI(cartId.value, query.join('&')),
          ...(loggedIn.value ? [getConsumerCreditCards()] : []),
        ]);

        allAvailablePaymentMethods.value = methods.data;

        const paymentMethodsHiddenInUi = [
          PaymentMethodCode.GIFT_CARD,
          PaymentMethodCode.REWARD_CARD,
          PaymentMethodCode.REWARD_CERTIFICATE,
          PaymentMethodCode.REWARD_CODE,
          PaymentMethodCode.LOYALTY_POINTS,
        ];

        paymentMethodsData.value = methods.data.filter(
          (payment) => !paymentMethodsHiddenInUi.includes(payment.code)
        );
        /** Fetch reward cards if acceptable */
        if (
          loggedIn.value &&
          methods.data.find((payment) =>
            [
              PaymentMethodCode.REWARD_CARD,
              PaymentMethodCode.REWARD_CODE,
            ].includes(payment.code)
          )
        ) {
          await useRewardStore(instance).getRewards();
        }
      } catch (e) {
        displayErrorMessages(e);
      } finally {
        clearRequest(tag, isBackgroundRequest);
      }
    } else {
      console.error('getPaymentMethods: No cart id');
      return null;
    }
  };

  const { applyPromoCode, deletePromoCode } = usePromotions(instance, {
    cartId,
    getPaymentMethods,
  });

  const handleAmountToPayUpdate = async (total) => {
    if (total <= 0) {
      return false;
    }
    try {
      await getPaymentMethods();
      if (
        isKlarnaInPaymentMethods(paymentMethodsData) &&
        !isKlarnaSdkLoaded.value
      ) {
        await loadKlarnaSdk();
      }
      if (
        !payFullWithGiftCard.value &&
        !paymentMethodsData.value.find(
          (method) => method.code === paymentMethod.value
        )
      ) {
        paymentMethod.value = paymentMethodsData.value[0]?.code;
      }
      if (paymentMethod.value === PaymentMethodCode.KLARNA) {
        await updateKlarnaSession();
      }
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const hasCustomsAndPOAddress = computed(() => {
    const hasCustomShippingMethod = shippingGroups.value.filter(
      (shippingMethod: ShippingMethod) =>
        shippingMethod.code.startsWith('customs')
    );
    return [hasCustomShippingMethod, isPoAddress.value].every(Boolean);
  });

  const goToPayment = async () => {
    if (isProceedBillingButtonDisabled.value || hasCustomsAndPOAddress.value) {
      return;
    }
    if (
      notifications.value.some(
        (notification) => notification.errorMessageId === 'INV409'
      )
    ) {
      // do not allow proceeding to payment if product is not in stock
      loadErrorMessage();
      return;
    }
    if (hasPickupItems.value && !validatePickupForm()) {
      openPickupValidationError();
      return;
    }
    if (hasShippingItems.value && !validateShippingAddressForm()) {
      openAddressValidationError(true);
      return;
    }
    if (hasPickupItems.value) {
      const arePickupAddressesValid = () => {
        const requiredFields = ['firstName', 'lastName', 'email'];
        return cart.value.shippingMethods.every((method) => {
          return requiredFields.every((fieldName) => method.address[fieldName]);
        });
      };
      if (!arePickupAddressesValid()) {
        await storeAddresses();
      }
      setAVSSkip(true);
      instance.$router.push(localePath(ROUTES.CHECKOUT_PAYMENT()));
      return;
    }
    isProceedBillingButtonDisabled.value = true;
    try {
      const addressValidationResponse = await validateAddress(cart.value.id);
      if (addressValidationResponse?.data) {
        const hasAddressChanged = await checkAddressChanges(
          addressValidationResponse.data,
          { changeShippingAddress: true }
        );
        if (!hasAddressChanged) {
          openAddressValidationError(false);
          return;
        }
        const AVSskipBackup = AVSskip.value;
        if (loggedIn.value && getAddresses('S').value.length === 0) {
          const data = {
            ...shippingAddress.value,
            approachType: 'S',
            main: true,
          };
          delete data.id;
          delete data.addressId;

          const res = await addAddress(data);
          if (res.status === 201) {
            shippingAddress.value = {
              ...shippingAddress.value,
              id: res.data.addressId,
            };
          }
        }
        setAVSSkip(AVSskipBackup);
        // clear notifications already rendered on shipping page
        // before proceeding to billing page
        clearCartLineItemCustomerNotifications();
        instance.$router.push(localePath(ROUTES.CHECKOUT_PAYMENT()));
      }
    } catch (e) {
      openAddressValidationError(false);
    } finally {
      isProceedBillingButtonDisabled.value = false;
    }
  };

  // TODO move it store getter
  const shipmentsSortedByDeliveryTime = computed(() => {
    const { shipments, items } = checkoutStore.order as any;

    return (
      groupShipmentWithItems(shipments, items)
        // sort shipments by deliveryTime if provided
        .sort((shipmentA, shipmentB) => {
          const dateA = getDeliveryDate(shipmentA.items[0].deliveryTime);
          if (dateA === null) return 1;
          const dateB = getDeliveryDate(shipmentB.items[0].deliveryTime);
          if (dateB === null) return -1;
          return compareAsc(dateA, dateB);
        })
    );
  });

  const showPlaceOrderButtonAtPayment = computed(
    () =>
      paymentMethod.value !== PaymentMethodCode.PAYPAL &&
      paymentMethod.value !== PaymentMethodCode.APPLEPAY
  );

  const setPaymentMethod = (code: PaymentMethodCode) => {
    paymentMethod.value = code;
  };

  return {
    applyPromoCode,
    deletePromoCode,
    setShippingAddress,
    setBillingAddress,
    setPickupAddress,
    confirmAddressAndClearAVSStorage,
    AVSrestoreAddress,
    shippingAddress,
    billingAddress,
    isPickupDataValid,
    wasShippingStepSkippedByUser,
    getPaymentMethods,
    handleAmountToPayUpdate,
    handleAddressUpdateForKlarna,
    storeAddresses,
    setPickupAddressField,
    setPickupAddressData,
    billingSameAsShipping,
    paymentMethod,
    savedCreditCard,
    placeOrder,
    sendOrder,
    resetPlaceOrderButton,
    moveDefaultAddressToShipping,
    validateAddress,
    checkIfAddressFieldValueChanged,
    pickupAddress,
    pickupFormAccordion,
    altPickupFormAccordion,
    shippingFormAccordion,
    billingFormAccordion,
    paymentFormAccordion,
    AVSchanged,
    AVSisAddressInvalid,
    AVSdone,
    AVSskip,
    setAVSSkip,
    AVSoldAddress,
    checkAddressChanges,
    isPlaceOrderButtonDisabled,
    isPoAddress,
    isZeroOrder,
    paymentMethodsData,
    setupInitialPickupAddress,
    goToPayment,
    // TODO this should not be return from useCheckout because its already shared utility
    scrollToFirstError,
    openAddressValidationError,
    validateShippingAddressForm,
    shipmentsSortedByDeliveryTime,
    setShippingAddressFromCart,
    saveShippingAddress,
    saveBillingAddress,
    loading: computed(() => loading.value),
    applePayOrderToken,
    showPlaceOrderButtonAtPayment,
    isShippingAddressIncomplete,
    validatePickupForm,
    setPaymentMethod,
  };
};

export default useCheckout;
