import { CMVideo, CmsSite } from '@vf/api-contract';
import { getMediaSeoAttributes, getVideoObject } from '../utils';

import { ComposableContext } from '../../types';

export const CMVideoMapping = (
  data: CMVideo,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const videoSrcData = getVideoObject([data], cmsBaseUri);

  return {
    component: 'common/VideoComponent',
    props: {
      video: { small: videoSrcData },
      seo: getMediaSeoAttributes(data),
    },
  };
};
