import { CMTeaser, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  generateLinkFromTeaserTargets,
  getImageObject,
  getMediaSeoAttributes,
} from './utils';

export const loyaltyPdpBanner = (
  data: CMTeaser,
  context,
  cmsSiteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const getImage = () => {
    const pictures = extractProperty(data, 'pictures[0]');

    if (!pictures) {
      return null;
    }

    const seo = getMediaSeoAttributes(pictures);
    const images = getImageObject(
      pictures,
      cmsSiteConfiguration,
      null,
      cmsBaseUri,
      data.responsiveMedia
    );

    return {
      images,
      seo,
    };
  };
  return {
    component: 'loyalty/LoyaltyPdpBanner',
    props: {
      title: extractProperty(data, 'teaserTitle', '$EARN POINTS GET REWARDS$'),
      imageObject: getImage(),
      link: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        cmsSiteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
      openInNewModal: extractProperty(data, 'openInNewModal', true),
      ctaLabel:
        extractProperty(data, 'teaserTargets[0].callToActionText', '') ||
        extractProperty(
          data,
          'teaserTargets[0].target.teaserTitle',
          '$LEARN MORE$'
        ),
    },
  };
};
