import { CMPlaceholder, SelectorVariant } from '@vf/api-contract';
import { getTranslation, getDynamicField } from './utils/extractProperty';

export const pdpQuantitySelect = (data: CMPlaceholder) => {
  return {
    component: 'pdp/ProductQuantitySelect',
    props: {
      showNumeration: getDynamicField(data, 'showNumeration', true),
      maxQuantity: getDynamicField(data, 'maxQuantity', 5),
      hideComponent: getDynamicField(data, 'hide-component', false),
      displayAs: getDynamicField(data, 'display-as', SelectorVariant.Dropdown),
      translations: {
        quantityLabel: getTranslation(data, 'quantityLabel', '$Quantity$'),
      },
    },
  };
};
