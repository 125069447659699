import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const setFamilyReward = (configuration: ApiClientConfiguration) => (
  cartId: string,
  rewardToApply: number
): Promise<AxiosResponse<any>> => {
  const baseUrl = endpoints.get(configuration).checkout.setFamilyReward;

  const url: string = urlBuilder(baseUrl, {
    config: configuration,
    mapping: { basketId: cartId },
  });

  return axios.put(
    url,
    { appliedLoyaltyVoucher: rewardToApply },
    {
      // TODO: move payload to the variable and add type.
      headers: headerBuilder(configuration),
    }
  );
};
