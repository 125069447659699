var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subscribe)?_c('div',{staticClass:"footer-bottom-section__subscribe",class:[_vm.subscribe.classModifiers],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.subscribe.id)}},[_c('h2',[_c('span',{staticClass:"vf-footer-column__title",class:[_vm.subscribe.titleClassModifiers],style:({
        '--vf-footer-subscribe-title-color': _vm.subscribe.titleColor,
        '--vf-footer-subscribe-paragraph-font-weight': _vm.subscribe.fontWeight,
        '--vf-footer-subscribe-paragraph-font-size': _vm.subscribe.fontSize,
        '--vf-footer-subscribe-paragraph-font-family': _vm.subscribe.fontFamily,
      }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.subscribe.id, 'teaserTitle')}},[_vm._v("\n      "+_vm._s(_vm.subscribe.title)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"vf-footer-column__paragraph",style:({
      '--vf-footer-subscribe-paragraph-color': _vm.subscribe.textColor,
      '--vf-footer-subscribe-paragraph-font-weight': _vm.subscribe.fontWeight,
      '--vf-footer-subscribe-paragraph-font-size': _vm.subscribe.fontSize,
      '--vf-footer-subscribe-paragraph-font-family': _vm.subscribe.fontFamily,
    }),attrs:{"id":"footer-email-signup-description","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.subscribe.id, 'teaserText')},domProps:{"innerHTML":_vm._s(_vm.subscribe.helperText)}}),_vm._v(" "),_c('VfSubscriptionForm',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.subscribe.id, 'resourceBundle'),"aria-label":_vm.subscribe.translations.emailLabel,"label":_vm.subscribe.translations.emailLabel,"button-text":_vm.subscribe.translations.sendButtonText,"validation-messages":_vm.subscribe.validationMessages,"privacy-text":_vm.privacyText,"privacy-error":_vm.subscribe.translations.privacyError,"show-privacy":_vm.subscribe.showPrivacy,"use-icon-for-button":_vm.subscribe.useIconForButton,"show-input-label-above":_vm.showInputLabelAbove},on:{"subscribe":_vm.handleSubscription,"subscribe-shown":_vm.handleSubscribeShown,"clear-notifications":_vm.clearNotifications}}),_vm._v(" "),_c('VfNotification',{staticClass:"footer-bottom-section__subscribe-notification",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.subscribe.id, 'resourceBundle'),"visible":_vm.newsletterNotification.showNotification,"type":_vm.newsletterNotification.isSuccess ? 'success' : 'danger'},on:{"click-close":_vm.clearNotifications},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.newsletterNotification.isSuccess
          ? _vm.subscribe.validationMessages.successMessage
          : _vm.subscribe.validationMessages.errorMessage)+"\n    ")]},proxy:true}],null,false,2176089550)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }