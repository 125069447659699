var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.storeLocator)?_c('div',{staticClass:"footer-bottom-section__store-locator vf-footer-column",class:[
    _vm.storeLocator.classModifiers,
    _vm.isCoreRedesignEnabled
      ? 'footer-bottom-section__store-locator--redesign-core'
      : '' ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.storeLocator.id, 'resourceBundle')}},[_c('h2',[_c('span',{staticClass:"vf-footer-column__title",class:[
        _vm.storeLocator.titleClassModifiers,
        _vm.isCoreRedesignEnabled ? '' : _vm.storeLocator.fontFamily ],style:({
        '--vf-footer-store-locator-title-color': _vm.storeLocator.titleColor,
        '--vf-footer-store-locator-title-font-size': _vm.storeLocator.fontSize,
        '--vf-footer-store-locator-title-font-weight':
          _vm.storeLocator.fontWeight,
      }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.storeLocator.id, 'teaserTitle')}},[_vm._v("\n      "+_vm._s(_vm.storeLocator.title)+"\n    ")])]),_vm._v(" "),_c('p',{staticClass:"vf-footer-column__paragraph",style:({
      '--vf-footer-store-locator-paragraph-color': _vm.storeLocator.textColor,
    }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.storeLocator.id, 'teaserText')}},[_vm._v("\n    "+_vm._s(_vm.storeLocator.helperText)+"\n  ")]),_vm._v(" "),(_vm.isCoreRedesignEnabled)?_c('ThemeButton',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.storeLocator.id, 'teaserTargets'),"to":_vm.storeLocator.link,"color":"primary","size":"lg"},on:{"click":_vm.handleClick}},[_vm._v("\n    "+_vm._s(_vm.storeLocator.buttonText)+"\n  ")]):_c('UiLink',{staticClass:"vf-button vf-button--fullWidth vf-button--hover-variant vf-button--flex footer-bottom-section__store-locator-button",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.storeLocator.id, 'teaserTargets'),"to":_vm.storeLocator.link},on:{"click":_vm.handleClick}},[_vm._v("\n    "+_vm._s(_vm.storeLocator.buttonText)+"\n  ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }