import { extractProperty, getTeaserStyle } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const pdpCustomizeCta = (data: CMPlaceholder) => {
  const recipeId = extractProperty(
    data,
    'localSettings.dynamicFields.recipeId',
    false
  );

  const style = {
    primary: {
      color: 'primary',
    },
    secondary: {
      color: 'secondary',
    },
    tertiary: {
      color: 'secondary',
      variant: 'border',
    },
    'tertiary-variant': {
      color: 'secondary',
      variant: 'border',
    },
    text: {
      color: 'primary',
    },
    'hover-variant': {
      color: 'primary',
      context: 'dark',
    },
    default: {
      color: 'primary',
    },
  }[extractProperty(data, 'localSettings.dynamicFields.style') || 'default'];

  const size = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
    fullWidth: 'lg',
  }[extractProperty(data, 'localSettings.dynamicFields.size') || 'medium'];

  return {
    component: recipeId
      ? 'pdp/ProductCustomizeByRecipeId'
      : 'pdp/ProductCustomize',
    props: {
      enabled: extractProperty(
        data,
        'localSettings.dynamicFields.enabled',
        true
      ),
      text: extractProperty(data, 'title'),
      textCustomClasses: getTeaserStyle(data, 'teaserTextClassModifiers'),
      icon: extractProperty(data, 'localSettings.dynamicFields.icon', ''),
      buttonStyle: style,
      iconPosition: extractProperty(
        data,
        'localSettings.dynamicFields.iconPosition'
      ),
      buttonSize: size,
      underline: extractProperty(
        data,
        'localSettings.dynamicFields.underline',
        false
      ),
      isFullWidth:
        extractProperty(data, 'localSettings.dynamicFields.size') ===
        'fullWidth',
      buttonComponent:
        extractProperty(data, 'localSettings.dynamicFields.style') === 'text'
          ? 'ThemeLink'
          : 'ThemeButton',
      recipeId,
    },
  };
};
