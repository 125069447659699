import { ref } from '@vue/composition-api';
import { ComponentInstance } from '../../../types';
import { useUserStore } from '../../../store/user';
import { storeToRefs } from 'pinia';

type PromiseFx = () => Promise<any>;
const pendingAction = ref<null | PromiseFx>(null);

export const useLoyaltyEnrolled = (instance: ComponentInstance) => {
  // TODO maybe this should be merged with useLoyalty
  const isLoyaltyEnabled = instance.$getEnvValueByCurrentLocale<boolean>(
    'LOYALTY_IS_ENABLED'
  );
  const userStore = useUserStore(instance);
  const { loyaltyEnrolledCookieName } = userStore;
  const { loyaltyEnrolled } = storeToRefs(userStore);

  const setLoyaltyEnrolled = (isEnrolled: boolean) => {
    loyaltyEnrolled.value = isEnrolled;
  };

  const processPendingActionForLoyaltyUser = async () => {
    if (pendingAction.value !== null) {
      const cb = pendingAction.value;
      setPendingActionForLoyaltyUser(null);
      await cb();
    }
  };

  const setPendingActionForLoyaltyUser = (cb: null | PromiseFx) => {
    pendingAction.value = cb;
  };

  return {
    loyaltyEnrolledCookieName,
    isLoyaltyEnabled,
    setLoyaltyEnrolled,
    loyaltyEnrolled,
    processPendingActionForLoyaltyUser,
    setPendingActionForLoyaltyUser,
  };
};
