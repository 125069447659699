import { CMPlaceholder, CmsSite, CMTeaser } from '@vf/api-contract';
import {
  extractCommonConfigLink,
  extractLocalSettingsExpandedLinks,
  extractProperty,
  generateLinkFromTeaserTargets,
  getDataNavigationValue,
  getDynamicField,
  getImageObject,
  getMediaSeoAttributes,
  getTranslation,
} from './utils';
import { ComposableContext } from '../types';
import { getApplePayComponentData } from './utils/getApplePayComponentData';

const getBottomImage = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const [bottomImageLink]: [CMTeaser] = extractLocalSettingsExpandedLinks(
    data,
    'bottomImage'
  );

  if (!bottomImageLink?.pictures?.[0]) {
    return null;
  }

  const [picture] = bottomImageLink.pictures;

  const imageSrcData = getImageObject(
    picture,
    siteConfiguration,
    null,
    cmsBaseUri,
    bottomImageLink.responsiveMedia
  );

  return {
    src: imageSrcData,
    seo: getMediaSeoAttributes(picture),
    alt: extractProperty(picture, 'alt'),
    openInNewTab: extractProperty(bottomImageLink, 'openInNewTab'),
    openInNewModal: extractProperty(bottomImageLink, 'openInNewModal'),
    dataNavigation: getDataNavigationValue(bottomImageLink),
    link: generateLinkFromTeaserTargets(
      bottomImageLink.teaserTargets,
      context,
      siteConfiguration,
      bottomImageLink.urlParams,
      cmsBaseUri
    ),
  };
};

export const cartMini = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const links = extractCommonConfigLink(siteConfiguration, 'promotions');
  const promotionsData = links.find(
    (link) => link.name === 'bounceBackVouchers'
  );

  const promotionMessages: PromotionMessage[] = extractProperty(
    promotionsData,
    'settings.miniCart'
  );

  const applePayData = getApplePayComponentData(
    data,
    cmsBaseUri,
    context,
    siteConfiguration
  );

  return {
    component: 'minicart/MiniCart',
    props: {
      bottomImage: getBottomImage(data, context, siteConfiguration, cmsBaseUri),
      showExpressCheckoutButtons: getDynamicField(
        data,
        'showExpressCheckoutButtons',
        true
      ),
      translations: {
        or: getTranslation(data, 'or', '$OR$'),
        qty: getTranslation(data, 'qty', '$Quantity$'),
        remove: getTranslation(data, 'remove', '$Remove$'),
        title: getTranslation(data, 'title', '$Your Cart$'),
        totalItems: getTranslation(data, 'totalItems', '$Total Items:$'),
        emptyCartInfo: getTranslation(
          data,
          'emptyCartInfo',
          '$There are no items in your cart$'
        ),
        close: getTranslation(data, 'close', '$Close$'),
        subtotal: getTranslation(data, 'subtotal', '$Subtotal:$'),
        checkoutCTA: getTranslation(data, 'checkoutCTA', '$Checkout$'),
        shareMyCartCTA: getTranslation(
          data,
          'shareMyCartCTA',
          '$Share my cart$'
        ),
        viewFavorites: getTranslation(
          data,
          'viewFavorites',
          '$View Favorites$'
        ),
        freeShippingInfo: getTranslation(
          data,
          'freeShippingInfo',
          '$Free shipping$'
        ),
        singleItem: getTranslation(data, 'singleItem', '$item$'),
        multipleItems: getTranslation(data, "'multipleItems'", '$items$'),
        promotions: {
          success: {
            title: extractProperty(
              promotionMessages,
              'success.title',
              "$You've qualified$"
            ),
            message: extractProperty(
              promotionMessages,
              'success.message',
              '${{discountAmount}} off your next purchase!$'
            ),
          },
          approaching: {
            title: extractProperty(
              promotionMessages,
              'approaching.title',
              "$You're almost there$"
            ),
            message: extractProperty(
              promotionMessages,
              'approaching.message',
              "$You're {{remainingAmount}} away from Free Shipping!$"
            ),
          },
        },
      },
      showApplePayComponent: !!applePayData.component,
      applePayTranslations: applePayData.translations,
    },
  };
};

type PromotionMessage = {
  title: string;
  message: string;
};
