import { CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  getImageObject,
  getTeaserStyle,
  getDataNavigationValue,
  generateLinkFromTeaserTargets,
  decorateHtmlValue,
  getVideoObject,
  getVideoResponsiveMedia,
  getButtons,
} from './utils';

const videoExists = (data, viewport) => {
  return (
    extractProperty(data, `responsiveMedia.${viewport}[0].type`, '') ===
    'CMVideo'
  );
};

export const teaserGridItem = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const hasVideo = data.videos.length > 0;
  const getImage = () => {
    const pictures = extractProperty(data, 'pictures');
    if (!pictures) {
      return null;
    }
    if (!hasVideo) {
      return Array.isArray(pictures) && pictures[0]
        ? getImageObject(pictures[0], siteConfiguration, null, cmsBaseUri, null)
        : {
            small: 'https://via.placeholder.com/440',
            medium: 'https://via.placeholder.com/440',
            large: 'https://via.placeholder.com/440',
          };
    }
  };
  const linkTeaserTargets = extractProperty(
    data,
    'teaserTargets[0].target.teaserTargets',
    false
  );

  return {
    component: 'shared/TeaserTile',
    props: {
      image: getImage(),
      video: {
        small: {
          ...getVideoObject(data.videos, cmsBaseUri),
          medium: videoExists(data, 'medium')
            ? {
                ...getVideoResponsiveMedia(data, cmsBaseUri, 'medium'),
              }
            : null,
          large: videoExists(data, 'large')
            ? {
                ...getVideoResponsiveMedia(data, cmsBaseUri, 'large'),
              }
            : null,
        },
      },
      translations: {
        readMore: extractProperty(
          data,
          'resourceBundle.common.readMoreText',
          '$Read more$'
        ),
        showLess: extractProperty(
          data,
          'resourceBundle.common.readLessText',
          '$Read less$'
        ),
      },
      styles: {
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        headingLevel: getTeaserStyle(data, 'teaserTitleHeadingLevel'),
        titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
        textColor: getTeaserStyle(data, 'teaserTextColor'),
        textBackgroundColor: getTeaserStyle(data, 'teaserTextBackgroundColor'),
        textClassModifiers: getTeaserStyle(data, 'teaserTextClassModifiers'),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        teaserClass: getTeaserStyle(data, 'teaserClassModifiers'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        titleBackgroundColor: getTeaserStyle(
          data,
          'teaserTitleBackgroundColor'
        ),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
      },
      textAlign: {
        small: extractProperty(
          data,
          'localSettings.dynamicFields.contentAlign',
          'left'
        ),
        medium: extractProperty(
          data,
          'localSettings.dynamicFields.contentAlign',
          'left'
        ),
        large: extractProperty(
          data,
          'localSettings.dynamicFields.contentAlign',
          'left'
        ),
      },
      text: data.useRichText
        ? decorateHtmlValue(
            data.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : data.teaserText,
      title: extractProperty(data, 'teaserTitle'),
      useRichText: extractProperty(data, 'useRichText', false),
      linkCta: extractProperty(data, 'teaserTargets[0].target.teaserTitle'),
      ctaButtons: getButtons(data, context, siteConfiguration, cmsBaseUri),
      buttonStyle: extractProperty(
        data,
        'teaserTargets[0].target.localSettings.dynamicFields.style',
        'text'
      ),
      buttonSize: extractProperty(
        data,
        'teaserTargets[0].target.localSettings.dynamicFields.size',
        ''
      ),
      buttonIcon: extractProperty(
        data,
        'teaserTargets[0].target.localSettings.dynamicFields.icon',
        ''
      ),
      buttonIconPosition: extractProperty(
        data,
        'teaserTargets[0].target.localSettings.dynamicFields.iconPosition',
        ''
      ),
      buttonUnderline: extractProperty(
        data,
        'teaserTargets[0].target.localSettings.dynamicFields.underline',
        false
      ),
      maxLinesSmall: Number(
        extractProperty(data, 'localSettings.dynamicFields.maxLinesSmall', 0)
      ),
      maxLinesMedium: Number(
        extractProperty(data, 'localSettings.dynamicFields.maxLinesMedium', 5)
      ),
      maxLinesLarge: Number(
        extractProperty(data, 'localSettings.dynamicFields.maxLinesLarge', 6)
      ),
      link: linkTeaserTargets?.length
        ? generateLinkFromTeaserTargets(
            linkTeaserTargets,
            context,
            siteConfiguration,
            data.urlParams,
            cmsBaseUri
          )
        : '',
      dataNavigation: getDataNavigationValue(data),
    },
  };
};
