import {
  CMPlaceholder,
  CMTeaser,
  ComponentConfig,
  FormValidationConfig,
} from '@vf/api-contract';

export const getValidationConfigValues = (
  data: CMPlaceholder | CMTeaser
): FormValidationConfig => {
  if (data && data.componentConfig) {
    const config: ComponentConfig<FormValidationConfig> = data.componentConfig.find(
      (conf: ComponentConfig) => conf.name === 'validation'
    );

    if (config && config.values) {
      return config.values;
    }
  }

  return {
    zipCodeFormat: '',
    zipCodeRegex: '',
    birthdayFormat: '',
    birthdayRegex: '',
    phoneNumberFormat: '',
    phoneNumberRegex: '',
    phoneNumberMask: '',
  };
};
