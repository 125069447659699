import { Plugin } from '@nuxt/types';
import { apiClientFactory } from '@vf/api-client';
import type {
  ComponentInstance,
  CloudinaryUrlGeneratorObject,
  MediaUrlGeneratorObject,
  S7UrlGeneratorObject,
} from '@vf/composables/src/types';
import type { ImageTransformations } from '@vf/api-contract/src/cloudinary';
import { getVueInstanceFromContext } from '../helpers';
import { storeLocatorLinkBuilder } from '../helpers/storeLocatorLinkBuilder';
import { useI18n } from '@vf/composables';
import { logger } from '@vf/shared/src/utils/logger-server';
import { slugify } from '@vf/shared/src/utils/slugify';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

const linkGenerators: Plugin = (context, inject) => {
  const defaultTransformationTokens: string[] = [];

  const transformationTokenMap: Record<keyof ImageTransformations, string> = {
    align: 'c_pad,b_white',
    format: 'f_',
    height: 'h_',
    size: 'c_scale,w_',
    width: 'w_',
    quality: 'q_',
    sharpness: 'e_sharpen:',
  };

  function generateTransformationValues(
    imageTransformations: ImageTransformations
  ): string {
    const transformationValues = new Set(defaultTransformationTokens);
    const t = { ...imageTransformations };
    const hasRedundantDimensions = t.size && (t.height || t.width);

    /* If we do not receive an override format, will use auto by default */
    t.format ??= 'auto';

    if (hasRedundantDimensions) {
      delete t.height;
      delete t.width;
      logger.debug(
        '[@packages/theme/plugins/link-generators::generateTransformationValues] Both a size and height/width value was set. Defaulting to size.'
      );
    }

    if (imageTransformations.height && imageTransformations.width)
      transformationValues.add(`${transformationTokenMap.align}`);

    for (const [key, token] of Object.entries(transformationTokenMap))
      if (t[key]) transformationValues.add(`${token}${t[key]}`);

    return [...transformationValues].join(',');
  }

  function storeLocatorUrlGenerator(
    startLink: string,
    state = '',
    city = '',
    clientKey = ''
  ): string {
    const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
    const { localePath } = useI18n(vueInstance);
    const endpoint = storeLocatorLinkBuilder(startLink, state, city, clientKey);
    return localePath(endpoint);
  }

  function generateS7Url(
    hostname,
    type,
    fullPath = '',
    name = '',
    pid = '',
    shotType = '_hero',
    preset = '',
    colorCode = ''
  ) {
    const prefixColorCode = colorCode ? `_${colorCode}` : '';
    return `${hostname}/is/${type}/${
      fullPath || name + '/' + pid + prefixColorCode + shotType
    }${preset ? `?${preset}` : ''}`;
  }

  function generatePresetsFromSizes(
    height: number,
    width: number,
    quality: number,
    resMode?: string,
    opSum?: string,
    bgc?: string
  ) {
    const resModeVal = resMode ? `&resMode=${resMode}` : '';
    const opSumVal = opSum ? `&op_sum=${opSum}` : '';
    const bgcVal = bgc ? `&bgc=${bgc}` : '';
    return `hei=${height}&wid=${width}&qlt=${quality}${resModeVal}${opSumVal}${bgcVal}`;
  }

  function getS7ObjectUrls(
    hostname: string,
    type: string,
    fullPath: string,
    name: string,
    pid: string,
    shotType: string,
    sizes: S7UrlGeneratorObject['imageSizes'],
    colorCode: string
  ) {
    const keys = Object.keys(sizes);
    const urls = {};
    for (const index in keys) {
      const key = keys[index];
      const item = sizes[key];
      const preset = generatePresetsFromSizes(
        item.height,
        item.width,
        item.quality,
        item.resMode,
        item.opSum,
        item.bgc
      );
      urls[key] = generateS7Url(
        hostname,
        type,
        fullPath,
        name,
        pid,
        shotType,
        preset,
        colorCode
      );
    }
    return urls;
  }

  /**
   * Returns full product image path.
   *
   * @param pid string for product id
   * @param type string image or content
   * @param shotType string value to determine if it is PDP / PLP or etc image
   * @param preset string value to determine width/height/quality values
   * @param fullPath string value in case full image path is known
   * @param imageSizes object value for small/medium/large breakpoints
   * @param colorCode string value of product color code. It is used for TNF only for proper image path which are in format `....${masterID}_${colorCode} ...`
   * @returns image path string
   */
  function s7UrlGenerator({
    pid = '',
    type = 'image',
    shotType = '',
    preset = '',
    fullPath = '',
    imageSizes = null,
    colorCode = '',
  }: S7UrlGeneratorObject) {
    const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
    const { generateProductIdThumbnailsScene7 } = apiClientFactory(vueInstance);
    const hostname = vueInstance.$env.S7_IMAGES_HOSTNAME;
    const name = vueInstance.$env.S7_IMAGES_COMPANY;

    pid = generateProductIdThumbnailsScene7(pid);
    if (imageSizes && Object.keys(imageSizes).length) {
      return getS7ObjectUrls(
        hostname,
        type,
        fullPath,
        name,
        pid,
        shotType,
        imageSizes,
        colorCode
      );
    }
    return generateS7Url(
      hostname,
      type,
      fullPath,
      name,
      pid,
      shotType,
      preset,
      colorCode
    );
  }

  function shotTypeDTO(shotType = '', colorCode: string) {
    let adaptShotType = shotType
      .replace(/_hero/i, '-HERO')
      .replace(/_alt/i, '-ALT');
    if (colorCode) {
      adaptShotType = adaptShotType.replace(`_${colorCode}`, '');
    }
    return adaptShotType;
  }
  /**
   * Returns full product image path or Object with image path ex {"small": "http://...."}.
   *
   * @param colorCode string value of product color code. It is used for TNF only for proper image path which are in format `....${masterID}_${colorCode} ...`
   * @param colorName string value of product color name.
   * @param fileExt string value determine the file extension
   * @param fullPath string value in case full image path is known
   * @param imageSizes object value for small/medium/large breakpoints
   * @param pid string for product id
   * @param preset string value to determine width/height/quality values
   * @param productName string value of product name.
   * @param publicId value used to create id in the URL.
   * @param shotType string value to determine if it is PDP / PLP or etc image
   * @param type string image or content
   * @returns image path string
   */
  function mediaUrlGenerator({
    colorCode = '',
    colorName = '',
    fileExt,
    fullPath = '',
    imageSizes = null,
    pid = '',
    preset = '',
    productName,
    publicId,
    shotType = '',
    type = 'image',
  }: MediaUrlGeneratorObject) {
    const { enableCloudinaryCdn } = useFeatureFlagsStore();
    const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
    if (preset !== null) {
      preset ||= vueInstance.$env.IMAGES_PLP_PRESET;
    }
    if (shotType === null) {
      shotType = '';
    } else {
      shotType ||= vueInstance.$env.IMAGES_SHOTTYPE;
    }

    if (enableCloudinaryCdn) {
      if (shotType) {
        shotType = shotTypeDTO(shotType, colorCode);
      }
      return cloudinaryUrlGenerator({
        pid: pid ? `${pid.replace('_', '')}${colorCode}` : undefined,
        preset,
        publicId,
        type,
        shotType,
        fileExt,
        colorName,
        productName,
        imageSizes,
      });
    } else {
      return s7UrlGenerator({
        pid,
        type,
        shotType,
        preset,
        fullPath,
        imageSizes,
        colorCode: colorCode && shotType.includes(colorCode) ? '' : colorCode,
      });
    }
  }

  const presetDTOKeys = {
    hei: 'height',
    qlt: 'quality',
    wid: 'width',
  };

  function presetDTO(preset: string) {
    if (preset) {
      return preset.split('&').reduce((acc, cur) => {
        const [key, value] = cur.split('=');
        if (value && presetDTOKeys[key]) {
          acc[presetDTOKeys[key]] = value;
        }
        return acc;
      }, {});
    }
    return {};
  }

  function cloudinaryUrlGenerator({
    pid,
    publicId,
    preset = '',
    type = 'image',
    fileExt = 'png',
    shotType = '-hero',
    productName = 'Product Image',
    colorName,
    imageSizes = null,
  }: CloudinaryUrlGeneratorObject) {
    try {
      const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
      const domain = vueInstance.$config.CLOUDINARY_DOMAIN;
      const slugifiedProductName = productName ? slugify(productName) : '';
      const slugifiedColor =
        productName && colorName ? '-in-' + slugify(colorName) : '';
      let url = domain;

      if (type === 'image') {
        url += `/images/t_img`;
      } else if (type === 'video') {
        url += `/videos`;
      }
      const id = publicId || [pid, shotType].filter(Boolean).join('');

      const getUrl = (transformation: ImageTransformations) =>
        url +
        `/${generateTransformationValues({
          ...transformation,
          quality: undefined,
          sharpness: 70,
        })}/${id || ''}/${slugifiedProductName}${slugifiedColor}.${fileExt}`;

      if (imageSizes && Object.keys(imageSizes).length) {
        return Object.keys(imageSizes).reduce((acc, key) => {
          acc[key] = getUrl(
            typeof imageSizes[key] === 'string'
              ? presetDTO(imageSizes[key])
              : imageSizes[key]
          );

          return acc;
        }, {});
      }

      return getUrl(presetDTO(preset));
    } catch (err) {
      logger.error(
        `[@packages/theme/plugins/link-generators::cloudinaryUrlGenerator] Error while building url for <${pid}>`,
        err
      );
    }
    return '';
  }

  function mediaUrlFallback() {
    const { enableCloudinaryCdn } = useFeatureFlagsStore();
    const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
    return vueInstance.$config.PRODUCT_FALLBACK_EMPTY_IMAGE_URL[
      enableCloudinaryCdn ? 'cloudinary' : 's7'
    ];
  }

  inject('mediaUrlFallback', mediaUrlFallback);
  inject('mediaUrlGenerator', mediaUrlGenerator);
  inject('storeLocatorUrlGenerator', storeLocatorUrlGenerator);
};
export default linkGenerators;
