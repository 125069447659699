import { EventPropsHandler } from '.';
import { LoadPageDataEvent } from '../../types/gtm';
import useProduct from '../../useProduct';
import { useI18n } from '../../useI18n';
import { extractGtmPageName, santizeEmailAddresses } from './helpers';
import sessionStorage from '../../utils/sessionStorage';
import { useCmsRefStore } from '../../store/cmsRef';

export const loadPageDataHandler: EventPropsHandler<
  LoadPageDataEvent,
  {
    gtm_preview: string;
    pageTypeName?: string;
    storeId?: string;
    categoryID?: string;
    searchResults?: number;
    searchTerm?: string;
    searchTermAdj?: string;
    searchType?: string;
    htmlTitle?: string;
  }
> = (eventProps, vmInstance) => {
  const cmsRefStore = useCmsRefStore(vmInstance.$pinia);
  const { product } = useProduct(vmInstance, 'page-content');
  const { defaultCurrency } = useI18n(vmInstance);

  /* specifically for dataLayer loadPageData event for Customizer
  which does not return a pageTypeName value in Cms -> dataFetcher.
  All other dataLayer events come from the iFrame */
  const isCustomizer = vmInstance.$route.name?.search('customizer') === 0;
  let pageTypeName;
  if (isCustomizer) {
    pageTypeName = 'Customs';
  } else {
    pageTypeName = eventProps.overrideAttributes?.pageTypeName;
  }
  const breadcrumbsString =
    cmsRefStore.breadcrumbs
      .reduce(
        (crumbs, crumb) =>
          crumb.type === 'category'
            ? `${crumbs}${crumb.text.toLowerCase()}|`
            : crumbs,
        ''
      )
      .slice(0, -1) || undefined;
  return {
    event: eventProps.eventName,
    page: {
      'app-webview': sessionStorage.getItem('app-webview') === 'true',
      brand: vmInstance
        .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
        .toUpperCase(),
      breadcrumb: breadcrumbsString,
      countryCode: vmInstance
        .$getEnvValueByCurrentLocale<string>('COUNTRY')
        .toUpperCase(),
      countryLanguage: vmInstance.$i18n.locale.split('-').shift().toLowerCase(),
      currencyCode:
        product?.value?.variant?.price?.currency || defaultCurrency.value,
      pageName: extractGtmPageName(
        pageTypeName,
        cmsRefStore.breadcrumbs
          ?.map((breadcrumb) => breadcrumb.text)
          ?.join(' - '),
        product?.value?.id,
        eventProps.overrideAttributes?.searchType
      ),
      pageTitle:
        eventProps.overrideAttributes?.htmlTitle ||
        (cmsRefStore.meta?.htmlTitle as string),
      pageCategory: pageTypeName,
      searchResults: eventProps.overrideAttributes?.searchResults,
      searchResultsAdj: undefined, // Not implemented yet
      searchTerm: santizeEmailAddresses(
        eventProps.overrideAttributes?.searchTerm
      ),
      searchTermAdj: santizeEmailAddresses(
        eventProps.overrideAttributes?.searchTermAdj
      ),
      searchType: eventProps.overrideAttributes?.searchType,
      categoryID: eventProps.overrideAttributes?.categoryID,
    },
    _clear: true,
  };
};
