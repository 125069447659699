import { getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const plpFiltersDisplayToggle = (data: CMPlaceholder) => {
  return {
    component: 'plp/CategoryFiltersDisplayToggle',
    props: {
      translations: {
        showFilters: getTranslation(data, 'showFilters', '$Show Filters$'),
        showFiltersAria: getTranslation(
          data,
          'showFiltersAria',
          '$Display filters next to products$'
        ),
        hideFilters: getTranslation(data, 'hideFilters', '$Hide Filters$'),
        hideFiltersAria: getTranslation(
          data,
          'hideFiltersAria',
          '$Hide filters to display products only$'
        ),
      },
    },
  };
};
