import { PaymentMethodCode } from '@vf/api-client';
import { CartLineItem } from '@vf/api-client/src/types';
import { EventPropsHandler } from '.';
import { ActionFieldObject, CheckoutCompletionEvent } from '../../types/gtm';
import useGtm from '../../useGtm';
import { useI18n } from '../../useI18n';
import {
  cartLineItemToProductObject,
  getOrderPromoActionFields,
  getShippingPromoAmount,
} from './helpers';
import { useCheckoutStore } from '../../store/checkoutStore';

export const checkoutCompletionHandler: EventPropsHandler<
  CheckoutCompletionEvent,
  { products: CartLineItem[] }
> = (eventProps, vmInstance) => {
  const { defaultCurrency } = useI18n(vmInstance);
  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();

  const { order: orderData } = useCheckoutStore();
  const { getCartProductTrace, clearGtmAttributesStorage } = useGtm(vmInstance);

  const paymentMethods = orderData.paymentMethod.filter(
    ({ code }) =>
      code !== PaymentMethodCode.GIFT_CARD &&
      code !== PaymentMethodCode.REWARD_CARD
  );
  const paymentType =
    paymentMethods.length > 0
      ? paymentMethods.map(({ code }) => code).join(', ')
      : orderData.paymentMethod[0]?.code;

  const shippingPromotions = orderData.shipments[0]?.shippingPromotions;

  const actionField: ActionFieldObject = {
    affiliation: vmInstance.$getEnvValueByCurrentLocale<string>('API_SITE_ID'),
    id: orderData.orderNumber,
    revenue: orderData.totals?.totalWithoutTax || 0,
    rewardsAmount:
      orderData.paymentMethod.find(({ code }) =>
        [
          PaymentMethodCode.REWARD_CARD,
          PaymentMethodCode.LOYALTY_POINTS,
          PaymentMethodCode.REWARD_CODE,
        ].includes(code as PaymentMethodCode)
      )?.additionalData?.amount || 0,
    shipping: orderData.totals?.shipping || 0,
    tax: orderData.totals?.tax || 0,
    total: orderData.totals?.total || 0,
    paymentType,
    ...getOrderPromoActionFields(orderData.orderPromotions),
    shippingMethod: orderData.shipments[0]?.shippingMethod?.label,
    shippingPromoCode: shippingPromotions?.length
      ? shippingPromotions?.find((promo) => !!promo.couponCode)?.couponCode ||
        'AUTO'
      : undefined,
    shippingPromoType: shippingPromotions?.find((promo) => !!promo.couponCode)
      ? 'CODE'
      : 'AUTO',
    shippingPromoAmount: getShippingPromoAmount(shippingPromotions || []),
    shippingPromoCampaignId: shippingPromotions?.find(
      (promo) => !!promo.campaignId
    )?.campaignId,
    giftCard: orderData.paymentMethod.some(({ code }) => code === 'GIFT_CARD'),
    giftCardAmount: orderData.paymentMethod.reduce(
      (acc, method) =>
        method.code === 'GIFT_CARD' ? acc + method.additionalData.amount : acc,
      0
    ),
    coom_01: orderData.billingAddress.email,
    firstTimePurchase: orderData.isFirstOrder,
  };
  const prodObject: CheckoutCompletionEvent = {
    event: 'checkoutCompletion',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Order Confirmation',
    eventLabel: undefined,
    nonInteractive: true,
    ecommerce: {
      currencyCode: orderData.totals.currency || defaultCurrency.value,
      purchase: {
        actionField,
        products: orderData.items.map((prod) => ({
          ...cartLineItemToProductObject(
            prod,
            getCartProductTrace(prod.productId)
          ),
          brand,
          quantity: prod.qty,
        })),
      },
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
  clearGtmAttributesStorage();
  return prodObject;
};
