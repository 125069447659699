import { PhotorankApi } from '../../api-types';

/**
 * photorankapi return always status 200 but in metadata put the real status code
 */
export const validateResponse = (response: PhotorankApi<any>) => {
  if (response.metadata.code !== 200 && response.metadata.code !== 201) {
    throw new Error(response as any);
  }
};
