export default async function ({ app }, inject) {
  let theme;
  switch (app.$env.THEME) {
    case 'vans':
      theme = (
        await import(
          `../../ui/theme/themes/vans` /* webpackChunkName: "themeConfigVans" */
        )
      ).default;
      break;
    case 'thenorthface':
      theme = (
        await import(
          `../../ui/theme/themes/thenorthface` /* webpackChunkName: "themeConfigTNF" */
        )
      ).default;
      break;
    case 'wl':
      theme = (
        await import(
          `../../ui/theme/themes/wl` /* webpackChunkName: "themeConfigWL" */
        )
      ).default;
      break;
  }

  inject('themeConfig', theme.config || {});
}
