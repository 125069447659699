import {
  Context,
  MonetateCartLine,
  MonetateEvent,
  MonetateEventType,
  MonetatePurchaseLine,
} from '@vf/api-contract';
import { ComponentInstance } from '@vf/composables';
import { v4 as uuidv4 } from 'uuid';
import { useAuthentication } from '../../useAuthentication';
import { useAccount } from '../../useAccount';
import useCategory from '../../useCategory';
import { PageTypeName } from '../../useCms/types';
import { useI18n } from '../../useI18n';
import useProduct from '../../useProduct';
import { useCart } from '../../useCart';
import useSearch from '../../useSearch';
import { useCheckoutStore } from '../../store/checkoutStore';
import { useCmsRefStore } from '../../store/cmsRef';
import { storeToRefs } from 'pinia';

const customVarsCookiesNames = [
  'mensnav',
  'shopnav',
  'womensnav',
  'kidsnav',
  'customsnav',
  'skatenav',
  'surfnav',
  'bmxnav',
  'hovans',
  'vaultnav',
  'newnav',
  'findstoreutility',
];

// Find alternate sku for product if selected variant is out of stock
// If no alternate sku is found, use the original product sku
const getAlternateSku = (product) => {
  const productVariantsWithQuantity = product.variants.filter(
    (variant) => variant.stock.inStock
  );

  if (!productVariantsWithQuantity.length) return product.sku;

  return (
    productVariantsWithQuantity.find(
      (variant) => variant.attributes.color === product.variant.attributes.color
    )?.id || productVariantsWithQuantity[0].id
  );
};

export const getMonetateEventsForDecisionRequest = (
  vmInstance: ComponentInstance,
  monetateEnvironment: 'p' | 'd',
  contextKey?: string,
  customVariables?: Record<string, string | boolean>
): MonetateEvent[] => {
  const cmsRefStore = useCmsRefStore();
  const { product } = useProduct(vmInstance, contextKey);
  const { cartItems, hasItems, totalCart, subTotal } = useCart(vmInstance);
  const { getIetfLocale } = useI18n(vmInstance);
  const { getConsumerId } = useAuthentication(vmInstance);
  const { interestsList } = useAccount(vmInstance);
  const { products } = useCategory(vmInstance, contextKey);
  const coordinates = vmInstance.$cookies.get('Edgescape-Lat-Long');

  const { pageTypeName, breadcrumbs } = storeToRefs(cmsRefStore);

  const categories = (breadcrumbs.value || []).map(
    (breadcrumb) => breadcrumb.text
  );

  const getPageType = (
    pageTypeName: PageTypeName,
    contextKey: string
  ): PageTypeName => {
    return pageTypeName === PageTypeName.PDP && contextKey === Context.QuickShop
      ? PageTypeName.PDP_QUICKSHOP
      : pageTypeName;
  };

  const events: MonetateEvent[] = [
    {
      eventType: MonetateEventType.PageView,
      url: window?.location?.href,
      pageType: getPageType(pageTypeName.value, contextKey) || '',
      breadcrumbs: categories,
      categories,
    },
    {
      requestId: uuidv4(),
      eventType: MonetateEventType.DecisionRequest,
      domain: window?.location?.hostname,
      includeReporting: true,
    },
    {
      eventType: MonetateEventType.ScreenSize,
      height: window.screen?.height,
      width: window.screen?.width,
    },
    {
      eventType: MonetateEventType.Language,
      language: getIetfLocale(),
    },
    {
      eventType: MonetateEventType.UserAgent,
      userAgent: window.navigator.userAgent,
    },
  ];

  if (pageTypeName.value === PageTypeName.ORDER_CONFIRMATION) {
    const { order } = useCheckoutStore();
    events.push({
      eventType: MonetateEventType.Purchase,
      account: getConsumerId.value,
      domain: window.location.host,
      instance: monetateEnvironment,
      purchaseId: order.orderNumber,
      purchaseLines: order.items.map(
        (item): MonetatePurchaseLine => ({
          currency: item.price.currency,
          pid: item[vmInstance.$themeConfig.monetate.masterIdType],
          quantity: item.qty,
          sku: item.sku,
          value: item.price.rowTotal.toFixed(2),
        })
      ),
    });
  }

  const interestVars = interestsList.value.length
    ? interestsList.value.map((interest) => ({
        variable: 'userInterest',
        value: interest,
      }))
    : [];

  const vars = Object.entries({
    ...(hasItems.value
      ? {
          total: totalCart.value,
          'sub-total': subTotal.value,
        }
      : {}),
    ...(customVariables ?? {}),
  });

  const cookieVars = customVarsCookiesNames.reduce((acc, cookieName) => {
    if (vmInstance.$cookies.get(cookieName)) {
      acc.push({
        variable: cookieName,
        value: true,
      });
    }
    return acc;
  }, []);

  const mappedVars = vars.map(([variable, value]) => ({ variable, value }));
  events.push({
    eventType: MonetateEventType.CustomVariables,
    customVariables: [...mappedVars, ...cookieVars, ...interestVars],
  });

  if (pageTypeName.value === PageTypeName.PDP && product.value) {
    const sku =
      product.value.variant && !product.value.variant.stock.inStock
        ? getAlternateSku(product.value)
        : product.value.sku;

    events.push({
      eventType: MonetateEventType.ProductDetailView,
      products: [
        {
          productId: product.value.id,
          sku: sku,
        },
      ],
    });
  }
  if (pageTypeName.value === PageTypeName.PLP) {
    const productIds = products.value.map((product) => product.id);
    const productSkus: string[] = products.value.map(
      vmInstance.$themeConfig.monetate.getPlpEventsProductSkus
    );
    events.push({
      eventType: MonetateEventType.ThumbnailView,
      products: productIds,
    });
    events.push({
      eventType: MonetateEventType.ThumbnailSkuView,
      productSkus,
    });
  }
  if (document?.referrer) {
    events.push({
      eventType: MonetateEventType.Referrer,
      referrer: document.referrer,
    });
  }
  if (pageTypeName.value === PageTypeName.SEARCH) {
    const { queryString, products } = useSearch(vmInstance);
    events.push({
      eventType: MonetateEventType.SearchView,
      searchTerm: queryString.value,
      searchTermType: products.value.find(
        (prod) => prod.id.toLowerCase() === queryString.value.toLowerCase()
      )
        ? 'product_id'
        : 'text',
      searchType: 'site',
    });
  }
  if (coordinates) {
    const [latitude, longitude] = coordinates.split(',');
    events.push({
      eventType: MonetateEventType.Coordinates,
      latitude,
      longitude,
    });
  }
  if (hasItems.value) {
    events.push({
      eventType: MonetateEventType.Cart,
      cartLines: cartItems.value.map(
        (item): MonetateCartLine => ({
          currency: item.price.currency,
          pid: item[vmInstance.$themeConfig.monetate.masterIdType],
          quantity: item.qty,
          sku: item.slug,
          value: item.price.rowTotal.toFixed(2),
        })
      ),
    });
  }
  return events;
};
