export function scrollIntoView(
  selector: string | Element,
  behavior: ScrollBehavior = 'smooth',
  block: ScrollLogicalPosition = 'start'
): void {
  if (!selector) return;
  if (document) {
    const target =
      typeof selector === 'string'
        ? document.querySelector(selector)
        : selector;
    if (target) {
      target.scrollIntoView({ behavior, block });
    } else {
      console.debug(
        `Could not find element ${selector} in the DOM to perform scroll.`
      );
    }
  }
}
