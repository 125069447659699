export const getCardDataFromJWT = (
  token: string
): {
  maskedNumber: string;
  expirationMonth: string;
  expirationYear: string;
} => {
  try {
    const decodedJWT = JSON.parse(atob(token.split('.')[1]));
    const { number, expirationMonth, expirationYear } = decodedJWT.data;
    return {
      maskedNumber: number,
      expirationMonth,
      expirationYear,
    };
  } catch (err) {
    return {
      maskedNumber: '',
      expirationMonth: '',
      expirationYear: '',
    };
  }
};
