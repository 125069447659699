





































































import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
const defaultInterval = 5000;
export default defineComponent({
  name: 'VfSwiper',
  props: {
    scrollProps: {
      // See Ui.Scroll.vue props
      type: Object,
      default: () => ({}),
    },
    classControls: [String, Object],
    iconName: {
      type: String,
      default: 'chevron_right',
    },
    iconSize: {
      type: [String, Number],
      default: '24',
    },
    ariaLabelNext: { type: String, default: 'next' },
    ariaLabelPrev: { type: String, default: 'prev' },
    dir: String as PropType<'vertical'>,
    pagination: Boolean,
    autoplay: [Boolean, Number],
    showArrows: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scroller: null,
      autoplayTimerID: null,
      shouldEmitChange: false,
      numberOfSlides: 0,
    };
  },
  computed: {
    scrollCompleteProps() {
      return { ...this.scrollProps, dir: this.dir };
    },
    classPrev() {
      return this.classControls?.prev || this.classControls || null;
    },
    classNext() {
      return this.classControls?.next || this.classControls || null;
    },
    interval() {
      return this.autoplay && typeof this.autoplay !== 'boolean'
        ? this.autoplay
        : defaultInterval;
    },
  },
  // Needs to be in beforeMount because of this https://github.com/vuejs/vue-loader/issues/1685
  beforeMount() {
    this.updateNumberOfSlides();
  },
  updated() {
    this.updateNumberOfSlides();
  },
  destroyed() {
    this.pauseAutoplay();
  },
  methods: {
    pauseAutoplay() {
      clearTimeout(this.autoplayTimerID);
    },
    playAutoplay() {
      if (!+this.autoplay || this.numberOfSlides <= 1) return;
      this.autoplayTimerID = setTimeout(() => {
        this.runAutoplay();
      }, this.interval);
    },
    runAutoplay() {
      this.scroller.next();
      this.shouldEmitChange = true;
      this.autoplayTimerID = setTimeout(() => {
        this.runAutoplay();
      }, this.interval);
    },
    notifyAutoplaySlideChange(data) {
      if (!this.shouldEmitChange) return;
      this.shouldEmitChange = false;
      this.$emit('autoplay-change', data);
    },
    notifySlideChange(data) {
      this.$emit('change', data);
    },
    init(scrollerInstance) {
      this.scroller = scrollerInstance;
      this.$emit('init', this);
      this.playAutoplay();
    },
    updateNumberOfSlides() {
      this.numberOfSlides =
        this.$slots.default?.filter((child) => child.tag).length ?? 0;
    },
  },
});
