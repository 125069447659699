import { ComposableContext } from '../types';
import { extractRichText, getTranslation } from './utils';
import {
  CMPlaceholder,
  CmsSite,
  OrderSummarySidebarTranslations,
} from '@vf/api-contract';

export const orderSidebar = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const translations: OrderSummarySidebarTranslations = {
    itemSubtotalLabel: getTranslation(
      data,
      'itemSubtotalLabel',
      '$ITEM SUBTOTAL ({count}):$'
    ),
    promotionLabel: getTranslation(data, 'promotionLabel', '$Promotion:$'),
    orderSummaryLabel: getTranslation(
      data,
      'orderSummaryLabel',
      '$Order Summary$'
    ),
    shippingLabel: getTranslation(data, 'shippingLabel', '$Shipping:$'),
    taxLabel: getTranslation(data, 'taxLabel', '$Tax:$'),
    taxTooltip: getTranslation(
      data,
      'taxTooltip',
      '$Im text in tax tooltip property$'
    ),
    shippingTooltip: getTranslation(
      data,
      'shippingTooltip',
      '$Im text in shipping tooltip property$'
    ),
    orderTotalLabel: getTranslation(data, 'orderTotalLabel', '$Order Total:$'),
    printButtonLabel: getTranslation(
      data,
      'printButtonLabel',
      '$Print receipt$'
    ),
    continueShoppingLabel: getTranslation(
      data,
      'continueShoppingLabel',
      '$Continue Shopping$'
    ),
    giftCardLabel: getTranslation(data, 'giftCardLabel', '$Gift Card$'),
    rewardCardLabel: getTranslation(data, 'rewardCardLabel', '$Reward Card$'),
    free: getTranslation(data, 'free', '$Free$'),
    pickup: getTranslation(data, 'pickup', `$Pickup$`),
    pickupTooltip: extractRichText(
      data,
      'pickupTooltip',
      getTranslation(
        data,
        'pickupTooltip',
        `$Express orders placed before 12 pm (EST) Monday - Friday to be processed and shipped the same day. Please allow an additional 24-48 hours of processing time for orders placed after 12pm EST.$`
      ),
      cmsBaseUri,
      context,
      siteConfiguration
    ),
    stsTooltip: getTranslation(
      data,
      'stsTooltip',
      `$Pickup processing is free.$`
    ),
    close: getTranslation(data, 'close', '$Close$'),
    athleteDiscount: getTranslation(
      data,
      'athleteDiscount',
      '$Athlete Discount$'
    ),
    springReward: getTranslation(
      data,
      'springReward',
      '$Spring {{year}} Reward$'
    ),
    summerReward: getTranslation(
      data,
      'summerReward',
      '$Summer {{year}} Reward$'
    ),
    winterReward: getTranslation(
      data,
      'winterReward',
      '$Winter {{year}} Reward$'
    ),
    legacyReward: getTranslation(data, 'legacyReward', '$Legacy Reward$'),
    appliedVouchersLabel: getTranslation(
      data,
      'appliedVouchersLabel',
      '$VANS FAMILY REWARDS$'
    ),
  };
  return {
    component: 'confirmation/OrderSidebar',
    props: {
      translations,
    },
  };
};
