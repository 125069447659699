import { CMCollection, CmsSite } from '@vf/api-contract';
import { ROUTES } from '../../utils/routes';
import { useI18n } from '../../useI18n';
import { ComposableContext } from '../types';
import {
  getTitle,
  extractProperty,
  generateLinkFromTeaserTargets,
  getImageObject,
} from './utils';

export const checkoutHeader = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const logo = getLogoUrl(data, siteConfiguration, cmsBaseUri, context);
  return {
    component: 'layout/CheckoutHeader',
    props: {
      title: '',
      // TODO: Adapt the linkTag prop to the response and possible type approach
      linkTag: 'a',
      logo: logo,
      steps: getSteps(data, context, siteConfiguration),
    },
  };
};

const getLogoUrl = (
  data: CMCollection,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  context
) => {
  const { localePath } = useI18n(context.instance);
  const logo = data.teasableItems.find((item) => item.viewtype === 'site-logo');
  const pictureObject = extractProperty(logo, 'pictures[0]', null);
  if (!pictureObject) {
    console.error('Checkout header logo error: no site logo component found');
    return {};
  }

  return {
    id: logo.id,
    link: localePath(ROUTES.HOME()),
    url: getImageObject(pictureObject, siteConfiguration, null, cmsBaseUri),
    ariaLabel: logo.name,
  };
};

const getSteps = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const steps = data.teasableItems.filter(
    (item) => item.viewtype === 'checkout-header-step'
  );

  if (!steps) {
    return [];
  }

  return steps.map((step, index) => {
    return {
      id: index + 1,
      link: generateLinkFromTeaserTargets(
        step.teaserTargets,
        context,
        siteConfiguration
      ),
      label: getTitle(step),
    };
  });
};
