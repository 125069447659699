import { CMImageBlockWithOverlay, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getTeaserTargets,
  extractComponentStyleDefinitions,
} from './utils';
import { ComposableContext } from '../types';

export const easyList = (
  data: CMImageBlockWithOverlay,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { ctas } = getTeaserTargets(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );

  return {
    component: 'shared/ContentList',
    props: {
      title: extractComponentStyleDefinitions(data, 'teaserTitle'),
      linkColorTextHex: extractProperty(
        data,
        'componentStylesDefinitions.textColor.data.color'
      ),
      bottomMarginStyle: extractProperty(data, 'componentStyles.bottomMargin'),
      columnCount:
        parseInt(extractProperty(data, 'componentStyles.columnCount'), 10) || 1,
      linkUnderline: extractProperty(data, 'componentStyles.linkUnderline'),
      sortOptions: extractProperty(data, 'componentStyles.sortOptions'),
      rowBackgroundColorHex: extractProperty(
        data,
        'componentStylesDefinitions.rowBackgroundColor.data.color'
      ),
      ctas,
    },
  };
};
