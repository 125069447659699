
import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ThemeButton',
  functional: true,
  // TODO: GLOBAL15-61059 remove after redesign core
  inject: ['isCoreRedesignEnabled'],
  props: {
    color: String as PropType<
      'primary' | 'secondary' | 'loyalty' | 'reverse' | 'tertiary'
    >,
    variant: String as PropType<'border'>,
    size: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'full'>,
    context: String as PropType<'dark'>,
    loading: Boolean,
    animation: Boolean,
    to: [String, Object],
    tag: String,
  },
  render(h, { props, data, listeners, children, parent, injections }) {
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = injections.isCoreRedesignEnabled?.value;

    return h(
      `${parent.$config.theme}-theme-button`,
      {
        ...data,
        class: [
          ...(data.staticClass || data.class
            ? [data.staticClass, data.class]
            : []),
          'vf-theme-button',
          `vf-theme-button-${parent.$config.theme}`,
          isCoreRedesignEnabled &&
            `vf-theme-button-${parent.$config.theme}--redesign-core`, // temporary feature flag for redesign
          props.color && `vf-color vf-color-${props.color}`,
          props.size && `vf-size vf-size-${props.size}`,
          props.variant && `vf-variant-${props.variant}`,
          props.context && `vf-context-${props.context}`,
          { 'vf-loading': props.loading },
          { 'vf-ripple': isCoreRedesignEnabled },
        ],
        props,
        on: listeners,
        nativeOn: data.nativeOn,
      },
      children
    );
  },
});
