








































import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
export default {
  name: 'VfPicture',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    // Empty src is allowed for custom lazy load scenarios based on data-src
    src: {
      type: [String, Object],
      default: null,
    },
    alt: {
      type: String,
      default: 'pic',
    },
    title: {
      type: String,
      default: null,
    },
    seo: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        date: '',
      }),
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number, Object],
      required: true,
    },
    height: {
      type: [String, Number, Object],
      required: true,
    },
    // Container for passing additional props to internal image, such as class
    propsImg: {
      type: Object,
      default: null,
    },
    // Allows subscribing for additional events to internal image, such as click
    onImg: {
      type: Object,
      default: null,
    },
    screenLarge: Number,
    screenMedium: Number,
  },
  computed: {
    // Checks if any SEO data was provided to a component
    isSeo() {
      return !!(this.seo.title || this.seo.description || this.seo.date);
    },
    finalSrc() {
      const notObj = !(this.src instanceof Object);
      return {
        large: this.src?.large || null,
        medium: this.src?.medium || null,
        small: notObj
          ? this.src
          : this.src.small || this.src.medium || this.src.large || '',
      };
    },
    finalWidth() {
      const notObj = !(this.width instanceof Object);
      return {
        large: notObj ? this.width : this.width.large ?? 0,
        medium: notObj ? this.width : this.width.medium ?? 0,
        small: notObj ? this.width : this.width.small ?? 0,
      };
    },
    finalHeight() {
      const notObj = !(this.height instanceof Object);
      return {
        large: notObj ? this.height : this.height.large ?? 0,
        medium: notObj ? this.height : this.height.medium ?? 0,
        small: notObj ? this.height : this.height.small ?? 0,
      };
    },
    altText() {
      return this.alt || this.title || this.seo.title || '';
    },
  },
};
