
import {
  useAsync,
  onMounted,
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import { useCms, useHeaderAndFooter, useMonetate } from '@vf/composables';
import HomepagePlaceholder from '@vf/ui/components/loading/Organism.HomepagePlaceholder.vue';
import {
  finalContentRenderer,
  PRESERVE_PREVIEW,
  addPreservePreviewParam,
  loadPreviewBlueprintPlugin,
} from './cmsUtils';
import { PreviewType } from '@vf/composables/src/useCms/types';
import useRootInstance from '@/shared/useRootInstance';
import { getRenderer } from '@/helpers';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { storeToRefs } from 'pinia';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { Context } from '@vf/api-contract';

export default defineComponent({
  name: 'CmsPreview',
  props: {
    previewType: {
      type: String as PropType<PreviewType>,
      validator: (value: string) => {
        return ['page', 'fragment'].indexOf(value) !== -1;
      },
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      default: null,
    },
    commerceId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const cms = useCms(root, Context.PageContent);
    const renderer = getRenderer();
    const { getExperienceDecision } = useMonetate(root, Context.PageContent);

    const { pageTypeName, cmsSiteConfiguration } = storeToRefs(cmsRefStore);

    onMounted(() => {
      const monetateParam = root.$route.query?.p13n_testcontext;
      window.sessionStorage.setItem(PRESERVE_PREVIEW, 'true');
      addPreservePreviewParam(monetateParam);
      getExperienceDecision(true);
      cms.afterSSR(
        { instance: root, contextKey: Context.PageContent },
        pageTypeName.value
      );
      // Metadata for responsive device slider preview
      const sliderPreviewMetadata =
        cmsSiteConfiguration.value?.previewSliderMetadata;
      document.body.setAttribute(
        'data-cm-metadata',
        JSON.stringify([sliderPreviewMetadata])
      );

      // Placement Highlighting for preview
      loadPreviewBlueprintPlugin(root, renderer);
    });

    useAsync(async () => {
      await cms.setup({ forcePreview: true });

      const {
        fetchHeaderAndFooter,
        getHeaderAndFooterData,
        sanitizeHeaderAndFooterData,
      } = useHeaderAndFooter(
        root,
        cms.verifySetup,
        cms.apiClientRef.value,
        Context.PageContent
      );
      await fetchHeaderAndFooter();

      // get CMS preview data
      await cms.fetchPreview(
        props.previewType,
        props.contentId,
        props.contentType,
        props.commerceId,
        getHeaderAndFooterData,
        sanitizeHeaderAndFooterData
      );
    });

    return {
      cms,
    };
  },

  render(createElement) {
    // Disable component caching when rendering CMS preview pages
    (getCacheKeyFromProps as any)._disabled = true;

    if (!this.cms.page.value && !this.cms.errors.value.length) {
      return createElement(HomepagePlaceholder);
    }

    const menuContentTypes = [
      'VfMenuContainer',
      'VfMenuItemLevel1',
      'VfMenuItemLevel2',
      'VfMenuItemLevel3',
    ];
    const elementsToRender = [this.cms.page.value];
    if (this.previewType === 'page') {
      this.cms.headerVisible.value &&
        elementsToRender.unshift(this.cms.header.value);
      this.cms.footerVisible.value &&
        elementsToRender.push(this.cms.footer.value);
    } else if (menuContentTypes.includes(this.contentType)) {
      this.cms.headerVisible.value &&
        elementsToRender.unshift(this.cms.header.value);
    }

    return finalContentRenderer(
      createElement,
      this.cms.pageLayoutName,
      Context.PageContent,
      elementsToRender,
      this.cms.errors.value,
      this.$root,
      [],
      this.cms.headerConfig
    );
  },
});
