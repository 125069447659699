


















































import type { PropType } from 'vue';
import { computed, defineComponent, ref, inject } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfProperty',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<{
        close?: string;
        edit?: string;
      }>,
    },
    name: String,
    value: [String, Number],
    info: [String, Number],
    description: [String, Number],
    tooltip: String,
    isTooltipRichText: Boolean,
    editable: Boolean,
    editLink: String,
  },
  setup(props) {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const id = computed(() => props.name?.replace(/\s/g, '-').toLowerCase());

    return {
      showTooltip: ref(false),
      text: computed(() => props.translations ?? {}),
      isCoreRedesignEnabled,
      id,
    };
  },
});
