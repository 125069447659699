import { inject, provide } from '@vue/composition-api';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import createThrowContextErrorFunction from './utilities/createThrowContextErrorFunction';

type Callbacks = {
  onAllDone: (cb: () => void) => number;
};

type UseAsyncScrollToLinkReturn = {
  scrollTo: (selector: string) => unknown;
  provideCallbacks: (callbacks: Callbacks) => void;
};

const CallbacksKey = Symbol('RequestTrackerCallbacks');

function provideCallbacks(callbacks: Callbacks): void {
  provide(CallbacksKey, callbacks);
}

function useAsyncScrollTo(): UseAsyncScrollToLinkReturn {
  const { onAllDone } = inject(CallbacksKey, {
    onAllDone: createThrowContextErrorFunction(CallbacksKey, 'onAllDone'),
  });

  const scrollToComponent = (selector, onAllDoneCb?) => {
    const targetElement = document.querySelector(selector) as HTMLElement;

    if (targetElement) {
      const top = targetElement.offsetTop;
      scrollTo({ top });
    } else if (onAllDoneCb) {
      onAllDoneCb(() => scrollToComponent(selector));
    }
  };

  return {
    scrollTo: (selector) => scrollToComponent(selector, onAllDone),
    provideCallbacks,
  };
}

export default useAsyncScrollTo;
