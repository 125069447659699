import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const checkoutShippingInfoNotification = (data: CMPlaceholder) => {
  return {
    component: 'checkout/CheckoutShippingInfoNotification',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Address Check$'),
        subtitle_line1: getTranslation(
          data,
          'subtitle_line1',
          '$Hi! We suggest this slight change to make your shipment more efficient.$'
        ),
        subtitle_line2: getTranslation(
          data,
          'subtitle_line2',
          '$Should we proceed?$'
        ),
        address_updated: getTranslation(
          data,
          'address_updated',
          '$We checked your shipping address and updated it to ensure prompt delivery.$'
        ),
        click_to_see_changes: getTranslation(
          data,
          'click_to_see_changes',
          '$Click here to see changes.$'
        ),
        use_suggested: getTranslation(data, 'use_suggested', '$Use Suggested$'),
        use_original: getTranslation(data, 'use_original', '$Use original$'),
      },
    },
  };
};
