import { addressFormTranslations, getDynamicField } from './utils';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTranslation } from './utils/extractProperty';

export const checkoutShippingInfo = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  return {
    component: 'checkout/CheckoutShippingInfo',
    props: {
      addressFormTranslations: addressFormTranslations(
        data,
        context,
        siteConfiguration
      ),
      translations: {
        addressVerification: getTranslation(
          data,
          'addressVerification',
          '$Your address couldn\'t be verified. Please review your address, and make the necessary changes. If you believe your address is correct, please select "I confirm this address is correct" to continue.$'
        ),
        addressConfirmation: getTranslation(
          data,
          'addressConfirmation',
          '$I confirm this address is correct.$'
        ),
        sameAsShipping: getTranslation(
          data,
          'sameAsShipping',
          '$Same as shipping$'
        ),
        editThisAddress: getTranslation(
          data,
          'editThisAddress',
          '$Edit this address$'
        ),
        paymentInformation: getTranslation(
          data,
          'paymentInformation',
          '$Payment information$'
        ),
        marketingOptIn: getTranslation(
          data,
          'marketingOptIn',
          '$Please send me offers and updates. I understand that I can unsubscribe at any time.$'
        ),
        marketingOptInMobile: getTranslation(
          data,
          'marketingOptInMobile',
          '$Please send me offers and updates. I understand that I can unsubscribe at any time.$'
        ),
        marketingOptInTooltip: getTranslation(
          data,
          'marketingOptInTooltip',
          '$Receive special offers, promotions and new product updates.$'
        ),
        selectSavedAddress: getTranslation(
          data,
          'selectSavedAddress',
          '$Select a saved shipping address, or$'
        ),
        enterNewAddress: getTranslation(
          data,
          'enterNewAddress',
          '$Enter a new address.$'
        ),
        addNewAddress: getTranslation(
          data,
          'addNewAddress',
          '$Add new address$'
        ),
        formHeading: getTranslation(
          data,
          'formHeading',
          '$Shipping Information$'
        ),
        addressSelect: getTranslation(
          data,
          'addressSelect',
          '$Select shipping address$'
        ),
        addressSelectionAdditionalOption: getTranslation(
          data,
          'addressSelectionAdditionalOption',
          {
            openAddressModal: '$Add new address$',
          }
        ),
        youAreShippingTheseItems: getTranslation(
          data,
          'youAreShippingTheseItems',
          '$You are shipping these items$'
        ),
        phoneNumberMissingNotification: getTranslation(
          data,
          'phoneNumberMissingNotification',
          '' // empty string is intentonal here, the default fallback is a phrase returned from the API
        ),
      },
      newsletterSignUpAutoCheck: getDynamicField(
        data,
        'newsletterSignUpAutoCheck',
        false
      ),
    },
  };
};
