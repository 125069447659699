import { ref } from '@vue/composition-api';

// Delay value after which the product removal notification is hidden (TNF only)
const HIDE_ERROR_MSG_DELAY = 5000;

export const useFlashErrors = () => {
  const rowNotificationInsteadProduct = ref([]);

  const setItemRowNotification = ({ id, message }) => {
    rowNotificationInsteadProduct.value.push({ id, message });
    clearProductRowNotification(id);
  };

  const findRowNotification = (itemId) =>
    rowNotificationInsteadProduct.value.find(({ id }) => id === itemId)
      ?.message;

  const clearProductRowNotification = (itemId) => {
    setTimeout(() => {
      rowNotificationInsteadProduct.value = rowNotificationInsteadProduct.value.filter(
        ({ id }) => id !== itemId
      );
    }, HIDE_ERROR_MSG_DELAY);
  };

  return {
    rowNotificationInsteadProduct,
    setItemRowNotification,
    findRowNotification,
  };
};
