import { CartLineItem } from '@vf/api-client/src/types';
import { EventPropsHandler } from '.';
import { UpdateCartEvent } from '../../types/gtm';
import { cartLineItemToProductObject } from './helpers';
import useGtm from '../../useGtm';
import { useCart } from '../../useCart';

export const updateCartHandler: EventPropsHandler<UpdateCartEvent> = (
  _,
  vmInstance
) => {
  const { getCartProductTrace } = useGtm(vmInstance);
  const { cartId, cartItems } = useCart(vmInstance);

  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();

  const cartLineItemMap = (product: CartLineItem) => {
    return {
      ...cartLineItemToProductObject(
        product,
        getCartProductTrace(product.productId)
      ),
      brand,
      quantity: product.qty,
    };
  };

  return {
    event: 'cartUpdate',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Cart Update',
    eventLabel: undefined,
    nonInteractive: true,
    cart: {
      cartId: cartId.value,
      products: cartItems.value.map(cartLineItemMap),
    },
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
