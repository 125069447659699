import { ArticleGridConfiguration } from '@vf/api-contract';
import { getComponentConfigByName } from './extractProperty';

export const defaultArticleGridConf: ArticleGridConfiguration = {
  'articles-per-row-small-screen': 3,
  'articles-per-row-medium-screen': 4,
  'articles-per-row-large-screen': 6,
  'articles-per-page-small-screen': 4,
  'articles-per-page-medium-screen': 16,
  'articles-per-page-large-screen': 25,
  'tile-show-title': true,
  'tile-show-body-text': true,
  'tile-show-read-more-cta': true,
  'tile-show-authors': true,
  'tile-show-thumbnail': true,
  'tile-show-tags': true,
  'tile-show-publication-date': true,
  'published-before-timestamp': 0,
  'published-after-timestamp': 0,
  'show-tag-filters': true,
  'search-order': 'publicationDate',
};

export const getGridConfigValues = (
  data: any,
  name = '',
  defaultConfig: ArticleGridConfiguration = defaultArticleGridConf
): ArticleGridConfiguration => {
  const values = getComponentConfigByName(data, name, null, false);
  if (!values) {
    console.error('Article grid component error: no config found.');
    return defaultConfig;
  }

  return {
    ...defaultConfig,
    ...values,
  };
};
