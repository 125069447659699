









































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import {
  useAccount,
  useGtm,
  useNotification,
  useRequestTracker,
  useUtilities,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export interface SubscribeProp {
  translations: {
    linkOne?: string;
    linkTwo?: string;
    legalTermText?: string;
  };
}

export default defineComponent({
  name: 'FooterSubscribe',
  props: {
    /** Array of subscribe section data */
    subscribe: {
      type: Object as PropType<SubscribeProp>,
      required: true,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const {
      updateProfile,
      getBasicInformation,
      setBasicInformation,
      basicInformation,
    } = useAccount(root);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { getCountry } = useUtilities(root);
    const { dispatchEvent } = useGtm(root);
    const {
      changeNewsletterNotification,
      newsletterNotification,
    } = useNotification(root);
    const { onBaseDone } = useRequestTracker(root);

    const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);

    const showInputLabelAbove =
      root.$themeConfig.footerSubscribe?.showInputLabelAbove ?? false;

    const canSubscribeFormImpressionBeDispatched = ref(false);

    const country = computed(() => getCountry().toUpperCase());

    // Generate legal term text with link from component config
    const privacyText = computed(() => {
      const { translations } = props.subscribe;
      let message = ``;

      const title1 = translations.linkOne.split('|')[0];
      const link1 = translations.linkOne.split('|')[1];

      message = translations.legalTermText.replace(
        '{link-1}',
        `<a href="${link1}" target="_blank">${title1}</a>`
      );

      const title2 = translations.linkTwo.split('|')[0];
      const link2 = translations.linkTwo.split('|')[1];

      message = message.replace(
        '{link-2}',
        `<a href="${link2}" target="_blank">${title2}</a>`
      );

      return message;
    });

    const currency = cmsSiteConfiguration.value?.commerceConfig?.currency || '';
    const locale = (cmsSiteConfiguration.value?.commerceConfig?.locale || '')
      .replace('-', '_')
      .split('_');

    const handleSubscription = async (email) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Newsletter',
          eventAction: 'Footer Newsletter Sign Up',
          eventLabel: 'Submit',
          nonInteractive: false,
          customMetrics: {},
          customVariables: {
            formLocation: 'inline:footer:none',
          },
          _clear: true,
        },
      });
      let subscribeResponse;
      try {
        if (loggedIn.value) {
          await getBasicInformation();
          const basicInfoData = {
            email: basicInformation.value.email,
            firstName: basicInformation.value.firstName,
            lastName: basicInformation.value.lastName,
            gender: basicInformation.value.gender,
            birthDate: basicInformation.value.birthDate,
          };
          subscribeResponse = await setBasicInformation({
            ...basicInfoData,
            subscriptions: [
              {
                type: 'Newsletter',
                optin: true,
                country: country.value,
                optinSource: root.$env.API_SOURCE,
                region: root.$env.API_REGION,
              },
            ],
          });
        } else {
          subscribeResponse = await updateProfile({
            email: email,
            currency: currency,
            language: locale.length ? locale[0].toUpperCase() : 'EN',
          });
        }
        const isSuccess =
          subscribeResponse.status === 200 || subscribeResponse.status === 204;
        changeNewsletterNotification({
          showNotification: true,
          isSuccess,
        });
        if (isSuccess) {
          dispatchEvent({
            eventName: 'loadEventData',
            overrideAttributes: {
              eventCategory: 'Newsletter',
              eventAction: 'Footer Newsletter Sign Up',
              eventLabel: 'Confirmed',
              nonInteractive: false,
              customMetrics: {
                elementConversion: 1,
              },
              customVariables: {
                formLocation: 'inline:footer:none',
              },
              _clear: true,
            },
          });
        }
      } catch (error) {
        changeNewsletterNotification({
          showNotification: true,
          isSuccess: false,
        });
      }
    };

    const handleSubscribeShown = () => {
      if (canSubscribeFormImpressionBeDispatched.value) {
        dispatchEvent({
          eventName: 'loadEventData',
          overrideAttributes: {
            eventCategory: 'Newsletter',
            eventAction: 'Footer Newsletter Sign Up',
            eventLabel: 'Impression',
            nonInteractive: true,
            customMetrics: {
              elementView: 1,
            },
            customVariables: {
              formLocation: 'inline:footer:none',
            },
            _clear: true,
          },
        });
        canSubscribeFormImpressionBeDispatched.value = false;
      }
    };

    const clearNotifications = () => {
      changeNewsletterNotification({
        showNotification: false,
        isSuccess: null,
      });
    };

    clearNotifications();

    const activeSubscribeFormImpressionBeDispatched = () => {
      document.removeEventListener(
        'scroll',
        activeSubscribeFormImpressionBeDispatched
      );
      canSubscribeFormImpressionBeDispatched.value = true;

      // subscribe form is fully visible if not wait trigger from element
      var elSubscriptionform = document.querySelector(
        '.vf-footer .subscription-form'
      );
      var position = elSubscriptionform?.getBoundingClientRect();

      if (
        position &&
        position.top >= 0 &&
        position.bottom <= window.innerHeight
      ) {
        handleSubscribeShown();
      }
    };

    watch(
      () => root.$route.fullPath,
      () => {
        if (isClient && canSubscribeFormImpressionBeDispatched.value) {
          // reset subscribe form validate
          canSubscribeFormImpressionBeDispatched.value = false;
          document.addEventListener(
            'scroll',
            activeSubscribeFormImpressionBeDispatched
          );
        }
      }
    );

    onMounted(() => {
      if (isClient) {
        onBaseDone(() => {
          canSubscribeFormImpressionBeDispatched.value = true;
        });
      }
    });

    return {
      canSubscribeFormImpressionBeDispatched,
      privacyText,
      newsletterNotification,
      handleSubscription,
      handleSubscribeShown,
      clearNotifications,
      showInputLabelAbove,
    };
  },
});
