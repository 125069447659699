import { CmsSite, CMTeaserTarget } from '@vf/api-contract';
import { extractProperty } from './extractProperty';
import { ROUTES } from '../../../utils/routes';
import { useI18n } from '../../../useI18n';
import { CmsCanonical, ComposableContext } from '../../types';

const getScrollToElementId = (element) => {
  const scrollTargetId = extractProperty(element, 'id', '');
  return `[data-id='${scrollTargetId}']`;
};

export const appendParamsToUrl = (url: string, params: string): string => {
  if (!params || !url) {
    return url;
  }

  const mergingSymbol = url.includes('?') ? '&' : '?';
  const paramsNoQuestionMark = params.startsWith('?')
    ? params.slice(1)
    : params;

  return [url, mergingSymbol, paramsNoQuestionMark].join('');
};

export const generateLinkFromTeaserTargets = (
  targets: CMTeaserTarget[],
  context: ComposableContext,
  siteConfiguration: CmsSite,
  urlParams?: string,
  cmsBaseUri?: string,
  scrollToComponent?: boolean
) => {
  const targetObject = targets?.[0];

  if (!targetObject) {
    return '';
  }

  const link = generateLinkFromTarget(
    targetObject.target,
    context,
    siteConfiguration,
    cmsBaseUri,
    scrollToComponent
  );

  return link.includes('data-id') ||
    targetObject.target?.type === 'CMExternalLink'
    ? link
    : appendParamsToUrl(link, urlParams);
};

export const generateLinkFromTarget = (
  target: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri?: string,
  scrollToComponent?: boolean
) => {
  if (!target) return '';

  /** External link */
  if (target.type === 'CMExternalLink') {
    return target.url;
  }

  /** Scroll to target with data-id */
  if (scrollToComponent) {
    return getScrollToElementId(target);
  }

  /** Internal page link */
  if (target?.type === 'CMChannel') {
    const urlSegment = extractProperty(target, 'urlSegment', '/');
    return removeRootSegmentFromUrlSegment(
      urlSegment,
      siteConfiguration,
      context
    );
  }

  /** Download link */
  if (target.type === 'CMDownload') {
    const url = extractProperty(target, 'data.uri');
    return cmsBaseUri ? `${cmsBaseUri}${url}` : url;
  }

  const { localePath } = useI18n(context.instance);

  /** Commerce internal link */
  const type = extractProperty(target, 'commerceRef.type', null);
  const id = extractProperty(target, 'commerceRef.id', null);
  const commerceLink = extractProperty(target, 'commerceRef.link', null);

  /** If whole link to commerce page is already there - use it */
  if (commerceLink) {
    return localePath(commerceLink);
  }

  if (type && id) {
    /** If link property with complete URL is missing - construct the link ourselves */
    switch (type) {
      case 'category':
        return localePath(ROUTES.CATEGORY(id));

      case 'product':
      case 'sku':
        return localePath(ROUTES.PRODUCT(id));

      default:
        return '';
    }
  }

  // default behavior: Scroll to target with data-id
  return getScrollToElementId(target);
};

export const getCanonicalUrl = (
  canonical: CmsCanonical,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  if (!canonical) {
    return '';
  }
  return generateLinkFromTarget(canonical, context, siteConfiguration);
};

export const getTeaserLink = (
  teaserTarget: CMTeaserTarget,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri?: string
) => {
  /**
   * If Teaser Target is a CTA (very likely) fetch deep link
   * If not, it may be just a direct link
   */
  const deepLink = teaserTarget?.target?.teaserTargets?.[0]?.target;
  const source = deepLink || teaserTarget?.target;
  if (source?.viewtype === 'cta-button' && !deepLink) {
    return '#';
  }

  return generateLinkFromTarget(source, context, siteConfiguration, cmsBaseUri);
};

export const removeRootSegmentFromUrlSegment = (
  urlSegment,
  siteConfiguration,
  context
) => {
  const { localePath } = useI18n(context.instance);
  try {
    const mainSiteSegment = siteConfiguration.root.segment;

    const replacePageSegment = new RegExp(`${mainSiteSegment}\/?`);

    return localePath(urlSegment.replace(replacePageSegment, ''));
  } catch (e) {
    return '';
  }
};
