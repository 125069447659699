import { endpoints } from '../../config';
import { headerBuilder, urlBuilder, axios } from '../../helpers';
import { NotifyMeObjectRequest } from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import type { ApiClientConfiguration } from '../../configuration';

export const notifyMe = (configuration: ApiClientConfiguration) => (
  data: NotifyMeObjectRequest
): Promise<AxiosResponse<{ success: boolean }>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).product.notifyMe,
    {
      config: configuration,
    }
  );

  return axios.put(url, data, {
    headers: headerBuilder(configuration),
  });
};
