import { CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { richText } from './richText';

export const cartBottomPromo = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const richTextData = richText(data, context, siteConfiguration, cmsBaseUri);

  return {
    component: 'cart/CartBottomPromo',
    children: [richTextData],
  };
};
