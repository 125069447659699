import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';
import { ComponentInstance } from '@vf/composables';
import { usePaymentSession } from '../usePaymentSession';

export const usePaymentStore = (instance: ComponentInstance) => {
  return defineStore('payment', () => {
    const provider = ref('');
    const session = ref();
    const data = ref();
    const service = ref();
    const deviceFingerprint = ref('');

    const reset = () => {
      provider.value = undefined;
      session.value = undefined;
      data.value = undefined;
    };

    return {
      data,
      deviceFingerprint,
      provider,
      session,
      service,
      async getSession(sessionData?) {
        const { data } = await usePaymentSession(instance, sessionData);
        session.value = data;
        provider.value = data?.provider;
      },
      reset,
    };
  })();
};
