import {
  CMCollection,
  CMCommerceRef,
  CmsHeader,
  CmsHeaderFavoritesTooltip,
  CmsHeaderMiniCart,
  CmsMenu,
  CmsMenuItem,
  CmsMenuItemLevel,
  CmsMenuItemLevel1,
  CmsMenuItemLevel2,
  CmsMenuItemType,
  CmsPromoBar,
  CmsSearchBar,
  CmsSite,
  CmsUtilNavigation,
  CmsUtilNavigationMobile,
  CMTeaser,
  MegaMenuMobileMenuSettings,
  UtilityNavigationSmallTranslations,
} from '@vf/api-contract';
import {
  extractLocalSettingsExpandedRichText,
  extractProperty,
  generateLinkFromTeaserTargets,
  getComponentConfigByName,
  getDataNavigationValue,
  getDynamicField,
  getImageObject,
  getStateManagementData,
  getTeaserStyle,
  getTitle,
  getTranslation,
  getSegmentsData,
  generateLinkFromTarget,
} from './utils';
import { ROUTES } from '../../utils/routes';
import {
  getColumnMargins,
  getColumnOrder,
  getColumnPaddings,
  getColumnSize,
  getColumnVisibility,
} from '../dataTransformators/utils/components/gridColumn';
import { useI18n } from '../../useI18n';
import { CmsHeaderConfig, ComposableContext } from '../types';

export const header = (
  data: CmsHeader,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  menu: CmsMenu,
  cmsBaseUri: string,
  headerConfig?: CmsHeaderConfig
) => {
  const mobileMenuSettings: MegaMenuMobileMenuSettings = {
    autoGeneratedL2LinksSmall: extractProperty(
      menu,
      'autoGeneratedL2LinksSmall',
      'disabled'
    ),
    autoGeneratedL2LinksCategoryText: extractProperty(
      menu,
      'autoGeneratedL2LinksCategoryText',
      '$Shop all {0}$'
    ),
    autoGeneratedL2LinksPageText: extractProperty(
      menu,
      'autoGeneratedL2LinksPageText',
      '$Unknown macro: {0}$'
    ),
  };

  const { localePath } = useI18n(context.instance);
  const utilityNavigation = getUtilityNavigation(
    data,
    'utility-navigation',
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const utilityMobileNavigation = getUtilityNavigation(
    data,
    'utility-navigation-small',
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const mainNavigation = getMainNavigation(
    menu,
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const promoBar = getPromoBar(data, context, siteConfiguration, cmsBaseUri);
  const searchBar = getSearchBar(data, context);
  const miniCart = getMiniCart(data, siteConfiguration);
  const {
    largeMaxItems,
    mediumMaxItems,
    smallMaxItems,
    openCategorySubmenu,
    closeCategorySubmenu,
  } = getMenuContainerProperties(menu);
  const favoritesTooltip = getFavoritesTooltip(data);
  const loyaltyRewardsProgressTranslations = getLoyalRewardsProgressTranslations(
    data
  );
  return {
    component: 'layout/Header',
    _cms_id: 'base-header',
    props: {
      utilityNavigation: {
        id: utilityNavigation['id'],
        desktop: utilityNavigation['items'],
        mobile: utilityNavigation['items'],
      },
      logo: {
        link: localePath(ROUTES.HOME()),
      },
      title: menu.title,
      linkTag: 'a',
      navigation: mainNavigation,
      searchButtonText: '',
      searchBar,
      miniCart,
      steps: [],
      moreTitle: menu.moreText || '$More$',
      shopAll: menu.shopAllText || '$Shop all$',
      mobileNav: {
        items: utilityMobileNavigation['items'],
        id: utilityMobileNavigation['id'],
        translations: utilityMobileNavigation.translations,
      },
      searchResults: null,
      promoBar: {
        id: promoBar.id,
        interval: promoBar.interval,
        message: promoBar.items,
        backgroundColor: promoBar.backgroundColor,
        showClose: promoBar.showClose,
        closeLabel: promoBar.closeLabel,
        wholeBarClickable: promoBar.wholeBarClickable,
      },
      maxItems: {
        largeMaxItems,
        mediumMaxItems,
        smallMaxItems,
      },
      menuTranslations: {
        openCategorySubmenu,
        closeCategorySubmenu,
      },
      headerConfig,
      favoritesTooltip,
      mobileMenuSettings,
      loyaltyRewardsProgressTranslations,
    },
  };
};

const mapUtilityNavigationItem = (
  item: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const role = extractProperty(
    item,
    'localSettings.dynamicFields.role',
    item.viewtype
  );

  return {
    id: item.id,
    title: getTitle(item),
    name: item.name,
    alternativeTitle: item.teaserSubtitle,
    icon: extractProperty(
      item,
      'localSettings.dynamicFields.icon',
      extractProperty(item, 'localSettings.icon')
    ),
    openInNewTab: !!item.openInNewTab,
    openInNewModal: !!item.openInNewModal,
    teaserTargetType: extractProperty(item, 'teaserTargets[0].target.type'),
    titleColor: getTeaserStyle(item, 'teaserTitleColor'),
    titleFontSize: getTeaserStyle(item, 'teaserTitleFontSize'),
    titleFontFamily: getTeaserStyle(item, 'teaserTitleFontFace'),
    titleFontWeight: getTeaserStyle(item, 'teaserTitleFontWeight'),
    titleBackgroundColor: getTeaserStyle(item, 'teaserTitleBackgroundColor'),
    titleClassModifier: getTeaserStyle(item, 'teaserTitleClassModifiers'),
    link: generateLinkFromTeaserTargets(
      item.teaserTargets,
      context,
      siteConfiguration,
      item.urlParams,
      cmsBaseUri
    ),
    counter: role === 'cart' ? 0 : null,
    visible: true,
    role,
    cssClass: getTeaserStyle(item, 'teaserClassModifiers'),
    mobile: extractProperty(item, 'localSettings.dynamicFields.mobile'),
    ariaLabel: getDynamicField(item, 'ariaLabel'),
    dataNavigation: getDataNavigationValue(item),
    states: getStateManagementData(item),
    segments: getSegmentsData(item),
  };
};

const getUtilityNavigation = (
  data: CmsHeader,
  viewType: 'utility-navigation-small' | 'utility-navigation',
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const utilityNavigationCmsData = <
    CmsUtilNavigationMobile | CmsUtilNavigation
  >data.find((item) => item.viewtype === viewType);

  if (!utilityNavigationCmsData) {
    return { items: [], id: '' };
  }

  const utilityNavigationTranslations = {
    'utility-navigation-small': {
      checkoutTooltipText: getTranslation(
        utilityNavigationCmsData,
        'checkoutTooltipText',
        '$You have successfully added {{productName}} to your shopping cart.$'
      ),
      checkoutTooltipButtonText: getTranslation(
        utilityNavigationCmsData,
        'checkoutTooltipButtonText',
        '$Checkout$'
      ),
      closeMenuButtonAriaLabel: getTranslation(
        utilityNavigationCmsData,
        'closeMenuButtonAriaLabel',
        '$Close$'
      ),
    } as UtilityNavigationSmallTranslations,
  };

  return {
    items: utilityNavigationCmsData.teasableItems.map((item) =>
      mapUtilityNavigationItem(item, context, siteConfiguration, cmsBaseUri)
    ),
    id: utilityNavigationCmsData.id,
    translations: utilityNavigationTranslations[viewType],
  };
};

const getPromoBar = (
  data: CmsHeader,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const promoBar = <CmsPromoBar>(
    data.find((item) => item.viewtype === 'promo-bar')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(promoBar)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(promoBar)
  );

  if (!promoBar || !stateFulfilled || !segmentsFulfilled) {
    return { interval: 0, items: [], backgroundColor: '', id: '' };
  }

  const isCollection = (
    item: CMCollection | CMTeaser
  ): item is CMCollection => {
    return !!(item as CMCollection).teasableItems;
  };

  const getPromoBarSlideMessage = (item: CMTeaser) => {
    return {
      id: item.id,
      text: getTitle(item),
      modalContent: extractProperty(item, 'teaserRichTextObject.html'),
      titleColor: getTeaserStyle(item, 'teaserTitleColor'),
      titleClassModifier: getTeaserStyle(item, 'teaserTitleClassModifiers'),
      titleFontSize: getTeaserStyle(item, 'teaserTitleFontSize'),
      titleFontFamily: getTeaserStyle(item, 'teaserTitleFontFace'),
      titleFontWeight: getTeaserStyle(item, 'teaserTitleFontWeight'),
      titleBackgroundColor: getTeaserStyle(item, 'teaserTitleBackgroundColor'),
      actionText: extractProperty(
        item,
        'localSettings.dynamicFields.actionButton'
      ),
      actionType: extractProperty(
        item,
        'localSettings.dynamicFields.actionButtonType'
      ),
      openInNewTab: item.openInNewTab === true,
      linkTarget: generateLinkFromTeaserTargets(
        item.teaserTargets,
        context,
        siteConfiguration,
        item.urlParams,
        cmsBaseUri
      ),
      states: getStateManagementData(item),
      segments: getSegmentsData(item),
    };
  };

  const getPromoBarSlide = (item: CMTeaser | CMCollection) => {
    if (isCollection(item)) {
      if (!item.teasableItems.length) {
        return null;
      }

      return {
        states: getStateManagementData(item),
        segments: getSegmentsData(item),
        items: item.teasableItems.map(getPromoBarSlideMessage),
      };
    }

    return getPromoBarSlideMessage(item);
  };

  return {
    id: promoBar.id,
    interval: getDynamicField(promoBar, 'interval'),
    items: promoBar.teasableItems
      .map(getPromoBarSlide)
      .filter((i) => i !== null),
    backgroundColor: getDynamicField(promoBar, 'backgroundColor'),
    showClose: getDynamicField(promoBar, 'showClose', false),
    closeLabel: getDynamicField(promoBar, 'closeLabel', '$CLOSE$'),
    wholeBarClickable: getDynamicField(promoBar, 'wholeBarClickable', true),
  };
};

const getMainNavigation = (
  menu: CmsMenu,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { localePath } = useI18n(context.instance);
  const extractLink = (item: CmsMenuItem) => {
    const linkId = extractProperty(item, 'target[0].commerceRef.id');
    const commerceLink = extractProperty(item, 'target[0].commerceRef.link');
    return localePath(commerceLink || ROUTES.CATEGORY(linkId));
  };

  const getNavigationColumn = (column) => {
    return {
      ...getColumnSize(column, true),
      ...getColumnVisibility(column),
      ...getColumnOrder(column),
      margins: getColumnMargins(column),
      paddings: getColumnPaddings(column),
      level2MenuItems: column.components.map(getNavigationItem),
      stateFulfilled:
        context.instance.$root.$stateManagementChecker(
          getStateManagementData(column)
        ) && context.instance.$root.$segmentsChecker(getSegmentsData(column)),
    };
  };

  const isLevel1MenuItem = (
    item: CmsMenuItemLevel
  ): item is CmsMenuItemLevel1 => {
    return !!(item as CmsMenuItemLevel1).gridColumn;
  };

  const isLevel2MenuItem = (
    item: CmsMenuItemLevel
  ): item is CmsMenuItemLevel2 => {
    return !!(
      (item as CmsMenuItemLevel2).children &&
      (item as CmsMenuItemLevel2).children.contentChildren
    );
  };

  const getElementType = (item: CmsMenuItem): CmsMenuItemType => {
    if (extractProperty(item, 'target.length') === 0) {
      return 'placeholder';
    }

    if (extractProperty(item, 'target[0].pictures.length') > 0) {
      return 'image';
    }

    if (extractProperty(item, 'target[0].type') === 'CMExternalProduct') {
      return 'product';
    }

    if (typeof extractProperty(item, 'target[0].url') === 'string') {
      return 'externalUrl';
    }

    if (typeof extractProperty(item, 'target[0].urlSegment') === 'string') {
      return 'internalUrl';
    }

    return 'link';
  };

  const getCommerceItem = (item: CMCommerceRef, target) => ({
    id: item.id,
    name: target.find((el) => el.externalTechId === item.id).name,
    title: item.title,
    mobileTitle: item.title,
    link: generateLinkFromTeaserTargets(
      [
        {
          callToActionEnabled: false,
          callToActionText: '',
          target: {
            id: '',
            commerceRef: item,
            segment: '',
            title: '',
            name: '',
            type: '',
            url: '',
            teaserTargets: [],
            urlParams: '',
          },
        },
      ],
      context,
      siteConfiguration,
      '',
      cmsBaseUri
    ),
    dataNavigation: item.id,
    ariaLabel: item.title,
    stateFulfilled: true,
  });

  const getLinkBasingOnType = (item: CmsMenuItem, type: CmsMenuItemType) => {
    const urlParams = extractProperty(item, 'urlParams');
    switch (type) {
      case 'externalUrl':
        return extractProperty(item, 'target[0].url');

      case 'internalUrl': {
        const target = extractProperty(item, 'target[0]', {});
        const url = generateLinkFromTarget(target, context, siteConfiguration);
        return urlParams ? `${url}?${urlParams}` : url;
      }

      case 'placeholder':
        return '';

      case 'product': {
        const commerceLink = extractProperty(
          item,
          'target[0].commerceRef.link'
        );
        const params = extractProperty(item, 'urlParams', '');
        if (commerceLink) {
          return (
            localePath(commerceLink) +
            (params
              ? `${
                  localePath(commerceLink)?.includes('?') ? '&' : '?'
                }${params}`
              : '')
          );
        }
        return (
          extractLink(item) +
          (params
            ? `${extractLink(item)?.includes('?') ? '&' : '?'}${params}`
            : '')
        );
      }

      case 'link':
        return urlParams
          ? `${extractLink(item)}?${urlParams}`
          : extractLink(item);
      case 'image': {
        const target = extractProperty(
          item,
          'target[0].teaserTargets[0].target'
        );
        const url = generateLinkFromTarget(target, context, siteConfiguration);
        return urlParams ? `${url}?${urlParams}` : url;
      }
      default:
        return extractLink(item);
    }
  };

  const getNavigationItem = (
    item: CmsMenuItemLevel1 | CmsMenuItemLevel2 | CmsMenuItem,
    index: number
  ) => {
    const elementType = getElementType(item);
    const getTitlesBasingOnType = (
      item: CmsMenuItem,
      type: CmsMenuItemType
    ) => {
      switch (type) {
        case 'product': {
          const target = extractProperty(item, 'target[0]');
          const title = item.title || target.title;
          return {
            title,
            mobileTitle: title,
            name: target.name,
          };
        }

        default:
          return {
            title:
              extractProperty(item, 'title') ||
              extractProperty(item, 'target[0].commerceRef.title') ||
              extractProperty(item, 'target[0].title'),
            mobileTitle:
              extractProperty(item, 'mobileTitle') ||
              extractProperty(item, 'title') ||
              extractProperty(item, 'target[0].commerceRef.title') ||
              extractProperty(item, 'target[0].title'),
            name:
              extractProperty(item, 'name') ||
              extractProperty(item, 'gaEventLabel') ||
              extractProperty(item, 'target[0].name') ||
              extractProperty(item, 'id'),
          };
      }
    };

    const { title, mobileTitle, name } = getTitlesBasingOnType(
      item,
      elementType
    );
    return {
      id: index,
      'data-id': extractProperty(item, 'target[0].id'),
      title: title,
      name: name,
      mobileTitle: mobileTitle,
      link: getLinkBasingOnType(item, elementType),
      image:
        elementType === 'product'
          ? context.instance.$root.$mediaUrlGenerator({
              productName: name,
              pid: extractProperty(item, 'target[0].commerceRef.id'),
            })
          : '',
      placeholder: elementType === 'placeholder',
      dataNavigation: getDataNavigationValue(item),
      cssClass: item.cssClass,
      style: item.style,
      componentStyles: item.componentStyles,
      overrideAltText: item.overrideAltText,
      overrideLinkTracking: item.overrideLinkTracking,
      linkTitle: item.linkTitle,
      overrideLinkText: item.overrideLinkText,
      ariaLabel: item.ariaLabel,
      openInNewModal: item.openInNewModal,
      openInNewTab: item.openInNewTab,
      expandMobile: item.expandMobile,
      accordionOpenByDefault: getComponentConfigByName(
        item,
        'accordion-mobile',
        { 'accordion-open-by-default-mobile': false }
      ),
      shopAllButton: getComponentConfigByName(item, 'nav-item-settings', {
        'show-shop-all-button': false,
        'shop-all-button': '',
      }),
      navItemSettings: getComponentConfigByName(item, 'nav-item-settings', {
        'show-shop-all-button-desktop': false,
        'shop-all-button-desktop': '',
        'show-shop-all-button-mobile': false,
        'shop-all-button-mobile': '',
      }),
      isImage: elementType === 'image',
      stateFulfilled:
        context.instance.$root.$stateManagementChecker(
          getStateManagementData(item)
        ) && context.instance.$root.$segmentsChecker(getSegmentsData(item)),
      imageData:
        elementType === 'image'
          ? getImageObject(
              extractProperty(item, 'target[0].pictures[0]'),
              siteConfiguration,
              null,
              cmsBaseUri
            )
          : null,
      ...(isLevel1MenuItem(item)
        ? { columns: item.gridColumn.columns.map(getNavigationColumn) }
        : {}),
      ...(isLevel2MenuItem(item)
        ? {
            children: [
              ...(item.exposeCatChildren
                ? item.children.commerceChildren.map((el) =>
                    getCommerceItem(
                      el,
                      extractProperty(item, 'target[0].category.children')
                    )
                  )
                : []),
              ...item.children.contentChildren.map(getNavigationItem),
            ],
          }
        : {}),
    };
  };

  return menu.children.map(getNavigationItem).filter(Boolean);
};

const extractMethodRichText = (data, settingName, defaultText) => {
  const richText = extractLocalSettingsExpandedRichText(
    data,
    settingName,
    defaultText
  );
  return richText?.html || defaultText;
};

const getSearchBar = (data: CmsHeader, context: ComposableContext) => {
  const searchBar = <CmsSearchBar>(
    data.find((item) => item.viewtype === 'header-search')
  );
  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(searchBar)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(searchBar)
  );

  if (!searchBar || !stateFulfilled || !segmentsFulfilled) {
    return null;
  }

  const resultsToShow = +extractProperty(
    searchBar,
    'localSettings.dynamicFields.resultsToShow'
  );
  const suggestionsToShow = +extractProperty(
    searchBar,
    'localSettings.dynamicFields.suggestionsToShow'
  );
  const searchAllLabel = getTranslation(searchBar, 'searchAll', '$Search all$');
  const clearSearchLabel = getTranslation(
    searchBar,
    'clearSearch',
    '$Clear search$'
  );
  const seeMoreLabel = getTranslation(searchBar, 'seeMore', '$See more$');
  const resultsFoundAria = getTranslation(
    searchBar,
    'resultsFoundAria',
    '${{numResults}} results are found for {{searchTerm}}$'
  );
  const closeSuggestionsLabel = getTranslation(
    searchBar,
    'closeSuggestionsLabel',
    '$Close suggestions$'
  );
  const featuredLabel = getTranslation(
    searchBar,
    'featuredResults',
    '$Featured results$'
  );
  const topSearches = getTranslation(
    searchBar,
    'topSearches',
    '$Top Searches$'
  );
  const visualSearchShowTooltip = getDynamicField(
    searchBar,
    'visualSearchShowTooltip',
    false
  );
  const visualSearchTooltipCloseButtonText = getTranslation(
    searchBar,
    'visualSearchTooltipCloseButtonText',
    '$Close$'
  );
  const visualSearchTooltipButtonText = getTranslation(
    searchBar,
    'visualSearchTooltipButtonText',
    '$START NOW$'
  );
  const visualSearchLabel = getTranslation(
    searchBar,
    'visualSearchLabel',
    '$visualSearchLabel$'
  );
  const visualSearchTooltipHtml = extractMethodRichText(
    searchBar,
    'visualSearchTooltipHtml',
    `
      <div style="text-align: center; padding: .5rem;">
        <span style="color: red; font-weight: bold; font-size: 1rem">$NEW$</span>
        <br/>
        <span style="font-weight: bold; font-size: 1.125rem">$Visual Search$</span>
        <p style="font-size: 0.75rem">
          $Take a photo or upload an<br/>image to find similar items.$
        </p>
      </div>
    `
  );

  return {
    id: searchBar.id,
    requiredMessage: extractProperty(
      searchBar,
      'localSettings.dynamicFields.requiredMessage'
    ),
    searchPlaceholder: getTranslation(
      searchBar,
      'searchInputField',
      '$Search..$'
    ),
    searchAriaLabel: extractProperty(
      searchBar,
      'localSettings.dynamicFields.searchAriaLabel',
      'search'
    ),
    ...(searchAllLabel && { searchAllLabel }),
    ...(clearSearchLabel && { clearSearchLabel }),
    ...(seeMoreLabel && { seeMoreLabel }),
    ...(resultsFoundAria && { resultsFoundAria }),
    ...(closeSuggestionsLabel && {
      closeSuggestionsLabel,
    }),
    ...(featuredLabel && { featuredLabel }),
    ...(resultsToShow && { resultsToShow }),
    ...(suggestionsToShow && { suggestionsToShow }),
    ...(topSearches && { topSearches }),
    visualSearchShowTooltip,
    ...(visualSearchTooltipCloseButtonText && {
      visualSearchTooltipCloseButtonText,
    }),
    ...(visualSearchTooltipButtonText && { visualSearchTooltipButtonText }),
    ...(visualSearchTooltipHtml && { visualSearchTooltipHtml }),
    visualSearchLabel,
  };
};

const getMiniCart = (data: CmsHeader, siteConfiguration: CmsSite) => {
  const miniCart = <CmsHeaderMiniCart>(
    data.find((item) => item.viewtype === 'header-mini-cart')
  );

  if (!miniCart) {
    return null;
  }

  return {
    id: miniCart.id,
    icon: getDynamicField(miniCart, 'icon', 'bag'),
    currencySymbol: siteConfiguration?.commonConfig?.currencySymbol,
    translations: {
      subtotal: getTranslation(miniCart, 'subtotal', '$Subtotal$'),
      viewCart: getTranslation(miniCart, 'viewCart', '$View Cart$'),
      checkout: getTranslation(miniCart, 'checkout', '$Checkout$'),
      remove: getTranslation(miniCart, 'remove', '$Remove$'),
      menuItemAriaLabel: getTranslation(
        miniCart,
        'menuItemAriaLabel',
        'show cart'
      ),
      swipePrevious: getTranslation(
        miniCart,
        'swipePrevious',
        'swipe previous'
      ),
      swipeNext: getTranslation(miniCart, 'swipeNext', 'swipe next'),
      removeNotification: getTranslation(
        miniCart,
        'removeNotification',
        '$Item has been removed from your shopping cart$'
      ),
      giftWithPurchaseMessage: getTranslation(
        miniCart,
        'giftWithPurchaseMessage',
        '$Free gifts with purchases are not eligible for returns or exchanges$'
      ),
      free: getTranslation(miniCart, 'free', '$Free$'),
    },
  };
};

const getMenuContainerProperties = (menu: CmsMenu) => {
  try {
    const config = menu.componentConfig.find(
      (conf) => conf.name === 'menu-container-properties'
    );

    return config.values;
  } catch (e) {
    console.error('Header component error: no config found.');
  }
  return {
    largeMaxItems: 6,
    mediumMaxItems: 4,
    smallMaxItems: 5,
    openCategorySubmenu: '$open ${itemtitle} submenu$',
    closeCategorySubmenu: 'close ${itemtitle} submenu$',
  };
};

const getFavoritesTooltip = (data: CmsHeader) => {
  const favoritesTooltip = <CmsHeaderFavoritesTooltip>(
    data.find((item) => item.viewtype === 'header-favorites-tooltip')
  );

  if (!favoritesTooltip) {
    return null;
  }

  return {
    translations: {
      notificationAuthorised: getTranslation(
        favoritesTooltip,
        'notificationAuthorised',
        '$Item authorised$'
      ),
      notificationUnauthorised: extractProperty(
        favoritesTooltip,
        'teaserRichTextObject.html',
        '$Item unauthorised$'
      ),
      heading: getTranslation(favoritesTooltip, 'heading', '$Item heading$'),
      buttonLabel: getTranslation(
        favoritesTooltip,
        'buttonLabel',
        '$Button label$'
      ),
    },
  };
};

const getLoyalRewardsProgressTranslations = (data) => {
  const loyaltyProgressBar = data.find(
    (item) => item.viewtype === 'loyalty-rewards-progress-bar'
  );

  return {
    greeting: getTranslation(loyaltyProgressBar, 'greeting', '$Welcome!$'),
    greetingWithName: getTranslation(
      loyaltyProgressBar,
      'greetingWithName',
      '$Hi, {{firstName}}$'
    ),
    totalRewards: getTranslation(
      loyaltyProgressBar,
      'totalRewards',
      '${{currencyAmount}} reward$'
    ),
    rewardsSummary: getTranslation(
      loyaltyProgressBar,
      'rewardsSummary',
      '$you currently have {{points}} points.$'
    ),
  };
};
