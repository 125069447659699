import useCategory from '../../useCategory';
import { ComposableContext } from '../types';
import {
  extractProperty,
  extractCommonConfigLink,
  generateLinkFromTarget,
  getTranslation,
  extractLocalSettingsExpandedLinks,
} from './utils';
import { CmsSite } from '@vf/api-contract';
import {
  CmsPlpGrid,
  getConfigValues,
  getPlpSettingsValues,
  getTitle,
} from './utils/plpConfig';
import { getQuickShopTranslations } from './utils/getQuickShopTranslations';

export const plpGrid = (
  data: CmsPlpGrid,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const {
    setProductsPerPage,
    setInitialLoadProductsAmount,
    setActiveCategoryId,
    categoryId,
  } = useCategory(context.instance, context.contextKey);
  const config = getConfigValues(data);
  const plpConfig = getPlpSettingsValues(data);
  const customLinkCatalog = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'catalogueCategory'),
    '[0]'
  )?.commerceRef?.id;
  const title = getTitle(data);

  setInitialLoadProductsAmount(config['initial-load-products']);
  setProductsPerPage(config['next-products']);
  if (customLinkCatalog && categoryId.value != customLinkCatalog) {
    setActiveCategoryId(customLinkCatalog);
  }
  const quickShopLink = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'plp-quick-shop'),
    '[0]'
  );
  const signInToBuy = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

  const loyaltyEnrollment = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'loyalty-enrollment-modal'),
    '[0]'
  )?.id;

  const showAddToFavourites = () => {
    const isPLPGridConfigExist = data.componentConfig.find((conf) => {
      return conf.name === 'plp-grid-properties';
    });
    if (isPLPGridConfigExist) {
      return extractProperty(config, 'showAddToFavourites', false);
    }
    return context.instance.$themeConfig?.plpGrid.showAddToFavourites || false;
  };

  return {
    component: 'plp/CategoryProducts',
    props: {
      title: title,
      cssClassProp: data?.componentConfig[3]?.values,
      customLinkCatalog: customLinkCatalog,
      translations: {
        itemsCountPlural: getTranslation(data, 'itemsCountPlural', '$Items$'),
        itemsCountSingular: getTranslation(
          data,
          'itemsCountSingular',
          '$Item$'
        ),
        specialPriceLabel: getTranslation(data, 'specialPriceLabel', '$NOW$'),
        offerPriceLabel: getTranslation(data, 'offerPriceLabel', '$WAS$'),
        seeMoreColorsLink: getTranslation(
          data,
          'seeMoreColorsLink',
          '$See more colors$'
        ),
        quickShopButtonText: getTranslation(
          data,
          'quickShopButtonText',
          '$Quick Shop$'
        ),
        addToCartLabel: getTranslation(data, 'addToCartLabel', '$Add to Cart$'),
        addWishListAriaLabel: getTranslation(
          data,
          'addWishListAriaLabel',
          '$Add to wishlist$'
        ),
        removeWishListAriaLabel: getTranslation(
          data,
          'removeWishListAriaLabel',
          '$Remove from wishlist$'
        ),
        notifyMeLabel: getTranslation(data, 'notifyMeLabel', '$Notify me$'),
        noLongerAvailableLabel: getTranslation(
          data,
          'noLongerAvailableLabel',
          '$No longer available$'
        ),
        ...getQuickShopTranslations(data),
        page: getTranslation(data, 'page', '$Page$'),
        next: getTranslation(data, 'next', '$Next$'),
        viewPrevious: getTranslation(data, 'viewPrevious', '$ViewPrevious$'),
      },
      quickShopLink: generateLinkFromTarget(
        quickShopLink,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      initialProductsLoad: config['initial-load-products'],
      productsPerPage: config['next-products'],
      showAddToFavourites: showAddToFavourites(),
      showNoInventoryProducts: config['showNoInventoryProducts'],
      showSeeMore: plpConfig['show-see-more'],
      showColorSwatches: plpConfig['show-color-swatches'],
      showRegularPrice: plpConfig['show-regular-price'],
      showSalePrice: plpConfig['show-sale-price'],
      showWishListIcon: plpConfig['show-wishlist-icon'],
      showProductBadge: plpConfig['show-product-badge'],
      showProductName: plpConfig['show-product-name'],
      showEyebrow: plpConfig['show-eyebrow'],
      showQuickShop: plpConfig['show-quick-shop'],
      showRatingsIcon: plpConfig['show-ratings-icon'],
      showProductDescription: plpConfig['show-product-desc'],
      showProductImages: plpConfig['show-product-images'],
      showBadges: plpConfig['show-badges'],
      showAddToCart: plpConfig['showAddToCart'],
      modals: {
        signInToBuy,
        loyaltyEnrollment,
      },
      manualProductsList: extractProperty(
        data,
        'localSettings.dynamicFields.products',
        []
      ),
      randomizeProducts: extractProperty(
        data,
        'localSettings.dynamicFields.randomizeProducts',
        false
      ),
      maxProducts: extractProperty(
        data,
        'localSettings.dynamicFields.maxProducts'
      ),
      itemsPerRow: {
        small: extractProperty(
          data,
          'localSettings.dynamicFields.items-row-small'
        ),
        medium: extractProperty(
          data,
          'localSettings.dynamicFields.items-row-medium'
        ),
        large: extractProperty(
          data,
          'localSettings.dynamicFields.items-row-large'
        ),
      },
      productSource: extractProperty(
        data,
        'localSettings.dynamicFields.productSource',
        'category'
      ),
      customTitle: extractProperty(
        data,
        'localSettings.dynamicFields.customProductGridTitle',
        null
      ),
    },
  };
};
