import { CMTeaser, ArticleGridConfiguration } from '@vf/api-contract';
import { extractProperty, getTranslation } from './utils/extractProperty';
import { getGridConfigValues } from './utils/articleGridUtils';

export const newsArticleGrid = (data: CMTeaser) => {
  const filters = extractProperty(data, 'subjectTaxonomy', []);

  return {
    component: 'article/NewsArticleGrid',
    props: {
      title: data.teaserTitle || '$Brand News$',
      filters,
      configuration: getGridConfigValues(
        data,
        'article-grid-settings',
        defaultArticleGridConf
      ),
      translations: {
        all: getTranslation(data, 'all', '$All$'),
        filter: getTranslation(data, 'filter', '$Filter$'),
        showMoreButtonText: getTranslation(
          data,
          'showMoreButtonText',
          '$View more$'
        ),
        emptySearchTitle: getTranslation(
          data,
          'emptySearchTitle',
          '$No results$'
        ),
        emptySearchSubtitle: getTranslation(
          data,
          'emptySearchSubtitle',
          '$We did not find any articles.$'
        ),
      },
      contextKey: 'news-articles',
    },
  };
};

const defaultArticleGridConf: ArticleGridConfiguration = {
  'articles-per-row-small-screen': 1,
  'articles-per-row-medium-screen': 3,
  'articles-per-row-large-screen': 3,
  'articles-per-page-small-screen': 6,
  'articles-per-page-medium-screen': 6,
  'articles-per-page-large-screen': 6,
  'tile-show-title': true,
  'tile-show-body-text': true,
  'tile-show-read-more-cta': false,
  'tile-show-authors': false,
  'tile-show-thumbnail': true,
  'tile-show-tags': true,
  'tile-show-publication-date': false,
  'published-before-timestamp': 0,
  'published-after-timestamp': 0,
  'show-tag-filters': true,
  'search-order': 'publicationDate',
};
