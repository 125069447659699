import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils';

export const checkoutAthleteDiscount = (data: CMPlaceholder) => {
  return {
    component: 'checkout/CheckoutAthleteDiscount',
    props: {
      translations: {
        title: getTranslation(data, 'title', '$Athlete Discount$'),
        amount: getTranslation(data, 'amount', '$Amount$'),
        amountMessage: getTranslation(
          data,
          'amountMessage',
          '$You have spent amount out of your annual associate discount limit of limit on brand.$'
        ),
        applyMessage: getTranslation(
          data,
          'applyMessage',
          '$Tap "Apply" to use the following credit balance towards your purchase.$'
        ),
        apply: getTranslation(data, 'apply', '$Apply$'),
        applySuccessMessage: getTranslation(
          data,
          'applySuccessMessage',
          '$Your credit has been successfully applied$'
        ),
        remove: getTranslation(data, 'remove', '$Remove$'),
        removeSuccessMessage: getTranslation(
          data,
          'removeSuccessMessage',
          '$Your credit has been successfully unapplied$'
        ),
      },
    },
  };
};
