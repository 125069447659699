import {
  apiClientFactory,
  UpdateCartPaymentInstrumentPayload,
} from '@vf/api-client';
import { useRequestTracker } from '../../../useRequestTracker';

export const usePayPal = (instance, { cartId }) => {
  const {
    getCartPaymentInstruments: getCartPaymentInstrumentsAPI,
    updateCartPaymentInstrument: updateCartPaymentInstrumentAPI,
  } = apiClientFactory(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const initPayPalSession = async ({
    isTemporary,
    context,
  }: {
    isTemporary: boolean;
    context?: string;
  }) => {
    const { tag } = trackRequest('usePayPal-initPayPalSession');
    return new Promise((resolve, reject) => {
      getCartPaymentInstrumentsAPI({
        cartId: cartId.value,
        payment_method_id: 'PAYPAL',
        isTemporary,
        context,
      })
        .then(resolve)
        .catch(reject)
        .finally(() => clearRequest(tag));
    });
  };

  const updatePayPalSession = (data: UpdateCartPaymentInstrumentPayload) => {
    return updateCartPaymentInstrumentAPI(data);
  };

  return {
    initPayPalSession,
    updatePayPalSession,
  };
};
