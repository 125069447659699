import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import {
  decorateHtmlValue,
  extractLocalSettingsExpandedRichText,
  getDynamicField,
} from './utils';
import { ComposableContext } from '../types';

export const plpDescription = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const thematic = getDynamicField(data, 'thematic', false);
  const extractRichText = (cmsData, settingName, defaultText) => {
    const richText = extractLocalSettingsExpandedRichText(
      cmsData,
      settingName,
      defaultText
    );
    return richText?.html
      ? decorateHtmlValue(richText, cmsBaseUri, context, siteConfiguration)
      : defaultText;
  };

  const component = thematic
    ? 'thematic/ThematicCategoryDescription'
    : 'plp/PlpCategoryDescription';

  const props = thematic
    ? {}
    : {
        plpDescription: extractRichText(
          data,
          'plpDescription',
          '<h1>$Title$</h1>'
        ),
      };

  return {
    component,
    props,
  };
};
