import { getTranslation, getKlarnaModalTranslations } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const klarnaIntegration = (data: CMPlaceholder) => {
  return {
    component: 'pdp/KlarnaIntegration',
    props: {
      translations: {
        labels: getTranslation(data, 'pdp', {
          belowThresholdLabel:
            '$Pay in {payments} interest-free payments on purchases of {min}-{max} with {klarnaIcon}$',
          inThresholdLabel:
            '$Pay in {payments} interest-free payments of {amount} with {klarnaIcon}$',
        }),
        ...getKlarnaModalTranslations(data),
      },
    },
  };
};
