import { AxiosResponse } from 'axios';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { GetCustomerCreditCardsQuery } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const getPaymentInstruments = (
  configuration: ApiClientConfiguration
) => <ResponseType>(
  query: GetCustomerCreditCardsQuery
): Promise<AxiosResponse<ResponseType>> => {
  return axios.get(
    urlBuilder(endpoints.get(configuration).customer.getPaymentInstruments, {
      config: configuration,
    }),
    {
      params: query,
      headers: headerBuilder(configuration),
    }
  );
};
