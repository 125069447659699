/** Locale code for Google ReCaptcha https://developers.google.com/recaptcha/docs/language */
export const getGoogleReCaptchaLocale = (localeString: string): string => {
  if (!localeString) {
    return '';
  }

  const [langPart, regionPart] = localeString.split('-');

  const lang = langPart.toLowerCase();
  const region = regionPart.toUpperCase();

  /** Special case for French Canadian */
  if (lang === 'fr' && region === 'CA') {
    return `${lang}-${region}`;
  }

  return lang;
};
