import { Logger, Log } from '@vf/api-contract';

const log: Log = (level, message, meta) => {
  const method = level in console ? level : 'log';
  if (typeof window !== 'undefined' && ['error', 'warn'].includes(method)) {
    window.dtrum?.reportCustomError(level, message, JSON.stringify(meta));
  }
  console[method](`[${level}]`, message, meta);
};

export const logger: Logger = {
  error(message, meta) {
    log('error', message, meta);
  },
  warn(message, meta) {
    log('warn', message, meta);
  },
  info(message, meta) {
    log('info', message, meta);
  },
  http(message, meta) {
    log('http', message, meta);
  },
  verbose(message, meta) {
    log('verbose', message, meta);
  },
  debug(message, meta) {
    log('debug', message, meta);
  },
  silly(message, meta) {
    log('silly', message, meta);
  },
};
