import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { ref, Ref } from '@vue/composition-api';

type PayPalOrder = {
  c_paymentID: string;
  c_payerID: string;
  c_requestID: string;
  payment_instrument_id: string;
};

const payPalOrder: PayPalOrder = {
  c_paymentID: '',
  c_payerID: '',
  c_requestID: '',
  payment_instrument_id: '',
};

export type SessionPaymentData = {
  c_paymentID: string;
  c_requestID: string;
  payment_instrument_id: string;
};

type UsePayPalStorage = {
  payPalCheckoutFlowInProgress: Ref<boolean>;
  payPalPaymentInProgress: Ref<boolean>;
};

export const usePayPal = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UsePayPalStorage> = initStorage<UsePayPalStorage>(
    instance,
    'usePayPal'
  );
  const payPalCheckoutFlowInProgress: Ref<boolean> = storage.getOrSave(
    'payPalCheckoutFlowInProgress',
    ref(false)
  );
  const payPalPaymentInProgress: Ref<boolean> = storage.getOrSave(
    'payPalPaymentInProgress',
    ref(false)
  );

  const setPayPalCheckoutFlowInProgress = (val: boolean) => {
    payPalCheckoutFlowInProgress.value = val;
  };

  const setPayPalPaymentInProgress = (val: boolean) => {
    payPalPaymentInProgress.value = val;
  };

  // Add types
  const setPayPalOrderObject = (
    sessionPaymentData: SessionPaymentData,
    payerId: string
  ): void => {
    payPalOrder.c_paymentID = sessionPaymentData.c_paymentID;
    payPalOrder.c_payerID = payerId;
    payPalOrder.c_requestID = sessionPaymentData.c_requestID;
    payPalOrder.payment_instrument_id =
      sessionPaymentData.payment_instrument_id;
  };

  const getPayPalOrderPayload = (isCheckoutFlow: boolean) => {
    /** We do not need to set certain fields if it's PayPal checkout flow */
    if (isCheckoutFlow) {
      return {
        payment_instrument_id: payPalOrder.payment_instrument_id,
      };
    }

    return payPalOrder;
  };

  const getPayPalOrder = () => payPalOrder;

  return {
    setPayPalOrderObject,
    payPalCheckoutFlowInProgress,
    setPayPalCheckoutFlowInProgress,
    setPayPalPaymentInProgress,
    payPalPaymentInProgress,
    getPayPalOrderPayload,
    getPayPalOrder,
  };
};
