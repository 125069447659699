import { getStateManagementData } from '../../../../mappings/utils/extractProperty';

export const stateManagementDecorator = (cmsData: any, mappedData: any) => {
  if (mappedData) {
    mappedData.props = {
      ...mappedData.props,
      _states: getStateManagementData(cmsData),
    };
  }

  return mappedData;
};
