import cloneDeep from '@vf/shared/src/utils/cloneDeep';
import {
  getReviewPros,
  getReviewCons,
  getReviewSizing,
  getReviewWidth,
  getReviewChestsize,
  getReviewSleevelength,
} from './getMappedRollupProperty';
import { getReviewRatingDistribution } from './getReviewRatingDistribution';
import { getProductReviewTags } from './getProductReviewTags';
import { getMappedFaceoffReview } from './getMappedFaceoffReview';
import { getMappedProductReviews } from './getMappedProductReviews';
import {
  ParsedProductReview,
  ProductReviewFilter,
  ProductReviewResponse,
  ProductReviewSorting,
} from '@vf/api-contract';
import { VotedProductReviewIds } from '..';

export type GetParsedProductReviewParams = {
  reviewResponse: ProductReviewResponse;
  filters: ProductReviewFilter[];
  sorting?: ProductReviewSorting;
  locale: string;
  localeMapping: string;
  votedProductReviewIds: VotedProductReviewIds;
  previousParsedProductReview?: ParsedProductReview;
};

export const emptyRollup = {
  properties: [],
  ratingHistogram: [],
  recommendedRatio: 0,
  averageRating: 0,
  reviewCount: 0,
  faceoff: { positive: null, negative: null },
  nativeReviewCount: 0,
  nativeSamplingReviewCount: 0,
  nativeSweepstakesReviewCount: 0,
  nativeCommunityContentReviewCount: 0,
  syndicatedReviewCount: 0,
};

export const getParsedProductReview = ({
  reviewResponse,
  filters,
  sorting,
  locale,
  localeMapping,
  votedProductReviewIds,
  previousParsedProductReview,
}: GetParsedProductReviewParams): ParsedProductReview => {
  const { paging, results } = reviewResponse;
  const { reviews, productId } = results[0];
  /* Since Power reviews API doesn't include rollup values for pages other than first we try to us previous value or an empty object */
  let rollup = results[0].rollup?.properties
    ? results[0].rollup
    : cloneDeep(emptyRollup);
  if (
    previousParsedProductReview?.rollup &&
    productId === previousParsedProductReview.productId
  ) {
    rollup = previousParsedProductReview.rollup;
  }
  return {
    productId,
    score: rollup.averageRating,
    reviewsCount: rollup.reviewCount,
    recommendationsCount: +(rollup.recommendedRatio * 100).toFixed(),
    ratingDistribution: getReviewRatingDistribution(rollup),
    pros: getReviewPros(rollup, filters),
    cons: getReviewCons(rollup, filters),
    sizing: getReviewSizing(rollup),
    sleevelength: getReviewSleevelength(rollup),
    chestsize: getReviewChestsize(rollup),
    width: getReviewWidth(rollup),
    tags: getProductReviewTags(rollup, filters),
    mostLikedPositive: getMappedFaceoffReview(rollup, 'positive'),
    mostLikedNegative: getMappedFaceoffReview(rollup, 'negative'),
    reviews: getMappedProductReviews(
      reviews,
      locale,
      localeMapping,
      votedProductReviewIds
    ),
    sorting: sorting || null,
    pagination: paging,
    filters: filters,
    rollup,
  };
};
