import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import iframeCommunicator from '../helpers/iframeCommunicator';

const EventBus = new Vue();

Vue.use(iframeCommunicator({ $eventBus: EventBus }));

const iframePlugin: Plugin = (_, inject) => {
  // The Nuxt inject function installs a Vue plugin with the following code:
  //
  // if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
  //   Object.defineProperty(Vue.prototype, key, {
  //     get () {
  //       return this.$root.$options[key]
  //     }
  //   })
  // }
  //
  // This means that attempting to overwrite a key on the Vue prototype leads to an error.
  // Unfortunately, this is something that the Customs UMD library does try to do
  //
  // This worked when the library was installed as a plugin, as the installation order
  // could be used to make sure that the customs library attaches its eventBus first;
  // this way the Nuxt code would skip its own logic and always serve the customs event bus.
  //
  // However, now that the customs library is imported at runtime, it will always attempt
  // to replace the already injected event bus, crashing the application.
  //
  // The below is a hack to have Nuxt skip its own injection logic, and to allow the customs
  // library to replace the existing event bus safely.
  Vue.prototype.$eventBus = EventBus;

  inject('eventBus', EventBus);
};

export default iframePlugin;
