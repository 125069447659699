import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { apiClientFactory, CartLineItem, Product } from '@vf/api-client';
import { ref, Ref, computed } from '@nuxtjs/composition-api';
import { useCart } from '../useCart';
import useUrl from '../useUrl';
import { useAuthentication } from '../useAuthentication';
import { useRequestTracker } from '../useRequestTracker';
import { errorMessages } from '../utils/errorMessages';
import { SaveForLaterItem } from '@vf/api-contract';
import { getProductListWithDeletedItems } from '../utils/getProductListWithDeletedItems';
import { useCartStore } from '../store/cartStore';

type ItemDeletedFromSavedForLaterProductsList = {
  index: Ref<SaveForLater>;
  product: Ref<CartLineItem>;
};

type UseSaveForLaterStorage = {
  saveForLaterRef: Ref<SaveForLater>;
  isSaveForLaterProductToUpdate: Ref<boolean>;
  deletedFromSavedForLaterProductsList: Ref<
    ItemDeletedFromSavedForLaterProductsList[]
  >;
  loading: Ref<boolean>;
};
export interface SaveForLater {
  items: SaveForLaterItem[];
  count: number;
}

type UpdateItemObject = {
  itemId: string;
  productId: string;
  productImageURL?: string;
  qty: number;
  pdpUrl: string;
  available: string;
  recipeId: string;
} & Product;

export const useSaveForLater = (instance: ComponentInstance) => {
  const {
    saveForLater: saveForLaterAPI,
    getSaveForLater: getSaveForLaterAPI,
    updateSaveForLater: updateSaveForLaterAPI,
    removeSaveForLater: removeSaveForLaterAPI,
  } = apiClientFactory(instance);
  const storage: ComposablesStorage<UseSaveForLaterStorage> = initStorage<UseSaveForLaterStorage>(
    instance,
    'useSaveForLater'
  );

  const deletedFromSavedForLaterProductsList: Ref<
    ItemDeletedFromSavedForLaterProductsList[]
  > =
    storage.get('deletedFromSavedForLaterProductsList') ??
    storage.save('deletedFromSavedForLaterProductsList', ref([]));

  const saveForLaterRef: Ref<SaveForLater> =
    storage.get('saveForLaterRef') ??
    storage.save('saveForLaterRef', ref({ items: [], count: 0 }));

  const isSaveForLaterProductToUpdate: Ref<boolean> =
    storage.get('isSaveForLaterProductToUpdate') ??
    storage.save('isSaveForLaterProductToUpdate', ref());

  const loading: UseSaveForLaterStorage['loading'] =
    storage.get('loading') ?? storage.save('loading', ref(false));

  const { cartId, loadCart, addItem, buildItemPDPDetails } = useCart(instance);
  const { getRelativeUrl } = useUrl(instance);
  const {
    getConsumerId,
    setSaveForLaterId,
    saveForLaterId,
  } = useAuthentication(instance);
  const { displayErrorMessages } = errorMessages(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const addSaveForLater = async (
    product: CartLineItem,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    useCartStore().clearINV408Flashes();
    const { tag } = trackRequest(
      'useSaveForLater-addSaveForLater',
      isBackgroundRequest
    );

    try {
      loading.value = true;
      await saveForLaterAPI({
        cartId: cartId.value,
        productId: product.productId,
        recipeId: product.recipeId,
        qty: product.qty,
        type: 'product',
        variants: product.variants,
        pdpUrl: product.pdpUrl,
        productImageURL: product.image,
        masterId: product.masterId,
        defaultProductView: '',
        available: product.available || '',
        saveForLater: {
          saveForLaterId: saveForLaterId.value || '',
          itemId: product.id,
          consumerID: getConsumerId.value,
        },
      });
      await Promise.all([
        loadCart({
          isBackgroundRequest: false,
          isTemporary: false,
          inventorySupplyCheck: true,
        }),
        getSaveForLaterItems(),
      ]);
      return true;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    } finally {
      loading.value = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const getSaveForLaterItems = async (
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useSaveForLater-getSaveForLaterItems',
      isBackgroundRequest
    );
    try {
      const {
        data: { saveForLater },
      } = await getSaveForLaterAPI();
      saveForLaterRef.value = {
        items: (saveForLater?.[0]?.items || []).map((item) => ({
          ...item,
          pdpUrlRelative: getRelativeUrl(item.pdpUrl),
        })),
        count: saveForLater?.[0]?.count || 0,
      };
      setSaveForLaterId(saveForLater?.[0]?.saveForLaterId);
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const updateSaveForLater = async (
    updateItemObject: Partial<UpdateItemObject>,
    oldItemId?: string,
    oldItemQty?: number,
    isSaveForLaterObj: boolean = true,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    useCartStore().clearINV408Flashes();
    const { tag } = trackRequest(
      'useSaveForLater-updateSaveForLater',
      isBackgroundRequest
    );
    try {
      const details = await buildItemPDPDetails({
        ...updateItemObject,
        saveForLater: isSaveForLaterObj,
      });
      const productImageURL =
        updateItemObject.productImageURL || details.productImageURL;
      const pdpUrl = updateItemObject.pdpUrl || details.pdpUrl;
      await updateSaveForLaterAPI({
        productId: updateItemObject.sku || updateItemObject.productId,
        recipeId: updateItemObject.recipeId || '',
        qty: oldItemQty || updateItemObject.quantity?.value,
        pdpUrl,
        productImageURL,
        type: 'product',
        defaultProductView: '',
        available: updateItemObject.available || '',
        saveForLater: {
          saveForLaterId: saveForLaterId.value,
          itemId: updateItemObject.itemId || oldItemId,
          consumerID: getConsumerId.value,
        },
      });
      await getSaveForLaterItems();
      return true;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  // TODO: Remove in GLOBAL15-56318
  const clearDeletedSFLproducts = (delay: number) =>
    setTimeout(() => {
      deletedFromSavedForLaterProductsList.value = [];
    }, delay);

  const removeSaveForLater = async (
    productId: string,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useSaveForLater-removeSaveForLater',
      isBackgroundRequest
    );
    try {
      loading.value = true;
      await removeSaveForLaterAPI({
        saveForLaterId: saveForLaterId.value,
        consumerId: getConsumerId.value,
        itemId: productId,
      });
      await getSaveForLaterItems();
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      loading.value = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };
  const moveSaveForLaterToCart = async (product: CartLineItem) => {
    try {
      loading.value = true;
      const color = product.variants.find((v) => v.code === 'color').id;
      const result = await addItem(
        {
          ...product,
          colors: [{ value: color }],
          saveForLater: {
            saveForLaterId: saveForLaterId.value,
            consumerId: getConsumerId.value,
            itemId: product.itemId,
          },
        },
        { isTemporary: false }
      );
      if (result) await getSaveForLaterItems();
      return result;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    } finally {
      loading.value = false;
    }
  };

  const savedForLaterProducts = computed(() => {
    return saveForLaterRef.value.items;
  });

  // TODO: Remove in GLOBAL15-56318
  const savedForLaterProductsWithDeletedItems = computed<
    Array<SaveForLaterItem | CartLineItem>
  >(() => {
    return getProductListWithDeletedItems(
      savedForLaterProducts.value,
      deletedFromSavedForLaterProductsList.value
    );
  });

  // TODO: Remove in GLOBAL15-56318
  const updateSavedForLaterProductsList = ({ index, product }) => {
    deletedFromSavedForLaterProductsList.value.push({
      index,
      product: { ...product, isRemovedFromSaveForLater: true },
    });
  };

  return {
    saveForLater: saveForLaterRef,
    addSaveForLater,
    getSaveForLaterItems,
    removeSaveForLater,
    updateSaveForLater,
    setSaveForLaterId,
    moveSaveForLaterToCart,
    isSaveForLaterProductToUpdate,
    deletedFromSavedForLaterProductsList,
    savedForLaterProducts,
    savedForLaterProductsWithDeletedItems,
    updateSavedForLaterProductsList,
    loading: computed(() => loading.value),
    clearDeletedSFLproducts,
  };
};
