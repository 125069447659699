import {
  CmsBottomBar,
  CmsCta,
  CmsFooter,
  CmsFooterColumns,
  CmsSiteLogo,
  CmsMenu,
  CmsSite,
  CmsSocialMedia,
  CmsStoreLocator,
  CmsSubscribe,
  CMTeaser,
} from '@vf/api-contract';
import {
  extractCommonConfigLink,
  extractProperty,
  getTeaserStyle,
  getTitle,
  generateLinkFromTeaserTargets,
  getComponentConfigByName,
  getDynamicField,
  generateLinkFromTarget,
  getImageObject,
  getTranslation,
} from './utils';
import { ComposableContext } from '../types';
import {
  getDataNavigationValue,
  getStateManagementData,
  getSegmentsData,
} from './utils';
import { ROUTES } from '../../utils/routes';
import { useI18n } from '../../useI18n';

export const footer = (
  data: CmsFooter,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  menu: CmsMenu,
  cmsBaseUri: string
) => {
  const footerColumns = getColumnsData(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const footerLinks = getSocialMediaData(data, context);
  const footerBottomBar = getBottomBarData(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const footerStoreLocator = getStoreLocatorData(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const footerSubscribe = getSubscribeData(data, context);
  const footerBackToTop = getBackToTopData(data, context);
  const footerCta = getCtaData(data, context);
  const footerLogo = getLogoData(data, context, cmsBaseUri);
  const footerLocales = getLocales(context, siteConfiguration);
  return {
    component: 'layout/Footer',
    _cms_id: 'base-footer',
    props: {
      bottomBar: footerBottomBar,
      column: footerColumns.length,
      columns: footerColumns,
      locales: footerLocales,
      multiple: '',
      logo: footerLogo,
      cta: footerCta,
      socialMedia: footerLinks,
      storeLocator: footerStoreLocator,
      subscribe: footerSubscribe,
      backToTop: footerBackToTop,
    },
  };
};

const generateLink = (link, context, siteConfiguration, cmsBaseUri) => {
  const hasTeaserTargets = extractProperty(link, 'teaserTargets[0]');
  return hasTeaserTargets
    ? generateLinkFromTeaserTargets(
        link.teaserTargets,
        context,
        siteConfiguration,
        link.urlParams,
        cmsBaseUri
      )
    : generateLinkFromTarget(link, context, siteConfiguration, cmsBaseUri);
};

const getColumnsData = (
  data: CmsFooter,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const columns = <CmsFooterColumns>(
    data.find((item) => item.viewtype === 'footer-columns-set')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(columns)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(columns)
  );

  if (!columns || !stateFulfilled || !segmentsFulfilled) {
    return [];
  }

  const isMenuItemOfType = (item: CMTeaser, type: 'link' | 'contact') => {
    switch (type) {
      case 'contact':
        return item.viewtype === 'footer-itemWithText';

      case 'link':
        return item.viewtype !== 'footer-itemWithText';
    }
  };

  const getCommerRefTitle = (link: any) => {
    const linkTT = link?.teaserTargets;

    if (link?.type !== 'CMExternalChannel') return null;
    return linkTT && linkTT[0]?.target?.commerceRef?.title;
  };

  return columns.teasableItems.map((item) => {
    return {
      id: item.id,
      title: getTitle(item),
      name: item.name,
      titleColor: getTeaserStyle(item, 'teaserTitleColor'),
      classModifiers: getTeaserStyle(item, 'teaserClassModifiers'),
      stateFulfilled:
        context.instance.$root.$stateManagementChecker(
          getStateManagementData(item)
        ) && context.instance.$root.$segmentsChecker(getSegmentsData(item)),
      items: item.teasableItems.reduce((links, link) => {
        if (!isMenuItemOfType(link, 'link')) {
          return links;
        }

        const element = {
          id: link.id,
          title: getCommerRefTitle(link) || getTitle(link),
          name: link.name,
          dataNavigation: getDataNavigationValue(link),
          titleColor: getTeaserStyle(link, 'teaserTitleColor'),
          titleClassModifiers: getTeaserStyle(
            link,
            'teaserTitleClassModifiers'
          ),
          icon: getDynamicField(link, 'icon', null),
          fontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
          fontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
          link: generateLink(link, context, siteConfiguration, cmsBaseUri),
          fontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
          openInNewTab: extractProperty(link, 'openInNewTab'),
          openInNewModal: extractProperty(link, 'openInNewModal'),
          states: getStateManagementData(link),
          segments: getSegmentsData(link),
          image:
            link.viewtype === 'image'
              ? getImageObject(
                  extractProperty(link, 'pictures[0]'),
                  siteConfiguration,
                  null,
                  cmsBaseUri
                )
              : null,
          role: link.viewtype?.includes('chat') ? 'chat' : '',
        };

        return [...links, element];
      }, []),

      sections: item.teasableItems.reduce((sections, section) => {
        if (isMenuItemOfType(section, 'contact')) {
          const hasLink = extractProperty(section, 'teaserTargets[0]');

          const titleColor = getTeaserStyle(section, 'teaserTitleColor');
          const titleClassModifiers = getTeaserStyle(
            section,
            'teaserTitleClassModifiers'
          );

          sections.push({
            id: section.id,
            title: section.teaserTitle,
            name: section.name,
            html: hasLink
              ? extractProperty(
                  section,
                  'teaserTargets[0].callToActionText',
                  section.teaserTitle
                )
              : section.teaserText,
            link: hasLink
              ? generateLinkFromTeaserTargets(
                  section.teaserTargets,
                  context,
                  siteConfiguration,
                  section.urlParams,
                  cmsBaseUri
                )
              : null,
            openInNewTab: extractProperty(section, 'openInNewTab'),
            openInNewModal: extractProperty(section, 'openInNewModal'),
            dataNavigation: getDataNavigationValue(section),
            titleColor,
            titleClassModifiers,
            smallFont: false,
            stateFulfilled:
              context.instance.$root.$stateManagementChecker(
                getStateManagementData(section)
              ) &&
              context.instance.$root.$segmentsChecker(getSegmentsData(section)),
          });
        }

        return sections;
      }, []),
    };
  });
};

const getSocialMediaData = (data: CmsFooter, context: ComposableContext) => {
  const links = <CmsSocialMedia>(
    data.find((item) => item.viewtype === 'footer-follow-vf')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(links)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(links)
  );

  if (!links || !stateFulfilled || !segmentsFulfilled) {
    return { items: [] };
  }

  return {
    id: links.id,
    title: links.teaserTitle,
    titleColor: getTeaserStyle(links, 'teaserTitleColor'),
    classModifiers: getTeaserStyle(links, 'teaserClassModifiers'),
    items: links.teasableItems.map((link) => ({
      id: link.id,
      title: link.teaserTitle,
      iconColor: getTeaserStyle(link, 'teaserTextColor'),
      classModifiers: getTeaserStyle(link, 'teaserTextClassModifiers'),
      url: link.url,
      dataNavigation: getDataNavigationValue(link),
      openInNewTab: extractProperty(link, 'openInNewTab'),
      openInNewModal: extractProperty(link, 'openInNewModal'),
      ariaLabel: extractProperty(link, 'callToActionCustomText'),
      stateFulfilled:
        context.instance.$root.$stateManagementChecker(
          getStateManagementData(link)
        ) && context.instance.$root.$segmentsChecker(getSegmentsData(link)),
    })),
  };
};

const getBottomBarData = (
  data: CmsFooter,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const bottomBar = <CmsBottomBar>(
    data.find((item) => item.viewtype === 'footer-bottomBar')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(bottomBar)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(bottomBar)
  );

  if (!bottomBar || !stateFulfilled || !segmentsFulfilled) {
    return {};
  }

  const bottomLinks =
    bottomBar.type === 'CMTeaser'
      ? extractProperty(bottomBar, 'teaserTargets', []).map((item) => ({
          text: item.callToActionText,
          link: generateLink(
            item.target,
            context,
            siteConfiguration,
            cmsBaseUri
          ),
          icon: getDynamicField(item.target, 'icon'),
          iconSize: getDynamicField(item.target, 'iconSize'),
        }))
      : [];

  return {
    id: bottomBar.id,
    title: bottomBar.teaserTitle,
    textColor: getTeaserStyle(bottomBar, 'teaserTextColor'),
    classModifiers: getTeaserStyle(bottomBar, 'teaserClassModifiers'),
    fontSize: getTeaserStyle(bottomBar, 'teaserTitleFontSize'),
    fontWeight: getTeaserStyle(bottomBar, 'teaserTitleFontWeight'),
    fontFamily: getTeaserStyle(bottomBar, 'teaserTitleFontFace'),
    linkText:
      bottomBar.type === 'CMTeaser'
        ? extractProperty(bottomBar, 'teaserTargets[0].callToActionText', '')
        : '',
    bottomLinks,
    link: generateLinkFromTeaserTargets(
      bottomBar.teaserTargets,
      context,
      siteConfiguration,
      bottomBar.urlParams,
      cmsBaseUri
    ),
    dataNavigation: getDataNavigationValue(bottomBar),
    openInNewTab: extractProperty(bottomBar, 'openInNewTab'),
    openInNewModal: extractProperty(bottomBar, 'openInNewModal'),
    translations: {
      countrySelectLabel: getTranslation(
        bottomBar,
        'countrySelectLabel',
        '$Country$'
      ),
    },
  };
};

const getStoreLocatorData = (
  data: CmsFooter,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const storeLocator = <CmsStoreLocator>(
    data.find((item) => item.viewtype === 'footer-store-locator')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(storeLocator)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(storeLocator)
  );

  if (!storeLocator || !stateFulfilled || !segmentsFulfilled) {
    return null;
  }
  return {
    id: storeLocator.id,
    title: storeLocator.teaserTitle,
    titleColor: getTeaserStyle(storeLocator, 'teaserTitleColor'),
    titleClassModifiers: getTeaserStyle(
      storeLocator,
      'teaserTitleClassModifiers'
    ),
    fontSize: getTeaserStyle(storeLocator, 'teaserTitleFontSize'),
    fontWeight: getTeaserStyle(storeLocator, 'teaserTitleFontWeight'),
    fontFamily: getTeaserStyle(storeLocator, 'teaserTitleFontFace'),
    classModifiers: getTeaserStyle(storeLocator, 'teaserClassModifiers'),
    helperText: storeLocator.teaserText,
    textColor: getTeaserStyle(storeLocator, 'teaserTextColor'),
    link: generateLinkFromTeaserTargets(
      storeLocator.teaserTargets,
      context,
      siteConfiguration,
      storeLocator.urlParams,
      cmsBaseUri
    ),
    dataNavigation: getDataNavigationValue(storeLocator),
    buttonText:
      storeLocator.type === 'CMTeaser'
        ? extractProperty(
            storeLocator,
            'teaserTargets[0].callToActionText',
            ''
          ) ||
          extractProperty(
            storeLocator,
            'teaserTargets[0].target.teaserTitle',
            ''
          )
        : '',
  };
};

const getCtaData = (data: CmsFooter, context: ComposableContext) => {
  const cta = <CmsCta>(
    data.find((item) => item.viewtype === 'footer-cta' && item.name === 'cta')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(cta)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(cta)
  );

  if (!cta || !stateFulfilled || !segmentsFulfilled) {
    return {};
  }
  return {
    id: cta.id,
    linkText: cta.teaserTitle,
    number: cta.teaserText,
  };
};

const getLogoData = (
  data: CmsFooter,
  context: ComposableContext,
  cmsBaseUri: string
) => {
  const { localePath } = useI18n(context.instance);

  const logo = <CmsSiteLogo>data.find((item) => item.viewtype === 'site-logo');

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(logo)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(logo)
  );

  if (
    !logo ||
    !logo.pictures ||
    !logo.pictures[0] ||
    !stateFulfilled ||
    !segmentsFulfilled
  ) {
    return {};
  }

  const pictureObject = logo.pictures[0];
  const url = extractProperty(logo.pictures[0], 'data.uri');

  return {
    id: logo.id,
    link: localePath(ROUTES.HOME()),
    url: {
      large: pictureObject.forcedAbsoluteUrl ? url : `${cmsBaseUri}${url}`,
      medium: pictureObject.forcedAbsoluteUrl ? url : `${cmsBaseUri}${url}`,
      small: pictureObject.forcedAbsoluteUrl ? url : `${cmsBaseUri}${url}`,
    },
    ariaLabel: logo.name,
    width: pictureObject.width,
    height: pictureObject.height,
  };
};

const getSubscribeData = (data: CmsFooter, context: ComposableContext) => {
  const subscribe = <CmsSubscribe>(
    data.find((item) => item.viewtype === 'footer-subscribe')
  );

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(subscribe)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(subscribe)
  );

  const config = getComponentConfigByName(
    subscribe,
    'footer-subscribe-properties',
    {}
  );

  if (!subscribe || !stateFulfilled || !segmentsFulfilled) {
    return null;
  }
  return {
    id: extractProperty(subscribe, 'id'),
    classModifiers: getTeaserStyle(subscribe, 'teaserClassModifiers'),
    title: subscribe.teaserTitle,
    titleColor: getTeaserStyle(subscribe, 'teaserTitleColor'),
    titleClassModifiers: getTeaserStyle(subscribe, 'teaserTitleClassModifiers'),
    textColor: getTeaserStyle(subscribe, 'teaserTextColor'),
    fontSize: getTeaserStyle(subscribe, 'teaserTitleFontSize'),
    fontWeight: getTeaserStyle(subscribe, 'teaserTitleFontWeight'),
    fontFamily: getTeaserStyle(subscribe, 'teaserTitleFontFace'),
    helperText: subscribe.teaserRichText,
    translations: {
      emailPlaceholder: extractProperty(
        subscribe,
        'resourceBundle.common.emailPlaceholder',
        '$Type your email address$'
      ),
      sendButtonText: extractProperty(
        subscribe,
        'resourceBundle.common.sendButtonText',
        '$Send$'
      ),
      emailLabel: extractProperty(
        subscribe,
        'resourceBundle.common.emailLabel',
        '$Subscription email address$'
      ),
      legalTermText: extractProperty(
        config,
        'legal-term-message',
        '$I agree to {link-1}$'
      ),
      linkOne: extractProperty(
        config,
        'link-1',
        '$Privacy Policy|https://thelink.com$'
      ),
      linkTwo: extractProperty(
        config,
        'link-2',
        '$Terms of Use|https://thelink.com$'
      ),
      privacyError: extractProperty(
        subscribe,
        'resourceBundle.common.required',
        '$*Required$'
      ),
    },
    validationMessages: extractProperty(
      subscribe,
      'localSettings.dynamicFields'
    ),
    showPrivacy: extractProperty(config, 'show-legal-terms-agreement', false),
    useIconForButton: getDynamicField(subscribe, 'use-icon-for-button', false),
  };
};

const getBackToTopData = (data: CmsFooter, context: ComposableContext) => {
  const backToTop = data.find((item) => item.viewtype === 'footer-backToTop');

  const stateFulfilled = context.instance.$root.$stateManagementChecker(
    getStateManagementData(backToTop)
  );

  const segmentsFulfilled = context.instance.$root.$segmentsChecker(
    getSegmentsData(backToTop)
  );

  if (!backToTop || !stateFulfilled || !segmentsFulfilled) {
    return null;
  }
  return {
    id: backToTop.id,
    title: backToTop.teaserTitle,
    titleColor: getTeaserStyle(backToTop, 'teaserTitleColor'),
    titleClassModifiers: getTeaserStyle(backToTop, 'teaserTitleClassModifiers'),
    fontSize: getTeaserStyle(backToTop, 'teaserTitleFontSize'),
    fontWeight: getTeaserStyle(backToTop, 'teaserTitleFontWeight'),
    fontFamily: getTeaserStyle(backToTop, 'teaserTitleFontFace'),
  };
};

const getLocales = (context: ComposableContext, siteConfiguration: CmsSite) => {
  const languageSelectorSites = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'language-selector-sites'),
    '[0].settings.sites',
    {}
  );

  if (!languageSelectorSites || !languageSelectorSites.length) {
    context.instance.$log.warn(
      'composables/useCms/mapping/footer Configuration for Site Language/Country Selector not found.'
    );
    return [];
  }

  return languageSelectorSites;
};
