import { CMComponent, VFConfigComponent } from '@vf/api-contract';

export const getTitle = (
  component: Exclude<CMComponent, VFConfigComponent>
): string => {
  switch (component.type) {
    case 'CMCollection':
    case 'CMTeaser':
    case 'CMExternalLink':
      return component.teaserTitle;

    case 'CMExternalChannel':
    case 'CMPlaceholder':
      return component.title;

    default:
      return component.teaserTitle || component.title || '';
  }
};
