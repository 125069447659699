import { getTranslation, getDynamicField } from './utils/extractProperty';
import { CMPlaceholder } from '@vf/api-contract';
import { getGiftOptionTranslations } from './utils/getGiftOptionTranslations';

export const orderProducts = (data: CMPlaceholder) => {
  return {
    component: 'confirmation/OrderSuccessProductList',
    props: {
      translations: {
        ...getGiftOptionTranslations(data),
        itemDescription: getTranslation(
          data,
          'itemDescription',
          '$Item Description$'
        ),
        quantity: getTranslation(data, 'quantity', '$Quantity$'),
        price: getTranslation(data, 'price', '$Price$'),
        total: getTranslation(data, 'total', '$Total$'),
        color: getTranslation(data, 'color', '$Color$'),
        size: getTranslation(data, 'size', '$Size$'),
        length: getTranslation(data, 'length', '$Length$'),
        qty: getTranslation(data, 'qtyLabel', '$Qty$'),
        shippingTimes: getTranslation(
          data,
          'shippingTimes',
          '$Shipping times may vary for each custom product types.$'
        ),
        shippingDetailsButton: getTranslation(
          data,
          'shippingDetailsButton',
          '$View Shipping Details$'
        ),
        customsTooltipMessages: getTranslation(data, 'customsTooltipMessages', [
          '$Arrival based on order date:$',
          '$Customs footwear (non-image) 2-3 weeks$',
          '$Upload Your Own customs footwear: 3 - 5 weeks$',
          '$Skate Classic customs footwear: 3 - 5 weeks$',
          '$ComfyCush custom footwear: 3 - 4 weeks$',
          '$UltraRange EXO custom footwear: 4 - 6 weeks$',
        ]),
        itemPromoAppliedLabel: getTranslation(
          data,
          'itemPromoAppliedLabel',
          '$PROMOTIONS APPLIED:$'
        ),
        ariaLabel: getTranslation(data, 'ariaLabel', '$Shopping Cart$'),
        pickupFromLabel: getTranslation(data, 'pickupFromLabel', '$Pickup$'),
        trackPackageLabel: getTranslation(
          data,
          'trackPackageLabel',
          '$Shipment: Tracking$'
        ),
        shipmentSummaryArrival: getTranslation(
          data,
          'shipmentSummaryArrival',
          '$Shipment #{{number}} Below:$'
        ),
        notificationText: getTranslation(
          data,
          'weWillNotify',
          '$We will notify you$'
        ),
        free: getTranslation(data, 'free', '$Free$'),
        giftItemReturnNotAvailable: getTranslation(
          data,
          'giftItemReturnNotAvailable',
          '$Not eligible for Returns.$'
        ),
      },
      showShipmentLabel: getDynamicField(data, 'showShipmentLabel', false),
    },
  };
};
