































import { defineComponent } from '@vue/composition-api';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SimplifiedEnrollmentToast',
  setup() {
    const { root } = useRootInstance();
    const {
      deactivate,
      activateProgressiveProfile,
    } = useSimplifiedEnrollmentStore(root);

    return {
      deactivate,
      activateProgressiveProfile,
    };
  },
});
