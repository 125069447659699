import { getComponentConfigByName } from '../mappings/utils';
import { CmsPageContent } from '@vf/api-contract';

export interface CmsHeaderConfig {
  backgroundColor: string;
  isAnimated: boolean;
  isFadeEnabled: boolean;
  isSticky: boolean;
}

export const getHeaderConfig = (content: CmsPageContent): CmsHeaderConfig => {
  return getComponentConfigByName(content, 'header-settings', {
    backgroundColor: '#FFF',
    isAnimated: false,
    isFadeEnabled: false,
    isSticky: true,
  });
};
