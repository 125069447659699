import Vue from 'vue';

if (inCoreMediaStudioIframe() || inPreviewPage()) {
  import('vue-axe').then(({ default: VueAxe }) => {
    Vue.use(VueAxe);
  });
}

function inCoreMediaStudioIframe() {
  try {
    const cmIframes = document.querySelectorAll(
      'iframe.cm-preview-device__iframe'
    );
    return window.self !== window.top && cmIframes.length;
  } catch (e) {
    return false;
  }
}

function inPreviewPage() {
  try {
    const previewParameter = 'preview';
    const url = window.location.href;
    if (url.indexOf('?' + previewParameter) != -1) {
      return true;
    } else if (url.indexOf('&' + previewParameter) != -1) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}
