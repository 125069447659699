import { render, staticRenderFns } from "./Organism.HomepagePlaceholder.vue?vue&type=template&id=bee3514a&"
import script from "./Organism.HomepagePlaceholder.vue?vue&type=script&lang=js&"
export * from "./Organism.HomepagePlaceholder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports