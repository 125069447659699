import { FormatWrap, TransformableInfo } from 'logform';
import { createLogger, transports, format } from 'winston';

const { combine, timestamp, logstash } = format;

const enabledChannels: string[] =
  process.env.LOGGER_ENABLED_CHANNELS?.split?.(',') || [];
const allowedChannels: string[] =
  process.env.LOGGER_ALLOWED_CHANNELS?.split?.(',') || [];

const filterChannels: FormatWrap = format((info: TransformableInfo) => {
  const isAllowed = allowedChannels.includes(info.channel);
  const isEnabled = enabledChannels.includes(info.channel);
  const skipChannels = !allowedChannels.length;

  if (skipChannels || (isAllowed && isEnabled)) return info;
});

const errorParser: FormatWrap = format((info: TransformableInfo) => ({
  ...info,
  message: (info.message as any).stack || info.message,
}));

const formatter = combine(
  filterChannels(),
  timestamp(),
  errorParser(),
  logstash()
);

export const logger = createLogger({
  transports: [
    new transports.Console({
      format: formatter,
      level: process.env.LOGGER_LEVEL || 'debug',
    }),
  ],
});
