export default async function ({ app }, inject) {
  const theme = app.$env.THEME;
  const countryCode = app.$getEnvValueByCurrentLocale('COUNTRY');

  const fileName = `${theme}-${countryCode}`;

  const settings = await import(
    `../settings/${fileName}` /* webpackChunkName: "theme-settings-[request]" */
  );

  inject('themeSettings', settings?.settings || {});
}
