import { getDynamicField, getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const shoeSizeSelector = (data: CMPlaceholder) => {
  const variant: string = getDynamicField(data, 'variant', '$nora$');
  return {
    component: 'shared/ShoeSizeSelector',
    props: {
      translations: {
        label: getTranslation(data, 'selectSize', '$Select Size$'),
        placeholder: getTranslation(data, 'selectSize', '$Select Size$'),
        mensText: getTranslation(data, 'mens', '$Mens$'),
        womensText: getTranslation(data, 'womens', '$Womens$'),
        displayFormat: getTranslation(
          data,
          `displayFormat[${variant}]`,
          '${{gender}} - {{size}}$'
        ),
      },
    },
  };
};
