import { CMCollection, CmsSite } from '@vf/api-contract';
import { extractProperty, getDynamicField } from './utils';
import { ComposableContext, CmsSanitizationRequirements } from '../types';
import * as mappings from '../mappings';
import { camelCase } from '../dataTransformators/utils/camelCase';

const NO_CAMPAIGN_KEY = 'default';

export const campaign = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const campaigns = data.teasableItems.reduce((acc, item) => {
    if (!item.viewtype && !item.campaignId) {
      console.warn(
        `There is no mapping for this campaign component view-type: ${item.viewtype} | id: ${item.id} | name: ${item.name}`
      );
    }

    const viewType = camelCase(item.viewtype);
    const mappingMethod = mappings[viewType];

    if (mappingMethod) {
      acc[item.campaignId || NO_CAMPAIGN_KEY] = mappingMethod(
        item,
        context,
        siteConfiguration,
        cmsBaseUri,
        requirements
      );
    }

    return acc;
  }, {} as any);

  return {
    component: 'shared/Campaign',
    props: {
      campaigns,
      campaignIdQueryParamName: getDynamicField(
        data,
        'campaignIdQueryParamName',
        'loyaltyCampaignId'
      ),
      campaignId:
        extractProperty(data, 'localSettings.campaignId', NO_CAMPAIGN_KEY) ||
        NO_CAMPAIGN_KEY,
    },
  };
};
