import { getTranslation } from './utils/extractProperty';
import { CMPlaceholder } from '@vf/api-contract';

export const breadcrumbs = (data: CMPlaceholder) => {
  return {
    component: 'common/Breadcrumbs',
    props: {
      translations: {
        shopAll: getTranslation(data, 'shopAll', '$Shop all$'),
      },
    },
  };
};
