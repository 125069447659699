import type { TokenizedCreditCard } from '@vf/api-contract';
import type { ComponentInstance } from '../types';

import { reactive, ref } from '@vue/composition-api';
import {
  load as loadScript,
  unload as unloadScript,
} from '@vf/shared/src/utils/helpers/load-script';
import { useCyberSourceStore } from '../store/cyberSourceStore';
import { usePaymentStore } from '../store/payment';
import { useRequestTracker } from '../useRequestTracker';
import useCyberSource from '../useCyberSource';
import { storeToRefs } from 'pinia';
import useValidators from '../useValidators';
import {
  validateCreditCardExpiration,
  validateRequired,
} from '../useValidators/validators';
import { useCardStore } from '../store/card';

declare class Flex {
  constructor(arg: unknown);

  public microform(arg: unknown);
}

const flexMicroformUrl =
  'https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js';

export const useCyberSourcePaymentProvider = (instance: ComponentInstance) => {
  const {
    initializeCCForm,
    isCyberSourceLoaded,
    setIsCyberSourceLoaded,
    prepareCSData,
    showSecurityNumberSuccess,
    showCreditCardNumberSuccess,
  } = useCyberSource(instance);
  const cyberSourceStore = useCyberSourceStore();
  const {
    captureContext,
    cardErrors,
    expirationDate,
    formInstance,
    microformToken,
    sessionID,
  } = storeToRefs(cyberSourceStore);
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const paymentStore = usePaymentStore(instance);
  const cardStore = useCardStore();

  const getValidationText = (scope: string) =>
    instance.$t(`creditCardMicroform.validation_messages.${scope}`) as string;

  const formState = reactive({
    cardNumber: null,
    securityCode: null,
    isCCValid: false,
    isSecurityCodeValid: false,
  });

  function resetState() {
    formState.cardNumber = null;
    formState.securityCode = null;
    formState.isCCValid = false;
    formState.isSecurityCodeValid = false;
    expirationDate.value = '';
  }

  // Validation rules for credit card form fields
  const { validate, validationFields } = useValidators(
    () => ({
      ...formState,
      expirationDate: expirationDate.value,
    }),
    ref({
      cardNumber: [
        validateRequired(getValidationText('required')),
        // Validate Card Number from CyberSource
        () => {
          const showNumberError = cardErrors.value?.some(
            (element) => element.location === 'number'
          );
          if (showNumberError || !formState.isCCValid)
            return getValidationText('cardNumber');
        },
      ],
      expirationDate: [
        validateRequired(getValidationText('required')),
        validateCreditCardExpiration(getValidationText('dateFormat')),
      ],
      securityCode: [
        validateRequired(getValidationText('required')),
        validateRequired(getValidationText('securityCodeFormat')),
        // Validate Security Code from CyberSource
        () => {
          const showCCVError = cardErrors.value?.some(
            (element) => element.location === 'securityCode'
          );
          if (showCCVError || !formState.isSecurityCodeValid)
            return getValidationText('securityCodeFormat');
        },
      ],
    })
  );

  async function load() {
    const { tag } = trackRequest('CreditCardForm-onLoadCyberSource');

    try {
      await loadScript(flexMicroformUrl, () => {
        setIsCyberSourceLoaded(true);
        captureContext.value = paymentStore.session.meta.captureContextKey;
      });
    } catch (err) {
      instance.$log.error('CyberSource script loading failed!', err);
    } finally {
      clearRequest(tag);
    }
  }

  async function unload() {
    if (isCyberSourceLoaded.value) {
      await unloadScript(flexMicroformUrl);
      setIsCyberSourceLoaded(false);
    }
  }

  async function render() {
    try {
      const flex = new Flex(captureContext.value);
      formInstance.value = flex.microform({
        styles: {
          input: {
            'font-size': getComputedStyle(document.body).getPropertyValue(
              '--font-base'
            ),
            'font-family': getComputedStyle(document.body).getPropertyValue(
              '--font-family-primary'
            ),
            color: getComputedStyle(document.body).getPropertyValue('--c-text'),
            'line-height': '73px',
          },
          ':disabled': { cursor: 'not-allowed' },
          invalid: {
            color: getComputedStyle(document.body).getPropertyValue(
              '--c-primary'
            ),
          },
        },
      });

      await initializeCCForm(
        formState,
        // Mimic vuelidate object for useCybersource composable backward compatibility
        ref({
          cardNumber: { $touch: () => validate('cardNumber') },
          expirationDate: { $touch: () => validate('expirationDate') },
          securityCode: { $touch: () => validate('securityCode') },
        } as any)
      );
    } catch (err) {
      instance.$log.error(
        '[@vf/composables/src/usePaymentProvider/cybersource.ts::render] Cannot initialize Cyber Source Form',
        err
      );
    }
  }

  async function getCardData(): Promise<TokenizedCreditCard> {
    await prepareCSData();
    return {
      cardType: cardStore.cardType,
      captureContextKey: captureContext.value,
      microformToken: microformToken.value,
      sessionID: sessionID.value,
    };
  }

  return {
    form: {
      expirationDate,
      validate,
      validationFields,
    },
    getAdditionalData: () => cyberSourceStore.getCyberSourcePayload(),
    getCardData,
    initService: async () => void 0,
    load,
    preparePaymentData: prepareCSData,
    render,
    showCreditCardNumberSuccess,
    showSecurityNumberSuccess,
    unload,
    unmount: () => resetState(),
    reset: () => {
      cyberSourceStore.setMicroformToken(null);
    },
  };
};
