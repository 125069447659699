import {
  CMComponent,
  CmsCategoryPageContent,
  CmsPageContent,
  CmsPlacement,
  CmsSite,
  PageType,
} from '@vf/api-contract';
import {
  ComposableContext,
  InitializedApiClient,
  PageLayoutName,
  PageTypeName,
} from '../types';
import useCategory from '../../useCategory';
import {
  extractProperty,
  getCanonicalUrl,
  getImageObject,
} from '../mappings/utils';

export const fetchPageContent = async (
  type: PageType,
  context: ComposableContext,
  cmsSiteConfig: CmsSite,
  apiClient: InitializedApiClient,
  pageId: string,
  path: string
) => {
  switch (type) {
    case 'category':
      return await getCategoryPageData(
        cmsSiteConfig.id,
        pageId,
        apiClient,
        context
      );

    case 'product':
      return await getProductPageData(cmsSiteConfig.id, pageId, apiClient);

    default:
      if (pageId) {
        return await getPageContentById(
          pageId,
          apiClient,
          context,
          cmsSiteConfig
        );
      }
      return await getPageContentByPath(
        path,
        apiClient,
        context,
        cmsSiteConfig
      );
  }
};

export const getModalContents = async (
  id: string,
  apiClient: InitializedApiClient
) => {
  try {
    const modalResponse = await apiClient.getFragment(id);

    const modalData = modalResponse.data.content.content;

    if (!modalData) {
      throw new Error(`Empty modal contents, modal data: ${modalData}`);
    }

    return {
      content: {
        grid: {
          rows: [
            {
              placements: [
                {
                  pageItems: [modalData],
                },
              ],
            },
          ],
        },
        locales: [],
      },
      errors: [],
    };
  } catch (e) {
    return {
      content: null,
      errors: [`HTTP Error while trying to get fragment data: ${e.message}`],
    };
  }
};

const getPageContentByPath = async (
  path: string,
  apiClient: InitializedApiClient,
  context: ComposableContext,
  cmsSiteConfig: CmsSite
) => {
  try {
    const { segment } = cmsSiteConfig.root;
    const pageDataResponse = await apiClient.getPageContentByPath(
      path,
      segment
    );

    const content = extractParentOfGridObject(pageDataResponse);

    return {
      content,
      pageLayoutName: extractPageLayoutName(pageDataResponse),
      pageTypeName: extractPageTypeName(content),
      pageProps: extractPageProps(pageDataResponse),
      meta: extractSeoMetadata(
        pageDataResponse,
        context,
        cmsSiteConfig,
        apiClient
      ),
      errors: [],
    };
  } catch (e) {
    return {
      content: null,
      pageTypeName: null,
      pageLayoutName: null,
      pageProps: {},
      meta: {},
      errors: [`HTTP Error while trying to get page data: ${e.message}`],
    };
  }
};

const getPageContentById = async (
  id: string,
  apiClient: InitializedApiClient,
  context: ComposableContext,
  cmsSiteConfig: CmsSite
) => {
  try {
    const pageDataResponse = await apiClient.getPageContent(id);

    const content = extractParentOfGridObject(pageDataResponse);

    return {
      content,
      pageLayoutName: extractPageLayoutName(pageDataResponse),
      pageTypeName: extractPageTypeName(content),
      pageProps: extractPageProps(pageDataResponse),
      meta: extractSeoMetadata(
        pageDataResponse,
        context,
        cmsSiteConfig,
        apiClient
      ),
      errors: [],
    };
  } catch (e) {
    return {
      content: null,
      pageTypeName: null,
      pageLayoutName: null,
      pageProps: {},
      meta: {},
      errors: [`HTTP Error while trying to get page data: ${e.message}`],
    };
  }
};

const extractPageLayoutName = (pageDataResponse: any) => {
  return pageDataResponse?.pagecontent?.templateName ?? '';
};

const extractPageProps = (pageDataResponse: any) => {
  if (!pageDataResponse?.pagecontent) {
    return {};
  }
  const {
    title,
    teaserRichTextObject,
    publicationDate,
  } = pageDataResponse.pagecontent;
  return {
    title,
    subtitle: teaserRichTextObject?.html ?? '',
    publicationDate,
  };
};

const extractSeoMetadata = (
  pageDataResponse: any,
  context,
  siteConfiguration,
  apiClient
) => {
  if (!pageDataResponse.pagecontent) return {};
  const {
    htmlTitle,
    htmlDescription,
    metaRobots,
    keywords,
    canonical,
    localizations,
    pictures,
    openGraph,
    twitterCards,
  } = pageDataResponse.pagecontent;

  const seoConfigs = {
    ogLocale: siteConfiguration?.locale,
  };
  const ogImage = getImageObject(
    openGraph?.ogImage?.[0],
    siteConfiguration,
    null,
    apiClient.settings.baseUri
  )?.small;

  const canonicalUrl = getCanonicalUrl(canonical, context, siteConfiguration);
  // OpenGraph Image tag logic - dataUrl is never nullish, but won't be rendered if empty string
  const picturesMeta = getImageObject(
    pictures && pictures[0],
    siteConfiguration,
    null,
    apiClient.settings.baseUri
  )?.small;

  return {
    htmlTitle,
    htmlDescription,
    metaRobots,
    keywords,
    canonicalUrl,
    localizations,
    picturesMeta,
    openGraph,
    seoConfigs,
    ogImage,
    twitterCards,
  };
};

const extractParentOfGridObject = (data: any, iteration = 0) => {
  if (iteration >= 5 || !data) {
    return null;
  }

  if (Object.prototype.hasOwnProperty.call(data, 'grid')) {
    return data;
  }

  return extractParentOfGridObject(Object.values(data)[0], iteration + 1);
};

const getCategoryPageData = async (
  siteId: string,
  commercePath: string,
  apiClient: InitializedApiClient,
  context: ComposableContext
) => {
  try {
    const pageDataResponse = await apiClient.getCategoryPageContent(
      siteId,
      commercePath
    );

    const { setCmsCategoryTitle } = useCategory(
      context.instance,
      context.contextKey
    );
    const categoryTitle = extractProperty(
      pageDataResponse,
      'data.content.commercepage.commerceRef.title',
      ''
    );
    setCmsCategoryTitle(categoryTitle);

    // Todo: ECOM15-11242 - Implement meta tags for commerce pages
    return {
      content: extractProperty(
        pageDataResponse,
        'data.content.commercepage',
        ''
      ),
      pageLayoutName: 'plp',
      pageTypeName: PageTypeName.PLP,
      pageProps: {},
      errors: [],
      meta: {},
    };
  } catch (e) {
    return {
      content: null,
      pageTypeName: null,
      pageLayoutName: null,
      pageProps: {},
      errors: [`HTTP Error while trying to get PLP data: ${e.message}`],
      meta: {},
    };
  }
};

const getProductPageData = async (
  siteId: string,
  productId: string,
  apiClient: InitializedApiClient
) => {
  try {
    const pageDataResponse = await apiClient.getProductPageContent(
      siteId,
      productId
    );
    // Todo: ECOM15-11242 - Implement meta tags for commerce pages
    return {
      content: pageDataResponse.data.content.commercepage,
      pageTypeName: PageTypeName.PDP,
      pageLayoutName: 'pdp',
      pageProps: {},
      errors: [],
      meta: {},
    };
  } catch (e) {
    return {
      content: null,
      pageTypeName: null,
      pageLayoutName: null,
      pageProps: {},
      errors: [`HTTP Error while trying to get PDP data: ${e.message}`],
      meta: {},
    };
  }
};

const extractPageTypeName = (
  data: CmsPageContent | CmsCategoryPageContent
): PageTypeName => {
  const rows = data?.grid?.rows;

  if (Array.isArray(rows)) {
    const allPlacements = rows.reduce((placements, current) => {
      placements.push(...current.placements);
      return placements;
    }, [] as CmsPlacement[]);

    let pageConfigPlacement = allPlacements.find(
      (placement) => placement.name === 'inheritableConfigs'
    );

    if (Array.isArray(pageConfigPlacement?.pageItems)) {
      let configObject = pageConfigPlacement.pageItems.filter(
        (item: CMComponent) =>
          (item?.localSettings as any)?.config?.pagetype?.pagetype
      );

      if (!configObject.length) {
        pageConfigPlacement = allPlacements.find(
          (placement: CmsPlacement) => placement.name === 'pagetype'
        );
        configObject = pageConfigPlacement?.pageItems?.filter(
          (item: CMComponent) =>
            (item?.localSettings as any)?.config?.pagetype?.pagetype
        );
      }

      if (configObject.length) {
        const pagetype = (configObject[0].localSettings as any).config.pagetype
          .pagetype;
        if (Object.values(PageTypeName).indexOf(pagetype) >= 0) {
          return pagetype as PageTypeName;
        } else {
          console.error(`${pagetype} is not a valid page type`);
          return PageTypeName.OTHER;
        }
      }
    }
  }

  return null;
};

export const getFlexibleContentByPath = async (
  siteId: string,
  apiClient: InitializedApiClient
) => {
  try {
    const pageDataResponse = await apiClient.getFlexibleContentByPath(siteId);
    return {
      content: pageDataResponse.data.content.flexibleContentByPath,
      pageTypeName: PageTypeName.HOME,
      pageLayoutName: PageLayoutName.HP,
      pageProps: {},
      errors: [],
      meta: {},
    };
  } catch (e) {
    return {
      content: null,
      pageTypeName: null,
      pageLayoutName: null,
      pageProps: {},
      errors: [
        `HTTP Error while trying to get Flex Content By Path data: ${e.message}`,
      ],
      meta: {},
    };
  }
};
