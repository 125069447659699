import { ROUTES } from '../../utils/routes';
import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  decorateHtmlValue,
  getImageObject,
  getMediaSeoAttributes,
} from './utils';

export const loyaltySuccessStep = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const image = extractProperty(data, 'pictures[0]');
  const [button1, button2] = (
    extractProperty(data, 'teaserTargets', '') || []
  ).map((teaser) => ({
    link: extractProperty(teaser, `target.urlSegment`, ''),
    text:
      extractProperty(teaser, `callToActionText`, '') ||
      extractProperty(teaser, `target.teaserTitle`, ''),
  }));

  return {
    component: 'loyalty/LoyaltySuccessStep',
    props: {
      translations: {
        heading: extractProperty(
          data,
          'teaserTitle',
          '$Welcome to the family!$'
        ),
        subheading:
          decorateHtmlValue(
            data?.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          ) || '',
        buttonText: button1?.text || '$Go to Dashboard$',
        secondButtonText: button2?.text || '$Continue shopping$',
      },
      imageObject: image
        ? {
            src: getImageObject(
              image,
              siteConfiguration,
              null,
              cmsBaseUri,
              data.responsiveMedia
            ),
            seo: getMediaSeoAttributes(image),
          }
        : null,
      buttonLink: button1?.link || ROUTES.PROFILE(),
      secondButtonLink: button2?.link || ROUTES.HOME(),
    },
  };
};
