import { getEnvValueByLocale } from '@vf/env';
import { apiClientFactory } from '@vf/api-client';
import { useI18n } from '@vf/composables';
import { useUserStore } from '../../../store/user';
import { storeToRefs } from 'pinia';

export const useEmployeeDiscount = (instance) => {
  const userStore = useUserStore(instance);
  const { localeCode } = useI18n(instance);
  const { usid } = storeToRefs(userStore);

  const { updateAcceptance: updateAcceptanceAPI } = apiClientFactory(instance);

  const updateAcceptance = async (data) => {
    if (!data.isEmployeeDiscountTCAccepted) {
      userStore.setEmployeeDiscountTCAcceptanceCriteria({
        answered: true,
        accepted: false,
      });
      return;
    }

    try {
      const country = getEnvValueByLocale<string>(
        'COUNTRY',
        localeCode(),
        instance.$env
      )?.toUpperCase();
      const result = await updateAcceptanceAPI({
        usid: usid.value,
        country,
        region: instance.context.$env.API_REGION,
      });
      userStore.setEmployeeDiscountTCAcceptanceCriteria({
        answered: true,
        accepted: true,
      });
      return result;
    } catch (error) {
      instance.$log.error(
        `[@useEmployeeDiscount/index::updateAcceptance]: Failed to update acceptance`
      );
    }
  };

  return {
    updateAcceptance,
  };
};
