import { computed, ComputedRef, Ref } from '@vue/composition-api';
import {
  ProductReviewConfResponse,
  ReviewsTranslations,
  ProductReviewPaging,
  ProductReviewSorting,
  ProductReviewFilter,
  ProductReviewRollup,
  ProductReviewSortingParameter,
  ParsedProductReview,
  ProductReviewVoteType,
  ParsedProductReviewItem,
  ParsedProductReviewUgcItem,
} from '@vf/api-contract';
import { apiClientFactory } from '@vf/api-client';
import Vue from 'vue';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { ssrRef } from '@nuxtjs/composition-api';
import { errorMessages } from '../utils/errorMessages';
import { prepareQuery } from '../utils/query';
import { getParsedProductReview, getReviewsTranslations } from './utils';
import { useRequestTracker } from '../useRequestTracker';
import ss from '../utils/sessionStorage';

type PowerReviewsConfig = {
  apiKey: string;
  merchantGroupId: string;
  merchantId: string;
  locale: string;
};

type UseReviewsStorage = {
  productId: Ref<string>;
  reviews: Ref<ParsedProductReviewItem[]>;
  paging: Ref<ProductReviewPaging>;
  sorting: Ref<ProductReviewSorting>;
  filters: Ref<ProductReviewFilter[]>;
  rollup: ComputedRef<ProductReviewRollup>;
  localizations: Ref<ProductReviewConfResponse['localizations']>;
  properties: Ref<ProductReviewConfResponse['properties']>;
  productReview: Ref<ParsedProductReview>;
  votedProductReviewIds: Ref<VotedProductReviewIds>;
  isLoadingProductReviews: Ref<boolean>;
};

type UsePowerReviewsComposable = {
  productReview: Ref<ParsedProductReview>;
  reviews: ComputedRef<ParsedProductReviewItem[]>;
  paging: Ref<ProductReviewPaging>;
  sorting: Ref<ProductReviewSorting>;
  filters: Ref<ProductReviewFilter[]>;
  rollup: ComputedRef<ProductReviewRollup | null>;
  translations: ComputedRef<ReviewsTranslations>;
  properties: Ref<ProductReviewConfResponse['properties']>;
  setProductId: (id: string) => void;
  powerReviewsConf: PowerReviewsConfig;
  getProductReviews: () => Promise<ParsedProductReviewItem[] | void>;
  getProductReviewsConf: () => Promise<void>;
  addFilter: (filterToAdd: ProductReviewFilter) => void;
  removeFilter: (filterToRemove?: ProductReviewFilter) => void;
  flagReview: (
    reviewId: number,
    comments: string,
    flagType: string,
    email: string,
    mediaId?: number
  ) => void;
  setSorting: (sortBy: ProductReviewSortingParameter) => void;
  changePage: (newPage: number) => void;
  addReviewVote: (
    id: number,
    voteType: ProductReviewVoteType,
    mediaId?: number
  ) => void;
  isReviewHelpful: (reviewId: number) => boolean;
  isReviewNotHelpful: (reviewId: number) => boolean;
  isReviewFlagged: (reviewId: number) => boolean;
  isLoadingProductReviews: ComputedRef<boolean>;
};

export type VotedProductReviewIds = {
  helpful: number[];
  notHelpful: number[];
  flagged: number[];
};

const LOCALIZATIONS_SS_KEY = 'reviewsConf-localizations';
const PROPERTIES_SS_KEY = 'reviewsConf-properties';

const useReviews = (
  instance: ComponentInstance,
  contextKey?: string
): UsePowerReviewsComposable => {
  const { displayErrorMessages } = errorMessages(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const {
    addReviewVote: addReviewVoteAPI,
    flagReview: flagReviewAPI,
    getProductReviews: getProductReviewsAPI,
    getProductReviewsConf: getProductReviewsConfAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseReviewsStorage> = initStorage<UseReviewsStorage>(
    instance,
    `useReviews-${contextKey}`
  );

  const productId: Ref<string> =
    storage.get('productId') ??
    storage.save('productId', ssrRef(null, `useReviews-productId`));

  const reviews: Ref<ParsedProductReviewItem[]> =
    storage.get('reviews') ??
    storage.save('reviews', ssrRef([], `useReviews-reviews`));

  const paging: Ref<ProductReviewPaging> =
    storage.get('paging') ??
    storage.save('paging', ssrRef(null, `useReviews-paging`));

  const sorting: Ref<ProductReviewSorting> =
    storage.get('sorting') ??
    storage.save(
      'sorting',
      ssrRef(
        { options: null, sortBy: ProductReviewSortingParameter.MostHelpful },
        `useReviews-sorting`
      )
    );

  const filters: Ref<ProductReviewFilter[]> =
    storage.get('filters') ??
    storage.save('filters', ssrRef([], `useReviews-filters`));

  const localizations: Ref<ProductReviewConfResponse['localizations']> =
    storage.get('localizations') ??
    storage.save(
      'localizations',
      ssrRef(
        JSON.parse(ss.getItem(LOCALIZATIONS_SS_KEY)),
        `useReviews-localizations`
      )
    );

  const properties: Ref<ProductReviewConfResponse['properties']> =
    storage.get('properties') ??
    storage.save(
      'properties',
      ssrRef(JSON.parse(ss.getItem(PROPERTIES_SS_KEY)), `useReviews-properties`)
    );

  const productReview: Ref<ParsedProductReview> =
    storage.get('productReview') ??
    storage.save('productReview', ssrRef(null, `useReviews-productReview`));

  const votedProductReviewIds: Ref<VotedProductReviewIds> =
    storage.get('votedProductReviewIds') ??
    storage.save(
      'votedProductReviewIds',
      ssrRef(
        { helpful: [], notHelpful: [], flagged: [] },
        `useReviews-votedProductReviewIds`
      )
    );

  const isLoadingProductReviews: Ref<boolean> =
    storage.get('isLoadingProductReviews') ??
    storage.save(
      'isLoadingProductReviews',
      ssrRef(false, `useReviews-isLoadingProductReviews`)
    );

  const powerReviewsConf = {
    merchantId: instance.$getEnvValueByCurrentLocale<any>(
      'POWERREVIEWS_LOCALE_CONFIG'
    ).merchant_id,
    locale: instance.$getEnvValueByCurrentLocale<any>(
      'POWERREVIEWS_LOCALE_CONFIG'
    ).locale,
    apiKey: instance.$env.POWERREVIEWS_API_KEY,
    merchantGroupId: instance.$env.POWERREVIEWS_MERCHANT_GROUP_ID,
  };

  const setProductId = (id: string) => (productId.value = id);

  const getProductReviews = async (
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    isLoadingProductReviews.value = true;
    const queryParams = {
      reviewLocale: powerReviewsConf.locale,
    };

    // Add filters to query string
    if (filters.value.length > 0) {
      queryParams['filters'] = filters.value
        .map(
          (filter) =>
            filter.key + ':' + filter.value.join(encodeURIComponent('||'))
        )
        .join(',');
    }

    // Add sorting to query string
    if (sorting.value?.sortBy) {
      queryParams['sort'] = sorting.value.sortBy;
    }

    // Add paging to query string
    if (paging.value?.currentPageNumber > 1) {
      queryParams['paging.from'] =
        (paging.value.currentPageNumber - 1) * paging.value.pageSize;
      queryParams['paging.size'] = paging.value.pageSize;
    }
    const { tag, isAlreadyTracked } = trackRequest(
      'useReviews-getProductReviews',
      isBackgroundRequest
    );
    if (isAlreadyTracked) return clearRequest(tag);

    try {
      const reviewsData = await getProductReviewsAPI(
        productId.value,
        prepareQuery(queryParams)
      );

      const parsedProductReview = getParsedProductReview({
        reviewResponse: reviewsData.data,
        filters: filters.value,
        sorting: sorting.value,
        locale: powerReviewsConf.locale,
        localeMapping: instance.$env.LOCALE_MAPPING,
        votedProductReviewIds: votedProductReviewIds.value,
        previousParsedProductReview: productReview.value,
      });

      productReview.value = parsedProductReview;
      if (!paging.value) {
        paging.value = parsedProductReview.pagination;
      }

      reviews.value = parsedProductReview.reviews;
      return parsedProductReview.reviews;
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      isLoadingProductReviews.value = false;
      clearRequest(tag);
    }
  };

  const getProductReviewsConf = async (
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    // in case the configs are already fetched, there is no need to fetch them again
    if (localizations.value && properties.value) return;

    const { tag, isAlreadyTracked } = trackRequest(
      'useReviews-getProductReviewsConf',
      isBackgroundRequest
    );
    if (isAlreadyTracked) return clearRequest(tag);
    try {
      const reviewsData = await getProductReviewsConfAPI(
        prepareQuery({
          reviewLocale: powerReviewsConf.locale,
          merchantId: powerReviewsConf.merchantId,
        })
      );
      localizations.value = reviewsData.data?.localizations || {};
      properties.value = reviewsData.data?.properties || {};
      ss.setItem(LOCALIZATIONS_SS_KEY, JSON.stringify(localizations.value));
      ss.setItem(PROPERTIES_SS_KEY, JSON.stringify(properties.value));
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      clearRequest(tag);
    }
  };

  const addFilter = (filterToAdd: ProductReviewFilter) => {
    const ifKeyExist = filters.value.find((f) => {
      return f.key === filterToAdd.key;
    });

    if (ifKeyExist) {
      filters.value.forEach((f) => {
        if (f.key === filterToAdd.key) {
          f.value.push(filterToAdd.value[0]);
        }
      });
    } else {
      filters.value.push(filterToAdd);
    }
  };

  const removeFilter = (filterToRemove?: ProductReviewFilter) => {
    if (!filterToRemove) return (filters.value = []);
    const ifKeyExist = filters.value.find((f) => f.key === filterToRemove.key);

    if (ifKeyExist) {
      filters.value.forEach((f) => {
        if (
          f.key === filterToRemove.key &&
          f.value.includes(filterToRemove.value[0])
        ) {
          f.value.splice(f.value.indexOf(filterToRemove.value[0]), 1);
        }
      });
      if (ifKeyExist.value.length === 0)
        filters.value = filters.value.filter(
          (f) => f.key !== filterToRemove.key
        );
    }
  };

  const flagReview = async (
    reviewId: number,
    comments: string,
    flagType: string,
    email: string,
    mediaId?: number
  ) => {
    try {
      const id = mediaId ?? reviewId;
      const flagReviewResponse = await flagReviewAPI(productId.value, id, {
        flagComment: comments,
        flagType: flagType,
        contactEmail: email,
      });
      votedProductReviewIds.value.flagged.push(id);
      const index = productReview.value.reviews.findIndex(
        (review) => review.reviewId === reviewId
      );
      let review = productReview.value.reviews[index];
      if (mediaId) {
        review = {
          ...review,
          media: review.media.map(
            (item: ParsedProductReviewUgcItem) =>
              updateFlag(item) as ParsedProductReviewUgcItem
          ),
        };
      } else {
        review = updateFlag(review) as ParsedProductReviewItem;
      }
      Vue.set(productReview.value.reviews, index, review);

      return flagReviewResponse;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const setSorting = (sortBy: ProductReviewSortingParameter) => {
    sorting.value = {
      options:
        sorting.value?.options ||
        getReviewsTranslations(localizations.value).sortingOptions ||
        ProductReviewSortingParameter,
      sortBy: sortBy,
    };
  };

  const changePage = (newPage: number) => {
    paging.value.currentPageNumber = newPage;
  };

  const addReviewVote = async (
    reviewId: number,
    voteType: ProductReviewVoteType,
    mediaId?: number
  ) => {
    try {
      const id = mediaId ?? reviewId;
      const result = await addReviewVoteAPI(productId.value, id, voteType);

      const isHelpful = voteType === ProductReviewVoteType.Helpful;
      if (isHelpful) {
        votedProductReviewIds.value.helpful.push(id);
      } else {
        votedProductReviewIds.value.notHelpful.push(id);
      }

      const index = productReview.value.reviews.findIndex(
        (review) => review.reviewId === reviewId
      );
      let review = productReview.value.reviews[index];
      if (mediaId) {
        review = { ...review, media: review.media.map(updateMediaVotes) };
      } else {
        review = updateReviewVotes(review);
      }
      Vue.set(productReview.value.reviews, index, review);

      return result;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const updateFlag = (
    item: ParsedProductReviewItem | ParsedProductReviewUgcItem
  ): ParsedProductReviewItem | ParsedProductReviewUgcItem => {
    const id =
      (item as ParsedProductReviewItem).reviewId ??
      (item as ParsedProductReviewUgcItem).id;
    return {
      ...item,
      flagged: isReviewFlagged(id),
    };
  };

  const updateReviewVotes = (item: ParsedProductReviewItem) => {
    const helpful = isReviewHelpful(item.reviewId);
    const notHelpful = isReviewNotHelpful(item.reviewId);
    const helpfulVotes = item.metrics.helpfulVotes + (helpful ? 1 : 0);
    const notHelpfulVotes = item.metrics.notHelpfulVotes + (notHelpful ? 1 : 0);
    return {
      ...item,
      isUpSelected: helpful,
      isDownSelected: notHelpful,
      thumbUpCount: helpfulVotes,
      thumbDownCount: notHelpfulVotes,
    };
  };

  const updateMediaVotes = (
    item: ParsedProductReviewUgcItem
  ): ParsedProductReviewUgcItem => {
    const helpful = isReviewHelpful(item.id);
    const notHelpful = isReviewNotHelpful(item.id);
    return {
      ...item,
      helpfulVotes: item.helpfulVotes + (helpful ? 1 : 0),
      notHelpfulVotes: item.notHelpfulVotes + (notHelpful ? 1 : 0),
      thumbUp: helpful,
      thumbDown: notHelpful,
    };
  };

  const isReviewHelpful = (reviewId: number): boolean => {
    return votedProductReviewIds.value.helpful.includes(reviewId);
  };

  const isReviewNotHelpful = (reviewId: number): boolean => {
    return votedProductReviewIds.value.notHelpful.includes(reviewId);
  };

  const isReviewFlagged = (reviewId: number): boolean => {
    return votedProductReviewIds.value.flagged.includes(reviewId);
  };

  return {
    reviews: computed(() => reviews.value),
    paging: computed(() => paging.value),
    sorting: computed(() => sorting.value),
    filters: computed(() => filters.value),
    rollup: computed(() => productReview.value?.rollup),
    translations: computed(() => getReviewsTranslations(localizations.value)),
    properties: computed(() => properties.value),
    productReview: computed(() => productReview.value),
    isLoadingProductReviews: computed(() => isLoadingProductReviews.value),
    setProductId,
    getProductReviews,
    powerReviewsConf,
    getProductReviewsConf,
    addFilter,
    removeFilter,
    flagReview,
    setSorting,
    changePage,
    addReviewVote,
    isReviewHelpful,
    isReviewNotHelpful,
    isReviewFlagged,
  };
};

export default useReviews;
