import { Plugin } from '@nuxt/types';
import { setup } from '@vf/api-client';
import { getEnvValueByLocale, mapLocale } from '@vf/env';
import { getClientIp } from '@supercharge/request-ip';
import cookieUniversal from 'cookie-universal';
import { getDomainName } from '../helpers';

const ApiClientPlugin: Plugin = async (context, inject) => {
  const cookies = cookieUniversal(context.req, context.res, {
    parseJSON: true,
  });

  // Cookies configuration
  inject('cookies', cookies);

  if (context.req) {
    cookies.set(
      'user-ip',
      context.req.headers['true-client-ip']?.toString() ||
        getClientIp(context.req)
    );
  }

  // API configuration
  const configurations = setup({
    apiUrl: context.$env.EAPI_URL,
    previewApiUrl: context.$env.PREVIEW_EAPI_URL,
    isPreview: context.app.$isPreview,
    apiHost: getDomainName(context),
    brand: context.$env.BRAND,
    source: context.$env.API_SOURCE,
    channel: context.$env.API_CHANNEL,
    siteId: getEnvValueByLocale<string>(
      'API_SITE_ID',
      context.app.i18n.locale,
      context.$env
    ),
    locale: mapLocale(context.app.i18n.locale, context.$env.LOCALE_MAPPING),
    region: context.$env.API_REGION,
    req: context.req,
    endpoints: JSON.parse(context.$env.EAPI_ENDPOINTS),
    ssrAccessToken: process.server
      ? process.env.COREMEDIA_ACCESS_TOKEN_SSR
      : null,
    ssrTrustId: process.server
      ? process.env.SECRET_SERVER_CDN_TRUST_HEADER
      : null,
    ssrBypassMaintenanceHeader:
      process.env.HEADER_BYPASS_AKAMAI_MAINTENANCE_PAGE,
    timeout: process.server
      ? parseInt(process.env.EAPI_AXIOS_SSR_TIMEOUT)
      : parseInt(process.env.EAPI_AXIOS_CSR_TIMEOUT),
    logger: context.app.$log,
  });

  inject('getApiClientConfiguration', { ...configurations });
};

export default ApiClientPlugin;
