import {
  onGlobalSetup,
  provide,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import { Plugin } from '@nuxt/types';
import { extractProductIdFromUrl } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { useCart } from '@vf/composables/src/useCart';
import useSocialNative from '@vf/composables/src/useSocialNative';
import useRootInstance from '@/shared/useRootInstance';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useOrderStore } from '@vf/composables/src/store/order';
import { CartLineItem } from '@vf/api-client';
import { usePayPal } from '@vf/composables';

const socialNativeAnalyticsTrackedProductUrl =
  'socialNativeAnalyticsTrackedProductUrl';
const socialNativeAnalyticsTrackedProductMediaId =
  'socialNativeAnalyticsTrackedProductMediaId';
const socialNativeAnalyticsTrackedComponent =
  'socialNativeAnalyticsTrackedComponent';
const socialNativeAnalyticsTrackedProductsInCart =
  'socialNativeAnalyticsTrackedProductsInCart';

let productBeingTracked = '';
let shouldKeepTrack = false;

const trackedProductIdCurrentlyViewed = (currentPath) => {
  const trackedProductUrl = window.localStorage.getItem(
    socialNativeAnalyticsTrackedProductUrl
  );
  const trackedProductId = extractProductIdFromUrl(trackedProductUrl);

  if (trackedProductId) {
    // product being viewed
    const id = extractProductIdFromUrl(currentPath);

    return id === trackedProductId ? id : '';
  }

  return '';
};

const invalidateTrackedItems = () => {
  window.localStorage.removeItem(socialNativeAnalyticsTrackedProductUrl);
  window.localStorage.removeItem(socialNativeAnalyticsTrackedComponent);
  window.localStorage.removeItem(socialNativeAnalyticsTrackedProductMediaId);
  productBeingTracked = '';
};

const keepTrackOfTheProduct = (product) => {
  window.localStorage.setItem(
    socialNativeAnalyticsTrackedProductUrl,
    product.url
  );
  window.localStorage.setItem(
    socialNativeAnalyticsTrackedProductMediaId,
    product.id
  );
};

const keepTrackOfTheComponent = (...args) => {
  // we should keep track of the social native component
  // since it's been rendered and until there's a new tracked product
  !productBeingTracked &&
    window.localStorage.setItem(
      socialNativeAnalyticsTrackedComponent,
      JSON.stringify(args)
    );
};

export const keepTrackOfTheProductInCart = (id: string): void => {
  const trackedProducts = [];
  const alreadyTrackedProducts = window.localStorage.getItem(
    socialNativeAnalyticsTrackedProductsInCart
  );

  if (alreadyTrackedProducts) {
    trackedProducts.push(...alreadyTrackedProducts.split(','));
  }
  trackedProducts.push(id);

  window.localStorage.setItem(
    socialNativeAnalyticsTrackedProductsInCart,
    trackedProducts.join(',')
  );
};

export const collectTrackedItemsInCart = (
  cartItems: CartLineItem[]
): string[] => {
  const products = [];
  const trackedItems = window.localStorage.getItem(
    socialNativeAnalyticsTrackedProductsInCart
  );

  trackedItems?.split(',').forEach((id) => {
    const productInCart = cartItems.find((cartItem) => cartItem.id === id);

    if (productInCart) {
      const id = productInCart.productId.split(':').slice(0, 3).join('');

      products.push(`${id}:${productInCart.price.rowTotal}`);
    }
  });

  return products;
};

const SocialNativeClient: Plugin = async (context) => {
  const { route: to } = context;
  productBeingTracked = trackedProductIdCurrentlyViewed(to.path);

  window.addEventListener('popstate', () => {
    // we should keep track of a product while a user is navigating
    // back and forth through the browser's history
    shouldKeepTrack = true;
  });

  onGlobalSetup(() => {
    provide('socialNativeAnalytics', {
      keepTrackOfTheProduct,
      keepTrackOfTheComponent,
    });

    const { root } = useRootInstance();
    const router = useRouter();

    const checkoutStore = useCheckoutStore();
    const orderStore = useOrderStore();
    const { cart } = useCart(root);

    router.afterEach((to) => {
      const trackedProductId = trackedProductIdCurrentlyViewed(to.path);

      if (!shouldKeepTrack && !trackedProductId) {
        invalidateTrackedItems();
      } else {
        productBeingTracked = trackedProductId;
      }

      // reset the track guard until the next popstate is triggered
      shouldKeepTrack = false;
    });

    const { recentlyAddedProduct } = useCart(root);
    const {
      trackProductShopEvent,
      trackCheckoutEvent,
      getAnalyticsId,
    } = useSocialNative(root);

    watch(recentlyAddedProduct, (product: any) => {
      if (product) {
        // product being viewed
        const id = extractProductIdFromUrl(product.pdpUrlRelative);

        if (productBeingTracked && productBeingTracked === id) {
          const [type, useCarousel, options] = JSON.parse(
            window.localStorage.getItem(socialNativeAnalyticsTrackedComponent)
          );

          const mediaId = window.localStorage.getItem(
            socialNativeAnalyticsTrackedProductMediaId
          );

          trackProductShopEvent(type, useCarousel, {
            ...options,
            mediaId,
          });
          keepTrackOfTheProductInCart(product.id);

          invalidateTrackedItems();
        }
      }
    });

    const { payPalPaymentInProgress } = usePayPal(root);

    watch(
      [() => orderStore.isPlaceOrderAttemptInProgress, payPalPaymentInProgress],
      (values) => {
        let stop = () => null;
        if (values.some((i) => i)) {
          const cartItems = cart.value?.items || [];

          stop = watch(
            () => checkoutStore.order?.orderNumber,
            (orderNumber, _, onCleanup) => {
              if (orderNumber) {
                const products = collectTrackedItemsInCart(cartItems);

                if (products.length) {
                  const analyticsId = getAnalyticsId();
                  const apiKey = root.$getEnvValueByCurrentLocale<string>(
                    'SOCIAL_NATIVE_API_RO_KEY'
                  );
                  trackCheckoutEvent(
                    {
                      transactionId: orderNumber,
                    },
                    {
                      products,
                      analytics_id: analyticsId,
                      auth_token: apiKey,
                    }
                  );
                }
              }

              onCleanup(() => {
                if (orderNumber) {
                  window.localStorage.removeItem(
                    socialNativeAnalyticsTrackedProductsInCart
                  );
                }
              });

              stop();
            }
          );
        } else {
          stop();
        }
      }
    );
  });
};

export default SocialNativeClient;
