import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { SignUpResponse, SignUpRequestSimplified } from '../../api-types';

export const signUpSimplified = (
  configuration: ApiClientConfiguration
) => async (
  data: SignUpRequestSimplified,
  usid: string
): Promise<AxiosResponse<SignUpResponse>> => {
  const { siteId, locale } = configuration;

  return axios.post<SignUpResponse>(
    urlBuilder(endpoints.get(configuration).authentication.signUp, {
      config: configuration,
    }),
    {
      email: data.email,
      password: data.password,
      country: data.country,
      isTermsConfirmed: true,
      locale: locale,
      source: {
        store: data.store || siteId,
        campaignName: data.campaignName,
        acquisitionType: 'Registered',
      },
      subscriptions: {
        loyaltyConsent: true, // terms and conditions are always accepted due to form validation
        newsletterConsent: data.isSubscribed,
        loyaltylNewsletterConsent: data.isSubscribed,
      },
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
        'x-consumer': usid,
        ...(data.source && { source: data.source }),
        ...(data.channel && { channel: data.channel }),
      }),
      params: {
        captchaResponse: data.recaptchaToken,
      },
    }
  );
};
