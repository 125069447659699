import { getTranslation } from './utils/extractProperty';
import { CMTeaser } from '@vf/api-contract';

export const maForgotPassword = (data: CMTeaser) => {
  return {
    component: 'account/ForgotPassword',
    props: {
      translations: {
        title: getTranslation(data, 'title', '$Forgot password$'),
        emailLabel: getTranslation(data, 'emailLabel', '$Email address$'),
        placeholder: getTranslation(data, 'placeholder', '$Type here$'),
        validationMessages: getTranslation(data, 'validationMessages', {
          requiredError: '$Field is required$',
          emailError: '$Please provide valid email address$',
        }),
        resetPasswordButton: getTranslation(
          data,
          'resetPasswordButton',
          '$Reset password$'
        ),
        cancelButton: getTranslation(data, 'cancelButton', '$Cancel$'),
        successReset: getTranslation(
          data,
          'successReset',
          '$An email will be sent to you shortly if an account exists for this email address$'
        ),
        errorReset: getTranslation(
          data,
          'errorReset',
          '$Cannot reset the password$'
        ),
        expiredLinkMessage: getTranslation(
          data,
          'expiredLinkMessage',
          '$Reset password link is expired or invalid.$'
        ),
      },
    },
  };
};
