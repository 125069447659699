




























































































































import { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { ProductLineTranslations, ProductLine } from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ThemeProductLine',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<ProductLineTranslations>,
      required: true,
    },
    /** Product Object containing:
     * - pdpUrl
     * - productImageURL
     * - name
     * - variants
     * */
    product: {
      type: Object as PropType<ProductLine>,
      required: true,
    },
    /** Product image width in pixels (px) */
    imageWidth: {
      type: [Number, String],
      default: 110,
    },
    /** Product image height in pixels (px) */
    imageHeight: {
      type: [Number, String],
      default: 130,
    },
    /**
     * Product messages considering flash errors, customer notifications etc.
     * Can be supported by details1 slot
     * */
    productMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** Disable product link and display plain text instead */
    noLink: { type: Boolean, default: false },
    /** Product Unit Price to be displayed if no price slot is used */
    price: { type: String, default: '' },
    /** Product Sale Price after discount if possible */
    salePrice: { type: String, default: '' },
    /** Product Total Price to be displayed if no price slot is used */
    totalPrice: { type: String, default: '' },
    /** Price value of gift box */
    giftBoxPrice: { type: String, default: '' },
  },
  emits: ['click:link'],
  setup(props, { emit }) {
    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const linkComponent = computed(() => {
      return props.noLink ? 'div' : 'ThemeLink';
    });

    const onClickLink = () => {
      if (!props.noLink) {
        emit('click:link');
      }
    };

    const attributes = computed(() => {
      const attrs =
        props.product.variants?.map((variant) => ({
          label: props.translations[variant.label] ?? variant.label,
          value: variant.value,
          code: variant.code,
        })) ?? null;

      if (attrs && props.giftBoxPrice) {
        attrs.push({
          label: props.translations.giftBox,
          value: props.giftBoxPrice,
          code: 'gift-box',
        });
      }

      return attrs;
    });

    return {
      isCoreRedesignEnabled,
      linkComponent,
      onClickLink,
      attributes,
    };
  },
});
