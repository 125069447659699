import { Plugin } from '@nuxt/types';
import { MetaInfo } from 'vue-meta';

const imagesPreconnectPlugin: Plugin = (context) => {
  (context.app.head as MetaInfo).link.push({
    rel: 'preconnect',
    //S7_IMAGES_HOSTNAME variable is exposed in runtime by publicRuntimeConfig
    href: context.$config.S7_IMAGES_HOSTNAME,
    crossorigin: 'true',
  });
};

export default imagesPreconnectPlugin;
