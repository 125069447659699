import { apiClientFactory } from '@vf/api-client';
import { ref, computed } from '@vue/composition-api';
import { ComponentInstance } from '../types';
import { useAuthentication } from '../useAuthentication';
import initStorage from '../utils/storage';
import { useRequestTracker } from './../useRequestTracker';
import session from '../utils/sessionStorage';
import { UseLoyalty, UseLoyaltyStorage } from './types';
import { LoyaltyVouchersResponse } from '@vf/api-contract';

const EMPTY_TOKEN = { token: '', expiresAt: 0 };

export const useLoyalty = (instance: ComponentInstance): UseLoyalty => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { getConsumerId, getUsid } = useAuthentication(instance);
  const {
    getLoyaltyToken: getLoyaltyTokenAPI,
    getLoyaltyWelcomePromoStatus: getLoyaltyWelcomePromoStatusAPI,
    getLoyaltyVouchers: getLoyaltyVouchersAPI,
  } = apiClientFactory(instance);

  const storage = initStorage<UseLoyaltyStorage>(instance, 'useLoyalty');

  const loyaltyToken = ref({
    expiresAt: 0,
    token: '',
  });
  const memberId = storage.get('memberId') ?? storage.save('memberId', ref());

  const isVisitor =
    storage.get('isVisitor') ?? storage.save('isVisitor', ref(false));

  const isConfirmed =
    storage.get('isConfirmed') ?? storage.save('isConfirmed', ref(false));

  const referralCode =
    storage.get('referralCode') ??
    storage.save('referralCode', ref(session.getItem('referralCode')));

  const rewards =
    storage.get('rewards') ??
    storage.save(
      'rewards',
      ref<LoyaltyVouchersResponse>({
        currentLoyaltyPoints: 0,
        applicableLoyaltyVouchers: [],
      })
    );

  const getLoyaltyToken = async (
    { isBackgroundRequest = false } = { isBackgroundRequest: false }
  ) => {
    if (!getConsumerId.value) {
      throw new Error('Not logged in');
    }

    if (loyaltyToken.value.expiresAt > Date.now()) {
      return loyaltyToken.value;
    }

    let tokenRequestData = null;

    if (referralCode.value) {
      // for backwards compatibility, only initialize tokenRequestData if there is a referral code
      tokenRequestData = {
        referralCode: referralCode.value,
      };
    }

    const { tag } = trackRequest('useLoyalty-getToken', isBackgroundRequest);
    try {
      const loyaltyTokenResponse = await getLoyaltyTokenAPI(tokenRequestData, {
        usid: getUsid.value,
      });
      loyaltyToken.value = {
        token: loyaltyTokenResponse.memberToken,
        expiresAt: Date.now() + loyaltyTokenResponse.expiresIn * 1000,
      };
      memberId.value = loyaltyTokenResponse.memberId;
      isVisitor.value = loyaltyTokenResponse.isVisitor;
      isConfirmed.value = loyaltyTokenResponse.isConfirmed;

      if (referralCode.value) {
        // clear the referral code after first successful call
        clearReferralCode();
      }

      return loyaltyToken.value;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const getLoyaltyWelcomePromoStatus = async (
    { isBackgroundRequest = false } = { isBackgroundRequest: false }
  ) => {
    if (!getConsumerId.value) {
      throw new Error('Not logged in');
    }

    const { tag } = trackRequest(
      'useLoyalty-getWelcomePromoStatus',
      isBackgroundRequest
    );

    try {
      return await getLoyaltyWelcomePromoStatusAPI({
        usid: getUsid.value,
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const getLoyaltyVouchers = async (
    { isBackgroundRequest = false } = { isBackgroundRequest: false }
  ) => {
    if (!getConsumerId.value) {
      throw new Error('Not logged in');
    }

    const { tag } = trackRequest('useLoyalty-getVouchers', isBackgroundRequest);

    try {
      rewards.value = await getLoyaltyVouchersAPI({
        usid: getUsid.value,
      });
      return rewards.value;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const clearLoyaltyData = () => {
    loyaltyToken.value = EMPTY_TOKEN;
    memberId.value = undefined;
    isVisitor.value = false;
    isConfirmed.value = false;
    referralCode.value = null;
    session.removeItem('referralCode');
  };

  const setReferralCode = (referral_code: string) => {
    referralCode.value = referral_code;
    session.setItem('referralCode', referral_code);
  };

  const clearReferralCode = () => {
    referralCode.value = null;
    session.removeItem('referralCode');
  };

  const captureReferralCodeQueryParam = () => {
    const query = instance.context.route?.query;
    // capture ?referralCode= or ?referral_code=
    const referral_code = query?.referralCode
      ? query?.referralCode
      : query?.referral_code;
    if (referral_code) {
      setReferralCode(
        Array.isArray(referral_code) ? referral_code[0] : referral_code
      );
    }
  };

  return {
    loyaltyToken: computed(() => loyaltyToken.value),
    memberId: computed(() => memberId.value),
    isVisitor: computed(() => isVisitor.value),
    isConfirmed: computed(() => isConfirmed.value),
    referralCode: computed(() => referralCode.value),
    rewards: computed(() => rewards.value),
    getLoyaltyToken,
    getLoyaltyWelcomePromoStatus,
    getLoyaltyVouchers,
    clearLoyaltyData,
    captureReferralCodeQueryParam,
  };
};
