import {
  extractProperty,
  generateLinkFromTeaserTargets,
  getVideoObject,
  getVideoResponsiveMedia,
} from './utils';
import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const gifVideo = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const options = {
    preload: 'metadata',
    autoplay: true,
    hideControls: true,
    muted: true,
    showPlayButton: false,
    loop: true,
    showSubtitles: false,
  };
  return {
    component: 'shared/GifVideo',
    props: {
      video: {
        small: {
          ...getVideoObject(data.videos, cmsBaseUri),
          options: options,
        },
        medium:
          extractProperty(data, 'responsiveMedia.medium[0].type', '') ===
          'CMVideo'
            ? {
                ...getVideoResponsiveMedia(data, cmsBaseUri, 'medium'),
                options,
              }
            : null,
        large:
          extractProperty(data, 'responsiveMedia.large[0].type', '') ===
          'CMVideo'
            ? {
                ...getVideoResponsiveMedia(data, cmsBaseUri, 'large'),
                options,
              }
            : null,
      },
      callToActionEnabled: extractProperty(data, 'teaserTargets.length', 0) > 0,
      openInNewTab: extractProperty(data, 'openInNewTab', false),
      teaserLink: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
    },
  };
};
