import { apiClientFactory } from '@vf/api-client';
import { Product } from '@vf/api-client';
import { PdpGalleryImage, PdpGalleryVideo } from '../types';
import { ComponentInstance } from '../../../types';
import { Scene7GalleryItem } from '@vf/api-contract';

const scene7Media = async (
  product: Product,
  instance: ComponentInstance,
  isPdpImage: boolean,
  configuredColor = ''
): Promise<(PdpGalleryImage | PdpGalleryVideo)[]> => {
  const { getGalleryScene7, generateProductIdScene7 } = apiClientFactory(
    instance
  );
  try {
    const productId = generateProductIdScene7(product, configuredColor);
    const galleryResponse = await getGalleryScene7(productId, instance);
    const galleryItemMappers = getGalleryItemMappers(
      instance,
      product,
      isPdpImage
    );

    /** Scene7 will return an object if there is only one image in the response, we need an array */
    const items = Array.isArray(galleryResponse.set.item)
      ? galleryResponse.set.item
      : [galleryResponse.set.item];

    return items.map((item) => {
      return item.type === 'video'
        ? galleryItemMappers.video(item, {
            lastModified: galleryResponse.lastModified || '',
          })
        : galleryItemMappers.image(item);
    });
  } catch (e) {
    console.error('scene7Media: scene7 images fetching error', e);
    return [];
  }
};

const scene7MediaPlp = async (
  productId: string,
  colorVariationId: string,
  instance: ComponentInstance
): Promise<any[]> => {
  const { getGalleryScene7 } = apiClientFactory(instance);
  try {
    const imageProductId = `${productId}_${colorVariationId}`;
    const galleryResponse = await getGalleryScene7(imageProductId, instance);
    const galleryItemMappers = getPlpGalleryItemMappers(instance);

    /** Scene7 will return an object if there is only one image in the response, we need an array */
    const items = Array.isArray(galleryResponse.set.item)
      ? galleryResponse.set.item
      : [galleryResponse.set.item];

    return items.map((item) => {
      return galleryItemMappers.image(item);
    });
  } catch (e) {
    console.error('scene7Media: scene7 images fetching error', e);
    return [];
  }
};

const getGalleryItemMappers = (
  instance: ComponentInstance,
  product: Product,
  isPdpImage: boolean
) => {
  return {
    image: getImageObject(instance, product, isPdpImage),
    video: getVideoObject(instance, product),
  };
};

const getPlpGalleryItemMappers = (instance: ComponentInstance) => {
  return {
    image: getPlpImageObject(instance),
  };
};

const getImageSrc = (instance, item, preset: string) => {
  return instance.$mediaUrlGenerator({
    preset,
    fullPath: item.i.n,
  });
};

const getPdpImagesObject = (
  instance: ComponentInstance,
  product: Product,
  item: Scene7GalleryItem,
  presets: any
) => {
  if (presets) {
    return {
      src: getImageSrc(instance, item, presets.main),
      zoom: getImageSrc(instance, item, presets.zoom),
      thumbnail: getImageSrc(instance, item, presets.thumbnail),
      alt: product.name,
    };
  }
  return {};
};

const getImageObject = (
  instance: ComponentInstance,
  product: Product,
  isPdpImage: boolean
) => (item: Scene7GalleryItem) => {
  const productGallery = instance.$themeConfig.productGallery;
  const usePresets = productGallery?.usePresets;
  if (usePresets && isPdpImage) {
    const presets = productGallery?.presets;
    if (presets) return getPdpImagesObject(instance, product, item, presets);
  }
  return {
    src: instance.$mediaUrlGenerator({
      preset: instance.$env.IMAGES_PDP_MAIN_PRESET,
      fullPath: item.i.n,
    }),
    zoom: instance.$mediaUrlGenerator({
      preset: instance.$env.IMAGES_PDP_ZOOM_PRESET,
      fullPath: item.i.n,
    }),
    thumbnail: instance.$mediaUrlGenerator({
      preset: instance.$env.IMAGES_PDP_GALLERY_THUMBNAIL_PRESET,
      fullPath: item.i.n,
    }),
    alt: product.name,
  };
};

const getPlpImageObject = (instance: ComponentInstance) => (
  item: Scene7GalleryItem
) => {
  const productsGrid = instance.$themeConfig?.productsGrid;
  return instance.$mediaUrlGenerator({
    preset: instance.$env.IMAGES_PDP_MAIN_PRESET,
    fullPath: item.i.n,
    imageSizes: productsGrid.imageSizes,
  });
};

const getVideoObject = (instance: ComponentInstance, product: Product) => (
  item: Scene7GalleryItem,
  { lastModified }
) => {
  return {
    thumbnail: instance.$mediaUrlGenerator({
      preset: instance.$env.IMAGES_PDP_GALLERY_THUMBNAIL_PRESET,
      fullPath: item.i.n,
    }),
    alt: product.name,
    video: {
      sources: [
        {
          src: instance.$mediaUrlGenerator({
            pid: item.v.id,
            type: 'content',
            shotType: null,
            preset: null,
            fullPath: item.v.id,
          }),
          type: `video/${item.v.suffix}`,
        },
      ],
      seo: {
        date: lastModified,
        title: product.name,
        description: product.description,
      },
      tracks: [],
      poster: instance.$mediaUrlGenerator({
        preset: instance.$env.IMAGES_PDP_MAIN_PRESET,
        fullPath: item.i.n,
      }),
      options: {
        preload: 'metadata',
        autoplay: false,
        hideControls: false,
        muted: false,
        loop: false,
        showPlayButton: true,
        showSubtitles: false,
      },
    },
  };
};

export { scene7Media, scene7MediaPlp };
