import { extractProperty } from './extractProperty';
import { extractLocalSettingsExpandedLinks } from './extractLocalSettingsExpandedLinks';

export const extractComponentName = (item: any, componentsMapping: any) => {
  const dataSource = extractProperty(
    extractLocalSettingsExpandedLinks(item, 'dataSources'),
    '[0].viewtype'
  );
  if (dataSource && Object.keys(componentsMapping).includes(dataSource)) {
    return componentsMapping[dataSource];
  }
};
