import { Image } from './utils';
import { Breadcrumb } from './breadcrumb';
import { Category } from './category';
import { Price, Sale, Discount } from './price';

export interface Product {
  id: string;
  recipeId: string;
  masterId: string;
  name: string;
  breadcrumbs: Breadcrumb[];
  categories: Category[];
  slug: string;
  price: Price;
  description: string;
  images: Image[];
  customized: boolean;
  sale: Sale;
  reviews: Reviews;
  masterVariant: ProductVariant;
  variants: ProductVariant[];
  badges?: Image[];
  benefits?: Image[];
}

export interface ProductVariant {
  id: string;
  recipeId: string;
  master: boolean;
  name: string;
  slug: string;
  isOutOfStock: boolean;
  stock: number;
  image: Image;
  attributes: Attribute[];
}

/**
 * Examples:
 * { id: 'color', value: 'red', label: 'red', src: '/image.png' }
 * { id: 'size', value: 'xl', label: 'XL', src: '/image.png' }
 */
export interface Attribute {
  id: string;
  value: string;
  label?: string;
  image?: Image;
}

export interface Reviews {
  rating: Rating;
  numReviews: number;
}

export interface Rating {
  score: number;
  count: number;
}

/**
 * Example:
 * {
 *  "discount" : {
 *    "type" : "fixed_price",
 *    "value" : 15.00
 *  }
 *  "itemId: "vn:12222:oa:m:12a:",
 *  "reasonCode": "appeasement"
 * }
 */
export interface ProductAdjustment {
  discount?: Discount;
  applied_discount?: Discount;
  item_id?: string;
  reasonCode?: string;
  level?: string;
}

export enum WidthAttributeValue {
  WIDE = 'WDE',
  REGULAR = 'REG',
}

export interface ProductLine {
  pdpUrlRelative?: string;
  pdpUrl: string;
  productImageURL: string;
  name: string;
  qty: string | number;
  variants: { label: string; value: string }[];
}
