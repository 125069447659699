import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
  generateLinkFromTarget,
  getImageObject,
  getVideoObject,
} from '../utils';
import { ComposableContext } from '../../types';
import { CmsSite } from '@vf/api-contract';

export const geminiContentCard = (
  data: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const img1Desktop = extractProperty(data, 'mainImage[0]');
  const img1Mobile = extractProperty(data, 'mobileImage[0]');
  const img2 = extractProperty(data, 'addImage[0]');
  const img3 = extractProperty(data, 'addImage2[0]');
  const ctaUrl1 = extractProperty(data, 'ctaUrl1[0]');
  const ctaUrl2 = extractProperty(data, 'ctaUrl2[0]');
  const ctaUrl3 = extractProperty(data, 'ctaUrl3[0]');

  const badgeImage = extractProperty(data, 'svgImage[0]');

  const img1Object = () =>
    img1Desktop
      ? getImageObject(img1Desktop, siteConfiguration, null, cmsBaseUri)?.small
      : '';

  const badgeImageObject = () =>
    badgeImage
      ? getImageObject(badgeImage, siteConfiguration, null, cmsBaseUri)?.small
      : '';

  const getMainVideo = () => {
    const mainVideoObject = getVideoObject(data.mainVideo || [], cmsBaseUri);

    if (mainVideoObject) {
      return mainVideoObject.sources[0].src;
    }

    return '';
  };

  const mainVideoHeight = extractProperty(data, 'mainVideo[0].height', '');
  const mainVideoWidth = extractProperty(data, 'mainVideo[0].width', '');

  const getMobileVideo = () => {
    const mobileVideoObject = getVideoObject(
      data.mobileVideo || [],
      cmsBaseUri
    );

    if (mobileVideoObject) {
      return mobileVideoObject.sources[0].src;
    }

    return '';
  };

  const mobileVideoHeight = extractProperty(data, 'mobileVideo[0].height', '');
  const mobileVideoWidth = extractProperty(data, 'mobileVideo[0].width', '');

  return {
    component: 'gemini/GeminiContentCard',
    attrs: {
      'data-id': data.id,
    },
    props: {
      htmlTag: extractProperty(data, 'htmlTag', '') ?? '',
      ctaTag: extractProperty(data, 'ctaTag', ''),
      pattern: extractProperty(data, 'cssPattern', ''),
      eyebrow: extractProperty(data, 'eyebrow', ''),
      badge: badgeImageObject(),
      heading1: extractProperty(data, 'heading1', ''),
      heading2: extractProperty(data, 'heading2', ''),
      headingUrl: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'headingUrl')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      headingTarget: extractProperty(data, 'headingUrl[0].type', ''),
      copy: extractProperty(data, 'copy1', ''),
      img1Url: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'imgUrl1')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      img1Desktop: img1Object(),
      img1DesktopH: extractProperty(
        data,
        'mainImage[0].height',
        ''
      )?.toString(),
      img1DesktopW: extractProperty(data, 'mainImage[0].width', '')?.toString(),
      img1Tablet: img1Object(),
      img1TabletH: extractProperty(data, 'mainImage[0].height', '')?.toString(),
      img1TabletW: extractProperty(data, 'mainImage[0].width', '')?.toString(),
      img1Mobile: img1Mobile
        ? getImageObject(img1Mobile, siteConfiguration, null, cmsBaseUri)?.small
        : img1Object(),
      img1MobileH: extractProperty(
        data,
        'mobileImage[0].height',
        ''
      )?.toString(),
      img1MobileW: extractProperty(
        data,
        'mobileImage[0].height',
        ''
      )?.toString(),
      img1Alt: extractProperty(data, 'mainImage[0].alt', ''),
      img1Target: extractProperty(data, 'imgUrl1[0].type', ''),
      img2Url: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'imgUrl2')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      img2: img2
        ? getImageObject(img2, siteConfiguration, null, cmsBaseUri)?.small
        : '',
      img2H: extractProperty(data, 'addImage[0].height', '')?.toString(),
      img2W: extractProperty(data, 'addImage[0].width', '')?.toString(),
      img2Alt: extractProperty(data, 'addImage[0].alt', '')?.toString(),
      img2Target: extractProperty(data, 'imgUrl2[0].type', ''),
      img3Url: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'imgUrl3')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      img3: img3
        ? getImageObject(img3, siteConfiguration, null, cmsBaseUri)?.small
        : '',
      img3H: extractProperty(data, 'addImage2[0].height', '')?.toString(),
      img3W: extractProperty(data, 'addImage2[0].width', '')?.toString(),
      img3Alt: extractProperty(data, 'addImage2[0].alt', '')?.toString(),
      img3Target: extractProperty(data, 'imgUrl3[0].type', ''),
      cta1Type: extractProperty(data, 'ctaType1', ''),
      cta1Copy: extractProperty(data, 'ctaCopy1', ''),
      cta1Url: ctaUrl1
        ? generateLinkFromTarget(
            ctaUrl1,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      cta1HasIcon: extractProperty(data, 'ctaHasIcon1', false),
      cta1Target: extractProperty(data, 'ctaUrl1[0].type', ''),
      cta2Type: extractProperty(data, 'ctaType2', ''),
      cta2Copy: extractProperty(data, 'ctaCopy2', ''),
      cta2Url: ctaUrl2
        ? generateLinkFromTarget(
            ctaUrl2,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      cta2HasIcon: extractProperty(data, 'ctaHasIcon2', false),
      cta2Target: extractProperty(data, 'ctaUrl2[0].type', ''),
      cta3Copy: extractProperty(data, 'ctaCopy3', false),
      cta3HasIcon: extractProperty(data, 'ctaHasIcon3', false),
      cta3Type: extractProperty(data, 'ctaType3', false),
      cta3Url: ctaUrl3
        ? generateLinkFromTarget(
            ctaUrl3,
            context,
            siteConfiguration,
            cmsBaseUri
          )
        : '',
      vidDesktop: getMainVideo(),
      vidDesktopH: mainVideoHeight ? mainVideoHeight.toString() : '',
      vidDesktopW: mainVideoWidth ? mainVideoWidth.toString() : '',
      vidMobile: getMobileVideo(),
      vidMobileH: mobileVideoHeight ? mobileVideoHeight.toString() : '',
      vidMobileW: mobileVideoWidth ? mobileVideoWidth.toString() : '',
    },
  };
};
