import { extractProperty } from './extractProperty';

export const getTeaserStyle = (item: any, value: string) => {
  return extractProperty(item, `teaserStyleCustomizations.${value}`);
};

export const getTeaserStyles = (item: any) => {
  return {
    teaserClass: getTeaserStyle(item, 'teaserClassModifiers'),
    titleColor: getTeaserStyle(item, 'teasterTitleColor'),
    titleFontSize: getTeaserStyle(item, 'teaserTitleFontSize'),
    titleFontFamily: getTeaserStyle(item, 'teaserTitleFontFace'),
    titleFontWeight: getTeaserStyle(item, 'teaserTitleFontWeight'),
    titleBackgroundColor: getTeaserStyle(item, 'teaserTitleBackgroundColor'),
    subtitleColor: getTeaserStyle(item, 'teaserSubTitleColor'),
    subtitleFontSize: getTeaserStyle(item, 'teaserSubTitleFontSize'),
    subtitleFontWeight: getTeaserStyle(item, 'teaserSubTitleFontWeight'),
    subtitleFontFamily: getTeaserStyle(item, 'teaserSubTitleFontFace'),
    subtitleBackgroundColor: getTeaserStyle(
      item,
      'teaserSubTitleBackgroundColor'
    ),
    richTextColor: getTeaserStyle(item, 'teaserTextColor'),
    richTextBackgroundColor: getTeaserStyle(item, 'teaserTextBackgroundColor'),
  };
};
