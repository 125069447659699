import { CMCollection, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getDynamicField,
  getStateManagementData,
  getTranslation,
} from './utils';
import { ComposableContext, CmsSanitizationRequirements } from '../types';
import * as mappings from '../mappings';
import * as plainMappings from '../mappings/plain';
import { camelCase } from '../dataTransformators/utils/camelCase';
import { getTeaserStyle } from './utils/getTeaserStyle';

const optionsMapperFn = (value) => (value === 'auto' ? value : Number(value));

const mapResponsiveOptions = (option) =>
  Object.fromEntries(
    Object.entries(option).map(([k, v]) => [k, optionsMapperFn(v)])
  );

export const carousel = (
  data: CMCollection,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const controlsPosition = getDynamicField(data, 'controlsPosition', 'bottom');
  const isFullHeight = getDynamicField(data, 'isFullHeight', false);
  const carouselParams = { controlsPosition, isFullHeight };
  let carouselComponent = 'carousel/Carousel';

  const customsViewTypes = [
    'customsHpSlide',
    'customsImageSlide',
    'customsProductSlide',
  ];

  const slides = data.teasableItems.reduce((result, item) => {
    let mappingMethod;
    const viewType = camelCase(item.viewtype);

    if (!item?.viewtype && item?.type && plainMappings[item.type]) {
      mappingMethod = plainMappings[item.type];
    } else if (item.viewtype) {
      mappingMethod = mappings[viewType];
    }

    if (mappingMethod) {
      const componentMapping = mappingMethod(
        item,
        context,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        carouselParams
      );

      // Is this a Vans Customs component?
      const isCustoms = customsViewTypes.includes(viewType);
      carouselComponent = isCustoms
        ? 'carousel/CustomsCarousel'
        : carouselComponent;

      // Disable lazy loading in image component if imagePreloading = true
      if (viewType === 'image') {
        componentMapping.props.lazy = !getDynamicField(
          data,
          'imagePreloading',
          true
        );
      }

      result.push({
        component: 'carousel/CarouselSlide',
        props: {
          _states: getStateManagementData(item),
        },
        children: [componentMapping],
      });

      return result;
    }

    console.warn(
      `There is no mapping for this carousel slide component view-type: ${item.viewtype} | id: ${item.id} | name: ${item.name}`
    );
    return result;
  }, []);

  const slidesPerView = getDynamicField(data, 'slidesPerView', {});
  const spaceBetween = getDynamicField(data, 'spaceBetween', {});

  return {
    component: carouselComponent,
    props: {
      controlsPosition,
      isFullHeight,
      slidesPerView: mapResponsiveOptions(slidesPerView),
      spaceBetween: mapResponsiveOptions(spaceBetween),
      showArrows: getDynamicField(data, 'showArrows', false),
      portraitOnly: getDynamicField(data, 'portraitOnly', false),
      slideDuration: getDynamicField(data, 'slideDuration', 2000) || 2000,
      autoplay: getDynamicField(data, 'autoplay', false),
      loop: getDynamicField(data, 'loop', false),
      controlsType: getDynamicField(data, 'controlsType', 'dots'),
      translations: {
        ctaText: getTranslation(data, 'ctaText', '$Rotate to customize$'),
        iconAltText: getTranslation(
          data,
          'iconAltText',
          '$Rotate to customize icon$'
        ),
        accessibility: getTranslation(data, 'accessibility', {
          carouselTitleLabel: '$Carousel.$',
          carouselNavPreviousSlideLabel: '$Go to previous slide.$',
          carouselNavNextSlideLabel: '$Go to next slide.$',
        }),
      },
      title: extractProperty(data, 'teaserTitle'),
      titleStyles: {
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        titleBackgroundColor: getTeaserStyle(
          data,
          'teaserTitleBackgroundColor'
        ),
        titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        titleStyle: getTeaserStyle(data, 'teaserTitleHeadingStyle'),
        level: getTeaserStyle(data, 'teaserTitleHeadingLevel'),
      },
    },
    children: slides,
  };
};
