import { BrandifyStoreData } from '../../api-types';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios } from '../../helpers';

/**
 * @param {BrandifyStoreData} body - object.
 * @return {Promise<any>}
 */
export const getCountriesList = (
  configuration: ApiClientConfiguration
) => async (body: BrandifyStoreData): Promise<any> => {
  return axios.post(endpoints.get(configuration).brandify.getlist, body);
};
