import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ProductReviewVoteType } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const addReviewVote = (configuration: ApiClientConfiguration) => async (
  productId: string,
  reviewId: number,
  voteType: ProductReviewVoteType
): Promise<any> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).product.addReviewVote,
    {
      config: configuration,
      mapping: {
        productId,
        reviewId: String(reviewId),
      },
    }
  );

  const response = await axios.post(
    url,
    {
      voteType: voteType,
    },
    {
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
