import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

type DeleteItemBody = {
  cartId: string;
  itemId: string;
};

export const deleteItem = (configuration: ApiClientConfiguration) => (
  data: DeleteItemBody,
  query: string
): Promise<any> => {
  return axios.delete(
    urlBuilder(`${endpoints.get(configuration).cart.deleteItem}?${query}`, {
      config: configuration,
    }),
    {
      data: {
        cartId: data.cartId,
        itemId: data.itemId,
      },
      headers: headerBuilder(configuration),
    }
  );
};
