import { EventPropsHandler } from '.';
import {
  EnhancedSaleEvent,
  EnhancedSaleType,
  GtmEnhancedSaleImpressionProduct,
} from '../../types/gtm';
import { ProductCrossSellItem, ProductUpsellItem } from '@vf/api-client';

export const mapProductImpression = (
  product: ProductCrossSellItem | ProductUpsellItem,
  {
    brand,
    list,
    position,
    useColorOnId,
  }: {
    brand: string;
    list: string;
    position: number;
    useColorOnId: boolean;
  }
): GtmEnhancedSaleImpressionProduct => ({
  brand,
  id: useColorOnId ? product.id + product.colorCode : product.id,
  colorCode: product.colorCode,
  list,
  name: product.name,
  onSale: product.price.original !== product.price.current,
  originalPrice: product.price.original,
  price: product.price.current,
  saleDiscountAmount: product.price.discount || 0,
  position: position + 1,
  styleCode: product.styleCode,
  customized: false,
});

export const enhancedSaleHandler: EventPropsHandler<
  EnhancedSaleEvent,
  {
    type: EnhancedSaleType;
    products: ProductCrossSellItem[] | ProductUpsellItem[];
  }
> = (eventProps, vmInstance) => {
  const eventName =
    eventProps.overrideAttributes.type === EnhancedSaleType.UP_SELL
      ? 'UpSellCompleteTheLook'
      : 'CrossSellCompleteTheLook';
  const brand = vmInstance
    .$getEnvValueByCurrentLocale<string>('API_SITE_ID')
    .toUpperCase();
  const useColorOnId = vmInstance.$themeConfig.gtm?.useColorOnId;
  const currencyCode =
    eventProps.overrideAttributes.products?.[0].price.currency;

  const ecommerce = {
    currencyCode,
    impressionsRec: eventProps.overrideAttributes.products.map(
      (product, index) =>
        mapProductImpression(product, {
          brand,
          list: eventName,
          position: index,
          useColorOnId,
        })
    ),
  };

  return {
    event: eventName,
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Impressions',
    nonInteractive: true,
    ecommerce,
    customMetrics: {},
    customVariables: {},
    _clear: true,
  };
};
