import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

const mapPayloadForCart = (payload) => {
  return {
    cartId: payload.cartId,
    productId: payload.productId,
    qty: parseInt(payload.qty),
    sku: payload.sku,
    maxQty: parseInt(payload.maxQty),
    ...(payload.recipeId && { recipeId: payload.recipeId }),
    ...(payload.precreatedCustomsCode && {
      precreatedCustomsCode: payload.precreatedCustomsCode,
    }),
    ...(payload.pdpUrl && { pdpUrl: payload.pdpUrl }),
    ...(payload.productImageURL && {
      productImageURL: payload.productImageURL,
    }),
    ...(payload.saveForLater && { saveForLater: payload.saveForLater }),
    ...(payload.storeId && { storeId: payload.storeId }),
  };
};

export const addItem = (configuration: ApiClientConfiguration) => (
  data: unknown,
  { query = '' } = {}
): Promise<any> => {
  const body = Array.isArray(data)
    ? data.map(mapPayloadForCart)
    : mapPayloadForCart(data);
  return axios.post(
    urlBuilder(
      `${endpoints.get(configuration).cart.addItem}${query ? '?' + query : ''}`,
      {
        config: configuration,
      }
    ),
    body,
    {
      headers: headerBuilder(configuration),
    }
  );
};
