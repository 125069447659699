import { Plugin } from '@nuxt/types';
import { load } from '@vf/shared/src/utils/helpers/load-script';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useOrderStore } from '@vf/composables/src/store/order';

const ForterPlugin: Plugin = (context) => {
  const featureFlags = useFeatureFlagsStore();
  if (!featureFlags.allowForterSessionToken) return;

  document.addEventListener('ftr:tokenReady', (evt: any) => {
    useOrderStore().forterToken = evt.detail;
  });

  const siteId = context.$config.FORTER_SITE_ID;
  if (siteId) {
    load('/forter.js', null, null, {
      id: siteId,
      append: 'body',
    });
  } else {
    context.app.$log.error('missing Forter siteId');
  }
};

export default ForterPlugin;
