














































































































































































































































































import { watch, ref } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { AnalyticsDataDomLocation } from '@/types';
import { isCategorySegment } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default {
  name: 'MobileMenuAccordion',
  components: {
    LoyaltyRewardsProgress: () =>
      import('../loyalty/LoyaltyRewardsProgress.vue'),
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
    shopAll: {
      type: String,
      default: '',
    },
    mobileMenuSettings: {
      type: Object,
      default: () => ({}),
    },
    displayLabel: {
      type: Function,
      default: () => undefined,
    },
    showShopAll: {
      type: Function,
      default: () => undefined,
    },
    handleMobileNavLinkClick: {
      type: Function,
      default: () => undefined,
    },
    loyaltyRewardsProgressTranslations: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig;
    const openL1Items = ref([]);
    const {
      isPointsToCurrencyEnabled,
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();
    const userStore = useUserStore(root);
    const { loyaltyEnrolled } = storeToRefs(userStore);

    const {
      hideShopAllText,
      alwaysShowIcon,
      shopAllIcon,
      showTopDivider,
      onlyAllowOneOpenL1Item,
    } = theme.header;

    const accordionIcon = {
      type: isCoreRedesignEnabled
        ? theme.header.accordionIconTypeRedesign
        : theme.header.accordionIconType,
      size: theme.header.accordionIconSize,
    };

    const trackOpenAccordionItems = (data) => {
      const level1Item = props.navigation.find(
        (item) => item['data-id'] === data.identifier
      );

      if (!level1Item) return;

      // l1 item clicked
      openL1Items.value =
        level1Item === openL1Items.value[0] ? [] : [level1Item];

      // scroll clicked item into view
      if (
        data.identifier &&
        !data.opened &&
        data.identifier !== props.navigation[0]['data-id']
      ) {
        root.$nextTick(() => {
          document.getElementById(data.identifier).scrollIntoView(true);
        });
      }
    };
    const handleAccordionToggle = (
      data: {
        header: string;
        opened: boolean;
        identifier: string;
      },
      parentTitle?: string
    ) => {
      onlyAllowOneOpenL1Item && trackOpenAccordionItems(data);

      emit('nav-toggle-accordion', {
        opened: data.opened,
        l1: parentTitle || data.header,
        l2: parentTitle ? data.header : '',
      });
    };

    const getShopAllLabel = (mobileTitle, title, settings = null) => {
      if (settings) {
        return settings['shop-all-button-mobile'];
      }
      return (props.shopAll || '').replace('{0}', mobileTitle || title);
    };

    const handleMobileNavLinkClickAndRedirect = (e, data) => {
      props.handleMobileNavLinkClick(e, data);
      if (data.openInNewTab || data.link.startsWith('https')) {
        window.open(data.link, data.openInNewTab ? '_blank' : '_self');
      } else {
        root.$router.push(data.link);
      }
    };

    const formatMobileMenuLink = (l2) => {
      const isCategoryUrl = isCategorySegment(l2.link);

      const template =
        l2.autoGeneratedL2LinkText ??
        props.mobileMenuSettings[
          isCategoryUrl
            ? 'autoGeneratedL2LinksCategoryText'
            : 'autoGeneratedL2LinksPageText'
        ];

      return template.replace('{0}', l2.mobileTitle || l2.title);
    };

    const shouldUseAutoGeneratedL2LinksOnMobile = (l2) => {
      return [
        l2.autoGeneratedL2LinkSmall,
        props.mobileMenuSettings.autoGeneratedL2LinksSmall,
      ].some((setting) => setting === 'top');
    };
    const shouldUseAutoGeneratedL2Click = (l2) => {
      return (
        shouldUseAutoGeneratedL2LinksOnMobile(l2) && l2.children?.length >= 1
      );
    };
    const getIcon = (l2) => {
      return !shouldUseAutoGeneratedL2Click(l2) ? 'none' : 'arrow';
    };

    const l2Icon = isCoreRedesignEnabled ? 'arrow' : 'cross';

    watch(
      () => props.navigation,
      (items) => {
        openL1Items.value = items.filter((item) => {
          return item.accordionOpenByDefault[
            'accordion-open-by-default-mobile'
          ];
        });
      },
      { immediate: true, deep: true }
    );

    return {
      openL1Items,
      accordionIcon,
      handleAccordionToggle,
      onlyAllowOneOpenL1Item,
      hideShopAllText,
      AnalyticsDataDomLocation,
      alwaysShowIcon,
      shopAllIcon,
      showTopDivider,
      getShopAllLabel,
      handleMobileNavLinkClickAndRedirect,
      getIcon,
      shouldUseAutoGeneratedL2LinksOnMobile,
      shouldUseAutoGeneratedL2Click,
      formatMobileMenuLink,
      isPointsToCurrencyEnabled,
      loyaltyEnrolled,
      isCoreRedesignEnabled,
      l2Icon,
    };
  },
};
