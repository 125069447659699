import { CMContentTextBlock, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getTeaserTargets,
  decorateHtmlValue,
  extractComponentStyleDefinitions,
} from './utils';
import { ComposableContext } from '../types';

export const easyTextBlock = (
  data: CMContentTextBlock,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { ctas } = getTeaserTargets(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );
  return {
    component: 'shared/ContentTextBlock',
    props: {
      title: extractComponentStyleDefinitions(data, 'teaserTitle'),
      subtitle: extractComponentStyleDefinitions(
        data,
        'teaserSubTitle',
        'teaserSubtitle'
      ),
      bodyText: {
        content:
          decorateHtmlValue(
            data?.teaserRichTextObject,
            cmsBaseUri,
            _context,
            siteConfiguration
          ) || extractProperty(data, 'teaserText'),
        color: extractProperty(
          data,
          'componentStylesDefinitions.teaserTextColor.data.color'
        ),
        variant: extractProperty(data, 'componentStyles.teaserTextStyle'),
      },
      ctas,
    },
  };
};
