import {
  decorateHtmlValue,
  extractProperty,
  generateLinkFromTarget,
  generateLinkFromTeaserTargets,
  getDynamicField,
} from './utils';
import { CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { ctaButton } from './ctaButton';
import usePlaceholders from '../../usePlaceholders';
import {
  getPlaceholdersInObject,
  replacePlaceholdersInObject,
  SourceObj,
} from '../../utils/placeholders';

export const richtextCtas = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  let content: SourceObj = {
    title: extractProperty(data, 'teaserTitle'),
    subtitle: extractProperty(data, 'teaserSubtitle'),
    ctaAlign: getDynamicField(data, 'ctaAlign', 'left'),
    content:
      decorateHtmlValue(
        data.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      ) || extractProperty(data, 'teaserText'),
  };
  const {
    fields,
    placeholders,
    currentPlaceholders,
    currentPlaceholderValues,
  } = usePlaceholders(context.instance);

  const placeholdersFound = getPlaceholdersInObject(
    content,
    fields,
    placeholders
  );

  const includedInCurrentPlaceholders = (placeholder) =>
    currentPlaceholders.value.includes(placeholder);

  const hasPlaceholders = !!placeholdersFound.length;
  const hasAnyCurrentPlaceholder =
    hasPlaceholders && placeholdersFound.some(includedInCurrentPlaceholders);
  const hasAllCurrentPlaceholders =
    hasPlaceholders && placeholdersFound.every(includedInCurrentPlaceholders);

  if (hasAnyCurrentPlaceholder)
    content = replacePlaceholdersInObject(
      content,
      fields,
      currentPlaceholderValues.value
    ) as SourceObj;

  return {
    component: 'shared/RichtextCtas',
    props: {
      ...content,
      links: data.teaserTargets.map((link) => {
        const mappedLink = {
          id: link.target.id,
          text: link.callToActionText || link.target['teaserTitle'],
          cta: {},
          link: link.target.teaserTargets
            ? generateLinkFromTeaserTargets(
                link.target.teaserTargets,
                context,
                siteConfiguration
              )
            : generateLinkFromTarget(
                link.target,
                context,
                siteConfiguration,
                cmsBaseUri
              ),
        };
        if (link.target['viewtype'] === 'cta-button') {
          mappedLink.cta = ctaButton(
            extractProperty(link, 'target'),
            context,
            siteConfiguration,
            cmsBaseUri
          ).props;
        }
        return mappedLink;
      }),
    },
    replacePlaceholders: hasPlaceholders && !hasAllCurrentPlaceholders,
  };
};
