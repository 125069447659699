import { Plugin } from '@nuxt/types';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import Vue from 'vue';

interface Observer {
  isSmall: boolean;
  isMobileDevice: boolean;
  size: 'small' | 'medium' | 'large';
  breakpoint: {
    [key in BreakpointKeys]: boolean;
  } & {
    current: BreakpointKey | null;
  };
}

enum BreakpointKeys {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  smUp = 'smUp',
  mdUp = 'mdUp',
  lgUp = 'lgUp',
  smDown = 'smDown',
  mdDown = 'mdDown',
  lgDown = 'lgDown',
}

type BreakpointKey = keyof typeof BreakpointKeys;

interface BreakpointThresholds {
  small: number;
  medium: number;
  large: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

const getMediaQueryString = (
  fromKey: BreakpointKey | undefined,
  toKey?: BreakpointKey
) => {
  if (!fromKey) {
    return getMaxWidth(breakpointThresholds[toKey]);
  }
  if (!toKey) {
    return getMinWidth(breakpointThresholds[fromKey]);
  }
  return `${getMinWidth(breakpointThresholds[fromKey])} and ${getMaxWidth(
    breakpointThresholds[toKey]
  )}`;
};

const getMaxWidth = (breakpoint: number): string =>
  `(max-width: ${breakpoint - 1}px)`;
const getMinWidth = (breakpoint: number): string =>
  `(min-width: ${breakpoint}px)`;

//ToDo: remove old small/medium/large keys and refactor code
export const breakpointThresholds: BreakpointThresholds = {
  small: 320,
  medium: 1024,
  large: 1440,
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1440,
  xl: 1920,
};

const mediaQueries = {
  xs: getMediaQueryString('xs', 'sm'),
  sm: getMediaQueryString('sm', 'md'),
  md: getMediaQueryString('md', 'lg'),
  lg: getMediaQueryString('lg', 'xl'),
  xl: getMediaQueryString('xl'),
  smUp: getMediaQueryString('sm'),
  mdUp: getMediaQueryString('md'),
  lgUp: getMediaQueryString('lg'),
  smDown: getMediaQueryString(undefined, 'sm'),
  mdDown: getMediaQueryString(undefined, 'md'),
  lgDown: getMediaQueryString(undefined, 'lg'),
  small: getMaxWidth(breakpointThresholds.medium),
  medium: `${getMinWidth(breakpointThresholds.medium)} and ${getMaxWidth(
    breakpointThresholds.large
  )}`,
  large: getMinWidth(breakpointThresholds.large),
};

const isMobile = () => {
  if (!process.server && navigator) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    )
      return true;
  }
  return false;
};

const breakpointDefaultState = {
  current: null,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  smUp: false,
  mdUp: false,
  lgUp: false,
  smDown: false,
  mdDown: false,
  lgDown: false,
};
const observer: Observer = Vue.observable({
  isSmall: false,
  isMobileDevice: isMobile(),
  size: 'large',
  breakpoints: breakpointThresholds,
  breakpoint: breakpointDefaultState,
  mediaQueries,
});

const onMatch = (key, e): void => {
  if (e.matches) {
    const isValidCurrentBreakpoint = Object.values(BreakpointKeys).includes(
      key
    );
    if (isValidCurrentBreakpoint) {
      observer.breakpoint.current = key;
      observer.breakpoint[key] = true;
    } else {
      observer.size = key;
    }
    observer.isSmall = observer.size === 'small';
    observer.isMobileDevice = isMobile();
    return;
  } else {
    observer.breakpoint[key] = false;
  }
};

const setupListener = () => {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined' ||
    !window.matchMedia ||
    !window.innerWidth
  ) {
    return;
  }

  for (const size of Object.keys(mediaQueries)) {
    const matchEvent = window.matchMedia(mediaQueries[size]);
    onMatch(size, matchEvent);

    try {
      // Chrome & Firefox
      matchEvent.addEventListener('change', onMatch.bind(null, size));
    } catch (e1) {
      console.error(
        'Error: Unable to bind event listener on matchEvent in Chrome & Firefox.',
        e1
      );
      try {
        // Safari
        matchEvent.addListener(onMatch.bind(null, size));
      } catch (e2) {
        console.error(
          'Error: Unable to bind event listener on matchEvent in your browser.',
          e2
        );
      }
    }

    const onMatchCallback = () => {
      onMatch(size, window.matchMedia(mediaQueries[size]));
    };

    window.addEventListener('DOMContentLoaded', onMatchCallback);

    const debouncedOnMatch = debounce(() => onMatchCallback, 300);

    window.addEventListener('resize', debouncedOnMatch);
  }
};

const ViewportObserverPlugin: Plugin = (context, inject) => {
  setupListener();
  inject('viewport', observer);
};

export default ViewportObserverPlugin;
