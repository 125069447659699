import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { TokensObject } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const signInGuest = (configuration: ApiClientConfiguration) => (
  usid: string
): Promise<AxiosResponse<TokensObject>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).authentication.signInGuest, {
      config: configuration,
    }),
    {
      type: 'Guest',
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
