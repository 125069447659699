import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const updateAcceptance = (configuration: ApiClientConfiguration) => ({
  usid = null,
  country = '',
  region = '',
} = {}): Promise<any> => {
  const { siteId } = configuration;
  return axios.put(
    urlBuilder(endpoints.get(configuration).customer.updateAcceptance, {
      config: configuration,
    }),
    {
      consumerProfile: {
        consumerDetails: {
          source: {
            store: siteId,
          },
        },
        enrollments: [
          {
            type: 'EmployeeDiscount',
            optin: true,
            channel: 'email',
            country,
            region,
          },
        ],
      },
    },
    {
      headers: headerBuilder(configuration, {
        'x-usid': usid,
      }),
    }
  );
};
