import { defineStore } from 'pinia';
import { SharedHeaderAndFooterState } from '../global/types';

export const useHeaderAndFooterStore = defineStore('headerAndFooter', {
  state: (): SharedHeaderAndFooterState => ({
    footer: null,
    header: null,
    errors: [],
    headerAndFooterData: null,
    headerVisible: true,
    footerVisible: true,
    headerConfig: {
      backgroundColor: '#FFF',
      isAnimated: false,
      isFadeEnabled: false,
      isSticky: true,
    },
    showRemoveItemNotificationInMinicart: false,
    showSticky: false,
    showHeader: true,
  }),
});
