import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { GetListingResultsResponse } from '../../api-types';
import { AxiosResponse } from 'axios';
import type { ApiClientConfiguration } from '../../configuration';

export const getSearchResults = (
  configuration: ApiClientConfiguration
) => async (
  query: string
): Promise<AxiosResponse<GetListingResultsResponse>> => {
  const url: string = urlBuilder(
    `${endpoints.get(configuration).search.getSearchResults}?${query}`,
    {
      config: configuration,
    }
  );
  const { logger } = configuration;
  logger.debug('Get Search Results', { url });
  const response = await axios.get<GetListingResultsResponse>(url, {
    headers: headerBuilder(configuration),
  });
  logger.debug('Get Search Results', { responseStatus: response.status });
  return response;
};
