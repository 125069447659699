import { apiClientFactory } from '@vf/api-client';
import {
  SetGuestSmsSubscriptionPayload,
  SetSmsSubscriptionPayload,
} from '@vf/api-client/src/api-types';

export const useSmsSubscription = (instance) => {
  const {
    setSubscription: setSubscriptionAPI,
    setSubscriptionGuest: setSubscriptionGuestAPI,
  } = apiClientFactory(instance);

  const prepareSubscriptionObject = ({
    phone,
    optin,
  }: {
    phone: string;
    optin: boolean;
  }): SetSmsSubscriptionPayload => ({
    type: 'Newsletter',
    optin,
    doubleOptin: false,
    channel: 'sms',
    recipient: phone,
    category: 'subscription',
    region: instance.context.$env.API_REGION,
    country: 'US', // TODO: Remove after api improvements
  });

  const setSubscription = (phone: string, optin: boolean) => {
    const subscriptionData = prepareSubscriptionObject({
      phone,
      optin,
    });

    return setSubscriptionAPI(subscriptionData);
  };

  const setSubscriptionGuest = (phone: string) => {
    const subscriptionData: SetGuestSmsSubscriptionPayload = {
      consumerProfile: {
        consumerDetails: {},
        subscriptions: [
          prepareSubscriptionObject({
            phone,
            optin: true,
          }),
        ],
      },
    };

    return setSubscriptionGuestAPI(subscriptionData);
  };

  return {
    setSubscription,
    setSubscriptionGuest,
  };
};
