import { CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { decorateHtmlValue, extractProperty, getTitle } from './utils';

export const accordionItem = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  accordionParams?: any
) => {
  return {
    component: 'accordion/AccordionItem',
    props: {
      id: extractProperty(data, 'id'),
      title: getTitle(data),
      text: decorateHtmlValue(
        data.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      ),
      iconType: accordionParams?.iconType ?? '',
    },
  };
};
