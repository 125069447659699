import { storeToRefs } from 'pinia';
import { computed } from '@vue/composition-api';

import { apiClientFactory } from '@vf/api-client';
import { getEnvValueByLocale } from '@vf/env';

import { ComponentInstance } from '../types';
import { useAccount } from '../useAccount';
import { useCart } from '../useCart';
import useGtm from '../useGtm';
import { useI18n } from '../useI18n';
import { useLoyalty } from '../useLoyalty';
import { useRequestTracker } from '../useRequestTracker';
import { useRouting } from '../useRouting';
import useSignInToStore from '../useSignInToStore';
import { errorMessages } from '../utils/errorMessages';
import ls from '../utils/localStorage';
import pkce from '../utils/pkce';
import { ROUTES } from '../utils/routes';
import { useReCaptcha } from '../useReCaptcha';
import { getEventFromTemplate } from '../useGtm/helpers';
import { useConsumer } from './composables/useConsumer';
import { useGuestUser } from './composables/useGuestUser';
import { useLoyaltyEnrolled } from './composables/useLoyaltyEnrolled';
import { useSaveForLater } from './composables/useSaveForLater';
import { useUserToken } from './composables/useUserToken';
import { useUserStore } from '../store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

import { setWindowLocationHref } from '@vf/shared/src/utils/helpers';

export const useAuthentication = (instance: ComponentInstance) => {
  const userStore = useUserStore(instance);
  const { isCustomerLoggedIn, setUsid } = userStore;
  const { usid, loggedIn } = storeToRefs(userStore);
  const { isPointsToCurrencyEnabled, isBopisEnabled } = useFeatureFlagsStore();

  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { displayErrorMessages } = errorMessages(instance);
  const {
    confirmForgotPassword: confirmForgotPasswordAPI,
    signIn: signInAPI,
    signOut: signOutAPI,
    signUp: signUpAPI,
    signUpSimplified: signUpSimplifiedAPI,
    getUserToken: getUserTokenAPI,
    emailLookup: emailLookupAPI,
  } = apiClientFactory(instance);

  const {
    consumerType,
    consumerId,
    consumerNo,
    customerGroups,
    setConsumerNo,
    getSegments,
    isEmployee,
    setConsumerType,
  } = useConsumer(instance);

  const { signInGuest } = useGuestUser(instance);

  const {
    isLoyaltyEnabled,
    loyaltyEnrolledCookieName,
    setLoyaltyEnrolled,
    loyaltyEnrolled,
    processPendingActionForLoyaltyUser,
    setPendingActionForLoyaltyUser,
  } = useLoyaltyEnrolled(instance);

  const { saveForLaterId, setSaveForLaterId } = useSaveForLater(instance);
  const { getToken, setNewStateWithToken } = useUserToken(instance);

  const emailLookup = async (email: string) => {
    const { executeRecaptcha } = useReCaptcha(instance);

    try {
      const recaptchaToken = await executeRecaptcha('emailLookup');
      return await emailLookupAPI({ email, recaptchaToken, usid: usid.value });
    } catch (err) {
      /*
       * 404 === email not found
       * otherwise we have a legitimate error
       * which we want to display to the user
       */
      if (err.response.status !== 404) {
        displayErrorMessages(err);
      } else {
        throw err;
      }
    }
  };

  const signUp = async (data) => {
    const { executeRecaptcha } = useReCaptcha(instance);
    const locale = useI18n(instance).localeCode();
    const country = getEnvValueByLocale<string>(
      'COUNTRY',
      locale,
      instance.$env
    ).toUpperCase();

    if (isLoyaltyEnabled) {
      const { captureReferralCodeQueryParam } = useLoyalty(instance);
      // stash referral code for the loyalty token API call later
      captureReferralCodeQueryParam();
    }

    try {
      const recaptchaToken = await executeRecaptcha('register');
      return await signUpAPI(
        { ...data, locale, country, recaptchaToken },
        usid.value
      );
    } catch (err) {
      displayErrorMessages(err);
      throw err;
    }
  };

  const signUpSimplified = async (data) => {
    const { executeRecaptcha } = useReCaptcha(instance);
    const locale = useI18n(instance).localeCode();
    const country = getEnvValueByLocale<string>(
      'COUNTRY',
      locale,
      instance.$env
    ).toUpperCase();

    if (isLoyaltyEnabled) {
      const { captureReferralCodeQueryParam } = useLoyalty(instance);
      // stash referral code for the loyalty token API call later
      captureReferralCodeQueryParam();
    }

    try {
      const recaptchaToken = await executeRecaptcha('register');
      return await signUpSimplifiedAPI(
        { ...data, locale, country, recaptchaToken },
        usid.value
      );
    } catch (err) {
      displayErrorMessages(err);
      throw err;
    }
  };

  const signIn = async (
    data: {
      username: string;
      password: string;
      orderNo?: string;
      action?: string;
      st?: string;
      formLocation?: string;
      recaptchaToken?: string;
      guestObjects: {
        basketId: string;
        saveForLaterId: string;
        favoriteId: string;
      };
    },
    isSignUp?: boolean
  ) => {
    const { codeChallenge } = await pkce.generate();

    if (!usid.value || usid.value === 'null') {
      console.error(
        'Usid has a null value. Please generate usid once again by clearing site data and refreshing the page.'
      );
      return;
    }

    const { captureReferralCodeQueryParam, getLoyaltyVouchers } = useLoyalty(
      instance
    );

    if (isLoyaltyEnabled) {
      // stash referral code for the loyalty token API call later
      captureReferralCodeQueryParam();
    }

    const { tag } = trackRequest('useAuthentication-signIn');
    const { executeRecaptcha } = useReCaptcha(instance);

    try {
      const recaptchaToken = await executeRecaptcha('login');
      const signInResult = await signInAPI({
        usid: usid.value,
        username: data.username,
        password: data.password,
        recaptchaToken,
        codeChallenge,
      });
      const { loadCart, resetCart, updateCartForBopis } = useCart(instance);
      const { setFavoriteStoreId } = useAccount(instance);
      if (!isSignUp) resetCart();

      const codeVerifier = ls.getItem('codeVerifier');

      const token = await getToken(
        {
          usid: signInResult.data.usid,
          authorizationCode: signInResult.data.authorizationCode,
          codeVerifier,
          st: data.st,
          orderNo: data.orderNo,
          action: data.action,
        },
        {
          ...data.guestObjects,
          guestId: consumerId.value,
        }
      );

      setNewStateWithToken(token.data);

      if (!data.orderNo && !isSignUp) {
        const storeId = token.data?.favorites?.favStoreId;
        if (isBopisEnabled && storeId) {
          setFavoriteStoreId(storeId);
          await updateCartForBopis();
        } else {
          await loadCart({
            isBackgroundRequest: false,
            isTemporary: false,
            inventorySupplyCheck: true,
          });
        }
      }

      if (isPointsToCurrencyEnabled && loyaltyEnrolled.value) {
        getLoyaltyVouchers();
      }

      loggedIn.value = true;
      instance.$eventBus.$emit('login');

      return signInResult;
    } catch (e) {
      displayErrorMessages(e);
      throw e;
    } finally {
      clearRequest(tag);
    }
  };

  const changePassword = async (data) => {
    // TODO WRONG NAME IT's CONFIRM FORGOT PASSWORD
    return confirmForgotPasswordAPI(data).catch((e) => {
      displayErrorMessages(e);
      throw e;
    });
  };

  const resetUser = () => {
    const { dispatchEvent } = useGtm(instance);
    dispatchEvent(getEventFromTemplate('logout:confirmed'));
    instance.$eventBus.$emit('logout');

    useCart(instance).resetCart();
    dispatchEvent(getEventFromTemplate('cart:update'));

    ls.clearStorage();
    setUsid('');
    setConsumerType(null);
    loggedIn.value = false;
    instance.$cookies.remove(loyaltyEnrolledCookieName);

    useAccount(instance).clearAccountData();
    useLoyalty(instance).clearLoyaltyData();
  };

  const isProtectedPath = (path: string = '') => {
    const protectedPaths = [
      ROUTES.ACCOUNT(),
      ROUTES.ACCOUNT_FAMILY(),
      ROUTES.ACCOUNT_PROFILE(),
      ROUTES.RETURNS(),
      ROUTES.RETURN_CONFIRMATION(),
      ROUTES.RETURN_DETAILS(),
      ROUTES.RETURN_HISTORY(),
      ROUTES.RETURN_ORDER(),
      ROUTES.PROFILE(),
      ROUTES.INTERESTS(),
      ROUTES.ADDRESS_BOOK(),
      ROUTES.NEW_SHIPPING_ADDRESS(),
      ROUTES.NEW_BILLING_ADDRESS(),
      ROUTES.CREDIT_CARDS(),
      ROUTES.ORDER_HISTORY(),
      ROUTES.ORDER_DETAILS(),
      ROUTES.FAVORITES(),
      ROUTES.FAVORITES_PRIVATE(),
    ];

    return !!protectedPaths.find((protectedPath) =>
      path.includes(protectedPath)
    );
  };

  const handleSignOutToCurrentPage = () => {
    const { localePath } = useI18n(instance);
    const { currentRoute } = useRouting(instance);

    let currentPath = currentRoute.value?.fullPath || window.location.href;
    if (isProtectedPath(currentPath)) {
      currentPath = null;
    }
    setWindowLocationHref(currentPath ?? localePath(ROUTES.HOME()));
  };

  const signOut = async (redirectPath?: string, skipRedirect = false) => {
    try {
      await signOutAPI();
      const { employeeConnected, employeeSignOut } = useSignInToStore(instance);
      if (employeeConnected.value) {
        employeeSignOut();
      }

      resetUser();

      if (skipRedirect) {
        // signInGuest is invoked in session.client.ts plugin so we need to invoke it manually only if redirection is skipped
        await signInGuest();
        return;
      }

      if (redirectPath) {
        setWindowLocationHref(redirectPath);
        return;
      }

      handleSignOutToCurrentPage();
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const isWranxProsumer = computed(() =>
    customerGroups.value.includes('ProsumersWranx')
  );

  const isIpaProsumer = computed(() =>
    customerGroups.value.includes('ProsumersIPA')
  );

  const handleRefreshToken = async () => {
    const tokenResponse = await getUserTokenAPI('refresh', {
      usid: usid.value,
    });

    if (tokenResponse.data.usid === null) {
      instance.$log.error(
        `[@useAuthentication/index::handleRefreshToken] usid from token data is missing`,
        tokenResponse.data.usid
      );
      return;
    }

    setNewStateWithToken(tokenResponse.data);
  };

  return {
    emailLookup,
    signUp,
    signUpSimplified,
    signIn,
    changePassword,
    signInGuest,
    signOut,
    isCustomerLoggedIn,
    setConsumerNo,
    getSegments,
    setSaveForLaterId,
    setLoyaltyEnrolled,
    processPendingActionForLoyaltyUser,
    setPendingActionForLoyaltyUser,
    isIpaProsumer,
    isProtectedPath,
    isWranxProsumer,
    loyaltyLegacyUser: computed(() => loggedIn.value && !loyaltyEnrolled.value),
    getUsid: computed(() => usid.value),
    consumerNo: computed(() => consumerNo.value),
    consumerType: computed(() => consumerType.value),
    getConsumerId: computed(() => consumerId.value),
    saveForLaterId: computed(() => saveForLaterId.value),
    isEmployee,
    handleRefreshToken,
    customerGroups,
  };
};
