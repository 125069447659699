import { ComponentInstance } from '../types';
import {
  useAuthentication,
  useProduct,
  useCart,
  useNotification,
  useGtm,
  useUpsell,
  useI18n,
} from '../index';
import { OrderDetails, OrderItemDetailed } from '@vf/api-contract';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';

type UseFitFinder = {
  exposeFitFinderData: (source: any, order?: any) => void;
  removeFitFinderData: (source: any) => void;
  addToCartByFitFinder: (productId: string, size: string) => void;
  attemptToAddProduct: () => Promise<boolean>;
  dispatchAddToCartEvent: () => void;
  toggleMinicartOnItemAdding: () => void;
};

const useFitFinder = (instance: ComponentInstance): UseFitFinder => {
  const { product, pdpImages, configureSize } = useProduct(
    instance,
    'page-content'
  );
  const { upsellItem, isUpsellSelected } = useUpsell(instance, 'page-content');
  const { getConsumerId } = useAuthentication(instance);
  const { addItem, setMiniCart } = useCart(instance);
  const { clearNotifications } = useNotification(instance);
  const { dispatchEvent } = useGtm(instance);
  const { localeCode } = useI18n(instance);

  const attemptToAddProduct = async (): Promise<boolean> => {
    return await addItem(
      isUpsellSelected.value
        ? [product.value, upsellItem.value]
        : product.value,
      { isTemporary: false }
    );
  };

  const dispatchAddToCartEvent = () => {
    dispatchEvent({
      ...getEventFromTemplate('cart:add', {}),
      composablesContexts: { useProduct: 'page-content' },
      overrideAttributes: {
        productId: product.value?.id,
        quantity: product.value?.quantity.value,
        availSizes: product.value?.sizes
          .filter((size) => size.available)
          .map((size) => size.value),
        availZips: product.value?.zips
          .filter((zip) => zip.available)
          .map((zip) => zip.value),
        availWidths: product.value?.widths
          .filter((width) => width.available)
          .map((width) => width.value),
        avgRating: product.value?.rating?.score,
        numReviews: product.value?.rating?.numReviews,
        customModel: product.value?.customModel ?? '',
        customsTool: product.value?.customsTool ?? '',
        isDummyCustom: product.value?.dummyCustoms,
        customsRecipeID: product.value?.customsRecipeID,
        sku: product.value?.sku,
      },
    });
    dispatchEvent(getEventFromTemplate('cart:update', {}));
  };

  const toggleMinicartOnItemAdding = () => {
    const closeMiniCartDelay =
      instance.$themeConfig.productAddToCart.closeMiniCartDelay ||
      instance.$viewport?.isSmall
        ? 6000
        : undefined;
    setMiniCart(true, closeMiniCartDelay);
  };

  const addToCartByFitFinder = async (productId, size) => {
    if (productId !== product.value.id) {
      return;
    }
    configureSize(size, 'page-content');
    clearNotifications();
    const isAddedToCart = await attemptToAddProduct();
    if (isAddedToCart) {
      dispatchAddToCartEvent();
      toggleMinicartOnItemAdding();
    }
  };

  const getPurchasedProducts = (orderItems: OrderItemDetailed[]) => {
    const purchasedProducts = orderItems.map((item) => {
      const purchasedSize = item.variants.find(
        (variant) => variant.code === 'size'
      )?.value;
      return {
        purchasedSize,
        shopArticleCode: item.sku, // size-level ID -> in our case it's {{sku}} property of the ordered item
        productId: item.masterId, // color-level ID -> in out case it's {{masterId}} property of the ordered item
        price: item.price.amount, // per item
        quantity: item.qty, // number of purchased items
      };
    });
    return purchasedProducts;
  };

  const exposeFitFinderData = (target: any, order?: OrderDetails) => {
    const nothingToExpose = !product.value && !order;
    if (nothingToExpose || instance.$env.EXPOSE_FIT_FINDER_DATA !== 'true') {
      return;
    }
    const [shopLanguage, shopCountry] = localeCode().split('-');
    if (!order) {
      const mainThumbnail =
        typeof pdpImages.value[0]?.thumbnail === 'string'
          ? pdpImages.value[0]?.thumbnail
          : pdpImages.value[0]?.thumbnail.large;
      target.fitAnalyticsData = {
        shopLanguage, // ISO 639-1, e.g. 'en'
        shopCountry, // ISO 3166-1, e.g. 'GB'
        mainThumbnail, // URL to thumbnail to be displayed within the widget
        userId: getConsumerId.value, // user ID, not email
        currentProductId: product.value.slug, // current color-level ID -> in our case in PDP it's the {{slug}} property
        allProductIds: product.value.colors.map((item) => item.slug), // all color-level IDs on the page -> slugs of all color variants
        sizes: product.value.sizes.map((item) => {
          return {
            value: item.value,
            isAvailable: item.available,
          };
        }),
        operations: {
          /**
           * This function should add the given size to cart for the given product id (basically mimic the add to cart button behavior, including opening the minicart)
           * @param {string} productId
           * @param {string} size
           */
          addToCart: addToCartByFitFinder,
        },
      };
      return;
    }
    const purchasedProducts = getPurchasedProducts(order.items);

    target.fitAnalyticsData = {
      orderId: order.st,
      shopLanguage, // ISO 639-1, e.g. 'en'
      shopCountry, // ISO 3166-1, e.g. 'GB'
      currency: order.totals.currency, // ISO 4217, e.g. 'EUR'
      userId: getConsumerId.value, // user ID, not email
      products: [...purchasedProducts],
    };
  };

  const removeFitFinderData = (source: any) => {
    source.fitAnalyticsData = undefined;
  };
  return {
    removeFitFinderData,
    exposeFitFinderData,
    attemptToAddProduct,
    dispatchAddToCartEvent,
    toggleMinicartOnItemAdding,
    addToCartByFitFinder,
  };
};

export default useFitFinder;
