import { computed } from '@nuxtjs/composition-api';

import { apiClientFactory } from '@vf/api-client';
import { Address } from '@vf/api-client/src/types';
import { AddressValidationResponse } from '@vf/api-client/src/api-types';
import { CartAddressRequest } from '@vf/api-contract';
import { stripPhoneAndPrependCountryCallingCode } from '@vf/shared/src/utils/helpers';
import cloneDeep from '@vf/shared/src/utils/cloneDeep';
import { ApplePayContext, useApplePayPdpCart, useCart } from '@vf/composables';

import { prepareTemporaryBasket } from '../../../utils/query';
import { getEmptyAddressObject } from '../../utils';
import { errorMessages } from '../../../utils/errorMessages';
import ss from '../../../utils/sessionStorage';

import { storeToRefs } from 'pinia';
import { useAddressValidationServiceStore } from '../../../store/addressValidationService';
import { useAddressesStore } from '../../../store/addresses';
import { useUserStore } from '../../../store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useCartStore } from '../../../store/cartStore';

interface AddressParams {
  updateCartData?: boolean;
  isTemporary?: boolean;
  setShippingQueryParams?: string[];
}

interface CheckAddressChangesParams extends AddressParams {
  changeShippingAddress?: boolean;
}

interface StoreAddressesParams extends AddressParams {
  updatePickupAddress?: boolean;
}

export const useAddress = (
  instance,
  { getAddresses, basicInformation, getStateCode, addAddress },
  contextKey?: string
) => {
  const addressesStore = useAddressesStore(instance);
  const AVSstore = useAddressValidationServiceStore();

  const { isAVSRejected } = AVSstore;

  const {
    AVSchanged,
    AVSisAddressInvalid,
    AVSdone,
    AVSskip,
    AVSoldAddress,
  } = storeToRefs(AVSstore);

  const userStore = useUserStore(instance);
  const { loggedIn } = storeToRefs(userStore);

  const { billingAddress, shippingAddress, pickupAddress } = storeToRefs(
    addressesStore
  );

  const {
    updateCart,
    defaultShippingAddress,
    cart,
    cartId,
    hasPickupItems,
    hasShippingItems,
    itemsForPickup,
  } =
    contextKey === ApplePayContext.PDP
      ? useApplePayPdpCart(instance)
      : useCart(instance);

  const { displayErrorMessages } = errorMessages(instance);
  const {
    setShippingAddress: setShippingAddressAPI,
    addressValidation: addressValidationAPI,
    setBillingAddress: setBillingAddressAPI,
    setPickupAddress: setPickupAddressAPI,
  } = apiClientFactory(instance);

  const country = instance.$getEnvValueByCurrentLocale('COUNTRY').toUpperCase();
  const emptyAddress = getEmptyAddressObject(country);
  const { isBopisEnabled } = useFeatureFlagsStore();

  const storeAddresses = async ({
    updateCartData = true,
    updatePickupAddress = false,
    isTemporary = false,
    setShippingQueryParams = [],
  }: StoreAddressesParams = {}) => {
    try {
      AVSdone.value = false;
      const setAddressForPickup: boolean =
        isBopisEnabled && hasPickupItems.value;
      const setAddressForShipping: boolean = hasShippingItems.value;
      const addresses: Address[] = [];

      if (setAddressForPickup) {
        addresses.push(
          ...itemsForPickup.value.map((item) => {
            const phone = stripPhoneAndPrependCountryCallingCode(
              pickupAddress.value.phone,
              pickupAddress.value.country || pickupAddress.value.countryCode
            );
            return {
              ...cloneDeep<Address>(pickupAddress.value),
              ...(phone && { phone }),
              storeId: item.shippingOptions?.find((option) => {
                return option.selected && option.storeInfo;
              })?.storeInfo?.id,
            };
          })
        );
      }

      if (setAddressForShipping) {
        addresses.push({
          ...(shippingAddress.value.id && {
            addressId: shippingAddress.value.id,
          }),
          firstName: shippingAddress.value.firstName,
          lastName: shippingAddress.value.lastName,
          email: shippingAddress.value.email,
          country: shippingAddress.value.country,
          addressLine1: shippingAddress.value.addressLine1,
          addressLine2: shippingAddress.value.addressLine2 || '',
          postalCode: shippingAddress.value.postalCode,
          city: shippingAddress.value.city,
          province: await getStateCode(
            shippingAddress.value.province || shippingAddress.value.stateCode,
            shippingAddress.value.country || shippingAddress.value.countryCode
          ),
          phone: stripPhoneAndPrependCountryCallingCode(
            shippingAddress.value.phone,
            shippingAddress.value.country || shippingAddress.value.countryCode
          ),
          subscriptions: {
            newsletterConsent:
              shippingAddress.value.subscriptions?.newsletterConsent,
          },
          stateCode: shippingAddress.value.province,
          shippingId: 'me', // Default shipping id
        });
      } else {
        AVSdone.value = true;
      }

      if (!updatePickupAddress || setAddressForPickup) {
        return setShippingAddress(
          addresses,
          {
            updateCartData,
            isTemporary,
          },
          setShippingQueryParams
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setShippingAddress = async (
    addresses: Address[],
    { updateCartData, isTemporary } = {
      updateCartData: true,
      isTemporary: false,
    },
    queryParams: string[] = []
  ) => {
    if (cartId.value) {
      try {
        const cartAddressRequests = addresses.map<CartAddressRequest>(
          (item: Address): CartAddressRequest => {
            return {
              ...item,
            };
          }
        );
        const query = [...queryParams];
        if (isTemporary) {
          query.push(prepareTemporaryBasket());
        }

        const response = await setShippingAddressAPI(
          cartId.value,
          cartAddressRequests,
          {
            query: query.join('&'),
          }
        );
        if (response.status === 200 && updateCartData) {
          await updateCart(response.data);
        }
        return response;
      } catch (e) {
        displayErrorMessages(e);
      }
    } else {
      console.error('setShippingAddress: No cart id');
      return Promise.resolve(null);
    }
  };

  const setBillingAddress = (addressForm: CartAddressRequest) => {
    if (cartId.value) return setBillingAddressAPI(cartId.value, addressForm);
    else {
      console.error('setBillingAddress: No cart id');
      return Promise.resolve(null);
    }
  };

  const setShippingAddressFromCart = (newsletterConsent = false): void => {
    const cartShippingAddress: Address = cart.value.shippingMethods[0]?.address;
    const cartAddressId = cart.value.shippingMethods[0]?.address?.addressId;
    const userShippingAddress = getAddresses('S').value.find(
      (address) => address.id === cartAddressId
    );

    if (cartShippingAddress) {
      const mergedAddress = {
        ...emptyAddress,
        ...cartShippingAddress,
        ...userShippingAddress,
        ...(newsletterConsent ? { subscriptions: { newsletterConsent } } : {}),
        id: cartShippingAddress.addressId,
        email:
          cartShippingAddress.email ??
          userShippingAddress.email ??
          shippingAddress.value.email ??
          '',
      };
      const phone = stripPhoneAndPrependCountryCallingCode(
        mergedAddress.phone,
        mergedAddress.country
      );
      shippingAddress.value = { ...mergedAddress, ...(phone && { phone }) };
    }
  };

  const moveDefaultAddressToShipping = async (newsletterConsent = false) => {
    try {
      if (!isDefaultShippingAddressSaveable.value) {
        return;
      }
      const newAddress = cloneDeep(defaultShippingAddress.value);
      newAddress.province = await getStateCode(
        newAddress.province || newAddress.stateCode,
        newAddress.country || newAddress.countryCode
      );
      if (!newAddress['id'] && defaultShippingAddress.value['addressId']) {
        newAddress['id'] = defaultShippingAddress.value['addressId'];
      }
      const mergedAddress = {
        ...emptyAddress,
        ...newAddress,
        ...{ subscriptions: { newsletterConsent } },
      };
      const phone = stripPhoneAndPrependCountryCallingCode(
        mergedAddress.phone,
        mergedAddress.country
      );
      shippingAddress.value = { ...mergedAddress, ...(phone && { phone }) };
    } catch (err) {
      console.error(err);
    }
  };

  // TODO it can be just a function?
  const isDefaultShippingAddressSaveable = computed(() => {
    const {
      country,
      countryCode,
      province,
      stateCode,
      postalCode,
    } = defaultShippingAddress.value;
    return [country || countryCode, province || stateCode, postalCode].every(
      Boolean
    );
  });

  const validateAddress = async (cartId: string) => {
    try {
      AVSdone.value = true;
      const address = {
        firstName: shippingAddress.value.firstName,
        lastName: shippingAddress.value.lastName,
        address1: shippingAddress.value.addressLine1,
        address2: shippingAddress.value.addressLine2 || '',
        city: shippingAddress.value.city,
        zipCode: shippingAddress.value.postalCode,
        countryCode: shippingAddress.value.country,
        state: await getStateCode(
          shippingAddress.value.province || shippingAddress.value.stateCode,
          shippingAddress.value.country || shippingAddress.value.countryCode
        ),
      };
      return addressValidationAPI(address, cartId);
    } catch (err) {
      console.error(err);
    }
  };

  const addressVerificationFieldMapping = new Map([
    ['addressLine1', 'standardizedAddress1'],
    ['addressLine2', 'standardizedAddress2'],
    ['city', 'standardizedCity'],
    ['postalCode', 'standardizedPostalCode'],
    ['country', 'standardizedCountry'],
    ['province', 'standardizedState'],
  ]);

  /**
   * Checks provided shipping address' validation response.
   * Verifies all validation flags to determine if the address
   * can be used for order.
   *
   * @param verificationResponse response from address validation endpoint
   * @param checkAddressParams parameters to control address validation
   * @returns boolean indication if address can be used to proceed
   */
  const checkAddressChanges = async (
    verificationResponse: AddressValidationResponse,
    {
      changeShippingAddress = false,
      updateCartData = false,
      isTemporary = false,
      setShippingQueryParams = [],
    }: CheckAddressChangesParams = {}
  ): Promise<boolean> => {
    const avsData = verificationResponse.serviceResponse;

    // set false as default value
    AVSchanged.value = false;

    if (AVSskip.value) {
      // the customer manually confirmed that provided address is correct
      AVSchanged.value = false;
      AVSisAddressInvalid.value = false;
      return true;
    }

    if (isAVSRejected(verificationResponse)) {
      // submitted address was validated negatively, needs manual confirmation from the customer
      AVSchanged.value = false;
      AVSisAddressInvalid.value = true;
      return false;
    }

    for (const [key, value] of addressVerificationFieldMapping.entries()) {
      if (await checkIfAddressFieldValueChanged(avsData, value, key)) {
        AVSchanged.value = true;
        AVSisAddressInvalid.value = true;
      }
    }

    if (changeShippingAddress) {
      if (AVSchanged.value) {
        AVSisAddressInvalid.value = true;
        // address has been corrected by the verification service
        // we need to store old values (to visualize corrections to the customer later)
        // and update local state with corrected values
        AVSoldAddress.value = { ...shippingAddress.value };
        ss.setItem('AVSoldAddress', JSON.stringify(AVSoldAddress.value));

        shippingAddress.value = {
          ...shippingAddress.value,
          addressLine1:
            avsData[addressVerificationFieldMapping.get('addressLine1')],
          addressLine2:
            avsData[addressVerificationFieldMapping.get('addressLine2')] || '',
          city: avsData[addressVerificationFieldMapping.get('city')],
          postalCode:
            avsData[addressVerificationFieldMapping.get('postalCode')],
          province: avsData[addressVerificationFieldMapping.get('province')],
        };

        try {
          // attempting to persist updated address in the cart object
          const response = await storeAddresses({
            updateCartData,
            isTemporary,
            setShippingQueryParams,
          });
          if (response.status !== 200) {
            // restoring the old address in case of failure
            shippingAddress.value = { ...AVSoldAddress.value };
            return false;
          }
        } catch (e) {
          console.log(e);
          displayErrorMessages(e);
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Compares locally stored address field value with value returned from verification
   * service.
   *
   * @param avsData data object of address validation endpoint response
   * @param serviceFieldName service response address object field name
   * @param fieldName local address object field name
   * @returns boolean indication if field value was updated by address verification service
   */
  const checkIfAddressFieldValueChanged = async (
    avsData,
    serviceFieldName: string,
    fieldName: string
  ): Promise<boolean> => {
    try {
      const savedAddress = ss.getItem('AVSoldAddress')
        ? JSON.parse(ss.getItem('AVSoldAddress'))
        : shippingAddress.value;
      return (
        avsData[serviceFieldName] !==
        (fieldName !== 'province'
          ? savedAddress[fieldName] || null
          : await getStateCode(
              shippingAddress.value[fieldName] ||
                shippingAddress.value['stateCode'],
              shippingAddress.value['country'] ||
                shippingAddress.value['countryCode']
            ))
      );
    } catch (err) {
      console.error(err);
    }
  };

  /* This function verifies if it is allowed store the address */
  const shouldStoreBillingAddress = (billingAddress) => {
    const isBillingAddress = billingAddress.addressId?.includes('B-');
    const isAllReadySaved = basicInformation.value.address.find(
      ({ id, approachType }) =>
        id === billingAddress.addressId && approachType.includes('B-')
    );
    return loggedIn.value && !isBillingAddress && !isAllReadySaved;
  };

  const storeBillingAddress = async (billingAddressObject) => {
    const data = {
      ...billingAddressObject,
      approachType: 'B',
      main: true,
    };
    delete data.id;
    delete data.addressId;
    try {
      const res = await addAddress(data);
      if (res.status === 201) {
        billingAddressObject.addressId = res.data.addressId;
        return true;
      }
    } catch (err) {
      displayErrorMessages(err);
    }
    return false;
  };

  const setPickupAddress = async () => {
    const { selectedPickupStore, pickupPerson, cartPickups } = storeToRefs(
      useCartStore()
    );

    if (cartPickups.value.length) {
      const request = cartPickups.value.map(({ shippingId }) => {
        return {
          shippingId,
          storeId: selectedPickupStore.value.id,
          firstName: pickupPerson.value.firstName,
          lastName: pickupPerson.value.lastName,
          email: pickupPerson.value.email,
        };
      });

      try {
        const response = await setPickupAddressAPI(cartId.value, request);
        if (response.status === 200) {
          await updateCart(response.data);
        }
        return response;
      } catch (e) {
        displayErrorMessages(e);
      }
    }
  };

  return {
    billingAddress,
    shippingAddress,
    setShippingAddress,
    setBillingAddress,
    setShippingAddressFromCart,
    storeAddresses,
    validateAddress,
    moveDefaultAddressToShipping,
    checkAddressChanges,
    checkIfAddressFieldValueChanged,
    shouldStoreBillingAddress,
    storeBillingAddress,
    setPickupAddress,
  };
};
