import { Taxonomy } from '../types';

/**
 * Create searchTerm query
 * Example: searchTerm=search-term
 * @param query
 */
export const prepareSearch = (query: string): string => {
  return `searchTerm=${query}`;
};

/**
 * Create sortBy query
 * Example: sortBy=wpublicationDate
 * @param sort
 */
export const prepareSort = (sort: string): string => {
  return `sortBy=${sort}`;
};

/**
 * Create offset query - which element to start with
 * Example: offset=20
 * @param offset
 */
export const prepareOffset = (offset: number): string => {
  return `offset=${offset.toString()}`;
};

/**
 * Create limit query - how many products should be fetched
 * Example: limit=10
 * @param limit
 */
export const prepareLimit = (limit: number): string => {
  return `limit=${limit.toString()}`;
};

/**
 * Create filters/taxonomy query
 * Example "taxonomyIds=7450,7542"
 *
 * @param filters
 */
export const prepareFilters = (filters: Taxonomy[]): string => {
  const taxonomyIds = filters.map((element) => element.id || element.value);

  return `taxonomyIds=${taxonomyIds.toString()}`;
};

/**
 * Create filters/taxonomy query
 * Example "filterTaxonomyIds=7450,7542"
 *
 * @param filters
 */
export const preparePrimaryFilters = (filters: Taxonomy[]): string => {
  const taxonomyIds = filters.map((element) => element.id || element.value);

  return `filterTaxonomyIds=${taxonomyIds.toString()}`;
};
