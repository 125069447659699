import { CMTeaser, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getTranslation,
  generateLinkFromTeaserTargets,
  getDynamicField,
} from './utils';
import { ComposableContext } from '../types';

export const backToOrderHistoryButton = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => ({
  component: 'order/BackToOrderHistoryButton',
  props: {
    translations: {
      title:
        extractProperty(data, 'teaserTitle') ||
        getTranslation(data, 'title', '$Back to Order history$'),
      pageTitle: getTranslation(data, 'pageTitle', '$Order Details$'),
    },
    teaserLink: generateLinkFromTeaserTargets(
      data.teaserTargets,
      context,
      siteConfiguration,
      data.urlParams,
      cmsBaseUri
    ),
    showPageTitle: getDynamicField(data, 'showPageTitle', false),
  },
});
