import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { SignOutResponse } from '../../api-types';
import type { ApiClientConfiguration } from '../../configuration';

export const signOut = (
  configuration: ApiClientConfiguration
) => async (): Promise<AxiosResponse<SignOutResponse>> => {
  const response = await axios.post<SignOutResponse>(
    urlBuilder(endpoints.get(configuration).authentication.signOut, {
      config: configuration,
    }),
    {},
    {
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
