import { Plugin } from '@nuxt/types';
import { creditCardsIcons } from '@/helpers';
import { ssrRef } from '@nuxtjs/composition-api';
import { getEnvValueByLocale } from '@vf/env';

const icons = ssrRef([], 'SUPPORTED_CREDIT_CARDS');

const SupportedCreditCards: Plugin = (context, inject) => {
  if (process.server) {
    const locale = context.app.i18n.locale;

    icons.value = creditCardsIcons.filter((cardIcon) =>
      getEnvValueByLocale<string>('SUPPORTED_CREDIT_CARDS', locale).includes(
        cardIcon.id
      )
    );
  }

  inject('supportedCreditCardsIcons', icons.value);
};

export default SupportedCreditCards;
