import { extractProperty, mergeProperty } from './extractProperty';

export const getAddressValidationMessages = (data: any) => {
  const validationMessages = extractProperty(data, 'validationMessages', {});

  return mergeProperty(
    {
      postalCode: {
        pattern: '$Please match the requested format: Zip Code.$',
      },
      phone: {
        pattern:
          '$Please match the requested format: {{phoneNumberFormat}} Enter phone number with no spaces or special characters.$',
      },
      default: {
        required: '$This field is required.$',
        minLength: '$This field must have a length no less than {{min}}.$',
        maxLength: '$This field must have a length no longer than {{max}}.$',
        pattern: '$Please match the requested format.$',
        email: '$Please enter a valid email address.$',
      },
      fallback: '$Invalid value.$',
    },
    validationMessages
  );
};
