import { Filter } from '../types';

export const transformListingFilters = (
  filters: Filter[],
  isNewColorDividerEnabled: boolean
) =>
  filters
    .filter((element) => {
      return element.code && element.code !== 'cgid' && element.options.length;
    })
    .map((filter) => {
      filter.options.forEach((option) => {
        const colorDivider = isNewColorDividerEnabled ? ';' : '|';

        const [label, ...colors] = option.label.split(colorDivider);

        option.text = label;
        option.colors = colors;
      });

      return filter;
    });
