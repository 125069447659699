import { CMTeaser } from '@vf/api-contract';
import { getDynamicField, getTranslation } from './utils';

export const discountNotification = (data: CMTeaser) => {
  return {
    component: 'shared/DiscountNotification',
    props: {
      translations: {
        employee: getTranslation(data, 'employee', {
          message:
            '$VF Associate Discount: You have spent {{amount}} out of your annual associate discount limit of {{limit}} on {{brand}}.$',
          exeededMessage:
            '$Your account has reached the annual VF associate discount limit and is no longer eligible for employee discount at this time.$',
        }),
        athlete: getTranslation(data, 'athlete', {
          message:
            '$Athlete Discount: You have spent {{amount}} out of your annual associate discount limit of {{limit}} on {{brand}}.$',
          exeededMessage:
            '$Your account has reached the annual VF associate discount limit and is no longer eligible for employee discount at this time.$',
        }),
        ipa: getTranslation(data, 'ipa', {
          message:
            '$IPA Discount: You have spent {{amount}} out of your annual associate discount limit of {{limit}} on {{brand}}.$',
          exeededMessage:
            '$Your account has reached the annual VF associate discount limit and is no longer eligible for employee discount at this time.$',
          seasonalExeededMessage:
            '$Your account has reached the seasonal VF associate discount limit and is no longer eligible for employee discount at this time.$',
        }),
        wranx: getTranslation(data, 'wranx', {
          message:
            '$Wranx Discount: You have spent {{amount}} out of your annual associate discount limit of {{limit}} on {{brand}}.$',
          exeededMessage:
            '$Your account has reached the annual VF associate discount limit and is no longer eligible for employee discount at this time.$',
          seasonalExeededMessage:
            '$Your account has reached the seasonal VF associate discount limit and is no longer eligible for employee discount at this time.$',
        }),
      },
      notificationPersistent: getDynamicField(
        data,
        'notificationPersistent',
        false
      ),
    },
  };
};
