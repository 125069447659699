import type { Plugin } from '@nuxt/types';
import type { ComponentInstance } from '@vf/composables/src/types';
import {
  COOKIE_NAME,
  FILTER_URL,
} from '@vf/composables/src/useShippingFilter/utils';
import { isCategorySegment } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { useShippingFilter, ROUTES } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getVueInstanceFromContext } from '../helpers';

/** Preserve store Filter query param when navigating between PLP/SRP pages */
const StoreFilterPlugin: Plugin = (context) => {
  const { isBopis20PlpEnabled } = useFeatureFlagsStore();
  if (!isBopis20PlpEnabled) return;

  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  const { isEnableShippingFilter } = useShippingFilter(vueInstance);

  const isListingPage = (path) =>
    isCategorySegment(path) || path.includes(ROUTES.SEARCH());

  context.app.router.beforeEach((to, _, next) => {
    const favStore = vueInstance.$cookies.get(COOKIE_NAME) || undefined;
    if (
      !isEnableShippingFilter.value ||
      !isListingPage(to.path) ||
      to.query[FILTER_URL] === favStore
    )
      next();
    else
      next({
        ...to,
        query: {
          ...to.query,
          [FILTER_URL]: favStore,
        },
      });
  });
};

export default StoreFilterPlugin;
