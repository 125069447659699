import { CMContentTextBlock } from '@vf/api-contract';
import { extractProperty, getTranslation } from './utils';

export const emailCapture = (data: CMContentTextBlock) => {
  const getEmailValidationMessage = () => {
    return getTranslation(
      data,
      'emailValidationMessage',
      '$emailValidationMessage$'
    );
  };
  return {
    component: 'shared/EmailCaptureForm',
    props: {
      title: extractProperty(data, 'localSettings.emailTitle'),
      summaryText: extractProperty(data, 'localSettings.summaryText'),
      campaignName: extractProperty(data, 'localSettings.campaignName'),
      ctaSubmitType: extractProperty(
        data,
        'componentStyles.ctaSubmitType',
        'submit-arrow'
      ),
      titleFontSize: extractProperty(data, 'componentStyles.fontSize'),
      textAlignment: extractProperty(
        data,
        'componentStyles.textAlignment',
        'left'
      ),
      validationMessages: {
        requiredError: getEmailValidationMessage(),
        emailError: getEmailValidationMessage(),
        successMessage: getTranslation(
          data,
          'successMessage',
          '$successMessage$'
        ),
        errorMessage: getTranslation(data, 'errorMessage', '$errorMessage$'),
      },
      backgroundColor: extractProperty(
        data,
        'componentStylesDefinitions.emailBackground.data.color'
      ),
      textColor: extractProperty(
        data,
        'componentStylesDefinitions.emailColor.data.color'
      ),
      translations: {
        checkboxText: getTranslation(data, 'checkboxText', '$checkboxText$'),
        emailLabel: getTranslation(data, 'emailLabel', '$emailLabel$'),
        emailPlaceholder: getTranslation(data, 'emailPlaceholder'),
        checkboxValidationMessage: getTranslation(
          data,
          'checkboxValidationMessage',
          '$checkboxValidationMessage$'
        ),
        ctaSubmitText: getTranslation(data, 'ctaSubmitText', '$ctaSubmitText$'),
      },
    },
  };
};
