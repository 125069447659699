/* eslint-disable @typescript-eslint/ban-types */
// utility to load external scripts into Vue components
export function load(
  src: string,
  onLoadCallback?: () => void,
  onErrorCallback?: () => void,
  attrs?: Record<string, string>
): Promise<void | Element> {
  return new Promise(function (resolve, reject) {
    const { defer: hasDefer, ...extraAttr } = attrs || {};
    let appendScript = false;
    let el = document.querySelector('script[src="' + src + '"]');
    if (!el) {
      el = document.createElement('script');
      el.setAttribute('type', 'text/javascript');
      el.setAttribute('src', src);
      if (hasDefer) {
        el.setAttribute('defer', 'defer');
      } else {
        el.setAttribute('async', 'true');
      }
      for (const key in extraAttr) {
        el.setAttribute(key, extraAttr[key]);
      }
      appendScript = true;
    } else if (el.hasAttribute('data-loaded')) {
      resolve(el);
      return;
    }

    el.addEventListener('error', () => {
      onErrorCallback?.();
      reject();
    });

    el.addEventListener('abort', () => {
      onErrorCallback?.();
      reject();
    });

    el.addEventListener('load', () => {
      el.setAttribute('data-loaded', 'true');
      onLoadCallback?.();
      resolve(el);
    });
    if (appendScript) {
      document[attrs?.append || 'head'].appendChild(el);
    }
  });
}

export function unload(src: string): Promise<void> {
  return new Promise(function (resolve, reject) {
    const el = document.querySelector('script[src="' + src + '"]');

    if (!el) {
      reject('Cannot find script ' + src);
      return;
    }

    el.remove();
    resolve();
  });
}
