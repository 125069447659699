import { CMContentRow, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  getTeaserTargets,
  extractComponentStyleDefinitions,
} from './utils';
import { ComposableContext, CmsSanitizationRequirements } from '../types';
import { camelCase } from '../dataTransformators/utils/camelCase';

import * as mappings from '../mappings';
import * as plainMappings from '../mappings/plain';

export const easyContentRow = (
  data: CMContentRow,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const { ctas } = getTeaserTargets(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const children = data.teasableItems.reduce((result, item) => {
    let mappingMethod;
    const viewType = camelCase(item.viewtype);

    if (!item?.viewtype && item?.type && plainMappings[item.type]) {
      mappingMethod = plainMappings[item.type];
    } else if (item.viewtype) {
      mappingMethod = mappings[viewType];
    }

    if (mappingMethod) {
      const componentMapping = mappingMethod(
        item,
        context,
        siteConfiguration,
        cmsBaseUri,
        requirements
      );

      result.push(componentMapping);
      return result;
    }

    context.instance.$log.warn(
      `[easyContentRow] There is no mapping for this content row column component view-type: ${item.viewtype} | id: ${item.id} | name: ${item.name}`
    );
    return result;
  }, []);

  return {
    component: 'shared/ContentRow',
    props: {
      title: extractComponentStyleDefinitions(data, 'teaserTitle'),
      subtitle: extractComponentStyleDefinitions(
        data,
        'teaserSubTitle',
        'teaserSubtitle'
      ),
      bottomMarginStyle: extractProperty(data, 'componentStyles.bottomMargin'),
      rowBackgroundColor: extractProperty(
        data,
        'componentStylesDefinitions.rowBackgroundColor.data.color'
      ),
      ctas,
    },
    children,
  };
};
