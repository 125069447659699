import Vue from 'vue';
import VueScreen from 'vue-screen';

export const config = {
  small: 320,
  smallLandscape: 768,
  medium: 1024,
  large: 1440,
};
// After changing values in config it needed to change
// also values in @vf/ui/styles/helpers/_breakpoints.scss
Vue.use(VueScreen, config);
