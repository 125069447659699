import { cmsApiClient, cmsSiteId } from './getCmsResource';
import type { CmsApiClientSettings } from './getCmsResource/types';
import * as CmsQueries from './getCmsResource/services/queries';
import * as CmsTypes from './getCmsResource/types';
export {
  PaymentMethodCode,
  PaymentDetailsCode,
  ProductAttributeCodes,
  ProductAttributeValues,
  ProductInventoryState,
} from './types';

export type {
  AddressCustomer,
  Article,
  ArticleResponse,
  BrandifyStoreInfo,
  Cart,
  CartTotals,
  AutoAppliedPromotion,
  CartProductDiscount,
  CartCouponDiscount,
  OrderPromotion,
  CartLineItem,
  NotAddedItem,
  CartLineItemAttribute,
  CartLineItemOutOfStock,
  ApproachingDiscount,
  CouponItem,
  CategoryProduct,
  Color,
  ColorSwatch,
  FlashError,
  FlashErrorDetails,
  Image,
  ImageAddress,
  Product,
  ProductAttribute,
  ProductAttributeOption,
  ProductCrossSellItem,
  ProductUpsellItem,
  Quantity,
  Rating,
  OrderDetails,
  OrderStatuses,
  CheckoutOrder,
  CheckoutOrderLineItem,
  ReturnItem,
  ReturnDetailedItem,
  ReturnPreviewObjectOverallTotals,
  ReturnPreviewObjectAddress,
  ReturnPreviewObjectHeaderCharges,
  ReturnPayload,
  ReturnObject,
  ReturnPreviewObject,
  Size,
  Length,
  Width,
  Zip,
  FaqItem,
  CartShippingGroup,
  CartShippingMethodPrice,
  PickupPerson,
  PickupStore,
  PaymentInstruments,
} from './types';

import * as apiConfig from './config/api';
import { setup, set401Handler } from './configuration';
import type { ApiClientConfiguration } from './configuration';
import { axiosclient as axios } from './helpers/axios';

export type { ApiClientConfiguration };
import { getArticles } from './getArticles';
import { confirmForgotPassword } from './api/authentication/confirmForgotPassword';
import { signOut } from './api/authentication/signOut';
import { signUp } from './api/authentication/signUp';
import { signUpSimplified } from './api/authentication/signUpSimplified';
import { signIn } from './api/authentication/signIn';
import { signInGuest } from './api/authentication/signInGuest';
import { getUserToken } from './api/authentication/getUserToken';
import { emailLookup } from './api/authentication/emailLookup';
import { forgotPassword } from './api/customer/forgotPassword';
import { getBasicInformation } from './api/customer/getBasicInformation';
import { setBasicInformation } from './api/customer/setBasicInformation';
import { setSubscription } from './api/customer/setSubscription';
import { setSubscriptionGuest } from './api/customer/setSubscriptionGuest';
import { changePassword } from './api/customer/changePassword';
import { updateAcceptance } from './api/customer/updateAcceptance';
import { updateProfile } from './api/customer/updateProfile';
import { getProductDetails } from './api/product/getProductDetails';
import { getAvailability } from './api/product/getAvailability';
import { getCart } from './api/cart/getCart';
import { addItem } from './api/cart/addItem';
import { deleteItem } from './api/cart/deleteItem';
import { getCartObject } from './api/cart/getCartObject';
import { swapItem } from './api/cart/swapItem';
import { updateItem } from './api/cart/updateItem';
import { getShippingMethods } from './api/checkout/getShippingMethods';
import { setShippingMethod } from './api/cart/setShippingMethod';
import { setShippingAddress } from './api/checkout/setShippingAddress';
import { setBillingAddress } from './api/checkout/setBillingAddress';
import { setFamilyReward } from './api/checkout/setFamilyReward';
import { getPaymentMethods } from './api/checkout/getPaymentMethods';
import { placeOrder } from './api/checkout/placeOrder';
import { getPriceByBundlePartNumber } from './api/product/getPriceByBundlePartNumber';
import { getBundleByRecipe } from './api/product/getBundleByRecipe';
import { getSizeByBundlePartNumber } from './api/product/getSizeByBundlePartNumber';
import { getWidgetRecommendations } from './api/product/getWidgetRecommendations';
import { getSearchResults } from './api/search/getSearchResults';
import { getSearchSuggestions } from './api/search/getSearchSuggestions';
import { getOrderHistory } from './api/customer/getOrderHistory';
import { getOrderDetails } from './api/customer/getOrderDetails';
import { getCloudinaryAnyList } from './api/images/getCloudinaryAnyList';
import { getGuestOrderDetails } from './api/customer/getGuestOrderDetails';
import { getGalleryScene7 } from './api/images/getGalleryScene7';
import { generateProductIdScene7 } from './api/images/generateProductIdScene7';
import { generateProductIdThumbnailsScene7 } from './api/images/generateProductIdThumbnailsScene7';
import { getGalleryCustoms3d } from './api/images/getGalleryCustoms3d';
import { probeApiMiddleware } from './api/healthCheck/probeApiMiddleware';
import { addAddress } from './api/customer/addAddress';
import { updateAddress } from './api/customer/updateAddress';
import { deleteAddress } from './api/customer/deleteAddress';
import { addressValidation } from './api/checkout/addressValidation';
import { getExperienceVariantDecision } from './monetate/decision/getExperienceVariantDecision';
import { addCoupon } from './api/cart/addCoupon';
import { deleteCoupon } from './api/cart/deleteCoupon';
import { locatorSearch } from './api/brandify/locatorSearch';
import { getCountryList } from './api/utilities/getCountryList';
import { getProvinceList } from './api/utilities/getProvinceList';
import { getI18n } from './api/utilities/getI18n';
import { getCountriesList } from './api/brandify/getCountriesList';
import { getStoresCities } from './api/stores/getStoresCities';
import { getStoresCountries } from './api/stores/getStoresCountries';
import { getStoresStates } from './api/stores/getStoresStates';
import { getStoresStores } from './api/stores/getStoresStores';
export type { ApplyPaymentInstrumentRequestObject } from './api/cart/getCartPaymentInstruments';
import { getCartPaymentInstruments } from './api/cart/getCartPaymentInstruments';
import { deleteCartPaymentInstruments } from './api/cart/deleteCartPaymentInstruments';
import { checkGiftCardBalance } from './api/cart/checkGiftCardBalance';
import { saveForLater } from './api/cart/saveForLater';
import { getSaveForLater } from './api/wishList/getSaveForLater';
import { removeSaveForLater } from './api/cart/removeSaveForLater';
import { updateSaveForLater } from './api/cart/updateSaveForLater';
import { deviceFingerPrint } from './api/cart/deviceFingerPrint';
import { getCustomsImage } from './api/images/getCustomsImage';
export type { GetCustomsImageParams } from './api/images/getCustomsImage';
import { getUGCModerationStatus } from './api/images/getUGCModerationStatus';
import { getCustomsSku } from './api/cart/getCustomsSku';
import { applyShared } from './api/cart/applyShared';
import { shareCart } from './api/cart/shareCart';
import { createCartAddItem } from './api/cart/createCartAddItem';
import { getPaymentInstruments } from './api/customer/getPaymentInstruments';
import { deletePaymentInstrument } from './api/customer/deletePaymentInstrument';
import { addPaymentInstrument } from './api/customer/addPaymentInstrument';
import { setDefaultPaymentInstrument } from './api/customer/setDefaultPaymentInstrument';
import { getFavorites } from './api/wishList/getFavorites';
import { addToFavorites } from './api/wishList/addToFavorites';
import { removeFromFavorites } from './api/wishList/removeFromFavorites';
import { getAffiliateCookies } from './api/affiliate/getAffiliateCookies';
import { shareFavorites } from './api/wishList/shareFavorites';
import { getSharedFavorites } from './api/wishList/getSharedFavorites';
import { createReturn } from './api/customer/createReturn';
import { getReturnHistory } from './api/customer/getReturnHistory';
import { printReturnLabel } from './api/customer/printReturnLabel';
import { getReturnDetails } from './api/customer/getReturnDetails';
import { getProductReviews } from './api/product/getProductReviews';
import { getProductReviewsConf } from './api/product/getProductReviewsConf';
import { getProductInventory } from './api/product/getProductInventory';
import { prepareOrder } from './api/cart/prepareOrder';
import { flagReview } from './api/product/flagReview';
import { addReviewVote } from './api/product/addReviewVote';
import { getLoyaltyToken } from './api/customer/getLoyaltyToken';
import { getLoyaltyWelcomePromoStatus } from './api/customer/getLoyaltyWelcomePromoStatus';
import { getLoyaltyVouchers } from './api/customer/getLoyaltyVouchers';
import { signInToStore } from './api/buyInStore/signInToStore';
import { getStores } from './api/buyInStore/getStores';
import { getStoreEmployees } from './api/buyInStore/getStoreEmployees';
import { getCategorySubject } from './api/contactForm/getCategorySubject';
import { submitContactForm } from './api/contactForm/submitContactForm';
import { submitContactFormSfcc } from './api/contactForm/submitContactFormSfcc';
import { notifyMe } from './api/product/notifyMe';
import { initCart } from './api/cart/initCart';
import { setPriceAdjustment } from './api/cart/setPriceAdjustment';
import { updatePriceAdjustment } from './api/cart/updatePriceAdjustment';
import { deletePriceAdjustment } from './api/cart/deletePriceAdjustment';
import { getSmartGiftCart } from './api/cart/getSmartGiftCart';
import { getPaymentSession } from './api/cart/getPaymentSession';
import { printPackingSlip } from './api/pdfPrinter/printPackingSlip';
import { updateCartPaymentInstrument } from './api/cart/updateCartPaymentInstrument';
import { getSegments } from './api/customer/getSegments';
import { getSNAuth } from './api/socialNative/getSNAuth';
import { getFeatureFlags } from './api/launchDarkly/getFeatureFlags';
import {
  getSNMediaByCategoryId,
  getSNMediaByCustomer,
  getSNMediaByStream,
  getSNMediaByTagKey,
} from './api/socialNative/getSNMedia';
import { getSNStreamByMedia } from './api/socialNative/getSNStream';
import { createSNUser } from './api/socialNative/createSNUser';
import { addSNMedia } from './api/socialNative/addSNMedia';
export type { UpdateCartPaymentInstrumentPayload } from './api/cart/updateCartPaymentInstrument';
import { trackWidgetEvent as trackSocialNativeWidgetEvent } from './api/socialNative/analytics/trackWidgetEvent';
import { trackMediaEvent as trackSocialNativeMediaEvent } from './api/socialNative/analytics/trackMediaEvent';
import { trackProductShopEvent as trackSocialNativeProductShopEvent } from './api/socialNative/analytics/trackProductShopEvent';
import { trackCheckoutEvent as trackSocialNativeCheckoutEvent } from './api/socialNative/analytics/trackCheckoutEvent';
import { setPickupAddress } from './api/checkout/setPickupAddress';
import { getFindInStoreLocations } from './api/stores/getFindInStoreLocations';
import { getCatalog } from './api/catalog/getCatalog';
import { patchOrder } from './api/checkout/patchOrder';
import { searchSite } from './api/utilities/searchSite';

const apiClientFactory = ({
  $getApiClientConfiguration,
  $circuitBreakers,
}: Vue) => {
  const configuration = $getApiClientConfiguration;

  return {
    getArticles: getArticles(configuration),
    confirmForgotPassword: confirmForgotPassword(configuration),
    signOut: signOut(configuration),
    signUp: signUp(configuration),
    signUpSimplified: signUpSimplified(configuration),
    signIn: signIn(configuration),
    signInGuest: signInGuest(configuration),
    getUserToken: getUserToken(configuration),
    emailLookup: emailLookup(configuration),
    forgotPassword: forgotPassword(configuration),
    getBasicInformation: getBasicInformation(configuration),
    setBasicInformation: setBasicInformation(configuration),
    setSubscription: setSubscription(configuration),
    setSubscriptionGuest: setSubscriptionGuest(configuration),
    changePassword: changePassword(configuration),
    updateAcceptance: updateAcceptance(configuration),
    updateProfile: updateProfile(configuration),
    getCatalog: process.server
      ? $circuitBreakers.getCatalog
      : getCatalog(configuration),
    getProductDetails: getProductDetails(configuration),
    getProductInventory: getProductInventory(configuration),
    getAvailability: getAvailability(configuration),
    getCart: getCart(configuration),
    addItem: addItem(configuration),
    deleteItem: deleteItem(configuration),
    getCartObject: getCartObject(configuration),
    swapItem: swapItem(configuration),
    updateItem: updateItem(configuration),
    getShippingMethods: getShippingMethods(configuration),
    setShippingMethod: setShippingMethod(configuration),
    setShippingAddress: setShippingAddress(configuration),
    setPickupAddress: setPickupAddress(configuration),
    setBillingAddress: setBillingAddress(configuration),
    setFamilyReward: setFamilyReward(configuration),
    getPaymentMethods: getPaymentMethods(configuration),
    placeOrder: placeOrder(configuration),
    patchOrder: patchOrder(configuration),
    getPriceByBundlePartNumber: getPriceByBundlePartNumber,
    getBundleByRecipe: getBundleByRecipe,
    getSizeByBundlePartNumber: getSizeByBundlePartNumber,
    getWidgetRecommendations: process.server
      ? $circuitBreakers.getWidgetRecommendations
      : getWidgetRecommendations(configuration),
    getSearchResults: process.server
      ? $circuitBreakers.getSearchResults
      : getSearchResults(configuration),
    getSearchSuggestions: getSearchSuggestions(configuration),
    getOrderHistory: getOrderHistory(configuration),
    getOrderDetails: getOrderDetails(configuration),
    getGuestOrderDetails: getGuestOrderDetails(configuration),
    getGalleryScene7: getGalleryScene7(configuration),
    generateProductIdScene7: generateProductIdScene7,
    generateProductIdThumbnailsScene7: generateProductIdThumbnailsScene7,
    getCloudinaryAnyList: getCloudinaryAnyList(configuration),
    getGalleryCustoms3d: getGalleryCustoms3d(configuration),
    probeApiMiddleware: probeApiMiddleware(configuration),
    addAddress: addAddress(configuration),
    updateAddress: updateAddress(configuration),
    deleteAddress: deleteAddress(configuration),
    addressValidation: addressValidation(configuration),
    getExperienceVariantDecision: getExperienceVariantDecision,
    addCoupon: addCoupon(configuration),
    deleteCoupon: deleteCoupon(configuration),
    // TODO: remove when GLOBAL15-51668 epic is done
    locatorSearch: locatorSearch(configuration),
    getCountryList: getCountryList(configuration),
    getProvinceList: getProvinceList(configuration),
    getI18n: getI18n(configuration),
    getCountriesList: getCountriesList(configuration),
    getStoresCities: getStoresCities(configuration),
    getStoresCountries: getStoresCountries(configuration),
    getStoresStates: getStoresStates(configuration),
    getStoresStores: getStoresStores(configuration),
    getCartPaymentInstruments: getCartPaymentInstruments(configuration),
    deleteCartPaymentInstruments: deleteCartPaymentInstruments(configuration),
    checkGiftCardBalance: checkGiftCardBalance(configuration),
    saveForLater: saveForLater(configuration),
    getSaveForLater: getSaveForLater(configuration),
    removeSaveForLater: removeSaveForLater(configuration),
    updateSaveForLater: updateSaveForLater(configuration),
    deviceFingerPrint: deviceFingerPrint(configuration),
    getCustomsImage: getCustomsImage(configuration),
    getUGCModerationStatus: getUGCModerationStatus,
    getCustomsSku: getCustomsSku,
    applyShared: applyShared(configuration),
    shareCart: shareCart(configuration),
    createCartAddItem: createCartAddItem(configuration),
    getPaymentInstruments: getPaymentInstruments(configuration),
    deletePaymentInstrument: deletePaymentInstrument(configuration),
    addPaymentInstrument: addPaymentInstrument(configuration),
    setDefaultPaymentInstrument: setDefaultPaymentInstrument(configuration),
    getFavorites: getFavorites(configuration),
    addToFavorites: addToFavorites(configuration),
    removeFromFavorites: removeFromFavorites(configuration),
    getAffiliateCookies: getAffiliateCookies(configuration),
    shareFavorites: shareFavorites(configuration),
    getSharedFavorites: getSharedFavorites(configuration),
    createReturn: createReturn(configuration),
    getReturnHistory: getReturnHistory(configuration),
    printReturnLabel: printReturnLabel(configuration),
    getReturnDetails: getReturnDetails(configuration),
    getProductReviews: getProductReviews(configuration),
    getProductReviewsConf: getProductReviewsConf(configuration),
    prepareOrder: prepareOrder(configuration),
    flagReview: flagReview(configuration),
    addReviewVote: addReviewVote(configuration),
    getLoyaltyToken: getLoyaltyToken(configuration),
    getLoyaltyWelcomePromoStatus: getLoyaltyWelcomePromoStatus(configuration),
    getLoyaltyVouchers: getLoyaltyVouchers(configuration),
    signInToStore: signInToStore(configuration),
    getStores: getStores(configuration),
    getStoreEmployees: getStoreEmployees(configuration),
    getCategorySubject: getCategorySubject(configuration),
    submitContactForm: submitContactForm(configuration),
    submitContactFormSfcc: submitContactFormSfcc(configuration),
    notifyMe: notifyMe(configuration),
    initCart: initCart(configuration),
    setPriceAdjustment: setPriceAdjustment(configuration),
    updatePriceAdjustment: updatePriceAdjustment(configuration),
    deletePriceAdjustment: deletePriceAdjustment(configuration),
    getSmartGiftCart: getSmartGiftCart(configuration),
    getPaymentSession: getPaymentSession(configuration),
    printPackingSlip: printPackingSlip(configuration),
    updateCartPaymentInstrument: updateCartPaymentInstrument(configuration),
    getSegments: getSegments(configuration),
    getSNAuth: getSNAuth(configuration),
    getSNMediaByCategoryId: getSNMediaByCategoryId(configuration),
    getSNMediaByCustomer: getSNMediaByCustomer(configuration),
    getSNMediaByStream: getSNMediaByStream(configuration),
    getSNMediaByTagKey: getSNMediaByTagKey(configuration),
    getSNStreamByMedia: getSNStreamByMedia(configuration),
    createSNUser: createSNUser(configuration),
    addSNMedia: addSNMedia(configuration),
    getFeatureFlags: getFeatureFlags(configuration),
    trackSocialNativeWidgetEvent: trackSocialNativeWidgetEvent(configuration),
    trackSocialNativeMediaEvent: trackSocialNativeMediaEvent(configuration),
    trackSocialNativeProductShopEvent: trackSocialNativeProductShopEvent(
      configuration
    ),
    trackSocialNativeCheckoutEvent: trackSocialNativeCheckoutEvent(
      configuration
    ),
    getFindInStoreLocations: getFindInStoreLocations(configuration),
    searchSite: searchSite(configuration),
  };
};

export {
  axios,
  apiConfig,
  setup,
  set401Handler,
  apiClientFactory,
  cmsApiClient,
  cmsSiteId,
  CmsApiClientSettings,
  CmsQueries,
  CmsTypes,
};
