import { render, staticRenderFns } from "./SkipToContent.vue?vue&type=template&id=06abc27b&scoped=true&functional=true&"
import script from "./SkipToContent.vue?vue&type=script&lang=ts&"
export * from "./SkipToContent.vue?vue&type=script&lang=ts&"
import style0 from "./SkipToContent.vue?vue&type=style&index=0&id=06abc27b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "06abc27b",
  null
  
)

export default component.exports