import isObject from './isObject';

export default (obj: any, paths: string | string[], def?: any): any => {
  if (
    !obj ||
    (!isObject(obj) && !Array.isArray(obj)) ||
    !paths ||
    !paths?.length
  )
    return def;

  const path: string = Array.isArray(paths)
    ? paths
        .map(
          (path: string): string =>
            `["${path
              .replace(/\\/g, '\\\\')
              .replace(/`/g, '\\`')
              .replace(/'/g, "\\'")
              .replace(/"/g, '\\"')}"]`
        )
        .join('')
    : paths;
  const steps = path.charCodeAt(0) === 46 ? [''] : [];
  path.replace(
    /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,
    (match, number, quote, subString): any =>
      steps.push(quote ? subString.replace(/\\(\\)?/g, '$1') : number || match)
  );
  return steps
    .filter(Boolean)
    .every(
      (step: string): boolean => !(step && (obj = obj?.[step]) === undefined)
    )
    ? obj
    : def;
};
