import { ComponentInstance } from '@vf/composables';

export interface UseReCaptcha {
  executeRecaptcha(action: string): Promise<string>;
  showBadge(): Promise<void>;
  hideBadge(): Promise<void>;
}

export const useReCaptcha = (instance: ComponentInstance): UseReCaptcha => {
  const executeRecaptcha = async (action: string): Promise<string> => {
    (instance as any).$recaptchaEvent.$emit('load-recaptcha');
    await instance.$nextTick();

    await instance.$recaptchaLoaded();
    return instance.$recaptcha(action);
  };

  const showBadge = async (): Promise<void> => {
    (instance as any).$recaptchaEvent.$emit('load-recaptcha');
    await instance.$nextTick();

    await instance.$recaptchaLoaded();
    instance.$recaptchaInstance.showBadge();
  };

  const hideBadge = async (): Promise<void> => {
    (instance as any).$recaptchaEvent.$emit('load-recaptcha');
    await instance.$nextTick();

    await instance.$recaptchaLoaded();
    instance.$recaptchaInstance.hideBadge();
  };

  return {
    executeRecaptcha,
    showBadge,
    hideBadge,
  };
};
