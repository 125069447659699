import { Plugin } from '@nuxt/types';
import {
  ComponentInstance,
  useLocationConfirmationStore,
} from '@vf/composables';
import { getVueInstanceFromContext } from '../helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { apiClientFactory } from '@vf/api-client';
import { getCookieByName } from '@vf/composables/src/utils/cookie';
import ls from '@vf/composables/src/utils/localStorage';

type locale = {
  code: string;
  default?: boolean;
  name: string;
  path: string;
};

type Site = {
  domain: string;
  locales: locale[];
  name: string;
};

const LocationConfirmationPlugin: Plugin = async (context) => {
  const featureFlags = useFeatureFlagsStore();
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  const { openDialog, setProposedSiteUrl } = useLocationConfirmationStore();
  if (
    !featureFlags.enableLocationConfirmation ||
    vueInstance.$isPreview ||
    vueInstance.$route.fullPath.includes('/select-location')
  )
    return;

  const countryCookie = getCookieByName('Edgescape-Country');
  const appCountryCode = context.app
    .$getEnvValueByCurrentLocale('COUNTRY')
    .toUpperCase();

  if (
    countryCookie === appCountryCode ||
    ls.getItem('confirmedCountry') === appCountryCode
  )
    return;

  const { searchSite } = apiClientFactory(vueInstance);
  const resp = await searchSite(countryCookie);

  if (resp.status !== 200 || !resp.data?.site?.locales) {
    context.app.$log.warn(
      `[@theme/plugins/location-confirmation.ts] User country cannot be determined`,
      resp
    );
    return;
  }
  const userSite: Site = resp.data.site;

  const [lang] = navigator.language.split('-');
  const userCountryCode = countryCookie.toLowerCase();
  const userLocale = `${lang}-${userCountryCode}`;

  const locale =
    userSite.locales.find((l) => l.code === userLocale) ||
    userSite.locales.find((l) => l.default);

  const redirectUrl = locale.path
    ? `${userSite.domain}/${locale.path}`
    : userSite.domain;

  setProposedSiteUrl(redirectUrl);
  openDialog();
};

export default LocationConfirmationPlugin;
