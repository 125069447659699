import { Plugin } from '@nuxt/types';

const StoreLocator: Plugin = (context, inject) => {
  function getBranchName(): string {
    if (context.$env.BRANCH_OF) {
      return context.$env.BRANCH_OF;
    }

    return '';
  }

  inject('getBranchName', getBranchName);
};

export default StoreLocator;
