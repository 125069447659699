function isObject(obj): boolean {
  return obj !== null && typeof obj === 'object';
}

export const isEmptyClass = (
  classes?: Record<string, boolean> | string[] | string
): boolean => {
  if (Array.isArray(classes)) return !classes.some((val) => Boolean(val));
  else if (isObject(classes)) return !Object.values(classes).some((val) => val);
  return !classes;
};
