var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiScroll',_vm._g(_vm._b({class:['vf-swiper', _vm.dir && ("dir-" + _vm.dir)],on:{"refresh":function (data) {
      _vm.notifySlideChange(data);
      _vm.notifyAutoplaySlideChange(data);
    },"init":_vm.init},nativeOn:{"mouseover":function($event){return _vm.pauseAutoplay.apply(null, arguments)},"mouseout":function($event){return _vm.playAutoplay.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.showArrows && _vm.numberOfSlides > 1)?{key:"prev",fn:function(ref){
    var active = ref.active;
    var action = ref.action;
return [_vm._t("prev",function(){return [_c('button',{staticClass:"vf-swiper-control vf-swiper-control-prev",class:[{ disabled: !active }, _vm.classPrev],attrs:{"type":"button","aria-label":_vm.ariaLabelPrev},on:{"click":function($event){$event.stopPropagation();return action.apply(null, arguments)}}},[_c('VfIcon',{attrs:{"icon":_vm.iconName,"size":_vm.iconSize}})],1)]},{"active":active,"action":action})]}}:null,{key:"default",fn:function(ref){
    var active = ref.active;
return [_vm._t("default",null,{"active":active})]}},(_vm.showArrows && _vm.numberOfSlides > 1)?{key:"next",fn:function(ref){
    var active = ref.active;
    var action = ref.action;
return [_vm._t("next",function(){return [_c('button',{staticClass:"vf-swiper-control vf-swiper-control-next",class:[{ disabled: !active }, _vm.classNext],attrs:{"type":"button","aria-label":_vm.ariaLabelNext},on:{"click":function($event){$event.stopPropagation();return action.apply(null, arguments)}}},[_c('VfIcon',{attrs:{"icon":_vm.iconName,"size":_vm.iconSize}})],1)]},{"active":active,"action":action})]}}:null,(_vm.pagination)?{key:"pagination",fn:function(ref){
    var active = ref.active;
    var total = ref.total;
    var action = ref.action;
return [_vm._t("pagination",function(){return [(total)?_c('nav',{staticClass:"vf-swiper-pagination"},_vm._l((total),function(i){return _c('button',{key:i,class:{ active: i - 1 === active },attrs:{"aria-label":i},on:{"click":function($event){return action(i - 1)}}})}),0):_vm._e()]})]}}:null],null,true)},'UiScroll',_vm.scrollCompleteProps,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }