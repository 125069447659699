import isObject from './isObject';

export default function merge(target: any, ...sources: any[]): any {
  if (!sources.length) return target;
  const source: any = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        merge(target[key], source[key]);
      } else Object.assign(target, { [key]: source[key] });
    }
  }
  return merge(target, ...sources);
}
