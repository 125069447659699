import { CmsSharedState } from '@vf/composables/src/useCms/types';
import { defineStore } from 'pinia';

export const useCmsRefStore = defineStore('cmsRef', {
  state: (): CmsSharedState => ({
    orderStatuses: null,
    site: null,
    baseUri: null,
    baseMediaUri: null,
    cmsSiteConfiguration: null,
    errors: [],
    page: null,
    pageTypeName: null,
    pageLayoutName: null,
    pageProps: {},
    breadcrumbs: [],
    espots: [],
    visualFilter: {},
    requirements: [],
    modalData: null,
    modalDataErrors: [],
    cmsInitialized: false,
    currentFetch: {},
    meta: {},
    pageUrl: '',
    stateRedirectUrlExecuted: false,
    canonicalRedirectUrlExecuted: false,
    isNotFound: false,
    badges: {},
    sizeCharts: {},
    seo: {},
    facetConfiguration: {},
    employeeSignInPage: '',
    flexibleContentByPathResult: null,
  }),
});
