import {
  CmsCategoryPageResponse,
  CmsFragmentResponse,
  CmsMenuResponse,
  CmsSiteConfigurationResponse,
  CmsFragmentFolderResponse,
} from '@vf/api-contract';
import type {
  ApiClient,
  CmsApiCallExecutorCaller,
  CmsApiClientSettings,
} from './types';
import { runRequest } from './services/http';
import {
  getCategoryPageContentQuery,
  getFragmentQuery,
  getMenuQuery,
  getPageContentQuery,
  getPageContentByPathQuery,
  getProductPageContentQuery,
  getSiteConfigurationQuery,
  getFragmentFolderQuery,
  getFlexibleContentByPathQuery,
} from './services/queries';

export async function cmsApiClient(
  settings: CmsApiClientSettings
): Promise<ApiClient> {
  const httpRequesterGetter = getCmsRequester(settings);

  return {
    settings,
    getPageContent: getCmsCommonPageContent(httpRequesterGetter),
    getPageContentByPath: getCmsCommonPageContentByPath(httpRequesterGetter),
    getCategoryPageContent: getCmsCategoryPage(httpRequesterGetter()),
    getProductPageContent: getCmsProductPage(httpRequesterGetter()),
    getHeaderAndFooter: getCmsHeaderAndFooter(
      httpRequesterGetter(),
      settings.menuEndpoint
    ),
    getSiteConfiguration: getCmsSiteConfiguration(httpRequesterGetter()),
    getFragment: getCmsFragment(runRequest(settings)),
    getFragmentFolder: getCmsFragmentFolder(runRequest(settings)),
    getFlexibleContentByPath: getCMSFlexibleContentByPath(runRequest(settings)),
  };
}

export const getCmsRequester = (settings: CmsApiClientSettings) => {
  return () => runRequest(settings);
};

export const cmsSiteId = {
  siteId: '',
  get: function () {
    return this.siteId;
  },
  set: function (id) {
    this.siteId = id;
  },
};

export const getCmsCommonPageContent = (
  executorGetter: ReturnType<typeof getCmsRequester>
) => (id: string): Promise<unknown> => {
  const executor = executorGetter();
  return executor(getPageContentQuery(id));
};

export const getCmsCommonPageContentByPath = (
  executorGetter: ReturnType<typeof getCmsRequester>
) => (path: string, segment: string): Promise<any> => {
  const executor = executorGetter();
  return executor(getPageContentByPathQuery(path, segment));
};

export const getCmsFragment = (executor: CmsApiCallExecutorCaller) => (
  id: string
): Promise<CmsFragmentResponse> => executor(getFragmentQuery(id));

export const getCmsCategoryPage = (executor: CmsApiCallExecutorCaller) => (
  siteId: string,
  commercePagePath: string
): Promise<CmsCategoryPageResponse> =>
  executor(getCategoryPageContentQuery(siteId, commercePagePath));

export const getCmsProductPage = (executor: CmsApiCallExecutorCaller) => (
  siteId: string,
  productId: string
): Promise<CmsCategoryPageResponse> =>
  executor(getProductPageContentQuery(siteId, productId));

export const getCmsHeaderAndFooter = (
  executor: ReturnType<typeof runRequest>,
  menuEndpoint: string
) => (id: string): Promise<CmsMenuResponse> =>
  executor(getMenuQuery(id, menuEndpoint));

export const getCmsSiteConfiguration = (
  executor: ReturnType<typeof runRequest>
) => (siteId: string): Promise<CmsSiteConfigurationResponse> =>
  executor(getSiteConfigurationQuery(siteId));

export const getCmsFragmentFolder = (executor: CmsApiCallExecutorCaller) => (
  folder: string,
  domain: string,
  fragmentName: string
): Promise<CmsFragmentFolderResponse> => {
  return executor(getFragmentFolderQuery(folder, domain, fragmentName));
};

export const getCMSFlexibleContentByPath = (
  executor: CmsApiCallExecutorCaller
) => (siteId: string): Promise<CmsFragmentFolderResponse> => {
  return executor(getFlexibleContentByPathQuery(siteId));
};
