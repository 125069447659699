import {
  ComputedRef,
  computed,
  nextTick,
  Ref,
  ssrRef,
} from '@nuxtjs/composition-api';
import {
  apiClientFactory,
  CartLineItem,
  Product,
  ProductAttribute,
  ProductAttributeCodes,
  ProductInventoryState,
} from '@vf/api-client';
import {
  useGtm,
  useRequestTracker,
  useCart,
  useSaveForLater,
  useI18n,
  useFindInStore,
  useRouting,
} from '@vf/composables';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { getBreadcrumbs } from '@vf/composables/src/useCms/utils';
import { getId } from '@vf/composables/src/useUrl/handlers/parseUrl';
import {
  prepareCount,
  prepareFiltersJson,
  prepareStart,
  prepareLocale,
  prepareCategoryFilters,
} from '../utils/query';
import { transformPageMetaData } from '../utils/metaTags';
import { productSnapshots } from '../utils/productSnapshots';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import { appendColorsPrice } from './utils/appendColorsPrice';
import { useProductGallery } from './composables/useProductGallery';
import { useProductColors } from './composables/useProductColors';
import { useQuickShop } from './composables/useQuickShop';
import { useCmsRefStore } from '../store/cmsRef';
import { useFeatureFlagsStore } from '../store/featureFlags';
import {
  GetProductDetailsOptions,
  PriceData,
  PriceRangeData,
  ProductConfiguration,
  ProductContext,
  UseProductStorage,
} from './types';
import { useProductAttributes } from './composables/useProductAttributes';
import { Context } from '@vf/api-contract';

export const initiateVariantsWithStockValue = (responseData) => {
  let inStock;
  let colorsAvailabilityMap: Record<string, boolean>;

  if (responseData.productInventoryState) {
    inStock =
      responseData.productInventoryState === ProductInventoryState.InStock;
  } else {
    colorsAvailabilityMap = {};
    const colors =
      responseData.attributes.find(({ code }) => code === 'color')?.options ||
      [];

    colors.forEach(({ value, available }) => {
      colorsAvailabilityMap[value] = available;
    });
  }

  responseData.variants.forEach((variant) => {
    if (colorsAvailabilityMap) {
      const color = variant.attributes.color;
      inStock = colorsAvailabilityMap[color];
    }

    variant.stock = {
      inStock,
    };
  });
};

const useProduct = (instance: ComponentInstance, contextKey?: string) => {
  const { isNotifyMeEnabled } = useFeatureFlagsStore();

  const { dispatchEvent } = useGtm(instance);
  const { resetData } = useFindInStore(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { swapItem } = useCart(instance);
  const { updateSaveForLater, isSaveForLaterProductToUpdate } = useSaveForLater(
    instance
  );

  const { localeCode } = useI18n(instance);
  const cmsRefStore = useCmsRefStore(instance.$pinia);
  const {
    getCatalog: getCatalogAPI,
    getProductDetails: getProductDetailsAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseProductStorage> = initStorage<UseProductStorage>(
    instance,
    `useProduct-${contextKey}`
  );
  const product: Ref<Product> =
    storage.get('product') ??
    storage.save('product', ssrRef(null, `useProduct-product-${contextKey}`));

  const loading: Ref<boolean> =
    storage.get('loading') ??
    storage.save('loading', ssrRef(false, `useProduct-loading-${contextKey}`));

  const oldProduct: Ref<CartLineItem> =
    storage.get('oldProduct') ??
    storage.save(
      'oldProduct',
      ssrRef(null, `useProduct-product-${contextKey}`)
    );
  const contextProducts: Ref<ProductContext> =
    storage.get('contextProducts') ??
    storage.save(
      'contextProducts',
      ssrRef(null, `useProduct-contextProducts-${contextKey}`)
    );

  const priceDataEmptyObject: PriceData = {
    selectedVariant: {
      currentPrice: 0,
      originalPrice: 0,
    },
    specialPrice: 0,
    currency: '',
    hasSpecialPrice: false,
    showPriceRange: false,
    priceRange: {
      lowest: 0,
      isLowestPriceDiscounted: false,
      highest: 0,
      isHighestPriceDiscounted: false,
      currency: '',
    },
  };

  const priceData: Ref<PriceData> =
    storage.get('priceData') ??
    storage.save(
      'priceData',
      ssrRef(priceDataEmptyObject, `useProduct-priceData-${contextKey}`)
    );

  const productGalleryConfig = instance.$themeConfig.productGallery;

  /**
   * Example:
   * getProductDetails('25791388M')
   * @param slug
   */
  const getProductDetails = async (
    slug: string,
    {
      isBackgroundRequest = false,
      loadImages = true,
      saveVariation = false,
      saveOnlyColor = false,
      showLoadingState = true,
      configuredColor = '',
      configuredVariant = '',
      configuredSize = '',
    }: GetProductDetailsOptions = {}
  ) => {
    const { tag } = trackRequest(
      'useProduct-getProductDetails',
      isBackgroundRequest
    );

    if (showLoadingState) {
      loading.value = true;
      // reset gallery so that during loading we do not show the gallery of previous product
      resetGallery();
    }

    try {
      const query: string[] = [prepareLocale(localeCode())];

      if (
        configuredColor &&
        instance.$getCustomConfiguration('useColorParamForProductApiCall')
      ) {
        query.push(`color=${configuredColor}`);
      }

      const response = await getProductDetailsAPI(slug, query.join('&'));
      let responseData = response.data;

      // Mock product color URLs
      if (responseData.attributes) {
        const colorsAttribute = responseData.attributes.find(
          (attribute: ProductAttribute) =>
            attribute.code === ProductAttributeCodes.Color
        );
        if (
          typeof colorsAttribute !== 'undefined' &&
          typeof colorsAttribute.options !== 'undefined'
        ) {
          colorsAttribute.options = colorsAttribute.options.map((color) => {
            color.src = instance.$root.$mediaUrlGenerator({
              colorName: color.label,
              pid: color.slug,
              preset:
                productGalleryConfig.presets.thumbnail ??
                '$COLOR-SWATCH$&wid=94&hei=94',
              productName: responseData.name,
              shotType: instance.$env.IMAGES_SHOTTYPE,
            });
            return color;
          });
        }
      }

      emptyStorageData();
      responseData = mapProductAttributeOptions(slug, responseData, instance);
      responseData.quantities = [];
      responseData.quantity = { id: 1, value: 1 };
      responseData.validation = {
        missingSize: false, // initial validation values can be removed after update to Vue 3 (reactivity issue in Vue 2)
      };
      responseData.validation = {
        missingLength: false, // initial validation values can be removed after update to Vue 3 (reactivity issue in Vue 2)
      };
      responseData.size =
        saveVariation && product.value?.size ? product.value.size : null;
      responseData.length =
        saveVariation && product.value?.length ? product.value.length : null;
      responseData.color =
        (saveVariation || saveOnlyColor) && product.value?.color
          ? product.value.color
          : null;
      responseData.zip =
        saveVariation && product.value?.zip ? product.value.zip : null;
      // Vans-specific - we don't want to carry over the width
      responseData.width = null;
      responseData.productSlug = responseData.product_slug;
      responseData.primaryCategorySlug = responseData.primary_category_slug;
      responseData.primaryCategoryName = responseData.primary_category_name;
      responseData.sizeChart = responseData.sizeChart
        ? responseData.sizeChart[0]
        : null;
      responseData.meta = transformPageMetaData(
        responseData.pageMetaData || responseData.pageMetaTags
      );

      const { getLocaleFromPath } = useRouting(instance);
      const locale = getLocaleFromPath(instance.$route.path);

      responseData.benefits = productSnapshots(
        responseData.benefitsAttribute,
        responseData.activityAttribute,
        locale
      );
      responseData.attributes = appendColorsPrice(
        responseData.attributes,
        responseData.variants
      );

      initiateVariantsWithStockValue(responseData);
      configureVariants(responseData, false);

      product.value = responseData;

      setSingleOptionAttributes();
      mapAttributesData();
      applySelectionFromUrl(configuredVariant, configuredSize);
      updatePriceData();

      if (!product.value?.variant?.price?.current) {
        instance.$log.error(
          `[@useProduct::getProductDetails] Missing <current> property of <variant.price> attribute for product <${slug}>. Please check catalog configuration for that product.`
        );
      }

      if (loadImages)
        await loadPdpGalleryImages({
          isBackgroundRequest,
          configuredColor,
          allowColorOutsideOfVariants:
            contextKey === Context.QuickShop && !saveOnlyColor,
        });

      loading.value = false;

      return product.value;
    } catch (e) {
      instance.$log.error(
        `[@useProduct::getProductDetails]. Error message: ${e.message}`
      );
      throw e;
    } finally {
      loading.value = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const applySelectionFromUrl = (variantId?: string, size?: string): void => {
    if (!variantId && !size) return;
    const productVariant = variantId
      ? product.value.variants?.find(
          (element) => element.id.toUpperCase() === variantId.toUpperCase()
        )
      : null;
    if (productVariant?.attributes?.size || size)
      configureSize(productVariant?.attributes?.size || size);
  };

  const configure = (configuration: ProductConfiguration) => {
    const size = product.value.sizes?.find(
      (s) => s.value === configuration?.size
    );
    const length = product.value.lengths?.find(
      (l) => l.value === configuration?.length
    );
    const color = product.value.colors?.find(
      (c) => c.value === configuration?.color
    );
    const quantity = product.value.quantities?.find(
      (q) => q.value === configuration?.quantity
    );
    const zip = product.value.zips?.find((z) => z.value === configuration?.zip);
    const width = product.value.widths?.find(
      (w) => w.value === configuration?.width
    );

    product.value.size = size || product.value.size;
    product.value.length = length || product.value.length;
    product.value.zip = zip || product.value.zip;
    product.value.width = width || product.value.width;
    product.value.color = color || product.value.color;
    product.value.quantity = quantity || product.value.quantity;
    configureVariants(product.value);
  };

  const {
    pdpImages,
    pdpGalleryDataSources,
    loadPdpGalleryImages,
    loadPlpGalleryImages,
    setPdpGalleryDataSource,
    resetGallery,
  } = useProductGallery(instance, { product }, contextKey);

  const {
    colorsGroupedByPriceDiscount,
    hasColorPriceWithDiscount,
    setInitialColor,
    colorsOptions,
    getColorByCode,
    getColorByWidth,
  } = useProductColors({ product, configure });

  const {
    findInStoreQuickShopVisible,
    isQuickShopOpen,
    toggleQuickShop,
    quickShopOpenProductId,
    closeQuickShop,
  } = useQuickShop(instance, contextKey, { getProductDetails, configure });

  const {
    attributesData,
    variantsData,
    mapAttributesAndVariantsData,
    mapAttributesData,
    isAttributePriceVariation,
    getDefaultAttributesOptionValues,
    setSingleOptionAttributes,
    setAttributePriceVariationClicked,
    availableAttributes,
    areAllAttributesSelected,
    checkAttributes,
    mapProductAttributeOptions,
    emptyAttributesData,
    emptyVariantsData,
  } = useProductAttributes(instance, { product, configure }, contextKey);

  const setOldProduct = (oldItem: CartLineItem): void => {
    oldProduct.value = oldItem;
  };

  const swapProduct = async () => {
    let isSwapped: boolean;
    if (!product.value.size) {
      product.value.validation.missingSize = true;
      return false;
    }
    if (!product.value.length) {
      product.value.validation.missingLength = true;
    }
    if (isSaveForLaterProductToUpdate.value) {
      isSwapped = await updateSaveForLater(
        product.value,
        oldProduct.value.itemId,
        oldProduct.value.qty,
        false
      );
    } else {
      isSwapped = await swapItem(oldProduct.value, {
        ...product.value,
        quantity: oldProduct.value.qty,
      });
    }
    if (isSwapped) {
      closeQuickShop();
    }
  };

  const configureVariants = (product: Product, setPriceData = true): void => {
    if (product.variants && product.variants.length) {
      // return all variant match with selected attributes or first variant occurence
      let filteredVariants = product.variants;
      product.attributes
        .map((el: { code: string }) => el.code)
        .forEach((attributeCode: string) => {
          if (product[attributeCode]?.value) {
            filteredVariants = filteredVariants.filter(
              (variant) =>
                variant.attributes[attributeCode] ===
                product[attributeCode].value
            );
          }
        });

      if (filteredVariants.length > 0) {
        product.variant = filteredVariants[0];
        product.sku = filteredVariants[0].id;
      } else {
        product.variant = product.variants[0];
        product.sku = product.variants[0].id;
      }

      if (setPriceData) {
        updatePriceData();
      }
    } else {
      instance.$log.error(
        `[@useProduct::configureVariants] Product <${product.id}> has no variants configured`
      );
    }
  };

  const fetchBreadcrumbsWithCatalogAPI = async () => {
    const categoryId = getId(product.value.primaryCategorySlug);
    const query: string[] = [
      prepareStart(0),
      prepareCount(1),
      prepareLocale(localeCode()),
      prepareCategoryFilters([], categoryId, null),
      prepareFiltersJson([], categoryId),
    ];
    loading.value = true;
    try {
      const catalogResp = await getCatalogAPI(query.join('&'));

      const breadcrumbs = getBreadcrumbs(
        {
          commercePath: catalogResp.data.breadcrumbs.map((item) => ({
            link: item.url,
            title: item.label,
            type: 'product',
          })),
          commerceRef: {
            link: product.value.fullPageUrl,
            title: product.value.name,
            type: 'product',
          },
        },
        'product',
        instance,
        cmsRefStore
      );
      cmsRefStore.$patch({ breadcrumbs });
      loading.value = false;

      return breadcrumbs;
    } finally {
      loading.value = false;
    }
  };

  const getProductsData = async (slugs: string[]) => {
    const query: string[] = [prepareLocale(localeCode())];
    const products: ProductContext = {};
    for (const slug of slugs) {
      const response = await getProductDetailsAPI(slug, query.join('&'));
      products[slug] = response.data;
    }
    contextProducts.value = products;
  };

  const changeQuantity = (value: number): void => {
    product.value.quantity = {
      id: value,
      value,
    };
  };

  const scrollToFirstValidationError = (
    offset: number,
    shouldScrollInToView = false
  ): void => {
    nextTick(() => {
      const firstErrorElem = document.querySelector(
        '.product-sizes__error'
      ) as HTMLElement;
      const top = firstErrorElem.offsetTop;
      if (firstErrorElem) {
        if (shouldScrollInToView) {
          firstErrorElem.scrollIntoView(true);
        } else {
          scrollToTop({
            top: top - offset,
          });
        }
      }
    });
  };

  const getProductSpecialPrice = (
    productData: Product,
    displayPriceRange = false
  ): number => {
    const currentItemInfo =
      displayPriceRange && areAllAttributesSelected.value
        ? productData.variants.find((item) => {
            attributesData.value.attributeCodes.every(
              (el) => productData[el] === item.attributes[el]
            );
          })
        : productData.variants.find((item) => {
            return (
              productData[attributesData.value.attributeGroupingPrices]
                ?.value ===
              item.attributes[attributesData.value.attributeGroupingPrices]
            );
          });

    // If discount is 0, show original price, otherwise if discount is present, show the discounted price,
    // and lastly if there is not discount for any reason, then show the current price of the variant (original logic)
    return currentItemInfo?.price?.discount === 0
      ? currentItemInfo.price?.original
      : currentItemInfo?.price?.discount
      ? currentItemInfo.price?.current
      : productData.variant?.price?.current;
  };

  const isSelectedVariationOutOfStock: ComputedRef<boolean> = computed(
    () =>
      areAllAttributesSelected.value && !product.value?.variant.stock.inStock
  );

  const isSelectedVariationLowOnStock: ComputedRef<boolean> = computed(
    () =>
      areAllAttributesSelected.value &&
      product.value?.variant.stock.quantity > 0 &&
      product.value?.variant.stock.quantity < 10
  );

  const isSelectedProductOutOfStock: ComputedRef<boolean> = computed(
    () =>
      variantsData.value.areAllVariantsOutOfStock ||
      isSelectedVariationOutOfStock.value
  );

  const isAlreadySelected = (size) =>
    size && product.value?.size?.value !== size;

  const configureSize = (size, contextKey = 'page-content') => {
    if (contextKey === 'quickshop') {
      resetData();
    }
    if (isAlreadySelected(size)) {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Select Size',
          eventLabel: `${product.value.id} - ${size}`,
        },
      });
    }
    configure({ size });
    product.value.validation.missingSize = false;
  };

  const getAttributeOrderOptions = (attr: ProductAttributeCodes) =>
    product.value.attributes
      .find((el: ProductAttribute) => el.code === attr)
      ?.options.map((el) => el.value) || [];

  const getSortedVariantGrouping = (attributeName) => {
    const priceVariation = Object.values(
      getGroupsByPriceVariation(attributeName)?.size || {}
    );
    const order = getAttributeOrderOptions(attributeName);
    return priceVariation.sort(
      (a: any, b: any) =>
        order.indexOf(a.firstVariantAttribute) -
        order.indexOf(b.firstVariantAttribute)
    );
  };

  const showPriceRange = computed(() => {
    if (
      !product.value.variants.length ||
      !product.value?.displayPriceRange ||
      areAllAttributesSelected.value ||
      !variantsData.value.sortedVariantsByCurrentPrice[
        variantsData.value.sortedVariantsByCurrentPrice.length - 1
      ]?.price.current ||
      variantsData.value.sortedVariantsByCurrentPrice.find(
        (el) => el.price?.current
      ).price?.current ===
        variantsData.value.sortedVariantsByOriginalPrice[
          variantsData.value.sortedVariantsByOriginalPrice.length - 1
        ]?.price.original ||
      attributesData.value.isAttributePriceVariationClicked
    ) {
      return false;
    }

    return true;
  });

  const priceRange: ComputedRef<PriceRangeData> = computed(() => {
    return {
      lowest: variantsData.value.sortedVariantsByCurrentPrice[0]?.price.current,
      isLowestPriceDiscounted:
        variantsData.value.sortedVariantsByCurrentPrice[0]?.price.current <
        variantsData.value.sortedVariantsByCurrentPrice[0]?.price.original,
      highest:
        variantsData.value.sortedVariantsByCurrentPrice[
          variantsData.value.sortedVariantsByCurrentPrice.length - 1
        ]?.price.current,
      isHighestPriceDiscounted:
        variantsData.value.sortedVariantsByCurrentPrice[
          variantsData.value.sortedVariantsByCurrentPrice.length - 1
        ]?.price.current <
        variantsData.value.sortedVariantsByCurrentPrice[
          variantsData.value.sortedVariantsByCurrentPrice.length - 1
        ]?.price.original,
      currency:
        variantsData.value.sortedVariantsByCurrentPrice[0]?.price.currency,
    };
  });

  const getGroupsByPriceVariation = (attribute: string) => {
    return variantsData.value.remappedVariantsByPriceAndAttributes.find(
      (el) => el[attribute]
    );
  };

  const isWideAvailable: ComputedRef<boolean> = computed(() => {
    const { colors, attributes } = product.value || {
      colors: [],
      attributes: [],
    };

    const width = attributes.find(({ code }) => code === 'width');

    return !!(
      width &&
      width.options?.length > 1 &&
      colors.some(({ badges }) =>
        badges?.map((badge) => badge.toLowerCase()).includes('wide')
      )
    );
  });

  const updatePriceData = (): void => {
    priceData.value = {
      selectedVariant: {
        currentPrice: product.value.variant?.price?.current || 0,
        originalPrice: product.value.variant?.price?.original || 0,
      },
      specialPrice: getProductSpecialPrice(
        product.value,
        product.value.displayPriceRange
      ),
      currency: product.value.variant?.price.currency || '',
      hasSpecialPrice:
        product.value.variant?.price?.original >
        product.value.variant?.price?.current,
      showPriceRange: showPriceRange.value,
      priceRange: priceRange.value,
    };
  };

  const emptyStorageData = (): void => {
    emptyAttributesData();
    emptyPriceData();
    emptyVariantsData();
  };

  const emptyPriceData = (): void => {
    priceData.value = {
      selectedVariant: {
        currentPrice: 0,
        originalPrice: 0,
      },
      specialPrice: 0,
      currency: '',
      hasSpecialPrice: false,
      showPriceRange: false,
      priceRange: {
        lowest: 0,
        isLowestPriceDiscounted: false,
        highest: 0,
        isHighestPriceDiscounted: false,
        currency: '',
      },
    };
  };

  const attributePriceVariationClickAction = (attributeCode: string): void => {
    if (attributesData.value.attributeGroupingPrices === attributeCode) {
      let triggerUpdateAfter = false;
      if (!attributesData.value.isAttributePriceVariationClicked) {
        triggerUpdateAfter = true;
      }
      setAttributePriceVariationClicked(true);
      if (triggerUpdateAfter) updatePriceData();
    }
  };

  const isAttributeOutOfStockStatus = (name, value) => {
    if (!availableAttributes.value[name]) {
      return false;
    }
    return (
      product.value &&
      product.value[name] !== null &&
      !availableAttributes.value[name].find(
        (variant) => variant.attributes[name] == value
      )
    );
  };

  const productSize = computed(() => product.value?.size || null);

  const availableSizes = computed(() =>
    product.value.sizes.filter((item) => item.available === true)
  );

  const shouldShowPdpStickyHeader = computed(
    () =>
      checkNotifyMeEnabled() ||
      (!variantsData.value.areAllVariantsOutOfStock &&
        availableSizes.value.length &&
        (productSize.value ? productSize.value.available : true) &&
        !isSelectedProductOutOfStock.value)
  );

  /**
   * Check if Notify Me state for product is enabled
   *
   * @returns - true if enabled, false otherwise
   */
  const checkNotifyMeEnabled = (): boolean =>
    isNotifyMeEnabled &&
    (attributesData.value.isFutureProduct ||
      ((product.value?.notifyMe || product.value?.variant?.notifyMe) &&
        isSelectedProductOutOfStock.value));

  return {
    configure,
    contextKey,
    pdpImages,
    isSelectedProductOutOfStock,
    isSelectedVariationLowOnStock,
    areAllAttributesSelected,
    product: computed(() => product.value),
    productVariant: computed(() => product.value?.variant),
    productColor: computed(() => product.value?.color),
    productSize,
    productZip: computed(() => product.value?.zip),
    productWidth: computed(() => product.value?.width),
    productLength: computed(() => product.value?.length),
    loading: computed(() => loading.value),
    meta: computed(() => product.value.meta),
    sizeChartId: computed(() => product.value?.sizeChart),
    isQuickShopContext: computed(() => contextKey === 'quickshop'),
    contextProducts: computed(() => contextProducts.value),
    fetchBreadcrumbsWithCatalogAPI,
    setOldProduct,
    swapProduct,
    getProductDetails,
    toggleQuickShop,
    setInitialColor,
    changeQuantity,
    setPdpGalleryDataSource,
    findInStoreQuickShopVisible,
    oldProduct: computed(() => oldProduct.value),
    pdpGalleryDataSources: computed(() => pdpGalleryDataSources.value),
    getProductsData,
    configureVariants,
    availableAttributes,
    checkAttributes,
    scrollToFirstValidationError,
    hasColorPriceWithDiscount,
    colorsGroupedByPriceDiscount,
    getProductSpecialPrice,
    loadPdpGalleryImages,
    isQuickShopOpen: computed(() => isQuickShopOpen.value),
    quickShopOpenProductId: computed(() => quickShopOpenProductId.value),
    closeQuickShop,
    loadPlpGalleryImages,
    configureSize,
    getDefaultAttributesOptionValues,
    setSingleOptionAttributes,
    isAttributePriceVariation,
    showPriceRange,
    getColorByCode,
    priceRange,
    attributePriceVariationClickAction,
    getGroupsByPriceVariation,
    getSortedVariantGrouping,
    isWideAvailable,
    colorsOptions,
    attributesData,
    variantsData,
    priceData,
    mapAttributesAndVariantsData,
    updatePriceData,
    getColorByWidth,
    isAttributeOutOfStockStatus,
    shouldShowPdpStickyHeader,
    checkNotifyMeEnabled,
  };
};

export default useProduct;
