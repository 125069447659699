import { useLoyalty } from '../../useLoyalty';
import { EventPropsHandler } from '.';
import { LoadUserDataEvent } from '../../types/gtm';
import { useAuthentication } from '../../useAuthentication';
import { useUserStore } from '../../store/user';
import { storeToRefs } from 'pinia';

export const loadUserDataHandler: EventPropsHandler<
  Omit<LoadUserDataEvent, 'customMetrics' | 'customVariables'>
> = (_, vmInstance) => {
  const userStore = useUserStore(vmInstance);
  const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
  const {
    getConsumerId,
    consumerNo,
    consumerType,
    isIpaProsumer,
    isWranxProsumer,
  } = useAuthentication(vmInstance);

  let user: LoadUserDataEvent['user'] = {
    loggedIn: loggedIn.value,
    'guest-id': getConsumerId.value,
  };

  if (loggedIn.value) {
    const { memberId } = useLoyalty(vmInstance);

    user = {
      ...user,
      'consumer-id': consumerNo.value,
      loyaltyMember: loyaltyEnrolled.value,
      ...(memberId.value && { 'loyaltyMember-id': memberId.value }),
      vfEmployee: consumerType.value === 'EMPLOYEE',
      ...(isIpaProsumer.value && { prosumer: 'ipa' }),
      ...(isWranxProsumer.value && { prosumer: 'wranx' }),
      ...(userStore.athlete && { prosumer: 'athlete' }),
    };
  }

  return {
    event: 'loadUserData',
    user,
    _clear: true,
  };
};
