import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader, CreateReturnObject } from '../../types';
import { AxiosResponse } from 'axios';
import { CreateReturnPayload } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const createReturn = (configuration: ApiClientConfiguration) => (
  orderHeaderKey: string,
  body: CreateReturnPayload,
  isPreview: boolean,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<CreateReturnObject>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.createReturn,
    {
      config: configuration,
      mapping: { orderHeaderKey },
    }
  );
  const headers = headerBuilder(configuration, {
    'x-usid': usid,
  });
  return axios.post(`${url}?preview=${isPreview ? 'Y' : 'N'}`, body, {
    headers,
  });
};
