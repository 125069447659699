export const CATEGORY_ID_PATTERN = '[0-9]{3,7}';

const OLD_PRODUCT_ID_PATTERN = '[a-z0-9]{6}';

// TODO remember to change following pattern if new brands are added

export const PRODUCT_ID_PATTERN = '(sw|vn|nf)([a-z0-9]{6,10})';

export const CATEGORY_ID_REGEX = new RegExp(CATEGORY_ID_PATTERN);

export const PRODUCT_ID_REGEX = new RegExp(PRODUCT_ID_PATTERN, 'i');

export const OLD_PRODUCT_ID_REGEX = new RegExp(OLD_PRODUCT_ID_PATTERN, 'i');

export const CATEGORY_URL_SEGMENT_REGEX = new RegExp(
  `-c${CATEGORY_ID_PATTERN}\/?$`,
  'i'
);

export const PRODUCT_URL_SEGMENT_REGEX = new RegExp(
  `-p${PRODUCT_ID_PATTERN}\/?$`,
  'i'
);

export const OLD_PRODUCT_URL_SEGMENT_REGEX = new RegExp(
  `-p${OLD_PRODUCT_ID_PATTERN}\/?$`,
  'i'
);
