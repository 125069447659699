import { logger } from '@vf/shared/src/utils/logger-client';
import { useAuthentication } from '@vf/composables/src/useAuthentication';
import { useCart } from '@vf/composables/src/useCart';
import { useBasicInformation } from '@vf/composables/src/useAccount/composables/useBasicInformation';
import { useSectionNumeration } from '@vf/composables';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import { watch } from '@vue/composition-api';
import { getRenderer } from '@/helpers';
import sessionStorage from '@vf/composables/src/utils/sessionStorage';

const collectComponents = (acc, el) => [
  ...acc,
  ...(el?.component ? [el.component] : []),
  ...(el?.children ? el.children.reduce(collectComponents, []) : []),
];

export const importPageComponents = async (page) => {
  const imports = [page]
    .reduce(collectComponents, [])
    .map((component) => import(`~/components/smart/${component}`));

  return Promise.all(imports).catch((e) => {
    logger.error(`Could not import page components: ${e.message}`);
  });
};

export const loadLocalComponent = (componentName) => {
  return `LazyCMS${getComponentName(componentName)}`;
};

export const getComponentName = (value) =>
  value?.split('/').reverse()[0] || value;

export const loadPreviewBlueprintPlugin = (root, renderer) => {
  /** CoreMedia base preview functionalities script */
  const previewBase = document.createElement('script');
  previewBase.setAttribute(
    'src',
    root.$env[`COREMEDIA_URI_PREVIEW_${renderer}`] +
      '/static/coremedia.preview.js'
  );

  /** Append all required preview scripts to Head */
  document.head.appendChild(previewBase);
};

export const PRESERVE_PREVIEW = 'preview';
export const MONETATE_PARAM = 'p13n_testcontext';

export const addPreservePreviewParam = (extraParams) => {
  if (typeof window !== 'undefined' && window.history.replaceState) {
    const { search, protocol, host, pathname } = window.location;
    const searchParams = new URLSearchParams(search);
    extraParams && window.sessionStorage.setItem(MONETATE_PARAM, extraParams);
    if (!searchParams.has(PRESERVE_PREVIEW)) {
      searchParams.append(PRESERVE_PREVIEW, 'true');
      const monetateParam = window.sessionStorage.getItem(MONETATE_PARAM);
      monetateParam && searchParams.append(MONETATE_PARAM, monetateParam);
      const newUrl = `${protocol}//${host}${pathname}?${searchParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    } else if (searchParams.get(PRESERVE_PREVIEW) == 'false') {
      window.sessionStorage.removeItem(PRESERVE_PREVIEW);
      window.sessionStorage.removeItem(MONETATE_PARAM);
    }
  }
};

export const handlePreviewCookie = (instance) => {
  const cookieName = 'previewDateTime';
  const preview = instance.$route.query[PRESERVE_PREVIEW];
  const previewDateTime = instance.$route.query[cookieName];

  if (preview === 'true' && previewDateTime) {
    instance.$cookies.set(cookieName, previewDateTime, { path: '/api' });
    return;
  }

  if (preview === 'false') {
    // setting empty cookie with expiration date in the past
    // to effectively delete it for both client and server side
    instance.$cookies.set(cookieName, '', {
      path: '/api',
      expires: new Date(0),
    });
  }
};

export const handlePreviewModeClientSide = (instance, cmsSiteConfig) => {
  const monetateParam = instance.$route.query?.p13n_testcontext;
  sessionStorage.setItem(PRESERVE_PREVIEW, 'true');
  addPreservePreviewParam(monetateParam);
  // Metadata for responsive device slider preview
  const sliderPreviewMetadata = cmsSiteConfig?.previewSliderMetadata;
  document.body.setAttribute(
    'data-cm-metadata',
    JSON.stringify([sliderPreviewMetadata])
  );
  handlePreviewCookie(instance);
  // Placement Highlighting for preview
  loadPreviewBlueprintPlugin(instance, getRenderer());
  watch(
    () => instance.$route.fullPath,
    () => addPreservePreviewParam()
  );
};

/**
 * Create hreflang url for given locale by removing brand name
 */
export const getLocaleUrl = (locale, canonicalUrlObject) => {
  const { urlSegment, urlHost = '' } = canonicalUrlObject;

  const urlLocaleIndex = urlSegment.indexOf(locale);
  let segment = urlSegment;

  if (urlLocaleIndex > 0) {
    segment = urlSegment.slice(urlLocaleIndex);
  }

  return `https://${urlHost}/${segment}`;
};

export const getCustomizerModelUrlObject = (url, canonicalUrlObject) => {
  const splitUrl = url.split('/customizer.');
  const model = splitUrl[1].includes('?')
    ? splitUrl[1].split('?')[0]
    : splitUrl[1];

  return {
    ...canonicalUrlObject,
    urlSegment: `${canonicalUrlObject.urlSegment}.${model}`,
  };
};

const getUniqueCmsId = (existingIds, id) => {
  if (!id) {
    return id;
  }

  /** If ID is not yet in the set we can return it and add it to the set */
  if (!existingIds.has(id)) {
    existingIds.add(id);
    return id;
  }

  /** Initialize unique id generator variables */
  let counter = 1;
  let uniqueId = `${id}-${counter}`;

  /**
   * Increase counter and set new component key until it's unique
   * It is required in case CMS author inserts the same component on the page several times
   * */
  while (existingIds.has(uniqueId)) {
    counter++;
    uniqueId = `${id}-${counter}`;
  }

  /** Add generated id to the set */
  existingIds.add(uniqueId);

  /** Return unique generated component key */
  return uniqueId;
};

export const checkIfShouldSkip = (
  c,
  root,
  contextKey,
  redirectComponentSpotted
) => {
  if (!c || redirectComponentSpotted) return true;
  return (
    (c.props &&
      c.props._states &&
      !root.$stateManagementChecker(c.props._states, contextKey)) ||
    (c._segments && !root?.$segmentsChecker(c._segments))
  );
};

const createTreeElement = (
  createElement,
  renderTree,
  c,
  componentKey,
  contextKey,
  propsOverride = null
) => {
  return createElement(
    loadLocalComponent(c.component),
    {
      key: componentKey,
      attrs: { ...c.attrs },
      props: {
        ...(propsOverride ? propsOverride : c.props),
        contextKey,
      },
      slot: c.slot,
      scopedSlots: c.slots
        ? c.slots.reduce((ob, slot) => {
            ob[slot.name] = () =>
              createElement(
                'div',
                { domProps: { innerHTML: slot.content } },
                []
              );
            return ob;
          }, {})
        : {},
    },
    c.children ? renderTree(c) : null
  );
};

let setNumberedSectionList;
let isManuallyRemoved;
export const treeRenderer = (createElement, content, contextKey, root) => {
  if (!content) {
    return null;
  }

  if (!setNumberedSectionList || !isManuallyRemoved) {
    ({ setNumberedSectionList, isManuallyRemoved } = useSectionNumeration(
      root
    ));
  }

  let redirectComponentSpotted = false;

  const componentKeysSet = new Set();
  const renderTree = (node) => {
    const numberedSections = [];

    const componentsTree = node.children.map((c) => {
      if (checkIfShouldSkip(c, root, contextKey, redirectComponentSpotted)) {
        return null;
      }

      if (c.uniqueKey === 'redirect-component') {
        redirectComponentSpotted = true;
        return null;
      }

      const { showNumeration, hideComponent } = c.props || {};

      if (
        showNumeration &&
        !hideComponent &&
        isManuallyRemoved &&
        !isManuallyRemoved(c.attrs['data-id'])
      ) {
        numberedSections.push(c.attrs['data-id']);
      }

      const componentKey = getUniqueCmsId(componentKeysSet, c._cms_id);

      if (!c.replacePlaceholders) {
        return createTreeElement(
          createElement,
          renderTree,
          c,
          componentKey,
          contextKey
        );
      }

      return createElement(
        loadLocalComponent('shared/PlaceholderReplacementWrapper'),
        {
          props: {
            childProps: {
              ...c.props,
              contextKey,
            },
          },
          scopedSlots: {
            default: (slotProps) =>
              createTreeElement(
                createElement,
                renderTree,
                c,
                componentKey,
                contextKey,
                slotProps
              ),
          },
        }
      );
    });

    if (numberedSections.length && setNumberedSectionList) {
      setNumberedSectionList(numberedSections);
    }

    return componentsTree;
  };

  if (redirectComponentSpotted) {
    return null;
  }

  return renderTree(content);
};

export const shouldRender = (
  { path, resourceId, resourceType },
  currentFetch
) => {
  const pathFetched = path && path === currentFetch.path;
  const resourceIdFetched =
    resourceId && resourceId === currentFetch.resourceId;
  const resourceTypeFetched =
    resourceType && resourceType === currentFetch.resourceType;

  return pathFetched || (resourceIdFetched && resourceTypeFetched);
};

export const finalContentRenderer = (
  createElement,
  pageLayoutName,
  contextKey,
  elementsArray,
  errors,
  root,
  additionalComponents = [],
  headerConfig
) => {
  return createElement(
    'div',
    {
      class: { [`vf-page-layout__${pageLayoutName}`]: pageLayoutName },
    },
    [
      errors && errors.length
        ? errorsRenderer(createElement, errors, headerConfig)
        : null,
      ...elementsArray
        .map((element) => {
          const tag =
            element?.children[0]?.component === 'grid/Placement'
              ? 'main'
              : 'div';
          return createElement(
            tag,
            {},
            treeRenderer(createElement, element, contextKey, root)
          );
        })
        .concat(additionalComponents),
    ]
  );
};

export const finalPageRenderer = (
  createElement,
  contextKey,
  elementsArray,
  errors,
  root,
  pageCategory,
  pageLayoutName,
  headerConfig
) =>
  finalContentRenderer(
    createElement,
    pageLayoutName,
    contextKey,
    elementsArray,
    errors,
    root,
    [
      createElement(
        'component',
        {
          is: 'script',
        },
        `window.vfPageCategory = '${pageCategory || ''}';`
      ),
    ],
    headerConfig
  );

export const errorsRenderer = (createElement, errors, headerConfig) => {
  return createElement(
    'div',
    {
      key: 'cms-errors-container',
      class: headerConfig.isAnimated ? ['cms-debug-offset'] : [],
    },
    (errors || []).map((error) =>
      createElement(
        'div',
        {
          style: {
            color: '#f00',
            padding: '0.2em 1em',
            margin: '0.1em',
            fontSize: '.8rem',
            border: '1px solid',
          },
        },
        error
      )
    )
  );
};

const getSeoLinks = (pageUrl, localizations = []) => {
  if (!pageUrl) return [];

  const getHreflangUrl = (item) => {
    const url = new URL(
      pageUrl.startsWith('http') ? pageUrl : `https://${pageUrl}`
    );

    switch (item.value.type) {
      case 'CMExternalLink':
        return item.value.url.startsWith('http')
          ? item.value.url
          : url.origin + item.value.url;
      case 'CMChannel': {
        return !pageUrl.includes('/customizer.')
          ? getLocaleUrl(item.key, item.value)
          : getLocaleUrl(
              item.key,
              getCustomizerModelUrlObject(pageUrl, item.value)
            );
      }
      default:
        return '';
    }
  };

  return localizations.map((item) => ({
    rel: 'alternate',
    hreflang: item.key,
    href: getHreflangUrl(item),
  }));
};

export const transformSeoMetadata = (instance) => {
  const { meta: rawMeta } = instance;
  if (!rawMeta) return {};
  const {
    htmlTitle,
    htmlDescription,
    keywords,
    metaRobots,
    canonicalUrl,
    localizations,
    picturesMeta,
    openGraph,
    twitterCards,
    commerceTags,
    analyticsTags,
  } = rawMeta;

  const [meta, setMeta] = metadataCollector([]);
  let allTags = {};

  /** Standard meta tags */
  allTags['apple-mobile-web-app-title'] = { content: htmlTitle, type: 'name' };
  allTags['description'] = { content: htmlDescription, type: 'name' };
  allTags['keywords'] = { content: keywords, type: 'name' };
  allTags['robots'] = { content: metaRobots, type: 'name' };
  allTags['image'] = { content: picturesMeta, type: 'name' };

  /** Defaults for OpenGraph and Facebook tags */
  const ogTitle = openGraph?.ogTitle || htmlTitle;
  const ogDescription = openGraph?.ogDescription || htmlDescription;
  const ogSite = instance.$env.WEBSITE_NAME;
  allTags['og:title'] = { content: ogTitle };
  allTags['og:description'] = { content: ogDescription };
  allTags['og:site_name'] = { content: ogSite };
  allTags['fb:app_id'] = { content: openGraph?.fbId };
  allTags['og:locale'] = { content: openGraph?.ogLocale };
  allTags['og:url'] = { content: openGraph?.ogUrl };
  allTags['og:type'] = { content: openGraph?.ogType };
  allTags['og:brand'] = { content: openGraph?.ogBrand };

  /** Twitter related meta tags */
  allTags['twitter:card'] = {
    content: instance.$config.SEO_TWITTER_CARD_DEFAULTS.tcCard || picturesMeta,
  };
  allTags['twitter:site'] = {
    content: instance.$config.SEO_TWITTER_CARD_DEFAULTS.tcSite || ogSite,
  };
  allTags['twitter:creator'] = {
    content: instance.$config.SEO_TWITTER_CARD_DEFAULTS.tcCreator,
  };
  allTags['twitter:title'] = { content: twitterCards?.tcTitle || ogTitle };
  allTags['twitter:description'] = {
    content: twitterCards?.tcDescription || ogDescription,
  };

  /** Analytics related meta tags */
  allTags['category-id'] = { content: analyticsTags?.categoryId, type: 'name' };
  allTags['product-id'] = { content: analyticsTags?.productId, type: 'name' };
  allTags['search-query'] = {
    content: analyticsTags?.searchQuery,
    type: 'name',
  };

  /**
   * Override default OG tag values with data provided in the catalog.
   * This allows marketers to modify and add tags from SFCC with no changes in FE code. */
  allTags = {
    ...allTags,
    ...commerceTags,
  };

  /** Apply all collected tags */
  Object.entries(allTags).forEach((tag) => {
    const [tagId, tagConfig] = tag;
    if (!tagConfig.content || tagId === 'title') return; // skip empty tags & title
    setMeta(tagId, tagConfig.content, tagConfig.type);
  });

  const pageUrl = instance.$getPageUrl();
  const link = getSeoLinks(pageUrl, localizations);

  if (canonicalUrl && pageUrl) {
    // Check if pageUrl has protocol, localhost:3000 has no protocol for some reason and causes an exception
    let origin = '';
    try {
      origin = new URL(pageUrl).origin;
    } catch (err) {
      console.error(err);
      origin = '';
    }
    link.push({
      rel: 'canonical',
      href: canonicalUrl.startsWith('http')
        ? canonicalUrl
        : origin + canonicalUrl,
    });
  }

  const langCodes = instance.$i18n.locale.split('-');

  const locale = `${langCodes[0]}-${langCodes[1].toUpperCase()}`;

  return {
    htmlAttrs: { lang: locale },
    title: htmlTitle,
    meta,
    link,
  };
};

const metadataCollector = (meta) => {
  return [
    meta,
    (name, content, type = 'property') => {
      if (meta.find((tag) => tag.hid === name)) return;
      try {
        if (name && typeof content !== 'undefined') {
          meta.push({
            hid: name,
            [type]: name,
            content: content.toString(),
          });
        }
      } catch (e) {
        /** Meta value not specified properly */
      }
    },
  ];
};

export const useUserData = (vmInstance) => {
  const userStore = useUserStore(vmInstance);
  const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
  const { consumerType } = useAuthentication(vmInstance);
  const { basicInformation } = useBasicInformation(vmInstance);

  const { cartItems } = useCart(vmInstance);

  const isCartEmpty = !cartItems.value.length;

  return {
    loggedIn: loggedIn.value,
    notLoggedIn: !loggedIn.value,
    employeeLoggedIn: loggedIn.value && consumerType.value === 'EMPLOYEE',
    employeeNotLoggedIn: !loggedIn.value || consumerType.value !== 'EMPLOYEE',
    loyaltyEnrolled: loggedIn.value && loyaltyEnrolled.value,
    loyaltyNotEnrolled: !loggedIn.value || !loyaltyEnrolled.value,
    cartEmpty: isCartEmpty,
    notCartEmpty: !isCartEmpty,
    displayName: basicInformation.value.firstName || '',
  };
};
