import { Plugin } from '@nuxt/types';
import { ComponentInstance } from '@vf/composables/src/types';
import { load } from '@vf/shared/src/utils/helpers/load-script';
import { getVueInstanceFromContext } from '../helpers';
import { useGtm } from '@vf/composables';

const GtmPlugin: Plugin = (context) => {
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  const { initGtm } = useGtm(vueInstance);

  loadOneTrust(context)
    .then(() => {
      initGtm(context.$config.gtm.id);
    })
    .catch((e) => {
      vueInstance.$log.warn('[@theme/plugins/gtm.ts::GtmPlugin]', e);
    });
};

/**
 * load One Trust
 */
const loadOneTrust = (context) => {
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);

  if (vueInstance.$route.query?.preview === 'true') {
    vueInstance.$log.warn(
      '[@theme/plugins/gtm.ts::loadOneTrust] OneTrust is disabled in the preview.'
    );
    return Promise.resolve();
  }

  return loadOneTrustScript(vueInstance)
    .then((isOneTrustEnabled) => {
      if (isOneTrustEnabled) return configureOneTrustInstance();
    })
    .catch(() => {
      context.app.$log.warn('OneTrust not loaded');
    });
};

const loadOneTrustScript = (vueInstance): Promise<void | boolean> => {
  const scriptSrc = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  const oneTrustConfig = JSON.parse(vueInstance.$env.ONETRUST_CONFIG);

  return new Promise((resolve, reject) => {
    if (!oneTrustConfig.enabled) return resolve(false);
    load(
      scriptSrc,
      () => resolve(true),
      () => reject(),
      {
        'data-document-language': 'true',
        charset: 'UTF-8',
        'data-domain-script': oneTrustConfig.configureKey,
      }
    );
  });
};

const configureOneTrustInstance = () => {
  return new Promise((resolve, reject) => {
    // fallback OptanonWrapper not called for some reason
    const fallbackTime = setTimeout(reject, 1500);

    // this function should be called from otBannerSdk.js loaded inside otSDKStub.js, but we have a fallback just in case inside that js will be an error
    window.OptanonWrapper = () => {
      /** OptanonWrapper code
       */
      resolve(true);
      clearTimeout(fallbackTime);
    };
  });
};

export default GtmPlugin;
