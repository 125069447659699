import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { ref, Ref } from '@vue/composition-api';
import { OrderHistoryObject, PaginationObject } from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { apiClientFactory } from '@vf/api-client';
import { mapAndAssignOrdersProducts, OrderProductData } from '../../helpers';
import { UseAccountStorage } from '../../index';
import { useAuthentication, useRequestTracker } from '@vf/composables';

export const useOrderHistory = (instance) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const { getUsid } = useAuthentication(instance);
  const { getOrderHistory: getOrderHistoryAPI } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const orderHistory: Ref<OrderHistoryObject> =
    storage.get('orderHistory') ??
    storage.save(
      'orderHistory',
      ref({
        isLastPage: false,
        orders: [],
        pageNumber: 1,
        size: 10,
      })
    );

  const getOrderHistory = async (
    data: PaginationObject = { pageSize: 10, pageNumber: 1 },
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useAccount-getOrderHistory',
      isBackgroundRequest
    );
    try {
      const sfccOrdersAmount = orderHistory.value.numberOfAdditionalOrders;
      const orderHistoryData: AxiosResponse<OrderHistoryObject> = await getOrderHistoryAPI(
        {
          ...data,
          ...(sfccOrdersAmount && {
            numberOfAdditionalOrders: sfccOrdersAmount,
          }),
        },
        {
          usid: getUsid.value,
        }
      );
      // add product images and links
      await mapAndAssignOrdersProducts(
        instance,
        (orderHistoryData.data as unknown) as OrderProductData
      );

      const updatedOrders = [...(orderHistoryData.data.orders || [])];

      if (data.pageNumber > 1) {
        const previousOrders = [...(orderHistory.value.orders || [])];
        updatedOrders.unshift(...previousOrders);
      }

      orderHistory.value = {
        ...orderHistoryData.data,
        orders: updatedOrders,
      };
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const resetOrderHistory = () => {
    // TODO after migration to pinia we can use reset state.$reset()
    // https://pinia.vuejs.org/core-concepts/state.html#resetting-the-state
    orderHistory.value = {
      isLastPage: false,
      orders: [],
      pageNumber: 1,
      size: 10,
    };
  };

  return {
    getOrderHistory,
    orderHistory,
    resetOrderHistory,
  };
};
