import { CmsPageContent, CmsSite } from '@vf/api-contract';
import { standardComponentDataParser } from './components/standard';
import { parseDataBasedOnType } from './parseDataBasedOnType';
import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
  PageTypeName,
} from '../../types';
import { normalizeFacetConfiguration } from './normalizers';

export const parseCmsPlacement = (
  placement: { name: string; pageItems: any[] },
  {
    componentParser,
    siteConfiguration,
    cmsBaseUri,
    requirements,
    context,
    pageTypeName,
    errors,
  }: any
) => ({
  component: 'grid/Placement',
  _cms_id: placement.name,
  props: {
    name: placement.name,
    hasItems: placement.pageItems.length > 0,
    isInLayout: true,
  },
  children: placement.pageItems
    .map((pageItem: any) => {
      const parsedData = parseDataBasedOnType(
        pageItem,
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        pageTypeName
      );
      errors.push(...parsedData.errors);
      return parsedData.data;
    })
    .filter((item: any) => item !== null),
});

export const transformPageContentData = (
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  content: CmsPageContent,
  componentParser: ReturnType<typeof standardComponentDataParser>,
  requirements: CmsSanitizationRequirements,
  context: ComposableContext,
  pageTypeName: PageTypeName
) => {
  const errors = [];

  const rows = content.grid.rows;
  let facetConfigsSpotted = false;

  const placements = rows.reduce(
    (allItems, row) =>
      allItems.concat(
        row.placements.reduce((placements, placement) => {
          /** Facet configuration placement data.
           * Normalize it and push to requirements for further processing.
           */
          if (placement.name === 'facetConfigs') {
            facetConfigsSpotted = true;
            requirements.push({
              name: CmsSanitizationRequirementName.FACET_CONFIGURATION,
              facetConfiguration: normalizeFacetConfiguration(
                placement,
                cmsBaseUri
              ),
            });
          }

          placements.push({
            name: placement.name,
            /** Ignore configuration elements in placement to avoid rendering warnings */
            pageItems: placement.pageItems.filter(
              (pageItem) => pageItem.type !== 'VFConfigComponent'
            ),
          });

          return placements;
        }, [])
      ),
    []
  );

  /**
   * Reset facet configuration whenever it's not there on the page response.
   * It will prevent keeping old configuration from one PLP flowing into
   * another PLP page if new one does not contain facet configuration placement.
   */
  if (!facetConfigsSpotted) {
    requirements.push({
      name: CmsSanitizationRequirementName.FACET_CONFIGURATION,
      facetConfiguration: {},
    });
  }

  const mappedContent = {
    children: placements.map((placement) =>
      parseCmsPlacement(placement, {
        componentParser,
        siteConfiguration,
        cmsBaseUri,
        requirements,
        context,
        pageTypeName,
        errors,
      })
    ),
    localizations: content.localizations,
  };

  return {
    data: mappedContent,
    errors,
  };
};
