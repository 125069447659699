import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, urlBuilder, headerBuilder } from '../../helpers';

export const getCountryList = (configuration: ApiClientConfiguration) => async (
  locale: string
): Promise<any> => {
  let url = urlBuilder(endpoints.get(configuration).utilities.countryList, {
    config: configuration,
  });
  url = `${url}?locale=${locale}`;
  const response = await axios.get(url, {
    headers: headerBuilder(configuration),
  });
  return response;
};
