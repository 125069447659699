import wrapper from 'axios-cache-plugin';
import { axios, getCacheWrapperConfig } from '../../helpers';

type CustomsSkuParams = {
  caid: string;
  recipeId: string;
  size: string;
  locale: string;
  baseUrl: string;
};

const customsSkuWrapperConfig = getCacheWrapperConfig('customs').sku;
const http = wrapper(axios, customsSkuWrapperConfig);

http.__addFilter(/sku\/byRecipeId/);

export const getCustomsSku = (params: CustomsSkuParams): Promise<any> => {
  return http.get(
    `${params.baseUrl}/${params.recipeId}?caid=${params.caid}&size=${params.size}`
  );
};
