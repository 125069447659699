import { CMContentBodyText, CmsSite } from '@vf/api-contract';
import { extractProperty, getTeaserTargets, decorateHtmlValue } from './utils';
import { ComposableContext } from '../types';

export const easyBodyText = (
  data: CMContentBodyText,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { ctas } = getTeaserTargets(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );
  return {
    component: 'shared/ContentBodyText',
    props: {
      title: {
        text: data.teaserTitle,
        variant: extractProperty(data, 'componentStyles.teaserTitleStyle'),
        color: extractProperty(data, 'componentStyles.teaserTitleColor'),
      },
      backgroundColorStyle: extractProperty(
        data,
        'componentStylesDefinitions.rowBackgroundColor.data.color'
      ),
      ctaAlignment: extractProperty(data, 'componentStyles.ctaAlignment'),
      bodyText: {
        content:
          decorateHtmlValue(
            data?.teaserRichTextObject,
            cmsBaseUri,
            _context,
            siteConfiguration
          ) || extractProperty(data, 'teaserText'),
        color: extractProperty(
          data,
          'componentStylesDefinitions.teaserTextColor.data.color'
        ),
        variant: extractProperty(data, 'componentStyles.teaserTextStyle'),
      },
      ctas,
      bottomMarginStyle: extractProperty(data, 'componentStyles.bottomMargin'),
    },
  };
};
