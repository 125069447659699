import { CMPicture, CMVideo, MediaSeoAttributes } from '@vf/api-contract';
import { extractProperty } from './extractProperty';

export const getMediaSeoAttributes = (pictureObject?: CMPicture | CMVideo) => {
  return {
    date: extractProperty(pictureObject, 'seoDate', ''),
    title: extractProperty(pictureObject, 'seoTitle', ''),
    description: extractProperty(pictureObject, 'seoDescription', ''),
  } as MediaSeoAttributes;
};
