import { CMPlaceholder, ComponentConfig } from '@vf/api-contract';
import { getDynamicField } from './utils';

export const pdpTitle = (data: CMPlaceholder) => {
  const config = getConfigValues(data);

  return {
    component: 'pdp/ProductName',
    props: {
      headingLevel: parseInt(config['heading-level'] as string, 10) || 2,
      displayBadgeInsideTitle: getDynamicField(
        data,
        'displayBadgeInsideTitle',
        true
      ),
      textAlign: {
        small: getDynamicField(data, 'contentAlignSmall', 'left'),
        medium: getDynamicField(data, 'contentAlignMedium', 'left'),
        large: getDynamicField(data, 'contentAlignLarge', 'left'),
      },
    },
  };
};

const getConfigValues = (data: CMPlaceholder) => {
  try {
    const config = data.componentConfig.find(
      (conf: ComponentConfig) => conf.name === 'title-properties'
    );

    return config.values;
  } catch (e) {
    console.error('PDP Title component error: no config found.');
  }

  return {
    'heading-level': '2',
  };
};

export type CmsPdpTitle = {
  id: string;
  type: string;
  name: string;
  viewtype: 'pdp-title';
  title: string;
  componentConfig: CmsPdpTitleProperties[];
};

export type CmsPdpTitleProperties = {
  name: 'title-properties';
  values: {
    'heading-level': string;
  };
};
