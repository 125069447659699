import { extractProperty } from '.';

export const getButtonStyles = (
  data,
  index: string,
  property: string,
  defaultValue?: string
) => {
  return extractProperty(
    data,
    `teaserTargets[${index}].target.localSettings.dynamicFields.${property}`,
    defaultValue
  );
};
