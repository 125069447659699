import {
  ProductReviewConfResponse,
  ReviewsTranslations,
} from '@vf/api-contract';

export const getReviewsTranslations = (
  localizations: ProductReviewConfResponse['localizations']
): ReviewsTranslations => {
  const prefix = 'review_display.4.0.';
  const $t = (key) => localizations?.[`${prefix}${key}`] || `$${key}$`;

  return {
    heading: $t('snapshot.header'),
    writeReview: $t('write_a_review'),
    writeFirstReview: $t('write_the_first_review'),
    reviewsCount: {
      singular: $t('common.review_count_singular'),
      plural: $t('common.review_count_plural'),
    },
    recommendationsLabel: $t('snippet.recommended_text'),
    ratingDistribution: {
      heading: $t('snapshot.ratings_distribution'),
      starCount: $t('stars_singular'),
      starsCount: $t('stars_plural'),
      tooltip: $t('stars_plural'),
    },
    proConTagTooltip: $t('snapshot.msqs.filter_tooltip'),
    pros: {
      heading: $t('review_tag_groups.pros'),
      emptyText: $t('search.no_results.header'),
    },
    cons: {
      heading: $t('review_tag_groups.cons'),
      emptyText: $t('search.no_results.header'),
    },
    mostLikedReadComplete: $t('common.read_complete_review'),
    mostLikedReadLess: $t('common.read_less_review'),
    mostLikedPositiveHeading: $t('snapshot.most_liked_positive_review'),
    mostLikedNegativeHeading: $t('snapshot.most_liked_negative_review'),
    flagReviewForm: {
      flagReviewNotFlagged: $t('flagging.flag_this_review'),
      flagReviewFlagged: $t('flagging_form.already_flagged_text'),
      flagUGCFlagged: $t('modal.is_flagged'),
      flagUGCNotFlagged: $t('flagging.flag_button_label'),
      heading: $t('flagging_form.whats_the_issue_label'),
      emailPlaceholder: $t('flagging_form.email_address_label'),
      emailRequiredText: $t('flagging_form.required_error'),
      emailInvalidText: $t('flagging_form.email_error'),
      commentsPlaceholder: $t('flagging_form.comments_label'),
      flagButtonLabel: $t('flagging_form.button_label.flag'),
      cancelButtonLabel: $t('flagging_form.button_label.cancel'),
      flagTypes: {
        profane: $t('flagging_form.profanity_label'),
        wrong_product: $t('flagging_form.wrong_product_label'),
        spam: $t('flagging_form.spam_label'),
        duplicate: $t('flagging_form.duplicate_label'),
        copyright: $t('flagging_form.copyright_label'),
        not_review: $t('flagging_form.not_product_review_label'),
        customer_image: $t('flagging_form.customer_image_label'),
        other: $t('flagging_form.other_label'),
      },
      helpfulVoteLabel: $t('helpful.helpful_vote_label'),
      unhelpfulVoteLabel: $t('helpful.unhelpful_vote_label'),
    },
    tags: {
      more: $t('snapshot.msqs.more'),
      less: $t('snapshot.msqs.less'),
    },
    list: {
      heading: $t('common.reviewed_by_plural'),
      filtersApplied: $t('snapshot.active_filters'),
      sortingAriaLabel: $t('sorting.dropdown_field_label'),
      merchantResponse: $t('merchant_response.merchant_response'),
      bottomLine: $t('bottom_line.bottom_line'),
      helpfulLine: $t('helpful.helpful_question'),
      displayingReviews: $t('pagination.displaying_reviews_plural'),
      backToTop: $t('pagination.back_to_top'),
      prevPage: $t('pagination.previous'),
      nextPage: $t('pagination.next'),
      noResults: $t('search.no_results.header'),
      disclosureLabelSweepstakes: $t('disclosure.sweepstakes'),
    },
    sortingOptions: {
      Newest: $t('sorting.most_recent_label'),
      MostHelpful: $t('sorting.most_helpful_label'),
      LowestRating: $t('sorting.lowest_rated_label'),
      HighestRating: $t('sorting.highest_rated_label'),
      Oldest: $t('sorting.oldest_label'),
      MediaSort: $t('sorting.sort_by_images_label'),
    },
    submittedBy: {
      submittedLabel: $t('common.submitted'),
      submittedByLabel: $t('common.by'),
      fromLabel: $t('common.from'),
      verifiedBuyerLabel: $t('badging.verified_buyer_text'),
    },
    reviewCategories: {
      moreDetails: $t('common.more_details'),
      lessDetails: $t('common.less_details'),
    },
    bottomLine: {
      recommended: $t('bottom_line.recommended'),
      notRecommended: $t('bottom_line.not_recommended'),
    },
    reviewBasedFitScale: {
      heading: $t('review_based_fit_scale.heading'),
    },
  };
};
