import { computed, ref, Ref } from '@vue/composition-api';
import { QuickshopSizeChartObject, UseCartStorage } from '../../types';
import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';

export const useQuickShop = (instance, contextKey) => {
  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  const quickshopSizeChart: Ref<QuickshopSizeChartObject> = storage.getOrSave(
    'quickshopSizeChart',
    ref({ content: null })
  );

  const setQuickshopSizeChart = (content: string | Record<string, unknown>) => {
    quickshopSizeChart.value.content = content;
  };

  return {
    quickshopSizeChart: computed(() => quickshopSizeChart.value),
    setQuickshopSizeChart,
  };
};
