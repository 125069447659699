import { CMPlaceholder, GiftOptionTranslations } from '@vf/api-contract';
import { getTranslation } from './extractProperty';

export const getGiftOptionTranslations = (
  data: CMPlaceholder
): GiftOptionTranslations => {
  return {
    addGiftOptionCTA: getTranslation(
      data,

      'addGiftOptionCTA',
      getTranslation(data, 'giftOptionAddCTA', '$Add Gift Option$')
    ),
    itemGiftOptions: getTranslation(data, 'itemGiftOptions', '$Gift Options$'),
    edit: getTranslation(data, 'editCTA', '$Edit$'),
    remove: getTranslation(data, 'removeCTA', '$Remove$'),
    modalTitle: getTranslation(
      data,
      'giftOptionModalTitle',
      '$Select Gift Option For Item$'
    ),
    giftOptionTo: getTranslation(data, 'giftOptionTo', '$To:$'),
    giftOptionFrom: getTranslation(data, 'giftOptionFrom', '$From:$'),
    giftOptionMessage: getTranslation(data, 'giftOptionMessage', '$Message:$'),
    giftOptionGiftBox: getTranslation(data, 'giftOptionGiftBox', '$Gift Box$'),
    giftOptionWithoutGiftBox: getTranslation(
      data,
      'giftOptionWithoutGiftBox',
      '$No$'
    ),
    giftOptionWithGiftBox: getTranslation(
      data,
      'giftOptionWithGiftBox',
      '$Yes @ {{price}}$'
    ),
    giftOptionsEmpty: getTranslation(data, 'giftOptionsEmpty', ''),
    giftOptionsWithoutGiftBox: getTranslation(
      data,
      'giftOptionsWithoutGiftBox',
      '$Msg: {{quantity}} at {{price}} ea.$'
    ),
    giftOptionsWithGiftBox: getTranslation(
      data,
      'giftOptionsWithGiftBox',
      '$Msg & Gift Box: {{quantity}} at {{price}} ea.$'
    ),
    requiredFieldsInfo: getTranslation(
      data,
      'giftOptionRequiredFields',
      '$Required Fields$'
    ),
    charactersRemaining: getTranslation(
      data,
      'giftOptionCharactersRemaining',
      '${{count}} Characters Remaining$'
    ),
    giftBoxLabel: getTranslation(
      data,
      'giftOptionGiftBoxLabel',
      '$Add Gift Box for {{price}}$'
    ),
    giftBoxImageAlt: getTranslation(
      data,
      'giftOptionGiftBoxImageAlt',
      '$Gift Box Image$'
    ),
    giftOptionTipDescription: getTranslation(
      data,
      'giftOptionTipDescription',
      '$If you choose gift options, the recipient will not receive a receipt with the value of the item$'
    ),
    save: getTranslation(data, 'saveCTA', '$Save$'),
    cancel: getTranslation(data, 'cancelCTA', '$Cancel$'),
    validationMessages: getTranslation(data, 'validationMessages', {
      required: '$This field is required.$',
      noSpecialCharacters: '$Please remove any emojis or special characters.$',
    }),
  };
};
