import { ref, Ref, computed } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';

import {
  UseNotificationStorage,
  NewsletterNotificationObject,
  NotificationStorage,
  Notification,
} from './types';
import { useFlashErrors } from './composables/useFlashErrors';
import { usePasswordChangeNotification } from './composables/usePasswordChangeNotification';

const useNotification = (instance: ComponentInstance) => {
  const { setItemRowNotification, findRowNotification } = useFlashErrors();
  const {
    passwordChangeNotification,
    setPasswordChangeNotification,
    showChangePassLinkExpiredNotification,
    setChangePassLinkExpiredNotification,
    hasChangePasswordNotification,
    setChangePasswordNotification,
  } = usePasswordChangeNotification(instance);

  const storage: ComposablesStorage<UseNotificationStorage> = initStorage<UseNotificationStorage>(
    instance,
    'useNotification'
  );

  const notificationRef: Ref<NotificationStorage> =
    storage.get('notificationRef') ??
    storage.save('notificationRef', ref({ notifications: [] }));

  const addNotification = (notification: Notification) => {
    notification.persistent = notification.persistent || false;
    notification.errorMessageId = notification.errorMessageId || null;
    notification.modifiers = notification.modifiers || '';
    if (!checkNotificationExists(notification)) {
      notificationRef.value.notifications.unshift(notification);
    }
    if (
      typeof window !== 'undefined' &&
      notification.modifiers !== 'scrollToNotification'
    ) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const removeNotification = (index: number) => {
    notificationRef.value.notifications.splice(index, 1);
  };

  const clearNotifications = () => {
    notificationRef.value.notifications = [];
  };

  const checkNotificationExists = (notification: Notification): boolean => {
    const newNotification: string = JSON.stringify(notification);
    return !!notificationRef.value.notifications.find(
      (value) => newNotification === JSON.stringify(value)
    );
  };

  const newsletterNotification: Ref<NewsletterNotificationObject> =
    storage.get('newsletterNotification') ??
    storage.save(
      'newsletterNotification',
      ref({ showNotification: false, isSuccess: null })
    );

  const changeNewsletterNotification = (data: NewsletterNotificationObject) => {
    newsletterNotification.value = data;
  };

  return {
    addNotification,
    removeNotification,
    clearNotifications,
    notifications: computed(() => notificationRef.value.notifications),
    newsletterNotification: computed(() => newsletterNotification.value),
    changeNewsletterNotification,
    passwordChangeNotification,
    setPasswordChangeNotification,
    showChangePassLinkExpiredNotification,
    setChangePassLinkExpiredNotification,
    hasChangePasswordNotification,
    setChangePasswordNotification,
    setItemRowNotification,
    findRowNotification,
  };
};

export default useNotification;
