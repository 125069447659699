export const getPlaceholdersInString = (
  value: string,
  placeholders: string[]
): string[] => {
  if (!value || !placeholders || !placeholders.length) return [];

  const placeholdersFound = [];
  for (const placeholder of placeholders) {
    if (
      value.includes(placeholder) &&
      !placeholdersFound.includes(placeholder)
    ) {
      placeholdersFound.push(placeholder);
    }
  }
  return placeholdersFound;
};

export const getPlaceholdersInObject = <T>(
  target: T,
  fields: string[],
  placeholders: string[]
): string[] => {
  if (!target || !placeholders || !placeholders.length) return [];

  let placeholdersFound = [];
  for (const key of Object.keys(target)) {
    if (!target[key]) continue;

    const value = target[key];
    let iterationPlaceholders = [];
    const isValueString = typeof value === 'string';
    const noFieldsProvided = !fields || !fields.length;
    const isFieldIncluded = fields && fields.includes(key);
    if (isValueString && [noFieldsProvided, isFieldIncluded].some(Boolean)) {
      iterationPlaceholders = getPlaceholdersInString(value, placeholders);
    }
    if (typeof value === 'object' || Array.isArray(value)) {
      iterationPlaceholders = getPlaceholdersInObject(
        value,
        fields,
        placeholders
      );
    }

    placeholdersFound = Array.from(
      new Set([...placeholdersFound, ...iterationPlaceholders])
    );
  }
  return placeholdersFound;
};

export const replacePlaceholdersInString = (
  value: string,
  placeholderValues: Record<string, string | number>,
  clearOnEmptyPlaceholder: boolean = false
): string => {
  if (!value || !placeholderValues) return value;
  let result = value;
  for (const placeholder in placeholderValues) {
    const placeholderValue = placeholderValues[placeholder].toString();
    if (
      [
        result.includes(placeholder),
        !placeholderValue,
        clearOnEmptyPlaceholder,
      ].every(Boolean)
    )
      return '';
    result = result.split(placeholder).join(placeholderValue);
  }
  return result;
};

export type SourceObj = {
  [key: string]: string | Source;
};
export type Source = string | string[] | SourceObj | SourceObj[];

export const replacePlaceholdersInObject = (
  source: Source,
  fields: string[],
  placeholderValues: Record<string, string | number>,
  clearOnEmptyPlaceholder: boolean = false
): Source | Source[] => {
  const replace = (value: Source | null, currentKey: string | null) => {
    if (value === null) return value;

    if (Array.isArray(value)) {
      return value.map((item) => replace(item, currentKey));
    }

    const isValueString = typeof value === 'string';
    const noFieldsProvided = !fields || !fields.length;
    const isFieldIncluded = !noFieldsProvided && fields.includes(currentKey);
    if (isValueString && [noFieldsProvided, isFieldIncluded].some(Boolean)) {
      return replacePlaceholdersInString(
        value as string,
        placeholderValues,
        clearOnEmptyPlaceholder
      );
    }

    if (typeof value === 'object') {
      const newValue = {};
      for (const key in value) {
        newValue[key] = replace(value[key], key);
      }
      return newValue;
    }

    return value;
  };

  return replace(source, null);
};
