import {
  extractProperty,
  getImageObject,
  getMediaSeoAttributes,
  decorateHtmlValue,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTeaserTargets,
  getDynamicField,
  generateLinkFromTarget,
} from './utils';
import { CMTeaser, CmsSite, CMPictureCropType } from '@vf/api-contract';
import { ComposableContext } from '../types';
export const customShoesTeaser = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const pictures = getPictures(data, siteConfiguration, cmsBaseUri);
  const overlayPictures = getOverlayPictures(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );
  const targetCta = extractProperty(data, 'teaserTargets[0].target');
  return {
    component: 'customs/CustomShoesTeaser',
    props: {
      background: extractProperty(pictures, 'imagesObject[1]', {}),
      heroPicture: extractProperty(pictures, 'imagesObject[0]', {}),
      overlayPictures: overlayPictures,
      richText: decorateHtmlValue(
        data.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      ),
      buttonText:
        extractProperty(data, 'teaserTargets[0].callToActionText') ||
        extractProperty(targetCta, 'teaserTitle'),
      isCustomsCta: extractProperty(targetCta, 'viewtype') === 'customs-cta',
      experience: getDynamicField(targetCta, 'experience'),
      dataObject: getDynamicField(targetCta, 'dataObject'),
      buttonLink: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
    },
  };
};

const getPictures = (data, siteConfiguration, cmsBaseUri) => {
  if (data.pictures?.length > 1) {
    const imagesObject = data.pictures.map((picture, index) => {
      return {
        ...getImageObject(
          picture,
          siteConfiguration,
          CMPictureCropType.LANDSCAPE_RATIO_8x3,
          cmsBaseUri,
          data.responsiveMedia,
          index
        ),
        seo: getMediaSeoAttributes(picture),
        alt: '',
        width: null,
        height: null,
      };
    });
    return {
      imagesObject,
    };
  }
};

const getOverlayPictures = (data, context, siteConfiguration, cmsBaseUri) => {
  const links = extractLocalSettingsExpandedLinks(data, 'products');
  const overlayPictures = links.map((link) => {
    if (link?.pictures[0]) {
      return {
        src: {
          ...getImageObject(
            link.pictures[0],
            siteConfiguration,
            CMPictureCropType.LANDSCAPE_RATIO_8x3,
            cmsBaseUri
          ),
        },
        seo: getMediaSeoAttributes(link.pictures[0]),
        link: generateLinkFromTarget(
          link,
          context,
          siteConfiguration,
          cmsBaseUri
        ),
        width: null,
        height: null,
        alt: '',
        buttonText:
          extractProperty(link, 'teaserTitle') ||
          extractProperty(link, 'teaserText'),
      };
    }
    return null;
  });
  return overlayPictures;
};
