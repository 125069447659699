export const hexToRGBA = (hexCode: string, opacity = 1): string => {
  let hex = hexCode.replace('#', '');

  if ([3, 4].includes(hex.length)) {
    hex = hex
      .split('')
      .map((v) => v + v)
      .join('');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};
