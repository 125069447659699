


















import {
  ref,
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  PropType,
} from '@vue/composition-api';

export default defineComponent({
  name: 'VfFrame',
  props: {
    /** The URL of the page to embed */
    src: {
      type: String as PropType<string>,
      default: '',
    },
    /** Frame title */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Frame width set via px or percentage */
    width: {
      type: [String, Number] as PropType<string | number>,
      default: null,
    },
    /** Frame height set via px */
    height: {
      type: [String, Number] as PropType<string | number>,
      default: null,
    },
    /** Specify policy for iframe src f.e geolocation */
    allow: {
      type: String as PropType<string>,
      default: '',
    },
    /** Whether to activate fullscreen mode  */
    allowFullScreen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Determine if iframe is being used for custom tools  */
    iframeType: {
      type: String as PropType<string>,
      default: '',
    },
    /** Whether the height should be auto  */
    autoHeight: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const iframe = ref(null);
    let heightCheckInterval: number | null = null;

    onMounted(() => {
      window.addEventListener('message', resizeMessageListener);
      heightCheckInterval = window.setInterval(() => loadFrameHeight(), 3000);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('message', resizeMessageListener);
      if (heightCheckInterval) {
        clearInterval(heightCheckInterval);
      }
    });

    const resizeMessageListener = (e: MessageEvent) => {
      try {
        let height: number | null = null;

        if (e.data.split) {
          const dimensions = e.data.split('//');

          if (dimensions[0] === 'w2gi:iframeHeightUpdated') {
            height = parseInt(dimensions[1]) || null;
          } else {
            height = parseInt(dimensions[0]) || null;
          }
        }

        if (height && !isNaN(height) && isFinite(height)) {
          iframe.value.setAttribute('scrolling', 'no');
          iframe.value.style.height = `${height + 1}px`;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const loadFrameHeight = () => {
      try {
        if (!props.autoHeight) return;
        const { scrollHeight } = iframe.value.contentWindow.document.body;
        const height = scrollHeight > 0 && `${scrollHeight + 1}px`;

        if (height) {
          iframe.value.setAttribute('scrolling', 'no');
          iframe.value.style.height = height;
        }
      } catch (e) {
        /** CORS error that forbids to read iframe contents. */
        console.error(e);
      }
    };

    const isCustomsIframe = computed(() => {
      return props.iframeType === 'customs';
    });

    return {
      iframe,
      isCustomsIframe,
      loadFrameHeight,
    };
  },
});
