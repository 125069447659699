import { CMPlaceholder } from '@vf/api-contract';
import {
  getStateManagementData,
  getTranslation,
  getDynamicField,
  getComponentConfigByName,
  extractProperty,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTarget,
  extractCommonConfigLink,
  extractRichText,
} from './utils';
import { MappingMethod } from '../types';

export const orderStatusForm: MappingMethod = (
  data: CMPlaceholder,
  context,
  siteConfiguration,
  cmsBaseUri
) => {
  const defaultValidationRules = {
    emailOrLastNameRegex: '^[A-Za-z0-9_@./#&+-]{1,256}$',
    orderNumberRegex: '^d{1,256}$',
    zipCodeRegex: '^[0-9]{5}(?:-[0-9]{4})?$',
    zipCodeFormat: '##### or #####-####',
  };

  const tooltipImageUrl =
    extractProperty(
      extractLocalSettingsExpandedLinks(data, 'tooltipImageUrl')[0],
      'url',
      ''
    ) ||
    extractProperty(
      extractLocalSettingsExpandedLinks(data, 'tooltipImgUrl')[0],
      'url',
      ''
    );
  const signInLink = generateLinkFromTarget(
    extractLocalSettingsExpandedLinks(data, 'signInLink')[0],
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const [contactUsURLTarget] = extractCommonConfigLink(
    siteConfiguration,
    'contactUsURL'
  );
  const contactUsLink = contactUsURLTarget
    ? generateLinkFromTarget(
        {
          urlSegment: contactUsURLTarget.urlSegment,
          type: contactUsURLTarget.type,
        },
        context,
        siteConfiguration,
        cmsBaseUri
      )
    : '';

  const validationMessages = getTranslation(data, 'validationMessages', {
    requiredError: '$This field is required, please add your {{fieldName}}.$',
    orderNumberError: '$Please provide valid order number.$',
    emailOrLastNameError: '$Please provide valid value.$',
    zipCodeError: '$Please provide valid zip code.$',
  });

  return {
    component: 'OrderStatusForm',
    props: {
      signInLink,
      showTooltip: getDynamicField(data, 'showTooltip', false),
      showRequiredFieldsText: getDynamicField(
        data,
        'showRequiredFieldsText',
        false
      ),
      tooltipImageUrl: tooltipImageUrl,
      tooltipImageHeight: getDynamicField(data, 'tooltipImageHeight', ''),
      tooltipImageWidth: getDynamicField(data, 'tooltipImageWidth', ''),
      tooltipImageAltText: getDynamicField(
        data,
        'tooltipImageAltText',
        '$Location of your order number$'
      ),
      showRadioButtonsSection: getDynamicField(
        data,
        'showRadioButtonsSection',
        false
      ),
      showCancelButton: getDynamicField(data, 'showCancelButton', true),
      translations: {
        heading: getTranslation(data, 'heading', '$View order status$'),
        signInLinkText: getTranslation(
          data,
          'signInLinkText',
          '$Returning customer?$'
        ),
        emailOrLastNameLabel: getTranslation(
          data,
          'emailOrLastNameLabel',
          '$Email Address$'
        ),
        orderNoLabel: getTranslation(data, 'orderNoLabel', '$Order Number$'),
        orderNoTooltip: extractRichText(
          data,
          'orderNoTooltip',
          '$You can find your order number in your order confirmation email.$',
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        submitButtonText: getTranslation(
          data,
          'submitButtonText',
          '$Order information$'
        ),
        cancelButtonText: getTranslation(data, 'cancelButtonText', '$Cancel$'),
        errorMessageText: getTranslation(
          data,
          'errorMessageText',
          "$WE'RE SORRY... WE WERE UNABLE TO LOCATE YOUR ORDER.$"
        ),
        fieldsRequiredText: getTranslation(
          data,
          'fieldsRequiredText',
          '$All fields required$'
        ),
        tooltipHelperText: getTranslation(
          data,
          'tooltipHelperText',
          '$Where do I find this?$'
        ),
        contactUsText: extractRichText(
          data,
          'contactUsText',
          "$Don't have your order confirmation email?$",
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        validationMessages,
        purchasedItemsRadioLabel: getTranslation(
          data,
          'purchasedItemsButtonText',
          '$I purchased the items.$'
        ),
        receivedItemsRadioLabel: getTranslation(
          data,
          'receivedItemsButtonText',
          '$I received the items as a gift.$'
        ),
        zipCodeLabel: getTranslation(data, 'zipCodeLabel', '$Zip Code$'),
        zipCodeTooltip: getTranslation(
          data,
          'zipCodeTooltip',
          '$Enter the zip code of the shipping address.$'
        ),
        tooltipCloseButton: getTranslation(
          data,
          'closeButtonText',
          '$Close tooltip$'
        ),
        narvar: {
          ...getTranslation(data, 'narvar', {}),
          contactUsText: getTranslation(
            data,
            'narvar.contactUsText',
            'Contact us: {{contactUsURL}}'
          ).replace('{{contactUsURL}}', contactUsLink),
          validationMessages: {
            ...validationMessages,
            ...getTranslation(data, 'narvar.validationMessages', {}),
          },
        },
      },
      validations: {
        ...defaultValidationRules,
        ...getComponentConfigByName(
          data,
          'orderValidation',
          defaultValidationRules
        ),
      },
      states: getStateManagementData(data),
    },
  };
};
