import {
  ProductReviewFaceoff,
  ProductReviewRollup,
} from 'packages/api-contract/src/entities';

export const getMappedFaceoffReview = (
  rollup: ProductReviewRollup,
  reviewType: string
): ProductReviewFaceoff => {
  if (
    !rollup.faceoff ||
    !rollup.faceoff.positive ||
    !rollup.faceoff.negative ||
    !reviewType
  ) {
    return null;
  }
  const key = reviewType === 'positive' ? 'positive' : 'negative';
  return rollup.faceoff[key];
};
