import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { GetShippingResponse } from '../../api-types';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const getShippingMethods = (
  configuration: ApiClientConfiguration
) => async (
  cartId: string,
  shippingId: string,
  query: string
): Promise<AxiosResponse<GetShippingResponse>> => {
  let url: string = urlBuilder(
    endpoints.get(configuration).checkout.getShippingMethods,
    {
      config: configuration,
      mapping: {
        cartId,
        shippingId,
      },
    }
  );
  url = `${url}?${query}`;

  const response = await axios.get<GetShippingResponse>(url, {
    headers: headerBuilder(configuration),
  });
  return response;
};
