import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { ref } from '@nuxtjs/composition-api';
import { Ref, computed } from '@vue/composition-api';
import {
  apiClientFactory,
  ReturnItem,
  ReturnObject,
  ReturnDetailedItem,
  ReturnPreviewObject,
} from '@vf/api-client';
import { RequestBody } from '@vf/api/src/types/PdfBuilder';
import { ReturnDetailsObject } from '@vf/api-contract';
import { ComponentInstance, ComposablesStorage } from '../types';
import { mapReturnOrder } from './helpers';
import { useAccount } from '../useAccount';
import { useOrders } from '../useOrders';
import { useAuthentication } from '../useAuthentication';
import { useRequestTracker } from '../useRequestTracker';
import { errorMessages } from '../utils/errorMessages';
import initStorage from '../utils/storage';
import { useFeatureFlagsStore } from '../../src/store/featureFlags';

export type UseReturnsStorage = {
  returnPayload: Ref<any>;
  returnPreviewObject: Ref<ReturnPreviewObject>;
  returnStep: Ref<ReturnStepsType>;
  giftEmail: Ref<string>;
  shippingMethod: Ref<string>;
  orderHeaderKey: Ref<string>;
  orderType: Ref<ReturnsOrderType>;
  setOrderType: (type: ReturnsOrderType) => void;
  loading: Ref<boolean>;
};

export type ReturnsOrderType = 'purchase' | 'gift';

export enum ReturnStepsType {
  EMPTY = '', // non return step / regular order details
  DETAILS = 'details', // create return flow - select refund method, items quantity and return reasons
  SUMMARY = 'summary', // create return flow - preview order
  CONFIRMATION = 'confirmation', // create return flow - return created and confirmation page displayed
  HISTORY_DETAILS = 'historyDetails', // return details page from return history
}

export type LabelsConfig = {
  printPackingSlip: boolean;
  printReturnLabel: boolean;
};
/**
 * Composable for returns
 * @param {ComponentInstance} instance root vue instance
 */
export const useReturns = (instance: ComponentInstance) => {
  const { customerEmail } = useAccount(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { getUsid } = useAuthentication(instance);
  const { displayErrorMessages } = errorMessages(instance);
  const { order, loadOrder } = useOrders(instance);
  const {
    areOnlineReturnsEnabled,
    areNarvarReturnsEnabled,
  } = useFeatureFlagsStore();
  const {
    createReturn: createReturnAPI,
    printPackingSlip: printPackingSlipAPI,
    printReturnLabel: printReturnLabelAPI,
    getReturnDetails: getReturnDetailsAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseReturnsStorage> = initStorage<UseReturnsStorage>(
    instance,
    'useReturns'
  );

  const orderType: Ref<ReturnsOrderType> =
    storage.get('orderType') ??
    storage.save('orderType', ref('purchase' as ReturnsOrderType));

  const returnStep: Ref<ReturnStepsType> =
    storage.get('returnStep') ??
    storage.save('returnStep', ref(ReturnStepsType.EMPTY));

  const giftEmail: Ref<string> =
    storage.get('giftEmail') ?? storage.save('giftEmail', ref(''));

  const shippingMethod: Ref<string> =
    storage.get('shippingMethod') ?? storage.save('shippingMethod', ref(''));

  const orderHeaderKey: Ref<string> =
    storage.get('orderHeaderKey') ?? storage.save('orderHeaderKey', ref(''));

  const returnPayload: Ref<ReturnObject> =
    storage.get('returnPayload') ??
    storage.save(
      'returnPayload',
      ref({
        customerEmail,
        items: [],
        isRefundOnGiftCard: null,
      })
    );

  const returnPreviewObject: Ref<ReturnPreviewObject> =
    storage.get('returnPreviewObject') ??
    storage.save('returnPreviewObject', ref(null));

  const loading: UseReturnsStorage['loading'] =
    storage.get('loading') ?? storage.save('loading', ref(false));

  const packingSlipBody: Ref<RequestBody> = ref({} as RequestBody);

  const packinSlipLabels = areOnlineReturnsEnabled
    ? instance.$t('returnSlipProps')
    : {};

  const returnReasonsTranslations =
    areOnlineReturnsEnabled || areNarvarReturnsEnabled
      ? instance.$t('returnReasons')
      : {};

  const setOrderType = (type: ReturnsOrderType) => (orderType.value = type);

  const setGiftOrder = (value: boolean) =>
    (returnPayload.value.isRefundOnGiftCard = value);

  const setReturnStep = (value: ReturnStepsType) => (returnStep.value = value);

  const setShippingMethod = (value: string) => (shippingMethod.value = value);

  const removeReturnItem = (returnItem: ReturnDetailedItem) => {
    returnPayload.value.items = returnPayload.value.items.filter(
      (existingItem) => existingItem.orderLineKey !== returnItem.orderLineKey
    );
  };

  const updateReturnItems = (returnItem: ReturnDetailedItem) => {
    removeReturnItem(returnItem);
    returnPayload.value.items.push(returnItem);
  };

  const resetReturn = () => {
    orderHeaderKey.value = '';
    returnStep.value = ReturnStepsType.EMPTY;
    returnPayload.value = {
      customerEmail: customerEmail.value,
      items: [],
      isRefundOnGiftCard: null,
    };
    resetReturnPreview();
  };

  const resetReturnPreview = () => {
    returnPreviewObject.value = null;
  };

  const prepareAPIReturnItem = (item: ReturnDetailedItem): ReturnItem => {
    return {
      qty: parseInt(item.returnQty),
      returnReason: item.reasonCode,
      item: {
        sku: item.sku,
        orderLineKey: item.orderLineKey,
      },
    };
  };

  const getReturnDetails = async (
    orderReturnKey: string,
    data = { pageSize: 10 },
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    orderHeaderKey.value = orderReturnKey;
    const { tag } = trackRequest(
      'useAccount-getReturnDetails',
      isBackgroundRequest
    );
    try {
      const response: AxiosResponse<ReturnDetailsObject> = await getReturnDetailsAPI(
        orderHeaderKey.value,
        data,
        {
          usid: getUsid.value,
        }
      );

      returnPreviewObject.value = mapReturnOrder(response.data, instance);
    } catch (e) {
      instance.$log.error(
        `[@composables/useReturns/index::getReturnDetails] Error getting return details`
      );
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const getPackingSlipOrderData = () => {
    return new Promise((resolve) => {
      packingSlipBody.value.filename = returnPreviewObject.value.returnNumber;

      packingSlipBody.value.destination = {
        title: packinSlipLabels['destination.title'],
        lines: [
          { value: packinSlipLabels['address.name'] },
          { value: packinSlipLabels['address.street'] },
          { value: packinSlipLabels['address.street2'] },
          {
            value: `${packinSlipLabels['address.postalCode']} ${packinSlipLabels['address.city']} ${packinSlipLabels['address.province']}`,
          },
        ],
      };

      packingSlipBody.value.origin = {
        title: packinSlipLabels['origin.title'],
        lines: [
          {
            value: `${returnPreviewObject.value.personInfoShipTo.firstName} ${returnPreviewObject.value.personInfoShipTo.lastName}`,
          },
          {
            value: customerEmail.value,
          },
        ],
      };

      packingSlipBody.value.refundHeaderData = {
        lines: [
          {
            key: packinSlipLabels['returnInfo.returnNumberLabel'],
            value: returnPreviewObject.value.returnNumber,
          },
          {
            key: packinSlipLabels['returnInfo.orderNumberLabel'],
            value:
              returnPreviewObject.value?.orderNumber ?? order.value.orderNumber,
          },
        ],
      };

      packingSlipBody.value.refundAdditionalData = {
        title: packinSlipLabels['returnInfo.title'],
        subtitle: packinSlipLabels['returnInfo.method'],
        lines: [
          {
            value: packinSlipLabels['returnInfo.infoSectionDescription'],
          },
        ],
      };

      packingSlipBody.value.refundLines = {
        header: [
          {
            value: packinSlipLabels['itemLinesHeaders.returnQty'],
            fieldLength: 40,
          },
          {
            value: packinSlipLabels['itemLinesHeaders.productId'],
            fieldLength: 80,
          },
          {
            value: packinSlipLabels['itemLinesHeaders.itemId'],
            fieldLength: 65,
          },
          {
            value: packinSlipLabels['itemLinesHeaders.description'],
            fieldLength: 150,
          },
          {
            value: packinSlipLabels['itemLinesHeaders.reason'],
            fieldLength: 140,
          },
          {
            value: packinSlipLabels['itemLinesHeaders.total'],
            fieldLength: 50,
          },
        ],
        lines: [],
      };

      const currency =
        order.value?.totals?.currency ??
        returnPreviewObject.value.items?.[0]?.totals?.currency;

      returnPreviewObject.value.orderLines.forEach((returnLine) => {
        const returnPayloadLine = returnPayload.value.items.find(
          (payloadItem) =>
            payloadItem.orderLineKey === returnLine.derivedFromOrderLineKey ||
            returnLine.derivedFromOrder?.orderHeaderKey
        );

        const linePayload = [
          { value: returnLine.orderedQty.toString() }, // Return Qty
          { value: returnLine.item.itemID }, // Style
          { value: returnLine.extn.extnReturnItemNo }, // Item number
          { value: returnLine.name }, // Item description
          {
            value:
              returnPayloadLine?.reasonText ||
              returnReasonsTranslations[returnLine.returnReason],
          }, // Reason
          {
            value: instance.context.$formatPrice(
              returnLine.lineOverallTotals.lineTotalWithoutTax,
              currency
            ),
          }, // Total line
        ];
        packingSlipBody.value.refundLines.lines.push(linePayload);
      });

      packingSlipBody.value.totals = {
        lines: [
          {
            key: packinSlipLabels['returnTotals.itemCreditSubtotal'],
            value: instance.context.$formatPrice(
              returnPreviewObject.value.overallTotals.lineSubTotal,
              currency
            ),
          },
          {
            key: packinSlipLabels['returnTotals.shippingCredit'],
            value: instance.context.$formatPrice(
              returnPreviewObject.value.overallTotals.grandShippingBaseCharge,
              currency
            ),
          },
          {
            key: packinSlipLabels['returnTotals.shippingTax'],
            value: instance.context.$formatPrice(
              returnPreviewObject.value.overallTotals.grandShippingCharges,
              currency
            ),
          },
          {
            key: packinSlipLabels['returnTotals.returnShippingCost'],
            value:
              returnPreviewObject.value.overallTotals.hdrTotal > 0
                ? instance.context.$formatPrice(
                    returnPreviewObject.value.overallTotals.hdrTotal,
                    currency
                  )
                : packinSlipLabels['returnTotals.free'],
          },
          {
            key: packinSlipLabels['returnTotals.salesTaxCredit'],
            value: instance.context.$formatPrice(
              returnPreviewObject.value.overallTotals.grandTax,
              currency
            ),
          },
          {
            key: packinSlipLabels['returnTotals.totalCredit'],
            value: instance.context.$formatPrice(
              returnPreviewObject.value.overallTotals.grandTotal,
              currency
            ),
          },
        ],
      };

      packingSlipBody.value.footer = {
        title: packinSlipLabels['footerInfo.message'],
        subtitle: packinSlipLabels['footerInfo.csChannelsLabel'],
        lines: [
          { value: packinSlipLabels['footerInfo.emailAddress'] },
          { value: packinSlipLabels['footerInfo.phoneNumber'] },
          { value: packinSlipLabels['footerInfo.url'] },
          { value: packinSlipLabels['footerInfo.others'] },
        ],
      };

      resolve(true);
    });
  };

  const getReturnsConfig = (): LabelsConfig =>
    instance.$getEnvValueByCurrentLocale<LabelsConfig>('ONLINE_RETURNS');

  // TODO: GLOBAL15-58107 - remove Create Returns References after Narvar Launch
  const createReturn = async (
    orderReturnKey: string,
    isPreview: boolean,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    orderHeaderKey.value = orderReturnKey;
    const { tag } = trackRequest(
      'useReturns-createReturn',
      isBackgroundRequest
    );
    try {
      loading.value = true;
      const preparedItems = returnPayload.value.items.map((item) =>
        prepareAPIReturnItem(item)
      );
      returnPayload.value.customerEmail =
        order.value?.billingAddress?.email || customerEmail.value;
      const isRefundOnGiftCard = returnPayload.value.isRefundOnGiftCard;
      const response = await createReturnAPI(
        orderHeaderKey.value,
        {
          customerEmail: returnPayload.value.customerEmail,
          isRefundOnGiftCard,
          items: preparedItems,
          giftRecipientEmailID: giftEmail.value,
        },
        isPreview,
        {
          usid: getUsid.value,
        }
      );
      const { extn, ...responseWithoutExtn } = response.data;

      returnPreviewObject.value = {
        ...responseWithoutExtn,
        ...extn,
        returnNumber: response.data.orderNo,
        orderNumber: response.data.orderLines[0].derivedFromOrder.orderNo,
        returnedItems: response.data.orderLines.reduce(
          (a, b) => +a + +b.orderedQty,
          0
        ),
      };
      return true;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    } finally {
      loading.value = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const printPackingSlip = async (
    returnOrder?: any,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useReturns-printPackingSlip',
      isBackgroundRequest
    );

    try {
      if (returnOrder) {
        const returnHeaderKey = returnOrder.orderHeaderKey;
        !order.value && (await loadOrder(returnHeaderKey));
        !returnPreviewObject.value && (await getReturnDetails(returnHeaderKey));
      }

      await getPackingSlipOrderData();

      const resp = await printPackingSlipAPI(packingSlipBody.value);

      fileDownload(resp.data, `${packingSlipBody.value.filename}.pdf`);
      return resp;
    } catch (e) {
      console.error(e);
      displayErrorMessages(e);
      return false;
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const printReturnLabel = async (
    returnHeaderkey?: string,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useReturns-printReturnLabel',
      isBackgroundRequest
    );
    try {
      returnHeaderkey && (await getReturnDetails(returnHeaderkey));

      const response = await printReturnLabelAPI(
        returnPreviewObject.value.returnNumber,
        {
          firstName: returnPreviewObject.value.personInfoShipTo.firstName,
          lastName: returnPreviewObject.value.personInfoShipTo.lastName,
          phone: parseInt(
            returnPreviewObject.value.customerPhoneNo?.replace(/-/g, '') ||
              returnPreviewObject.value.personInfoShipTo.dayPhone.replace(
                /-/g,
                ''
              )
          ),
          address: {
            addressLine1:
              returnPreviewObject.value.personInfoShipTo.addressLine1,
            addressLine2:
              returnPreviewObject.value.personInfoShipTo.addressLine2,
            addressLine3:
              returnPreviewObject.value.personInfoShipTo.addressLine2,
            city: returnPreviewObject.value.personInfoShipTo.city,
            state: returnPreviewObject.value.personInfoShipTo.state,
            country: returnPreviewObject.value.personInfoShipTo.country,
            zipCode: returnPreviewObject.value.personInfoShipTo.zipCode,
          },
        },
        {
          usid: getUsid.value,
        }
      );
      const buf = Buffer.from(response.data.returnLabel, 'base64');
      fileDownload(buf, `${response.data.trackingNumber}.pdf`);
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  return {
    setGiftOrder,
    giftEmail,
    setReturnStep,
    returnStep: computed(() => returnStep.value),
    updateReturnItems,
    removeReturnItem,
    returnPayload: computed(() => returnPayload.value),
    returnPreviewObject: computed(() => returnPreviewObject.value),
    returnFee: computed(() => {
      const charge = returnPreviewObject.value?.headerCharges?.HeaderCharge?.find(
        (header) => header.ChargeCategory === 'RETURN_FEE'
      );
      return Number(charge?.ChargeAmount ?? 0);
    }),
    resetReturn,
    resetReturnPreview,
    createReturn,
    orderType: computed(() => orderType.value),
    setOrderType,
    getReturnDetails,
    printPackingSlip,
    printReturnLabel,
    getReturnsConfig,
    setShippingMethod,
    loading,
  };
};

export default useReturns;
