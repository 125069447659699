import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Plugin } from '@nuxt/types';
import { ComponentInstance } from '@vf/composables/src/types';
import { getVueInstanceFromContext } from '../helpers';

let loggingEnabled = false; // activate logging only once

type AxiosRequestConfigMeta = {
  requestStartedAt: number;
};

type AxiosRequestConfigWithMeta = AxiosRequestConfig & {
  meta?: AxiosRequestConfigMeta | Record<string, never>;
};

const NuxtjsGtmPlugin: Plugin = (context) => {
  const vueInstance: ComponentInstance = getVueInstanceFromContext(context);
  if (
    vueInstance.$env.DEBUG_CONNECTIVITY === 'true' &&
    loggingEnabled === false
  ) {
    // Since this is a plugin, it gets executed on each request,
    // leading to an accumulation of interceptors.
    Axios.interceptors.request.eject(0);
    Axios.interceptors.response.eject(0);

    Axios.interceptors.request.use((x: AxiosRequestConfigWithMeta) => {
      // to avoid overwriting if another interceptor
      // already defined the same object (meta)
      x.meta = x.meta || {};
      x.meta.requestStartedAt = new Date().getTime();
      return x;
    });

    Axios.interceptors.response.use(
      (x: AxiosResponse) => {
        const startedAt = (x.config as AxiosRequestConfigWithMeta).meta
          .requestStartedAt;
        console.log(
          `Execution time for: ${x.config.url} - ${
            new Date().getTime() - startedAt
          } ms`
        );
        return x;
      },
      // Handle 4xx & 5xx responses
      (x) => {
        console.error(
          `Execution time for: ${x.config.url} - ${
            new Date().getTime() - x.config.meta.requestStartedAt
          } ms`
        );
        throw x;
      }
    );
    loggingEnabled = true;
  }
};

export default NuxtjsGtmPlugin;
