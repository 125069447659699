import {
  Article,
  Breadcrumb,
  Cart,
  Category,
  CustomerAddress,
  CustomerBasicInformation,
  CustomerMainData,
  FilterItem,
  Page,
  Pagination,
  PaymentInstrument,
  Price,
  Product,
  ReturnItem,
  ReturnLabelAddress,
  SaveForLaterItem,
  SaveForLaterItemRequest,
  SearchSuggestions,
  ShippingGroup,
  SortItem,
  TokenizedCreditCard,
} from './entities';
import { FavoriteProductObject } from './entities/favorites';

/**
 * Catalog
 */
export interface GetProductRequest {
  id: string;
}

export interface GetProductResponse {
  product: Product;
}

export interface GetProductsRequest {
  ids?: string[];
  facets?: FilterItem[];
  sort?: SortItem;
  pagination?: Pagination;
}

export interface GetProductsResponse {
  products: Product[];
  pagination: Pagination;
}

export interface GetCategoryRequest {
  id: string;
}

export interface GetCategoryResponse {
  category: Category;
}

export interface BrowseCatalogRequest {
  facets: FilterItem[];
  sort?: SortItem;
  pagination?: Pagination;
}

export interface BrowseCatalogResponse {
  products: Product[];
  breadcrumbs: Breadcrumb[];
  selectedFacets: FilterItem[];
  availableFacets: FilterItem[];
  pagination: Pagination;
}

/**
 * Article
 */
export interface ArticleRequest {
  pagination: Pagination;
  filters: string[];
  filter?: string;
}

export interface ArticleResponse {
  items: Article[];
  filters: string[];
  filter?: string;
  pagination: Pagination;
}

/**
 * TODO: CMS
 */
export interface GetCmsPageRequest {
  pageName: string;
}

export interface GetCmsPageResponse {
  page: Page;
}

export interface SearchSuggestionResponse {
  results: SearchSuggestions[];
  totalResults: number;
  adjustedResults: number;
  adjustedSearchTerm: string;
}

export interface SearchSuggestionRequest {
  label: string;
  url: string;
  image: string;
  price: Price;
}

/**
 * Get Shipping groups
 */

export interface GetShippingMethodsRequest {
  [key: string]: unknown;
}

export interface GetShippingMethodsResponse {
  methods: ShippingGroup[];
}

/**
 * Get Upsells
 */

export interface GetUpsellsRequest {
  [key: string]: unknown;
}

export interface GetUpsellsResponse {
  items: Product[];
}

/**
 * Checkout
 */

export interface ApplyGiftCardRequest {
  number: string;
  pin: number;
}

export interface ApplyGiftCardResponse {
  [key: string]: unknown;
}

export interface ApplyCouponRequest {
  id: string;
}

export interface ApplyCouponResponse {
  id: string;
}

/**
 * Add to Cart
 */

export interface AddToCartRequest {
  id: string;
  quantity: number;
}

export interface AddToCartResponse {
  cart: Cart;
}

// ------------------------------------------------
/**
 * User
 */

/**
 * [POST] Sign In endpoint
 *
 * Respone:
 * 200 - user token
 * 500 - Error object
 *
 * User token:
 * {
 *   token: 'jwvWRPQplQvaXf1JtajTJIEnSahzjlmIgQC_qBkbvcs'
 * }
 */
export interface SignInRequest {
  email: string;
  password: string;
}

export interface SignInResponse {
  token: string;
}

/**
 * Sign in via social media
 *
 * Respone:
 * 200 - user token
 * 500 - Error object
 *
 */
export interface SignInSocialRequest {
  [key: string]: unknown;
}

/**
 * [POST] Reset password endpoint.
 * Backend should send reset password link
 *
 * Respone:
 * 200 - true (Just information that everything went ok)
 * 500 - Error object
 */
export interface ResetPasswordRequest {
  email: string;
}

export interface ResetPasswordResponse {
  [key: string]: unknown;
}

/**
 * [PUT] Set new password endpoint.
 *
 * Respone:
 * 200 - true or user token - up to decision
 * 500 - Error object
 *
 * User token:
 * {
 *   token: 'jwvWRPQplQvaXf1JtajTJIEnSahzjlmIgQC_qBkbvcs'
 * }
 */
export interface NewPasswordRequest {
  newPassword: string;
  resetToken: string;
}

export interface NewPasswordResponse {
  [key: string]: unknown;
}

/**
 * [POST] Create new account endpoint.
 *
 * Response:
 * 200 - true or user token - up to decision
 * 500 - Error object
 *
 * User token:
 * {
 *   token: 'jwvWRPQplQvaXf1JtajTJIEnSahzjlmIgQC_qBkbvcs'
 * }
 *
 * CustomerMainData:
 * {
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 * }
 */
export interface NewAccountRequest {
  mainData: CustomerMainData;
  password: string;
}

/**
 * [PUT] Update user basic information
 *
 * Response:
 * 200 - true
 * 500 - Error object
 *
 *
 * CustomerBasicInformation:
 * {
 *  firstName: string;
 *  lastName: string;
 *  email: string;
 *  gender: number;
 *  dob: number;
 * }
 */
export interface UpdateBasicInformationRequest {
  basicInfo: CustomerBasicInformation;
}

export interface UpdateBasicInformationResponse {
  basicInfo: CustomerBasicInformation;
}

/**
 * [GET] Endpoint for getting customer basic inromation
 *
 * Response:
 * 200 - CustomerBasicInformation
 * 500 - Error object
 *
 * CustomerBasicInformation:
 * {
 *  firstName: string;
 *  lastName: string;
 *  email: string;
 *  gender: number;
 *  dob: number;
 * }
 */
export interface GetBasicInformationRequest {
  [key: string]: unknown;
}

export interface GetBasicInformationResponse {
  [key: string]: unknown;
}

/**
 * [PUT] Update email preferences
 *
 * Response:
 * 200 - true
 * 500 - Error object
 *
 * interests: string[];
 */
export interface UpdateEmailPreferencesRequest {
  interests: string[];
}

export interface UpdateEmailPreferencesRsponse {
  interests: string[];
}

/**
 * [GET] Endpoint for getting customer basic inromation
 *
 * Response:
 * 200 - interests: string[]
 * 500 - Error object
 */
export interface GetEmailPreferencesRequest {
  interests: string[];
}

export interface GetEmailPreferencesResponse {
  interests: string[];
}

/**
 * [PUT] Endpoint for adding addresses.
 * isBilling and isShipping should determinated type of address
 *
 * Response:
 * 200 - true
 * 500 - Error object
 */
export interface AddAddressRequest {
  customerAddress: CustomerAddress;
}

export interface AddAddressResponse {
  customerAddress: CustomerAddress;
}

/**
 * [GET] Endpoint for getting customer addresses
 *
 * Response:
 * 200 - CustomerAddress[]
 * 500 - Error object
 *
 * CustomerAddress:
 * {
 *  firstName: string;
 *  lastName: string;
 *  email: string;
 *  country: number;
 *  streetAddress: string;
 *  building: string;
 *  city: string;
 *  province: number;
 *  phone: string;
 *  isBilling: boolean;
 *  isShipping: boolean;
 * }
 */
export interface GetCustomerAddressesRequest {
  [key: string]: unknown;
}

export interface GetCustomerAddressesResponse {
  customerAddress: CustomerAddress;
}

/**
 * [POST] Endpoint for adding product to wishlist
 *
 * Response:
 * 200 - products: Product[]
 * 500 - Error object
 */
export interface AddToWishlistRequest {
  sku: string;
}

export interface AddToWishlistResponse {
  products: Product[];
}

/**
 * Endpoint for getting customer wishlist
 *
 * Response:
 * 200 - products: Product[]
 * 500 - Error object
 */
export interface GetCustomerWishlistRequest {
  [key: string]: unknown;
}

export interface GetCustomerWishlistResponse {
  products: Product[];
}

/**
 * [GET] Endpoint for getting all customer credit cards
 *
 * Response:
 * 200 - GetCustomerCreditCardsResponse
 * 500 - Error object
 */
export interface GetCustomerCreditCardsQuery {
  payment_method_id: string;
}

export interface GetCustomerCreditCardsResponse {
  [index: number]: PaymentInstrument;
}

/**
 * [POST] Endpoint for adding new credit card
 *
 * Response:
 * 200 - AddCustomerCreditCardsResponse
 * 500 - Error object
 */
export interface AddCustomerCreditCardsRequest {
  paymentMethodId: string;
  card: TokenizedCreditCard;
  addressId: string;
  default: boolean;
  recaptcha_response: string;
}

export interface AddCustomerCreditCardsResponse {
  payment_method_id: string;
  payment_card: {
    card_type: string;
  };
  c_microformToken: string;
  c_captureContextKey: string;
  c_addressId: string;
}

/**
 * [DELETE] Endpoint for deleting saved credit card
 *
 * Response:
 * 200 - DeleteCustomerCreditCardsResponse
 * 500 - Error object
 */
export interface DeleteCustomerCreditCardsRequest {
  [key: string]: unknown;
}

export interface DeleteCustomerCreditCardsResponse {
  code: number;
  message: string;
}

/**
 * [PATCH] Endpoint for setting a saved credit card as default
 *
 * Response:
 * 200 - SetDefaultCustomerCreditCardResponse
 * 500 - Error object
 */
export interface SetDefaultCustomerCreditCardRequest {
  default: boolean;
}

export interface SetDefaultCustomerCreditCardResponse {
  [index: number]: PaymentInstrument;
}

/**
 * [GET] Endpoint for getting all customer orders
 */
export interface GetCustomerOrdersRequest {
  [key: string]: unknown;
}

export interface GetCustomerOrdersResponse {
  [key: string]: unknown;
}

// ------------------------------------------------
/**
 * Search
 */

/**
 * [GET] Search endpoint
 *
 * Query:
 * Search
 *
 * Response:
 * 200 - Product[]
 * 500 - Error object
 *
 * Search:
 * {
 *  query: string;
 * }
 *
 */
export interface SearchGetProductsRequest {
  [key: string]: unknown;
}

export interface SearchQueryParams {
  query: string;
  brId?: string;
  url?: string;
  refUrl?: string;
}

export interface SearchSuggestionsQueryParams {
  query: string;
  brId?: string;
  url?: string;
  refUrl?: string;
}

/**
 * @todo
 * - Order history
 * - Delete endpoints
 */

// ------------------------------------------------
/**
 * Favorites
 */

/**
 * [GET] Endpoint for getting list of favorites
 *
 * Response:
 * 200 - GetFavoritesResponse
 * 500 - Error object
 */

export interface GetFavoritesResponse {
  type: string;
  public: boolean;
  count: number;
  favoriteId: string;
  ownerFirstName?: string;
  items: FavoriteProductObject[];
}

export interface AddToFavoritesPayload {
  favoriteId: string;
  recipeId: string;
  dummyCustoms?: boolean;
  id: string;
  available?: string;
  qty: number;
  itemType: string;
  defaultProductView: string;
  public: boolean;
  pdpUrl: string;
  pageUrl?: string;
  productImageUrl: string;
}

export interface AddToFavoritesResponse {
  itemId: string;
  favoriteId: string;
  recipeId: string;
  id: string;
  available: string;
  qty: number;
  itemType: string;
  defaultProductView: string;
  public: boolean;
  pdpUrl: string;
  pageUrl: string;
  productImageUrl: string;
}

/**
 * [GET] Endpoint for getting save for later products
 *
 * Response:
 * 200 - GetSaveForLaterResponse
 * 500 - Error object
 */
interface GetSaveForLaterItem {
  count: number;
  items: SaveForLaterItem[];
  public: boolean;
  saveForLaterId: string;
  type: string;
}

export interface GetSaveForLaterResponse {
  saveForLater: GetSaveForLaterItem[];
}

/**
 * [DELETE] Endpoint for updating save for later product
 *
 * Response:
 * 200 - RemoveSaveForLaterResponse
 * 500 - Error object
 */
export interface RemoveSaveForLaterResponse {
  message: string;
  saveForLater: SaveForLaterItemRequest;
}

/**
 * [PUT] Endpoint for updating save for later product
 *
 * Response:
 * 200 - SaveForLaterResponse
 * 500 - Error object
 */
export interface UpdateSaveForLaterItemRequest {
  productId: string;
  recipeId: string;
  qty: number;
  pdpUrl: string;
  type: string;
  defaultProductView: string;
  productImageURL: string;
  available: string;
  saveForLater: SaveForLaterItemRequest;
}

/**
 * [POST] Endpoint for adding new save for later product
 *
 * Response:
 * 201 - SaveForLaterResponse
 * 500 - Error object
 */

export interface SaveForLaterResponse {
  id: string;
  isCustoms: boolean;
  listId: string;
  pdpUrl: string;
  priority: number;
  productId: string;
  productImageURL: string;
  public: boolean;
  purchasedQty: number;
  quantity: number;
  type: string;
}

/**
 * [POST] Endpoint for setting shipping / billing address
 *
 * Response:
 * 200 -
 * 500 - Error object
 */
export type CartAddressRequest = {
  id?: string;
  shippingId?: string;
  addressId?: string;
  approachType?: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  province: string;
  phone: string;
  stateCode?: string;
  subscriptions?: {
    newsletterConsent: boolean;
  };
  storeId?: string;
  altFirstName?: string;
  altLastName?: string;
  altEmail?: string;
  countryCode?: string;
};

export type CartPickupAddressRequest = {
  storeId: string;
  firstName: string;
  lastName: string;
  email: string;
  shippingId: string;
};

export interface ShareFavoritesResponse {
  favoritesShareUrl: string;
}

export interface NotifyMeObjectRequest {
  productId: string;
  email: string;
  backInStock?: boolean;
}

export interface LoyaltyWelcomePromoStatusResponse {
  isRedeemed: boolean;
}

export interface LoyaltyVouchersResponse {
  expiringLoyaltyPoints?: number;
  expiringLoyaltyPointsDays?: number;
  currentLoyaltyPoints: number;
  applicableLoyaltyVouchers?: LoyaltyVoucher[];
}

export type LoyaltyVoucher = {
  points: number;
  amountInCurrency: string;
  eligible: boolean;
  available: boolean;
};

export interface LoyaltyTokenResponse {
  memberToken: string;
  refreshToken: string;
  memberId: string;
  isVisitor: boolean;
  isConfirmed: boolean;
  expiresIn: number;
}

export enum CartCustomerNotificationType {
  OrderShippingMethodDowngraded = 'OrderShippingMethodDowngraded',
  CustomerSeasonDiscountLimitReached = 'CustomerSeasonDiscountLimitReached',
}

export enum CustomFlashType {
  ShippingMethodChanged = 'ShippingMethodChanged',
}

export enum FlashErrorType {
  Invalid = 'InvalidProductItem',
  NotAvailable = 'ProductItemNotAvailable',
  InventoryMissing = 'ProductLineItemInventoryMissing',
  FullInventoryMissing = 'ProductLineItemFullInventoryMissing',
  NoLongerAvailable = 'ProductLineItemNoLongerAvailable',
  ProductItemMaxQuantity = 'ProductItemMaxQuantity',
  InvalidShippingAddress = 'InvalidShippingAddress',
  CouponInvalidException = 'CouponInvalidException',
  ProductStyleMaxQuantity = 'ProductStyleMaxQuantity',
  ProductLineItemIsNotEligibleForGiftOption = 'ProductLineItemIsNotEligibleForGiftOption',
  ProductLineItemInventoryMissing = 'ProductLineItemInventoryMissing',
  BasketNotFoundException = 'BasketNotFoundException',
  PaymentMethodRequired = 'PaymentMethodRequired',
  BillingAddressRequired = 'BillingAddressRequired',
  LoyaltyPointsNotApplicable = 'LoyaltyPointsNotApplicable',
}

export type EAPIError = {
  code: number;
  errorId: string;
  message: string;
  reason: string;
};

export interface CreateReturnPayload {
  customerEmail: string;
  items: ReturnItem[];
  isRefundOnGiftCard?: boolean;
  giftRecipientEmailID?: string;
}

export interface PrintReturnLabelPayload {
  firstName: string;
  lastName: string;
  phone: number;
  address: ReturnLabelAddress;
}

export interface PrintReturnLabelResponse {
  trackingNumber: string;
  returnLabel: string;
}

export enum CustomerNotificationType {
  PickupToStsTransition = 'PickupToStsTransition',
  PickupToSthTransition = 'PickupToSthTransition',
  StsToSthTransition = 'StsToSthTransition',
  StsToPickupTransition = 'StsToPickupTransition',
  STHToPickupTransition = 'STHToPickupTransition',
  ChangedStoreId = 'ChangedStoreId',
}

export type GetCustomerRewardCardsResponse = {
  card: RewardCard;
  address: null;
}[];

export interface RewardCard {
  paymentMethodId: 'REWARD_CARD';
  cardType: null;
  creditCardExpired: boolean;
  paymentInstrumentId: string;
  holder: string;
  maskedNumber: string | null;
  expirationMonth: string | null;
  main: boolean;
  expirationYear: string | null;
  gcNumber: string;
  gcBalance: number;
  gcLastFourDigit: string;
  expirationDate?: string;
  gcPIN?: string;
  issueDate?: string;
  rewardCategory?: string;
}

export interface RewardCode {
  amount: number;
  annualCredit: number;
  cardType: string;
  expireDateTime: string;
  issueDateTime: string;
  paymentInstrumentId: string;
  paymentMethodId: 'REWARD_CODE';
  rewardCategory: 'SUMMER' | 'WINTER' | 'SPRING';
  rewardCode: string;
  authorized: boolean;
}

/**
 *  Contact Us
 */
export interface ContactUsCategory {
  category: string;
  translation: string;
  id: string;
  subjects: {
    subject: string;
    translation: string;
    id: string;
  }[];
}
