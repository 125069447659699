import { Element } from './../types';

const observer =
  process.client &&
  'IntersectionObserver' in window &&
  new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage(entry.target as Element);
        }
      });
    },
    {
      root: null,
      threshold: 0.9,
      rootMargin: `0px`,
    }
  );

const loadImage = (el: Element) => {
  setImageSrc(el);
  unObserveImage(el);
};

export const setImageSrc = (el: Element): void => {
  el.setAttribute('src', el._lazySrc);
};

export const observeImage = (el: Element): void => {
  if (observer) {
    observer.observe(el);
  }
};

export const unObserveImage = (el: Element): void => {
  if (observer) {
    observer.unobserve(el);
  }
};
