import { ref, readonly } from '@vue/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import type { Validator } from './validators';
export interface ValidatorField {
  message: string;
  messages: Record<string, string>;
  valid?: boolean;
  invalid?: boolean;
  firstInvalid?: boolean;
  validated: boolean;
}

export type Rule = Validator | Validator[] | Record<string, Validator>;

const pipe = (validators: Rule | Rule[]) => (
  value,
  data,
  key
): ValidatorField => {
  let message;
  const messages = {};
  if (value === null) value = ''; // phone input it's returning null and should be an empty string
  if (Array.isArray(validators)) {
    message = validators
      .map((validator) => validator(value, data, key))
      .find((e) => e);
  } else if (typeof validators === 'function') {
    message = validators(value, data, key);
  } else {
    message = Object.entries(validators)
      .map(([name, validator]) => {
        const msg = validator(value, data, key);
        messages[name] = msg;
        return msg;
      })
      .find((e) => e);
  }

  return {
    messages,
    message: message || '',
    valid: !message,
    invalid: !!message,
    validated: true,
  };
};

export default (
  form: { [key: string]: any } | (() => { [key: string]: any }),
  rules?: Ref<{ [key: string]: Rule[] | Rule }>
) => {
  const getForm = () => (form instanceof Function ? form() : form);
  function generateField(): ValidatorField {
    return {
      messages: {},
      message: '',
      valid: true,
      invalid: false,
      firstInvalid: false,
      validated: false,
    };
  }

  function generateFields(): Record<string, ValidatorField> {
    return Object.keys(getForm()).reduce(
      (acc, key) => ({
        ...acc,
        [key]: generateField(),
      }),
      {}
    );
  }
  function stamp() {
    return { ...getForm() };
  }

  const fields = ref(generateFields());

  let cur = stamp();
  const valid = ref(false);

  // validate function accepts a field name or 'all' to validate all fields
  const validate = (field: string = 'all'): boolean => {
    cur = stamp();
    if (!rules.value) return false; // TODO: this should only happen if loading the rules from the API fails.

    const fieldsEntries: [string, ValidatorField][] = Object.keys(
      getForm()
    ).map((key) => {
      const validator = rules.value[key];
      // if a field does not require validation, return it as is but flagged as validated
      if (!validator) return [key, { ...fields.value[key], validated: true }];
      return [
        key,
        field === 'all' || key === field
          ? pipe(validator)(cur[key], readonly(cur), key)
          : fields.value[key],
      ];
    });

    /* In Canvas, first invalid field is used to scroll the screen to the first error
      to ensure it's visible for the user, but 1.5 has a different method to do this
      TODO: review this logic and remove it if not needed
      */
    // Find first invalid field
    const firstInvalid = Object.keys(getForm()).find((name) =>
      fieldsEntries.some(([key, field]) => key === name && field.invalid)
    );
    // Set firstInvalid flag
    fieldsEntries.forEach(
      ([key, field]) => (field.firstInvalid = key === firstInvalid)
    );

    fields.value = Object.fromEntries(fieldsEntries);

    valid.value =
      Object.values(fields.value).every(({ validated }) => validated) &&
      Object.values(fields.value).every((error) => !error.message);

    if (field !== 'all') {
      return fields.value[field].valid && !fields.value[field].message;
    }

    return valid.value;
  };

  const reset = (fieldNames?: string[]) => {
    if (fieldNames) {
      fieldNames.forEach((name) => {
        fields.value[name] = generateField();
      });
    } else fields.value = generateFields();
  };

  return {
    valid,
    validationFields: fields,
    validate,
    reset,
  };
};
