//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'VfPageLoader',
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
});
