import type {
  PaymentSessionPayload,
  PaymentSessionResponse,
} from '../../api-types';

import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const getPaymentSession = (configuration: ApiClientConfiguration) => (
  payload: PaymentSessionPayload
): Promise<AxiosResponse<PaymentSessionResponse>> => {
  const query = new URLSearchParams(payload);
  if (!query.get('paymentMethodId'))
    query.set('paymentMethodId', 'CREDIT_CARD');

  const endpoint = endpoints.get(configuration).cart.getPaymentSession;
  const url: string = urlBuilder(`${endpoint}?${query?.toString()}`, {
    config: configuration,
  });

  return axios.get(url, {
    data: {},
    headers: headerBuilder(configuration),
  });
};
