import type { AppliedPromotion } from '@vf/api-contract';
import type {
  AutoAppliedPromotion,
  OrderPromotion,
} from '@vf/api-client/src/types';

export const calculateAppliedPromotions = (cart) => {
  const { discounts } = cart;
  const productDiscounts = discounts?.productDiscounts || [];
  const couponDiscounts = discounts?.couponDiscounts || [];
  const promotions = [];

  const pushPromotion = (
    promotions: AppliedPromotion[],
    promo: AppliedPromotion
  ) => {
    const getCouponDiscountAmount = (couponCode) => {
      return couponDiscounts.find(({ code }) => code === couponCode)?.discount;
    };

    const {
      description,
      price,
      id,
      type,
      couponCode,
      isBonus = false,
      isGWP = false,
      calloutMsg = '',
      itemText,
      isOrderAuto = false,
    } = promo;
    const existingPromo = promotions.find(
      (existingPromo) =>
        existingPromo.description === description && existingPromo.type === type
    );

    if (!existingPromo) {
      promotions.push({
        id,
        description,
        /** GLOBAL15-38828 The correct discount amount is placed order level. Product level discount price doesn't consider gift option with gifbox price included */
        price: (getCouponDiscountAmount(couponCode) ?? price) as string,
        type,
        couponCode,
        calloutMsg,
        itemText,
        isBonus,
        isGWP,
        isOrderAuto,
      });
    } else if (!couponCode) {
      existingPromo.price += price;
    }
  };

  cart.discounts?.autoAppliedPromotions?.forEach(
    (promo: AutoAppliedPromotion) => {
      pushPromotion(promotions, {
        id: promo.promotionId,
        description: promo.calloutMsg,
        itemText: promo.promotionName,
        calloutMsg: promo.calloutMsg,
        isOrderAuto: promo.level === 'ORDER',
        price: `${promo.amount}`,
        type: 'auto-promotion',
      });
    }
  );

  cart.items?.forEach((product) => {
    product.productPromotions?.forEach((promo) => {
      /** skip buy in store overriden price as it's handled and displayed separately */
      if (promo.appliedDiscount?.type === 'fixed_price') return;

      /**
       * Displaying autoAppliedPromotions from SFCC has higher priority and contains correct calloutMsg.
       * product level promotions contains only itemText which in fact is only promotion name, not actual callout message we need to display
       */
      if (promotions.find((promotion) => promotion.id === promo.promotionId))
        return;

      pushPromotion(promotions, {
        id: promo.priceAdjustmentId,
        description: promo.calloutMsg || promo.itemText,
        calloutMsg: promo.calloutMsg,
        itemText: promo.itemText,
        price: promo.price,
        type: 'product-promotion',
        couponCode: promo.couponCode,
        isBonus: promo.appliedDiscount?.type === 'bonus',
        isGWP: !!promo.promotionId?.includes('GWP'),
      });
    });
  });

  cart.orderPromotions?.forEach((promo: OrderPromotion) => {
    /**
     * Displaying autoAppliedPromotions from SFCC has higher priority and contains correct calloutMsg.
     * Order level promotions contains only itemText which in fact is only promotion name, not actual callout message we need to display
     */
    if (promotions.find((promotion) => promotion.id === promo.promotionId))
      return;

    pushPromotion(promotions, {
      id: promo.promotionId,
      description: promo.itemText,
      calloutMsg: promo.calloutMsg,
      itemText: promo.itemText,
      price: `${promo.price}`,
      type: 'cart-promotion',
      couponCode: promo.couponCode,
    });
  });

  /**
   * Temporary solution. Refactoring in GLOBAL15-54807.
   * For product product level discounts calculate gift option gift box price from option items
   */
  const optionItemsDiscount = productDiscounts
    ? cart.items?.reduce((value, item) => {
        value += item.optionItems?.reduce((acc, next) => {
          if (
            next.optionId !== 'giftBox' &&
            next.optionValueId !== 'withGiftBox'
          )
            acc += next.priceAfterItemDiscount;
          return acc;
        }, 0);
        return value;
      }, 0)
    : 0;

  const appliedPromotions = promotions
    .filter(
      (promo, index, self) =>
        self.findIndex((item) => item.description === promo.description) ===
        index
    )
    .map((promo, index) => {
      const amount = index ? promo.price : promo.price - optionItemsDiscount; // GLOBAL15-54807
      const price = Math.abs(amount);
      return { ...promo, price };
    });

  return {
    appliedPromotions,
    couponDiscounts,
    productDiscounts,
  };
};
