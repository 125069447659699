import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  getTranslation,
  decorateHtmlValue,
  getDynamicField,
  getCreateAccountTranslations,
  mapPasswordValidationSteps,
  getValidationConfigValues,
  extractLocalSettingsExpandedRichText,
} from './utils';

export const loyaltyRegister = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const translations = getCreateAccountTranslations(
    data,
    context,
    siteConfiguration,
    cmsBaseUri
  );

  const validationMessages = getTranslation(data, 'validationMessages', {
    ...translations.validationMessages,
  });

  return {
    component: 'loyalty/LoyaltyRegister',
    props: {
      translations: {
        ...translations,
        heading: getTranslation(
          data,
          'greeting',
          '$Hello! Welcome to the family.$'
        ),
        subheading: getTranslation(
          data,
          'subtext',
          '$Help us get to know you better. You know, because family stays close$'
        ),
        phoneNumberLabel: getTranslation(data, 'phoneLabel', '$* Cell Phone$'), // change to phoneNumberLabel
        countryCodeLabel: getTranslation(
          data,
          'countryCodeLabel',
          '$Country Code$'
        ), // change to phoneNumberLabel
        phoneMask: getTranslation(data, 'phoneMask', '###-###-####'),
        birthdayLabel: getTranslation(
          // change to birthdayLabel
          data,
          'dateOfBirthLabel',
          '$* Date of Birth (MM-DD-YYYY)$'
        ),
        zipCodeLabel: getTranslation(data, 'postalCodeLabel', '$* Zip Code$'), // change to zipCodeLabel
        passwordHelperText: getTranslation(
          data,
          'passwordHelperText',
          '$Password must contain at least 8 characters and include 1 digit and 1 letter and 1 uppercase letter and 1 lowercase letter.$'
        ),
        joinNowButtonText: getTranslation(
          data,
          'joinNowButtonText',
          '$JOIN NOW$'
        ),
        close: getTranslation(data, 'close', '$Close$'),
        phoneWhyText: getTranslation(
          data,
          'phoneWhyText',
          '$We will use this as your Vans Family ID in store.$'
        ),
        dateOfBirthWhyText: getTranslation(
          data,
          'dateOfBirthWhyText',
          '$We need to make sure you are over 16 and we want to celebrate you on your birthday.$'
        ),
        zipCodeHelpText: getTranslation(data, 'zipCodeHelpText', ''),
        phoneNumberHelpText: getTranslation(
          data,
          'phoneWhyText',
          '$We will use this as your Vans Family ID in store.$'
        ),
        birthdayHelpText: getTranslation(
          data,
          'dateOfBirthWhyText',
          '$Birthday$'
        ),
        termsLabel: decorateHtmlValue(
          data.teaserRichTextObject,
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        privacyLabel: decorateHtmlValue(
          extractLocalSettingsExpandedRichText(data, 'privacyPolicyText', ''),
          cmsBaseUri,
          context,
          siteConfiguration
        ),
        buttonText: getTranslation(data, 'joinNowButtonText', '$JOIN NOW$'),
        validationMessages: {
          ...validationMessages,
          zipError: validationMessages.zipCodeError,
        },
        sms: {
          ...translations.sms,
          termsAndConditions: {
            ...translations.sms.termsAndConditions,
            body: decorateHtmlValue(
              extractLocalSettingsExpandedRichText(
                data,
                'smsTermsAndConditionsBody',
                ''
              ),
              cmsBaseUri,
              context,
              siteConfiguration
            ),
          },
        },
      },
      showSmsSubscription: getDynamicField(
        data,
        'smsSubscriptionEnabled',
        false
      ),
      validations: getValidationConfigValues(data),
      passwordValidationSteps: mapPasswordValidationSteps(
        getTranslation(data, 'validationSteps', {
          characters: '$at least 8 characters$',
          uppercaseLetter: '$1 uppercase letter$',
          number: '$1 number$',
          lowercaseLetter: '$1 lowercase letter$',
        })
      ),
      marketingConsentText: getDynamicField(
        data,
        'marketingConsentText',
        '$Please send me emails with offers and updates from Vans. I understand that I can unsubscribe at any time.$'
      ),
      newsletterSignUpAutoCheck: getDynamicField(
        data,
        'newsletterSignUpAutoCheck',
        false
      ),
    },
  };
};
