import { axios } from '@vf/api-client';
import { isClient } from '@vf/shared/src/utils/helpers';
import { ComponentInstance } from '../../types';

export const csvToArray = (csvData: any): any[] => {
  const delimiter = ',';
  const pattern = new RegExp(
    '(\\' +
      delimiter +
      '|\\r?\\n|\\r|^)' +
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      '([^"\\' +
      delimiter +
      '\\r\\n]*))',
    'gi'
  );
  const data = [[]];
  let matches = null;
  while ((matches = pattern.exec(csvData))) {
    let matchedDelimiter = matches[1];
    if (matchedDelimiter.length && matchedDelimiter != delimiter) {
      data.push([]);
    }
    if (matches[2]) {
      matchedDelimiter = matches[2].replace(/""/g, '"');
    } else {
      matchedDelimiter = matches[3];
    }
    data[data.length - 1].push(matchedDelimiter);
  }

  const result = [];
  for (let i = 1; i < data.length; i++) {
    result[i - 1] = {};
    for (let k = 0; k < data[0].length && k < data[i].length; k++) {
      const key = data[0][k].replace(/\s/g, '');
      result[i - 1][key] = data[i][k];
    }
  }
  return result;
};

const getHeaders = (instance: ComponentInstance) => {
  const renderer = isClient ? 'CSR' : 'SSR';
  return instance.$root.$env.NODE_ENV === 'development' || renderer === 'SSR'
    ? {
        [instance.$root.$env[`COREMEDIA_ACCESS_HEADER_${renderer}`]]:
          instance.$root.$env[`COREMEDIA_ACCESS_TOKEN_${renderer}`],
      }
    : {};
};

export const parseRemoteCsvFile = async (
  instance: ComponentInstance,
  filePath: string
): Promise<any[]> => {
  try {
    console.group('[INFO] Get CSV File');
    console.log(`URL: ${filePath}`);
    console.groupEnd();
    const response = await axios.get(filePath, {
      headers: getHeaders(instance),
    });
    console.group('[INFO] Get CSV File');
    console.log(`Status: ${response.status}`);
    console.groupEnd();
    return csvToArray(response.data);
  } catch (e) {
    console.error(
      `Error occurred getting CSV file. File: ${filePath}, error:`,
      e
    );
    return [];
  }
};
