import { getTranslation } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const shoppingCartLabel = (data: CMPlaceholder) => {
  return {
    component: 'cart/ShoppingCartLabel',
    props: {
      translations: {
        multipleItemLabel: getTranslation(
          data,
          'multipleItemLabel',
          '$multipleItemLabel$'
        ),
        shoppingCartLabel: getTranslation(
          data,
          'shoppingCartLabel',
          '$shoppingCartLabel$'
        ),
        singleItemLabel: getTranslation(
          data,
          'singleItemLabel',
          '$singleItemLabel$'
        ),
        cartMergedNotification: getTranslation(
          data,
          'cartMergedNotification',
          '$Items have been added to your cart from your last visit$'
        ),
      },
    },
  };
};
