import { ref, Ref } from '@vue/composition-api';

import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { UseCartStorage } from '../../types';
import { useRecentlyAddedProduct } from '../useRecentlyAddedProduct';

export const useMiniCart = (instance, contextKey?: string) => {
  const { setRecentlyAddedProduct } = useRecentlyAddedProduct(
    instance,
    contextKey
  );

  const storage: ComposablesStorage<UseCartStorage> = initStorage<UseCartStorage>(
    instance,
    ['useCart', contextKey].join('-')
  );

  // TODO name of this variables should be correct isMiniCartOpen
  const isMinicartOpen: Ref<boolean> = storage.getOrSave(
    'isMinicartOpen',
    ref(false)
  );

  // TODO name of this variables should be correct isMiniCartHidden
  // TODO do we need both this REFs with same state or maybe name is wrong?
  const isMinicartHidden: Ref<boolean> = storage.getOrSave(
    'isMinicartHidden',
    ref(false)
  );

  let _setMiniCartTimerId = null;
  const setMiniCart = (state: boolean, closeDelay?: number) => {
    isMinicartOpen.value = state;
    clearTimeout(_setMiniCartTimerId);
    setHideMiniCart(state);
    if (closeDelay) {
      _setMiniCartTimerId = setTimeout(() => {
        isMinicartOpen.value = false;
        // TODO why we reset recently added product only when close delay is set?
        setRecentlyAddedProduct(null);
      }, closeDelay);
    }
  };

  const setHideMiniCart = (hidden: boolean) => {
    isMinicartHidden.value = hidden;
  };

  return {
    isMinicartOpen,
    isMinicartHidden,
    setMiniCart,
    setHideMiniCart,
  };
};
