import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTeaserStyle } from './utils/getTeaserStyle';
import {
  extractProperty,
  getDynamicField,
  generateLinkFromTeaserTargets,
} from './utils';

export const ctaButton = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'shared/RegularButton',
    props: {
      enabled: getDynamicField(data, 'enabled', true),
      id: extractProperty(data, 'id'),
      link: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri,
        data.scrollToComponent
      ),
      openInNewModal: extractProperty(data, 'openInNewModal'),
      openInNewTab: extractProperty(data, 'openInNewTab'),
      text:
        extractProperty(data, 'teaserTitle') ||
        extractProperty(data, 'teaserTargets[0].target.teaserTitle'),
      textColor: getTeaserStyle(data, 'teaserTextColor'),
      textCustomClasses: getTeaserStyle(data, 'teaserTextClassModifiers'),
      icon: getDynamicField(data, 'icon', ''),
      buttonStyle: getDynamicField(data, 'style'),
      fontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
      fontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
      fontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
      iconPosition: extractProperty(
        data,
        'localSettings.dynamicFields.iconPosition'
      ),
      buttonSize: getDynamicField(data, 'size'),
      buttonTargetComponent: extractProperty(
        data,
        `teaserTargets[0].target.type`,
        ''
      ),
      underline: extractProperty(
        data,
        'localSettings.dynamicFields.underline',
        false
      ),
      scrollToLink: extractProperty(data, 'scrollToComponent'),
    },
  };
};
