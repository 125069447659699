




















































import { PropType } from 'vue';
import type { PriceRangeObject } from '@vf/api-contract';

export default {
  name: 'VfPriceRange',
  props: {
    priceRange: {
      type: Object as PropType<PriceRangeObject>,
      default: (): PriceRangeObject => ({
        lowest: 0,
        isLowestPriceDiscounted: false,
        highest: 0,
        isHighestPriceDiscounted: false,
        currency: '',
      }),
    },
    showBrackets: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lowestPrice() {
      return `${this.$root.$formatPrice(
        this.priceRange.lowest,
        this.priceRange.currency
      )}`;
    },
    highestPrice() {
      return `${this.$root.$formatPrice(
        this.priceRange.highest,
        this.priceRange.currency
      )}`;
    },
    showTextInColor() {
      return (
        this.priceRange.isLowestPriceDiscounted &&
        this.priceRange.isHighestPriceDiscounted
      );
    },
  },
};
