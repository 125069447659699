import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../../types';
import { extractProperty } from './extractProperty';
import { extractCommonConfigLink } from './extractCommonConfigLink';
import { getAddressValidationMessages } from './getAddressValidationMessages';

export const addressFormTranslations = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const addressFormTranslations = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'address-form'),
    '[0].resourceBundle.common',
    {}
  );
  return {
    backButton: extractProperty(
      addressFormTranslations,
      'backButton',
      '$< Back to Address Book$'
    ),
    requiredFieldsText: extractProperty(
      addressFormTranslations,
      'requiredFieldsText',
      '$* Required fields$'
    ),
    addHeading: extractProperty(
      addressFormTranslations,
      'addHeading',
      '$Add address$'
    ),
    editHeading: extractProperty(
      addressFormTranslations,
      'editHeading',
      '$Edit address$'
    ),
    addedMessage: extractProperty(
      addressFormTranslations,
      'addedMessage',
      '$Address added$'
    ),
    modifiedAddress: extractProperty(
      addressFormTranslations,
      'modifiedAddress',
      '$Address modified$'
    ),
    billing: {
      addHeading: extractProperty(
        addressFormTranslations,
        'billing.addHeading',
        '$Add billing address$'
      ),
      editHeading: extractProperty(
        addressFormTranslations,
        'billing.editHeading',
        '$Edit billing address$'
      ),
      addedMessage: extractProperty(
        addressFormTranslations,
        'billing.addedMessage',
        'Billing address added'
      ),
      modifiedAddress: extractProperty(
        addressFormTranslations,
        'billing.modifiedAddress',
        'Billing address modified'
      ),
    },
    shipping: {
      addHeading: extractProperty(
        addressFormTranslations,
        'shipping.addHeading',
        '$Add shipping address$'
      ),
      editHeading: extractProperty(
        addressFormTranslations,
        'shipping.editHeading',
        '$Edit shipping address$'
      ),
      addedMessage: extractProperty(
        addressFormTranslations,
        'shipping.addedMessage',
        'Shipping address added'
      ),
      modifiedAddress: extractProperty(
        addressFormTranslations,
        'shipping.modifiedAddress',
        'Shipping address modified'
      ),
    },
    firstNameLabel: extractProperty(
      addressFormTranslations,
      'firstNameLabel',
      '$First Name$'
    ),
    lastNameLabel: extractProperty(
      addressFormTranslations,
      'lastNameLabel',
      '$Last Name$'
    ),
    streetLabel: extractProperty(
      addressFormTranslations,
      'streetLabel',
      '$Street Address$'
    ),
    apartmentLabel: extractProperty(
      addressFormTranslations,
      'apartmentLabel',
      '$Building / Unit / Apt / Buzzer$'
    ),
    postalCodeLabel: extractProperty(
      addressFormTranslations,
      'postalCodeLabel',
      '$Postal Code$'
    ),
    cityLabel: extractProperty(addressFormTranslations, 'cityLabel', '$City$'),
    provinceLabel: extractProperty(
      addressFormTranslations,
      'provinceLabel',
      '$Province$'
    ),
    emailLabel: extractProperty(
      addressFormTranslations,
      'emailLabel',
      '$E-mail$'
    ),
    countryLabel: extractProperty(
      addressFormTranslations,
      'countryLabel',
      '$Country$'
    ),
    countryCodeLabel: extractProperty(
      addressFormTranslations,
      'countryCodeLabel',
      '$Country Code$'
    ),
    phoneLabel: extractProperty(
      addressFormTranslations,
      'phoneLabel',
      '$Phone$'
    ),
    phoneMask: extractProperty(
      addressFormTranslations,
      'phoneMask',
      '$###-###-####$'
    ),
    emailHelperText: extractProperty(
      addressFormTranslations,
      'emailHelperText',
      '$To send you updates about your order.$'
    ),
    phoneHelperText: extractProperty(
      addressFormTranslations,
      'phoneHelperText',
      '$To reach you about your order$'
    ),
    saveButton: extractProperty(
      addressFormTranslations,
      'saveButton',
      '$Save$'
    ),
    cancelButton: extractProperty(
      addressFormTranslations,
      'cancelButton',
      '$Cancel$'
    ),
    hintJoin: extractProperty(addressFormTranslations, 'hintJoin', '$or$'),
    validationMessages: getAddressValidationMessages(addressFormTranslations),
  };
};
