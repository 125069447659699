import { CMPlaceholder, ProductRatingTranslations } from '@vf/api-contract';
import { getDynamicField, getTranslation } from './utils/extractProperty';

export const pdpRating = (data: CMPlaceholder) => {
  const translations: ProductRatingTranslations = {
    reviewPlural: getTranslation(data, 'reviewPlural', '${{count}} Reviews$'),
    reviewSingular: getTranslation(
      data,
      'reviewSingular',
      '${{count}} Review$'
    ),
    reviewRatio: getTranslation(data, 'reviewRatio', '$Customer Recommended$'),
    noReviews: getTranslation(data, 'noReviews', '$No Reviews$'),
  };

  return {
    component: 'pdp/ProductRating',
    props: {
      translations,
      showReviewRatio: getDynamicField(data, 'showReviewRatio', false),
    },
  };
};
