






















































import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { ROUTES, useCart, useI18n, useNotification } from '@vf/composables';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { cmsCommonConfigRoutes } from '@vf/composables/src/utils/cmsCommonConfigRoutes';
import { useRoute } from '@nuxtjs/composition-api';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import type { NotificationsTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'Notifications',
  props: {
    /* Code of default message which should be displayed if server response will not contain neither errorId nor message.  */
    defaultMessageCode: {
      type: String,
      default: 'GEN500',
    },
    /** Indicates if notifications are placed in the modal or not */
    inModal: {
      type: Boolean,
      default: false,
    },
    /** Indicates if notifications are placed inside nested modal */
    inNestedModal: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object as PropType<NotificationsTranslations>,
      default: () => ({
        customerNotifications: {
          OrderShippingMethodDowngraded:
            'Sorry, but we updated your order based on the following reason(s): <br/>Expedited shipping is no longer available. Your order will ship via standard shipping. <br/>Order totals have been updated to reflect the change.',
        },
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      addNotification,
      clearNotifications,
      removeNotification,
      notifications,
    } = useNotification(root);
    const { getStaticTranslation } = useI18n(root);
    const { cart, getProductRelatedFlashMessages } = useCart(root);
    const { isNotificationInModalVisible, setScrollModalTop } = useModal();
    const { getMessageFormatter } = errorMessages(root);
    const { contactUsURL, forgotPasswordURL } = cmsCommonConfigRoutes(root);
    const route = useRoute();
    const pathRef = computed(() => root.$route.path);
    const productRelatedFlashMessages = ref(getProductRelatedFlashMessages());

    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

    watch(pathRef, clearNotifications, { immediate: true });

    const getMessage = (notification) => {
      const { errorMessageId } = notification;
      let messageFromCMS;
      const msErrorsTranslations = getStaticTranslation('muleSoftErrors');

      if (typeof errorMessageId === 'string') {
        messageFromCMS = msErrorsTranslations[errorMessageId];

        const formatter = getMessageFormatter(errorMessageId);

        const formatterConfig = {
          messageFromCMS,
          errorDetails: notification,
          otherParams: {
            currency: cart.value?.currency,
            cartItems: cart.value?.items || [],
            productRelatedFlashMessages:
              productRelatedFlashMessages.value || [],
            contactUsURL,
            forgotPasswordURL,
          },
        };

        messageFromCMS = formatter(formatterConfig);
      }
      return (
        messageFromCMS ||
        notification.message ||
        msErrorsTranslations[props.defaultMessageCode]
      );
    };

    const isNotificationVisible = ref(true);

    const setVisibility = () => {
      const inModalNotification = props.inNestedModal || props.inModal;
      isNotificationVisible.value = isNotificationInModalVisible.value
        ? inModalNotification
        : !inModalNotification;
    };

    const notificationsEl = ref(null);

    const scrollToNotification = () => {
      const notification = notifications.value[0];
      if (props.inModal && notification) {
        setScrollModalTop(true);
      } else if (notification?.modifiers === 'scrollToNotification') {
        if (!notificationsEl.value) return;
        const headerHeight = (document.querySelector(
          '.vf-header__container'
        ) as HTMLElement).offsetHeight;
        scrollTo({
          top: notificationsEl.value.offsetTop - headerHeight - 20,
        });
      }
    };

    const hasCustomerNotifications = computed(
      () => !!cart.value?.customerNotifications?.length
    );

    watch(hasCustomerNotifications, () => {
      if (
        isCheckoutRedesignEnabled ||
        (typeof window === 'undefined' && typeof document === 'undefined')
      ) {
        return;
      }
      const notificationEl: HTMLElement = document.querySelector(
        '.vf-notification'
      );
      scrollTo({ top: notificationEl?.offsetTop || 0 });
    });

    watch(
      notifications,
      () => {
        setVisibility();
        scrollToNotification();
      },
      { immediate: true }
    );

    watch(
      () => route.value.query.redirectNotification,
      () => {
        if (process.server || !route.value.query.redirectNotification) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { redirectNotification, ...query } = route.value.query;

        root.$router.replace({ query });

        addNotification({
          type: 'info',
          message: decodeURIComponent(
            route.value.query.redirectNotification as string
          ),
        });
      },
      { immediate: true }
    );

    const isCart = computed(() => pathRef.value.endsWith(ROUTES.CART()));

    const isCheckout = computed(
      () =>
        pathRef.value.endsWith(ROUTES.CHECKOUT()) ||
        pathRef.value.endsWith(ROUTES.CHECKOUT_SHIPPING()) ||
        pathRef.value.endsWith(ROUTES.CHECKOUT_PAYMENT())
    );

    const shouldExcludeINV408 = (notification) => {
      return [
        notification.errorMessageId === 'INV408',
        isCheckoutRedesignEnabled,
        isCart.value || isCheckout.value,
      ].every(Boolean);
    };

    return {
      removeNotification,
      notifications: computed(() =>
        /**
         * Display smart notifications (without placement context)
         */
        notifications.value.filter((n) => {
          return n.context === undefined && !shouldExcludeINV408(n);
        })
      ),
      getMessage,
      isNotificationVisible,
      cart,
      isCheckout,
      hasCustomerNotifications,
      notificationsEl,
      isCheckoutRedesignEnabled,
    };
  },
});
