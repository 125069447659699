import { manageState } from '@vf/composables';
import { Plugin } from '@nuxt/types';
import { States } from '@vf/api-contract';

const StateManegement: Plugin = (context, inject) => {
  function stateManagementChecker(
    states: States[] | null,
    contextKey?: string | null
  ): boolean {
    if (!states || !states.length) return true;

    return manageState(states, this, contextKey);
  }

  inject('stateManagementChecker', stateManagementChecker);
};

export default StateManegement;
