import { Plugin } from '@nuxt/types';
import { set401Handler } from '@vf/api-client';
import {
  ComponentInstance,
  useAuthentication,
  useBasicInformation,
  useCart,
  useLoyalty,
  useSignInToStore,
} from '@vf/composables';
import { getVueInstanceFromContext } from '../helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

/**
 * clearProductionCookies
 *
 * When using shared cookies, we are setting up cookies as the TLD closest to the environment we are working on
 * otw.dev2.vans.com and dev2.vans.com share the ".dev2.vans.com" cookies
 * otw.vans.com and vans.com share ".vans.com" cookies.
 *
 * If a user is accessing vans.com and then goes to dev2.vans.com, it will be having two cookies with the same value because both vans.com and dev2.vans.com will share the .vans.com cookies.
 * So we need to clear all the production cookies on non-prod environment
 * Explanation: https://stackoverflow.com/questions/1062963/how-do-browser-cookie-domains-work
 */
const clearProductionCookies = (vueInstance: ComponentInstance) => {
  const host = window.location.host;

  if (!['dev2', 'qa2', 'preprod2'].some((prefix) => host.includes(prefix))) {
    return;
  }

  const siteId = vueInstance.$getEnvValueByCurrentLocale('API_SITE_ID');

  const currentEnv = host.substring(0, host.indexOf('.'));

  const cookiesPostfix = {
    Mock: 'mk',
    Login: 'li',
    Refresh: 'refresh_exp',
    Access: 'access_exp',
  };

  if (host.includes(currentEnv)) {
    const tld = host.substring(
      host.indexOf(currentEnv) + currentEnv.length + 1
    );

    Object.values(cookiesPostfix).forEach((value) => {
      document.cookie = `vfa_${siteId}_${value}=;domain=${tld};Max-Age=0`;
    });
  }
};

const Session: Plugin = async (context) => {
  const vueInstance = getVueInstanceFromContext(context);

  const allowSharedCookies = vueInstance.$config.ALLOW_SHARED_COOKIES;

  if (allowSharedCookies) {
    clearProductionCookies(vueInstance);
  }

  const { loadCart } = useCart(vueInstance);
  const { getBasicInformation } = useBasicInformation(vueInstance);
  const {
    isMobileTokenEnabled,
    isPointsToCurrencyEnabled,
  } = useFeatureFlagsStore();
  const { isTokenCallRequired } = useUserStore(vueInstance);
  const userStore = useUserStore(vueInstance);
  const { hasValidRefreshToken, isCustomerLoggedIn } = userStore;
  const { loyaltyEnrolled } = storeToRefs(userStore);
  const { handleRefreshToken, signInGuest, signOut } = useAuthentication(
    vueInstance
  );
  const { getLoyaltyVouchers } = useLoyalty(vueInstance);
  const isLoggedIn = isCustomerLoggedIn();

  set401Handler((url: string) => {
    const isCartRequest = /\/api\/cart/.test(url);
    const shouldRefreshPage =
      (!userStore.loggedIn && isLoggedIn) || hasValidRefreshToken();
    if (isCartRequest && shouldRefreshPage) {
      window.location.reload();
    } else if (isLoggedIn) {
      return signOut();
    }
  });

  /**
   * Always call refresh token when "isMobileTokenEnabled" feature flag is false
   */
  if (isMobileTokenEnabled ? isTokenCallRequired() : true) {
    if (
      isLoggedIn ||
      vueInstance.$cookies.get(useSignInToStore(vueInstance).employeeCookieName)
    ) {
      await handleRefreshToken();
    } else {
      await signInGuest();
    }
  }

  if (isLoggedIn && (loyaltyEnrolled.value || allowSharedCookies)) {
    getBasicInformation();

    if (isPointsToCurrencyEnabled) {
      getLoyaltyVouchers();
    }
  }

  /**
   * Cart section
   * Load cart if exists
   * Load save for later items if exists
   */
  if (
    localStorage.getItem('cartId') ||
    (allowSharedCookies && hasValidRefreshToken())
  ) {
    loadCart({
      isBackgroundRequest: true,
      isTemporary: false,
      inventorySupplyCheck: true,
    });
  }
};

export default Session;
