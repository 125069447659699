import { CMPlaceholder, CmsSite } from '@vf/api-contract';
import {
  getTranslation,
  extractLocalSettingsExpandedRichText,
  decorateHtmlValue,
} from './utils';
import { ComposableContext } from '../types';
// TODO: Remove it after GLOBAL15-56318
export const checkoutShippingMethod = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const extractMethodRichText = (data, settingName, defaultText) => {
    const richText = extractLocalSettingsExpandedRichText(
      data,
      settingName,
      defaultText
    );
    return richText?.html
      ? decorateHtmlValue(richText, cmsBaseUri, context, siteConfiguration)
      : defaultText;
  };

  return {
    component: 'checkout/CheckoutShippingMethod',
    props: {
      translations: {
        shippingMethodTitle: getTranslation(
          data,
          'shippingMethodTitle',
          '$SHIPPING METHOD$'
        ),
        customsTitle: getTranslation(data, 'customsTitle', '$CUSTOMS ITEMS$'),
        poAddressErrorMessageHeader: getTranslation(
          data,
          'poAddressErrorMessageHeader',
          '$Custom items$'
        ),
        poAddressErrorMessage: getTranslation(
          data,
          'poAddressErrorMessage',
          '$Custom shoes orders cannot be shipped to PO Box or FPO/APO addresses. Please change your address or remove the item from the cart.$'
        ),
        fedExDeliverPOBox: getTranslation(
          data,
          'fedExDeliverPOBox',
          '$FedEx does not deliver to a P.O. Box.$'
        ),
        shippingTimes: getTranslation(
          data,
          'shippingTimes',
          '$Shipping times may vary for each custom product types.$'
        ),
        shippingDetailsButton: getTranslation(
          data,
          'shippingDetailsButton',
          '$View Shipping Details$'
        ),
        customsTooltipMessages: getTranslation(data, 'customsTooltipMessages', [
          '$Arrival based on order date:$',
          '$Customs footwear (non-image) 2-3 weeks$',
          '$Upload Your Own customs footwear: 3 - 5 weeks$',
          '$Skate Classic customs footwear: 3 - 5 weeks$',
          '$ComfyCush custom footwear: 3 - 4 weeks$',
          '$UltraRange EXO custom footwear: 4 - 6 weeks$',
        ]),
        customsTooltipCloseText: getTranslation(
          data,
          'customsTooltipCloseText',
          '$Close$'
        ),
        shippingMethodText: extractMethodRichText(
          data,
          'shipping-method-text',
          ''
        ),
        freeShippingGroupLabel: getTranslation(
          data,
          'freeShippingGroupLabel',
          '$Free$'
        ),
        deliveryDateDisclaimer: getTranslation(
          data,
          'deliveryDateDisclaimer',
          '$Some items may arrive sooner$'
        ),
      },
    },
  };
};
