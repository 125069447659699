import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const deleteCoupon = (configuration: ApiClientConfiguration) => (
  data
): Promise<any> => {
  return axios.delete(
    urlBuilder(endpoints.get(configuration).cart.deleteCoupon, {
      config: configuration,
    }),
    {
      data: {
        cartId: data.cartId,
        couponItemId: data.couponItemId,
      },

      headers: headerBuilder(configuration),
    }
  );
};
