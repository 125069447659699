import { getCreateAccountTranslations } from './utils/getCreateAccountTranslations';
import { CMTeaser, CmsSite } from '@vf/api-contract';
import {
  extractProperty,
  extractLocalSettingsExpandedLinks,
  generateLinkFromTeaserTargets,
  getDynamicField,
  getTranslation,
  getTitle,
  getValidationConfigValues,
  generateLinkFromTarget,
} from './utils';
import { ComposableContext } from '../types';

export const maSignIn = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const createAccountLink = extractLocalSettingsExpandedLinks(
    data,
    'createAccountLink'
  );
  const createAccountData = extractProperty(createAccountLink, '[0]', {});

  return {
    component: 'account/SignInForm',
    props: {
      headingLevel: Number(
        extractProperty(
          data,
          'teaserStyleCustomizations.teaserTitleHeadingLevel',
          5
        )
      ),
      facebookUrl: getDynamicField(
        data,
        'facebookUrl',
        'https://images.vans.com/is/image/Vans/facebook?$SCALE-ORIGINAL$'
      ),
      showSocialLinks: getDynamicField(data, 'showSocialLinks', true),
      forgotPasswordLinkType: getDynamicField(
        data,
        'forgotPasswordLinkType',
        'modal'
      ),
      showCreateAccountLink: getDynamicField(
        data,
        'showCreateAccountLink',
        false
      ),
      showCaptcha: getDynamicField(data, 'showCaptcha', false),
      showPasswordToggler: getDynamicField(data, 'showPasswordToggler', false),
      createAccountLinkType: getDynamicField(
        data,
        'createAccountLinkType',
        'modal'
      ),
      showSmsSubscription: getDynamicField(
        createAccountData,
        'smsSubscriptionEnabled',
        false
      ),
      interestsLink: generateLinkFromTeaserTargets(
        extractProperty(createAccountData, 'teaserTargets'),
        context,
        siteConfiguration
      ),
      validations: getValidationConfigValues(createAccountData),
      contextKey: context.contextKey,
      translations: {
        title: getTranslation(data, 'title', '$Sign in$'),
        emailLabel: getTranslation(data, 'emailLabel', '$Email Address$'),
        emailPlaceholder: getTranslation(data, 'placeholder', '$Email$'),
        passwordLabel: getTranslation(data, 'passwordLabel', '$Password$'),
        validationMessages: {
          email: getTranslation(
            data,
            'emailErrorMessage',
            '$Please enter a valid email address.$'
          ),
          required: getTranslation(
            data,
            'requiredErrorMessage',
            '$This field is required, please add your {{fieldName}}.$'
          ),
        },
        forgotPasswordTitle: getTranslation(
          data,
          'forgotPasswordTitle',
          '$Forgot Password$'
        ),
        forgotPasswordButtonText: getTranslation(
          data,
          'forgotPasswordButtonText',
          '$Forgot password?$'
        ),
        signInButtonText: getTranslation(data, 'signInButtonText', '$Sign in$'),
        createAccountButtonLabel: getTranslation(
          data,
          'createAccountButtonLabel',
          '$Not a member?$'
        ),
        createAccountButtonText: getTranslation(
          data,
          'createAccountButtonText',
          '$Join Now$'
        ),
        bottomHeading: getTranslation(data, 'subheading', '$Bottom Heading$'),
        facebookAriaLabel: getTranslation(
          data,
          'facebookAriaLabel',
          '$Facebook aria label$'
        ),
        loginError: getTranslation(
          data,
          'loginError',
          '$Either the login id or password entered is incorrect. Enter the information again.$'
        ),

        createAccountTranslations: {
          heading: getTranslation(
            createAccountData,
            'greeting',
            getTitle(createAccountData)
          ),
          subheading: getTranslation(
            createAccountData,
            'subtext',
            getTitle(createAccountData)
          ),
          ...getCreateAccountTranslations(
            createAccountData,
            context,
            siteConfiguration,
            cmsBaseUri
          ),
        },
      },
      shippingTermsLink: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(
          createAccountData,
          'shippingTermsLink'
        )[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
    },
  };
};
