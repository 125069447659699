import { logger } from '../utils/logger-client';

function isDatabaseAvailable() {
  return typeof indexedDB !== 'undefined';
}

function getDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('cachedPLP');
    request.onupgradeneeded = () => {
      request.result.createObjectStore('cachedPLPStore');
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject(request.error);
    };
  });
}

const dbHandlerTypeMap = {
  reset: {
    accessLevel: 'readwrite',
    method: 'clear',
  },
  get: {
    accessLevel: 'readonly',
    method: 'get',
  },
  set: {
    accessLevel: 'readwrite',
    method: 'put',
  },
  remove: {
    accessLevel: 'readwrite',
    method: 'delete',
  },
};

const createDbHandler = (type) => async (...args: unknown[]): Promise<any> => {
  if (!isDatabaseAvailable()) {
    return;
  }

  try {
    const db = await getDatabase();

    const { accessLevel: dbAccessLevel, method: dbMethod } = dbHandlerTypeMap[
      type
    ];

    return new Promise((resolve, reject) => {
      const tx = db.transaction('cachedPLPStore', dbAccessLevel);
      const st = tx.objectStore('cachedPLPStore');
      const request = st[dbMethod](...args);

      request.onsuccess = function () {
        db.close();
        resolve(request.result);
      };
      request.onerror = function () {
        db.close();
        reject(request.error);
      };
    });
  } catch (e) {
    logger.error(`[@/productsGridCache::createDbHandler::${type}]`, e);
    return false;
  }
};

const SimpleIDB = {
  reset: () => createDbHandler('reset')(),

  get: (key) => createDbHandler('get')(key),

  set: (key, value) => createDbHandler('set')(value, key),

  remove: (key) => createDbHandler('remove')(key),
};

const DESTINATION_KEY = 'DESTINATION_KEY';

const getCacheKey = (currentRoute) => {
  return `items-${currentRoute}`;
};

type Destination = {
  from: string;
  to: string;
  productId: string;
  productPage: number;
  rowId: string;
};
export const productsGridCache = {
  currentPage: 1,
  checkForCache: false,
  async reset(): Promise<void> {
    await SimpleIDB.reset();
    this.currentPage = 1;
  },
  storeDestination(destination: Destination): Promise<void> {
    return SimpleIDB.set(DESTINATION_KEY, destination);
  },
  getDestination(): Promise<Destination> {
    return SimpleIDB.get(DESTINATION_KEY);
  },
  async getCachedItem(
    currentRoute: string
  ): Promise<{ lastRequest: any; products: any; currentPage: number }> {
    if (!isDatabaseAvailable()) {
      return null;
    }

    const cacheKey = getCacheKey(currentRoute);
    const cachedData = await SimpleIDB.get(cacheKey);
    if (!cachedData) {
      return null;
    }

    return {
      ...cachedData,
      currentPage: this.currentPage,
    };
  },
  storeCachedItem(
    currentRoute: string,
    item = { products: [], lastRequest: null }
  ): Promise<void> {
    return SimpleIDB.set(getCacheKey(currentRoute), item);
  },
};

export default productsGridCache;
