import {
  extractProperty,
  extractLocalSettingsExpandedLinks,
  getImageObject,
  getVideoObject,
  getDynamicField,
  generateLinkFromTeaserTargets,
  decorateHtmlValue,
  getTeaserStyle,
} from './utils';
import { customContent } from './customContent';
import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

const getLogoImage = (
  logoConfig: any,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  if (logoConfig[0]) {
    return getImageObject(logoConfig[0], siteConfiguration, null, cmsBaseUri);
  }

  return null;
};

export const customsHpStatic = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const logoConfig = extractLocalSettingsExpandedLinks(data, 'topPicture');
  const clickHereButton = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'linkBelowCtaButtons'),
    '[0]'
  );
  const bottomLink = extractProperty(
    extractLocalSettingsExpandedLinks(data, 'bottomLink'),
    '[0]'
  );
  const getImage = getImageObject(
    data.pictures[0],
    siteConfiguration,
    null,
    cmsBaseUri,
    data.responsiveMedia
  );
  const teaserTargets = extractProperty(data, 'teaserTargets', []);

  const allCustomsCTA = teaserTargets.filter(
    (t) => t?.target.viewtype === 'customs-cta'
  );

  const allCTAButtons = teaserTargets.filter(
    (t) => t?.target.viewtype === 'cta-button'
  );

  const customContentData = teaserTargets.filter(
    (t) => t?.target.viewtype === 'custom-content'
  );

  const smallBackgroundVideo = getVideoObject(data.videos, cmsBaseUri);

  return {
    component: 'customs/CustomShoesHpStatic',
    props: {
      richText: decorateHtmlValue(
        data.teaserRichTextObject,
        cmsBaseUri,
        context,
        siteConfiguration
      ),
      richTextColor: getTeaserStyle(data, 'teaserTextColor') || '#000000',
      richTextBackgroundColor:
        getTeaserStyle(data, 'teaserTextBackgroundColor') || 'transparent',
      customCTAS: allCustomsCTA.map((singleCTA) => {
        const targetProperty = extractProperty(singleCTA, 'target');
        return {
          dataObject: getDynamicField(targetProperty, 'dataObject'),
          experience: getDynamicField(targetProperty, 'experience'),
          experienceType: getDynamicField(targetProperty, 'experienceType'),
          text:
            extractProperty(singleCTA, 'target.teaserTitle') ||
            extractProperty(singleCTA, 'callToActionText'),
          underline: getDynamicField(targetProperty, 'underline'),
          icon: getDynamicField(targetProperty, 'icon'),
          style: getDynamicField(targetProperty, 'style'),
        };
      }),
      buttonsCTA: allCTAButtons.map((singleBtn) => {
        return {
          linkToScroll:
            singleBtn?.target &&
            generateLinkFromTeaserTargets(
              singleBtn?.target.teaserTargets,
              context,
              siteConfiguration,
              singleBtn?.target.urlParams,
              cmsBaseUri,
              singleBtn?.target.scrollToComponent
            ),
          text:
            extractProperty(singleBtn, 'target.teaserTitle') ||
            extractProperty(singleBtn, 'callToActionText'),
          icon: getDynamicField(extractProperty(singleBtn, 'target'), 'icon'),
          scrollToButtonId: extractProperty(singleBtn, 'target.id'),
        };
      }),
      customContentInfo: customContentData.map(
        (content) => customContent(content.target).props
      ),
      textBelowCtaButton: getDynamicField(data, 'textBelowCtaButtons'),
      // Set default #FFFFFF for Vans CA. Todo: Remove default color later
      textBelowCtaButtonsColor: getDynamicField(
        data,
        'textBelowCtaButtonsColor',
        '#FFFFFF'
      ),
      clickHereText: clickHereButton.title || clickHereButton.teaserTitle,
      secondDataObject: getDynamicField(clickHereButton, 'dataObject'),
      secondExperience: getDynamicField(clickHereButton, 'experience'),
      // Set default true for Vans CA. Todo: Change to false later
      fullHeightSmall: getDynamicField(data, 'fullHeightSmall', true),
      secondExperienceType:
        extractProperty(
          clickHereButton,
          'localSettings.dynamicFields.experienceType'
        ) || undefined,
      bottomInspirationLink: {
        text: bottomLink?.title || bottomLink?.teaserTitle || '',
        link: generateLinkFromTeaserTargets(
          bottomLink?.teaserTargets,
          context,
          siteConfiguration,
          bottomLink?.urlParams,
          cmsBaseUri,
          bottomLink?.scrollToComponent
        ),
      },
      logo: {
        src: getLogoImage(logoConfig, siteConfiguration, cmsBaseUri),
        alt: extractProperty(logoConfig[0], 'title', 'Customs Logo'),
        height: null,
        width: null,
      },
      smallBackgroundVideo,
      media: {
        small: getImage.small,
        medium: getImage.medium,
        large: getImage.large,
      },
    },
  };
};
