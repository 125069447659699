import {
  CmsSanitizationRequirement,
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  CmsSanitizationRequirementStorage,
  ComposableContext,
} from '../types';
import useArticles from '../../useArticles';
import useProduct from '../../useProduct';
import useCms from '../../useCms';

export const requirementsResolver = async (
  context: ComposableContext,
  requirements: CmsSanitizationRequirements,
  whatToResolve: CmsSanitizationRequirementName[]
): Promise<boolean> => {
  /** Go through requested requirements */
  const reducedRequirements = reduceRequirements(requirements);

  for (const requirementName of whatToResolve) {
    /** Just to be sure that no one added not handled requirement */
    if (resolvers[requirementName]) {
      /** Get requirement data payload from the array */
      const requirementData = reducedRequirements[requirementName];

      /** Execute resolver only when requirement is there */
      if (requirementData) {
        const resolved = await resolvers[requirementName](
          context,
          requirementData
        );

        /** If redirection is executed - stop resolving rest of requirements */
        if (
          resolved &&
          requirementName === CmsSanitizationRequirementName.REDIRECT
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const reduceRequirements = (requirements: CmsSanitizationRequirements) => {
  return requirements.reduce((result, item) => {
    /** For product context it merges all the skus into a single array without duplicates */
    if (
      item.name === CmsSanitizationRequirementName.PRODUCT_CONTEXT &&
      result[CmsSanitizationRequirementName.PRODUCT_CONTEXT]
    ) {
      item.productSkus = [
        ...new Set([
          ...result[CmsSanitizationRequirementName.PRODUCT_CONTEXT].productSkus,
          ...item.productSkus,
        ]),
      ];
    }
    /** For the other requirements creates an object with key the name and in the process eliminates duplicates */
    return {
      ...result,
      [item.name]: item,
    };
  }, {});
};

/**
 * Resolver functions, it needs to implement all functions for possible requirement objects
 */
const resolvers: {
  [key in CmsSanitizationRequirementName]: (
    context: ComposableContext,
    requirementData: CmsSanitizationRequirement
  ) => Promise<boolean>;
} = {
  [CmsSanitizationRequirementName.PRODUCT_CONTEXT]: async (
    context,
    requirementData: CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.PRODUCT_CONTEXT]
  ) => {
    const { getProductsData } = useProduct(
      context.instance,
      context.contextKey
    );

    try {
      await getProductsData(requirementData.productSkus);
    } catch (e) {
      console.error(e);
    }

    return true;
  },

  [CmsSanitizationRequirementName.REDIRECT]: async (
    context,
    requirementData: CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.REDIRECT]
  ) => {
    // Remove query from the URL
    const toUrl: string = requirementData.to.split('?')[0];
    const router = context.instance.$router;
    const { permanent, redirectBack, notification } =
      requirementData.options ?? {};

    const query = {
      ...(redirectBack && { redirectTo: router.currentRoute.fullPath }),
      ...(notification && { redirectNotification: notification }),
    };

    if (process.client) {
      if (
        router.currentRoute?.path.toLowerCase() !==
        decodeURIComponent(toUrl).toLowerCase()
      ) {
        router.push({
          path: requirementData.to,
          query,
        });

        return true;
      }
      return false;
    } else {
      if (
        context.instance.context.route?.path.toLowerCase() !==
        decodeURIComponent(toUrl).toLowerCase()
      ) {
        context.instance.context.redirect(
          permanent ? 301 : 302,
          requirementData.to,
          {
            ...query,
            _sr: '1',
          }
        );
        return true;
      }
      return false;
    }
  },

  [CmsSanitizationRequirementName.ARTICLES]: async (
    context,
    requirementData: CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.ARTICLES]
  ) => {
    const { articles, getArticlesResults, selectPrimaryFilters } = useArticles(
      context.instance,
      context.contextKey
    );
    if (requirementData.filters?.length) {
      selectPrimaryFilters(requirementData.filters);
    }
    await getArticlesResults();

    if (articles.value.length === 1) {
      const router = context.instance.$router;
      router.push({
        path: articles.value[0].link,
      });
    }
    return true;
  },

  [CmsSanitizationRequirementName.SORTED_ARTICLES]: async (
    context,
    requirementData: CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.SORTED_ARTICLES]
  ) => {
    const {
      getArticlesResults,
      changeSort,
      selectPrimaryFilters,
      setQueryString,
    } = useArticles(context.instance, context.contextKey);
    if (requirementData.searchTerm) {
      setQueryString(requirementData.searchTerm);
    }
    if (requirementData.order) {
      changeSort(requirementData.order);
    }
    if (requirementData.filters?.length) {
      selectPrimaryFilters(requirementData.filters);
    }
    await getArticlesResults();
    return true;
  },

  [CmsSanitizationRequirementName.FACET_CONFIGURATION]: async (
    context,
    requirementData: CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.FACET_CONFIGURATION]
  ) => {
    const { setFacetConfiguration } = useCms(
      context.instance,
      context.contextKey
    );
    setFacetConfiguration(requirementData.facetConfiguration);
    return true;
  },
};
