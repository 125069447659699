import { CmsSite, CMTeaser } from '@vf/api-contract';
import {
  extractProperty,
  generateLinkFromTeaserTargets,
  getDynamicField,
  getTranslation,
  getTitle,
} from './utils';
import { ComposableContext } from '../types';

export const cartEmpty = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  return {
    component: 'cart/EmptyCartInfo',
    props: {
      showZeroItemsText: getDynamicField(data, 'show-zero-items-text', false),
      buttonUrl: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams
      ),
      headingLevel: parseInt(
        extractProperty(
          data,
          'teaserStyleCustomizations.teaserTitleHeadingLevel',
          5
        )
      ),
      showCtaButton: extractProperty(
        data,
        'teaserTargets[0].callToActionEnabled',
        false
      ),
      translations: {
        buttonText: extractProperty(data, 'teaserTargets[0].callToActionText'),
        heading: getTitle(data) || '$There are no items in your cart$',
        text: extractProperty(data, 'teaserText', ''),
        zeroItemsText: getTranslation(
          data,
          'zeroItemsText',
          '$Zero items text$'
        ),
        shareCartExpiredHeading: getTranslation(
          data,
          'shareCartExpiredHeading',
          '$Bummer, the link you clicked has expired$'
        ),
        shareCartExpiredButtonText: getTranslation(
          data,
          'shareCartExpiredButtonText',
          '$Return Home$'
        ),
        noAddedItemsError: getTranslation(
          data,
          'noAddedItemsError',
          '$Sorry, but we updated your order totals based on the following reason(s)$'
        ),
        maxQuantityErrorMessage: getTranslation(
          data,
          'maxQuantity',
          '$Sorry, but we updated your order totals based on the following reason(s)$'
        ),
        removingReasons: {
          WILL_EXCEED_MAX_ALLOWED_QTY: getTranslation(
            data,
            'willExceedMaxAllowedQtyMessage',
            '$You have exceeded the maximum quantity allowed for this product. The total in your cart has been updated.$'
          ),
          NO_LONGER_AVAILABLE: getTranslation(
            data,
            'noLongerAvailableMessage',
            '$This item is no longer available$'
          ),
          NOT_ENOUGH_INVENTORY: getTranslation(
            data,
            'notEnoughInventoryMessage',
            '$The full quantity of items is no longer available.$'
          ),
        },
      },
    },
  };
};
