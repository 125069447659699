import { CmsFooter, CmsHeader, CmsMenu, CmsSite } from '@vf/api-contract';
import { ComposableContext, CmsHeaderConfig } from '../../../types';
import * as mappings from '../../../mappings';

export const headerAndFooter = (
  siteConfiguration: CmsSite,
  context: ComposableContext,
  cmsBaseUri: string,
  headerConfig: CmsHeaderConfig
) => {
  return (
    item: CmsHeader | CmsFooter,
    viewType: 'header' | 'footer',
    menu?: CmsMenu
  ) => {
    return mappings[viewType]
      ? mappings[viewType](
          item as any,
          context,
          siteConfiguration,
          menu,
          cmsBaseUri,
          headerConfig
        )
      : null;
  };
};
