import { GiftOption, OrderProductsSummaryTranslations } from '@vf/api-contract';
import { CartLineItem } from '@vf/api-client';
import { ComponentInstance } from '@vf/composables';

type ItemOptionItems = Pick<CartLineItem, 'optionItems'>;

export type ItemGiftOptions = Pick<
  CartLineItem,
  | 'qty'
  | 'price'
  | 'optionItems'
  | 'gift'
  | 'isGiftBoxAvailable'
  | 'isGiftBoxSelected'
  | 'giftOption'
>;

interface GiftOptionBindings
  extends Pick<
    CartLineItem,
    'gift' | 'isGiftBoxAvailable' | 'isGiftBoxSelected' | 'giftOption'
  > {
  giftBoxPrice: string;
}

type OrderDetailsGiftOptionsTranslations = Pick<
  OrderProductsSummaryTranslations,
  'giftOptionsEmpty' | 'giftOptionsWithGiftBox' | 'giftOptionsWithoutGiftBox'
>;

const getEmptyGiftOption = (): GiftOption => ({
  to: '',
  from: '',
  message: '',
});

/**
 *
 * @returns Returns giftBoxPrice from product item
 */
export const getItemGiftBoxPrice = (item: ItemOptionItems): number => {
  const option = item.optionItems?.find(
    (el) => el.optionId === 'giftBox' && el.optionValueId === 'withGiftBox'
  );
  return option ? option.price : 0;
};

export const getGiftOptionItem = (
  item: CartLineItem,
  data: (GiftOption & { isGiftBoxSelected: boolean }) | false
): CartLineItem => {
  const product: CartLineItem = {
    ...item,
    itemId: item.id, // To Do can be moved to cart composable
  };
  if (!data || !data.to || !data.from) {
    return {
      ...product,
      gift: false,
      isGiftBoxSelected: item.isGiftBoxAvailable ? false : undefined,
      giftOption: getEmptyGiftOption(),
    };
  }
  const { isGiftBoxSelected, ...giftOption } = data;
  return {
    ...product,
    gift: true,
    isGiftBoxSelected: item.isGiftBoxAvailable ? isGiftBoxSelected : undefined,
    giftOption,
  };
};

export const getGiftOptionBindings = (
  item: ItemGiftOptions,
  instance: ComponentInstance
): GiftOptionBindings => {
  const { gift, giftOption, isGiftBoxAvailable, isGiftBoxSelected } = item;

  const giftBoxPrice = instance.$formatPrice(
    getItemGiftBoxPrice(item),
    item.price?.currency
  );

  return {
    gift,
    giftBoxPrice,
    isGiftBoxAvailable,
    isGiftBoxSelected,
    giftOption,
  };
};

export const getGiftOptionOrderLabelText = (
  item: ItemGiftOptions,
  instance: ComponentInstance,
  translations: OrderDetailsGiftOptionsTranslations
): string => {
  const { gift, isGiftBoxSelected, qty } = item;

  const giftBoxPrice = instance.$formatPrice(
    getItemGiftBoxPrice(item),
    item.price?.currency
  );

  let giftOptionLabel = translations.giftOptionsEmpty;
  if (gift) {
    giftOptionLabel = isGiftBoxSelected
      ? translations.giftOptionsWithGiftBox
      : translations.giftOptionsWithoutGiftBox;

    giftOptionLabel = `${giftOptionLabel}`
      .replace('{{quantity}}', `${qty}`)
      .replace('{{price}}', giftBoxPrice);
  }
  return giftOptionLabel;
};
