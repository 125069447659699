import {
  axios,
  headerBuilder,
  urlBuilder,
  getCacheWrapperConfig,
  getClientInstance,
} from '../../helpers';
import { endpoints } from '../../config';
import { GetListingResultsResponse } from '../../api-types';
import { AxiosResponse } from 'axios';
import wrapper from 'axios-cache-plugin';
import type { ApiClientConfiguration } from '../../configuration';

const catalogWrapperConfig = getCacheWrapperConfig('catalog');
const http = wrapper(axios, catalogWrapperConfig);

http.__addFilter(/catalog/);

export default http;

export const getCatalog = (configuration: ApiClientConfiguration) => async (
  query: string
): Promise<AxiosResponse<GetListingResultsResponse>> => {
  let url = `${endpoints.get(configuration).catalog.getCatalog}`;
  if (query && query !== '') url = `${url}?${query}`;
  const { logger } = configuration;
  logger.debug('Get Catalog', { url });

  const client = getClientInstance({
    instanceWithCache: http,
    preview: configuration.isPreview,
  });

  const response = await client.get<GetListingResultsResponse>(
    urlBuilder(url, { config: configuration }),
    {
      headers: headerBuilder(configuration),
    }
  );
  logger.debug('Get Catalog', { responseStatus: response.status });
  return response;
};
