export const createGradient = (
  colorHexCode: string | string[],
  separator = '|',
  direction = '45deg'
): string => {
  if (!colorHexCode) return '';
  const colors =
    typeof colorHexCode === 'string'
      ? colorHexCode.split(separator)
      : colorHexCode;
  const width = 100 / colors.length;
  const gradient = colors.reduce((acc, color, i) => {
    return (
      acc +
      ` #${color} ${Math.floor(i * width)}%, #${color} ${Math.floor(
        (i + 1) * width
      )}%,`
    );
  }, `linear-gradient(${direction},`);
  return gradient.slice(0, -1) + ')';
};
