export type ProductDetail = {
  name: string;
  value: string;
};

export const mapProductDetails = (
  items: string[],
  separator: string = '|'
): ProductDetail[] => {
  if (!items || items.length === 0) return [];
  return items
    .filter((item) => item.indexOf(separator) < item.length - 1)
    .map((item) => {
      const index = item.indexOf(separator);
      if (index === -1) return { name: '', value: item };
      return {
        name: item.substring(0, index),
        value: item.substr(index + 1),
      };
    });
};
