export const createCheckerboard = (
  colorHexCode: string | string[],
  separator = ';',
  size = '0.375rem'
): string => {
  if (!colorHexCode) return '';
  const [color1, color2 = 'FFFFFF'] =
    typeof colorHexCode === 'string'
      ? colorHexCode.split(separator)
      : colorHexCode;
  if (!color1) return '';
  return `repeating-conic-gradient(from 0deg, #${color2} 0% 25%, #${color1} 0% 50%) 50% / ${size} ${size}`;
};
