import type { Product } from '@vf/api-client';
import { apiClientFactory } from '@vf/api-client';
import { AddToFavoritesPayload, FavoriteProductObject } from '@vf/api-contract';
import { ComponentInstance, ComposablesStorage } from '../types';
import { useRequestTracker } from '../useRequestTracker';
import { useCustoms } from '../useCustoms';
import { useCart } from '../useCart';
import { errorMessages } from '../utils/errorMessages';
import ls from '../utils/localStorage';
import initStorage from '../utils/storage';
import { computed, ref, Ref } from '@nuxtjs/composition-api';
import { useFavoritesStore } from '../store/favorites';

type UseFavoritesStorage = {
  favoriteId: Ref<string>;
  favoritesCount: Ref<number>;
  favorites: Ref<FavoriteProductObject[]>;
  ownerFirstName: Ref<string>;
  sharedFavorites: Ref<FavoriteProductObject[]>;
  sharedOwnerFirstName: Ref<string>;
  sharedFavoritesCount: Ref<number>;
  sharedFavoriteLink: Ref<string>;
  isFavoritesTooltipOpen: Ref<boolean>;
  loading: Ref<boolean>;
  isPublicList: Ref<boolean>;
};

export const useFavorites = (instance: ComponentInstance) => {
  useFavoritesStore(instance);
  const {
    addToFavorites: addToFavoritesAPI,
    getFavorites: getFavoritesAPI,
    getSharedFavorites: getSharedFavoritesAPI,
    removeFromFavorites: removeFromFavoritesAPI,
    shareFavorites: shareFavoritesAPI,
  } = apiClientFactory(instance);
  const storage: ComposablesStorage<UseFavoritesStorage> = initStorage<UseFavoritesStorage>(
    instance,
    'useFavorites'
  );
  const isFavoritesTooltipOpen: Ref<boolean> =
    storage.get('isFavoritesTooltipOpen') ??
    storage.save('isFavoritesTooltipOpen', ref(false));

  const isPublicList: Ref<boolean> =
    storage.get('isPublicList') ?? storage.save('isPublicList', ref(true));

  const favorites: Ref<FavoriteProductObject[]> =
    storage.get('favorites') ?? storage.save('favorites', ref([]));

  const ownerFirstName: Ref<string> =
    storage.get('ownerFirstName') ?? storage.save('ownerFirstName', ref(''));

  const favoriteId: Ref<string> =
    storage.get('favoriteId') ??
    storage.save('favoriteId', ref(ls.getItem('favoriteId') || ''));

  const setFavoriteId = (value: string = '') => {
    favoriteId.value = value;
    ls.setItem('favoriteId', value);
  };

  const sharedFavorites: Ref<FavoriteProductObject[]> =
    storage.get('sharedFavorites') ?? storage.save('sharedFavorites', ref([]));

  const sharedOwnerFirstName: Ref<string> =
    storage.get('sharedOwnerFirstName') ??
    storage.save('sharedOwnerFirstName', ref(''));

  const sharedFavoriteLink: Ref<string> =
    storage.get('sharedFavoriteLink') ??
    storage.save('sharedFavoriteLink', ref(''));

  const favoritesCount: Ref<number> =
    storage.get('favoritesCount') ??
    storage.save(
      'favoritesCount',
      ref(parseInt(ls.getItem('favoritesCount')) || 0)
    );

  const sharedFavoritesCount: Ref<number> =
    storage.get('sharedFavoritesCount') ??
    storage.save('sharedFavoritesCount', ref(0));

  const loading: UseFavoritesStorage['loading'] =
    storage.get('loading') ?? storage.save('loading', ref(false));

  const setFavoritesCount = (value: number = 0) => {
    favoritesCount.value = value;
    ls.setItem('favoritesCount', value);
  };

  const { displayErrorMessages } = errorMessages(instance);
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { setImagesDeclined } = useCustoms(instance);
  const { addItem } = useCart(instance);

  const getFavorites = async (
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag, isAlreadyTracked } = trackRequest(
      'useFavorites-getFavorites',
      isBackgroundRequest
    );
    if (isAlreadyTracked) {
      return clearRequest(tag);
    }
    try {
      const response = await getFavoritesAPI();
      const addProductImageUrlIfNotExists = (favorite) => ({
        ...favorite,
        productImageUrl:
          favorite.productImageUrl ??
          instance.$root.$mediaUrlGenerator({
            colorName: favorite.colorDescription,
            pid: makeImageUrlIdentifierByProductId(favorite.id),
            productName: favorite.name,
          }),
      });
      favorites.value = (
        await setImagesDeclined(response.data?.items || [])
      ).map(addProductImageUrlIfNotExists);
      ownerFirstName.value = response.data?.ownerFirstName || '';
      isPublicList.value = response.data?.public || false;
      setFavoriteId(response.data?.favoriteId);
      setFavoritesCount(response.data?.count);
    } catch (e) {
      instance.$log.error(e);
      if (e.response?.status !== 400) {
        displayErrorMessages(e);
      }
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const addToFavorites = async (
    payload: AddToFavoritesPayload,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useFavorites-addToFavorites',
      isBackgroundRequest
    );
    try {
      loading.value = true;
      const response = await addToFavoritesAPI(payload);
      setFavoriteId(response.data?.favoriteId);
      await getFavorites();
      return true;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    } finally {
      loading.value = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const removeFromFavorites = async (
    productId: string,
    recipeId?: string,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useFavorites-removeFromFavorites',
      isBackgroundRequest
    );
    try {
      loading.value = true;
      const item = favorites.value.find(
        (element) =>
          element.id === productId &&
          (!recipeId || element.recipeId === recipeId)
      );
      if (item) {
        await removeFromFavoritesAPI(favoriteId.value, item?.itemId);
      }
      await getFavorites();
      return true;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    } finally {
      loading.value = false;
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const isFavorite = (id: string, recipeId?: string) => {
    return favorites.value.some(
      (item) => item.id === id && (!recipeId || item.recipeId === recipeId)
    );
  };

  const setShareLink = async () => {
    try {
      const result = await shareFavoritesAPI(favoriteId.value);
      sharedFavoriteLink.value = result.data.favoritesShareUrl;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const getSharedFavorites = async (
    sharedFavoriteId: string,
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    const { tag } = trackRequest(
      'useFavorites-getSharedFavorites',
      isBackgroundRequest
    );
    try {
      const response = await getSharedFavoritesAPI(sharedFavoriteId);
      sharedFavorites.value = await setImagesDeclined(
        response.data?.items || []
      );
      sharedOwnerFirstName.value = response.data?.ownerFirstName || '';
      sharedFavoritesCount.value = response.data?.count || 0;
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  let _setFavoritesTooltipTimerId = null;
  const showFavoritesTooltip = (closeDelay: number) => {
    isFavoritesTooltipOpen.value = true;
    clearTimeout(_setFavoritesTooltipTimerId);
    if (closeDelay) {
      _setFavoritesTooltipTimerId = setTimeout(() => {
        isFavoritesTooltipOpen.value = false;
      }, closeDelay);
    }
  };

  const moveFavoriteItemToCart = async (itemId: string) => {
    const favoriteItem = favorites.value.find(
      (element) => element.id === itemId
    );
    const cartItem = {
      ...favoriteItem,
      productId: favoriteId.value,
      pdpUrl: favoriteItem.pdpUrl || favoriteItem.pageUrl,
      sku: itemId,
      variant: {
        id: favoriteId.value,
      },
      isFavoriteItem: true,
    };

    try {
      const isAddedToCart = await addItem(cartItem);
      if (isAddedToCart) {
        await removeFromFavorites(itemId);
        return true;
      }
      return false;
    } catch (e) {
      displayErrorMessages(e);
      return false;
    }
  };

  const makeImageUrlIdentifierByProductId = (id: string) => {
    try {
      return id.split(':').slice(0, 3).join('');
    } catch {
      return '';
    }
  };

  const makeImageVariantUrlIdentifierByProduct = (product: Product) => {
    try {
      return makeImageUrlIdentifierByProductId(product.variant.id);
    } catch {
      return '';
    }
  };

  return {
    getFavorites,
    addToFavorites,
    removeFromFavorites,
    isFavorite,
    setFavoriteId,
    setFavoritesCount,
    favorites,
    favoriteId: computed(() => favoriteId.value),
    favoritesCount: computed(() => favoritesCount.value),
    ownerFirstName: computed(() => ownerFirstName.value),
    setShareLink,
    getSharedFavorites,
    sharedFavorites,
    sharedFavoritesCount: computed(() => sharedFavoritesCount.value),
    sharedOwnerFirstName: computed(() => sharedOwnerFirstName.value),
    sharedFavoriteLink: computed(() => sharedFavoriteLink.value),
    isFavoritesTooltipOpen,
    showFavoritesTooltip,
    loading: computed(() => loading.value),
    isPublicList: computed(() => isPublicList.value),
    moveFavoriteItemToCart,
    makeImageVariantUrlIdentifierByProduct,
    makeImageUrlIdentifierByProductId,
  };
};
