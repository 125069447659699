import Vue from 'vue';

import {
  getFocusableChildren,
  setFocusFirstElement,
  moveFocus,
} from './focus-trap';
import { Element, Target } from './../types';
import { DirectiveBinding } from 'vue/types/options';

let intervalId = null;
// iterate 15 times at 200ms = 3 seconds
const interationNum = 15;
const iterationMs = 200;

export const focusTrap = {
  bind(el: Element, binding?: DirectiveBinding): void {
    el._lastFocusedElement = (document.activeElement as unknown) as Target;

    let iteration = 1;
    intervalId = setInterval(() => {
      el._focusableChildrenElements = getFocusableChildren(el);
      if (el._focusableChildrenElements?.length > 0) {
        setFocusFirstElement(el._focusableChildrenElements, binding?.value);
        clearInterval(intervalId);
      }
      if (iteration === interationNum) clearInterval(intervalId);
      iteration++;
    }, iterationMs);

    el._keyHandler = (e: KeyboardEvent) => {
      // if model ever changes this will aways get the childen elements
      if (e.key === 'Tab') {
        el._focusableChildrenElements = getFocusableChildren(el);
      }
      moveFocus(e, el._focusableChildrenElements, binding?.value);
    };

    document.addEventListener('keydown', el._keyHandler);
  },
  componentUpdated(el: Element): void {
    Vue.nextTick(() => {
      el._focusableChildrenElements = getFocusableChildren(el);
    });
  },
  unbind(el: Element): void {
    if (intervalId) clearInterval(intervalId);
    if (el._lastFocusedElement) el._lastFocusedElement.focus();
    document.removeEventListener('keydown', el._keyHandler);
  },
};
