import { inject, provide } from '@vue/composition-api';

type PrefetchConfig = {
  getPrefetchConfig: () => {
    urls: string[];
    enabled?: boolean;
    intentPeriod?: number;
  };
  prefetchUrl: (string) => void;
};

type UsePrefetchLinkReturn = {
  handleIntent: (link: string | Record<string, unknown>) => void;
  clearIntent: () => void;
  providePrefetchConfig: (prefetchConfig: PrefetchConfig) => void;
};

export const PrefetchConfigKey = Symbol('PrefetchConfig');

const providePrefetchConfig = (prefetchConfig: PrefetchConfig): void => {
  provide(PrefetchConfigKey, prefetchConfig);
};

function usePrefetch(): UsePrefetchLinkReturn {
  const { getPrefetchConfig, prefetchUrl } = inject<PrefetchConfig>(
    PrefetchConfigKey,
    {
      getPrefetchConfig: undefined,
      prefetchUrl: undefined,
    }
  );

  let intent = null;

  const handleIntent = (link: string) => {
    if (getPrefetchConfig && prefetchUrl) {
      const prefetchConfig = getPrefetchConfig();

      const prefetch =
        prefetchConfig.enabled &&
        prefetchConfig.urls.some((url) => link.includes(url));

      if (!intent && prefetch) {
        intent = setTimeout(
          () => prefetchUrl(link),
          prefetchConfig.intentPeriod
        );
      }
    }
  };

  const clearIntent = () => {
    if (intent) {
      clearTimeout(intent);
      intent = null;
    }
  };

  return {
    handleIntent,
    clearIntent,
    providePrefetchConfig,
  };
}

export default usePrefetch;
