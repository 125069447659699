import { CMCollection } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const signInConfirmationModal = (data: CMCollection) => {
  return {
    component: 'confirmation/SignInConfirmation',
    attrs: {
      'data-id': 'dataId',
    },
    props: {
      translations: {
        title: getTranslation(
          data,
          'title',
          '$ADD THIS ORDER TO YOUR ACCOUNT$'
        ),
        passwordPlaceholder: getTranslation(
          data,
          'passwordPlaceholder',
          '$Enter password$'
        ),
        email: getTranslation(data, 'email', '$ACCOUNT EMAIL$'),
        password: getTranslation(data, 'password', '$PASSWORD$'),
        errorMessage: getTranslation(
          data,
          'errorMessage',
          '$This field is required, please add your {{fieldName}}.$'
        ),
        forgotPassword: getTranslation(
          data,
          'forgotPassword',
          '$Forgot password$'
        ),
        submit: getTranslation(data, 'submit', '$SUBMIT$'),
        resetPassword: getTranslation(
          data,
          'resetPassword',
          '$RESET PASSWORD$'
        ),
        close: getTranslation(data, 'close', '$CLOSE$'),
        passwordResetNotification: getTranslation(
          data,
          'passwordResetNotification',
          '$A password reset email has been sent to the email address on file.$'
        ),
      },
    },
  };
};
