import {
  CartLineItem,
  CategoryProduct,
  Product,
  CartShippingGroup,
} from '@vf/api-client/src/types';
import { Price } from '@vf/api-contract/src/entities/price';

import {
  GtmCartProductObject,
  GtmImpressionProductObject,
} from '../../../types/gtm';
import { PageTypeName as PageEnum } from '../../../useCms/types';
import { PdoSearch, SearchType } from '../../../useSearch/types';
import { EventPersistentVariables } from '../index';
import type { ShippingPromotion } from '@vf/api-contract/src/entities';
import type { OrderPromotion } from '@vf/api-client';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { isSTS, isPickupOrSts } from '@vf/shared/src/utils/helpers';

const getCouponFields = (productPromotions: OrderPromotion[]) => {
  if (!productPromotions) {
    return {
      onCoupon: false,
      coupon: undefined,
      couponType: undefined,
      couponDiscountAmount: 0,
    };
  }

  const couponType = productPromotions
    .map((promo) => (promo.couponCode ? 'CODE' : 'AUTO'))
    .join('|');

  return {
    coupon: productPromotions
      .map((promo) => promo.couponCode?.toUpperCase() || 'AUTO')
      .join('|'),
    onCoupon: !!productPromotions.length,
    couponDiscountAmount: productPromotions.reduce(
      (acc, { price }) => acc + Math.abs(price),
      0
    ),
    couponType,
    productPromoCampaignId: productPromotions
      .map(({ campaignId }) => campaignId)
      .filter(Boolean)
      .join('|'),
  };
};

const getShippingTrack = (
  product: CartLineItem
):
  | {
      shippingMethod: GtmCartProductObject['shippingMethod'];
      shippingWindow: string;
      shippingStoreId?: string;
    }
  | undefined => {
  const shipping = product.shippingOptions?.find(({ selected }) => selected);
  if (!shipping) return;
  const shippingGTM = {
    shippingStoreId: undefined,
    shippingWindow: shipping.shippingMethod.deliveryTime,
  };
  if (isPickupOrSts(shipping.shippingMethod.code))
    return {
      ...shippingGTM,
      shippingMethod: isSTS(shipping.shippingMethod.code)
        ? 'BOPIS_STS'
        : 'BOPIS_SD',
      shippingStoreId: shipping.storeInfo.id,
    };
  return {
    ...shippingGTM,
    shippingMethod: 'STH',
  };
};

export const cartLineItemToProductObject = (
  product: CartLineItem,
  persistentVariables?: EventPersistentVariables
): Omit<GtmCartProductObject, 'brand' | 'quantity'> => {
  const colorSelected = product.variants.find((attr) => attr.code === 'color');
  const gtmCartObject: Omit<GtmCartProductObject, 'brand' | 'quantity'> = {
    availSizes: [], // Overridden in ProductAddToCart component.
    avgRating: null, // Overridden in ProductAddToCart component.
    bundleId: undefined,
    colorCode: product.colorCode || colorSelected.id,
    colorDescription: product.colorDescription || colorSelected.value,
    customized: !!product.recipeId,
    discountType: getDiscountTypes(product) || undefined,
    id: product.precreatedCustomsCode || product.masterId,
    masterId: product.masterId2,
    merchantProductId: product.merchantProductId,
    name: product.name.trim(),
    onSale: isOnSale(product),
    originalPrice: product.price.original,
    preCreatedCustomCode: product.precreatedCustomsCode || undefined,
    price: product.price.proratedPrice,
    recipeID: product.recipeId || undefined,
    saleDiscountAmount: parseFloat(
      (product.price.original - product.price.current).toFixed(2)
    ),
    season: product.season || undefined,
    sizeCode1: product.variants.find((attr) => attr.code === 'size').value,
    styleCode: '', // Overridden in ProductAddToCart component.
    /* Defaults to 0 in case of empty value from BE */
    taxTotal: product.taxTotal || 0,
    variant: product.variant || undefined,
    vf_itemID: product.productId,
    virtualStyleCode: getVirtualStyleCode(product.colorBundleCode),
    lowOnStock: product.maxQty < 10,
    ...getShippingTrack(product),
    ...getCouponFields(product.productPromotions),
    ...persistentVariables,
  };
  let sizeCount = 2;
  product.variants.forEach((attr) => {
    if (!['color', 'size'].includes(attr.code)) {
      gtmCartObject[`sizeCode${sizeCount++}`] = attr.value;
    }
  });
  return gtmCartObject;
};

type ProductType = Product | CategoryProduct | CartLineItem;

export const isPreCreatedCustom = (product: ProductType) =>
  product.dummyCustoms;

export const recipeID = (product: ProductType) => {
  return product.recipeId ?? (product as CategoryProduct).customsRecipeID;
};

// ToDo redundand of getCouponDiscount function
export const getCouponDiscountAmount = (product) => {
  const amount = product.productPromotions?.find((promo) => !!promo.couponCode)
    ?.price;
  return amount ? Math.abs(amount) : 0;
};

export const getCustomsCategory = (
  product: ProductType,
  persistentCategory
) => {
  if (!recipeID(product)) {
    return persistentCategory || undefined;
  }
  return isPreCreatedCustom(product) ? 'Customs Pre-created' : 'Customs';
};

export const getDiscountTypes = (product: CartLineItem): string => {
  const discountTypes = [];
  const price = product.price;
  const promos = product.productPromotions || [];
  price.amount === 0 && discountTypes.push('Free Gift');
  price.amount < price.original && discountTypes.push('Marked Down');
  promos.some((promo) => promo.couponCode) && discountTypes.push('Coupon');
  return discountTypes.join(',');
};

export const getDiscountTypesByPrice = (product: {
  price: Price;
}): string | undefined => {
  const discountTypes = [];
  const price = product.price;
  price.current === 0 && discountTypes.push('Free Gift');
  price.current < price.original && discountTypes.push('Marked Down');
  return discountTypes.length ? discountTypes.join(',') : undefined;
};

export const capitalize = (phrase: string): string => {
  if (typeof phrase !== 'string') return '';
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const extractGtmPageName = (
  pageTypeName = '',
  breadcrumbs?: string,
  id?: string,
  searchType?: string
): string => {
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  const url = new URL(window.location.href);
  const lastUrlPiece = url.pathname.split('/').pop();
  const query = url.search;

  switch (pageTypeName) {
    case PageEnum.CART:
    case PageEnum.HOME:
    case PageEnum.FAVORITES:
    case PageEnum.ORDER_CONFIRMATION:
    case PageEnum.STORE_LOCATOR:
    case PageEnum.ERROR_NOT_FOUND:
      return pageTypeName;
    case PageEnum.NEWS_ARTICLE:
    case PageEnum.FAQ:
    case PageEnum.EVENT:
      return PageEnum.CONTENT;
    case PageEnum.MY_ACCOUNT:
    case PageEnum.CONTENT:
    case PageEnum.HELP:
    case PageEnum.SUPPORT:
    case PageEnum.CHECKOUT:
      if (isCheckoutRedesignEnabled) return pageTypeName;
      return `${pageTypeName}: ${capitalize(
        lastUrlPiece.replaceAll('-', ' ')
      )}`;
    case PageEnum.CUSTOMS: {
      let customsName = 'Main Page';
      if (query && query.includes('customize')) {
        customsName = 'Style Options';
      }
      if (lastUrlPiece.search('customizer') === 0) {
        customsName = 'Product Customization';
      }
      return `${pageTypeName}: ${customsName}`;
    }
    case PageEnum.GIFT_CARD:
      return `${pageTypeName}: ${
        lastUrlPiece.split('-').pop().toLowerCase() === 'balance'
          ? 'Check Balance'
          : 'Main Page'
      }`;
    case PageEnum.PDP:
      return `${pageTypeName}: ${id || ''}`;
    case PageEnum.PLP:
      return `${pageTypeName}: ${breadcrumbs || ''}`;
    case PageEnum.COMPANY:
      return `${pageTypeName}: ${
        lastUrlPiece.includes('privacy')
          ? 'Privacy Policy'
          : capitalize(lastUrlPiece.replaceAll('-', ' - '))
      }`;
    case PageEnum.SEARCH:
      return `Search: ${searchType}`;
    default:
      return '';
  }
};

const getFinalSearchType = (
  pageTypeName: string,
  pdoSearchType: string,
  queryString: string,
  autoCorrectQuery: string,
  isRedirected: boolean,
  searchResults: number
): string => {
  if (pageTypeName !== PageEnum.SEARCH && pageTypeName !== PageEnum.PDP) return;
  if (queryString && !searchResults) return SearchType.NO_RESULTS;
  if (autoCorrectQuery != '') return SearchType.AUTO_CORRECTION;
  if (isRedirected) return SearchType.REDIRECTED;
  return pdoSearchType ?? SearchType.STANDARD;
};

const getSearchResultCount = (
  pdoSearchResults: number,
  isRedirected: boolean,
  paginationTotal: number
): number => {
  if (isRedirected) return 1;
  if (pdoSearchResults !== -1) return pdoSearchResults;
  return paginationTotal;
};

const isOnSale = (product) => {
  return (
    product.price.original !== product.price.current ||
    !!product.productPromotions?.find((promo) => !!promo.couponCode)
  );
};

export const getFinalPdoSearch = (
  pdoSearch: PdoSearch,
  pageTypeName: string,
  queryString: string,
  autoCorrectQuery: string,
  isRedirected: boolean,
  paginationTotal: number
): PdoSearch => {
  const searchTerm = pdoSearch.searchTerm ?? queryString ?? undefined;
  const searchTermAdj =
    pdoSearch.searchTermAdj ??
    (autoCorrectQuery !== '' ? autoCorrectQuery : undefined);
  const searchType = getFinalSearchType(
    pageTypeName,
    pdoSearch.searchType,
    queryString,
    autoCorrectQuery,
    isRedirected,
    paginationTotal
  );
  const searchResults = getSearchResultCount(
    pdoSearch.searchResults,
    isRedirected,
    paginationTotal
  );
  return {
    searchTerm,
    searchTermAdj,
    searchType,
    searchResults,
  };
};

export const getShippingPromoAmount = (shippingPromos: ShippingPromotion[]) => {
  return shippingPromos.reduce(
    (amount, promo) =>
      promo.appliedDiscount?.amount ? amount + Math.abs(promo.price) : amount,
    0
  );
};
export const getOrderPromoActionFields = (
  orderPromotions?: OrderPromotion[]
) => {
  let totalPrice = 0;
  const promoCodes = [];
  const promoTypes = [];
  const promoCampaigns = [];

  orderPromotions?.forEach((promo) => {
    totalPrice += Math.abs(promo.price);
    promoCodes.push(promo.couponCode || promo.itemText);
    promoTypes.push(promo.couponCode ? 'CODE' : 'AUTO');
    if (promo.campaignId) {
      promoCampaigns.push(promo.campaignId);
    }
  });

  return {
    orderPromoAmount: totalPrice,
    orderPromoCode: promoCodes.length ? promoCodes.join('|') : undefined,
    orderPromoType: promoTypes.length ? promoTypes.join('|') : undefined,
    orderPromoCampaignId: promoCampaigns.length
      ? promoCampaigns.join('|')
      : undefined,
  };
};

export const getShippingActionFields = (
  shippingMethods?: CartShippingGroup[]
) => ({
  shippingMethod: shippingMethods?.[0]?.label,
  shippingPromoCode: shippingMethods?.[0]?.shippingPromotions?.find(
    (promo) => !!promo.couponCode
  )?.couponCode,
  shippingPromoType: shippingMethods?.[0]?.shippingPromotions?.find(
    (promo) => !!promo.couponCode
  )
    ? 'CODE'
    : 'AUTO',
  shippingPromoAmount: getShippingPromoAmount(
    shippingMethods?.[0]?.shippingPromotions || []
  ),
});

export const santizeEmailAddresses = (content: string): string => {
  if (!content) return;
  const emailRegExp = /([a-zA-Z0-9._-]+(?:@|\%40)[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
  const replacement = '[REDACTED EMAIL]';
  const replaced = decodeURIComponent(content).replace(
    emailRegExp,
    replacement
  );
  if (content !== decodeURIComponent(content))
    return encodeURIComponent(replaced);
  return replaced;
};

export const updateItemInProductImpressionsObject = (
  productId: string,
  storedProduct: GtmImpressionProductObject,
  filteredProductPosition: number,
  productImpressionsData: GtmImpressionProductObject[]
) => {
  if (storedProduct.position !== filteredProductPosition) {
    const storedProductIndex = productImpressionsData.findIndex(
      (prod) => prod.id === productId
    );
    productImpressionsData.splice(storedProductIndex, 1);
    storedProduct.position = filteredProductPosition;
    productImpressionsData.push(storedProduct);
  }
  return productImpressionsData;
};

export const getColorInfoOrDefault = (
  product: CategoryProduct
): { colorCode: string; colorDescription: string } => {
  const res = {
    colorCode: product.colorCode,
    colorDescription: product.colorDescription,
  };
  if (
    (!product.colorCode || !product.colorDescription) &&
    product.color_swatches?.length
  ) {
    const defaultColor =
      product.color_swatches.find((swatch) => swatch.defaultColor) ||
      product.color_swatches[0];
    if (defaultColor) {
      res.colorCode = defaultColor.value;
      res.colorDescription = defaultColor.name;
    }
  }
  return res;
};

export const getStyleCode = (
  product: CategoryProduct,
  $themeConfig: any = {}
): string | undefined => {
  if ($themeConfig.gtm?.getStyleCode) {
    return $themeConfig.gtm.getStyleCode(product);
  }
  return product.styleCode;
};

export const getStyleCodePDP = (
  product: Product,
  $themeConfig: any = {}
): string | undefined => {
  if ($themeConfig.gtm?.getStyleCodePDP) {
    return $themeConfig.gtm.getStyleCodePDP(product);
  }
  return product.styleCode;
};

export const getVirtualStyleCode = (colorBundleCode?: string) =>
  colorBundleCode || undefined;

export const getAddToCartOverrideAttributes = (
  product: Product,
  $themeConfig: any
) => ({
  productId: product.id,
  quantity: product.quantity.value,
  availSizes: product.sizes
    .filter(
      (size) =>
        size.available &&
        product.variants.find(
          (variant) =>
            variant.attributes.color === product.color?.value &&
            variant.attributes.size === size.value
        )
    )
    .map((size) => size.value),
  availZips: product.zips
    .filter((zip) => zip.available)
    .map((zip) => zip.value),
  availWidths: product.widths
    .filter((width) => width.available)
    .map((width) => width.value),
  avgRating: product.rating?.score,
  badge: product.labels[0],
  styleCode: getStyleCodePDP(product, $themeConfig),
  virtualStyleCode: getVirtualStyleCode(product.colorBundleCode),
  numReviews: product.rating?.numReviews,
  customModel: product.customModel ?? '',
  customsTool: product.customsTool ?? '',
  isDummyCustom: product.dummyCustoms,
  customsRecipeID: product.customsRecipeID,
  catalogCategory: product.primaryCategoryName,
  colorCode: product.colorCode || product.color.value,
  colorDescription: product.colorDescription || product.color.label,
  sku: product.sku,
});

/**
 * Create product groups for impressions
 * @param ids - array of product id's
 * @param maxProductsPerImpression - max number of products per impression, default is 24.
 * @return array of productId groups
 *
 * @example
 * const ids = ['1', '2', '3', '4', '5', '6'];
 * createProductGroups(ids, 3);
 * returns [['1', '2', '3'], ['4', '5', '6']]
 */
export const createProductGroups = (
  ids: string[],
  maxProductsPerImpression: number = 24
): string[][] => {
  return ids.reduce((groups, id, index) => {
    if (index % maxProductsPerImpression === 0) groups.push([]);
    const products = groups[groups.length - 1];
    products.push(id);
    return groups;
  }, []);
};
