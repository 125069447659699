




import { defineComponent } from '@vue/composition-api';
import { useCms } from '@vf/composables';
import { Context } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  setup() {
    const { root } = useRootInstance();

    const { pageLoadPercentage } = useCms(root, Context.PageContent);

    return {
      pageLoadPercentage,
    };
  },
});
