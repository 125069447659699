import { Ref } from '@vue/composition-api';

type ApplePayLineItemType = 'final' | 'pending';

export type ApplePayLineItem = {
  label: string;
  amount: string;
  type?: ApplePayLineItemType | undefined;
};

export type paymentRequestType = {
  countryCode?: string;
  currencyCode?: string;
  shippingContactEditingMode?: string;
  lineItems?: ApplePayLineItem[];
  merchantCapabilities?: string[];
  supportedNetworks?: string[];
  requiredBillingContactFields?: string[];
  requiredShippingContactFields?: string[];
  total?: ApplePayLineItem;
  shippingContact?: ShippingContact;
  shippingMethods?: ApplePayShippingMethod[];
  shippingType?: string;
};

export type ShippingContact = {
  addressLines: string[];
  locality: string;
  administrativeArea: string;
  postalCode: string;
  countryCode: string;
  emailAddress: string;
  familyName: string;
  givenName: string;
  phoneNumber: string;
  shippingMethods?: ApplePayShippingMethod[];
  shippingType?: string;
};

export type ApplePayShippingMethod = {
  label: string;
  amount: string;
  detail: string;
  identifier: string;
};

export type useApplePayStorage = {
  paymentRequest: Ref<paymentRequestType>;
  postalCodeValidationRegex: Ref<string | null>;
};

export interface ApplePayPaymentContact {
  emailAddress?: string;
  familyName?: string;
  givenName?: string;
  phoneNumber?: string;
  phoneticFamilyName?: string;
  phoneticGivenName?: string;
  addressLines?: string[];
  locality?: string;
  subLocality?: string;
  administrativeArea?: string;
  subAdministrativeArea?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
}

export enum ApplePayContext {
  CHECKOUT = 'CHECKOUT',
  PDP = 'PDP',
  CART = 'CART',
}
