import type {
  Cart,
  CartLineItem,
  CartProductDiscount,
  CartCouponDiscount,
} from '@vf/api-client';
import type { AppliedPromotion } from '@vf/api-contract';

import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import { calculateAppliedPromotions } from './helpers/discountStore';

const getTotalBuyInStoreFixedPrices = (
  cartItems: CartLineItem[] = []
): number => {
  {
    const totalDiscount = cartItems.reduce((total, nextItem) => {
      nextItem.productPromotions?.forEach(({ appliedDiscount, price }) => {
        if (appliedDiscount?.type !== 'fixed_price') return;
        total = total + price;
      });
      return total;
    }, 0);
    return Math.abs(totalDiscount);
  }
};

export const useDiscountStore = defineStore('discount', () => {
  const productDiscounts = ref<CartProductDiscount[]>([]);
  const couponDiscounts = ref<CartCouponDiscount[]>([]);
  const totalBuyInStoreFixedPrices = ref(0);
  const giftCards = ref([]);
  const rewardCards = ref([]);
  const appliedFamilyVoucher = ref(null);
  const appliedPromotions = ref<AppliedPromotion[]>([]);

  return {
    productDiscounts,
    couponDiscounts,
    totalBuyInStoreFixedPrices,
    giftCards,
    rewardCards,
    appliedPromotions,
    appliedFamilyVoucher,

    setDiscounts: (data: Cart) => {
      // TODO: GLOBAL15-65058 move to paymentStore
      const { payment_instruments } = data;
      giftCards.value =
        payment_instruments?.filter(
          (item) => item.payment_method_id === PaymentMethodCode.GIFT_CARD
        ) || [];
      rewardCards.value =
        payment_instruments?.filter((item) =>
          [
            PaymentMethodCode.REWARD_CARD,
            PaymentMethodCode.REWARD_CODE,
          ].includes(item.payment_method_id)
        ) || [];

      // TODO: GLOBAL15-65058 replace with appliedPointRewards
      appliedFamilyVoucher.value = data.payment_instruments?.find(
        (payment) =>
          payment.payment_method_id === PaymentMethodCode.LOYALTY_POINTS
      );

      totalBuyInStoreFixedPrices.value = getTotalBuyInStoreFixedPrices(
        data.items
      );

      const promotions = calculateAppliedPromotions(data);
      productDiscounts.value = promotions.productDiscounts;
      couponDiscounts.value = promotions.couponDiscounts;
      appliedPromotions.value = promotions.appliedPromotions;
    },
  };
});
