import useArticles from '../../useArticles';
import { ComposableContext } from '../types';
import { getTranslation } from './utils/extractProperty';
import { CMPlaceholder } from 'packages/api-contract/src/cms';

export const searchArticlesSort = (
  data: CMPlaceholder,
  context: ComposableContext
) => {
  const { changeSort } = useArticles(context.instance, context.contextKey);

  const defaultSortOption = getDefaultSortingOption(data);

  changeSort(defaultSortOption);

  return {
    component: 'articleSearch/SearchArticleSortBy',
    props: {
      translations: {
        sortLabel: getTranslation(data, 'sortLabel', '$Sort by$'),
        sortByOptions: [
          {
            value: 'publicationDate',
            label: getTranslation(data, 'publicationDate', '$Date$'),
          },
          {
            value: 'relevance',
            label: getTranslation(data, 'relevance', '$Relevance'),
          },
        ],
      },
      selectedSort: defaultSortOption,
    },
  };
};

const getDefaultSortingOption = (data: CMPlaceholder) => {
  try {
    const sortConfig = data.componentConfig.find(
      (conf) => conf.name === 'plp-sort-by-properties'
    );

    return sortConfig.values['sort-by'];
  } catch (e) {
    console.warn(
      'Articles Sort by component warning: no default sorting option found, falling back to default.'
    );
  }

  return 'relevance';
};
