import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { headerBuilder, urlBuilder, axios } from '../../helpers';

export const getAvailability = (
  configuration: ApiClientConfiguration
) => async (
  productId: string,
  shops,
  params?: { sortByAvailability?: boolean; resultSetSize?: number }
): Promise<any> => {
  const config = configuration;
  const url = urlBuilder(endpoints.get(configuration).product.getAvailability, {
    config: configuration,
    mapping: { productId },
  });
  config.logger.info('api-client/getAvailability received url', {
    URL: url,
  });
  const response = await axios.post(url, shops, {
    headers: headerBuilder(configuration),
    params,
  });
  config.logger.info('api-client/getAvailability response status', {
    Status: response.status,
  });
  return response;
};
