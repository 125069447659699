// NOTE: optional fields in the interfaces below represent attributes that could not be implemented yet
export interface GtmProductObject {
  /**
   * A concatenation of the website Brand and Region separated by a dash (-)
   * @format Uppercase
   * @example 'VANS-US'
   * @source Environmental variables (API_SITE_ID by locale, transformed to uppercase)
   */
  brand: string;
  /**
   * The e-commerce Category to which the product belongs.
   * @format A concatenation of the PLP "levels" separated by a slash ("/"). The first letter of each word capitalized.
   * @example 'Men/Shoes/Skate Shoes2'
   * @source ???
   */
  category?: string;
  /**
   * Product Identifier
   * @format Uppercase
   * @example 'VN0A347VSYD'
   * @source SFCC - product object
   */
  id: string;
  /**
   * Product Master Identifier
   * @format Uppercase
   * @example 'VN0A347VSYD'
   * @source SFCC - product object
   */
  masterId: string;
  /**
   * Product name
   * @format First letter of each word capitalized.
   * @example 'Vans x Baker Slip-on Shoes'
   * @source SFCC - product object
   */
  name: string;
  /**
   * The Product Price in local currency. If the Product is on discount pass the discounted price.
   * @example 60.75
   * @source SFCC - product object
   */
  price: number;
  /**
   * A list of the sizes that are currenlty in stock
   * @example ['6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5']
   * @source SFCC - product object
   */
  availSizes: string[];
  /**
   * The average rating of the product from reviews
   * @example 4.56
   * @source SFCC - product object
   */
  avgRating: string;
  /**
   * The Mixed PDP (ex bundle for WCS) of the Product
   * @format The BundleID as provided by the Product Merch. Platform
   * @example 'Story_SandyLiang_Acc_App_Ftw_22'
   * @source SFCC ??
   */
  bundleId?: string;
  /**
   * The Catalog Category of the Product
   * @format string
   * @example 'mens'
   * @source SFCC - product object
   */
  catalogCategory?: string;
  /**
   * The Color Code of the Product
   * @format Uppercase
   * @example 'W5J'
   * @source SFCC - product object
   */
  colorCode: string;
  /**
   * The name of the Product Color as shown on the website
   * @format First letter of each word capitalized.
   * @example '(Mirage) Blue/White'
   * @source SFCC - product object
   */
  colorDescription: string;
  /**
   * The name of the Product Family
   * @format First letter of each word capitalized.
   * @example ??
   * @source SFCC??
   */
  colorFamily?: string;
  /**
   * Whether the item is user customized
   * @example false
   * @source SFCC
   */
  customized: boolean;
  /**
   * The merchandising product id
   * @format As provided by the Platform
   * @example NF0A52SE
   * @source SFCC
   */
  merchantProductId: string;
  /**
   * The type of page or component from which an item was found.
   * @format Uppercase
   * @example 'RECOMMENDATION: MAY WE SUGGEST'
   * @source SFCC??
   */
  merchLocation?: string;
  /**
   * ??
   * @format First letter of each word capitalized.
   * @example ??
   * @source SFCC??
   */
  merchStory?: string;
  /**
   * The number of reviews the product has received.
   * @example 125
   * @source SFCC??
   */
  numReviews?: number | string;
  /**
   * Whether an item is on sale
   * @example true
   * SFCC - product object
   */
  onSale: boolean;
  /** If the Product is not on discount (onSale = false), the value of this attribute is the same as that of the price attribute.
   * If the Product is discounted (onSale = false), the value of this attribute is the Product Original Price.
   * @example 60.75
   * @source SFCC - product object
   */
  originalPrice: number;
  /**
   * The Recipe ID is the unique ID assigned to Products that get Customized using the Product Customization tool.
   * @example '311bd5f42f82265a3264ef649e1057ff'
   * @source SFCC - product object
   */
  recipeID: string;
  /**
   * The sale discount amount in local currency
   * @example 9.99,
   * @source SFCC - product object
   */
  saleDiscountAmount: number;
  /**
   * The search term where the product was clicked or added to the cart.
   * @format Lowercase
   * @example 'green shoes'
   * @source SFCC??
   */
  searchTerm?: string;
  /**
   * The Season Code of the Product.
   * @format Uppercase
   * @example 'FW20'
   * @source SFCC - product object
   */
  season?: string;
  /**
   * Whether the Product is in stock
   * @example false
   * @source SFCC??
   */
  stockStatus?: boolean;
  /**
   * The Style Code of the Product
   * @format Uppercase
   * @example '0A4TZC'
   * @source SFCC - product object
   */
  styleCode: string;
  /**
   * The Virtual Style Code of the Product
   * @format Uppercase
   * @example 'VN000000JU4'
   * @source SFCC - product object
   */
  virtualStyleCode?: string;
  /**
   * The toal amount of tax on the Product
   * @example 9.99
   * @source SFCC??
   */
  taxTotal?: number;
  /**
   * The Code that gets assigned to a Product when the Merchandising Team pre-creates Custom Recipes.
   * @example '0A4OFQ'
   * @source SFCC - product object
   */
  preCreatedCustomCode?: string;
  /**
   * The Code that gets assigned to a Product when the Merchandising Team pre-creates Custom Recipes.
   * @example 'VN0A4OFQNOQ'
   * @source SFCC - product object
   */
  customModel?: string;
  /**
   * The Code that gets assigned to a Product when the Merchandising Team pre-creates Custom Recipes.
   * @example 'Pattern Maker'
   * @source SFCC - product object
   */
  customsTool?: string;
  /**
   * Product position in category page.
   * @example 1
   * @source Product index.
   */
  position?: number;
  /**
   * A description of the discount status of the product such as whether it is marked down, on coupon, both, a free gift, etc.
   * @format comma-delimited list
   * @example 'Coupon, Marked Down, Free Gift'
   * @source SFCC - cart object
   */
  discountType?: string;
  /**
   * The name of the badge that applies to a product.
   * @format string
   * @example 'Futurelight'
   * @source SFCC - product object
   */
  badge?: string;
  /**
   * @example 'Customs'
   * @source SFCC - cart object
   */
  variant?: string;
}

export type GtmCategoryProductObject = Omit<
  GtmProductObject,
  'availSizes' | 'vf_itemID'
>;

export interface GtmCartProductObject extends GtmProductObject {
  /**
   * The redeemable Product-level promo code entered by the user
   * @format The code name as it appears on the Website
   * @example '25_OFF_MENS_SHOES2'
   * @source SFCC + OMS - cart line item
   */
  coupon: string;
  /**
   * The quantity of the product (added, removed, purchased or in the cart)
   * @example 2
   * @source VSF - addToCart
   */
  quantity: number;
  /**
   * The promotion discount amount in local currency
   * @example 9.99
   * @source SFCC - cart
   */
  couponDiscountAmount: number;
  /**
   * Coupons can either be code-based or non code-based (auto-applied). This value should refelect that.
   * @format Uppercase
   * @example 'CODE', 'AUTO'
   * @source SFCC - cart object
   */
  couponType?: string; // 'CODE' | 'AUTO' | 'CODE|AUTO' | 'AUTO|CODE' or any other variation;
  /**
   * The product level promo applied campaign id
   * @format As provided by the Platform
   * @example "US_Evergreen_Campaigns"
   * @source SFCC??
   */
  productPromoCampaignId?: string;
  /**
   * The master reference for the code
   * @format As provided by the Platform
   * @example 25_OFF_MENS_SHOES_123456789
   * @source SFCC??
   */
  masterCouponCode?: string;
  /**
   * The merchandising category of the product
   * @format First letter of each word capitalized.
   * @example Footwear
   * @source SFCC??
   */
  merchCategory?: string;
  /**
   * Whether an item has a promotion associated with it.
   * @example true
   * @source SFCC - cart object
   */
  onCoupon: boolean;
  /**
   * The shipping method selected for the Product.
   * @source SFCC??
   */
  shippingMethod?:
    | 'STH'
    | 'BOPIS_STS'
    | 'BOPIS_SD'
    | 'BOPIS_PDP_STS'
    | 'BOPIS_PDP_SD'
    | 'BOPIS_PLP_SD';
  /**
   * The ID of the shipping store selected for the Product.
   * @example store-2842
   * @source SFCC??
   */
  shippingStoreId?: string;
  /**
   * The shipping window for the Product.
   * @example 3-4 business days
   * @source SFCC??
   */
  shippingWindow?: string;
  /**
   * The Size Dimension 1 of the Product
   * @format Uppercase
   * @example 'OS', 'L', '32x32'
   * @source SFCC - product object
   */
  sizeCode1: string;
  /**
   * The Size Dimension 2 of the Product
   * @format Uppercase
   * @example Medium, Regular
   * @source SFCC??
   */
  sizeCode2?: string;
  /**
   * The Size possible third size of the Product
   * @example text
   * @source SFCC??
   */
  sizeCode3?: string;
  /**
   * Most granular available product identifier including size, color, and/or options.
   * @format Uppercase (colon-delimited)
   * @example 'NF:00CEQ5:K82:M::1:'
   * @source SFCC - product object - SKU
   */
  vf_itemID: string;

  /**
   * The number of product reviews.
   * @example '0'
   * @source SFCC - product object - numReviews
   */
  numReviews?: string | number;
  /**
   * The Code that gets assigned to a Product when the Merchandising Team pre-creates Custom Recipes.
   * @example '0A4OFQ'
   * @source SFCC - product object
   */
  preCreatedCustomCode: string;
  /**
   * Notifies if the item is low on stock or not
   * @example 'true'
   */
  lowOnStock: boolean;
}

export interface GtmImpressionProductObject extends GtmProductObject {
  /**
   * The name of the Product List where the Product was included.
   * @format First letter of each word capitalized.
   * @example 'Product Grid'
   * @source page component type + category
   */
  list: string;
  /**
   * Position in products array
   * @example 12
   * @source SFCC
   */
  position: number;
  /**
   * The merchandising category of the product
   * @format First letter of each word capitalized.
   * @example 'Footwear'
   * @source SFCC??
   */
  merchCategory?: string;
}

export type GtmEnhancedSaleProduct = Omit<
  GtmProductObject,
  'merchantProductId' | 'recipeID'
>;

export type GtmEnhancedSaleImpressionProduct = Pick<
  GtmImpressionProductObject,
  | 'brand'
  | 'id'
  | 'colorCode'
  | 'list'
  | 'name'
  | 'onSale'
  | 'originalPrice'
  | 'price'
  | 'saleDiscountAmount'
  | 'position'
  | 'styleCode'
  | 'customized'
>;

/** @public
 * GTM object defining page related attributes */
export interface GtmPageDataObject {
  'app-webview': boolean;
  brand: string;
  breadcrumb?: string;
  countryCode: string;
  countryLanguage: string;
  currencyCode: string;
  pageName: string;
  pageTitle: string;
  searchResults?: number;
  searchResultsAdj?: number;
  searchTerm?: string;
  searchTermAdj?: string;
  searchType?: string;
  pageCategory: string;
  categoryID?: string;
}

/** @public
 * 'pageLoadedData' event dispatched to GTM when a page is loaded */
export interface PageLoadedDataEvent extends GtmPageDataObject {
  event: 'pageLoadedData';
}

export interface ActionFieldObject {
  /**
   * The store or affiliation from which the transaction occurred
   * @format Uppercase
   * @example 'VANS-DE'
   * @source env variable - API_SITE_ID by locale, upper case transformation
   */
  affiliation?: string;
  /**
   * The redeemable order-level promo code entered by the user.
   * @format The code name as it appears on the Website
   * @example '10_OFF_FIRST_ORDER'
   * @source SFCC - cart object
   */
  coupon?: string;
  /**
   * The ID of the transaction
   * @example '57736527'
   * @source SFCC - order object
   */
  id?: string;
  /**
   * The name of the product list where the E-commerce Event happened.
   * @format First letter of each word capitalized.
   * @example 'Product Grid'
   * @source VSF - page component - page/component type + category meta title
   */
  list?: string;
  /**
   * A string to provide extra information about the user such as the shipping type or the payment method
   * @format First letter of each word capitalized.
   * @example ??
   * @source ??
   */
  option?: string;
  /**
   * Revenue excluding taxes and shipping in local currency.
   * @example 33.2
   * @source SFCC - cart object - totals.itemTotal
   */
  revenue?: number;
  /**
   * Amount of rewards used to pay the whole order or a part of it, in local
   * currency (it can be a fixed increment, e.g. 5-10-15-20, or a free value)
   * @example 15
   * @source SFCC - cart object
   */
  rewardsAmount?: number;
  /**
   * The shipping amount in local currency
   * @example 5.05
   * @source SFCC - cart object - totals.shipping
   */
  shipping?: number;
  /**
   * An integer to indicate the step of the checkout
   * @example 2
   * @source VSF - packages/theme/pages/checkout
   */
  step?: CheckoutStepNumber;
  /**
   * The tax amount in local currency
   * @example 9.99
   * @source SFCC - cart object - totals.tax
   */
  tax?: number;
  /**
   * The final amount Users pay (what is showed on the Order Confirmation Page=
   * @example 150.5
   * @source SFCC - cart object - totals.totalWithoutTax + totals.tax
   */
  total?: number;
  /**
   * The delivery date of the purchased products
   * @format YYYY-MM-DD
   * @example '2021-10-25'
   * @source ??
   */
  deliveryDate?: string;
  /**
   * Identifies whether the order was fully or partially paid with a Gift Card
   * @example true
   * @source SFCC ??
   */
  giftCard?: boolean;
  /**
   * The amount paid on a Gift Card
   * @example 25.5
   * @source SFCC ??
   */
  giftCardAmount?: number;
  /**
   * The amount of discount given by the order-level promo.
   * @example 9.99
   * @source SFCC - cart object
   */
  orderPromoAmount?: number;
  /**
   * The redeemable order-level promo code entered by the user.
   * @format The code name as it appears on the Website
   * @example '10_OFF_FIRST_ORDER'
   * @source SFCC - cart object
   */
  orderPromoCode?: string;
  /**
   * The master order-level promo code from the platform.
   * @format The code name as it appears on the Website
   * @example '10_OFF_FIRST_ORDER_123456789'
   * @source SFCC - cart object
   */
  orderPromoMasterCode?: string;
  /**
   * A indicator for whether the promotion is code-based (user enters a coupon code) or whether it is automatically applied.
   * @format Uppercase
   * @example 'CODE', 'AUTO'
   * @source SFCC - cart object
   */
  orderPromoType?: string;
  /**
   * The Payment Option used to complete the transaction
   * @format As it is shown on the Website
   * @example 'PayPal'
   * @source SFCC - order object
   */
  paymentType?: string;
  /**
   * The Shipping Method used to complete the transaction.
   * @format First letter of each word capitalized.
   * @example 'Standard'
   * @source SFCC - order object
   */
  shippingMethod?: string;
  /**
   * The amount of discount given by the shipping-level promo code
   * @example 9.99
   * @source SFCC ??
   */
  shippingPromoAmount?: number;
  /**
   * The redeemable shipping-level promo code entered by the user.
   * @format The code name as it appears on the Website
   * @example 'FREE_SHIPPING'
   * @source SFCC ??
   */
  shippingPromoCode?: string;
  /**
   * The master shipping-level promo code from the platform.
   * @format The code name as it appears on the Website
   * @example 'FREE_SHIPPING_123456789'
   * @source SFCC ??
   */
  shippingPromoMasterCode?: string;
  /**
   * A indicator for whether the promotion is code-based (user enters a coupon code) or whether it is automatically applied.
   * @format Uppercase
   * @example 'CODE', 'AUTO'
   * @source SFCC ??
   */
  shippingPromoType?: string;
  /**
   * Is the detail view type.
   * @format action triggered by user
   * @example 'PDP' | 'Quick View' | 'Quick Shop'| View Details'
   * @source ??
   */
  viewType?: ProductDetailViewViewType;
  /**
   * Shipping promotion campaign id
   * @example true
   * @source SFCC ??
   */
  shippingPromoCampaignId?: string;
  coom_01?: string;
  firstTimePurchase?: boolean;
}

export interface GtmUserDataObject {
  'guest-id': string;
  loggedIn?: boolean;
}

export interface GtmLoggedUserDataObject extends GtmUserDataObject {
  loggedIn: true;
  loyaltyMember?: boolean;
  vfEmployee: boolean;
  'consumer-id': string;
}

export interface GtmLoggedLoyaltyEnrolledUserDataObject
  extends GtmLoggedUserDataObject {
  'loyaltyMember-id': string;
  loyaltyMember: true;
}

export interface GtmPromotionObject {
  /**
   * The ID of the internal Promotion/Banner/Content
   * @format Uppercase
   * @example 'JUNE_PROMO13'
   * @source ??
   */
  id?: string;
  /**
   * The name of the internal Promotion/Banner/Content
   * @format First letter of each word capitalized.
   * @example 'June Sale'
   * @source ??
   */
  name?: string;
  /**
   * The name of the creative used for the Promotion.
   * @format Lowercase
   * @example 'banner1'
   * @source ??
   */
  creative?: string;
  /**
   * The position of the Promotion on the page.
   * @example 4
   * @source ??
   */
  position?: string | number;
  /**
   * A concatenation of the other 4 PromO attributes
   * @example 'JUNE_PROMO:June Sale:banner1:4'
   */
  internalPromoCombined?: string;
}

export interface IGtmEvent {
  event: EventName;
  eventCategory?: EventCategory | any;
  eventAction?: string;
  eventLabel?: string;
  nonInteractive?: boolean;
  customMetrics?: unknown;
  customVariables?: unknown;
  _clear?: boolean;
}

export interface MonetateExperienceEvent {
  event: 'monetateActiveExperiences';
  monetateExperiences: MonetateExperience[];
  recommendations: {
    products: any[];
  };
}

export interface LaunchDarklyExperienceEvent {
  event: 'launchDarklyActiveExperiences';
  launchDarklyExperience: LaunchDarklyExperience[];
}

export interface ProductRecClickEvent extends IEcommerceEvent {
  eventAction: 'Product Clicks';
  eventLabel: string; // product sku
  nonInteractive: false;
  ecommerce: {
    currencyCode: string;
    click: {
      actionField: ActionFieldObject;
      products: any[];
    };
  };
}

export interface ProductRecImpressionsEvent extends IEcommerceEvent {
  eventAction: 'Product Impressions';
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    impressions: any[];
  };
}

export interface IEcommerceEvent extends IGtmEvent {
  eventCategory: 'Enhanced Ecommerce';
  ecommerce: unknown;
  customVariables: unknown;
}

export interface PromotionImpressionsEvent extends IEcommerceEvent {
  eventAction: 'Promotion Impressions';
  nonInteractive: true;
  ecommerce: {
    promoView: {
      promotions: GtmPromotionObject[];
    };
  };
}

export interface PromotionClickEvent extends IEcommerceEvent {
  eventAction: 'Promotion Click';
  nonInteractive: false;
  ecommerce: {
    promoClick: {
      promotions: GtmPromotionObject[];
    };
  };
}

export interface ProductImpressionsEvent extends IEcommerceEvent {
  eventAction: 'Product Impressions';
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    impressions: GtmImpressionProductObject[];
  };
}

export interface ProductClickEvent extends IEcommerceEvent {
  eventAction: 'Product Clicks';
  eventLabel: string; // product sku
  nonInteractive: false;
  ecommerce: {
    currencyCode: string;
    click: {
      actionField: ActionFieldObject;
      products: GtmCategoryProductObject[];
    };
  };
}

export interface ProductDetailViewEvent extends IEcommerceEvent {
  eventAction: 'Product Detail View';
  eventLabel: string; // product sku
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    detail: {
      actionField: ActionFieldObject;
      products: GtmProductObject[];
    };
  };
}

export interface AddToCartEvent extends IEcommerceEvent {
  eventAction: 'Add to Cart';
  nonInteractive: false;
  ecommerce: {
    currencyCode: string;
    add: {
      products: GtmCartProductObject[];
    };
  };
}

export interface RemoveFromCartEvent extends IEcommerceEvent {
  eventAction: 'Remove from Cart';
  nonInteractive: false;
  ecommerce: {
    currencyCode: string;
    remove: {
      products: GtmCartProductObject[];
    };
  };
}

export interface UpdateCartEvent extends IGtmEvent {
  eventAction: 'Cart Update';
  nonInteractive: true;
  customMetrics: unknown;
  customVariables: unknown;
  cart: {
    cartId: string;
    products: GtmCartProductObject[];
  };
}

export interface CheckoutEvent extends IEcommerceEvent {
  eventAction: CheckoutStepName;
  nonInteractive: true;
  ecommerce: {
    currencyCode?: string;
    checkout?: {
      actionField: ActionFieldObject;
      products: GtmCartProductObject[];
    };
  };
}

export type CheckoutStepName =
  | 'Empty Cart'
  | 'Checkout - Cart'
  | 'Checkout - Shipping'
  | 'Checkout - Payment'
  | 'Checkout - Summary';

export interface CheckoutOptionEvent extends IEcommerceEvent {
  eventAction: 'Checkout Option';
  eventLabel: CheckoutOptionEventLabel;
  nonInteractive: true;
  ecommerce: {
    checkout_option: {
      actionField: ActionFieldObject;
    };
  };
}

export type CheckoutOptionEventLabel =
  | 'Shipping Method'
  | 'Shipping Address'
  | 'Payment Method'
  | 'Billing Address';

export interface CheckoutCompletionEvent extends IEcommerceEvent {
  eventAction: 'Order Confirmation';
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    purchase: {
      actionField: ActionFieldObject;
      products: GtmCartProductObject[];
    };
  };
}

export interface RefundEvent extends IEcommerceEvent {
  eventAction: 'Refund';
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    refund: {
      actionField: ActionFieldObject;
      products?: {
        id: string;
        quantity: number;
      };
    };
  };
}

export enum EnhancedSaleList {
  UP_SELL = 'UpSellCompleteTheLook',
  CROSS_SELL = 'CrossSellCompleteTheLook',
}

export enum EnhancedSaleType {
  UP_SELL = 'upSell',
  CROSS_SELL = 'crossSell',
}

export interface EnhancedSaleEvent extends IEcommerceEvent {
  eventAction: 'Product Impressions';
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    impressionsRec: GtmEnhancedSaleImpressionProduct[];
  };
}

export interface EnhancedSaleClickEvent extends IEcommerceEvent {
  eventAction: 'Product Clicks';
  eventLabel: string;
  nonInteractive: false;
  ecommerce: {
    currencyCode: string;
    click: {
      actionField: {
        action: string;
        list: string;
      };
      products: GtmEnhancedSaleImpressionProduct[];
    };
  };
}

export interface EnhancedSaleViewEvent extends IEcommerceEvent {
  eventAction: 'Product Detail View';
  eventLabel: string;
  nonInteractive: true;
  ecommerce: {
    currencyCode: string;
    detail: {
      actionField: ActionFieldObject;
      products: GtmEnhancedSaleProduct[];
    };
  };
}

export interface LoadPageDataEvent extends IGtmEvent {
  page: GtmPageDataObject;
}

export interface LoadUserDataEvent extends IGtmEvent {
  user:
    | GtmUserDataObject
    | GtmLoggedUserDataObject
    | GtmLoggedLoyaltyEnrolledUserDataObject;
}

export interface LoadEventDataEvent extends IGtmEvent {
  event: 'loadEventData';
  eventCategory: string;
}

export interface ErrorEvent extends LoadEventDataEvent {
  eventCategory: 'ERRORS';
  customMetrics: unknown;
  customVariables: {
    errorMessage: string;
    errorOrigin: string;
    errorDetail: string;
  };
}

export interface VirtualPageViewEvent {
  event: 'virtualPageView';
  pageType: 'PageView';
  pageUrl: string;
  routeName: string;
}
type MonetateExperience = {
  split: string;
  id: number;
  key: string;
};

type LaunchDarklyExperience = {
  key: string;
  value: string;
  variationIndex: number;
};

export type EventName =
  | 'loadPageData'
  | 'loadUserData'
  | 'virtualPageView'
  | 'pageLoadedData'
  | 'productImpressions'
  | 'productClick'
  | 'productDetailView'
  | 'addToCart'
  | 'removeFromCart'
  | 'cartUpdate'
  | 'checkout'
  | 'checkoutOption'
  | 'checkoutCompletion'
  | 'enhancedSale'
  | 'UpSellCompleteTheLook'
  | 'CrossSellCompleteTheLook'
  | 'enhancedSaleClick'
  | 'UpSellCompleteTheLookClick'
  | 'CrossSellCompleteTheLookClick'
  | 'enhancedSaleView'
  | 'UpSellCompleteTheLookView'
  | 'CrossSellCompleteTheLookView'
  | 'refund'
  | 'promotionImpressions'
  | 'promotionClick'
  | 'loadEventData'
  | 'monetateActiveExperiences'
  | 'launchDarklyActiveExperiences'
  | 'productRecClick'
  | 'productRecImpressions';

export type EventCategory = 'Enhanced Ecommerce';

export enum CheckoutStepNumber {
  CART_EMPTY = 0,
  CART = 1,
  SHIPPING = 2,
  BILLING = 3,
  REVIEW = 4,
}

export enum ProductDetailViewViewType {
  PDP = 'PDP',
  QUICK_VIEW = 'Quick View',
  QUICK_SHOP = 'Quick Shop',
  VIEW_DETAILS = 'View Details',
}
