import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { GetFavoritesResponse } from '@vf/api-contract';
import { ApiClientConfiguration } from '../../configuration';

export const getSharedFavorites = (configuration: ApiClientConfiguration) => (
  favoriteId: string
): Promise<AxiosResponse<GetFavoritesResponse>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).wishList.getSharedFavorites,
    {
      config: configuration,
      mapping: {
        favoriteId,
      },
    }
  );
  return axios.get(url, {
    headers: headerBuilder(configuration),
  });
};
