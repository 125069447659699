import {
  PromotionMessage,
  PromotionMessageContext,
  CMPlaceholder,
  CmsSite,
} from '@vf/api-contract';
import {
  extractCommonConfigLink,
  extractProperty,
  getDynamicField,
  getTextWithAttrHrefFromCommonConfig,
} from './utils';
import { ComposableContext } from '../types';

export const promotionMessage = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const links = extractCommonConfigLink(siteConfiguration, 'promotions');
  const componentContext = getDynamicField(data, 'context');

  const getTranslationsStrings = (obj: PromotionMessage) => {
    const promotionTranslations = getTextWithAttrHrefFromCommonConfig(
      JSON.stringify(obj),
      siteConfiguration
    );
    return JSON.parse(promotionTranslations);
  };

  return {
    component: 'promotion/PromotionMessage',
    props: {
      promotions: getTranslationsStrings(
        links.map((link) => ({
          name: link.name,
          translations: extractProperty(link, `settings.${componentContext}`),
        }))
      ) as PromotionMessage,
      context: componentContext as PromotionMessageContext,
    },
  };
};
