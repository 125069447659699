//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfSticky',
  props: {
    triggerElementId: {
      type: String,
      default: '',
    },
    triggerDefinedNumber: {
      type: Number,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    noPaddingTop: {
      type: Boolean,
      default: false,
    },
    alwaysTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      top: 0,
      height: 0,
      width: 0,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      parentTop: 0,
      parentHeight: 0,
      scrollY: 0,
      isSticky: false,
      isBound: false,
      isStickyTop: false,
      isStickyTopBound: false,
      isStickyBottom: false,
      isStickyBottomBound: false,
      triggerElement: null,
      triggerElementTop: 0,
      triggerElementHeight: 0,
      isFixed: false,
      parentElement: null,
    };
  },
  computed: {
    scrollBegin() {
      return this.parentTop + this.top;
    },
    scrollEnd() {
      return (
        this.parentHeight + this.parentTop - this.height - this.padding.bottom
      );
    },
    triggerStickyNumber() {
      if (this.triggerElementId) {
        return this.triggerElementTop + this.triggerElementHeight;
      } else if (this.triggerDefinedNumber) {
        return this.triggerDefinedNumber;
      } else {
        return this.parentTop;
      }
    },
    isStickyVisibleOnTrigger() {
      if (!this.triggerElementId) return;
      return this.scrollY <= this.triggerElementTop + this.triggerElementHeight;
    },
  },
  watch: {
    scrollY(value) {
      this.isFixed = value > 100 && this.alwaysTop;
      this.toggleSticky();
      this.toggleStickyTop();
      this.toggleStickyBottom();
    },
    parentTop() {
      this.$el.style.bottom = '';
      this.parentElement.style.paddingTop = '';
      this.isSticky = false;
      this.isBound = false;
      this.computedPadding();
      this.parentHeight = this.parentElement.offsetHeight;
    },
    width(value) {
      this.$el.style.width = `${value}px`;
    },
    isBound(state) {
      if (state && !this.alwaysTop) {
        this.$el.style.bottom = `${this.padding.bottom}px`; //if parent have padding
      } else {
        this.$el.style.bottom = '';
      }
    },
    isVisible(value) {
      if (value) this.resizeHandler();
    },
  },
  mounted: function () {
    this.$el.parentElement.style.position = 'relative';
    this.padding = this.computedPadding();
    const parentElement = this.$el.parentElement;
    this.parentElement = parentElement;
    if (this.nested) {
      this.parentTop = this.getOffsetTop(parentElement.offsetParent);
    } else {
      this.parentTop = this.getOffsetTop(parentElement);
    }
    this.top = this.getOffsetTop(this.$el);
    this.parentHeight = this.$el.parentElement.offsetHeight;
    this.height = this.$el.offsetHeight;
    this.width = this.$el.parentElement.offsetWidth;
    window.addEventListener('scroll', this.scrollHandler, { passive: true });
    window.addEventListener('resize', this.resizeHandler, { passive: true });
    window.addEventListener(
      're-calculate-scroll',
      this.reCalculateScrollHandler,
      {
        passive: true,
      }
    );
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener(
      're-calculate-scroll',
      this.reCalculateScrollHandler
    );
  },
  methods: {
    scrollHandler() {
      this.height = this.$el.offsetHeight;
      this.parentHeight = this.parentElement.offsetHeight;
      this.scrollY = Math.ceil(window.scrollY);
      if (window.scrollY < 200 && this.$el.parentElement) {
        this.$el.parentElement.style.paddingTop = 0;
      }

      if (window.scrollY > 60) {
        this.$el.style.zIndex = this.$el.querySelector('.sticky-header')
          ? 14
          : 3;
      } else {
        this.$el.style.zIndex = 2;
      }
      this.reCalculateParentTopHandler();
    },
    reCalculateParentTopHandler() {
      if (this.nested) {
        this.parentTop = this.getOffsetTop(this.parentElement.offsetParent);
      } else {
        this.parentTop = this.getOffsetTop(this.parentElement);
      }
    },
    reCalculateParentMinHeight() {
      const pxHeight = `${this.height}px`;

      if (
        this.$el.parentElement &&
        this.$el.parentElement.style.minHeight !== pxHeight
      ) {
        this.$el.parentElement.style.minHeight = pxHeight;
      }
    },
    resizeHandler() {
      this.width = this.$el.parentElement.offsetWidth;
      this.parentHeight = this.$el.parentElement.offsetHeight;

      if (this.isSticky && this.$el.nextSibling && !this.noPaddingTop) {
        this.scrollHandler();
        this.$el.parentElement.style.paddingTop = `${
          window.scrollY == 0 ? 0 : this.height + this.padding.top
        }px`;
      }
      if (window.scrollY > 60) {
        this.$el.style.zIndex = 4;
      } else {
        this.$el.style.zIndex = 2;
      }
    },
    reCalculateScrollHandler() {
      this.$nextTick(() => {
        this.scrollHandler();
        this.reCalculateParentMinHeight();
        this.toggleSticky();
        this.toggleStickyTop();
        this.toggleStickyBottom();
      });
    },
    toggleSticky() {
      this.determineTriggerElement();

      if (
        (this.scrollY >= this.triggerStickyNumber &&
          this.scrollY < this.scrollEnd) ||
        this.alwaysTop
      ) {
        this.isSticky = true;
      }
    },
    toggleStickyTop() {
      if (
        (this.scrollY >= this.triggerStickyNumber &&
          this.scrollY < this.scrollEnd &&
          this.height <= window.innerHeight) ||
        this.alwaysTop
      ) {
        this.isStickyTop = true;
      } else {
        this.isStickyTop = false;
      }

      if (
        this.scrollY >= this.scrollEnd &&
        this.scrollBegin < this.scrollEnd &&
        this.height <= window.innerHeight &&
        !this.alwaysTop
      ) {
        this.isStickyTopBound = true;
      } else {
        this.isStickyTopBound = false;
      }
    },
    toggleStickyBottom() {
      if (
        this.scrollY >= this.top + this.height - window.innerHeight &&
        this.height > window.innerHeight &&
        this.scrollY >= this.triggerStickyNumber
      ) {
        this.isStickyBottom = true;
      } else {
        this.isStickyBottom = false;
      }

      if (
        this.scrollY >=
          this.parentTop + this.parentHeight - window.innerHeight &&
        !this.alwaysTop
      ) {
        this.isStickyBottomBound = true;
      } else {
        this.isStickyBottomBound = false;
      }
    },
    computedPadding() {
      const computed = window.getComputedStyle(this.$el.parentElement);
      return {
        top: parseInt(computed['padding-top'], 10),
        right: parseInt(computed['padding-right'], 10),
        bottom: parseInt(computed['padding-bottom'], 10),
        left: parseInt(computed['padding-left'], 10),
      };
    },
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    },
    determineTriggerElement() {
      if (this.triggerElementId && !this.triggerElement) {
        this.triggerElement = document.getElementById(this.triggerElementId);

        if (this.triggerElement) {
          this.triggerElementTop = this.getOffsetTop(this.triggerElement);
          this.triggerElementHeight = this.triggerElement.offsetHeight;
        }
      }
    },
  },
});
