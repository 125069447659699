//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'FilterItem',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    type: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    tiled: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: String,
      default: undefined,
    },
    link: {
      type: [String, Object],
      default: '',
    },
    prevent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imgSrc: {
      type: String,
      default: '',
    },
  },
});
