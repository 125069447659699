import { CmsSite, CMPlaceholder } from '@vf/api-contract';
import {
  extractProperty,
  getTranslation,
  getDynamicField,
} from './utils/extractProperty';
import {
  CmsSanitizationRequirementName,
  CmsSanitizationRequirements,
  ComposableContext,
} from '../types';
import { getGridConfigValues } from './utils/articleGridUtils';

export const searchResultsArticles = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  requirements: CmsSanitizationRequirements
) => {
  const filters = extractProperty(data, 'subjectTaxonomy', []);

  requirements.push({
    name: CmsSanitizationRequirementName.ARTICLES,
    filters,
  });
  return {
    component: 'articleSearch/SearchResultsArticles',
    props: {
      filters,
      configuration: {
        ...getGridConfigValues(data, 'search-result-article-settings'),
        ...{
          'tile-show-title': true,
          'tile-show-body-text': true,
          'tile-show-read-more-cta': true,
        },
      },
      translations: {
        showMoreButtonText: getTranslation(
          data,
          'showMoreButtonText',
          '$View more$'
        ),
        emptySearchTitle: getTranslation(
          data,
          'emptySearchTitle',
          '$No results$'
        ),
        emptySearchSubtitle: getTranslation(
          data,
          'emptySearchSubtitle',
          '$We did not find any articles.$'
        ),
        readMore: getTranslation(data, 'readMore', '$Read more$'),
      },
      noResultShowArticles: getDynamicField(data, 'noResultArticles', false),
      noResultSearch: getDynamicField(
        data,
        'noResultSearch',
        'no result search'
      ),
    },
  };
};
