import type { PdpGalleryImage, PdpGalleryVideo } from '../types';
import type { ComponentInstance, ImageSizesType } from '../../../types';
import { apiClientFactory } from '@vf/api-client';

const cloudinaryMedia = async ({
  colorCode,
  colorName,
  instance,
  imageSizes,
  productDescription,
  productId,
  productName,
  supportVideo,
}: {
  colorCode: string;
  colorName: string;
  imageSizes?: ImageSizesType | Record<string, string>;
  instance: ComponentInstance;
  productDescription?: string;
  productId: string;
  productName: string;
  supportVideo?: boolean;
}) => {
  const { getCloudinaryAnyList } = apiClientFactory(instance);
  try {
    const cloudinaryDomain = instance.$config.CLOUDINARY_DOMAIN;
    const anyList = await getCloudinaryAnyList(
      `${productId}${colorCode}`,
      cloudinaryDomain
    );
    const media: Array<{
      mediaItem: PdpGalleryImage | PdpGalleryVideo;
      position: number;
    }> = [];

    anyList.data.resources.forEach((resource) => {
      const { position, product_name, color_name } = resource.metadata.reduce(
        (acc, item) => {
          acc[item.external_id] = item.value;
          return acc;
        },
        { product_name: '', position: 0, color_name: '' }
      );

      const resourceBase = {
        publicId: resource.public_id,
        type: resource.resource_type,
        productName: product_name || productName,
        colorName: color_name || colorName,
        fileExt: resource.format,
        imageSizes,
      };

      let mediaItem: PdpGalleryImage | PdpGalleryVideo = {};
      if (supportVideo && resourceBase.type === 'video') {
        mediaItem = {
          thumbnail: instance.$mediaUrlGenerator({
            ...resourceBase,
            fileExt: 'jpg',
            imageSizes: undefined,
            preset: imageSizes?.['thumbnail'],
          }) as string,
          video: {
            sources: [
              {
                src: instance.$mediaUrlGenerator({
                  ...resourceBase,
                  imageSizes: undefined,
                  preset: null,
                }) as string,
                type: `video/${resourceBase.fileExt}`,
              },
            ],
            seo: {
              date: resource.created_at,
              title: productName,
              description: productDescription,
            },
            tracks: [],
            poster: instance.$mediaUrlGenerator({
              ...resourceBase,
              fileExt: 'jpg',
              imageSizes: undefined,
              preset: instance.$env.IMAGES_PDP_MAIN_PRESET,
            }) as string,
            options: {
              preload: 'metadata',
              autoplay: false,
              hideControls: false,
              muted: false,
              loop: false,
              showPlayButton: true,
              showSubtitles: false,
            },
          },
        };
      } else {
        if (resourceBase.type === 'video') resourceBase.fileExt = 'jpg';

        const tmpMedia = instance.$mediaUrlGenerator(resourceBase);
        if (typeof tmpMedia === 'string') mediaItem.src = tmpMedia;
        else mediaItem = tmpMedia;
      }

      media.push({ position, mediaItem });
    });
    return media
      .sort((a, b) => a.position - b.position)
      .map(({ mediaItem }) => mediaItem);
  } catch (err) {
    console.error(
      '[@packages/composables/src/utils/dataSources/handlers/cloudinary.ts] Error here',
      err
    );
    return [];
  }
};

export { cloudinaryMedia };
