import { apiClientFactory } from '@vf/api-client';
import { errorMessages } from '../../../utils/errorMessages';
import { isAxiosApiError } from '../../../utils/isAxiosApiError';

export const usePromotions = (instance, { cartId, getPaymentMethods }) => {
  const { displayErrorMessages } = errorMessages(instance);
  const {
    addCoupon: addCouponAPI,
    deleteCoupon: deleteCouponAPI,
  } = apiClientFactory(instance);

  const applyPromoCode = async (promoCodeData, showErrorMessage = true) => {
    try {
      const response = await addCouponAPI({
        cartId: cartId.value,
        code: promoCodeData.trim(),
      });
      await getPaymentMethods();
      return response;
    } catch (error) {
      // Prevent displaying invalid promo code notification for TNF
      if (
        isAxiosApiError(error) &&
        !showErrorMessage &&
        [
          'PRM130',
          'PRM135',
          'PRM140',
          'PRM150',
          'PRM155',
          'PRM160',
          'PRM416',
        ].includes(error.response.data.errorDetails[0].errorId)
      ) {
        return error.response;
      }
      displayErrorMessages(error);
    }
  };

  const deletePromoCode = async (promoCodeData) => {
    try {
      const response = await deleteCouponAPI({
        cartId: cartId.value,
        couponItemId: promoCodeData,
      });
      await getPaymentMethods();
      return response;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  return {
    applyPromoCode,
    deletePromoCode,
  };
};
