import { ParsedProductReviewItem, ProductReview } from '@vf/api-contract';
import { formatDistanceToNow } from 'date-fns';
import { ProductReviewProperty } from 'packages/api-contract/src/entities';
import { VotedProductReviewIds } from '..';
import { getDateFnsLocale } from '@vf/shared/src/utils/helpers';
import { getMappedReviewMedia } from './getMappedReviewMedia';

export const getSortedProperties = (
  properties: ProductReviewProperty[]
): ProductReviewProperty[] => {
  const propertiesOrder = ['pros', 'cons', 'bestuses'];
  const sortedProperties = properties
    .filter(({ key }) => propertiesOrder.includes(key))
    .sort(
      (a, b) => propertiesOrder.indexOf(a.key) - propertiesOrder.indexOf(b.key)
    );

  return Array.from(new Set([...sortedProperties, ...properties]).values());
};

export const getMappedProductReviews = (
  reviews: ProductReview[],
  locale: string,
  localeMapping: string,
  votedProductReviewIds: VotedProductReviewIds
): ParsedProductReviewItem[] => {
  if (!reviews.length) {
    return [];
  }
  const dateFnsLocale = getDateFnsLocale(locale, localeMapping);
  return reviews.map<ParsedProductReviewItem>((review) => ({
    score: review.metrics.rating,
    title: review.details.headline,
    description: review.details.comments,
    merchantResponse: review.details?.merchantResponse || null,
    details: {
      nickname: review.details.nickname,
      createdDate: formatDistanceToNow(review.details.createdDate, {
        addSuffix: true,
        ...(dateFnsLocale && { locale: dateFnsLocale }),
      }),
      location: review.details.location,
      disclosureCode: review.details.disclosureCode,
    },
    bottomLine: review.details.bottomLine,
    badges: {
      isVerifiedBuyer: review.badges.isVerifiedBuyer,
    },
    thumbUpCount: review.metrics.helpfulVotes,
    thumbDownCount: review.metrics.notHelpfulVotes,
    isUpSelected: votedProductReviewIds.helpful.includes(review.reviewId),
    isDownSelected: votedProductReviewIds.notHelpful.includes(review.reviewId),
    flagged: votedProductReviewIds.flagged.includes(review.reviewId),
    ugcId: review.ugcId,
    reviewId: review.reviewId,
    media: getMappedReviewMedia(review.media),
    metrics: review.metrics,
    properties: getSortedProperties(review.details.properties),
  }));
};
