import {
  MappedProductReviewProperty,
  ParsedProductReview,
  ProductReviewFilter,
  ProductReviewRollup,
  ProductReviewRollupProperty,
} from 'packages/api-contract/src/entities';

export const getMappedRollupProperty = (
  properties: ProductReviewRollupProperty[],
  key: string,
  filters: ProductReviewFilter[]
): MappedProductReviewProperty[] =>
  properties
    .filter((property) => property.key === key)
    .flatMap((property) =>
      property.values.map(({ label, count }) => ({
        label,
        count,
        isSelected: !!filters.find(
          (f) => f.key === key && f.value.includes(label)
        ),
      }))
    );

export const getPropertyDisplayValues = (
  properties: ProductReviewRollupProperty[],
  key: string
): ProductReviewRollupProperty['display_values'] => {
  const prop = properties.find((property) => property.key === key);
  return prop ? prop.display_values : [];
};

export const getReviewPros = (
  rollup: ProductReviewRollup,
  filters: ProductReviewFilter[]
): MappedProductReviewProperty[] => {
  return getMappedRollupProperty(rollup.properties, 'pros', filters);
};

export const getReviewCons = (
  rollup: ProductReviewRollup,
  filters: ProductReviewFilter[]
): MappedProductReviewProperty[] => {
  return getMappedRollupProperty(rollup.properties, 'cons', filters);
};

export const getReviewSizing = (
  rollup: ProductReviewRollup
): ParsedProductReview['sizing'] => {
  const keys = ['sizing', 'fit', 'fit_old'];

  for (const key of keys) {
    const prop = rollup.properties.find((property) => property.key === key);

    if (prop) {
      const displayValues = prop.display_values ?? [];
      let maxCount = 0;
      const value = getMappedRollupProperty(rollup.properties, key, []).reduce(
        (size, { label, count }) => {
          if (count >= maxCount) {
            maxCount = count;
            return displayValues.indexOf(label) + 1;
          }

          return size;
        },
        0
      );

      return {
        value,
        maxValue: displayValues.length,
      };
    }
  }
};

export const getReviewSleevelength = (
  rollup: ProductReviewRollup
): ParsedProductReview['sleevelength'] => {
  const displayValues = getPropertyDisplayValues(
    rollup.properties,
    'sleevelength'
  );
  return getMappedRollupProperty(rollup.properties, 'sleevelength', []).reduce(
    (size, { label, count }) =>
      count > size ? displayValues.indexOf(label) + 1 : size,
    0
  );
};

export const getReviewChestsize = (
  rollup: ProductReviewRollup
): ParsedProductReview['chestsize'] => {
  const displayValues = getPropertyDisplayValues(
    rollup.properties,
    'chestsize'
  );
  return getMappedRollupProperty(rollup.properties, 'chestsize', []).reduce(
    (size, { label, count }) =>
      count > size ? displayValues.indexOf(label) + 1 : size,
    0
  );
};

export const getReviewWidth = (
  rollup: ProductReviewRollup
): ParsedProductReview['width'] => {
  const displayValues = getPropertyDisplayValues(rollup.properties, 'width');
  return getMappedRollupProperty(rollup.properties, 'width', []).reduce(
    (width, { label, count }) =>
      count > width ? displayValues.indexOf(label) + 1 : width,
    0
  );
};
