

let pluginContext = {}

export const setOptionsContext = (c) => { pluginContext = c }

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
 get vueI18n() {
        const envSource = pluginContext.req || pluginContext.nuxtState

        if (envSource) {
          return ((env) => ({
    fallbackLocale: env.DEFAULT_LANGUAGE }))(envSource.env)
        }

        return ((env) => ({
    fallbackLocale: env.DEFAULT_LANGUAGE }))({})
      },
  vueI18nLoader: false,
 get locales() {
        const envSource = pluginContext.req || pluginContext.nuxtState

        if (envSource) {
          return ((env) => env.SUPPORTED_LANGUAGES.split(','))(envSource.env)
        }

        return ((env) => env.SUPPORTED_LANGUAGES.split(','))({})
      },
 get defaultLocale() {
        const envSource = pluginContext.req || pluginContext.nuxtState

        if (envSource) {
          return ((env) => env.DEFAULT_LANGUAGE)(envSource.env)
        }

        return ((env) => env.DEFAULT_LANGUAGE)({})
      },
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/var/www/packages/theme/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
 get brand() {
        const envSource = pluginContext.req || pluginContext.nuxtState

        if (envSource) {
          return ((env) => env.LANG_DIR_DOMAIN)(envSource.env)
        }

        return ((env) => env.LANG_DIR_DOMAIN)({})
      },
 get isBuild() {
        const envSource = pluginContext.req || pluginContext.nuxtState

        if (envSource) {
          return ((env) => typeof env.SUPPORTED_LANGUAGES === 'undefined')(envSource.env)
        }

        return ((env) => typeof env.SUPPORTED_LANGUAGES === 'undefined')({})
      },
 get staticLayoutRoutes() {
        const envSource = pluginContext.req || pluginContext.nuxtState

        if (envSource) {
          return ((env) => {
    const {
      CATEGORY_ID_PATTERN } =
    require('@vf/composables/src/useUrl/handlers/patterns');
    const envStaticLayout = JSON.parse(env.STATIC_LAYOUT);
    const routes = [];
    if (envStaticLayout.default.plp) {
      routes.push({
        name: 'static_plp',
        path: `/*-c(${CATEGORY_ID_PATTERN})`,
        /**
         * Import_name looks up at the /theme/config/nuxt/router.js so we can import it only when needed
         * Check GLOBAL15-43552 for reference
         */
        import_name: 'dynamic_import_plp' });
    }

    return routes;
  })(envSource.env)
        }

        return ((env) => {
    const {
      CATEGORY_ID_PATTERN } =
    require('@vf/composables/src/useUrl/handlers/patterns');
    const envStaticLayout = JSON.parse(env.STATIC_LAYOUT);
    const routes = [];
    if (envStaticLayout.default.plp) {
      routes.push({
        name: 'static_plp',
        path: `/*-c(${CATEGORY_ID_PATTERN})`,
        /**
         * Import_name looks up at the /theme/config/nuxt/router.js so we can import it only when needed
         * Check GLOBAL15-43552 for reference
         */
        import_name: 'dynamic_import_plp' });
    }

    return routes;
  })({})
      },
}

export const localeMessages = {}
