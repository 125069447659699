import { getTeaserStyle } from './utils/getTeaserStyle';
import { ComposableContext } from '../types';

export const heading = (data: CMSHeading, context: ComposableContext) => {
  return {
    component: 'common/Heading',
    props: {
      title: data.teaserTitle,
      subtitle: data.teaserSubtitle,
      level: getHeadingLevelOption(data, context),
      // TODO: CMS to send predefined heading styles value: "title-1", "title-2" etc.
      // https://digital.vfc.com/jira/browse/ECOM15-13070
      titleModifier: getTeaserStyle(data, 'teaserTitleClassModifiers'),
      styles: {
        classModifiers: getTeaserStyle(data, 'teaserClassModifiers'),
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        subtitleColor: getTeaserStyle(data, 'teaserSubTitleColor'),
        titleBackgroundColor: getTeaserStyle(
          data,
          'teaserTitleBackgroundColor'
        ),
        subtitleBackgroundColor: getTeaserStyle(
          data,
          'teaserSubTitleBackgroundColor'
        ),
        subtitleClassModifiers: getTeaserStyle(
          data,
          'teaserSubTitleClassModifiers'
        ),
        titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        subtitleFontSize: getTeaserStyle(data, 'teaserSubTitleFontSize'),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        subtitleFontFamily: getTeaserStyle(data, 'teaserSubTitleFontFace'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
      },
    },
  };
};

const getHeadingLevelOption = (
  data: CMSHeading,
  context: ComposableContext
) => {
  const DEFAULT_HEADING_LEVEL_OPTION = 2;
  try {
    const levelConfig = data.componentConfig.find(
      (conf) => conf.name === 'title-properties'
    );

    return (
      parseInt(levelConfig.values['heading-level'].toString()) ||
      DEFAULT_HEADING_LEVEL_OPTION
    );
  } catch (e) {
    context.instance.$log.warn(
      `[@useCms/mappings/heading::getHeadingLevelOption] No heading level specified, falling back to default (${DEFAULT_HEADING_LEVEL_OPTION}).`
    );
  }

  return DEFAULT_HEADING_LEVEL_OPTION;
};

export type CMSHeading = {
  componentConfig: CMSHeadingConfig[];
  id: string;
  name: string;
  teaserSubtitle: string;
  teaserTitle: string;
  type: 'CMTeaser';
  viewtype: 'heading';
};

export type CMSHeadingConfig = {
  name: 'title-properties';
  values: {
    'heading-level': string | number;
  };
};
