
































































































































import { computed } from '@vue/composition-api';
import { AnalyticsDataDomLocation } from '@/types';

export default {
  name: 'MobileMenuMoreSection',
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
    maxItems: {
      type: Object,
      default: () => ({}),
    },
    moreTitle: {
      type: String,
      default: '',
    },
    handleActiveMegaMenuColumn: {
      type: Function,
      required: true,
    },
    getShopAllLabel: {
      type: Function,
      required: true,
    },
    handleMobileNavLinkClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const isMobileMoreSectionVisible = computed(() => {
      return props.navigation.length >= props.maxItems?.smallMaxItems;
    });
    return {
      isMobileMoreSectionVisible,
      AnalyticsDataDomLocation,
    };
  },
};
