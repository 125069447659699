import {
  useCheckout,
  useFavorites,
  useSaveForLater,
  useReturns,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { computed, ComputedRef } from '@nuxtjs/composition-api';
import useLoader from './useLoader';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { storeToRefs } from 'pinia';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

type UseGlobalState = {
  loading: ComputedRef<boolean>;
};

function useGlobalState(): UseGlobalState {
  const { root } = useRootInstance();

  const { loading: favoritesLoading } = useFavorites(root);
  const { loading: checkoutLoading } = useCheckout(root);
  const { loading: saveForLaterLoading } = useSaveForLater(root);
  const { loading: returnsLoading } = useReturns(root);
  const { isSpinnerVisible } = useLoader();
  const { loading: cartLoading } = storeToRefs(useCartStore());
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

  const loading = computed(() =>
    [
      isSpinnerVisible,
      favoritesLoading,
      checkoutLoading,
      saveForLaterLoading,
      returnsLoading,
      isCheckoutRedesignEnabled && cartLoading,
    ].some((r) => r?.value)
  );

  return {
    loading,
  };
}

export default useGlobalState;
