import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import { getTeaserStyle } from './utils/getTeaserStyle';
import {
  extractProperty,
  getDynamicField,
  generateLinkFromTeaserTargets,
} from './utils';

// TODO: Remove it after GLOBAL15-56318
export const clearSessionButton = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  return {
    component: 'buyInStore/ClearSessionButton',
    props: {
      title: extractProperty(data, 'teaserTitle', ''),
      buttonUrl: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams
      ),
      positionX: getDynamicField(data, 'positionX', 'left'),
      positionY: getDynamicField(data, 'positionY', 'bottom'),
      titleColor: getTeaserStyle(data, 'teaserTitleColor'),
      backgroundColor: getTeaserStyle(data, 'teaserTitleBackgroundColor'),
    },
  };
};
