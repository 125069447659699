import Vue from 'vue';
import CmsPage from '../components/cms/CmsPage.vue';
import CmsPreview from '../components/cms/CmsPreview.vue';

Vue.component('CmsPage', CmsPage);
Vue.component('CmsPreview', CmsPreview);

/** Preserve CMS query param when navigating between pages */
export default function (context) {
  context.app.router.beforeEach((to, from, next) => {
    if (from.query.cms && !to.query.cms) {
      next({
        ...to,
        query: {
          ...to.query,
          cms: from.query.cms,
        },
      });
    } else {
      next();
    }
  });
}
