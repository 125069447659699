import {
  extractLocalSettingsExpandedLinks,
  extractProperty,
  generateLinkFromTarget,
} from '../utils';
import { ComposableContext } from '../../types';
import { CmsSite } from '@vf/api-contract';

export const geminiProductCard = (
  data: any,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  return {
    component: 'gemini/ProductCard',
    attrs: {
      'data-id': data.id,
    },
    props: {
      title: extractProperty(data, 'title', ''),
      pattern: extractProperty(data, 'cssPattern', ''),
      price: extractProperty(data, 'price', ''),
      price2: extractProperty(data, 'price2', ''),
      salesPrice: extractProperty(data, 'salesPrice', ''),
      url: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'url')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      imgUrl: generateLinkFromTarget(
        extractLocalSettingsExpandedLinks(data, 'imgUrl')[0],
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      imgH: extractProperty(data, 'imgH', ''),
      imgW: extractProperty(data, 'imgW', ''),
      imgAlt: extractProperty(data, 'title', ''),
    },
  };
};
