import { easyBannerImage } from './easyBannerImage';
import { mapDefaultGifVideoOptions } from './utils/mapDefaultVideoOptions';
import { CMContentHero, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';

export const easyBannerAnimation = (
  data: CMContentHero,
  _context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const easyBannerData = easyBannerImage(
    data,
    _context,
    siteConfiguration,
    cmsBaseUri
  );

  return mapDefaultGifVideoOptions(easyBannerData);
};
