const DATA_PROTECTED_FIELDS = [
  'firstname',
  'lastname',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'email',
  'phone',
  'phonenumber',
  'altfirstname',
  'altlastname',
  'altemail',
];

export type DataProtectedInfo = {
  value: string;
  isProtected: boolean;
};

const isFieldDataProtected = (fieldName: string): boolean => {
  return DATA_PROTECTED_FIELDS.indexOf(fieldName.toLowerCase()) !== -1;
};

export const mapValuesToDataProtectedInfos = (
  values: string[],
  isProtected: boolean
): DataProtectedInfo[] => {
  return values.map((v) => ({ value: v, isProtected: isProtected }));
};

export const mapToDataProtectedInfo = (
  data: Record<string, any>,
  template: string
): DataProtectedInfo => {
  if (!data || !template) return null;
  let isProtected = false;
  const value = template.replace(/{{(\w+)}}/g, (_, fieldName) => {
    isProtected =
      isProtected || (!!data[fieldName] && isFieldDataProtected(fieldName));
    return data[fieldName] ?? '';
  });
  return {
    value: value ? value : null,
    isProtected: isProtected,
  };
};

export const mapToDataProtectedInfos = (
  data: Record<string, any>,
  templates: string[]
): DataProtectedInfo[] => {
  if (!templates) return [];
  const mapped = templates.map((template) =>
    mapToDataProtectedInfo(data, template)
  );
  return mapped.filter((item) => !!item?.value);
};

export const pushUnprotected = (
  value: string,
  target: DataProtectedInfo[]
): void => {
  target.push({ value, isProtected: false });
};

export const pushProtected = (
  value: string,
  target: DataProtectedInfo[]
): void => {
  target.push({ value, isProtected: true });
};
