import { axios } from '../../helpers';

export const getPriceByBundlePartNumber = (
  product: any,
  baseUrl: string
): Promise<any> => {
  const url = `${baseUrl}`;

  return axios.post(url, product);
};
