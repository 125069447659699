import { AxiosResponse } from 'axios';
import { ProductReviewConfResponse } from '@vf/api-contract';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getProductReviewsConf = (
  configuration: ApiClientConfiguration
) => (query = ''): Promise<AxiosResponse<ProductReviewConfResponse>> => {
  let url: string = urlBuilder(
    endpoints.get(configuration).product.getProductReviewsConf,
    {
      config: configuration,
    }
  );
  url = query ? `${url}?${query}` : url;

  return axios.get(url, {
    headers: headerBuilder(configuration),
  });
};
