import { CMPlaceholder } from 'packages/api-contract/src/cms';

export const pdpPrice = (data: CMPlaceholder) => {
  const config = getConfigValues(data);

  return {
    component: 'pdp/ProductPrice',
    props: {
      priceType: config['price-type'],
      strikeOriginalPrice: config['strike-original-price'],
    },
  };
};

const getConfigValues = (data: CMPlaceholder) => {
  try {
    const config = data.componentConfig.find(
      (conf) => conf.name === 'pdp-price-properties'
    );

    return config.values;
  } catch (e) {
    console.error('PDP ProductPrice component error: no config found.');
  }

  return {
    'price-type': 'sale',
    'strike-original-price': true,
  };
};
