import {
  CMPlaceholder,
  OrderDetailsSidebarTranslations,
} from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

interface OrderDetailsSidebarMapping {
  component: string;
  attrs?: {
    'data-id': string;
  };
  props: {
    translations: OrderDetailsSidebarTranslations;
  };
}

export const orderDetailsSidebar = (
  data: CMPlaceholder
): OrderDetailsSidebarMapping => ({
  component: 'order/OrderDetailsSidebar',
  props: {
    translations: {
      backButtonText: getTranslation(
        data,
        'backButtonText',
        '$Back to Order History$'
      ),
      orderLabel: getTranslation(data, 'orderLabel', '$Order$'),
      trackPackageLabel: getTranslation(
        data,
        'trackPackageLabel',
        '$Track Package$'
      ),
      trackYourOrder: getTranslation(
        data,
        'trackYourOrder',
        '$Track your order$'
      ),
      trackYourShipment: getTranslation(
        data,
        'trackYourShipment',
        '$Shipment: #{{index}}: {{number}}$'
      ),
      orderedItemsLabel: getTranslation(
        data,
        'orderedItemsLabel',
        '$Items Ordered$'
      ),
      estimatedShippingLabel: getTranslation(
        data,
        'estimatedShippingLabel',
        '$Estimated Shipping$'
      ),
      estimatedTaxLabel: getTranslation(
        data,
        'estimatedTaxLabel',
        '$Estimated Tax$'
      ),
      shippingAddressLabel: getTranslation(
        data,
        'shippingAddressLabel',
        '$ShippingAddress:$'
      ),
      itemsSubTotalLabel: getTranslation(
        data,
        'itemsSubTotalLabel',
        '$Items Subtotal:$'
      ),
      discountLabel: getTranslation(data, 'discountLabel', '$Discount:$'),
      orderTotalLabel: getTranslation(data, 'orderTotalLabel', '$Order Total$'),
      free: getTranslation(data, 'free', '$Free$'),
      returnLinkButton: getTranslation(
        data,
        'returnLinkButton',
        '$Create a Return$'
      ),
      trackReturnLinkButton: getTranslation(
        data,
        'narvar.trackReturnLinkButton',
        '$Track My Return$'
      ),
      viewReturnLabel: getTranslation(
        data,
        'narvar.viewReturnLabel',
        '$View Return$'
      ),
      printPackingSlipButtonText: getTranslation(
        data,
        'printPackingSlipButtonText',
        '$Print Packing Slip$'
      ),
      printReturnLabelButtonText: getTranslation(
        data,
        'printReturnLabelButtonText',
        '$Print Return Label$'
      ),
      detailsInfoLabel: getTranslation(data, 'detailsInfoLabel', '$Details$'),
      detailsIntroText: getTranslation(
        data,
        'detailsIntroText',
        '$We will send you a notification when your order is ready for pickup$'
      ),
      detailsQuestionText: getTranslation(
        data,
        'detailsQuestionText',
        '$What do I do when I get to the store?$'
      ),
      detailsInStoreStepsTexts: getTranslation(
        data,
        'detailsInStoreStepsTexts',
        [
          '$Please stop by within the next 2 days.$',
          '$Bring a valid ID and a copy of the receipt (email or paper).$',
          '$IN-STORE: let a sales associate know you’re there to pick up your order.$',
        ]
      ),
      detailsCurbsideStepsTexts: getTranslation(
        data,
        'detailsCurbsideStepsTexts',
        [
          '$Please stop by within the next 2 days.$',
          '$Bring a valid ID and a copy of the receipt (email or paper).$',
        ]
      ),
      detailsInStoreAndCurbsideStepsTexts: getTranslation(
        data,
        'detailsInStoreAndCurbsideStepsTexts',
        [
          '$Please stop by within the next 2 days.$',
          '$Bring a valid ID and a copy of the receipt (email or paper).$',
          '$IN-STORE: let a sales associate know you’re there to pick up your order.$',
        ]
      ),
      authorizationLabel: getTranslation(
        data,
        'authorizationLabel',
        '$Authorization: $'
      ),
      orderNumberLabel: getTranslation(
        data,
        'orderNumberLabel',
        '$Order Number$'
      ),
      refundIssueDateLabel: getTranslation(
        data,
        'refundIssueDateLabel',
        '$Refund Issue Date$'
      ),
      itemsInReturn: getTranslation(data, 'itemsInReturn', '$Items In Return$'),
      itemCreditSubtotal: getTranslation(
        data,
        'itemCreditSubtotal',
        '$Item Credit Subtotal$'
      ),
      shippingCreditLabel: getTranslation(
        data,
        'shippingCreditLabel',
        '$Shipping Credit$'
      ),
      shippingTaxLabel: getTranslation(
        data,
        'shippingTaxLabel',
        '$Shipping Tax$'
      ),
      returnShippingCostLabel: getTranslation(
        data,
        'returnShippingCostLabel',
        '$Return Shipping Cost$'
      ),
      salesTaxCreditLabel: getTranslation(
        data,
        'salesTaxCreditLabel',
        '$Sales Tax Credit$'
      ),
      returnTotalLabel: getTranslation(
        data,
        'returnTotalLabel',
        '$Return Total$'
      ),
      issuedToLabel: getTranslation(data, 'issuedToLabel', '$Issued To$'),
      giftCard: getTranslation(data, 'giftCard', '$Gift Card$'),
      rewardCardLabel: getTranslation(data, 'rewardCardLabel', '$Reward Card$'),
      originaleMethod: getTranslation(
        data,
        'originalMethod',
        '$Original method of payment$'
      ),
    },
  },
});
