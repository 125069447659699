//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SimplifiedEnrollmentHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    html: {
      type: Boolean,
      default: false,
    },
    hasParentTag: {
      type: Boolean,
      default: false,
    },
  },
};
