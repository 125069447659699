import { RequestBody } from '@vf/api/src/types/PdfBuilder';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';
import { axios, headerBuilder } from '../../helpers';

export const printPackingSlip = (
  configuration: ApiClientConfiguration
) => async (body: RequestBody): Promise<any> => {
  return axios.post(
    endpoints.get(configuration).pdfPrinter.printPackingSlip,
    body,
    {
      headers: headerBuilder(configuration, {
        Accept: 'application/pdf',
      }),
    }
  );
};
