import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation, getDynamicField } from './utils';

export const emailPreferencesForm = (data: CMPlaceholder): any => {
  return {
    component: 'account/EmailPreferencesForm',
    props: {
      translations: {
        heading: getTranslation(data, 'heading', '$Email Preferences$'),
        emailLabel: getTranslation(data, 'emailLabel', '$Email Address$'),
        subscribeText: getTranslation(
          data,
          'subscribedText',
          '$SignUp for updates about our product news and special promotions.$'
        ),
        unsubscribeText: getTranslation(
          data,
          'unsubscribeText',
          '$You are currently subscribed to our email updates. You are on our radar to receive the latest product news and special promotions.$'
        ),
        unsubscribeInfoText: getTranslation(
          data,
          'unsubscribeInfoText',
          '$If you unsubscribe now, you will miss out on all the latest athlete news, expedition updates, cool gear and exclusive offers from The North Face.$'
        ),
        unsubscribeConfirmationText: getTranslation(
          data,
          'unsubscribeConfirmationText',
          '$Are you sure want to unsubscribe?$'
        ),
        signUpCheckbox: getTranslation(
          data,
          'signUpCheckbox',
          '$Sign up for the lates Vans news, events & offers$'
        ),
        subscribeButtonText: getTranslation(
          data,
          'subscribeButtonText',
          '$Subscribe$'
        ),
        saveButtonText: getTranslation(data, 'saveCTA', '$Save$'),
        cancelButtonText: getTranslation(data, 'cancelButtonText', '$Cancel$'),
        unsubscribeButtonText: getTranslation(
          data,
          'unsubscribeButtonText',
          '$Unsubscribe$'
        ),
        validationMessages: getTranslation(data, 'validationMessages', {
          requiredError: '$Required field$',
          emailError: '$Please provide valid e-mail address.$',
        }),
        emailPreferencesSaved: getTranslation(
          data,
          'emailPreferencesSaved',
          '$Email preferences saved$'
        ),
        setBasicInformationSuccess: getTranslation(
          data,
          'setBasicInformationSuccess',
          '$ROFILE INFORMATION HAS BEEN SAVED.$'
        ),
        setBasicInformationError: getTranslation(
          data,
          'setBasicInformationError',
          '$CANNOT UPDATE PROFILE INFORMATION.$'
        ),
        headerLanguagePreference: getTranslation(
          data,
          'headerLanguagePreference',
          '$Language preferences$'
        ),
      },
      showLanguagePreference: getDynamicField(
        data,
        'showLanguagePreference',
        false
      ),
      showUnsubscribeConfirmation: getDynamicField(
        data,
        'showUnsubscribeConfirmation',
        false
      ),
      componentVariant: getDynamicField(data, 'componentVariant', 'VARIANT_A'),
    },
  };
};
