import { CMCollection } from '@vf/api-contract';
import { extractProperty, getTeaserStyle } from './utils';
import type { FaqItem } from '@vf/api-client';

export const seoFaqs = (data: CMCollection) => {
  const questions: FaqItem[] = [];

  //Alphabetically sort object entries to not break order of elements
  const sortedEntries = Object.entries(
    data.localSettings.dynamicFields
  ).sort((a, b) => a[0].localeCompare(b[0]));
  //Extracting questions and corresponding answers into the object and filling array of questions
  for (const [key, value] of sortedEntries) {
    if (key.startsWith('question')) {
      const keyIndex = key.replace(/\D/g, '');
      const question: FaqItem = {
        question: value as string,
        answer: data.localSettings.dynamicFields[`answer${keyIndex}`],
      };
      questions.push(question);
    }
  }
  return {
    component: 'shared/SeoFaq',
    props: {
      title: extractProperty(data, 'teaserTitle'),
      titleStyles: {
        headingLevel: getTeaserStyle(data, 'teaserTitleHeadingLevel'),
        headingModifier: getTeaserStyle(data, 'teaserTitleHeadingStyle'),
        titleFontFamily: getTeaserStyle(data, 'teaserTitleFontFace'),
        titleFontSize: getTeaserStyle(data, 'teaserTitleFontSize'),
        titleFontWeight: getTeaserStyle(data, 'teaserTitleFontWeight'),
        titleColor: getTeaserStyle(data, 'teaserTitleColor'),
        textBackgroundColor: getTeaserStyle(data, 'teaserTextBackgroundColor'),
        titleClassModifiers: getTeaserStyle(data, 'teaserTitleClassModifiers'),
      },
      questions,
    },
  };
};
