import { urlBuilder, getHistory } from '../../helpers';
import { endpoints } from '../../config';
import { RequestHeader } from '../../types';
import { PaginationObject, OrderHistoryObject } from '@vf/api-contract';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';

export const getOrderHistory = (configuration: ApiClientConfiguration) => (
  data: PaginationObject,
  { usid = null }: RequestHeader = {}
): Promise<AxiosResponse<OrderHistoryObject>> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).customer.getOrderHistory,
    {
      config: configuration,
    }
  );

  return getHistory(url, data, configuration, usid);
};
