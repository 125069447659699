import { CMTeaser } from '@vf/api-contract';
import { extractProperty } from './utils';
import { getTeaserStyle } from './utils/getTeaserStyle';

export const liveChatButtonSidebar = (data: CMTeaser) => {
  return {
    component: 'shared/FloatingChatButton',
    props: {
      title: extractProperty(data, 'teaserTitle', ''),
      cssClass: getTeaserStyle(data, 'teaserClassModifiers'),
      backgroundColor: getTeaserStyle(data, 'teaserTitleBackgroundColor'),
      titleColor: getTeaserStyle(data, 'teaserTitleColor'),
    },
  };
};
