import { FilterItem, Image, Video, VideoObject, ImageObject } from './entities';

export type PriceOverrideTranslations = {
  /** Heading fo price override */
  headingTitle: string;
  /** Price input label */
  priceLabel: string;
  /** Comment text area label */
  commentLabel: string;
  /** Override CTA button text */
  overrideButtonText: string;
  /** Reset button text */
  resetButtonText: string;
  /** Successful override message */
  overrideSuccessfulMessage?: string;
  /** Successful reset message */
  resetSuccessfulMessage?: string;
};

export type ProductCtaButtonTranslations = {
  buttonCTANotify: string;
  /** Button CTA Discontinued text */
  buttonCTADiscontinued: string;
  /** Button CTA Active text */
  buttonCTAActive: string;
  /** Notify box title text */
  notifyBoxTitle: string;
  /** Notify email title */
  notifyEmailTitle: string;
  /** Notify email input placeholder */
  notifyEmailPlaceholder: string;
  /** Notify button text in modal */
  notifyButtonText: string;
  /** Notify box text */
  notifyBoxTextBox: string;
  /** Validation Messages for email input */
  validationMessages: {
    /** Required error message */
    requiredError: string; // TODO: refactor to 'required' for validationMessages consistency
    /** Invalid email error message */
    emailError: string; // TODO: refactor to 'email' for validationMessages consistency
  };
  /** Notify successful subscribe message */
  notificationMessage: string;
  /** Notify subscribe error message */
  notificationErrorMessage: string;
  /** Notify already subscribed message */
  notificationAlreadySubscribedMessage: string;
  /** Sign In To Buy button text */
  signInToBuy: string;
  /** CTA text for sold out item */
  soldOutCta: string;
  /** Size label */
  sizeLabel: string;
};

export type SearchTitleTranslations = {
  /** Plural translation for items number label, for example: "Items" */
  itemsCountPlural: string;
  /** Singular translation for items number label, for example: "Item" */
  itemsCountSingular: string;
};

export interface CheckGiftCardBalanceFormTranslations {
  /** Cart number placeholder */
  cardNumberPlaceholder: string;
  /** Cart number label (not used) */
  cardNumberLabel: string;
  /** Submit button text */
  submitButton: string;
  /** Card pin input label text (not used) */
  cardPinLabel: string;
  /** Card pin input label */
  cardPinPlaceholder: string;
  /** Captcha label (not used) */
  captchaLabel: string;
  /** Security check text (not on designs so not used) */
  securityCheckText: string;
  /** Security check link text (not on designs so not used) */
  securityLinkText: string;
  /** Validation error messages */
  validationMessages: {
    /** Field is required error message */
    required: string;
    /** Field needs to have 16 digits error message */
    digits16: string;
    /** Field needs to have 4 or 8 digits error message */
    digits4or8: string;
  };
}

export type SmartGiftCtaTranslations = {
  /** Button CTA text */
  sendWithSmartGift: string;
};

export interface CheckGiftCardBalanceMessageTranslations {
  /** Text for indicate gift card number for displayed balance */
  giftCardBalanceFor: string;
}

export interface CheckoutGiftCardTranslations {
  /** Close tooltip text */
  close: string;
  /** Gift card accordion header text */
  title: string;
  /** Gift card accordion header text for not logged in customers */
  titleForNotLoggedIn: string;
  /** Card number input placeholder */
  card_number: string;
  /** Validation error messages */
  validation_messages: {
    /** Field is required error message */
    required: string;
    /** Validation message for Card Number field */
    digitsLength: string;
    /** Validation message for PIN field */
    pinLength: string;
  };
  /** Message to display when gift card is successfully applied */
  applySuccessMessage: string;
  /** Message to display when gift card is successfully removed */
  removeSuccessMessage: string;
  /** Message to display when reward card is successfully applied  */
  applyRewardCardSuccessMessage: string;
  /** Message to display when reward card is successfully removed */
  removeRewardCardSuccessMessage: string;
  /** Message to display when reward card is not successfully applied  */
  invalidRewardCardErrorMessage: string;
  /** Message to display when reward card is not successfully applied  */
  notLoggedinRewardCardErrorMessage: string;
  /** Message to display when reward card is not successfully applied  */
  notLoggedinRewardCardErrorMessageModal: string;
  /** PIN input placeholder */
  pin: string;
  /** Button text that shows up tooltip with extra information */
  info: string;
  /** Tooltip contents */
  tooltip: string;
  /** Apply button text */
  btnCTA: string;
  /** Captcha section title */
  captcha: string;
  /** Gift card applied label */
  giftCardApplied: string;
  /** Reward card applied label */
  rewardCardApplied: string;
  /** Remove gift card button text */
  removeGiftCard: string;
  /** Add another gift card title */
  addAnotherGiftCard: string;
}

// TODO: GLOBAL15-56318 remove CheckoutRewardCardTranslations
export interface CheckoutRewardCardTranslations {
  /** Reward card accordion header text */
  title: string; // TODO: GLOBAL15-56318 remove title translation
  /** Reward card information text */
  description: string; // TODO: GLOBAL15-56318 remove description translation
  /** Message to display when reward card is successfully applied */
  applySuccessMessage: string;
  /** Message to display when reward card is successfully removed */
  removeSuccessMessage: string;
  /** Card number listing heading */
  cardNumberHeading: string; // TODO: GLOBAL15-56318 remove cardNumberHeading translation
  /** Card amount listing heading */
  amount: string;
  /** Apply CTA */
  apply: string;
  /** Message to display when max reward cards number have been already applied */
  maxCardsApplied: string;
  /** Success message after a reward card apply **/
  rewardCardApplied: string; // TODO: GLOBAL15-56318 remove rewardCardApplied translation
  /** Remove card CTA **/
  removeRewardCard: string;
  /** Membership Reward Balance **/
  titleReward: string;
  /** Membership Reward Balance with Summary **/
  titleRewardWithAvailableReward: string;
  /** Text that is used for GiveX card without issue date  **/
  legacyIssuedText: string;
  /** Expires text  **/
  expires: string;
  /** Expires Summer text  **/
  issuedSummer: string;
  /** Expires Winter text  **/
  issuedWinter: string;
  /** Expires Spring text  **/
  issuedSpring: string;
  /** Apply any reward below to use on this purchase.  **/
  instructionsDefault: string;
  /** Congrats! Your reward has been applied.  **/
  instructionsOneApplied: string;
  /** Congrats! Your rewards have been applied.  **/
  instructionsMaxApplied: string;
  /** Reward Details  **/
  rewardDetails: string;
  /** Select up to 2 rewards max. **/
  selectionMax: string;
  /** Text that shows whhen Reward Details button is clicked **/
  detailsExpanded: string;
  /** This reward is temporarily processing. **/
  lockedRewardInfoText: string;
  /** Aria label for the info icon button that opens the locked reward tooltip **/
  lockedRewardToolipButtonAriaLabel: string;
  /** See More Rewards text for button to view more rewards **/
  seeMoreRewards: string;
}

export interface CheckoutFamilyRewardsTranslations
  extends Record<string, string> {
  rewardDetails: string;
  rewardDetailsDescription: string;
  availableRewards: string;
  doNotHaveAnyAvailableRewards: string;
  thisPurchaseWillEarn: string;
  saveRewardForLater: string;
  rewardAppliedStillHaveRewards: string;
  rewardAmount: string;
  vansFamilyRewards: string;
  selectAmount: string;
  orderUpdated: string;
  rewardAppliedNoRemainingRewards: string;
}

export interface GiftOptionTranslations {
  /** Item Add Gift option CTA text */
  addGiftOptionCTA: string;
  /** Item Gift Options label text */
  itemGiftOptions: string;
  /** Edit Gift Option button text */
  edit: string;
  /** Remove Gift Option button text */
  remove: string;
  /** Select Gift Option for item modal title */
  modalTitle: string;
  /** Gift Option Block Gift To Label */
  giftOptionTo: string;
  /** Gift Option Block Gift From Label */
  giftOptionFrom: string;
  /** Gift Option Block Gift Message Label */
  giftOptionMessage: string;
  /** Gift Option Block GiftBox Label */
  giftOptionGiftBox: string;
  /** Gift Option Without Gift Box Text */
  giftOptionWithoutGiftBox: string;
  /** Gift Option With Gift Box Text */
  giftOptionWithGiftBox: string;
  /** Gift Option required fields label for inputs */
  requiredFieldsInfo: string;
  /** Gift options message characters remaining text */
  charactersRemaining: string;
  /** Gift options tip description text */
  giftOptionTipDescription: string;
  /** Item gift options label text */
  giftBoxLabel: string;
  /** Gift box image alt text */
  giftBoxImageAlt: string;
  /** Save option button text */
  save: string;
  /** Cancel button text */
  cancel: string;
  /** Item gift options label text */
  giftOptionsLabel?: string;
  /** Item no gift options text */
  giftOptionsEmpty?: string;
  /** Item gift with no giftbox text */
  giftOptionsWithoutGiftBox?: string;
  /** Item gift with giftbox text */
  giftOptionsWithGiftBox?: string;
  /** Validation error messages */
  validationMessages: {
    /** Required error message */
    required: string;
    noSpecialCharacters: string;
  };
}

export interface CheckoutGiftOptionTranslations extends GiftOptionTranslations {
  /** Gift Option checkout accordion header text */
  giftOptionAccordionHeader: string;
  /** Gift Option checkout accordion header text */
  giftOptionAccordionDescription: string;
  /** Edit Gift Option accordion text */
  editGiftOptionCTA: string;
  /** In product Gift Option CTA text */
  giftOptionCTA: string;
  /** In product Gift Option is added CTA text */
  giftOptionAddedCTA: string;
  /** Choose product for Gift Option item modal subtitle */
  modalSubtitle: string;
  /** Displayed in GiftOptionModal when gift option cannot be added to the product */
  modalGiftOptionUnavailable: string;
  /** Gift Option Finished Label */
  finished: string;
  /** Gift Option form heading Text */
  formHeading: string;
}

export interface SortByOptionsTranslations {
  /** Options for sort select box */
  label: string;
  value: string;
}

export interface SearchSortByTranslations {
  /** Label for sort select box */
  sortBy: string;
  sortByOptions: [SortByOptionsTranslations];
  /** Placeholder for sort select box */
  sortLabel: string;
  /** Label for sort select options*/
  sortOptions: Record<string, string>;
}

export type SearchToggleTranslations = {
  /** Products radio button text */
  products: string;
  /** Articles radio button text */
  articles: string;
};

export type CategoryFiltersDisplayToggle = {
  /** Hide filters text */
  hideFilters: string;
  /** Hide filters aria-label */
  hideFiltersAria: string;
  /** Show filters text */
  showFilters: string;
  /** Show filters aria-label */
  showFiltersAria: string;
};

export type UtilityNavigationSmallTranslations = {
  /** Checkout tooltip text */
  checkoutTooltipText: string;
  /** Checkout tooltip button text */
  checkoutTooltipButtonText: string;
  /** Aria label for the mobile menu close button */
  closeMenuButtonAriaLabel: string;
};

export type CmsMonetateExperienceComponentVariant = {
  /** Variant ID obtained from monetate */
  id: string;
  /** Variant Label obtained from monetate */
  name: string;
  /** Variant content (CoreMedia component) */
  target: unknown;
  label?: string;
  content?: any;
};

/** List of GridElements used by ArticleGrid */
export type ArticleGridGridElements = ArticleGridGridElement[];

export enum CTAExperience {
  Customcheckerboard = 'Customcheckerboard',
  UGCEntrypoint = 'UGCEntrypoint',
  Patternmaker = 'Patternmaker',
  Randomizer = 'Randomizer',
  styleSelectorIframeCallEvent = 'styleSelectorIframeCallEvent',
}

export enum Context {
  PageContent = 'page-content',
  Modal = 'modal-content',
  QuickShop = 'quickshop',
  ShopByActivity = 'shop-by-activity',
}

export enum CheckoutContext {
  MiniCart = 'mini-cart',
  Cart = 'cart',
  Shipping = 'shipping',
  Payment = 'payment',
  Review = 'review',
  Success = 'success',
}

export enum FavoritesContext {
  Cart = 'Cart',
  SaveForLater = 'SaveForLater',
}

export enum States {
  LoggedIn = 'loggedin',
  NotLoggedIn = 'not-loggedin',
  CustomsPDP = 'customsPDP',
  NotCustomsPDP = 'not-customsPDP',
  SearchResults = 'searchResults',
  NoSearchResults = 'no-searchResults',
  CartEmpty = 'cartEmpty',
  NotCartEmpty = 'not-cartEmpty',
  LoyaltyEnrolled = 'loyalty-enrolled',
  NotLoyaltyEnrolled = 'loyalty-not-enrolled',
  EmployeeInstoreLoggedIn = 'instore-loggedin',
  EmployeeInstoreNotLoggedIn = 'instore-not-loggedin',
  EmployeeLoggedIn = 'employee-loggedin',
  EmployeeNotLoggedIn = 'employee-not-loggedin',
  InStoreDevice = 'in-store-device',
}

export enum StateConfigOperator {
  AND = 'and',
  OR = 'or',
  NOT = 'not',
}

export enum StateConfigNames {
  STATES = 'states',
  STATES_GROUP = 'states-group',
}

export type StateSingle = {
  name: StateConfigNames.STATES;
  values: {
    state: States;
  };
};

export type StateGroup = {
  name: StateConfigNames.STATES_GROUP;
  values: {
    operator: StateConfigOperator;
    states?: States[];
    segment?: Segment[];
  };
};

export type State = StateSingle | StateGroup;

export const CustomsCTAExperiences = Object.values(CTAExperience);

export const CustomsCTAExperienceTypes = {
  IFrame: 'IFrame',
  Modal: 'Modal',
};

export const CustomsCTAExperienceModalNames = {
  Customcheckerboard: 'customCheckerboard',
  UGCEntrypoint: 'ugcentrypoint',
  Patternmaker: 'patternMaker',
  Randomizer: 'randomizer',
};

export const CustomsCTAExperienceComponentNames = {
  Customcheckerboard: 'customcheckerboard',
  UGCEntrypoint: 'ugcEntryPoint',
  Patternmaker: 'patternmaker',
  Randomizer: 'randomizer',
};

/** A single GridElement, used by ArticleGrid */
export type ArticleGridGridElement = {
  /** Article title */
  title: string;
  /** Article content */
  text: string;
  /** Link to the article */
  link: string;
  /** Link label */
  linkCta: string;
  /** Article thumbnail (VFImage object) */
  image: Image;
  /** Number of columns the article should span for each screen size */
  gridRow: {
    large: number;
    medium: number;
    small: number;
  };
};
export type StickyHeaderPosition =
  | 'center'
  | 'left'
  | 'right'
  | 'space-between'
  | 'space-around'
  | 'default';
export type StickyHeaderContentPosition = {
  small: StickyHeaderPosition;
  medium: StickyHeaderPosition;
  large: StickyHeaderPosition;
};

export type CategoryPageProductsTranslations = {
  clearFilters: string;
  results: string;
};

export type CategoryPageFiltersTranslations = {
  clearFilters: string;
  results: string;
};
export type CategoryPageFitlersGroup = {
  /** Title of category page filter group */
  title: string;
  /** Array of filters */
  filters: FilterItem[];
};
export type ContentAlignProperties = {
  small: ContentAlignType;
  medium: ContentAlignType;
  large: ContentAlignType;
};

export type ContentAlignType =
  | 'center'
  | 'right'
  | 'left'
  | 'top_right'
  | 'top_left'
  | 'bottom_right'
  | 'bottom_left'
  | 'middle_center'
  | 'middle_left'
  | 'middle_right';

export type ArticleGridConfiguration = {
  'articles-per-row-small-screen': number;
  'articles-per-row-medium-screen': number;
  'articles-per-row-large-screen': number;
  'articles-per-page-small-screen': number;
  'articles-per-page-medium-screen': number;
  'articles-per-page-large-screen': number;
  'tile-show-title': boolean;
  'tile-show-body-text': boolean;
  'tile-show-read-more-cta': boolean;
  'tile-show-authors': boolean;
  'tile-show-thumbnail': boolean;
  'tile-show-tags': boolean;
  'tile-show-publication-date': boolean;
  'published-before-timestamp': number;
  'published-after-timestamp': number;
  'show-tag-filters': boolean;
  'search-term'?: string;
  'search-order': string;
};

export type ArticleGridTeaserStyleCustomization = {
  titleColor: string;
  titleFontSize: string;
  teaserClass: string;
  titleFontFamily: string;
  titleFontWeight: string;
  titleBackgroundColor: string;
  subtitleColor: string;
  subtitleFontSize: string;
  subtitleFontWeight: string;
  subtitleFontFamily: string;
  subtitleBackgroundColor: string;
};

/** A list of ShopByGridGridBlock */
export type ShopByGridGridBlocks = ShopByGridGridBlock[];

/** A GridBlock to be included in the Shop By Grid smart component */
export type ShopByGridGridBlock = {
  /** The title of the grid block. e.g. Mens, Womens, Skate, Custom, etc.*/
  title: string;
  /** Title color of the grid block.*/
  titleColor: string;
  /** Title CSS class modifiers of the grid block.*/
  titleClassModifiers: string;
  /** Heading level of the grid block.*/
  headingLevel: string;
  /** Link color of the grid block.*/
  linkColor: string;
  /** Link CSS class modifiers of the grid block.*/
  linkClassModifiers: string;
  /** An object containing the grid column spans, for a 12 column grid, for small, medium, and large screen sizes */
  gridRow: {
    /** The number of columns to span on a small screen */
    small: number;
    /** The number of columns to span on a medium screen */
    medium: number;
    /** The number of columns to span on a large screen */
    large: number;
  };
  /** The URL of an image to have be the background of the block */
  backgroundUrl: string;
  /** An array of links to include in the block */
  links: ShopByGridLink[];
  /** Flag to determine if all links in shop by block should open in new tab */
  openInNewTab: boolean;
  /** (automatic) Data extracted from specified target for analytics purposes */
  dataNavigation: string;
};

/** A link to be included in ShopByGridGridBlock */
export type ShopByGridLink = {
  /** The text of link to be shown in the UI */
  label: string;
  /** The relative path or URL to be included in the link */
  link: string;
};

/** A CategoryTeaserItem to be included in the ImageWithOverlay and VideoWithOverlay smart components */
export type CategoryTeaserItem = {
  /** The Image object */
  image?: Image;
  /** The Video object */
  video?: {
    small: VideoObject;
    medium?: VideoObject;
    large?: VideoObject;
  };
  /** The title of the teaser */
  title: string;
  /** The subtitle of the teaser */
  subtitle: string;
  /** The text below title */
  text: string;
  /** Flag to determine if use richText */
  useRichText: string;
  /** The URL for the button  */
  link: string;
  /** The URL for image link  */
  imageLink: string;
  /** Button text to redirect to the category */
  buttonText: string;
  /** Category styles for teaser item */
  styles: CategoryTeasersStyles;
  /** CTA buttons */
  ctaButtons: CTAButtons[];
};

export type CTAButtons = {
  buttonText: string;
  buttonStyle: string;
  buttonSize: string;
  buttonIcon: string;
  buttonIconPosition: string;
  buttonLink: string;
  buttonUnderline: string;
  buttonTargetComponent?: string;
};

export type CategoryTeasersStyles = {
  /** Title color of the teaser */
  titleColor: string;
  /** Title backgroundcolor of the teaser */
  titleBackgroundColor: string;
  /** Title font size of the teaser */
  titleFontSize: string;
  teaserClass: string;
  /** Title font weight of the teaser */
  titleFontWeight: string;
  /** Title font family of the teaser */
  titleFontFamily: string;
  /** Title heading level of the teaser */
  headingLevel: string;
  /** Title CSS class modifiers of the teaser */
  titleClassModifiers: string;
  /** Text color of the teaser */
  textColor: string;
  /** Text background color of the teaser */
  textBackgroundColor: string;
  /** Text CSS classes modifiers of the teaser */
  textClassModifiers: string;
  subtitleColor: string;
  subtitleFontSize: string;
  subtitleFontWeight: string;
  subtitleFontFamily: string;
  subtitleBackgroundColor: string;
  subtitleClassModifiers: string;
};

/** Class modifier for title styling */
export type HeadingTitleStylingModifier =
  | 'large-title'
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'title-4'
  | 'title-5'
  | 'title-6'
  | 'alt-title-1'
  | 'alt-title-2'
  | 'alt-title-3';

/** Class modifier for subtitle styling */
export type HeadingSubtitleStylingModifier = 'subtitle-1' | 'subtitle-2';

export interface HeadingTeaserStyles {
  /** Custom class modifier for title styling */
  titleClassModifiers: string;
  /** Custom class modifier for subtitle styling */
  subtitleClassModifiers: string;
  /** Custom class modifier for heading teaser styling */
  classModifiers: string;
  /** Title color. */
  titleColor: string;
  /** Subtitle color. */
  subtitleColor: string;
  /** Title background color. */
  titleBackgroundColor: string;
  /** Title text align */
  titleTextAlign?: string;
  /** Subtitle text align */
  subtitleTextAlign?: string;
}
export interface KlarnaModalAndThreshold {
  modal: {
    /** Klarna modal title */
    title: {
      /** Title to display inside Klarna modal when product price is in threshold */
      inThresholdLabel: string;
      /** Title to display inside Klarna modal when product price is below threshold */
      belowThresholdLabel: string;
    };
    /** Klarna modal subtitle */
    subtitle: string;
    /** Array of Klarna purchase steps */
    listItem: string[];
    /** Klarna modal legal disclaimer text*/
    disclaimerText: string;
    /** Klarna modal close button text*/
    closeLabel: string;
  };
}

export interface KlarnaIntegration extends KlarnaModalAndThreshold {
  labels: {
    /** Label for case order can not be payed by klarna*/
    belowThresholdLabel: string;
    /** Label for case order can be payed by klarna*/
    inThresholdLabel: string;
  };
}

export interface CheckoutPromoCodeTranslations {
  /** Close tooltip text */
  close: string;
  /** Promo code title text */
  promoCodeTitle: string;
  /** Promo code translations */
  promo_code: {
    /** Promo code heading */
    title: string;
    /** Promo code input label */
    label: string;
    /** Promo code apply button text*/
    btnApply: string;
    /** Promo code remove button text*/
    removePromo: string;
    /** Promo code info message text */
    info: string;
    /** Promo code tooltip text */
    tooltip: string;
    /** Add another promo title */
    addAnother: string;
    /** Promotion applied text */
    promoApplied: string;
    /** Notifications translations */
    notifications: {
      /** Text of the notification after applying the coupon */
      success: string;
      /** Text of the notification after removing the coupon */
      removed: string;
      /** Error message */
      error: string;
    };
  };
  /** Checkout promo code translations */
  notifications: {
    error: string;
    appliedNotApplicable: string;
    applied: string;
    removed: string;
  };
  promoNotApplied: string;
  promoApplied: string;
  couponApplied: string;
  promoNotActive: string;
}

export interface CheckoutLogInTranslations {
  /** Heading with text to continue as a guest */
  continueAsAGuest: string;
  /** Button text for log in CTA */
  logIn: string;
  /** Text for cart merged notification */
  cartMergedNotification: string;
}

export interface CheckoutShippingInfoNotificationTranslations {
  /** Heading text */
  heading: string;
  /** Subtitle line 1 */
  subtitle_line1: string;
  /** Subtitle line 2 */
  subtitle_line2: string;
  /** Address updated notification text */
  address_updated: string;
  /** Click to see changes link text */
  click_to_see_changes: string;
  /** Use suggestion button text */
  use_suggested: string;
  /** Use original reset button text */
  use_original: string;
}

/** Union type for ProductLine containing translations for all use cases */
export type ProductLineTranslations =
  | CartProductsTranslations
  | GiftOptionTranslations;

export interface CartProductsTranslations {
  /** Edit button text */
  edit: string;
  /** Remove button text */
  remove: string;
  /** Text for availability info label */
  availability: string;
  /** Color label text */
  color: string;
  /** Size label text */
  size: string;
  /** Product description column header */
  itemDescription: string;
  /** Text for item count label  */
  item: string;
  /** Text for items count label  */
  items: string;
  /** Price column header */
  price: string;
  /** Quantity column header */
  quantity: string;
  /** Total column header */
  total: string;
  /** Aria Label text for quantity select */
  ariaLabelQty: string;
  /** Save for later button text */
  saveForLater: string;
  /** Saved for later label text */
  savedForLater: string;
  /** Notification text when product has been saved for later */
  savedForLaterNotification: string;
  /** Move to Cart button text */
  moveToCart: string;
  /** Notification text when product has been moved to cart from saved for later section */
  movedToCartNotification: string;
  /** Save To Favorites button text */
  saveToFavorites: string;
  /** Notification text when product has been added to favorites */
  addedToFavoritesNotification: string;
  /** Notification text when product has been moved to favorites */
  moveToFavoritesNotification: string;
  /** Notification text when product has been moved to favorites for authorised users */
  moveToFavoritesNotificationAuthorised: string;
  /** View Favorites button text */
  viewFavorites: string;
  /** Text for customs seperator */
  customs: string;
  /** Flash messages  */
  flashMessages: {
    /** Flash message to display when more specific message is not available */
    DefaultFlashMessage: string;
    /** Flash message to display when product in the cart is invalid */
    InvalidProductItem: string;
    /** Flash message to display when product in the cart is not available */
    ProductItemNotAvailable: string;
    /** Flash message to display when product in the cart has to low stock quantity */
    ProductLineItemInventoryMissing: string;
    /** Flash message to display when product has exceeded the maximum quantity */
    ProductItemMaxQuantity: string;
    /** Flash message to display when product have been updated to reflect the changes. */
    ProductLineItemFullInventoryMissing: string;
    /** Flash message to display when product is no longer available (offline) */
    ProductLineItemNoLongerAvailable: string;
    /** Flash message to display when product is not eligible for gift option */
    ProductLineItemIsNotEligibleForGiftOption: string;
    PickupToStsTransition: string;
    PickupToSthTransition: string;
    StsToSthTransition: string;
    StsToPickupTransition: string;
    ProductStyleMaxQuantity: string;
  };
  /** Text for no added items */
  noAddedItemsError: string;
  /** Contents of store selector link tooltip */
  selectStoreTooltip: string;
  /** Free shipping radio button label text */
  freeShipping: string;
  /** In store pickup radio button label text */
  inStorePickup: string;
  /** Curbside pickup radio button label text */
  curbsidePickup: string;
  /** STS radio button label text */
  sts: string;
  /** Close tooltip button text */
  close: string;
  /** Change link text */
  change: string;
  /** Item not available radio button label text */
  itemNotAvailable: string;
  /** Free pickup radio button label text */
  freePickup: string;
  /** Select store radio button label link text */
  selectStore: string;
  /** Displayed at ShippingSelector when BOPIS collides with gift option */
  selectStoreRemoveGiftOption: string;
  /** Ship it radio button label text */
  shipIt: string;
  /** In-store availability checking modal */
  storeAvailabilityModal: {
    /** Cancel button text */
    cancelCTA: string;
    /** Postal code field label */
    storesNearLabel: string;
    /** Distance field label */
    withinLabel: string;
    /** Continue button text */
    continueCTA: string;
    /** No results found message text */
    noStoresWithinRangeError: string;
    /** Return to shopping link text */
    returnToShoppingCTA: string;
    /** Modal title text */
    title: string;
    /** Kilometer unit text */
    km: string;
    /** Mile unit text */
    miles: string;
    /** STS available label */
    stsAvailableLabel: string;
    validationMessages: {
      required: string;
      maxLength: string;
    };
    /** Displayed at modal in ShippingSelector for avaiability modal */
    distanceSuffix: string;
    /** Displayed at modal in ShippingSelector when BOPIS store collides with gift option */
    giftOptionWarning: string;
  };
  /** Text for removed from cart message */
  removedFromCartMessage: string;
  /** Text for removed from saved for later message */
  removedFromSavedForLaterMessage: string;
  /** Text for price override */
  priceOverride: PriceOverrideTranslations;
  /** Custom text for max quantity error */
  maxQuantityErrorMessage: string;
  /** Custom error messages based on removing rason literals coming from API */
  removingReasons: { [key: string]: string };
  /** General error message to display at the top when some of products in cart has an error  */
  productItemHasErrorMessage: string;
  /** Header for accordion if component renders only error info  */
  accordionLabel: string;
  /** Promo code applied Label */
  itemPromoAppliedLabel: string;
  /** AddGiftOption translations object */
  addGiftOption: GiftOptionTranslations;
  free: string;
  giftItemPickupNotAvailable: string;
  availabilityCustom: string;
  outOfStock: string;
  itemsCount: string;
  moveToWishList: string;
  useSuccessNotification: string;
  excludedFromAthleteMessage: string;
  excludedFromAthleteDueSale: string;
  /** pick up not available text for shipping option name  */
  pickUpNotAvailable: string;
}

export interface CartProductQuantity {
  /** Value id */
  id: number;
  /** Numeric quantity value*/
  value: string;
}

export interface CartUpsellTranslations {
  /** Add to cart button text */
  addToCartButtonText: string;
  /** aria-label attribute value */
  ariaLabel: string;
  /** Default option for a multi sized upsell */
  defaultOption: string;
  /** Out of Stock label, for example: "Out of Stock" */
  outOfStock: string;
}

export interface ProductUpsellTranslations {
  /** Checkbox upsell Details button text */
  details: string;
  extras: string;
  title: string;
}

export interface EmptyCartInfoTranslations {
  /** Empty cart heading text */
  heading: string;
  /** Empty cart info text below heading */
  text: string;
  /** Continue shopping button text */
  buttonText: string;
  /** Empty cart zero items text above heading */
  zeroItemsText: string;
  /** Share cart expired heading text */
  shareCartExpiredHeading: string;
  /** Share cart expired button text */
  shareCartExpiredButtonText: string;
  /** Text for no added items */
  noAddedItemsError: string;
  /** Custom text for max quantity error */
  maxQuantityErrorMessage: string;
  /** Custom error messages based on removing rason literals coming from API */
  removingReasons: { [key: string]: string };
}

export interface ShareMyCartModalWindowTranslations {
  /** Share your cart modal window title */
  title: string;
  /** Share your cart modal window content (regular text) */
  content: string;
  /** Close Button text */
  closeButtonText: string;
  /** Copy to clipboard Button text */
  copyButtonText: string;
  /** Copy to clipboard success notification text */
  copySuccessNotificationText: string;
  /** Copy to clipboard failed notification text */
  copyFailedNotificationText: string;
  /** Error notification to display when there is an error during sharing the cart */
  shareCartError: string;
}

export interface ShareMyFavoritesModalWindowTranslations {
  /** Share your favorites modal window title */
  title: string;
  /** Share your favorites modal window content (regular text) */
  copyLinkMark: string;
  /** Close Button text */
  closeWindowButton: string;
  /** Copy to clipboard Button text */
  copyLinkButton: string;
  /** Copy to clipboard success notification text */
  copySuccessNotificationText: string;
  /** Copy to clipboard failed notification text */
  copyFailedNotificationText: string;
  /** Error notification to display when there is an error during sharing the favorites */
  shareFavoritesError: string;
}

export interface CheckoutSidebarTranslations {
  /** Tooltip close text */
  close: string;
  /** Quantity text */
  quantity: string;
  /** Order Total label text */
  orderTotal: string;
  /** Before tax info text */
  beforeTax: string;
  /** Edit button text */
  edit: string;
  /** Shipping To  text */
  shippingTo: string;
  /** Show tooltip aria label text for button */
  showTooltip: string;
  /** Close tooltip aria label text for button */
  closeTooltip: string;
  /** Order summary label text */
  orderSummary: string;
  /** Items subtotal label text */
  itemSubtotal: string;
  /** Estimated shipping label text */
  estimatedShipping: string;
  /** Shipping label text */
  shipping: string;
  /** Tax info label text */
  taxLabel: string;
  /** Estimated tax ax info label text */
  estimatedTax: string;
  /** Promotion label text */
  promotionLabel: string;
  /** Promotion info text */
  promotionDescription: string;
  /** Shipping description text */
  shippingDescription: string;
  /** Shipping tooltip content */
  shippingTooltip: string;
  /** Tax tooltip content */
  taxTooltip: string;
  /** Proceed to checkout button text */
  proceedToCheckoutButton: string;
  /** Proceed to billing button text */
  proceedToBillingButton: string;
  /** Place order button text */
  placeOrderButton: string;
  /** Or connector text*/
  or: string;
  /** Currency code text */
  currency: string;
  /** Share button text */
  shareButton: string;
  /** Continue shopping button text */
  continueShoppingButton: string;
  /** Edit cart link text */
  editCart: string;
  /** Free shipping value text */
  free: string;
  /** Information above Continue to billing button */
  ageConfirmation: string;
  /** Information above place order button */
  privacyPolicy: string;
  /** Gift card label text */
  giftCardLabel: string;
  /** Reward card label text */
  // TODO: GLOBAL15-56318 remove rewardCardLabel after checkout redesign
  rewardCardLabel: string;
  /** Remove button text */
  remove: string;
  /** Message displayed after successful gift card removal */
  removeSuccessMessage: string;
  /** Message displayed after successful reward card removal */
  removeRewardCardSuccessMessage: string;
  /** Product list translations */
  productList: {
    qty: string;
    remove: string;
    customItems: string;
    giftBox: string;
    giftBoxPrice: string;
    inStorePickup: string;
    commonNarvarAndPickupLabel: string;
    excludedFromAthleteMessage: string;
    excludedFromAthleteDueSale: string;
    giftWithPurchaseMessage: string;
    free: string;
    /** Plural translation for items number label, for example: "Items" */
    itemsCountPlural: string;
    /** Singular translation for items number label, for example: "Item" */
    itemsCountSingular: string;
  };
  /** Remove code message */
  removeCodeMessage: string;
  /** Promotion not applied label */
  promotionNotAppliedLabel: string;
  /** Promotion not active label */
  promotionNotActiveLabel: string;
  /** Promotion not active error message */
  promotionNotActiveErrorMessage: string;
  /** Pickup property name */
  pickup: string;
  /** Pickup property tooltip */
  pickupTooltip: string;
  /** Item summary pickup info label */
  inStorePickup: string;
  /** STS property tooltip */
  stsTooltip: string;
  /** Athlete discount label */
  athleteDiscount: string;
  /** Applied Vouchers Label */
  appliedVouchersLabel: string;
  /** Reward summary property label */
  summerReward: string;
  /** Reward summary property label */
  winterReward: string;
  /** Reward summary property label */
  springReward: string;
  /** Legacy reward label */
  legacyReward: string;
}

export interface CheckoutSidebarTopTranslations {
  /** Order Total label text */
  orderTotal: string;
  /** Before tax info text */
  beforeTax: string;
  /** Information above Continue to billing button */
  ageConfirmation: string;
  /** Information above place order button */
  privacyPolicy: string;
}

export interface OrderStatusFormTranslations {
  /** Form heading text */
  heading: string;
  /** Sign in link text */
  signInLinkText: string;
  /** Email address or last name label text */
  emailOrLastNameLabel: string;
  /** Order number label text */
  orderNoLabel: string;
  /** Order number tooltip text */
  orderNoTooltip: string;
  /** Submit button text */
  submitButtonText: string;
  /** Cancel button text */
  cancelButtonText: string;
  /** Error message text */
  errorMessageText: string;
  /** Fields required label text */
  fieldsRequiredText: string;
  /** tooltip helper label text */
  tooltipHelperText: string;
  /** tooltip internal text */
  contactUsText: string;
  /** Customer purchased items radio label text */
  purchasedItemsButtonText: string;
  /** Customer received items as gift radio label text */
  receivedItemsButtonText: string;
  /** Zip code inupt label text */
  zipCodeLabel: string;
  /** Zip code tooltip text */
  zipCodeTooltip: string;
  /** Tooltip close button text */
  tooltipCloseButton: string;
  /** Validation messages object to display on FE. */
  validationMessages: {
    /** Required field rule message */
    requiredError: string;
    /** Order number rule message */
    orderNumberError: string;
    /** Email address rule message */
    emailOrLastNameError: string;
    /** Zip code rule message */
    zipCodeError: string;
  };
  narvar?: NarvarOrderStatusFormTranslations;
}

interface NarvarOrderStatusFormTranslations
  extends OrderStatusFormTranslations {
  signInText: string;
  closeButtonText: string;
}

export interface CarouselTranslations {
  /** Icon alternative text */
  iconAltText: string;
  /** CTA heading text */
  ctaText: string;
  /** Accessibility Labels for Carousel Controls/Content */
  accessibility: {
    /** screen reader carousel title */
    carouselTitleLabel: string;
    /** arrow nav button label for previous slide */
    carouselNavPreviousSlideLabel: string;
    /** arrow nav button label for next slide */
    carouselNavNextSlideLabel: string;
  };
}

export interface OrderDetailsSidebarTranslations {
  /** Back to order history button text */
  backButtonText: string;
  /** Order label text */
  orderLabel: string;
  /** Package tracking number label text */
  trackPackageLabel: string;
  /** Ordered items count label text */
  orderedItemsLabel: string;
  /** Sub Total amount label text */
  itemsSubTotalLabel: string;
  /** Discount amount label text */
  discountLabel: string;
  /** Estimated shipping label text */
  estimatedShippingLabel: string;
  /** Estimated tax label text */
  estimatedTaxLabel: string;
  /** Shipping Address label text */
  shippingAddressLabel: string;
  /** Order total label text */
  orderTotalLabel: string;
  /** When Shipping Price is zero */
  free: string;
  /** Tracking order link text */
  trackYourOrder: string;
  /** Tracking shipment link text */
  trackYourShipment: string;
  /** Label for Create a Return Link */
  returnLinkButton: string;
  /** Label for Track My Narvar Return Link */
  trackReturnLinkButton: string;
  /** Label for View Return Button */
  viewReturnLabel: string;
  /** Print packing slip button text */
  printPackingSlipButtonText?: string;
  /** Print return label button text */
  printReturnLabelButtonText?: string;
  /** Details Information text */
  detailsInfoLabel: string;
  /** Details introduction text */
  detailsIntroText: string;
  /** Details question text */
  detailsQuestionText: string;
  /** Details steps texts for in-store pickup*/
  detailsInStoreStepsTexts: string[];
  /** Details steps texts for Curbside pickup*/
  detailsCurbsideStepsTexts: string[];
  /** Details steps texts for in-store or Curbside pickup*/
  detailsInStoreAndCurbsideStepsTexts: string[];
  /** Authorization aka the return number */
  authorizationLabel: string;
  /** Label for the order number */
  orderNumberLabel: string;
  /** Date of the return */
  refundIssueDateLabel: string;
  /** Amount of items in the return */
  itemsInReturn: string;
  /** Amount of money you are getting back pre tax*/
  itemCreditSubtotal: string;
  /** Amount of shipping money you are getting back pre tax */
  shippingCreditLabel: string;
  /** Amount of shipping tax money you are getting back */
  shippingTaxLabel: string;
  /** How much the return shipping costs you */
  returnShippingCostLabel: string;
  /** Amount of sales tax money you are getting back */
  salesTaxCreditLabel: string;
  /** The money you are actually getting back*/
  returnTotalLabel: string;
  /** What form of payment is receiving the above money */
  issuedToLabel: string;
  /** Gift card translation if the money are sent to a gift card via mail */
  giftCard: string;
  /** Reward card label */
  rewardCardLabel: string;
  /** Originale method if the money are not sent via gift card */
  originaleMethod: string;
}

export interface OrderDetailsTranslations extends GiftOptionTranslations {
  /** Shipping info label text */
  shippingInfoLabel: string;
  /** Shipping to label text */
  shippingToLabel: string;
  /** Shipping method label text */
  shippingMethodLabel: string;
  /** Address label text */
  addressLabel: string;
  /** Billing info label text */
  billingInfoLabel: string;
  /** Billing to label text */
  billingToLabel: string;
  /** Item summary label text */
  itemSummaryLabel: string;
  /** Package tracking label text */
  trackPackageLabel2: string;
  /** Item description label text */
  itemDescription: string;
  /** Quantity label text */
  quantity: string;
  /** Price label text */
  price: string;
  /** Order total label text */
  total: string;
  /** Pending items heading text */
  pendingItems: string;
  /** Item color label text */
  color: string;
  /** Item size label text */
  size: string;
  /** Item quantity label text */
  qty: string;
  /** Alternate Text for Shipment Label */
  shipmentSummaryArrival: string;
  /** Text when there's returned items */
  returnedItemsText: string;
  /** Text for return button */
  returnItemsButtonText: string;
  /** Text for the items summary in the details return step */
  selectItemsToReturnSummaryHeading: string;
  /** Text for the items summary in the summary return step */
  itemsToReturnSummaryHeading: string;
  /** Text for the items summary in the confirmation return step */
  itemsInReturnSummaryHeading: string;
  /** Text for confirm return button */
  confirmReturnsButtonText: string;
  /** Link text to Returns Page */
  returnedItemsLinkText: string;
  /** Reason select default option text for Returns Detail Page */
  reasonDropdownLabel: string;
  /** Array containing list of return reasons */
  returnReasons: string[];
  /** Label for Cancelled Items Table */
  cancelledItemsLabel: string;
  /** Object item with shipping method's durations */
  // TODO: GLOBAL15-6675 check if shippingDurationInfo is still valid
  shippingDurationInfo: Record<string, { line1: string; line2: string }>;
  /** Object item with paymentMethodLabel label */
  paymentMethodLabel: Record<string, string>;
  /** Payment label text */
  paymentLabel: string;
  /** Credit Card Info Text */
  paymentTextWithCardType: string;
  /** Credit Card and Gift Card Info Text */
  paymentTextWithoutCardType: string;
  /** Processing label text */
  processingLabel: string;
  /** Shipped label text */
  shippedLabel: string;
  /** Return created label text */
  returnCreatedLabel: string;
  /** Return received label text */
  returnReceivedLabel: string;
  /** Cancelled label text */
  cancelledLabel: string;
  /** Picked label text */
  pickedLabel: string;
  /** Ready for Customer Pickup label text */
  readyPickLabel: string;
  /** Shipped to Store label text */
  shippedToStoreLabel: string;
  /** Pick Up Expired label text */
  pickExpiredLabel: string;
  /** Shipment data being processed message text */
  shipmentDataProcessedMessage: string;
  /** When Shipping Price is zero */
  free: string;
  /** Pickup info label text */
  pickupInfoLabel: string;
  /** Pickup location label text */
  pickupLocationLabel: string;
  /** Pickup person label text */
  pickupPersonLabel: string;
  /** Alt Pickup person label text */
  altPickupPersonLabel: string;
  /** Reason for the the summary column in case of returns */
  reason: string;
  /** Aria label for cart products table */
  ariaLabel: string;
  /** Label for pickup information */
  pickupFromLabel: string;
  /** Notification text for the customer */
  notificationText: string;
  /** Label for tracking number listed in separate line */
  tracking: string;
  /** Label for Split Shipment return modal title */
  selectReturnToTrackLabel: string;
  /** Label for item return date */
  returnDateLabel: string;
  /** Label for Start a new Narvar Return Link */
  startReturnLinkButton: string;
  /** Label for Track My Narvar Return Link */
  trackReturnLinkButton: string;
  legacyReward: string;
  summer: string;
  winter: string;
  spring: string;
}

export type ShipmentTrackReturnModalTranslations = OrderDetailsTranslations;

export interface ReturnHeadingTranslations {
  returnSubtitle: string;
  titles: {
    details: string;
    summary: string;
    confirmation: string;
    postReturn: string;
  };
}

export interface ReturnStepsTranslations {
  printPackingSlipButtonText: string;
  printReturnLabelButtonText: string;
  stepLabelOne: string;
  stepLabelTwo: string;
  summaryConfirmText: string;
  summaryConfirmSubText: string;
  radioOptions: {
    free: string;
    paid: string;
  };
  radioOptionsTooltips: {
    free: string;
    paid: string;
  };
}

export interface BackToTopButtonTranslations {
  /** Title for back to top button link */
  title: string;
  /** Aria label for back to top button */
  ariaLabel: string;
}

export type BackToTopButtonVariants = 'floating' | 'link';

export interface AccountSubNavigation {
  /** Navigation items content */
  items: AccountSubNavigationItem[];
}

export interface AccountSubNavigationItem {
  /** Title to show on the page. */
  title: string;
  /** URL to the resource. */
  link: string;
  /** URL string */
  url: string;
  _states: States[];
  _segments: Segment[];
}

export interface AddressSummaryTranslations {
  heading: string;
  text: string;
  textNoAddress: string;
  nameLabel: string;
  nameText: string;
  phoneLabel: string;
  addressLabel: string;
  ctaLink: string;
  ctaText: string;
  ctaTextNoAddress: string;
}

export interface BasicInformationTranslations {
  /** Form heading text */
  heading: string;
  /** Form subheading text */
  subheading: string;
  /** First subtitle text */
  firstSubtitle: string;
  /** Second subtitle text */
  secondSubtitle: string;
  /** Save Info button text */
  buttonText: string;
  /** First name label text */
  firstNameLabel: string;
  /** Email Address label text */
  emailLabel: string;
  /** Gender label text */
  genderLabel: string;
  /** Last name label text */
  lastNameLabel: string;
  /** Birthday label text */
  birthdayLabel: string;
  /** Genders content */
  genders: GenderOption[];
  /** Success message on save basic information */
  setBasicInformationSuccess: string;
  /** Error message on save basic information */
  setBasicInformationError: string;
  /** Loyalty interest heading */
  interestsHeading: string;
  /** Loyalty interest subheading */
  interestsSubheading: string;
  /** Shoes size description label text */
  shareYourShoeSizeLabel: string;
  /** Shoes size label text */
  shoesSizeLabel: string;
  /** Address1 label text */
  streetLabel: string;
  /** Address2 label text */
  apartmentLabel: string;
  /** City label text */
  cityLabel: string;
  /** Province label text */
  provinceLabel: string;
  /** Zip code label text */
  zipCodeLabel: string;
  /** Shoes size label text */
  howBigFeetLabel: string;
  /** Country code label text */
  countryCodeLabel: string;
  /** Phone label text */
  phoneLabel: string;
  /** Birtday heading text */
  birthdayHeading: string;
  /** Birtday subheading text */
  birthdaySubheading: string;
  /** Validation messages object to display on FE. */
  validationMessages: BasicInformationValidationMessages;
  /** Member ID label text */
  memberId?: string;
  /** Message describing enabled state for xprl pass */
  loyaltyStateEnrolled: string;
  /** Label text for view points */
  viewPointsLabel: string;
}

export interface BasicInformationValidationMessages {
  /** Required field rule message */
  requiredError: string;
  /** Email rule message */
  emailError: string;
  /** Name rule message */
  nameError?: string;
  /** Birthdate format message */
  birthdayError: string;
  /** Birthdate minimum age  message */
  minRegisterAgeError: string;
  /** Zip code rule message */
  postalCodeError: string;
  /** Invalid phone error message */
  phoneError: string;
}

export interface FormValidationConfig {
  /** Zip code format */
  zipCodeFormat: string;
  /** Zip code regex */
  zipCodeRegex: string;
  /** Birthday Format */
  birthdayFormat: string;
  /** Birthday regex */
  birthdayRegex: string;
  /** Phone number format */
  phoneNumberFormat: string;
  /** Phone number regex */
  phoneNumberRegex: string;
  /** Phone number mask */
  phoneNumberMask: string;
}

export interface GenderOption {
  /** Gender identifier */
  id: 'M' | 'F';
  /** Gender label */
  label: string;
}

export interface InterestsFormTranslations {
  /** Heading containing password policy information */
  heading: string;
  /** Subheading containing password policy information */
  subheading: string;
  /** Array containing interests to display on FE */
  interests: string[];
  /** Save button text */
  saveButtonText: string;
  /** Success message on save interests */
  setBasicInformationSuccess: string;
  /** Error message on save interests */
  setBasicInformationError: string;
}

export interface InterestsItemsProp {
  /** Value id */
  id: string;
  /** Name of the item */
  name: string;
  /** Checked item flag */
  checked: boolean;
}

export interface ResetPasswordTranslations {
  /** Heading text */
  heading: string;
  /** Subeading text */
  subheading: string;
  /** Old password input label */
  oldPasswordLabel: string;
  /** Password input label */
  passwordLabel: string;
  /** Confirm password input label */
  confirmPasswordLabel: string;
  /** Validation messages object to display on FE. */
  validationMessages: ResetPasswordValidationMessages;
  /** Change Password button text */
  changePasswordButton: string;
  /** Cancel button text */
  cancelButton: string;
  /** Success message on change password */
  changePasswordSuccess: string;
}

export interface ResetPasswordValidationMessages {
  /** Field is required error message */
  required: string;
  /** Invalid password error message */
  password: string;
  /** Confirmed password does not match error message */
  confirmPassword: string;
}

export interface ChangePasswordTranslations {
  /** Form heading title */
  heading: string;
  /** Subheading containing password policy information */
  subheading: string;
  /** Password input label */
  passwordLabel: string;
  /** Old password input label */
  oldPasswordLabel: string;
  /** Confirm password input label */
  confirmPasswordLabel: string;
  /** Show password input text */
  showText: string;
  /** Hide password input text */
  hideText: string;
  /** Validation messages object to display on FE. */
  validationMessages: ChangePasswordValidationMessages;
  /** Must contain password helper message */
  passwordMustContain: string;
  /** Validation steps object to display on FE. */
  validationSteps: ChangePasswordValidationSteps;
  /** Change Password button text */
  changePasswordButton: string;
  /** Reset Password button text */
  resetPasswordButton: string;
  /** Cancel button text */
  cancelButton: string;
  /** Success message on change password */
  changePasswordSuccess: string;
}

export interface ChangePasswordValidationMessages {
  /** Field is required error message */
  required: string;
  /** Invalid password error message */
  password: string;
  /** Confirmed password does not match error message */
  confirmPassword: string;
}

export interface ChangePasswordValidationSteps {
  /** Required minimum length message */
  length: string;
  /** Required uppercase character message */
  uppercase: string;
  /** Required at least one digit message */
  number: string;
  /** Required lowercase haracter message */
  lowercase: string;
}

export interface EmailPreferencesFormTranslations {
  /** Heading text */
  heading: string;
  /** Email heading text */
  emailLabel: string;
  /** Text to display when customer is not subscribed to the newsletter */
  subscribeText: string;
  /** Text to display when customer is subscribed to the newsletter */
  unsubscribeText: string;
  /** Unsubscribe info text */
  unsubscribeInfoText: string;
  /** Unsubscribe confirmation text */
  unsubscribeConfirmationText: string;
  /** Sign up checkbox text */
  signUpCheckbox: string;
  /** Subscribe button text */
  subscribeButtonText: string;
  /** Unsubscribe button text */
  unsubscribeButtonText: string;
  /** Save button text */
  saveButtonText: string;
  /** Cancel button text */
  cancelButtonText: string;
  /** Validation messages object to display on FE. */
  validationMessages: EmailPreferencesValidationMessages;
  /** Email preferences saved */
  emailPreferencesSaved: string;
  /** Set basic information success message */
  setBasicInformationSuccess: string;
  /** Set basic information error message */
  setBasicInformationError: string;
  /** Header language preference */
  headerLanguagePreference: string;
}

export interface LanguagePreferencesFormTranslations {
  /** Heading text */
  heading: string;
  /** Button text */
  saveButtonText: string;
  /** Language preferences saved */
  languagePreferencesSaved: string;
  /** Set basic information success message */
  setBasicInformationError: string;
  /** Label for input */
  label: string;
}

export interface EmailPreferencesValidationMessages {
  /** Required field rule message */
  requiredRequiredError: string;
  /** Correct email rule message */
  emailError: string;
}

export interface ForgotPasswordTranslations {
  /** Form heading title */
  title: string;
  /** Email input label */
  emailLabel: string;
  /** Email input placeholder */
  placeholder: string;
  /** Validation messages */
  validationMessages: ForgotPasswordValidationMessages;
  /** Reset password button text */
  resetPasswordButton: string;
  /** Cancel button text */
  cancelButton: string;
  /** Success message for the notification */
  successReset: string;
  /** Failure message for the notification */
  errorReset: string;
  /** Failure message to inform customer that reset password link has been expired or is invalid */
  expiredLinkMessage: string;
}

export interface ForgotPasswordValidationMessages {
  /** Field is required error message */
  requiredError: string;
  /** Invalid email error message */
  emailError: string;
}

export interface SocialAccountLinkTranslations {
  /** Heading text */
  heading: string;
  /** Subheading text */
  subheading: string;
  /** "aria-label" property to use for anchor element. */
  ariaLabel: string;
}

export interface DataListingTableTranslations {
  /** Heading text */
  heading: string;
  /** Subheading text */
  subheading: string;
  /** Save/Add button text */
  buttonText: string;
  /** Edit Button text */
  editButton: string;
  /** Delete Button text */
  deleteButton: string;
  /** Selection/update success message */
  updateSuccess: string;
  /** Deletion success message */
  deleteSuccess: string;
  /** Addition success message */
  addSuccess: string;
  /** Last credit card numbers prefix text */
  cardEndingIn: string;
  /** Credit card expiration prefix text */
  cardExpiration: string;
  /** Delete error text */
  deleteError: string;
  subtitle: string;
}

export interface CustomsHeaderTranslations {
  /** Button text */
  buttonCta: string;
  /** Customs logo alt text */
  altText: string;
}

export interface AddressFormTranslations {
  /** Back button text */
  backButton: string;
  /** Cancel button text */
  cancelButton: string;
  /** Save button text */
  saveButton: string;
  /** Add heading text */
  firstNameLabel: string;
  /** Last name label text */
  lastNameLabel: string;
  /** Street label text */
  streetLabel: string;
  /** Apartment label text */
  apartmentLabel: string;
  /** Postal code label text */
  postalCodeLabel: string;
  /** City label text */
  cityLabel: string;
  /** Province label text */
  provinceLabel: string;
  /** Email label text */
  emailLabel: string;
  /** country select label text */
  countryLabel: string;
  /** Country code label text */
  countryCodeLabel: string;
  /** Phone label text */
  phoneLabel: string;
  /** Phone mask format helper e.g '###-###-###' */
  phoneMask: string;
  /** Email helper text which is displayed under the email input */
  emailHelperText: string;
  /** Phone helper text which is displayed under the phone input */
  phoneHelperText: string;
  /** Back to top button text */
  backToTopButton: string;
  /** Formatting join (XXXXX or XXXXX-XXXX) */
  hintJoin: string;

  /** Billing form translations */
  billing: {
    /** Add heading text */
    addHeading: string;
    /** First name label text */
    editHeading: string;
    /** Message after address is added */
    addedMessage: string;
    /** Message after address is modified */
    modifiedAddress: string;
  };

  /** Shipping form translations */
  shipping: {
    /** Add heading text */
    addHeading: string;
    /** First name label text */
    editHeading: string;
    /** Message after address is added */
    addedMessage: string;
    /** Message after address is modified */
    modifiedAddress: string;
  };

  /** Validation messages object to display on FE. It is allowed to create a custom field in validationMessages.
   Custom validations messages are used are used to change a validation message for a specific field */
  validationMessages: {
    /** Example: Custom validation messages for the field postalCode */
    postalCode: {
      pattern: string;
    };
    default: {
      /** Default message when a field is required */
      required: string;
      /** Default message when field value is too short. {{min}} is replaced with the required length */
      minLength: string;
      /** Default message when field value is too long. {{max}} is replaced with the required length */
      maxLength: string;
      /** Default message when field value does not fulfill the pattern .*/
      pattern: string;
      /** Default message when field value isn't a valid email */
      email: string;
    };
    /** Default error message if no custom message was found */
    fallback: string;
  };
  editHeading: string;
  addHeading: string;
}

export interface CheckoutBillingInfoTranslations {
  /** Same as shipping text */
  sameAsShipping: string;
  /** Edit this address text */
  editThisAddress: string;
  /** Payment information text */
  billingAddress: string;
  /** Address selection text */
  selectSavedAddress: string;
  /** Add new address text */
  enterNewAddress: string;
  /** Select billing address placeholder */
  addressSelect: string;
  /** Additional options in address select text */
  addressSelectionAdditionalOption: {
    /** Open modal text */
    openAddressModal: string;
    /** Use same billing address as shipping */
    setSameAsShipping: string;
  };
  /** Validation messages object to display on FE. */
  validationMessages: {
    required: string;
    email: string;
    phone: string;
  };
}

export interface CheckoutPaymentInfoTranslations {
  paymentInformation: string;
  /** Checkout as paypal text */
  payPalCheckOutInformation: string;
  /** Add new credit card button text */
  addNewCreditCard: string;
  /** Billing address header text */
  cardNumber: string;
  /** Month text */
  month: string;
  /** Year text */
  year: string;
  /** Credit card form alt text */
  altText: string;
  /** Security code text */
  securityCode: string;
  /** Save credit card text */
  saveCreditCard: string;
  /** Validation messages object to display on FE. */
  validationMessages: {
    required: string;
    email: string;
    securityCode: string;
    phone: string;
    cardNumber: string;
  };
  /** Last credit card numbers prefix text */
  cardEndingIn: string;
  /** Credit card expiration prefix text */
  cardExpiration: string;
  /** Paypal tooltip text */
  paypalTooltip: string;
  /** Paypal tooltip Aria Label text */
  paypalTooltipAriaLabel: string;
  /** Back to exising cards*/
  backToSavedCards: string;
}

export interface CheckoutShippingInfoTranslations {
  /** Address confirmation text */
  addressConfirmation: string;
  /** Same as shipping text */
  sameAsShipping: string;
  /** Edit this address text */
  editThisAddress: string;
  /** Payment information text */
  paymentInformation: string;
  /** Marketing option text */
  marketingOptIn: string;
  /** Select saved address text */
  selectSavedShippingAddress: string;
  /** Enter new address text */
  enterNewAddress: string;
  /** Add new address text */
  addNewAddress: string;
  /** Form heading text */
  formHeading: string;
  /** Address verification text */
  addressVerification: string;
  /** Translations for address selection additional option */
  addressSelectionAdditionalOption: {
    openAddressModal: string;
  };
  /** Header to display above list of products to be shipped. List is displayed only on mixed order (Shipping + BOPIS/STS). */
  youAreShippingTheseItems: string;
  /** Notification text when phone number is missing on shipping address */
  phoneNumberMissingNotification: string;
}

export type PaymentMethod = {
  description: string;
  label: string;
  code: string;
  additionalData: {
    [key: string]: any;
  };
};

export type PaymentMethodsLabel = {
  /** code corresponding to payment method from API */
  code: string;
  /** label  */
  label: string;
};

export type PaymentMethodsIcon = {
  /** Icon name - this will render icon component */
  icon: string;
  /** Image url - this will render image if no icon provided */
  image: string;
  /** code corresponding to payment method from API */
  code: string;
};

export type CardsIcons = {
  /** Icon name - this will render icon component */
  icon: string;
  /** Image url - this will render image if no icon provided */
  src: string;
  /** code corresponding to card from API */
  alt: string;
}[];

export interface QuickShopTileTranslations {
  /** Text for quick shop tile cart button */
  addToCartButtonText: string;
  /** Text for quick shop tile sign in to buy button */
  signInToBuyButtonText: string;
  /** Text for quick shop tile for view details button */
  viewDetailsText: string;
  placeholders: {
    /** Text for quick shop tile size placeholder */
    sizePlaceholder: string;
    /** Text for quick shop tile length placeholder */
    lengthPlaceholder: string;
    /** Text for quick shop width size placeholder */
    widthPlaceholder: string;
    /** Text for quick shop tile zip placeholder */
    zipPlaceholder: string;
  };
  errors: {
    /** Text for quick shop tile for non picked size */
    selectSizeError: string;
    /** Text for quick shop tile for non picked length */
    selectLengthError: string;
    /** Text for quick shop tile for non picked width */
    selectWidthError: string;
    /** Text for quick shop tile for non picked zip */
    selectZipError: string;
  };
  /** Text for quick shop tile cart button for out of stock */
  outOfStockText: string;
  /** Aria label for color picker **/
  ariaLabelChooseColor: string;
}

export type QuickShopTileErrorMessage = {
  /** Attribute in which the message has to be displayed */
  role: string;
  /** Translated error message to be displayed */
  message: string;
};

export interface CategoryProductsTranslations
  extends QuickShopTileTranslations {
  /** Plural translation for view all button, for example: "Items" */
  itemsCountPlural: string;
  /** Singular translation for view all button, for example: "Item" */
  itemsCountSingular: string;
  /** Price label after discount (special price) */
  specialPriceLabel: string;
  /** Original price label when there is a discount */
  offerPriceLabel: string;
  /** Text for see more color link */
  seeMoreColorsLink: string;
  /** Text for quick shop button */
  quickShopButtonText: string;
  /** Text for quick shop button */
  addWishListAriaLabel: string;
  /** Translation for 'Page' */
  page: string;
  /** Translation for 'Next' */
  next: string;
  /** Remove wishlist aria label */
  removeWishListAriaLabel: string;
  /** Error message when there is a problem retrieving the product */
  missingProductError: string;
  /* Text for add to cart CTA button on the bottom of product card */
  addToCartLabel: string;
  /* Text for sold out product on the bottom of product card */
  noLongerAvailableLabel: string;
  /* Text for notify me CTA button on the bottom of product card */
  notifyMeLabel: string;
  /* Text for view previous button on the top of Product Grid */
  viewPrevious: string;
}

export interface FavoritesTranslations {
  /** Title of component */
  title: string;
  /** CTA label to display favorites settings */
  editSettingsLabel: string;
  /** CTA label to leave favorites settings */
  cancelLabel: string;
  /** CTA label to change the view type */
  gridViewLabel: string;
  /** CTA label to change the view type */
  listViewLabel: string;
  /** CTA label to print the favorites page */
  printButtonText: string;
  /** Notification after move favorite item to cart */
  addedToCartText: string;
  /** CTA label to leave favorites settings */
  backLabel: string;
  /** Translation for word after number of items in list */
  itemsCount: string;
  /** String showed when list is empty */
  emptyFavoritesListText: string;
  /** Message to explain about public lists */
  privacyDescription: string;
  /** Notification showed after romeving item from list */
  removedItemText: string;
  /** radio buttons options to set list visibility */
  radioButtonLabels: {
    /** radio button label to make private lists */
    privateLabel: string;
    /** radio button label to make public lists */
    sharedLabel: string;
  };
  /** Title for settings section */
  privacyTitle: string;
  /** CTA label to move item to cart */
  addToCartLabel: string;
  /** String showed when list is empty */
  favoritesListText: {
    /** message showed when list is private */
    private: string;
    /** message showed when list is public */
    shared: string;
  };
  /** CTA to remove item from list */
  removeLabel: string;
  /** Notifications messages */
  notifications: {
    /** message showed after make list private */
    private: string;
    /** message showed after make list public */
    shared: string;
    /** message showed when user clicks on copy link */
    copied: string;
  };
  shareUrl: {
    /** title showed above the link to a shareable list link*/
    title: string;
    /** CTA label to copy the link to clipboard */
    copyLabel: string;
  };
  /** CTA label to navigate to PDP */
  viewDetails: string;
}

export interface ViewMoreProductsButtonsTranslations {
  /** View all button text */
  viewAllButtonText: string;
  /** View more button text*/
  viewMoreButtonText: string;
  /** Plural translation for view all button, for example: "Items" */
  itemsCountPlural: string;
  /** Singular translation for view all button, for example: "Item" */
  itemsCountSingular: string;
  /** Progress bar label */
  progressBarLabel: string;
}

export interface PageTitleTranslations {
  /** Plural translation to tell how many items is in category, for example: "Items" */
  itemsCountPlural: string;
  /** Singular translation to tell how many items is in category, for example: "Item" */
  itemsCountSingular: string;
  /** Page translation */
  page: string;
  weFound: string;
  favoritesLoggedIn?: string;
  favoritesLoggedOut?: string;
  /** For search results */
  misspellQueryResults: string;
  /** For search results */
  autoCorrectQueryInfo: string;
  /** Did You Mean text on SRP */
  didYouMean: string;
}

export interface BreadcrumbsTranslations {
  /** Shop All translation for commerce page */
  shopAll: string;
}

export interface FavoritesButtonTranslations {
  /** Text for go back shopping button */
  backToShoppingLabel: string;
  /** Text for sign in to save button */
  signInText: string;
  /** Text for share favorites button */
  shareFavorites: string;
}

export type ProductRatingTranslations = RatingTranslations &
  ReviewRatioTranslations;

export type RatingTranslations = {
  /** Text for reviews singular */
  reviewSingular: string;
  /** Text for reviews plural */
  reviewPlural: string;
  /** Text for no reviews */
  noReviews: string;
  /** Text for aria label */
  ariaLabel?: string;
};

export type ReviewRatioTranslations = {
  /** Text for displaying percent of positive reviews */
  reviewRatio: string;
};

export interface ProductGalleryTranslations {
  /** Aria label to zoom in */
  zoomIn: {
    ariaLabel: string;
  };
  /** Aria label to zoom out */
  zoomOut: {
    ariaLabel: string;
  };
  /** Aria label to exit full screen mode */
  return: {
    ariaLabel: string;
  };
  /** Aria label to enter full screen mode */
  fullScreen: {
    ariaLabel: string;
  };
  /** Aria label to swipe Previous */
  swipePrevious: {
    ariaLabel: string;
  };
  /** Aria label to swipe Next */
  swipeNext: {
    ariaLabel: string;
  };
  /** Aria label to close fullScreen mode */
  closeFullScreen: {
    ariaLabel: string;
  };
  /** Notification text when product has been added to favorites */
  addedToFavoritesNotification: string;
  /** visual search button label */
  visualSearchButtonLabel: string;
}

export interface ProductColorsTranslations {
  /** Choose color label for Color Selector */
  chooseColor: string;
  /** Choose color label displayed in swap item modal */
  colorLabel: string;
  /** View all button label */
  viewAll: string;
  /** Show less button label */
  showLess: string;
}

export interface ProductQuantityTranslations {
  /** Quantity label for Quantity Selector  */
  quantityLabel: string;
}

export interface ProductSizesTranslations {
  /** Size chart label, for example: "Size Chart" */
  sizeChart: string;
  /** Out of Stock label, for example: "Out of Stock" */
  outOfStock: string;
  /** Choose size label for Size Selector */
  chooseSize: string;
  /** Choose size label displayed in swap item modal */
  sizeLabel: string;
  /** Size Chart button text, for example: "Size Chart" */
  sizeButtonText: string;
  /** Size placeholder text, for example: "Please choose the size" */
  sizePlaceholder: string;
  /** Text of unavailable size link, for example: "Your size is unavailable?". */
  yourSizeUnavailableCTA: string;
  /** Copy to show when all the variants are sold out  */
  soldOutCopy: string;
  /** Copy to show when all the variants are sold out  */
  soldOutTitle: string;
  /** Validation messages object to display on FE. */
  validationMessages: {
    unselectedMessage: string;
    invalidMessage: string;
    fieldIsRequired: string;
  };
  /** Notification form title for future items */
  comingSoon: string;
  /** Notification form intro text for future items */
  signUpToBeAlerted: string;
  /** Notification form email field label text */
  emailAddressTitle: string;
  /** Notification form email field aria label text */
  emailFieldAriaLabel: string;
  /** Notification form email field placeholder text */
  enterEmailAddress: string;
  /** Notification form success feedback message text */
  youAreOnTheList: string;
  /** Coming soon already subscribed feedback message text */
  weReceivedYourEmail: string;
  /** Notification form title for items which went out of stock */
  letMeKnow: string;
  /** Notification form intro text for items which went out of stock */
  weCanNotifyYou: string;
  /** Notification when the quantity of stock is low **/
  justAFewLeft: string;
}

export interface ProductDescriptionTranslations {
  /** Product overview label, for example: "Overview" */
  overview: string;
  /** Product details label, for example: "Product Details" */
  productDetails: string;
}

export interface QuickAddToCartTranslations {
  /** Add to cart button label */
  addToCartButtonText: string;
  /** Size label */
  sizeLabel: string;
  /** Size select placegoholder */
  sizePlaceholder: string;
  /** Qty label */
  qtyLabel: string;
}

export interface ProductActionButtonsTranslations {
  /** Delivery details label */
  deliveryDetails: string;
  /** Delivery details Modal HTML */
  deliveryDetailsModal: string;
  /** Find in stores label */
  findInAStore: string;
  /** Find in stores label redesign */
  findInAStoreRedesign: string;
  /** Free shipping - Icon label redesign */
  freeShippingRedesign: string;
  /** Free shipping for loyalty user - redesign */
  freeShippingRedesignLoyaltyUser: string;
  /** join Now Or SignIn - redesign */
  joinNowOrSignIn: string;
  /** Store pickup - Icon label redesign */
  needItFastRedesign: string;
  /** Remove from favorites label */
  removeFromFavorites: string;
  /** Add to favorites label */
  addToFavorites: string;
  /** Added to favorites label */
  addedToFavorites: string;
  /** Added to favorites notification */
  addedToFavoritesNotification: string;
  /** CTA notify button text */
  buttonCTANotify: string;
  /** CTA discontinued button text */
  buttonCTADiscontinued: string;
  /** CTA active button text */
  buttonCTAActive: string;
  /** Notify box title text  */
  notifyBoxTitle: string;
  /** Notify box text */
  notifyBoxTextBox: string;
}

export interface ProductCustomizeTranslations {
  /** Customize button label */
  buttonCta: string;
}

export interface ProductRecommendationsTranslations
  extends QuickShopTileTranslations {
  /** Heading text */
  heading: string;
  /** Quickshop button text */
  quickShop: string;
}

export interface CategorySortTranslations {
  /** Label for sort select box */
  sortBy: string;
  /** Label for sort select box */
  sortLabel: string;
  /** Label for sort select options*/
  sortOptions: Record<string, string>;
}

export interface SearchSortTranslations {
  /** Label for sort select box */
  sortLabel: string;
}

export interface CategoryFiltersTranslations {
  /** Apply mobile button text */
  apply: string;
  /** Apply filter button when no selection is made */
  applyDisabled: string;
  /** Filters mobile button text */
  filtersLabel: string;
  /** Sort mobile button text */
  sortLabel: string;
  /** Reset all filters button text */
  resetButtonText: string;
  /** Aria label for removing selection of single filter */
  resetAria: string;
  /** Reset button label */
  reset: string;
  /** Reset specific filter button label */
  resetFilter: string;
  /** Aria label for reset specific filter button */
  resetFilterAria: string;
  /** Aria label for selecting single filter */
  selectAria: string;
  /** View all filters button text */
  showAll: string;
  /** Show less filters button text */
  showLess: string;
  /** Show More Applied Filters button text */
  seeMoreFilters: string;
  /** Aria label for Show More Applied Filters button */
  seeMoreFiltersAria: string;
  /** Product counter text */
  productsQuantitySingle: string;
  /** Product counter text */
  productsQuantityPlural: string;
  /** Meta description text */
  shopAt: string;
  /** Show more filters button text */
  showMore: string;
  sortOptions: Record<string, string>;
  applyButton: string;
  topResetButton: string;
  topResetButtonAria: string;
  filtersAria: string;
  collapseAll: string;
  collapseAllAria: string;
  expandAllAria: string;
  /** Bottom reset button text - for mobile */
  bottomResetButton: string;
  /** Text above selected grid */
  selectedGridSizeSelectorLabel: string;
  /** Labels for price range input */
  priceRangeInputLabels: {
    minPriceInputLabel: string;
    maxPriceInputLabel: string;
    minPriceRangeLabel: string;
    maxPriceRangeLabel: string;
    maximumDisplayPrice: number;
  };
  /** Accessibility label to notify a status change */
  allFiltersRemoved: string;
  /** Accessibility label to notify a status change */
  filterRemoved: string;
  /** Custom price filter label for prices above certain value */
  abovePriceFilterLabel: string;
  /** Custom price filter label for prices below certain value */
  belowPriceFilterLabel: string;
}

export interface CategoryDescriptionTranslations {
  /** Category description label, for example: "Overview" */
  descriptionLabel: string;
  description: string;
}

export interface NewsArticleTranslations {
  /** Filter toggle button text */
  filter: string;
  /** All filters button text */
  all: string;
  /** Show more articles button text */
  showMoreButtonText: string;
}

export interface BreadcrumbItem {
  text: string;
  link: string;
  dataNavigation: string;
}

export interface HeaderLogo {
  src: Image;
  link?: string;
}
export interface HeaderMaxItems {
  smallMaxItems: number;
  mediumMaxItems: number;
  largeMaxItems: number;
}

export interface HeaderMenuTranslations {
  closeCategorySubmenu: string;
  openCategorySubmenu: string;
}

export interface HeroItem {
  /** Video data to render */
  video: Video;
  /** Image data to render */
  image: Image;
  /** Either to show CTA button or not */
  buttonVisible: boolean;
  /** Hero heading text */
  headingText: string;
  /** Hero subheading text */
  subheadingText: string;
  /** CTA button text */
  buttonText: string;
}

export interface PromoBar {
  /** Contains message and action definitions for Promo Bar */
  message: PromoBarItem[];
  /** Message autorotation interval (in milliseconds) - used only if message prop is an array */
  interval: number;
}

export interface PromoBarItem {
  /** Message to show in Promo Bar */
  text: string;
  /** Text for action link in Promo Bar */
  actionText: string;
  /** Message to show in a modal after clicking on action link */
  modalContent: string;
  /** Destination URL to open after clicking on action link, can be internal (relative within the app) or external one */
  linkTarget: string;
  /** CSS class modifiers for PromoBarItem title teaser. */
  titleClassModifiers: string;
  /** Color for title. */
  titleColor: string;
}

export interface HeaderUtilityNavigation {
  /** Array of utility navigation items. */
  desktop: UtilityNavigationItem[];
  /** Array of utility navigation items. */
  mobile: UtilityNavigationItem[];
}

export interface UtilityNavigationItem {
  /** Title to show on the page. */
  title: string;
  /** Icon name to render. */
  icon: string;
  /** URL to the resource. */
  link: string;
  /** Either show it to user or not. */
  visible: boolean;
  /** Role of given element to base business logic on it. */
  role: 'link' | 'login' | 'favorites' | 'cart' | 'account';
  /** Title CSS class modifiers. */
  titleClassModifier: string;
  /** Title color. */
  titleColor: string;
}

export interface MegaMenuNavigationItem {
  /** Title to show on the page on medium and large view ports. */
  title: string;
  /** Title to show on the page on small view ports. */
  mobileTitle?: string;
  /** URL to the resource. */
  link: string;
  /** */
  dataNavigation?: boolean;
  /** "aria-label" property for menu item component. */
  ariaLabel?: string;
  /** Array of child menu items to render in MegaMenu on hover. */
  children?: MegaMenuNavigationItem[];
  /** Array of featured menu items to render in MegaMenu on hover. */
  featured?: MegaMenuNavigationItem[];
  /** Pass true to highlight / bold navigation menu element. */
  highlighted?: boolean;
  /** Pass true to open link in a new browser tab (target="blank" will be applied to anchor element). */
  openInNewTab?: boolean;
  /** Pass true to render as text. By default navigation item is a link to configured resource (category/product). */
  placeholder?: boolean;
}

export interface MegaMenuMobileMenuSettings {
  /** Automatically generate L2 links for small screens. */
  autoGeneratedL2LinksSmall?: 'disabled' | 'top';
  /** Default text for categories */
  autoGeneratedL2LinksCategoryText?: string;
  /** Default text for pages */
  autoGeneratedL2LinksPageText?: string;
}

export interface FooterColumn {
  /** Title to render as a first item in top of footer column. */
  title: string;
  /** CSS class modifiers for columns teaser. */
  classModifiers: string;
  /** Color for column title. */
  titleColor: string;
  /** List of links to render in as column items. */
  items?: FooterColumnItem[];
}

export interface FooterColumnItem {
  /** Title to render on the page. */
  title: string;
  /** URL to the resource. */
  link?: string;
  /** Data attribute value needed for breadcrumbs. */
  dataNavigation?: string;
  /** Raw HTML to render as column item. */
  html?: string;
  /** Either use smaller font to display certain item. */
  smallFont: boolean;
  stateFulfilled?: boolean;
  items?: {
    states: States[];
    segments: Segment[];
  }[];
}

export interface FooterLogo {
  /** Image object */
  src: Image;
  /** "aria-label" property to use for anchor element. */
  ariaLabel?: string;
  /** Data attribute value needed for breadcrumbs. */
  dataNavigation?: string;
}

export interface FooterSocialLink {
  /**Name of social link. */
  name: 'facebook' | 'twitter' | 'instagram' | 'pinterest';
  /**URL to the resource. */
  url: string;
  /** "aria-label" property to use for anchor element. */
  ariaLabel: string;
  /** CSS class modifiers for social link teaser. */
  classModifiers: string;
  /** Color for icon. */
  iconColor: string;
}

export interface FooterBottomBar {
  /** Name of social icon */
  text: string;
  /** URL text to render on the page */
  linkText: string;
  /** URL to the resource. */
  link: string;
  /** Data attribute value needed for breadcrumbs. */
  dataNavigation?: string;
  /** CSS class modifiers for bottom bar teaser. */
  classModifiers: string;
  /** Color for text helper. */
  textColor: string;
}

export interface FooterStoreLocator {
  /** Main title. */
  title: string;
  /** URL to the resource. */
  link: string;
  /** Text to render on the button.. */
  buttonText: string;
  /**Helper text to render on hover. */
  helperText: string;
  /** CSS class modifiers for store locator teaser. */
  classModifiers: string;
  /** Color for title. */
  titleColor: string;
  /** CSS class modifiers for title. */
  titleClassModifiers: string;
  /** Color for text helper. */
  textColor: string;
}

export interface FooterSubscribe {
  /** CSS class modifiers for subscribe teaser. */
  classModifiers: string;
  /** Main title. */
  title: string;
  /** Helper text to render on hover. */
  helperText: string;
  /** Color for title. */
  titleColor: string;
  /** CSS class modifiers for title. */
  titleClassModifiers: string;
  /** Color for text helper. */
  textColor: string;
  /** Validation messages object to display on FE. */
  validationMessages: {
    /** Validation messages object to display on FE. */
    requiredError: string;
    emailError: string;
    successMessage: string;
    errorMessage: string;
  };
  translations: FooterSubscribeTranslation;
}

export interface FooterSubscribeTranslation {
  /** Email input placeholder text */
  emailPlaceholder: string;
  /** Send button text */
  sendButtonText: string;
}

export interface FooterBackToTop {
  /** Title. */
  title: string;
  /** Color for title and icon. */
  titleColor: string;
  /** CSS class modifiers for title and icon. */
  titleClassModifiers: string;
}
export type GridOverImageBackgroundPosition = 'center' | 'top' | 'bottom';
export type GridOverImageBackgroundSize = 'cover' | 'contain' | 'auto';
export type GridOverImageBackgroundRepeat = 'repeat' | 'no-repeat';

export interface RegularButtonCustomStyles {
  /** Font size */
  fontSize: string;
  /** Font weight */
  fontWeight: string;
  /** Font family */
  fontFamily: string;
  /** Text color for button*/
  textColor: string;
  /** Background color for button */
  backgroundColor: string;
  /** CSS class modifiers for button */
  customClasses: string;
}

export type RegularButtonStyle =
  | 'secondary'
  | 'hover-variant'
  | 'tertiary'
  | 'tertiary-variant'
  | 'text';
export type RegularButtonSize = 'small' | 'medium' | 'large';
export type IconPosition = 'left' | 'right' | 'below';
export type RichTextTagElement =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'strong'
  | 'small'
  | 'mark'
  | 'ins'
  | 'del'
  | 'sup'
  | 'sub'
  | 'b'
  | 'i'
  | 'p'
  | 'div'
  | 'span';

export type RichTextSizeProp =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | '2xs-sm'
  | 'xs-sm'
  | 'sm-sm'
  | 'md-sm'
  | 'base-sm'
  | 'lg-sm'
  | 'xl-sm'
  | '2xl-sm'
  | '3xl-sm'
  | '4xl-sm'
  | '5xl-sm'
  | '6xl-sm'
  | '7xl-sm'
  | '2xs-lg'
  | 'xs-lg'
  | 'sm-lg'
  | 'md-lg'
  | 'base-lg'
  | 'lg-lg'
  | 'xl-lg'
  | '2xl-lg'
  | '3xl-lg'
  | '4xl-lg'
  | '5xl-lg'
  | '6xl-lg'
  | '7xl-lg';

export type RichTextColorProp = 'primary' | 'secondary' | 'tertiary' | 'white';

export type RichTextWeightProp =
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extra-bold'
  | 'black';

export type RichTextFamilyProp = 'primary' | 'secondary';
export type ComponentWithVariants = 'VARIANT_A' | 'VARIANT_B';

export interface MinicartTranslations {
  /** Remove button text */
  remove: string;
  /** Quantity label */
  qty: string;
  /** Main title */
  title: string;
  /** total items label */
  totalItems: string;
  /** empty cart info label */
  emptyCartInfo: string;
  /** close button label */
  close: string;
  /** subtotal label */
  subtotal: string;
  /** checkout call to action label */
  checkoutCTA: string;
  /** share my cart call to action label */
  shareMyCartCTA: string;
  /** view favorites button label */
  viewFavorites: string;
  /** free shipping info label */
  freeShippingInfo: string;
  /** or label */
  or: string;
  /** Bounce back voucher translations */
  promotions: {
    /** Voucher translations if promotion was successfully applied */
    success: {
      /** Voucher heading if promotion was successfully applied */
      title: string;
      /** Voucher description if promotion was successfully applied */
      message: string;
    };
    /** Voucher translations if promotion still approaching */
    approaching: {
      /** Voucher heading if promotion still approaching */
      title: string;
      /** Voucher description if promotion still approaching */
      message: string;
    };
  };
  /** Single item text */
  singleItem: string;
  /** Multiple items text */
  multipleItems: string;
}

export enum VoucherMessageType {
  Approaching = 'approaching',
  Success = 'success',
}

export interface ApproachingDiscountTranslations {
  /** Voucher translations if promotion was successfully applied */
  [VoucherMessageType.Success]: {
    /** Voucher heading */
    title: string;
    /** Voucher description */
    message: string;
  };
  /** Voucher translations if promotion still approaching */
  [VoucherMessageType.Approaching]: {
    /** Voucher heading */
    title: string;
    /** Voucher description */
    message: string;
  };
}

export type Icons =
  | 'user'
  | 'cart'
  | 'info'
  | 'check'
  | 'chat'
  | 'cross'
  | 'correct'
  | 'customize'
  | 'customize_board'
  | 'error'
  | 'maximize'
  | 'minimize'
  | 'return'
  | 'heart'
  | 'heart__fill'
  | 'gift'
  | 'login'
  | 'logout'
  | 'pin'
  | 'menu'
  | 'minus'
  | 'play'
  | 'plus'
  | 'search'
  | 'chevron_up'
  | 'chevron_down'
  | 'chevron_left'
  | 'chevron_right'
  | 'facebook'
  | 'mail'
  | 'instagram'
  | 'pinterest'
  | 'tiktok'
  | 'twitter'
  | 'tumblr'
  | 'youtube'
  | 'creditCard';

export interface SearchTopTranslations {
  /** Label for sort select box */
  sortBy: string;
  /** First part of information about count of found search results */
  weFound: string;
  /** Second part of information about count of found search results */
  items: string;
  /** Heading for information about no results */
  emptySearchTitle: string;
  /** Additional text for information about no results */
  emptySearchSubtitle: string;
}

export interface NotFoundTranslations {
  /** Subheading on 404 Not Found page */
  subheading: string;
  /** Heading on 404 Not Found page */
  heading: string;
  /** Link anchor on 404 Not Found page */
  link: string;
}

export interface SignInFormTranslations {
  /** Form heading text */
  heading: string;
  /** Form heading subtitle text */
  subheading?: string;
  /** Email input label  */
  emailLabel: string;
  /** Email input label  */
  emailPlaceholder: string;
  /** Password input label */
  passwordLabel: string;
  validationMessages: {
    /** Email rule message */
    email: string;
    /** Required field rule message */
    required: string;
  };
  /** Forgot password title text */
  forgotPasswordTitle?: string;
  /** Forgot password button text */
  forgotPasswordButtonText: string;
  /** Submit button text */
  signInButtonText: string;
  /** Login error text */
  loginError: string;
  /** Create account button label */
  createAccountButtonLabel: string;
  /** Create account button text */
  createAccountButtonText: string;
  /** Form bottom heading text */
  bottomHeading?: string;
  /** Facebook button aria-label property value */
  facebookAriaLabel?: string;
  /** Reser Password button text */
  resetPasswordButton?: string;
  /** Cancel button text */
  CancelButton?: string;
  /** Reset success text */
  successReset?: string;
  /** Reset error text */
  errorReset?: string;
  /** Create Account texts */
  createAccountTranslations: CreateAccountTranslations;
}

export interface SignInConfirmationModalTranslations {
  /** Title for confirmation page */
  title: string;
  /** Email label for confirmation page */
  email: string;
  /** Password label for confirmation page */
  password: string;
  /** Submit button label for confirmation page */
  submit: string;
  /** Reset password button label for confirmation page */
  resetPassword: string;
  /** Close button label for confirmation page */
  close: string;
  /** Forgot password link for confirmation page */
  forgotPassword: string;
  /** Password placeholder label for confirmation page */
  passwordPlaceholder: string;
  /** Password reset notification for confirmation page */
  passwordResetNotification: string;
}

export type SignInToStoreTranslations = {
  /** Form Heading label */
  heading: string;
  /** User ID input label */
  userId: string;
  /** Password input label */
  password: string;
  /** Store select input label */
  store: string;
  /** Employee select input label */
  employee: string;
  /** Sign In button text */
  signInButtonText: string;
  /** Required field text */
  requiredFieldInfo: string;
  /** Required fields text */
  requiredFieldsInfo: string;
  /** Invalid user id error message */
  userIdErrorMessage: string;
  /** Error message when credentials are invalid */
  wrongCredentialsInfo: string;
  /** Error message when user have a network error */
  networkErrorMessage: string;
  /** General Error message */
  generalErrorMessage: string;
};

export type BuyInStoreInfoTranslations = {
  /** AssociateId field label */
  associateIdLabel: string;
  /** AssociateId field required error message */
  associateIdRequiredError: string;
  /** StoreId field label */
  storeIdLabel: string;
};

interface CreateAccountTranslations {
  /** Form heading text */
  heading: string;
  /** Form subheading text */
  subheading?: string;
  /** First name input label */
  firstNameLabel: string;
  /** Last name input label */
  lastNameLabel: string;
  /** Email input label */
  emailLabel: string;
  /** Zip code input label */
  zipCodeLabel: string;
  /** Zip code regex */
  zipCodeRegex?: string;
  /** Zip code help text */
  zipCodeHelpText?: string;
  /** Password  input label */
  passwordLabel: string;
  /** Password helper text */
  passwordHelperText?: string;
  /** Password minimum length helper text */
  passwordMinCharsLabel?: string;
  /** Password require number text */
  passwordNumberLabel?: string;
  /** Password require uppercase letter text */
  passwordUpperLetterLabel?: string;
  /** Password require lowercase letter text */
  passwordLowerLetterLabel?: string;
  /** Birthday input label */
  birthdayLabel?: string;
  /** Birthday help text */
  birthdayHelpText?: string;
  /** Phone input label */
  phoneNumberLabel: string;
  /** Birthday help text */
  phoneNumberHelpText?: string;
  /** Error message when input field is required */
  requiredFieldsInfo: string;
  /** Subscribe checkbox text */
  subscribeTextCheckbox: string;
  /** Agreement label text */
  agreementLabel: string; // same as termsTextCheckbox
  /** Terms and Terms & Condition Agreement label text */
  termsLabel: string;
  /** Privacy Policy Agreement checkbox label */
  privacyLabel: string;
  /** Submit button text */
  buttonText: string;
  /** Sign in Link for already existing account notification */
  signInButtonText: string;
  /** Form validation messages */
  validationMessages: CreateAccountValidationMessages;
  sms?: {
    checkboxLabel: string;
    termsAndConditions: {
      title: string;
      body: string;
      ctaLabel: string;
    };
  };
}

export interface XplrPassTranslations {
  /** XPLR Title label text */
  xplrTitleLabel: string;
  /** XPLR Points icon name */
  xplrJoinIcon: string;
  /** XPLR Join label text */
  xplrJoinLabel: string;
  /** XPLR Access icon name */
  xplrAccessIcon: string;
  /** XPLR Access label text */
  xplrAccessLabel: string;
  /** XPLR Points icon name */
  xplrPointsIcon: string;
  /** XPLR Points label text */
  xplrPointsLabel: string;
  /** XPLR Learn more label text */
  xplrLearnMoreLabel: string;
  /** XPLR Learn more link url */
  xplrLearnMoreLink: string;
  /** XPLR Agreement label text */
  xplrAgreementLabel: string;
  /** Privacy Policy Agreement checkbox label */
  privacyLabel: string;
}
export interface CreateAccountFormTranslations
  extends CreateAccountTranslations,
    XplrPassTranslations {
  /** Confirm password input label */
  confirmPasswordLabel: string;
  /** Terms and conditions acceptance text */
  termsTextCheckbox: string;
  privacyPolicyText: string;
}

export interface CreateAccountValidationMessages {
  /** Input is required message */
  requiredError: string;
  /** Invalid name message */
  nameError: string;
  /** Invalid email message */
  emailError: string;
  /** Zip code validation error message */
  zipError: string;
  /** Birthday validation error message */
  birthdayError: string;
  /** Phone validation error message */
  phoneError: string;
  /** Invalid password message */
  passwordError: string;
  /** Invalid repeated password message */
  confirmPasswordError: string;
  /** Terms and conditions checkbox unchecked message */
  termsError: string;
  /** TNF Loyalty checkbox unchecked message */
  privacyError: string;
  /** TNF Loyalty checkbox unchecked message */
  xplrTermsError: string;
  /** Email already in use error message */
  uniqueEmailError: string;
}

export interface SearchFiltersTranslations {
  /** Top reset button text - for desktop */
  topResetButton: string;
  /** ARIA label for top reset button */
  topResetButtonAria: string;
  /** ARIA label for a filter option that can be selected */
  selectAria: string;
  /** ARIA label for a filter option that is chosen and can be reset */
  resetAria: string;
  /** Sort label */
  sortLabel: string;
  /** Top label for filters sidebar - for mobile */
  filtersLabel: string;
  /** Bottom reset button text - for mobile */
  bottomResetButton: string;
  /** ARIA label for bottom reset button */
  bottomResetButtonAria: string;
  /** Apply button text - mobile only */
  applyButton: string;
  /** ARIA label for apply button */
  applyButtonAria: string;
  showLess: string;
  showMore: string;
  sortOptions?: any;
  /** Product counter text */
  productsQuantitySingle: string;
  /** Product counter text */
  productsQuantityPlural: string;
  collapseAll: string;
  expandAll: string;
  filtersAria: string;
  /** Labels for price range input */
  priceRangeInputLabels: {
    minPriceInputLabel: string;
    maxPriceInputLabel: string;
    minPriceRangeLabel: string;
    maxPriceRangeLabel: string;
    maximumDisplayPrice: number;
  };
  /** Custom price filter label for prices above certain value */
  abovePriceFilterLabel: string;
  /** Custom price filter label for prices below certain value */
  belowPriceFilterLabel: string;
}

export interface OrderHistoryTranslations {
  /** Page Title text */
  pageTitle: string;
  /** Page Title text for return history page */
  pageReturnTitle: string;
  /** Starting title text for single order */
  orderLabel: string;
  /** Starting title text for single return order */
  authorizationLabel: string;
  /** Label for ordered items count */
  orderedItemsLabel: string;
  /** Label for estimated shipping */
  estimatedShippingLabel: string;
  /** Label for estimated tax */
  estimatedTaxLabel: string;
  /** Label for order total */
  orderTotalLabel: string;
  /** Label for order details button */
  orderDetailsButton: string;
  /** Label for Create a Return Link */
  returnLinkButton: string;
  /** Information about no orders */
  noOrders: string;
  /** Text thats appear in review links */
  reviewLabel: string;
  /** Ordered QTY Product Label */
  qty: string;
  /** Ordered Quantity Product Label */
  orderedQuantity: string;
  /** Returnable Quantity Product Label */
  returnableQuantity: string;
  /** Notification when server encountered error */
  serverErrorMessage: string;
  /** Show all orders CTA button translation text */
  showAllOrders: string;
  /** Continue Shopping CTA button translation text */
  continueShopping: string;
  /** Disclaimer for return history sidebar */
  returnDisclaimer: string;
  /** Label for print packing slip button */
  printPackingSlipButtonText: string;
  /** Label for print return label button */
  printReturnLabelButtonText: string;
}

export interface OrderHistoryCtaShowAllTranslations {
  /** Label for show all orders button */
  showAllOrders: string;
  /** Label for the button where is no orders */
  continueShopping: string;
}

export interface ContactFormTranslations {
  /** Name label text */
  nameLabel: string;
  /** Subject label text */
  subjectLabel: string;
  /** Category label text */
  categoryLabel: string;
  /** Email label text */
  emailLabel: string;
  /** Phone label text */
  phoneLabel: string;
  /** Phone mask format helper e.g '###-###-###' */
  phoneMask: string;
  /** Order number label text */
  orderNumberLabel: string;
  /** Message label text */
  messageLabel: string;
  /** Submit button text */
  submitButton: string;
  /** Continue shopping button text */
  continueShoppinglButton: string;
  /** Successfully sent the message notification */
  messageSent: string;
  /** Validation messages object to display on FE. */
  validationMessages: {
    /** Required field rule message */
    required: string;
    /** Email rule message */
    email: string;
    /** Phone rule message */
    phone: string;
  };
}

export interface TeaserGridItemTranslations {
  /** Read more button label */
  readMore: string;
  /** Show more button label */
  showLess: string;
}

export interface CreditCardFormTranslations {
  /** Card Number label text */
  cardNumber: string;
  /** Month label text */
  month: string;
  /** Year label text */
  year: string;
  /** Alt text inside security code tooltip */
  altText: string;
  /** Security code label text */
  securityCode: string;
  /** Recaptcha text */
  recaptcha: string;
  /** Prop to set image src of security code tooltip */
  securityCodeTooltip: string;
  /** Button label for the security code tooltip */
  securityCodeTooltipButtonLabel: string;
  /** Validation messages object to display on FE. */
  validationMessages: {
    /** Required field rule message */
    required: string;
    /** Email rule message */
    email: string;
    /** Security code rule message */
    securityCode: string;
    /** Phone rule message */
    phone: string;
    /** Date rule message */
    date: string;
    /** Card number rule message */
    cardNumber: string;
  };
}

export interface CreditCardListingTranslations {
  /** Heading text */
  heading: string;
  /** Subheading text */
  subtitle: string;
  /** Select saved billing address label text */
  selectSavedAddress: string;
  /** Select saved billing address input placeholder text */
  addressSelect: string;
  /** Select saved billing address input option text */
  addNewAddress: string;
  /** Back to top text */
  backToTop: string;
  /** Back to credit card list button text */
  backToCreditCards: string;
  /** Title text for page */
  addCreditCard: string;
}

export interface AccordionTab {
  /** Accordion tab id */
  id: string;
  /** Accordion tab header text */
  header: string;
  /** Accordion tab strong header text */
  strongHeader: string;
  /** Accordion tab content text or html or another component or grid */
  content: unknown;
}

export interface SocialNativeShopThelookTranslations {
  /** label for previus button in carousel */
  prevControlLabel: string;
  /** label for next button in carousel */
  nextControlLabel: string;
  items: string;
  featuredProducts: string;
  productNameFallback: string;
  /** Shop the Look CTA/link label */
  shopTheLook: string;
}

export interface SocialNativeTranslations
  extends SocialNativeShopThelookTranslations {
  /** title */
  title: string;
  /** summary */
  summary: string;
  /** Add Your Photo CTA/link label */
  addYourPhoto: string;
  /** Load More CTA/link label */
  loadMore: string;
}

export interface ImageAddCaptionTranslations {
  /** add caption title */
  addCaptionTitle: string;
  /** add caption copy */
  addCaptionCopy: string;
  /** add caption placeholder textarea */
  addCaption: string;
  /** remove CTA label */
  remove: string;
  /** submit CTA label */
  submit: string;
}

export interface SocialNativeUploadMediaTranslations
  extends ImageAddCaptionTranslations {
  /** title */
  title: string;
  /** sub copy */
  subCopy: string;
  /** display name */
  displayName: string;
  /** email */
  email: string;
  /** agreement checkbox label */
  agreementLabel: string;
  /** email error message */
  emailErrorMessage: string;
  /** agreement checkbox error message */
  termsError: string;
  /** input required error message */
  requiredErrorMessage: string;
  /** create user error message */
  createUserError: string;
  /** upload media mobile title */
  uploaderMobileTitle: string;
  /** upload media mobile desc */
  uploadMediaMobile: string;
  /** next CTA label */
  next: string;
  /** upload media desc */
  uploader: string;
  /** submit photo CTA label */
  submitPhoto: string;
  /** error message when size is more than limit */
  errorImageOutOfSize: string;
  /** error message when extension is different than availables */
  errorImageWrongExtension: string;
  /** upload media success message */
  successMessage: string;
}

export enum SocialNativeLayout {
  HOMEPAGE = 'homepage',
  CLP = 'clp',
  GALLERY = 'gallery',
  PDP = 'pdp',
}

export interface ProductFindInStoreTranslations
  extends ProductFindInStoreListTranslations {
  /** Modal heading */
  heading: string;
  /** Modal subtitle */
  subtitle: string;
  /** Or separator */
  or: string;
  /** Of separator */
  of: string;
  /** Select miles */
  miles: string;
  /** Location button */
  locationButton: string;
  /** Find  button */
  findButton: string;
  /** Placeholder text of postal code / city / address input field */
  postalCodeLabel: string;
  /** No result text */
  errorMessage: string;
  /** No required field text */
  requiredError: string;
  km: string;
  /** Aria label for distance select */
  selectAriaLabel: string;
}

export interface ProductFindInStoreListTranslations
  extends ProductFindInStoreListRowTranslations {
  /** Checkbox text */
  showOnlyWithStock: string;
  /** Checkbox text */
  resultsOf: string;
  /** Store name label */
  storeName: string;
  /** Stock label */
  stock: string;
  /** viewMore label */
  viewMore: string;
}

export interface ProductFindInStoreListRowTranslations {
  /** Direction link text */
  direction: string;
  /** Hours label */
  hours: string;
  /** Week label */
  week: string;
  /** GIAS header */
  headerGIAS: string;
  /** GIAS text */
  descriptionGIAS: string;
  /** OOS transaltion */
  outOfStock: string;
  /** Available In Store translation */
  availableInStore: string;
  /** Ship To Store translation */
  stsAvailableLabel: string;
  /** In stock translation */
  inStock: string;
  /** Low Inventory translation */
  lowInventory: string;
  /** Make your store button text */
  makeYourStore: string;
  /** Remove from your store button text */
  removeFromYourStore: string;
  /** Your store label text */
  yourStore: string;
  /** Size label text */
  size: string;
  /** Color label text */
  color: string;
  /** day of the week name translation */
  monday: string;
  /** day of the week name translation */
  tuesday: string;
  /** day of the week name translation */
  wednesday: string;
  /** day of the week name translation */
  thursday: string;
  /** day of the week name translation */
  friday: string;
  /** day of the week name translation */
  saturday: string;
  /** day of the week name translation */
  sunday: string;
}

export interface OrderSummarySidebarTranslations {
  /** Item Subtotal label */
  itemSubtotalLabel: string;
  /** Promotion label */
  promotionLabel: string;
  /** Order summary label */
  orderSummaryLabel: string;
  /** Shipping label */
  shippingLabel: string;
  /** Tax label */
  taxLabel: string;
  /** Tax tooltip text */
  taxTooltip: string;
  /** Shipping tooltip text */
  shippingTooltip: string;
  /** Pickup tooltip text */
  pickupTooltip: string;
  /** STS tooltip text */
  stsTooltip: string;
  /** Order total label */
  orderTotalLabel: string;
  /** Print button label */
  printButtonLabel: string;
  /** continue shopping label */
  continueShoppingLabel: string;
  /** Gift card label text */
  giftCardLabel: string;
  /** Reward card label text */
  rewardCardLabel: string;
  /** Text for zero shipping price */
  free: string;
  /** Tooltip close text */
  close: string;
  /** Pickup label */
  pickup: string;
  /** Athlete discount property name label */
  athleteDiscount: string;
  /** Reward summary property label */
  summerReward: string;
  /** Reward summary property label */
  winterReward: string;
  /** Reward summary property label */
  springReward: string;
  /** Legacy reward label */
  legacyReward: string;
  /** Applied Vouchers Label */
  appliedVouchersLabel: string;
}

export interface SearchResultsArticlesTranslations {
  /** Show more articles button text */
  showMoreButtonText: string;
  /** Empty results title text */
  emptySearchTitle: string;
  /** Empty results subtitle text */
  emptySearchSubtitle: string;
  /** Read more articles button text */
  readMore: string;
}

export interface OrderProductsSummaryTranslations
  extends GiftOptionTranslations {
  /** Item description text */
  itemDescription: string;
  /** Quantity label */
  quantity: string;
  /** Price label */
  price: string;
  /** Total label */
  total: string;
  /** Color label */
  color: string;
  /** Size label */
  size: string;
  /** Length label */
  length: string;
  /** Quantity Label */
  qtyLabel: string;
  /** Promo code applied Label */
  itemPromoAppliedLabel: string;
  /** Aria label for cart products table */
  ariaLabel: string;
  /** Package Tracking label text */
  trackPackageLabel: string;
  /** Alternate Text for Shipment Label */
  shipmentSummaryArrival: string;
  /** Label for pickup information */
  pickupFromLabel: string;
  /** Notification text that the customer will be informed about the possibility of collecting the order */
  notificationText: string;
  /** Free label */
  free: string;
  /** Text for gift item */
  giftItemReturnNotAvailable: string;
}

export interface OrderSummaryRegisterTranslations {
  /** Heading text */
  heading: string;
  /** First line in list text */
  firstLine: string;
  /** Second line in list text */
  secondLine: string;
  /** Third line in list text */
  thirdLine: string;
  /** Password placeholder text */
  passwordPlaceholder: string;
  /** Register button text */
  registerButtonText: string;
  /** Field is required text */
  fieldIsRequired: string;
}

export interface OrderSuccessDetailsTranslation {
  /** Shipping info text */
  shippingInfoLabel: string;
  /** Shipping to text*/
  shippingToLabel: string;
  /** Shipping method text*/
  shippingMethodLabel: string;
  /** Address text */
  addressLabel: string;
  /** Billing info text */
  billingInfoLabel: string;
  /** Billing to text  */
  billingToLabel: string;
  /** Payment label text */
  paymentLabel: string;
  /** Pickup info label text */
  pickupInfoLabel: string;
  /** Pickup location label text */
  pickupLocationLabel: string;
  /** Pickup person label text */
  pickupPersonLabel: string;
  /** Alt Pickup person label text */
  altPickupPersonLabel: string;
  /** Get direction button text */
  direction: string;
  /** Payment method label texts */
  paymentMethodLabel: {
    REWARD_CODE: string;
    CREDIT_CARD: string;
    GIFT_CARD: string;
    REWARD_CARD: string;
    PAYPAL: string;
    KLARNA: string;
    DW_APPLE_PAY: string;
    ATHLETE_CREDIT: string;
    LOYALTY_POINTS: string;
  };
  /** Loyalty points label text */
  loyaltyPointsDisplayText: string;
  /** Quantity text */
  quantity: string;
  /** Price text */
  price: string;
  /** Total text */
  total: string;
  /** Color text */
  color: string;
  /** Size text */
  size: string;
  /** mask for card number Text */
  cardNumberReplacement: string;
  /** Details info label text */
  detailsInfoLabel: string;
  /** Details introduction text */
  detailsIntroText: string;
  /** Details question text */
  detailsQuestionText: string;
  /** Details steps texts for in-store pickup*/
  detailsInStoreStepsTexts: string[];
  /** Details steps texts for Curbside pickup*/
  detailsCurbsideStepsTexts: string[];
  /** Details steps texts for in-store or Curbside pickup*/
  detailsInStoreAndCurbsideStepsTexts: string[];
  /** Reward code description */
  summerReward: string;
  /** Reward code description */
  winterReward: string;
  /** Reward code description */
  springReward: string;
}

export interface OrderPickupDetailsTranslation {
  /** Details info label text */
  detailsInfoLabel: string;
  /** Details introduction text */
  detailsIntroText: string;
  /** Details question text */
  detailsQuestionText?: string;
  /** Details steps texts */
  detailsStepsTexts: string[];
}

export interface OrderSmsSubscriptionTranslations {
  /* SMS title text */
  title: string;
  /* SMS subtitles */
  subtitle: {
    /* SMS unsbscribed user subtitle text */
    unsubscribed: string;
    /* SMS opted In user subtitle text */
    optedIn: string;
    /* SMS confirmed user subtitle text */
    confirmed: string;
  };
  /* SMS phone input label text */
  phoneLabel: string;
  /* SMS checkbox input label text */
  checkboxLabel: string;
  /* SMS update preferences for logged in users text */
  updatePreferencesText: string;
  /* SMS CTA text */
  subscribeCTA: string;
  /* SMS validation messages */
  validationMessages: {
    /* SMS phone error text */
    phoneError: string;
    /* SMS checkbox error text */
    checkboxError: string;
  };
}

export interface OrderBrandAddressTranslation {
  /** Address text */
  address: string;
}

export interface OrderSummaryDetails {
  /** Shipping info label text */
  shippingInfoLabel: string;
  /** Shipping to label text*/
  shippingToLabel: string;
  /** Shipping method label text */
  shippingMethodLabel: string;
  /** Address label text*/
  addressLabel: string;
  /** Billing info label text */
  billingInfoLabel: string;
  /** Billing to label text*/
  billingToLabel: string;
  /** Payment label text */
  paymentLabel: string;
  /** Item summary label text*/
  itemSummaryLabel: string;
  /** Track package label text */
  trackPackageLabel2: string;
  /** Item description label text*/
  itemDescription: string;
  /** Quantity label text */
  quantity: string;
  /** Price label text*/
  price: string;
  /** Total label text */
  total: string;
  /** Pending items label text*/
  pendingItems: string;
  /** Color label text */
  color: string;
  /** Size label text*/
  size: string;
  /** Quantity label text*/
  qtyLabel: string;
}

export interface OrderSummaryProfileTranslations {
  /** Heading text */
  heading: string;
  /** No orders exists text */
  textNoOrders: string;
  /** CTA link url */
  ctaLink: string;
  /** CTA text if orders exists */
  ctaText: string;
  /** CTA text if orders do not exists */
  ctaTextNoOrders: string;
  /** Order number label */
  orderLabel: string;
  /** Order date label */
  placedLabel: string;
  /** Order status label */
  statusLabel: string;
  /** Notification when server encountered error */
  serverErrorMessage: string;
}
export interface SpinnerTranslations {
  /** Loading text  */
  loading: string;
}

export interface ProfileSummaryTranslations {
  /** Proflie Summary heading text */
  heading: string;
  /** The URL for the button  */
  link: string;
  /** Button text to redirect to the Edit Page */
  buttonText: string;
  /** Name label text */
  nameLabel: string;
  /** Email address label text */
  emailLabel: string;
  /** Password label text */
  passwordLabel: string;
  /** Interests label text */
  interestsLabel: string;
}

export interface MyAccountHeadingTranslations {
  /** MyAccount heading Heading text */
  headingLabel: string;
  /** Signout label for Signout link */
  signOutLabel: string;
  /** Signout link */
  signOutLink: string;
  /** Signout ('Not') label for current logged user */
  notUserLabel: string;
}

export interface ProductAdditionalDetailsTranslations {
  title: {
    /** Additional product description title */
    description: string;
    /** Additional product features title */
    features: string;
    /** Additional product details title */
    details: string;
  };
  style: string;
}

export interface LoyaltyMemberDashboardTranslations {
  /** Loading message text */
  loadingIndicator: string;
  /** Retry again message text */
  retryAgain: string;
  /** Application error message text */
  appError: string;
}

export type XPRLWidgetTranslations = Pick<
  BasicInformationTranslations,
  'memberId' | 'loyaltyStateEnrolled' | 'viewPointsLabel'
>;

export interface LoyaltyRegisterTranslations extends CreateAccountTranslations {
  /** Title for legacy users */
  headingLegacy?: string;
  /** Subtitle text for legacy users */
  subheadingLegacy?: string;
  /** Country code label form text*/
  countryCodeLabel: string;
  /** Phone mask characters*/
  phoneMask: string;
  /** Button for join loyalty text */
  joinNowButtonText: string;
  /** Cta for the tooltip text */
  close: string;
  /** Content of the phone tooltip text*/
  phoneWhyText: string;
  /** Content of the dob tooltip text*/
  dateOfBirthWhyText: string;
  /** Validation messages */
  validationMessages: LoyaltyRegistrationValidationMessages;
}

interface LoyaltyRegistrationValidationMessages
  extends CreateAccountValidationMessages {
  /** Message for a valid name */
  phoneUniqueError: string;
  /** Error for user too young */
  minRegisterAgeError: string;
}

export interface LoyaltySuccessStepTranslations {
  heading: string;
  headingLegacy?: string;
  headingSignIn?: string;
  subheading: string;
  subheadingLegacy?: string;
  subheadingSignIn?: string;
  buttonText: string;
  secondButtonText: string;
}

export interface LoyaltyRewardsProgressTranslations {
  /* default greeting */
  greeting: string;
  /* greeting for a user with a name */
  greetingWithName: string;
  /* total rewards text */
  totalRewards: string;
  /* rewards summary text */
  rewardsSummary: string;
}

export interface SiteSelectorTranslations {
  /** Site Selector heading text */
  heading: string;
  /** Delivery Location label for dropdown  */
  deliveryLocationLabel: string;
  /** Language selector label for dropdown  */
  languageSelectorLabel: string;
  /** Button text to set site and language selected */
  buttonText: string;
  /** Helper text */
  helperText: string;
  /** Currency text */
  currencyText: string;
  /** Language Selector PlaceHolder (dropdown first option) text */
  languageSelectorPlaceHolder: string;
  /** Language selected text (for when there's only one option) */
  languageSelectedLabel: string;
  /** Delivery Location Selector PlaceHolder (dropdown first option) text */
  deliveryLocationPlaceHolder: string;
}

export interface CartNotificationTranslations {
  updatedOrderReasons: string;
  itemNotAvailable: string;
  updatedOrderTotal: string;
}

export interface NotificationsTranslations {
  /** Customer notifications */
  customerNotifications: {
    /** Customer notification to display when selected shipping method is not available */
    OrderShippingMethodDowngraded: string;
  };
}

export interface InterestsArray {
  key: string;
  label: string;
}
export interface ProfileSummaryCardTranslations {
  /** Heading */
  heading: string;
  /** Button text */
  buttonText: string;
  /** Name label */
  nameLabel: string;
  /** Email label */
  emailLabel: string;
  /** Password label */
  passwordLabel: string;
  /** Interests label */
  interestsLabel: string;
  /** Interests Items */
  interestItems: InterestsArray[];
}

export interface ProfileSmsSubscriptionTranslations {
  title: string;
  subtitle: {
    subscribed: string;
  };
  unsubscribed: string;
  checkboxLabel: string;
  termsAndConditions: {
    body: string;
  };
  confirmationTooltip: string;
  phoneLabel: string;
  notifications: {
    unsubscribe: string;
  };
  validationMessages: {
    phoneError: string;
  };
  unsubscribeCTA: string;
  subscribeCTA: string;
  saveButtonText: string;
  required: string;
}

export interface CustomProductSlideTranslations {
  buyCta: string;
  inspiredBy: string;
  editDesignCTA: string;
  noProductFound: string;
  accessibility: {
    carouselSlideDescription: string;
  };
}

export interface CustomsSizeQuantitySelectorTranslations {
  addAnotherButtonText: string;
  addToCartButtonText: string;
  sizeLabel: string;
  sizePlaceholder: string;
  qtyLabel: string;
}

export interface ShoppingCartLabelTranslations {
  multipleItemLabel: string;
  shoppingCartLabel: string;
  singleItemLabel: string;
  cartMergedNotification: string;
}

export interface ProductAddToCartTranslations {
  buttonCTASuccess: string;
  notificationDiscontinued: string;
  buttonCTANotify: string;
  /** Button CTA Discontinued text */
  buttonCTADiscontinued: string;
  /** Button CTA Active text */
  buttonCTAActive: string;
  /** Notify box title text */
  notifyBoxTitle: string;
  /** Notify email title */
  notifyEmailTitle: string;
  /** Notify email input placeholder */
  notifyEmailPlaceholder: string;
  /** Notify button text in modal */
  notifyButtonText: string;
  /** Notify box text */
  notifyBoxTextBox: string;
  /** Validation Messages for email input */
  validationMessages: {
    /** Required error message */
    requiredError: string;
    /** Invalid email error message */
    emailError: string;
  };
  /** Notify successful subscribe message */
  notificationMessage: string;
  /** Notify subscribe error message */
  notificationErrorMessage: string;
  /** Notify already subscribed message */
  notificationAlreadySubscribedMessage: string;
  /** Sign In To Buy button text */
  signInToBuy: string;
  /** Size label */
  sizeLabel: string;
  /** Customize button text */
  buttonCTACustomize: string;
  /** Button cta to show if all variants are sold out */
  soldOutCta: string;
  /** Remove from favorites label */
  removeFromFavorites: string;
  /** Add to favorites label */
  addToFavorites: string;
  /** Added to favorites label */
  addedToFavorites: string;
  /** Added to favorites notification */
  addedToFavoritesNotification: string;
}

export interface ShopByGridGridTitleTeaserStyles {
  titleColor: string;
  titleFontSize: string;
  teaserClass: string;
  titleFontWeight: string;
  titleFontFamily: string;
  titleBackgroundColor: string;
  headingLevel: string;
  titleClassModifiers: string;
}

// TODO: Remove it after GLOBAL15-56318
export interface ClearSession {
  title: string;
  role: string;
  icon: string;
  titleColor: string;
  titleBackgroundColor: string;
  titleClassModifiers: string;
  link: string;
}

export interface BenefitItem {
  title: string;
  benefitAttributeName: string;
}

export interface SearchGridTranslations extends QuickShopTileTranslations {
  addedToWishlist: string;
  removedFromWishlist: string;
  itemsCountPlural: string;
  itemsCountSingular: string;
  specialPriceLabel: string;
  offerPriceLabel: string;
  seeMoreColorsLink: string;
  quickShopButtonText: string;
  addWishListAriaLabel: string;
  removeWishListAriaLabel: string;
  addToCartLabel: string;
  viewPrevious: string;
}

export interface InterestsFormCollectionTranslations {
  /** Heading text */
  heading: string;
  /** Subheading text */
  subheading: string;
}

export interface LoyaltyQuestionsTranslations {
  /** Heading text */
  heading: string;
  /** Subheading text */
  subheading: string;
  /** Form submit CTA text */
  buttonText: string;
  whichLocation: {
    /** Different Store option label */
    differentStore: string;
    /** Did Not Hear option label */
    didNotHearInStore: string;
    /** Default option label */
    selectStore: string;
    /** Select label */
    label: string;
  };
  /** Interests description label */
  interestsSubheading: string;
  /** Shoe size option label */
  shoeSizeLabel: string;
  /** Shoe size option placeholder label */
  shoeSizePlaceholder: string;
  /** Shoe size selector mens button text */
  shoeSizeMensText: string;
  /** Shoe size selector womens button text */
  shoeSizeWomensText: string;
  /** How to display shoe size inside input e.g. {{gender}} - {{size}} */
  shoeSizeDisplayFormat: string;
  age: {
    /** Age input label */
    label: string;
    /** Select label */
    selectAge: string;
  };
}

export interface ShoeSizeSelectorTranslations {
  /** Shoe size selector input label */
  label: string;
  /** Shoe size selector input placeholder */
  placeholder: string;
  /** Shoe size selector mens button text */
  mensText: string;
  /** Shoe size selector womens button text */
  womensText: string;
  /** How to display shoe size inside input e.g. {{gender}} - {{size}} */
  displayFormat: string;
}

export interface ApplePayTranslations {
  /** Label for order total value */
  total: string;
  /** Label for order subtotal value */
  subtotal: string;
  /** Label for order tax value */
  tax: string;
  /** Label for order shipping value */
  shipping: string;
  /** Label for order discount value */
  discount: string;
  /** Place order call to action */
  placeOrder: string;
  /** ApplePay payment method name */
  applePay: string;
  /** ApplePay payment tooltip text */
  tooltip: string;
  /** Gift Cards line item label */
  giftCard: string;
  /** Label for Athlete Credit dicount value  */
  athleteCreditLabel: string;
  /** Wrong name format error  */
  nameFormatError: string;
  /** Name max length error  */
  nameLengthError: string;
  /** Address format error  */
  addressFormatError: string;
}

export type LoyaltyFinalizationType =
  | 'displayConfirmationStep'
  | 'redirectToPage'
  | 'refreshCurrentPage'
  | 'closeModal';

export interface LoyaltyQueryParamSettings {
  campaignName?: string;
  stlHash?: string;
  pageToRedirectTo?: string;
  email?: string;
  join?: boolean;
}

export type LoyaltyRegisterLoginStepName =
  | 'login'
  | 'join'
  | 'survey'
  | 'confirmation';

export type LoyaltySurveyStep = {
  enabled: boolean;
  heading: string;
  subheading: string;
  imageObject: ImageObject;
  buttonText: string;
  buttonLink: string;
  secondButtonText?: string;
  secondButtonLink?: string;
};

export type LoyaltyConfirmationStep = {
  bindings: Record<string, unknown>;
  heading: string;
  subheading: string;
};

export interface OrderSuccessTranslation {
  /** Header displayed on order success page */
  header: string;
  /** Message displayed on order success page, below the header */
  message: string;
  /** Label for button for adding an order to the customer account */
  addOrderToAccount: string;
  /** Info message that customer account with provided e-mail was found */
  emailHeader: string;
  /** Question text if customer wants to add an order to his account */
  orderAccountAssign: string;
  /** Success message to inform customer that an order was added to his account */
  successMessage: string;
  /** A Link for users to go to thier Order History to see thier newly added order */
  viewOrderHistory: string;
  /** Text of notification to display at the top of whole section  */
  notification: string;
  /** Details info label text */
  detailsInfoLabel: string;
  /** Details introduction text */
  detailsIntroText: string;
  /** Details question text */
  detailsQuestionText: string;
  /** Details steps texts for in-store pickup*/
  detailsInStoreStepsTexts: string[];
  /** Details steps texts for Curbside pickup*/
  detailsCurbsideStepsTexts: string[];
  /** Details steps texts for in-store or Curbside pickup*/
  detailsInStoreAndCurbsideStepsTexts: string[];
}

export interface OrderRegisterXPLRTranslations {
  plusText: string;
  heading: string;
  earnMorePoints: string;
  passwordPlaceholder: string;
  show: string;
  validationSteps: {
    characters: string;
    number: string;
    heading: string;
    uppercaseLetter: string;
    lowercaseLetter: string;
  };
  agree: string;
  emailAddress: string;
  enrolledHeading: string;
  learnMore: string;
  shippingTerms: string;
  joinNow: string;
  ptsExplanation: string;
  loyaltyStateEnrolled: string;
}

export interface CreditCardsSummaryProfileTranslations {
  /** Heading text */
  heading: string;
  /** No orders exists text */
  textNoCreditCards: string;
  /** CTA link url */
  ctaLink: string;
  /** CTA link url for add credit card */
  ctaLinkNew: string;
  /** CTA text if orders exists */
  ctaText: string;
  /** CTA text if orders do not exists */
  ctaTextNoCreditCards: string;
  /** Default credit card label */
  defaultCreditCardLabel: string;
  /** Credit card label */
  cardLabel: string;
  /** Address label */
  addressLabel: string;
  /** Phone label */
  phoneLabel: string;
  /** Replacement text for credit card number */
  cardNumberReplacementText: string;
  /** Replacement text for credit card expiration date */
  cardExpReplacementText: string;
  /** Notification when server encountered error */
  serverErrorMessage: string;
}

interface ContentMediaImage {
  image: Image;
}
interface ContentMediaVideo {
  video: Video;
}

export type ContentMedia = ContentMediaImage | ContentMediaVideo;

export interface ContentText {
  variant: string;
  color: string;
  backgroundColor: string;
  text: string;
}

export enum LinkBehavior {
  Tab = 'openInNewTab',
  Modal = 'openInNewModal',
  Scroll = 'scrollToComponent',
}

export interface ContentCTA {
  styleClass: string;
  label: string;
  link: string;
  icon: string;
  linkBehavior?: LinkBehavior;
  buttonTargetComponent?: string;
}

export interface ContentHeroOverlay {
  position: string;
  width: string;
}

export interface QuickShopProductCtasTranslations
  extends ProductCtaButtonTranslations {
  /** CTA show more text */
  showMoreText: string;
  /** CTA more details text */
  moreDetailsCTA: string;
  /** Text displayed inside notification after product is added to favourites */
  addedToFavoritesNotification: string;
  buttonCTASuccess: string;
}

export interface PdpSizeAndFitTranslations {
  /* Fit based on label */
  sizeFitSubBeginning: string;
  /* Label that comes after sizeFitSubBeginnign */
  sizeFitSubEnding: string;
  tooBig: string;
  tooSmall: string;
}

export type CuralateType =
  | 'custom-homepage'
  | 'custom-gallery'
  | 'custom-category'
  | 'custom-plp'
  | 'custom-product'
  | 'custom-campaign'
  | 'custom-campaign-gallery'
  | 'other';

export enum SelectorVariant {
  Dropdown = 'dropdown',
  Chips = 'titles-chips',
}

export const SelectorVariants = Object.values(SelectorVariant);

export interface CheckoutAthleteDiscountTranslations {
  /** Athlete credit accordion header text */
  title: string;
  /** Athlete credit amount heading */
  amount: string;
  /** Message about using the entire limit of athlete credit */
  amountMessage: string;
  /** Apply CTA */
  apply: string;
  /** Additional CTA message about possibility to pay with athlete credit */
  applyMessage: string;
  /** Success notification to display after successfully applied discount */
  applySuccessMessage: string;
  /** Remove CTA */
  remove: string;
  /** Success notification to display after successfully unapplied discount */
  removeSuccessMessage: string;
}

export interface StoreCitiesTranslations {
  /** Sub Title*/
  cities: string;
}

export type Segment = {
  segmentID: string;
  negation: boolean;
};

export interface PlpVisualCarouselFilter {
  headline: string;
  headlineAria?: string;
  code: string;
  value: string;
  backgroundStyle?: 'dark' | 'light';
  imageUrl?: string;
  subcopy?: string;
}

export interface SeoRelatedWidgetTranslations {
  relatedSearches: string;
  relatedProducts: string;
  relatedItems: string;
  relatedCategories: string;
  showMore: string;
  showLess?: string;
  viewProduct: string;
}

export interface SlidesToShow {
  lg: number;
  md: number;
  sm: number;
}

export type TitlePosition = 'titleImgDescriptionCta' | 'imgTitleDescriptionCta';

export type SearchDisplayMode = 'merged' | 'split';
