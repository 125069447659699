import type { PaymentSessionPayload } from '@vf/api-client/src/api-types';
import { apiClientFactory } from '@vf/api-client';
import type { ComponentInstance } from '../types';

export const usePaymentSession = (
  instance: ComponentInstance,
  sessionData: Partial<PaymentSessionPayload> = {}
) => {
  const { getPaymentSession } = apiClientFactory(instance);
  const referer = instance.$getDomainName();

  return getPaymentSession({
    referer: !/^https?:\/\//i.test(referer) ? 'https://' + referer : referer,
    ...sessionData,
  });
};
