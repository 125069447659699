import { CMDataSource } from '../../../utils/dataSources/types';
import { extractProperty } from './extractProperty';

export const extractDataSources = (item: any): CMDataSource[] => {
  const localSettingsExpanded = extractProperty(
    item,
    'localSettingsExpanded',
    []
  );

  if (!Array.isArray(localSettingsExpanded)) {
    return [];
  }

  const dataSourcesObjects = localSettingsExpanded.find(
    (setting) => setting.path === 'dynamicFields.dataSources'
  );

  if (!dataSourcesObjects || !Array.isArray(dataSourcesObjects.links)) {
    return [];
  }

  return dataSourcesObjects.links as CMDataSource[];
};
