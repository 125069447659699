import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractCommonConfigLink,
  extractProperty,
  getDynamicField,
} from './utils';
import { getProductCtaButtonTranslations } from './utils/getProductCtaButtonTranslations';

export const ctaButtonProduct = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite
) => {
  const signInToBuy = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

  const loyaltyEnrollment = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'loyalty-enrollment-modal'),
    '[0]'
  )?.id;

  const isVans = context.instance.context.$env.brand === 'vans';

  const style = {
    'primary-variant': {
      //tnf only
      color: 'primary',
    },
    secondary: {
      color: 'secondary',
    },
    tertiary: {
      color: isVans ? 'secondary' : 'primary',
      variant: 'border',
    },
    'tertiary-variant': {
      color: 'secondary',
      variant: 'border',
    },
    loyalty: {
      color: 'loyalty',
    },
    'loyalty-variant': {
      color: 'loyalty',
      variant: 'border',
    },
    text: {
      color: 'primary',
    },
    'hover-variant': {
      color: 'primary',
      context: 'dark',
    },
    default: {
      color: 'primary',
    },
  }[getDynamicField(data, 'style') || 'default'];

  const size = {
    small: 'sm',
    'extra-small': 'xs',
    medium: 'md',
  }[getDynamicField(data, 'size') || 'medium'];

  const buttonStyles = {
    ...style,
    size,
  };

  return {
    component: 'shared/CtaButtonProduct',
    props: {
      modals: {
        signInToBuy,
        loyaltyEnrollment,
      },
      type: getDynamicField(data, 'type', 'add-to-cart'),
      text: extractProperty(data, 'teaserTitle'),
      enabled: getDynamicField(data, 'enabled', true),
      buttonStyles,
      isButtonText: getDynamicField(data, 'style') === 'text',
      isFullWidth: !getDynamicField(data, 'size'),
      translations: getProductCtaButtonTranslations(data),
    },
  };
};
