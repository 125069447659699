import { getDynamicField } from './utils/extractProperty';
import {
  CheckoutGiftOptionTranslations,
  CMPlaceholder,
} from '@vf/api-contract';
import { getGiftOptionTranslations } from './utils/getGiftOptionTranslations';
import { getTranslation } from './utils';

export const checkoutGiftOption = (data: CMPlaceholder) => {
  const translations: CheckoutGiftOptionTranslations = {
    ...getGiftOptionTranslations(data),
    giftOptionAccordionHeader: getTranslation(
      data,
      'giftOptionHeader',
      '$Gift Option$'
    ),
    giftOptionAccordionDescription: getTranslation(
      data,
      'giftOptionAccordionDescription',
      '$Our gift options includes gift messages and boxes$'
    ),
    editGiftOptionCTA: getTranslation(
      data,
      'editGiftOptionCTA',
      '$Edit Gift Option$'
    ),
    giftOptionCTA: getTranslation(data, 'giftOptionCTA', '$Gift Option$'),
    giftOptionAddedCTA: getTranslation(
      data,
      'giftOptionAddedCTA',
      '$Gift Option Added$'
    ),
    modalSubtitle: getTranslation(data, 'giftOptionModalSubitle', ''),
    modalGiftOptionUnavailable: getTranslation(
      data,
      'modalGiftOptionUnavailable',
      '$Gift options are not available for this item$'
    ),
    finished: getTranslation(data, 'finished', '$Finished$'),
    formHeading: getTranslation(data, 'formHeading', ''),
  };

  return {
    component: 'checkout/CheckoutGiftOption',
    props: {
      translations,
      context: getDynamicField(data, 'giftOptionContext', 'shipping'),
    },
  };
};
