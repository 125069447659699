import { CMPictureCropType, CmsSite, CMTeaser } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  decorateHtmlValue,
  extractLocalSettingsExpandedLinks,
  extractProperty,
  generateLinkFromTeaserTargets,
  getImageObject,
  getMediaSeoAttributes,
  getTranslation,
  getVideoObject,
  getVideoResponsiveMedia,
} from './utils';
import { ctaButton, pdpCustomizeCta } from './index';

export const customsImageSlide = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string,
  carouselParams?: { controlsPosition: string; isFullHeight: boolean }
) => {
  const headerImageLinks = extractLocalSettingsExpandedLinks(
    data,
    'headerImage'
  );
  let headerTitle = extractProperty(
    data,
    'localSettings.dynamicFields.headerTitle',
    ''
  );
  if (headerTitle?.length === 0) {
    headerTitle = extractProperty(data, 'teaserTitle', '');
  }

  const headerImage = headerImageLinks?.length
    ? getImageObject(
        headerImageLinks[0],
        siteConfiguration,
        CMPictureCropType.LANDSCAPE_RATIO_8x3,
        cmsBaseUri
      )
    : {};

  const imageData = extractProperty(data, 'pictures[0]', {});
  const ctaButtonPosition = extractProperty(
    data,
    'localSettings.dynamicFields.ctaButtonPosition',
    'middle_center'
  );
  const isClickableImage = extractProperty(
    data,
    'localSettings.dynamicFields.clickableImage',
    false
  );
  const target = extractProperty(data, 'teaserTargets[0].target', null);
  const ctaButtonLink = generateLinkFromTeaserTargets(
    data.teaserTargets,
    context,
    siteConfiguration,
    data.urlParams
  );
  const ctaButtonText = extractProperty(
    data,
    'teaserTargets[0].callToActionText',
    ''
  );
  const ctaType = () => {
    switch (target?.viewtype) {
      case 'pdp-customize-cta':
      case 'cta-button':
        return 'slot';
      default:
        return ctaButtonLink && ctaButtonText ? 'link' : '';
    }
  };
  const cta = () => {
    let cta = null;
    if (target?.viewtype === 'pdp-customize-cta') {
      cta = pdpCustomizeCta(target);
    } else if (target?.viewtype === 'cta-button') {
      cta = ctaButton(target, context, siteConfiguration, cmsBaseUri);
    }
    if (cta) {
      cta.props.textCustomClasses = `${
        cta.props.textCustomClasses ?? ''
      } vf-button--${ctaButtonPosition}`;
      return {
        children: [cta],
      };
    }
    return {};
  };
  return {
    component: 'carousel/CustomsContentSlide',
    props: {
      id: data.id,
      type: data.viewtype,
      image: {
        ...getImageObject(
          imageData,
          siteConfiguration,
          CMPictureCropType.LANDSCAPE_RATIO_8x3,
          cmsBaseUri
        ),
        seo: getMediaSeoAttributes(imageData),
      },
      video: data.videos?.length
        ? {
            small: getVideoObject(data.videos, cmsBaseUri),
            medium: getVideoResponsiveMedia(data, cmsBaseUri, 'medium'),
            large: getVideoResponsiveMedia(data, cmsBaseUri, 'large'),
          }
        : null,
      useRichText: data.useRichText,
      text: data.useRichText
        ? decorateHtmlValue(
            data.teaserRichTextObject,
            cmsBaseUri,
            context,
            siteConfiguration
          )
        : data.teaserText,
      headerTitle,
      headerSubtitle: extractProperty(data, 'teaserSubtitle', ''),
      carouselSlideRoleDescription: getTranslation(
        data,
        'carouselSlideDescription',
        'Slide'
      ),
      headerImage,
      controlsPosition: carouselParams?.controlsPosition ?? 'bottom',
      isFullHeight: carouselParams?.isFullHeight ?? true,
      ctaButtonPosition,
      ctaType: ctaType(),
      ctaButtonLink,
      ctaButtonText,
      isClickableImage,
    },
    ...cta(),
  };
};
