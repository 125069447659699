
import { defineComponent } from '@vue/composition-api';
import VfIcon from '@vf/ui/components/Atom.Icon.vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { icon, ...iconPropsDef } = VfIcon.props;

export default defineComponent({
  name: 'VfChevron',
  functional: true,
  props: {
    ...iconPropsDef,
    direction: {
      validator(value) {
        return ['up', 'down', 'left', 'right'].includes(value);
      },
    },
  },
  render(h, { props }) {
    const { direction, ...iconProps } = props;

    return h('VfIcon', {
      class: `vf-chevron--${direction}`,
      props: { icon: 'chevron', ...iconProps },
    });
  },
});
