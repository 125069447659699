import { CmsSite, CMPicture } from '@vf/api-contract';
import { getImageObject, getMediaSeoAttributes } from '../utils';
import { ComposableContext } from '../../types';

export const CMPictureMapping = (
  data: CMPicture,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const { alt, width, height } = data;
  const imageObject = getImageObject(data, siteConfiguration, null, cmsBaseUri);

  return {
    component: 'common/ImageComponent',
    props: {
      src: imageObject,
      seo: getMediaSeoAttributes(data),
      alt,
      width,
      height,
    },
  };
};
