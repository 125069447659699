import { ComponentInstance } from '@vf/composables';
import { getCurrentInstance } from '@vue/composition-api';

interface ExtendedComponentInstance extends ComponentInstance {
  $getEDDLabel: (instance: ComponentInstance, dateString: string) => string;
}

// Add this import for testable components
export const useRootInstance = (): { root: ExtendedComponentInstance } => {
  const root = getCurrentInstance().proxy;
  return {
    root: root.$root as ExtendedComponentInstance,
  };
};

export default useRootInstance;
