import { Product, BrandifyStoreInfo, apiClientFactory } from '@vf/api-client';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { ref, Ref, computed, nextTick } from '@vue/composition-api';
import { AxiosResponse } from 'axios';
import { ssrRef } from '@nuxtjs/composition-api';
import { scrollIntoView } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '../store/featureFlags';
import sortBy from '../utils/sortBy';

type UseFindInStore = {
  stores: Ref<BrandifyStoreInfo[]>;
  productAvailabilities: Ref<AvailabilityInfo[]>;
  countries: Ref<any[]>;
  state: Ref<any>;
  cityStores: Ref<any>;
  store: Ref<any>;
  emptyResponseError: Ref<boolean>;
  errorResponseDetails: Ref<any>;
  requestPendingFlag: Ref<boolean>;
  showOnlyWithAvailability: Ref<boolean>;
};

type AvailabilityInfo = {
  storeId: string;
  quantity: number;
};

export const BRAND_ID_FIELD = 'enterprise_store_identifier';

const useFindInStore = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UseFindInStore> = initStorage<UseFindInStore>(
    instance,
    'useFindInStore'
  );
  const {
    getAvailability,
    getStoresCities,
    getCountriesList,
    getStoresCountries,
    getFindInStoreLocations,
    getStoresStates,
    getStoresStores,
    locatorSearch,
  } = apiClientFactory(instance);

  const featureFlags = useFeatureFlagsStore();

  const stores: Ref<BrandifyStoreInfo[]> =
    storage.get('stores') ?? storage.save('stores', ref([]));

  const productAvailabilities: Ref<AvailabilityInfo[]> =
    storage.get('productAvailabilities') ??
    storage.save('productAvailabilities', ref([]));

  const countries: Ref<any[]> =
    storage.get('countries') ??
    storage.save('countries', ssrRef([], 'useFindInStore-countries'));

  const state: Ref<any> =
    storage.get('state') ??
    storage.save('state', ssrRef({}, 'useFindInStore-state'));

  const cityStores: Ref<any> =
    storage.get('cityStores') ??
    storage.save('cityStores', ssrRef({}, 'useFindInStore-cityStores'));

  const store: Ref<any> =
    storage.get('store') ??
    storage.save('store', ssrRef({}, 'useFindInStore-store'));

  const emptyResponseError: Ref<boolean> =
    storage.get('emptyResponseError') ??
    storage.save('emptyResponseError', ref(false));

  const errorResponseDetails: Ref<any> =
    storage.get('errorResponseDetails') ??
    storage.save('errorResponseDetails', ref(null));

  const requestPendingFlag: Ref<boolean> =
    storage.get('requestPendingFlag') ??
    storage.save('requestPendingFlag', ref(false));

  const showOnlyWithAvailability: Ref<boolean> =
    storage.get('showOnlyWithAvailability') ??
    storage.save('showOnlyWithAvailability', ref(false));

  const validateSize = (productObject: Product) => {
    if (!productObject?.size) {
      productObject.validation.missingSize = true;
      return false;
    }
    return true;
  };

  const getStoresByPostalCode = async (
    postalCode: string,
    distance: string,
    unitOfMeasure: string,
    productId: string
  ) => {
    return getStores({ postalCode, distance, unitOfMeasure, productId });
  };

  const getStoresByGeo = async (
    lat: number,
    lng: number,
    distance: string,
    unitOfMeasure: string,
    productId: string
  ) => {
    return getStores({ lat, lng, distance, unitOfMeasure, productId });
  };

  const getStores = async ({
    productId,
    enhancedStoreSearch,
    sortByAvailability,
    resultSetSize,
    filterBOPISStores,
    ...body
  }: {
    lat?: number;
    lng?: number;
    postalCode?: string;
    distance?: string;
    unitOfMeasure?: string;
    productId: string;
    enhancedStoreSearch?: boolean;
    sortByAvailability?: boolean;
    resultSetSize?: number;
    filterBOPISStores?: boolean;
  }) => {
    requestPendingFlag.value = true;
    resetData();
    emptyResponseError.value = false;

    // TODO: update once GLOBAL15-51668 epic is done
    // and the feature flag is no more needed
    const apiCall = featureFlags.isStoresEapiEnabled
      ? getFindInStoreLocations
      : locatorSearch;

    try {
      const searchResponse: AxiosResponse = await apiCall(body, {
        enhancedStoreSearch,
        filterBOPISStores,
      });

      if (searchResponse.status === 200 && searchResponse.data.code === 1) {
        const storesWithId = searchResponse.data.response.collection.filter(
          (element) => element[BRAND_ID_FIELD]
        );
        if (storesWithId.length) {
          if (!productId) {
            stores.value = storesWithId;
            return searchResponse;
          }
          try {
            const availabilityResponse = await getProductAvailabilities(
              storesWithId,
              productId,
              { sortByAvailability, resultSetSize }
            );
            productAvailabilities.value = availabilityResponse
              ? availabilityResponse.data.storeInventory
              : [];
          } catch (e) {
            instance.$log.error(
              `[@useFindInStore::getStores]: Failed getProductAvailabilities of productId: ${productId}`,
              e
            );
          } finally {
            stores.value = storesWithId.map((storeInfo) => {
              const availableStore = productAvailabilities.value.find(
                (availInfo) =>
                  availInfo.storeId === storeInfo[BRAND_ID_FIELD] &&
                  availInfo.quantity
              );
              return {
                ...storeInfo,
                has_product: !!availableStore,
                quantity: availableStore?.quantity || 0,
              };
            });
          }
        } else emptyResponseError.value = true;
      } else emptyResponseError.value = true;
      return searchResponse;
    } catch (err) {
      emptyResponseError.value = true;
      errorResponseDetails.value = err;

      instance.$log.error(
        `[@useFindInStore::getStores]: Error while fetching product availability of productId: ${productId}`,
        err
      );
      return null;
    } finally {
      requestPendingFlag.value = false;
    }
  };

  const getProductAvailabilities = async (
    storeInfos: BrandifyStoreInfo[],
    productId: string,
    params?: { resultSetSize?: number; sortByAvailability?: boolean }
  ) => {
    const vansStoreIds = storeInfos.reduce((acc, element) => {
      if (element[BRAND_ID_FIELD])
        acc.push({ storeId: element[BRAND_ID_FIELD] });
      return acc;
    }, []);

    if (vansStoreIds.length)
      return getAvailability(productId, vansStoreIds, params);
    return false;
  };

  const resetData = () => {
    stores.value = [];
    productAvailabilities.value = [];
    emptyResponseError.value = false;
    errorResponseDetails.value = null;
    showOnlyWithAvailability.value = false;
  };

  const storesWithAvailability = computed(() =>
    stores.value.filter(availabilityFilter)
  );

  const availabilityFilter = (storeInfo) =>
    productAvailabilities.value.some(
      (availInfo) =>
        availInfo.storeId === storeInfo[BRAND_ID_FIELD] && availInfo.quantity
    );

  const isSTSEnabled = (store) => store.sts_enabled === '1';
  const isBOPISEnabled = (store) => store.bopis_enabled === '1';

  const storesWithAvailabilityOrSts = computed(() =>
    stores.value.filter(
      (storeInfo) => availabilityFilter(storeInfo) || isSTSEnabled(storeInfo)
    )
  );

  const storesForPickup = computed(() =>
    stores.value.filter((store) => {
      return (
        (availabilityFilter(store) && isBOPISEnabled(store)) ||
        isSTSEnabled(store)
      );
    })
  );

  const getCountriesData = (data) => {
    if (instance.$env.BRANDIFY_USE_FILTER_BY_COUNTRY === 'true') {
      return Array.from(
        data.filter((item) => item.name === instance.$env.BRANDIFY_COUNTRY)
      );
    }
    return Array.from(data);
  };

  const getStatesOfCountry = async (countryName: string) => {
    const apiCall = featureFlags.isStoresEapiEnabled
      ? getStoresStates({ country: countryName })
      : getCountriesList({
          objectname: 'Account::State',
          where: {
            country: {
              eq: countryName,
            },
          },
          limit: 0,
        });
    return apiCall
      .then((stateResponse) => {
        return stateResponse.data.response.collection;
      })
      .catch((err) => {
        instance.$log.error(
          `[@useFindInStore::getStatesOfCountry]: Failed get States of Country: ${countryName}`,
          err.message
        );
        return [];
      });
  };

  const getCountries = async () => {
    try {
      const response: AxiosResponse<any> = featureFlags.isStoresEapiEnabled
        ? await getStoresCountries()
        : await getCountriesList({
            objectname: 'Account::Country',
          });
      return response.data.response.collection || [];
    } catch (err) {
      instance.$log.error(
        `[@useFindInStore::getCountries]: Failed get Countries`,
        err
      );
    }
    return [];
  };

  const getCountriesAndStates = async () => {
    emptyResponseError.value = false;
    let items = [];

    const countriesCollection = await getCountries();
    if (countriesCollection.length) {
      items = await Promise.all(
        getCountriesData(countriesCollection).map(async (countryObj: any) => ({
          ...countryObj,
          states: await getStatesOfCountry(countryObj.name),
        }))
      );

      countries.value = items;
    } else emptyResponseError.value = true;
    return items;
  };

  const getCitiesOfState = async (countryName: string, stateName: string) => {
    try {
      const cities = featureFlags.isStoresEapiEnabled
        ? await getStoresCities({ country: countryName, state: stateName })
        : await getCountriesList({
            objectname: 'Account::City',
            where: {
              state: {
                eq: stateName,
              },
            },
            limit: 0,
          });
      return cities.data.response.collection || [];
    } catch (e) {
      instance.$log.error(
        `[@useFindInStore::getCitiesOfState]: Failed get cities of: country: ${countryName}, state: ${stateName}`,
        e.message
      );
      return [];
    }
  };

  const getState = async (stateName) => {
    try {
      const response = featureFlags.isStoresEapiEnabled
        ? await getStoresStates({ state: stateName })
        : await getCountriesList({
            objectname: 'Account::State',
            where: {
              name: {
                eq: stateName,
              },
            },
          });
      return response.data.response?.collection?.[0];
    } catch (e) {
      instance.$log.error(
        `[@useFindInStore::getState]: Failed get state of: ${stateName}`,
        e.message
      );
      return null;
    }
  };

  const getStateWithCities = async (stateName: string, withCities = true) => {
    const stateObject = await getState(stateName);
    if (stateObject) {
      if (withCities) {
        stateObject.cities = await getCitiesOfState(
          stateObject.country,
          stateName
        );
      }
      state.value = stateObject;
    }
    return stateObject;
  };

  const getStoresOfCity = async ({
    city,
    country,
    state,
    order,
  }: {
    city: string;
    country: string;
    state: string;
    order?: string;
  }) => {
    try {
      const response = featureFlags.isStoresEapiEnabled
        ? await getStoresStores({ city, country, state })
        : await getCountriesList({
            objectname: 'Locator::Store',
            order,
            where: {
              and: {
                city: {
                  ilike: city,
                },
                or: {
                  province: {
                    ilike: state || '%',
                  },
                  state: {
                    ilike: state || '%',
                  },
                },
              },
            },
            limit: 0,
          });
      return featureFlags.isStoresEapiEnabled
        ? orderBy(response.data.response?.collection || [], order)
        : response.data.response?.collection || [];
    } catch (e) {
      instance.$log.error(
        `[@useFindInStore::getStoresOfCity]: Failed get stores of city: ${city}, state: ${state}`,
        e.message
      );
      return [];
    }
  };

  const getCity = async ({ city, state }: { city: string; state: string }) => {
    try {
      const response = featureFlags.isStoresEapiEnabled
        ? await getStoresCities({ state })
        : await getCountriesList({
            objectname: 'Account::City',
            where: {
              and: {
                city: {
                  ilike: city,
                },
                state: {
                  ilike: state || '%',
                },
              },
            },
          });
      if (featureFlags.isStoresEapiEnabled) {
        // find function is necessary because we don't have filter by city in eAPI call
        const cityInLowerCase = city.toLowerCase();
        const cityRegex = city.includes('%')
          ? new RegExp(cityInLowerCase.replace(/%/g, '.'))
          : null;

        return response.data.response.collection?.find((cityObj) => {
          if (cityObj.city) {
            return cityRegex
              ? cityRegex.test(cityObj.city.toLowerCase())
              : cityObj.city.toLowerCase() === cityInLowerCase;
          }
          return false;
        });
      }
      return response.data.response.collection?.[0];
    } catch (e) {
      instance.$log.error(
        `[@useFindInStore::getCity]: Failed getCity of city: ${city}, state: ${state}`,
        e.message
      );
      return undefined;
    }
  };

  const getCityWithStores = async ({
    city,
    state,
    storeOrder,
  }: {
    city: string;
    state: string;
    storeOrder?: string;
  }) => {
    const cityObj = await getCity({ city, state });
    if (cityObj) {
      cityObj.stores = await getStoresOfCity({
        city,
        country: cityObj.country,
        state,
        order: storeOrder,
      });

      cityStores.value = cityObj;
    }
  };

  const orderBy = (collection: any[] | undefined, order: string) => {
    if (order && collection?.length > 1) {
      return sortBy(
        collection,
        order
          .split(',')
          .map((or) => {
            const key = or.trim();
            return (item) => (item[key] ? item[key].toLowerCase() : '');
          })
          .reverse()
      );
    }
    return collection;
  };

  const getStore = async ({
    allStores,
    city,
    clientKey,
    country,
    order,
    state,
  }: {
    allStores?: boolean;
    city?: string;
    clientKey?: string;
    country?: string;
    order?: string;
    state?: string;
  }) => {
    try {
      const response: AxiosResponse<any> = featureFlags.isStoresEapiEnabled
        ? await getStoresStores({
            city,
            country,
            state,
            store: clientKey,
          })
        : await getCountriesList({
            objectname: 'Locator::Store',
            order,
            where: {
              and: {
                or: {
                  province: {
                    eq: state || '%',
                  },
                  state: {
                    eq: state || '%',
                  },
                },
                city: {
                  ilike: city || '%',
                },
                clientkey: {
                  ilike: clientKey || '%',
                },
              },
            },
          });
      if (
        response.data &&
        response.data.response.collection &&
        response.data.response.collection.length >= 1
      ) {
        const collection = featureFlags.isStoresEapiEnabled
          ? orderBy(response.data.response.collection, order)
          : response.data.response.collection;
        store.value = !allStores ? collection[0] : collection;
      }
    } catch (e) {
      instance.$log.error(`[@useFindInStore::getStore]: Failed get store`, e);
    }
  };

  const scrollToFindInStoreForm = () => {
    nextTick(() => {
      scrollIntoView('#find-in-store__form');
    });
  };

  const scrollToFindInStoreResults = () => {
    scrollIntoView('#product-find-in-store-list');
  };

  const isDealer = (store: { [BRAND_ID_FIELD]: string | null }): boolean =>
    !store[BRAND_ID_FIELD];

  return {
    BRAND_ID_FIELD,
    storesForPickup,
    getCityWithStores,
    getCountriesAndStates,
    getProductAvailabilities,
    getStateWithCities,
    getStore,
    getStores,
    getStoresByGeo,
    getStoresByPostalCode,
    isDealer,
    validateSize,
    resetData,
    stores: computed(() => stores.value),
    numStores: computed(() => stores.value.length),
    productAvailabilities,
    storesWithAvailability,
    storesWithAvailabilityOrSts,
    countries: computed(() => countries.value),
    cityStores: computed(() => cityStores.value),
    state: computed(() => state.value),
    store: computed(() => store.value),
    showOnlyWithAvailability,
    emptyResponseError,
    errorResponseDetails,
    requestPendingFlag,
    scrollToFindInStoreForm,
    scrollToFindInStoreResults,
  };
};

export default useFindInStore;
