import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getGalleryCustoms3d = (
  configuration: ApiClientConfiguration
) => async (recipeId: string): Promise<any> => {
  const url: string = `${
    endpoints.get(configuration).images.gallery.customs3d
  }`.replace('{recipeId}', recipeId);
  const response = await axios.get(url);

  return response;
};
