//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfNotification',
  directives: { focus },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'secondary',
      validator: function (value) {
        return ['secondary', 'info', 'success', 'danger'].includes(value);
      },
    },
    html: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    ariaLabel: {
      type: String,
      default: 'Close Notification',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  computed: {
    isTypographyEnabled() {
      return this.$themeConfig?.notification?.isTypographyEnabled
        ? 'VfText'
        : 'span';
    },
    icon() {
      switch (this.type) {
        case 'success':
          return 'correct';
        case 'danger':
          return 'error';
        default:
          return 'info';
      }
    },
    wrapperClass() {
      return this.showCloseButton ? 'vf-notification__message-wrapper' : '';
    },
  },
  methods: {
    actionHandler() {
      /**
       * Event for action button
       * @type {Event}
       */
      this.$emit('click-action');
    },
    closeHandler() {
      /**
       * Event for close icon
       * @type {Event}
       */
      this.$emit('click-close');
    },
  },
});
