import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils';

export const giftCardBalanceFormMessage = (data: CMPlaceholder) => {
  return {
    component: 'giftCards/CheckGiftCardBalanceMessage',
    props: {
      translations: {
        giftCardBalanceFor: getTranslation(
          data,
          'giftCardBalanceFor',
          '$Gift Card balance for$'
        ),
      },
    },
  };
};
