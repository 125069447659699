import { extractProperty } from './utils';
import { CmsSite, CMPlaceholder } from '@vf/api-contract';

export const pdpAttribute = (
  data: CMPlaceholder,
  _,
  siteConfiguration: CmsSite
) => {
  const priorityAttributes = extractProperty(
    siteConfiguration?.commonConfig,
    'pdp-attributes.attributes',
    []
  );

  return {
    component: 'pdp/PdpAttribute',
    props: {
      priorityAttributes,
    },
  };
};
