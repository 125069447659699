import {
  extractCommonConfigLink,
  extractProperty,
  generateLinkFromTarget,
  getTranslation,
} from './utils';
import { CmsSite, CMPlaceholder } from '@vf/api-contract';

export const pdpProductUpsell = (
  data: CMPlaceholder,
  context,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const quickShopLink = generateLinkFromTarget(
    extractProperty(
      extractCommonConfigLink(
        siteConfiguration,
        'pdp-product-upsell-quick-shop'
      ),
      '[0]'
    ),
    context,
    siteConfiguration,
    cmsBaseUri
  );
  return {
    component: 'shared/ProductsUpsell',
    props: {
      translations: {
        details: getTranslation(data, 'selectCta', '$Select$'),
        extras: getTranslation(data, 'title', '$Extras$'),
        title: getTranslation(
          data,
          'summary',
          '$Would you like to add {{product_name}} for an extra?"$'
        ),
      },
      quickShopLink,
    },
  };
};
