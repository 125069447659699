import axios from 'axios';
import {
  MonetateDecisionRequestBody,
  MonetateDecisionResponseBody,
} from '@vf/api-contract';

export const getExperienceVariantDecision = async (
  decisionEndpointUrl: string,
  body: MonetateDecisionRequestBody
): Promise<{ data: MonetateDecisionResponseBody }> => {
  const response = await axios.post<MonetateDecisionResponseBody>(
    decisionEndpointUrl,
    body
  );
  return response;
};
