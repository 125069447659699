import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';
import { Cart } from '../../types';

export const getCartObject = (configuration: ApiClientConfiguration) => (
  id: string = null,
  query: string
): Promise<AxiosResponse<Cart>> => {
  const url = urlBuilder(
    `${endpoints.get(configuration).cart.getCartObject}?${query}`,
    {
      config: configuration,
      mapping: {
        id,
      },
    }
  );
  return axios.get(url, {
    headers: headerBuilder(configuration),
  });
};
