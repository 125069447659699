import {
  CMComponent,
  CmsSite,
  VFConfigComponent,
  CMPlaceholder,
} from '@vf/api-contract';
import { Ref } from '@vue/composition-api';
import { cmsApiClient, OrderStatuses } from '@vf/api-client';
import { ComponentInstance, Taxonomy } from '../types';
import type { getImageObject } from './mappings/utils';

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type DecoratorMappingName =
  | 'StateManagement'
  | 'ComponentCmsIdDecorator'
  | 'SegmentsDecorator'
  | 'DataIdDecorator'
  | 'DataTrackingDecorator';

export type MappingMethod = (
  data: Exclude<CMComponent, VFConfigComponent>,
  context?: ComposableContext,
  siteConfiguration?: CmsSite,
  cmsBaseUri?: string,
  requirements?: CmsSanitizationRequirements,
  pageTypeName?: PageTypeName
) => {
  component: string;
  props?: any;
  attrs?: any;
  children?: any[];
};

export type FacetCmsConfiguration = {
  crawlable: boolean;
  fallbackCode: string;
  displayType: 'chip' | 'text' | 'checkbox' | 'thumbnail' | 'range';
  code: string;
  icons?: any;
  showAllVariants?: boolean;
};

/**
 * Set for gathering all requirements while recursive parser runs.
 * All requirements shall be fulfilled in CMS afterSSR lifecycle method.
 * More possibilities can be added if needed.
 * */
export enum CmsSanitizationRequirementName {
  PRODUCT_CONTEXT = 'productContext',
  REDIRECT = 'redirect',
  ARTICLES = 'articles',
  SORTED_ARTICLES = 'latestArticles',
  FACET_CONFIGURATION = 'facetConfiguration',
}
export type CmsSanitizationRequirementPayload = {
  to: string;
} | null;

export type CmsSanitizationRequirementStorage = {
  [CmsSanitizationRequirementName.PRODUCT_CONTEXT]: {
    name: CmsSanitizationRequirementName.PRODUCT_CONTEXT;
    productSkus: string[];
  };
  [CmsSanitizationRequirementName.REDIRECT]: {
    name: CmsSanitizationRequirementName.REDIRECT;
    to: string;
    options?: {
      permanent?: boolean;
      redirectBack?: boolean;
      notification?: string;
    };
  };
  [CmsSanitizationRequirementName.ARTICLES]: {
    name: CmsSanitizationRequirementName.ARTICLES;
    filters: Taxonomy[];
  };
  [CmsSanitizationRequirementName.SORTED_ARTICLES]: {
    name: CmsSanitizationRequirementName.SORTED_ARTICLES;
    filters: Taxonomy[];
    searchTerm: string;
    order: string;
  };
  [CmsSanitizationRequirementName.FACET_CONFIGURATION]: {
    name: CmsSanitizationRequirementName.FACET_CONFIGURATION;
    facetConfiguration: Record<string, FacetCmsConfiguration>;
  };
};

export type CmsSanitizationRequirement =
  | CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.PRODUCT_CONTEXT]
  | CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.REDIRECT]
  | CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.ARTICLES]
  | CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.SORTED_ARTICLES]
  | CmsSanitizationRequirementStorage[CmsSanitizationRequirementName.FACET_CONFIGURATION];

export type CmsSanitizationRequirements = CmsSanitizationRequirement[];

export enum PageTypeName {
  HOME = 'Home',
  PLP = 'PLP',
  PDP = 'PDP',
  CART = 'Cart',
  CHECKOUT = 'Checkout',
  ORDER_CONFIRMATION = 'Order Confirmation',
  MY_ACCOUNT = 'My Account',
  STORE_LOCATOR = 'Store Locator',
  CONTENT = 'Content',
  CUSTOMS = 'Customs',
  FAVORITES = 'Favorites',
  GIFT_CARD = 'Gift Card',
  SEARCH = 'Search',
  SUPPORT = 'Support',
  COMPANY = 'Company',
  IMPORTED = 'Imported',
  MODAL = 'Modal',
  ERROR_NOT_FOUND = '404',
  OTHER = 'Other',
  TAG = 'Tag',
  NEWS_ARTICLE = 'News Article',
  EVENT = 'Event',
  FAQ = 'FAQ Page',
  HELP = 'Help',
  SHOP_BY_ACTIVITY = 'Shop By Activity',
  PDP_QUICKSHOP = 'PDP Quickshop',
}

export enum PageLayoutName {
  PDP = 'pdp',
  PLP = 'plp',
  // SRP and Thematic page
  SRP = 'plpLayout',
  HP = 'homepage',
}

export type InitializedApiClient = ThenArg<ReturnType<typeof cmsApiClient>>;

export type ComposableContext = {
  instance: ComponentInstance;
  contextKey: string;
};

export type PreviewType = 'page' | 'fragment';
export type PreviewContentType =
  | string
  | 'CMChannel'
  | 'CMExternalChannel'
  | 'CMExternalProduct';

export type CmsSetupSettings = {
  forcePreview: boolean;
};

type CmsFetchMeta = {
  path?: string;
  resourceId?: string;
  resourceType?: string;
};

export type CmsHeaderConfig = {
  backgroundColor: string;
  isAnimated: boolean;
  isFadeEnabled: boolean;
  isSticky?: boolean;
};

export type CmsSharedState = {
  orderStatuses: OrderStatuses;
  baseUri: string | null;
  baseMediaUri: string | null;
  cmsSiteConfiguration: CmsSite | null;
  site: string | null;
  modalDataErrors: string[];
  cmsInitialized: boolean;
  page: any;
  pageTypeName: PageTypeName;
  pageLayoutName: string;
  pageProps: { [key: string]: string };
  modalData: any;
  errors: string[];
  currentFetch: CmsFetchMeta;
  requirements: CmsSanitizationRequirements;
  meta: {
    [key: string]: any;
  };
  pageUrl: string;
  stateRedirectUrlExecuted: boolean;
  canonicalRedirectUrlExecuted: boolean;
  breadcrumbs: CmsBreadcrumb[];
  espots: CmsEspot[];
  visualFilter: Record<string, unknown>;
  isNotFound: boolean;
  sizeCharts: {
    [key: string]: {
      src: {
        small: string;
        medium: string;
        large: string;
      };
      alt: string;
      type?: string;
    };
  };
  badges: {
    [key: string]: {
      src: {
        small: string;
        medium: string;
        large: string;
      };
      alt: string;
    };
  };
  benefits?: {
    [key: string]: {
      src: {
        small: string;
        medium: string;
        large: string;
      };
      alt: string;
    };
  };
  seo: {
    numberOfSimultaneousFilters?: string;
    numberOfSimultaneousFacets?: string;
  };
  facetConfiguration: Record<string, FacetCmsConfiguration>;
  employeeSignInPage: string;
  flexibleContentByPathResult: {
    [key: string]: any;
  };
};

export type CmsCanonical = {
  id: string;
  name: string;
  type: string;
  urlSegment: string;
  urlHost: string;
};

export type CmsBreadcrumb = {
  link: string;
  text: string;
  dataNavigation?: string;
  type?: string;
};

export type CmsFlexibleContentByPath = {
  [key: string]: any;
};

export type UseCmsStorage = {
  apiClientRef: Ref<InitializedApiClient | null>;
  cmsRef: Ref<CmsSharedState>;
  pageLoadPercentage: Ref<number>;
  pageLoadingPath: Ref<string>;
};

export type LanguageSelectorSite = {
  link: string;
  locale: string;
  displayName: string;
};

/**
 * Toggles property for the SearchResultsToggle component
 */
export type SearchResultToggle = {
  label: string;
  value: string;
};

export type CmsEspot = {
  position: number;
  mobileWidth: string;
  desktopWidth: string;
  tabletWidth: string;
  component: CMPlaceholder;
};

export interface Interest {
  title: string;
  code: string;
  image: ReturnType<typeof getImageObject>;
}

export interface RelatedSearch {
  anchor: string;
  url: string;
}
export interface RelatedProduct {
  attributes?: {
    key: string;
    value: string;
  }[];
  description: string;
  shortDescription: string;
  image: string;
  title: string;
  url: string;
}
