import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { PaymentMethodCode } from '@vf/api-client';
import { AxiosResponse } from 'axios';
import { ApiClientConfiguration } from '../../configuration';
import { Cart } from '../../types';

export type UpdateCartPaymentInstrumentPayload = {
  cartId: string;
  paymentInstrumentId: string;
  payerId: string;
  requestId: string;
  paymentMethodId: PaymentMethodCode.PAYPAL;
};

export const updateCartPaymentInstrument = (
  configuration: ApiClientConfiguration
) => async (
  data: UpdateCartPaymentInstrumentPayload
): Promise<AxiosResponse<Cart>> => {
  const response = await axios.patch<Cart>(
    urlBuilder(endpoints.get(configuration).cart.updatePaymentInstrument, {
      config: configuration,
      mapping: {
        basketId: data.cartId,
        instrumentId: data.paymentInstrumentId,
      },
    }),
    {
      c_paymentID: '',
      c_payerID: data.payerId,
      c_requestID: data.requestId,
      payment_method_id: data.paymentMethodId,
    },
    {
      headers: headerBuilder(configuration),
    }
  );
  return response;
};
