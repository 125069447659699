import { OrderDetails, Shipment, ShipmentExtended } from '@vf/api-contract';

export const getItemsFromShipment = (
  shipment: Shipment,
  items: OrderDetails['items']
): OrderDetails['items'] => {
  /**
   * IF shipmentId doesn't exists,
   * returns all items without shimpent.
   */
  const shipmentItems = items ?? [];
  if (!shipment?.shipmentId) {
    return shipmentItems;
  }

  const shipmentItemsSku = [];
  let products: OrderDetails['items'] = [];
  /**
   * IF shipment shimpentLine array with items and its quantity exists,
   * filter items matching shipment sku
   */
  if (shipment.shipmentLine?.length) {
    products = shipmentItems
      .filter((item) => {
        const isSameSku = shipment.shipmentLine.some(
          (line) => line.itemId === item.sku
        );
        const isSameShipment = item.shipments?.some((line) => {
          return shipment.shipmentIds?.includes(line.shipmentId) || false;
        });
        return isSameSku && isSameShipment;
      })
      // use quantity value from MuleSoft response if possible [GLOBAL15-36086]
      .map((product) => {
        product.shippingQuantity = Number(
          shipment.shipmentLine.find((line) => line.itemId === product.sku)
            ?.quantity || 0
        );
        return product;
      });
  } else {
    /**
     * IF some shipment doesn't contain shipmentId (shipmentId === '0')
     */
    products = shipmentItems.filter((item) => {
      const matchedShipmentId =
        item.shipmentId ??
        item.shipments?.find(
          (assignedShipment) =>
            assignedShipment.shipmentId === shipment.shipmentId
        )?.shipmentId;
      if (matchedShipmentId === shipment.shipmentId) {
        shipmentItemsSku.push(item.sku);
        return matchedShipmentId;
      }
    });
  }
  return products.filter((item) => !!item);
};

const getShipmentMethodString = (shipment) => {
  const { shipmentId, shippingMethod } = shipment ?? {};
  if (!shippingMethod) return shipmentId;
  const { label, deliveryTime, price } = shippingMethod ?? {};
  return `${label} ${deliveryTime} ${price?.currency ?? ''}`;
};

type ShipmentWithItems = Shipment & { items: OrderDetails['items'] };

export const groupShipmentWithItems = (
  shipments: ShipmentWithItems[],
  items: OrderDetails['items']
): ShipmentWithItems[] => {
  return (shipments ?? []).reduce((acc, shipment) => {
    // check if contains deliveryTime (different handling for Narvar EDD: GLOBAL15-10805)
    const hasEDD = items.some((item) => item.deliveryTime);
    // group by shipmentId when EDD present or pickup
    const sameShipment =
      hasEDD ?? shipment.storeName
        ? acc.find((item) => item.shipmentId === shipment.shipmentId)
        : acc.find(
            (item) =>
              getShipmentMethodString(item) ===
              getShipmentMethodString(shipment)
          );
    if (!sameShipment) {
      shipment.items = getItemsFromShipment(shipment, items);
      acc.push(shipment);
    } else {
      sameShipment.items.push(...getItemsFromShipment(shipment, items));
      sameShipment.shippingMethod.price.amount +=
        shipment.shippingMethod.price.amount;
    }
    return acc;
  }, []);
};

export const getShipmentTrackings = (
  shipments: Shipment[],
  shipment: Shipment
) => {
  const trackingNumber = [];
  const trackingUrl = [];
  if (!shipment)
    return {
      trackingNumber,
      trackingUrl,
    };
  if (Array.isArray(shipment.shipmentId) && shipment.shipmentId.length > 1) {
    shipment.shipmentId.forEach((id) => {
      const tracks = (shipments as any).find((s) => s.shipmentId === id)
        ?.shippingMethod;
      if (!tracks) return;
      tracks.trackingNumber.forEach((tNumber, i) => {
        if (trackingNumber.includes(tNumber)) return;
        trackingNumber.push(tNumber);
        trackingUrl.push(tracks.trackingUrl[i]);
      });
    });
    return {
      trackingNumber,
      trackingUrl,
    };
  }
  return shipment.shippingMethod;
};

export const sortOrderShipmentsByDeliveryDate = (
  shipments: ShipmentExtended[],
  groupItemsFromShipment: boolean
) => {
  const shipmentsByDeliveryDate = [];

  shipments.forEach((shipment) => {
    let deliveryDate = new Date('2100-12-31T12:00'); // Setting a future date to push down those shipments with no EDD

    const productEdd = groupItemsFromShipment
      ? shipment.shipmentProductsByStatus[shipment.shippingStatusLabel]?.[0]
          ?.extnEstimatedDeliveryDate
      : (shipment.shipmentProductsByStatus['all'] as any)?.find(
          (product) =>
            product.shipments?.[0]?.shipmentGroupId ===
            shipment['shipmentGroupId']
        )?.extnEstimatedDeliveryDate;

    if (productEdd) deliveryDate = new Date(productEdd);

    shipmentsByDeliveryDate.push({
      ...shipment,
      shipmentsByDeliveryDate: deliveryDate,
    });
  });

  return shipmentsByDeliveryDate.sort(
    (a, b) => a.shipmentsByDeliveryDate - b.shipmentsByDeliveryDate
  );
};
export const prepareOrderShipments = (shipmentsArr) => {
  return shipmentsArr
    .map((shipments) => {
      // this is check for legacy shipment array: GLOBAL15-33803
      if (!Array.isArray(shipments)) return shipments;
      const shipmentIds = [];

      const groupedShipmentsObject = shipments.reduce((acc, shipment) => {
        const groupId = shipment.shipmentGroupId;
        shipmentIds.push(shipment.shipmentId);
        acc[groupId] = {
          ...shipment,
          shipmentIds,
          shipmentLine: [
            ...(acc[groupId] ? acc[groupId].shipmentLine : []),
            ...(shipment.shipmentLine || []),
          ],
        };
        return acc;
      }, {});

      return Object.values(groupedShipmentsObject);
    })
    .flat();
};
