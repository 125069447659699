import { CMTeaser, CmsSite } from '@vf/api-contract';
import { ComposableContext } from '../types';
import {
  extractProperty,
  getTranslation,
  generateLinkFromTeaserTargets,
  extractLocalSettingsExpandedLinks,
} from './utils';

export const maHomeProfileBlock = (
  data: CMTeaser,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const interestsSource = extractLocalSettingsExpandedLinks(
    data,
    'interestsSource'
  )[0];

  return {
    component: 'account/ProfileSummary',
    props: {
      link: generateLinkFromTeaserTargets(
        data.teaserTargets,
        context,
        siteConfiguration,
        data.urlParams,
        cmsBaseUri
      ),
      interests: extractProperty(
        interestsSource,
        'resourceBundle.common.interests',
        []
      ),
      translations: {
        heading: getTranslation(data, 'heading', '$Heading$'),
        buttonText: getTranslation(data, 'buttonText', '$Button Text$'),
        nameLabel: getTranslation(data, 'nameLabel', '$Name Label$'),
        emailLabel: getTranslation(data, 'emailLabel', '$Email$'),
        passwordLabel: getTranslation(data, 'passwordLabel', '$Password$'),
        interestsLabel: getTranslation(
          data,
          'interestsLabel',
          '$Interests Label$'
        ),
      },
    },
  };
};
