import { ComposableContext } from '../types';
import { extractCommonConfigLink, generateLinkFromTarget } from './utils';
import {
  getTranslation,
  extractProperty,
  getComponentConfigByName,
} from './utils/extractProperty';
import { CMPlaceholder, CmsSite } from '@vf/api-contract';
export const favoritesProducts = (
  data: CMPlaceholder,
  context: ComposableContext,
  siteConfiguration: CmsSite,
  cmsBaseUri: string
) => {
  const config = getComponentConfigByName(data, 'product-grid-settings', {});
  const quickShopLink = extractProperty(
    extractCommonConfigLink(siteConfiguration, 'plp-quick-shop'),
    '[0]'
  );
  return {
    component: 'favorites/FavoritesProducts',
    props: {
      productsPerPage: extractProperty(config, 'productsPerPage', 20),
      showAddToFavourites: extractProperty(config, 'showAddToFavourites', true),
      showNoInventoryProducts: extractProperty(
        config,
        'showNoInventoryProducts',
        true
      ),
      showSeeMore: extractProperty(config, 'showSeeMore', true),
      showColorSwatches: extractProperty(config, 'showColorSwatches', true),
      showRegularPrice: extractProperty(config, 'showRegularPrice', true),
      showSalePrice: extractProperty(config, 'showSalePrice', true),
      showWishListIcon: extractProperty(config, 'showWishListIcon', true),
      showProductBadge: extractProperty(config, 'showProductBadge', true),
      showProductName: extractProperty(config, 'showProductName', true),
      showEyebrow: extractProperty(config, 'showEyebrow', true),
      showQuickShop: extractProperty(config, 'showQuickShop', true),
      quickShopLink: generateLinkFromTarget(
        quickShopLink,
        context,
        siteConfiguration,
        cmsBaseUri
      ),
      showRatingsIcon: extractProperty(config, 'showRatingsIcon', true),
      showProductDescription: extractProperty(
        config,
        'showProductDescription',
        true
      ),
      showProductImages: extractProperty(config, 'showProductImages', true),
      translations: {
        addedToWishlist: getTranslation(
          data,
          'addedToWishlist',
          '$Product {{product}} has been added to wishlist$'
        ),
        removedFromWishlist: getTranslation(
          data,
          'removedFromWishlist',
          '$Product {{product}} has been removed from wishlist$'
        ),
        itemsCountPlural: getTranslation(data, 'itemsCountPlural', '$Items$'),
        itemsCountSingular: getTranslation(
          data,
          'itemsCountSingular',
          '$Item$'
        ),
        specialPriceLabel: getTranslation(data, 'specialPriceLabel', '$NOW$'),
        offerPriceLabel: getTranslation(data, 'offerPriceLabel', '$WAS$'),
        seeMoreColorsLink: getTranslation(
          data,
          'seeMoreColorsLink',
          '$See more colors$'
        ),
        quickShopButtonText: getTranslation(
          data,
          'quickShopButtonText',
          '$Quick Shop$'
        ),
        addWishListAriaLabel: getTranslation(
          data,
          'addWishListAriaLabel',
          '$Add to wishlist$'
        ),
        removeWishListAriaLabel: getTranslation(
          data,
          'removeWishListAriaLabel',
          '$Remove from wishlist$'
        ),
        missingProductError: getTranslation(
          data,
          'missingProductError',
          '$Your request cannot be completed, as one or more of the products are not available at this time$'
        ),
      },
    },
  };
};
