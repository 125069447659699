import Vue from 'vue';
import { ComponentInstance, useGtm } from '@vf/composables';
import { Plugin } from '@nuxt/types';
import {
  getCurrentInstance,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

const promotionTracker: Plugin = () => {
  const { isInternalPromotionTrackingEnabled } = useFeatureFlagsStore();
  if (!isInternalPromotionTrackingEnabled) {
    return;
  }
  (Vue as any).mixin({
    setup: function (props, context) {
      const trackingEnabled = context.attrs['data-tracking-enabled'];
      if (!trackingEnabled) {
        return;
      }
      const dispatchEventHandler = ref(null);
      const promotionTrackerIntersectionStatus = ref(false);
      const promotionTrackerTarget = ref(null);
      const promotionTrackerObserver = ref(null);
      const root = getCurrentInstance().proxy;
      const { dispatchEvent } = useGtm(root as ComponentInstance);
      dispatchEventHandler.value = dispatchEvent;

      const handlePromotionTrackerClick = (event: Event) => {
        if (!dispatchEventHandler.value) {
          return;
        }
        const element = event.target as HTMLElement;
        const clickableElements = ['A', 'BUTTON'];
        const isClosestElementClickable = !!element.closest(
          clickableElements.join(', ')
        );
        const isClickableElement =
          clickableElements.includes(element.tagName) ||
          isClosestElementClickable;
        const elementHaveClickHandler = element.getAttribute('onclick');

        if (isClickableElement || elementHaveClickHandler) {
          dispatchEventHandler.value({
            eventName: 'promotionClick',
            overrideAttributes: {
              id: context.attrs['data-id'],
              name: context.attrs['data-tracking-name'],
              creative: context.attrs['data-tracking-creative'],
              position: context.attrs['data-tracking-position'],
            },
          });
        }
      };

      const handlePromotionTrackerImpressions = () => {
        if (!dispatchEventHandler.value) {
          return;
        }
        dispatchEventHandler.value({
          eventName: 'promotionImpressions',
          overrideAttributes: {
            id: context.attrs['data-id'],
            name: context.attrs['data-tracking-name'],
            creative: context.attrs['data-tracking-creative'],
            position: context.attrs['data-tracking-position'],
          },
        });
      };

      onMounted(() => {
        requestAnimationFrame(() => {
          promotionTrackerTarget.value =
            root.$el ??
            (root.$slots.default && root.$slots.default.length
              ? root.$slots.default[0].elm
              : null);

          if (!promotionTrackerTarget.value) {
            return;
          }

          promotionTrackerTarget.value.addEventListener(
            'click',
            handlePromotionTrackerClick,
            {
              capture: true,
            }
          );

          promotionTrackerObserver.value = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (
                  entry.isIntersecting &&
                  !promotionTrackerIntersectionStatus.value
                ) {
                  promotionTrackerIntersectionStatus.value = true;
                  handlePromotionTrackerImpressions();
                }
              });
            },
            { threshold: 0.95 }
          );
          promotionTrackerObserver.value.observe(promotionTrackerTarget.value);
        });
      });

      onUnmounted(() => {
        promotionTrackerTarget.value?.removeEventListener(
          'click',
          handlePromotionTrackerClick,
          {
            capture: true,
          }
        );
        promotionTrackerObserver.value?.disconnect();
      });
    },
  });
};

export default promotionTracker;
