import { CMPlaceholder } from '@vf/api-contract';
import { getDynamicField } from './utils';

export const pdpCustomAttribute = (data: CMPlaceholder) => {
  return {
    component: 'pdp/CustomAttributeRenderer',
    props: {
      attributeName: getDynamicField(data, 'attribute'),
    },
  };
};
