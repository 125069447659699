import { useCustoms } from '../../../useCustoms';
import { ComponentInstance } from '../../../types';

type GetCartImageProduct = {
  image_uri: string;
  fallbackImage?: string;
  productId: string;
  recipeId?: string;
  isCustoms?: boolean;
  imageSize?: string;
  imageView?: string;
};

const getCartImage = async (
  instance: ComponentInstance,
  params: GetCartImageProduct
): Promise<string> => {
  const customsEnabled = instance.$env.CUSTOMS_ENABLED === 'true';
  const fallbackImage = params.fallbackImage || '/img/product-fallback.svg';

  // Customs recipe image
  if (customsEnabled && params.isCustoms) {
    const { customsImage } = useCustoms(instance);

    try {
      return await customsImage({
        recipe: params.recipeId,
        view: params.imageView || 'left_pdp',
        size: params.imageSize || 'lg-pdp-white',
      });
    } catch (e) {
      return fallbackImage;
    }
  }

  // standard product
  if (params.productId) {
    return params.image_uri;
  }

  return fallbackImage;
};

export default getCartImage;
