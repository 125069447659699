import { Plugin } from '@nuxt/types';
import { useAuthentication, useSignInToStore } from '@vf/composables';
import { getCookieName, getVueInstanceFromContext } from '../helpers';

type EmployeeCookieType = {
  employeeToken: string;
  employeeExpiryTime: string;
};

const CHECK_EMPLOYEE_TOKEN_INTERVAL = 1000 * 60 * 5; // 5min

const EmployeeSession: Plugin = async (context) => {
  const vueInstance = getVueInstanceFromContext(context);
  const { signInGuest } = useAuthentication(vueInstance);
  const { employeeSignOut, getEmployeeSignInPageUrl } = useSignInToStore(
    vueInstance
  );
  const cookies = context.app.$cookies;

  setInterval(async () => {
    const employeeInfoData: EmployeeCookieType = cookies.get(
      getCookieName('emp', vueInstance)
    );
    if (employeeInfoData && employeeInfoData.employeeExpiryTime) {
      const expDateTime = new Date(
        employeeInfoData.employeeExpiryTime
      ).getTime();
      const isoDateTime = new Date(new Date().toISOString()).getTime();
      if (expDateTime <= isoDateTime) {
        employeeSignOut();
        await signInGuest();
        window.location.pathname = getEmployeeSignInPageUrl.value;
      }
    }
  }, CHECK_EMPLOYEE_TOKEN_INTERVAL);
};

export default EmployeeSession;
