import { endpoints } from '../../config';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { AxiosResponse } from 'axios';
import { TokensObject } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

type AuthorisationBody = {
  usid: string;
  authorizationCode: string;
  codeVerifier: string;
  st?: string;
  orderNo?: string;
  action?: string;
  guestObjects: {
    guestId: string;
    basketId: string;
    favoriteId: string;
    saveForLaterId: string;
  };
};

export const getUserToken = (configuration: ApiClientConfiguration) => (
  type: 'Guest' | 'Registered' | 'refresh',
  data: Partial<AuthorisationBody>
): Promise<AxiosResponse<TokensObject>> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).authentication.getUserToken, {
      config: configuration,
    }),
    {
      type,
      ...data,
    },
    {
      headers: {
        ...headerBuilder(configuration),
        'x-usid': data.usid,
      },
    }
  );
};
