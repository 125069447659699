import { computed } from '@vue/composition-api';
import { useBasicInformation } from '../useAccount/composables/useBasicInformation';

enum PlaceholderFields {
  title = 'title',
  subtitle = 'subtitle',
  text = 'text',
  content = 'content',
}

export type ReplaceableField = keyof typeof PlaceholderFields;

const usePlaceholders = (instance) => {
  const { basicInformation } = useBasicInformation(instance);

  const fields: ReplaceableField[] = Object.values(PlaceholderFields);

  const placeholderValues = computed(() => ({
    '{{firstName}}': basicInformation.value.firstName,
  }));

  const currentPlaceholderValues = computed(() => {
    const result = {};
    for (const key in placeholderValues.value) {
      if (!placeholderValues.value[key]) continue;
      result[key] = placeholderValues.value[key];
    }
    return result;
  });

  return {
    fields,
    placeholders: Object.keys(placeholderValues.value),
    placeholderValues,
    currentPlaceholders: computed(() =>
      Object.keys(currentPlaceholderValues.value)
    ),
    currentPlaceholderValues,
  };
};

export default usePlaceholders;
