import { Product, CartLineItem } from '@vf/api-client';
import { CustomsAddToCartPayloadItem } from '../useCart';

export const parseCustomsCodeBySku = (sku: string): string => {
  try {
    return sku.replace(/:/g, '').slice(5, 11);
  } catch (e) {
    // If this failed, then the recipe is invalid, and the customizer
    // will fall through to a white model, so no pre-created code is to be sent
    console.error(`Could not generate the customs code: ${e.message}`);
  }
};

export const isCustomsProduct = (
  product: Partial<Product | CustomsAddToCartPayloadItem | CartLineItem>
): boolean => {
  const hasRecipe = !!(
    (product as Partial<Product>).customsRecipeID ||
    (product as Partial<Product>).recipeId ||
    (product as Partial<Product>).recipe
  );
  return (
    hasRecipe &&
    !!(
      product.isCustoms ||
      (product as Partial<Product>).dummyCustoms ||
      (product as Partial<Product>).customsCustomizer
    )
  );
};

export const getRecipeId = (product: Partial<Product | CartLineItem>) =>
  product.customsRecipeID || product.recipeId || product.recipe;
