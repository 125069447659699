import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils';

export const pdpDescription = (data: CMPlaceholder) => {
  return {
    component: 'pdp/ProductDescription',
    props: {
      translations: {
        overview: getTranslation(data, 'overview', '$Overview$'),
        productDetails: getTranslation(
          data,
          'productDetails',
          '$Product Details$'
        ),
      },
    },
  };
};
