
import { onMounted, defineComponent, PropType } from '@vue/composition-api';
import { useCms, useMonetate } from '@vf/composables';
import { PreviewType } from '@vf/composables/src/useCms/types';
import { finalContentRenderer } from '@/components/cms/cmsUtils';
import { Context } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'CmsModal',
  props: {
    /** Name of modal window to fetch and render */
    resourceId: {
      type: String,
      default: null,
    },
    resourceType: {
      type: String as PropType<PreviewType>,
      default: 'fragment',
    },
    path: {
      type: String,
      default: null,
    },
    contextKey: {
      type: String as PropType<Context>,
      default: Context.Modal,
    },
  },

  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    // TODO: set "modal-content" context to dynamic one basing on requested resource
    const cms = useCms(root, props.contextKey);
    const { displayErrorMessages } = errorMessages(root);
    const { getExperienceDecision } = useMonetate(root, props.contextKey);

    cms.clearModalContent();

    onMounted(async () => {
      showSpinner();

      try {
        await cms.setup({ forcePreview: root.$isPreview });

        /** get CMS Modal data and save it in composable */
        await cms.fetchModalContents(
          props.resourceType,
          props.resourceId,
          props.path
        );
        getExperienceDecision(true);

        emit('content-load', true);
      } catch (e) {
        console.error(e);
        useModal().closeModal();
        displayErrorMessages(null);
      } finally {
        hideSpinner();
      }
    });

    return {
      cms,
      cmsDebugEnabled: root.$env.DEBUG_SSR === 'true',
    };
  },

  render(createElement) {
    if (!this.cms.modalData.value && !this.cms.modalDataErrors.value.length) {
      return createElement('div', '');
    }

    return finalContentRenderer(
      createElement,
      this.cms.pageLayoutName.value,
      this.contextKey,
      [this.cms.modalData.value],
      this.cms.modalDataErrors.value,
      this.$root,
      [],
      this.cms.headerConfig
    );
  },
});
