import { anchor } from './anchor';
import { accountSubNavigation } from './accountSubNavigation';
import { addCreditCard } from './addCreditCard';
import { addToCartButton } from './addToCartButton';
import { aemContentImport } from './aemContentImport';
import { applePayCta } from './applePayCta';
import { articleAbout } from './articleAbout';
import { articleGrid } from './articleGrid';
import { backToOrderHistoryButton } from './backToOrderHistoryButton';
import { backToTopButton } from './backToTopButton';
import { basicAccountForm } from './basicAccountForm';
import { basicAccountFormBirthdate } from './basicAccountFormBirthdate';
import { billingAddressesSelect } from './billingAddressesSelect';
import { breadcrumbs } from './breadcrumbs';
import { buyInStoreInfo } from './buyInStoreInfo';
import { carousel } from './carousel';
import { cartBottomPromo } from './cartBottomPromo';
import { cartEmpty } from './cartEmpty';
import { cartMini } from './cartMini';
import { cartNotification } from './cartNotification';
import { cartOrderSummary } from './cartOrderSummary';
import { cartProductList } from './cartProductList';
import { cartTopPromo } from './cartTopPromo';
import { cartUpsell } from './cartUpsell';
import { cartProductUpsell } from './cartProductUpsell';
import { changePasswordForm } from './changePasswordForm';
import { checkoutBillingInfo } from './checkoutBillingInfo';
import { checkoutCreateAccount } from './checkoutCreateAccount';
import { checkoutFooter } from './checkoutFooter';
import { checkoutGiftCard } from './checkoutGiftCard';
import { checkoutReward } from './checkoutReward';
import { checkoutRewards } from './checkoutRewards';
import { checkoutAthleteDiscount } from './checkoutAthleteDiscount';
import { checkoutHeader } from './checkoutHeader';
import { checkoutHeaderDynamic } from './checkoutHeaderDynamic';
import { checkoutLogin } from './checkoutLogin';
export { checkoutGiftOption } from './checkoutGiftOption';
import { checkoutPaymentInfo } from './checkoutPaymentInfo';
import { checkoutPickupInfo } from './checkoutPickupInfo';
import { checkoutPromoCode } from './checkoutPromoCode';
import { checkoutShippingInfo } from './checkoutShippingInfo';
import { checkoutShippingInfoNotification } from './checkoutShippingInfoNotification';
import { checkoutShippingMethod } from './checkoutShippingMethod';
import { checkoutSidebarTop } from './checkoutSidebarTop';
import { checkoutTermsConditions } from './checkoutTermsConditions';
import { clearSessionButton } from './clearSessionButton';
import { contactForm } from './contactForm';
import { createAccountForm } from './createAccountForm';
import { creditCardsSelect } from './creditCardsSelect';
import { ctaButton } from './ctaButton';
import { ctaButtonProduct } from './ctaButtonProduct';
import { customContent } from './customContent';
import { customsCta } from './customsCta';
import { customsCustomizer } from './customsCustomizer';
import { customShoesTeaser } from './customShoesTeaser';
import { customShoesTopLeft } from './customShoesTopLeft';
import { customsHpSlide } from './customsHpSlide';
import { customsHpStatic } from './customsHpStatic';
import { customsImageSlide } from './customsImageSlide';
import { customsProductSlide } from './customsProductSlide';
import { dashboardLayout } from './dashboardLayout';
import { dataListingTable } from './dataListingTable';
import { easyContentRow } from './easyContentRow';
import { easyHero } from './easyHero';
import { easyHeroGifVideo } from './easyHeroGifVideo';
import { easyHeroVideoPlayer } from './easyHeroVideoPlayer';
import { emailPreferencesForm } from './emailPreferencesForm';
import { languagePreferencesForm } from './languagePreferencesForm';
import { discountNotification } from './discountNotification';
import { employeeDiscountModal } from './employeeDiscountModal';
import { extendedSizing } from './extendedSizing';
import { faqCollection } from './faqCollection';
import { favoritesListGridShare } from './favoritesListGridShare';
import { favoritesProducts } from './favoritesProducts';
import { favoritesShareButton } from './favoritesShareButton';
import { footer } from './footer';
import { fullWidthTape } from './fullWidthTape';
import { geminiHero } from './gemini/hero';
import { geminiSectionCarousel } from './gemini/sectionCarousel';
import { geminiProductCard } from './gemini/productCard';
import { geminiSectionGrid } from './gemini/sectionGrid';
import { geminiContentCard } from './gemini/contentCard';
import { giftCardBalanceForm } from './giftCardBalanceForm';
import { giftCardBalanceFormMessage } from './giftCardBalanceFormMessage';
import { gifVideo } from './gifVideo';
import { header } from './header';
import { heading } from './heading';
import { hero } from './hero';
import { heroItem } from './heroItem';
import { iframe } from './iframe';
import { image } from './image';
import { imageWithOverlay } from './imageWithOverlay';
import { interestsForm } from './interestsForm';
import { interestsFormCollection } from './interestsFormCollection';
import { klarnaIntegration } from './klarnaIntegration';
import { liveChatButtonSidebar } from './liveChatButtonSidebar';
import { lottieLoader } from './lottieLoader';
import { loyaltyLogin } from './loyaltyLogin';
import { loyaltyMemberDashboard } from './loyaltyMemberDashboard';
import { loyaltyOrderRegister } from './loyaltyOrderRegister';
import { loyaltyPdpBanner } from './loyaltyPdpBanner';
import { loyaltyQuestions } from './loyaltyQuestions';
import { loyaltyRegister } from './loyaltyRegister';
import { loyaltyRegisterLogin } from './loyaltyRegisterLogin';
import { loyaltySuccessStep } from './loyaltySuccessStep';
import { maAddAddress } from './maAddAddress';
import { maCreateAccount } from './maCreateAccount';
import { maCreateAccountBirthdate } from './maCreateAccountBirthdate';
import { maForgotPassword } from './maForgotPassword';
import { maHomeAddressBlock } from './maHomeAddressBlock';
import { maHomeOrderBlock } from './maHomeOrderBlock';
import { maHomeProfileBlock } from './maHomeProfileBlock';
import { maHomeCreditCardsBlock } from './maHomeCreditCardsBlock';
import { maNavigation } from './maNavigation';
import { maResetPassword } from './maResetPassword';
import { maSignIn } from './maSignIn';
import { maTitleHeader } from './maTitleHeader';
import { notifications } from './notifications';
import { olapicImageGrid } from './olapicImageGrid';
import { oneLineLabel } from './oneLineLabel';
import { orderBrandAddress } from './orderBrandAddress';
import { orderDetails } from './orderDetails';
import { orderDetailsSidebar } from './orderDetailsSidebar';
import { orderHistory } from './orderHistory';
import { orderHistoryCtaShowAll } from './orderHistoryCtaShowAll';
import { orderProducts } from './orderProducts';
import { orderRegister } from './orderRegister';
import { orderSidebar } from './orderSidebar';
import { orderStatusForm } from './orderStatusForm';
import { orderSuccess } from './orderSuccess';
import { orderSuccessDetails } from './orderSuccessDetails';
export { smsSubscriptionCheckout } from './smsSubscriptionCheckout';
import { pageTitle } from './pageTitle';
import { pdpActionButtons } from './pdpActionButtons';
import { pdpAdditionalProductDetails } from './pdpAdditionalProductDetails';
import { pdpAdditionalSizesSelector } from './pdpAdditionalSizesSelector';
import { pdpAttribute } from './pdpAttribute';
import { pdpBenefitRating } from './pdpBenefitRating';
import { pdpButtonsWrapper } from './pdpButtonsWrapper';
import { pdpColorChoser } from './pdpColorChoser';
import { pdpCompleteTheLook } from './pdpCompleteTheLook';
import { pdpCustomAttribute } from './pdpCustomAttribute';
import { pdpCustomizeCta } from './pdpCustomizeCta';
import { pdpDescription } from './pdpDescription';
import { pdpFeaturesSection } from './pdpFeaturesSection';
import { pdpLink } from './pdpLink';
import { pdpPrice } from './pdpPrice';
import { pdpQuantitySelect } from './pdpQuantitySelect';
import { pdpReviews } from './pdpReviews';
import { pdpReviewsForm } from './pdpReviewsForm';
import { pdpSizeAndFit } from './pdpSizeAndFit';
import { pdpSizeFitGuide } from './pdpSizeFitGuide';
import { pdpSizeSelector } from './pdpSizeSelector';
import { pdpStickyHeader } from './pdpStickyHeader';
import { pdpThumbnail } from './pdpThumbnail';
import { pdpTitle } from './pdpTitle';
import { pdpRating } from './pdpRating';
import { pdpProductUpsell } from './pdpProductUpsell';
import { pdpUpsell } from './pdpUpsell';
import { shopByActivityPageTabs } from './shopByActivityPageTabs';
import { plpFiltersAndGrid } from './plpFiltersAndGrid';
import { plpFiltersDisplayToggle } from './plpFiltersDisplayToggle';
import { plpCtaViewAll } from './plpCtaViewAll';
import { plpDescription } from './plpDescription';
import { plpFilters } from './plpFilters';
import { plpGrid } from './plpGrid';
import productGrid from './productGrid';
import { plpSortBy } from './plpSortBy';
import { productFindInStore } from './productFindInStore';
import { productsListNoResults } from './productsListNoResults';
import { promoBar } from './promoBar';
import { promotionMessage } from './promotionMessage';
import { quickAddToCart } from './quickAddToCart';
import { quickshopFindInStore } from './quickshopFindInStore';
import { quickshopSizeChart } from './quickshopSizeChart';
import { recommendationCarousel } from './recommendationCarousel';
import { returnHeading } from './returnHeading';
import { returnSteps } from './returnSteps';
import { richText } from './richText';
import { searchArticlesSort } from './searchArticlesSort';
import { searchArticlesTaxonomy } from './searchArticlesTaxonomy';
import { searchProductsSort } from './searchProductsSort';
import { searchResultsArticles } from './searchResultsArticles';
import { searchResultsProducts } from './searchResultsProducts';
import { searchResultsToggle } from './searchResultsToggle';
import { seoRelatedWidget } from './seoRelatedWidget';
import { shareMyCartModal } from './shareMyCartModal';
import { shareMyFavoritesModal } from './shareMyFavoritesModal';
import { shippingAddressesSelect } from './shippingAddressesSelect';
import { shoeSizeSelector } from './shoeSizeSelector';
import { shopBySectionList } from './shopBySectionList';
import { shoppingCartLabel } from './shoppingCartLabel';
import { signInConfirmationModal } from './signInConfirmationModal';
import { signInToStore } from './signInToStore';
import { signOutCta } from './signOutCta';
import { siteSelector } from './siteSelector';
import { smartGiftCtaButton } from './smartGiftCTAButton';
import { socialMediaLink } from './socialMediaLink';
import { socialNative } from './socialNative';
import { socialNativeUploadMedia } from './socialNativeUploadMedia';
import { stateRedirect } from './stateRedirect';
import { stickyHeader } from './stickyHeader';
import { storesList } from './storesList';
import { teaserGridItem } from './teaserGridItem';
import { thirdPartyComponent } from './thirdPartyComponent';
import { videoWithOverlay } from './videoWithOverlay';
import { orderRegisterXplr } from './orderRegisterXplr';
import { richtextCtas } from './richtextCtas';
import { carouselSlideActivity } from './carouselSlideActivity';
import { carouselSlideStory } from './carouselSlideStory';
import { teaserTile } from './teaserTile';
import { openChat } from './openChat';
import { quickshopProductCtas } from './quickshopProductCtas';
import { curalateSocialMedia } from './curalateSocialMedia';
import { plpRestrict } from './plpRestrict';
import { visualFilterCarousel } from './visualFilterCarousel';
import { visualFilterPlaceholder } from './visualFilterPlaceholder';
import { emailCapture } from './emailCapture';
import { seoFaqs } from './seoFaqs';

// alias names to support multiple view types with the same codebase
const heroComponent = hero;
const pdpAddToCart = addToCartButton;

/** @deprecated, add-credit-card is new viewtype name for this component.
 * Left here for backwards compatibility, to be removed later. */
const creditCardForm = addCreditCard;

export {
  anchor,
  accountSubNavigation,
  addCreditCard,
  addToCartButton,
  aemContentImport,
  applePayCta,
  articleAbout,
  articleGrid,
  backToOrderHistoryButton,
  backToTopButton,
  basicAccountForm,
  basicAccountFormBirthdate,
  billingAddressesSelect,
  breadcrumbs,
  buyInStoreInfo,
  carousel,
  cartBottomPromo,
  cartEmpty,
  cartMini,
  cartNotification,
  cartOrderSummary,
  cartProductList,
  cartTopPromo,
  cartUpsell,
  cartProductUpsell,
  changePasswordForm,
  checkoutBillingInfo,
  checkoutCreateAccount,
  checkoutFooter,
  checkoutGiftCard,
  checkoutReward,
  checkoutRewards,
  checkoutAthleteDiscount,
  checkoutHeader,
  checkoutHeaderDynamic,
  checkoutLogin,
  checkoutPaymentInfo,
  checkoutPickupInfo,
  checkoutPromoCode,
  checkoutShippingInfo,
  checkoutShippingInfoNotification,
  checkoutShippingMethod,
  checkoutSidebarTop,
  checkoutTermsConditions,
  clearSessionButton,
  contactForm,
  createAccountForm,
  creditCardForm,
  creditCardsSelect,
  ctaButton,
  ctaButtonProduct,
  customContent,
  customsCta,
  customsCustomizer,
  customShoesTeaser,
  customShoesTopLeft,
  customsHpSlide,
  customsHpStatic,
  customsImageSlide,
  customsProductSlide,
  dashboardLayout,
  dataListingTable,
  easyContentRow,
  easyHero,
  easyHeroGifVideo,
  easyHeroVideoPlayer,
  emailPreferencesForm,
  languagePreferencesForm,
  discountNotification,
  employeeDiscountModal,
  extendedSizing,
  faqCollection,
  favoritesListGridShare,
  favoritesProducts,
  favoritesShareButton,
  footer,
  fullWidthTape,
  geminiHero,
  geminiSectionCarousel,
  geminiProductCard,
  geminiSectionGrid,
  geminiContentCard,
  giftCardBalanceForm,
  giftCardBalanceFormMessage,
  gifVideo,
  header,
  heading,
  hero,
  heroComponent,
  heroItem,
  iframe,
  image,
  imageWithOverlay,
  interestsForm,
  interestsFormCollection,
  klarnaIntegration,
  liveChatButtonSidebar,
  lottieLoader,
  loyaltyLogin,
  loyaltyMemberDashboard,
  loyaltyOrderRegister,
  loyaltyPdpBanner,
  loyaltyQuestions,
  loyaltyRegister,
  loyaltyRegisterLogin,
  loyaltySuccessStep,
  maAddAddress,
  maCreateAccount,
  maCreateAccountBirthdate,
  maForgotPassword,
  maHomeAddressBlock,
  maHomeOrderBlock,
  maHomeProfileBlock,
  maHomeCreditCardsBlock,
  maNavigation,
  maResetPassword,
  maSignIn,
  maTitleHeader,
  notifications,
  olapicImageGrid,
  oneLineLabel,
  orderBrandAddress,
  orderDetails,
  orderDetailsSidebar,
  orderHistory,
  orderHistoryCtaShowAll,
  orderProducts,
  orderRegister,
  orderSidebar,
  orderStatusForm,
  orderSuccess,
  orderSuccessDetails,
  pageTitle,
  pdpActionButtons,
  pdpAdditionalProductDetails,
  pdpAdditionalSizesSelector,
  pdpAttribute,
  pdpAddToCart,
  pdpBenefitRating,
  pdpButtonsWrapper,
  pdpColorChoser,
  pdpCompleteTheLook,
  pdpCustomAttribute,
  pdpCustomizeCta,
  pdpDescription,
  pdpFeaturesSection,
  pdpLink,
  pdpPrice,
  pdpQuantitySelect,
  pdpReviews,
  pdpReviewsForm,
  pdpSizeAndFit,
  pdpSizeFitGuide,
  pdpSizeSelector,
  pdpStickyHeader,
  pdpThumbnail,
  pdpTitle,
  pdpRating,
  pdpProductUpsell,
  pdpUpsell,
  plpFiltersAndGrid,
  plpFiltersDisplayToggle,
  plpCtaViewAll,
  plpDescription,
  plpFilters,
  plpGrid,
  productGrid,
  plpRestrict,
  plpSortBy,
  productFindInStore,
  promoBar,
  promotionMessage,
  quickAddToCart,
  quickshopFindInStore,
  quickshopSizeChart,
  recommendationCarousel,
  returnSteps,
  returnHeading,
  richText,
  searchArticlesSort,
  searchArticlesTaxonomy,
  productsListNoResults,
  searchProductsSort,
  searchResultsArticles,
  searchResultsProducts,
  searchResultsToggle,
  seoRelatedWidget,
  shareMyCartModal,
  shareMyFavoritesModal,
  shippingAddressesSelect,
  shoeSizeSelector,
  shopBySectionList,
  shoppingCartLabel,
  signInConfirmationModal,
  signInToStore,
  signOutCta,
  siteSelector,
  smartGiftCtaButton,
  socialMediaLink,
  socialNative,
  socialNativeUploadMedia,
  stateRedirect,
  stickyHeader,
  storesList,
  teaserGridItem,
  thirdPartyComponent,
  videoWithOverlay,
  orderRegisterXplr,
  richtextCtas,
  carouselSlideActivity,
  carouselSlideStory,
  teaserTile,
  openChat,
  quickshopProductCtas,
  curalateSocialMedia,
  visualFilterCarousel,
  visualFilterPlaceholder,
  emailCapture,
  shopByActivityPageTabs,
  seoFaqs,
};

export { newsArticleFilters } from './newsArticleFilters';
export { newsArticleGrid } from './newsArticleGrid';
export { pinnedArticle } from './pinnedArticle';
export { campaign } from './campaign';
export { accordion } from './accordion';
export { accordionItem } from './accordionItem';
export { smsSubscriptionProfile } from './smsSusbcriptionProfile';
export { eSpot } from './espot';
export { easyImageBlockWithOverlay } from './easyImageBlockWithOverlay';
export { easyBannerImage } from './easyBannerImage';
export { easyBodyText } from './easyBodyText';
export { easyTextBlock } from './easyTextBlock';
export { easyBannerAnimation } from './easyBannerAnimation';
export { easyList } from './easyList';
export { easyHorizontalSimple } from './easyHorizontalSimple';
