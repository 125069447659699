import { getTranslation, extractProperty } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const pdpAdditionalProductDetails = (data: CMPlaceholder) => {
  return {
    component: 'pdp/ProductAdditionalDetails',
    props: {
      translations: {
        title: getTranslation(data, 'title', '$title$'),
        style: getTranslation(data, 'style', '$style$'),
      },
      types: extractProperty(data, `resourceBundle.types`, [
        'description',
        'features',
        'productDetails',
      ]),
    },
  };
};
