import { ComposablesStorage } from '../../../types';
import initStorage from '../../../utils/storage';
import { computed, ref, Ref } from '@vue/composition-api';
import { BasicInformationObject } from '@vf/api-contract';
import { prepareLocale } from '../../../utils/query';
import { apiClientFactory } from '@vf/api-client';
import { useAuthentication, useI18n, useRequestTracker } from '@vf/composables';
import { errorMessages } from '../../../utils/errorMessages';
import { UseAccountStorage } from '../../index';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useUserStore } from '@vf/composables/src/store/user';

const NON_EDITABLE_PROFILE_FIELDS = ['email', 'birthDate'];

export const useBasicInformation = (instance) => {
  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const { localeCode } = useI18n(instance);

  const userStore = useUserStore(instance);

  const {
    getUsid,
    setLoyaltyEnrolled,
    processPendingActionForLoyaltyUser,
  } = useAuthentication(instance);

  const { displayErrorMessages } = errorMessages(instance);
  const {
    getBasicInformation: getBasicInformationAPI,
    setBasicInformation: setBasicInformationAPI,
  } = apiClientFactory(instance);

  const storage: ComposablesStorage<UseAccountStorage> = initStorage<UseAccountStorage>(
    instance,
    'useAccount'
  );

  const basicInformation: Ref<BasicInformationObject> =
    storage.get('basicInformation') ??
    storage.save(
      'basicInformation',
      ref({
        loyaltyOptInDate: '',
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        birthDate: '',
        postalCode: '',
        address: [],
        subscriptions: [],
        enrollments: [],
        preferences: {
          languageCode: '',
          countryCode: '',
          currencyCode: '',
          interests: '',
          preferredShoeSize: '',
          preferredShoeSizeGender: '',
        },
        employee: null,
        athlete: null,
        prosumer: null,
      })
    );

  const getBasicInformation = async (
    { isBackgroundRequest } = { isBackgroundRequest: false }
  ) => {
    if (!isClient) {
      const error = new Error();
      instance.$log.warn(
        `[@useBasicInformation/index::getBasicInformation] Called on SSR. Trace: ${error.stack}`
      );
      return;
    }

    const { tag, isAlreadyTracked, ongoingRequest } = trackRequest(
      'useAccount-getBasicInformation',
      isBackgroundRequest
    );
    if (isAlreadyTracked) {
      await ongoingRequest;
      return clearRequest(tag);
    }

    try {
      const query: string[] = [prepareLocale(localeCode())];
      const basicData = await getBasicInformationAPI(query.join('&'), {
        usid: getUsid.value,
      });
      const profile = basicData.data.consumerProfile;
      const userAddresses: Record<string, any>[] =
        profile.consumerDetails?.address ?? [];

      const athlete = profile.consumerDetails?.athlete;
      const prosumer = profile.consumerDetails?.prosumer;

      basicInformation.value = {
        ...profile.consumerDetails,
        loyaltyOptInDate: profile.loyaltyOptInDate,
        address: userAddresses.map((item) => {
          return {
            ...item,
            postalCode: item.postalCode,
            // TODO: change when province is provided from backend: ECOM15-9020
            province: item.stateCityProvDept,
            firstName: item.recipientFirstName,
            lastName: item.recipientLastName,
            phone: item.recipientContactPhone,
            ...(item.recipientContactEmail && {
              email: item.recipientContactEmail,
            }),
          };
        }),
        subscriptions: profile.subscriptions as any,
        enrollments: profile.enrollments as any,
        preferences: profile.preferences as any,
        ...(athlete && {
          athlete: {
            ...athlete,
            annualCap: athlete.annualCredit,
          },
        }),
        ...(prosumer && {
          prosumer: {
            ...prosumer,
            annualCap: prosumer.annualCredit,
          },
        }),
      };

      userStore.$patch({
        athlete: athlete && {
          currentSpend: athlete.currentSpend,
          annualCap: athlete.annualCredit,
        },
      });

      // set loyalty enrollment status based on latest fetched consumer data
      // important in case of updating enrollment by a legacy customer
      setLoyaltyEnrolled(profile.isLoyaltyMember ?? false);
      await processPendingActionForLoyaltyUser();
    } catch (e) {
      displayErrorMessages(e);
    } finally {
      clearRequest(tag, isBackgroundRequest);
    }
  };

  const setBasicInformation = async (dataForm) => {
    NON_EDITABLE_PROFILE_FIELDS.forEach((fieldName: string) => {
      if (basicInformation.value[fieldName]) {
        delete dataForm[fieldName]; // if already set, trying to update specific fields will cause AUT436/AUT403 error
      }
    });

    try {
      const updatedBasicForm = await setBasicInformationAPI(dataForm, {
        usid: getUsid.value,
      });
      // basicInformation.value = updatedBasicForm.data;
      // TODO: save response data to storage (basicInformation.value) after ECOM15-6714 is done
      // TODO: remove getBasicInformation() from line 54;
      // https://digital.vfc.com/jira/browse/ECOM15-6714
      await getBasicInformation();
      return updatedBasicForm;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const resetBasicInformation = () => {
    // TODO after migration to pinia we can use reset state.$reset()
    // https://pinia.vuejs.org/core-concepts/state.html#resetting-the-state
    basicInformation.value = {
      loyaltyOptInDate: '',
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      birthDate: '',
      address: [],
      subscriptions: [],
      enrollments: [],
      preferences: {
        languageCode: '',
        countryCode: '',
        currencyCode: '',
        interests: '',
        preferredShoeSize: '',
        preferredShoeSizeGender: '',
      },
      employee: null,
      athlete: null,
      prosumer: null,
    };
  };

  const interestsList = computed(() =>
    basicInformation.value.preferences.interests?.length
      ? basicInformation.value.preferences.interests.split('|')
      : []
  );

  const setCountryPreferences = ({ languageCode, country, currency }) => {
    basicInformation.value.preferences.languageCode = languageCode;
    basicInformation.value.preferences.countryCode = country;
    basicInformation.value.preferences.currencyCode = currency;
  };

  return {
    getBasicInformation,
    setBasicInformation,
    basicInformation,
    resetBasicInformation,
    customerEmail: computed(() => basicInformation.value.email),
    interestsList,
    setCountryPreferences,
  };
};
