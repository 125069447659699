import { extractProperty } from './utils';
import { CMPlaceholder } from '@vf/api-contract';

export const anchor = (data: CMPlaceholder) => {
  if (data.viewtype !== 'anchor') return null;
  const customId = extractProperty(data, 'customId');
  return {
    component: 'shared/Anchor',
    attrs: {
      'data-id': customId,
    },
    props: {
      id: customId,
    },
  };
};
