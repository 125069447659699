import { ApiCallMethod, CmsQuery, CmsApiClientSettings } from '../types';
import Axios, { AxiosInstance } from 'axios';
import wrapper from 'axios-cache-plugin';
import { getCacheWrapperConfig } from '../../helpers';

const cmsResourcesWrapperConfig = getCacheWrapperConfig('cmsResourceHttp');
export const http = wrapper(Axios, cmsResourcesWrapperConfig);
export const defaultHttp = Axios.create();

http.__addFilter(/site|fragment|menu|pagecontentbypath/);

export const runRequest = (settings: CmsApiClientSettings) => async <T>(
  query: CmsQuery
): Promise<T> => {
  const uri = replaceUrlParams(query);
  const executor = settings.disableCache ? defaultHttp : http;

  try {
    // call axios request using
    const response = await execute(
      `${settings.baseUri}${uri}${settings.contentEnv || ''}`,
      query.method,
      settings.headers,
      executor
    );

    return response.data as T;
  } catch (err) {
    // needs to be console for now, until CMS API client gets proper config
    console.error(
      `[@api-client/getCmsResource/services/http.ts::runRequest] Cms request executor error. URI: ${
        settings.baseUri
      }${uri} - Method: ${query.method} - Error: ${
        err.message
      } - Request Settings: ${JSON.stringify(settings)}`
    );
    return null;
  }
};

export const replaceUrlParams = (query: CmsQuery) => {
  let uri = query.uri;

  // replace params placeholders with values
  if (query.params) {
    Object.keys(query.params).forEach((paramName: string) => {
      uri = uri.replace(
        `:${paramName}`,
        (query.params[paramName] || '').toString()
      );
    });
  }

  return uri;
};

export const execute = (
  uri: string,
  method: ApiCallMethod,
  headers: CmsApiClientSettings['headers'],
  executor: AxiosInstance
) =>
  executor({
    url: uri,
    method: method,
    headers: headers,
    validateStatus: () => true,
  });
