import { CMVideo, CMVideoTrack } from '@vf/api-contract';
import { VideoObject } from '@vf/api-contract/src';
import { getMediaSeoAttributes } from './getMediaSeoAttributes';
import { extractProperty } from './extractProperty';

export const getVideoObject = (
  videos: CMVideo[],
  cmsBaseUri: string
): VideoObject => {
  const mainVideoObject = videos?.[0];

  if (!mainVideoObject) {
    return null;
  }

  const videoSourcesKeys = ['data', 'data2', 'data3'];

  const sourcesArray = videoSourcesKeys.reduce((sources, key) => {
    if (mainVideoObject[key]?.uri) {
      sources.push({
        src: `${cmsBaseUri}${mainVideoObject[key].uri}`,
        type: extractProperty(mainVideoObject, `${key}.contentType`),
      });
    }
    return sources;
  }, []);

  if (mainVideoObject.dataUrl) {
    sourcesArray.push({
      src: mainVideoObject.dataUrl,
    });
  }

  const poster = mainVideoObject?.pictures?.[0]?.data.uri
    ? `${cmsBaseUri}${mainVideoObject.pictures[0]?.data.uri}`
    : '';

  return {
    sources: sourcesArray,
    name: mainVideoObject.name,
    seo: getMediaSeoAttributes(mainVideoObject),
    tracks: (mainVideoObject.videoTracks || []).reduce((tracks, videoTrack) => {
      if (videoTrack?.data?.uri) {
        tracks.push({
          data: {
            uri: `${cmsBaseUri}${videoTrack.data.uri}`,
            contentType: videoTrack.data.contentType,
          },
          kind: videoTrack.kind,
          srclang: videoTrack.srclang,
          label: videoTrack.label,
          default: videoTrack.default,
        });
      }
      return tracks;
    }, [] as CMVideoTrack[]),
    poster,
    options: {
      preload: extractProperty(mainVideoObject, 'preload', null),
      autoplay: extractProperty(
        mainVideoObject,
        'playerSettings.autoplay',
        false
      ),
      hideControls: extractProperty(
        mainVideoObject,
        'playerSettings.hideControls',
        false
      ),
      muted: extractProperty(mainVideoObject, 'playerSettings.muted', false),
      loop: extractProperty(mainVideoObject, 'playerSettings.loop', false),
      showPlayButton: extractProperty(
        mainVideoObject,
        'playerSettings.showPlayButton',
        false
      ),
      showSubtitles: extractProperty(
        mainVideoObject,
        'playerSettings.showSubtitles',
        false
      ),
    },
  };
};
