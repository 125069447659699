import { axios, headerBuilder } from '../../helpers';
import { ApiClientConfiguration } from '../../configuration';
import { endpoints } from '../../config';
import { AxiosResponse } from 'axios';
import { GetAllFlagsResponse } from '@vf/api-contract';

const hasParamsInUrl = (url: string, param: string) => {
  const startParams = url.indexOf('?');
  if (startParams > -1) {
    const urlParams = new URLSearchParams(
      url.substring(startParams).toLowerCase()
    );
    return urlParams.get(param) === 'true';
  }
  return false;
};

export const getFeatureFlags = (
  configuration: ApiClientConfiguration
) => async (): Promise<AxiosResponse<GetAllFlagsResponse>> => {
  const url = endpoints.get(configuration).featureFlags.allFlagsState;

  const vfQaEngineerEnabled =
    configuration.req.headers['vf-qa-engineer'] === 'true' ||
    hasParamsInUrl(configuration.req.url, 'vfqaengineer');

  const vfCoreRedesignEnabled =
    configuration.req.headers['vf-core-redesign'] === 'true' ||
    hasParamsInUrl(configuration.req.url, 'vfcoreredesign');

  const ldPassthrough =
    configuration.req.headers['x-ld-passthrough']?.toString() ||
    new URLSearchParams(configuration.req.url.split('?')[1])
      .get('ldPassthrough')
      ?.toString();

  const launchDarklyHeaders = {
    ...(vfQaEngineerEnabled && {
      'vf-qa-engineer': `${vfQaEngineerEnabled}`,
    }),
    ...(vfCoreRedesignEnabled && {
      'vf-core-redesign': `${vfCoreRedesignEnabled}`,
    }),
    ...(ldPassthrough && { 'x-ld-passthrough': ldPassthrough }),
  };

  return axios.get<GetAllFlagsResponse>(url, {
    headers: headerBuilder(configuration, launchDarklyHeaders),
  });
};
