import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const swapItem = (configuration: ApiClientConfiguration) => (
  data
): Promise<any> => {
  const body = {
    cartId: data.cartId,
    oldItem: {
      ...data.oldItem,
    },
    newItem: {
      ...data.newItem,
      qty: parseInt(data.newItem?.qty),
      maxQty: parseInt(data.newItem?.maxQty),
    },
  };
  const params = data.storeId
    ? { favStoreId: data.storeId, action: 'pickup' }
    : undefined;
  return axios.put(
    urlBuilder(endpoints.get(configuration).cart.swapItem, {
      config: configuration,
      mapping: {
        id: data.cartId,
      },
    }),
    body,
    {
      headers: headerBuilder(configuration),
      params,
    }
  );
};
