import { DecoratorMappingName } from '../../../types';
import decoratorHandlers from './handlers';

export const applyDecorators = (
  decorators: DecoratorMappingName[],
  cmsItem: any,
  mappedData: any
) => {
  let decoratedContent = mappedData;

  for (const decorator of decorators) {
    if (decoratorHandlers[decorator]) {
      decoratedContent = decoratorHandlers[decorator](cmsItem, mappedData);
    }
  }

  return decoratedContent;
};
