import { CMTeaser } from '@vf/api-contract';
import { getDynamicField, getTranslation } from './utils';

export const orderHistoryCtaShowAll = (data: CMTeaser) => {
  const orderPageType = getDynamicField(data, 'orderPageType', 'order');

  return {
    component: 'account/OrderHistoryCtaShowAll',
    props: {
      translations: {
        continueShopping: getTranslation(
          data,
          'continueShopping',
          '$Continue shopping$'
        ),
        showAllOrders: getTranslation(
          data,
          'showAllOrders',
          '$Show all orders$'
        ),
      },
      orderPageType,
    },
  };
};
