import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const removeFromFavorites = (configuration: ApiClientConfiguration) => (
  favoriteId: string,
  itemId: string
): Promise<any> => {
  const url: string = urlBuilder(
    endpoints.get(configuration).wishList.removeFromFavorites,
    {
      config: configuration,
      mapping: {
        favoriteId,
        itemId,
      },
    }
  );

  return axios.delete(url, {
    data: null,
    headers: headerBuilder(configuration),
  });
};
