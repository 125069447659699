import { Plugin } from '@nuxt/types';

const CustomsCaidPlugin: Plugin = async (context, inject) => {
  const locale = context.app.i18n.locale;
  const envVar = `CUSTOMS_CAID_${locale.toUpperCase().replace('-', '_')}`;

  inject('customsCAID', context.app.$env[envVar] || '');
};

export default CustomsCaidPlugin;
