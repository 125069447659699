





































































































































































































































































































import { PropType } from 'vue';
import { computed, defineComponent, inject, ref } from '@vue/composition-api';
import VfEyebrow from './Atom.Eyebrow.vue';
import VfProductBadge from './Molecule.ProductBadge.vue';
import { OrderStatus } from '@vf/shared/src/utils/helpers';
import { focus, swipe } from '@vf/shared/src/utils/directives';
import { isSafari } from '@vf/shared/src/utils/helpers';
import { useCompareStore } from '@vf/composables/src/store/compare';
import { storeToRefs } from 'pinia';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { prepareStoreFilter } from '@vf/composables/src/utils/query';
import { FILTER_URL } from '@vf/composables/src/useShippingFilter/utils';

interface BadgeImgMap {
  large: string;
  medium: string;
  small: string;
}

interface Badge {
  src?: BadgeImgMap;
  alt: string;
}

interface AddToCartCtaConfig {
  label: string;
  icon: string;
  disabled: boolean;
}

export default defineComponent({
  name: 'VfProductCard',
  components: {
    VfProductBadge,
    VfEyebrow,
  },
  directives: { focus, swipe },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    badges: {
      type: Array as PropType<Array<Badge>>,
      default: () => [],
    },
    imageWidth: {
      type: Number,
      default: 326,
    },
    imageHeight: {
      type: Number,
      default: 326,
    },
    rating: {
      type: [Object, Array],
      default: () => ({}),
    },
    ratingLink: {
      type: String,
      default: '',
    },
    showAddToCartButton: {
      type: Boolean,
      default: true,
    },
    showAddToFavourites: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: String,
      default: '',
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    isOnWishlist: {
      type: Boolean,
      default: false,
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: 'heart',
    },
    addToWishlistLoading: {
      type: Boolean,
      default: false,
    },
    isOnWishlistIcon: {
      type: [String, Array],
      default: 'heart__fill',
    },
    title: {
      type: String,
      default: '',
    },
    eyebrow: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    link: {
      type: String,
      default: '',
    },
    seeMoreLabel: {
      type: String,
      default: '',
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    specialPricePrefix: {
      type: String,
      default: '',
    },
    regularPricePrefix: {
      type: String,
      default: '',
    },
    addWishListAriaLabel: {
      type: String,
      default: '',
    },
    removeWishListAriaLabel: {
      type: String,
      default: '',
    },
    reviewLabel: {
      type: String,
      default: '',
    },
    reviewLink: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    searchState: {
      type: Object,
      default: null,
    },
    isProductGrid: {
      type: Boolean,
      default: false,
    },
    colors: {
      type: Object,
      default: () => ({}),
    },
    productId: {
      type: String,
      default: '',
    },
    parsedProductId: {
      type: String,
      default: '',
    },
    showQuickShop: {
      type: Boolean,
      default: true,
    },
    showQuickShopCta: {
      type: Boolean,
      default: true,
    },
    isImageSwiper: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    swiperSettings: {
      type: Object,
      default: () => ({}),
    },
    showSwiperIcons: {
      type: Boolean,
      default: false,
    },
    variantId: {
      type: String,
      default: '',
    },
    pictureScreenLarge: Number,
    pictureScreenMedium: Number,
    addToCartCta: {
      type: Object as PropType<AddToCartCtaConfig>,
      default: () => null,
    },
    showAddToCartCta: Boolean,
    isCustoms: Boolean,
    isSoldOut: {
      type: Boolean,
      default: false,
    },
    photoRatioBadge: {
      type: String,
      default: '',
    },
    lastFocusedElement: {
      type: String,
    },
  },
  setup(props, { root, emit }) {
    const { isCompareEnabled } = useFeatureFlagsStore();
    const isVansPlpRedesignEnabled = inject('isVansPlpRedesignEnabled');
    const _parsedLink = ref('');
    const swiped = ref(false);
    const { isMobileDevice } = root.$viewport;
    const { products: compareProducts } = storeToRefs(useCompareStore());

    const isQuickShopOpened = ref(false);
    const quickShopImage = ref('');
    const selectedColorCode = ref(
      props.colors.selected?.value ?? props.colors.selected
    );

    const showReviewLink = computed(() => {
      const showReview = [OrderStatus.shipped].includes(
        props.status as OrderStatus
      );
      return !!(showReview && props.reviewLink && props.reviewLabel);
    });

    const isShowQuickShopTile = computed(() => {
      return (
        props.showQuickShop &&
        !!root.$themeConfig?.productCard?.showQuickShopTile
      );
    });

    const productCardModifier = computed<string>(() => {
      return (
        root.$themeConfig?.productCard?.productCardModifier || 'vf-product-card'
      );
    });

    const parseLink = (link: string): string => {
      if (root.$themeConfig?.productCard?.updateColorOnBack) {
        return `${link}${link.includes('?') ? '' : '?'}&tile=${
          props.variantId
        }`;
      }

      return link;
    };

    const changeSelectedSwatch = (data): void => {
      _parsedLink.value = parseLink(data.link);
      emit('change-selected-swatch', data.label);
    };

    const handlePictureWrapperSwipe = () => {
      swiped.value = !swiped.value;
    };

    _parsedLink.value = parseLink(props.link);

    const handleCtaClick = () => {
      if (props.addToCartCta.disabled) return;
      emit('add-to-cart');
    };

    const toggleCompare = (selected: boolean) => {
      if (selected) {
        const selectedPrice = productConfig.value.colors.find(
          ({ value }) => value === selectedColorCode.value
        )?.price;
        compareProducts.value.push({
          id: productConfig.value.id,
          fullId: props.productId,
          link: productConfig.value.link,
          title: productConfig.value.title,
          regularPrice: selectedPrice
            ? root.$formatPrice(selectedPrice.original, props.currency)
            : productConfig.value.regularPrice,
          specialPrice:
            selectedPrice && selectedPrice.current !== selectedPrice.original
              ? root.$formatPrice(selectedPrice.current, props.currency)
              : '',
          image: arrayImages.value[0],
        });
      } else {
        compareProducts.value = compareProducts.value.filter(
          ({ fullId }) => fullId !== props.productId
        );
      }
    };

    const isCompareSelected = computed(() =>
      compareProducts.value.some(({ fullId }) => fullId === props.productId)
    );

    const ariaLabel = computed(() => {
      return props.isOnWishlist
        ? props.removeWishListAriaLabel
        : props.addWishListAriaLabel;
    });

    const currentWishlistIcon = computed(() => {
      return props.isOnWishlist ? props.isOnWishlistIcon : props.wishlistIcon;
    });

    const wishlistIconClasses = computed(() => {
      const defaultClass = 'vf-button--pure vf-product-card__wishlist-icon';
      return `${defaultClass} ${
        props.isOnWishlist
          ? 'vf-product-card--on-wishlist product-card__wishlist-button--on-wishlist'
          : ''
      }`;
    });

    const getProductEyebrow = computed(() => {
      return props.eyebrow?.length > 0 ? props.eyebrow[0] : '';
    });

    const renderRating = computed(() => {
      return (
        !props.isCustoms &&
        props.rating &&
        !!Object.keys(props.rating).length &&
        props.ratingLink
      );
    });

    const hasEyebrow = computed(() => {
      return props.eyebrow && !!Object.keys(props.eyebrow).length;
    });

    const hasSpecialPrice = computed(() => {
      return props.specialPrice && props.regularPrice !== props.specialPrice;
    });

    const badgePosition = computed(() => {
      const positionModifier =
        root.$themeConfig.productCard?.badgePosition ?? 'left';
      return `product-card__badges--${positionModifier}`;
    });

    const arrayImages = computed(() => {
      const imageArray = Array.isArray(props.image)
        ? props.image
        : [props.image];
      if (quickShopImage.value) {
        imageArray[0] = quickShopImage.value;
      }
      return imageArray.map((image) =>
        typeof image === 'string' ? { small: image } : image
      );
    });

    const showQuickshopButton = computed(() => {
      return (
        props.showAddToCartButton &&
        !root.$viewport?.isMobileDevice &&
        props.showQuickShopCta
      );
    });

    const parsedLink = computed(() => {
      if (!root.$route.query[FILTER_URL]) return _parsedLink.value;
      return (
        _parsedLink.value +
        (_parsedLink.value.includes('?') ? '&' : '?') +
        prepareStoreFilter(root.$route.query[FILTER_URL].toString())
      );
    });

    const productConfig = computed(() => ({
      id: props.parsedProductId,
      link: parsedLink.value,
      title: props.title,
      regularPrice: root.$formatPrice(props.regularPrice, props.currency),
      specialPrice: hasSpecialPrice.value
        ? root.$formatPrice(props.specialPrice, props.currency)
        : '',
      colors: props.colors.options,
    }));

    const changeQuickshopImage = (colorCode: string) => {
      const colorName = props.colors.options?.find(
        ({ value }) => value === colorCode
      ).name;
      selectedColorCode.value = colorCode;
      if (props.isImageSwiper) {
        emit('change-quickshop-image', {
          colorCode,
          colorName,
          productId: props.productId,
          productName: props.title,
        });
      } else {
        let shotType = '';
        if (root.$themeConfig?.productCard?.showImagesWithShotType) {
          shotType = `_${colorCode || ''}_hero`;
        }
        quickShopImage.value = root.$mediaUrlGenerator({
          colorCode,
          colorName,
          productName: props.title,
          pid: props.parsedProductId,
          shotType,
        }) as string;
      }
    };

    const toggleQuickshop = (flag: boolean) => {
      isQuickShopOpened.value = flag;
    };

    const actionsConfig = {
      changeSelectedSwatch,
      changeQuickshopImage,
      toggleQuickshop,
    };

    const isSwiperItemLazy = (key: number) => {
      return isSafari()
        ? key > 1 && key < arrayImages.value.length - 1
        : props.lazy || key > 0;
    };

    const toggleIsOnWishlist = () => {
      emit('click-wishlist', !props.isOnWishlist);
    };

    const triggerMouseOver = () => {
      if (!props.isImageSwiper) return;
      emit('load-plp-gallery', {
        productId: props.productId,
        productName: props.title,
        colorCode: props.colors.selected,
        colorName: props.colors.options.find(
          (color) => color.value === props.colors.selected
        )?.name,
      });
    };

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isQuickShopOpened,
      quickShopImage,
      changeSelectedSwatch,
      isShowQuickShopTile,
      parsedLink,
      productCardModifier,
      showReviewLink,
      isMobileDevice,
      swiped,
      handlePictureWrapperSwipe,
      handleCtaClick,
      isCoreRedesignEnabled,
      isVansPlpRedesignEnabled,
      compareProducts,
      toggleCompare,
      isCompareSelected,
      ariaLabel,
      currentWishlistIcon,
      wishlistIconClasses,
      getProductEyebrow,
      renderRating,
      hasEyebrow,
      hasSpecialPrice,
      badgePosition,
      arrayImages,
      showQuickshopButton,
      productConfig,
      actionsConfig,
      isSwiperItemLazy,
      toggleIsOnWishlist,
      triggerMouseOver,
      changeQuickshopImage,
      isCompareEnabled,
    };
  },
});
