/**
 * @deprecated - logic moved to news-article-grid component
 */
import { CMPlaceholder } from '@vf/api-contract';
import { getTranslation } from './utils/extractProperty';

export const newsArticleFilters = (data: CMPlaceholder) => {
  return {
    component: 'shared/NewsArticleFilters',
    props: {
      title: data.title || '$Brand News$',
      translations: {
        all: getTranslation(data, 'all', '$All$'),
        filter: getTranslation(data, 'filter', '$Filter$'),
      },
    },
  };
};
