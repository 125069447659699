import { axios } from '../../helpers';
import { endpoints } from '../../config';
import { validateResponse } from './helpers';
import { PhotorankApi, PhotorankApiAuth } from '../../api-types';
import { ApiClientConfiguration } from '../../configuration';

export const getSNAuth = (configuration: ApiClientConfiguration) => async (
  apiKey: string
): Promise<string> => {
  const url = endpoints
    .get(configuration)
    .socialNative.getAuth.replace('{api_key}', apiKey);
  const response = await axios.get<PhotorankApi<PhotorankApiAuth>>(url);
  validateResponse(response.data);
  return response.data.data._embedded.customer.id;
};
