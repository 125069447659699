import { CmsBreadcrumb } from './../types';
import { useI18n } from '../../useI18n';
import { ROUTES } from './../../utils/routes';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useProductStore } from '../../store/product';

const getBreadcrumbLink = (breadcrumb, localePath) => {
  /** CMS should have whole link to target page in breadcrumbs response */
  if (breadcrumb.breadcrumbLink) {
    return localePath(breadcrumb.breadcrumbLink);
  } else if (breadcrumb.link) {
    return localePath(breadcrumb.link);
  }

  /** As a fallback lets build links to commerce pages ourselves */
  switch (breadcrumb.type) {
    case 'product':
      return localePath(ROUTES.PRODUCT(breadcrumb.id));
    case 'category':
      return localePath(ROUTES.CATEGORY(breadcrumb.id));
    default:
      return null;
  }
};

/** Transform the categoryBreadcrumb object from the CMS form to a FE usable form */
const getCategoryBreadcrumbs = (commerceBreadcrumbs, localePath) => {
  return commerceBreadcrumbs.map((breadcrumb) => {
    return {
      link: getBreadcrumbLink(breadcrumb, localePath),
      text: breadcrumb.title || breadcrumb.id || '$breadcrumb title$',
      dataNavigation: `${breadcrumb.type}-${breadcrumb.title}`,
      type: breadcrumb.type,
    };
  });
};

// get extra info when we are in PDP, for example is come from plp or change color in VANS
const getExtraInformationForProduct = (
  cmsRef,
  filteredPaths,
  pageContent,
  lastProductTilePath,
  type
) => {
  let isMovingTroughThumbnails = false;
  let isSameCategory = false;

  if (type === 'product') {
    const currentCategory = filteredPaths[filteredPaths.length - 1];
    const lastPreviousBreadcrumb =
      cmsRef?.breadcrumbs[cmsRef?.breadcrumbs?.length - 1];

    // when move from plp grid to pdp
    isMovingTroughThumbnails =
      (isClient &&
        lastProductTilePath
          ?.toLowerCase()
          .includes(pageContent?.link.toLowerCase())) ||
      false;
    // ex: vans pdp change color
    isSameCategory = lastPreviousBreadcrumb?.link.includes(
      currentCategory?.link
    );
  }
  return { isMovingTroughThumbnails, isSameCategory };
};

export const getBreadcrumbs = (
  pageContent,
  type: string,
  instance,
  cmsRef
): CmsBreadcrumb[] => {
  const { localePath } = useI18n(instance);
  try {
    const replacePageSegment = new RegExp(
      `${cmsRef.cmsSiteConfiguration.root.segment}\/?`
    );

    const breadcrumbConfig = {
      includeProductTitle: false,
      includeCategoriesPath: true,
    };

    /** If these two exists we are in PDP or PLP */
    if (pageContent?.commercePath && pageContent?.commerceRef) {
      const filteredPaths = pageContent.commercePath
        .filter((item) => item.id !== 'root')
        .reverse();

      const productStore = useProductStore();
      const {
        isSameCategory,
        isMovingTroughThumbnails,
      } = getExtraInformationForProduct(
        cmsRef,
        filteredPaths,
        pageContent,
        productStore.lastClickedProductTilePath,
        type
      );
      productStore.$patch({ lastClickedProductTilePath: null });

      const breadcrumbPaths = breadcrumbConfig.includeCategoriesPath
        ? [...filteredPaths]
        : [];

      /** If user is on PDP and not landed directy on it, it returns previous category breadcrumbs with product addition, if not takes breadcrumb from CMS */
      const breadcrumbs =
        type === 'product' && (isSameCategory || isMovingTroughThumbnails)
          ? [
              ...cmsRef.breadcrumbs.filter(
                (breadcrumb) => breadcrumb.type === 'category' // Prevents breadcrumb from getting longer moving trough thumbnails
              ),
              ...getCategoryBreadcrumbs([pageContent.commerceRef], localePath),
            ]
          : type === 'product'
          ? getCategoryBreadcrumbs(
              [...breadcrumbPaths, pageContent.commerceRef],
              localePath
            ) // Landed directly on PDP
          : getCategoryBreadcrumbs(
              [...filteredPaths, pageContent.commerceRef],
              localePath
            ); // Landed directly on PLP

      if (
        !breadcrumbConfig.includeProductTitle &&
        breadcrumbs?.[breadcrumbs.length - 1]?.type === 'product'
      ) {
        // Remove last segment of Breadcrumb (Product Title)
        breadcrumbs.pop();
        return breadcrumbs;
      }

      return breadcrumbs;
    } else if (pageContent?.pagePath) {
      const result = pageContent.pagePath.map((path) => {
        const replaceChar = path.segment.includes('home') ? '/' : '';
        const link = localePath(
          path.urlSegment.replace(replacePageSegment, replaceChar)
        );

        return {
          link: link,
          text: path.title,
          dataNavigation: `${path.segment}`,
        };
      });

      if (pageContent.urlSegment && pageContent.canonical) {
        result.push({
          link: localePath(
            pageContent.urlSegment.replace(replacePageSegment, '')
          ),
          text: pageContent.title,
          dataNavigation: pageContent.canonical.name
            .toLowerCase()
            .split(' ')
            .join('-'),
        });
      }

      result.shift();
      return result;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};
