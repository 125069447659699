
import { CreateElement, PropType, RenderContext, VNode } from 'vue';

type ContentPositionProp = {
  small: string;
  medium: string;
  large: string;
};

type GuttersProp = {
  custom?: Record<string, unknown>;
  top: string;
  right: string;
  bottom: string;
  left: string;
};

const defaultGutters = {
  custom: '',
  top: '',
  right: '',
  bottom: '',
  left: '',
};

const getSpacersClasses = (spacersSettings: GuttersProp): string[] => {
  const { ...spacers } = spacersSettings;
  return Object.values(spacers);
};

const getCustomSpacers = (spacersSettings: GuttersProp) => {
  if (!spacersSettings.custom) return;
  return spacersSettings.custom;
};

export default {
  name: 'VfColumn',
  functional: true,
  props: {
    small: [String, Number],
    medium: [String, Number],
    large: [String, Number],
    fullWidth: Boolean,
    hiddenOnSmall: Boolean,
    hiddenOnMedium: Boolean,
    hiddenOnLarge: Boolean,
    orderSmall: Number,
    orderMedium: Number,
    orderLarge: Number,
    smallNoVerticalMargin: Boolean,
    mediumNoVerticalMargin: Boolean,
    largeNoVerticalMargin: Boolean,
    smallNoMargin: Boolean,
    mediumNoMargin: Boolean,
    largeNoMargin: Boolean,
    verticalAlign: String,
    contentPosition: {
      type: Object as PropType<ContentPositionProp>,
      default: () => ({ small: '', medium: '', large: '' }),
    },
    smallPaddings: {
      type: Object as PropType<GuttersProp>,
      default: () => defaultGutters,
    },
    mediumPaddings: {
      type: Object as PropType<GuttersProp>,
      default: () => defaultGutters,
    },
    largePaddings: {
      type: Object as PropType<GuttersProp>,
      default: () => defaultGutters,
    },
    smallMargins: {
      type: Object as PropType<GuttersProp>,
      default: () => defaultGutters,
    },
    mediumMargins: {
      type: Object as PropType<GuttersProp>,
      default: () => defaultGutters,
    },
    largeMargins: {
      type: Object as PropType<GuttersProp>,
      default: () => defaultGutters,
    },
    sticky: {
      type: Object as PropType<GuttersProp>,
      default: () => ({ small: false, medium: false, large: false }),
    },
  },
  render(
    h: CreateElement,
    { props, listeners, data, slots }: RenderContext
  ): VNode {
    return h(
      'div',
      {
        ...data,
        style: {
          ...(props.orderSmall > 0 && { '--order-sm': props.orderSmall }),
          ...(props.orderMedium > 0 && { '--order-md': props.orderMedium }),
          ...(props.orderLarge > 0 && { '--order-lg': props.orderLarge }),
          '--sm-custom-margin': getCustomSpacers(props.smallMargins),
          '--md-custom-margin': getCustomSpacers(props.mediumMargins),
          '--lg-custom-margin': getCustomSpacers(props.largeMargins),
          '--sm-custom-padding': getCustomSpacers(props.smallPaddings),
          '--md-custom-padding': getCustomSpacers(props.mediumPaddings),
          '--lg-custom-padding': getCustomSpacers(props.largePaddings),
          alignSelf: props.verticalAlign,
        },
        class: [
          'column',
          data.class,
          {
            [`small-${props.small}`]: props.small,
            [`medium-${props.medium}`]: props.medium,
            [`large-${props.large}`]: props.large,

            [`small-full-width-${props.small}`]: props.fullWidth,
            [`medium-full-width-${props.medium}`]: props.fullWidth,
            [`large-full-width-${props.large}`]: props.fullWidth,
            'hide-on-small': props.hiddenOnSmall,
            'hide-on-medium': props.hiddenOnMedium,
            'hide-on-large': props.hiddenOnLarge,
            'no-margin-vertical-small': props.smallNoVerticalMargin,
            'no-margin-vertical-medium': props.mediumNoVerticalMargin,
            'no-margin-vertical-large': props.largeNoVerticalMargin,
            'no-margin-small': props.smallNoMargin,
            'no-margin-medium': props.mediumNoMargin,
            'no-margin-large': props.largeNoMargin,
            'full-width': props.fullWidth,
            'custom-margin-sm': props.smallMargins.custom,
            'custom-margin-md': props.mediumMargins.custom,
            'custom-margin-lg': props.largeMargins.custom,
            'custom-padding-sm': props.smallPaddings.custom,
            'custom-padding-md': props.mediumPaddings.custom,
            'custom-padding-lg': props.largePaddings.custom,
            ...(props.contentPosition.small && {
              [`align-${props.contentPosition.small}-sm`]: true,
            }),
            ...(props.contentPosition.medium && {
              [`align-${props.contentPosition.medium}-md`]: true,
            }),
            ...(props.contentPosition.large && {
              [`align-${props.contentPosition.large}-lg`]: true,
            }),
          },
          getSpacersClasses(props.largePaddings),
          getSpacersClasses(props.mediumPaddings),
          getSpacersClasses(props.smallPaddings),
          getSpacersClasses(props.largeMargins),
          getSpacersClasses(props.mediumMargins),
          getSpacersClasses(props.smallMargins),
        ],
        on: listeners,
      },
      slots().default
    );
  },
};
