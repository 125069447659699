import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

type CheckGiftCardBalanceObject = {
  gc_number: string;
  gc_pin: string;
  g_recaptcha_response: string;
};

export const checkGiftCardBalance = (configuration: ApiClientConfiguration) => (
  data: CheckGiftCardBalanceObject
): Promise<any> => {
  return axios.post(
    urlBuilder(endpoints.get(configuration).cart.checkGiftCardBalance, {
      config: configuration,
    }),
    {
      gc_number: data.gc_number,
      gc_pin: data.gc_pin,
      g_recaptcha_response: data.g_recaptcha_response,
    },
    {
      headers: headerBuilder(configuration),
    }
  );
};
