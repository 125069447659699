import type { ContactUsCategory } from '@vf/api-contract';
import { axios, headerBuilder, urlBuilder } from '../../helpers';
import { endpoints } from '../../config';
import { ApiClientConfiguration } from '../../configuration';

export const getCategorySubject = (configuration: ApiClientConfiguration) => (
  locale: string
): Promise<ContactUsCategory[]> => {
  return axios
    .get(
      urlBuilder(
        endpoints
          .get(configuration)
          .contactForm.getCategorySubject.replace('{locale}', locale),
        { config: configuration }
      ),
      { headers: headerBuilder(configuration) }
    )
    .then((res) => res.data.categories || []);
};
