import { getDynamicField, getTranslation } from './utils';
import { CMPlaceholder } from 'packages/api-contract/src/cms';

export const pdpSizeFitGuide = (data: CMPlaceholder) => {
  return {
    component: 'pdp/PdpSizeFitGuide',
    props: {
      translations: {
        ctaText: getTranslation(data, 'ctaText', '$Size Chart$'),
      },
      showModelMeasurements: getDynamicField(data, 'showModelPlaque', false),
    },
  };
};
