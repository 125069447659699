





















import { defineComponent } from '@vue/composition-api';

const getDefaultSpacers = () => ({
  custom: '',
  top: '',
  right: '',
  bottom: '',
  left: '',
});

export default defineComponent({
  name: 'SmartColumn',
  props: {
    /** Column size on small viewports 1-12 */
    small: {
      type: [String, Number],
      default: null,
    },
    /** Column size on medium viewports 1-12 */
    medium: {
      type: [String, Number],
      default: null,
    },
    /** Column size on large viewports 1-12 */
    large: {
      type: [String, Number],
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /** Content position inside the column */
    contentPosition: {
      type: Object,
      default: () => ({
        small: '',
        medium: '',
        large: '',
      }),
    },
    /** Flag to define if grid component is sticky or not  */
    sticky: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Flag to render column without bottom margins on small viewport */
    smallNoBottomMargin: {
      type: Boolean,
      default: false,
    },
    /** Flag to render column without bottom margins on medium viewport */
    mediumNoBottomMargin: {
      type: Boolean,
      default: false,
    },
    /** Flag to render column without bottom margins on large viewport */
    largeNoBottomMargin: {
      type: Boolean,
      default: false,
    },
    /** Flag to hide column on small breakpoint */
    hiddenOnSmall: {
      type: Boolean,
      default: false,
    },
    /** Flag to hide column on medium breakpoint */
    hiddenOnMedium: {
      type: Boolean,
      default: false,
    },
    /** Flag to hide column on large breakpoint */
    hiddenOnLarge: {
      type: Boolean,
      default: false,
    },
    /** Column position order on small breakpoint */
    orderSmall: {
      type: Number,
      default: 0,
    },
    /** Column position order on medium breakpoint */
    orderMedium: {
      type: Number,
      default: 0,
    },
    /** Column position order on large breakpoint */
    orderLarge: {
      type: Number,
      default: 0,
    },
    verticalAlign: {
      type: String,
      default: null,
    },
    /** Paddings for small viewport inside the column */
    smallPaddings: {
      type: Object,
      default: () => getDefaultSpacers(),
    },
    /** Paddings for medium viewport inside the column */
    mediumPaddings: {
      type: Object,
      default: () => getDefaultSpacers(),
    },
    /** Paddings for large viewport inside the column */
    largePaddings: {
      type: Object,
      default: () => getDefaultSpacers(),
    },
    /** Margins for small viewport inside the column */
    smallMargins: {
      type: Object,
      default: () => getDefaultSpacers(),
    },
    /** Margins for medium viewport inside the column */
    mediumMargins: {
      type: Object,
      default: () => getDefaultSpacers(),
    },
    /** Margins for large viewport inside the column */
    largeMargins: {
      type: Object,
      default: () => getDefaultSpacers(),
    },
  },
  computed: {
    isConsistent() {
      const stickyObj = Object.values(this.$props.sticky);
      return (
        stickyObj.every((value) => value) || stickyObj.every((value) => !value)
      );
    },
    isSticky() {
      switch (this.$root.$viewport.size) {
        case 'large':
          return this.$props.sticky.large;
        case 'medium':
          return this.$props.sticky.medium;
        default:
          return this.$props.sticky.small;
      }
    },
  },
});
