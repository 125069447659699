


























import { defineComponent, ref } from '@vue/composition-api';

enum DropFileEvents {
  fileChanged = 'file-changed',
  extensionNotAccepted = 'extension-not-accepted',
  outOfMaxSizes = 'out-of-max-sizes',
}

export default defineComponent({
  name: 'UiDropFile',
  props: {
    // file extension accepted
    accept: {
      type: String,
      default: '',
    },
    // max size available in bytes
    maxSizes: {
      type: Number,
      default: 0,
    },
    ariaLabel: {
      type: String,
    },
  },
  emits: [
    DropFileEvents.extensionNotAccepted,
    DropFileEvents.fileChanged,
    DropFileEvents.outOfMaxSizes,
  ],
  setup(props, { emit }) {
    const RefUploadFile = ref<HTMLInputElement | null>(null);
    const isDragging = ref(false);

    // for now validateExt support only real extension ex: '.jpg,.png...'
    // feel free to extend if you want support mimeType like image/*...
    const validateExt = (files: FileList) => {
      if (props.accept === '') {
        return files;
      }
      for (let i = 0; i < files.length; i++) {
        const ext = files[i].name.substring(files[i].name.lastIndexOf('.'));
        if (!props.accept.includes(ext)) {
          emit(DropFileEvents.extensionNotAccepted, files[i]);
          throw new Error(DropFileEvents.extensionNotAccepted);
        }
      }
      return files;
    };

    const validateSize = (files: FileList) => {
      if (props.maxSizes) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > props.maxSizes) {
            emit(DropFileEvents.outOfMaxSizes, files[i]);
            throw new Error(DropFileEvents.outOfMaxSizes);
          }
        }
      }
      return files;
    };

    const onChange = () => {
      if (RefUploadFile.value) {
        try {
          const validFiles = validateSize(
            validateExt(RefUploadFile.value.files)
          );
          emit(DropFileEvents.fileChanged, validFiles);
        } catch {
          // reset input file
          RefUploadFile.value.value = '';
        }
      }
    };

    const onDrop = (e: DragEvent) => {
      e.preventDefault();
      if (RefUploadFile.value) {
        RefUploadFile.value.files = e.dataTransfer.files;
        onChange();
      }
      isDragging.value = false;
    };

    const onDragover = (e: DragEvent) => {
      e.preventDefault();
      isDragging.value = true;
    };

    const onDragleave = () => {
      isDragging.value = false;
    };

    const onContentClick = () => {
      RefUploadFile.value.click();
    };

    return {
      isDragging,
      onChange,
      onContentClick,
      onDragleave,
      onDragover,
      onDrop,
      RefUploadFile,
    };
  },
});
