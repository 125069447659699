import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';

export const useProductStore = defineStore('product', () => {
  const lastClickedProductTilePath = ref<string | null>(null);

  return {
    lastClickedProductTilePath,
  };
});
