import { CMComponent, VFConfigComponent } from '@vf/api-contract';

export const dataIdDecorator = (
  cmsData: Exclude<CMComponent, VFConfigComponent>,
  mappedData: any
) => {
  if (!mappedData || (mappedData.attrs && mappedData.attrs['data-id']))
    return mappedData;
  if (!mappedData.attrs) {
    mappedData.attrs = { 'data-id': cmsData?.id };
    return mappedData;
  }
  mappedData.attrs['data-id'] = cmsData?.id;
  return mappedData;
};
